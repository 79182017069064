import { ApiResult, FieldType, ViewModelBase } from "@shoothill/core";
import { ClientListItemDTO, ClientListModel } from "./ClientListModel";
import { observable, computed, IObservableArray, action, runInAction } from "mobx";
import { GenericIncludeDeleted } from "Globals/Models";
import { AppUrls } from "AppUrls";
import { ClientListItem } from "./ClientListItem";

export class ClientListViewModel extends ViewModelBase<ClientListModel> {
    private static _instance: ClientListViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }
    @observable public showAEModal: boolean = false;
    @observable public errorMessage: string = "";

    public clients: IObservableArray<ClientListModel> = observable<ClientListModel>([]);

    @computed get getClients(): ClientListModel[] {
        return this.clients.slice();
    }

    @computed get getClientsList(): ClientListItemDTO[] {
        return this.clients.slice().map((c) => {
            return c.toTableModel();
        });
    }

    @action
    public cleanUp = () => {
        // TODO Any Cleanup Code here.
    };

    private constructor() {
        super(new ClientListModel(), false);
        this.setDecorators(ClientListModel);
    }

    @action
    public showAddEditModal(show: boolean) {
        this.showAEModal = show;
    }

    @computed
    public get getShowAddEditModal() {
        return this.showAEModal;
    }

    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (await this.isModelValid()) {
            //Do stuff here
            this.errorMessage = "Form is valid";
        } else {
            this.errorMessage = "Form is not valid";
        }
    };

    public exportAsCSV = () => {
        alert("TODO Export CSV ");
    };

    public async isFieldValid(fieldName: keyof FieldType<ClientListModel>): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    @action
    public setClients(clients: ClientListItem[]) {
        this.clients.clear();
        clients.forEach((item, index) => {
            let domainModel = this.clients.find((c) => c.id === item.id);

            if (!domainModel) {
                domainModel = new ClientListModel();

                domainModel.fromDto(item);
                this.clients.push(domainModel);
            }
        });
    }

    @action
    public async loadClientsAsync(): Promise<ApiResult<ClientListItem[]>> {
        const request: GenericIncludeDeleted = {
            includeDeleted: true,
        };

        let apiResult = await this.Post<ClientListItem[]>(AppUrls.Server.Client.GetAll, request);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                this.setClients(apiResult.payload);
            });
        }
        return apiResult;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
