import { FieldType, ViewModelBase } from "@shoothill/core";
import { RFIListFilterParamsModel } from "./RFIListFilterParamsModel";

export class RFIFilterViewModel extends ViewModelBase<RFIListFilterParamsModel> {
    private static _intsance: RFIFilterViewModel;
    public static get Instance() {
        return this._intsance || (this._intsance = new this());
    }

    constructor() {
        super(new RFIListFilterParamsModel(), false);
        this.setDecorators(RFIListFilterParamsModel);
    }

    public async isFieldValid(fieldName: keyof FieldType<RFIListFilterParamsModel>): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
