import React, { useEffect, useState } from "react";
import { observer, useObserver } from "mobx-react-lite";
import { EndOfWeekViewModel } from "./EndOfWeekViewModel";
import { DarwinSelect } from "../../../../../Components/AutoComplete/DarwinSelect";
import { SHBox } from "Components/Box";
import { FormControl, FormControlLabel, Radio, Typography } from "@material-ui/core";
import moment from "moment";
import { RadioGroup } from "../../../../../Globals/Styles/AdminStyling";
import { FireExitViewModel } from "./FireExitViewModel";
import { FieldType, ViewModelBase } from "@shoothill/core";
import { uniqueId } from "lodash-es";
import { AuditQuestionModel } from "../EndOfDay/AuditQuestionModel";
import { FireExitModel } from "./FireExitModel";
import { Uploader } from "../../../../../Components/Uploader/Uploader";
import { SectionSplitter } from "../../../../../Components/Form/SectionSplitter";
import { DefaultButton, PrimaryButton } from "../../../../../Components/Buttons/Buttons";
import { FireExitView } from "./FireExitView";
import { FireExtinguishersView } from "./FireExtinguishersView";
import { FirePointView } from "./FirePointView";
import { SpillKitView } from "./SpillKitView";
import { FirstAidKitView } from "./FirstAidKitView";
import { ProjectButtonContainer } from "../../../Project.styles";
import { Signature } from "../../../../../Components/Signature/Signature";
import { isEmpty } from "../../../../../Utils/Utils";

interface Props {
    id: string | null;
    projectId: string | null;
    handleClose: () => void;
}
export const EndOfWeekView: React.FC<Props> = observer((props: Props) => {
    const [viewModel] = useState(() => new EndOfWeekViewModel());
    useEffect(() => {
        viewModel.setValue("id", props.id);
        viewModel.setValue("projectId", props.projectId);
        if (props.id) {
            viewModel.loadAudit();
        } else {
            viewModel.loadNewAudit();
        }
    }, []);

    const handleSave = () => {
        viewModel.submit(props.handleClose);
    };

    const handleCancel = () => {
        viewModel.cancel(props.handleClose);
    };

    return (
        <>
            <SHBox p={3}>
                <Typography variant={"h1"}>Weekly Audit</Typography>
                <SHBox flexBox mt={1}>
                    <Typography variant={"h5"}>Date/time added {moment(new Date()).format("DD/MM/YYYY")}</Typography>
                    {/*<Typography variant={"h5"}>Project reference: {viewModel.model.projectId}</Typography>
                    <Typography variant={"h5"}>Project name: {viewModel.model.projectId}</Typography>*/}
                </SHBox>
                <SHBox mt={3} mb={2} width={"300px"}>
                    <DarwinSelect
                        displayName="Auditor"
                        execute={viewModel.setAuditUser}
                        fullWidth={true}
                        getOptionLabel={(option: any) => option.displayName}
                        options={viewModel.users}
                        placeholder="Please select user"
                        value={viewModel.model.auditUser}
                        canExecute={viewModel.editable}
                    />
                </SHBox>
                <SectionSplitter />
                <Typography variant={"h2"}>Fire and Emergency Equipment</Typography>
                {viewModel.fireExitViewModels.map((fireExitViewModel, index) => {
                    return <FireExitView key={uniqueId()} index={index} fireExitViewModel={fireExitViewModel} endOfWeekViewModel={viewModel} />;
                })}
                {viewModel.editable && <PrimaryButton execute={() => viewModel.addAnotherFireExit()} displayName={"Add another fire exit"} canExecute={!viewModel.server.IsBusy} />}

                <SectionSplitter />

                <Typography variant={"h2"}>Fire Extinguishers</Typography>
                {viewModel.fireExtinguisherViewModels.map((fireExtinguisherViewModel, index) => {
                    return <FireExtinguishersView key={uniqueId()} index={index} fireExtinguisherViewModel={fireExtinguisherViewModel} endOfWeekViewModel={viewModel} />;
                })}
                {viewModel.editable && (
                    <PrimaryButton execute={() => viewModel.addAnotherFireExtinguisher()} displayName={"Add another fire extinguisher"} canExecute={!viewModel.server.IsBusy} />
                )}

                <SectionSplitter />

                <Typography variant={"h2"}>Fire Point</Typography>
                {viewModel.firePointViewModels.map((firePointViewModel, index) => {
                    return <FirePointView key={uniqueId()} index={index} firePointViewModel={firePointViewModel} endOfWeekViewModel={viewModel} />;
                })}
                {viewModel.editable && (
                    <PrimaryButton execute={() => viewModel.addAnotherFirePoint()} displayName={"Add another fire point"} canExecute={!viewModel.server.IsBusy} />
                )}

                <SectionSplitter />

                <Typography variant={"h2"}>Spill Kit</Typography>
                {viewModel.spillKitViewModel.map((spillKitViewModel, index) => {
                    return <SpillKitView key={uniqueId()} index={index} spillKitViewModel={spillKitViewModel} endOfWeekViewModel={viewModel} />;
                })}
                {viewModel.editable && <PrimaryButton execute={() => viewModel.addAnotherSpillKit()} displayName={"Add another spill kit"} canExecute={!viewModel.server.IsBusy} />}

                <SectionSplitter />

                <Typography variant={"h2"}>First Aid Kit</Typography>
                <Typography variant={"h6"}>
                    The quantities in the first aid kit should reflect the num,ber of individuals on site. Any items used should be replaced as soon as possible.
                </Typography>
                <SHBox mt={3}>
                    <Typography variant={"h4"}>First Aid Kit Guidance</Typography>
                    <SHBox grid dc={"200px 200px"} mt={3} mb={3} style={{ gap: "10px" }}>
                        <Typography variant={"h5"}>Less than 5 individuals</Typography>
                        <Typography variant={"h5"}>Small</Typography>
                        <Typography variant={"h5"}>5-25 individuals</Typography>
                        <Typography variant={"h5"}>Medium</Typography>
                        <Typography variant={"h5"}>More than 25 individuals</Typography>
                        <Typography variant={"h5"}>Large (1 per 25 individuals)</Typography>
                    </SHBox>
                </SHBox>
                {viewModel.firstAidKitViewModels.map((firstAidKitViewModel, index) => {
                    return <FirstAidKitView key={uniqueId()} index={index} firstAidKitViewModel={firstAidKitViewModel} endOfWeekViewModel={viewModel} />;
                })}
                {viewModel.editable && (
                    <PrimaryButton execute={() => viewModel.addAnotherSpillKit()} displayName={"Add another first aid kit"} canExecute={!viewModel.server.IsBusy} />
                )}
                <SectionSplitter />
            </SHBox>
            <SHBox pl={3} pb={"50px"}>
                <Typography variant={"h3"}>Authorisation</Typography>
                <Typography variant={"h5"}>I can confirm I have carried out this audit to the best of my ability and the information is true</Typography>
                <SHBox mt={3} mb={3} grid dc={"20% 20%"}>
                    <Typography variant={"h5"}>Auditor: {viewModel.model.auditUser.displayName}</Typography>
                    <Typography variant={"h5"}>Date / time added: {moment(viewModel.model.createdDate).format("DD/MM/YYYY @ HH:mm")}</Typography>
                </SHBox>
                <SHBox grid dc={"600px"} pt={2}>
                    <Signature
                        displayName={"Auditor signature:"}
                        execute={viewModel.updateSignature}
                        value={viewModel.model.signatureBase64}
                        canExecute={isEmpty(viewModel.model.signatureBase64)}
                    />
                </SHBox>
            </SHBox>
            <SHBox showIf={viewModel.showValidationError} style={{ textAlign: "center" }} pb={3}>
                <Typography variant={"h3"} color={"error"}>
                    Please fill in all details in red above
                </Typography>
            </SHBox>
            <SHBox showIf={viewModel.editable}>
                <ProjectButtonContainer>
                    {viewModel.canSubmit() && <PrimaryButton execute={handleSave} displayName={"Submit"} disabled={viewModel.server.IsBusy} />}
                    <DefaultButton execute={handleCancel} displayName={"Cancel"} canExecute={!viewModel.server.IsBusy} />
                </ProjectButtonContainer>
            </SHBox>
        </>
    );
});

interface RadioAuditProps {
    value: () => string;
    validationMessage: () => string;
    onChange: (value: string) => void;
    title: string;
    canExecute: boolean;
}
export function RadioAuditView(props: RadioAuditProps) {
    return useObserver(() => (
        <>
            <Typography variant={"h5"}>{props.title}</Typography>
            <SHBox>
                <FormControl component="fieldset">
                    <RadioGroup row name={"radio-" + "" + "-group"} value={props.value()} onChange={(e: any) => props.onChange(e.target.value)} disabled={!props.canExecute}>
                        <FormControlLabel value="yes" control={<Radio color={"secondary"} />} label="Yes" />
                        <FormControlLabel value="no" control={<Radio color={"secondary"} />} label="No" />
                    </RadioGroup>
                    <Typography variant={"h5"} color={"error"}>
                        {props.validationMessage()}
                    </Typography>
                </FormControl>
            </SHBox>
        </>
    ));
}
