import React, { useContext } from "react";
import { Stores, StoresContext } from "Globals/Stores";
import { PrivateRoute } from "Globals/Views/PrivateRoute";
import * as RouterUtil from "Utils/Routing";
import { StoresInstance } from "Globals/Stores";
import { AppUrls } from "../../AppUrls";
import { DarwinLayout } from "Globals/Views/Layouts/DarwinLayout";
import { InvoiceViewContainer } from "./Form/InvoiceViewContainer";
import { InvoicingListView } from "Views/Invoices/InvoicingListView";

export const InvoiceRoutes: React.FC = () => {
    function getRootLayout() {
        return DarwinLayout;
    }
    const store = useContext<Stores>(StoresContext);
    return (
        <>
            <PrivateRoute
                exact
                path={AppUrls.Client.Invoicing.List}
                component={InvoicingListView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireNotSiteManagerLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Invoicing.Add}
                component={InvoiceViewContainer}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireNotSiteManagerLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Invoicing.AddIE}
                component={InvoiceViewContainer}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireNotSiteManagerLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Invoicing.Edit}
                component={InvoiceViewContainer}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireNotSiteManagerLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Invoicing.EditIE}
                component={InvoiceViewContainer}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireNotSiteManagerLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
        </>
    );
};
