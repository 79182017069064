import { action, observable, runInAction } from "mobx";
import { FieldType, ViewModelBase } from "@shoothill/core";

import { AppUrls } from "AppUrls";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { ScaffoldingListItemModel, ScaffoldInspectionListDTO } from "./ScaffoldingListItemModel";

export class ScaffoldingListViewModel extends ViewModelBase<any> {
    public server: ServerViewModel = new ServerViewModel();

    @observable
    public projectId: string = "";

    @observable
    public scaffoldingListModels = observable<ScaffoldingListItemModel>([]);

    constructor() {
        super();
    }

    public GetList = async (projectId: string, date: Date): Promise<void> => {
        return this.server.query<ScaffoldInspectionListDTO>(
            () => this.Get(`${AppUrls.Server.Projects.Construction.ScaffoldInspection.GetProjectScaffoldList}?projectId=${projectId}&date=${date.toISOString()}`),
            (result) => {
                runInAction(() => {
                    this.projectId = projectId;

                    const inspections: ScaffoldingListItemModel[] = [];

                    for (const inspection of result.scaffoldInspectionList) {
                        const model = new ScaffoldingListItemModel();

                        model.fromDto(inspection);

                        inspections.push(model);
                    }

                    this.scaffoldingListModels.replace(inspections);
                });
            },
        );
    };

    @action
    public navigateToScaffoldInspection = (model: ScaffoldingListItemModel) => {
        this.history.push(AppUrls.Client.Project.Scaffolding.View.replace(":projectid", this.projectId).replace(":scaffoldingId", model.id));
    };

    @action
    public navigateToScaffoldAddInspectionForm = () => {
        this.history.push(AppUrls.Client.Project.Scaffolding.Add.replace(":projectid", this.projectId));
    };

    // #region Bolierplate

    public async isFieldValid(fieldName: keyof FieldType<ScaffoldingListItemModel>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Bolierplate
}
