import { FieldType, ViewModelBase } from "@shoothill/core";
import { DesignDataStatusModel, DesignDataStatusRequestDTO } from "./DesignDataStatusModel";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";

export class DesignDataStatusViewModel extends ViewModelBase<DesignDataStatusModel> {
    public constructor(designDataId: string | null) {
        super(new DesignDataStatusModel(), false);
        this.setDecorators(DesignDataStatusModel);
        this.model.designDataId = designDataId!;
    }

    public server: ServerViewModel = new ServerViewModel();

    public async isFieldValid(fieldName: keyof FieldType<DesignDataStatusModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        errorMessage = "";
        isValid = true;

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
