import { FieldType, ViewModelBase } from "@shoothill/core";
import { action, observable, computed } from "mobx";
import { PackagesViewModel } from "Views/Project/Commercial/PackagesViewModel";
import { VariationCategoryModel } from "./VariationCategoryModel";
import { VariationSubCategoryViewModel } from "./VariationSubCategoryViewModel";

export class VariationCategoryViewModel extends ViewModelBase<VariationCategoryModel> {
    // #region Constructors and Disposers
    constructor(variationCategory: VariationCategoryModel) {
        super(variationCategory);
        this.setDecorators(VariationCategoryViewModel);

        this.createViewModels();
    }

    private packagesViewModel: PackagesViewModel = PackagesViewModel.Instance;

    // #region Properties

    @observable
    public variationSubCategoryViewModels: VariationSubCategoryViewModel[] = [];

    // #endregion Properties

    @computed
    public get displayName(): string | null {
        return this.model.displayName;
    }

    @computed
    public get isDefault(): boolean {
        const category = this.packagesViewModel.getCategory(this.model.categoryId!);

        return category ? category.isVariationDefault : false;
    }

    @computed
    public get futureSpendSum(): number {
        // Sum of expected costs.

        return this.variationSubCategoryViewModels.map((item) => item.futureSpendSum).reduce((prev, next) => parseFloat((prev + next).toFixed(2)), 0);
    }

    @computed
    public get lineTotalSum(): number {
        // Sum of line totals.

        return this.variationSubCategoryViewModels.map((item) => item.lineTotalSum).reduce((prev, next) => parseFloat((prev + next).toFixed(2)), 0);
    }

    @action
    private createViewModels() {
        for (const item of this.model.variationSubCategories) {
            this.variationSubCategoryViewModels.push(new VariationSubCategoryViewModel(item));
        }
    }

    @action
    public reset = () => {
        this.model.reset();
    };

    /**
     * Custom model validation function. Validates child subcategory models and its children
     * @returns True if model is valid, false if not.
     */
    public isMyModelValid = async (): Promise<boolean> => {
        let isValid = true;

        // JC: Changed forEach into for loop as the await seems to have issues with forEach.
        for (let i = 0; i < this.variationSubCategoryViewModels.length; i++) {
            let subCategory = this.variationSubCategoryViewModels[i];

            // Validate each child subcategory.
            if ((await subCategory.isMyModelValid()) === false) {
                isValid = false;
            }
        }

        // The category has no fields of its own that require validation.

        return isValid;
    };

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<VariationCategoryModel>): Promise<boolean> {
        return true;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
