// Libraries
import React, { useState } from "react";
import { useObserver } from "mobx-react-lite";

// Custom
import { IEViewModel } from "../IE.ViewModel";
import { PackagesViewModel } from "../PackagesViewModel";
import { formatCurrencyNoSign } from "Utils/Format";
import { IEGridCategoryModel, IEGridModel } from "../IEmodels";

// Styling & Images
import { MasterSummaryGridView } from "./IEGrid.Styles";
import { formatCreditDebitClass, formatCreditDebitReverseClass } from "Utils/Utils";

interface ITableProps {
    IEid: string;
    viewModel: IEViewModel;
    packagesViewModel: PackagesViewModel;
    chosenId(saved: string): void;
}

export const IEGridTotals: React.FunctionComponent<ITableProps> = (props) => {
    const [ieCategory, setIECategory] = useState<IEGridCategoryModel[]>([]);
    const [ieCurrent, setIECurrent] = useState<IEGridModel>(new IEGridModel());

    const formatDebit = (val: number) => {
        return val < 0 ? " debit" : "";
    };

    return useObserver(() => (
        <>
            {ieCategory.length > 0 && (
                <>
                    <MasterSummaryGridView>
                        <li className="footing">
                            <div className="right">Master total</div>
                            <div className="right">{formatCurrencyNoSign(ieCurrent.income)}</div>
                            <div className="right">{formatCurrencyNoSign(ieCurrent.estimateCost)}</div>
                            <div className="right">{formatCurrencyNoSign(ieCurrent.margin)}</div>
                            <div className={"right" + formatDebit(ieCurrent.committedCost)}>{formatCurrencyNoSign(ieCurrent.committedCost)}</div>
                            <div className="right">{formatCurrencyNoSign(ieCurrent.futureSpend)}</div>
                            <div className="right">{formatCurrencyNoSign(ieCurrent.totalExpectedSpend)}</div>
                            <div className={"right" + formatCreditDebitClass(ieCurrent.variance)}>{formatCurrencyNoSign(ieCurrent.variance)}</div>
                            <div className={"right" + formatCreditDebitReverseClass(ieCurrent.roValue)}>{formatCurrencyNoSign(ieCurrent.roValue)}</div>
                            <div className="right"></div>
                        </li>
                    </MasterSummaryGridView>
                </>
            )}
        </>
    ));
};
