import { FieldType, ViewModelBase, isEmptyOrWhitespace } from "@shoothill/core";
import { runInAction, observable, action } from "mobx";
import { DesignDataItemModel, DesignDataItemRequestDTO } from "./DesignDataItemModel";
import { DesignDataFormItemModel } from "../../Forms/DesignDataFormItemModel";
import { AppUrls } from "AppUrls";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";

export class DesignDataItemViewModel extends ViewModelBase<DesignDataItemModel> {
    public constructor(designDataId: string | null) {
        super(new DesignDataItemModel(), false);
        this.setDecorators(DesignDataItemModel);
        this.model.designDataId = designDataId!;
        if (!isEmptyOrWhitespace(this.model.designDataId)) {
            this.loadDesignDataItems();
        }
    }

    public server: ServerViewModel = new ServerViewModel();

    @observable
    public items = observable<DesignDataFormItemModel>([]);

    @action
    public loadDesignDataItems = async (): Promise<void> => {
        const request: DesignDataItemRequestDTO = {
            id: this.model.designDataId,
        };
        let apiResult = await this.Post<any>(AppUrls.Server.Projects.ProjectTrackers.DesignData.GetDesignDataItemsById, request);
        if (apiResult) {
            if (apiResult.wasSuccessful) {
                if (apiResult.wasSuccessful) {
                    runInAction(() => {
                        this.items.replace(apiResult.payload);
                    });
                } else {
                    console.log(apiResult.errors);
                }
            }
        }
    };

    public async isFieldValid(fieldName: keyof FieldType<DesignDataItemModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        errorMessage = "";
        isValid = true;

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
