import { ModelBase } from "@shoothill/core";
import { action, observable, computed } from "mobx";

export class SafetyFormModel extends ModelBase<SafetyFormModel> {
    // #region Constants

    public static readonly DEFAULT_SAFETY_ID = null;
    public static readonly DEFAULT_PROJECT_ID = "";
    public static readonly DEFAULT_NON_CONFORMANCE_TYPE_ID = "";

    // Concerning safety
    public static readonly DEFAULT_SUPPLIER_ID = null;
    public static readonly DEFAULT_ISSUEDTO_USER_ID = null;
    public static readonly DEFAULT_LOCATION = "";
    public static readonly DEFAULT_DESCRIPTION = "";
    public static readonly DEFAULT_SAFETY_PHOTO_FILES = [];

    // Concerning rectification
    public static readonly DEFAULT_RECTIFICATION_REQUIRED = false;
    public static readonly DEFAULT_RECTIFICATION_DATE = null;

    // Concerning open/closed state
    public static readonly DEFAULT_COMPLETION_ISOPEN = true;
    public static readonly DEFAULT_COMPLETION_DESCRIPTION = "";
    public static readonly DEFAULT_COMPLETION_PHOTOS = [];

    // Concerning audit
    public static readonly DEFAULT_CREATED_DATE = null;
    public static readonly DEFAULT_COMPLETED_BY_USED_ID = "";

    // #endregion Constants

    // #region Related Properties

    @observable
    public suppliers = observable<SupplierDTO>([]);

    @observable
    public inductionUsers = observable<InductionUserDTO>([]);

    @observable
    public workerUsers = observable<InductionUserDTO>([]);

    @computed
    public get users() {
        const groupInuductionUsers = this.inductionUsers.map((u) => {
            return {
                groupType: "INDUCTION_USER",
                groupDisplayName: "Inducted",
                ...u,
            };
        });

        const groupWorkerUsers = this.workerUsers.map((u) => {
            return {
                groupType: "WORKER_USER",
                groupDisplayName: "Darwin staff",
                ...u,
            };
        });

        return [...groupInuductionUsers, ...groupWorkerUsers];
    }

    // #endregion Related Properties

    // #region Form Properties

    @observable
    public id: string | null = SafetyFormModel.DEFAULT_SAFETY_ID;

    @observable
    public projectId: string = SafetyFormModel.DEFAULT_PROJECT_ID;

    @observable
    public nonConformanceTypeId: string = SafetyFormModel.DEFAULT_NON_CONFORMANCE_TYPE_ID;

    // Concerning safety
    @observable
    public supplierId: string | null = SafetyFormModel.DEFAULT_SUPPLIER_ID;

    @observable
    public userId: string | null = SafetyFormModel.DEFAULT_ISSUEDTO_USER_ID;

    public get inductionUserId() {
        const result = this.users.find((u) => u.id === this.userId);

        return result?.groupType === "INDUCTION_USER" ? result!.id : SafetyFormModel.DEFAULT_ISSUEDTO_USER_ID;
    }

    public get workerUserId() {
        const result = this.users.find((u) => u.id === this.userId);

        return result?.groupType === "WORKER_USER" ? result!.id : SafetyFormModel.DEFAULT_ISSUEDTO_USER_ID;
    }

    @observable
    public location: string = SafetyFormModel.DEFAULT_LOCATION;

    @observable
    public description: string = SafetyFormModel.DEFAULT_DESCRIPTION;

    @observable
    public safetyPhotos: SafetyPhotoFile[] = SafetyFormModel.DEFAULT_SAFETY_PHOTO_FILES;

    // Concerning rectification
    @observable
    public rectificationRequired: boolean = SafetyFormModel.DEFAULT_RECTIFICATION_REQUIRED;

    @observable
    public rectificationDate: string | null = SafetyFormModel.DEFAULT_RECTIFICATION_DATE;

    // Concerning open/closed state
    @observable
    public isOpen: boolean = SafetyFormModel.DEFAULT_COMPLETION_ISOPEN;

    @observable
    public rectificationDescription: string = SafetyFormModel.DEFAULT_COMPLETION_DESCRIPTION;

    // Concerning audit
    @observable
    public createdDate: string | null = SafetyFormModel.DEFAULT_CREATED_DATE;

    @observable
    public completedByUserId: string | null = SafetyFormModel.DEFAULT_COMPLETED_BY_USED_ID;

    @observable
    public safetyRectificationPhotos: SafetyRectificationPhotoFile[] = SafetyFormModel.DEFAULT_COMPLETION_PHOTOS;

    // #endregion Form Properties

    // #region Validation

    @computed
    public get validateUserId(): string {
        return this.userId === SafetyFormModel.DEFAULT_ISSUEDTO_USER_ID ? "Please Select User" : "";
    }

    @computed
    public get validateLocation(): string {
        return this.location === SafetyFormModel.DEFAULT_LOCATION ? "Please enter exact location of non-conformance" : "";
    }

    @computed
    public get validateDescription(): string {
        return this.description === SafetyFormModel.DEFAULT_DESCRIPTION ? "Please enter a description" : "";
    }

    @computed
    public get validateSafetyPhoto(): string {
        return this.safetyPhotos === SafetyFormModel.DEFAULT_SAFETY_PHOTO_FILES ? "Please Upload Photo" : "";
    }

    // #endregion Validation

    @action
    public fromRelatedDto(dto: SafetyRelatedResponseDTO): void {}

    @action
    public reset = () => {
        this.id = SafetyFormModel.DEFAULT_SAFETY_ID;
        this.projectId = SafetyFormModel.DEFAULT_PROJECT_ID;
        this.nonConformanceTypeId = SafetyFormModel.DEFAULT_NON_CONFORMANCE_TYPE_ID;

        // Concerning safety
        this.supplierId = SafetyFormModel.DEFAULT_SUPPLIER_ID;
        this.userId = SafetyFormModel.DEFAULT_ISSUEDTO_USER_ID;
        this.location = SafetyFormModel.DEFAULT_LOCATION;
        this.description = SafetyFormModel.DEFAULT_DESCRIPTION;
        this.safetyPhotos = SafetyFormModel.DEFAULT_SAFETY_PHOTO_FILES;

        // Concerning rectification
        this.rectificationRequired = SafetyFormModel.DEFAULT_RECTIFICATION_REQUIRED;
        this.rectificationDate = SafetyFormModel.DEFAULT_RECTIFICATION_DATE;

        // Concerning open/closed state
        this.isOpen = SafetyFormModel.DEFAULT_COMPLETION_ISOPEN;
        this.rectificationDescription = SafetyFormModel.DEFAULT_COMPLETION_DESCRIPTION;

        // Concerning audit
        this.createdDate = SafetyFormModel.DEFAULT_CREATED_DATE;

        // APM Review - Not sure about this.
        this.safetyRectificationPhotos = SafetyFormModel.DEFAULT_COMPLETION_PHOTOS;
    };

    public fromDto(dto: any): void {
        //throw new Error("Method not implemented.");
    }

    public toDto(): safetyDTO {
        return {
            id: this.id,
            projectId: this.projectId,
            nonConformanceTypeId: this.nonConformanceTypeId,

            supplierId: this.supplierId,
            issuedToInductionUserId: this.inductionUserId,
            workerUserId: this.workerUserId,
            location: this.location,
            description: this.description,

            rectificationRequired: this.rectificationRequired,
            rectificationDate: this.rectificationDate,

            isOpen: this.isOpen,
            rectificationDescription: this.rectificationDescription,

            isDeleted: false,
            rowVersion: null,
        };
    }
}

export interface SafetyRelatedResponseDTO {
    suppliers: SupplierDTO[];
    issuedToInductionUsers: InductionUserDTO[];
    workerUsers: InductionUserDTO[];
    safetyProjectDetails: SafetyProjectDetailsDTO;
}

export interface SafetyRelatedByIdResponseDTO {
    suppliers: SupplierDTO[];
    issuedToInductionUsers: InductionUserDTO[];
    workerUsers: InductionUserDTO[];
    safetyProjectDetails: SafetyProjectDetailsDTO;
    safetyPhotoFile: SafetyPhotoFile;
}

export interface SafetyProjectDetailsDTO {
    projectReference: string;
    projectName: string;
}

export interface SupplierDTO {
    id: string;
    displayName: string;
}

export interface InductionUserDTO {
    id: string;
    displayName: string;
}

export interface IDisplayFile {
    id: string;
    displayName: string;
}

export interface SafetyAndRelatedResponseDTO {
    safety: safetyDTO;
    safetyPhotos: SafetyPhotosDTO[];
}

export interface SafetyPhotoFile {
    safetyId: string | null;
    fileName: string;
    photoUrl: string;
    createdByUserId: string;
    isDeleted: boolean;
}

export interface SafetyRectificationPhotoFile {
    safetyId: string | null;
    fileName: string;
    photoUrl: string;
    createdByUserId: string;
    isDeleted: boolean;
}

export interface safetyDTO {
    id: string | null;
    projectId: string;
    nonConformanceTypeId: string;

    supplierId: string | null;
    issuedToInductionUserId: string | null;
    workerUserId: string | null;
    location: string;
    description: string;

    rectificationRequired: boolean;
    rectificationDate: string | null;

    isOpen: boolean;
    rectificationDescription: string;

    isDeleted: boolean;
    rowVersion: string | null;
}

export interface SafetyPhotosDTO {
    safetyId: string | null;
    fileName: string;
    photoUrl: string;
    createdByUserId: number | null;
    isDeleted: boolean;
}

export interface UpsertSafetyAndRelatedRequestDTO {
    safety: safetyDTO;
    safetyPhotos: SafetyPhotosDTO[];
    safetyRectificationPhotos: SafetyPhotosDTO[];
}
