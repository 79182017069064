import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import { AdminSnackBarStyle } from "./SnackBar.Styles";

interface IAdminSnackBar {
    messageText: string;
    messageType?: string;
    active: boolean;
    closeOption: (closeOption: Boolean) => void;
    autoHideDuration: number;
}

/**
 * AdminSnackBar
 * @param props messageText, messageType? (error, warning, info, success), active (true | false)
 */
export const SnackBar: React.FC<IAdminSnackBar> = (props) => {
    const handleClose = () => {
        props.closeOption(false);
    };

    const handleType = (snackType: string | undefined) => {
        switch (snackType) {
            case "error":
                return "#f44336";
                break;

            case "warning":
                return "#ff9800";
                break;

            case "success":
                return "#4caf50";
                break;

            default:
                return "#2196f3";
                break;
        }
    };

    return (
        <div>
            <AdminSnackBarStyle
                open={props.active}
                onClose={handleClose}
                message={props.messageText}
                key={"snack"}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                autoHideDuration={props.autoHideDuration}
                backgroundcolor={handleType(props.messageType)}
            />
        </div>
    );
};
