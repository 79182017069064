import { action, computed, observable, runInAction } from "mobx";
import { FieldType, ViewModelBase, isEmptyOrWhitespace } from "@shoothill/core";

import { AppUrls } from "AppUrls";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { viewModelModelLink } from "Utils/Utils";
import { ScaffoldInspectionAuditUserDTO, ScaffoldInspectionModel } from "./ScaffoldInspectionModel";
import { ScaffoldInspectionDetailModel } from "./InspectionDetail/ScaffoldInspectionDetailModel";
import { ScaffoldInspectionDetailViewModel } from "./InspectionDetail/ScaffoldInspectionDetailViewModel";
import { Stores, StoresInstance } from "Globals/Stores";

export class ScaffoldInspectionViewModel extends ViewModelBase<ScaffoldInspectionModel> {
    public server: ServerViewModel = new ServerViewModel();
    public scaffoldInspectionDetailsViewModel = observable<ScaffoldInspectionDetailViewModel>([]);

    @observable
    public showValidationError: boolean = false;

    @observable
    public editable: boolean = false;

    @observable
    public projectReference: string = "";

    @observable
    public projectName: string = "";

    @observable
    public scaffoldInspectionAuditUsers = observable<ScaffoldInspectionAuditUserDTO>([]);

    @computed
    public get isFormDisabled(): boolean {
        return this.model.hasId;
    }

    @computed
    public get navigationDisplayName() {
        const isSiteTablet: boolean = StoresInstance.Domain.AccountStore.isSiteTablet;

        let displayName = "Back to scaffold inspections";

        if (isSiteTablet) {
            displayName = "Back to Site Menu";
        } else {
            if (!isEmptyOrWhitespace(this.projectReference)) {
                displayName = `${displayName} (${this.projectReference} - ${this.projectName})`;
            }
        }

        return displayName;
    }

    @computed
    public get displayName() {
        return this.model.hasId ? "Scaffold inspection" : "New scaffold inspection";
    }

    constructor(projectId: string, scaffoldInspectionId: string) {
        super(new ScaffoldInspectionModel());

        this.model.projectId = projectId;
        this.model.id = scaffoldInspectionId;

        this.setDecorators(ScaffoldInspectionModel);

        if (isEmptyOrWhitespace(this.model.id)) {
            this.loadNewInspection();
        } else {
            this.loadInspection();
        }

        (window as any).ScaffoldInspectionViewModel = this;
    }

    public updateSignature = (data: any) => {
        this.setValue("signatureBase64", data);
    };

    public getUserName(id: string | null): string {
        return this.scaffoldInspectionAuditUsers.find((i: any) => i.id === id)?.displayName ?? "-";
    }

    public scaffoldInspectionDetailsLinkDispose = viewModelModelLink(
        this.model.scaffoldInspectionDetailsModel,
        this.scaffoldInspectionDetailsViewModel,
        ScaffoldInspectionDetailViewModel,
    );

    @action
    public addNewScaffold = () => {
        this.model.scaffoldInspectionDetailsModel.push(new ScaffoldInspectionDetailModel());
    };

    @action
    public navigateToScaffoldInspections = () => {
        const isSiteTablet: boolean = StoresInstance.Domain.AccountStore.isSiteTablet;

        if (isSiteTablet) {
            this.history.push(AppUrls.Client.Project.SiteTablet.Menu);
        } else {
            this.history.push(AppUrls.Client.Project.ConstructionHAndS.replace(":projectid", this.model.projectId!) + "#scaffolding");
        }
    };

    // #region Api Actions

    public loadNewInspection = (): Promise<void> => {
        return this.server.query<any>(
            () => this.Get(`${AppUrls.Server.Projects.Construction.ScaffoldInspection.GetProjectScaffoldRelated}?ProjectId=${this.model.projectId}`),
            (result) => {
                runInAction(() => {
                    // Model properties.
                    this.model.scaffoldInspectionDetailsModel.clear();
                    this.model.scaffoldInspectionDetailsModel.push(new ScaffoldInspectionDetailModel());

                    // ViewModel properties.
                    this.editable = true;
                    this.projectName = result.scaffoldInspectionProjectDetails.projectName;
                    this.projectReference = result.scaffoldInspectionProjectDetails.projectReference;
                });
            },
        );
    };

    public loadInspection = (): Promise<void> => {
        return this.server.query<any>(
            () => this.Get(`${AppUrls.Server.Projects.Construction.ScaffoldInspection.GetProjectScaffoldWithRelated}?Id=${this.model.id}`),
            (result) => {
                runInAction(() => {
                    // Model properties.
                    const scaffoldInspectionDetails: ScaffoldInspectionDetailModel[] = [];

                    for (const inspection of result.scaffoldInspectionDetails) {
                        const model = new ScaffoldInspectionDetailModel();

                        model.fromDto(inspection);

                        scaffoldInspectionDetails.push(model);
                    }

                    this.model.scaffoldInspectionDetailsModel.replace(scaffoldInspectionDetails);

                    this.model.fromDto(result);

                    // ViewModel properties.
                    this.scaffoldInspectionAuditUsers.replace(result.scaffoldInspectionAuditUsers);
                    this.editable = false;
                    this.projectName = result.scaffoldInspectionProjectDetails.projectName;
                    this.projectReference = result.scaffoldInspectionProjectDetails.projectReference;
                });
            },
        );
    };

    @action
    public submit = async () => {
        let isValid = await this.isModelValid();

        for (const scaffoldInspectionDetail of this.scaffoldInspectionDetailsViewModel) {
            const valid = await scaffoldInspectionDetail.isModelValid();

            if (!valid) {
                isValid = false;
            }
        }

        return this.server.command(
            () => this.Post(`${AppUrls.Server.Projects.Construction.ScaffoldInspection.UpsertProjectScaffoldInspection}`, this.model.toDto()),
            (result) => {
                runInAction(() => {
                    this.navigateToScaffoldInspections();
                });
            },
            () => Promise.resolve(isValid),
            "There was an error trying to add the inspection",
        );
    };

    // #endregion Api Actions

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<ScaffoldInspectionModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        if (!isValid) {
            console.log(fieldName, errorMessage);
        }

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
