import { ModelBase } from "@shoothill/core";
import { ApprovalSubType } from "Globals/Models";
import { action, computed, observable } from "mobx";
import { IEAdministratorRolesModel } from "./IEAdministratorRolesModel";

export class IEAdministrationRoleUserModel extends ModelBase<IEAdministrationRoleUserModel, IEAdministrationRoleUserResponseDTO> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Defaults and Constants

    public static readonly DEFAULT_ID = null;
    public static readonly DEFAULT_ROWVERSION = null;

    public static readonly DEFAULT_USERID = "";
    public static readonly DEFAULT_USERNAME = "";

    public static readonly DEFAULT_ROLEID = "";
    public static readonly DEFAULT_ROLENAME = "";

    public static readonly DEFAULT_ISDELETED = false;

    // #endregion Defaults and Constants

    // #region Observables

    @observable
    public id: string | null = IEAdministrationRoleUserModel.DEFAULT_ID;

    @observable
    public rowVersion: string | null = IEAdministrationRoleUserModel.DEFAULT_ROWVERSION;

    @observable
    public userId: string | null = IEAdministrationRoleUserModel.DEFAULT_USERID;

    @observable
    public userName: string | null = IEAdministrationRoleUserModel.DEFAULT_USERNAME;

    @observable
    public roleId: string | null = IEAdministrationRoleUserModel.DEFAULT_ROLEID;

    @observable
    public roleName: string | null = IEAdministrationRoleUserModel.DEFAULT_ROLENAME;

    @observable
    public isDeleted: boolean = IEAdministrationRoleUserModel.DEFAULT_ISDELETED;

    // #endregion Observables

    // #region Actions

    @action
    public fromDto(dto: IEAdministrationRoleUserResponseDTO): void {
        this.id = dto.id;
        this.rowVersion = dto.rowVersion;
        this.userId = dto.userId;
        this.userName = dto.userName;
        this.roleId = dto.roleId;
        this.roleName = dto.roleName;
        this.isDeleted = dto.isDeleted;
    }

    @action
    public fromRequestDto(dto: IEAdministrationRoleUserRequestDTO): void {
        this.id = dto.id;
        this.rowVersion = dto.rowVersion;
        this.userId = dto.userId;
        this.userName = dto.userName;
        this.roleId = dto.roleId;
        this.roleName = dto.roleName;
        this.isDeleted = dto.isDeleted;
    }

    public toDto(): IEAdministrationRoleUserRequestDTO {
        return {
            id: this.id,
            rowVersion: this.rowVersion,
            userId: this.userId,
            userName: this.userName,
            roleId: this.roleId,
            roleName: this.roleName,
            isDeleted: this.isDeleted,
        };
    }

    // #endregion Actions

    // #region Helpers

    public static fromDtos(dtos: IEAdministrationRoleUserResponseDTO[]): IEAdministrationRoleUserModel[] {
        const types: IEAdministrationRoleUserModel[] = [];

        for (const dto of dtos) {
            const model = new IEAdministrationRoleUserModel();

            model.fromDto(dto);
            types.push(model);
        }

        return types;
    }

    public static fromIEAdministrationRoleModel(ieAdministrationRolesModel: IEAdministratorRolesModel) {
        const model = new IEAdministrationRoleUserModel();

        model.id = ieAdministrationRolesModel.id!;
        model.userId = ieAdministrationRolesModel.userId;
        model.userName = ieAdministrationRolesModel.userDisplayName!;
        model.roleId = ieAdministrationRolesModel.roleId;
        model.roleName = ieAdministrationRolesModel.roleDisplayName!;
        model.isDeleted = ieAdministrationRolesModel.isDeleted;

        //model.items.push(RequisitionRequestItemModel.fromOrderLineModel(null, ieAdministrationRolesModel));

        return model;
    }

    // #endregion Helpers

    // #region Business Logic

    // #endregion Business Logic
}

export interface IEAdministrationRoleUserRequestDTO {
    id: string | null;
    rowVersion: string | null;
    userId: string | null;
    userName: string | null;
    roleId: string | null;
    roleName: string | null;
    isDeleted: boolean;
}

export interface IEAdministrationRoleUserResponseDTO {
    id: string | null;
    rowVersion: string | null;
    userId: string | null;
    userName: string | null;
    roleId: string | null;
    roleName: string | null;
    isDeleted: boolean;
}
