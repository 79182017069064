// Libraries
import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";

// Custom

// Styling & Images
import { makeStyles } from "@material-ui/core";

interface ITableProps {}

export const ReportsTab: React.FunctionComponent<ITableProps> = (props) => {
    return useObserver(() => <>ToDo</>);
};
