import { debounce } from "@material-ui/core";
import { getHistory, FieldType, isEmptyOrWhitespace, ViewModelBase, ApiResult, isNullOrUndefined } from "@shoothill/core";
import type { ValidationResponse } from "@shoothill/core";
import { action, computed, observable, runInAction } from "mobx";

import { AppUrls } from "AppUrls";
import { OrderType } from "Globals/Models/Domain";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import {
    BudgetForecast,
    IDisplayFile,
    ProcessedRequisitionRequestInvoiceItem,
    PurchaseOrderAndRelatedResponseDTO,
    PurchaseOrderModel,
    PurchaseOrderRelatedResponseDTO,
    PurchaseOrderUpsertResponseDTO,
} from "./PurchaseOrderModel";
import type { BudgetForecastFormatted } from "./PurchaseOrderModel";
import { AddressModel } from "./Supporting/AddressModel";
import { CategoryModel } from "./Supporting/CategoryModel";
import { IncomeAndExpenditureModel } from "./Supporting/IncomeAndExpenditureModel";
import { LineDescriptionModel } from "./Supporting/LineDescriptionModel";
import { OrderTypeModel } from "./Supporting/OrderTypeModel";
import { PaymentTermsModel } from "./Supporting/PaymentTermsModel";
import { PaymentTypeModel } from "./Supporting/PaymentTypeModel";
import { ProjectModel } from "./Supporting/ProjectModel";
import { SubCategoryModel } from "./Supporting/SubCategoryModel";
import { SupplierDetailsDTO, SupplierDetailsModel } from "./Supporting/SupplierDetailsModel";
import { SupplierModel } from "./Supporting/SupplierModel";
import { TermsAndConditionsModel } from "./Supporting/TermsAndConditionsModel";
import { OrderLineViewModel } from "./Supporting/OrderLine/Form/OrderLineViewModel";
import { OrderLineModel } from "./Supporting/OrderLine/Form/OrderLineModel";
import { RequisitionRequestItemGroupViewModel } from "./Supporting/OrderLine/Tables/RequisitionRequestItemGroupViewModel";
import { RequisitionRequestItemGroupModel } from "./Supporting/OrderLine/Tables/RequisitionRequestItemGroupModel";
import { PurchaseOrderApprovalPanelViewModel } from "./PurchaseOrderApprovalPanelViewModel";
import { ApprovalSubType, RequisitionPOStatusEnum } from "Globals/Models";
import { NotificationBarViewModel } from "Components/NotificationBar/NotificationBarViewModel";
import { formatCurrencyFromPounds, formatCurrencyNoSign } from "Utils/Format";
import { UserFile } from "Globals/Models/UserFile";
import { IEGridItemViewModel } from "../../Project/Commercial/IEmodels/IEGridItemViewModel";
import { generateOrIncrementRevision } from "Utils/GenerateOrIncrementRevision";
import { StockTypeEnum, StockTypeModel } from "./Supporting/StockTypeModel";
import { PurchaseOrderApprovalStatusDTO } from "./PurchaseOrderApprovalPanelModel";
import { ChangeEvent } from "react";
import validator from "validator";
import { StoresInstance } from "Globals/Stores";
import { ApprovalDocumentDTO, ApprovalDocumentModel } from "Components/Approval/ApprovalDocumentModel";
import { csvAxiosRequestConfig, exportPDFWithGet } from "Utils/Utils";
import { POTypeModel } from "./Supporting/POTypeModel";

export class PurchaseOrderViewModel extends ViewModelBase<PurchaseOrderModel> {
    // #region Constructors and Disposers
    public filesToDisplay: UserFile[] = [];

    constructor(id: string | null) {
        super(new PurchaseOrderModel());

        this.setDecorators(PurchaseOrderViewModel);
    }

    public get hasId(): boolean {
        return this.model.id !== "" && this.model.id !== undefined && this.model.id !== null;
    }

    @observable
    public isViewOnly: boolean = false;

    @observable
    public isInReviseMode: boolean = false;

    @computed
    public get getIsViewOnly() {
        return this.isViewOnly;
    }

    @action
    public setIsViewOnly = (val: boolean) => {
        this.isViewOnly = val;
    };

    @computed
    public get getIsInReviseMode() {
        return this.isInReviseMode;
    }

    @action
    public setIsInReviseMode = (val: boolean) => {
        this.isInReviseMode = val;
        this.setIsViewOnly(!val);
        // Update the view/edit route.
        this.requisitionGroupViewModels.forEach((group) => {
            group.handleSetIsInRevisionMode(val);
        });
        this.model.supplierContactEmails = this.model.latestSupplierPOContactEmails;
    };

    @observable
    public revisionHistory = observable<{ formattedPONumber: string; revision: string }>([]);

    @action
    public deleteAllGroupsAndItems = () => {
        this.requisitionGroupViewModels.forEach((group) => {
            group.handleDeleteGroup();
        });
    };

    @computed
    public get canShowDuplicateSupplierBanner(): boolean {
        const draftStatusId: string = this.approvalPanelViewModel.getDraftStatusId;
        const submittedForApprovalStatusId: string = this.approvalPanelViewModel.getSubmittedForApprovalStatusId;
        const isStatusValid: boolean = this.model.requisitionPOStatusId === draftStatusId || this.model.requisitionPOStatusId === submittedForApprovalStatusId;
        const isDuplicateSupplier = this.model.isDuplicateSupplier;

        return isStatusValid && isDuplicateSupplier && !this.model.isCentral && !this.model.isStock;
    }

    @computed
    public get canShowReviseButton(): boolean {
        const approvedStatusId: string = this.approvalPanelViewModel.getApprovedStatusId;
        const isApproved: boolean = this.model.requisitionPOStatusId === approvedStatusId;
        const isPO: boolean = this.model.isPO;
        const path: string = this.history.location.pathname;
        const isPathValid: boolean = this.model.id !== null && AppUrls.Client.PurchaseOrder.View.replace(":poid", this.model.id) === path;
        return isApproved && isPO && isPathValid;
    }

    @computed
    public get canShowSendSupplierEmailButton(): boolean {
        if (!this.model.sendEmailToSupplier) {
            return false;
        }

        // Can only send the email from the final prepayment requisition.
        if (this.model.isPrePayment && !this.model.isFinalPrePayment) {
            return false;
        }

        const approvedStatusId: string = this.approvalPanelViewModel.getApprovedStatusId;
        const isApproved: boolean = this.model.requisitionPOStatusId === approvedStatusId;
        const isPO: boolean = this.model.isPO;
        const path: string = this.history.location.pathname;
        const isPathValid: boolean = this.model.id !== null && AppUrls.Client.PurchaseOrder.View.replace(":poid", this.model.id) === path;
        return isApproved && isPO && isPathValid;
    }

    @computed
    public get canShowUnrejectButton(): boolean {
        let isLevel5OrHigher: boolean = StoresInstance.Domain.AccountStore.isLevel5OrHigher;

        const rejectedStatusId: string = this.approvalPanelViewModel.getRejectedStatusId;
        const isRejected: boolean = this.model.requisitionPOStatusId === rejectedStatusId;
        return isRejected && isLevel5OrHigher;
    }

    @computed
    public get getPageTitle() {
        if (this.model.poNumberFormatted && this.model.poNumberFormatted !== "") {
            return this.model.poNumberFormatted;
        } else {
            return "Raise requisition";
        }
    }

    @computed
    public get getApprovalPanelSecondaryTitle() {
        if (!this.project || !this.approvalPanelViewModel.model.requesterName || this.approvalPanelViewModel.getRequestedDateFormatted === "") {
            return null;
        }

        return `${this.project?.displayName}\n${this.approvalPanelViewModel.model.requesterName} ${this.approvalPanelViewModel.getRequestedDateFormatted}`;
    }

    @computed
    public get canAddRequisition() {
        return this.model.id === "" || this.model.id === null || this.model.id === undefined;
    }

    @computed
    public get isFormDisabled(): boolean {
        if (this.isViewOnly) {
            return this.isViewOnly;
        }

        if (this.canAmendRequisition || this.getIsInReviseMode || this.canAddRequisition) {
            return false;
        }

        return true;
    }

    @computed
    private get isAmendingANonRevisionRequisition(): boolean {
        // Make these fields editable if the PO has been sent back for amends, but only if it's not a revision.
        if (!isEmptyOrWhitespace(this.model.revision)) {
            return false;
        }

        if (this.canAmendRequisition) {
            return true;
        }

        return false;
    }

    @computed
    public get isPrePaymentChild(): boolean {
        return this.model.isPrePayment && this.model.prePaymentParentRequisitionRequestId !== PurchaseOrderModel.DEFAULT_PREPAYMENTPARENTREQUISITIONREQUESTID;
    }

    @computed
    public get canExecuteAmendingField(): boolean {
        if (this.isAmendingANonRevisionRequisition) {
            return true;
        }

        return !this.isFormDisabled && !this.isExistingRequest;
    }

    @computed
    public get canExecuteDateRequiredField(): boolean {
        // If you're revising an order, then you should be able to edit the date required field
        if (this.canAmendRequisition) {
            return true;
        }

        if (this.isInReviseMode) {
            return true;
        }

        return !this.isFormDisabled && !this.isExistingRequest;
    }

    @computed
    public get canExecuteHireOrderDateField(): boolean {
        // If you're revising an order, then you should be able to edit the hire order date fields
        if (this.isInReviseMode) {
            return true;
        }

        if (this.isAmendingANonRevisionRequisition) {
            return true;
        }

        return !this.isFormDisabled && !this.isExistingRequest;
    }

    @computed
    public get canEditDeliveryAddress(): boolean {
        if (this.model.prePaymentParentRequisitionRequestId !== PurchaseOrderModel.DEFAULT_PREPAYMENTPARENTREQUISITIONREQUESTID) {
            return false; // Use the parent one and can't edit it.
        }

        return true;
    }

    @computed
    public get hasBeenRevised(): boolean {
        return this.model.revision !== "" && this.model.revision !== null && this.model.revision !== undefined;
    }

    @computed
    public get canEditIEOrProject(): boolean {
        // You are able to edit the IE or project if you are amending a requisition that has never been approved.
        // So you can't edit them when revising.

        if (this.isFormDisabled || this.isInReviseMode || this.hasBeenRevised) {
            return false;
        }

        return this.canAmendRequisition || this.isNewRequest;
    }

    @computed
    public get isExistingRequest() {
        return this.model.id !== "" && this.model.id !== null && this.model.id !== undefined;
    }

    @computed
    public get isNewRequest() {
        return this.model.id === "" || this.model.id === null || this.model.id === undefined;
    }

    @computed
    public get canEditVariationId() {
        return this.requisitionGroups.length === 0;
    }

    @computed
    public get hasTermsAndconditionsFile() {
        return this.model.termsAndConditionsFileName !== null && this.model.termsAndConditionsFileName !== undefined;
    }

    @observable
    public approvalPanelViewModel: PurchaseOrderApprovalPanelViewModel = new PurchaseOrderApprovalPanelViewModel();

    @action
    public handleGeneratePreviewPDF = async () => {
        await exportPDFWithGet(AppUrls.Server.PurchaseOrder.GeneratePOPDFPreviewById, { id: this.model.id }, await this.getConfig(true, csvAxiosRequestConfig));
    };

    @observable
    public showSendForApprovalModal: boolean = false;

    @action
    public setSendEmailToSupplier = (event: ChangeEvent<HTMLInputElement> | null, checked: boolean): void => {
        this.model.sendEmailToSupplier = checked;
    };

    @action
    public setIsSubcontractorAgreement = (val: boolean): void => {
        this.model.isSubcontractorAgreement = val;

        if (!val) {
            this.model.termsAndConditionsFileName = undefined;
            this.model.termsAndConditionsFileUrl = undefined;
        }
    };

    @observable
    public invalidItems: ProcessedRequisitionRequestInvoiceItem[] = [];

    @observable
    public showInvalidItemsModal: boolean = false;

    @action
    public handleShowInvalidItemsModalChange(show: boolean) {
        this.showInvalidItemsModal = show;
    }

    @computed
    public get getShowInvalidItemsModal() {
        return this.showInvalidItemsModal;
    }

    @computed
    public get getInvalidItemsList() {
        return this.invalidItems;
    }

    @observable
    public showMatchingUnapprovedRequisitionsModal: boolean = false;

    @observable
    public matchingUnapprovedRequisitions: { id: string; name: string; requestedByUserName: string }[] = [];

    @action
    public handleShowMatchingUnapprovedRequisitionsModalChange(show: boolean) {
        this.showMatchingUnapprovedRequisitionsModal = show;

        if (!show) {
            this.setMatchingUnapprovedRequisitions([]);
        }
    }

    @action
    public setMatchingUnapprovedRequisitions(items: { id: string; name: string; requestedByUserName: string }[]) {
        this.matchingUnapprovedRequisitions = items;
    }

    @computed
    public get getShowMatchingUnapprovedRequisitionsModal() {
        return this.showMatchingUnapprovedRequisitionsModal;
    }

    @observable
    public showInvalidDocumentsModal: boolean = false;

    @observable
    public invalidDocuments: string[] = [];

    @action
    public handleShowInvalidDocumentsModalChange(show: boolean) {
        this.showInvalidDocumentsModal = show;

        if (!show) {
            this.setInvalidDocuments([]);
        }
    }

    @action
    public setInvalidDocuments(items: string[]) {
        this.invalidDocuments = items;
    }

    @computed
    public get getShowInvalidDocumentsModal() {
        return this.showInvalidDocumentsModal;
    }

    @observable
    public showEmailSizeModal: boolean = false;

    @action
    public handleShowEmailSizeModalChange(show: boolean) {
        this.showEmailSizeModal = show;
    }

    @computed
    public get getShowEmailSizeModal() {
        return this.showEmailSizeModal;
    }

    @observable
    public showEmailMaxAttachmentsModal: boolean = false;

    @action
    public handleShowEmailMaxAttachmentsModalChange(show: boolean) {
        this.showEmailMaxAttachmentsModal = show;
    }

    @computed
    public get getShowEmailMaxAttachmentsModal() {
        return this.showEmailMaxAttachmentsModal;
    }

    @observable
    public showInvalidSubmissionDocumentsModal: boolean = false;

    @observable
    public invalidSubmissionDocuments: string[] = [];

    @action
    public handleShowInvalidSubmissionDocumentsModalChange(show: boolean) {
        this.showInvalidSubmissionDocumentsModal = show;

        if (!show) {
            this.setInvalidSubmissionDocuments([]);
        }
    }

    @action
    public setInvalidSubmissionDocuments(items: string[]) {
        this.invalidSubmissionDocuments = items;
    }

    @computed
    public get getShowInvalidSubmissionDocumentsModal() {
        return this.showInvalidSubmissionDocumentsModal;
    }

    @observable
    public showSubmissionMaxAttachmentsModal: boolean = false;

    @action
    public handleShowSubmissionMaxAttachmentsModalChange(show: boolean) {
        this.showSubmissionMaxAttachmentsModal = show;
    }

    @computed
    public get getShowSubmissionMaxAttachmentsModal() {
        return this.showSubmissionMaxAttachmentsModal;
    }

    @observable
    public showSubmissionSizeModal: boolean = false;

    @action
    public handleShowSubmissionSizeModalChange(show: boolean) {
        this.showSubmissionSizeModal = show;
    }

    @computed
    public get getShowSubmissionSizeModal() {
        return this.showSubmissionSizeModal;
    }

    @observable
    public showDuplicateSupplierModal: boolean = false;

    @action
    public handleShowDuplicateSupplierModalChange(show: boolean) {
        this.showDuplicateSupplierModal = show;
    }

    @computed
    public get getShowDuplicateSupplierModal() {
        return this.showDuplicateSupplierModal;
    }

    @action
    public setIsDuplicateSupplier(val: boolean) {
        this.model.isDuplicateSupplier = val;
        this.handleShowDuplicateSupplierModalChange(val);
    }

    @observable
    public showInvalidPrePaymentYearModal: boolean = false;

    @action
    public setShowInvalidPrePaymentYearModal(show: boolean) {
        this.showInvalidPrePaymentYearModal = show;
    }

    @computed
    public get getShowInvalidPrePaymentYearModal() {
        return this.showInvalidPrePaymentYearModal;
    }

    // #endregion Constructors and Disposers

    // #region Pre-Populate form from i&e item line

    @observable
    public fromIEItemLine = false;

    @computed
    public get getProjectIdfromIEItem() {
        const ieGridItemViewModel = IEGridItemViewModel.Instance;
        let ieAndRelatedmodel = ieGridItemViewModel.getIEAndRelatedGuids;

        return ieAndRelatedmodel.projectId;
    }

    @computed
    public get getIEIdfromIEItem() {
        const ieGridItemViewModel = IEGridItemViewModel.Instance;
        let ieAndRelatedmodel = ieGridItemViewModel.getIEAndRelatedGuids;

        return ieAndRelatedmodel.ieId;
    }

    @action
    public populateFromItem = () => {
        this.populateFromIEItem();
        this.populateFromVariationItem();
    };

    @action
    public populateFromIEItem = () => {
        // Get IE line details from viewmodel
        const ieGridItemViewModel = IEGridItemViewModel.Instance;
        let ieAndRelatedmodel = ieGridItemViewModel.getIEAndRelatedGuids;

        // Populate the Project, I & E and the category|subcategory|line description
        if (ieAndRelatedmodel.projectId.length > 1) {
            this.fromIEItemLine = true;
            this.model.projectId = ieAndRelatedmodel.projectId;
            this.model.ieId = ieAndRelatedmodel.ieId;
            this.orderLine.setValue("categoryId", ieAndRelatedmodel.ieCategoryId);
            this.orderLine.setCategoryFromIEItem(ieAndRelatedmodel.ieCategoryId, ieAndRelatedmodel.ieCategoryName);

            this.orderLine.fromIEItemLine = true;
            this.orderLine.setSubcategoryFromIEItem(ieAndRelatedmodel.ieSubcategoryId, ieAndRelatedmodel.ieSubcategoryName);
            this.orderLine.setDescriptionFromIEItem(ieAndRelatedmodel.ieItemLineId, ieAndRelatedmodel.ieItemName);
        }
    };

    @observable
    public fromVariationItemLine = false;

    @computed
    public get getProjectIdfromVariationItem() {
        const variationGridItemViewModel = IEGridItemViewModel.Instance;
        let variationAndRelatedmodel = variationGridItemViewModel.getVariationAndRelatedGuids;

        return variationAndRelatedmodel.projectId;
    }

    @computed
    public get getIEIdfromVariationItem() {
        const variationGridItemViewModel = IEGridItemViewModel.Instance;
        let variationAndRelatedmodel = variationGridItemViewModel.getVariationAndRelatedGuids;

        return variationAndRelatedmodel.ieId;
    }

    @computed
    public get getVariationIdfromVariationItem() {
        const variationGridItemViewModel = IEGridItemViewModel.Instance;
        let variationAndRelatedmodel = variationGridItemViewModel.getVariationAndRelatedGuids;

        return variationAndRelatedmodel.variationId;
    }

    @action
    public populateFromVariationItem = () => {
        // Get IE line details from viewmodel
        const variationGridItemViewModel = IEGridItemViewModel.Instance;
        let variationAndRelatedmodel = variationGridItemViewModel.getVariationAndRelatedGuids;

        // Populate the Project, I & E and the category|subcategory|line description
        if (variationAndRelatedmodel.projectId.length > 1) {
            this.fromVariationItemLine = true;
            this.model.projectId = variationAndRelatedmodel.projectId;
            this.model.ieId = variationAndRelatedmodel.ieId;
            this.orderLine.setValue("variationId", variationAndRelatedmodel.variationId);
            this.orderLine.setValue("categoryId", variationAndRelatedmodel.variationCategoryId);
            this.orderLine.setCategoryFromVariationItem(
                variationAndRelatedmodel.variationCategoryId,
                variationAndRelatedmodel.variationCategoryName,
                variationAndRelatedmodel.variationId,
            );

            this.orderLine.fromVariationItemLine = true;
            this.orderLine.setSubcategoryFromVariationItem(variationAndRelatedmodel.variationSubcategoryId, variationAndRelatedmodel.variationSubcategoryName);
            this.orderLine.setDescriptionFromVariationItem(variationAndRelatedmodel.variationItemLineId, variationAndRelatedmodel.variationItemName);
        }
    };

    @action
    public resetFromIEItem = () => {
        // Allow the form to continue as normal
        this.fromIEItemLine = false;
        this.orderLine.fromIEItemLine = false;
    };

    @action
    public resetFromVariationItem = () => {
        // Allow the form to continue as normal
        this.fromVariationItemLine = false;
        this.orderLine.fromVariationItemLine = false;
    };

    // #endregion Pre-Populate form from i&e item line

    /**
     * Stock / Standard Section
     */

    // #region Is Stock

    @computed
    public get canDisplayAsStockRequisitionForm() {
        return this.model.isStock;
    }

    @computed
    public get canDisplayAsStandardRequisitionForm() {
        return !this.model.isStock;
    }

    @observable
    public isStockOptions = observable<{ id: boolean; displayName: string }>([
        { id: true, displayName: "Yes" },
        { id: false, displayName: "No" },
    ]);

    @computed
    public get isStockOption() {
        return this.isStockOptions.find((o) => o.id === this.model.isStock);
    }

    @action
    public setIsStock = (isStock: boolean) => {
        this.model.isStock = isStock;

        // SIDE-EFFECT.
        // For stock, we are only supporting a stock type of "ToStock".
        // Since the stock type option is not on the user interface, we
        // have to set the stock type as a side-effect.
        const stockType = isStock
            ? this.stockTypes.find((st) => st.type === StockTypeEnum.ToStock) ?? PurchaseOrderModel.DEFAULT_STOCKTYPEID
            : PurchaseOrderModel.DEFAULT_STOCKTYPEID;

        this.setStockType(stockType);

        // SIDE-EFFECT.
        // For stock, we are only supporting an order type of "Sales".
        // Since the order type option is not on the user interface, we
        // have to set the order type as a side-effect.
        const orderType = isStock ? this.orderTypes.find((ot) => ot.type === OrderType.Purchase) ?? PurchaseOrderModel.DEFAULT_ORDERTYPEID : PurchaseOrderModel.DEFAULT_ORDERTYPEID;

        this.setOrderType(orderType);

        // SIDE-EFFECT
        // Reset the following supplier-based options.
        this.setSupplierAsync(PurchaseOrderModel.DEFAULT_SUPPLIERID);
        this.setTermsAndConditions(PurchaseOrderModel.DEFAULT_TERMSANDCONDITIONSID);
        this.setPaymentTerms(PurchaseOrderModel.DEFAULT_PAYMENTTERMSID);
        this.model.paymentTermsInDays = PurchaseOrderModel.DEFAULT_PAYMENTTERMSINDAYS;
        this.setPaymentType(PurchaseOrderModel.DEFAULT_PAYMENTTYPEID);

        // Reset the following delivery-based options.
        this.setAddress(PurchaseOrderModel.DEFAULT_DELIVERYADDRESSID);
        this.setDeliveryContactName(PurchaseOrderModel.DEFAULT_DELIVERYCONTACTNAME);
        this.setDeliveryContactMobile(PurchaseOrderModel.DEFAULT_DELIVERYCONTACTMOBILE);
    };

    @computed
    private get validateIsStock(): ValidationResponse {
        const errorMessage = this.model.validateIsStock;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    // #endregion Is Stock

    // #region Stock Type

    @observable
    public stockTypes = observable<StockTypeModel>([]);

    @computed
    public get stockType() {
        return this.stockTypes.find((st) => st.id === this.model.stockTypeId) ?? null;
    }

    @action
    public setStockType = async (stockType: StockTypeModel | null) => {
        this.model.stockTypeId = stockType?.id ?? PurchaseOrderModel.DEFAULT_STOCKTYPEID;

        // SIDE-EFFECT.
        // For stock, we are only supporting a hardwired stock project.
        // Since the project option is not on the user interface, we
        // have to set the project as a side-effect.
        const project =
            stockType?.type === StockTypeEnum.ToStock ? this.projects.find((p) => p.isStockProject) ?? PurchaseOrderModel.DEFAULT_PROJECTID : PurchaseOrderModel.DEFAULT_PROJECTID;

        this.setProjectAsync(project);
    };

    @computed
    private get validateStockTypeId(): ValidationResponse {
        const errorMessage = this.model.validateStockTypeId;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    // #endregion Stock Type

    // #region PrePaymentPOOption Type

    @observable
    public centralPrePaymentPOOptions = observable<POTypeModel>([]);

    @computed
    public get centralPrePaymentPOOption() {
        return this.centralPrePaymentPOOptions.find((st) => st.id === this.model.prePaymentParentRequisitionRequestId) ?? null;
    }

    @action
    public setCentralPrePaymentPOOption = async (poType: POTypeModel | null) => {
        this.setValue("prePaymentParentRequisitionRequestId", poType?.id ?? PurchaseOrderModel.DEFAULT_PREPAYMENTPARENTREQUISITIONREQUESTID);
        this.setValue("prePaymentParentPONumber", poType?.poNumber ?? PurchaseOrderModel.DEFAULT_PREPAYMENTPARENTPONUMBER);

        if (poType) {
            let supplier = new SupplierModel();
            supplier.id = poType.supplierId!;

            await this.setSupplierAsync(supplier);
            this.setValue("paymentTermsInDays", poType?.paymentTermsInDays ?? PurchaseOrderModel.DEFAULT_PAYMENTTERMSINDAYS);
            this.setValue("paymentTermsId", poType?.paymentTermsId ?? PurchaseOrderModel.DEFAULT_PAYMENTTERMSID);
            this.setValue("paymentTypeId", poType?.paymentTypeId ?? PurchaseOrderModel.DEFAULT_PAYMENTTYPEID);
            this.setValue("termsAndConditionsId", poType?.termsAndConditionsId ?? PurchaseOrderModel.DEFAULT_TERMSANDCONDITIONSID);
            this.setValue("termsAndConditionsFileName", poType?.termsAndConditionsFileName ?? undefined);
            this.setValue("termsAndConditionsFileUrl", poType?.termsAndConditionsFileUrl ?? undefined);
            await this.loadPrePaymentDetails(poType.id);
        }
    };

    @computed
    private get validatePrePaymentParentRequisitionRequestId(): ValidationResponse {
        const errorMessage = this.model.validatePrePaymentParentRequisitionRequestId;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    // #endregion PrePaymentPOOption Type

    // #region Is Central

    @observable
    public isCentralOptions = observable<{ id: boolean; displayName: string }>([
        { id: true, displayName: "Central" },
        { id: false, displayName: "Project" },
    ]);

    @computed
    public get isCentral() {
        return this.model.isCentral;
    }

    @computed
    public get isCentralOption() {
        if (this.model.isCentral) {
            return this.isCentralOptions.find((o) => o.displayName === "Central");
        }
        return this.isCentralOptions.find((o) => o.displayName === "Project");
    }

    @observable
    public isCentralNavigation: boolean = false;

    @action
    public setIsCentralNavigation = async (isCentral: boolean) => {
        this.isCentralNavigation = isCentral;

        if (isCentral) {
            this.setIsCentral(isCentral, false);
        }
    };

    @action
    public setIsCentral = async (isCentral: boolean, propogate: boolean = true) => {
        this.model.isCentral = isCentral;

        if (propogate) {
            this.setProjectAsync(null);
        }
    };

    // #endregion Is Central

    // #region Projects

    @observable
    public projects = observable<ProjectModel>([]);

    @computed
    public get projectOptions() {
        const showToStockProjects = this.stockType?.type === StockTypeEnum.ToStock;

        // If "to stock", then only show stock project, otherwise only show non stock projects.
        // If is central, then only show central project, otherwise only show non central projects.
        return this.projects
            .filter((p) => p.isStockProject === showToStockProjects)
            .filter((p) => p.isCentralProject === this.isCentral)
            .sort((a, b) => a.displayName.localeCompare(b.displayName));
    }

    @computed
    public get project() {
        return this.projects.find((p) => p.id === this.model.projectId) ?? null;
    }

    @action
    public setProjectAsync = async (value: ProjectModel | null) => {
        this.model.projectId = value?.id ?? PurchaseOrderModel.DEFAULT_PROJECTID;

        // SIDE-EFFECT.
        // Having set the project, we need to see if we have the income and expenditures available
        // locally and if not load them from the server.
        this.setAddress(PurchaseOrderModel.DEFAULT_DELIVERYADDRESSID);
        this.setDeliveryContactName(PurchaseOrderModel.DEFAULT_DELIVERYCONTACTNAME);
        this.setDeliveryContactMobile(PurchaseOrderModel.DEFAULT_DELIVERYCONTACTMOBILE);

        switch (true) {
            case value?.isStockProject:
            case value?.isCentralProject:
                await this.setIncomeAndExpendituresAsync(value!.id);
                await this.setIncomeAndExpenditureAsync(this.incomeAndExpenditures.find((ie) => ie.projectId === value?.id) ?? PurchaseOrderModel.DEFAULT_INCOMEANDEXPENDITUREID);
                this.setAddresses(value!.id);
                break;

            case !isNullOrUndefined(value):
                await this.setIncomeAndExpendituresAsync(value!.id);
                await this.setIncomeAndExpenditureAsync(PurchaseOrderModel.DEFAULT_INCOMEANDEXPENDITUREID);
                this.setAddresses(value!.id);
                break;

            default:
                await this.setIncomeAndExpenditureAsync(PurchaseOrderModel.DEFAULT_INCOMEANDEXPENDITUREID);
                break;
        }
    };

    @computed
    private get validateProject(): ValidationResponse {
        const errorMessage = this.model.validateProjectId;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    // #endregion Projects

    // #region Income and Expenditures

    @observable
    public incomeAndExpenditures = observable<IncomeAndExpenditureModel>([]);

    @computed
    public get incomeAndExpenditure() {
        return this.incomeAndExpenditures.find((p) => p.id === this.model.ieId) ?? null;
    }

    @action
    public setIncomeAndExpenditureAsync = async (value: IncomeAndExpenditureModel | null) => {
        this.model.ieId = value?.id ?? PurchaseOrderModel.DEFAULT_INCOMEANDEXPENDITUREID;

        // SIDE-EFFECT.
        // Having set the income and expenditure, we need to see if we have the categories available
        // locally and if not load them from the server.
        switch (true) {
            case value?.displayName === "Stock":
                await this.orderLine.setCategoriesAsync(value!.id);
                await this.orderLine.setCategoryAsync(this.orderLine.categories.find((c: any) => c.displayName === "Stock") ?? OrderLineModel.DEFAULT_CATEGORYID);
                break;

            case !isNullOrUndefined(value):
                await this.orderLine.setCategoriesAsync(value!.id);
                await this.orderLine.setCategoryAsync(OrderLineModel.DEFAULT_CATEGORYID);
                break;

            default:
                await this.orderLine.setCategoryAsync(OrderLineModel.DEFAULT_CATEGORYID);
                break;
        }

        if (!this.isCentral && !this.model.isStock && this.isNewRequest && this.incomeAndExpenditure && this.supplier) {
            await this.getIsDuplicateRequisitionSupplierOnIE();
        }
    };

    @computed
    private get validateIncomeAndExpenditure(): ValidationResponse {
        const errorMessage = this.model.validateIncomeAndExpenditureId;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    public get incomeAndExpendituresForProject(): IncomeAndExpenditureModel[] {
        return this.incomeAndExpenditures.filter((ie) => ie.projectId === this.model.projectId);
    }

    @action
    public setIncomeAndExpendituresAsync = async (projectId: string) => {
        if (this.incomeAndExpenditures.findIndex((ie) => ie.projectId === projectId) === -1) {
            return this.loadIncomeAndExpenditures();
        }
    };

    // #endregion Income and Expenditures

    // #region Order Types

    @observable
    public orderTypes = observable<OrderTypeModel>([]);

    @computed
    public get orderType() {
        return this.orderTypes.find((ot) => ot.id === this.model.orderTypeId) ?? null;
    }

    @action
    public setOrderType = (value: OrderTypeModel | null) => {
        this.model.orderTypeId = value?.id ?? PurchaseOrderModel.DEFAULT_ORDERTYPEID;

        // Side-effect
        switch (true) {
            case (this.orderType ? this.orderType.type : 0) === OrderType.Hire:
                this.model.dateFrom = PurchaseOrderModel.DEFAULT_DATEFROM;
                this.model.dateTo = PurchaseOrderModel.DEFAULT_DATETO;
                this.model.weekCommencing = PurchaseOrderModel.DEFAULT_WEEKCOMMENCING;
                break;

            case (this.orderType ? this.orderType.type : 0) === OrderType.Purchase:
                this.model.dateRequired = PurchaseOrderModel.DEFAULT_DATEREQUIRED;
                this.model.weekCommencing = PurchaseOrderModel.DEFAULT_WEEKCOMMENCING;
                break;

            case (this.orderType ? this.orderType.type : 0) === OrderType.Labour:
                this.model.dateRequired = PurchaseOrderModel.DEFAULT_DATEREQUIRED;
                this.model.dateFrom = PurchaseOrderModel.DEFAULT_DATEFROM;
                this.model.dateTo = PurchaseOrderModel.DEFAULT_DATETO;
                this.model.supplierId = null;
                this.model.termsAndConditionsId = null;
                this.model.paymentTermsId = null;
                this.model.paymentTermsInDays = 0;
                this.model.paymentTypeId = null;
                break;
        }
    };

    @computed
    private get validateOrderType(): ValidationResponse {
        const errorMessage = this.model.validateOrderId;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    public get canDisplayHireOrderTypeFeatures(): boolean {
        return (this.orderType ? this.orderType.type : 0) === OrderType.Hire;
    }

    @computed
    public get canDisplayPurchaseOrderTypeFeatures(): boolean {
        return (this.orderType ? this.orderType.type : 0) === OrderType.Purchase;
    }

    @computed
    public get canDisplayLabourTypeFeatures(): boolean {
        return (this.orderType ? this.orderType.type : 0) === OrderType.Labour;
    }

    // #endregion Order Types

    // #region Description

    @computed
    private get validateDescription(): ValidationResponse {
        const errorMessage = this.model.validateDescription;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    // #endregion Description

    // #region Dates for Purchase Order Type

    @computed
    private get validateRequiredDate(): ValidationResponse {
        // RULES
        // The date must be defined if the order type is purchase.
        const errorMessage =
            this.orderType && this.orderType.type === OrderType.Purchase && this.model.dateRequired === PurchaseOrderModel.DEFAULT_DATEREQUIRED ? "Please provide a date" : "";

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    // #endregion Dates for Purchase Order Type

    // #region Dates for Hire Order Type

    @computed
    private get validateFromDate(): ValidationResponse {
        // RULES
        // The date must be defined if the order type is hire.
        const errorMessage = this.orderType && this.orderType.type === OrderType.Hire && this.model.dateFrom === PurchaseOrderModel.DEFAULT_DATEFROM ? "Please provide a date" : "";

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateToDate(): ValidationResponse {
        // RULES
        // The date must be defined if the order type is hire.
        const errorMessage = this.orderType && this.orderType.type === OrderType.Hire && this.model.dateTo === PurchaseOrderModel.DEFAULT_DATETO ? "Please provide a date" : "";

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    // #endregion Dates for Hire Order Type

    // #region Dates for Labour Order Type

    @computed
    private get validateWeekCommencing(): ValidationResponse {
        // RULES
        // The date must be defined if the order type is labour.
        const errorMessage =
            this.orderType && this.orderType.type === OrderType.Labour && this.model.weekCommencing === PurchaseOrderModel.DEFAULT_WEEKCOMMENCING ? "Please provide a date" : "";

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    // #endregion Dates for Labour Order Type

    /**
     * Supplier Section
     */

    // #region Suppliers

    @observable
    public suppliers = observable<SupplierModel>([]);

    @computed
    public get supplier() {
        return this.suppliers.find((p) => p.id === this.model.supplierId) ?? null;
    }

    @action
    public setSupplierAsync = async (value: SupplierModel | null) => {
        this.model.supplierId = value?.id ?? PurchaseOrderModel.DEFAULT_SUPPLIERID;

        // SIDE-EFFECT.
        // Having set the supplier. we need to see if we have the suppliers details available
        // locally and if not load them from the server.
        if (this.model.supplierId) {
            this.setSupplierDetails(this.model.supplierId);
        }

        // SIDE-EFFECT.
        // Having set the supplier, if the requisition is a stock requisition, we need to see
        // if we have the materials available locally and if not load them from the server.
        switch (this.model.isStock) {
            case true:
                await this.orderLine.setMaterialsAsync(value!.id);
                this.orderLine.setMaterial(OrderLineModel.DEFAULT_MATERIALID);
                break;

            case false:
                this.orderLine.setMaterial(OrderLineModel.DEFAULT_MATERIALID);
                break;
        }

        if (!this.isCentral && !this.model.isStock && this.isNewRequest && this.incomeAndExpenditure && this.supplier) {
            await this.getIsDuplicateRequisitionSupplierOnIE();
        }
    };

    @computed
    private get validateSupplier(): ValidationResponse {
        // RULES
        // A supplier must be defined, unless this is a "from stock" requisition.
        const errorMessage = this.canViewSupplier && this.model.supplierId === PurchaseOrderModel.DEFAULT_SUPPLIERID ? "Please select a supplier" : "";

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    public get canViewSupplier() {
        if (this.model.isStock) {
            return this.stockType?.type === StockTypeEnum.ToStock;
        } else {
            return this.orderType?.type !== OrderType.Labour ?? true;
        }
    }

    @computed
    public get isPaymentTypeNonBacs(): boolean {
        const currentPaymentType = this.paymentType;

        if (currentPaymentType && currentPaymentType.displayName.toLowerCase() === "Non Bacs".toLowerCase()) {
            return true;
        }

        return false;
    }

    // #endregion Suppliers

    // #region Supplier Details

    private readonly SD_DEBOUNCE_VALUE_MS = 200;

    @observable
    public supplierDetails = observable<SupplierDetailsModel>([]);

    @computed
    public get supplierDetailsForSupplier(): SupplierDetailsModel | null {
        return this.supplierDetails.find((sd) => sd.id === this.model.supplierId) ?? null;
    }

    @computed
    public get canDisplaySupplierDetails(): boolean {
        return this.supplierDetailsForSupplier !== null;
    }

    private setSupplierDetails = debounce(
        action((supplierId: string) => {
            const supplier = this.supplierDetails.find((sd) => sd.id === supplierId);
            if (supplier) {
                this.model.paymentTermsId = supplier.paymentTerms;
                this.model.paymentTermsInDays = supplier.paymentTermsInDays;
                this.model.paymentTypeId = supplier.paymentTypeId;

                this.model.supplierContactEmails = supplier.latestSupplierPOContactEmails;

                if (!this.isPaymentTypeNonBacs) {
                    this.setSendEmailToSupplier(null, true);
                }
            } else {
                this.loadSupplierDetails();
            }
        }),
        this.SD_DEBOUNCE_VALUE_MS,
    );

    // #endregion Supplier Details

    // #region Terms And Conditions

    @observable
    public termsAndConditions = observable<TermsAndConditionsModel>([]);

    @computed
    public get termsAndConditionsOptions() {
        return this.termsAndConditions;
    }

    @computed
    public get termsAndConditionsWithoutSubcontractorOptions() {
        return this.termsAndConditions.filter((i) => i.displayName !== "Darwin Group Ltd Subcontractor Agreement");
    }

    @computed
    public get termsAndCondition() {
        return this.termsAndConditions.find((p) => p.id === this.model.termsAndConditionsId) ?? null;
    }

    @computed
    public get termsAndConditionSubContractor() {
        if (this.model.isSubcontractorAgreement) {
            return this.termsAndConditions.find((p) => p.displayName === "Darwin Group Ltd Subcontractor Agreement");
        }
        return this.termsAndConditions.find((p) => p.id === this.model.termsAndConditionsId) ?? null;
    }

    @action
    public setTermsAndConditions = (value: TermsAndConditionsModel | null) => {
        this.model.termsAndConditionsId = value?.id ?? PurchaseOrderModel.DEFAULT_TERMSANDCONDITIONSID;
    };

    @action
    public setTermsAndConditionsSubContractor = (value: TermsAndConditionsModel | null) => {
        if (value?.displayName === "Darwin Group Ltd Subcontractor Agreement") {
            this.setIsSubcontractorAgreement(true);
            this.model.termsAndConditionsId = PurchaseOrderModel.DEFAULT_TERMSANDCONDITIONSID;
        } else {
            this.model.termsAndConditionsId = value?.id ?? PurchaseOrderModel.DEFAULT_TERMSANDCONDITIONSID;
            this.setIsSubcontractorAgreement(false);
        }
    };

    @computed
    private get validateTermsAndConditions(): ValidationResponse {
        const errorMessage = this.validateTermsAndConditionsId;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    public get validateTermsAndConditionsId(): string {
        // RULES
        // Terms and Conditions must be defined, unless this is a "from stock" requisition.
        return this.canViewSupplier && this.model.termsAndConditionsId === PurchaseOrderModel.DEFAULT_TERMSANDCONDITIONSID ? "Please select terms and conditions" : "";
    }

    @computed
    private get validateTermsAndConditionsSub(): ValidationResponse {
        const errorMessage = this.validateTermsAndConditionsSubContractor;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    public get validateTermsAndConditionsSubContractor(): string {
        // RULES
        // Terms must be uploaded if subcontractor is selected.
        return this.canViewSupplier && this.model.isSubcontractorAgreement && (this.model.termsAndConditionsFileUrl === undefined || this.model.termsAndConditionsFileUrl === null)
            ? "Please upload a terms and conditions file"
            : "";
    }

    // #endregion Terms and Conditions

    // #region Payment Terms

    @observable
    public paymentTerms = observable<PaymentTermsModel>([]);

    @computed
    public get paymentTerm() {
        return this.paymentTerms.find((p) => p.id === this.model.paymentTermsId) ?? null;
    }

    @action
    public setPaymentTerms = (value: PaymentTermsModel | null) => {
        this.model.paymentTermsId = value?.id ?? PurchaseOrderModel.DEFAULT_PAYMENTTERMSID;
    };

    @computed
    private get validatePaymentTerms(): ValidationResponse {
        const errorMessage = this.validatePaymentTermsId;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    public get validatePaymentTermsId(): string {
        // RULES
        // Payment terms must be defined.
        return this.canViewSupplier && this.model.paymentTermsId === PurchaseOrderModel.DEFAULT_PAYMENTTERMSID ? "Please select payment terms" : "";
    }

    // #endregion Payment Terms

    // #region Payment Type

    @observable
    public paymentTypes = observable<PaymentTypeModel>([]);

    @computed
    public get paymentType() {
        return this.paymentTypes.find((p) => p.id === this.model.paymentTypeId) ?? null;
    }

    @action
    public setPaymentType = (value: PaymentTypeModel | null) => {
        this.model.paymentTypeId = value ? value.id : PurchaseOrderModel.DEFAULT_PAYMENTTYPEID;

        if (!this.isPaymentTypeNonBacs) {
            this.setSendEmailToSupplier(null, true);
        }
    };

    @computed
    private get validatePaymentType(): ValidationResponse {
        const errorMessage = this.validatePaymentTypeId;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    public get validatePaymentTypeId(): string {
        // RULES
        // Payment type must be defined.
        return this.canViewSupplier && this.model.paymentTypeId === PurchaseOrderModel.DEFAULT_PAYMENTTYPEID ? "Please select a payment type" : "";
    }

    // #endregion Payument Type

    /**
     * Delivery Section
     */

    // #region Delivery Contact Name

    @computed
    private get validateDeliveryContactName(): ValidationResponse {
        const errorMessage = this.model.validateDeliveryContactName;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @action
    private setDeliveryContactName = (contactName: string) => {
        this.model.deliveryContactName = contactName;
    };

    // #endregion Delivery Contact Name

    // #region Delivery Contact Mobile

    @computed
    private get validateDeliveryContactMobile(): ValidationResponse {
        const errorMessage = this.model.validateDeliveryContactMobile;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @action
    private setDeliveryContactMobile = (contactMobile: string) => {
        this.model.deliveryContactMobile = contactMobile;
    };

    // #endregion Delivery Contact Mobile

    // #region Addresses

    private readonly ADD_DEBOUNCE_VALUE_MS = 200;

    @observable
    public addresses = observable<AddressModel>([]);

    @computed
    public get projectAddresses() {
        return this.addresses.filter((a) => a.projectId === this.model.projectId);
    }

    @computed
    public get address() {
        return this.addresses.find((a) => a.id === this.model.deliveryAddressId) ?? null;
    }

    @action
    public setAddress = (value: AddressModel | null) => {
        this.model.deliveryAddressId = value?.id ?? PurchaseOrderModel.DEFAULT_DELIVERYADDRESSID;

        // SIDE-EFFECT.
        // Having set the deivery address. Set the associated contact information.
        const result = this.addresses.find((a) => a.id === this.model.deliveryAddressId);

        if (result) {
            this.model.deliveryContactMobile = result.deliveryContactNumber;
            this.model.deliveryContactName = result.deliveryContactName;
        }
    };

    @computed
    private get validateDeliveryAddress(): ValidationResponse {
        const errorMessage = this.model.validateDeliveryAddressId;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    public get addressesForProject(): AddressModel[] {
        return this.addresses.filter((add) => add.projectId === this.model.projectId);
    }

    public setAddresses = debounce(
        action((projectId: string) => {
            if (this.addresses.findIndex((add) => add.projectId === projectId) === -1) {
                this.loadAddresses().then(() => {
                    this.setPrePaymentDeliveryAddress();
                });
            } else {
                this.setPrePaymentDeliveryAddress();
            }
        }),
        this.ADD_DEBOUNCE_VALUE_MS,
    );

    // Set the delivery address to the parents if this is a prepayment.
    private setPrePaymentDeliveryAddress() {
        if (this.model.prePaymentParentRequisitionRequestId !== PurchaseOrderModel.DEFAULT_PREPAYMENTPARENTREQUISITIONREQUESTID) {
            const address = this.addresses.find((add) => add.projectId === this.model.projectId && add.displayName === this.model.prePaymentDeliveryAddress);

            if (address) {
                this.setAddress(address);
            }
        }
    }

    // #endregion Addresses

    /**
     * Line Order Items Section
     */

    /**
     * Instructions to Supplier Section
     */

    @computed
    private get validateAdditionalContactEmailAddress(): ValidationResponse {
        let errorMessage: string = "";

        if (this.canViewSupplier && this.canDisplaySupplierDetails) {
            const value: string | null = this.model.additionalContactEmailAddress;
            const valueList: string[] = value ? value.split(",") : [];

            for (let i = 0; i < valueList.length; i++) {
                const e: string = valueList[i];
                if (!validator.isEmail(e!)) {
                    errorMessage = "The list of email addresses is invalid.";
                    break;
                }
            }
        }

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    // #region Requisition Groups

    @action
    private createViewModels() {
        for (const item of this.model.requisitionGroups) {
            this.requisitionGroupViewModels.push(new RequisitionRequestItemGroupViewModel(item, this.model.projectId, this.model.ieId, this.isCentral));
        }
    }

    @observable
    private requisitionGroupViewModels: RequisitionRequestItemGroupViewModel[] = [];

    @computed
    public get requisitionGroups() {
        let forceRerun = this.isInReviseMode;
        let groups = this.requisitionGroupViewModels.filter((g) => g.haveItems);

        groups.slice().sort((lhs, rhs) => {
            if (lhs.displayName < rhs.displayName) return -1;
            if (lhs.displayName > rhs.displayName) return 1;
            return 0;
        });

        return groups;
    }

    @computed
    public get getBudgetForecastTotalFormatted(): BudgetForecastFormatted {
        const total: BudgetForecast = this.requisitionGroups
            .filter((g) => !g.model.getIsVariation)
            .reduce(
                (acc: BudgetForecast, group: RequisitionRequestItemGroupViewModel) => {
                    const budgetForecastModel: BudgetForecast = group.toBudgetForecastModel();

                    Object.keys(acc).forEach((property: string) => {
                        acc[property] += budgetForecastModel[property];
                    });

                    return acc;
                },
                {
                    preTargetCost: 0,
                    preCommittedCost: 0,
                    preFutureSpend: 0,
                    preTotalExpectedSpend: 0,
                    preVariance: 0,
                    postTargetCost: 0,
                    postCommittedCost: 0,
                    postFutureSpend: 0,
                    postTotalExpectedSpend: 0,
                    postVariance: 0,
                },
            );

        const formattedTotal: BudgetForecastFormatted = {} as BudgetForecastFormatted;

        Object.keys(total).forEach((property: string) => {
            formattedTotal[property] = formatCurrencyNoSign(parseFloat(total[property]));
        });

        return formattedTotal;
    }

    @computed
    public get canShowBudgetForecast(): boolean {
        return !this.model.isCentral;
    }

    @computed
    public get total(): string {
        return formatCurrencyFromPounds(this.model.total);
    }

    @computed
    public get haveGroups(): boolean {
        return this.requisitionGroups.length > 0;
    }

    @action
    public addOrderLineToRequisitionGroupAsync = async (orderLineModel: OrderLineModel) => {
        const requisitionGroup: RequisitionRequestItemGroupViewModel | undefined = this.requisitionGroupViewModels.find((rg) => rg.isMatchingGroup(orderLineModel));

        // Can only add one group for a central requisition.
        if (this.isCentral) {
            if (!requisitionGroup && this.requisitionGroupViewModels.filter((g) => !g.model.isDeleted).length > 0) {
                this.setSnackMessage("Can only add one group for a cental requisition.");
                this.setSnackType(this.SNACKERROR);
                this.setSnackbarState(true);
                return false;
            }
        }

        const noMatches: boolean = await this.getLineIdMatchingExistingRequisitionAsync(orderLineModel);

        if (!noMatches) {
            runInAction(() => {
                if (requisitionGroup) {
                    requisitionGroup!.addItem(orderLineModel);
                } else {
                    const model: RequisitionRequestItemGroupModel = RequisitionRequestItemGroupModel.fromOrderLineModel(orderLineModel);
                    model.isCentral = this.isCentral;
                    this.model.requisitionGroups.push(model);
                    const viewModel: RequisitionRequestItemGroupViewModel = new RequisitionRequestItemGroupViewModel(model, this.model.projectId, this.model.ieId, this.isCentral);
                    this.requisitionGroupViewModels.push(viewModel);
                }
            });
        }

        return noMatches;
    };

    @observable
    public isLineValidationLoading: boolean = false;

    @action
    public setIsLineValidationLoading = (val: boolean) => {
        this.isLineValidationLoading = val;
    };

    public getLineIdMatchingExistingRequisitionAsync = async (orderLineModel: OrderLineModel): Promise<boolean> => {
        this.setIsLineValidationLoading(true);

        const poId: string | null = this.model.id !== "" && this.model.id !== undefined ? this.model.id : null;
        const dto = { ieId: this.model.ieId, poId: poId, lineId: orderLineModel.lineDescriptionId };

        const apiResult = await this.Post<{ id: string; name: string; requestedByUserName: string }[]>(AppUrls.Server.PurchaseOrder.GetLineIdMatchingExistingRequisition, dto)
            .then((apiResult) => {
                if (apiResult.payload.length > 0) {
                    runInAction(() => {
                        this.handleShowMatchingUnapprovedRequisitionsModalChange(true);
                        this.setMatchingUnapprovedRequisitions(apiResult.payload);
                    });
                }

                return apiResult.payload.length > 0;
            })
            .catch((err) => {
                return true;
            })
            .finally(() => this.setIsLineValidationLoading(false));

        return apiResult;
    };

    @computed
    public get getNotWithinSpendAllowanceGroupsInBudget(): RequisitionRequestItemGroupViewModel[] {
        return this.requisitionGroups.filter(
            (g) => g.model.id !== null && g.model.id !== undefined && g.model.id !== "" && g.model.isWithinSpendAllowance === false && g.model.subType === ApprovalSubType.InBudget,
        );
    }

    @computed
    public get getNotWithinSpendAllowanceGroupsOuOfBudget(): RequisitionRequestItemGroupViewModel[] {
        return this.requisitionGroups.filter(
            (g) =>
                g.model.id !== null && g.model.id !== undefined && g.model.id !== "" && g.model.isWithinSpendAllowance === false && g.model.subType === ApprovalSubType.OutOfBudget,
        );
    }

    // #endregion Requisition Groups

    // #region Order Line

    public orderLine: OrderLineViewModel = new OrderLineViewModel(
        computed(() => this.model.ieId),
        computed(() => this.model.supplierId),
        computed(() => this.model.isCentral),
        computed(() => this.model.prePaymentCategoryNames),
        computed(() => this.model.prePaymentSubCategoryNames),
        computed(() => this.model.prePaymentDescriptionNames),
        this.addOrderLineToRequisitionGroupAsync,
    );

    // #endregion Order Line

    @computed
    public get canAmendRequisition(): boolean {
        return this.approvalPanelViewModel.getCanShowAmenderPanel;
    }

    @action
    public handleShowSendForApprovalModalChange(show: boolean) {
        this.showSendForApprovalModal = show;
    }

    @computed
    public get getShowSendForApprovalModal() {
        return this.showSendForApprovalModal;
    }

    @action
    public setRequesterNote = (val: string) => {
        this.model.requesterNotes = val;
    };

    @computed
    public get getRequesterNoteValid() {
        return this.model.requesterNotes !== "" && this.model.requesterNotes !== null && this.model.requesterNotes !== undefined;
    }

    // #region Server Actions

    public server: ServerViewModel = new ServerViewModel();

    // related, for empty form
    public loadRelated = async (): Promise<void> => {
        return await this.server.query<PurchaseOrderRelatedResponseDTO>(
            () => this.Get(`${AppUrls.Server.PurchaseOrder.Load.Related}`),
            (result) => {
                runInAction(() => {
                    this.orderLine.categories.replace(CategoryModel.fromDtos([]));
                    this.orderLine.subCategories.replace(SubCategoryModel.fromDtos([]));
                    this.orderLine.lineDescriptions.replace(LineDescriptionModel.fromDtos([]));

                    this.incomeAndExpenditures.replace(IncomeAndExpenditureModel.fromDtos([]));

                    this.orderTypes.replace(OrderTypeModel.fromDtos(result.orderTypes));
                    this.projects.replace(ProjectModel.fromDtos(result.projects));
                    this.suppliers.replace(SupplierModel.fromDtos(result.suppliers));
                    this.termsAndConditions.replace(TermsAndConditionsModel.fromDtos(result.termsAndConditions));
                    this.paymentTerms.replace(PaymentTermsModel.fromDtos(result.paymentTerms));
                    this.paymentTypes.replace(PaymentTypeModel.fromDtos(result.paymentTypes));
                    this.stockTypes.replace(StockTypeModel.fromDtos(result.stockTypes));
                    this.centralPrePaymentPOOptions.replace(POTypeModel.fromDtos(result.centralPrePaymentPOOptions));
                });
            },
        );
    };

    // req id
    public loadWithRelated = async (): Promise<void> => {
        this.setIsLoading(true);
        return await this.server
            .query<PurchaseOrderAndRelatedResponseDTO>(
                () => this.Get(`${AppUrls.Server.PurchaseOrder.Load.WithRelatedById}\\${this.model.id}`),
                (result) => {
                    runInAction(() => {
                        this.orderLine.categories.replace(CategoryModel.fromDtos(result.ieCategory));
                        this.orderLine.subCategories.replace(SubCategoryModel.fromDtos(result.ieSubcategory));
                        this.orderLine.lineDescriptions.replace(LineDescriptionModel.fromDtos(result.ieLineItem));
                        this.incomeAndExpenditures.replace(IncomeAndExpenditureModel.fromDtos(result.ie));
                        this.orderTypes.replace(OrderTypeModel.fromDtos(result.orderTypes));
                        this.projects.replace(ProjectModel.fromDtos(result.projects));
                        this.suppliers.replace(SupplierModel.fromDtos(result.suppliers));
                        this.termsAndConditions.replace(TermsAndConditionsModel.fromDtos(result.termsAndConditions));
                        this.paymentTerms.replace(PaymentTermsModel.fromDtos(result.paymentTerms));
                        this.paymentTypes.replace(PaymentTypeModel.fromDtos(result.paymentTypes));
                        this.addresses.replace(AddressModel.fromDtos(result.addresses));
                        this.stockTypes.replace(StockTypeModel.fromDtos(result.stockTypes));
                        this.centralPrePaymentPOOptions.replace(POTypeModel.fromDtos(result.centralPrePaymentPOOptions));

                        this.revisionHistory.replace(result.revisionHistory);

                        // Set the supplier details.
                        if (result.supplierDetails !== null) {
                            const supplierDetails = new SupplierDetailsModel();
                            supplierDetails.fromDto(result.supplierDetails);
                            this.supplierDetails.push(supplierDetails);
                        }

                        // Set the documents.
                        if (result.requisitionDocuments) result.requisitionDocuments.map(this.setRequisitionFile);
                        if (result.purchaseDocuments) result.purchaseDocuments.map(this.setPurchaseFile);

                        // Set the isPO flag for the reqisition groups to use. Used for the committed cost calculations in the budget forecast.
                        // Also check ponumber because revising a PO resets the ispo flag back to 0.
                        const requiresAmendStatus = result.requisitionPOStatuses.find((s) => s.type === RequisitionPOStatusEnum.AmendRequired);
                        const isAmending = result.requisitionDetail.requisitionPOStatusId === requiresAmendStatus?.id;

                        if (result.requisitionDetail.requisitionGroups) {
                            result.requisitionDetail.requisitionGroups.map((group) => {
                                group.isPO = result.requisitionDetail.isPO;
                                group.poNumber = result.requisitionDetail.poNumber;
                                group.isAmending = isAmending;
                            });
                        }

                        this.model.fromDto(result.requisitionDetail);

                        this.model.prePaymentCategoryNames = result.prePaymentCategoryNames;
                        this.model.prePaymentSubCategoryNames = result.prePaymentSubCategoryNames;
                        this.model.prePaymentDescriptionNames = result.prePaymentDescriptionNames;

                        // If this is a stock requistion, we need to configure items in the orderline.
                        // I cannot see a clean way to do this, so we will call the appropriate setters
                        // in the form to propagate changes that will configure the orderline.
                        if (this.incomeAndExpenditure?.displayName === "Stock") {
                            this.setIncomeAndExpenditureAsync(this.incomeAndExpenditure);
                            this.setSupplierAsync(this.supplier);
                        }

                        this.createViewModels();

                        // Set the approval panel data.
                        this.approvalPanelViewModel.model.fromDto(result.approvalPanel);
                        this.approvalPanelViewModel.populateRequisitionPOStatuses(result.requisitionPOStatuses);
                        this.approvalPanelViewModel.populateApprovalHistory(result.approvalHistoryItems);
                        this.approvalPanelViewModel.approvalHistoryViewModel.fromDto(result.approvalHistory);

                        // Determine whether to show the notification bar.
                        if (this.approvalPanelViewModel.model.id !== null && this.approvalPanelViewModel.model.approvalStatusId !== null) {
                            NotificationBarViewModel.Instance.setItem("requisition");
                            NotificationBarViewModel.Instance.setPath(AppUrls.Client.PurchaseOrder.Edit);
                            NotificationBarViewModel.Instance.setIsActive(!this.approvalPanelViewModel.getIsRejectedOrApproved);
                        }

                        this.model.isDuplicateSupplier = result.isDuplicateSupplier;
                    });
                },
            )
            .finally(() => this.setIsLoading(false));
    };

    public loadIncomeAndExpenditures = async () => {
        return this.server.query<any>(
            () => this.Get(`${AppUrls.Server.PurchaseOrder.GetIEByProjectId}\\${this.model.projectId}`),
            (result: any) => {
                runInAction(() => {
                    this.incomeAndExpenditures.push(...IncomeAndExpenditureModel.fromDtos(result));
                });
            },
        );
    };

    public loadSupplierDetails = (): Promise<void> => {
        return this.server.query<SupplierDetailsDTO>(
            () => this.Get(`${AppUrls.Server.PurchaseOrder.GetSupplierDetailBySupplierId}\\${this.model.supplierId}`),
            (result) => {
                runInAction(() => {
                    const supplierDetails = new SupplierDetailsModel();
                    supplierDetails.fromDto(result);
                    this.supplierDetails.push(supplierDetails);
                    this.model.paymentTermsId = result.paymentTerms;
                    this.model.paymentTermsInDays = result.paymentTermsInDays;
                    this.model.paymentTypeId = result.paymentTypeId;
                    this.model.latestSupplierPOContactEmails = result.latestSupplierPOContactEmails;
                    this.model.supplierContactEmails = result.latestSupplierPOContactEmails;
                    if (!this.isPaymentTypeNonBacs) {
                        this.setSendEmailToSupplier(null, true);
                    }
                });
            },
        );
    };

    public getIsDuplicateRequisitionSupplierOnIE = async (): Promise<void> => {
        this.setIsLoading(true);
        return await this.server
            .query<boolean>(
                () => this.Get(`${AppUrls.Server.PurchaseOrder.GetIsDuplicateRequisitionSupplierOnIE}\\${this.model.supplierId}\\${this.model.ieId}`),
                (result) => {
                    runInAction(() => {
                        this.setIsDuplicateSupplier(result);
                    });
                },
            )
            .finally(() => this.setIsLoading(false));
    };

    public loadAddresses = () => {
        return this.server.query<any>(
            () => this.Get(`${AppUrls.Server.PurchaseOrder.GetAddressesByProjectId}\\${this.model.projectId}`),
            (result: any) => {
                runInAction(() => {
                    this.addresses.push(...AddressModel.fromDtos(result));
                });
            },
        );
    };

    public loadPrePaymentDetails = async (id: string) => {
        return this.server.query<any>(
            () => this.Get(`${AppUrls.Server.PurchaseOrder.GetCatsSubCatsAndLineIdsByPOId}\\${id}`),
            (result: any) => {
                runInAction(() => {
                    this.model.prePaymentCategoryNames = result.prePaymentCategoryNames;
                    this.model.prePaymentSubCategoryNames = result.prePaymentSubCategoryNames;
                    this.model.prePaymentDescriptionNames = result.prePaymentDescriptionNames;
                    this.model.prePaymentDeliveryAddress = result.prePaymentDeliveryAddress;

                    this.setPrePaymentDeliveryAddress();
                });
            },
        );
    };

    /**
     * Custom model validation function. Validates child order line models and its children
     * @returns True if model is valid, false if not.
     */
    public isMyModelValid = async (): Promise<boolean> => {
        let isValid = true;

        if (this.requisitionGroups.length === 0) {
            isValid = false;
            this.setSnackMessage("Add at least one line before saving.");
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }

        // JC: Changed forEach into for loop as the await seems to have issues with forEach.
        for (let i = 0; i < this.requisitionGroups.length; i++) {
            let item = this.requisitionGroups[i];

            // Validate each child item.
            if ((await item.isMyModelValid()) === false) {
                isValid = false;
            }
        }

        if ((await this.isModelValid()) === false) {
            isValid = false;
        }

        return isValid;
    };

    @observable
    public submissionValidationFunction: SaveFunctionScenario | null = null;

    @action
    public setSubmissionValidationFunction = (key: SaveFunctionScenario | null) => {
        this.submissionValidationFunction = key;
    };

    private validateRequisitionDocuments = async (key: SaveFunctionScenario): Promise<boolean> => {
        let dto = this.model.toDto();

        this.server.setIsSubmitted(true);

        if (!(await this.isMyModelValid())) {
            return false;
        }

        const result = await this.Post<any>(AppUrls.Server.PurchaseOrder.ValidateRequisitionDocuments, dto).then((apiResult) => {
            if (!apiResult.wasSuccessful) {
                console.log(apiResult.errors);
                if (apiResult.errors[0].message === "One or more documents exceeds size limit." && apiResult.payload.invalidDocuments.length > 0) {
                    this.handleShowInvalidSubmissionDocumentsModalChange(true);
                    this.setInvalidSubmissionDocuments(apiResult.payload.invalidDocuments);
                }

                if (apiResult.errors[0].message === "Maximum number of attachments.") {
                    this.handleShowSubmissionMaxAttachmentsModalChange(true);
                }

                if (apiResult.errors[0].message === "Email is too large to send.") {
                    this.handleShowSubmissionSizeModalChange(true);
                }

                this.setSubmissionValidationFunction(key);

                return false;
            }

            return true;
        });

        this.server.resetIsSubmitted();

        return result;
    };

    public saveWithoutDocumentValidation = async (): Promise<void> => {
        if (this.submissionValidationFunction) {
            const func = this.functionsMap[this.submissionValidationFunction];
            if (func) {
                await func(true);
                this.handleShowSubmissionMaxAttachmentsModalChange(false);
                this.handleShowSubmissionSizeModalChange(false);
                this.handleShowInvalidSubmissionDocumentsModalChange(false);
            } else {
                console.log("Function not found for key:", this.submissionValidationFunction);
            }
        } else {
            console.log("Key not set:", this.submissionValidationFunction);
        }
    };

    public sendAndCreate = async (saveWithoutDocumentValidation: boolean): Promise<void> => {
        if (!saveWithoutDocumentValidation) {
            const isValid = await this.validateRequisitionDocuments(SaveFunctionScenario.sendAndCreate);

            if (!isValid) {
                return;
            }
        }

        return this.server.command<PurchaseOrderUpsertResponseDTO>(
            () => this.saveAndExitPost(),
            (result) => {
                runInAction(() => {
                    console.log(result);
                    this.reset();
                    this.setSnackMessage("Purchase Order/Requisition has been submitted");
                    this.setSnackType(this.SNACKSUCCESS);
                    this.setSnackbarState(true);
                });
            },
            this.isMyModelValid,
            "There was an error trying to send the purchase order",
        );
    };

    public handleUnreject = (): Promise<void> => {
        return this.server.command<PurchaseOrderUpsertResponseDTO>(
            () => this.unrejectPost(),
            (result) => {
                runInAction(() => {
                    this.reset();
                    getHistory().goBack();
                });
            },
            this.isMyModelValid,
            "There was an error trying to unreject the purchase order",
        );
    };

    private unrejectPost = async (): Promise<ApiResult<PurchaseOrderUpsertResponseDTO>> => {
        let dto = this.model.toDto();

        const result = await this.Post<PurchaseOrderUpsertResponseDTO>(AppUrls.Server.PurchaseOrder.Unreject, dto).then((apiResult) => {
            if (!apiResult.wasSuccessful) {
                console.log(apiResult.errors);
                runInAction(() => {
                    console.log(apiResult.errors);
                    this.setSnackMessage("There was an error trying to unreject the purchase order");
                    this.setSnackType(this.SNACKERROR);
                    this.setSnackbarState(true);
                });

                if (apiResult.errors.length > 0) {
                    if (apiResult.errors[0].message === "Lines match existing unapproved requisition." && apiResult.payload.matchingUnapprovedRequisitions.length > 0) {
                        runInAction(() => {
                            this.handleShowMatchingUnapprovedRequisitionsModalChange(true);
                            this.setMatchingUnapprovedRequisitions(apiResult.payload.matchingUnapprovedRequisitions);
                        });
                    }
                }
            }

            return apiResult;
        });

        return result;
    };

    public sendAndExit = async (saveWithoutDocumentValidation: boolean): Promise<void> => {
        if (!saveWithoutDocumentValidation) {
            const isValid = await this.validateRequisitionDocuments(SaveFunctionScenario.sendAndExit);

            if (!isValid) {
                return;
            }
        }

        return this.server.command<PurchaseOrderUpsertResponseDTO>(
            () => this.saveAndExitPost(),
            (result) => {
                runInAction(() => {
                    this.reset();
                    getHistory().goBack();
                });
            },
            this.isMyModelValid,
            "There was an error trying to send the purchase order",
        );
    };

    private saveAndExitPost = async (): Promise<ApiResult<PurchaseOrderUpsertResponseDTO>> => {
        let dto = this.model.toDto();

        const result = await this.Post<PurchaseOrderUpsertResponseDTO>(AppUrls.Server.PurchaseOrder.Upsert, dto).then((apiResult) => {
            if (!apiResult.wasSuccessful) {
                console.log(apiResult.errors);
                runInAction(() => {
                    console.log(apiResult.errors);
                    this.setSnackMessage("There was an error trying to save the purchase order");
                    this.setSnackType(this.SNACKERROR);
                    this.setSnackbarState(true);
                });

                if (apiResult.errors.length > 0) {
                    if (apiResult.errors[0].message === "Lines match existing unapproved requisition." && apiResult.payload.matchingUnapprovedRequisitions.length > 0) {
                        runInAction(() => {
                            this.handleShowMatchingUnapprovedRequisitionsModalChange(true);
                            this.setMatchingUnapprovedRequisitions(apiResult.payload.matchingUnapprovedRequisitions);
                        });
                    }

                    if (apiResult.errors[0].message === "You have already included this year in your prepayment.") {
                        runInAction(() => {
                            this.setShowInvalidPrePaymentYearModal(true);
                        });
                    }
                }
            }

            return apiResult;
        });

        return result;
    };

    public reviseAndExit = async (saveWithoutDocumentValidation: boolean): Promise<void> => {
        if (!saveWithoutDocumentValidation) {
            const isValid = await this.validateRequisitionDocuments(SaveFunctionScenario.reviseAndExit);

            if (!isValid) {
                return;
            }
        }

        return this.server.command<PurchaseOrderUpsertResponseDTO>(
            () => this.reviseAndExitPost(),
            (result) => {
                runInAction(() => {
                    this.reset();
                    getHistory().goBack();
                });
            },
            this.isMyModelValid,
            "There was an error trying to revise and send the purchase order",
        );
    };

    private reviseAndExitPost = async (): Promise<ApiResult<PurchaseOrderUpsertResponseDTO>> => {
        let dto = this.model.toDto();

        dto.revision = generateOrIncrementRevision(dto.revision);

        const result = await this.Post<PurchaseOrderUpsertResponseDTO>(AppUrls.Server.PurchaseOrder.ReviseRequisition, dto).then((apiResult) => {
            if (!apiResult.wasSuccessful) {
                console.log(apiResult.errors);
                runInAction(() => {
                    console.log(apiResult.errors);
                    this.setSnackMessage("There was an error trying to revise and send the purchase order");
                    this.setSnackType(this.SNACKERROR);
                    this.setSnackbarState(true);
                });

                if (apiResult.errors.length > 0) {
                    if (apiResult.errors[0].message === "Invoice allocation errors." && apiResult.payload.invalidItems.length > 0) {
                        runInAction(() => {
                            this.invalidItems = apiResult.payload.invalidItems;
                            this.handleShowInvalidItemsModalChange(true);
                        });
                    } else if (apiResult.errors[0].message === "Lines match existing unapproved requisition." && apiResult.payload.matchingUnapprovedRequisitions.length > 0) {
                        runInAction(() => {
                            this.handleShowMatchingUnapprovedRequisitionsModalChange(true);
                            this.setMatchingUnapprovedRequisitions(apiResult.payload.matchingUnapprovedRequisitions);
                        });
                    }
                }
            }

            return apiResult;
        });

        return result;
    };

    private amendRequisitionPost = async (): Promise<ApiResult<PurchaseOrderUpsertResponseDTO>> => {
        const result = await this.Post<PurchaseOrderUpsertResponseDTO>(AppUrls.Server.PurchaseOrder.AmendRequisition, this.model.toDto()).then((apiResult) => {
            if (!apiResult.wasSuccessful) {
                console.log(apiResult.errors);
                runInAction(() => {
                    console.log(apiResult.errors);
                    this.setSnackMessage("There was an error trying to amend the purchase order");
                    this.setSnackType(this.SNACKERROR);
                    this.setSnackbarState(true);
                    this.handleShowSendForApprovalModalChange(false);
                });

                if (apiResult.errors.length > 0) {
                    if (apiResult.errors[0].message === "Invoice allocation errors." && apiResult.payload.invalidItems.length > 0) {
                        runInAction(() => {
                            this.invalidItems = apiResult.payload.invalidItems;
                            this.handleShowInvalidItemsModalChange(true);
                        });
                    } else if (apiResult.errors[0].message === "Lines match existing unapproved requisition." && apiResult.payload.matchingUnapprovedRequisitions.length > 0) {
                        runInAction(() => {
                            this.handleShowMatchingUnapprovedRequisitionsModalChange(true);
                            this.setMatchingUnapprovedRequisitions(apiResult.payload.matchingUnapprovedRequisitions);
                        });
                    }
                }
            }

            return apiResult;
        });

        return result;
    };

    public amendRequisition = async (saveWithoutDocumentValidation: boolean): Promise<void> => {
        if (!saveWithoutDocumentValidation) {
            const isValid = await this.validateRequisitionDocuments(SaveFunctionScenario.amendRequisition);

            if (!isValid) {
                return;
            }
        }

        return this.server.command<PurchaseOrderUpsertResponseDTO>(
            () => this.amendRequisitionPost(),
            (result) => {
                runInAction(() => {
                    this.reset();
                    getHistory().goBack();
                });
            },
            this.isMyModelValid,
            "There was an error trying to amend the purchase order",
        );
    };

    // Function map to store functions and their associated types
    private functionsMap: { [key in SaveFunctionScenario]: (param: boolean) => Promise<void> } = {
        [SaveFunctionScenario.sendAndCreate]: this.sendAndCreate.bind(this), // Binding this for correct context
        [SaveFunctionScenario.sendAndExit]: this.sendAndExit.bind(this),
        [SaveFunctionScenario.reviseAndExit]: this.reviseAndExit.bind(this),
        [SaveFunctionScenario.amendRequisition]: this.amendRequisition.bind(this),
    };

    @observable
    public emailValidationParams: any = null;

    @action
    public setEmailValidationParams = ({ isApproved, approverNotes, requisitionStatusId }: any) => {
        this.emailValidationParams = { isApproved: isApproved, approverNotes: approverNotes, requisitionStatusId: requisitionStatusId };
    };

    @action
    public resetEmailValidationParams = () => {
        this.emailValidationParams = null;
    };

    public upsertApprovalStatusWithoutAttachments = async (): Promise<void> => {
        if (this.emailValidationParams) {
            await this.upsertApprovalStatus(this.emailValidationParams.isApproved, this.emailValidationParams.approverNotes, this.emailValidationParams.requisitionStatusId, false);
        }
    };

    public upsertApprovalStatus = async (isApproved: boolean, approverNotes: string, requisitionStatusId: string, sendWithAttachments = true): Promise<void> => {
        let existingApproverNotes = this.approvalPanelViewModel.model.approverNotes !== null ? this.approvalPanelViewModel.model.approverNotes : "";
        let existingRequesterNotes = this.approvalPanelViewModel.model.requesterNotes !== null ? this.approvalPanelViewModel.model.requesterNotes : "";
        let sendEmailToSupplier = this.model.sendEmailToSupplier;

        let approvalDocumentsToUpload: ApprovalDocumentDTO[] = [];

        const isRequiresAmends: boolean = this.approvalPanelViewModel.getAmendRequiredStatusId === requisitionStatusId;
        const documentModels: ApprovalDocumentModel[] = this.approvalPanelViewModel.model.approvalDocuments;

        if (isRequiresAmends) {
            documentModels.map((m) => {
                if (!m.hasId && m.isDeleted) {
                    // do nothing
                } else {
                    approvalDocumentsToUpload.push(m.toDto());
                }
            });
        } else {
            documentModels.map((m) => {
                m.isDeleted = true;
                if (!m.hasId && m.isDeleted) {
                    // do nothing
                } else {
                    approvalDocumentsToUpload.push(m.toDto());
                }
            });
        }

        const request: PurchaseOrderApprovalStatusDTO = {
            requisitionPOId: this.model.id !== null ? this.model.id : "",
            variationId: null,
            invoiceId: null,
            drawingId: null,
            requisitionStatusId: requisitionStatusId,
            requesterNotes: existingRequesterNotes,
            approverNotes: approverNotes !== "" ? approverNotes : existingApproverNotes,
            isApproved: isApproved,
            sendEmailToSupplier: sendEmailToSupplier,
            approvalDocuments: approvalDocumentsToUpload,
            sendWithAttachments: sendWithAttachments,
            rowVersion: this.approvalPanelViewModel.model.rowVersion,
        };

        const apiResult = await this.Post<any>(AppUrls.Server.Approval.UpsertPurchaseOrderApprovalStatus, request);

        if (apiResult) {
            if (apiResult.wasSuccessful) {
                this.history.push(AppUrls.Client.Approval.List);
            } else {
                console.log(apiResult.errors);
                runInAction(() => {
                    console.log(apiResult.errors);
                    this.setSnackMessage("Failed to process approval");
                    this.setSnackType(this.SNACKERROR);
                    this.setSnackbarState(true);

                    // Check errors and show modals.
                    if (apiResult.errors.length > 0) {
                        if (apiResult.errors[0].message === "One or more documents exceeds size limit." && apiResult.payload.invalidDocuments.length > 0) {
                            this.handleShowInvalidDocumentsModalChange(true);
                            this.setInvalidDocuments(apiResult.payload.invalidDocuments);
                        }

                        if (apiResult.errors[0].message === "Email is too large to send.") {
                            this.handleShowEmailSizeModalChange(true);
                        }

                        if (apiResult.errors[0].message === "Maximum number of attachments.") {
                            this.handleShowEmailMaxAttachmentsModalChange(true);
                        }

                        this.setEmailValidationParams({ isApproved, approverNotes, requisitionStatusId });
                    }
                });
            }
            //this.setHasLoaded(true);
        }
    };

    public sendSupplierEmail = (): Promise<void> => {
        return this.server.command<string>(
            () => this.sendSupplierEmailPost(),
            (result) => {
                runInAction(() => {
                    this.reset();
                    getHistory().goBack();
                });
            },
            this.isMyModelValid,
            "There was an error trying to send the supplier",
        );
    };

    public sendSupplierEmailPost = async (): Promise<ApiResult<string>> => {
        let dto = this.model.toDto();

        const result = await this.Post<string>(AppUrls.Server.PurchaseOrder.SendSupplierEmail, dto).then((apiResult) => {
            if (!apiResult.wasSuccessful) {
                console.log(apiResult.errors);
                runInAction(() => {
                    console.log(apiResult.errors);
                    this.setSnackMessage("There was an error trying to send the email to the supplier.");
                    this.setSnackType(this.SNACKERROR);
                    this.setSnackbarState(true);
                });
            }

            return apiResult;
        });

        return result;
    };

    public fileUpload = async (data: any): Promise<ApiResult<any>> => {
        const formData = new FormData();
        formData.append("formFile", data.formFile);
        formData.append("fileName", data.fileName);
        const apiResult = await this.Post<any>(AppUrls.Server.File.UploadFile, formData);
        if (apiResult) {
            if (!apiResult.wasSuccessful) {
                console.log(apiResult.errors);
                runInAction(() => {
                    let errorMessage: string = "Error uploading file please try again.";

                    if (apiResult?.errors?.length > 0) {
                        errorMessage =
                            apiResult.errors[0].message === "The file type is not supported." ? "The file type is not supported." : "Error uploading file please try again.";
                    }

                    this.setSnackMessage(errorMessage);
                    this.setSnackType(this.SNACKERROR);
                    this.setSnackbarState(true);
                });
            }
        }
        return apiResult;
    };

    /**
     * Download a file that exists in azure.
     * @param fileUrl The URL of the file to be downloaded.
     * @param fileName The name of the file to be downloaded.
     */
    public DownloadFile = async (fileUrl: string, fileName: string): Promise<void> => {
        try {
            const apiResult = await this.Post<Blob>(AppUrls.Server.File.DownloadFile, fileUrl, undefined, { responseType: "blob" });
            const response = apiResult as any;
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
        } catch (exception) {
            console.error(exception);
            this.setIsErrored(true);
        }
    };

    @action
    public setPurchaseFile = (file: IDisplayFile) => {
        this.model.purchaseFile.push(file);
    };

    @action
    public setRequisitionFile = (file: IDisplayFile) => {
        this.model.requisitionFile.push(file);
    };
    @action
    public setTermsconditionsFile = (file: IDisplayFile) => {
        this.model.termsAndConditionsFileName = file.fileName;
        this.model.termsAndConditionsFileUrl = file.fileUrl;
    };

    public fileChange = async (event: any, isRequisitionFile: boolean = false): Promise<void> => {
        if (event.target.files.length > 0) {
            let data: any = {
                fileName: event.target.files[0].name,
                formFile: event.target.files[0],
            };
            event.target.value = null;
            const apiResult = await this.fileUpload(data);
            if (apiResult && apiResult.wasSuccessful) {
                let fileToDisplay: IDisplayFile = {
                    id: null,
                    fileUrl: apiResult.payload,
                    fileName: data.fileName,
                    isDeleted: false,
                    rowVersion: null,
                    originatorId: null,
                    noteTypeId: null,
                    requisitionRequestId: null,
                };
                if (isRequisitionFile) {
                    this.setRequisitionFile(fileToDisplay);
                } else {
                    this.setPurchaseFile(fileToDisplay);
                }
            }
        }
    };

    public termsconditionsFileChange = async (event: any): Promise<void> => {
        if (event.target.files.length > 0) {
            let data: any = {
                fileName: event.target.files[0].name,
                formFile: event.target.files[0],
            };
            event.target.value = null;
            const apiResult = await this.fileUpload(data);
            if (apiResult && apiResult.wasSuccessful) {
                let fileToDisplay: IDisplayFile = {
                    id: null,
                    fileUrl: apiResult.payload,
                    fileName: data.fileName,
                    isDeleted: false,
                    rowVersion: null,
                    originatorId: null,
                    noteTypeId: null,
                    requisitionRequestId: null,
                };
                this.setTermsconditionsFile(fileToDisplay);
            }
        }
    };

    @action
    public deleteRequisitionFile = async (index: number): Promise<void> => {
        this.model.requisitionFile[index].isDeleted = true;
    };

    @action
    public deletePurchaseFile = async (index: number): Promise<void> => {
        this.model.purchaseFile[index].isDeleted = true;
    };

    @action
    public removeTermsconditionsFile = async (): Promise<void> => {
        this.model.termsAndConditionsFileName = undefined;
        this.model.termsAndConditionsFileUrl = undefined;
    };

    public handleGenerateAllPurchaseOrderPDF = async (id: string | null, revision: string | null) => {
        await exportPDFWithGet(
            AppUrls.Server.PurchaseOrder.GeneratePOPDFByIdAndRevision,
            { id: id, revision: revision ? revision : undefined },
            await this.getConfig(true, csvAxiosRequestConfig),
        );
    };

    @action
    public reset = () => {
        this.model.reset();
        this.server.reset();

        this.orderLine.reset(true);

        this.requisitionGroupViewModels = [];
    };

    @action
    public handleCancel = (): void => {
        getHistory().goBack();
    };

    // #endregion Client Actions

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<PurchaseOrderModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        if (this.server.IsSubmitted) {
            // Process the properties of the model that cannot be supported via
            // the use of decorators.
            switch (fieldName) {
                case "dateFrom": {
                    const result = this.validateFromDate;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }

                case "dateRequired": {
                    const result = this.validateRequiredDate;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }

                case "dateTo": {
                    const result = this.validateToDate;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }

                case "weekCommencing": {
                    const result = this.validateWeekCommencing;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }

                case "deliveryAddressId": {
                    const result = this.validateDeliveryAddress;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }

                case "deliveryContactName": {
                    const result = this.validateDeliveryContactName;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }

                case "deliveryContactMobile": {
                    const result = this.validateDeliveryContactMobile;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }

                case "description": {
                    const result = this.validateDescription;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }

                case "ieId": {
                    const result = this.validateIncomeAndExpenditure;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }

                case "orderTypeId": {
                    const result = this.validateOrderType;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }

                case "paymentTermsId": {
                    const result = this.validatePaymentTerms;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }

                case "paymentTypeId": {
                    const result = this.validatePaymentType;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }

                case "projectId": {
                    const result = this.validateProject;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }

                case "supplierId": {
                    const result = this.validateSupplier;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }

                case "additionalContactEmailAddress": {
                    const result = this.validateAdditionalContactEmailAddress;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }

                case "termsAndConditionsId": {
                    const result = this.validateTermsAndConditions;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;

                    if (result.isValid) {
                        const subResult = this.validateTermsAndConditionsSub;

                        errorMessage = subResult.errorMessage;
                        isValid = subResult.isValid;
                    }
                    break;
                }

                case "stockTypeId": {
                    const result = this.validateStockTypeId;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }

                case "isStock": {
                    const result = this.validateIsStock;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }

                case "prePaymentParentRequisitionRequestId": {
                    const result = this.validatePrePaymentParentRequisitionRequestId;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }

                //
            }
        } else {
            // Do not validate if the properties of the model have not been
            // submitted to the server.
            errorMessage = "";
            isValid = true;
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    // #region Snackbar

    @observable
    public snackbarState = false;

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public snackMessage = "";

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @observable
    public snackType = "";

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    @observable
    public SNACKSUCCESS = "success";

    @observable
    public SNACKERROR = "error";
    // #endregion

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}

enum SaveFunctionScenario {
    sendAndCreate,
    sendAndExit,
    reviseAndExit,
    amendRequisition,
}
