import { ModelBase } from "@shoothill/core";
import { computed, observable } from "mobx";
import moment from "moment/moment";

export class DailyDiaryQuestionModel extends ModelBase<DailyDiaryQuestionModel, DailyDiaryQuestionModelDTO> {
    public id: string | null = null;
    @observable
    public title: string | null = null;
    @observable
    public ordinal: number = 0;
    @observable
    public completed: boolean = false;
    @observable
    public completedBy: string = "";
    @observable
    public completedByDate: Date | null = null;
    @computed
    public get formattedCompletedDate(): string {
        return moment(this.completedByDate).format("DD/MM/YYYY @ HH:mm");
    }
    constructor() {
        super();
    }

    fromDto(model: DailyDiaryQuestionModelDTO): void {
        this.id = model.id;
        this.title = model.title;
        this.ordinal = model.ordinal;
        this.completed = model.completed;
        this.completedBy = model.completedBy;
        this.completedByDate = model.completedByDate;
    }

    toDto(model: DailyDiaryQuestionModel): void {
        model.id = this.id;
        model.title = this.title;
        model.ordinal = this.ordinal;
        model.completed = this.completed;
        model.completedBy = this.completedBy;
        model.completedByDate = this.completedByDate;
    }
}

export class DailyDiaryQuestionModelDTO {
    public id: string | null = null;
    public title: string | null = null;
    public ordinal: number = 0;
    public completed: boolean = false;
    public completedBy: string = "";
    public completedByDate: Date | null = null;
}
