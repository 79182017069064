import styled from "styled-components";

export const TabsContainer = styled.div`
    display: flex;

    ol {
        display: flex;
        flex: 1;
        height: 46px;
        list-style-type: none;
        margin: 0 30px;
    }

    li {
        align-items: center;
        background-color: #ededed;
        border-left: 1px solid #d4d4d4;
        border-top: 1px solid #d4d4d4;
        border-right: 1px solid #d4d4d4;
        color: #040506;
        cursor: pointer;
        display: flex;
        font-size: 14px;
        justify-content: center;
        min-width: 132px;
        padding: 8px;
        user-select: none;

        &:not(first-child) {
            margin-left: -1px;
        }

        &.active {
            background-color: white;
        }

        span {
            align-items: center;
            background-color: #d32f2f;
            border-radius: 12px;
            color: white;
            display: inline-flex;
            font-size: 12px;
            height: 24px;
            justify-content: center;
            margin-left: 6px;
            min-width: 24px;
            padding: 0 4px;
        }
    }
`;
