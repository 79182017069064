//import styled from "@emotion/styled";
//import { Box } from "Components";
import styled, { css } from "styled-components";
import { Box } from "@material-ui/core";
import { theme } from "Globals/Styles/AppTheme";

export const TableWrapper = styled(Box)`
    * {
        box-sizing: border-box;
    }

    html {
        font-family: sans-serif;
        font-size: ${theme.typography.body2.fontSize};
    }

    td {
        border: 1px solid #d4d4d4;
        font-size: ${theme.typography.body2.fontSize};
    }

    /* //Spacing between thead and tbody
    tbody:before {
        content: "@";
        display: block;
        line-height: 14px;
        text-indent: -99999px;
        border: none;
    } */

    table,
    .divTable {
        width: fit-content;
        border-collapse: collapse;
        width: 100%;
        table-layout: auto;
    }

    table {
        width: 100% !important;
    }

    tbody > tr:nth-of-type(1) {
        border-top: 1px solid #d4d4d4;
    }

    tbody > tr:hover {
        background-color: rgba(0, 0, 0, 0.04);
    }

    thead {
        background-color: rgb(206, 212, 218);
    }

    .t-head {
        height: 40px;
    }

    .tr {
        display: flex;
    }

    tbody {
        td {
            > div {
                max-width: 350px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 4;
            }
        }
    }

    tr,
    .tr {
        width: fit-content;
        height: 30px;
        cursor: pointer;
    }

    th,
    .th,
    td,
    .td {
        padding: 6px;
    }

    th,
    .th {
        padding: 2px 8px;
        font-weight: 600;
        font-size: ${theme.typography.body2.fontSize};
        text-align: left;
        height: 30px;
        border-right: 1px solid white;
    }

    td,
    .td {
        height: 30px;
    }

    th {
        cursor: pointer;
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
    }

    .resizer {
        position: absolute;
        top: 0;
        height: 100%;
        width: 3px;
        background: transparent;
        cursor: col-resize;
        user-select: none;
        touch-action: none;

        display: none; // Even when disabled, the hover still shows...
    }

    .resizer.isResizing {
        background: blue;
        opacity: 1;
    }

    @media (hover: hover) {
        .resizer {
            opacity: 0;
        }

        *:hover > .resizer {
            opacity: 1;
        }
    }

    .arrow-box {
        min-width: 15px;
        padding-left: 4px;
    }

    .title-wrapper {
        display: flex;
    }

    .action-cell {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }
`;

export const PaginationWrapper = styled(Box)`
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
`;

export interface IPagButton {
    isStartOrEnd: boolean;
}

export const PaginationButton = styled.button<IPagButton>`
    background-color: transparent;
    border: none;
    padding: 10px;
    color: black;
    font-size: ${theme.typography.body2.fontSize};
    font-weight: 300;
    cursor: ${(props) => (props.isStartOrEnd ? "default" : "pointer")};

    ${(props) =>
        !props.isStartOrEnd &&
        css`
            &:hover {
                background-color: rgba(0, 0, 0, 0.04);
            }
        `}
`;

export const ButtonsWrapper = styled(Box)`
    display: flex;
    align-items: center;
    padding-top: 8px;

    .PaginationInput {
        border: 1px solid #dedede;
        padding: 3px 8px;
        min-height: 37px;
        font-size: ${theme.typography.body2.fontSize};
    }
`;

export const ItemsPerPageWrapper = styled(Box)`
    display: flex;
    align-items: center;
    gap: 20px;
`;

export const FilteringSectionTop = styled(Box)`
    background-color: transparent !important;
    padding: 0px 0px 15px 0px;
`;

export const FilteringSectionBottom = styled(Box)`
    background-color: #ededed;
    min-height: 98px;
    padding: 15px 10px 25px 10px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;

export interface INextPrevText {
    isStartOrEnd: boolean;
}

export interface IImg {
    isStartOrEnd: boolean;
}

export const ButtonImg = styled.img<IImg>`
    opacity: ${(props) => (props.isStartOrEnd ? 0.5 : 1)};
`;
