import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { EndOfDayViewModel } from "./EndOfDayViewModel";
import { AuditQuestionView } from "./AuditQuestionView";
import { SHBox } from "../../../../../Components/Box";
import { Typography } from "@material-ui/core";
import { DarwinSelect } from "../../../../../Components/AutoComplete/DarwinSelect";
import { Signature } from "../../../../../Components/Signature/Signature";
import { SectionSplitter } from "../../../../../Components/Form/SectionSplitter";
import { ProjectButtonContainer } from "../../../Project.styles";
import { DefaultButton, PrimaryButton } from "../../../../../Components/Buttons/Buttons";
import { isEmpty } from "../../../../../Utils/Utils";

interface Props {
    id: string | null;
    projectId: string | null;
    handleClose: () => void;
}
export const EndOfDayView: React.FC<Props> = observer((props: Props) => {
    const [viewModel] = useState(() => new EndOfDayViewModel());
    useEffect(() => {
        viewModel.setValue("id", props.id);
        viewModel.setValue("projectId", props.projectId);
        if (props.id) {
            viewModel.loadAudit();
        } else {
            viewModel.loadQuestions();
        }
    }, []);

    const handleSave = () => {
        viewModel.submit(props.handleClose);
    };

    const handleCancel = () => {
        viewModel.cancel(props.handleClose);
    };

    return (
        <SHBox pl={3} pr={3} pb={"200px"} pt={3}>
            {viewModel.questionViewModels.map((questionViewModel, index) => {
                return (
                    <SHBox key={"audit-question-" + index}>
                        <AuditQuestionView editable={viewModel.editable} viewModel={questionViewModel} />
                        <SectionSplitter />
                    </SHBox>
                );
            })}
            <SectionSplitter />
            <Typography variant={"h3"}>Authorisation</Typography>
            <Typography variant={"h5"}>I can confirm I have carried out this audit to the best of my ability and the information is true</Typography>
            <SHBox grid dc={"300px 600px"} pt={2}>
                <DarwinSelect
                    displayName="Name"
                    execute={viewModel.setAuditUser}
                    fullWidth={false}
                    getOptionLabel={(option: any) => option.displayName}
                    options={viewModel.users}
                    placeholder="Please select user"
                    value={viewModel.model.auditUser}
                    canExecute={viewModel.editable}
                />
                <Signature
                    displayName={"Your signature:"}
                    execute={viewModel.updateSignature}
                    value={viewModel.model.signatureBase64}
                    canExecute={isEmpty(viewModel.model.signatureBase64) && viewModel.editable}
                />
            </SHBox>
            <SectionSplitter />
            <SHBox showIf={viewModel.editable}>
                <ProjectButtonContainer>
                    {viewModel.canSubmit() && <PrimaryButton execute={handleSave} displayName={"Submit"} disabled={viewModel.server.IsBusy} />}
                    <DefaultButton execute={handleCancel} displayName={"Cancel"} canExecute={!viewModel.server.IsBusy} />
                </ProjectButtonContainer>
            </SHBox>
        </SHBox>
    );
});
