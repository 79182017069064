import * as React from "react";

import { CancelButton } from "Globals/Styles/Control/Buttons";
import { BaseModal } from "Components/Modal/BaseModal";
import { InvoiceMatchMissingAllocationErrorModalDialogPaper } from "./InvoiceMatchMissingAllocationErrorModal.styles";
import { formatCurrencyFromPounds } from "Utils/Format";

interface IProps {
    open?: boolean;
    onClose(): void;
    title: string;
    text: string;
    isLoading?: boolean;
    items: { id: string; itemDescription: string; projectName: string }[];
}

export const InvoiceMatchMissingAllocationErrorModal: React.FC<IProps> = ({ open, onClose, title, text, isLoading = false, items }) => (
    <BaseModal
        open={open}
        onClose={onClose}
        title={title}
        PaperComponent={InvoiceMatchMissingAllocationErrorModalDialogPaper}
        actions={
            <>
                <CancelButton onClick={onClose}>Ok</CancelButton>
            </>
        }
    >
        <p className="client-modal-description">{text}</p>
        <ul>
            {items.map((p) => {
                return (
                    <li key={p.id}>
                        {p.projectName} - {p.itemDescription}
                    </li>
                );
            })}
        </ul>
    </BaseModal>
);
