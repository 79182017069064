import { Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { AppUrls } from "AppUrls";
import { ClassNameMap } from "@material-ui/styles";
import { BreadcrumbViewModel } from "../BreadcrumbViewModel";

export const SupplierMatchSection1 = (classes: ClassNameMap<"root" | "common" | "prev" | "heading" | "main" | "secondary" | "lists" | "nested">): any => {
    return (
        <Link component={RouterLink} to={AppUrls.Client.Supplier.List}>
            <div className={classes.main}>
                Suppliers
                <span className="triangle"></span>
            </div>
        </Link>
    );
};

export const SupplierMatchSection2 = (
    viewModel: BreadcrumbViewModel,
    classes: ClassNameMap<"root" | "common" | "prev" | "heading" | "main" | "secondary" | "lists" | "nested">,
    plusSign: any,
): any => {
    return (
        <div className={classes.secondary}>
            {viewModel.canAddSupplier && (
                <div onClick={viewModel.onAddSupplier}>
                    <img src={plusSign} className="add-image" alt="Add new supplier" />
                    <span className="add-text">Add supplier</span>
                </div>
            )}
        </div>
    );
};

export const SupplierDetailMatchSection1 = (classes: ClassNameMap<"root" | "common" | "prev" | "heading" | "main" | "secondary" | "lists" | "nested">, arrowSign: any): any => {
    return (
        <div>
            <Link component={RouterLink} to={AppUrls.Client.Supplier.List} className={classes.prev}>
                Suppliers
            </Link>

            <Link component={RouterLink} to={AppUrls.Client.Supplier.Detail} className="secondary-text">
                <div className={classes.main}>
                    <img src={arrowSign} className="add-image" alt="Detail" />
                    Detail
                    <span className="triangle"></span>
                </div>
            </Link>
        </div>
    );
};
