import { EnumType, IEnumType } from "./Base/EnumType";

export class InductionType extends EnumType {
    // #region Constants

    public static readonly Full = "FULL";
    public static readonly Visitor = "VISITOR";

    // #endregion Constants

    // #region Properties
    // #endregion Properties

    // #region Methods

    public fromDto(dto: IInductionType) {
        super.fromDto(dto);
    }

    // #endregion Methods
}

export interface IInductionType extends IEnumType {}
