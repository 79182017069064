import * as React from "react";
import { Button, TextField } from "@material-ui/core";
import styled from "styled-components";

import { BaseModal } from "Components/Modal/BaseModal";
import { CancelButton } from "Globals/Styles/Control/Buttons";
import { useObserver } from "mobx-react-lite";
import { EditContactModalDialogPaper } from "Views/Contacts/EditContactModal.styles";
import { theme } from "Globals/Styles/AppTheme";
import { VariationViewModel } from "./VariationViewModel";

interface IVariationChangesRequiredModalProps {
    open?: boolean;
    onClose(): void;
    title: string;
    confirmText: string;
    viewModel: VariationViewModel;
    isLoading: boolean;
}

export const VariationChangesRequiredModal: React.FunctionComponent<IVariationChangesRequiredModalProps> = ({ open, onClose, title, confirmText, viewModel, isLoading }) => {
    const [amendmentNote, setAmendmentNote] = React.useState<string>("");

    const handleAmend = () => {
        const requisitionPOStatusId = viewModel.approvalPanelViewModel.getAmendRequiredStatusId;
        viewModel.upsertApprovalStatus(false, amendmentNote, requisitionPOStatusId ? requisitionPOStatusId : "");
    };

    const handleClose = () => {
        setAmendmentNote("");
        onClose();
    };

    return useObserver(() => (
        <BaseModal
            open={open}
            onClose={handleClose}
            title={title}
            PaperComponent={VariationChangesRequiredModalDialogPaper}
            actions={
                <>
                    <Button onClick={handleAmend} color="secondary" autoFocus variant="contained" disabled={isLoading}>
                        {confirmText}
                    </Button>
                    <CancelButton onClick={handleClose}>Cancel</CancelButton>
                </>
            }
        >
            <TextField
                id="amend-reason"
                multiline
                rows={10}
                value={amendmentNote}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setAmendmentNote(event.target.value)}
                fullWidth
                className="textarea"
            />
        </BaseModal>
    ));
};

export const VariationChangesRequiredModalDialogPaper = styled(EditContactModalDialogPaper)`
    width: 100% !important;

    .MuiDialogActions-root > button {
        max-width: 262px;
    }

    .MuiDialogContent-root {
        margin-bottom: 20px;
    }
`;
