import * as React from "react";

import { BaseModal } from "Components/Modal/BaseModal";
import { DarwinModalDialogPaper } from "Components/Modal/BaseModal.styles";
import { Button, Typography } from "@material-ui/core";
import { useEffect } from "react";
import { ApprovalDelegateUserDataViewModel } from "./ApprovalDelegateUserDataViewModel";
import { useObserver } from "mobx-react-lite";
import styled from "styled-components";

interface IProps {
    open?: boolean;
    title: string;
    onClose(): void;
}

export const DelegationRemovedNotificationModal: React.FC<IProps> = ({ open, onClose, title }) => {
    const viewModel: ApprovalDelegateUserDataViewModel = ApprovalDelegateUserDataViewModel.Instance;

    const [errorMessage, setErrorMessage] = React.useState<string>("");

    //useEffect below only gets run on initial render
    useEffect(() => {
        return () => {
            viewModel.cleanUp();
        };
    }, []);

    const onSaveLocal = async () => {
        setErrorMessage("");
        let upsertPromise = viewModel.deleteDelegations();
        await upsertPromise.then((response) => {
            if (response.wasSuccessful) {
                onClose();
            } else {
                setErrorMessage("Failed to mark delegations as removed. Please refresh and try again.");
            }
        });
    };

    return useObserver(() => (
        <BaseModal
            dialogClasses="delegation-removed-dialog"
            open={open}
            onClose={onClose}
            title={title}
            PaperComponent={DelegationRemovedNotificationModalDialogPaper}
            actions={
                <>
                    <Button onClick={onSaveLocal} color="secondary" autoFocus variant="contained" disabled={viewModel.IsLoading}>
                        Ok
                    </Button>
                </>
            }
            canClose={false}
        >
            <div>
                <ul>
                    {viewModel.getDelegationsRemovedNotifications.map((item, index) => {
                        return <li key={index}>{item}</li>;
                    })}
                </ul>
            </div>

            {viewModel !== null && errorMessage !== "" && (
                <Typography variant="caption" style={{ color: "red" }}>
                    {errorMessage}
                </Typography>
            )}
        </BaseModal>
    ));
};

export const DelegationRemovedNotificationModalDialogPaper = styled(DarwinModalDialogPaper)`
    .MuiDialogContent-root {
        min-height: 80px;

        ul {
            list-style-type: none;

            li {
                font-size: 14px;
            }
        }

        > div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;
            > div.MuiFormControl-root {
                max-width: 250px;
                min-width: 200px;
            }

            > div.MuiFormControl-root:nth-child(1) {
                margin-right: 10px;
            }
        }
    }
`;
