import { action, computed, observable } from "mobx";
import { ModelBase } from "@shoothill/core";
import { validateTwoDecimalPlaces } from "Utils/Utils";

export class InvoiceProjectModel extends ModelBase<InvoiceProjectModel, any> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Constants and Defaults

    public static readonly DEFAULT_ID = null;
    public static readonly DEFAULT_PROJECTID = "";
    public static readonly DEFAULT_PROJECTREFERENCE = "";
    public static readonly DEFAULT_PROJECTNAME = "";
    public static readonly DEFAULT_INVOICEVALUE = "";
    public static readonly DEFAULT_ISDELETED = false;

    // #endregion Constants and Defaults

    // #region Properties

    @observable
    public id: string | null = InvoiceProjectModel.DEFAULT_ID;

    @observable
    public projectId: string = InvoiceProjectModel.DEFAULT_PROJECTID;

    @observable
    public projectReference: string = InvoiceProjectModel.DEFAULT_PROJECTREFERENCE;

    @observable
    public projectName: string = InvoiceProjectModel.DEFAULT_PROJECTNAME;

    @observable
    public invoiceValue: string = InvoiceProjectModel.DEFAULT_INVOICEVALUE;

    @observable
    public isDeleted: boolean = InvoiceProjectModel.DEFAULT_ISDELETED;

    // #endregion Properties

    // #region Actions

    @action
    public reset = () => {
        this.id = InvoiceProjectModel.DEFAULT_ID;
        this.projectId = InvoiceProjectModel.DEFAULT_PROJECTID;
        this.projectReference = InvoiceProjectModel.DEFAULT_PROJECTREFERENCE;
        this.projectName = InvoiceProjectModel.DEFAULT_PROJECTNAME;
        this.invoiceValue = InvoiceProjectModel.DEFAULT_INVOICEVALUE;
        this.isDeleted = InvoiceProjectModel.DEFAULT_ISDELETED;
    };

    @action
    public fromDto(dto: InvoiceProjectDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself

        for (let key in dto) {
            if (dto.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(dto[key]);
                } else {
                    this[key] = dto[key];
                }
            }
        }
    }

    public toDto(): UpsertInvoiceProjectDTO {
        const dto: UpsertInvoiceProjectDTO = {
            id: this.id,
            projectId: this.projectId,
            invoiceValue: this.invoiceValue,
            isDeleted: this.isDeleted,
        };

        return dto;
    }

    // #endregion Actions

    // #region Custom Validation

    @computed
    public get validateInvoiceValue(): string {
        // RULES
        const invoiceValueNumber: number = Number(this.invoiceValue);
        if (this.invoiceValue === InvoiceProjectModel.DEFAULT_INVOICEVALUE || this.invoiceValue === undefined || this.invoiceValue === null || isNaN(invoiceValueNumber)) {
            return "Please provide an invoice value";
        } else if (!validateTwoDecimalPlaces(invoiceValueNumber)) {
            return "No more than two decimal places";
        }

        return "";
    }

    // #endregion Custom Validation
}

export interface UpsertInvoiceProjectDTO {
    id: string | null;
    projectId: string | null;
    invoiceValue: string | null;
    isDeleted: boolean;
}

export interface InvoiceProjectDTO {
    id: string | null;
    invoiceId: string | null;
    projectId: string | null;
    projectReference: string | null;
    projectName: string | null;
    invoiceValue: string;
    isDeleted: boolean;
}
