// Libraries
import { Typography } from "@material-ui/core";
import { Grid, useObserver } from "@shoothill/core";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";

// Custom
import { DefaultButton, PrimaryButton } from "Components/Buttons/Buttons";
import { DeleteModal } from "Components/Modal/DeleteModal";
import { GenericModal } from "Components/Modal/GenericModal";
import { PatchedPagination } from "Components/Table/PatchedPagination";
import { TableListSearch } from "Components/Table/TableListSearch";
import * as Defaults from "Globals/Defaults/TableOptions";
import { DarwinPage, DarwinPageTitle, DarwinTablePageContent, DarwinTableSearchBox, DarwinTableToolbar } from "Globals/Styles/AppStyling";
import { formatDateTime } from "Utils/Format";
import { IEGridItemViewModel } from "Views/Project/Commercial/IEmodels/IEGridItemViewModel";
import { PackagesViewModel } from "Views/Project/Commercial/PackagesViewModel";
import { MaterialApprovalView } from "Views/Stock/Material/MaterialApproval/MaterialApprovalView";
import { MaterialApprovalViewModel } from "Views/Stock/Material/MaterialApproval/MaterialApprovalViewModel";
import { MaterialPriceDetailsApprovalViewModel } from "Views/Stock/Material/MaterialPriceDetailsApproval/MaterialPriceDetailsApprovalViewModel";
import { MaterialPriceDetailsApprovalView } from "Views/Stock/Material/MaterialPriceDetailsApproval/MaterialPriceDetailsApprovalView";
import { StockAdjustmentApprovalViewModel } from "Views/Stock/Stock/StockAdjustmentApproval/StockAdjustmentApprovalViewModel";
import { StockAdjustmentApprovalView } from "Views/Stock/Stock/StockAdjustmentApproval/StockAdjustmentApprovalView";
import { StockTransferApprovalViewModel } from "Views/Stock/Stock/StockTransferApproval/StockTransferApprovalViewModel";
import { StockTransferApprovalView } from "Views/Stock/Stock/StockTransferApproval/StockTransferApprovalView";
import { ApprovalListViewModel } from "./ApprovalListViewModel";
import { MyApprovalsActionedListItemViewModel } from "./MyApprovalsActionedListItemViewModel";
import { MyApprovalsNewListItemViewModel } from "./MyApprovalsNewListItemViewModel";
import { Tabsv2 } from "./Navigation/Tabsv2";
import { ApprovalDelegateUserDataViewModel } from "./ApprovalDelegate/ApprovalDelegateUserDataViewModel";
import { DelegateApprovalsModal } from "./ApprovalDelegate/DelegateApprovalsModal";
import { DelegationAddedNotificationModal } from "./ApprovalDelegate/DelegationAddedNotificationModal";
import { DelegationRemovedNotificationModal } from "./ApprovalDelegate/DelegationRemovedNotificationModal";

export const ApprovalListView: React.FunctionComponent = () => {
    const [viewModel] = useState(() => new ApprovalListViewModel());
    const [showDelegateApprovalsModal, setShowDelegateApprovalsModal] = useState<boolean>(false);
    const [showDismissDelegateApprovalsModal, setShowDismissDelegateApprovalsModal] = useState<boolean>(false);

    useEffect(() => {
        PackagesViewModel.Instance.apiGetRelated();
        IEGridItemViewModel.Instance.setIsCentral(false);
        ApprovalDelegateUserDataViewModel.Instance.getDelegateData();

        return () => {};
    }, []);

    const setSearchString = (value: string) => {
        const name = viewModel.tabsViewModel.activeTabItemViewModel?.id ?? "";

        viewModel.setSearchString(value, name);
    };

    const onNewRowClick = (event?: any, rowViewModel?: MyApprovalsNewListItemViewModel) => {
        rowViewModel?.navigateToFormApproval(viewModel);
    };

    const onActionedRowClick = (event?: any, rowViewModel?: MyApprovalsActionedListItemViewModel) => {
        rowViewModel?.navigateToForm(viewModel);
    };

    const handleDelegateApprovalsClick = () => {
        setShowDelegateApprovalsModal(true);
    };

    const handleDismissDelegateApprovalsClick = () => {
        setShowDismissDelegateApprovalsModal(true);
    };

    const handleCloseDismissModal = () => {
        setShowDismissDelegateApprovalsModal(false);
    };

    const handleCloseDelegateModal = () => {
        setShowDelegateApprovalsModal(false);
    };

    const handleDismissDelegation = () => {
        ApprovalDelegateUserDataViewModel.Instance.dismissDelegatedApprovals().then((apiResult) => {
            if (apiResult.wasSuccessful) {
                handleCloseDismissModal();
            } else {
                console.log(apiResult.errors);
            }
        });
    };

    const renderNewTable = () => {
        const tableOptions = Defaults.GetDarwinTableOptions() as any;

        return (
            <>
                <MaterialTable
                    isLoading={viewModel.IsLoading || ApprovalDelegateUserDataViewModel.Instance.IsLoading}
                    columns={[
                        {
                            title: "What is being amended?",
                            field: "approvalSectionName",
                            width: "auto",
                        },
                        {
                            title: "Ref/Name",
                            field: "refNameFormatted",
                            width: "auto",
                        },
                        {
                            title: "Description",
                            field: "itemDescription",
                            width: "auto",
                        },
                        {
                            title: "Cost",
                            field: "itemCost",
                            width: "auto",
                            render: (rowData: MyApprovalsNewListItemViewModel) => <>{rowData.itemCostFormatted}</>,
                        },
                        {
                            title: "Approver",
                            field: "approverRoleName",
                            width: "auto",
                        },
                        {
                            title: "Supplier",
                            field: "supplierName",
                            width: "auto",
                        },
                        {
                            title: "Approval type",
                            field: "approvalTypeName",
                            align: "center",
                            width: "auto",
                        },
                        {
                            title: "Requested by",
                            field: "requestedBy",
                            width: "auto",
                        },
                        {
                            title: "Requested on",
                            field: "requestedOnDate",
                            width: "auto",
                            type: "date",
                            render: (rowData: MyApprovalsNewListItemViewModel) => <>{formatDateTime(rowData.requestedOnDate !== null ? rowData.requestedOnDate.toString() : "")}</>,
                        },
                    ]}
                    components={{
                        Pagination: PatchedPagination,
                    }}
                    data={viewModel.filteredMyApprovalsNewViewModels}
                    onRowClick={onNewRowClick}
                    options={{ ...tableOptions, filtering: false, search: false, toolbar: false }}
                    title=""
                />
            </>
        );
    };

    const renderRequiredTable = () => {
        const tableOptions = Defaults.GetDarwinTableOptions() as any;

        return (
            <>
                <MaterialTable
                    isLoading={viewModel.IsLoading || ApprovalDelegateUserDataViewModel.Instance.IsLoading}
                    columns={[
                        {
                            title: "What is being amended?",
                            field: "approvalSectionName",
                            width: "auto",
                        },
                        {
                            title: "Ref/Name",
                            field: "refNameFormatted",
                            width: "auto",
                        },
                        {
                            title: "Description",
                            field: "itemDescription",
                            width: "auto",
                        },
                        {
                            title: "Cost",
                            field: "itemCost",
                            width: "auto",
                            render: (rowData: MyApprovalsNewListItemViewModel) => <>{rowData.itemCostFormatted}</>,
                        },
                        {
                            title: "Approver",
                            field: "approverRoleName",
                            width: "auto",
                        },
                        {
                            title: "Supplier",
                            field: "supplierName",
                            width: "auto",
                        },
                        {
                            title: "Approval type",
                            field: "approvalTypeName",
                            align: "center",
                            width: "auto",
                        },
                        {
                            title: "Requested by",
                            field: "requestedBy",
                            width: "auto",
                        },
                        {
                            title: "Requested on",
                            field: "requestedOnDate",
                            width: "auto",
                            type: "date",
                            render: (rowData: MyApprovalsNewListItemViewModel) => <>{formatDateTime(rowData.requestedOnDate !== null ? rowData.requestedOnDate.toString() : "")}</>,
                        },
                    ]}
                    components={{
                        Pagination: PatchedPagination,
                    }}
                    data={viewModel.filteredMyApprovalsRequiredViewModels}
                    onRowClick={onNewRowClick}
                    options={{ ...tableOptions, filtering: false, search: false, toolbar: false }}
                    title=""
                />
            </>
        );
    };

    const renderActionedTable = () => {
        const tableOptions = Defaults.GetDarwinTableOptions() as any;

        return (
            <>
                <MaterialTable
                    isLoading={viewModel.IsLoading || ApprovalDelegateUserDataViewModel.Instance.IsLoading}
                    columns={[
                        {
                            title: "What is being amended?",
                            field: "approvalSectionName",
                            width: "auto",
                        },
                        {
                            title: "Ref/Name",
                            field: "refNameFormatted",
                            width: "auto",
                        },
                        {
                            title: "Description",
                            field: "itemDescription",
                            width: "auto",
                        },
                        {
                            title: "Cost",
                            field: "itemCost",
                            width: "auto",
                            render: (rowData: MyApprovalsActionedListItemViewModel) => <>{rowData.itemCostFormatted}</>,
                        },
                        {
                            title: "Approval type",
                            field: "approvalTypeName",
                            align: "center",
                            width: "auto",
                        },
                        {
                            title: "Supplier",
                            field: "supplierName",
                            width: "auto",
                        },
                        {
                            title: "Requested by",
                            field: "requestedBy",
                            width: "auto",
                        },
                        {
                            title: "Requested on",
                            field: "requestedOnDate",
                            width: "auto",
                            type: "date",
                            render: (rowData: MyApprovalsActionedListItemViewModel) => (
                                <>{formatDateTime(rowData.requestedOnDate !== null ? rowData.requestedOnDate.toString() : "")}</>
                            ),
                        },
                    ]}
                    components={{
                        Pagination: PatchedPagination,
                    }}
                    data={viewModel.filteredMyApprovalsActionedViewModels}
                    onRowClick={onActionedRowClick}
                    options={{ ...tableOptions, filtering: false, search: false, toolbar: false }}
                    title=""
                />
            </>
        );
    };

    const renderTable = () => {
        const name = viewModel.tabsViewModel.activeTabItemViewModel?.id ?? "";

        switch (name) {
            case ApprovalListViewModel.TAB_ALL_APPROVALS:
                return renderNewTable();

            case ApprovalListViewModel.TAB_RESOLVED:
                return renderActionedTable();

            case ApprovalListViewModel.TAB_REQUIRED_APPROVALS:
            default:
                return renderRequiredTable();
        }
    };

    const renderApprovalModal = () => {
        switch (true) {
            case viewModel.approvalViewModel instanceof MaterialApprovalViewModel:
                return <MaterialApprovalView viewModel={viewModel.approvalViewModel as MaterialApprovalViewModel} />;

            case viewModel.approvalViewModel instanceof MaterialPriceDetailsApprovalViewModel:
                return <MaterialPriceDetailsApprovalView viewModel={viewModel.approvalViewModel as MaterialPriceDetailsApprovalViewModel} />;

            case viewModel.approvalViewModel instanceof StockAdjustmentApprovalViewModel:
                return <StockAdjustmentApprovalView viewModel={viewModel.approvalViewModel as StockAdjustmentApprovalViewModel} />;

            case viewModel.approvalViewModel instanceof StockTransferApprovalViewModel:
                return <StockTransferApprovalView viewModel={viewModel.approvalViewModel as StockTransferApprovalViewModel} />;

            default:
                return null;
        }
    };

    return useObserver(() => {
        return (
            <DarwinPage>
                <DarwinPageTitle>
                    <Typography variant="h1" color="textPrimary">
                        Approvals - Needs my authority
                    </Typography>
                </DarwinPageTitle>
                <DarwinTableToolbar>
                    <Grid columnGap={30} dc={"1fr 1fr"} rowGap={15} tc={"1fr 1fr"}>
                        <DarwinTableSearchBox>
                            <TableListSearch searchString={viewModel.getSearchString()} setSearchString={setSearchString} />
                        </DarwinTableSearchBox>
                        <div style={{ textAlign: "right" }}>
                            {ApprovalDelegateUserDataViewModel.Instance.canAssignDelegation && (
                                <PrimaryButton
                                    displayName={"Delegate approvals"}
                                    execute={handleDelegateApprovalsClick}
                                    fullWidth={false}
                                    canExecute={
                                        viewModel.hasLoaded &&
                                        !viewModel.IsLoading &&
                                        ApprovalDelegateUserDataViewModel.Instance.hasLoaded &&
                                        !ApprovalDelegateUserDataViewModel.Instance.IsLoading
                                    }
                                />
                            )}
                            {ApprovalDelegateUserDataViewModel.Instance.canDismissDelegatedApprovals && (
                                <DefaultButton
                                    displayName={"Dismiss delegated approvals"}
                                    execute={() => handleDismissDelegateApprovalsClick()}
                                    fullWidth={false}
                                    canExecute={
                                        viewModel.hasLoaded &&
                                        !viewModel.IsLoading &&
                                        ApprovalDelegateUserDataViewModel.Instance.hasLoaded &&
                                        !ApprovalDelegateUserDataViewModel.Instance.IsLoading
                                    }
                                />
                            )}
                        </div>
                    </Grid>
                </DarwinTableToolbar>
                <DarwinTablePageContent>
                    <Tabsv2 viewModel={viewModel.tabsViewModel} />
                    {viewModel.hasLoaded && renderTable()}
                </DarwinTablePageContent>
                <DeleteModal
                    open={showDismissDelegateApprovalsModal}
                    onClose={handleCloseDismissModal}
                    onDelete={handleDismissDelegation}
                    title="Dismiss delegate?"
                    text="Are you sure you want to dismiss your delegated approvals?"
                />

                <DelegateApprovalsModal
                    open={showDelegateApprovalsModal}
                    onClose={handleCloseDelegateModal}
                    onSave={ApprovalDelegateUserDataViewModel.Instance.delegateApprovals}
                    title="Delegate approvals"
                />

                <GenericModal
                    open={ApprovalDelegateUserDataViewModel.Instance.getShowHasDelegateApprovalsModal}
                    onClose={ApprovalDelegateUserDataViewModel.Instance.handleCloseHasDelegateApprovalsModal}
                    onFirstButtonClick={ApprovalDelegateUserDataViewModel.Instance.handleCloseHasDelegateApprovalsModal}
                    onSecondButtonClick={ApprovalDelegateUserDataViewModel.Instance.handleDismissDelegateApprovalsDelegation}
                    firstButtonText="Continue"
                    secondButtonText="Dismiss"
                    title="Delegated approvals"
                    text={ApprovalDelegateUserDataViewModel.Instance.getHasDelegateApprovalsModalText}
                    isLoading={ApprovalDelegateUserDataViewModel.Instance.IsLoading || viewModel.IsLoading}
                    canClose={false}
                />

                <DelegationAddedNotificationModal
                    open={ApprovalDelegateUserDataViewModel.Instance.getShowHasDelegationAddedNotificationModal}
                    onClose={ApprovalDelegateUserDataViewModel.Instance.handleCloseHasDelegationAddedNotificationModal}
                    title="Delegated approvals"
                />

                <DelegationRemovedNotificationModal
                    open={ApprovalDelegateUserDataViewModel.Instance.getShowHasDelegationRemovedNotificationModal}
                    onClose={ApprovalDelegateUserDataViewModel.Instance.handleCloseHasDelegationRemovedNotificationModal}
                    title="Delegated approvals"
                />
                {renderApprovalModal()}
            </DarwinPage>
        );
    });
};
