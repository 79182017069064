import { FieldType, ViewModelBase, isNullOrUndefined } from "@shoothill/core";
import { computed } from "mobx";

import { formatCurrencyFromPounds } from "Utils/Format";
import { CalculatedAdjustedStockModel } from "./CalculatedAdjustedStockModel";

export class CalculatedAdjustedStockViewModel extends ViewModelBase<CalculatedAdjustedStockModel> {
    constructor(model: CalculatedAdjustedStockModel) {
        super(model, false);
    }

    // #region Formatted Labels

    @computed
    public get previousStockUnitsFormatted(): string {
        if (!isNullOrUndefined(this.model.previousUnits)) {
            return `${this.model.previousUnits}`;
        } else {
            return "-";
        }
    }

    @computed
    public get previousStockValueFormatted(): string {
        if (!isNullOrUndefined(this.model.previousValue)) {
            return formatCurrencyFromPounds(this.model.previousValue!);
        } else {
            return "-";
        }
    }

    @computed
    public get adjustmentStockUnitsFormatted(): string {
        if (!isNullOrUndefined(this.model.adjustedUnits)) {
            return `${this.model.adjustedUnits}`;
        } else {
            return "-";
        }
    }

    @computed
    public get adjustmentStockValueFormatted(): string {
        if (!isNullOrUndefined(this.model.adjustedValue)) {
            return formatCurrencyFromPounds(this.model.adjustedValue!);
        } else {
            return "-";
        }
    }

    @computed
    public get newStockUnitsFormatted(): string {
        if (!isNullOrUndefined(this.model.nextUnits)) {
            return `${this.model.nextUnits}`;
        } else {
            return "-";
        }
    }

    @computed
    public get newStockValueFormatted(): string {
        if (!isNullOrUndefined(this.model.nextValue)) {
            return formatCurrencyFromPounds(this.model.nextValue!);
        } else {
            return "-";
        }
    }

    // #endregion Formatted Labels

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<CalculatedAdjustedStockModel>): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boliderplate
}
