import { observable, action, computed } from "mobx";
import { ModelBase } from "@shoothill/core";
import { ApprovalDelegateUserDataDTO } from "./ApprovalDelegateUserDataModel";

export class ApprovalDelegateModel extends ModelBase<ApprovalDelegateModel, ApprovalDelegateUserDataDTO> {
    @observable
    public id: string | null = null;

    @observable
    public delegatedByUserId: string | null = null;

    @observable
    public delegatedToUserId: string | null = null;

    @observable
    public startDate: string | null = null;

    @observable
    public hasDelegatedUserViewedNotification: boolean = false;

    @observable
    public hasDelegationBeenRemoved: boolean = false;

    @observable
    public createdDate: string | null = null;

    @observable
    public createdByUserId: string | null = null;

    @observable
    public rowVersion: string | null = null;

    @observable
    public delegatedByUserName: string | null = null;

    @observable
    public delegatedToUserName: string | null = null;

    @action
    public cleanUp() {
        this.id = null;
        this.delegatedByUserId = null;
        this.delegatedToUserId = null;
        this.startDate = null;
        this.hasDelegatedUserViewedNotification = false;
        this.hasDelegationBeenRemoved = false;
        this.createdDate = null;
        this.createdByUserId = null;
        this.rowVersion = null;
        this.delegatedByUserName = null;
        this.delegatedToUserName = null;
    }

    //fromDto is required but you can leave it blank
    fromDto(model: any): void {}

    fromRelatedDto(model: ApprovalDelegatedBy): void {
        if (model === null || model === undefined) {
            this.cleanUp();
        } else {
            //this just iterates through every key assigning it to the model
            //Should only use if there is a direct mapping between dto and domain model
            //otherwise just map them yourself
            for (let key in model) {
                if (model.hasOwnProperty(key)) {
                    if (this[key] instanceof Date) {
                        this[key] = new Date(model[key]);
                    } else {
                        this[key] = model[key];
                    }
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: ApprovalDelegateModel): void {}

    @computed
    public get validateStartDate(): string {
        return this.startDate === null ? "Please select start date" : "";
    }

    @computed
    public get validateDelegatedToUserId(): string {
        return this.delegatedToUserId === null ? "Please select user" : "";
    }
}

export interface ApprovalDelegateDTO {
    id: string | null;
    delegatedByUserId: string | null;
    delegatedToUserId: string | null;
    startDate: string | null;
    hasDelegatedUserViewedNotification: boolean;
    hasDelegationBeenRemoved: boolean;
    createdDate: string | null;
    createdByUserId: string | null;
    rowVersion: string | null;
}

export interface ApprovalDelegatedBy extends ApprovalDelegateDTO {
    delegatedByUserName: string | null;
    delegatedToUserName: string | null;
}
