import { action, observable, computed } from "mobx";
import { ModelBase } from "@shoothill/core";
import { IncidentDTO } from "../IncidentModel";
import { GenericPhotoListItemDTO, GenericPhotoListItemModel } from "../Supporting/GenericPhotoListItemModel";
import { GenericFreeTextListItemDTO, GenericFreeTextListItemModel } from "../Supporting/GenericFreeTextListItemModel";

export class IncidentFormNearMissModel extends ModelBase<IncidentFormNearMissModel, any> {
    public static readonly DEFAULT_ID = null;
    public static readonly DEFAULT_INCIDENTID: string | null = null;
    public static readonly DEFAULT_CREATED_BY_USER_ID: string | null = null;
    public static readonly DEFAULT_CREATED_DATE: string | null = null;
    public static readonly DEFAULT_IS_DELETED: boolean = false;
    public static readonly DEFAULT_ROW_VERSION: string | null = null;
    public static readonly DEFAULT_LOCATION: string = "";
    public static readonly DEFAULT_WHAT_HAPPENED: string = "";
    public static readonly DEFAULT_HAS_INVESTIGATION_STARTED: boolean = false;
    public static readonly DEFAULT_INVESTIGATION_DATE: Date | null = null;
    public static readonly DEFAULT_INVESTIGATOR_USER_ID: string | null = null;
    public static readonly DEFAULT_WHICH_PART_OF_WORK_PROCESS: string | null = null;
    public static readonly DEFAULT_DESCRIPTION_OF_ENVIRONMENT: string | null = null;
    public static readonly DEFAULT_DESCRIPTION_OF_EQUIPMENT: string | null = null;
    public static readonly DEFAULT_PPE_REQUIRED: string | null = null;
    public static readonly DEFAULT_PPE_WORN: string | null = null;
    public static readonly DEFAULT_DEFECTIVE_WORKPLACE: boolean | null = null;
    public static readonly DEFAULT_LIGHTING: boolean | null = null;
    public static readonly DEFAULT_DESIGN_OR_LAYOUT: boolean | null = null;
    public static readonly DEFAULT_NOISE_OR_DISTRACTION: boolean | null = null;
    public static readonly DEFAULT_HOUSEKEEPING: boolean | null = null;
    public static readonly DEFAULT_WEATHER: boolean | null = null;
    public static readonly DEFAULT_LACK_OF_ROOM: boolean | null = null;
    public static readonly DEFAULT_ACCESS_OR_EGRESS: boolean | null = null;
    public static readonly DEFAULT_FAILURE_TO_FOLLOW_RULES: boolean | null = null;
    public static readonly DEFAULT_LACK_OF_EXPERIENCE: boolean | null = null;
    public static readonly DEFAULT_FAILURE_TO_ADHERE_TO_RISK_ASSESSMENT: boolean | null = null;
    public static readonly DEFAULT_FATIGUE: boolean | null = null;
    public static readonly DEFAULT_INSTRUCTIONS_MISUNDERSTOOD: boolean | null = null;
    public static readonly DEFAULT_UNSAFE_ATTITUDE: boolean | null = null;
    public static readonly DEFAULT_HORSEPLAY: boolean | null = null;
    public static readonly DEFAULT_WORKING_WITHOUT_AUTHORISATION: boolean | null = null;
    public static readonly DEFAULT_ERROR_OF_JUDGEMENT: boolean | null = null;
    public static readonly DEFAULT_UNDUE_HASTE: boolean | null = null;
    public static readonly DEFAULT_LAPSE_OF_CONCENTRATION: boolean | null = null;
    public static readonly DEFAULT_DESIGN: boolean | null = null;
    public static readonly DEFAULT_POORLY_MAINTAINED_OR_DEFECTIVE: boolean | null = null;
    public static readonly DEFAULT_NOT_USED: boolean | null = null;
    public static readonly DEFAULT_WRONG_TYPE_USED: boolean | null = null;
    public static readonly DEFAULT_NOT_PROVIDED_OR_UNAVAILABLE: boolean | null = null;
    public static readonly DEFAULT_NON_COMMUNICATION_OF_RISK: boolean | null = null;
    public static readonly DEFAULT_SUPERVISION: boolean | null = null;
    public static readonly DEFAULT_TRAINING: boolean | null = null;
    public static readonly DEFAULT_SYSTEM_FAILURE: boolean | null = null;
    public static readonly DEFAULT_CONSTRUCTION_OR_DESIGN: boolean | null = null;
    public static readonly DEFAULT_SAFETY_DEVICE: boolean | null = null;
    public static readonly DEFAULT_MECHANICAL_FAILURE: boolean | null = null;
    public static readonly DEFAULT_INSTALLATION: boolean | null = null;
    public static readonly DEFAULT_OPERATION_OR_USE: boolean | null = null;
    public static readonly DEFAULT_THIRD_PARTY: boolean | null = null;
    public static readonly DEFAULT_UNDER_INVESTIGATION: boolean | null = null;
    public static readonly DEFAULT_ACTIONS_TO_RECOVER: string | null = null;
    public static readonly DEFAULT_ACTIONS_TO_PREVENT: string | null = null;
    public static readonly DEFAULT_LAST_UPDATED_BY_USER_ID: string | null = null;
    public static readonly DEFAULT_LAST_UPDATED_DATE: Date | null = null;
    public static readonly DEFAULT_HAS_INVESTIGATION_ENDED: boolean = false;
    public static readonly DEFAULT_INVESTIGATION_END_DATE: Date | null = null;

    // #endregion Constants and Defaults

    // #region Properties

    @observable
    public id: string | null = IncidentFormNearMissModel.DEFAULT_ID;

    @observable
    public incidentId: string | null = IncidentFormNearMissModel.DEFAULT_INCIDENTID;

    @observable
    public createdByUserId: string | null = IncidentFormNearMissModel.DEFAULT_CREATED_BY_USER_ID;

    @observable
    public createdDate: string | null = IncidentFormNearMissModel.DEFAULT_CREATED_DATE;

    @observable
    public isDeleted: boolean = IncidentFormNearMissModel.DEFAULT_IS_DELETED;

    @observable
    public rowVersion: string | null = IncidentFormNearMissModel.DEFAULT_ROW_VERSION;

    @observable
    public location: string = IncidentFormNearMissModel.DEFAULT_LOCATION;

    @observable
    public whatHappened: string = IncidentFormNearMissModel.DEFAULT_WHAT_HAPPENED;

    @observable
    public hasInvestigationStarted: boolean = IncidentFormNearMissModel.DEFAULT_HAS_INVESTIGATION_STARTED;

    @observable
    public investigationDate: Date | null = IncidentFormNearMissModel.DEFAULT_INVESTIGATION_DATE;

    @observable
    public investigatorUserId: string | null = IncidentFormNearMissModel.DEFAULT_INVESTIGATOR_USER_ID;

    @observable
    public whichPartOfWorkProcess: string | null = IncidentFormNearMissModel.DEFAULT_WHICH_PART_OF_WORK_PROCESS;

    @observable
    public descriptionOfEnvironment: string | null = IncidentFormNearMissModel.DEFAULT_DESCRIPTION_OF_ENVIRONMENT;

    @observable
    public descriptionOfEquipment: string | null = IncidentFormNearMissModel.DEFAULT_DESCRIPTION_OF_EQUIPMENT;

    @observable
    public ppeRequired: string | null = IncidentFormNearMissModel.DEFAULT_PPE_REQUIRED;

    @observable
    public ppeWorn: string | null = IncidentFormNearMissModel.DEFAULT_PPE_WORN;

    @observable
    public defectiveWorkplace: boolean | null = IncidentFormNearMissModel.DEFAULT_DEFECTIVE_WORKPLACE;

    @observable
    public lighting: boolean | null = IncidentFormNearMissModel.DEFAULT_LIGHTING;

    @observable
    public designOrLayout: boolean | null = IncidentFormNearMissModel.DEFAULT_DESIGN_OR_LAYOUT;

    @observable
    public noiseOrDistraction: boolean | null = IncidentFormNearMissModel.DEFAULT_NOISE_OR_DISTRACTION;

    @observable
    public housekeeping: boolean | null = IncidentFormNearMissModel.DEFAULT_HOUSEKEEPING;

    @observable
    public weather: boolean | null = IncidentFormNearMissModel.DEFAULT_WEATHER;

    @observable
    public lackOfRoom: boolean | null = IncidentFormNearMissModel.DEFAULT_LACK_OF_ROOM;

    @observable
    public accessOrEgress: boolean | null = IncidentFormNearMissModel.DEFAULT_ACCESS_OR_EGRESS;

    @observable
    public failureToFollowRules: boolean | null = IncidentFormNearMissModel.DEFAULT_FAILURE_TO_FOLLOW_RULES;

    @observable
    public lackOfExperience: boolean | null = IncidentFormNearMissModel.DEFAULT_LACK_OF_EXPERIENCE;

    @observable
    public failureToAdhereToRiskAssessment: boolean | null = IncidentFormNearMissModel.DEFAULT_FAILURE_TO_ADHERE_TO_RISK_ASSESSMENT;

    @observable
    public fatigue: boolean | null = IncidentFormNearMissModel.DEFAULT_FATIGUE;

    @observable
    public instructionsMisunderstood: boolean | null = IncidentFormNearMissModel.DEFAULT_INSTRUCTIONS_MISUNDERSTOOD;

    @observable
    public unsafeAttitude: boolean | null = IncidentFormNearMissModel.DEFAULT_UNSAFE_ATTITUDE;

    @observable
    public horseplay: boolean | null = IncidentFormNearMissModel.DEFAULT_HORSEPLAY;

    @observable
    public workingWithoutAuthorisation: boolean | null = IncidentFormNearMissModel.DEFAULT_WORKING_WITHOUT_AUTHORISATION;

    @observable
    public errorOfJudgement: boolean | null = IncidentFormNearMissModel.DEFAULT_ERROR_OF_JUDGEMENT;

    @observable
    public undueHaste: boolean | null = IncidentFormNearMissModel.DEFAULT_UNDUE_HASTE;

    @observable
    public lapseOfConcentration: boolean | null = IncidentFormNearMissModel.DEFAULT_LAPSE_OF_CONCENTRATION;

    @observable
    public design: boolean | null = IncidentFormNearMissModel.DEFAULT_DESIGN;

    @observable
    public poorlyMaintainedOrDefective: boolean | null = IncidentFormNearMissModel.DEFAULT_POORLY_MAINTAINED_OR_DEFECTIVE;

    @observable
    public notUsed: boolean | null = IncidentFormNearMissModel.DEFAULT_NOT_USED;

    @observable
    public wrongTypeUsed: boolean | null = IncidentFormNearMissModel.DEFAULT_WRONG_TYPE_USED;

    @observable
    public notProvidedOrUnavailable: boolean | null = IncidentFormNearMissModel.DEFAULT_NOT_PROVIDED_OR_UNAVAILABLE;

    @observable
    public nonCommunicationOfRisk: boolean | null = IncidentFormNearMissModel.DEFAULT_NON_COMMUNICATION_OF_RISK;

    @observable
    public supervision: boolean | null = IncidentFormNearMissModel.DEFAULT_SUPERVISION;

    @observable
    public training: boolean | null = IncidentFormNearMissModel.DEFAULT_TRAINING;

    @observable
    public systemFailure: boolean | null = IncidentFormNearMissModel.DEFAULT_SYSTEM_FAILURE;

    @observable
    public constructionOrDesign: boolean | null = IncidentFormNearMissModel.DEFAULT_CONSTRUCTION_OR_DESIGN;

    @observable
    public safetyDevice: boolean | null = IncidentFormNearMissModel.DEFAULT_SAFETY_DEVICE;

    @observable
    public mechanicalFailure: boolean | null = IncidentFormNearMissModel.DEFAULT_MECHANICAL_FAILURE;

    @observable
    public installation: boolean | null = IncidentFormNearMissModel.DEFAULT_INSTALLATION;

    @observable
    public operationOrUse: boolean | null = IncidentFormNearMissModel.DEFAULT_OPERATION_OR_USE;

    @observable
    public thirdParty: boolean | null = IncidentFormNearMissModel.DEFAULT_THIRD_PARTY;

    @observable
    public underInvestigation: boolean | null = IncidentFormNearMissModel.DEFAULT_UNDER_INVESTIGATION;

    @observable
    public actionsToRecover: string | null = IncidentFormNearMissModel.DEFAULT_ACTIONS_TO_RECOVER;

    @observable
    public actionsToPrevent: string | null = IncidentFormNearMissModel.DEFAULT_ACTIONS_TO_PREVENT;

    @observable
    public lastUpdatedByUserId: string | null = IncidentFormNearMissModel.DEFAULT_LAST_UPDATED_BY_USER_ID;

    @observable
    public lastUpdatedDate: Date | null = IncidentFormNearMissModel.DEFAULT_LAST_UPDATED_DATE;

    @observable
    public hasInvestigationEnded: boolean = IncidentFormNearMissModel.DEFAULT_HAS_INVESTIGATION_ENDED;

    @observable
    public investigationEndDate: Date | null = IncidentFormNearMissModel.DEFAULT_INVESTIGATION_END_DATE;

    @observable
    public incidentFormNearMissPhotos: GenericPhotoListItemModel[] = [];

    @observable
    public incidentFormNearMissRootCauses: GenericFreeTextListItemModel[] = [];

    // #endregion Properties

    // #endregion Properties

    @computed
    public get hasId(): boolean {
        return this.id !== null && this.id !== undefined && this.id !== "";
    }

    // #region Actions

    @action
    public reset = () => {
        this.id = IncidentFormNearMissModel.DEFAULT_ID;
        this.incidentId = IncidentFormNearMissModel.DEFAULT_INCIDENTID;
        this.createdByUserId = IncidentFormNearMissModel.DEFAULT_CREATED_BY_USER_ID;
        this.createdDate = IncidentFormNearMissModel.DEFAULT_CREATED_DATE;
        this.isDeleted = IncidentFormNearMissModel.DEFAULT_IS_DELETED;
        this.rowVersion = IncidentFormNearMissModel.DEFAULT_ROW_VERSION;
        this.location = IncidentFormNearMissModel.DEFAULT_LOCATION;
        this.whatHappened = IncidentFormNearMissModel.DEFAULT_WHAT_HAPPENED;
        this.hasInvestigationStarted = IncidentFormNearMissModel.DEFAULT_HAS_INVESTIGATION_STARTED;
        this.investigationDate = IncidentFormNearMissModel.DEFAULT_INVESTIGATION_DATE;
        this.investigatorUserId = IncidentFormNearMissModel.DEFAULT_INVESTIGATOR_USER_ID;
        this.whichPartOfWorkProcess = IncidentFormNearMissModel.DEFAULT_WHICH_PART_OF_WORK_PROCESS;
        this.descriptionOfEnvironment = IncidentFormNearMissModel.DEFAULT_DESCRIPTION_OF_ENVIRONMENT;
        this.descriptionOfEquipment = IncidentFormNearMissModel.DEFAULT_DESCRIPTION_OF_EQUIPMENT;
        this.ppeRequired = IncidentFormNearMissModel.DEFAULT_PPE_REQUIRED;
        this.ppeWorn = IncidentFormNearMissModel.DEFAULT_PPE_WORN;
        this.defectiveWorkplace = IncidentFormNearMissModel.DEFAULT_DEFECTIVE_WORKPLACE;
        this.lighting = IncidentFormNearMissModel.DEFAULT_LIGHTING;
        this.designOrLayout = IncidentFormNearMissModel.DEFAULT_DESIGN_OR_LAYOUT;
        this.noiseOrDistraction = IncidentFormNearMissModel.DEFAULT_NOISE_OR_DISTRACTION;
        this.housekeeping = IncidentFormNearMissModel.DEFAULT_HOUSEKEEPING;
        this.weather = IncidentFormNearMissModel.DEFAULT_WEATHER;
        this.lackOfRoom = IncidentFormNearMissModel.DEFAULT_LACK_OF_ROOM;
        this.accessOrEgress = IncidentFormNearMissModel.DEFAULT_ACCESS_OR_EGRESS;
        this.failureToFollowRules = IncidentFormNearMissModel.DEFAULT_FAILURE_TO_FOLLOW_RULES;
        this.lackOfExperience = IncidentFormNearMissModel.DEFAULT_LACK_OF_EXPERIENCE;
        this.failureToAdhereToRiskAssessment = IncidentFormNearMissModel.DEFAULT_FAILURE_TO_ADHERE_TO_RISK_ASSESSMENT;
        this.fatigue = IncidentFormNearMissModel.DEFAULT_FATIGUE;
        this.instructionsMisunderstood = IncidentFormNearMissModel.DEFAULT_INSTRUCTIONS_MISUNDERSTOOD;
        this.unsafeAttitude = IncidentFormNearMissModel.DEFAULT_UNSAFE_ATTITUDE;
        this.horseplay = IncidentFormNearMissModel.DEFAULT_HORSEPLAY;
        this.workingWithoutAuthorisation = IncidentFormNearMissModel.DEFAULT_WORKING_WITHOUT_AUTHORISATION;
        this.errorOfJudgement = IncidentFormNearMissModel.DEFAULT_ERROR_OF_JUDGEMENT;
        this.undueHaste = IncidentFormNearMissModel.DEFAULT_UNDUE_HASTE;
        this.lapseOfConcentration = IncidentFormNearMissModel.DEFAULT_LAPSE_OF_CONCENTRATION;
        this.design = IncidentFormNearMissModel.DEFAULT_DESIGN;
        this.poorlyMaintainedOrDefective = IncidentFormNearMissModel.DEFAULT_POORLY_MAINTAINED_OR_DEFECTIVE;
        this.notUsed = IncidentFormNearMissModel.DEFAULT_NOT_USED;
        this.wrongTypeUsed = IncidentFormNearMissModel.DEFAULT_WRONG_TYPE_USED;
        this.notProvidedOrUnavailable = IncidentFormNearMissModel.DEFAULT_NOT_PROVIDED_OR_UNAVAILABLE;
        this.nonCommunicationOfRisk = IncidentFormNearMissModel.DEFAULT_NON_COMMUNICATION_OF_RISK;
        this.supervision = IncidentFormNearMissModel.DEFAULT_SUPERVISION;
        this.training = IncidentFormNearMissModel.DEFAULT_TRAINING;
        this.systemFailure = IncidentFormNearMissModel.DEFAULT_SYSTEM_FAILURE;
        this.constructionOrDesign = IncidentFormNearMissModel.DEFAULT_CONSTRUCTION_OR_DESIGN;
        this.safetyDevice = IncidentFormNearMissModel.DEFAULT_SAFETY_DEVICE;
        this.mechanicalFailure = IncidentFormNearMissModel.DEFAULT_MECHANICAL_FAILURE;
        this.installation = IncidentFormNearMissModel.DEFAULT_INSTALLATION;
        this.operationOrUse = IncidentFormNearMissModel.DEFAULT_OPERATION_OR_USE;
        this.thirdParty = IncidentFormNearMissModel.DEFAULT_THIRD_PARTY;
        this.underInvestigation = IncidentFormNearMissModel.DEFAULT_UNDER_INVESTIGATION;
        this.actionsToRecover = IncidentFormNearMissModel.DEFAULT_ACTIONS_TO_RECOVER;
        this.actionsToPrevent = IncidentFormNearMissModel.DEFAULT_ACTIONS_TO_PREVENT;
        this.lastUpdatedByUserId = IncidentFormNearMissModel.DEFAULT_LAST_UPDATED_BY_USER_ID;
        this.lastUpdatedDate = IncidentFormNearMissModel.DEFAULT_LAST_UPDATED_DATE;

        this.incidentFormNearMissPhotos.length = 0;
        this.incidentFormNearMissRootCauses.length = 0;
    };

    @action
    public fromDto(dto: IncidentFormNearMissAndRelatedResponseDTO): void {
        const nearMissDto = dto.incidentFormNearMiss;

        for (let key in nearMissDto) {
            if (nearMissDto.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(nearMissDto[key]);
                } else {
                    this[key] = nearMissDto[key];
                }
            }
        }

        this.incidentFormNearMissPhotos.length = 0;
        this.incidentFormNearMissRootCauses.length = 0;

        // Manually process the child array otherwise we will end up with an array of dtos being stored in the array of models.
        let processedPhotos: GenericPhotoListItemModel[] = [];

        for (const photo of dto.incidentFormNearMissPhotos) {
            const photoToAdd = new GenericPhotoListItemModel();
            photoToAdd.fromDto(photo);
            processedPhotos.push(photoToAdd);
        }

        this.incidentFormNearMissPhotos = [...this.incidentFormNearMissPhotos, ...processedPhotos];

        let processedCauses: GenericFreeTextListItemModel[] = [];

        for (const cause of dto.incidentFormNearMissRootCauses) {
            const causeToAdd = new GenericFreeTextListItemModel();
            causeToAdd.fromDto(cause);
            processedCauses.push(causeToAdd);
        }

        this.incidentFormNearMissRootCauses = [...this.incidentFormNearMissRootCauses, ...processedCauses];
    }

    public toDto() {
        const nearMissModel: IncidentFormNearMissDTO = {
            id: this.id,
            incidentId: this.incidentId,
            createdByUserId: this.createdByUserId,
            createdDate: this.createdDate,
            isDeleted: this.isDeleted,
            rowVersion: this.rowVersion,
            location: this.location,
            whatHappened: this.whatHappened,
            hasInvestigationStarted: this.hasInvestigationStarted,
            investigationDate: this.investigationDate,
            investigatorUserId: this.investigatorUserId,
            whichPartOfWorkProcess: this.whichPartOfWorkProcess,
            descriptionOfEnvironment: this.descriptionOfEnvironment,
            descriptionOfEquipment: this.descriptionOfEquipment,
            ppeRequired: this.ppeRequired,
            ppeWorn: this.ppeWorn,
            defectiveWorkplace: this.defectiveWorkplace,
            lighting: this.lighting,
            designOrLayout: this.designOrLayout,
            noiseOrDistraction: this.noiseOrDistraction,
            housekeeping: this.housekeeping,
            weather: this.weather,
            lackOfRoom: this.lackOfRoom,
            accessOrEgress: this.accessOrEgress,
            failureToFollowRules: this.failureToFollowRules,
            lackOfExperience: this.lackOfExperience,
            failureToAdhereToRiskAssessment: this.failureToAdhereToRiskAssessment,
            fatigue: this.fatigue,
            instructionsMisunderstood: this.instructionsMisunderstood,
            unsafeAttitude: this.unsafeAttitude,
            horseplay: this.horseplay,
            workingWithoutAuthorisation: this.workingWithoutAuthorisation,
            errorOfJudgement: this.errorOfJudgement,
            undueHaste: this.undueHaste,
            lapseOfConcentration: this.lapseOfConcentration,
            design: this.design,
            poorlyMaintainedOrDefective: this.poorlyMaintainedOrDefective,
            notUsed: this.notUsed,
            wrongTypeUsed: this.wrongTypeUsed,
            notProvidedOrUnavailable: this.notProvidedOrUnavailable,
            nonCommunicationOfRisk: this.nonCommunicationOfRisk,
            supervision: this.supervision,
            training: this.training,
            systemFailure: this.systemFailure,
            constructionOrDesign: this.constructionOrDesign,
            safetyDevice: this.safetyDevice,
            mechanicalFailure: this.mechanicalFailure,
            installation: this.installation,
            operationOrUse: this.operationOrUse,
            thirdParty: this.thirdParty,
            underInvestigation: this.underInvestigation,
            actionsToRecover: this.actionsToRecover,
            actionsToPrevent: this.actionsToPrevent,
            lastUpdatedByUserId: this.lastUpdatedByUserId,
            lastUpdatedDate: this.lastUpdatedDate,
            hasInvestigationEnded: this.hasInvestigationEnded,
            investigationEndDate: this.investigationEndDate,
        };

        return nearMissModel;
    }

    // #endregion Actions

    // #region Custom Validation

    @computed
    public get validateLocation(): string {
        return this.location === IncidentFormNearMissModel.DEFAULT_LOCATION || this.location?.trim() === "" ? "Please provide a location" : "";
    }

    @computed
    public get validateWhatHappened(): string {
        return this.whatHappened === IncidentFormNearMissModel.DEFAULT_WHAT_HAPPENED || this.whatHappened?.trim() === "" ? "Please provide a description of what happened" : "";
    }

    @computed
    public get validateInvestigatorUserId(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.investigatorUserId === IncidentFormNearMissModel.DEFAULT_INVESTIGATOR_USER_ID ? "Please provide an investigator" : "";
    }

    @computed
    public get validateWhichPartOfWorkProcess(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.whichPartOfWorkProcess === IncidentFormNearMissModel.DEFAULT_WHICH_PART_OF_WORK_PROCESS ? "Please provide which part of the work process was involved" : "";
    }

    @computed
    public get validateDescriptionOfEnvironment(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.descriptionOfEnvironment === IncidentFormNearMissModel.DEFAULT_DESCRIPTION_OF_ENVIRONMENT ? "Please provide a description of the environment" : "";
    }

    @computed
    public get validateDescriptionOfEquipment(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.descriptionOfEquipment === IncidentFormNearMissModel.DEFAULT_DESCRIPTION_OF_EQUIPMENT ? "Please provide a description of the equipment involved" : "";
    }

    @computed
    public get validatePpeRequired(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.ppeRequired === IncidentFormNearMissModel.DEFAULT_PPE_REQUIRED ? "Please indicate whether PPE was required" : "";
    }

    @computed
    public get validatePpeWorn(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.ppeWorn === IncidentFormNearMissModel.DEFAULT_PPE_WORN ? "Please indicate whether PPE was worn" : "";
    }

    @computed
    public get validateDefectiveWorkplace(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.defectiveWorkplace === IncidentFormNearMissModel.DEFAULT_DEFECTIVE_WORKPLACE ? "Please indicate whether there were any defects in the workplace" : "";
    }

    @computed
    public get validateLighting(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.lighting === IncidentFormNearMissModel.DEFAULT_LIGHTING ? "Please indicate whether lighting was a factor" : "";
    }

    @computed
    public get validateDesignOrLayout(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.designOrLayout === IncidentFormNearMissModel.DEFAULT_DESIGN_OR_LAYOUT ? "Please indicate whether design or layout was a factor" : "";
    }

    @computed
    public get validateNoiseOrDistraction(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.noiseOrDistraction === IncidentFormNearMissModel.DEFAULT_NOISE_OR_DISTRACTION ? "Please indicate whether noise or distraction was a factor" : "";
    }

    @computed
    public get validateHousekeeping(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.housekeeping === IncidentFormNearMissModel.DEFAULT_HOUSEKEEPING ? "Please indicate whether housekeeping was a factor" : "";
    }

    @computed
    public get validateWeather(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.weather === IncidentFormNearMissModel.DEFAULT_WEATHER ? "Please indicate whether weather was a factor" : "";
    }

    @computed
    public get validateLackOfRoom(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.lackOfRoom === IncidentFormNearMissModel.DEFAULT_LACK_OF_ROOM ? "Please indicate whether there was a lack of room" : "";
    }

    @computed
    public get validateAccessOrEgress(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.accessOrEgress === IncidentFormNearMissModel.DEFAULT_ACCESS_OR_EGRESS ? "Please indicate whether access or egress was a factor" : "";
    }

    @computed
    public get validateFailureToFollowRules(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.failureToFollowRules === IncidentFormNearMissModel.DEFAULT_FAILURE_TO_FOLLOW_RULES ? "Please indicate whether there was a failure to follow rules" : "";
    }

    @computed
    public get validateLackOfExperience(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.lackOfExperience === IncidentFormNearMissModel.DEFAULT_LACK_OF_EXPERIENCE ? "Please indicate whether lack of experience was a factor" : "";
    }

    @computed
    public get validateFailureToAdhereToRiskAssessment(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.failureToAdhereToRiskAssessment === IncidentFormNearMissModel.DEFAULT_FAILURE_TO_ADHERE_TO_RISK_ASSESSMENT
            ? "Please indicate whether there was a failure to adhere to risk assessment"
            : "";
    }

    @computed
    public get validateFatigue(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.fatigue === IncidentFormNearMissModel.DEFAULT_FATIGUE ? "Please indicate whether fatigue was a factor" : "";
    }

    @computed
    public get validateInstructionsMisunderstood(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.instructionsMisunderstood === IncidentFormNearMissModel.DEFAULT_INSTRUCTIONS_MISUNDERSTOOD ? "Please indicate whether instructions were misunderstood" : "";
    }

    @computed
    public get validateUnsafeAttitude(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.unsafeAttitude === IncidentFormNearMissModel.DEFAULT_UNSAFE_ATTITUDE ? "Please indicate whether an unsafe attitude was a factor" : "";
    }

    @computed
    public get validateHorseplay(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.horseplay === IncidentFormNearMissModel.DEFAULT_HORSEPLAY ? "Please indicate whether horseplay was a factor" : "";
    }

    @computed
    public get validateWorkingWithoutAuthorisation(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.workingWithoutAuthorisation === IncidentFormNearMissModel.DEFAULT_WORKING_WITHOUT_AUTHORISATION
            ? "Please indicate whether working without authorisation was a factor"
            : "";
    }

    @computed
    public get validateErrorOfJudgement(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.errorOfJudgement === IncidentFormNearMissModel.DEFAULT_ERROR_OF_JUDGEMENT ? "Please indicate whether an error of judgement was a factor" : "";
    }

    @computed
    public get validateUndueHaste(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.undueHaste === IncidentFormNearMissModel.DEFAULT_UNDUE_HASTE ? "Please indicate whether undue haste was a factor" : "";
    }

    @computed
    public get validateLapseOfConcentration(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.lapseOfConcentration === IncidentFormNearMissModel.DEFAULT_LAPSE_OF_CONCENTRATION ? "Please indicate whether a lapse of concentration was a factor" : "";
    }

    @computed
    public get validateDesign(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.design === IncidentFormNearMissModel.DEFAULT_DESIGN ? "Please indicate whether a design issue was a factor" : "";
    }

    @computed
    public get validatePoorlyMaintainedOrDefective(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.poorlyMaintainedOrDefective === IncidentFormNearMissModel.DEFAULT_POORLY_MAINTAINED_OR_DEFECTIVE
            ? "Please indicate whether poor maintenance or defective equipment was a factor"
            : "";
    }

    @computed
    public get validateNotUsed(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.notUsed === IncidentFormNearMissModel.DEFAULT_NOT_USED ? "Please indicate whether a required item was not used" : "";
    }

    @computed
    public get validateWrongTypeUsed(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.wrongTypeUsed === IncidentFormNearMissModel.DEFAULT_WRONG_TYPE_USED ? "Please indicate whether the wrong type of equipment was used" : "";
    }

    @computed
    public get validateNotProvidedOrUnavailable(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.notProvidedOrUnavailable === IncidentFormNearMissModel.DEFAULT_NOT_PROVIDED_OR_UNAVAILABLE
            ? "Please indicate whether a required item was not provided or unavailable"
            : "";
    }

    @computed
    public get validateNonCommunicationOfRisk(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.nonCommunicationOfRisk === IncidentFormNearMissModel.DEFAULT_NON_COMMUNICATION_OF_RISK ? "Please indicate whether there was a failure to communicate risk" : "";
    }

    @computed
    public get validateSupervision(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.supervision === IncidentFormNearMissModel.DEFAULT_SUPERVISION ? "Please indicate whether there was a failure in supervision" : "";
    }

    @computed
    public get validateTraining(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.training === IncidentFormNearMissModel.DEFAULT_TRAINING ? "Please indicate whether there was a failure in training" : "";
    }

    @computed
    public get validateSystemFailure(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.systemFailure === IncidentFormNearMissModel.DEFAULT_SYSTEM_FAILURE ? "Please indicate whether a system failure was a factor" : "";
    }

    @computed
    public get validateConstructionOrDesign(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.constructionOrDesign === IncidentFormNearMissModel.DEFAULT_CONSTRUCTION_OR_DESIGN ? "Please indicate whether a construction or design issue was a factor" : "";
    }

    @computed
    public get validateSafetyDevice(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.safetyDevice === IncidentFormNearMissModel.DEFAULT_SAFETY_DEVICE ? "Please indicate whether a safety device was missing or not functioning" : "";
    }

    @computed
    public get validateMechanicalFailure(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.mechanicalFailure === IncidentFormNearMissModel.DEFAULT_MECHANICAL_FAILURE ? "Please indicate whether a mechanical failure was a factor" : "";
    }

    @computed
    public get validateInstallation(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.installation === IncidentFormNearMissModel.DEFAULT_INSTALLATION ? "Please indicate whether there was a problem during installation" : "";
    }

    @computed
    public get validateOperationOrUse(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.operationOrUse === IncidentFormNearMissModel.DEFAULT_OPERATION_OR_USE ? "Please indicate whether there was a problem during operation or use" : "";
    }

    @computed
    public get validateThirdParty(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.thirdParty === IncidentFormNearMissModel.DEFAULT_THIRD_PARTY ? "Please indicate whether a third party was involved" : "";
    }

    @computed
    public get validateUnderInvestigation(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.underInvestigation === IncidentFormNearMissModel.DEFAULT_UNDER_INVESTIGATION ? "Please indicate whether the incident is under investigation" : "";
    }

    @computed
    public get validateActionsToRecover(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.actionsToRecover === IncidentFormNearMissModel.DEFAULT_ACTIONS_TO_RECOVER || this.actionsToRecover?.trim() === "" ? "Please provide actions to recover" : "";
    }

    @computed
    public get validateActionsToPrevent(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.actionsToPrevent === IncidentFormNearMissModel.DEFAULT_ACTIONS_TO_PREVENT || this.actionsToPrevent?.trim() === "" ? "Please provide actions to prevent" : "";
    }

    // #endregion Custom Validation
}

export interface IncidentFormNearMissDTO {
    id: string | null;
    incidentId: string | null;
    createdByUserId: string | null;
    createdDate: string | null;
    isDeleted: boolean;
    rowVersion: string | null;
    location: string | null;
    whatHappened: string | null;
    hasInvestigationStarted: boolean;
    investigationDate: Date | null;
    investigatorUserId: string | null;
    whichPartOfWorkProcess: string | null;
    descriptionOfEnvironment: string | null;
    descriptionOfEquipment: string | null;
    ppeRequired: string | null;
    ppeWorn: string | null;
    defectiveWorkplace: boolean | null;
    lighting: boolean | null;
    designOrLayout: boolean | null;
    noiseOrDistraction: boolean | null;
    housekeeping: boolean | null;
    weather: boolean | null;
    lackOfRoom: boolean | null;
    accessOrEgress: boolean | null;
    failureToFollowRules: boolean | null;
    lackOfExperience: boolean | null;
    failureToAdhereToRiskAssessment: boolean | null;
    fatigue: boolean | null;
    instructionsMisunderstood: boolean | null;
    unsafeAttitude: boolean | null;
    horseplay: boolean | null;
    workingWithoutAuthorisation: boolean | null;
    errorOfJudgement: boolean | null;
    undueHaste: boolean | null;
    lapseOfConcentration: boolean | null;
    design: boolean | null;
    poorlyMaintainedOrDefective: boolean | null;
    notUsed: boolean | null;
    wrongTypeUsed: boolean | null;
    notProvidedOrUnavailable: boolean | null;
    nonCommunicationOfRisk: boolean | null;
    supervision: boolean | null;
    training: boolean | null;
    systemFailure: boolean | null;
    constructionOrDesign: boolean | null;
    safetyDevice: boolean | null;
    mechanicalFailure: boolean | null;
    installation: boolean | null;
    operationOrUse: boolean | null;
    thirdParty: boolean | null;
    underInvestigation: boolean | null;
    actionsToRecover: string | null;
    actionsToPrevent: string | null;
    lastUpdatedByUserId: string | null;
    lastUpdatedDate: Date | null;
    hasInvestigationEnded: boolean;
    investigationEndDate: Date | null;
}

// export interface IncidentFormNearMissUpsertAndRelatedResponseDTO {
//     incidentFormNearMiss: IncidentFormNearMissDTO;
// }

export interface IncidentFormNearMissRelatedResponseDTO {
    incident: IncidentDTO;
    incidentProjectDetails: IncidentFormNearMissProjectDetailsDTO;
}

export interface IncidentFormNearMissAndRelatedResponseDTO extends IncidentFormNearMissRelatedResponseDTO {
    incidentFormNearMiss: IncidentFormNearMissDTO;
    incidentUsers: IncidentUserDTO[];
    incidentFormNearMissPhotos: GenericPhotoListItemDTO[];
    incidentFormNearMissRootCauses: GenericFreeTextListItemDTO[];
}

export interface IncidentFormNearMissProjectDetailsDTO {
    projectReference: string;
    projectName: string;
}

export interface IncidentUserDTO {
    id: string;
    displayName: string;
}

export interface IncidentFormNearMissPhotoDTO {
    id: string | null;
    incidentFormNearMissId: string | null;
    fileName: string | null;
    photoURL: string | null;
    isDeleted: boolean;
    createdDate: string | null;
    createdByUserId: string | null;
}

export interface IncidentFormNearMissRootCauseDTO {
    id: string | null;
    incidentFormNearMissId: string | null;
    name: string | null;
    isDeleted: boolean;
    createdDate: string | null;
    createdByUserId: string | null;
}

export interface UpsertIncidentFormNearMissAndRelatedRequestDTO {
    incident: IncidentDTO;
    incidentFormNearMiss: IncidentFormNearMissDTO;
    incidentFormNearMissPhotos: GenericPhotoListItemDTO[];
    incidentFormNearMissRootCauses: GenericFreeTextListItemDTO[];
}
