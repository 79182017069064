import React, { useEffect } from "react";
import { useObserver } from "@shoothill/core";
import { DarwinInput } from "Globals/Styles/Control/DarwinInput";
import { FilterRow, FilterWrapper } from "./PurchaseOrderList.styles";
import { MultiSelectView } from "Components/Select/MultiSelect";
import { ProjectCellHelpers } from "Globals/Models/Domain/ProjectCellEnum";
import { PrimaryButton } from "Components/Buttons/Buttons";
import { RejectedOrderListViewModel } from "./RejectedOrderListViewModel";
import { RejectedOrderListFilterViewModel } from "./RejectedOrderListFilterViewModel";

interface IRejectedOrderListFilterViewProps {
    rejectedOrderListViewModel: RejectedOrderListViewModel;
    filtersViewModel: RejectedOrderListFilterViewModel;
}

export const RejectedOrderListFilterView: React.FunctionComponent<IRejectedOrderListFilterViewProps> = (props: IRejectedOrderListFilterViewProps) => {
    useEffect(() => {
        // eslint-disable-next-line prettier/prettier
        return () => {};
    }, []);

    const renderPage = () => {
        return (
            <>
                <FilterWrapper style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                    <FilterRow>
                        <MultiSelectView
                            style={{ maxWidth: "200px", marginRight: "20px" }}
                            display="Category"
                            fieldName="categories"
                            values={props.filtersViewModel.model.categories}
                            getOptions={props.filtersViewModel.getCategoryOptions}
                            handleChange={props.rejectedOrderListViewModel.handleCategoryChange}
                        />

                        <MultiSelectView
                            style={{ maxWidth: "200px", marginRight: "20px" }}
                            display="Subcategory"
                            fieldName="subCategories"
                            values={props.filtersViewModel.model.subCategories}
                            getOptions={props.filtersViewModel.getSubCategoryOptions}
                            handleChange={props.rejectedOrderListViewModel.handleSubCategoryChange}
                        />

                        <MultiSelectView
                            style={{ maxWidth: "200px", marginRight: "20px" }}
                            display="Status"
                            fieldName="statuses"
                            values={props.filtersViewModel.model.statuses}
                            getOptions={props.filtersViewModel.getStatusOptions}
                            handleChange={props.rejectedOrderListViewModel.handleStatusChange}
                        />
                    </FilterRow>
                    <FilterRow>
                        <MultiSelectView
                            style={{ maxWidth: "200px", marginRight: "20px" }}
                            display="Cell"
                            fieldName="cells"
                            values={props.filtersViewModel.model.cells}
                            getOptions={ProjectCellHelpers.getAllKeyValueSelectOptions}
                            handleChange={props.rejectedOrderListViewModel.handleCellChange}
                        />

                        <MultiSelectView
                            style={{ maxWidth: "200px", marginRight: "20px" }}
                            display="Order Type"
                            fieldName="orderTypes"
                            values={props.filtersViewModel.model.orderTypes}
                            getOptions={props.filtersViewModel.getOrderTypeOptions}
                            handleChange={props.rejectedOrderListViewModel.handleOrderTypeChange}
                        />
                        <MultiSelectView
                            style={{ maxWidth: "200px", marginRight: "20px" }}
                            display="Raised by"
                            fieldName="raisedBy"
                            values={props.filtersViewModel.model.raisedByIds}
                            getOptions={props.filtersViewModel.getRaisedByOptions}
                            handleChange={props.rejectedOrderListViewModel.handleRaisedByChange}
                        />
                        <MultiSelectView
                            style={{ maxWidth: "200px", marginRight: "20px" }}
                            display="Project"
                            fieldName="projects"
                            values={props.filtersViewModel.model.projects}
                            getOptions={props.filtersViewModel.getProjectOptions}
                            handleChange={props.rejectedOrderListViewModel.handleProjectChange}
                        />
                        <MultiSelectView
                            style={{ maxWidth: "200px", marginRight: "20px" }}
                            display="Supplier"
                            fieldName="supplierIds"
                            values={props.filtersViewModel.model.supplierIds}
                            getOptions={props.filtersViewModel.getSupplierOptions}
                            handleChange={props.rejectedOrderListViewModel.handleSupplierChange}
                        />
                    </FilterRow>
                    <FilterRow>
                        <DarwinInput
                            type="text"
                            label="Search"
                            validateOnBlur={true}
                            viewModel={props.filtersViewModel}
                            fieldName="searchText"
                            shrink={true}
                            maxLength={128}
                            onChange={(e: any) => props.rejectedOrderListViewModel.handleSearchChange(e.target.value)}
                        />
                        <div className="filter-button-container">
                            <PrimaryButton
                                displayName={"Clear"}
                                execute={props.rejectedOrderListViewModel.clear}
                                fullWidth={false}
                                canExecute={!props.rejectedOrderListViewModel.IsLoading}
                            />
                        </div>
                    </FilterRow>
                </FilterWrapper>
            </>
        );
    };

    return useObserver(() => renderPage());
};
