import { Cell, Grid } from "@shoothill/core";
import { observer } from "mobx-react-lite";

import { DarwinSelect } from "Components/AutoComplete/DarwinSelect";
import { DarwinDateSelect } from "Components/DateSelect/DarwinDateSelect";
import { DarwinInput } from "Globals/Styles/Control/DarwinInput";
import { SolidDivider } from "../Dividers";
import { PurchaseOrderModel } from "../../PurchaseOrderModel";
import { PurchaseOrderViewModel } from "../../PurchaseOrderViewModel";

interface IProps {
    viewModel: PurchaseOrderViewModel;
}

export const StockSectionView: React.FC<IProps> = observer((props) => {
    // #region Code Behind

    const COLUMNS8 = "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr";
    const viewModel = props.viewModel;

    const setIsStock = async (val: boolean) => {
        await viewModel.setIsStock(val);
        viewModel.deleteAllGroupsAndItems();
    };

    // #endregion Code Behind

    return (
        <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
            {/* Group of 8 columns */}
            <Cell ts={2} ds={2}>
                <DarwinSelect
                    displayName="Is this a stock requisition?:*"
                    execute={(val: any) => setIsStock(val.id)}
                    fullWidth={true}
                    getOptionLabel={(option: any) => option.displayName || ""}
                    onBlur={() => viewModel.isFieldValid("isStock")}
                    options={viewModel.isStockOptions}
                    placeholder="Please select"
                    validationMessage={viewModel.getError("isStock")}
                    value={viewModel.isStockOption}
                    canExecute={!viewModel.isFormDisabled && !viewModel.isExistingRequest}
                />
            </Cell>

            <Cell ts={2} ds={2}>
                <DarwinDateSelect
                    displayName="Date required:*"
                    execute={(value: string | null) => viewModel.setValue("dateRequired", value)}
                    onBlur={() => viewModel.isFieldValid("dateRequired")}
                    placeholder="Please select"
                    validationMessage={viewModel.getError("dateRequired")}
                    value={viewModel.getValue("dateRequired")}
                    canExecute={viewModel.canExecuteDateRequiredField}
                />
            </Cell>

            <Cell display={{ xs: "none", md: "block" }} ts={4} ds={4} />

            {/* Group of 8 columns */}
            <Cell ts={6} ds={6}>
                <DarwinInput<PurchaseOrderModel>
                    type="text"
                    label="Purchase order description:*"
                    placeholder="Description"
                    validateOnBlur={true}
                    viewModel={viewModel}
                    fieldName="description"
                    shrink={true}
                    editMode={viewModel.canExecuteAmendingField}
                />
            </Cell>

            <Cell display={{ xs: "none", md: "block" }} ts={2} ds={2} />

            {/* Group of 8 columns */}
            <Cell ts={8} ds={8}>
                <SolidDivider gutterBottom />
            </Cell>
        </Grid>
    );
});
