import * as React from "react";
import * as Mui from "@material-ui/core";

import { InductionPhotoEvidenceModalDialogPaper } from "./InductionPhotoEvidenceModal.styles";
import { BaseModal } from "Components/Modal/BaseModal";

interface IProps {
    open?: boolean;
    onDownload(): void;
    onClose(): void;
    title: string;
}

export const InductionPhotoEvidenceModal: React.FC<IProps> = ({ open, onDownload, onClose, title, children }) => (
    <BaseModal
        open={open}
        onClose={onClose}
        title={title}
        PaperComponent={InductionPhotoEvidenceModalDialogPaper}
        actions={
            <>
                <Mui.Button onClick={onDownload} color="primary" autoFocus variant="contained">
                    Download
                </Mui.Button>

                <Mui.Button onClick={onClose} color="secondary" autoFocus variant="contained">
                    Close
                </Mui.Button>
            </>
        }
    >
        {children}
    </BaseModal>
);
