import { debounce } from "@material-ui/core";
import { ApiResult, FieldType, isNullOrUndefined, ViewModelBase } from "@shoothill/core";
import { action, computed, observable, IObservableArray } from "mobx";
import { AppUrls } from "AppUrls";
import { InductionListModel } from "./InductionListModel";
import { InductionListItemsAndRelatedDTO } from "./InductionListItemsAndRelatedDTO";
import { InductionStatusTypeModel } from "./InductionStatusTypeModel";
import { InductionListItemViewModel } from "./InductionListItemViewModel";
import { InductionListFilterViewModel } from "./InductionListFilterViewModel";
import { InductionListFilterParamsModelDTO } from "./InductionListFilterParamsModel";

export class InductionListViewModel extends ViewModelBase<InductionListModel> {
    private static _instance: InductionListViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }
    constructor() {
        super(new InductionListModel(), false);
        this.setDecorators(InductionListModel);
    }

    private readonly DEBOUNCE_VALUE_MS = 200;

    @observable
    public workingId = "";

    @observable
    public searchString: string = "";

    @observable
    public filterSearchString: string = "";

    public getSearchString = () => {
        return computed(() => this.searchString);
    };

    @action
    public setSearchString = (value: string) => {
        this.searchString = value;
        this.setFilterSearchString(value);
    };

    private setFilterSearchString = debounce(
        action((value: string) => {
            this.filterSearchString = value;
        }),
        this.DEBOUNCE_VALUE_MS,
    );

    @observable
    public inductionIdToDelete: string | null = null;

    @action
    public setInductionIdToDelete = (val: string | null) => {
        this.inductionIdToDelete = val;
    };

    @computed
    public get getInductionToDeleteName(): string {
        const inductionToDelete = this.filteredInductionViewModels.find((i) => i.id === this.inductionIdToDelete);
        return inductionToDelete ? inductionToDelete.model.firstName + " " + inductionToDelete.model.lastName : "unknown";
    }

    @observable
    public errorMessage: string = "";

    @computed
    public get filteredInductionViewModels(): InductionListItemViewModel[] {
        return this.InductionViewModels.filter((vm) => vm.matchesFilter(this.filterSearchString));
    }

    //@observable
    //private InductionViewModels: InductionListItemViewModel[] = [];
    public InductionViewModels: IObservableArray<InductionListItemViewModel> = observable<InductionListItemViewModel>([]);

    @action
    private populateListItemViewModels = (dto: InductionListItemsAndRelatedDTO) => {
        const InductionViewModels2: InductionListItemViewModel[] = [];
        for (const InductionListItem of dto.inductionListItems) {
            const itemModel = new InductionListModel(InductionListItem);
            const statusTypeModel = new InductionStatusTypeModel(dto.inductionStatus.find((ist) => ist.id === InductionListItem.inductionStatusId));
            InductionViewModels2.push(new InductionListItemViewModel(itemModel, statusTypeModel));
        }
        this.InductionViewModels.replace(InductionViewModels2);
    };

    // Api GetAll Record

    public apiGetAllInvoices = async (): Promise<ApiResult<InductionListItemsAndRelatedDTO>> => {
        this.setIsLoading(true);
        let paramsViewModel = InductionListFilterViewModel.Instance;
        let params = paramsViewModel.model.toDto();

        const apiResult = await this.Post<InductionListItemsAndRelatedDTO>(AppUrls.Server.Induction.GetInductionItemsRelated, params);
        if (apiResult) {
            if (apiResult.wasSuccessful) {
                this.populateListItemViewModels(apiResult.payload);
            } else {
                console.log(apiResult.errors);
            }
        }
        this.setIsLoading(false);
        return apiResult;
    };

    // End Api GetAll Record

    public deleteInduction = async (inductionId: string, ieId: string): Promise<void> => {
        const inductionToDelete = this.InductionViewModels.find((v) => v.model.id === inductionId);

        if (inductionToDelete) {
            let apiResult = await this.Post<any>(AppUrls.Server.Induction.Delete, { id: inductionId });
            if (apiResult) {
                if (apiResult.wasSuccessful) {
                    this.apiGetAllInvoices();
                } else {
                    console.log(apiResult.errors);
                }
            }
        }
    };

    // end Invoice By Date

    public async isFieldValid(fieldName: keyof FieldType<InductionListModel>): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}

export interface RequestModel {
    Id: string;
}

export class InductionListAndRelatedRequest {
    id: string = "";
    startDatefilter?: Date | null = null;
    endDateFilter?: Date | null = null;
    filterParams: InductionListFilterParamsModelDTO | null = null;
}
