import { ViewModelBase, isEmptyOrWhitespace } from "@shoothill/core";
import { action, computed, observable, runInAction } from "mobx";

import { AppUrls } from "AppUrls";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { StockHistoryItemViewModel } from "../StockHistory/StockHistoryItemViewModel";
import debounce from "lodash-es/debounce";

export class StockHistoryGlobalViewModel extends ViewModelBase<any> {
    private readonly DEBOUNCE_VALUE_MS = 200;
    public server: ServerViewModel = new ServerViewModel();

    private stockHistoryItems = observable<StockHistoryItemViewModel>([]);

    constructor() {
        super({});

        // Load stock history.
        this.apiLoadStockHistoryAsync();
    }

    // #region Filtering

    @observable
    public searchString: string = "";

    @observable
    public filterSearchString: string = "";

    public getSearchString = () => {
        return computed(() => this.searchString);
    };

    @action
    public setSearchString = (value: string) => {
        this.searchString = value;
        this.setFilterSearchString(value);
    };

    private setFilterSearchString = debounce(
        action((value: string) => {
            this.filterSearchString = value;
        }),
        this.DEBOUNCE_VALUE_MS,
    );

    @computed
    public get filteredStockHistoryItems(): StockHistoryItemViewModel[] {
        return this.stockHistoryItems.slice().filter((m) => this.matchesFilter(m));
    }

    public matchesFilter = (item: StockHistoryItemViewModel): boolean => {
        if (isEmptyOrWhitespace(this.filterSearchString)) {
            return true;
        }

        const filterStringUpperCase = this.filterSearchString.toUpperCase();

        return (
            item.skuNumber.toUpperCase().includes(filterStringUpperCase) ||
            item.description.toUpperCase().includes(filterStringUpperCase) ||
            item.stockTransactionFrom.toUpperCase().includes(filterStringUpperCase) ||
            item.stockTransactionTo.toUpperCase().includes(filterStringUpperCase) ||
            item.stockTransactionType.toUpperCase().includes(filterStringUpperCase)
        );
    };

    // #endregion Filtering

    // #region Actions

    @action
    public navigateToPurchaseOrder = (value: string) => {
        this.history.push(`${AppUrls.Client.PurchaseOrder.List}/${value}/view`);
    };

    // #endregion Actions

    // #region Api Actions

    @action
    public apiLoadStockHistoryAsync = async (): Promise<void> => {
        await this.server.query<any>(
            () => this.Get(AppUrls.Server.Stock.GetStockHistoryViews),
            (result) => {
                runInAction(() => {
                    this.stockHistoryItems.replace(
                        result.map((dto: any) => {
                            const stock = new StockHistoryItemViewModel();

                            stock.note = dto.note;
                            stock.pricePerUnit = dto.pricePerUnit;
                            stock.purchaseOrderId = dto.purchaseOrderId;
                            stock.purchaseOrderNumber = dto.purchaseOrderNumber;
                            stock.stockQuantity = dto.stockQuantity;
                            stock.stockTransactionType = dto.stockTransactionType;
                            stock.stockTransactionRequestNumber = dto.stockTransactionRequestNumber;
                            stock.stockValue = dto.stockValue;
                            stock.skuNumber = dto.skuNumber;
                            stock.unitChange = dto.unitChange;
                            stock.createdByUser = dto.createdByUser;
                            stock.updatedDate = dto.updatedDate;
                            stock.stockTransactionFrom = dto.stockTransactionFrom;
                            stock.stockTransactionTo = dto.stockTransactionTo;
                            stock.description = dto.description;

                            return stock;
                        }),
                    );
                });
            },
            "Error whilst loading the stock data",
        );

        if (this.server.HaveValidationMessage) {
            this.setSnackMessage(this.server.ValidationMessage);
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }
    };

    @action
    public apiDownloadStockHistoryAsync = async (): Promise<void> => {
        await this.server.queryAuthenticatedFile(
            AppUrls.Server.Stock.DownloadStockHistoryViews,
            this.getConfig,
            (data, filename) => {
                // Create a link element for the file and use the filename provided.
                const link = document.createElement("a");

                link.href = window.URL.createObjectURL(new Blob([data]));
                link.setAttribute("download", filename);

                document.body.appendChild(link);

                // Download.
                link.click();

                // Having clicked the link, delete the element otherwise it will
                // remain attached to the document.
                document.body.removeChild(link);
            },
            "Error whilst downloading the file",
        );

        if (this.server.HaveValidationMessage) {
            this.setSnackMessage(this.server.ValidationMessage);
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }
    };

    // #endregion Api Actions

    // #region Snack Bar

    public SNACKERROR = "error";

    @observable
    public snackbarState = false;

    @observable
    public snackType = "";

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public snackMessage = "";

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    // #endregion Snack Bar

    // #region Boilerplate

    isFieldValid(fieldName: string | number | symbol, value: any): Promise<boolean> {
        throw new Error("Method not implemented.");
    }
    beforeUpdate?(fieldName: string | number | symbol, value: any) {
        throw new Error("Method not implemented.");
    }
    afterUpdate?(fieldName: string | number | symbol, value: any): void {
        throw new Error("Method not implemented.");
    }

    // #endregion Boliderplate
}
