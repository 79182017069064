import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { FireExtinguishersViewModel } from "./FireExtinguishersViewModel";
import { SHBox } from "../../../../../Components/Box";
import { RadioAuditView } from "./EndOfWeekView";
import { FireExitModel } from "./FireExitModel";
import { Uploader } from "../../../../../Components/Uploader/Uploader";
import { SectionSplitter } from "../../../../../Components/Form/SectionSplitter";
import { EndOfWeekViewModel } from "./EndOfWeekViewModel";
import { uniqueId } from "lodash-es";
import { IconButton, Typography } from "@material-ui/core";
import { FirstAidKitViewModel } from "./FirstAidKitViewModel";
import SystemUpdateTwoToneIcon from "@material-ui/icons/SystemUpdateTwoTone";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { AuditDocumentModel } from "../AuditDocument";
import { DarwinInput } from "../../../../../Globals/Styles/Control/DarwinInput";
import { FireExitViewModel } from "./FireExitViewModel";
import { FirePointViewModel } from "./FirePointViewModel";
interface Props {
    index: number;
    firstAidKitViewModel: FirstAidKitViewModel;
    endOfWeekViewModel: EndOfWeekViewModel;
}
export const FirstAidKitView: React.FC<Props> = observer((props: Props) => {
    const { firstAidKitViewModel, endOfWeekViewModel } = props;

    return (
        <>
            <SHBox mt={2} key={uniqueId()}>
                <Typography variant={"h4"}>First Aid Kit {props.index + 1}:</Typography>
                <SHBox grid dc={"262px"} mt={2} mb={2}>
                    <DarwinInput<FirstAidKitViewModel>
                        type="text"
                        label="Location:"
                        placeholder="Please add"
                        validateOnBlur={false}
                        viewModel={firstAidKitViewModel}
                        fieldName={"location"}
                        shrink={true}
                        maxLength={4096}
                        editMode={endOfWeekViewModel.editable}
                    />
                </SHBox>
                <SHBox grid dc={"2fr 1fr"} mt={1}>
                    <RadioAuditView
                        title={"Are all contents in date"}
                        value={() => firstAidKitViewModel.getValue("areAllContentsInDate")}
                        validationMessage={() => firstAidKitViewModel.getError("areAllContentsInDate")}
                        onChange={(e) => firstAidKitViewModel.radioChanged(e, "areAllContentsInDate", endOfWeekViewModel.editable)}
                        canExecute={endOfWeekViewModel.editable}
                    />
                    <SHBox dcs={"2"} showIf={firstAidKitViewModel.getValue("areAllContentsInDate") === "no"}>
                        <DarwinInput<FirstAidKitViewModel>
                            type="text"
                            label="Please give more details"
                            multiLine={true}
                            validateOnBlur={false}
                            viewModel={firstAidKitViewModel}
                            fieldName={"areAllContentsInDateDetail"}
                            shrink={true}
                            maxLength={4096}
                            editMode={endOfWeekViewModel.editable}
                        />
                    </SHBox>
                    <RadioAuditView
                        title={"All contents in place"}
                        value={() => firstAidKitViewModel.getValue("allContentsInPlace")}
                        validationMessage={() => firstAidKitViewModel.getError("allContentsInPlace")}
                        onChange={(e) => firstAidKitViewModel.radioChanged(e, "allContentsInPlace", endOfWeekViewModel.editable)}
                        canExecute={endOfWeekViewModel.editable}
                    />
                    <SHBox dcs={"2"} showIf={firstAidKitViewModel.getValue("allContentsInPlace") === "no"}>
                        <DarwinInput<FirstAidKitViewModel>
                            type="text"
                            label="Please give more details"
                            multiLine={true}
                            validateOnBlur={false}
                            viewModel={firstAidKitViewModel}
                            fieldName={"allContentsInPlaceDetail"}
                            shrink={true}
                            maxLength={4096}
                            editMode={endOfWeekViewModel.editable}
                        />
                    </SHBox>
                    <RadioAuditView
                        title={"All contents in good condition"}
                        value={() => firstAidKitViewModel.getValue("allContentsInGoodCondition")}
                        validationMessage={() => firstAidKitViewModel.getError("allContentsInGoodCondition")}
                        onChange={(e) => firstAidKitViewModel.radioChanged(e, "allContentsInGoodCondition", endOfWeekViewModel.editable)}
                        canExecute={endOfWeekViewModel.editable}
                    />
                    <SHBox dcs={"2"} showIf={firstAidKitViewModel.getValue("allContentsInGoodCondition") === "no"}>
                        <DarwinInput<FirstAidKitViewModel>
                            type="text"
                            label="Please give more details"
                            multiLine={true}
                            validateOnBlur={false}
                            viewModel={firstAidKitViewModel}
                            fieldName={"allContentsInGoodConditionDetail"}
                            shrink={true}
                            maxLength={4096}
                            editMode={endOfWeekViewModel.editable}
                        />
                    </SHBox>
                    <RadioAuditView
                        title={"Eye wash"}
                        value={() => firstAidKitViewModel.getValue("eyeWash")}
                        validationMessage={() => firstAidKitViewModel.getError("eyeWash")}
                        onChange={(e) => firstAidKitViewModel.radioChanged(e, "eyeWash", endOfWeekViewModel.editable)}
                        canExecute={endOfWeekViewModel.editable}
                    />
                    <SHBox dcs={"2"} showIf={firstAidKitViewModel.getValue("eyeWash") === "no"}>
                        <DarwinInput<FirstAidKitViewModel>
                            type="text"
                            label="Please give more details"
                            multiLine={true}
                            validateOnBlur={false}
                            viewModel={firstAidKitViewModel}
                            fieldName={"eyeWashDetail"}
                            shrink={true}
                            editMode={endOfWeekViewModel.editable}
                            maxLength={4096}
                        />
                    </SHBox>
                    <RadioAuditView
                        title={"Guidance leaflet"}
                        value={() => firstAidKitViewModel.getValue("guidanceLeaflet")}
                        validationMessage={() => firstAidKitViewModel.getError("guidanceLeaflet")}
                        canExecute={endOfWeekViewModel.editable}
                        onChange={(e) => firstAidKitViewModel.radioChanged(e, "guidanceLeaflet", endOfWeekViewModel.editable)}
                    />
                    <SHBox dcs={"2"} showIf={firstAidKitViewModel.getValue("guidanceLeaflet") === "no"}>
                        <DarwinInput<FirstAidKitViewModel>
                            type="text"
                            label="Please give more details"
                            multiLine={true}
                            validateOnBlur={false}
                            viewModel={firstAidKitViewModel}
                            fieldName={"guidanceLeafletDetail"}
                            shrink={true}
                            maxLength={4096}
                            editMode={endOfWeekViewModel.editable}
                        />
                    </SHBox>
                    <RadioAuditView
                        title={"Contents list"}
                        value={() => firstAidKitViewModel.getValue("contentsList")}
                        validationMessage={() => firstAidKitViewModel.getError("contentsList")}
                        onChange={(e) => firstAidKitViewModel.radioChanged(e, "contentsList", endOfWeekViewModel.editable)}
                        canExecute={endOfWeekViewModel.editable}
                    />
                    <SHBox dcs={"2"} showIf={firstAidKitViewModel.getValue("contentsList") === "no"}>
                        <DarwinInput<FirstAidKitViewModel>
                            type="text"
                            label="Please give more details"
                            multiLine={true}
                            validateOnBlur={false}
                            viewModel={firstAidKitViewModel}
                            fieldName={"contentsListDetail"}
                            shrink={true}
                            editMode={endOfWeekViewModel.editable}
                            maxLength={4096}
                        />
                    </SHBox>
                    <RadioAuditView
                        title={"Medium sterile dressing"}
                        value={() => firstAidKitViewModel.getValue("mediumSterileDressing")}
                        validationMessage={() => firstAidKitViewModel.getError("mediumSterileDressing")}
                        onChange={(e) => firstAidKitViewModel.radioChanged(e, "mediumSterileDressing", endOfWeekViewModel.editable)}
                        canExecute={endOfWeekViewModel.editable}
                    />
                    <SHBox dcs={"2"} showIf={firstAidKitViewModel.getValue("mediumSterileDressing") === "no"}>
                        <DarwinInput<FirstAidKitViewModel>
                            type="text"
                            label="Please give more details"
                            multiLine={true}
                            validateOnBlur={false}
                            viewModel={firstAidKitViewModel}
                            fieldName={"mediumSterileDressingDetail"}
                            shrink={true}
                            maxLength={4096}
                            editMode={endOfWeekViewModel.editable}
                        />
                    </SHBox>
                    <RadioAuditView
                        title={"Large sterile dressing"}
                        value={() => firstAidKitViewModel.getValue("largeSterileDressing")}
                        validationMessage={() => firstAidKitViewModel.getError("largeSterileDressing")}
                        onChange={(e) => firstAidKitViewModel.radioChanged(e, "largeSterileDressing", endOfWeekViewModel.editable)}
                        canExecute={endOfWeekViewModel.editable}
                    />
                    <SHBox dcs={"2"} showIf={firstAidKitViewModel.getValue("largeSterileDressing") === "no"}>
                        <DarwinInput<FirstAidKitViewModel>
                            type="text"
                            label="Please give more details"
                            multiLine={true}
                            validateOnBlur={false}
                            viewModel={firstAidKitViewModel}
                            fieldName={"largeSterileDressingDetail"}
                            shrink={true}
                            maxLength={4096}
                            editMode={endOfWeekViewModel.editable}
                        />
                    </SHBox>
                    <RadioAuditView
                        title={"Triangular bandage"}
                        value={() => firstAidKitViewModel.getValue("triangularBandage")}
                        validationMessage={() => firstAidKitViewModel.getError("triangularBandage")}
                        onChange={(e) => firstAidKitViewModel.radioChanged(e, "triangularBandage", endOfWeekViewModel.editable)}
                        canExecute={endOfWeekViewModel.editable}
                    />
                    <SHBox dcs={"2"} showIf={firstAidKitViewModel.getValue("triangularBandage") === "no"}>
                        <DarwinInput<FirstAidKitViewModel>
                            type="text"
                            label="Please give more details"
                            multiLine={true}
                            validateOnBlur={false}
                            viewModel={firstAidKitViewModel}
                            fieldName={"triangularBandageDetail"}
                            shrink={true}
                            maxLength={4096}
                            editMode={endOfWeekViewModel.editable}
                        />
                    </SHBox>
                    <RadioAuditView
                        title={"Safety pins"}
                        value={() => firstAidKitViewModel.getValue("safetyPins")}
                        validationMessage={() => firstAidKitViewModel.getError("safetyPins")}
                        onChange={(e) => firstAidKitViewModel.radioChanged(e, "safetyPins", endOfWeekViewModel.editable)}
                        canExecute={endOfWeekViewModel.editable}
                    />
                    <SHBox dcs={"2"} showIf={firstAidKitViewModel.getValue("safetyPins") === "no"}>
                        <DarwinInput<FirstAidKitViewModel>
                            type="text"
                            label="Please give more details"
                            multiLine={true}
                            validateOnBlur={false}
                            viewModel={firstAidKitViewModel}
                            fieldName={"safetyPinsDetail"}
                            shrink={true}
                            maxLength={4096}
                            editMode={endOfWeekViewModel.editable}
                        />
                    </SHBox>
                    <RadioAuditView
                        title={"Sterile eye pads"}
                        value={() => firstAidKitViewModel.getValue("sterileEyePads")}
                        validationMessage={() => firstAidKitViewModel.getError("sterileEyePads")}
                        onChange={(e) => firstAidKitViewModel.radioChanged(e, "sterileEyePads", endOfWeekViewModel.editable)}
                        canExecute={endOfWeekViewModel.editable}
                    />
                    <SHBox dcs={"2"} showIf={firstAidKitViewModel.getValue("sterileEyePads") === "no"}>
                        <DarwinInput<FirstAidKitViewModel>
                            type="text"
                            label="Please give more details"
                            multiLine={true}
                            validateOnBlur={false}
                            viewModel={firstAidKitViewModel}
                            fieldName={"sterileEyePadsDetail"}
                            shrink={true}
                            maxLength={4096}
                            editMode={endOfWeekViewModel.editable}
                        />
                    </SHBox>
                    <RadioAuditView
                        title={"Waterproof plasters"}
                        value={() => firstAidKitViewModel.getValue("waterproofPlasters")}
                        validationMessage={() => firstAidKitViewModel.getError("waterproofPlasters")}
                        onChange={(e) => firstAidKitViewModel.radioChanged(e, "waterproofPlasters", endOfWeekViewModel.editable)}
                        canExecute={endOfWeekViewModel.editable}
                    />
                    <SHBox dcs={"2"} showIf={firstAidKitViewModel.getValue("waterproofPlasters") === "no"}>
                        <DarwinInput<FirstAidKitViewModel>
                            type="text"
                            label="Please give more details"
                            multiLine={true}
                            validateOnBlur={false}
                            viewModel={firstAidKitViewModel}
                            fieldName={"waterproofPlastersDetail"}
                            shrink={true}
                            maxLength={4096}
                            editMode={endOfWeekViewModel.editable}
                        />
                    </SHBox>
                    <RadioAuditView
                        title={"Sterile saline wipes"}
                        value={() => firstAidKitViewModel.getValue("sterileSalineWipes")}
                        validationMessage={() => firstAidKitViewModel.getError("sterileSalineWipes")}
                        onChange={(e) => firstAidKitViewModel.radioChanged(e, "sterileSalineWipes", endOfWeekViewModel.editable)}
                        canExecute={endOfWeekViewModel.editable}
                    />
                    <SHBox dcs={"2"} showIf={firstAidKitViewModel.getValue("sterileSalineWipes") === "no"}>
                        <DarwinInput<FirstAidKitViewModel>
                            type="text"
                            label="Please give more details"
                            multiLine={true}
                            validateOnBlur={false}
                            viewModel={firstAidKitViewModel}
                            fieldName={"sterileSalineWipesDetail"}
                            shrink={true}
                            maxLength={4096}
                            editMode={endOfWeekViewModel.editable}
                        />
                    </SHBox>
                    <RadioAuditView
                        title={"Microporous tape"}
                        value={() => firstAidKitViewModel.getValue("microporousTape")}
                        validationMessage={() => firstAidKitViewModel.getError("microporousTape")}
                        onChange={(e) => firstAidKitViewModel.radioChanged(e, "microporousTape", endOfWeekViewModel.editable)}
                        canExecute={endOfWeekViewModel.editable}
                    />
                    <SHBox dcs={"2"} showIf={firstAidKitViewModel.getValue("microporousTape") === "no"}>
                        <DarwinInput<FirstAidKitViewModel>
                            type="text"
                            label="Please give more details"
                            multiLine={true}
                            validateOnBlur={false}
                            viewModel={firstAidKitViewModel}
                            fieldName={"microporousTapeDetail"}
                            shrink={true}
                            maxLength={4096}
                            editMode={endOfWeekViewModel.editable}
                        />
                    </SHBox>
                    <RadioAuditView
                        title={"Nitrile gloves"}
                        value={() => firstAidKitViewModel.getValue("nitrileGloves")}
                        validationMessage={() => firstAidKitViewModel.getError("nitrileGloves")}
                        onChange={(e) => firstAidKitViewModel.radioChanged(e, "nitrileGloves", endOfWeekViewModel.editable)}
                        canExecute={endOfWeekViewModel.editable}
                    />
                    <SHBox dcs={"2"} showIf={firstAidKitViewModel.getValue("nitrileGloves") === "no"}>
                        <DarwinInput<FirstAidKitViewModel>
                            type="text"
                            label="Please give more details"
                            multiLine={true}
                            validateOnBlur={false}
                            viewModel={firstAidKitViewModel}
                            fieldName={"nitrileGlovesDetail"}
                            shrink={true}
                            maxLength={4096}
                            editMode={endOfWeekViewModel.editable}
                        />
                    </SHBox>
                    <RadioAuditView
                        title={"Sterile finger dressing"}
                        value={() => firstAidKitViewModel.getValue("sterileFingerDressing")}
                        validationMessage={() => firstAidKitViewModel.getError("sterileFingerDressing")}
                        onChange={(e) => firstAidKitViewModel.radioChanged(e, "sterileFingerDressing", endOfWeekViewModel.editable)}
                        canExecute={endOfWeekViewModel.editable}
                    />
                    <SHBox dcs={"2"} showIf={firstAidKitViewModel.getValue("sterileFingerDressing") === "no"}>
                        <DarwinInput<FirstAidKitViewModel>
                            type="text"
                            label="Please give more details"
                            multiLine={true}
                            validateOnBlur={false}
                            viewModel={firstAidKitViewModel}
                            fieldName={"sterileFingerDressingDetail"}
                            shrink={true}
                            maxLength={4096}
                            editMode={endOfWeekViewModel.editable}
                        />
                    </SHBox>
                    <RadioAuditView
                        title={"Resuscitation Face shield"}
                        value={() => firstAidKitViewModel.getValue("resusitationFaceShield")}
                        validationMessage={() => firstAidKitViewModel.getError("resusitationFaceShield")}
                        onChange={(e) => firstAidKitViewModel.radioChanged(e, "resusitationFaceShield", endOfWeekViewModel.editable)}
                        canExecute={endOfWeekViewModel.editable}
                    />
                    <SHBox dcs={"2"} showIf={firstAidKitViewModel.getValue("resusitationFaceShield") === "no"}>
                        <DarwinInput<FirstAidKitViewModel>
                            type="text"
                            label="Please give more details"
                            multiLine={true}
                            validateOnBlur={false}
                            viewModel={firstAidKitViewModel}
                            fieldName={"resusitationFaceShieldDetail"}
                            shrink={true}
                            maxLength={4096}
                            editMode={endOfWeekViewModel.editable}
                        />
                    </SHBox>
                    <RadioAuditView
                        title={"Foil blanket"}
                        value={() => firstAidKitViewModel.getValue("foilBlanket")}
                        validationMessage={() => firstAidKitViewModel.getError("foilBlanket")}
                        onChange={(e) => firstAidKitViewModel.radioChanged(e, "foilBlanket", endOfWeekViewModel.editable)}
                        canExecute={endOfWeekViewModel.editable}
                    />
                    <SHBox dcs={"2"} showIf={firstAidKitViewModel.getValue("foilBlanket") === "no"}>
                        <DarwinInput<FirstAidKitViewModel>
                            type="text"
                            label="Please give more details"
                            multiLine={true}
                            validateOnBlur={false}
                            viewModel={firstAidKitViewModel}
                            fieldName={"foilBlanketDetail"}
                            shrink={true}
                            maxLength={4096}
                            editMode={endOfWeekViewModel.editable}
                        />
                    </SHBox>
                    <RadioAuditView
                        title={"Hydrogel burns dressing"}
                        value={() => firstAidKitViewModel.getValue("hydrogelBurnsDressing")}
                        validationMessage={() => firstAidKitViewModel.getError("hydrogelBurnsDressing")}
                        onChange={(e) => firstAidKitViewModel.radioChanged(e, "hydrogelBurnsDressing", endOfWeekViewModel.editable)}
                        canExecute={endOfWeekViewModel.editable}
                    />
                    <SHBox dcs={"2"} showIf={firstAidKitViewModel.getValue("hydrogelBurnsDressing") === "no"}>
                        <DarwinInput<FirstAidKitViewModel>
                            type="text"
                            label="Please give more details"
                            multiLine={true}
                            validateOnBlur={false}
                            viewModel={firstAidKitViewModel}
                            fieldName={"hydrogelBurnsDressingDetail"}
                            shrink={true}
                            maxLength={4096}
                            editMode={endOfWeekViewModel.editable}
                        />
                    </SHBox>
                    <RadioAuditView
                        title={"Tuff cut shears"}
                        value={() => firstAidKitViewModel.getValue("tuffCutShears")}
                        validationMessage={() => firstAidKitViewModel.getError("tuffCutShears")}
                        onChange={(e) => firstAidKitViewModel.radioChanged(e, "tuffCutShears", endOfWeekViewModel.editable)}
                        canExecute={endOfWeekViewModel.editable}
                    />
                    <SHBox dcs={"2"} showIf={firstAidKitViewModel.getValue("tuffCutShears") === "no"}>
                        <DarwinInput<FirstAidKitViewModel>
                            type="text"
                            label="Please give more details"
                            multiLine={true}
                            validateOnBlur={false}
                            viewModel={firstAidKitViewModel}
                            fieldName={"tuffCutShearsDetail"}
                            shrink={true}
                            maxLength={4096}
                            editMode={endOfWeekViewModel.editable}
                        />
                    </SHBox>
                    <RadioAuditView
                        title={"Conforming bandages"}
                        value={() => firstAidKitViewModel.getValue("conformingBandages")}
                        validationMessage={() => firstAidKitViewModel.getError("conformingBandages")}
                        onChange={(e) => firstAidKitViewModel.radioChanged(e, "conformingBandages", endOfWeekViewModel.editable)}
                        canExecute={endOfWeekViewModel.editable}
                    />
                    <SHBox dcs={"2"} showIf={firstAidKitViewModel.getValue("conformingBandages") === "no"}>
                        <DarwinInput<FirstAidKitViewModel>
                            type="text"
                            label="Please give more details"
                            multiLine={true}
                            validateOnBlur={false}
                            viewModel={firstAidKitViewModel}
                            fieldName={"conformingBandagesDetail"}
                            shrink={true}
                            maxLength={4096}
                            editMode={endOfWeekViewModel.editable}
                        />
                    </SHBox>
                    {/*<SHBox grid dc={"1fr"}>
                        <SHBox>
                            <Uploader
                                handleSelectFile={(e: React.ChangeEvent<HTMLInputElement>) => endOfWeekViewModel.fileChange(e, firstAidKitViewModel.model.auditDocuments)}
                                isFormDisabled={endOfWeekViewModel.IsLoading}
                                label="Photos(s)"
                            />
                            <Typography variant={"h5"} color={"error"}>
                                {firstAidKitViewModel.getError("auditDocuments")}
                            </Typography>
                        </SHBox>
                        <div className="uploadedFile">
                            {firstAidKitViewModel.model.auditDocuments &&
                                firstAidKitViewModel.model.auditDocuments.map((item: AuditDocumentModel, index: number) => {
                                    return (
                                        <div className="uploadedFile-box" key={uniqueId()}>
                                            <div className="uploadedFile-heading">
                                                <IconButton onClick={() => endOfWeekViewModel.DownloadFile(item.url, item.fileName)}>
                                                    <SystemUpdateTwoToneIcon />
                                                </IconButton>
                                                <h3>{item.fileName}</h3>
                                            </div>
                                            <IconButton onClick={() => item.deleteDocument()} disabled={false}>
                                                <DeleteOutlinedIcon />
                                            </IconButton>
                                        </div>
                                    );
                                })}
                        </div>
                    </SHBox>*/}
                </SHBox>
                <SectionSplitter />
            </SHBox>
        </>
    );
});
