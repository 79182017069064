import { Theme } from "@material-ui/core";

export enum CostCategory {
    Unknown = 0,
    All = 1000,
}

export class CostCategoryHelpers {
    public static getText = (status: CostCategory) => {
        switch (status) {
            case CostCategory.All: {
                return "All";
            }
            case CostCategory.Unknown:
            default: {
                return "Unknown";
            }
        }
    };

    public static getClassName = (status: CostCategory) => {
        switch (status) {
            case CostCategory.All:
                return "costcategory-all";
            case CostCategory.Unknown:
            default:
                return "costcategory-unknown";
        }
    };

    public static getCellColour = (status: CostCategory, theme: Theme) => {
        switch (status) {
            case CostCategory.All:
                return theme.palette.costCategory.all;
            case CostCategory.Unknown:
            default:
                return theme.palette.costCategory.unknown;
        }
    };

    public static getCellTextColour = (status: CostCategory) => {
        switch (status) {
            case CostCategory.All:
                return "#FFFFFF";
            case CostCategory.Unknown:
            default:
                return "#FFFFFF";
        }
    };

    public static getOptions = (ignoreUnknown: boolean = false) => {
        const options: {
            label: string;
            value: string;
        }[] = [];

        for (const key in CostCategory) {
            const keyAsInt = parseInt(key);

            if (isNaN(keyAsInt) === false) {
                const status: CostCategory = keyAsInt as CostCategory;

                if (ignoreUnknown === false || status !== CostCategory.Unknown) {
                    options.push({
                        label: CostCategoryHelpers.getText(status),
                        value: keyAsInt.toString(),
                    });
                }
            }
        }

        /*         let retVal: any = {};
        for (let i: number = 0; i < options.length; i++) {
            retVal[options[i].value] = options[i].label;
        } */

        return options;
    };
}
