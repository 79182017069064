import * as React from "react";

import { GenericWarningModalDialogPaper } from "./GenericWarningModal.styles";
import { BaseModal } from "./BaseModal";
import { CancelButton } from "Globals/Styles/Control/Buttons";

interface IProps {
    open?: boolean;
    onClose(): void;
    onButtonClick(): void;
    title: string;
    buttonText?: string;
    text: string;
    isLoading?: boolean;
    canClose?: boolean;
}

export const GenericWarningModal: React.FC<IProps> = ({ open, onButtonClick, onClose, title, text, buttonText, isLoading = false, canClose = true }) => (
    <BaseModal
        open={open}
        onClose={onClose}
        title={title}
        PaperComponent={GenericWarningModalDialogPaper}
        actions={
            <>
                <CancelButton onClick={onButtonClick} color="secondary" autoFocus variant="contained">
                    {buttonText ? buttonText : "Ok"}
                </CancelButton>
            </>
        }
        canClose={canClose}
    >
        <p className="client-modal-description">{text}</p>
    </BaseModal>
);
