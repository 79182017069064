import { styled } from "@shoothill/core";
import { theme } from "../../../Globals/Styles/AppTheme";

export const AddContactTitle = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: row;
    color: ${theme.palette.orange.main};
    font-size: 18px;
    padding: 15px 30px;
    cursor: pointer;

    img {
        padding-right: 15px;
    }

    @media screen and (max-width: 1024px) {
    }

    @media screen and (max-width: 640px) {
        flex-direction: row;
    }
`;
