import { Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useObserver, useRouter } from "@shoothill/core";
import { SupplierListViewModel } from "./SupplierListViewModel";
import MaterialTable from "material-table";
import * as Defaults from "Globals/Defaults/TableOptions";
import { AppUrls } from "AppUrls";
import { DarwinPage, DarwinPageTitle, DarwinTablePageContent } from "Globals/Styles/AppStyling";
import { CostCategoryHelpers, SupplierStatusHelpers } from "Globals/Models/Domain";
import { SupplierListModel } from "./SupplierListModel";
import { theme } from "Globals/Styles/AppTheme";
import { StatusCell } from "Globals/Styles/Control/StatusCell.style";
import { AddSupplierModal } from "./AddSupplierModal";
import { PatchedPagination } from "../../Components/Table/PatchedPagination";
import { SupplierListFilterViewModel } from "./SupplierListFilterViewModel";
import { SupplierListFilterView } from "./SupplierListFilterView";
import { formatDate } from "Utils/Format";

export const SupplierListView: React.FunctionComponent = () => {
    const [viewModel] = useState(() => SupplierListViewModel.Instance);
    const filtersViewModel = SupplierListFilterViewModel.Instance;
    const { history } = useRouter();

    useEffect(() => {
        viewModel.loadSupplierForListAsync().then((result) => {});

        return () => {
            viewModel.cleanUp();
        };
    }, []);

    const handleRowClick = (e: any, rowData: any) => {
        const url: string = AppUrls.Client.Supplier.Detail.replace(":supplierid", rowData.id);
        history.push(url);
    };

    const onCloseModal = () => {
        viewModel.showAddEditModal(false);
    };

    const onAdd = (): any => {
        viewModel.loadSupplierForListAsync().then((result) => {});
    };

    const renderPage = () => {
        return (
            <DarwinPage className="addSupplierModel">
                <AddSupplierModal open={viewModel.getShowAddEditModal} onClose={onCloseModal} title="Add new Supplier" text="" onSave={onAdd} />
                <DarwinPageTitle>
                    <Typography variant="h1" color="textPrimary">
                        Suppliers
                    </Typography>
                </DarwinPageTitle>

                <SupplierListFilterView filtersViewModel={filtersViewModel} supplierListViewModel={viewModel} />

                <DarwinTablePageContent>
                    <MaterialTable
                        columns={[
                            {
                                title: "Supplier ref",
                                field: "reference",
                                customSort: (a, b) => {
                                    return a.reference.localeCompare(b.reference, undefined, {
                                        numeric: true,
                                        sensitivity: "base",
                                    });
                                },
                            },
                            { title: "Supplier name", field: "name" },
                            {
                                title: "Cost category",
                                field: "costCategory",
                                render: (rowData: SupplierListModel) => <div className="supplier-costCategory ">{CostCategoryHelpers.getText(rowData.costCategory)}</div>,
                            },
                            { title: "Contact Email", field: "contactEmail" },
                            { title: "Contact Phone", field: "contactPhone" },
                            { title: "Queried po", field: "queriedPo" },
                            { title: "Queried Invoices", field: "queriedInvoices" },
                            {
                                title: "Insurance expiry date",
                                field: "insuranceExpiryDate",
                                width: "auto",
                                type: "date",
                                render: (rowData: SupplierListModel) => <>{formatDate(rowData.insuranceExpiryDate !== null ? rowData.insuranceExpiryDate.toString() : "")}</>,
                            },
                            {
                                title: "Status",
                                field: "status",
                                render: (rowData: SupplierListModel) => (
                                    <StatusCell
                                        className={SupplierStatusHelpers.getClassName(rowData.status)}
                                        style={{
                                            backgroundColor: SupplierStatusHelpers.getCellColour(rowData.status, theme),
                                            color: SupplierStatusHelpers.getCellTextColour(rowData.status),
                                        }}
                                    >
                                        <div>{SupplierStatusHelpers.getText(rowData.status)}</div>
                                    </StatusCell>
                                ),
                            },
                        ]}
                        components={{
                            Pagination: PatchedPagination,
                        }}
                        options={Defaults.GetDarwinTableOptions()}
                        data={viewModel.getSuppliers}
                        onRowClick={handleRowClick}
                        title=""
                    />
                </DarwinTablePageContent>
            </DarwinPage>
        );
    };

    return useObserver(() => renderPage());
};
