import { IconButton, Typography } from "@material-ui/core";
import { Grid } from "@shoothill/core";
import MaterialTable from "material-table";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

import { AddButton, DownloadButton } from "Components/Buttons/Buttons";
import { SnackBar } from "Components/SnackBar/SnackBar";
import { PatchedPagination } from "Components/Table/PatchedPagination";
import { TableListSearch } from "Components/Table/TableListSearch";
import AdjustIcon from "Content/AdjustIcon";
import TimerBlackIcon from "Content/TimerBlackIcon";
import TransferIcon from "Content/TransferIcon";
import * as Defaults from "Globals/Defaults/TableOptions";
import { DarwinPage, DarwinPageTitle, DarwinTableButtonContainer, DarwinTablePageContent, DarwinTableSearchBox, DarwinTableToolbar } from "Globals/Styles/AppStyling";
import { formatCurrencyFromPounds } from "Utils/Format";
import { StockListViewModel } from "./StockListViewModel";
import { StockAdjustmentView } from "../StockAdjustment/StockAdjustmentView";
import { StockHistoryView } from "../StockHistory/StockHistoryView";
import { StockTransferView } from "../StockTransfer/StockTransferView";
import { StockListFilterViewModel } from "./StockListFilterViewModel";
import { StockListFilterView } from "./StockListFilterView";

export const StockListView: React.FunctionComponent = observer(() => {
    const [viewModel] = useState(() => new StockListViewModel());
    const tableOptions = Defaults.GetDarwinTableOptions() as any;
    const filtersViewModel = StockListFilterViewModel.Instance;

    return (
        <React.Fragment>
            {/* Display Stock Adjustment Modal */}
            {viewModel.canDisplayStockAdjustment && <StockAdjustmentView viewModel={viewModel.stockAdjustmentViewModel!} />}

            {/* Display Stock Transfer Modal */}
            {viewModel.canDisplayStockTransfer && <StockTransferView viewModel={viewModel.stockTransferViewModel!} />}

            {/* Display Sttock History Modal */}
            {viewModel.canDisplayStockHistory && <StockHistoryView viewModel={viewModel.stockHistoryViewModel!} />}

            <DarwinPage>
                <DarwinPageTitle>
                    <Typography variant="h1" color="textPrimary">
                        Stock list
                    </Typography>
                </DarwinPageTitle>
                <DarwinTableToolbar>
                    <StockListFilterView stockListViewModel={viewModel} filtersViewModel={filtersViewModel} />
                    <Grid columnGap={30} dc={"1fr 1fr"} rowGap={15} tc={"1fr 1fr"}>
                        <DarwinTableSearchBox>
                            <TableListSearch searchString={viewModel.getSearchString()} setSearchString={viewModel.setSearchString} />
                        </DarwinTableSearchBox>
                        <DarwinTableButtonContainer>
                            <AddButton color="primary" displayName="Add purchase order" execute={viewModel.navigateToNewPurchaseOrder} />
                            <DownloadButton color="primary" canExecute={!viewModel.server.IsBusy} displayName="Export as CSV" execute={viewModel.apiDownloadStockAsync} />
                        </DarwinTableButtonContainer>
                    </Grid>
                </DarwinTableToolbar>
                <DarwinTablePageContent>
                    <MaterialTable
                        columns={[
                            {
                                title: "Item description",
                                field: "description",
                                width: "auto",
                            },
                            {
                                title: "Type",
                                field: "materialType",
                                width: "auto",
                            },
                            {
                                title: "Sub type",
                                field: "materialSubType",
                                width: "auto",
                            },
                            {
                                title: "Supplier code",
                                field: "supplierCode",
                                width: "auto",
                            },
                            {
                                title: "UoM",
                                field: "unitOfMeasure",
                                width: "auto",
                            },
                            {
                                title: "Stock quantity",
                                field: "stockQuantity",
                                align: "right",
                                width: "auto",
                            },
                            {
                                title: "Stock value",
                                field: "stockValue",
                                align: "right",
                                width: "auto",
                                render: (rowData: any) => formatCurrencyFromPounds(rowData.stockValue),
                            },
                            {
                                title: "",
                                field: "",
                                cellStyle: { ...tableOptions.cellStyle, width: "125px" },
                                headerStyle: { ...tableOptions.headerStyle, width: "125px" },
                                render: (rowData: any) => (
                                    <div>
                                        <IconButton
                                            style={{ padding: "5px" }}
                                            size="small"
                                            onClick={() => viewModel.displayStockAdjustment(rowData)}
                                            title="Stock adjustment"
                                            disabled={!rowData.canEditStock}
                                        >
                                            <AdjustIcon />
                                        </IconButton>
                                        {viewModel.canTransferStock && (
                                            <IconButton
                                                style={{ padding: "5px" }}
                                                size="small"
                                                onClick={() => viewModel.displayStockTransfer(rowData)}
                                                title="Transfer stock"
                                                disabled={!rowData.canEditStock && !viewModel.canTransferStock}
                                            >
                                                <TransferIcon />
                                            </IconButton>
                                        )}
                                        <IconButton style={{ padding: "5px" }} size="small" onClick={() => viewModel.displayStockHistory(rowData)} title="Stock history">
                                            <TimerBlackIcon />
                                        </IconButton>
                                    </div>
                                ),
                                sorting: false,
                            },
                        ]}
                        components={{
                            Pagination: PatchedPagination,
                        }}
                        data={viewModel.filteredStocks}
                        options={{ ...tableOptions, filtering: false, search: false, toolbar: false }}
                        title=""
                    />
                </DarwinTablePageContent>
                <SnackBar
                    messageText={viewModel.snackMessage}
                    messageType={viewModel.snackType}
                    active={viewModel.snackbarState}
                    closeOption={() => viewModel.setSnackbarState(false)}
                    autoHideDuration={5000}
                />
            </DarwinPage>
        </React.Fragment>
    );
});
