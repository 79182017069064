import { ModelBase, observable } from "@shoothill/core";

export class DilapidationListModel extends ModelBase<DilapidationListModel> {
    @observable
    public id: string = "";
    @observable
    public projectId: string = "";
    @observable
    public dilapidationTypeName: string = "";
    @observable
    public completedByUserName: string = "";
    @observable
    public createdDate: string = "";
    @observable
    public completedDate: string = "";

    constructor() {
        super();
    }

    fromDto(model: DilapidationListDTO): void {
        this.id = model.id;
        this.dilapidationTypeName = model.dilapidationTypeName;
        this.completedByUserName = model.completedByUserName;
        this.createdDate = model.createdDate;
        this.completedDate = model.completedDate;
        this.projectId = model.projectId;
    }
    toDto(model: DilapidationListModel): void {
        throw new Error("Method not implemented.");
    }
}

export interface DilapidationListDTO {
    id: string;
    dilapidationTypeName: string;
    completedByUserName: string;
    createdDate: string;
    completedDate: string;
    projectId: string;
}

export interface DilapidationReportTypeDTO {
    id: string;
    displayName: string;
    type: number;
    createdDate: string;
    ordinal: number;
    isDeleted: boolean;
}

export interface DilapidationListAndRelatedDTO {
    dilapidationListItems: DilapidationListDTO[];
    dilapidationReportTypes: DilapidationReportTypeDTO[];
}
