import * as MobX from "mobx";
import { ModelBase } from "@shoothill/core";
import { IsNotEmpty } from "class-validator";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class ApprovalListModel extends ModelBase<ApprovalListModel, ApprovalListModelDTO> {
    constructor(model: ApprovalListModelDTO | null = null) {
        super();

        if (model) {
            this.fromDto(model);
        }
    }

    @MobX.observable
    public id: string = "";

    @MobX.observable
    public approvalSectionId: string = "";

    @MobX.observable
    public approvalSectionName: string = "";

    @MobX.observable
    public reference: string = "";

    @MobX.observable
    public name: string = "";

    @MobX.observable
    public approvalTypeId: string = "";

    @MobX.observable
    public approvalTypeName: string = "";

    @MobX.observable
    public requestedDate: Date | null = null;

    @MobX.observable
    public requestedBy: string = "";

    @MobX.observable
    public requisitionOrPOId: string = "";

    @MobX.observable
    public supplierName: string = "";

    //fromDto is required but you can leave it blank
    fromDto(model: ApprovalListModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: ApprovalListModel): void {}
}

export type ApprovalListModelDTO = {
    id: string | null;
    approvalSectionId: string;
    approvalSectionName: string;
    reference: string;
    name: string;
    approvalTypeId: string;
    approvalTypeName: string;
    requestedDate: Date;
    requestedBy: string;
    requisitionOrPOId: string | null;
    variationId: string | null;
    supplierName: string | null;
};
