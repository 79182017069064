import { FieldType, isEmptyOrWhitespace, ViewModelBase, getHistory } from "@shoothill/core";
import { action, computed, observable, runInAction } from "mobx";

import { AppUrls } from "AppUrls";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import {
    InductionUserDTO,
    PermitAndRelatedResponseDTO,
    PermitAnswerDTO,
    PermitDTO,
    PermitModel,
    PermitProjectDetailsDTO,
    PermitRelatedResponseDTO,
    PermitTypeDTO,
    UpsertPermitAndRelatedRequestDTO,
} from "./PermitModel";
import { PermitQuestionAnswerViewModel } from "./PermitQuestionAnswerViewModel";
import moment from "moment";
import { StoresInstance } from "../../../../Globals/Stores";

export class PermitViewModel extends ViewModelBase<PermitModel> {
    // #region Constructors and Disposers

    constructor(id: string | null, projectId: string | null, permitTypeId: string | null) {
        super(new PermitModel());
        this.setDecorators(PermitViewModel);
        this.model.id = id;
        this.model.projectId = projectId!;
        this.model.permitTypeId = permitTypeId!;

        isEmptyOrWhitespace(this.model.id) ? this.loadRelated() : this.loadWithRelated();
    }

    // #region Properties

    @observable
    public isViewOnly: boolean = false;

    @computed
    public get getIsViewOnly(): boolean {
        return this.isViewOnly;
    }

    @action
    public setIsViewOnly = (val: boolean) => {
        this.isViewOnly = val;
    };

    @computed
    public get isFormDisabled(): boolean {
        return this.isViewOnly || (this.model.id !== null && this.model.id !== undefined && this.model.id !== "");
    }

    @computed
    public get hasId(): boolean {
        return this.model.id !== null && this.model.id !== undefined && this.model.id !== "";
    }

    @computed
    public get canSaveForm(): boolean {
        return this.model.cancelledByDate === null && this.model.surrenderedByDate === null;
    }

    @computed
    public get getProjectTitle(): string {
        return `${this.getProjectReferenceFormatted} - ${this.getProjectName}`;
    }

    @computed
    public get getTodayDateFormatted(): string {
        return this.model.createdDate ? moment(this.model.createdDate).format("DD/MM/YYYY").toString() : moment().format("DD/MM/YYYY").toString();
    }

    @computed
    public get getProjectReferenceFormatted(): string {
        if (!this.permitProjectDetails) {
            return "";
        }

        return this.permitProjectDetails.projectReference;
    }

    @computed
    public get getProjectName(): string {
        if (!this.permitProjectDetails) {
            return "";
        }

        return this.permitProjectDetails.projectName;
    }

    @computed
    public get getPermitTypeName(): string {
        if (this.permitTypes.length > 0) {
            let type: PermitTypeDTO | undefined = this.permitTypes.find((t) => t.id == this.model.permitTypeId);

            if (type) {
                return type.displayName;
            }
        }

        return "";
    }

    public server: ServerViewModel = new ServerViewModel();

    @observable
    public permitTypes = observable<PermitTypeDTO>([]);

    @observable
    public inductionUsers = observable<InductionUserDTO>([]);

    @observable
    public permitProjectDetails: PermitProjectDetailsDTO | null = null;

    @observable
    public permitQuestionAnswerViewModels = observable<PermitQuestionAnswerViewModel>([]);

    @action
    private createViewModels(): void {
        for (const item of this.model.permitQuestionAnswers) {
            this.permitQuestionAnswerViewModels.push(new PermitQuestionAnswerViewModel(item));
        }
    }

    @action
    public handleCancel(projectId: string | null): void {
        const localProjectId = this.model.projectId;
        this.reset();
        if (!StoresInstance.Domain.AccountStore.isSiteTablet) {
            this.history.push(AppUrls.Client.Project.ConstructionHAndS.replace(":projectid", projectId ? projectId : localProjectId) + "#permits");
        } else {
            this.history.push(AppUrls.Client.Project.SiteTablet.Menu);
        }
    }

    @observable
    public inductionUser: { id: string; displayName: string } | null = null;

    @action
    public handleSetInductionUser = (item: { id: string; displayName: string }) => {
        this.inductionUser = item;
        this.model.issuedToInductionUserId = item.id;
    };

    @computed
    public get canDisplaySurrenderedBy() {
        const canDisplayIfEditable = isEmptyOrWhitespace(this.model.surrenderedByDate) && isEmptyOrWhitespace(this.model.cancelledByDate);
        const canDisplayIfReadOnly = !isEmptyOrWhitespace(this.model.surrenderedByDate);

        return canDisplayIfEditable || canDisplayIfReadOnly;
    }

    @computed
    public get canDisplayCancelledBy() {
        const canDisplayIfEditable = isEmptyOrWhitespace(this.model.surrenderedByDate) && isEmptyOrWhitespace(this.model.cancelledByDate);
        const canDisplayIfReadOnly = !isEmptyOrWhitespace(this.model.cancelledByDate);

        return canDisplayIfEditable || canDisplayIfReadOnly;
    }

    // #endregion Properties

    // #region Actions

    @action
    public setSurrenderedByUserName = (value: string) => {
        this.setValue("surrenderedByUserName", !isEmptyOrWhitespace(value) ? value : PermitModel.DEFAULT_SURRENDERED_BY_USER_NAME);

        // SIDE-EFFECT.
        // If defining the "surrendered by" section, "cancelled by" must be reset.
        this.setValue("cancelledByUserName", PermitModel.DEFAULT_CANCELLED_BY_USER_NAME);
        this.setValue("cancelledBySignatureURL", PermitModel.DEFAULT_CANCELLED_BY_SIGNATURE_URL);
    };

    @action
    public setSurrenderedBySignatureUrl = (value: string) => {
        this.setValue("surrenderedBySignatureURL", !isEmptyOrWhitespace(value) ? value : PermitModel.DEFAULT_SURRENDERED_BY_SIGNATURE_URL);

        // SIDE-EFFECT.
        // If defining the "surrendered by" section, "cancelled by" must be reset.
        this.setValue("cancelledByUserName", PermitModel.DEFAULT_CANCELLED_BY_USER_NAME);
        this.setValue("cancelledBySignatureURL", PermitModel.DEFAULT_CANCELLED_BY_SIGNATURE_URL);
    };

    @action
    public setCancelledByUserName = (value: string) => {
        this.setValue("cancelledByUserName", !isEmptyOrWhitespace(value) ? value : PermitModel.DEFAULT_CANCELLED_BY_USER_NAME);

        // SIDE-EFFECT.
        // If defining the "cancelled by" section, "surrendered by" must be reset.
        this.setValue("surrenderedByUserName", PermitModel.DEFAULT_SURRENDERED_BY_USER_NAME);
        this.setValue("surrenderedBySignatureURL", PermitModel.DEFAULT_SURRENDERED_BY_SIGNATURE_URL);
    };

    @action
    public setCancelledBySignatureUrl = (value: string) => {
        this.setValue("cancelledBySignatureURL", !isEmptyOrWhitespace(value) ? value : PermitModel.DEFAULT_CANCELLED_BY_SIGNATURE_URL);

        // SIDE-EFFECT.
        // If defining the "cancelled by" section, "surrendered by" must be reset.
        this.setValue("surrenderedByUserName", PermitModel.DEFAULT_SURRENDERED_BY_USER_NAME);
        this.setValue("surrenderedBySignatureURL", PermitModel.DEFAULT_SURRENDERED_BY_SIGNATURE_URL);
    };

    // #endregion Actions

    // #region Server Actions

    //related, for empty form
    public loadRelated = async (): Promise<void> => {
        this.setIsLoading(true);
        return await this.server
            .query<PermitRelatedResponseDTO>(
                () => this.Get(`${AppUrls.Server.Projects.Construction.Permit.Load.Related}\\${this.model.projectId}\\${this.model.permitTypeId}`),
                (result) => {
                    runInAction(() => {
                        this.model.fromRelatedDto({
                            permitQuestionAnswers: result.permitQuestionAnswers,
                            permitTypes: result.permitTypes,
                            inductionUsers: result.inductionUsers,
                            permitProjectDetails: result.permitProjectDetails,
                        });
                        this.permitTypes.replace(result.permitTypes);
                        this.inductionUsers.replace(result.inductionUsers);
                        this.permitProjectDetails = result.permitProjectDetails;
                        this.createViewModels();
                    });
                },
            )
            .finally(() => this.setIsLoading(false));
    };

    /**
     * Load an existing Permit with any related data.
     */
    public loadWithRelated = async (): Promise<void> => {
        this.setIsLoading(true);
        return await this.server
            .query<PermitAndRelatedResponseDTO>(
                () => this.Get(`${AppUrls.Server.Projects.Construction.Permit.Load.WithRelatedById}\\${this.model.id}`),
                (result) => {
                    runInAction(() => {
                        this.model.fromDto({
                            permit: result.permit,
                            permitQuestionAnswers: result.permitQuestionAnswers,
                            permitTypes: result.permitTypes,
                            inductionUsers: result.inductionUsers,
                            permitProjectDetails: result.permitProjectDetails,
                        });
                        this.permitTypes.replace(result.permitTypes);
                        this.inductionUsers.replace(result.inductionUsers);
                        this.permitProjectDetails = result.permitProjectDetails;
                        this.handleSetInductionUser(this.inductionUsers.find((i) => i.id === this.model.issuedToInductionUserId)!);
                        this.createViewModels();
                    });
                },
            )
            .finally(() => this.setIsLoading(false));
    };

    public upsert = async (): Promise<void> => {
        this.setIsLoading(true);
        const localProjectId = this.model.projectId;
        const model: PermitDTO = this.model.toDto();

        let permitAnswers: PermitAnswerDTO[] = [];

        // Only send dynamic answers to the server if this is a new permit.
        if (!this.hasId) {
            this.permitQuestionAnswerViewModels.forEach((questionAnswer) => {
                const answer: PermitAnswerDTO = {
                    id: null,
                    permitId: null,
                    permitQuestionId: questionAnswer.model.id,
                    answerText: questionAnswer.model.answerText !== "" ? questionAnswer.model.answerText : null,
                    answerYesNo: questionAnswer.model.answerYesNo,
                    answerRadio: questionAnswer.model.answerRadio,
                    answerCheckbox: questionAnswer.model.answerCheckbox,
                    createdDate: null,
                    createdByUserId: null,
                };

                permitAnswers.push(answer);
            });
        }

        const request: UpsertPermitAndRelatedRequestDTO = {
            permit: model,
            permitAnswers: permitAnswers,
        };

        return await this.server
            .command<PermitAndRelatedResponseDTO>(
                () => this.Post(AppUrls.Server.Projects.Construction.Permit.Upsert, request),
                (result: PermitAndRelatedResponseDTO) => {
                    runInAction(() => {
                        this.reset();
                        if (!StoresInstance.Domain.AccountStore.isSiteTablet) {
                            this.history.push(AppUrls.Client.Project.ConstructionHAndS.replace(":projectid", localProjectId) + "#permits");
                        } else {
                            this.history.push(AppUrls.Client.Project.SiteTablet.Menu);
                        }
                    });
                },
                this.isMyModelValid,
                "There was an error trying to send the permit",
            )
            .finally(() => this.setIsLoading(false));
    };

    /**
     * Custom model validation function. Validates child models and its children
     * @returns True if model is valid, false if not.
     */
    private isMyModelValid = async (): Promise<boolean> => {
        let isValid = true;

        for (let i = 0; i < this.permitQuestionAnswerViewModels.length; i++) {
            let questionAnswer = this.permitQuestionAnswerViewModels[i];

            // Validate each child.
            if ((await questionAnswer.isModelValid()) === false) {
                isValid = false;
            }
        }

        // Validate the permit model.
        if ((await this.isModelValid()) === false) {
            isValid = false;
        }

        return isValid;
    };

    @action
    public reset = () => {
        this.model.reset();
        this.server.reset();
        this.permitQuestionAnswerViewModels.length = 0;
        this.permitTypes.length = 0;
        this.permitProjectDetails = null;
        this.inductionUsers.length = 0;
    };

    // #endregion Client Actions

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<PermitModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        if (this.server.IsSubmitted) {
            switch (fieldName) {
                case "estimatedStartTime": {
                    const result = this.validateEstimatedStartTime;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "estimatedEndTime": {
                    const result = this.validateEstimatedEndTime;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "numWorkingUnderPermit": {
                    const result = this.validateNumWorkingUnderPermit;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "numWorkingInArea": {
                    const result = this.validateNumWorkingInArea;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "exactLocationOfWork": {
                    const result = this.validateExactLocationOfWork;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "signageNoticesLocations": {
                    const result = this.validateSignageNoticesLocations;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "descriptionOfWorksBeingUndertaken": {
                    const result = this.validateDescriptionOfWorksBeingUndertaken;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "issuedByUserName": {
                    const result = this.validateIssuedByUserName;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "issuedBySignatureURL": {
                    const result = this.validateIssuedBySignatureURL;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "issuedToInductionUserId": {
                    const result = this.validateIssuedToInductionUserId;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "issuedToSignatureURL": {
                    const result = this.validateIssuedToSignatureURL;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "surrenderedByUserName": {
                    if (this.hasId) {
                        const result = this.validateSurrenderedByUserName;

                        errorMessage = result.errorMessage;
                        isValid = result.isValid;
                    }
                    break;
                }
                case "surrenderedBySignatureURL": {
                    if (this.hasId) {
                        const result = this.validateSurrenderedBySignatureURL;

                        errorMessage = result.errorMessage;
                        isValid = result.isValid;
                    }

                    break;
                }
                case "cancelledByUserName": {
                    if (this.hasId) {
                        const result = this.validateCancelledByUserName;

                        errorMessage = result.errorMessage;
                        isValid = result.isValid;
                    }
                    break;
                }
                case "cancelledBySignatureURL": {
                    if (this.hasId) {
                        const result = this.validateCancelledBySignatureURL;

                        errorMessage = result.errorMessage;
                        isValid = result.isValid;
                    }
                    break;
                }
            }
        } else {
            // Do not validate if the properties of the model have not been
            // submitted to the server.
            errorMessage = "";
            isValid = true;
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    @computed
    private get validateEstimatedStartTime() {
        const errorMessage = this.model.validateEstimatedStartTime;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateEstimatedEndTime() {
        const errorMessage = this.model.validateEstimatedEndTime;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateNumWorkingUnderPermit() {
        const errorMessage = this.model.validateNumWorkingUnderPermit;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateNumWorkingInArea() {
        const errorMessage = this.model.validateNumWorkingInArea;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateExactLocationOfWork() {
        const errorMessage = this.model.validateExactLocationOfWork;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateSignageNoticesLocations() {
        const errorMessage = this.model.validateSignageNoticesLocations;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateDescriptionOfWorksBeingUndertaken() {
        const errorMessage = this.model.validateDescriptionOfWorksBeingUndertaken;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateIssuedByUserName() {
        const errorMessage = this.model.validateIssuedByUserName;
        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateIssuedBySignatureURL() {
        const errorMessage = this.model.validateIssuedBySignatureURL;
        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateIssuedToInductionUserId() {
        const errorMessage = this.model.validateIssuedToInductionUserId;
        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateIssuedToSignatureURL() {
        const errorMessage = this.model.validateIssuedToSignatureURL;
        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateSurrenderedByUserName() {
        const errorMessage = this.model.validateSurrenderedByUserName;
        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateSurrenderedBySignatureURL() {
        const errorMessage = this.model.validateSurrenderedBySignatureURL;
        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateCancelledByUserName() {
        const errorMessage = this.model.validateCancelledByUserName;
        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateCancelledBySignatureURL() {
        const errorMessage = this.model.validateCancelledBySignatureURL;
        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    // #region Snackbar

    @observable
    public snackbarState = false;

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public snackMessage = "";

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @observable
    public snackType = "";

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    @observable
    public SNACKSUCCESS = "success";

    @observable
    public SNACKERROR = "error";
    // #endregion

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}

enum PermitDocumentTypeEnum {
    Internal = 10,
    Client = 20,
}
