import { ModelBase } from "@shoothill/core";
import { IsArray, IsNotEmpty } from "class-validator";

import { action, observable } from "mobx";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class ContractTypeModel extends ModelBase<ContractTypeModel, ContractTypeModelDTO> {
    public id: string = "";

    @observable
    public name: string = "";

    @observable
    public type: string = "";

    public isDeleted: boolean = false;
    public rowVersion: string | null = null;

    @action
    public clearModel() {
        this.id = "";
        this.name = "";
        this.type = "";
        this.isDeleted = false;
        this.rowVersion = null;
    }

    //fromDto is required but you can leave it blank
    fromDto(model: ContractTypeModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: ContractTypeModel): void {}
}

export type ContractTypeModelDTO = {
    id: string;
    name: string;
    type: string;
    isDeleted: boolean;
};
