import React, { useState } from "react";
import { useObserver } from "mobx-react-lite";
import { BaseModal } from "Components/Modal/BaseModal";
import { CancelButton } from "Globals/Styles/Control/Buttons";
import { Box, IconButton, Typography } from "@material-ui/core";
import { Cell, Grid } from "@shoothill/core";
import { RFIFileViewModel } from "./RFIFileViewModel";
import { ReportModalStyle } from "Views/Project/Modals/Modal.Styles";
import SystemUpdateTwoToneIcon from "@material-ui/icons/SystemUpdateTwoTone";

interface ICreatedModalProps {
    open?: boolean;
    onClose(): void;
    rFIId: string | null;
}

export const RFIFileView: React.FC<ICreatedModalProps> = (props) => {
    const { open, onClose, rFIId } = props;
    const [viewModel] = useState(() => new RFIFileViewModel(rFIId));
    const COLUMNS8 = "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr";
    const COLUMNS4 = "1fr 1fr 1fr 1fr";

    return useObserver(() => (
        <BaseModal
            open={open}
            onClose={onClose}
            title={"Attachments"}
            PaperComponent={ReportModalStyle}
            actions={
                <>
                    <CancelButton style={{ background: "#0095da" }} onClick={onClose}>
                        Ok
                    </CancelButton>
                </>
            }
        >
            <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8} mb="35px">
                <Cell ts={8} ds={8}>
                    <Box>
                        <Typography style={{ marginBottom: "8px", font: "normal normal 700 12px/16px Segoe UI", letterSpacing: "-0.18px", color: "#191919" }} variant="h5">
                            Documents to refer to:
                        </Typography>
                        <Box className="uploadedFileRFI">
                            {viewModel.files
                                .filter((f) => f.rfiCategoryId !== null)
                                .map((item, index) => {
                                    return (
                                        !item.isDeleted && (
                                            <Box className="uploadedFile-box">
                                                <Box className="uploadedFile-heading">
                                                    <IconButton onClick={() => viewModel.DownloadFile(item.fileURL, item.fileName)}>
                                                        <SystemUpdateTwoToneIcon />
                                                    </IconButton>
                                                    <h3>{item.fileName}</h3>
                                                </Box>
                                            </Box>
                                        )
                                    );
                                })}
                        </Box>
                    </Box>
                    <Box style={{ marginTop: "20px" }}>
                        <Typography style={{ marginBottom: "8px", font: "normal normal 700 12px/16px Segoe UI", letterSpacing: "-0.18px", color: "#191919" }} variant="h5">
                            Attached item(s):
                        </Typography>
                        <Box className="uploadedFileRFI">
                            {viewModel.files
                                .filter((f) => f.rfiCategoryId === null)
                                .map((item, index) => {
                                    return (
                                        !item.isDeleted && (
                                            <Box className="uploadedFile-box">
                                                <Box className="uploadedFile-heading">
                                                    <IconButton onClick={() => viewModel.DownloadFile(item.fileURL, item.fileName)}>
                                                        <SystemUpdateTwoToneIcon />
                                                    </IconButton>
                                                    <h3>{item.fileName}</h3>
                                                </Box>
                                            </Box>
                                        )
                                    );
                                })}
                        </Box>
                    </Box>
                </Cell>
            </Grid>
        </BaseModal>
    ));
};
