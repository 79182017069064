import { ModelBase } from "@shoothill/core";
import { action, observable } from "mobx";

import { IMaterialTransactionRequestStatusType, MaterialTransactionRequestStatusType } from "Globals/Models/Enums/MaterialTransactionRequestStatusType";
import type { ICreateMaterialTransactionRequestViewDTO } from "../Shared/MaterialTransactionRequestDtos";

export class MaterialApprovalModel extends ModelBase<MaterialApprovalModel> {
    // #region Defaults
    // #endregion Defaults

    // #region Related Properties

    @observable
    public transactionRequestId: string | null = null;

    @observable
    public createdDate: string = "";

    @observable
    public createdByUser: string = "";

    @observable
    public lastUpdatedDate: string | null = null;

    @observable
    public lastUpdatedByUser: string | null = null;

    @observable
    public materialTypeDisplayName: string = "";

    @observable
    public materialSubTypeDisplayName: string = "";

    @observable
    public unitOfMeasureDisplayName: string = "";

    @observable
    public supplierCode: string = "";

    @observable
    public description: string = "";

    @observable
    public supplierDisplayName: string = "";

    @observable
    public estimatedPrice: number = 0;

    @observable
    public price: number = 0;

    @observable
    public note: string = "";

    @observable
    public effectiveFromDate: string = "";

    @observable
    public currentTransactionRequestStatusType: MaterialTransactionRequestStatusType | null = null;

    @observable
    public availableTransactionRequestStatusTypes = observable<MaterialTransactionRequestStatusType>([]);

    // #endregion Related Properties

    // #region Properties

    @observable
    public transactionRequestStatusType: MaterialTransactionRequestStatusType | null = null;

    // #endregion Properties

    // #region Methods

    @action
    public fromDto(dto: ICreateMaterialTransactionRequestViewDTO): void {
        this.transactionRequestId = dto.id;

        this.createdDate = dto.createdDate;
        this.createdByUser = dto.createdByUser;
        this.lastUpdatedDate = dto.lastUpdatedDate;
        this.lastUpdatedByUser = dto.lastUpdatedByUser;

        this.materialTypeDisplayName = dto.materialTypeDisplayName;
        this.materialSubTypeDisplayName = dto.materialSubTypeDisplayName;
        this.unitOfMeasureDisplayName = dto.unitOfMeasureDisplayName;
        this.supplierCode = dto.supplierCode;
        this.description = dto.description;

        this.supplierDisplayName = dto.supplierDisplayName;
        this.estimatedPrice = dto.estimatedPrice;
        this.price = dto.price;
        this.note = dto.note;
        this.effectiveFromDate = dto.effectiveFromDate;

        // Current status of the transaction request.
        this.currentTransactionRequestStatusType = new MaterialTransactionRequestStatusType();
        this.currentTransactionRequestStatusType.fromDto(dto.currentTransactionRequestStatusType);

        // Available statuses for the transaction request.
        this.availableTransactionRequestStatusTypes.replace(
            dto.availableTransactionRequestStatusTypes.map((statusType: IMaterialTransactionRequestStatusType) => {
                const enumType = new MaterialTransactionRequestStatusType();

                enumType.fromDto(statusType);

                return enumType;
            }),
        );
    }

    public toDto(): any {
        return {
            materialTransactionRequestStatusTypeId: this.transactionRequestStatusType?.id,
        };
    }

    // #endregion Methods
}
