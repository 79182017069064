import { action, runInAction, computed } from "mobx";
import { FieldType, ViewModelBase, observable } from "@shoothill/core";
import { DilapidationListAndRelatedDTO, DilapidationListModel } from "./DilapidationListModel";
import { DilapidationTypesLookupModel } from "./DilapidationTypesLookupModel";
import { AppUrls } from "AppUrls";

export class NonConformanceListViewModel extends ViewModelBase<any> {
    handleRowClick(e: React.MouseEvent<Element, MouseEvent> | undefined, rowData: DilapidationListModel) {
        if (rowData) {
            const url: string = AppUrls.Client.Project.Dilapidation.View.replace(":projectid", rowData.projectId).replace(":dilapidationReportId", rowData.id);
            this.history.push(url);
        }
    }
    public projectId: string = "";
    @observable public dilapidationFormType: DilapidationTypesLookupModel | null = null;
    @observable public dilapidationTypes: any = [];
    @observable public dilapidationListModels: DilapidationListModel[] = [];
    constructor() {
        super();
    }

    @computed
    public get DilapidationListItems() {
        const list = this.dilapidationListModels.sort((a, b) => {
            const dateA = a.createdDate ? new Date(a.createdDate) : null;
            const dateB = b.createdDate ? new Date(b.createdDate) : null;

            if (dateA && dateB) {
                return dateB.getTime() - dateA.getTime();
            } else if (dateA) {
                return -1;
            } else if (dateB) {
                return 1;
            } else {
                return 0;
            }
        });
        return list;
    }

    @action
    public addDilapidationForm = () => {
        this.history.push(AppUrls.Client.Project.Dilapidation.Add.replace(":projectid", this.projectId));
    };

    @action
    public loadNonConformanceAndRelated = async (): Promise<void> => {
        this.setIsLoading(true);
        const request: any = {
            id: this.projectId,
            dateFilter: null,
        };
        let apiResult = await this.Post<DilapidationListAndRelatedDTO>(AppUrls.Server.Projects.Construction.Dilapidation.GetDilapidationList, request);
        if (apiResult) {
            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.dilapidationTypes.replace(DilapidationTypesLookupModel.fromDtos(apiResult.payload.dilapidationReportTypes));
                    this.dilapidationListModels = [];
                    for (const dilapidation of apiResult.payload.dilapidationListItems) {
                        let model = new DilapidationListModel();
                        model.fromDto(dilapidation);
                        this.dilapidationListModels.push(model);
                    }
                });
            } else {
                console.log(apiResult.errors);
            }
        }
        this.setIsLoading(false);
    };

    public async isFieldValid(fieldName: keyof FieldType<any>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }
    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
