import { ApiResult, FieldType } from "@shoothill/core";
import { computed, runInAction, action } from "mobx";

import { DrawingApprovalPanelModel } from "./DrawingApprovalPanelModel";
import { ApprovalPanelViewModelBase } from "Globals/ViewModels/ApprovalPanelViewModelBase";
import { ApprovalHistoryViewModel } from "Components/ApprovalHistory/ApprovalHistoryViewModel";
import { AppUrls } from "AppUrls";
import { ApprovalDocumentDTO, ApprovalDocumentModel } from "Components/Approval/ApprovalDocumentModel";

export class DrawingApprovalPanelViewModel extends ApprovalPanelViewModelBase<DrawingApprovalPanelModel> {
    public approvalHistoryViewModel = new ApprovalHistoryViewModel();

    // #region Constructors and Disposers

    constructor() {
        super(DrawingApprovalPanelModel);
        this.setDecorators(DrawingApprovalPanelViewModel);
    }

    // #endregion Constructors and Disposers

    @computed
    public get getTitle(): string {
        if (this.getCanShowNewApprovalPanel) {
            return "New drawing";
        } else if (this.getCanShowAmendedApprovalPanel) {
            return "Drawing amend";
        } else if (this.getCanShowAmenderPanel) {
            return "Amends required";
        } else {
            return "New drawing";
        }
    }

    // @action
    // public setApprovalDocument = (file: ApprovalDocumentModel): void => {
    //     this.model.approvalDocuments.push(file);
    // };

    // private fileUpload = async (data: any): Promise<ApiResult<any>> => {
    //     const formData = new FormData();
    //     formData.append("formFile", data.formFile);
    //     formData.append("fileName", data.fileName);
    //     const apiResult = await this.Post<any>(AppUrls.Server.File.UploadFile, formData);
    //     if (apiResult) {
    //         if (!apiResult.wasSuccessful) {
    //             console.log(apiResult.errors);
    //             runInAction(() => {
    //                 let errorMessage: string = "Error uploading file please try again.";
    //                 if (apiResult && apiResult.errors && apiResult.errors.length > 0) {
    //                     errorMessage =
    //                         apiResult.errors[0].message === "The file type is not supported." ? "The file type is not supported." : "Error uploading file please try again.";
    //                 }
    //                 // this.setSnackMessage(errorMessage);
    //                 // this.setSnackType(this.SNACKERROR);
    //                 // this.setSnackbarState(true);
    //             });
    //         }
    //     }
    //     return apiResult;
    // };

    // /**
    //  * Download a file that exists in azure.
    //  * @param fileUrl The URL of the file to be downloaded.
    //  * @param fileName The name of the file to be downloaded.
    //  */
    // public downloadFile = async (fileUrl: string, fileName: string): Promise<void> => {
    //     try {
    //         const apiResult = await this.Post<Blob>(AppUrls.Server.File.DownloadFile, fileUrl, undefined, { responseType: "blob" });
    //         const response = apiResult as any;
    //         const url = window.URL.createObjectURL(new Blob([response]));
    //         const link = document.createElement("a");
    //         link.href = url;
    //         link.setAttribute("download", fileName);
    //         document.body.appendChild(link);
    //         link.click();
    //     } catch (exception) {
    //         console.error(exception);
    //         this.setIsErrored(true);
    //     }
    // };

    // public fileChange = async (event: any): Promise<void> => {
    //     if (event.target.files.length > 0) {
    //         let data: any = {
    //             fileName: event.target.files[0].name,
    //             formFile: event.target.files[0],
    //         };
    //         event.target.value = null;
    //         const apiResult = await this.fileUpload(data);
    //         if (apiResult && apiResult.wasSuccessful) {
    //             let fileToDisplay: ApprovalDocumentDTO = {
    //                 id: null,
    //                 fileUrl: apiResult.payload,
    //                 fileName: data.fileName,
    //                 isDeleted: false,
    //                 createdByUserId: null,
    //                 createdDate: null,
    //                 parentId: null,
    //             };
    //             let model = new ApprovalDocumentModel();
    //             model.fromDto(fileToDisplay);
    //             this.setApprovalDocument(model);
    //         }
    //     }
    // };

    // public handleCancelAmend = () => {
    //     this.model.approvalDocuments = [];
    // };

    // #region Server Actions
    // #endregion Server Actions

    // #region Client Actions
    // #endregion Client Actions

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<any>): Promise<boolean> {
        return true;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
