// Note: Stolen from HBP drawings.
/**
 * Generate or increment the next revision string.
 * @param currentRevision The current revision string.
 * @returns The new revision string.
 */
export const generateOrIncrementRevision = (currentRevision: string | null) => {
    // If there's no current revision, just set it to "A".
    if (currentRevision === null) {
        return "A";
    }

    let currentRevisionUpper: string = currentRevision.toUpperCase();

    // Check if rcurrentRevision is all 'Z's
    if (isSame(currentRevisionUpper, "Z")) {
        // Handle resetting from 'Z's to 'A's and append new character to the end.
        let newRevision: string = "";
        let i: number = currentRevisionUpper.length;

        while (i--) {
            newRevision += "A";
        }

        return newRevision + "A";
    } else {
        // Increment the last character and decrement the character before it if necessary.
        let p: string = "";
        let q: string = "";

        if (currentRevisionUpper.length > 1) {
            p = currentRevisionUpper.substring(0, currentRevisionUpper.length - 1);
            q = String.fromCharCode(p.slice(-1).charCodeAt(0));
        }

        let currentRevisionLastLetterCharCode: number = currentRevisionUpper.slice(-1).charCodeAt(0);
        let nextLetter: string = getNextLetter(currentRevisionLastLetterCharCode);

        if (nextLetter === "A") {
            return p.slice(0, -1) + getNextLetter(q.slice(-1).charCodeAt(0)) + nextLetter;
        } else {
            return p + nextLetter;
        }
    }
};

/**
 * Get the next letter, or restart the sequence back to "A".
 * @param l Current letter as number char code.
 * @returns Next letter as string.
 */
const getNextLetter = (l: number) => {
    if (l < 90) {
        return String.fromCharCode(l + 1);
    } else {
        return "A";
    }
};

/**
 * Check if every character in a string matches a character.
 * @param str Revision string.
 * @param char Character to compare.
 * @returns True if matches.
 */
const isSame = (str: string, char: string) => {
    let i: number = str.length;

    while (i--) {
        if (str[i] !== char) {
            return false;
        }
    }

    return true;
};
