import { Box, Button, Link, Typography, useMediaQuery } from "@material-ui/core";
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components/macro";
import { palette, theme } from "../../Globals/Styles/AppTheme";
import * as sh from "@shoothill/core";
import { Grid } from "@shoothill/core";
import { AppUrls } from "AppUrls";

const Main = styled.div`
    margin-top: auto;
    .MuiTypography-h6 {
        text-transform: none;
    }
    a {
        text-decoration: none;
    }

    h6 {
        font-weight: bold;
        font-size: 0.95rem !important;
    }

    @media (max-width: 767px) {
        svg.logo {
            max-width: 75%;
        }

        .MuiTypography-body1 {
            font-size: 0.75rem;
        }

        h6 {
            font-weight: bold;
            font-size: 0.75rem;
        }
    }
`;

const SocialsGrid = styled(Grid)`
    @media (max-width: 767px) {
        justify-items: center;
    }
`;

const ExternalLink = styled.a`
    color: ${palette.secondary.main};
    font-size: 1em;
`;

export const Footer: React.FC = () => {
    const isMobile = useMediaQuery("(max-width: 767px)");

    const mainText = (
        <Box textAlign={isMobile ? "center" : "right"} paddingTop={isMobile ? "25px" : "0"} style={{ cursor: "pointer" }}>
            <Button>
                <Link component={RouterLink} to={AppUrls.Client.Home}>
                    <Typography variant={"caption"} color={"textSecondary"}>
                        Home
                    </Typography>
                </Link>
            </Button>
            <Button>
                <Link component={RouterLink} to="/faq">
                    <Typography variant={"caption"} color={"textSecondary"}>
                        How does it work?
                    </Typography>
                </Link>
            </Button>
            <Button>
                <Link component={RouterLink} to="/contact">
                    <Typography variant={"caption"} color={"textSecondary"}>
                        Contact
                    </Typography>
                </Link>
            </Button>
            <Button>
                <Link component={RouterLink} to={AppUrls.Client.Account.Login}>
                    <Typography variant={"caption"} color={"textSecondary"}>
                        Login
                    </Typography>
                </Link>
            </Button>
        </Box>
    );

    const copyright = (
        <Box paddingTop={isMobile ? "10px" : "20px"} textAlign={isMobile ? "center" : undefined}>
            <sh.TypographyX customcolor="white" variant={isMobile ? "h6" : undefined}>
                <strong>&copy; {new Date().getFullYear()} Shoothill Ltd</strong>
            </sh.TypographyX>
        </Box>
    );

    return (
        <>
            <div></div>
        </>
    );
};
