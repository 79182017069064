import styled from "styled-components";
import { theme } from "../../Globals/Styles/AppTheme";

interface ITabsContainerProps {
    tabWidth?: number;
    tabPadding?: string;
}

export const TabsContainer = styled.div<ITabsContainerProps>`
    display: flex;

    div.tabs-padding {
        min-width: 30px;
        border-bottom: 1px solid #d4d4d4;

        &.right {
            flex-grow: 1;
        }
    }

    div.tab-back,
    div.tab {
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;
        cursor: pointer;
        outline: none;
    }

    div.tab-back {
        padding-right: 30px;
        border-bottom: 1px solid #d4d4d4;
        font-size: 10px;

        .back-icon {
            margin-left: -10px;
            color: ${theme.palette.primary.main};
            font-size: 18px;
            font-family: "Open Sans", "Roboto Condensed", "Helvetica", "Arial", "sans-serif";
            display: flex;
            margin-bottom: 1px;
        }
    }

    div.tab {
        padding: ${(props) => (props.tabPadding === undefined ? "10px 30px" : props.tabPadding)};
        min-width: ${(props) => (props.tabWidth === undefined ? undefined : `${props.tabWidth}px`)};
        border: 1px solid #d4d4d4;
        font-size: 14px;
        background-color: #ededed;

        &.selected {
            border-bottom-color: transparent;
            background-color: white;
        }
    }

    div.tab ~ div.tab {
        border-left: none;
    }

    div.tabs-extraComponent {
        padding-left: 20px;
        border-bottom: 1px solid #d4d4d4;
    }
`;
