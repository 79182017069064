import { getHistory, FieldType, isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { action, computed } from "mobx";
import { PermitQuestionAnswerModel, PermitQuestionFormTypeEnum } from "./PermitQuestionAnswerModel";

export class PermitQuestionAnswerViewModel extends ViewModelBase<PermitQuestionAnswerModel> {
    // #region Constructors and Disposers

    constructor(questionAnswer: PermitQuestionAnswerModel) {
        super(questionAnswer);
        this.setDecorators(PermitQuestionAnswerViewModel);
    }

    // #region Properties

    // #endregion Properties

    @action
    public reset = () => {
        this.model.reset();
    };

    // #endregion Client Actions

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<PermitQuestionAnswerModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        switch (fieldName) {
            case "answerText": {
                if (this.model.formType === PermitQuestionFormTypeEnum.TextInput) {
                    const result = this.validateAnswerText;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                }
                break;
            }
            case "answerRadio": {
                if (this.model.formType === PermitQuestionFormTypeEnum.RadioButtons) {
                    const result = this.validateAnswerRadio;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                }
                break;
            }
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    @computed
    private get validateAnswerText() {
        const errorMessage = this.model.validateAnswerText;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateAnswerRadio() {
        const errorMessage = this.model.validateAnswerRadio;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
