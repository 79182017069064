import { ModelBase } from "@shoothill/core";
import { IsNotEmpty } from "class-validator";
import { action, observable } from "mobx";

import { InductionType } from "Globals/Models/Enums/InductionType";

export class Step1Model extends ModelBase<Step1Model> {
    // #region Defaults

    public static readonly DEFAULT_INDUCTIONTYPEID = null;
    public static readonly DEFAULT_INDUCTIONTYPES = [];

    // #endregion Defaults

    // #region Properties

    public id: string = "";

    public inductionTypeId: string | null = Step1Model.DEFAULT_INDUCTIONTYPEID;

    @observable
    public inductionTypes = observable<InductionType>(Step1Model.DEFAULT_INDUCTIONTYPES);

    @observable
    @IsNotEmpty({ message: "You must take your picture" })
    public photoData: string = "";

    // #endregion Properties

    constructor() {
        super();
    }

    // #region Actions

    @action
    public fromDto(dto: any): void {
        throw new Error("Method not implemented.");
    }

    public toDto(): any {
        return {
            id: this.id,
            photoData: this.photoData,
        };
    }

    // #endregion Actions
}
