import React from "react";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { Step1ViewModel } from "./Step1ViewModel";
import { WizardButtonsView } from "../WizardButtons";
import { SHBox } from "../../../Components/Box";
import { ImageUploadPlaceHolder } from "../../../Content/ImageUploadPlaceHolder";
import { PrimaryButton } from "../../../Components/Buttons/Buttons";
import Camera from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import styled from "styled-components";
import "react-image-crop/dist/ReactCrop.css";
import faceOutline from "Content/faceoutline.png";
import { Loader } from "@shoothill/core";
import { InductionViewModel } from "../InductionViewModel";

const Overlay = styled(SHBox)`
    width: 100px;
    height: 100px;
    background-color: red;
    z-index: 10000;
`;
const PreviewImage = styled.img`
    width: 256px;
    height: 256px;
    border-radius: 100%;
    border: 10px solid #6c93a6;
`;
const CameraWrapper = styled(SHBox)`
    div:first-child {
        width: 100%;
        display: flex;
        justifycontent: center;
        alignitems: center;

        img {
            position: absolute;
            height: auto;
            opacity: 0.6;
            z-index: 1;
        }
    }
    @media only screen and (max-width: 800px) {
        #container-circles {
            bottom: 58px;
            left: 53%;

            #outer-circle {
                height: 50px;
                width: 50px;

                #inner-circle {
                    height: 30px;
                    width: 30px;
                    margin: -27px 0 0 -15px;
                }
            }
        }
    }
`;

interface Props {
    viewModel: Step1ViewModel;
    parentViewModel: InductionViewModel;
}

export const Step1View: React.FC<Props> = observer((props: Props) => {
    const { viewModel } = props;

    function handleTakePhoto(dataUri: string) {
        viewModel.turnOffCamera();

        let image = new Image();

        image.src = dataUri;
        image.onload = () => {
            let canvas = document.createElement("CANVAS") as HTMLCanvasElement;
            let ctx = canvas.getContext("2d");
            let dataURL;
            canvas.height = 300;
            canvas.width = 300;
            ctx!.drawImage(image, -170, -90, image.width, image.height);
            dataURL = canvas.toDataURL("image/png");
            viewModel.savePicture(dataURL);
        };
    }

    if (props.parentViewModel.isStageLoading) {
        return <Loader />;
    }

    return (
        <>
            <SHBox showIf={!viewModel.takingPicture}>
                <SHBox grid dc="256px 350px 1fr" mc={"1fr"} mt={3} ml={3} mr={"3"} alignItems={"center"} justifyItems={"center"}>
                    <SHBox>{viewModel.model.photoData === "" ? <ImageUploadPlaceHolder /> : <PreviewImage src={viewModel.model.photoData} />}</SHBox>
                    <SHBox>
                        <p>Please take a picture of yourself for identification.</p>
                        <SHBox flexBox mt={3}>
                            <PrimaryButton
                                displayName={viewModel.startCamera ? "Starting camera..." : "Take Picture"}
                                execute={props.viewModel.takePicture}
                                fullWidth={true}
                                canExecute={props.viewModel.canTakePicture}
                            />
                            {/*<PrimaryButton displayName="Upload Picture" execute={props.viewModel.uploadPicture} fullWidth={true} canExecute={true} />*/}
                        </SHBox>
                    </SHBox>
                </SHBox>
                <WizardButtonsView nextStep={viewModel.nextStep} previousStep={viewModel.previousStep} canExecute={viewModel.canExecuteNextStep} />
            </SHBox>
            <CameraWrapper showIf={viewModel.startCamera}>
                <SHBox style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {viewModel.takingPicture && <img src={faceOutline} style={{ width: viewModel.isMobile ? "50vw" : "412px" }} />}
                    <Camera
                        onTakePhoto={(dataUri) => {
                            handleTakePhoto(dataUri);
                        }}
                        onCameraStart={() => {
                            runInAction(() => {
                                viewModel.takingPicture = true;
                            });
                        }}
                    />
                </SHBox>
            </CameraWrapper>
        </>
    );
});
