// Libraries
import React from "react";
import { useObserver } from "mobx-react-lite";
import { useRouter } from "@shoothill/core";

// Custom
import { IEViewModel } from "../IE.ViewModel";
import { PackagesViewModel } from "../PackagesViewModel";
import { formatCurrencyForGrid, formatCurrencyNoSign } from "Utils/Format";
import { isNullOrUndefined } from "@shoothill/core";

// Styling & Images
import { SubsGridView } from "./IEGrid.Styles";
import { IEGridModel } from "../IEmodels/IEGridModel";
import { formatCreditDebitClass, formatCreditDebitReverseClass } from "Utils/Utils";

interface ITableProps {
    viewModel: IEViewModel;
    packagesViewModel: PackagesViewModel;
    parentId: string;
    chosenId(saved: string): void;
    rowTitle(title: string | undefined): void;
    IEid: string;
}

export const IEGridViewSubs: React.FunctionComponent<ITableProps> = (props) => {
    const router = useRouter();
    const { history } = useRouter();
    const { viewModel, chosenId } = props;

    const formatDebit = (val: number) => {
        return val < 0 ? " debit" : "";
    };

    const handleRowClick = (val: string, title: string | undefined) => {
        chosenId(val);
        props.rowTitle(!isNullOrUndefined(title) ? title : "");
    };

    return useObserver(() => (
        <>
            {viewModel.filterIEBySub.length > 0 && (
                <>
                    <span style={{ letterSpacing: "-0.36px", fontSize: "18px", marginLeft: "35px" }}>Sub I & E</span>
                    <SubsGridView>
                        {viewModel.filterIEBySub.map((obj: IEGridModel, index: number) => (
                            <>
                                <li className="ie" key={"ie_" + index} style={{ fontStyle: "italic", color: "#6C93A6" }}>
                                    <div style={{ paddingLeft: "25px" }}>{obj.name}</div>
                                    <div className="right">{formatCurrencyNoSign(obj.income)}</div>
                                    <div className="right">{formatCurrencyNoSign(obj.estimateCost)}</div>
                                    <div className="right">{obj.margin}</div>
                                    <div className={"right" + formatDebit(obj.committedCost)}>{formatCurrencyNoSign(obj.committedCost)}</div>
                                    <div className="right">{formatCurrencyNoSign(obj.futureSpend)}</div>
                                    <div className="right">{formatCurrencyNoSign(obj.totalExpectedSpend)}</div>
                                    <div className={"right" + formatCreditDebitClass(obj.variance)}>{formatCurrencyNoSign(obj.variance)}</div>
                                    <div className={"right" + formatCreditDebitReverseClass(obj.roValue)}>{formatCurrencyNoSign(obj.roValue)}</div>
                                    <div
                                        onClick={() => handleRowClick(obj.id, obj.name)}
                                        style={{ cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "space-around" }}
                                    ></div>
                                </li>
                            </>
                        ))}
                        <li className="ie">
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <span style={{ paddingLeft: "5px" }}>Subtotal</span>
                            </div>
                            <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(viewModel.ieGridViewModel.model.incomeSub))}</div>
                            <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(viewModel.ieGridViewModel.model.estimateCostSub))}</div>
                            <div className="right">{viewModel.ieGridViewModel.model.marginSub}</div>
                            <div className={"right" + formatDebit(viewModel.ieGridViewModel.model.committedCostSub)}>
                                {formatCurrencyForGrid(formatCurrencyNoSign(viewModel.ieGridViewModel.model.committedCostSub))}
                            </div>
                            <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(viewModel.ieGridViewModel.model.futureSpendSub))}</div>
                            <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(viewModel.ieGridViewModel.model.totalExpectedSpendSub))}</div>
                            <div className={"right" + formatCreditDebitClass(viewModel.ieGridViewModel.model.varianceSub)}>
                                {formatCurrencyForGrid(formatCurrencyNoSign(viewModel.ieGridViewModel.model.varianceSub))}
                            </div>
                            <div className={"right" + formatCreditDebitReverseClass(viewModel.ieGridViewModel.model.roValueSub)}>
                                {formatCurrencyForGrid(formatCurrencyNoSign(viewModel.ieGridViewModel.model.roValueSub))}
                            </div>
                            <div className="right" style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}></div>
                        </li>
                    </SubsGridView>
                </>
            )}
        </>
    ));
};
