import { observable } from "mobx";

import { ApprovalPanelModelBase, ApprovalStatusBaseDTO, ApprovalPanelBaseDTO } from "Globals/Models/ApprovalPanelModelBase";
import { ApprovalDocumentDTO, ApprovalDocumentModel } from "Components/Approval/ApprovalDocumentModel";

export class DrawingApprovalPanelModel extends ApprovalPanelModelBase<DrawingApprovalPanelModel> {
    // #region Properties
    // #endregion Properties
    // #region Actions
    // #endregion Actions
}

export interface DrawingApprovalPanelDTO extends ApprovalPanelBaseDTO {}

export interface DrawingApprovalStatusDTO extends ApprovalStatusBaseDTO {}
