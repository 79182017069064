import React, { useEffect } from "react";
import { Grid, useObserver, useRouter } from "@shoothill/core";
import MaterialTable from "material-table";
import * as Defaults from "Globals/Defaults/TableOptions";
import { DarwinTablePageContent } from "Globals/Styles/AppStyling";
import { ProjectSiteAccessItemViewModel } from "./ProjectSiteAccessItemViewModel";
import { styled } from "@shoothill/core";
import { CustomHr } from "../CustomComponents";
import { Box, Checkbox, FormControlLabel } from "@material-ui/core";
import moment from "moment";
import { PrimaryButton } from "Components/Buttons/Buttons";
import { pxToRem } from "Globals/Styles/AppTheme";
import { SiteAccessViewModel } from "./SiteAccessViewModel";
import { DeleteModal } from "Components/Modal/DeleteModal";
import { SiteAccessFilterViewModel } from "./SiteAccessFilterViewModel";
import { DarwinInput } from "Globals/Styles/Control/DarwinInput";
import { PatchedPagination } from "../../../Components/Table/PatchedPagination";
import { DarwinDateSelect } from "Components/DateSelect/DarwinDateSelect";

interface ISiteAccessTableProps {
    data: ProjectSiteAccessItemViewModel[];
    isLoading: boolean;
    handleChangeStartDate: (startDate: string | null) => void;
    handleChangeEndDate: (endDate: string | null) => void;
    handleResetDateFilters: () => void;
    startDateValue: string | null;
    endDateValue: string | null;
    handleForceSignoutInductions: () => void;
    handleSearchChange: (val: string) => void;
    handleSignoutFilterChange: (val: boolean) => void;
    onSiteFilterValue: boolean;
    handleDownloadCSV: () => void;
}

moment.locale("en-GB", {
    week: {
        dow: 1,
    },
});

export const SiteAccessTable: React.FunctionComponent<ISiteAccessTableProps> = (props: ISiteAccessTableProps) => {
    const { history } = useRouter();
    //useEffect below only gets run on initial render
    useEffect(() => {
        return () => {};
    }, []);

    const [deleteOpen, setDeleteOpen] = React.useState(false);

    const onInductionStartDateChanged = (date: string | null) => {
        props.handleChangeStartDate(date);
    };

    const onInductionEndDateChanged = (date: string | null) => {
        props.handleChangeEndDate(date);
    };

    const resetInductionDateFilters = () => {
        props.handleResetDateFilters();
    };

    const handleDownloadCSV = () => {
        props.handleDownloadCSV();
    };

    const handlesignoutInductions = () => {
        setDeleteOpen(true);
    };

    const handleDeleteClose = () => {
        setDeleteOpen(false);
    };

    const handleSignOutInductions = () => {
        if (SiteAccessViewModel.Instance.getItemsToSignOut.length > 0) {
            props.handleForceSignoutInductions();
            setDeleteOpen(false);
        }
    };

    const renderPage = () => {
        return (
            <>
                <SubTitleContainer>
                    <p>Visitor/Contractor ins & outs</p>
                    <div>
                        <PrimaryButton
                            displayName="Download spreadsheet"
                            execute={handleDownloadCSV}
                            fullWidth={true}
                            canExecute={!props.isLoading && SiteAccessViewModel.Instance.siteAccessItemViewModels.length > 0}
                        />
                        <p>Spreadsheet will download filtered results as they appear below</p>
                    </div>
                </SubTitleContainer>
                <DateFilterContainer className="dateField">
                    <Box maxWidth="150px" marginRight={"30px"}>
                        <DarwinDateSelect
                            displayName="Start date(IW 14):"
                            execute={(value: string | null) => onInductionStartDateChanged(value)}
                            placeholder="Please select"
                            value={props.startDateValue}
                            maxDate={moment.utc().toDate()}
                            clearable
                            format="DD/MM/YYYY"
                            canExecute={!props.isLoading}
                        />
                    </Box>
                    <Box maxWidth="150px">
                        <DarwinDateSelect
                            displayName="End date (IW 14):"
                            execute={(value: string | null) => onInductionEndDateChanged(value)}
                            placeholder="Please select"
                            value={props.endDateValue}
                            maxDate={moment.utc().toDate()}
                            clearable
                            format="DD/MM/YYYY"
                            canExecute={!props.isLoading}
                            defaultToStartOfDay={false}
                        />
                    </Box>
                    <PrimaryButton displayName="Reset date" execute={resetInductionDateFilters} fullWidth={true} canExecute={!props.isLoading} />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={props.onSiteFilterValue}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => props.handleSignoutFilterChange(checked)}
                                disabled={props.isLoading}
                            />
                        }
                        label={"Show people on site"}
                        name={`show-on-site-checkbox`}
                        disabled={props.isLoading}
                        labelPlacement={"top"}
                        style={{ marginBottom: "-10px" }}
                    />
                </DateFilterContainer>

                <SearchBarContainer>
                    <DarwinInput
                        type="text"
                        label="Search"
                        validateOnBlur={true}
                        viewModel={SiteAccessFilterViewModel.Instance}
                        fieldName="searchText"
                        shrink={true}
                        maxLength={128}
                        onChange={(e: any) => props.handleSearchChange(e.target.value)}
                    />

                    <ForceSignOutContainer>
                        <ForceSignOutButton
                            displayName="Force sign out"
                            execute={() => handlesignoutInductions()}
                            fullWidth={true}
                            canExecute={!props.isLoading && SiteAccessViewModel.Instance.getItemsToSignOut.length > 0 && SiteAccessViewModel.Instance.hasSignoutItems}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={SiteAccessViewModel.Instance.allItemsSelected}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => SiteAccessViewModel.Instance.handleSelectAll(checked)}
                                    disabled={SiteAccessViewModel.Instance.siteAccessItemViewModels.length === 0 || !SiteAccessViewModel.Instance.hasSignoutItems}
                                />
                            }
                            label={"Select all"}
                            name={`force-sign-out-all-checkbox`}
                            disabled={SiteAccessViewModel.Instance.siteAccessItemViewModels.length === 0 || !SiteAccessViewModel.Instance.hasSignoutItems}
                        />
                    </ForceSignOutContainer>
                </SearchBarContainer>

                <Grid padding={"0px 30px"}>
                    <span className="small-title">Showing: {props.data.length} results</span>
                </Grid>
                <CustomHr margin={"6px 0"} padding={"0px 30px"} />
                <DarwinTablePageContent>
                    <MaterialTable
                        columns={[
                            {
                                title: "",
                                field: "isSignIn",
                                align: "center",
                                render: (rowData: ProjectSiteAccessItemViewModel) =>
                                    rowData.isSignIn ? (
                                        <SignInIconContainer>
                                            IN
                                            <GreenTriangleIcon />
                                        </SignInIconContainer>
                                    ) : (
                                        <SignOutIconContainer>
                                            <RedTriangleIcon />
                                            OUT
                                        </SignOutIconContainer>
                                    ),
                            },
                            {
                                title: "Date / time",
                                field: "visitDate",
                                render: (rowData: ProjectSiteAccessItemViewModel) => rowData.visitDateFormatted,
                            },
                            {
                                title: "Visitor",
                                field: "visitorFullName",
                                render: (rowData: ProjectSiteAccessItemViewModel) => (
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <InductionPhotoContainer>
                                            <img src={rowData.model.photographUrl} alt="Induction photo" />
                                        </InductionPhotoContainer>
                                        {rowData.visitorFullName}
                                    </div>
                                ),
                            },
                            { title: "Employer name", field: "priorityEmployerName" },
                            {
                                title: "Last 3 digits",
                                field: "lastThreePhoneDigits",
                                render: (rowData: ProjectSiteAccessItemViewModel) => {
                                    return <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>{rowData.lastThreePhoneDigitsFormatted}</div>;
                                },
                            },
                            { title: "Inductee type", field: "visitorTypeName", align: "center" },
                            { title: "RAMS form", field: "ramsTitle", align: "center" },
                            { title: "Health problems", field: "healthProblemsFormatted", align: "center" },
                            {
                                title: "",
                                field: "forceSignOut",
                                render: (rowData: ProjectSiteAccessItemViewModel) =>
                                    !rowData.model.hasSignedOut && (
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={SiteAccessViewModel.Instance.getItemsToSignOut.findIndex((i) => i === rowData.id) !== -1}
                                                        onChange={() => SiteAccessViewModel.Instance.changeItemToSignOut(rowData.id)}
                                                    />
                                                }
                                                label={""}
                                                name={`force-sign-out-checkbox`}
                                            />
                                        </div>
                                    ),
                            },
                        ]}
                        components={{
                            Pagination: PatchedPagination,
                        }}
                        options={Defaults.GetDarwinTableOptionsNoSearchV2()}
                        data={props.data}
                        isLoading={props.isLoading}
                        title=""
                    />
                </DarwinTablePageContent>
                <DeleteModal
                    open={deleteOpen}
                    onClose={handleDeleteClose}
                    onDelete={handleSignOutInductions}
                    title="Signout inductions?"
                    text={`Are you sure you want to signout the selected inductions?`}
                />
            </>
        );
    };

    return useObserver(() => renderPage());
};

export const InductionPhotoContainer = styled.div`
    margin-right: 20px;

    > img {
        border-radius: 50%;
        width: 32px;
        height: 32px;
        border: 1px solid #85b5cc;
    }
`;

export const TriangleContainer = styled.div`
    position: relative;
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;

    ::before {
        content: "";
        position: absolute;
        top: -50px;
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent;
    }
`;

export const GreenTriangleIcon = styled(TriangleContainer)`
    border-left: 16px solid #449b23;
    margin-left: 10px;

    ::before {
        left: 100px;
        transform: rotate(90deg);
    }
`;

export const RedTriangleIcon = styled(TriangleContainer)`
    border-right: 16px solid #9b2323;
    margin-right: 10px;

    ::before {
        content: "";
        left: -100px;
        transform: rotate(-90deg);
    }
`;

export const SignIconContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
`;

export const SignInIconContainer = styled(SignIconContainer)`
    color: #449b23;
`;

export const SignOutIconContainer = styled(SignIconContainer)`
    color: #9b2323;
`;

const DateFilterContainer: any = styled.div`
    display: flex;
    flex-direction: row;
    align-items: end;
    padding: 0px 30px 0px;

    > label {
        width: unset;

        > div {
            > div {
                margin: 0px;
            }
        }
    }

    > button {
        width: auto;
        white-space: nowrap;
        min-width: 110px;
        margin-left: 30px;

        span {
            font-size: ${pxToRem(14)} !important;
        }
    }
`;

export const ForceSignOutButton = styled(PrimaryButton)`
    margin-right: 20px;
`;

export const ForceSignOutContainer = styled.div`
    display: flex;
    align-self: end;
    align-items: center;

    > label {
        min-width: 100px;
    }
`;

export const SearchBarContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 30px 10px;

    > div:nth-child(1) {
        max-width: 330px;
        width: 100%;
    }
`;

export const SubTitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 30px 10px;

    > p {
        font-size: 18px;
        letter-spacing: -0.36px;
        font-weight: 600;
    }

    > div {
        button {
            margin-bottom: 8px;
        }

        p {
            font-size: 8px;
            font-weight: 600;
            line-height: 11px;
            letter-spacing: -0.16px;
        }
    }
`;
