import React, { useEffect, useState } from "react";
import { Grid, styled, useObserver, useRouter } from "@shoothill/core";
import { AppUrls } from "AppUrls";
import { DarwinTablePageContent, DarwinTableSearchBox, DarwinTableToolbar } from "Globals/Styles/AppStyling";
import { StatusCell } from "Globals/Styles/Control/StatusCell.style";
import { TableListSearch } from "Components/Table/TableListSearch";
import { InvoicingListItemViewModel } from "./InvoicingListItemViewModel";
import { MultiSelectView } from "Components/Select/MultiSelect";
import { InvoiceListFilterViewModel } from "./InvoiceListFilterViewModel";
import { InvoiceListViewModel } from "./InvoiceListViewModel";
import { ColumnHelper, createColumnHelper } from "@tanstack/react-table";
import { formatCurrencyFromPounds, formatDate, formatEmptyValueForTableDash } from "Utils/Format";
import { DarwinTableV2, PaginationDirection, SortOrderDirection } from "Components/Table/DarwinTableV2";

interface IProps {
    ieId: string;
}

export const InvoiceListView: React.FC<IProps> = (props: IProps) => {
    const { ieId } = props;
    const [viewModel] = useState(() => InvoiceListViewModel.Instance);
    const { history } = useRouter();
    const router = useRouter();

    const filtersViewModel = InvoiceListFilterViewModel.Instance;

    useEffect(() => {
        viewModel.apiGetAll(ieId);

        return () => {
            viewModel.cleanUp();
        };
    }, []);

    const handleRowClick = (rowId: string | number) => {
        const id = rowId as string;
        const url: string = AppUrls.Client.Invoicing.EditIE.replace(":ieid", ieId).replace(":invoiceid", id).replace(":approvalMode", "false");
        history.push(url);
    };

    const setSearchString = (value: string) => {
        viewModel.handleSearchChange(value, ieId);
    };

    const handleSorting = async (columnName: string, orderBy: SortOrderDirection) => {
        if (viewModel.hasLoaded) {
            filtersViewModel.handleSorting(columnName, orderBy);
            loadData();
        }
    };

    const handlePageChange = async (change: PaginationDirection) => {
        const pageNumber: number = filtersViewModel.model.pageNumber;
        const pageCount: number = filtersViewModel.model.pageCount;

        switch (change) {
            case PaginationDirection.NEXT:
                pageNumber < pageCount ? filtersViewModel.setPageNumber(pageNumber + 1) : filtersViewModel.setPageNumber(1);
                loadData();
                break;
            case PaginationDirection.BACK:
                pageNumber > 1 ? filtersViewModel.setPageNumber(pageNumber - 1) : filtersViewModel.setPageNumber(1);
                loadData();

                break;
            case PaginationDirection.START:
                filtersViewModel.setPageNumber(1);
                loadData();

                break;
            case PaginationDirection.END:
                filtersViewModel.setPageNumber(pageCount);
                loadData();

                break;
            default:
        }
    };

    const handleRowsPerPageChange = async (pageSize: number) => {
        filtersViewModel.handleRowsPerPageChange(pageSize);
        loadData();
    };

    const handlePageValueChange = async (page: number) => {
        filtersViewModel.setPageNumber(page);
        loadData();
    };

    const loadData = async () => {
        await viewModel.apiGetAll(ieId);
    };

    const columnHelper: ColumnHelper<InvoicingListItemViewModel> = createColumnHelper<InvoicingListItemViewModel>();

    const columns = [
        columnHelper.accessor("invoiceNumber", {
            header: () => "Invoice number",
            cell: (info) => <>{formatEmptyValueForTableDash(info.getValue())}</>,
            enableResizing: false,
        }),
        columnHelper.accessor("projectName", {
            header: () => "Project name",
            cell: (info) => <>{formatEmptyValueForTableDash(info.getValue())}</>,
            size: 20,
            enableResizing: false,
        }),
        columnHelper.accessor("invoiceDate", {
            header: () => "Invoice date",
            cell: (info) => <>{formatEmptyValueForTableDash(formatDate(info.getValue() !== null ? info.getValue()!.toString() : ""))}</>,
            size: 100,
            enableResizing: false,
        }),
        columnHelper.accessor("approverRoleName", {
            header: () => "Approver",
            cell: (info) => <>{formatEmptyValueForTableDash(info.getValue())}</>,
            enableResizing: false,
        }),
        columnHelper.accessor("supplierName", {
            header: () => "Supplier",
            cell: (info) => <>{formatEmptyValueForTableDash(info.getValue())}</>,
            enableResizing: false,
        }),
        columnHelper.accessor("statusName", {
            header: () => "Status",
            cell: (info) => (
                <StatusCell
                    className="Unknown"
                    style={{
                        backgroundColor: info.row.original.statusColor,
                        color: info.row.original.statusTextColor,
                        margin: "3px 0",
                        padding: "2px 0",
                    }}
                >
                    {info.row.original.statusNameFormatted}
                </StatusCell>
            ),
            enableResizing: false,
        }),
        columnHelper.accessor("invoiceValue", {
            header: () => "Value",
            cell: (info) => <div style={{ textAlign: "right" }}>{formatCurrencyFromPounds(info.getValue())}</div>,
            enableResizing: false,
        }),
        columnHelper.accessor("approvedDate", {
            header: () => "Approved date",
            cell: (info) => <>{formatEmptyValueForTableDash(formatDate(info.getValue() !== null ? info.getValue()!.toString() : ""))}</>,
            size: 100,
            enableResizing: false,
        }),
        columnHelper.accessor("postedDate", {
            header: () => "Posted Date",
            cell: (info) => <>{formatEmptyValueForTableDash(formatDate(info.getValue() !== null ? info.getValue()!.toString() : ""))}</>,
            size: 100,
            enableResizing: false,
        }),
    ];

    const renderPage = () => {
        return (
            <div style={{ width: "100%", paddingTop: 22 }}>
                <DarwinTableToolbar>
                    <FilterRow>
                        <MultiSelectView
                            style={{ maxWidth: "200px", marginRight: "20px" }}
                            display="Status"
                            fieldName="statuses"
                            values={filtersViewModel.model.statuses}
                            getOptions={filtersViewModel.getStatusOptions}
                            handleChange={(values: number[] | null) => viewModel.handleStatusChange(values, ieId)}
                        />
                        <MultiSelectView
                            style={{ maxWidth: "200px", marginRight: "20px" }}
                            display="Project"
                            fieldName="projects"
                            values={filtersViewModel.model.projects}
                            getOptions={filtersViewModel.getProjectOptions}
                            handleChange={(values: number[] | null) => viewModel.handleProjectChange(values, ieId)}
                        />
                        <MultiSelectView
                            style={{ maxWidth: "200px", marginRight: "20px" }}
                            display="Supplier"
                            fieldName="suppliers"
                            values={filtersViewModel.model.suppliers}
                            getOptions={filtersViewModel.getSupplierOptions}
                            handleChange={(values: number[] | null) => viewModel.handleSupplierChange(values, ieId)}
                        />
                    </FilterRow>
                    <br />
                    <Grid columnGap={30} dc={"1fr 1fr"} rowGap={15} tc={"1fr 1fr"}>
                        <DarwinTableSearchBox className="invoice-search-box">
                            <TableListSearch searchString={viewModel.getSearchStringComputed()} setSearchString={setSearchString} />
                        </DarwinTableSearchBox>
                    </Grid>
                </DarwinTableToolbar>
                <Grid margin={"20px 0"}>
                    <DarwinTablePageContent className="invoice-Table">
                        <DarwinTableV2
                            columns={columns}
                            data={viewModel.invoicingViewModels}
                            onRowClick={handleRowClick}
                            onSortChange={handleSorting}
                            onChangeRowPerPage={handleRowsPerPageChange}
                            onChangePage={handlePageChange}
                            onChangePageNumber={handlePageValueChange}
                            initialSortColumn={filtersViewModel.model.sortBy}
                            initialSortDirection={SortOrderDirection[filtersViewModel.model.sortDirection as keyof typeof SortOrderDirection]}
                            showPagination={true}
                            totalRowCount={filtersViewModel.model.totalCount}
                            totalPageCount={filtersViewModel.model.pageCount}
                            pageNumber={filtersViewModel.model.pageNumber}
                            pageSize={filtersViewModel.model.pageSize}
                            isProcessing={viewModel.IsLoading}
                        />
                    </DarwinTablePageContent>
                </Grid>
            </div>
        );
    };

    return useObserver(() => renderPage());
};

export const FilterRow = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 8px 0;
    align-items: flex-start;
`;
