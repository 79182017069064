import { ModelBase, isNullOrUndefined } from "@shoothill/core";
import { IsInt, IsNotEmpty, NotEquals } from "class-validator";
import { action, observable } from "mobx";

import { CalculatedAdjustedStockModel } from "../Shared/CalculatedAdjustedStock/CalculatedAdjustedStockModel";
import type { ICalculatedAdjustedStockDto } from "../Shared/CalculatedAdjustedStockDtos";

export class StockAdjustmentModel extends ModelBase<StockAdjustmentModel> {
    // #region Defaults

    public static readonly DEFAULT_ADJUSTMENTUNITS = 0;
    public static readonly DEFAULT_ADJUSTMENTNOTE = "";

    // #endregion Defaults

    // #region Related Properties

    @observable
    public calculatedAdjustedStock: CalculatedAdjustedStockModel | null = null;

    // #endregion Related Properties

    // #region Properties

    @observable
    @IsInt({ message: "Please provide an integer value" })
    @NotEquals(0, { message: "Please select a value" })
    public adjustmentUnits: number | string = StockAdjustmentModel.DEFAULT_ADJUSTMENTUNITS;

    @observable
    @IsNotEmpty({ message: "Please provide a note" })
    public adjustmentNote: string = StockAdjustmentModel.DEFAULT_ADJUSTMENTNOTE;

    // #endregion Properties

    // #region Methods

    @action
    public fromDto(dto: ICalculatedAdjustedStockDto): void {
        if (!isNullOrUndefined(dto)) {
            const calculatedAdjustedStock = new CalculatedAdjustedStockModel();

            calculatedAdjustedStock.fromDto(dto);

            this.calculatedAdjustedStock = calculatedAdjustedStock;
        } else {
            this.calculatedAdjustedStock = null;
        }
    }

    public toDto(): any {
        return {
            units: this.adjustmentUnits,
            note: this.adjustmentNote,
        };
    }

    // #endregion Methods
}
