import { theme } from "Globals/Styles/AppTheme";
import styled from "styled-components";

export const NavigationBarContainer = styled.div`
    display: flex;
    margin: 3px 10px 7px 10px;
    position: relative;
`;

export const NavigationBarArrow = styled.div`
    position: absolute;
    top: 5px;
`;

export const NavigationBarDisplayName = styled.div`
    margin-left: 20px;
    font-size: 18px;
    font-weight: bold;
    color: ${theme.palette.blue.main};
`;
