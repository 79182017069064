import { Button, Typography } from "@material-ui/core";
import * as React from "react";
import { ClientAddModalDialogPaper } from "./ClientAddModal.styles";
import { CancelButton } from "Globals/Styles/Control/Buttons";
import { ClientAddEditForm } from "./ClientAddEditForm";
import { ClientDetailViewModel } from "./ClientDetailViewModel";
import { BaseModal } from "Components/Modal/BaseModal";
import { Main } from "./ClientAddEdit.style";
import { Observer } from "mobx-react-lite";
import { StoresInstance } from "Globals/Stores";
import { ClientAddEditViewModel } from "./ClientAddEditViewModel";
import { ClientWithRelatedDTO } from "./ClientWithRelatedDTO";
import AddressViewModel from "Globals/ViewModels/AddressViewModel";
import { AddressModel } from "Globals/Models/Domain";
import { runInAction } from "mobx";

interface IProps {
    open?: boolean;
    onSave(editedClient: ClientWithRelatedDTO): any;
    onClose(): void;
    title: string;
    confirmText: string;
}

export const ClientAddEditModal: React.FC<IProps> = ({ open, onClose, onSave, title, confirmText }) => {
    const [viewModel, setViewModel] = React.useState<ClientAddEditViewModel>(ClientAddEditViewModel.Instance);
    const [errorMessage, setErrorMessage] = React.useState<string>("");

    //useEffect below only gets run on initial render
    React.useEffect(() => {
        return () => {
            viewModel.cleanUp();
            viewModel.addressFormViewModel.cleanUp();
        };
    }, []);

    React.useEffect(() => {
        const addModel: AddressModel = viewModel.getModelAddress;
        runInAction(() => {
            viewModel.addressFormViewModel = new AddressViewModel(addModel);
        });
    }, [viewModel.getAddressViewModels.length]);

    React.useEffect(() => {
        if (open) {
            setViewModel(ClientAddEditViewModel.Instance);
        }
    }, [open]);

    const onCloseLocal = (): void => {
        viewModel.cleanUp();
        viewModel.addressFormViewModel.cleanUp();
        viewModel.contactFormViewModel.cleanUp();
        onClose();
    };

    const onSaveLocal = () => {
        setErrorMessage("");
        // Added as a temporary fix if there is only ever one address. Otherwise this would be handled by the add button.
        viewModel.updateAddress(viewModel.addressFormViewModel.model.toAddressDto());
        let promise = viewModel.upsert();
        promise.then((response) => {
            if (response.wasSuccessful) {
                onSave(response.payload);
                onClose();
            } else {
                setErrorMessage("Please fix the following errors and re-submit");
            }
        });
    };

    return (
        <BaseModal
            open={open}
            onClose={onCloseLocal}
            title={title}
            PaperComponent={ClientAddModalDialogPaper}
            actions={
                viewModel ? (
                    <Observer>
                        {() => (
                            <>
                                <Button onClick={onSaveLocal} color="secondary" variant="contained" disabled={viewModel?.IsLoading}>
                                    {viewModel?.IsLoading ? "Saving..." : confirmText}
                                </Button>
                                <CancelButton onClick={onCloseLocal} disabled={viewModel?.IsLoading}>
                                    Cancel
                                </CancelButton>
                            </>
                        )}
                    </Observer>
                ) : (
                    <></>
                )
            }
        >
            {viewModel !== null && (
                <Main>
                    {errorMessage !== "" && (
                        <Typography variant="caption" style={{ color: "red" }}>
                            {errorMessage}
                        </Typography>
                    )}
                    <ClientAddEditForm viewModel={viewModel!} />
                </Main>
            )}
        </BaseModal>
    );
};
