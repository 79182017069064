import { ModelBase, observable } from "@shoothill/core";
import { action } from "mobx";
import { RFIFormFileModel } from "../../Forms/Common/RFIFormFileModel";
export class RFIFileModel extends ModelBase<RFIFileModel> {
    public static readonly DEFAULT_RFI_ID = "";

    @observable
    public rFIId: string = RFIFileModel.DEFAULT_RFI_ID;

    @observable
    public rFIFiles: RFIFormFileModel[] = [];

    @action
    public fromDto(dto: RFIFileResponseDTO): void {
        for (let key in dto) {
            if (dto.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(dto[key]);
                } else if (key === "rFIItems") {
                    // Do nothing.
                } else {
                    this[key] = dto[key];
                }
            }
        }
    }

    @action
    toDto(model: RFIFileModel): void {}
}

export interface RFIFileResponseDTO {
    rFIFiles: RFIFormFileModel[];
}

export interface RFIFileRequestDTO {
    id: string | null;
}
