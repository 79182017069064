import { action, observable, computed } from "mobx";
import { ModelBase } from "@shoothill/core";
import { IEGridModel, IEGridModelDTO } from "../IEmodels/IEGridModel";
import { InvoiceListBaseModelDTO } from "Views/Invoices/InvoiceListBaseModel";
import { InvoicingStatusTypeDTO } from "Views/Invoices/InvoicingStatusTypeModel";

export class CommercialGridModel extends ModelBase<CommercialGridModel, any> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Constants and Defaults

    public static readonly DEFAULT_ID = null;
    public static readonly DEFAULT_INCOMEANDEXPENDITUREID = null;
    public static readonly DEFAULT_PROJECTID = null;

    // #endregion Constants and Defaults

    // #region Properties

    @observable
    public id: string | null = CommercialGridModel.DEFAULT_ID;

    @observable
    public ieId: string | null = CommercialGridModel.DEFAULT_INCOMEANDEXPENDITUREID;

    @observable
    public projectId: string | null = CommercialGridModel.DEFAULT_PROJECTID;

    @observable
    public income: number = 0;

    @observable
    public estimateCost: number = 0;

    @observable
    public estimatedMargin: number = 0;

    @observable
    public futureSpend: number = 0;

    // @observable
    // public targetCost: number = 0;

    @observable
    public committedCost: number = 0;

    @observable
    public variance: number = 0;

    @observable
    public totalExpectedSpend: number = 0;

    @observable
    public roValue: number = 0;

    @observable
    public ie: IEGridModel[] = [];

    @computed
    public get getIEs() {
        return this.ie.slice();
    }

    // #endregion Properties

    // #region Actions

    @action
    public reset = () => {
        this.id = CommercialGridModel.DEFAULT_ID;
        this.projectId = CommercialGridModel.DEFAULT_PROJECTID; // Project (inc Stock)
        this.ieId = CommercialGridModel.DEFAULT_INCOMEANDEXPENDITUREID; // I & E (inc Stock)
    };

    fromDto(model: CommercialParentModelDTO): void {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    if (key === "ie") {
                        let ies: IEGridModel[] = [];
                        model.ie.forEach((item) => {
                            let newIe: IEGridModel = new IEGridModel();
                            newIe.fromDto(item);
                            ies.push(newIe);
                        });
                        this[key] = ies;
                    } else {
                        this[key] = model[key];
                    }
                }
            }
        }
    }

    public toDto(): void {}

    // #endregion Actions

    // #region Custom Validation

    // #endregion Business Logic
}

export type CommercialParentModelDTO = {
    id: string;
    createdDate: string;
    income: number;
    estimatedCost: number;
    estimatedMargin: number;
    futureSpend: number;
    targetCost: number;
    committedCost: number;
    totalExpectedSpend: number;
    variance: number;
    roValue: number;
    ie: IEGridModelDTO[];
    invoiceItem: InvoiceListBaseModelDTO[];
    invoiceStatusType: InvoicingStatusTypeDTO[];
};
