import { ModelBase } from "@shoothill/core";
import { observable, action, computed } from "mobx";

export class AccidentGenericListItemModel extends ModelBase<AccidentGenericListItemModel> {
    @observable
    public id: string | null = null;
    @observable
    public incidentAccidentId: string | null = null;
    @observable
    public notInSystem: string | null = null;
    @observable
    public isDeleted: boolean = false;
    @observable
    public createdByUserId: string | null = "";
    @observable
    public createdDate: string | null = "";
    @observable
    public displayName: string | null = null;
    @observable
    public typeId: string | null = null;
    @observable
    public isNotInSystem: boolean = false;
    constructor() {
        super();
    }

    @action
    public handleDelete = (val: boolean) => {
        this.isDeleted = val;
    };

    fromDto(model: AccidentGenericListItemDTO): void {
        this.id = model.id;
        this.notInSystem = model.notInSystem;
        this.isDeleted = model.isDeleted;
        this.createdByUserId = model.createdByUserId;
        this.createdDate = model.createdDate;
        this.incidentAccidentId = model.incidentAccidentId;
        this.displayName = model.displayName;
        this.typeId = model.typeId;
        this.isNotInSystem = model.isNotInSystem;
    }

    public static fromDtos(dtos: AccidentGenericListItemDTO[]): AccidentGenericListItemModel[] {
        const types: AccidentGenericListItemModel[] = [];

        for (const dto of dtos) {
            const model = new AccidentGenericListItemModel();

            model.fromDto(dto);
            types.push(model);
        }

        return types;
    }

    toDto(): AccidentGenericListItemDTO {
        return {
            id: this.id,
            notInSystem: this.isNotInSystem ? this.notInSystem : null,
            isDeleted: this.isDeleted,
            createdByUserId: this.createdByUserId,
            createdDate: this.createdDate,
            incidentAccidentId: this.incidentAccidentId,
            displayName: this.displayName,
            typeId: this.typeId,
            isNotInSystem: this.isNotInSystem,
        };
    }

    public toGenericListItemModel(): AccidentGenericListItemModel {
        return this;
    }
}

export interface AccidentGenericListItemDTO {
    id: string | null;
    notInSystem: string | null;
    isDeleted: boolean;
    createdByUserId: string | null;
    createdDate: string | null;
    incidentAccidentId: string | null;
    displayName: string | null;
    typeId: string | null;
    isNotInSystem: boolean;
}

export interface GenericViewListItemDTO {
    id: string;
    displayName: string | null;
}
