import { styled } from "@shoothill/core";

export const StatusCell = styled.div`
    position: relative;
    display: flex;
    flex: 1 0 0;
    flex-direction: column;

    align-items: center;
    text-align: center;
    vertical-align: middle;

    font-size: 12px;
    padding: 5px 0;
`;
