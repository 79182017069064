import { getHistory, FieldType, ViewModelBase } from "@shoothill/core";
import { action, computed, observable, runInAction } from "mobx";

import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { CommercialGridModel } from "./CommercialGrid.Model";
import { InvoicingListItemViewModel } from "Views/Invoices/InvoicingListItemViewModel";
import { InvoicingListModel } from "Views/Invoices/InvoicingListModel";
import { InvoicingStatusTypeModel } from "Views/Invoices/InvoicingStatusTypeModel";
import { InvoicingListItemsAndRelatedDTO } from "Views/Invoices/InvoicingListItemsAndRelatedDTO";

export class CommercialGridViewModel extends ViewModelBase<CommercialGridModel> {
    // #region Constructors and Disposers

    constructor() {
        super(new CommercialGridModel());

        this.setDecorators(CommercialGridViewModel);
    }

    @computed
    public get getInvoices(): InvoicingListItemViewModel[] {
        return this.invoicingViewModels;
    }

    @computed
    public get getInvoicesTotal(): number {
        return this.invoicingViewModels.reduce((a, b) => a + (b.model.invoiceValue || 0), 0);
    }

    @computed
    public get getTotal(): number {
        return this.model.committedCost + this.getInvoicesTotal;
    }

    @observable
    private invoicingViewModels: InvoicingListItemViewModel[] = [];

    @action
    public populateInvoiceListItemViewModels = (dto: InvoicingListItemsAndRelatedDTO) => {
        const invoicingViewModels2: InvoicingListItemViewModel[] = [];
        for (const invoicingListItem of dto.invoiceItem) {
            let itemModel = new InvoicingListModel();
            itemModel.fromDto(invoicingListItem);
            const statusTypeModel = new InvoicingStatusTypeModel(dto.invoiceStatusType.find((pst) => pst.id === invoicingListItem.invoiceStatusId));
            invoicingViewModels2.push(new InvoicingListItemViewModel(itemModel, statusTypeModel));
        }
        this.invoicingViewModels = invoicingViewModels2;
    };

    // #region Server Actions

    public server: ServerViewModel = new ServerViewModel();

    @action
    public reset = () => {
        this.model.reset();
        this.server.reset();
    };

    @action
    public handleCancel = (): void => {
        getHistory().goBack();
    };

    // #endregion Client Actions

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<CommercialGridModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    // #region Snackbar

    @observable
    public snackbarState = false;

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public snackMessage = "";

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @observable
    public snackType = "";

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    @observable
    public SNACKSUCCESS = "success";

    @observable
    public SNACKERROR = "error";
    // #endregion

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
