import { InputAdornment, TextField } from "@material-ui/core";
import { isEmptyOrWhitespace } from "@shoothill/core";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

import { DarwinSelect } from "Components/AutoComplete/DarwinSelect";
import { SHBox } from "Components/Box";
import { PrimaryButton, DefaultButton } from "Components/Buttons/Buttons";
import { DarwinDateSelect } from "Components/DateSelect/DarwinDateSelect";
import { DashedSectionSplitter, SolidSectionSplitter } from "Components/Form/SectionSplitter";
import { SnackBar } from "Components/SnackBar/SnackBar";
import { MaterialSubType } from "Globals/Models/Enums/MaterialSubType";
import { MaterialType } from "Globals/Models/Enums/MaterialType";
import { UnitOfMeasure } from "Globals/Models/Enums/UnitOfMeasure";
import { DarwinMoneyInput } from "Globals/Styles/Control/DarwinInput";
import { Form, FormSection } from "Views/PurchaseOrder/Form/Views/Forms";
import { ProjectButtonContainer } from "Views/Project/Project.styles";
import { InformationBarView } from "./Components/InformationBar/InformationBar";
import { NavigationBarView } from "./Components/NavigationBar/NavigationBar";
import { MaterialModel } from "./MaterialModel";
import { MaterialViewModel } from "./MaterialViewModel";
import { MaterialSupplier } from "../Shared/MaterialSupplier";
import { FormContent, FormRow } from "Views/Stock/Shared/Shared.styles";

export const MaterialView: React.FC = observer(() => {
    const [viewModel] = useState(() => new MaterialViewModel());

    const onSubmit = (event: any) => {
        event.preventDefault();
    };

    return (
        <Form onSubmit={onSubmit}>
            <NavigationBarView action={viewModel.close} displayName="Back to master materials" />
            <InformationBarView displayName="New material" />
            <DashedSectionSplitter />

            <FormContent>
                {/* Material Details */}
                <FormSection>
                    <FormRow grid dc={"1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr"}>
                        <SHBox dcs={"2"}>
                            <DarwinSelect
                                displayName="Type:"
                                execute={viewModel.setMaterialType}
                                fullWidth={true}
                                getOptionLabel={(option: MaterialType) => option?.displayName ?? ""}
                                options={viewModel.materialTypes}
                                placeholder="Please select"
                                validationMessage={viewModel.getError("materialType")}
                                value={viewModel.materialType}
                            />
                        </SHBox>
                        <SHBox dcs={"2"}>
                            <DarwinSelect
                                displayName="Sub-type:"
                                execute={viewModel.setMaterialSubType}
                                fullWidth={true}
                                getOptionLabel={(option: MaterialSubType) => option?.displayName ?? ""}
                                options={viewModel.materialSubTypes}
                                placeholder="Please select"
                                validationMessage={viewModel.getError("materialSubType")}
                                value={viewModel.materialSubType}
                            />
                        </SHBox>
                        <SHBox dcs={"2"}>
                            <TextField
                                className="textarea-forminput"
                                InputLabelProps={{ shrink: true }}
                                label="Supplier code:"
                                onBlur={() => viewModel.isFieldValid("supplierCode")}
                                onChange={(event) => viewModel.setValue("supplierCode", event.target.value)}
                                helperText={viewModel.getError("supplierCode")}
                                error={!isEmptyOrWhitespace(viewModel.getError("supplierCode"))}
                                value={viewModel.getValue("supplierCode")}
                            />
                        </SHBox>
                        <SHBox dcs={"2"}>
                            <DarwinSelect
                                displayName="UoM:"
                                execute={viewModel.setUnitOfMeasure}
                                fullWidth={true}
                                getOptionLabel={(option: UnitOfMeasure) => option?.displayName ?? ""}
                                options={viewModel.unitsOfMeasure}
                                placeholder="Please select"
                                validationMessage={viewModel.getError("unitOfMeasure")}
                                value={viewModel.unitOfMeasure}
                            />
                        </SHBox>
                    </FormRow>
                    <FormRow grid dc={"1fr"}>
                        <TextField
                            className="textarea-forminput"
                            InputLabelProps={{ shrink: true }}
                            label="Item description:"
                            onBlur={() => viewModel.isFieldValid("description")}
                            onChange={(event) => viewModel.setValue("description", event.target.value)}
                            helperText={viewModel.getError("description")}
                            error={!isEmptyOrWhitespace(viewModel.getError("description"))}
                            value={viewModel.getValue("description")}
                        />
                    </FormRow>
                </FormSection>

                <SolidSectionSplitter />

                {/* Material Price Details */}
                <FormSection>
                    <FormRow grid dc={"1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr"}>
                        <SHBox dcs={"2"}>
                            <DarwinSelect
                                displayName="Supplier:"
                                execute={viewModel.setSupplier}
                                fullWidth={true}
                                getOptionLabel={(option: MaterialSupplier) => option?.displayName ?? ""}
                                options={viewModel.suppliers}
                                placeholder="Please select"
                                validationMessage={viewModel.getError("supplier")}
                                value={viewModel.supplier}
                            />
                        </SHBox>
                        <SHBox dcs={"1"}>
                            <DarwinMoneyInput<MaterialModel>
                                fieldName="estimatedPrice"
                                InputProps={{ startAdornment: <InputAdornment position="start">£</InputAdornment> }}
                                label="Estimating price:"
                                maxLength={11}
                                placeholder=""
                                shrink={true}
                                type="number"
                                validateOnBlur={true}
                                viewModel={viewModel}
                            />
                        </SHBox>
                        <SHBox dcs={"1"}>
                            <DarwinMoneyInput<MaterialModel>
                                fieldName="price"
                                InputProps={{ startAdornment: <InputAdornment position="start">£</InputAdornment> }}
                                label="Purchase price:"
                                maxLength={11}
                                placeholder=""
                                shrink={true}
                                type="number"
                                validateOnBlur={true}
                                viewModel={viewModel}
                            />
                        </SHBox>
                        <SHBox dcs={"1"}>
                            <DarwinDateSelect
                                displayName="Purchase effective date:"
                                execute={viewModel.setEffectiveFromDate}
                                placeholder="dd/mm/yyyy"
                                hideFormHelperText={true}
                                validationMessage={viewModel.getError("effectiveFromDate")}
                                value={viewModel.getValue("effectiveFromDate")}
                            />
                        </SHBox>
                    </FormRow>
                    <FormRow grid dc={"1fr"}>
                        <TextField
                            className="textarea-forminput"
                            InputLabelProps={{ shrink: true }}
                            label="Notes:"
                            onBlur={() => viewModel.isFieldValid("note")}
                            onChange={(event) => viewModel.setValue("note", event.target.value)}
                            helperText={viewModel.getError("note")}
                            error={!isEmptyOrWhitespace(viewModel.getError("note"))}
                            value={viewModel.getValue("note")}
                        />
                    </FormRow>
                </FormSection>

                <SolidSectionSplitter />
            </FormContent>

            {/* Form buttons */}
            <ProjectButtonContainer style={{ marginTop: "20px", paddingBottom: "20px" }}>
                <PrimaryButton execute={viewModel.apiSaveMaterialAsync} displayName={"Submit"} canExecute={!viewModel.server.IsBusy} />
                <DefaultButton execute={viewModel.close} displayName={"Cancel"} />
            </ProjectButtonContainer>
            <SnackBar
                messageText={viewModel.snackMessage}
                messageType={viewModel.snackType}
                active={viewModel.snackbarState}
                closeOption={() => viewModel.setSnackbarState(false)}
                autoHideDuration={5000}
            />
        </Form>
    );
});
