import { FormControl, FormControlLabel, Radio, Typography } from "@material-ui/core";
import { useObserver } from "mobx-react-lite";
import styled from "styled-components";

import { RadioGroup } from "Globals/Styles/AdminStyling";

const QuestionRow = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;

    h5,
    fieldset {
        flex: 1;
    }

    h5 {
        font-weight: normal;
    }
`;

// #region Editable Component

interface ScaffoldInspectionRadioProps {
    value: () => string;
    validationMessage: () => string;
    onChange: (value: string) => void;
    title: string;
}

export function ScaffoldInspectionRadioView(props: ScaffoldInspectionRadioProps) {
    return useObserver(() => (
        <QuestionRow>
            <Typography variant={"h5"}>{props.title}</Typography>
            <FormControl component="fieldset">
                <RadioGroup row name={"radio-" + "" + "-group"} value={props.value()} onChange={(e: any) => props.onChange(e.target.value)} disabled={false}>
                    <FormControlLabel value="pass" control={<Radio color={"secondary"} />} label="Pass" />
                    <FormControlLabel value="fail" control={<Radio color={"secondary"} />} label="Fail" />
                </RadioGroup>
                <Typography variant={"h5"} color={"error"}>
                    {props.validationMessage()}
                </Typography>
            </FormControl>
        </QuestionRow>
    ));
}

// #region Editable Component

// #region ReadOnly Component

interface ScaffoldInspectionRadioReadOnlyProps {
    value: () => string;
    title: string;
}

export function ScaffoldInspectionRadioReadOnlyView(props: ScaffoldInspectionRadioReadOnlyProps) {
    return useObserver(() => (
        <QuestionRow>
            <Typography variant={"h5"}>{props.title}</Typography>
            <FormControl component="fieldset">
                <RadioGroup aria-label="passFail" name="passFail" value={props.value()}>
                    {props.value() === "pass" && <FormControlLabel value="pass" control={<Radio color={"secondary"} />} label="Pass" />}
                    {props.value() === "fail" && <FormControlLabel value="fail" control={<Radio color={"secondary"} />} label="Fail" />}
                </RadioGroup>
            </FormControl>
        </QuestionRow>
    ));
}

// #endregion ReadOnly Component
