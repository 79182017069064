import React, { useContext } from "react";
import { Stores, StoresContext } from "Globals/Stores";
import { PrivateRoute } from "Globals/Views/PrivateRoute";
import * as RouterUtil from "Utils/Routing";
import { StoresInstance } from "Globals/Stores";
import { AppUrls } from "../../AppUrls";
import { PurchaseOrderListView } from "./PurchaseOrderListView";
import { RejectedOrderListView } from "./RejectedOrderListView";
import { PurchaseOrderView } from "./Form/PurchaseOrderView";
import { DarwinLayout } from "Globals/Views/Layouts/DarwinLayout";
import { RequisitionsListView } from "Views/Project/Requisitions/RequisitionsListView";

export const PurchaseOrderRoutes: React.FC = () => {
    function getRootLayout() {
        return DarwinLayout;
    }
    const store = useContext<Stores>(StoresContext);
    return (
        <>
            <PrivateRoute
                exact
                path={AppUrls.Client.PurchaseOrder.List}
                component={PurchaseOrderListView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireNotSiteManagerLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />

            <PrivateRoute
                exact
                path={AppUrls.Client.PurchaseOrder.RequisitionList}
                component={RequisitionsListView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireNotSiteManagerLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />

            <PrivateRoute
                exact
                path={AppUrls.Client.PurchaseOrder.RejectedList}
                component={RejectedOrderListView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireNotSiteManagerLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />

            <PrivateRoute
                exact
                path={AppUrls.Client.PurchaseOrder.Add}
                component={PurchaseOrderView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireNotSiteManagerLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.PurchaseOrder.AddIE}
                component={PurchaseOrderView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireNotSiteManagerLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.PurchaseOrder.Edit}
                component={PurchaseOrderView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireNotSiteManagerLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />

            <PrivateRoute
                exact
                path={AppUrls.Client.PurchaseOrder.View}
                component={PurchaseOrderView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireNotSiteManagerLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
        </>
    );
};
