import { Box, Typography } from "@material-ui/core";
import { Cell, Grid } from "@shoothill/core";
import { observer } from "mobx-react-lite";
import React from "react";
import styled from "styled-components";

import { pxToRem } from "Globals/Styles/AppTheme";
import { DashedDivider, H2TextSolidDivider, SolidDivider } from "../Dividers";
import { PurchaseOrderViewModel } from "../../PurchaseOrderViewModel";
import { OrderLineView } from "../../Supporting/OrderLine/Form/OrderLineView";
import { BudgetForecastTable } from "../../Supporting/OrderLine/Tables/BudgetForecastTable";
import { RequisitionRequestItemGroupView } from "../../Supporting/OrderLine/Tables/RequisitionRequestGroupItemView";

interface IProps {
    viewModel: PurchaseOrderViewModel;
    refs: React.RefObject<HTMLDivElement>[];
}

export const OrderLineItemsView: React.FC<IProps> = observer((props) => {
    // #region Code Behind
    const { refs, viewModel } = props;

    const COLUMNS8 = "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr";

    // #endregion Code Behind

    return (
        <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
            {/* Group of 8 columns */}
            <Cell ts={8} ds={8}>
                <Typography variant="h2">Order line items</Typography>
                <H2TextSolidDivider />
            </Cell>

            {/* Group of 8 columns */}
            <Cell ts={8} ds={8}>
                <OrderLineView viewModel={viewModel.orderLine} isDisabled={viewModel.isFormDisabled || viewModel.isLineValidationLoading} />
            </Cell>

            {/* Group of 8 columns */}
            {viewModel.requisitionGroups && (
                <>
                    {viewModel.requisitionGroups.map((rq) => (
                        <Cell key={rq.key} ts={8} ds={8}>
                            <div ref={refs[rq.model.id!]}>{/* Used for the scrolling via the approval panel. */}</div>
                            <RequisitionRequestItemGroupView
                                viewModel={rq}
                                isDisabled={viewModel.isFormDisabled}
                                canShowBudgetForecast={viewModel.canShowBudgetForecast}
                                isCentral={viewModel.isCentral}
                            />
                        </Cell>
                    ))}
                    {viewModel.requisitionGroups.length > 1 && viewModel.canShowBudgetForecast && (
                        <>
                            <Cell ts={8} ds={8}>
                                <DashedDivider gutterBottom={true} />
                                <Typography variant="h2">Overall I and E summary (Excluding variations)</Typography>
                                <H2TextSolidDivider />
                            </Cell>
                            <Cell ts={8} ds={8}>
                                <Box>
                                    <BudgetForecastTable budgetForecast={viewModel.getBudgetForecastTotalFormatted} isTotal={true} />
                                </Box>
                            </Cell>
                        </>
                    )}
                </>
            )}

            {/* Group of 8 columns */}
            {viewModel.haveGroups && (
                <Cell ts={8} ds={8}>
                    <GroupsTotal>Total: {viewModel.total}</GroupsTotal>
                </Cell>
            )}

            {/* Group of 8 columns */}
            <Cell ts={8} ds={8}>
                <SolidDivider gutterBottom />
            </Cell>
        </Grid>
    );
});

const GroupsTotal = styled(Box)`
    background-color: #425b66;
    color: white;
    display: flex;
    font-size: ${pxToRem(18)};
    font-weight: bold;
    justify-content: end;
    margin-top: ${pxToRem(-1)};
    padding: ${pxToRem(4)} ${pxToRem(16)};
`;
