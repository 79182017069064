import { action, computed, observable } from "mobx";
import { TabItemViewModel } from "./TabItemViewModel";

export class TabsViewModel {
    public tabItemViewModels = observable<TabItemViewModel>([]);
    private onSetActiveTabItemViewModel: (vm: TabItemViewModel) => void;

    public constructor(tabItemViewModels: TabItemViewModel[], onSetActiveTabItemViewModel: (vm: TabItemViewModel) => void) {
        this.tabItemViewModels.replace(tabItemViewModels);
        this.onSetActiveTabItemViewModel = onSetActiveTabItemViewModel;
    }

    @action
    public setActiveTabItemViewModel = (value: TabItemViewModel) => {
        for (const tabItemViewModel of this.tabItemViewModels) {
            tabItemViewModel.isActive = tabItemViewModel.id === value.id;

            if (tabItemViewModel.isActive) {
                // SIDE-EFFECT.
                // Notify parent that the active tab has changed.
                this.onSetActiveTabItemViewModel(tabItemViewModel);
            }
        }
    };

    @computed
    public get activeTabItemViewModel() {
        return this.tabItemViewModels.find((vm) => vm.isActive);
    }
}
