import { action, observable, computed } from "mobx";
import { ModelBase } from "@shoothill/core";

export class InvoicingListFilterParamsBaseModel extends ModelBase<InvoicingListFilterParamsBaseModel, InvoicingListFilterParamsBaseModelDTO> {
    @observable
    public includeDeleted: boolean = false;

    @observable
    public statuses: string[] | null = [];

    @observable
    public projects: string[] | null = [];

    @observable
    public suppliers: string[] | null = [];

    @observable
    public searchText: string = "";

    @observable
    public pageNumber: number = 1;

    @observable
    public pageSize: number = 50;

    @observable
    public sortDirection: string = "DESC";

    @observable
    public sortBy: string = "invoiceDate";

    @observable
    public totalCount: number = 0;

    @computed
    public get pageCount() {
        return Math.ceil(this.totalCount / this.pageSize);
    }

    fromDto(model: InvoicingListFilterParamsBaseModelDTO): void {
        this.searchText = model.searchText;
    }

    toDto(): InvoicingListFilterParamsBaseModelDTO {
        let dto: InvoicingListFilterParamsBaseModelDTO = {
            includeDeleted: this.includeDeleted,
            statuses: this.statuses,
            projects: this.projects,
            suppliers: this.suppliers,
            searchText: this.searchText,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
            sortDirection: this.sortDirection.toUpperCase(),
            sortBy: this.sortBy,
            initialLoad: true,
        };
        return dto;
    }

    @action
    public reset = () => {
        this.statuses = [];
        this.projects = [];
        this.suppliers = [];
        this.searchText = "";
        this.pageNumber = 1;
        this.pageSize = 50;
        this.totalCount = 0;
        this.sortBy = "invoiceDate";
        this.sortDirection = "DESC";
    };
}

export interface InvoicingListFilterParamsBaseModelDTO {
    includeDeleted: boolean;
    statuses: string[] | null;
    projects: string[] | null;
    suppliers: string[] | null;
    searchText: string;
    pageNumber: number;
    pageSize: number;
    sortDirection: string;
    sortBy: string;
    initialLoad: boolean;
}
