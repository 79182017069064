import * as MobX from "mobx";
import { ModelBase } from "@shoothill/core";

export class InductionListModel extends ModelBase<InductionListModel, InductionListModelDTO> {
    constructor(model: InductionListModelDTO | null = null) {
        super();
        if (model) {
            this.fromDto(model);
        }
    }

    @MobX.observable
    public id: string = "";

    @MobX.observable
    public firstName: string = "";
    @MobX.observable
    public lastName: string = "";

    @MobX.observable
    public priorityEmployerName: string = "";

    @MobX.observable
    public age: number = 0;

    @MobX.observable
    public visitorTypeName: string = "";

    @MobX.observable
    public hasHealthProblems: boolean = false;

    @MobX.observable
    public lastSignedInDate: Date | null = null;

    @MobX.observable
    public inductedDate: Date | null = null;

    @MobX.observable
    public inductionStatusId: string = "";
    @MobX.observable
    public photographUrl: string = "";

    fromDto(model: InductionListModelDTO): void {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    toDto(model: InductionListModel): void {}
}

export type InductionListModelDTO = {
    id: string;
    fullName: string;
    firstName: string;
    lastName: string;
    priorityEmployerName: string;
    age: number;
    visitorTypeName: string;
    hasHealthProblems: boolean;
    lastSignedInDate: Date | null;
    inductionStatusId: string;
    photographUrl: string;
    inductedDate: string;
};
