import { Button } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React from "react";

import { Files } from "Components/Files/Files/Files";
import { BaseModal } from "Components/Modal/BaseModal";
import { SnackBar } from "Components/SnackBar/SnackBar";
import { CancelButton } from "Globals/Styles/Control/Buttons";
import { AddPlanOfWorkModalStyle } from "Views/Project/Modals/Modal.Styles";
import { UpdateDrawingModel, UpdateDrawingViewModel } from ".";
import { DarwinSelect } from "Components/AutoComplete/DarwinSelect";
import { DarwinInputWrapper } from "Components/Form/DarwinInputWrapper";

interface IProps {
    viewModel: UpdateDrawingViewModel;
}

export const UpdateDrawingView: React.FC<IProps> = observer((props) => {
    const viewModel = props.viewModel;

    return (
        <BaseModal
            open={viewModel !== null}
            onClose={viewModel.cancel}
            title="Update drawing"
            PaperComponent={AddPlanOfWorkModalStyle}
            actions={
                <React.Fragment>
                    <Button autoFocus color="secondary" disabled={viewModel.server.IsBusy} onClick={viewModel.apiUpdateDrawingAsync} variant="contained">
                        Save
                    </Button>
                    <CancelButton onClick={viewModel.cancel}>Cancel</CancelButton>
                </React.Fragment>
            }
        >
            <DarwinSelect
                displayName="Users:*"
                execute={viewModel.setApproverUserAsync}
                fullWidth={true}
                getOptionLabel={(option: any) => option.displayName}
                onBlur={() => viewModel.isFieldValid("approverUserId")}
                options={viewModel.approverUsers}
                placeholder="Please select"
                validationMessage={viewModel.getError("approverUserId")}
                value={viewModel.approverUser}
                canExecute={!viewModel.IsLoading}
            />

            <DarwinInputWrapper<UpdateDrawingModel>
                type="text"
                label="Description:*"
                placeHolder="Description"
                validateOnBlur={true}
                viewModel={viewModel}
                fieldName="description"
                shrink={true}
                editMode={!viewModel.IsLoading}
            />

            <br />

            <Files viewModel={viewModel.filesViewModel} disabled={viewModel.IsLoading} />
            <SnackBar
                messageText={viewModel.snackMessage}
                messageType={viewModel.snackType}
                active={viewModel.snackbarState}
                closeOption={() => viewModel.setSnackbarState(false)}
                autoHideDuration={5000}
            />
        </BaseModal>
    );
});
