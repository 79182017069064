import { ModelBase } from "@shoothill/core";
import { action, observable } from "mobx";

import type { ICalculatedAdjustedStockDto } from "../../Shared/CalculatedAdjustedStockDtos";

export class CalculatedAdjustedStockModel extends ModelBase<CalculatedAdjustedStockModel> {
    // #region Defaults

    public static readonly DEFAULT_CURRENTUNITS = null;
    public static readonly DEFAULT_CURRENTVALUE = null;

    // #endregion Defaults

    // #region Related Properties

    @observable
    public previousUnits: number | null = CalculatedAdjustedStockModel.DEFAULT_CURRENTUNITS;

    @observable
    public previousValue: number | null = CalculatedAdjustedStockModel.DEFAULT_CURRENTVALUE;

    @observable
    public adjustedUnits: number | null = CalculatedAdjustedStockModel.DEFAULT_CURRENTUNITS;

    @observable
    public adjustedValue: number | null = CalculatedAdjustedStockModel.DEFAULT_CURRENTVALUE;

    @observable
    public nextUnits: number | null = CalculatedAdjustedStockModel.DEFAULT_CURRENTUNITS;

    @observable
    public nextValue: number | null = CalculatedAdjustedStockModel.DEFAULT_CURRENTVALUE;

    // #endregion Related Properties

    // #region Methods

    @action
    public fromDto(dto: ICalculatedAdjustedStockDto): void {
        this.previousUnits = dto.previousStock.item1;
        this.previousValue = dto.previousStock.item2;

        this.adjustedUnits = dto.adjustment.item1;
        this.adjustedValue = dto.adjustment.item2;

        this.nextUnits = dto.nextStock.item1;
        this.nextValue = dto.nextStock.item2;
    }

    public toDto(): any {}

    // #endregion Methods
}
