import { SHBox } from "Components/Box";
import { PrimaryButton } from "Components/Buttons/Buttons";
import { PatchedPagination } from "Components/Table/PatchedPagination";
import { DarwinTablePageContent } from "Globals/Styles/AppStyling";
import { formatDate, formatTime } from "Utils/Format";
import MaterialTable from "material-table";
import { observer } from "mobx-react-lite";
import { RAMSReviewListModel } from "./RAMSReviewListModel";
import { useEffect, useState } from "react";
import { RAMSReviewListViewModel } from "./RAMSReviewListViewModel";
import styled from "styled-components";
import * as Defaults from "../../../../Globals/Defaults/TableOptions";

const Wrapper = styled.div`
    padding: 5px 30px;
`;

interface Props {
    projectId: string;
    projectName: string;
    projectReference: string;
}

export const RAMSReview: React.FC<Props> = observer((props: Props) => {
    const [listViewModel] = useState(() => new RAMSReviewListViewModel());

    useEffect(() => {
        listViewModel.projectId = props.projectId;
        listViewModel.loadRAMSListItemsAndRelated();
    }, []);

    const renderListView = (
        <SHBox>
            <SHBox grid pt="20px" dc={"300px 100px"} alignItems={"flex-end"}>
                <PrimaryButton execute={listViewModel.addRAMSReviewForm} displayName={"Add new RA/MS review and sign off"} value={"Add"} />
            </SHBox>
            <DarwinTablePageContent>
                <MaterialTable
                    columns={[
                        {
                            title: "Date / time added",
                            field: "createdDate",
                            render: (rowData: RAMSReviewListModel) => (
                                <SHBox>
                                    <SHBox>
                                        {rowData.createdDate !== "" ? formatDate(rowData.createdDate) : "-"} @
                                        {rowData.createdDate !== "" ? " " + formatTime(rowData.createdDate) : "-"}
                                    </SHBox>
                                </SHBox>
                            ),
                        },
                        { title: "RA/MS title and revision", field: "title" },
                        { title: "Sub-contractor", field: "subContractor" },
                        { title: "Sub-contractor supervisor", field: "supervisorUserName" },
                        { title: "Review by", field: "reviewedByUserName" },
                        {
                            title: "Is approved",
                            field: "isApproved",
                            render: (rowData: RAMSReviewListModel) => <SHBox>{rowData.isApproved ? "Approved" : "Declined"}</SHBox>,
                        },
                        {
                            title: "Last updated",
                            field: "lastUpdatedDate",
                            render: (rowData: RAMSReviewListModel) => (
                                <SHBox>
                                    <SHBox>
                                        {rowData.lastUpdatedDate !== "" ? formatDate(rowData.lastUpdatedDate) : "-"} @
                                        {rowData.lastUpdatedDate !== "" ? " " + formatTime(rowData.lastUpdatedDate) : "-"} by
                                        {rowData.lastUpdatedByUserName !== "" ? " " + rowData.lastUpdatedByUserName : "-"}
                                    </SHBox>
                                </SHBox>
                            ),
                        },
                    ]}
                    components={{
                        Pagination: PatchedPagination,
                    }}
                    options={Defaults.GetDarwinTableOptions()}
                    data={listViewModel.RAMSReviewListItems}
                    onRowClick={(e, rowData) => {
                        listViewModel.handleRowClick(e, rowData!);
                    }}
                    title=""
                />
            </DarwinTablePageContent>
        </SHBox>
    );

    return <Wrapper>{renderListView}</Wrapper>;
});
