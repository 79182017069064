import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { SHBox } from "../../../../Components/Box";
import styled from "styled-components";
import image from "../../../../Content/signinimage.jpg";
import DarwinLogo from "Content/darwinlogoInduction.svg";

const TopImage = styled.img`
    width: 100%;
    flex: 1;
`;
const SiteTitle = styled.p`
    font-size: 24px;
    font-weight: 600;
`;
const Wrapper = styled(SHBox)`
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 30px;
    margin-top: 30px;
`;
export const TopAccessHeaderView: React.FC = observer(() => {
    return (
        <>
            <Wrapper>
                <TopImage src={image} />
                <img height={"50px"} src={DarwinLogo} alt="Darwin Group" className="logoDarwin" />
                <SiteTitle />
            </Wrapper>
        </>
    );
});
