import { FieldType, ViewModelBase } from "@shoothill/core";
import { observable, runInAction, computed, action } from "mobx";

import { AppUrls } from "AppUrls";
import { InductionType } from "Globals/Models/Enums/InductionType";
import { Step5Model } from "./Step5Model";

export class Step5ViewModel extends ViewModelBase<Step5Model> {
    @observable public videoFinished: boolean = false;
    @observable public allowNextStep: boolean = false;

    constructor() {
        super(new Step5Model());
    }

    // #region Step1

    @computed
    public get isVisitorInductionType() {
        return this.model.inductionType?.IsOfType(InductionType.Visitor);
    }

    @action
    public setIsVideoFinished = (isFinished: boolean) => {
        this.videoFinished = isFinished;
    };

    @action
    public setInductionTypeId = (inductionTypeId: string | null) => {
        this.model.inductionTypeId = inductionTypeId;
    };

    public nextStep = () => {
        this.setIsLoading(true);
        this.history.push(AppUrls.Client.Induction.Step6);
        setTimeout(() => {
            this.setIsLoading(false);
        }, 100);
    };

    public previousStep = () => {
        this.setIsLoading(true);

        if (this.isVisitorInductionType) {
            this.history.push(AppUrls.Client.Induction.Landing);
        } else {
            this.history.push(AppUrls.Client.Induction.Step4);
        }

        setTimeout(() => {
            this.setIsLoading(false);
        }, 100);
    };

    @computed
    public get canExecuteNextStep() {
        this.isModelValid().then((isValid) => {
            runInAction(() => {
                this.allowNextStep = isValid;
            });
        });
        return this.videoFinished && this.allowNextStep;
    }

    public radioChange = (fieldName: keyof FieldType<Step5Model>, value: string) => {
        this.setValue(fieldName, value);
    };

    @computed
    public get inductionVideoURL(): string {
        if (this.isVisitorInductionType) {
            return "https://vimeo.com/919996161/2cd83e81de";
        } else {
            return "https://vimeo.com/920090759/a051591211";
        }
    }

    // #endregion Step1

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<Step5Model>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
