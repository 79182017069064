import { observable, runInAction, action } from "mobx";
import { ViewModelBase, FieldType } from "@shoothill/core";
import { FirstAidKitModel } from "./FirstAidKitModel";

export class FirstAidKitViewModel extends ViewModelBase<FirstAidKitModel> {
    constructor(model = new FirstAidKitModel()) {
        super(model);
        this.setDecorators(FirstAidKitModel);
    }
    @action
    public radioChanged = async (value: string, fieldName: string, editable: boolean) => {
        if (!editable) return;
        this.model[fieldName] = value;
        await this.isFieldValid(fieldName as any, value);
    };
    public async isFieldValid(fieldName: keyof FieldType<FirstAidKitModel>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }
    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
