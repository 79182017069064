// Libs
import moment, { Moment, Duration } from "moment";

/**
 * Returns the first date of the working week as a date.
 * @param none.
 * @returns Formatted date as string.
 */
export const GetStartOfWeek = (val: Date): Date => {
    return new Date(val.setDate(val.getDate() - val.getDay() + (val.getDay() == 0 ? -6 : 1)));
};

/**
 * Calculate the duration for a shift and return the formatted duration as a string.
 * @param startTime Start time.
 * @param stopTime Stop time.
 * @returns The formatted duration as a string.
 */
export const CalcDuration = (startTime: any, stopTime: any): string => {
    if (startTime !== null && startTime !== "" && stopTime !== null && stopTime !== "") {
        const duration: Duration | null = GetDuration(startTime, stopTime);

        return GetFormattedDuration(duration);
    }

    return "00:00";
};

/**
 * Get the duration between two dates/times.
 * @param startTime Start time.
 * @param stopTime Stop time.
 * @returns moment.duration of two dates/times.
 */
export const GetDuration = (startTime: any, stopTime: any): Duration => {
    if (startTime !== null && startTime !== "" && stopTime !== null && stopTime !== "") {
        const startDateMoment: Moment = moment(startTime);
        const stopDateMoment: Moment = moment(stopTime);

        const duration: Duration = moment.duration(stopDateMoment.diff(startDateMoment));

        return duration;
    }

    return moment.duration();
};

/**
 * Returns the formatted duration as a string.
 * @param duration The unformatted moment.duration.
 * @returns Formatted duration as string.
 */
export const GetFormattedDuration = (duration: Duration): string => {
    if (duration !== null) {
        const _days = duration.days() * 24;
        const hours = duration.hours() + _days;
        const minutes = duration.minutes();

        if (Math.sign(hours) === -1 || Math.sign(minutes) === -1 || Math.sign(hours).toString() === "-0" || Math.sign(minutes).toString() === "-0") {
            return "00:00";
        }

        let hoursFormatted = hours.toString();
        let minutesFormatted = minutes.toString();

        if (hoursFormatted.length <= 1) {
            hoursFormatted = hoursFormatted.padStart(2, "0");
        }

        if (minutesFormatted.length <= 1) {
            minutesFormatted = minutesFormatted.padStart(2, "0");
        }

        return `${hoursFormatted}:${minutesFormatted}`;
    }

    return "00:00";
};

/**
 * Returns the current week as a number.
 * @param Date.
 * @returns Week as a number.
 */
export const GetWeekNumber = (val: Date): number => {
    const currentMoment: Moment = moment(val);
    return currentMoment.week();
};

/**
 * first date of the working week as a string (ISO) from week number.
 * @param number.
 * @returns first date of the working week from week number.
 */
export const GetStartFromWeekNumber = (val: number): string => {
    const retVal = moment().day("Monday").week(val).toISOString();
    return retVal;
};

/**
 * Last date of the working week as a string (ISO) from week number.
 * @param number.
 * @returns Last date of the working week from week number.
 */
export const GetEndFromWeekNumber = (val: number): string => {
    const retVal = moment().day("Friday").week(val).toISOString();
    return retVal;
};

export const IsDateStringValid = (val: string | null): boolean => {
    // Check if the date string is null
    if (val === null) {
        return false;
    }

    // Parse the date using moment
    const parsedDate = moment(val);

    // Check if the parsed date is valid
    return parsedDate.isValid();
};
