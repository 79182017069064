import { FieldType, ViewModelBase, isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import { action, computed, observable, runInAction } from "mobx";

import { AppUrls } from "AppUrls";
import { StockTransactionType } from "Globals/Models/Enums/StockTransactionType";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { formatCurrencyFromPounds } from "Utils/Format";
import { isTypeOfNumber } from "Utils/Utils";
import { StockTransferModel } from "./StockTransferModel";
import { CategoryModel } from "./Supporting/CategoryModel";
import { IncomeAndExpenditureModel } from "./Supporting/IncomeAndExpenditureModel";
import { LineDescriptionModel } from "./Supporting/LineDescriptionModel";
import { ProjectModel } from "./Supporting/ProjectModel";
import { SubCategoryModel } from "./Supporting/SubCategoryModel";

export class StockTransferViewModel extends ViewModelBase<StockTransferModel> {
    public server: ServerViewModel = new ServerViewModel();

    private materialId: string;
    private parentCloseAction: (refreshPage: boolean) => void;

    constructor(materialId: string, closeAction: (refreshPage: boolean) => void) {
        super(new StockTransferModel(), false);

        this.materialId = materialId;
        this.parentCloseAction = closeAction;

        this.setDecorators(this.model);

        // Load transferable stock.
        this.apiLoadTransferStockAsync();
    }

    // #region Formatted Labels

    @computed
    public get currentQuantity() {
        if (isNullOrUndefined(this.model.currentUnits)) {
            return "Quantity:";
        }

        return `Quantity (${this.model.currentUnits}):`;
    }

    @computed
    public get currentStockValue() {
        if (!isNullOrUndefined(this.model.currentValue)) {
            return formatCurrencyFromPounds(this.model.currentValue!);
        } else {
            return "-";
        }
    }

    // #endregion Formatted Labels

    // #region Projects

    @computed
    public get projects() {
        return this.model.projects;
    }

    @computed
    public get project() {
        return this.model.project;
    }

    @action
    public setProjectAsync = async (value: ProjectModel | null): Promise<void> => {
        this.model.project = value ?? StockTransferModel.DEFAULT_PROJECT;
        this.isFieldValid("project");

        // SIDE-EFFECT.
        // Having set the project, we need to see if we have the income and expenditures available
        // locally and if not load them from the server.
        switch (true) {
            case !isNullOrUndefined(value):
                await this.setIncomeAndExpenditureAsync(StockTransferModel.DEFAULT_INCOMEANDEXPENDITURE);
                await this.setIncomeAndExpendituresAsync(value!.id);
                break;

            default:
                await this.setIncomeAndExpenditureAsync(StockTransferModel.DEFAULT_INCOMEANDEXPENDITURE);
                break;
        }
    };

    // #endregion Projects

    // #region Income and expenditures

    @computed
    public get incomeAndExpenditures() {
        if (isNullOrUndefined(this.model.project)) {
            return [];
        }

        return this.model.incomeAndExpenditures.filter((ie) => ie.projectId === this.model.project!.id);
    }

    @computed
    public get incomeAndExpenditure() {
        return this.model.incomeAndExpenditure;
    }

    @action
    public setIncomeAndExpenditureAsync = async (value: IncomeAndExpenditureModel | null): Promise<void> => {
        this.model.incomeAndExpenditure = value ?? StockTransferModel.DEFAULT_INCOMEANDEXPENDITURE;
        this.isFieldValid("incomeAndExpenditure");

        // SIDE-EFFECT.
        // Having set the income and expenditure, we need to see if we have the categories available
        // locally and if not load them from the server.
        switch (true) {
            case !isNullOrUndefined(value):
                await this.setCategoryAsync(StockTransferModel.DEFAULT_CATEGORY);
                await this.setCategoriesAsync(value!.id);
                break;

            default:
                await this.setCategoryAsync(StockTransferModel.DEFAULT_CATEGORY);
                break;
        }
    };

    public setIncomeAndExpendituresAsync = async (projectId: string): Promise<void> => {
        if (this.incomeAndExpenditures.findIndex((ie) => ie.projectId === projectId) === -1) {
            return this.apiLoadIncomeAndExpendituresAsync();
        }
    };

    // #endregion Income and expenditures

    // #region Categories

    @computed
    public get categories() {
        if (isNullOrUndefined(this.model.incomeAndExpenditure)) {
            return [];
        }

        return this.model.categories.filter((cat) => cat.incomeAndExpenditureId === this.model.incomeAndExpenditure!.id);
    }

    @computed
    public get category() {
        return this.model.category;
    }

    @action
    public setCategoryAsync = async (value: CategoryModel | null): Promise<void> => {
        this.model.category = value ?? StockTransferModel.DEFAULT_CATEGORY;
        this.isFieldValid("category");

        // SIDE-EFFECT.
        // Having set the category, we need to see if we have the subcategories available
        // locally and if not load them from the server.
        switch (true) {
            case !isNullOrUndefined(value):
                await this.setSubCategoryAsync(StockTransferModel.DEFAULT_SUBCATEGORY);
                await this.setSubCategoriesAsync(value!.id);
                break;

            default:
                await this.setSubCategoryAsync(StockTransferModel.DEFAULT_SUBCATEGORY);
                break;
        }
    };

    public setCategoriesAsync = async (incomeAndExpenditureId: string): Promise<void> => {
        if (this.categories.findIndex((cat) => cat.incomeAndExpenditureId === incomeAndExpenditureId) === -1) {
            return this.apiLoadCategoriesAsync();
        }
    };

    // #endregion Categories

    // #region Sub-categories

    @computed
    public get subCategories() {
        if (isNullOrUndefined(this.model.category)) {
            return [];
        }

        return this.model.subCategories.filter((subCat) => subCat.categoryId === this.model.category!.id);
    }

    @computed
    public get subCategory() {
        return this.model.subCategory;
    }

    @action
    public setSubCategoryAsync = async (value: SubCategoryModel | null): Promise<void> => {
        this.model.subCategory = value ?? StockTransferModel.DEFAULT_SUBCATEGORY;
        this.isFieldValid("subCategory");

        // SIDE-EFFECT.
        // Having set the subcategory, we need to see if we have the line descriptions available
        // locally and if not load them from the server.
        switch (true) {
            case !isNullOrUndefined(value):
                this.setLineDescription(StockTransferModel.DEFAULT_LINEDESCRIPTION);
                await this.setLineDescriptionsAsync(value!.id);
                break;

            default:
                this.setLineDescription(StockTransferModel.DEFAULT_LINEDESCRIPTION);
                break;
        }
    };

    public setSubCategoriesAsync = async (categoryId: string): Promise<void> => {
        if (this.subCategories.findIndex((subCat) => subCat.categoryId === categoryId) === -1) {
            return this.apiLoadSubCategoriesAsync();
        }
    };

    // #endregion Sub-categories

    // #region Line descriptions

    @computed
    public get lineDescriptions() {
        if (isNullOrUndefined(this.model.subCategory)) {
            return [];
        }

        return this.model.lineDescriptions.filter((ld) => ld.subCategoryId === this.model.subCategory!.id);
    }

    @computed
    public get lineDescription() {
        return this.model.lineDescription;
    }

    @action
    public setLineDescription = (value: LineDescriptionModel | null): void => {
        this.model.lineDescription = value ?? StockTransferModel.DEFAULT_LINEDESCRIPTION;
        this.isFieldValid("lineDescription");

        // SIDE-EFFECT
        // Having set the line-description, reset the transaction.
        this.setStockTransactionType(StockTransferModel.DEFAULT_STOCKTRANSACTIONTYPE);
    };

    public setLineDescriptionsAsync = async (subCategoryId: string): Promise<void> => {
        if (this.lineDescriptions.findIndex((ld) => ld.subCategoryId === subCategoryId) === -1) {
            return this.apiLoadLineDescriptionsAsync();
        }
    };

    // #endregion Line descriptions

    // #region Stock transaction types

    @computed
    public get stockTransactionTypes() {
        return this.model.stockTransactionTypes;
    }

    @computed
    public get stockTransactionType() {
        return this.model.stockTransactionType;
    }

    @action
    public setStockTransactionType = (value: StockTransactionType | null): void => {
        this.model.stockTransactionType = value ?? StockTransferModel.DEFAULT_STOCKTRANSACTIONTYPE;
        this.isFieldValid("stockTransactionType");

        // SIDE-EFFECT
        // Having set the line-description, we need to try and evaluate the number
        // of available units and the stock price.
        this.model.transferUnits = StockTransferModel.DEFAULT_TRANSFERUNITS;
        this.isFieldValid("transferUnits");

        this.model.suggestedFutureSpend = StockTransferModel.DEFAULT_SUGGESTEDFUTURESPEND;
        this.isFieldValid("suggestedFutureSpend");
        this.model.suggestedFutureSpendReason = StockTransferModel.DEFAULT_SUGGESTEDFUTURESPENDREASON;
        this.isFieldValid("suggestedFutureSpendReason");

        this.apiGetUnitsAndCostsAsync();
    };

    // #endregion Stock transaction types

    // #region Actions

    @action
    public cancel = () => this.parentCloseAction(false);

    // #endregion Actions

    // #region Api Actions

    @action
    public apiGetUnitsAndCostsAsync = async (): Promise<void> => {
        // Before calling the api, make sure we have all the parameters needed to
        // find the available units and cost. Material is already defined.
        const isValid =
            !isEmptyOrWhitespace(this.model.stockTransactionType?.id) && !isEmptyOrWhitespace(this.model.lineDescription?.id) && isTypeOfNumber(this.model.transferUnits);

        if (isValid) {
            await this.server.query<any>(
                () =>
                    this.Get(
                        AppUrls.Server.Stock.GetUnitsAndCostsForTransfer.replace("{materialid}", this.materialId)
                            .replace("{units}", this.model.transferUnits.toString())
                            .replace("{transactiontypeid}", this.model.stockTransactionType!.id)
                            .replace("{ieitemid}", this.model.lineDescription!.id),
                    ),
                (result) => {
                    runInAction(() => {
                        // Set the current units and cost.
                        this.model.currentUnits = result.units;
                        this.model.currentValue = result.price;
                        this.model.suggestedFutureSpend = parseFloat(result.suggestedFutureSpend.toFixed(2));
                        this.isFieldValid("suggestedFutureSpend");
                    });
                },
                "Error whilst loading the stock data",
            );

            if (this.server.HaveValidationMessage) {
                this.setSnackMessage(this.server.ValidationMessage);
                this.setSnackType(this.SNACKERROR);
                this.setSnackbarState(true);
            }
        } else {
            // Reset the current units and cost.
            this.model.currentUnits = StockTransferModel.DEFAULT_CURRENTUNITS;
            this.model.currentValue = StockTransferModel.DEFAULT_CURRENTVALUE;
        }
    };

    @action
    public apiLoadTransferStockAsync = async (): Promise<void> => {
        await this.server.query<any>(
            () => this.Get(AppUrls.Server.Stock.GetStockForTransferWithRelatedByMaterialId.replace("{materialid}", this.materialId)),
            (result) => this.model.fromDto(result),
            "Error whilst loading the stock data",
        );

        if (this.server.HaveValidationMessage) {
            this.setSnackMessage(this.server.ValidationMessage);
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }
    };

    @action
    public apiSaveTransferStockAsync = async (): Promise<void> => {
        await this.server.command<any>(
            () => this.Post(AppUrls.Server.Stock.RequestTransferStock.replace("{materialid}", this.materialId), this.model.toDto()),
            (result) => this.parentCloseAction(true),
            this.isModelValid,
            "Error whilst saving the stock transfer request",
        );

        if (this.server.HaveValidationMessage) {
            this.setSnackMessage(this.server.ValidationMessage);
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }
    };

    @action
    public apiLoadIncomeAndExpendituresAsync = async (): Promise<void> => {
        await this.server.query<any>(
            () => this.Get(AppUrls.Server.Stock.GetIncomeAndExpendituresByProjectId.replace("{projectid}", this.model.project!.id)),
            (result: any) => {
                runInAction(() => {
                    this.incomeAndExpenditures.push(...IncomeAndExpenditureModel.fromDtos(result));
                });
            },
            "Error whilst loading the income and expenditure data",
        );

        if (this.server.HaveValidationMessage) {
            this.setSnackMessage(this.server.ValidationMessage);
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }
    };

    @action
    public apiLoadCategoriesAsync = async (): Promise<void> => {
        await this.server.query<any>(
            () => this.Get(AppUrls.Server.Stock.GetCategoriesByIncomeAndExpenditureId.replace("{incomeandexpenditureid}", this.model.incomeAndExpenditure!.id)),
            (result: any) => {
                runInAction(() => {
                    this.categories.push(...CategoryModel.fromDtos(result));
                });
            },
            "Error whilst loading the category data",
        );

        if (this.server.HaveValidationMessage) {
            this.setSnackMessage(this.server.ValidationMessage);
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }
    };

    @action
    public apiLoadSubCategoriesAsync = async (): Promise<void> => {
        await this.server.query<any>(
            () => this.Get(AppUrls.Server.Stock.GetSubCategoriesByCategoryId.replace("{categoryid}", this.model.category!.id)),
            (result: any) => {
                runInAction(() => {
                    this.subCategories.push(...SubCategoryModel.fromDtos(result));
                });
            },
            "Error whilst loading the sub-category data",
        );

        if (this.server.HaveValidationMessage) {
            this.setSnackMessage(this.server.ValidationMessage);
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }
    };

    @action
    public apiLoadLineDescriptionsAsync = async (): Promise<void> => {
        await this.server.query<any>(
            () => this.Get(AppUrls.Server.Stock.GetLineDescriptionsBySubCategoryId.replace("{subcategoryid}", this.model.subCategory!.id)),
            (result: any) => {
                runInAction(() => {
                    this.lineDescriptions.push(...LineDescriptionModel.fromDtos(result));
                });
            },
            "Error whilst loading the line description data",
        );

        if (this.server.HaveValidationMessage) {
            this.setSnackMessage(this.server.ValidationMessage);
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }
    };

    // #endregion Api Actions

    // #region Snack Bar

    public SNACKERROR = "error";

    @observable
    public snackbarState = false;

    @observable
    public snackType = "";

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public snackMessage = "";

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    // #endregion Snack Bar

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<StockTransferModel>): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boliderplate
}
