import { FieldType, ViewModelBase } from "@shoothill/core";
import { action } from "mobx";
import { RFIFormFileModel } from "./RFIFormFileModel";

export class RFIFormFileViewModel extends ViewModelBase<RFIFormFileModel> {
    constructor(item: RFIFormFileModel | null) {
        super(item ? item : new RFIFormFileModel());
        this.setDecorators(RFIFormFileModel);
    }

    @action
    public reset = () => {
        this.model.reset();
    };

    private isMyModelValid = async (): Promise<boolean> => {
        let isValid = true;
        if ((await this.isModelValid()) === false) {
            isValid = false;
        }

        return isValid;
    };

    public async isFieldValid(fieldName: keyof FieldType<RFIFormFileModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
