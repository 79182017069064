import { action, computed } from "mobx";
import { FieldType, isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import type { ValidationResponse } from "@shoothill/core";
import { InspectionsQuestionModel } from "./InspectionsQuestionModel";

export class InspectionsQuestionViewModel extends ViewModelBase<InspectionsQuestionModel> {
    constructor(model = new InspectionsQuestionModel()) {
        super(model);
        this.setDecorators(InspectionsQuestionModel);
    }

    @action
    public handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.model.pass = (event.target as HTMLInputElement).value === "pass" ? true : false;
    };

    @action
    public handleFailDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        this.model.failDescription = (event.target as HTMLInputElement).value;
    };

    @computed
    private get validateFailDescription(): ValidationResponse {
        const errorMessage = this.model.validateFailDescription;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    public async isFieldValid(fieldName: keyof FieldType<InspectionsQuestionModel>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        switch (fieldName) {
            case "failDescription": {
                const result = this.validateFailDescription;

                errorMessage = result.errorMessage;
                isValid = result.isValid;
                break;
            }
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }
    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
