import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { PrimaryButton } from "../../../../Components/Buttons/Buttons";
import { SHBox } from "../../../../Components/Box";
import { PatchedPagination } from "../../../../Components/Table/PatchedPagination";
import * as Defaults from "../../../../Globals/Defaults/TableOptions";
import { DarwinTablePageContent } from "../../../../Globals/Styles/AppStyling";
import MaterialTable from "material-table";
import { formatDate, formatTime } from "../../../../Utils/Format";
import styled from "styled-components";
import { NonConformanceListViewModel } from "./DilapidationListViewModel";
import { DilapidationListModel } from "./DilapidationListModel";

const Wrapper = styled.div`
    padding: 5px 30px;
`;

interface Props {
    projectId: string;
    projectName: string;
    projectReference: string;
}
export const Dilapidation: React.FC<Props> = observer((props: Props) => {
    const [listViewModel] = useState(() => new NonConformanceListViewModel());

    useEffect(() => {
        listViewModel.projectId = props.projectId;
        listViewModel.loadNonConformanceAndRelated();
    }, []);

    const renderListView = (
        <SHBox>
            <SHBox grid dc={"300px 100px"} alignItems={"flex-end"}>
                <PrimaryButton execute={listViewModel.addDilapidationForm} displayName={"Add new dilapidation report"} value={"Add"} />
            </SHBox>
            <DarwinTablePageContent>
                <MaterialTable
                    columns={[
                        { title: "Type of report", field: "dilapidationTypeName" },
                        {
                            title: "Date / time added",
                            field: "createdDate",
                            render: (rowData: DilapidationListModel) => (
                                <SHBox>
                                    <SHBox>
                                        {rowData.createdDate !== "" ? formatDate(rowData.createdDate) : "-"} @ {rowData.createdDate !== "" ? formatTime(rowData.createdDate) : "-"}
                                    </SHBox>
                                </SHBox>
                            ),
                        },
                        {
                            title: "Completed By",
                            field: "lastUpdatedByUserName",
                            render: (rowData: DilapidationListModel) => (
                                <SHBox>
                                    <SHBox>
                                        {rowData.completedDate !== "" ? formatDate(rowData.completedDate) : "-"} @{" "}
                                        {rowData.completedDate !== "" ? formatTime(rowData.completedDate) : "-"}
                                    </SHBox>
                                    <SHBox>{rowData.completedByUserName ? `by ${rowData.completedByUserName} ` : ""}</SHBox>
                                </SHBox>
                            ),
                        },
                    ]}
                    components={{
                        Pagination: PatchedPagination,
                    }}
                    options={Defaults.GetDarwinTableOptions()}
                    data={listViewModel.DilapidationListItems}
                    onRowClick={(e, rowData) => {
                        listViewModel.handleRowClick(e, rowData!);
                    }}
                    title=""
                />
            </DarwinTablePageContent>
        </SHBox>
    );

    return <Wrapper>{renderListView}</Wrapper>;
});
