// Libraries
import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { FieldType, generateID, isNullOrUndefined, KeyValuePair, useRouter } from "@shoothill/core";
import "./Contractual.styles";

// Custom
import { DetailsHeader } from "Globals/Views/DetailsPage/DetailsHeader";
import { ProjectGeneralViewModel } from "../General/ProjectGeneralViewModel";

// Styling & Images
import { DetailsPage } from "Globals/Views/DetailsPage/DetailsPage.styles";
import { ContractCell, ContractRow } from "./Contractual.styles";
import { ControlLabel } from "Components/Form/ControlLabel";
import { Box, Button, FormHelperText, MenuItem, Select, Typography } from "@material-ui/core";
import { ClientSelectViewModel } from "Components/AutoComplete/ClientSelectViewModel";
import { ContractTypeViewModel } from "Components/AutoComplete/ContractTypeSelectViewModel";
import { DarwinInput } from "Globals/Styles/Control/DarwinInput";
import { ProjectContractualViewModel } from "./ProjectContractualViewModel";
import { ProjectContractualModel } from "./ProjectContractual";
import { ProjectValuationFrequency, ProjectValuationFrequencyHelpers } from "Globals/Models/Domain";
import { ProjectButtonContainer, ProjectEditBox } from "../Project.styles";
import { CancelButton } from "Globals/Styles/Control/Buttons";
import { AppUrls } from "AppUrls";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { SnackBar } from "Components/SnackBar/SnackBar";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { DarwinDateSelect } from "Components/DateSelect/DarwinDateSelect";

moment.locale("en-GB", {
    week: {
        dow: 1,
    },
});

export const ContractualEditView: React.FunctionComponent = () => {
    const router = useRouter();
    const [generalViewModel] = useState(() => ProjectGeneralViewModel.Instance);
    const [viewModel] = useState(new ProjectContractualViewModel());
    const [clientList, setClientList] = useState<KeyValuePair<any>[]>([]);
    const [altClientList, setAltClientList] = useState<any[]>([]);
    const [contractTypes, setContractTypes] = useState<KeyValuePair<any>[]>([]);

    const { history } = useRouter();

    useEffect(() => {
        let { projectid } = router.match.params as any;
        let promise = generalViewModel.apiGetbyId(projectid);

        promise.then(() => {
            const model = generalViewModel.model.getProjectConstractual;

            if (model !== null && model !== undefined) {
                if (model.projectId === null || model.projectId === undefined || model.projectId === "00000000-0000-0000-0000-000000000000") {
                    model.projectId = projectid;
                }

                viewModel.loadModel(model);
            } else {
                viewModel.model.projectId = projectid;
            }

            const clientVM: ClientSelectViewModel = ClientSelectViewModel.Instance;
            setClientList(clientVM.returnClientList);
            setAltClientList(clientVM.returnActiveClients);

            const contractTypesVM: ContractTypeViewModel = ContractTypeViewModel.Instance;
            setContractTypes(contractTypesVM.getContractTypes);
        });

        return () => {
            viewModel.clean();
        };
    }, []);

    const handleContractTypeChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        viewModel.setValue<string>("contractTypeId", event.target.value as string);
    };

    const handleValuationFrequencyhange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        viewModel.setValue<number>("valuationFrequencyId", parseInt(event.target.value as string, 10));
    };

    const onStartDateChanged = (date: string | null) => {
        viewModel.setValue("contractStartDate", date);
    };

    const onCompletionDateChanged = (date: string | null) => {
        viewModel.setValue("contractCompletionDate", date);
    };

    const onValidationDateChanged = (date: string | null) => {
        viewModel.setValue("valuationDate", date);
    };

    const isInError = (fieldName: keyof FieldType<ProjectContractualModel>) => {
        let isValid = viewModel.getValid(fieldName);
        return !isValid;
    };

    const showValueField: boolean = viewModel.model.valuationFrequencyId === ProjectValuationFrequency.XDays;

    const onSaveClick = async () => {
        if ((await viewModel.isModelValid()) === true) {
            const promise = viewModel.upsertContractual();

            promise.then((result: any) => {
                if (result.wasSuccessful === true) {
                }
            });
        } else {
            window.scrollTo(0, 0);
        }
    };

    const renderPage = () => {
        return (
            <>
                <DetailsPage className="cell-right rcselect">
                    <DetailsHeader viewModel={generalViewModel.getHeader} />
                    <ProjectEditBox>
                        <div className="row">
                            <ContractCell>
                                {/* <ControlLabel label="Client Name:" htmlFor="client-select"> */}
                                <Box maxWidth="200px">
                                    {/* <GenericAutoCompleteView
                                                allowMultiple={false}
                                                currentId={viewModel.model.clientId}
                                                includeDeleted={false}
                                                dataSet={clientList}
                                                returnPair={(val) => handleAutocompleteClientChange(val)}
                                                placeHolder={"Choose a client"}
                                                idVal={"clientId"}
                                                allowAdd={false}
                                                variant={undefined}
                                            /> */}

                                    <DarwinInput<ProjectContractualModel>
                                        type="text"
                                        label="Client Name:"
                                        validateOnBlur={true}
                                        viewModel={viewModel}
                                        fieldName="clientName"
                                        shrink={true}
                                        maxLength={128}
                                    />
                                </Box>
                                {/* </ControlLabel> */}
                            </ContractCell>
                            <ContractCell></ContractCell>
                        </div>
                    </ProjectEditBox>
                    <ContractRow>
                        <Typography variant="h3">Contract</Typography>
                    </ContractRow>
                    <ProjectEditBox>
                        <div className="row">
                            <ContractCell>
                                <ControlLabel label="Contract type:" htmlFor="contractType-select">
                                    <Box maxWidth="200px">
                                        <Select
                                            id="contractType-select"
                                            value={viewModel.model.contractTypeId}
                                            onChange={handleContractTypeChange}
                                            MenuProps={{
                                                getContentAnchorEl: null,
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "left",
                                                },
                                            }}
                                            fullWidth
                                        >
                                            {contractTypes.map((client: KeyValuePair<any>, index: number) => {
                                                return (
                                                    <MenuItem key={generateID()} value={client.value}>
                                                        {client.key}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                        <Box>{isInError("contractTypeId") && <FormHelperText style={{ color: "red" }}>{viewModel.getError("contractTypeId")}</FormHelperText>}</Box>
                                    </Box>
                                </ControlLabel>
                            </ContractCell>
                            <ContractCell>
                                <DarwinInput<ProjectContractualModel>
                                    type="number"
                                    label="Contract Value:"
                                    validateOnBlur={true}
                                    viewModel={viewModel}
                                    fieldName="contractValue"
                                    shrink={true}
                                    maxLength={12}
                                />
                            </ContractCell>
                            <ContractCell>
                                <Box maxWidth="200px">
                                    <DarwinDateSelect
                                        displayName="Start date:"
                                        execute={(value: string | null) => onStartDateChanged(value)}
                                        onBlur={() => viewModel.isFieldValid("contractStartDate", true)}
                                        placeholder="Please select"
                                        validationMessage={viewModel.getError("contractStartDate")}
                                        value={viewModel.model.contractStartDate}
                                        canExecute={!viewModel.IsLoading}
                                    />
                                </Box>
                            </ContractCell>
                            <ContractCell>
                                <Box maxWidth="200px">
                                    <DarwinDateSelect
                                        displayName="Completion date:"
                                        execute={(value: string | null) => onCompletionDateChanged(value)}
                                        onBlur={() => viewModel.isFieldValid("contractCompletionDate", true)}
                                        placeholder="Please select"
                                        validationMessage={viewModel.getError("contractCompletionDate")}
                                        value={viewModel.model.contractCompletionDate}
                                    />
                                </Box>
                            </ContractCell>
                            <ContractCell style={{ width: "0" }}></ContractCell>
                        </div>
                    </ProjectEditBox>

                    <ProjectEditBox>
                        <div className="row">
                            <ContractCell>
                                <Box maxWidth="200px">
                                    <DarwinDateSelect
                                        displayName="Validation date:"
                                        execute={(value: string | null) => onValidationDateChanged(value)}
                                        onBlur={() => viewModel.isFieldValid("valuationDate", true)}
                                        placeholder="Please select"
                                        validationMessage={viewModel.getError("valuationDate")}
                                        value={viewModel.model.valuationDate}
                                    />
                                </Box>
                            </ContractCell>
                            <ContractCell>
                                <ControlLabel label="Valuation frequency:" htmlFor="valuationfreq-select">
                                    <Box maxWidth="200px">
                                        <Select
                                            id="valuationfreq-select"
                                            value={viewModel.model.valuationFrequencyId}
                                            onChange={handleValuationFrequencyhange}
                                            MenuProps={{
                                                getContentAnchorEl: null,
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "left",
                                                },
                                            }}
                                            fullWidth
                                        >
                                            {ProjectValuationFrequencyHelpers.getAllKeyValueSelectOptions().map((item: KeyValuePair<any>, index: number) => {
                                                return (
                                                    <MenuItem key={generateID()} value={item.value}>
                                                        {item.key}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                        <Box>
                                            {isInError("valuationFrequencyId") && (
                                                <FormHelperText style={{ color: "red" }}>{viewModel.getError("valuationFrequencyId")}</FormHelperText>
                                            )}
                                        </Box>
                                    </Box>
                                </ControlLabel>
                            </ContractCell>
                            {showValueField && (
                                <ContractCell>
                                    <DarwinInput<ProjectContractualModel>
                                        type="number"
                                        label="X days:"
                                        validateOnBlur={true}
                                        viewModel={viewModel}
                                        fieldName="valuationFrequencyDays"
                                        shrink={true}
                                        maxLength={3}
                                    />
                                </ContractCell>
                            )}
                        </div>
                    </ProjectEditBox>

                    <ProjectEditBox>
                        <div className="row">
                            <ContractCell>
                                <DarwinInput<ProjectContractualModel>
                                    type="text"
                                    label="Emplorer's agent (E.A.):"
                                    validateOnBlur={true}
                                    viewModel={viewModel}
                                    fieldName="employersAgent"
                                    shrink={true}
                                    maxLength={128}
                                />
                            </ContractCell>
                            <ContractCell>
                                <DarwinInput<ProjectContractualModel>
                                    type="text"
                                    label="E.A. contact name:"
                                    validateOnBlur={true}
                                    viewModel={viewModel}
                                    fieldName="eaContactName"
                                    shrink={true}
                                    maxLength={128}
                                />
                            </ContractCell>
                            <ContractCell>
                                <DarwinInput<ProjectContractualModel>
                                    type="email"
                                    label="E.A. email:"
                                    validateOnBlur={true}
                                    viewModel={viewModel}
                                    fieldName="eaContactEmail"
                                    shrink={true}
                                    maxLength={256}
                                />
                            </ContractCell>
                        </div>
                    </ProjectEditBox>

                    <ProjectEditBox>
                        <div className="row">
                            <ContractCell>
                                <DarwinInput<ProjectContractualModel>
                                    type="tel"
                                    label="E.A. phone:"
                                    validateOnBlur={true}
                                    viewModel={viewModel}
                                    fieldName="eaContactPhone"
                                    shrink={true}
                                    maxLength={128}
                                />
                            </ContractCell>
                            <ContractCell>
                                <DarwinInput<ProjectContractualModel>
                                    type="tel"
                                    label="E.A. mobile:"
                                    validateOnBlur={true}
                                    viewModel={viewModel}
                                    fieldName="eaContactMobile"
                                    shrink={true}
                                    maxLength={128}
                                />
                            </ContractCell>
                        </div>
                    </ProjectEditBox>

                    <ProjectEditBox>
                        <div className="row">
                            <ContractCell>
                                <DarwinInput<ProjectContractualModel>
                                    type="number"
                                    label="Overhead percentage:"
                                    validateOnBlur={true}
                                    viewModel={viewModel}
                                    fieldName="overheadPercentage"
                                    shrink={true}
                                    maxLength={128}
                                />
                            </ContractCell>
                            <ContractCell>
                                <DarwinInput<ProjectContractualModel>
                                    type="number"
                                    label="Design percentage:"
                                    validateOnBlur={true}
                                    viewModel={viewModel}
                                    fieldName="designPercentage"
                                    shrink={true}
                                    maxLength={128}
                                />
                            </ContractCell>
                        </div>
                    </ProjectEditBox>

                    <ProjectButtonContainer>
                        <Button onClick={onSaveClick} color="primary" autoFocus variant="contained" disabled={viewModel.IsLoading}>
                            Update Contractual
                        </Button>
                        <CancelButton href={AppUrls.Client.Project.List}>Cancel</CancelButton>
                    </ProjectButtonContainer>
                </DetailsPage>
                <SnackBar
                    messageText={viewModel.snackMessage}
                    messageType={viewModel.snackType}
                    active={viewModel.snackbarState}
                    closeOption={() => viewModel.setSnackbarState(false)}
                    autoHideDuration={5000}
                />
            </>
        );
    };

    return useObserver(() => renderPage());
};
