import { EnumType, IEnumType } from "./Base/EnumType";

export class MaterialSubType extends EnumType {
    // #region Constants

    public static readonly Placeholder = "PLACEHOLDER";

    // #endregion Constants

    // #region Properties

    public materialTypeId: string = "";

    // #endregion Properties

    // #region Methods

    public fromDto(dto: IMaterialSubType) {
        super.fromDto(dto);

        this.materialTypeId = dto.materialTypeId;
    }

    // #endregion Methods
}

export interface IMaterialSubType extends IEnumType {
    materialTypeId: string;
}
