import { ModelBase } from "@shoothill/core";
import { observable } from "mobx";
import { InvoiceDocumentDTO } from "../../../../Invoice/Form/Details/InvoiceDetailsModel";
import { AuditDocumentModel } from "../AuditDocument";

export class AuditQuestionModel extends ModelBase<AuditQuestionModel> {
    public id: string | null = null;
    public answerId: string | null = null;
    @observable
    ordinal: number = 0;
    @observable
    checkTitle: string = "";
    @observable
    answer: string = "";
    /* @observable
    passFailNA: boolean = false;*/
    @observable
    showPassFail: boolean = false;
    @observable
    showPassFailNA: boolean = false;
    @observable
    showPhotoUpload: boolean = false;
    @observable
    showSecondaryPhotoUpload: boolean = false;
    @observable
    public auditDocuments: AuditDocumentModel[] = [];
    constructor() {
        super();
    }

    public get radioValue() {
        if (this.answer === null) return ""; //This ensures that no radio button is selected when the value is null

        return this.answer ? "pass" : "fail" || "";
    }
    fromDto(model: any): void {
        this.id = model.id;
        this.answerId = model.answerId;
        this.ordinal = model.ordinal;
        this.checkTitle = model.checkTitle;
        this.answer = model.answer;
        this.showPassFail = model.showPassFail;
        this.showPassFailNA = model.showPassFailNA;
        this.showPhotoUpload = model.showPhotoUpload;
        this.showSecondaryPhotoUpload = model.showSecondaryPhotoUpload;
    }
    toDto() {
        return {
            id: this.id,
            answerId: this.answerId,
            ordinal: this.ordinal,
            checkTitle: this.checkTitle,
            answer: this.answer,
            //auditDocuments: this.auditDocuments.filter((a) => a.id === null && a.isDeleted),
            auditDocuments: this.auditDocuments.map((a) => a.toDto()),
        };
    }
}

/*export interface AuditDocumentDTO {
    id: string | null;
    fileName: string;
    url: string;
    isDeleted: boolean;
}*/
