const AdjustIcon = (props: any) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={19.269} height={17.88} {...props}>
        <g data-name="Group 9551" transform="translate(-623.75 2809.6)">
            <path fill="none" stroke="#707070" strokeLinecap="round" strokeWidth={1.5} d="M624.5-2794.497h17.769" data-name="Line 286" />
            <path fill="none" stroke="#707070" strokeLinecap="round" strokeWidth={1.5} d="M624.5-2800.66h17.769" data-name="Line 287" />
            <path fill="none" stroke="#707070" strokeLinecap="round" strokeWidth={1.5} d="M624.5-2806.824h17.769" data-name="Line 288" />
            <circle cx={2.776} cy={2.776} r={2.776} fill="#0095da" data-name="Ellipse 208" transform="translate(626.777 -2803.437)" />
            <circle cx={2.776} cy={2.776} r={2.776} fill="#0095da" data-name="Ellipse 209" transform="translate(634.362 -2809.6)" />
            <circle cx={2.776} cy={2.776} r={2.776} fill="#0095da" data-name="Ellipse 210" transform="translate(634.362 -2797.273)" />
        </g>
    </svg>
);
export default AdjustIcon;
