import * as MobX from "mobx";
import { ModelBase } from "@shoothill/core";
import { IEGridCategoryModel, IEGridCategoryModelDTO } from "./IEGridCategoryModel";
import moment from "moment";

export class IEGridModel extends ModelBase<IEGridModel, IEGridModelDTO> {
    public id: string = "";
    public createdDate: string = "";

    @MobX.observable
    public createdMomentDate: moment.Moment | undefined;

    public sourceId: number = 0;

    @MobX.observable
    public parentId: string | null = null;

    @MobX.observable
    public nameId: string = "";

    @MobX.observable
    public name: string = "";

    @MobX.observable
    public cell: number = -1;

    @MobX.observable
    public income: number = 0;

    @MobX.observable
    public estimateCost: number = 0;

    @MobX.observable
    public estimatedMargin: number = 0;

    @MobX.observable
    public futureSpend: number = 0;

    // @MobX.observable
    // public targetCost: number = 0;

    @MobX.observable
    public committedCost: number = 0;

    @MobX.observable
    public totalExpectedSpend: number = 0;

    @MobX.observable
    public variance: number = 0;

    @MobX.observable
    public changePeriod: number = 0;

    @MobX.observable
    public margin: number = 0;

    @MobX.observable
    public roValue: number = 0;

    @MobX.observable
    public type: string = "";

    @MobX.observable
    public categories: IEGridCategoryModel[] = [];
    displayName: any;

    fromDto(model: IEGridModelDTO): void {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else if (key === "categories") {
                    let items: IEGridCategoryModel[] = [];
                    model[key].forEach((element) => {
                        let newModel = new IEGridCategoryModel();
                        newModel.fromDto(element);
                        items.push(newModel);
                    });
                    this.categories = items;
                } else {
                    this[key] = model[key];
                }
            }
        }

        if (model.createdDate !== null && model.createdDate !== undefined) {
            this.createdMomentDate = moment.utc(model.createdDate);
        }
    }

    toDto(model: IEGridModel): void {}
}

export type IEGridModelDTO = {
    id: string;
    nameId: string;
    name: string;
    sourceId: number;
    cell: number;
    createdDate: string;
    income: number;
    estimateCost: number;
    estimatedMargin: number;
    futureSpend: number;
    //targetCost: number;
    committedCost: number;
    totalExpectedSpend: number;
    variance: number;
    changePeriod: number;
    margin: number;
    roValue: number;
    categories: IEGridCategoryModelDTO[];
    type: string;
};

export interface IEItemModalRequest {
    categoryId: number;
    subCategoryId: number;
    descriptionId: number;
    itemId: string;
}
