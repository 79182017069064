import { FieldType, ViewModelBase } from "@shoothill/core";
import { action, observable, computed } from "mobx";
import { VariationItemViewModel } from "./VariationItemViewModel";
import { VariationSubCategoryModel } from "./VariationSubCategoryModel";

export class VariationSubCategoryViewModel extends ViewModelBase<VariationSubCategoryModel> {
    // #region Constructors and Disposers
    constructor(variationSubCategory: VariationSubCategoryModel) {
        super(variationSubCategory);
        this.setDecorators(VariationSubCategoryViewModel);

        this.createViewModels();
    }

    // #region Properties

    @observable
    public variationItemViewModels: VariationItemViewModel[] = [];

    @computed
    public get futureSpendSum(): number {
        // Sum of expected costs.

        return this.variationItemViewModels
            .filter((i) => !i.model.isDeleted)
            .map((item) => (item.model.futureSpend ? item.model.futureSpend : 0))
            .reduce((prev, next) => parseFloat((prev + next).toFixed(2)), 0);
    }

    @computed
    public get lineTotalSum(): number {
        // Sum of line totals.

        return this.variationItemViewModels
            .filter((i) => !i.model.isDeleted)
            .map((item) => (item.lineTotal ? item.lineTotal : 0))
            .reduce((prev, next) => parseFloat((prev + next).toFixed(2)), 0);
    }

    // #endregion Properties

    @action
    private createViewModels() {
        for (const item of this.model.variationItems) {
            this.variationItemViewModels.push(new VariationItemViewModel(item));
        }
    }

    @action
    public reset = () => {
        this.model.reset();
    };

    /**
     * Custom model validation function. Validates child item models
     * @returns True if model is valid, false if not.
     */
    public isMyModelValid = async (): Promise<boolean> => {
        let isValid = true;

        // JC: Changed forEach into for loop as the await seems to have issues with forEach.
        for (let i = 0; i < this.variationItemViewModels.filter((i) => !i.model.isDeleted).length; i++) {
            let item = this.variationItemViewModels[i];

            // Validate each child item.
            if ((await item.isModelValid()) === false) {
                isValid = false;
            }
        }

        // The subcategory has no fields of its own that require validation.

        return isValid;
    };

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<VariationSubCategoryModel>): Promise<boolean> {
        return true;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
