import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";

import { ContactViewViewModel } from "./ContactViewViewModel";
import { ContactViewWrapper, ContactViewRow, ContactViewTitle, ContactViewDetails, EmailCol } from "./ContactView.styles";
import EmailLogo from "Content/Email.svg";
import { ContactBaseDTO } from "Globals/Models/Domain";
import { isNullOrUndefined } from "@shoothill/core";

interface IContactViewProps {
    model: ContactBaseDTO;
    isLoading: boolean;
}

export const ContactView: React.FunctionComponent<IContactViewProps> = ({ model }) => {
    const [viewModel] = useState(() => new ContactViewViewModel());

    //useEffect below only gets run on initial render
    useEffect(() => {
        return () => {
            viewModel.cleanUp();
        };
    }, []);

    const mailto: string = "mailto:" + (isNullOrUndefined(model) === false ? model?.email : "");

    return useObserver(() => (
        <ContactViewWrapper>
            <ContactViewRow>
                <ContactViewTitle>Primary contact:</ContactViewTitle>
                <ContactViewDetails>{model.firstName + " " + model.lastName}</ContactViewDetails>
            </ContactViewRow>
            <ContactViewRow>
                <ContactViewTitle>Contact email:</ContactViewTitle>
                <ContactViewDetails>
                    <Box>{model.email}</Box>{" "}
                    {model.id !== "" && model.id !== null && model.id !== undefined && (
                        <EmailCol>
                            <a href={mailto}>
                                <img src={EmailLogo} alt="Email contact" className="email" />
                            </a>
                        </EmailCol>
                    )}
                </ContactViewDetails>
            </ContactViewRow>
            <ContactViewRow>
                <ContactViewTitle>Contact phone:</ContactViewTitle>
                <ContactViewDetails>{model.phone}</ContactViewDetails>
            </ContactViewRow>
        </ContactViewWrapper>
    ));
};
