const TransferIcon = (props: any) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={20.936} height={12} {...props}>
        <g data-name="Group 9506">
            <g fill="#425b66" data-name="Group 9505" transform="rotate(90 10.468 10.468)">
                <path d="m4.486 0 4.487 4.985H0Z" data-name="Polygon 26" />
                <rect width={1.994} height={9.97} data-name="Rectangle 2978" rx={0.997} transform="translate(3.491 1.993)" />
            </g>
            <g fill="#425b66" data-name="Group 9504" transform="rotate(90 4.468 7.495)">
                <path d="M4.487 11.963 0 6.978h8.973Z" data-name="Polygon 27" />
                <rect width={1.994} height={9.97} data-name="Rectangle 2980" rx={0.997} transform="rotate(180 2.741 4.985)" />
            </g>
        </g>
    </svg>
);
export default TransferIcon;
