import { FieldType, isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import type { ValidationResponse } from "@shoothill/core";
import { action, computed } from "mobx";
import { formatCurrencyFromPounds } from "Utils/Format";
import { VariationLineModalModel } from "./VariationLineModalModel";

export class VariationLineModalViewModel extends ViewModelBase<VariationLineModalModel> {
    // #region Constructors and Disposers
    constructor(item: VariationLineModalModel) {
        super(item);
        this.setDecorators(VariationLineModalViewModel);
    }

    // #region Properties

    // #endregion Properties

    @action
    public reset = () => {
        this.model.reset();
    };

    @action
    public setQuantity = (val: string) => {
        this.model.quantity = val !== null && val !== "" ? Number(val) : null;
    };

    @action
    public setRate = (val: string) => {
        this.model.rate = val !== null && val !== "" ? Number(val) : null;
    };

    @action
    public setFutureSpend = (val: string) => {
        this.model.futureSpend = val !== null && val !== "" ? Number(val) : null;
    };

    @computed
    private get validateRate(): ValidationResponse {
        const errorMessage = this.model.validateRate;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateVariationUnitName(): ValidationResponse {
        const errorMessage = this.model.validateVariationUnitName;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateQuantity(): ValidationResponse {
        const errorMessage = this.model.validateQuantity;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateFutureSpend(): ValidationResponse {
        const errorMessage = this.model.validateFutureSpend;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateCategory(): ValidationResponse {
        const errorMessage = this.model.validateCategory;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateSubCategory(): ValidationResponse {
        const errorMessage = this.model.validateSubCategory;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateDescriptionId(): ValidationResponse {
        const errorMessage = this.model.validateDescriptionId;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateDescriptionOther(): ValidationResponse {
        const errorMessage = this.model.validateDescriptionOther;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    /**
     * The line total calculation per variation item.
     * Returns null if nothing can be calculated.
     * (rate * quantity).
     */
    @computed
    public get lineTotal(): number | null {
        if (this.model.rate !== null && this.model.quantity !== null && !isNaN(this.model.rate) && !isNaN(this.model.quantity)) {
            return this.model.rate * this.model.quantity;
        } else {
            return null;
        }
    }

    /**
     * The formatted line total. E.g. £0.00.
     */
    @computed
    public get lineTotalFormatted(): string {
        if (this.lineTotal !== null) {
            return formatCurrencyFromPounds(this.lineTotal);
        } else {
            return "-";
        }
    }

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<VariationLineModalModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        switch (fieldName) {
            case "rate": {
                const result = this.validateRate;

                errorMessage = result.errorMessage;
                isValid = result.isValid;
                break;
            }

            case "variationUnitName": {
                const result = this.validateVariationUnitName;
                errorMessage = result.errorMessage;
                isValid = result.isValid;
                break;
            }

            case "quantity": {
                const result = this.validateQuantity;

                errorMessage = result.errorMessage;
                isValid = result.isValid;
                break;
            }

            case "futureSpend": {
                const result = this.validateFutureSpend;

                errorMessage = result.errorMessage;
                isValid = result.isValid;
                break;
            }

            case "category": {
                const result = this.validateCategory;

                errorMessage = result.errorMessage;
                isValid = result.isValid;
                break;
            }

            case "subCategory": {
                const result = this.validateSubCategory;

                errorMessage = result.errorMessage;
                isValid = result.isValid;
                break;
            }

            case "descriptionId": {
                const result = this.validateDescriptionId;

                errorMessage = result.errorMessage;
                isValid = result.isValid;
                break;
            }

            case "descriptionOther": {
                const result = this.validateDescriptionOther;

                errorMessage = result.errorMessage;
                isValid = result.isValid;
                break;
            }
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
