import { ApiResult, FieldType, KeyValuePair, observable, ViewModelBase } from "@shoothill/core";
import * as mobx from "mobx";
import { RequisitionsModel, RequisitionsModelDTO, RequisitionsTableModel } from "./Requisitions.Model";
import { RequisitionsViewModel } from "./Requisitions.ViewModel";
import { AppUrls } from "AppUrls";
import { debounce } from "@material-ui/core";

export class RequisitionsListViewModel extends ViewModelBase<any> {
    private static _instance: RequisitionsListViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    public constructor() {
        super(new RequisitionsModel(), false);
        this.setDecorators(RequisitionsModel);
        (window as any).vm = this;
    }

    @observable
    private requisitionsArr: mobx.IObservableArray<RequisitionsViewModel> = observable<RequisitionsViewModel>([]);

    @mobx.computed
    public get returnRequisitions(): RequisitionsTableModel[] {
        return this.requisitionsArr
            .filter((vm) => vm.matchesFilter(this.filterSearchString))
            .map((item: RequisitionsViewModel) => {
                return item.model.toTableModel();
            });
    }

    @mobx.action
    private populateViewModels = (dto: RequisitionsTabsDTO) => {
        const po: RequisitionsViewModel[] = [];

        for (const item of dto.requisitionsLists) {
            let vm = new RequisitionsViewModel();
            vm.model.fromDto(item);
            po.push(vm);
        }

        this.requisitionsArr.replace(po);
    };

    @mobx.action
    public apiGetRequisitions = async (val: string): Promise<void> => {
        const request: RequestModel = {
            Id: val,
        };
        let apiResult = await this.Post<RequisitionsTabsDTO>(AppUrls.Server.Projects.IncomeExpend.GetRequisitionsList, request);
        if (apiResult) {
            if (apiResult.wasSuccessful) {
                this.populateViewModels(apiResult.payload);
            } else {
                console.log(apiResult.errors);
            }
        }
    };

    @mobx.action
    public GetAllRequisitions = async (): Promise<void> => {
        let apiResult = await this.Get<RequisitionsTabsDTO>(AppUrls.Server.PurchaseOrder.GetAllRequisitionList);
        if (apiResult) {
            if (apiResult.wasSuccessful) {
                this.populateViewModels(apiResult.payload);
            } else {
                console.log(apiResult.errors);
            }
        }
    };

    // #region Search String Filter

    private readonly DEBOUNCE_VALUE_MS = 200;

    @observable
    public searchString: string = "";

    @observable
    public filterSearchString: string = "";

    public getSearchString = () => {
        return mobx.computed(() => this.searchString);
    };

    @mobx.action
    public setSearchString = (value: string) => {
        this.searchString = value;
        this.setFilterSearchString(value);
    };

    private setFilterSearchString = debounce(
        mobx.action((value: string) => {
            this.filterSearchString = value;
        }),
        this.DEBOUNCE_VALUE_MS,
    );

    // #endregion Search String Filter

    public async isFieldValid(fieldName: keyof FieldType<RequisitionsModel>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}

export interface RequisitionsTabsDTO {
    requisitionsLists: RequisitionsModel[];
}

export interface RequestModel {
    Id: string;
}
