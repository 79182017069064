import { ModelBase } from "@shoothill/core";
import { IsNotEmpty } from "class-validator";
import { observable } from "mobx";

export class LandingModel extends ModelBase<LandingModel> {
    @observable
    @IsNotEmpty({ message: "You must enter your last name" })
    public lastName: string = "";
    @observable
    @IsNotEmpty({ message: "You must enter the last three digits of your mobile phone number" })
    public lastThreeDigits: string = "";

    public projectId: string = "";

    constructor() {
        super();
    }

    fromDto(model: any): void {
        throw new Error("Method not implemented.");
    }
    toDto(model: LandingModel): void {
        throw new Error("Method not implemented.");
    }
}
