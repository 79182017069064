import { FieldType, ViewModelBase } from "@shoothill/core";
import { VariationListModel } from "./VariationListModel";

export class VariationViewModel extends ViewModelBase<VariationListModel> {
    public constructor() {
        super(new VariationListModel(), false);
        this.setDecorators(VariationListModel);
    }

    public async isFieldValid(fieldName: keyof FieldType<VariationListModel>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
