import { ModelBase } from "@shoothill/core";
import { action, observable, computed } from "mobx";

export class CategoryModel extends ModelBase<CategoryModel, CategoryDTO> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Defaults and Constants

    public static readonly DEFAULT_ID = "";
    public static readonly DEFAULT_DISPLAYNAME = "";
    public static readonly DEFAULT_IEID = null;
    public static readonly DEFAULT_VARIATIONID = null;

    // #endregion Defaults and Constants

    // #region Observables

    @observable
    public id: string = CategoryModel.DEFAULT_ID;

    @observable
    public displayName: string = CategoryModel.DEFAULT_DISPLAYNAME;

    @observable
    public incomeAndExpenditureId: string | null = CategoryModel.DEFAULT_IEID;

    @observable
    public variationId: string | null = CategoryModel.DEFAULT_VARIATIONID;

    @computed
    public get isVariation(): boolean {
        return this.variationId !== "" && this.variationId !== CategoryModel.DEFAULT_VARIATIONID;
    }

    // #endregion Observables

    // #region Actions

    @action
    public reset(): void {
        this.id = CategoryModel.DEFAULT_ID;
        this.displayName = CategoryModel.DEFAULT_DISPLAYNAME;
        this.incomeAndExpenditureId = CategoryModel.DEFAULT_IEID;
        this.variationId = CategoryModel.DEFAULT_VARIATIONID;
    }

    @action
    public fromDto(dto: CategoryDTO): void {
        this.id = dto.id;
        this.displayName = dto.label;
        this.incomeAndExpenditureId = dto.ieId;
        this.variationId = dto.variationId;
    }

    public toDto() {
        throw new Error("Method not implemented.");
    }

    // #endregion Actions

    // #region Helpers

    public static fromDtos(dtos: CategoryDTO[]): CategoryModel[] {
        const types: CategoryModel[] = [];

        for (const dto of dtos) {
            const model = new CategoryModel();

            model.fromDto(dto);
            types.push(model);
        }

        return types;
    }

    // #endregion Helpers
}

export interface CategoryDTO {
    id: string;
    label: string;
    ieId: string | null;
    variationId: string | null;
}
