import { Link } from "@material-ui/core";
import React from "react";

import { DownloadFileIcon } from "Content/DownloadFileIcon";
import { DownloadDrawingContainer } from ".";

interface IProps {
    fileName: string;
    attachmentUrl: string;
    inlineUrl: string;
}

export const DownloadDrawingView: React.FC<IProps> = (props) => {
    /**
     * This is required should you host this component on a table row
     * that is clickable.
     */
    const onLinkClick = (event: any) => {
        event.stopPropagation();
    };

    return (
        <DownloadDrawingContainer>
            <Link className="downloaddrawing-attachment" onClick={onLinkClick} href={props.attachmentUrl} title={`Download ${props.fileName}`}>
                <DownloadFileIcon />
            </Link>
            <Link className="downloaddrawing-inline" onClick={onLinkClick} href={props.inlineUrl} target="_blank" title={`View ${props.fileName}`}>
                {props.fileName}
            </Link>
        </DownloadDrawingContainer>
    );
};
