import { isEmptyOrWhitespace } from "@shoothill/core";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { useRef } from "react";

import { SHBox } from "Components/Box";
import { FilesViewModel } from "./FilesViewModel";
import { File } from "../File/File";
import { FilesRoot } from "../Shared/Files.styles";

export interface IFilesProps {
    className?: string;
    viewModel: FilesViewModel;
    disabled: boolean;
}

export const Files: React.FC<IFilesProps> = observer((props) => {
    // #region Code Behind

    const getClassName = (): string => {
        return clsx({
            "file-button": true,
            "file-button-error": haveErrorMessage(),
        });
    };

    const inputReference = useRef<any>(null);

    const displayName = (): string => {
        return haveErrorMessage() ? props.viewModel.errorMessage : allowMultipleFiles() ? "Click to add one or more files" : "Click to add a file";
    };

    const allowMultipleFiles = (): boolean => {
        return props.viewModel.allowMultipleFiles;
    };

    const fileTypes = (): string => {
        return props.viewModel.fileTypes;
    };

    const isDisabled = (): boolean => {
        return !props.viewModel.canAdd || props.disabled;
    };

    const haveErrorMessage = (): boolean => {
        return !isEmptyOrWhitespace(props.viewModel.errorMessage);
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (!isDisabled()) {
            props.viewModel.add(event.target.files);

            // Do this to allow the file(s) to be reselected the next time the file explorer is open.
            event.target.value = "";
        }
    };

    const onDropFile = (event: React.DragEvent<HTMLElement>) => {
        event.preventDefault();

        if (!isDisabled()) {
            // TODO: APM - Extend this to cover dataTransfer.items.
            props.viewModel.add(event.dataTransfer.files);
        }
    };

    const onDragFile = (event: React.DragEvent<HTMLElement>) => {
        event.preventDefault();
    };

    const onClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        inputReference?.current?.click();
    };

    // #endregion Code Behind

    return (
        <FilesRoot>
            <div className={getClassName()} onDragEnter={onDragFile} onDragOver={onDragFile} onDrop={onDropFile}>
                <input
                    accept={fileTypes()}
                    multiple={allowMultipleFiles()}
                    ref={inputReference}
                    style={{ display: "none" }}
                    type="file"
                    onChange={onChange}
                    disabled={isDisabled()}
                />
                <button disabled={isDisabled()} onClick={onClick} type="button">
                    {displayName()}
                </button>
            </div>
            {props.viewModel.fileViewModels.length > 0 && (
                <SHBox className="file-container" dc={"1fr"} mt="1rem">
                    {props.viewModel.fileViewModels.map((viewModel) => {
                        return <File className={props.className} viewModel={viewModel} key={viewModel.KEY} />;
                    })}
                </SHBox>
            )}
        </FilesRoot>
    );
});
