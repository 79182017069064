import styled from "styled-components";

export const DownloadDrawingContainer = styled.div`
    align-items: center;
    display: flex;
    font-size: 0.75rem;

    .downloaddrawing-attachment {
        margin-right: 0.5rem;
        margin-top: 0.25rem;
    }

    .downloaddrawing-inline {
        line-height: 1.25;
    }

    .nodrawings {
        color: #0095da;
        line-height: 1.25;
    }
`;
