import { computed } from "mobx";
import { AccidentGenericListItemDTO, AccidentGenericListItemModel } from "./AccidentGenericListItemModel";

export class AccidentInjuryTypeItemModel extends AccidentGenericListItemModel {
    constructor() {
        super();
    }

    fromDto(model: AccidentInjuryTypeItemDTO): void {
        super.fromDto(model);
        this.typeId = model.injuryTypeId;
    }

    @computed
    public get injuryTypeId() {
        return this.typeId;
    }

    public static fromDtos(dtos: AccidentInjuryTypeItemDTO[]): AccidentInjuryTypeItemModel[] {
        const types: AccidentInjuryTypeItemModel[] = [];

        for (const dto of dtos) {
            const model = new AccidentInjuryTypeItemModel();

            model.fromDto(dto);
            types.push(model);
        }

        return types;
    }

    toDto(): AccidentInjuryTypeItemDTO {
        const dto: AccidentGenericListItemDTO = super.toDto();
        return {
            ...dto,
            injuryTypeId: this.injuryTypeId,
        };
    }
}

export interface AccidentInjuryTypeItemDTO extends AccidentGenericListItemDTO {
    injuryTypeId: string | null;
}
