import { FormLabel } from "@material-ui/core";
import styled from "styled-components";

import { SHBox } from "Components/Box";

export const FormContent = styled(SHBox)`
    margin: 0 1.875rem;
`;

export const FormModalSection = styled(SHBox)`
    display: flex;
    flex-direction: column;
    margin: 0 0 0.625rem 0;
`;

export const FormSection = styled(SHBox)`
    display: flex;
    flex-direction: column;
    margin: 0.375rem 0 0.625rem 0;
`;

export const FormRow = styled(SHBox)`
    margin-bottom: 0.625rem;
    margin-top: 0.625rem;
`;

export const FormLabelValueSection = styled.div`
    display: flex;
    flex-direction: column;
`;

export const FormReadonlyLabel = styled(FormLabel)`
    min-height: 1.3125rem;
`;

export const FormReadOnlyValue = styled(FormLabel)`
    min-height: 1.1876em;
    padding: 0.375rem 0 0.4375rem;
`;

// The following styles are for form summaries. The data displayed is readonly.

export const SummaryRow = styled(SHBox)`
    margin-bottom: 0.375rem;
    margin-top: 0.375rem;
`;

export const SummaryLabelValueSection = styled.div`
    display: flex;
`;

export const SummaryLabelValueSectionStack = styled.div`
    display: flex;
    flex-direction: column;
`;

export const SummaryLabel = styled(FormLabel)`
    line-height: 1.33;
    margin-right: 0.5rem;
    margin-bottom: 0.125rem;
`;

export const SummaryValue = styled(FormLabel)`
    font-weight: normal;
    line-height: 1.33;
    margin-bottom: 0.125rem;
`;
