import { FieldType, ViewModelBase } from "@shoothill/core";
import { computed } from "mobx";
import { VariationViewModel } from "./VariationViewModel";
import { VariationGridModel } from "./VariationGridModel";

export class VariationViewViewModel extends ViewModelBase<VariationViewModel> {
    public constructor() {
        super(new VariationViewModel(), false);
        this.setDecorators(VariationViewModel);
    }

    /**
     * Returns variations and Subs for variation grid
     */
    @computed
    public get variationsAndRelated(): VariationGridModel[] {
        const retArrs: VariationGridModel[] = [];
        const vm = this.model.variations;
        if (vm) {
            for (const variation of vm) {
                retArrs.push(variation);
            }
        }

        return retArrs;
    }

    public async isFieldValid(fieldName: keyof FieldType<VariationViewModel>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
