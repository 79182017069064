import { ModelBase } from "@shoothill/core";
import { action, observable, computed } from "mobx";

export class MaterialModel extends ModelBase<MaterialModel, MaterialDTO> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Defaults and Constants

    public static readonly DEFAULT_ID = "";
    public static readonly DEFAULT_DISPLAYNAME = "";
    public static readonly DEFAULT_SUPPLIERID = null;
    public static readonly DEFAULT_ESTIMATEDPRICE = 0;
    public static readonly DEFAULT_PRICE = 0;
    public static readonly DEFAULT_EFFECTIVEFROMDATE = "";

    // #endregion Defaults and Constants

    // #region Observables

    @observable
    public id: string = MaterialModel.DEFAULT_ID;

    @observable
    public displayName: string = MaterialModel.DEFAULT_DISPLAYNAME;

    @observable
    public supplierId: string | null = MaterialModel.DEFAULT_SUPPLIERID;

    @observable
    public estimatedPrice: number = MaterialModel.DEFAULT_ESTIMATEDPRICE;

    @observable
    public price: number = MaterialModel.DEFAULT_PRICE;

    @observable
    public effectiveFromDate: string = MaterialModel.DEFAULT_EFFECTIVEFROMDATE;

    // #endregion Observables

    // #region Actions

    @action
    public reset(): void {
        this.id = MaterialModel.DEFAULT_ID;
        this.displayName = MaterialModel.DEFAULT_DISPLAYNAME;
        this.supplierId = MaterialModel.DEFAULT_SUPPLIERID;
        this.estimatedPrice = MaterialModel.DEFAULT_ESTIMATEDPRICE;
        this.price = MaterialModel.DEFAULT_PRICE;
        this.effectiveFromDate = MaterialModel.DEFAULT_EFFECTIVEFROMDATE;
    }

    @action
    public fromDto(dto: MaterialDTO): void {
        this.id = dto.id;
        this.displayName = dto.description;
        this.supplierId = dto.supplierId;
        this.estimatedPrice = dto.estimatedPrice;
        this.price = dto.price;
        this.effectiveFromDate = dto.effectiveFromDate;
    }

    public toDto() {
        throw new Error("Method not implemented.");
    }

    // #endregion Actions

    // #region Helpers

    public static fromDtos(dtos: MaterialDTO[]): MaterialModel[] {
        const types: MaterialModel[] = [];

        for (const dto of dtos) {
            const model = new MaterialModel();

            model.fromDto(dto);
            types.push(model);
        }

        return types;
    }

    // #endregion Helpers
}

export interface MaterialDTO {
    id: string;
    supplierId: string;
    estimatedPrice: number;
    price: number;
    effectiveFromDate: string;
    description: string;
}
