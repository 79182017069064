import { FieldType, ViewModelBase, isEmptyOrWhitespace } from "@shoothill/core";
import { action, computed, observable, runInAction } from "mobx";

import { AppUrls } from "AppUrls";
import { OutputCategory } from "Globals/Models/Enums/OutputCategory";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { AddEditOutputModel } from "./AddEditOutputModel";

export class AddOutputViewModel extends ViewModelBase<AddEditOutputModel> {
    public server: ServerViewModel = new ServerViewModel();

    private planOfWorkId: string;
    private outputId: string | null;
    private parentCloseAction: (refreshPage: boolean) => void;

    constructor(planOfWorkId: string, outputId: string | null, closeAction: (refreshPage: boolean) => void) {
        super(new AddEditOutputModel(), false);

        this.planOfWorkId = planOfWorkId;
        this.outputId = outputId;
        this.parentCloseAction = closeAction;

        this.setDecorators(AddEditOutputModel);

        // Load data.
        isEmptyOrWhitespace(outputId) ? this.apiLoadRelatedAsync() : this.apiLoadWithRelatedAsync();
    }

    // #region Properties

    public get displayName() {
        return isEmptyOrWhitespace(this.outputId) ? "Add new output" : "Edit output";
    }

    // #endregion Properties

    // #region Output Types

    @computed
    public get outputTypes() {
        return this.model.outputTypes;
    }

    @computed
    public get outputType() {
        return this.model.outputType;
    }

    @action
    public setOutputType = (value: any | null): void => {
        this.model.outputType = value ?? AddEditOutputModel.DEFAULT_OUTPUTTYPE;
        this.isFieldValid("outputType");
    };

    // #endregion Output Types

    // #region Output Categories

    @computed
    public get outputCategories() {
        return this.model.outputCategories;
    }

    @computed
    public get outputCategory() {
        return this.model.outputCategory;
    }

    @action
    public setOutputCategory = (value: OutputCategory | null): void => {
        this.model.outputCategory = value ?? AddEditOutputModel.DEFAULT_OUTPUTCATEGORY;
        this.isFieldValid("outputCategory");
    };

    // #endregion Output Categories

    // #region Required by date

    @action
    public setRequiredByDate = (value: string | null) => {
        this.model.requiredByDate = value ?? AddEditOutputModel.DEFAULT_REQUIREDBYDATE;
        this.isFieldValid("requiredByDate");
    };

    // #endregion Required by date

    // #region Actions

    @action
    public cancel = () => this.parentCloseAction(false);

    // #endregion Actions

    // #region Api Actions

    @action
    public apiUpsertOutputAsync = async (): Promise<void> => {
        await this.server.command<any>(
            () => this.Post(AppUrls.Server.Projects.ProjectTrackers.OutputTracker.Outputs.Upsert, this.model.toDto()),
            (result) => this.parentCloseAction(true),
            this.isModelValid,
            "Error whilst saving the output data",
        );

        if (this.server.HaveValidationMessage) {
            this.setSnackMessage(this.server.ValidationMessage);
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }
    };

    @action
    public apiLoadRelatedAsync = async (): Promise<void> => {
        await this.server.query<any>(
            () => this.Get(AppUrls.Server.Projects.ProjectTrackers.OutputTracker.Outputs.Related),
            (result) =>
                runInAction(() => {
                    this.model.planOfWorkId = this.planOfWorkId;
                    this.model.fromDto(result);
                }),
            "Error whilst loading the ouput data",
        );

        if (this.server.HaveValidationMessage) {
            this.setSnackMessage(this.server.ValidationMessage);
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }
    };

    @action
    public apiLoadWithRelatedAsync = async (): Promise<void> => {
        await this.server.query<any>(
            () => this.Get(AppUrls.Server.Projects.ProjectTrackers.OutputTracker.Outputs.WithRelated.replace("{outputid}", this.outputId!)),
            (result) => this.model.fromDto(result),
            "Error whilst loading the output data",
        );

        if (this.server.HaveValidationMessage) {
            this.setSnackMessage(this.server.ValidationMessage);
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }
    };

    // #endregion Api Actions

    // #region Snack Bar

    public SNACKERROR = "error";

    @observable
    public snackbarState = false;

    @observable
    public snackType = "";

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public snackMessage = "";

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    // #endregion Snack Bar

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<AddEditOutputModel>): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boliderplate
}
