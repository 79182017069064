import { UserListContainer } from "./UserListContainer";
import React from "react";

export const UsersList: React.FC = () => {
    return (
        <>
            <UserListContainer />
        </>
    );
};
