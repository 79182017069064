import * as React from "react";
import { DialogActions, DialogContent, DialogTitle, PaperProps } from "@material-ui/core";
import { BaseModalDialogPaper } from "./BaseModal.styles";
import { Dialog } from "@material-ui/core";
import { CustomHr } from "Views/Project/CustomComponents";

interface IProps {
    open?: boolean;
    onClose(): void;
    title: string;
    alternateTitle?: React.ReactChild;
    actions?: React.ReactChild;
    PaperComponent?: React.ComponentType<PaperProps>;
    dialogClasses?: string;
    canClose?: boolean;
    onClick?: (event: any) => void;
}

export const BaseModal: React.FC<IProps> = ({ open, dialogClasses, onClose, onClick, title, alternateTitle, children, actions, PaperComponent, canClose = true }) => (
    <Dialog
        className={dialogClasses}
        PaperComponent={PaperComponent || BaseModalDialogPaper}
        open={open === true}
        onClose={onClose}
        onClick={onClick}
        disableAutoFocus
        disableEnforceFocus
        disableRestoreFocus
    >
        <DialogTitle className="darwin-dialog-title">
            {alternateTitle !== undefined ? (
                <>{alternateTitle}</>
            ) : (
                <>
                    <span>{title}</span>
                    {canClose && (
                        <span role="button" onClick={onClose} className="modal-close">
                            🗙
                        </span>
                    )}
                </>
            )}
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        {actions !== undefined && (
            <div style={{ padding: "0 20px" }}>
                <CustomHr border={"1px solid #CED4DA"} />
                <DialogActions>{actions}</DialogActions>
            </div>
        )}
    </Dialog>
);
