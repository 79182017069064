import React from "react";
import { observer } from "mobx-react-lite";
import { SHBox } from "../../../Components/Box";
import styled from "styled-components";
import { Loader } from "@shoothill/core";
import { InductionViewModel } from "../InductionViewModel";

const Title = styled.p`
    font-size: 18px !important;
    font-weight: 600;
`;

interface Props {
    parentViewModel: InductionViewModel;
}

export const Submitted: React.FC<Props> = observer((props: Props) => {
    if (props.parentViewModel.isStageLoading) {
        return (
            <>
                <Loader />
            </>
        );
    }

    return (
        <>
            <SHBox height={"85vh"} ml={3} mt={3} mr={3}>
                <Title>Thank you for completing the Darwin Group site induction.</Title>
                <Title>Remember, the most important thing is that you stay safe!</Title>
            </SHBox>
        </>
    );
});
