import styled from "styled-components";
import { BaseModalDialogPaper } from "Components/Modal/BaseModal.styles";

export const InductionPhotoEvidenceModalDialogPaper = styled(BaseModalDialogPaper)`
    width: 75%;
    min-width: 300px;
    max-width: 640px;

    .MuiDialogContent-root {
        p {
            padding-bottom: 40px;
            font-size: 14px;
        }
    }

    display: flex;
    flex-direction: column;
    max-height: 90vh;

    .modal-photoevidence-body {
        flex: 1 1 auto;
        padding: 0 10px;
        overflow-y: auto;

        .photoEvidence-container {
            border: 1px solid #707070;
            padding: 20px;
            text-align: center;

            img {
                max-width: 450px;
            }
        }
    }
`;
