// Libs
import React from "react";
import { useObserver } from "mobx-react-lite";

// App
import { AddressModel } from "Globals/Models/Domain";
//import { AddressListItem } from "./AddressListItem";

// Styling
//import { AddressStyledList } from "./Address.styles";
import { ConstructionAddressComponentView } from "./ConstructionAddressComponentView";
import AddressViewModel from "Globals/ViewModels/AddressViewModel";
import { Typography } from "@material-ui/core";

interface IAddressLIProps {
    addressViewModels: AddressViewModel[];
    addressRowClicked: (val: string) => any;
    addressPrimaryText?: string;
    addressDelete: (model: AddressModel, index: number) => any;
    addressDeleteText?: string;
}

export const ConstructionAddressListContainer: React.FC<IAddressLIProps> = (props) => {
    const { addressViewModels, addressRowClicked, addressDelete, addressDeleteText, addressPrimaryText } = props;

    return useObserver(() => (
        <>
            <div>
                {addressViewModels.map((address: AddressViewModel, index: number) => {
                    return (
                        <div style={{ marginBottom: "40px", padding: "0px 30px" }}>
                            {!address.model.isDeleted && (
                                <>
                                    <Typography variant="h2">Address {index + 1}</Typography>
                                    <ConstructionAddressComponentView
                                        onUpdateModel={() => {}}
                                        viewModel={address}
                                        isLoading={false}
                                        showAddButton={false}
                                        isDeliveryAddresses={true}
                                    />
                                </>
                            )}
                        </div>
                    );
                })}
            </div>
        </>
    ));
};
