import { ModelBase } from "@shoothill/core";
import { action, observable } from "mobx";

export class RamsItemModel extends ModelBase<RamsItemModel, IRAMSResponse> {
    // #region Defaults

    public static readonly DEFAULT_ID = "";
    public static readonly DEFAULT_DISPLAYNAME = "";
    public static readonly DEFAULT_ATTACHMENTCOUNT = 0;

    // #endregion Defaults

    // #region Observables

    @observable
    public id: string = RamsItemModel.DEFAULT_ID;

    @observable
    public displayName: string = RamsItemModel.DEFAULT_DISPLAYNAME;

    @observable
    public attachmentCount: number = RamsItemModel.DEFAULT_ATTACHMENTCOUNT;

    // #endregion Observables

    // #region Actions

    @action
    public reset(): void {
        this.id = RamsItemModel.DEFAULT_ID;
        this.displayName = RamsItemModel.DEFAULT_DISPLAYNAME;
        this.attachmentCount = RamsItemModel.DEFAULT_ATTACHMENTCOUNT;
    }

    @action
    public fromDto(dto: IRAMSResponse): void {
        this.id = dto.id;
        this.displayName = dto.title;
        this.attachmentCount = dto.attachmentCount;
    }

    public toDto() {
        throw new Error("Method not implemented.");
    }

    // #endregion Actions
}

export interface IRAMSResponse {
    id: string;
    title: string;
    attachmentCount: number;
}
