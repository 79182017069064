import { Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { AppUrls } from "AppUrls";
import { ClassNameMap } from "@material-ui/styles";
import { BreadcrumbViewModel } from "../BreadcrumbViewModel";

export const PurchaseMatchSection1 = (classes: ClassNameMap<"root" | "common" | "prev" | "heading" | "main" | "secondary" | "lists" | "nested">): any => {
    return (
        <div>
            <Link component={RouterLink} to={AppUrls.Client.PurchaseOrder.List} className={classes.heading}>
                Purchase Orders
            </Link>
            <Link component={RouterLink} to={AppUrls.Client.PurchaseOrder.List}>
                <div className={classes.main}>
                    Purchase Orders
                    <span className="triangle"></span>
                </div>
            </Link>
            <Link component={RouterLink} to={AppUrls.Client.PurchaseOrder.RequisitionList} className={classes.prev}>
                Requisition
            </Link>
            <Link component={RouterLink} to={AppUrls.Client.PurchaseOrder.RejectedList} className={classes.prev}>
                Rejected
            </Link>
        </div>
    );
};

export const PurchaseMatchSection2 = (
    viewModel: BreadcrumbViewModel,
    classes: ClassNameMap<"root" | "common" | "prev" | "heading" | "main" | "secondary" | "lists" | "nested">,
    plusSign: any,
): any => {
    return (
        <div className={classes.secondary}>
            <div onClick={viewModel.onAddPurchaseOrder}>
                <img src={plusSign} className="add-image" alt="Add new purchase order" />
                <span className="add-text">Add PO request</span>
            </div>
        </div>
    );
};

export const PurchaseMatchSection3 = (classes: ClassNameMap<"root" | "common" | "prev" | "heading" | "main" | "secondary" | "lists" | "nested">): any => {
    return (
        <div>
            <Link component={RouterLink} to={AppUrls.Client.PurchaseOrder.List} className={classes.heading}>
                Purchase Orders
            </Link>
            <Link component={RouterLink} to={AppUrls.Client.PurchaseOrder.List} className={classes.prev}>
                Purchase Orders
            </Link>
            <Link component={RouterLink} to={AppUrls.Client.PurchaseOrder.RequisitionList}>
                <div className={classes.main}>
                    Requisition
                    <span className="triangle"></span>
                </div>
            </Link>
            <Link component={RouterLink} to={AppUrls.Client.PurchaseOrder.RejectedList} className={classes.prev}>
                Rejected
            </Link>
        </div>
    );
};

export const PurchaseMatchSection4 = (classes: ClassNameMap<"root" | "common" | "prev" | "heading" | "main" | "secondary" | "lists" | "nested">): any => {
    return (
        <div>
            <Link component={RouterLink} to={AppUrls.Client.PurchaseOrder.List} className={classes.heading}>
                Purchase Orders
            </Link>
            <Link component={RouterLink} to={AppUrls.Client.PurchaseOrder.List} className={classes.prev}>
                Purchase Orders
            </Link>
            <Link component={RouterLink} to={AppUrls.Client.PurchaseOrder.RequisitionList} className={classes.prev}>
                Requisition
            </Link>
            <Link component={RouterLink} to={AppUrls.Client.PurchaseOrder.RejectedList}>
                <div className={classes.main}>
                    Rejected
                    <span className="triangle"></span>
                </div>
            </Link>
        </div>
    );
};
