import { ModelBase, observable } from "@shoothill/core";
import { PermitTypeDTO } from "./PermitModel";

export class PermitListModel extends ModelBase<PermitListModel> {
    public id: string = "";
    @observable
    public permitTypeName: string = "";
    @observable
    public permitNumber: number = 0;
    @observable
    public issuedByDate: string = "";
    @observable
    public issuedByUserName: string = "";
    @observable
    public issuedToDate: string = "";
    @observable
    public issuedToUserName: string = "";
    @observable
    public surrenderedByDate: string | null = null;
    @observable
    public surrenderedByUserName: string | null = null;
    @observable
    public cancelledByDate: string | null = null;
    @observable
    public cancelledByUserName: string | null = null;

    constructor() {
        super();
    }

    fromDto(dto: PermitListDTO): void {
        this.id = dto.id;
        this.permitTypeName = dto.permitTypeName;
        this.permitNumber = dto.permitNumber;
        this.issuedByDate = dto.issuedByDate;
        this.issuedByUserName = dto.issuedByUserName;
        this.issuedToDate = dto.issuedToDate;
        this.issuedToUserName = dto.issuedToUserName;
        this.surrenderedByDate = dto.surrenderedByDate;
        this.surrenderedByUserName = dto.surrenderedByUserName;
        this.cancelledByDate = dto.cancelledByDate;
        this.cancelledByUserName = dto.cancelledByUserName;
    }

    toDto(model: PermitListModel): void {
        throw new Error("Method not implemented.");
    }
}

export interface PermitListDTO {
    id: string;
    permitTypeName: string;
    permitNumber: number;
    issuedByDate: string;
    issuedByUserName: string;
    issuedToDate: string;
    issuedToUserName: string;
    surrenderedByDate: string | null;
    surrenderedByUserName: string | null;
    cancelledByDate: string | null;
    cancelledByUserName: string | null;
}

export interface PermitListAndRelatedDTO {
    permitListItems: PermitListDTO[];
    permitTypes: PermitTypeDTO[];
}
