import { observable, action, computed } from "mobx";
import { KeyValuePair, ModelBase } from "@shoothill/core";
// import { mobxPendingDecorators } from "mobx/lib/internal";
import moment from "moment";
import { formatDate } from "Utils/Format";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class DeliveryScheduleModel extends ModelBase<DeliveryScheduleModel, DeliveryScheduleModelDTO> {
    // DarwinModelBase
    // public id: string = "";
    // public createdDate: string = "";

    @observable
    public projectId: string = "";

    @observable weekCommencing: string = moment.utc().startOf("isoWeek").toISOString();

    @computed public get getAWeeksSchedule() {
        let retVal: KeyValuePair[] = [];
        let day: moment.Moment = moment(this.weekCommencing);

        for (let i: number = 0; i < 7; i++) {
            const item: KeyValuePair = {
                key: day.format("ddd"),
                value: formatDate(day.toISOString()),
            };

            retVal.push(item);
            day = day.add(1, "day");
        }

        return retVal;
    }

    //fromDto is required but you can leave it blank
    fromDto(model: DeliveryScheduleModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: DeliveryScheduleModel): void {}
}

export type DeliveryScheduleModelDTO = {
    projectId: string;
};
