import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, Button, IconButton, Typography } from "@material-ui/core";
import styled from "styled-components";
import { ProgrammeUpdatesViewModel } from "./ProgrammeUpdatesViewModel";
import { CleanlinessReportView } from "./CleanlinessReport/CleanlinessReportView";
import { CustomerVisitsSiteInstructionsView } from "./CustomerVisitsSiteInstructions/CustomerVisitsSiteInstructionsView";
import { DailyProgrammeProgressUpdateView } from "./DailyProgrammeProgressUpdate/DailyProgrammeProgressUpdateView";
import { OtherProgrammeNotesView } from "./OtherProgrammeNotes/OtherProgrammeNotesView";
import { PotentialProgrammeDelaysView } from "./PotentialProgrammeDelays/PotentialProgrammeDelaysView";
import { OutstandingDesignInformationView } from "./OutstandingDesignInformation/OutstandingDesignInformationView";
import { MaterialStorageView } from "./MaterialStorage/MaterialStorageView";
import { LabourShortageView } from "./LabourShortage/LabourShortageView";
import EditLogo from "Content/AddEdit.svg";
import SystemUpdateTwoToneIcon from "@material-ui/icons/SystemUpdateTwoTone";
import { Grid } from "@shoothill/core";
import { DashedDivider } from "Views/PurchaseOrder/Form/Views/Dividers";
import { WeatherReportView } from "./WeatherReport/WeatherReportView";

interface Props {
    projectId: string;
    date: Date;
}

const UpdateList = styled(Box)`
    h5 {
        font: normal normal normal 14px/40px Open Sans;
        letter-spacing: -0.28px;
        color: #191919;
        cursor: pointer;
    }
`;

const FileUploader = styled(Box)`
    margin-top: 0;
    .fileUpload {
        background: #6c93a626;
        border: 1px dashed #6c93a6;
        font: normal normal normal 12px/14px Open Sans;
        letter-spacing: 0px;
        color: #425b66;
        padding: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 90px;
        margin: 0 !important;
    }
`;

export const ProgrammeUpdatesView: React.FC<Props> = observer((props: Props) => {
    const [viewModel] = useState(() => new ProgrammeUpdatesViewModel(props.projectId, props.date));

    useEffect(() => {
        viewModel.date = props.date;
        viewModel.projectId = props.projectId;
        viewModel.getProgrammeProgressUpdates();
    }, [props.date]);

    const renderList = (
        <UpdateList px={"30px"}>
            {/* Weather Report */}
            {viewModel.weatherReport !== null ? (
                <Box paddingY={"15px"} borderBottom="1px solid #ced4da">
                    <Box display={"flex"} alignItems={"center"} style={{ gap: "10px" }} justifyContent={"space-between"}>
                        <Typography className={"title"} variant={"h5"} style={{ font: "normal normal 700 12px/40px Open Sans", letterSpacing: "-0.24px", color: "#52BB71" }}>
                            Weather report
                        </Typography>
                        <Box display={"flex"} alignItems={"center"} style={{ gap: "20px" }}>
                            <Typography variant={"h4"} style={{ font: "normal normal normal 12px/40px Open Sans", letterSpacing: "-0.24px", color: "#52BB71" }}>
                                {`Last updated ${viewModel.model.weatherReportModel.formattedLastUpdatedDate} by ${viewModel.getUserName(
                                    viewModel.model.weatherReportModel.updatedByUserId,
                                )}`}
                            </Typography>
                            <img src={EditLogo} onClick={viewModel.handleWeatherReportShow} style={{ cursor: "pointer" }} />
                        </Box>
                    </Box>
                    <Typography variant={"h4"} style={{ font: "normal normal normal 12px/16px Open Sans", letterSpacing: "-0.24px", color: "#191919" }}>
                        {viewModel.model.weatherReportModel.weatherReportNote}
                    </Typography>
                </Box>
            ) : (
                <Box paddingY={"5px"} borderBottom="1px solid #ced4da" onClick={viewModel.handleWeatherReportShow}>
                    <Typography className={"title"} variant={"h5"}>
                        <strong>Weather report</strong>
                    </Typography>
                </Box>
            )}

            {/* Building Control Site Visit */}
            {viewModel.buildingControlSiteVisit !== null ? (
                <Box paddingY={"0"} borderBottom="1px solid #ced4da">
                    <Box display={"flex"} alignItems={"center"} style={{ gap: "10px" }} justifyContent={"space-between"}>
                        <Typography className={"title"} variant={"h5"} style={{ font: "normal normal 700 12px/40px Open Sans", letterSpacing: "-0.24px", color: "#52BB71" }}>
                            Building control site visit
                        </Typography>
                        <Box display={"flex"} alignItems={"center"} style={{ gap: "20px" }}>
                            <Typography variant={"h4"} style={{ font: "normal normal normal 12px/40px Open Sans", letterSpacing: "-0.24px", color: "#52BB71" }}>
                                {`Last updated ${viewModel.model.buildingControlSiteVisitModel.formattedLastUpdatedDate} by ${viewModel.getUserName(
                                    viewModel.model.buildingControlSiteVisitModel.updatedByUserId,
                                )}`}
                            </Typography>
                            <img src={EditLogo} onClick={viewModel.navigateBuildingControlSiteVisit} style={{ cursor: "pointer" }} />
                        </Box>
                    </Box>
                </Box>
            ) : (
                <Box paddingY={"5px"} borderBottom="1px solid #ced4da" onClick={viewModel.navigateBuildingControlSiteVisit}>
                    <Typography className={"title"} variant={"h5"}>
                        <strong> Building control site visit</strong>
                    </Typography>
                </Box>
            )}

            {viewModel.model.cleanlinessReportModel.length > 0 ? (
                <Box pb={"18px"} borderBottom="1px solid #ced4da">
                    <Typography className={"title"} variant={"h5"} style={{ font: "normal normal 600 12px/40px Open Sans", letterSpacing: "-0.24px", color: "#EB8D00" }}>
                        <strong>Cleanliness report</strong>
                    </Typography>
                    <Box display={"grid"} gridTemplateColumns={"1fr 1fr"} style={{ gap: "15px" }}>
                        {viewModel.model.cleanlinessReportModel.map((item) => {
                            return (
                                <div>
                                    <Box
                                        display={"flex"}
                                        alignItems={"center"}
                                        justifyContent={"space-between"}
                                        bgcolor={"#EDEDED"}
                                        p={"10px"}
                                        style={{
                                            borderLeft: `18px solid ${viewModel.setBackGroundStatus(item.cleanlinessReportStatusId)}`,
                                        }}
                                    >
                                        <Typography
                                            className={"title"}
                                            variant={"h5"}
                                            style={{ font: "normal normal normal 12px/17px Open Sans", letterSpacing: "-0.24px", color: "#191919" }}
                                        >
                                            {item.cleanlinessSubContractorName}
                                        </Typography>
                                        {/* <Box fontSize={"10px"} lineHeight={"14px"} letterSpacing={"-0.2px"} color={"#006FFF"} style={{ textDecoration: "underline" }}>
                                            Clean up notice
                                        </Box> */}
                                    </Box>
                                    <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"} style={{ gap: "20px" }}>
                                        <Typography
                                            className={"title"}
                                            variant={"h5"}
                                            style={{ font: "normal normal normal 12px/40px Open Sans", letterSpacing: "-0.24px", color: "#52BB71" }}
                                        >
                                            Last updated {item.formattedLastUpdatedDate} by {viewModel.getUserName(item.lastUpdatedByUserId)}
                                        </Typography>
                                        <img src={EditLogo} onClick={() => viewModel.cleanlinessReportId(item.id)} style={{ cursor: "pointer" }} />
                                    </Box>
                                </div>
                            );
                        })}
                    </Box>
                    <DashedDivider />
                    <Box mt={"15px"}>
                        <Button onClick={() => viewModel.handleCleanlinessReportShow()} color="secondary" autoFocus variant="contained">
                            Add another
                        </Button>
                    </Box>
                </Box>
            ) : (
                <Box paddingY={"5px"} borderBottom="1px solid #ced4da" onClick={() => viewModel.handleCleanlinessReportShow()}>
                    <Typography className={"title"} variant={"h5"}>
                        <strong>Cleanliness report</strong>
                    </Typography>
                </Box>
            )}

            {viewModel.customerVisitsSiteInstruction !== null ? (
                <Box paddingY={"15px"} borderBottom="1px solid #ced4da">
                    <Box display={"flex"} alignItems={"center"} style={{ gap: "10px" }} justifyContent={"space-between"}>
                        <Typography className={"title"} variant={"h5"} style={{ font: "normal normal 700 12px/40px Open Sans", letterSpacing: "-0.24px", color: "#52BB71" }}>
                            Customer visits/site instructions
                        </Typography>
                        <Box display={"flex"} alignItems={"center"} style={{ gap: "20px" }}>
                            <Typography variant={"h4"} style={{ font: "normal normal normal 12px/40px Open Sans", letterSpacing: "-0.24px", color: "#52BB71" }}>
                                Last updated {viewModel.model.customerVisitsSiteInstructions.formattedLastUpdatedDate} by
                                {viewModel.getUserName(viewModel.model.customerVisitsSiteInstructions.lastUpdatedByUserId)}
                            </Typography>
                            <img src={EditLogo} onClick={() => viewModel.handleCustomerVisitsSiteInstructionsShow()} style={{ cursor: "pointer" }} />
                        </Box>
                    </Box>
                    <Typography variant={"h4"} style={{ font: "normal normal normal 12px/16px Open Sans", letterSpacing: "-0.24px", color: "#191919" }}>
                        {viewModel.model.customerVisitsSiteInstructions.customerSiteNote}
                    </Typography>
                    <Grid columnGap={30} rowGap={20} tc={"1fr"} dc={"1fr"}>
                        <FileUploader>
                            <Box className="uploadedFile">
                                {viewModel.model.customerVisitsSiteInstructions.customerVisitsSitePhotos.map((item) => {
                                    return (
                                        <Box className="uploadedFile-box">
                                            <Box className="uploadedFile-heading">
                                                <IconButton>
                                                    <SystemUpdateTwoToneIcon onClick={() => viewModel.DownloadFile(item.photoURL, item.fileName)} />
                                                </IconButton>
                                                <h3>{item.fileName}</h3>
                                            </Box>
                                        </Box>
                                    );
                                })}
                            </Box>
                        </FileUploader>
                    </Grid>
                </Box>
            ) : (
                <Box paddingY={"5px"} borderBottom="1px solid #ced4da" onClick={() => viewModel.handleCustomerVisitsSiteInstructionsShow()}>
                    <Typography className={"title"} variant={"h5"}>
                        <strong> Customer visits/site instructions</strong>
                    </Typography>
                </Box>
            )}

            {viewModel.dailyProgrammeProgressUpdate !== null ? (
                <Box paddingY={"15px"} borderBottom="1px solid #ced4da">
                    <Box display={"flex"} alignItems={"center"} style={{ gap: "10px" }} justifyContent={"space-between"}>
                        <Typography className={"title"} variant={"h5"} style={{ font: "normal normal 700 12px/40px Open Sans", letterSpacing: "-0.24px", color: "#52BB71" }}>
                            Daily programme progress update
                        </Typography>
                        <Box display={"flex"} alignItems={"center"} style={{ gap: "20px" }}>
                            <Typography variant={"h4"} style={{ font: "normal normal normal 12px/40px Open Sans", letterSpacing: "-0.24px", color: "#52BB71" }}>
                                Last updated {viewModel.model.dailyProgrammeProgressUpdateModel.formattedLastUpdatedDate} by{"  "}
                                {viewModel.getUserName(viewModel.model.dailyProgrammeProgressUpdateModel.lastUpdatedByUserId)}
                            </Typography>
                            <img src={EditLogo} onClick={() => viewModel.handleDailyProgrammeProgressUpdateShow()} style={{ cursor: "pointer" }} />
                        </Box>
                    </Box>
                    <Typography variant={"h4"} style={{ font: "normal normal normal 12px/16px Open Sans", letterSpacing: "-0.24px", color: "#191919" }}>
                        {viewModel.model.dailyProgrammeProgressUpdateModel.dailyProgrammeProgressNote}
                    </Typography>
                    <Grid columnGap={30} rowGap={20} tc={"1fr"} dc={"1fr"}>
                        <FileUploader>
                            <Box className="uploadedFile">
                                {viewModel.model.dailyProgrammeProgressUpdateModel.dailyProgrammeProgressPhotos.map((item) => {
                                    return (
                                        <Box className="uploadedFile-box">
                                            <Box className="uploadedFile-heading">
                                                <IconButton>
                                                    <SystemUpdateTwoToneIcon onClick={() => viewModel.DownloadFile(item.photoURL, item.fileName)} />
                                                </IconButton>
                                                <h3>{item.fileName}</h3>
                                            </Box>
                                        </Box>
                                    );
                                })}
                            </Box>
                        </FileUploader>
                    </Grid>
                </Box>
            ) : (
                <Box paddingY={"5px"} borderBottom="1px solid #ced4da" onClick={() => viewModel.handleDailyProgrammeProgressUpdateShow()}>
                    <Typography className={"title"} variant={"h5"}>
                        <strong>Daily programme progress update</strong>
                    </Typography>
                </Box>
            )}

            {viewModel.otherProgrammeNote !== null ? (
                <Box paddingY={"15px"} borderBottom="1px solid #ced4da">
                    <Box display={"flex"} alignItems={"center"} style={{ gap: "10px" }} justifyContent={"space-between"}>
                        <Typography className={"title"} variant={"h5"} style={{ font: "normal normal 700 12px/40px Open Sans", letterSpacing: "-0.24px", color: "#52BB71" }}>
                            <strong>Other programme notes</strong>
                        </Typography>
                        <Box display={"flex"} alignItems={"center"} style={{ gap: "20px" }}>
                            <Typography variant={"h4"} style={{ font: "normal normal normal 12px/40px Open Sans", letterSpacing: "-0.24px", color: "#52BB71" }}>
                                Last updated {viewModel.model.otherProgrammeNotesModel.formattedLastUpdatedDate} by{" "}
                                {viewModel.getUserName(viewModel.model.otherProgrammeNotesModel.lastUpdatedByUserId)}
                            </Typography>
                            <img src={EditLogo} onClick={() => viewModel.handleOtherProgrammeNotesShow()} style={{ cursor: "pointer" }} />
                        </Box>
                    </Box>
                    <Typography variant={"h4"} style={{ font: "normal normal normal 12px/16px Open Sans", letterSpacing: "-0.24px", color: "#191919" }}>
                        {viewModel.model.otherProgrammeNotesModel.otherProgrammeNote}
                    </Typography>

                    <Grid columnGap={30} rowGap={20} tc={"1fr"} dc={"1fr"}>
                        <FileUploader>
                            <Box className="uploadedFile">
                                {viewModel.model.otherProgrammeNotesModel.otherProgrammePhotos.map((item) => {
                                    return (
                                        <Box className="uploadedFile-box">
                                            <Box className="uploadedFile-heading">
                                                <IconButton>
                                                    <SystemUpdateTwoToneIcon onClick={() => viewModel.DownloadFile(item.photoURL, item.fileName)} />
                                                </IconButton>
                                                <h3>{item.fileName}</h3>
                                            </Box>
                                        </Box>
                                    );
                                })}
                            </Box>
                        </FileUploader>
                    </Grid>
                </Box>
            ) : (
                <Box paddingY={"5px"} borderBottom="1px solid #ced4da" onClick={() => viewModel.handleOtherProgrammeNotesShow()}>
                    <Typography className={"title"} variant={"h5"}>
                        <strong>Other programme notes</strong>
                    </Typography>
                </Box>
            )}

            {viewModel.model.potentialProgrammeDelaysModel.length > 0 ? (
                <Box paddingY={"15px"} borderBottom="1px solid #ced4da">
                    <Typography className={"title"} variant={"h5"} style={{ font: "normal normal 700 12px/28px Open Sans", letterSpacing: "-0.24px", color: "#52BB71" }}>
                        Potential programme delays
                    </Typography>
                    {viewModel.model.potentialProgrammeDelaysModel.map((item) => {
                        return (
                            <Box>
                                <Box display={"flex"} alignItems={"center"} style={{ gap: "20px" }} justifyContent={"space-between"}>
                                    <Typography variant={"h4"} style={{ font: "normal normal normal 12px/28px Open Sans", letterSpacing: "-0.24px", color: "#52BB71" }}>
                                        Last updated {item.formattedLastUpdatedDate} by {viewModel.getUserName(item.lastUpdatedByUserId)}
                                    </Typography>
                                    <img src={EditLogo} onClick={() => viewModel.potentialProgrammeDelayId(item.id)} style={{ cursor: "pointer" }} />
                                </Box>
                                <Typography
                                    className={"title"}
                                    variant={"h5"}
                                    style={{ font: "normal normal normal 12px/24px Open Sans", letterSpacing: "-0.24px", color: "#191919" }}
                                >
                                    {item.potentialProgrammeNote}
                                </Typography>
                                <Grid columnGap={30} rowGap={20} tc={"1fr"} dc={"1fr"}>
                                    <FileUploader>
                                        <Box className="uploadedFile">
                                            {viewModel.model.potentialProgrammeDelays.potentialProgrammeDelaysPhotos
                                                .filter((a) => a.potentialProgrammeDelaysId === item.id)
                                                .map((item) => {
                                                    return (
                                                        <Box className="uploadedFile-box">
                                                            <Box className="uploadedFile-heading">
                                                                <IconButton>
                                                                    <SystemUpdateTwoToneIcon onClick={() => viewModel.DownloadFile(item.photoURL, item.fileName)} />
                                                                </IconButton>
                                                                <h3>{item.fileName}</h3>
                                                            </Box>
                                                        </Box>
                                                    );
                                                })}
                                        </Box>
                                    </FileUploader>
                                </Grid>
                                <DashedDivider gutterBottom={true} gutterTop={true} borderThickness={1} borderOpacity={0.5} />
                            </Box>
                        );
                    })}
                    <Box>
                        <Button onClick={() => viewModel.handlePotentialProgrammeDelaysShow()} color="secondary" autoFocus variant="contained">
                            Add another
                        </Button>
                    </Box>
                </Box>
            ) : (
                <Box paddingY={"5px"} borderBottom="1px solid #ced4da" onClick={() => viewModel.handlePotentialProgrammeDelaysShow()}>
                    <Typography className={"title"} variant={"h5"}>
                        <strong> Potential programme delays</strong>
                    </Typography>
                </Box>
            )}

            {viewModel.model.outstandingDesignInformationModel.length > 0 ? (
                <Box paddingY={"15px"} borderBottom="1px solid #ced4da">
                    <Typography className={"title"} variant={"h5"} style={{ font: "normal normal 700 12px/28px Open Sans", letterSpacing: "-0.24px", color: "#52BB71" }}>
                        Outstanding design information
                    </Typography>
                    {viewModel.model.outstandingDesignInformationModel.map((item) => {
                        return (
                            <Box>
                                <Box display={"flex"} alignItems={"center"} style={{ gap: "20px" }} justifyContent={"space-between"}>
                                    <Typography variant={"h4"} style={{ font: "normal normal normal 12px/28px Open Sans", letterSpacing: "-0.24px", color: "#52BB71" }}>
                                        Last updated {item.formattedLastUpdatedDate} by {viewModel.getUserName(item.lastUpdatedByUserId)}
                                    </Typography>
                                    <img src={EditLogo} onClick={() => viewModel.outstandingDesignInformationIds(item.id)} style={{ cursor: "pointer" }} />
                                </Box>
                                <Typography
                                    className={"title"}
                                    variant={"h5"}
                                    style={{ font: "normal normal normal 12px/24px Open Sans", letterSpacing: "-0.24px", color: "#191919" }}
                                >
                                    {item.outstandingDesignInformationNote}
                                </Typography>
                                <Grid columnGap={30} rowGap={20} tc={"1fr"} dc={"1fr"}>
                                    <FileUploader>
                                        <Box className="uploadedFile">
                                            {viewModel.model.outstandingDesignInformation.outstandingDesignInformationPhotos
                                                .filter((a) => a.outstandingDesignInformationId === item.id)
                                                .map((item) => {
                                                    return (
                                                        <Box className="uploadedFile-box">
                                                            <Box className="uploadedFile-heading">
                                                                <IconButton>
                                                                    <SystemUpdateTwoToneIcon onClick={() => viewModel.DownloadFile(item.photoURL, item.fileName)} />
                                                                </IconButton>
                                                                <h3>{item.fileName}</h3>
                                                            </Box>
                                                        </Box>
                                                    );
                                                })}
                                        </Box>
                                    </FileUploader>
                                </Grid>
                                <DashedDivider gutterBottom={true} gutterTop={true} borderThickness={1} borderOpacity={0.5} />
                            </Box>
                        );
                    })}
                    <Box>
                        <Button onClick={() => viewModel.handleOutstandingDesignInformationShow()} color="secondary" autoFocus variant="contained">
                            Add another
                        </Button>
                    </Box>
                </Box>
            ) : (
                <Box paddingY={"5px"} borderBottom="1px solid #ced4da" onClick={() => viewModel.handleOutstandingDesignInformationShow()}>
                    <Typography className={"title"} variant={"h5"}>
                        <strong>Outstanding design information</strong>
                    </Typography>
                </Box>
            )}

            {viewModel.model.materialStorageModel.length > 0 ? (
                <Box paddingY={"15px"} borderBottom="1px solid #ced4da">
                    <Typography className={"title"} variant={"h5"} style={{ font: "normal normal 700 12px/28px Open Sans", letterSpacing: "-0.24px", color: "#52BB71" }}>
                        <strong>Material storage</strong>
                    </Typography>
                    {viewModel.model.materialStorageModel.map((item) => {
                        return (
                            <Box>
                                <Box display={"flex"} alignItems={"center"} style={{ gap: "20px" }} justifyContent={"space-between"}>
                                    <Typography variant={"h4"} style={{ font: "normal normal normal 12px/28px Open Sans", letterSpacing: "-0.24px", color: "#52BB71" }}>
                                        Last updated {item.formattedLastUpdatedDate} by {viewModel.getUserName(item.lastUpdatedByUserId)}
                                    </Typography>
                                    <img src={EditLogo} onClick={() => viewModel.materialStorageIds(item.id)} style={{ cursor: "pointer" }} />
                                </Box>
                                <Typography
                                    className={"title"}
                                    variant={"h5"}
                                    style={{ font: "normal normal normal 12px/24px Open Sans", letterSpacing: "-0.24px", color: "#191919" }}
                                >
                                    {item.materialStorageNote}
                                </Typography>
                                <Grid columnGap={30} rowGap={20} tc={"1fr"} dc={"1fr"}>
                                    <FileUploader>
                                        <Box className="uploadedFile">
                                            {viewModel.model.materialStorage.materialStoragePhotos
                                                .filter((a) => a.materialStorageId === item.id)
                                                .map((item) => {
                                                    return (
                                                        <Box className="uploadedFile-box">
                                                            <Box className="uploadedFile-heading">
                                                                <IconButton>
                                                                    <SystemUpdateTwoToneIcon onClick={() => viewModel.DownloadFile(item.photoURL, item.fileName)} />
                                                                </IconButton>
                                                                <h3>{item.fileName}</h3>
                                                            </Box>
                                                        </Box>
                                                    );
                                                })}
                                        </Box>
                                    </FileUploader>
                                </Grid>
                                <DashedDivider gutterBottom={true} gutterTop={true} borderThickness={1} borderOpacity={0.5} />
                            </Box>
                        );
                    })}
                    <Box>
                        <Button onClick={() => viewModel.handleMaterialStorageShow()} color="secondary" autoFocus variant="contained">
                            Add another
                        </Button>
                    </Box>
                </Box>
            ) : (
                <Box paddingY={"5px"} borderBottom="1px solid #ced4da" onClick={() => viewModel.handleMaterialStorageShow()}>
                    <Typography className={"title"} variant={"h5"}>
                        <strong>Material storage</strong>
                    </Typography>
                </Box>
            )}

            {viewModel.model.labourShortageModel.length > 0 ? (
                <Box paddingY={"15px"} borderBottom="1px solid #ced4da">
                    <Typography className={"title"} variant={"h5"} style={{ font: "normal normal 700 12px/28px Open Sans", letterSpacing: "-0.24px", color: "#52BB71" }}>
                        <strong>Labour shortage</strong>
                    </Typography>
                    {viewModel.model.labourShortageModel.map((item) => {
                        return (
                            <Box>
                                <Box display={"flex"} alignItems={"center"} style={{ gap: "20px" }} justifyContent={"space-between"}>
                                    <Typography variant={"h4"} style={{ font: "normal normal normal 12px/28px Open Sans", letterSpacing: "-0.24px", color: "#52BB71" }}>
                                        Last updated {item.formattedLastUpdatedDate} by {viewModel.getUserName(item.lastUpdatedByUserId)}
                                    </Typography>
                                    <img src={EditLogo} onClick={() => viewModel.labourShortageIds(item.id)} style={{ cursor: "pointer" }} />
                                </Box>
                                <Typography
                                    className={"title"}
                                    variant={"h5"}
                                    style={{ font: "normal normal normal 12px/17px Open Sans", letterSpacing: "-0.24px", color: "#191919" }}
                                >
                                    {item.labourShortageNote}
                                </Typography>
                                <Grid columnGap={30} rowGap={20} tc={"1fr"} dc={"1fr"}>
                                    <FileUploader>
                                        <Box className="uploadedFile">
                                            {viewModel.model.labourShortage.labourShortagePhotos
                                                .filter((a) => a.labourShortageId === item.id)
                                                .map((item) => {
                                                    return (
                                                        <Box className="uploadedFile-box">
                                                            <Box className="uploadedFile-heading">
                                                                <IconButton>
                                                                    <SystemUpdateTwoToneIcon onClick={() => viewModel.DownloadFile(item.photoURL, item.fileName)} />
                                                                </IconButton>
                                                                <h3>{item.fileName}</h3>
                                                            </Box>
                                                        </Box>
                                                    );
                                                })}
                                        </Box>
                                    </FileUploader>
                                </Grid>
                                <DashedDivider gutterBottom={true} gutterTop={true} borderThickness={1} borderOpacity={0.5} />
                            </Box>
                        );
                    })}
                    <Box>
                        <Button onClick={() => viewModel.handleLabourShortageShow()} color="secondary" autoFocus variant="contained">
                            Add another
                        </Button>
                    </Box>
                </Box>
            ) : (
                <Box paddingY={"5px"} borderBottom="1px solid #ced4da" onClick={() => viewModel.handleLabourShortageShow()}>
                    <Typography className={"title"} variant={"h5"}>
                        <strong>Labour shortage</strong>
                    </Typography>
                </Box>
            )}
        </UpdateList>
    );

    return (
        <>
            {renderList}
            {viewModel.cleanlinessReport && (
                <CleanlinessReportView
                    formId={viewModel.cleanlinessReportFormId!}
                    date={props.date}
                    open={viewModel.cleanlinessReport}
                    onClose={() => viewModel.handleCleanlinessReportClose()}
                />
            )}
            {viewModel.canDisplayWeatherReport && (
                <WeatherReportView
                    open={viewModel.canDisplayWeatherReport}
                    onClose={viewModel.handleWeatherReportClose}
                    projectId={props.projectId}
                    weatherReportDate={props.date}
                />
            )}
            {viewModel.customerVisitsSiteInstructions && (
                <CustomerVisitsSiteInstructionsView
                    date={props.date}
                    open={viewModel.customerVisitsSiteInstructions}
                    onClose={viewModel.handleCustomerVisitsSiteInstructionsClose}
                />
            )}
            {viewModel.dailyProgrammeProgress && (
                <DailyProgrammeProgressUpdateView date={props.date} open={viewModel.dailyProgrammeProgress} onClose={() => viewModel.handleDailyProgrammeProgressUpdateClose()} />
            )}
            {viewModel.otherProgrammeNotes && (
                <OtherProgrammeNotesView date={props.date} open={viewModel.otherProgrammeNotes} onClose={() => viewModel.handleOtherProgrammeNotesClose()} />
            )}
            {viewModel.potentialProgrammeDelays && (
                <PotentialProgrammeDelaysView
                    formId={viewModel.potentialProgrammeDelaysId!}
                    date={props.date}
                    open={viewModel.potentialProgrammeDelays}
                    onClose={() => viewModel.handlePotentialProgrammeDelaysClose()}
                />
            )}
            {viewModel.outstandingDesignInformation && (
                <OutstandingDesignInformationView
                    date={props.date}
                    formId={viewModel.outstandingDesignInformationId!}
                    open={viewModel.outstandingDesignInformation}
                    onClose={() => viewModel.handleOutstandingDesignInformationClose()}
                />
            )}
            {viewModel.materialStorage && (
                <MaterialStorageView
                    formId={viewModel.materialStorageId!}
                    date={props.date}
                    open={viewModel.materialStorage}
                    onClose={() => viewModel.handleMaterialStorageClose()}
                />
            )}
            {viewModel.labourShortage && (
                <LabourShortageView formId={viewModel.labourShortageId!} date={props.date} open={viewModel.labourShortage} onClose={() => viewModel.handleLabourShortageClose()} />
            )}
        </>
    );
});
