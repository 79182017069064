import { action, computed, observable } from "mobx";
import { isEmptyOrWhitespace, ModelBase } from "@shoothill/core";

export class IEAdministratorRolesModel extends ModelBase<IEAdministratorRolesModel> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Constants and Defaults

    public static readonly DEFAULT_ID = null;
    public static readonly DEFAULT_ROLEID = null;
    public static readonly DEFAULT_ROLEDISPLAYNAME = "";
    public static readonly DEFAULT_USERID = null;
    public static readonly DEFAULT_USERDISPLAYNAME = "";
    public static readonly DEFAULT_ISDELETED = false;

    // #endregion Constants and Defaults

    // #region Properties

    @observable
    public id: string | null = IEAdministratorRolesModel.DEFAULT_ID;

    @observable
    public roleId: string | null = IEAdministratorRolesModel.DEFAULT_ROLEID;

    @observable
    public roleDisplayName: string = IEAdministratorRolesModel.DEFAULT_ROLEDISPLAYNAME;

    @observable
    public userId: string | null = IEAdministratorRolesModel.DEFAULT_USERID;

    @observable
    public userDisplayName: string = IEAdministratorRolesModel.DEFAULT_USERDISPLAYNAME;

    @observable
    public isDeleted: boolean = IEAdministratorRolesModel.DEFAULT_ISDELETED;

    // #endregion Properties

    // #region Actions

    @action
    public reset(): void {
        this.id = IEAdministratorRolesModel.DEFAULT_ID;
        this.roleId = IEAdministratorRolesModel.DEFAULT_ROLEID;
        this.userId = IEAdministratorRolesModel.DEFAULT_USERID;
        this.roleDisplayName = IEAdministratorRolesModel.DEFAULT_ROLEDISPLAYNAME;
        this.userDisplayName = IEAdministratorRolesModel.DEFAULT_USERDISPLAYNAME;
        this.isDeleted = IEAdministratorRolesModel.DEFAULT_ISDELETED;
    }

    fromDto(model: any): void {
        throw new Error("Method not implemented.");
    }
    toDto(model: IEAdministratorRolesModel): void {
        throw new Error("Method not implemented.");
    }

    // #endregion Actions

    // #region Custom Validation

    @computed
    public get validateRoleId(): string {
        // RULES
        // A category must be defined.
        return this.roleId === IEAdministratorRolesModel.DEFAULT_ROLEID ? "Please select a role" : "";
    }

    @computed
    public get validateUserId(): string {
        // RULES
        // An sub-category must be defined.
        return this.userId === IEAdministratorRolesModel.DEFAULT_USERID ? "Please select a user" : "";
    }

    // #endregion Custom Validation
}
