import { Button, TextField } from "@material-ui/core";
import { isEmptyOrWhitespace } from "@shoothill/core/dist/Utils";
import { observer } from "mobx-react-lite";
import React from "react";

import { DarwinSelect } from "Components/AutoComplete/DarwinSelect";
import { BaseModal } from "Components/Modal/BaseModal";
import { SnackBar } from "Components/SnackBar/SnackBar";
import { MaterialSubType } from "Globals/Models/Enums/MaterialSubType";
import { MaterialType } from "Globals/Models/Enums/MaterialType";
import { UnitOfMeasure } from "Globals/Models/Enums/UnitOfMeasure";
import { CancelButton } from "Globals/Styles/Control/Buttons";
import { ReportModalStyle } from "Views/Project/Modals/Modal.Styles";
import { MaterialDetailsViewModel } from "./MaterialDetailsViewModel";
import { FormModalSection, FormRow } from "Views/Stock/Shared/Shared.styles";

interface IProps {
    viewModel: MaterialDetailsViewModel;
}

export const MaterialDetailsView: React.FC<IProps> = observer((props) => {
    const viewModel = props.viewModel;

    return (
        <BaseModal
            open={viewModel !== null}
            onClose={viewModel.cancel}
            title="Edit material details"
            PaperComponent={ReportModalStyle}
            actions={
                <React.Fragment>
                    <Button autoFocus color="secondary" disabled={viewModel.server.IsBusy} onClick={viewModel.apiSaveDetailsAsync} variant="contained">
                        Save
                    </Button>
                    <CancelButton onClick={viewModel.cancel}>Cancel</CancelButton>
                </React.Fragment>
            }
        >
            <FormModalSection>
                <FormRow grid dc={"1fr 1fr"}>
                    <DarwinSelect
                        displayName="Type:"
                        execute={viewModel.setMaterialType}
                        fullWidth={true}
                        getOptionLabel={(option: MaterialType) => option?.displayName ?? ""}
                        options={viewModel.materialTypes}
                        placeholder="Please select"
                        validationMessage={viewModel.getError("materialType")}
                        value={viewModel.materialType}
                    />
                    <DarwinSelect
                        displayName="Sub-type:"
                        execute={viewModel.setMaterialSubType}
                        fullWidth={true}
                        getOptionLabel={(option: MaterialSubType) => option?.displayName ?? ""}
                        options={viewModel.materialSubTypes}
                        placeholder="Please select"
                        validationMessage={viewModel.getError("materialSubType")}
                        value={viewModel.materialSubType}
                    />
                </FormRow>
                <FormRow grid dc={"1fr 1fr"}>
                    <TextField
                        className="textarea-forminput"
                        InputLabelProps={{ shrink: true }}
                        label="Supplier code:"
                        onBlur={() => viewModel.isFieldValid("supplierCode")}
                        onChange={(event) => viewModel.setValue("supplierCode", event.target.value)}
                        helperText={viewModel.getError("supplierCode")}
                        error={!isEmptyOrWhitespace(viewModel.getError("supplierCode"))}
                        value={viewModel.getValue("supplierCode")}
                    />
                    <DarwinSelect
                        displayName="UoM:"
                        execute={viewModel.setUnitOfMeasure}
                        fullWidth={true}
                        getOptionLabel={(option: UnitOfMeasure) => option?.displayName ?? ""}
                        options={viewModel.unitsOfMeasure}
                        placeholder="Please select"
                        validationMessage={viewModel.getError("unitOfMeasure")}
                        value={viewModel.unitOfMeasure}
                    />
                </FormRow>
                <FormRow grid dc={"1fr"}>
                    <TextField
                        className="textarea-forminput"
                        InputLabelProps={{ shrink: true }}
                        label="Item description:"
                        onBlur={() => viewModel.isFieldValid("description")}
                        onChange={(event) => viewModel.setValue("description", event.target.value)}
                        helperText={viewModel.getError("description")}
                        error={!isEmptyOrWhitespace(viewModel.getError("description"))}
                        value={viewModel.getValue("description")}
                    />
                </FormRow>
            </FormModalSection>
            <SnackBar
                messageText={viewModel.snackMessage}
                messageType={viewModel.snackType}
                active={viewModel.snackbarState}
                closeOption={() => viewModel.setSnackbarState(false)}
                autoHideDuration={5000}
            />
        </BaseModal>
    );
});
