import { action, observable } from "mobx";
import { ModelBase } from "@shoothill/core";
import { VariationSubCategoryDTO, VariationSubCategoryModel } from "./VariationSubCategoryModel";

export class VariationCategoryModel extends ModelBase<VariationCategoryModel, any> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Constants and Defaults

    public static readonly DEFAULT_ID = null;
    public static readonly DEFAULT_VARIATIONID = null;
    public static readonly DEFAULT_CREATEDBYUSERID = null;
    public static readonly DEFAULT_CREATEDDATE = null;
    public static readonly DEFAULT_CATEGORYID = null;
    public static readonly DEFAULT_ISDELETED = false;
    public static readonly DEFAULT_DISPLAYNAME = null;
    public static readonly DEFAULT_VARIATIONCATEGORYID = null;
    public static readonly DEFAULT_VARIATIONSUBCATEGORIES = [];

    // #endregion Constants and Defaults

    // #region Properties

    @observable
    public id: string | null = VariationCategoryModel.DEFAULT_ID;

    @observable
    public variationId: string | null = VariationCategoryModel.DEFAULT_VARIATIONID;

    @observable
    public createdByUserId: string | null = VariationCategoryModel.DEFAULT_CREATEDBYUSERID;

    @observable
    public createdDate: string | null = VariationCategoryModel.DEFAULT_CREATEDDATE;

    @observable
    public categoryId: number | null = VariationCategoryModel.DEFAULT_CATEGORYID;

    @observable
    public isDeleted: boolean = VariationCategoryModel.DEFAULT_ISDELETED;

    @observable
    public displayName: string | null = VariationCategoryModel.DEFAULT_DISPLAYNAME;

    // JC: Had to update the array being observed to Type[] instead of IObservableArray<Type> as it was causing scope issues.
    // A newly created model contained existing data in the array when it definitely shouldn't.
    @observable
    public variationSubCategories: VariationSubCategoryModel[] = VariationCategoryModel.DEFAULT_VARIATIONSUBCATEGORIES;

    // #endregion Properties

    // #region Actions

    @action
    public reset = () => {
        this.id = VariationCategoryModel.DEFAULT_ID;
        this.createdByUserId = VariationCategoryModel.DEFAULT_CREATEDBYUSERID;
        this.createdDate = VariationCategoryModel.DEFAULT_CREATEDDATE;
        this.categoryId = VariationCategoryModel.DEFAULT_CATEGORYID;
        this.isDeleted = VariationCategoryModel.DEFAULT_ISDELETED;
        this.displayName = VariationCategoryModel.DEFAULT_DISPLAYNAME;
        this.variationSubCategories = VariationCategoryModel.DEFAULT_VARIATIONSUBCATEGORIES;
    };

    @action
    public fromDto(dto: VariationCategoryDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in dto) {
            if (dto.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(dto[key]);
                } else if (key === "variationSubCategories") {
                    // Manually process the child array otherwise we will end up with an array of dtos being stored in the array of models.
                    let processedSubCategories: VariationSubCategoryModel[] = [];

                    for (const subCategory of dto.variationSubCategories) {
                        const subCategoryToAdd = new VariationSubCategoryModel();
                        subCategoryToAdd.fromDto(subCategory);
                        processedSubCategories.push(subCategoryToAdd);
                    }

                    this.variationSubCategories = [...this.variationSubCategories, ...processedSubCategories];
                } else {
                    this[key] = dto[key];
                }
            }
        }
    }

    public toDto(): VariationCategoryDTO {
        let subCategoriesToAdd: VariationSubCategoryDTO[] = [];

        this.variationSubCategories.forEach((s) => {
            subCategoriesToAdd.push(s.toDto());
        });

        const model: VariationCategoryDTO = {
            id: this.id,
            createdByUserId: this.createdByUserId,
            variationId: this.variationId,
            createdDate: this.createdDate,
            categoryId: this.categoryId,
            isDeleted: this.isDeleted,
            displayName: this.displayName,
            variationSubCategories: subCategoriesToAdd,
        };

        return model;
    }

    // #endregion Actions

    // #region Custom Validation

    // #endregion Custom Validation
}

// export interface VariationSubCategoryUpsertRequestDTO {
//     id: string | null;
//     packageCategoryId: number | null;
//     displayName: string | null;
//     variationCategoryId: string | null;
// }

export interface VariationCategoryDTO {
    id: string | null;
    variationId: string | null;
    createdByUserId: string | null;
    createdDate: string | null;
    categoryId: number | null;
    isDeleted: boolean;
    displayName: string | null;
    variationSubCategories: VariationSubCategoryDTO[];
}
