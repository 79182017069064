import { FieldType, ViewModelBase } from "@shoothill/core";
import { RFIStatusModel } from "./RFIStatusModel";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";

export class RFIStatusViewModel extends ViewModelBase<RFIStatusModel> {
    public constructor(rFIId: string | null) {
        super(new RFIStatusModel(), false);
        this.setDecorators(RFIStatusModel);
        this.model.rFIId = rFIId!;
    }

    public server: ServerViewModel = new ServerViewModel();

    public async isFieldValid(fieldName: keyof FieldType<RFIStatusModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        errorMessage = "";
        isValid = true;

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
