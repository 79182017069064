import styled from "styled-components";
import { BaseModalDialogPaper } from "Components/Modal/BaseModal.styles";

export const ClientAddModalDialogPaper = styled(BaseModalDialogPaper)`
    width: 100%;
    max-width: 800px;
    margin: 10px;

    .MuiDialogActions-root {
        width: 378px;
        margin: auto;
        padding-bottom: 40px;
    }
`;
