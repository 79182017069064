// Libs
import { FieldType, isEmptyOrWhitespace, isNullOrUndefined, KeyValuePair, ViewModelBase } from "@shoothill/core";
import { action, computed, runInAction } from "mobx";
import * as MobX from "mobx";
import validator from "validator";

// App
import { NoteModelDTO, NoteModel } from "Globals/Models/Domain";
import { StoresInstance } from "Globals/Stores";

export default class NoteViewModel extends ViewModelBase<NoteModel> {
    constructor(note: NoteModel) {
        super(note);
        this.setDecorators(NoteModel);
    }

    public noteViewModel: NoteViewModel | null = null;

    @action public addDocuments(acceptedFiles: any) {
        this.model.documentsToUpload.push(...acceptedFiles);
    }

    @computed public get noteCreatedDate() {
        return isEmptyOrWhitespace(this.model.createdDate) || !this.model.createdDate ? new Date() : new Date(this.model.createdDate);
    }

    @action public cleanUp() {
        // TODO CMR if needs be.
        this.model.clear();
    }

    public async isFieldValid(fieldName: keyof FieldType<NoteModel>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        if ((fieldName as any) === "note") {
            if (validator.isEmpty(value)) {
                errorMessage = "A note is required";
                isValid = false;
            }
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
