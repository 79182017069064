import * as React from "react";
import { Button } from "@material-ui/core";

import { EditContactModalDialogPaper } from "./EditContactModal.styles";
import { BaseModal } from "Components/Modal/BaseModal";
import { CancelButton } from "Globals/Styles/Control/Buttons";
import { useState, useEffect } from "react";
import { useObserver } from "mobx-react-lite";
import { AddEditContactModel } from "./AddEditContactModel";
import { AddEditContactView } from "./AddEditContactView";
import { AddEditContactViewModel } from "./AddEditContactViewModel";
import { ContactBaseDTO } from "Globals/Models/Domain/ContactBaseDTO";
import { runInAction } from "mobx";

interface IEditContactProps {
    open?: boolean;
    onClose(): void;
    onSave(editedContact: AddEditContactModel): any;
    title: string;
    text: string;
    contact: ContactBaseDTO | undefined;
}

export const EditContactModal: React.FunctionComponent<IEditContactProps> = ({ open, onClose, onSave, title, text, contact }) => {
    const [viewModel] = useState(() => new AddEditContactViewModel());

    //useEffect below only gets run on initial render
    useEffect(() => {
        return () => {
            viewModel.cleanUp();
        };
    }, []);

    useEffect(() => {
        if (contact === undefined) {
            viewModel.cleanUp();
        } else {
            runInAction(() => {
                viewModel.model.fromContactBaseContactDto(contact);
            });
        }
    }, [contact]);

    const onSaveLocal = async () => {
        if (await viewModel.isModelValid()) {
            onSave(viewModel.model);
        }
    };

    return useObserver(() => (
        <BaseModal
            open={open}
            onClose={onClose}
            title={title}
            PaperComponent={EditContactModalDialogPaper}
            actions={
                <>
                    <Button onClick={onSaveLocal} color="secondary" autoFocus variant="contained">
                        Save
                    </Button>
                    <CancelButton onClick={onClose}>Cancel</CancelButton>
                </>
            }
        >
            <AddEditContactView /* reference={editContactRef} */ viewModel={viewModel} onUpsertContact={onSave} isLoading={viewModel.IsLoading} />

            {text.length > 0 && <p className="client-modal-description">{text}</p>}
        </BaseModal>
    ));
};
