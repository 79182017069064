import { FieldType, ViewModelBase, isNullOrUndefined } from "@shoothill/core";
import { action, computed, observable } from "mobx";

import { AppUrls } from "AppUrls";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { StockAdjustmentModel } from "./StockAdjustmentModel";
import { CalculatedAdjustedStockViewModel } from "../Shared/CalculatedAdjustedStock/CalculatedAdjustedStockViewModel";
import { ICalculatedAdjustedStockDto } from "../Shared/CalculatedAdjustedStockDtos";

export class StockAdjustmentViewModel extends ViewModelBase<StockAdjustmentModel> {
    public server: ServerViewModel = new ServerViewModel();

    private materialId: string;
    private parentCloseAction: (refreshPage: boolean) => void;

    constructor(materialId: string, closeAction: (refreshPage: boolean) => void) {
        super(new StockAdjustmentModel(), false);

        this.materialId = materialId;
        this.parentCloseAction = closeAction;

        this.setDecorators(StockAdjustmentModel);

        // Load adjustable stock.
        this.apiLoadAdjustableStockAsync();
    }

    // #region Formatted Labels

    @computed
    public get calculatedAdjustedStock() {
        if (!isNullOrUndefined(this.model.calculatedAdjustedStock)) {
            return new CalculatedAdjustedStockViewModel(this.model.calculatedAdjustedStock!);
        }

        return null;
    }

    @computed
    public get canDisplayCalculatedAdjustedStock() {
        return this.calculatedAdjustedStock !== null;
    }

    // #endregion Formatted Labels

    // #region Actions

    @action
    public cancel = () => this.parentCloseAction(false);

    // #endregion Actions

    // #region Api Actions

    @action
    public apiLoadAdjustableStockAsync = async (): Promise<void> => {
        await this.server.query<ICalculatedAdjustedStockDto>(
            () => this.Get(AppUrls.Server.Stock.GetUnitsAndCostsForAdjustment.replace("{materialid}", this.materialId).replace("{units}", this.model.adjustmentUnits.toString())),
            (result) => this.model.fromDto(result),
            "Error whilst loading the stock data",
        );

        if (this.server.HaveValidationMessage) {
            this.setSnackMessage(this.server.ValidationMessage);
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }
    };

    @action
    public apiSaveStockAdjustmentAsync = async (): Promise<void> => {
        await this.server.command<any>(
            () => this.Post(AppUrls.Server.Stock.RequestAdjustStock.replace("{materialid}", this.materialId), this.model.toDto()),
            (result) => this.parentCloseAction(true),
            this.isModelValid,
            "Error whilst saving the stock adjustment",
        );

        if (this.server.HaveValidationMessage) {
            this.setSnackMessage(this.server.ValidationMessage);
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }
    };

    // #endregion Api Actions

    // #region Snack Bar

    public SNACKERROR = "error";

    @observable
    public snackbarState = false;

    @observable
    public snackType = "";

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public snackMessage = "";

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    // #endregion Snack Bar

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<StockAdjustmentModel>): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boliderplate
}
