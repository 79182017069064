// Libraries
import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { KeyValuePair, useRouter } from "@shoothill/core";

// Custom
import { DetailsHeader } from "Globals/Views/DetailsPage/DetailsHeader";
import { ProjectGeneralViewModel } from "../General/ProjectGeneralViewModel";
import { DeliveryScheduleViewModel } from "./DeliveryScheduleViewModel";

// Styling & Images
import { DetailsPage } from "Globals/Views/DetailsPage/DetailsPage.styles";
import { ProjectButtonContainer, ProjectEditBox } from "../Project.styles";
import { ContractCell } from "../Contractual/Contractual.styles";
import { Button, Typography } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { isNullOrUndefined } from "Utils/Utils";
import moment from "moment";

export const DeliveryView: React.FunctionComponent = () => {
    const router = useRouter();
    const [generalViewModel] = useState(() => ProjectGeneralViewModel.Instance);
    const [viewModel] = useState(() => DeliveryScheduleViewModel.Instance);

    useEffect(() => {
        let { projectid } = router.match.params as any;
        let promise = generalViewModel.apiGetbyId(projectid);
        promise.then(() => {
            const model = generalViewModel.model.getProjectConstractual;

            if (model !== null && model !== undefined) {
                if (model.projectId === null || model.projectId === undefined || model.projectId === "00000000-0000-0000-0000-000000000000") {
                    model.projectId = projectid;
                }

                viewModel.loadModel(model);
            } else {
                viewModel.model.projectId = projectid;
            }
        });

        return () => {
            viewModel.clean();
        };
    }, []);

    const onWeekCommencingChanged = (date: MaterialUiPickersDate) => {
        let newDate: string | undefined = date?.toISOString();

        if (isNullOrUndefined(newDate) === true) {
            newDate = moment.utc().startOf("isoWeek").toISOString();
        } else {
            newDate = moment(newDate).startOf("isoWeek").toISOString();
        }

        viewModel.setValue("weekCommencing", newDate);
    };

    const onNextWeekClick = async () => {
        let newDate: string | undefined = viewModel.model.weekCommencing;

        if (isNullOrUndefined(newDate) === true) {
            newDate = moment.utc().startOf("isoWeek").toISOString();
        } else {
            newDate = moment(newDate).add(7, "day").startOf("isoWeek").toISOString();
        }

        viewModel.setValue("weekCommencing", newDate);
        window.scrollTo(0, 0);
    };

    const renderPage = () => {
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DetailsPage className="cell-right rcselect">
                    <DetailsHeader viewModel={generalViewModel.getHeader} />
                    <ProjectEditBox>
                        <div className="row">
                            <ContractCell>
                                <Typography variant="h3">Daily delivery schedule</Typography>
                            </ContractCell>
                            <ContractCell style={{ width: "0" }}></ContractCell>
                        </div>
                    </ProjectEditBox>
                    <ProjectEditBox>
                        <div className="column">
                            {viewModel.model.getAWeeksSchedule.map((value: KeyValuePair) => {
                                return (
                                    <div className="row" style={{ marginBottom: "5px" }}>
                                        <div className="description" style={{ backgroundColor: "#EDEDED", maxWidth: "100%", padding: "5px 15px" }}>
                                            {value.key} {value.value}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </ProjectEditBox>
                    <ProjectButtonContainer>
                        <Button onClick={onNextWeekClick} color="primary" autoFocus variant="contained">
                            load next week
                        </Button>
                    </ProjectButtonContainer>
                </DetailsPage>
            </MuiPickersUtilsProvider>
        );
    };

    return useObserver(() => renderPage());
};
