import { PackageCategoryBase } from "./PackageCategoryBase";
import { FieldType, KeyValuePair, observable, ViewModelBase } from "@shoothill/core";
import { ROFilterParamsModel } from "./ROFilterParamsModel";
import { action } from "mobx";
import { OrderType, ProjectDTO, RequisitionPOStatus, UserDTO } from "Views/Approval/PurchaseListViewModel";
import type { PackageCategoriesResult } from "./PackageCategoriesResult";
import { SupplierDTO } from "./Form/Supporting/SupplierModel";

export class RejectedOrderListFilterViewModel extends ViewModelBase<ROFilterParamsModel> {
    private static _intsance: RejectedOrderListFilterViewModel;
    public static get Instance() {
        return this._intsance || (this._intsance = new this());
    }

    constructor() {
        super(new ROFilterParamsModel(), false);
        this.setDecorators(ROFilterParamsModel);
    }

    @observable
    public readonly categoryOptions: KeyValuePair[] = [];

    @observable
    public readonly raisedByOptions: KeyValuePair[] = [];

    @observable
    public readonly supplierOptions: KeyValuePair[] = [];

    @observable
    public subCategoryOptions: KeyValuePair[] = [];

    @observable
    public readonly statusOptions: KeyValuePair[] = [];

    @observable
    public readonly orderTypeOptions: KeyValuePair[] = [];

    @observable
    public readonly projectOptions: KeyValuePair[] = [];

    public getStatusOptions = (includeUnKnown?: boolean): KeyValuePair[] => {
        return this.statusOptions === undefined ? [] : this.statusOptions.slice();
    };

    public getCategoryOptions = (includeDeleted: boolean): KeyValuePair[] => {
        return this.categoryOptions === undefined ? [] : this.categoryOptions.slice();
    };

    public getRaisedByOptions = (includeDeleted: boolean): KeyValuePair[] => {
        return this.raisedByOptions === undefined ? [] : this.raisedByOptions.slice();
    };

    public getSupplierOptions = (includeDeleted: boolean): KeyValuePair[] => {
        return this.supplierOptions === undefined ? [] : this.supplierOptions.slice();
    };

    public getSubCategoryOptions = (includeDeleted: boolean): KeyValuePair[] => {
        return this.subCategoryOptions === undefined ? [] : this.subCategoryOptions.slice();
    };

    public getOrderTypeOptions = (includeUnKnown?: boolean): KeyValuePair[] => {
        return this.orderTypeOptions === undefined ? [] : this.orderTypeOptions.slice();
    };

    public getProjectOptions = (includeUnKnown?: boolean): KeyValuePair[] => {
        return this.projectOptions === undefined ? [] : this.projectOptions.slice();
    };

    @action
    public setCategories(result: PackageCategoriesResult, resetFilters: boolean) {
        if (this.categoryOptions !== null && this.categoryOptions.length === 0) {
            if (result.categories.length > 0) {
                this.categoryOptions.push(
                    ...result.categories.map((item: PackageCategoryBase) => {
                        return { key: item.displayName, value: item.id };
                    }),
                );
            }
        }

        this.subCategoryOptions = [];
        if (result.subCategories.length > 0) {
            this.subCategoryOptions.push(
                ...result.subCategories.map((item: PackageCategoryBase) => {
                    return { key: item.displayName, value: item.id };
                }),
            );
        }

        if (resetFilters === true) {
        }
    }

    @action
    public setStatuses(statusOptions: RequisitionPOStatus[], resetFilters: boolean) {
        if (this.statusOptions !== null && this.statusOptions.length === 0) {
            if (statusOptions.length > 0) {
                this.statusOptions.push(
                    ...statusOptions.map((item: RequisitionPOStatus) => {
                        return { key: item.name, value: item.id };
                    }),
                );
            }
        }
    }

    @action
    public setPurchaseUsers(raisedByOptions: UserDTO[], resetFilters: boolean) {
        if (this.raisedByOptions !== null && this.raisedByOptions.length === 0) {
            if (raisedByOptions.length > 0) {
                this.raisedByOptions.push(
                    ...raisedByOptions.map((item: UserDTO) => {
                        return { key: item.displayName, value: item.id };
                    }),
                );
            }
        }
    }

    @action
    public setSuppliers(supplierOptions: SupplierDTO[], resetFilters: boolean) {
        if (this.supplierOptions !== null && this.supplierOptions.length === 0) {
            if (supplierOptions.length > 0) {
                this.supplierOptions.push(
                    ...supplierOptions.map((item: SupplierDTO) => {
                        return { key: item.name, value: item.id };
                    }),
                );
            }
        }
    }

    @action
    public setOrderTypes(orderTypeOptions: OrderType[], resetFilters: boolean) {
        if (this.orderTypeOptions !== null && this.orderTypeOptions.length === 0) {
            if (orderTypeOptions.length > 0) {
                this.orderTypeOptions.push(
                    ...orderTypeOptions.map((item: OrderType) => {
                        return { key: item.displayName, value: item.id };
                    }),
                );
            }
        }
    }

    @action
    public setProjects(projectOptions: ProjectDTO[], resetFilters: boolean) {
        if (this.projectOptions !== null && this.projectOptions.length === 0) {
            if (projectOptions.length > 0) {
                this.projectOptions.push(
                    ...projectOptions.map((item: ProjectDTO) => {
                        return { key: item.displayName, value: item.id };
                    }),
                );
            }
        }
    }

    @action
    public handleSorting(sortCol: string, sortDir: string) {
        this.model.sortBy = sortCol;
        this.model.sortDirection = sortDir;
    }

    @action
    public handlePageChange(page: number, pageSize: number) {
        this.model.pageNumber = page;
        this.model.pageSize = pageSize;
    }

    @action
    public handleRowsPerPageChange(pageSize: number) {
        this.model.pageSize = pageSize;
    }

    @action
    public setTotalCount = (val: number) => {
        this.model.totalCount = val;
    };

    @action
    public setPageNumber = (val: number) => {
        this.model.pageNumber = val;
    };

    public async isFieldValid(fieldName: keyof FieldType<ROFilterParamsModel>): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
