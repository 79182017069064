import { action, computed, observable } from "mobx";
import { isEmptyOrWhitespace, ModelBase } from "@shoothill/core";
import { validateTwoDecimalPlaces } from "Utils/Utils";

export class IERiskOppModel extends ModelBase<IERiskOppModel, IERiskOppUpsertResponseDTO> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Constants and Defaults

    public static readonly DEFAULT_ID = null;
    public static readonly DEFAULT_IEID = "";
    public static readonly DEFAULT_IEITEMID = "";
    public static readonly DEFAULT_RISKVALUE = "";
    public static readonly DEFAULT_RISKNOTE = "";
    public static readonly DEFAULT_ROWVERSION = null;
    public static readonly DEFAULT_CREATEDBYUSERNAME = null;

    // #endregion Constants and Defaults

    // #region Properties

    @observable
    public id: string | null = null;

    @observable
    public ieId: string = IERiskOppModel.DEFAULT_IEID;

    @observable
    public ieItemId: string = IERiskOppModel.DEFAULT_IEITEMID;

    @observable
    public riskValue: string = IERiskOppModel.DEFAULT_RISKVALUE;

    @observable
    public riskNote: string = IERiskOppModel.DEFAULT_RISKNOTE;

    @observable
    public rowVersion: string | null = IERiskOppModel.DEFAULT_ROWVERSION;

    @observable
    public createdByUserName: string | null = IERiskOppModel.DEFAULT_CREATEDBYUSERNAME;

    // #endregion Properties

    // #region Actions

    @action
    public fromDto(dto: IERiskOppUpsertResponseDTO): void {
        this.id = dto.id;
        this.ieId = dto.ieId;
        this.ieItemId = dto.ieItemId;
        this.riskValue = dto.riskValue;
        this.riskNote = dto.riskNote;
        this.rowVersion = dto.rowVersion;
        this.createdByUserName = dto.createdByUserName;
    }

    public toDto(): IERiskOppUpsertRequestDTO {
        return {
            id: this.id,
            ieId: this.ieId,
            ieItemId: this.ieItemId,
            riskValue: Number(this.riskValue),
            riskNote: this.riskNote,
            rowVersion: this.rowVersion,
        };
    }

    public reset(): void {
        this.id = null;
        this.riskValue = IERiskOppModel.DEFAULT_RISKVALUE;
        this.riskNote = IERiskOppModel.DEFAULT_RISKNOTE;
        this.rowVersion = IERiskOppModel.DEFAULT_ROWVERSION;
        this.createdByUserName = IERiskOppModel.DEFAULT_CREATEDBYUSERNAME;
    }

    // #endregion Actions

    // #region Custom Validation

    @computed
    public get validateRiskValue(): string {
        // RULES
        const riskValueNumber: number = Number(this.riskValue);
        if (this.riskValue === IERiskOppModel.DEFAULT_RISKVALUE || isNaN(riskValueNumber)) {
            return "Please enter a valid value";
        } else if (!validateTwoDecimalPlaces(riskValueNumber)) {
            return "No more than two decimal places";
        }

        return "";
    }

    @computed
    public get validateRiskNote(): string {
        return this.riskNote === IERiskOppModel.DEFAULT_RISKNOTE ? "Please enter a note" : "";
    }

    // #endregion Custom Validation
}

export interface IERiskOppUpsertRequestDTO {
    id: string | null;
    ieId: string;
    ieItemId: string;
    riskValue: number;
    riskNote: string;
    rowVersion: string | null;
}

export interface IERiskOppUpsertResponseDTO {
    id: string;
    ieId: string;
    ieItemId: string;
    riskValue: string;
    riskNote: string;
    rowVersion: string | null;
    createdByUserName: string | null;
}

export interface IERiskOppAndRelatedResponseDTO {
    categoryName: string;
    subCategoryName: string;
    lineDescriptionName: string;
    ieTitle: string;
    ieRiskOppItems: IERiskOppDTO[];
}

export interface IERiskOppDTO {
    id: string;
    riskNote: string;
    riskValue: number;
    createdDate: string;
    isCurrent: boolean;
    createdByUserName: string;
}

//export interface IncomeAndExpenditureAndRelatedResponseDTO extends IncomeAndExpenditureUpsertResponseDTO, IncomeAndExpenditureRelatedResponseDTO {}
