import { debounce } from "@material-ui/core";
import { isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import type { ValidationResponse } from "@shoothill/core";
import { action, computed, runInAction, observable } from "mobx";

import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { formatCurrencyFromPounds, formatCurrencyNoSign } from "Utils/Format";
import { RequisitionRequestItemGroupModel } from "./RequisitionRequestItemGroupModel";
import { RequisitionRequestItemModel } from "./RequisitionRequestItemModel";
import { RequisitionRequestItemViewModel } from "./RequisitionRequestItemViewModel";
import { OrderLineModel } from "../Form/OrderLineModel";
import { AppUrls } from "AppUrls";
import { BudgetForecast } from "Views/PurchaseOrder/Form/PurchaseOrderModel";
import type { BudgetForecastFormatted } from "Views/PurchaseOrder/Form/PurchaseOrderModel";

export class RequisitionRequestItemGroupViewModel extends ViewModelBase<RequisitionRequestItemGroupModel> {
    // #region Constructors and Disposers

    constructor(requisitionRequestItemGroupModel: RequisitionRequestItemGroupModel, projectId: string | null, incomeAndExpenditureId: string | null, isCentral: boolean) {
        super(requisitionRequestItemGroupModel);
        this.setDecorators(RequisitionRequestItemGroupModel);

        this.createViewModels();

        // Side-effect
        // This is a RAII approach to get any missing data. We fire and forget.
        if (!isEmptyOrWhitespace(projectId) && !isEmptyOrWhitespace(incomeAndExpenditureId) && isEmptyOrWhitespace(requisitionRequestItemGroupModel.id)) {
            this.setBudgetCosts(incomeAndExpenditureId!, requisitionRequestItemGroupModel.lineDescriptionId);
        }

        this.model.isCentral = isCentral;
    }

    // #region Constructors and Disposers

    // #region Item

    @action
    public addItem = (model: OrderLineModel) => {
        this.model.categoryId = model.categoryId!;
        this.model.categoryDisplayName = model.categoryDisplayName;
        this.model.subCategoryId = model.subCategoryId!;
        this.model.subCategoryDisplayName = model.subCategoryDisplayName;
        this.model.lineDescriptionId = model.lineDescriptionId!;
        this.model.lineDescriptionDisplayName = model.lineDescriptionDisplayName;

        const max: number = this.model.requisitionRequestItems.reduce((prev, current) => (prev > current.ordinal ? prev : current.ordinal), 0);
        let reqModel: RequisitionRequestItemModel = RequisitionRequestItemModel.fromOrderLineModel(this.model.id, model);

        reqModel.ordinal = max + 1;

        this.model.requisitionRequestItems.push(reqModel);
        let viewModel: RequisitionRequestItemViewModel = new RequisitionRequestItemViewModel(reqModel);
        this.requisitionGroupViewItemModels.push(viewModel);
        this.setSuggestedNewFutureSpend(this.model.futureSpend);
        this.model.isDeleted = false;
    };

    // #endregion Item

    // #region Properties

    public server = new ServerViewModel();

    public get key(): string {
        return this.composeKey(this.model.categoryId, this.model.subCategoryId, this.model.lineDescriptionId);
    }

    @computed
    public get displayName(): string {
        return `${this.model.categoryDisplayName} > ${this.model.subCategoryDisplayName} > ${this.model.lineDescriptionDisplayName}`;
    }

    @computed
    public get total(): string {
        return formatCurrencyFromPounds(this.model.total);
    }

    @computed
    public get haveItems(): boolean {
        return this.requisitionRequestItems.length > 0;
    }

    @action
    private createViewModels() {
        for (const item of this.model.requisitionRequestItems) {
            this.requisitionGroupViewItemModels.push(new RequisitionRequestItemViewModel(item));
        }
    }

    @observable
    private requisitionGroupViewItemModels: RequisitionRequestItemViewModel[] = [];

    @computed
    public get requisitionRequestItems() {
        let items: RequisitionRequestItemViewModel[] = [];

        items = this.requisitionGroupViewItemModels.filter((i) => !i.model.isDeleted);

        return items.sort((a, b) => a.model.ordinal - b.model.ordinal);
    }

    // #endregion Properties

    // #region Pre-Costs

    @computed
    public get preTargetCost(): string {
        return formatCurrencyNoSign(parseFloat(this.model.targetCost.toFixed(2)));
    }

    @computed
    public get preCommittedCost(): string {
        return formatCurrencyNoSign(parseFloat(this.model.committedCost.toFixed(2)));
    }

    @computed
    public get preFutureSpend(): string {
        return formatCurrencyNoSign(parseFloat(this.model.futureSpend.toFixed(2)));
    }

    @computed
    public get preTotalExpectedSpend(): string {
        return formatCurrencyNoSign(parseFloat(this.model.totalExpectedSpend.toFixed(2)));
    }

    @computed
    public get preVariance(): string {
        return formatCurrencyNoSign(parseFloat(this.model.variance.toFixed(2)));
    }

    // #endregion Pre-Costs

    // #region Post-Costs

    @computed
    public get postTargetCost(): string {
        return formatCurrencyNoSign(parseFloat(this.model.targetCost.toFixed(2)));
    }

    @computed
    public get postCommittedCost(): string {
        return formatCurrencyNoSign(this.model.postCommittedCost);
    }

    @computed
    public get postFutureSpend(): string {
        return formatCurrencyNoSign(this.model.postFutureSpend);
    }

    @computed
    public get postTotalExpectedSpend(): string {
        return formatCurrencyNoSign(this.model.postTotalExpectedSpend);
    }

    @computed
    public get postVariance(): string {
        return formatCurrencyNoSign(this.model.postVariance);
    }

    public toBudgetForecastModel = (): BudgetForecast => {
        const budgetForecast: BudgetForecast = {
            preCommittedCost: this.model.committedCost,
            preFutureSpend: this.model.futureSpend,
            preTargetCost: this.model.targetCost,
            preTotalExpectedSpend: this.model.totalExpectedSpend,
            preVariance: this.model.variance,
            postCommittedCost: this.model.postCommittedCost,
            postFutureSpend: this.model.postFutureSpend,
            postTargetCost: this.model.targetCost,
            postTotalExpectedSpend: this.model.postTotalExpectedSpend,
            postVariance: this.model.postVariance,
        };

        return budgetForecast;
    };

    public toBudgetForecastFormattedModel = (): BudgetForecastFormatted => {
        const budgetForecast: BudgetForecastFormatted = {
            preCommittedCost: this.preCommittedCost,
            preFutureSpend: this.preFutureSpend,
            preTargetCost: this.preTargetCost,
            preTotalExpectedSpend: this.preTotalExpectedSpend,
            preVariance: this.preVariance,
            postCommittedCost: this.postCommittedCost,
            postFutureSpend: this.postFutureSpend,
            postTargetCost: this.postTargetCost,
            postTotalExpectedSpend: this.postTotalExpectedSpend,
            postVariance: this.postVariance,
        };

        return budgetForecast;
    };

    @computed
    public get getBudgetForecastTotalFormatted(): BudgetForecastFormatted {
        return this.toBudgetForecastFormattedModel();
    }

    // #endregion Post-Costs

    // #region Budget Costs

    private readonly BDGCOST_DEBOUNCE_VALUE_MS = 200;

    public setBudgetCosts = debounce(
        action((incomeAndExpenditureId: string, lineDescriptionId: string) => {
            this.loadBudgetCosts(incomeAndExpenditureId, lineDescriptionId);
        }),
        this.BDGCOST_DEBOUNCE_VALUE_MS,
    );

    // #endregion Budget Costs

    // #region Helpers

    public isMatchingGroup = (model: OrderLineModel): boolean => {
        const key = this.composeKey(model.categoryId!, model.subCategoryId!, model.lineDescriptionId!);

        return key === this.key;
    };

    private composeKey = (categoryId: string, subCategoryId: string, lineDescriptionId: string): string => {
        return `${categoryId} - ${subCategoryId} - ${lineDescriptionId}`;
    };

    // #endregion Helpers

    // #region Server Actions

    public loadBudgetCosts = (incomeAndExpenditureId: string, lineDescriptionId: string) => {
        const url = `${AppUrls.Server.PurchaseOrder.GetBudgetCosts}\\${incomeAndExpenditureId}\\${lineDescriptionId}`;
        return this.server.query<any>(
            () => this.Get(url),
            (result: any) => {
                runInAction(() => {
                    const newModels = RequisitionRequestItemModel.fromDtos(result.requisitionRequestItems);

                    for (const model of newModels) {
                        this.model.requisitionRequestItems.push(model);
                        let viewModel: RequisitionRequestItemViewModel = new RequisitionRequestItemViewModel(model);
                        this.requisitionGroupViewItemModels.push(viewModel);
                    }

                    this.model.targetCost = result.targetCost;
                    this.model.committedCost = result.committedCost;
                    this.model.futureSpend = result.futureSpend;
                    this.model.totalExpectedSpend = result.totalExpectedSpend;
                    this.model.variance = result.variance;

                    this.setSuggestedNewFutureSpend(result.newFutureSpend);
                });
            },
        );
    };

    /**
     * Update the suggested new future spend value if a line item is edited.
     */
    @action
    public handleLineChange = () => {
        this.setSuggestedNewFutureSpend(this.model.futureSpend);
    };

    /**
     * When a line item is deleted, check if the group needs to be deleted.
     */
    @action
    public handleLineDelete = () => {
        if (this.requisitionRequestItems.length === 0) {
            this.model.isDeleted = true;
        }
    };

    /**
     * When a line item is deleted, check if the group needs to be deleted.
     */
    @action
    public handleDeleteGroup = () => {
        this.model.isDeleted = true;
        this.requisitionRequestItems.forEach((item) => {
            item.setDeleted();
        });

        // Set the note if it's empty so you can pass validation checks.
        if (this.model.note === "" || this.model.note === null || this.model.note === undefined) {
            this.model.note = "Note";
        }
    };

    /**
     * Suggested new future spend value is (PreFutureSpend - SubTotal)
     * @param futureSpend The pre future spend value
     */
    @action
    private setSuggestedNewFutureSpend = (futureSpend: number) => {
        const suggestedNewFutureSpend: number = futureSpend - this.model.totalChange;
        this.model.newFutureSpend = suggestedNewFutureSpend < 0 ? 0 : parseFloat(suggestedNewFutureSpend.toFixed(2));
    };

    // #endregion Server Actions

    /**
     * Update the local isInRevisionMode value for the group. Comes from the parent revise button.
     */
    @action
    public handleSetIsInRevisionMode = (val: boolean) => {
        this.model.isInRevisionMode = val;
        //this.model.futureSpend = this.model.postFutureSpend;
        this.setSuggestedNewFutureSpend(this.model.futureSpend);
    };

    @computed
    private get validateNote(): ValidationResponse {
        const errorMessage = this.model.validateNote;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    /**
     * Custom model validation function. Validates child order line models and its children
     * @returns True if model is valid, false if not.
     */
    public isMyModelValid = async (): Promise<boolean> => {
        let isValid = true;

        // JC: Changed forEach into for loop as the await seems to have issues with forEach.
        for (let i = 0; i < this.requisitionRequestItems.length; i++) {
            let item = this.requisitionRequestItems[i];

            // Validate each child item.
            if ((await item.isModelValid()) === false) {
                isValid = false;
            }
        }

        if ((await this.isModelValid()) === false) {
            isValid = false;
        }

        return isValid;
    };

    // #region Boilerplate

    public async isFieldValid(fieldName: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        switch (fieldName) {
            case "note": {
                const result = this.validateNote;

                errorMessage = result.errorMessage;
                isValid = result.isValid;
                break;
            }
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
