import styled from "styled-components";

import { pxToRem } from "Globals/Styles/AppTheme";
import { SHBox } from "Components/Box";

export const DetailPanel = styled.div`
    margin-left: ${pxToRem(48)};
    padding: ${pxToRem(6)} ${pxToRem(12)};

    > div:first-child {
        font-size: ${pxToRem(12)};
        font-weight: 600;
        margin-bottom: ${pxToRem(2)};
    }
    > div:last-child {
        font-size: ${pxToRem(10)};
        margin-bottom: ${pxToRem(4)};
    }
`;

export const EntryLink = styled(SHBox)`
    color: #0095da;
    cursor: pointer;
    font-size: ${pxToRem(10)};
`;

export const DummyEntryLink = styled(SHBox)`
    color: #0095da;
    font-size: ${pxToRem(10)};
`;
