import { ModelBase } from "@shoothill/core";
import { action, observable, computed } from "mobx";

export class QualityFormModel extends ModelBase<QualityFormModel> {
    // #region Constants

    public static readonly DEFAULT_QUALITY_ID = null;
    public static readonly DEFAULT_PROJECT_ID = "";
    public static readonly DEFAULT_NON_CONFORMANCE_TYPE_ID = "";

    // Concerning quality
    public static readonly DEFAULT_SUPPLIER_ID = null;
    public static readonly DEFAULT_ISSUEDTO_USER_ID = null;
    public static readonly DEFAULT_LOCATION = "";
    public static readonly DEFAULT_DESCRIPTION = "";
    public static readonly DEFAULT_QUALITY_PHOTO_FILES = [];

    // Concerning rectification
    public static readonly DEFAULT_RECTIFICATION_REQUIRED = false;
    public static readonly DEFAULT_RECTIFICATION_DATE = null;

    // Concerning open/closed state
    public static readonly DEFAULT_COMPLETION_ISOPEN = true;
    public static readonly DEFAULT_COMPLETION_DESCRIPTION = "";
    public static readonly DEFAULT_COMPLETION_PHOTOS = [];

    // Concerning audit
    public static readonly DEFAULT_CREATED_DATE = null;

    // #endregion Constants

    // #region Related Properties

    @observable
    public suppliers = observable<SupplierDTO>([]);

    @observable
    public inductionUsers = observable<InductionUserDTO>([]);

    @observable
    public workerUsers = observable<InductionUserDTO>([]);

    @computed
    public get users() {
        const groupInuductionUsers = this.inductionUsers.map((u) => {
            return {
                groupType: "INDUCTION_USER",
                groupDisplayName: "Inducted",
                ...u,
            };
        });

        const groupWorkerUsers = this.workerUsers.map((u) => {
            return {
                groupType: "WORKER_USER",
                groupDisplayName: "Darwin staff",
                ...u,
            };
        });

        return [...groupInuductionUsers, ...groupWorkerUsers];
    }

    // #endregion Related Properties

    // #region Form Properties

    @observable
    public id: string | null = QualityFormModel.DEFAULT_QUALITY_ID;

    @observable
    public projectId: string = QualityFormModel.DEFAULT_PROJECT_ID;

    @observable
    public nonConformanceTypeId: string = QualityFormModel.DEFAULT_NON_CONFORMANCE_TYPE_ID;

    // Concerning quality
    @observable
    public supplierId: string | null = QualityFormModel.DEFAULT_SUPPLIER_ID;

    @observable
    public userId: string | null = QualityFormModel.DEFAULT_ISSUEDTO_USER_ID;

    public get inductionUserId() {
        const result = this.users.find((u) => u.id === this.userId);

        return result?.groupType === "INDUCTION_USER" ? result!.id : QualityFormModel.DEFAULT_ISSUEDTO_USER_ID;
    }

    public get workerUserId() {
        const result = this.users.find((u) => u.id === this.userId);

        return result?.groupType === "WORKER_USER" ? result!.id : QualityFormModel.DEFAULT_ISSUEDTO_USER_ID;
    }

    @observable
    public location: string = QualityFormModel.DEFAULT_LOCATION;

    @observable
    public description: string = QualityFormModel.DEFAULT_DESCRIPTION;

    @observable
    public qualityPhotos: QualityPhotoFile[] = QualityFormModel.DEFAULT_QUALITY_PHOTO_FILES;

    // Concerning rectification
    @observable
    public rectificationRequired: boolean = QualityFormModel.DEFAULT_RECTIFICATION_REQUIRED;

    @observable
    public rectificationDate: string | null = QualityFormModel.DEFAULT_RECTIFICATION_DATE;

    // Concerning open/closed state
    @observable
    public isOpen: boolean = QualityFormModel.DEFAULT_COMPLETION_ISOPEN;

    @observable
    public rectificationDescription: string = QualityFormModel.DEFAULT_COMPLETION_DESCRIPTION;

    // Concerning audit
    @observable
    public createdDate: string | null = QualityFormModel.DEFAULT_CREATED_DATE;

    // #endregion Form Properties

    // APM Review
    // Not sure what is going on here. Wouldn't we only need one array not 2?
    @observable
    public completeNonConformanceWorksFile: QualityPhotoFile[] = [];
    @observable
    public qualityRectificationPhotos: QualityPhotoFile[] = QualityFormModel.DEFAULT_QUALITY_PHOTO_FILES;

    // #region Validation

    @computed
    public get validateUserId(): string {
        return this.userId === QualityFormModel.DEFAULT_ISSUEDTO_USER_ID ? "Please Select User" : "";
    }

    @computed
    public get validateLocation(): string {
        return this.location === QualityFormModel.DEFAULT_LOCATION ? "Please enter exact location of non-conformance" : "";
    }

    @computed
    public get validateDescription(): string {
        return this.description === QualityFormModel.DEFAULT_DESCRIPTION ? "Please enter a description" : "";
    }

    // #endregion Validation

    @action
    public fromRelatedDto(dto: QualityRelatedResponseDTO): void {}

    @action
    public reset = () => {
        this.id = QualityFormModel.DEFAULT_QUALITY_ID;
        this.projectId = QualityFormModel.DEFAULT_PROJECT_ID;
        this.nonConformanceTypeId = QualityFormModel.DEFAULT_NON_CONFORMANCE_TYPE_ID;

        // Concerning quality
        this.supplierId = QualityFormModel.DEFAULT_SUPPLIER_ID;
        this.userId = QualityFormModel.DEFAULT_ISSUEDTO_USER_ID;
        this.location = QualityFormModel.DEFAULT_LOCATION;
        this.description = QualityFormModel.DEFAULT_DESCRIPTION;
        this.qualityPhotos = QualityFormModel.DEFAULT_QUALITY_PHOTO_FILES;

        // Concerning rectification
        this.rectificationRequired = QualityFormModel.DEFAULT_RECTIFICATION_REQUIRED;
        this.rectificationDate = QualityFormModel.DEFAULT_RECTIFICATION_DATE;

        // Concerning open/closed state
        this.isOpen = QualityFormModel.DEFAULT_COMPLETION_ISOPEN;
        this.rectificationDescription = QualityFormModel.DEFAULT_COMPLETION_DESCRIPTION;

        // Concerning audit
        this.createdDate = QualityFormModel.DEFAULT_CREATED_DATE;

        // APM Review - Not sure about this.
        this.qualityRectificationPhotos = QualityFormModel.DEFAULT_COMPLETION_PHOTOS;
    };

    public fromDto(dto: any): void {
        //throw new Error("Method not implemented.");
    }

    public toDto(): qualityDTO {
        return {
            id: this.id,
            projectId: this.projectId,
            nonConformanceTypeId: this.nonConformanceTypeId,

            supplierId: this.supplierId,
            issuedToInductionUserId: this.inductionUserId,
            workerUserId: this.workerUserId,
            location: this.location,
            description: this.description,

            rectificationRequired: this.rectificationRequired,
            rectificationDate: this.rectificationDate,

            isOpen: this.isOpen,
            rectificationDescription: this.rectificationDescription,

            isDeleted: false,
            rowVersion: null,
        };
    }
}

export interface QualityRelatedResponseDTO {
    suppliers: SupplierDTO[];
    issuedToInductionUsers: InductionUserDTO[];
    workerUsers: InductionUserDTO[];
    qualityProjectDetails: QualityProjectDetailsDTO;
}

export interface QualityRelatedByIdResponseDTO {
    suppliers: SupplierDTO[];
    issuedToInductionUsers: InductionUserDTO[];
    workerUsers: InductionUserDTO[];
    qualityProjectDetails: QualityProjectDetailsDTO;
    qualityPhotoFile: QualityPhotoFile;
}

export interface QualityProjectDetailsDTO {
    projectReference: string;
    projectName: string;
}

export interface SupplierDTO {
    id: string;
    displayName: string;
}

export interface InductionUserDTO {
    id: string;
    displayName: string;
}

export interface IDisplayFile {
    id: string;
    displayName: string;
}

export interface QualityAndRelatedResponseDTO {
    quality: qualityDTO;
    qualityPhotos: QualityPhotosDTO[];
}

export interface QualityPhotoFile {
    qualityId: string | null;
    fileName: string;
    photoUrl: string;
    createdByUserId: string;
    isDeleted: boolean;
}

export interface qualityDTO {
    id: string | null;
    projectId: string;
    nonConformanceTypeId: string;

    supplierId: string | null;
    issuedToInductionUserId: string | null;
    workerUserId: string | null;
    location: string;
    description: string;

    rectificationRequired: boolean;
    rectificationDate: string | null;

    isOpen: boolean;
    rectificationDescription: string;

    isDeleted: boolean;
    rowVersion: string | null;
}

export interface QualityPhotosDTO {
    qualityId: string | null;
    fileName: string;
    photoUrl: string;
    createdByUserId: number | null;
    isDeleted: boolean;
}

export interface UpsertQualityAndRelatedRequestDTO {
    quality: qualityDTO;
    qualityPhotos: QualityPhotosDTO[];
    qualityRectificationPhotos: QualityPhotosDTO[];
}
