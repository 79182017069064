import { observable } from "mobx";

import { ModelBase } from "@shoothill/core";
import { action } from "mobx";

import { IStockTransactionRequestStatusType, StockTransactionRequestStatusType } from "Globals/Models/Enums/StockTransactionRequestStatusType";
import type { ITransferStockTransactionRequestViewDTO } from "../Shared/StockTransactionRequestDtos";

export class StockTransferApprovalModel extends ModelBase<StockTransferApprovalModel> {
    // #region Defaults
    // #endregion Defaults

    // #region Related Properties

    @observable
    public transactionRequestId: string | null = null;

    @observable
    public createdDate: string = "";

    @observable
    public createdByUser: string = "";

    @observable
    public lastUpdatedDate: string | null = null;

    @observable
    public lastUpdatedByUser: string | null = null;

    @observable
    public description: string = "";

    @observable
    public stockTransactionTypeDisplayName: string = "";

    @observable
    public projectDisplayName: string = "";

    @observable
    public ieDisplayName: string = "";

    @observable
    public categoryDisplayName: string = "";

    @observable
    public subCategoryDisplayName: string = "";

    @observable
    public lineDescriptionDisplayName: string = "";

    @observable
    public units: number = 0;

    @observable
    public note: string = "";

    @observable
    public currentTransactionRequestStatusType: StockTransactionRequestStatusType | null = null;

    @observable
    public availableTransactionRequestStatusTypes = observable<StockTransactionRequestStatusType>([]);

    @observable
    public costOfTransfer: number | null = null;

    @observable
    public costOfTransferError: string | null = null;

    @observable
    public suggestedFutureSpend: number = 0;

    @observable
    public suggestedFutureSpendReason: string = "";

    // #endregion Related Properties

    // #region Properties

    @observable
    public transactionRequestStatusType: StockTransactionRequestStatusType | null = null;

    // #endregion Properties

    // #region Methods

    @action
    public fromDto(dto: ITransferStockTransactionRequestViewDTO): void {
        this.transactionRequestId = dto.id;

        this.createdDate = dto.createdDate;
        this.createdByUser = dto.createdByUser;
        this.lastUpdatedDate = dto.lastUpdatedDate;
        this.lastUpdatedByUser = dto.lastUpdatedByUser;

        this.description = dto.description;

        this.stockTransactionTypeDisplayName = dto.stockTransactionTypeDisplayName;
        this.projectDisplayName = dto.projectDisplayName;
        this.ieDisplayName = dto.ieDisplayName;
        this.categoryDisplayName = dto.categoryDisplayName;
        this.subCategoryDisplayName = dto.subCategoryDisplayName;
        this.lineDescriptionDisplayName = dto.lineDescriptionDisplayName;
        this.units = dto.units;
        this.note = dto.note;
        this.suggestedFutureSpend = dto.suggestedFutureSpend;
        this.suggestedFutureSpendReason = dto.suggestedFutureSpendReason;

        // Set the current cost.
        this.costOfTransfer = dto.costOfTransfer;
        this.costOfTransferError = dto.costOfTransferError;

        // Current status of the transaction request.
        this.currentTransactionRequestStatusType = new StockTransactionRequestStatusType();
        this.currentTransactionRequestStatusType.fromDto(dto.currentTransactionRequestStatusType);

        // Available statuses for the transaction request.
        this.availableTransactionRequestStatusTypes.replace(
            dto.availableTransactionRequestStatusTypes.map((statusType: IStockTransactionRequestStatusType) => {
                const enumType = new StockTransactionRequestStatusType();

                enumType.fromDto(statusType);

                return enumType;
            }),
        );
    }

    public toDto(): any {
        return {
            stockTransactionRequestStatusTypeId: this.transactionRequestStatusType?.id,
        };
    }

    // #endregion Methods
}
