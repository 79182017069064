// Libraries
import React from "react";
import { useObserver } from "mobx-react-lite";
import styled from "styled-components";

// Custom

// Styling & Images
import { theme } from "Globals/Styles/AppTheme";
import { formatCurrencyForGrid, formatCurrencyNoSign } from "Utils/Format";
import { RowDash } from "Views/Project/CustomComponents";
import { ProjectReportIEDTO, ProjectReportProjectDTO } from "./ProjectReportModelBase";
import { CVRSummaryGridView } from "../CVR/ProjectCVRReport.styles";
import { makeStyles } from "@material-ui/core";

interface ITableProps {
    reportViewModel: ProjectReportProjectDTO;
}

export const ProjectReportGrid: React.FunctionComponent<ITableProps> = (props) => {
    const { reportViewModel } = props;

    const DEFAULTPADDINGLEFT = 20;

    const classes = useStyles();

    const getNewPaddingLeftValue = (val: number): number => {
        return val <= 160 ? val + DEFAULTPADDINGLEFT : val;
    };

    interface ISubsProps {
        parentId: string;
        paddingLeft: number;
    }

    /**
     * Recursive component that renders the sub I&Es of sub I&Es.
     * This section could do with improving so that it's more performant.
     * This was implemented as a quick fix.
     * @param subProps parentId and paddingLeft values.
     * @returns A list of sub I&Es for an I&E.
     */
    const RenderSubs: React.FunctionComponent<ISubsProps> = (subProps) => {
        const { parentId, paddingLeft } = subProps;

        return (
            <>
                {reportViewModel.iEs
                    .filter((subs) => subs.parentId === parentId)
                    .filter((subs) => subs.type === "SUB")
                    .map((i: ProjectReportIEDTO, index: number) => (
                        <>
                            <li className="ie" key={"ie_" + index}>
                                <div title={i.name}>
                                    <div className="ie-row-name">
                                        <RowDash style={{ display: "inline-block", marginRight: "10px", paddingLeft: `${paddingLeft}px` }} />
                                        {i.name} (Sub)
                                    </div>
                                </div>
                                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column1.previous))}</div>
                                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column1.current))}</div>
                                <div className={`right ${i.column1.creditOrDebitClassName}`}>{formatCurrencyForGrid(formatCurrencyNoSign(i.column1.movement))}</div>
                                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column2.previous))}</div>
                                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column2.current))}</div>
                                <div className={`right ${i.column2.creditOrDebitClassName}`}>{formatCurrencyForGrid(formatCurrencyNoSign(i.column2.movement))}</div>
                                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column3.previous))}</div>
                                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column3.current))}</div>
                                <div className={`right ${i.column3.creditOrDebitClassName}`}>{formatCurrencyForGrid(formatCurrencyNoSign(i.column3.movement))}</div>
                                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column4.previous))}</div>
                                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column4.current))}</div>
                                <div className={`right ${i.column4.creditOrDebitClassName}`}>{formatCurrencyForGrid(formatCurrencyNoSign(i.column4.movement))}</div>
                            </li>
                            <RenderSubs parentId={i.id} paddingLeft={getNewPaddingLeftValue(paddingLeft)} />
                        </>
                    ))}
            </>
        );
    };

    const RenderGrid = () => {
        return (
            <CVRSummaryGridView className={classes.IELineGroup}>
                {reportViewModel.iEs
                    .filter((masters) => masters.parentId === null || masters.parentId.length === 0)
                    .filter((masters) => masters.type === "MASTER")
                    .map((i: ProjectReportIEDTO, index: number) => (
                        <>
                            <li className="ie" key={"ie_" + index}>
                                <div style={{ display: "flex" }} className="ie-row-name" title={i.name}>
                                    {i.name} (Master)
                                </div>
                                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column1.previous))}</div>
                                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column1.current))}</div>
                                <div className={`right ${i.column1.creditOrDebitClassName}`}>{formatCurrencyForGrid(formatCurrencyNoSign(i.column1.movement))}</div>
                                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column2.previous))}</div>
                                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column2.current))}</div>
                                <div className={`right ${i.column2.creditOrDebitClassName}`}>{formatCurrencyForGrid(formatCurrencyNoSign(i.column2.movement))}</div>
                                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column3.previous))}</div>
                                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column3.current))}</div>
                                <div className={`right ${i.column3.creditOrDebitClassName}`}>{formatCurrencyForGrid(formatCurrencyNoSign(i.column3.movement))}</div>
                                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column4.previous))}</div>
                                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column4.current))}</div>
                                <div className={`right ${i.column4.creditOrDebitClassName}`}>{formatCurrencyForGrid(formatCurrencyNoSign(i.column4.movement))}</div>
                            </li>
                            <RenderSubs parentId={i.id} paddingLeft={DEFAULTPADDINGLEFT} />
                        </>
                    ))}
                {reportViewModel.iEs.filter((masters) => masters.parentId === null || masters.parentId.length === 0).filter((masters) => masters.type === "MASTER").length > 0 && (
                    <>
                        <li className="footing">
                            <div className="right">Project total</div>
                            <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(reportViewModel.column1.previous))}</div>
                            <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(reportViewModel.column1.current))}</div>
                            <div className={`right ${reportViewModel.column1.creditOrDebitClassName}`}>
                                {formatCurrencyForGrid(formatCurrencyNoSign(reportViewModel.column1.movement))}
                            </div>
                            <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(reportViewModel.column2.previous))}</div>
                            <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(reportViewModel.column2.current))}</div>
                            <div className={`right ${reportViewModel.column2.creditOrDebitClassName}`}>
                                {formatCurrencyForGrid(formatCurrencyNoSign(reportViewModel.column2.movement))}
                            </div>
                            <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(reportViewModel.column3.previous))}</div>
                            <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(reportViewModel.column3.current))}</div>
                            <div className={`right ${reportViewModel.column3.creditOrDebitClassName}`}>
                                {formatCurrencyForGrid(formatCurrencyNoSign(reportViewModel.column3.movement))}
                            </div>
                            <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(reportViewModel.column4.previous))}</div>
                            <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(reportViewModel.column4.current))}</div>
                            <div className={`right ${reportViewModel.column4.creditOrDebitClassName}`}>
                                {formatCurrencyForGrid(formatCurrencyNoSign(reportViewModel.column4.movement))}
                            </div>
                        </li>
                    </>
                )}
            </CVRSummaryGridView>
        );
    };

    return useObserver(() => <>{props.reportViewModel && RenderGrid()}</>);
};

const useStyles = makeStyles({
    IELineGroup: {
        borderBottom: `4px solid ${theme.palette.detail.main}`,
    },
});

export const IESubItem = styled.div`
    > li {
        > div:nth-child(1) {
            margin-left: 50px;
        }
    }
`;
