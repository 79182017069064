import { observable } from "mobx";

import { ModelBase, isNullOrUndefined } from "@shoothill/core";
import { action } from "mobx";

import { IStockTransactionRequestStatusType, StockTransactionRequestStatusType } from "Globals/Models/Enums/StockTransactionRequestStatusType";
import { CalculatedAdjustedStockModel } from "../Shared/CalculatedAdjustedStock/CalculatedAdjustedStockModel";
import type { IAdjustStockTransactionRequestViewDTO } from "../Shared/StockTransactionRequestDtos";

export class StockAdjustmentApprovalModel extends ModelBase<StockAdjustmentApprovalModel> {
    // #region Defaults
    // #endregion Defaults

    // #region Related Properties

    @observable
    public transactionRequestId: string | null = null;

    @observable
    public createdDate: string = "";

    @observable
    public createdByUser: string = "";

    @observable
    public lastUpdatedDate: string | null = null;

    @observable
    public lastUpdatedByUser: string | null = null;

    @observable
    public description: string = "";

    @observable
    public units: number = 0;

    @observable
    public note: string = "";

    @observable
    public currentTransactionRequestStatusType: StockTransactionRequestStatusType | null = null;

    @observable
    public availableTransactionRequestStatusTypes = observable<StockTransactionRequestStatusType>([]);

    @observable
    public calculatedAdjustedStock: CalculatedAdjustedStockModel | null = null;

    // #endregion Related Properties

    // #region Properties

    @observable
    public transactionRequestStatusType: StockTransactionRequestStatusType | null = null;

    // #endregion Properties

    // #region Methods

    @action
    public fromDto(dto: IAdjustStockTransactionRequestViewDTO): void {
        this.transactionRequestId = dto.id;

        this.createdDate = dto.createdDate;
        this.createdByUser = dto.createdByUser;
        this.lastUpdatedDate = dto.lastUpdatedDate;
        this.lastUpdatedByUser = dto.lastUpdatedByUser;

        this.description = dto.description;

        this.units = dto.units;
        this.note = dto.note;

        // Current status of the transaction request.
        this.currentTransactionRequestStatusType = new StockTransactionRequestStatusType();
        this.currentTransactionRequestStatusType.fromDto(dto.currentTransactionRequestStatusType);

        // Available statuses for the transaction request.
        this.availableTransactionRequestStatusTypes.replace(
            dto.availableTransactionRequestStatusTypes.map((statusType: IStockTransactionRequestStatusType) => {
                const enumType = new StockTransactionRequestStatusType();

                enumType.fromDto(statusType);

                return enumType;
            }),
        );

        if (!isNullOrUndefined(dto.calculatedAdjustedStock)) {
            const calculatedAdjustedStock = new CalculatedAdjustedStockModel();

            calculatedAdjustedStock.fromDto(dto.calculatedAdjustedStock!);

            this.calculatedAdjustedStock = calculatedAdjustedStock;
        }
    }

    public toDto(): any {
        return {
            stockTransactionRequestStatusTypeId: this.transactionRequestStatusType?.id,
        };
    }

    // #endregion Methods
}
