import * as MobX from "mobx";
import { IsNotEmpty } from "class-validator";

// Libs
import { ModelBase } from "@shoothill/core";

// App

export class ClientNameAndId extends ModelBase<ClientNameAndId, ClientNameAndIdDTO> {
    public id: string = "";

    @MobX.observable
    public name: string = "";

    public isDeleted: boolean = false;

    @MobX.action
    public fromDto = (model: ClientNameAndIdDTO) => {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                this[key] = model[key];
            }
        }
    };

    public toDto = (model: ClientNameAndIdDTO) => {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                model[key] = this[key];
            }
        }
    };

    @MobX.action
    public clearModel() {
        this.id = "";
        this.name = "";
        this.isDeleted = false;
    }
}

export interface ClientNameAndIdDTO {
    id: string;
    name: string;
    isDeleted: boolean;
}
