import { ApiResult, FieldType, ViewModelBase, isEmptyOrWhitespace, observable } from "@shoothill/core";
import { runInAction, action, computed } from "mobx";
import moment from "moment";

import { AppUrls } from "AppUrls";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import {
    BuildingControlSiteVisitModel,
    BuildingControlSiteVisitPhotoFile,
    BuildingControlSiteVisitProjectDetailsDTO,
    UpsertBuildingControlSiteVisitAndRelatedRequestDTO,
    BuildingControlReportPhotosFile,
    BuildingControlSiteVisitDTO,
    FormRequestDTO,
    BuildingControlSiteVisitAuditUserDTO,
} from "./BuildingControlSiteVisitModel";

export class BuildingControlSiteVisitViewModel extends ViewModelBase<BuildingControlSiteVisitModel> {
    constructor(projectId: string | null, buildingControlSiteVisitDate: Date) {
        super(new BuildingControlSiteVisitModel());

        this.setDecorators(BuildingControlSiteVisitModel);

        this.model.projectId = projectId!;
        this.model.buildingControlSiteVisitDate = buildingControlSiteVisitDate;

        // Load the building control site visit.
        this.loadWithRelated();
    }

    public server: ServerViewModel = new ServerViewModel();

    @observable
    public buildingControlSiteVisit: BuildingControlSiteVisitDTO | null = null;

    @observable
    public buildingControlInspectionType = observable<any>([]);

    @observable
    public buildingControlSiteVisitAuditUsers = observable<BuildingControlSiteVisitAuditUserDTO>([]);

    @observable
    public buildingControlSiteVisitProjectDetails: BuildingControlSiteVisitProjectDetailsDTO | null = null;

    @observable
    public inspectionType: { id: string; displayName: string } | null = null;

    @observable
    public buildingInspectors: { id: string; displayName: string } | null = null;

    public getUserName(id: string | null): string {
        return this.buildingControlSiteVisitAuditUsers.find((i: any) => i.id === id)?.displayName ?? "-";
    }

    @action
    public handleInspectionTypeId = (item: { id: string; displayName: string }) => {
        if (item && item.id !== undefined) {
            this.inspectionType = item;
            this.setValue("buildingControlInspectionTypeId", item.id);
        }
    };

    @action
    public hasPassedInspection = (fieldName: any, value: string) => {
        const val = value === "true" ? true : false;
        this.setValue(fieldName as any, val);
    };

    @action
    public reset = () => {
        this.model.reset();
        this.buildingControlSiteVisitProjectDetails = null;
        this.server.reset();
    };

    @action
    public handleCancel(projectId: string | null): void {
        const navProjectId = projectId ?? this.model.projectId;

        this.reset();

        this.history.push(AppUrls.Client.Project.Construction.replace(":projectid", navProjectId) + "#programmeUpdates");
    }

    @action
    public setBuildingControlSiteVisitPhotos = (file: BuildingControlSiteVisitPhotoFile) => {
        this.model.buildingControlSiteVisitPhotos.push(file);
    };
    @action
    public setBuildingControlReportPhotos = (file: BuildingControlReportPhotosFile) => {
        this.model.buildingControlReportPhotos.push(file);
    };

    @action
    public deleteBuildingControlSiteVisitFile = async (index: number): Promise<void> => {
        this.model.buildingControlSiteVisitPhotos[index].isDeleted = true;
    };

    @action
    public deleteBuildingControlReportPhotosFile = async (index: number): Promise<void> => {
        this.model.buildingControlReportPhotos[index].isDeleted = true;
    };

    public updateSignature = (data: any) => {
        this.setValue("signatureIssuedByURL", data);
    };

    @computed
    public get isFormDisabled(): boolean {
        return this.model.id !== null && this.model.id !== undefined && this.model.id !== "";
    }

    @computed
    public get getTodayDateFormatted(): string {
        return this.model.createdDate ? moment(this.model.createdDate).format("DD/MM/YYYY").toString() : moment().format("DD/MM/YYYY").toString();
    }

    // Validation Start

    @computed
    private get validateInspectionTypeId() {
        const errorMessage = this.model.validateInspectionTypeId;
        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateAreaOfWorkInspected() {
        const errorMessage = this.model.validateAreaOfWorkInspected;
        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateComments() {
        const errorMessage = this.model.validateComments;
        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateBuildingInspectorName() {
        const errorMessage = this.model.validateBuildingInspectorName;
        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateSignatureIssuedByURL() {
        const errorMessage = this.model.validateSignatureIssuedByURL;
        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    // End Validation

    // File Upload

    public fileChange = async (event: any, isCompleted: boolean = false): Promise<void> => {
        if (event.target.files.length > 0) {
            let data: any = {
                fileName: event.target.files[0].name,
                formFile: event.target.files[0],
            };
            event.target.value = null;
            const apiResult = await this.fileUpload(data);
            if (apiResult && apiResult.wasSuccessful) {
                let fileToDisplay: BuildingControlSiteVisitPhotoFile = {
                    id: null,
                    buildingControlSiteVisitId: null,
                    fileName: data.fileName,
                    photoURL: apiResult.payload,
                    isDeleted: false,
                    createdByUserId: "",
                    createdDate: "",
                };
                if (isCompleted) {
                    this.setBuildingControlReportPhotos(fileToDisplay);
                } else {
                    this.setBuildingControlSiteVisitPhotos(fileToDisplay);
                }
            }
        }
    };

    public fileUpload = async (data: any): Promise<ApiResult<any>> => {
        const formData = new FormData();
        formData.append("formFile", data.formFile);
        formData.append("fileName", data.fileName);
        const apiResult = await this.Post<any>(AppUrls.Server.File.UploadFile, formData);
        if (apiResult) {
            if (!apiResult.wasSuccessful) {
                console.log(apiResult.errors);
                runInAction(() => {});
            }
        }
        return apiResult;
    };

    public DownloadFile = async (fileUrl: string, fileName: string): Promise<void> => {
        try {
            const apiResult = await this.Post<Blob>(AppUrls.Server.File.DownloadFile, fileUrl, undefined, { responseType: "blob" });
            const response = apiResult as any;
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
        } catch (exception) {
            console.error(exception);
            this.setIsErrored(true);
        }
    };

    // End FileUpload

    // Start load WithRelated

    @action
    public loadWithRelated = async () => {
        const request: FormRequestDTO = {
            date: this.model.buildingControlSiteVisitDate,
            projectId: this.model.projectId,
            formId: null,
        };

        const apiResult = await this.Post<any>(AppUrls.Server.Projects.Construction.ProgrammeUpdates.GetBuildingControlSiteVisitAndRelatedByProjectId, request);

        // Decorated actions do not work with asynchronous code.
        runInAction(() => {
            if (apiResult.wasSuccessful) {
                this.model.fromDto(apiResult.payload.buildingControlSiteVisit);

                this.buildingControlSiteVisitProjectDetails = apiResult.payload.buildingControlSiteVisitProjectDetails;
                this.buildingControlSiteVisit = apiResult.payload.buildingControlSiteVisit;
                this.buildingControlInspectionType.replace(apiResult.payload.buildingControlInspectionType);
                this.buildingControlSiteVisitAuditUsers.replace(apiResult.payload.buildingControlSiteVisitAuditUsers);
                this.handleInspectionTypeId(this.buildingControlInspectionType.find((i) => i.id === this.model.buildingControlInspectionTypeId)!);

                apiResult.payload.buildingControlSiteVisitPhotos.map((el: any) => this.setBuildingControlSiteVisitPhotos(el));
                apiResult.payload.buildingControlReportPhotos.map((el: any) => this.setBuildingControlReportPhotos(el));
            } else {
                console.log(apiResult.errors);
            }
        });

        return apiResult;
    };

    // End loadWithRelated

    //Add API

    public upsert = async (): Promise<void> => {
        this.setIsLoading(true);

        const model: any = this.model.toDto();

        let buildingControlSiteVisitPhotos: BuildingControlSiteVisitPhotoFile[] = [];
        let buildingControlReportPhotos: BuildingControlReportPhotosFile[] = [];

        this.model.buildingControlSiteVisitPhotos.forEach((buildingControlSiteVisitPhoto) => {
            const environment: BuildingControlSiteVisitPhotoFile = {
                id: buildingControlSiteVisitPhoto.id,
                buildingControlSiteVisitId: buildingControlSiteVisitPhoto.buildingControlSiteVisitId,
                fileName: buildingControlSiteVisitPhoto.fileName,
                photoURL: buildingControlSiteVisitPhoto.photoURL,
                createdByUserId: buildingControlSiteVisitPhoto.createdByUserId,
                isDeleted: buildingControlSiteVisitPhoto.isDeleted,
                createdDate: buildingControlSiteVisitPhoto.createdDate,
            };
            buildingControlSiteVisitPhotos.push(environment);
        });

        this.model.buildingControlReportPhotos.forEach((buildingControlReportPhoto) => {
            const qualityRectification: BuildingControlReportPhotosFile = {
                id: buildingControlReportPhoto.id,
                buildingControlSiteVisitId: buildingControlReportPhoto.buildingControlSiteVisitId,
                fileName: buildingControlReportPhoto.fileName,
                photoURL: buildingControlReportPhoto.photoURL,
                createdByUserId: buildingControlReportPhoto.createdByUserId,
                isDeleted: buildingControlReportPhoto.isDeleted,
                createdDate: buildingControlReportPhoto.createdDate,
            };
            buildingControlReportPhotos.push(qualityRectification);
        });

        const request: UpsertBuildingControlSiteVisitAndRelatedRequestDTO = {
            buildingControlSiteVisit: model,
            buildingControlSiteVisitPhotos: buildingControlSiteVisitPhotos,
            buildingControlReportPhotos: buildingControlReportPhotos,
        };

        return await this.server
            .command<string>(
                () => this.Post(AppUrls.Server.Projects.Construction.ProgrammeUpdates.UpsertBuildingControl, request),
                (result) => {
                    runInAction(() => {
                        // Nothing to process.
                        this.handleCancel(model.projectId);
                    });
                },
                this.isModelValid,
                "There was an error trying to send the permit",
            )
            .finally(() => this.setIsLoading(false));
    };

    // End API

    public async isFieldValid(fieldName: keyof FieldType<BuildingControlSiteVisitModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);
        if (this.server.IsSubmitted) {
            switch (fieldName) {
                case "buildingControlInspectionTypeId": {
                    const result = this.validateInspectionTypeId;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }

                case "workInspectDetail": {
                    const result = this.validateAreaOfWorkInspected;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }

                case "comments": {
                    const result = this.validateComments;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }

                case "buildingInspectorName": {
                    const result = this.validateBuildingInspectorName;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }

                case "signatureIssuedByURL": {
                    const result = this.validateSignatureIssuedByURL;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
            }
        } else {
            errorMessage = "";
            isValid = true;
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
