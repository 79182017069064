import { FieldType, ViewModelBase } from "@shoothill/core";
import { AppUrls } from "AppUrls";
import { action, computed, runInAction } from "mobx";
import moment from "moment";

import { InductionType } from "Globals/Models/Enums/InductionType";
import { InductionAdminItemDTO, InductionAdminItemModel } from "./InductionAdminItemModel";
import { InductionAdminDetailsResultDTO, InductionStatusEnum } from "../InductionAdminModel";
import { InductionAdminViewModel } from "../InductionAdminViewModel";

export class InductionAdminItemViewModel extends ViewModelBase<InductionAdminItemModel> {
    // #region Constructors and Disposers

    constructor() {
        super(new InductionAdminItemModel());
        this.setDecorators(InductionAdminItemModel);
    }

    @computed
    public get confirmOver18Formatted(): string {
        return this.getFormattedBoolean(this.model.confirmOver18);
    }

    @computed
    public get confirmAdjustmentsFormatted(): string {
        return this.getFormattedBoolean(this.model.confirmAdjustments);
    }

    @computed
    public get healthProblemsFormatted(): string {
        return this.getFormattedBoolean(this.model.healthProblems);
    }

    @computed
    public get isGeneralConfirmedFormatted(): string {
        return this.getFormattedBoolean(this.model.general);
    }

    @computed
    public get isPPEConfirmedFormatted(): string {
        return this.getFormattedBoolean(this.model.ppe);
    }

    @computed
    public get isSafetyConfirmedFormatted(): string {
        return this.getFormattedBoolean(this.model.ppe);
    }

    @computed
    public get isHousekeepingConfirmedFormatted(): string {
        return this.getFormattedBoolean(this.model.housekeeping);
    }

    @computed
    public get isRulesOrDisciplineConfirmedFormatted(): string {
        return this.getFormattedBoolean(this.model.rulesDiscipline);
    }

    @computed
    public get isSiteSpecificBriefingConfirmedFormatted(): string {
        return this.getFormattedBoolean(this.model.siteSpecificBriefing);
    }

    private getFormattedBoolean = (val: boolean): string => {
        return val ? "Yes" : "No";
    };

    @computed
    public get createdAtFormatted(): string {
        return moment(this.model.createdAt).format("DD/MM/YY").toString();
    }

    @computed
    public get isApproved(): boolean {
        if (this.inductionAdminViewModelExists) {
            if (InductionAdminViewModel.GetInstance && InductionAdminViewModel.GetInstance.inductionStatuses) {
                const approvedStatus = InductionAdminViewModel.GetInstance.inductionStatuses.find((s) => s.type === InductionStatusEnum.AccessApproved);

                if (approvedStatus && approvedStatus.id === this.model.inductionStatusId) {
                    return true;
                }
            }
        }

        return false;
    }

    @computed
    public get approvedDateFormatted(): string {
        return moment(this.model.approvedDate).format("DD/MM/YYYY").toString();
    }

    @computed
    public get isApprovedStringFormatted(): string {
        return this.isApproved ? `This visitor was approved on ${this.approvedDateFormatted} by ${this.model.approvedByUserName}` : "This visitor has not been approved.";
    }

    @computed
    public get hasSignature(): boolean {
        return this.model.signatureURL !== null && this.model.signatureURL !== undefined && this.model.signatureURL !== "";
    }

    @computed
    public get getCurrentStatusId(): string | null {
        if (this.inductionAdminViewModelExists) {
            return InductionAdminViewModel.GetInstance.inductionAdminItemViewModel.model.inductionStatusId;
        }
        return null;
    }

    @computed
    public get inductionAdminViewModelExists(): boolean {
        if (
            InductionAdminViewModel.GetInstance &&
            InductionAdminViewModel.GetInstance.inductionAdminItemViewModel &&
            InductionAdminViewModel.GetInstance.inductionAdminItemViewModel.model &&
            InductionAdminViewModel.GetInstance.inductionAdminItemViewModel.model.inductionStatusId
        ) {
            return true;
        }
        return false;
    }

    @computed
    public get inductionType(): InductionType | null {
        return this.model.inductionTypes.find((r) => r.id === this.model.inductionTypeId) ?? null;
    }

    // #endregion Properties

    // #region Server Actions

    //public server: ServerViewModel = new ServerViewModel();

    public deleteInduction = async (): Promise<void> => {
        this.setIsLoading(true);
        let retVal = await InductionAdminViewModel.GetInstance.server
            .command<InductionAdminItemDTO>(
                () => this.Post(AppUrls.Server.Induction.Delete, { id: this.model.id }),
                (result) => {
                    runInAction(() => {
                        this.reset();
                        this.history.push(AppUrls.Client.Induction.List);
                    });
                },
                this.isMyModelValid,
                "There was an error trying to delete the induction",
            )
            .finally(() => this.setIsLoading(false));

        if (InductionAdminViewModel.GetInstance.server.HaveValidationMessage) {
            InductionAdminViewModel.GetInstance.setSnackMessage("There was an error trying to delete the induction");
            InductionAdminViewModel.GetInstance.setSnackType(InductionAdminViewModel.GetInstance.SNACKERROR);
            InductionAdminViewModel.GetInstance.setSnackbarState(true);
        }

        return retVal;
    };

    public updateInductionStatus = async (inductionStatusId: string): Promise<void> => {
        this.setIsLoading(true);
        let retVal = await InductionAdminViewModel.GetInstance.server
            .command<InductionAdminDetailsResultDTO>(
                () => this.Post(AppUrls.Server.Induction.UpdateInductionStatus, { id: this.model.id, inductionStatusId: inductionStatusId }),
                (result) => {
                    runInAction(() => {
                        InductionAdminViewModel.GetInstance.inductionAdminItemViewModel.model.fromExtendedDto(result.inductionItem, result.inductionTypes);
                        InductionAdminViewModel.GetInstance.inductionStatuses = result.inductionStatuses;
                    });
                },
                this.isMyModelValid,
                "There was an error trying to update the induction",
            )
            .finally(() => this.setIsLoading(false));

        if (InductionAdminViewModel.GetInstance.server.HaveValidationMessage) {
            InductionAdminViewModel.GetInstance.setSnackMessage("There was an error trying to update the induction");
            InductionAdminViewModel.GetInstance.setSnackType(InductionAdminViewModel.GetInstance.SNACKERROR);
            InductionAdminViewModel.GetInstance.setSnackbarState(true);
        }

        return retVal;
    };

    public isMyModelValid = async (): Promise<boolean> => {
        return true;
    };

    // #endregion Server Actions

    @action
    public reset = () => {
        InductionAdminViewModel.GetInstance.reset();
    };

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<InductionAdminItemModel>): Promise<boolean> {
        return true;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
