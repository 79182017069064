import { Box, Button, FormHelperText, MenuItem, Select } from "@material-ui/core";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { ControlLabel } from "Components/Form/ControlLabel";
import { generateID, KeyValuePair } from "@shoothill/core";
import "./MultiSelect.scss";

interface IMultiSelectProps {
    // Data
    fieldName: string;
    values: number[] | null | string[];
    display: string;

    // Callbacks
    getOptions: (includeDeleted: boolean) => KeyValuePair[];
    handleChange: (values: number[] | null) => void;
    // getValid: (fieldName: string) => boolean;
    // getError: (fieldName: string) => string;

    //styling
    style?: any;
}

/**
 *
 * @param props current Client Id
 * @returns autocomplete form component
 */
export const MultiSelectView: React.FC<IMultiSelectProps> = (props) => {
    const [placeholder, setPlaceHolder] = useState("Select...");

    useEffect(() => {
        generatePlaceHolder();
        return () => {};
    }, []);

    useEffect(() => {
        generatePlaceHolder();
    }, [props.values]);

    // const isInError = (fieldName: string): boolean => {
    //     let isValid = props.getValid(fieldName);
    //     return !isValid;
    // };

    // const getError = (fieldName: string) => {
    //     let retVal: string = props.getError(fieldName);
    //     return retVal;
    // };

    const selectAll = (event: any) => {
        event.stopPropagation();
        const { getOptions, handleChange } = props;
        const newValue: number[] | null = getOptions(true).map((a) => a.value);
        handleChange(newValue);
    };

    const selectNone = (event: any) => {
        event.stopPropagation();
        const { handleChange } = props;
        handleChange([]);
    };

    const generatePlaceHolder = () => {
        if (props.values !== null && props.values.length > 0) {
            const total: number = props.getOptions(true).length;
            const selected: number = props.values === null ? 0 : props.values.length;

            if (total === selected) {
                setPlaceHolder(props.display + ": All");
            } else {
                setPlaceHolder(props.display + ": " + selected.toString() + " of " + total.toString());
            }
        } else {
            setPlaceHolder("Select...");
        }
    };

    const renderSelectAll = () => {
        return (
            <div className="allnonerow">
                <div className="button-wrapper">
                    <Button onClick={selectAll} color="default" autoFocus variant="contained">
                        All
                    </Button>
                    <Button onClick={selectNone} color="default" autoFocus variant="contained">
                        None
                    </Button>
                </div>
            </div>
        );
    };

    const onChange = (values: unknown) => {
        props.handleChange(values == null ? null : (values as number[]));
    };

    return useObserver(() => (
        <>
            <Box className="cell-right rcselect" marginTop="-3px" width="100%" style={props.style}>
                <ControlLabel label={props.display} htmlFor={props.fieldName + "-select"}>
                    <Box maxWidth="200px">
                        <Select
                            id={props.fieldName + "-select"}
                            value={props.values}
                            onChange={(e: React.ChangeEvent<{ name?: string; value: unknown }>) => onChange(e.target.value)}
                            MenuProps={{
                                getContentAnchorEl: null,
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                            }}
                            renderValue={() => <em>{placeholder}</em>}
                            fullWidth
                            multiple
                        >
                            {props.getOptions(true).map((option, index) => {
                                return (
                                    <MenuItem key={generateID()} value={option.value}>
                                        {option.key}
                                    </MenuItem>
                                );
                            })}
                            {renderSelectAll()}
                        </Select>
                        {/* <Box>{isInError(props.fieldName) && <FormHelperText style={{ color: "red" }}>{getError(props.fieldName)}</FormHelperText>}</Box> */}
                    </Box>
                </ControlLabel>
            </Box>
        </>
    ));
};
