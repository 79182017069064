import { action, observable, computed } from "mobx";
import { ModelBase } from "@shoothill/core";
import { IncidentDTO } from "../IncidentModel";
import { GenericPhotoListItemDTO, GenericPhotoListItemModel } from "../Supporting/GenericPhotoListItemModel";

export class IncidentFormWitnessStatementModel extends ModelBase<IncidentFormWitnessStatementModel, any> {
    public static readonly DEFAULT_ID = null;
    public static readonly DEFAULT_INCIDENTID: string | null = null;
    public static readonly DEFAULT_CREATED_BY_USER_ID: string | null = null;
    public static readonly DEFAULT_CREATED_DATE: string | null = null;
    public static readonly DEFAULT_IS_DELETED: boolean = false;
    public static readonly DEFAULT_ROW_VERSION: string | null = null;
    public static readonly DEFAULT_LOCATION: string = "";
    public static readonly DEFAULT_WITNESSSTATEMENT_DATE: Date | null = null;
    public static readonly DEFAULT_WHERE_WERE_YOU: string | null = null;
    public static readonly DEFAULT_WHAT_DID_YOU_SEE: string | null = null;
    public static readonly DEFAULT_WHAT_DID_YOU_OBSERVE: string | null = null;
    public static readonly DEFAULT_WITNESS_NAME_NOT_IN_SYSTEM: string | null = null;
    public static readonly DEFAULT_WITNESS_SIGNATURE_URL: string | null = null;
    public static readonly DEFAULT_LAST_UPDATED_BY_USER_ID: string | null = null;
    public static readonly DEFAULT_WITNESS_SIGNATURE_DATE: Date | null = null;
    public static readonly DEFAULT_WITNESS_USER_ID: string | null = null;
    public static readonly DEFAULT_LAST_UPDATED_DATE: Date | null = null;

    // #endregion Constants and Defaults

    // #region Properties

    @observable
    public id: string | null = IncidentFormWitnessStatementModel.DEFAULT_ID;

    @observable
    public incidentId: string | null = IncidentFormWitnessStatementModel.DEFAULT_INCIDENTID;

    @observable
    public createdByUserId: string | null = IncidentFormWitnessStatementModel.DEFAULT_CREATED_BY_USER_ID;

    @observable
    public createdDate: string | null = IncidentFormWitnessStatementModel.DEFAULT_CREATED_DATE;

    @observable
    public isDeleted: boolean = IncidentFormWitnessStatementModel.DEFAULT_IS_DELETED;

    @observable
    public rowVersion: string | null = IncidentFormWitnessStatementModel.DEFAULT_ROW_VERSION;

    @observable
    public location: string = IncidentFormWitnessStatementModel.DEFAULT_LOCATION;

    @observable
    public witnessStatementDate: Date | null = IncidentFormWitnessStatementModel.DEFAULT_WITNESSSTATEMENT_DATE;

    @observable
    public whereWereYou: string | null = IncidentFormWitnessStatementModel.DEFAULT_WHERE_WERE_YOU;

    @observable
    public whatDidYouSee: string | null = IncidentFormWitnessStatementModel.DEFAULT_WHAT_DID_YOU_SEE;

    @observable
    public whatDidYouObserve: string | null = IncidentFormWitnessStatementModel.DEFAULT_WHAT_DID_YOU_OBSERVE;

    @observable
    public witnessUserId: string | null = IncidentFormWitnessStatementModel.DEFAULT_WITNESS_USER_ID;

    @observable
    public witnessNameNotInSystem: string | null = IncidentFormWitnessStatementModel.DEFAULT_WITNESS_NAME_NOT_IN_SYSTEM;

    @observable
    public witnessSignatureURL: string | null = IncidentFormWitnessStatementModel.DEFAULT_WITNESS_SIGNATURE_URL;

    @observable
    public witnessSignatureDate: Date | null = IncidentFormWitnessStatementModel.DEFAULT_WITNESS_SIGNATURE_DATE;

    @observable
    public lastUpdatedByUserId: string | null = IncidentFormWitnessStatementModel.DEFAULT_LAST_UPDATED_BY_USER_ID;

    @observable
    public lastUpdatedDate: Date | null = IncidentFormWitnessStatementModel.DEFAULT_LAST_UPDATED_DATE;

    @observable
    public incidentFormWitnessStatementPhotos: GenericPhotoListItemModel[] = [];

    public readonly notInSystemOption: IncidentUserDTO = { id: "notInSystem", displayName: "Not in system" };

    // #endregion Properties

    // #endregion Properties

    @computed
    public get hasId(): boolean {
        return this.id !== null && this.id !== undefined && this.id !== "";
    }

    // #region Actions

    @action
    public reset = () => {
        this.id = IncidentFormWitnessStatementModel.DEFAULT_ID;
        this.incidentId = IncidentFormWitnessStatementModel.DEFAULT_INCIDENTID;
        this.createdByUserId = IncidentFormWitnessStatementModel.DEFAULT_CREATED_BY_USER_ID;
        this.createdDate = IncidentFormWitnessStatementModel.DEFAULT_CREATED_DATE;
        this.isDeleted = IncidentFormWitnessStatementModel.DEFAULT_IS_DELETED;
        this.rowVersion = IncidentFormWitnessStatementModel.DEFAULT_ROW_VERSION;
        this.location = IncidentFormWitnessStatementModel.DEFAULT_LOCATION;
        this.witnessUserId = IncidentFormWitnessStatementModel.DEFAULT_WITNESS_USER_ID;
        this.whereWereYou = IncidentFormWitnessStatementModel.DEFAULT_WHERE_WERE_YOU;
        this.whatDidYouSee = IncidentFormWitnessStatementModel.DEFAULT_WHAT_DID_YOU_SEE;
        this.whatDidYouObserve = IncidentFormWitnessStatementModel.DEFAULT_WHAT_DID_YOU_OBSERVE;
        this.lastUpdatedByUserId = IncidentFormWitnessStatementModel.DEFAULT_LAST_UPDATED_BY_USER_ID;
        this.lastUpdatedDate = IncidentFormWitnessStatementModel.DEFAULT_LAST_UPDATED_DATE;

        this.incidentFormWitnessStatementPhotos.length = 0;
    };

    @action
    public fromDto(dto: IncidentFormWitnessStatementAndRelatedResponseDTO): void {
        const WitnessStatementDto = dto.incidentFormWitnessStatement;

        for (let key in WitnessStatementDto) {
            if (WitnessStatementDto.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(WitnessStatementDto[key]);
                } else {
                    this[key] = WitnessStatementDto[key];
                }
            }
        }

        this.incidentFormWitnessStatementPhotos.length = 0;

        // Manually process the child array otherwise we will end up with an array of dtos being stored in the array of models.
        let processedPhotos: GenericPhotoListItemModel[] = [];

        for (const photo of dto.incidentFormWitnessStatementPhotos) {
            const photoToAdd = new GenericPhotoListItemModel();
            photoToAdd.fromDto(photo);
            processedPhotos.push(photoToAdd);
        }

        this.incidentFormWitnessStatementPhotos = [...this.incidentFormWitnessStatementPhotos, ...processedPhotos];
    }

    public toDto() {
        const WitnessStatementModel: IncidentFormWitnessStatementDTO = {
            id: this.id,
            incidentId: this.incidentId,
            createdByUserId: this.createdByUserId,
            createdDate: this.createdDate,
            isDeleted: this.isDeleted,
            rowVersion: this.rowVersion,
            location: this.location,
            witnessStatementDate: this.witnessStatementDate,
            whereWereYou: this.whereWereYou,
            whatDidYouSee: this.whatDidYouSee,
            whatDidYouObserve: this.whatDidYouObserve,
            witnessUserId: this.witnessUserId,
            witnessNameNotInSystem: this.witnessNameNotInSystem,
            witnessSignatureURL: this.witnessSignatureURL,
            witnessSignatureDate: this.witnessSignatureDate,
            lastUpdatedByUserId: this.lastUpdatedByUserId,
            lastUpdatedDate: this.lastUpdatedDate,
        };

        return WitnessStatementModel;
    }

    // #endregion Actions

    // #region Custom Validation

    @computed
    public get validateLocation(): string {
        return this.location === IncidentFormWitnessStatementModel.DEFAULT_LOCATION || this.location?.trim() === "" ? "Please provide a location" : "";
    }

    @computed
    public get validateWitnessStatementDate(): string {
        return this.witnessStatementDate === IncidentFormWitnessStatementModel.DEFAULT_WITNESSSTATEMENT_DATE ? "Please provide a date and time" : "";
    }

    @computed
    public get validateWhereWereYou(): string {
        return this.whereWereYou === IncidentFormWitnessStatementModel.DEFAULT_WHERE_WERE_YOU || this.whereWereYou?.trim() === ""
            ? "Please provide a description of where you were"
            : "";
    }

    @computed
    public get validateWhatDidYouSee(): string {
        return this.whatDidYouSee === IncidentFormWitnessStatementModel.DEFAULT_WHAT_DID_YOU_SEE || this.whatDidYouSee?.trim() === ""
            ? "Please provide a description of what you saw"
            : "";
    }

    @computed
    public get validateWhatDidYouObserve(): string {
        return this.whatDidYouObserve === IncidentFormWitnessStatementModel.DEFAULT_WHAT_DID_YOU_OBSERVE || this.whatDidYouObserve?.trim() === ""
            ? "Please provide a description of what you observed"
            : "";
    }

    @computed
    public get validateWitnessUserId(): string {
        return this.witnessUserId === IncidentFormWitnessStatementModel.DEFAULT_WITNESS_USER_ID ? "Please provide a witness" : "";
    }

    @computed
    public get validateWitnessNameNotInSystem(): string {
        if (this.witnessUserId !== this.notInSystemOption.id) {
            return "";
        }
        return this.witnessNameNotInSystem === IncidentFormWitnessStatementModel.DEFAULT_WITNESS_NAME_NOT_IN_SYSTEM ? "Please provide a not in system name" : "";
    }

    @computed
    public get validateWitnessSignatureURL(): string {
        return this.witnessSignatureURL === IncidentFormWitnessStatementModel.DEFAULT_WITNESS_SIGNATURE_URL ? "Please provide a signature" : "";
    }

    // #endregion Custom Validation
}

export interface IncidentFormWitnessStatementDTO {
    id: string | null;
    incidentId: string | null;
    createdByUserId: string | null;
    createdDate: string | null;
    isDeleted: boolean;
    rowVersion: string | null;
    location: string | null;
    witnessStatementDate: Date | null;
    whereWereYou: string | null;
    whatDidYouSee: string | null;
    whatDidYouObserve: string | null;
    witnessUserId: string | null;
    witnessNameNotInSystem: string | null;
    witnessSignatureURL: string | null;
    witnessSignatureDate: Date | null;
    lastUpdatedByUserId: string | null;
    lastUpdatedDate: Date | null;
}

export interface IncidentFormWitnessStatementRelatedResponseDTO {
    incident: IncidentDTO;
    incidentProjectDetails: IncidentFormWitnessStatementProjectDetailsDTO;
    incidentUsers: IncidentUserDTO[];
}

export interface IncidentFormWitnessStatementAndRelatedResponseDTO extends IncidentFormWitnessStatementRelatedResponseDTO {
    incidentFormWitnessStatement: IncidentFormWitnessStatementDTO;
    incidentFormWitnessStatementPhotos: GenericPhotoListItemDTO[];
}

export interface IncidentFormWitnessStatementProjectDetailsDTO {
    projectReference: string;
    projectName: string;
}

export interface IncidentUserDTO {
    id: string;
    displayName: string;
}

export interface GenericListItemDTO {
    id: string;
    displayName: string;
}

export interface IncidentFormWitnessStatementPhotoDTO {
    id: string | null;
    incidentFormWitnessStatementId: string | null;
    fileName: string | null;
    photoURL: string | null;
    isDeleted: boolean;
    createdDate: string | null;
    createdByUserId: string | null;
}

export interface UpsertIncidentFormWitnessStatementAndRelatedRequestDTO {
    incident: IncidentDTO;
    incidentFormWitnessStatement: IncidentFormWitnessStatementDTO;
    incidentFormWitnessStatementPhotos: GenericPhotoListItemDTO[];
}
