import { FieldType, isNullOrEmpty, ViewModelBase } from "@shoothill/core";
import { AddEditContactModel, ContactModelBaseDTO } from "./AddEditContactModel";
import { observable, action, computed } from "mobx";
import validator from "validator";
import { AppUrls } from "AppUrls";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { ContactTypeModel } from "./ContactTypeModel";
import { ContactType } from "./ContactTypeEnum";

export class AddEditContactViewModel extends ViewModelBase<AddEditContactModel> {
    private static _instance: AddEditContactViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable public errorMessage: string = "";

    public constructor() {
        super(new AddEditContactModel(), false);
        this.setDecorators(AddEditContactModel);
        this.loadRelated();
    }

    @action
    public cleanUp = () => {
        this.model.clear();
    };

    @observable
    public contactTypes = observable<ContactTypeModel>([]);

    @computed
    public get contactType() {
        const result = this.contactTypes.find((co) => co.id === this.model.contactTypeId);
        return result ? result! : null;
    }

    @action
    public setContactType = (value: ContactTypeModel | null) => {
        this.model.contactTypeId = value ? value.id : null;
        switch (true) {
            case this.model.contactTypeId === ContactType.PurchaseOrder:
                break;

            case this.model.contactTypeId === ContactType.Invoice:
                break;
        }
    };

    public server: ServerViewModel = new ServerViewModel();

    public loadRelated = (): Promise<void> => {
        return this.server.query<ContactModelBaseDTO>(
            () => this.Get(`${AppUrls.Server.Supplier.GetContactType}`),
            (result) => {
                this.contactTypes.replace(ContactTypeModel.fromDtos(result.contactTypes));
            },
        );
    };

    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (await this.isModelValid()) {
            this.errorMessage = "Form is valid";
        } else {
            this.errorMessage = "Form is not valid";
        }
    };

    public async isFieldValid(fieldName: keyof FieldType<AddEditContactModel>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        if (fieldName === "firstName") {
            errorMessage = this.isFirstNameValid;
            isValid = errorMessage === "";
        }

        if (fieldName === "lastName") {
            errorMessage = this.isLastNameValid;
            isValid = errorMessage === "";
        }

        if (fieldName === "position") {
            errorMessage = this.isPositionValid;
            isValid = errorMessage === "";
        }

        if (fieldName === "email") {
            errorMessage = this.isContactEmailValid;
            isValid = errorMessage === "";
        }

        /*  ismobilenumber seems to no longer work       if (fieldName === "phone") {
            errorMessage = this.isContactPhoneNumberValid;
            isValid = errorMessage === "";
        } */

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public get isFirstNameValid(): string {
        let error = "";

        const field: string | null = this.getValue<string | null>("firstName");

        if (isNullOrEmpty(field)) {
            error = "First name is required";
        } else if (field!.length > 128) {
            error = "First name needs to be less than 128 characters";
        }

        return error;
    }

    public get isLastNameValid(): string {
        let error = "";

        const field: string | null = this.getValue<string | null>("lastName");

        if (isNullOrEmpty(field)) {
            error = "Last name is required";
        } else if (field!.length > 128) {
            error = "Last name needs to be less than 128 characters";
        }

        return error;
    }

    public get isPositionValid(): string {
        let error = "";

        const field: string | null = this.getValue<string | null>("position");

        if (isNullOrEmpty(field)) {
            error = "Position is required";
        } else if (field!.length > 50) {
            error = "Position needs to be less than 50 characters";
        }

        return error;
    }

    public get isContactEmailValid(): string {
        let error = "";

        const field: string | null = this.getValue<string | null>("email");

        if (isNullOrEmpty(field)) {
            error = "Contact email is required";
        } else {
            if (!validator.isEmail(field!)) {
                error = "Email address is invalid";
            }
        }

        return error;
    }

    /* public get isContactPhoneNumberValid(): string {
        let error = "";

        const field: string | null = this.getValue<string | null>("phone");

        if (!isNullOrEmpty(field)) {
            // if (field!.length < 9 || field!.length > 11) {
            //     error = "Phone number is invalid";
            // }

            if (!validator.isMobilePhone("en-GB")) {
                error = "Phone number is invalid";
            }
        }

        return error;
    } */

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
