const DownloadIcon = (props: any) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={13} height={12} {...props}>
        <g data-name="Group 9666" transform="translate(-1159 -153)">
            <rect width={12} height={2} fill="#425b66" data-name="Rectangle 1649" rx={1} transform="rotate(90 509.5 662.5)" />
            <rect width={8} height={2} fill="#fff" data-name="Rectangle 1526" rx={1} transform="translate(1160 158.001)" />
            <path fill="#fff" d="M1159.976 159.838a1 1 0 0 1 0-1.562l2.399-1.919a1 1 0 0 1 1.625.781v3.838a1 1 0 0 1-1.625.781Z" data-name="Polygon 12" />
        </g>
    </svg>
);
export default DownloadIcon;
