import * as React from "react";
import { SVGProps, useEffect } from "react";
import { useLocation } from "react-router";

interface Props extends SVGProps<SVGSVGElement> {}
export const ProgressCounter = (props: Props) => {
    const location = useLocation();
    const [step, setStep] = React.useState(1);
    useEffect(() => {
        setStep(parseInt(window.location.pathname.split("/").pop() as string) || 1);
    }, [location.pathname]);

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={350} height={30} {...props}>
            <g data-name="Group 8538">
                <g data-name="Group 4987" transform="translate(-19.998 -69.998)" fill="#425b66">
                    <circle data-name="Ellipse 167" cx={7} cy={7} r={7} transform="translate(28 78)" />
                    {step >= 2 && <circle data-name="Ellipse 167" cx={7} cy={7} r={7} transform="translate(92 78)" />}
                    {step >= 3 && <circle data-name="Ellipse 167" cx={7} cy={7} r={7} transform="translate(156 78)" />}
                    {step >= 4 && <circle data-name="Ellipse 167" cx={7} cy={7} r={7} transform="translate(220 78)" />}
                    {step >= 5 && <circle data-name="Ellipse 167" cx={7} cy={7} r={7} transform="translate(284 78)" />}
                    {step >= 6 && <circle data-name="Ellipse 167" cx={7} cy={7} r={7} transform="translate(348 78)" />}
                </g>
            </g>
            <circle data-name="Ellipse 166" cx={13.5} cy={13.5} r={13.5} transform="translate(1.5 1.5)" fill="none" stroke="#6c93a6" strokeWidth={3} opacity={0.7} />
            <circle data-name="Ellipse 166" cx={13.5} cy={13.5} r={13.5} transform="translate(65.5 1.5)" fill="none" stroke="#6c93a6" strokeWidth={3} opacity={0.7} />
            <circle data-name="Ellipse 166" cx={13.5} cy={13.5} r={13.5} transform="translate(193.5 1.5)" fill="none" stroke="#6c93a6" strokeWidth={3} opacity={0.7} />
            <circle data-name="Ellipse 175" cx={13.5} cy={13.5} r={13.5} transform="translate(129.5 1.5)" fill="none" stroke="#6c93a6" strokeWidth={3} opacity={0.7} />
            <circle data-name="Ellipse 166" cx={13.5} cy={13.5} r={13.5} transform="translate(257.5 1.5)" fill="none" stroke="#6c93a6" strokeWidth={3} opacity={0.7} />
            <circle data-name="Ellipse 166" cx={13.5} cy={13.5} r={13.5} transform="translate(321.5 1.5)" fill="none" stroke="#6c93a6" strokeWidth={3} opacity={0.7} />
            <path data-name="Line 52" fill="none" stroke="#6c93a6" strokeWidth={3} opacity={0.7} d="M28.5 15.5h37" />
            <path data-name="Line 53" fill="none" stroke="#6c93a6" strokeWidth={3} opacity={0.7} d="M92.5 15.5h37" />
            <path data-name="Line 54" fill="none" stroke="#6c93a6" strokeWidth={3} opacity={0.7} d="M156.5 15.5h37" />
            <path data-name="Line 55" fill="none" stroke="#6c93a6" strokeWidth={3} opacity={0.7} d="M220.5 15.5h37" />
            <path data-name="Line 68" fill="none" stroke="#6c93a6" strokeWidth={3} opacity={0.7} d="M284.5 15.5h37" />
        </svg>
    );
};
