import { FieldType, ViewModelBase } from "@shoothill/core";
import { action } from "mobx";
import { SiteAccessListFilterParamsModel } from "./SiteAccessListFilterParamsModel";

export class SiteAccessFilterViewModel extends ViewModelBase<SiteAccessListFilterParamsModel> {
    private static _intsance: SiteAccessFilterViewModel;
    public static get Instance() {
        return this._intsance || (this._intsance = new this());
    }

    constructor() {
        super(new SiteAccessListFilterParamsModel(), false);
        this.setDecorators(SiteAccessListFilterParamsModel);
    }

    @action
    public setStartDateFilter = (val: Date | null) => {
        this.model.startDateFilter = val;
    };

    @action
    public setEndDateFilter = (val: Date | null) => {
        this.model.endDateFilter = val;
    };

    @action
    public resetStartDateFilter = () => {
        this.model.startDateFilter = null;
    };

    @action
    public resetEndDateFilter = () => {
        this.model.endDateFilter = null;
    };

    @action
    public resetDateFilters = () => {
        this.resetStartDateFilter();
        this.resetEndDateFilter();
    };

    public async isFieldValid(fieldName: keyof FieldType<SiteAccessListFilterParamsModel>): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
