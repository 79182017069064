export class MaterialSupplier {
    // #region Properties

    public id: string = "";
    public displayName: string = "";
    public isDeleted: boolean = false;

    // #endregion Properties

    // #region Methods

    public fromDto(dto: IMaterialSupplier) {
        this.id = dto.id;
        this.displayName = dto.displayName;
        this.isDeleted = dto.isDeleted;
    }

    // #endregion Methods
}

export interface IMaterialSupplier {
    id: string;
    displayName: string;
    isDeleted: boolean;
}
