import { ModelBase } from "@shoothill/core";
import { IsNotEmpty, ValidateIf } from "class-validator";
import { observable, runInAction } from "mobx";
import { AuditDocumentModel } from "../AuditDocument";

export class FirstAidKitModel extends ModelBase<FirstAidKitModel> {
    public id: string | null = null;
    @observable
    @IsNotEmpty({ message: "Please enter a location" })
    public location: string = "";
    @observable
    @IsNotEmpty({ message: "Please select yes or no" })
    public areAllContentsInDate: string = "";
    @observable
    @ValidateIf((o) => o.areAllContentsInDate === "no")
    @IsNotEmpty({ message: "Please enter more detail" })
    public areAllContentsInDateDetail: string = "";
    @observable
    @IsNotEmpty({ message: "Please select yes or no" })
    public allContentsInPlace: string = "";
    @observable
    @ValidateIf((o) => o.allContentsInPlace === "no")
    @IsNotEmpty({ message: "Please enter more detail" })
    public allContentsInPlaceDetail: string = "";
    @observable
    @IsNotEmpty({ message: "Please select yes or no" })
    public allContentsInGoodCondition: string = "";
    @observable
    @ValidateIf((o) => o.allContentsInGoodCondition === "no")
    @IsNotEmpty({ message: "Please enter more detail" })
    public allContentsInGoodConditionDetail: string = "";
    @observable
    @IsNotEmpty({ message: "Please select yes or no" })
    public eyeWash: string = "";
    @observable
    @ValidateIf((o) => o.allContentsInGoodConditionDetail === "no")
    @IsNotEmpty({ message: "Please enter more detail" })
    public eyeWashDetail: string = "";
    @observable
    @IsNotEmpty({ message: "Please select yes or no" })
    public guidanceLeaflet: string = "";
    @observable
    @ValidateIf((o) => o.eyeWashDetail === "no")
    @IsNotEmpty({ message: "Please enter more detail" })
    public guidanceLeafletDetail: string = "";
    @observable
    @IsNotEmpty({ message: "Please select yes or no" })
    public contentsList: string = "";
    @observable
    @ValidateIf((o) => o.contentsList === "no")
    @IsNotEmpty({ message: "Please enter more detail" })
    public contentsListDetail: string = "";
    @observable
    @IsNotEmpty({ message: "Please select yes or no" })
    public mediumSterileDressing: string = "";
    @observable
    @ValidateIf((o) => o.mediumSterileDressing === "no")
    @IsNotEmpty({ message: "Please enter more detail" })
    public mediumSterileDressingDetail: string = "";
    @observable
    @IsNotEmpty({ message: "Please select yes or no" })
    public largeSterileDressing: string = "";
    @observable
    @ValidateIf((o) => o.largeSterileDressing === "no")
    @IsNotEmpty({ message: "Please enter more detail" })
    public largeSterileDressingDetail: string = "";
    @observable
    @IsNotEmpty({ message: "Please select yes or no" })
    public triangularBandage: string = "";
    @observable
    @ValidateIf((o) => o.triangularBandage === "no")
    @IsNotEmpty({ message: "Please enter more detail" })
    public triangularBandageDetail: string = "";
    @observable
    @IsNotEmpty({ message: "Please select yes or no" })
    public safetyPins: string = "";
    @observable
    @ValidateIf((o) => o.safetyPins === "no")
    @IsNotEmpty({ message: "Please enter more detail" })
    public safetyPinsDetail: string = "";
    @observable
    @IsNotEmpty({ message: "Please select yes or no" })
    public sterileEyePads: string = "";
    @observable
    @ValidateIf((o) => o.sterileEyePads === "no")
    @IsNotEmpty({ message: "Please enter more detail" })
    public sterileEyePadsDetail: string = "";
    @observable
    @IsNotEmpty({ message: "Please select yes or no" })
    public waterproofPlasters: string = "";
    @observable
    @ValidateIf((o) => o.waterproofPlasters === "no")
    @IsNotEmpty({ message: "Please enter more detail" })
    public waterproofPlastersDetail: string = "";
    @observable
    @IsNotEmpty({ message: "Please select yes or no" })
    public sterileSalineWipes: string = "";
    @observable
    @ValidateIf((o) => o.sterileSalineWipes === "no")
    @IsNotEmpty({ message: "Please enter more detail" })
    public sterileSalineWipesDetail: string = "";
    @observable
    @IsNotEmpty({ message: "Please select yes or no" })
    public microporousTape: string = "";
    @observable
    @ValidateIf((o) => o.microporousTape === "no")
    @IsNotEmpty({ message: "Please enter more detail" })
    public microporousTapeDetail: string = "";
    @observable
    @IsNotEmpty({ message: "Please select yes or no" })
    public nitrileGloves: string = "";
    @observable
    @ValidateIf((o) => o.nitrileGloves === "no")
    @IsNotEmpty({ message: "Please enter more detail" })
    public nitrileGlovesDetail: string = "";
    @observable
    @IsNotEmpty({ message: "Please select yes or no" })
    public sterileFingerDressing: string = "";

    @observable
    @ValidateIf((o) => o.sterileFingerDressing === "no")
    @IsNotEmpty({ message: "Please enter more detail" })
    public sterileFingerDressingDetail: string = "";
    @observable
    @IsNotEmpty({ message: "Please select yes or no" })
    public resusitationFaceShield: string = "";
    @observable
    @ValidateIf((o) => o.resusitationFaceShield === "no")
    @IsNotEmpty({ message: "Please enter more detail" })
    public resusitationFaceShieldDetail: string = "";
    @observable
    @IsNotEmpty({ message: "Please select yes or no" })
    public foilBlanket: string = "";
    @observable
    @ValidateIf((o) => o.foilBlanket === "no")
    @IsNotEmpty({ message: "Please enter more detail" })
    public foilBlanketDetail: string = "";
    @observable
    @IsNotEmpty({ message: "Please select yes or no" })
    public hydrogelBurnsDressing: string = "";
    @observable
    @ValidateIf((o) => o.hydrogelBurnsDressing === "no")
    @IsNotEmpty({ message: "Please enter more detail" })
    public hydrogelBurnsDressingDetail: string = "";
    @observable
    @IsNotEmpty({ message: "Please select yes or no" })
    public tuffCutShears: string = "";
    @observable
    @ValidateIf((o) => o.tuffCutShears === "no")
    @IsNotEmpty({ message: "Please enter more detail" })
    public tuffCutShearsDetail: string = "";
    @observable
    @IsNotEmpty({ message: "Please select yes or no" })
    public conformingBandages: string = "";
    @observable
    @ValidateIf((o) => o.conformingBandages === "no")
    @IsNotEmpty({ message: "Please enter more detail" })
    public conformingBandagesDetail: string = "";
    @observable
    public auditId: string | null = null;
    @observable
    public auditDocuments: AuditDocumentModel[] = [];
    constructor() {
        super();
    }

    fromDto(model: any): void {
        this.id = model.id;
        this.location = model.location;
        this.areAllContentsInDate = model.areAllContentsInDate ? "yes" : "no";
        this.allContentsInPlace = model.allContentsInPlace ? "yes" : "no";
        this.allContentsInGoodCondition = model.allContentsInGoodCondition ? "yes" : "no";
        this.eyeWash = model.eyeWash ? "yes" : "no";
        this.guidanceLeaflet = model.guidanceLeaflet ? "yes" : "no";
        this.contentsList = model.contentsList ? "yes" : "no";
        this.mediumSterileDressing = model.mediumSterileDressing ? "yes" : "no";
        this.largeSterileDressing = model.largeSterileDressing ? "yes" : "no";
        this.triangularBandage = model.triangularBandage ? "yes" : "no";
        this.safetyPins = model.safetyPins ? "yes" : "no";
        this.sterileEyePads = model.sterileEyePads ? "yes" : "no";
        this.waterproofPlasters = model.waterproofPlasters ? "yes" : "no";
        this.sterileSalineWipes = model.sterileSalineWipes ? "yes" : "no";
        this.microporousTape = model.microporousTape ? "yes" : "no";
        this.nitrileGloves = model.nitrileGloves ? "yes" : "no";
        this.sterileFingerDressing = model.sterileFingerDressing ? "yes" : "no";
        this.resusitationFaceShield = model.resusitationFaceShield ? "yes" : "no";
        this.foilBlanket = model.foilBlanket ? "yes" : "no";
        this.hydrogelBurnsDressing = model.hydrogelBurnsDressing ? "yes" : "no";
        this.tuffCutShears = model.tuffCutShears ? "yes" : "no";
        this.conformingBandages = model.conformingBandages ? "yes" : "no";
        this.auditId = model.auditId;
        if (model.auditDocuments) {
            runInAction(() => {
                this.auditDocuments = model.auditDocuments.map((x: any) => {
                    const auditDocument = new AuditDocumentModel();
                    auditDocument.fromDto(x);
                    return auditDocument;
                });
            });
        }
        this.areAllContentsInDateDetail = model.areAllContentsInDateDetail;
        this.allContentsInPlaceDetail = model.allContentsInPlaceDetail;
        this.allContentsInGoodConditionDetail = model.allContentsInGoodConditionDetail;
        this.eyeWashDetail = model.eyeWashDetail;
        this.guidanceLeafletDetail = model.guidanceLeafletDetail;
        this.contentsListDetail = model.contentsListDetail;
        this.mediumSterileDressingDetail = model.mediumSterileDressingDetail;
        this.largeSterileDressingDetail = model.largeSterileDressingDetail;
        this.triangularBandageDetail = model.triangularBandageDetail;
        this.safetyPinsDetail = model.safetyPinsDetail;
        this.sterileEyePadsDetail = model.sterileEyePadsDetail;
        this.waterproofPlastersDetail = model.waterproofPlastersDetail;
        this.sterileSalineWipesDetail = model.sterileSalineWipesDetail;
        this.microporousTapeDetail = model.microporousTapeDetail;
        this.nitrileGlovesDetail = model.nitrileGlovesDetail;
        this.sterileFingerDressingDetail = model.sterileFingerDressingDetail;
        this.resusitationFaceShieldDetail = model.resusitationFaceShieldDetail;
        this.foilBlanketDetail = model.foilBlanketDetail;
        this.hydrogelBurnsDressingDetail = model.hydrogelBurnsDressingDetail;
        this.tuffCutShearsDetail = model.tuffCutShearsDetail;
        this.conformingBandagesDetail = model.conformingBandagesDetail;
    }

    toDto() {
        return {
            id: this.id,
            location: this.location,
            areAllContentsInDate: this.areAllContentsInDate === "yes" ? true : false,
            allContentsInPlace: this.allContentsInPlace === "yes" ? true : false,
            allContentsInGoodCondition: this.allContentsInGoodCondition === "yes" ? true : false,
            eyeWash: this.eyeWash === "yes" ? true : false,
            guidanceLeaflet: this.guidanceLeaflet === "yes" ? true : false,
            contentsList: this.contentsList === "yes" ? true : false,
            mediumSterileDressing: this.mediumSterileDressing === "yes" ? true : false,
            largeSterileDressing: this.largeSterileDressing === "yes" ? true : false,
            triangularBandage: this.triangularBandage === "yes" ? true : false,
            safetyPins: this.safetyPins === "yes" ? true : false,
            sterileEyePads: this.sterileEyePads === "yes" ? true : false,
            waterproofPlasters: this.waterproofPlasters === "yes" ? true : false,
            sterileSalineWipes: this.sterileSalineWipes === "yes" ? true : false,
            microporousTape: this.microporousTape === "yes" ? true : false,
            nitrileGloves: this.nitrileGloves === "yes" ? true : false,
            sterileFingerDressing: this.sterileFingerDressing === "yes" ? true : false,
            resusitationFaceShield: this.resusitationFaceShield === "yes" ? true : false,
            foilBlanket: this.foilBlanket === "yes" ? true : false,
            hydrogelBurnsDressing: this.hydrogelBurnsDressing === "yes" ? true : false,
            tuffCutShears: this.tuffCutShears === "yes" ? true : false,
            conformingBandages: this.conformingBandages === "yes" ? true : false,
            auditId: this.auditId,
            auditDocuments: this.auditDocuments,
            areallcontentsindateDetail: this.areAllContentsInDateDetail,
            allcontentsinplaceDetail: this.allContentsInPlaceDetail,
            allcontentsingoodconditionDetail: this.allContentsInGoodConditionDetail,
            eyewashDetail: this.eyeWashDetail,
            guidanceleafletDetail: this.guidanceLeafletDetail,
            contentslistDetail: this.contentsListDetail,
            mediumsteriledressingDetail: this.mediumSterileDressingDetail,
            largesteriledressingDetail: this.largeSterileDressingDetail,
            triangularbandageDetail: this.triangularBandageDetail,
            safetypinsDetail: this.safetyPinsDetail,
            sterileeyepadsDetail: this.sterileEyePadsDetail,
            waterproofplastersDetail: this.waterproofPlastersDetail,
            sterilesalinewipesDetail: this.sterileSalineWipesDetail,
            microporoustapeDetail: this.microporousTapeDetail,
            nitrileglovesDetail: this.nitrileGlovesDetail,
            sterilefingerdressingDetail: this.sterileFingerDressingDetail,
            resusitationfaceshieldDetail: this.resusitationFaceShieldDetail,
            foilblanketDetail: this.foilBlanketDetail,
            hydrogelburnsdressingDetail: this.hydrogelBurnsDressingDetail,
            tuffcutshearsDetail: this.tuffCutShearsDetail,
            conformingbandagesDetail: this.conformingBandagesDetail,
        };
    }
}
