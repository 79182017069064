import { FieldType, isEmptyOrWhitespace, ViewModelBase, ApiResult } from "@shoothill/core";
import { action, computed, observable, runInAction } from "mobx";
import { viewModelModelLink } from "../../../../Utils/Utils";

import { AppUrls } from "AppUrls";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import {
    ToolboxTalkFormAndRelatedResponseDTO,
    ToolboxTalkFormDTO,
    ToolboxTalkFormModel,
    ToolboxTalkFormProjectDetailsDTO,
    ToolboxTalkFormRelatedResponseDTO,
    ToolboxTalkUserDTO,
    UpsertToolboxTalkFormAndRelatedRequestDTO,
} from "./ToolboxTalkFormModel";
import moment from "moment";
import { ToolboxTalkDTO } from "./ToolboxTalkModel";
import { InductionListItemDTO, InductionListItemModel } from "./InductionUserListItemModel";
import { InductionUserViewModel } from "./InductionUserViewModel";
import { InductionUserModel } from "./InductionUserModel";
import { InductionUserLookupModel } from "../Inspections/InductionUserLookupModel";
import { ToolboxTalkFileDTO, ToolboxTalkFileModel } from "./ToolboxTalkFileModel";

export class ToolboxTalkFormViewModel extends ViewModelBase<ToolboxTalkFormModel> {
    public inductionUsersViewModels = observable<InductionUserViewModel>([]);
    @observable
    public inductionLookupUsers = observable<InductionUserLookupModel>([]);

    // #region Constructors and Disposers

    constructor(id: string | null, projectId: string | null) {
        super(new ToolboxTalkFormModel());
        this.setDecorators(ToolboxTalkFormViewModel);
        this.model.id = id;
        this.model.projectId = projectId!;

        isEmptyOrWhitespace(this.model.id) ? this.loadRelated() : this.loadWithRelated();

        (window as any).ToolboxTalkFormViewModel = this; //For debugging purposes
    }

    public inductionUserLinkObserverDispose = viewModelModelLink(this.model.inductionUserModels, this.inductionUsersViewModels, InductionUserViewModel);
    // #region Properties

    @observable
    public hasLoaded: boolean = false;

    @computed
    public get getHasLoaded(): boolean {
        return this.hasLoaded;
    }

    @action
    public setHasLoaded = (val: boolean) => {
        this.hasLoaded = val;
    };

    @observable
    public isViewOnly: boolean = false;

    @computed
    public get getIsViewOnly(): boolean {
        return this.isViewOnly;
    }

    @action
    public setIsViewOnly = (val: boolean) => {
        this.isViewOnly = val;
    };

    @computed
    public get isFormDisabled(): boolean {
        return this.isViewOnly || this.model.hasId;
    }

    @computed
    public get canSaveForm(): boolean {
        // return !this.model.hasId ? true : false;
        return true;
    }

    @computed
    public get getTodayDateFormatted(): string {
        return this.model.createdDate ? moment(this.model.createdDate).format("DD/MM/YYYY").toString() : moment().format("DD/MM/YYYY").toString();
    }

    @computed
    public get getProjectReferenceAndNameFormatted(): string {
        if (!this.toolboxTalkProjectDetails) {
            return "";
        }

        return `(${this.toolboxTalkProjectDetails.projectReference} - ${this.toolboxTalkProjectDetails.projectName})`;
    }

    @computed
    public get getProjectReferenceFormatted(): string {
        if (!this.toolboxTalkProjectDetails) {
            return "";
        }

        return this.toolboxTalkProjectDetails.projectReference;
    }

    @computed
    public get getProjectName(): string {
        if (!this.toolboxTalkProjectDetails) {
            return "";
        }

        return this.toolboxTalkProjectDetails.projectName;
    }

    @observable
    public toolboxTalkTalkerUsers = observable<ToolboxTalkUserDTO>([]);

    @observable
    public toolboxTalkInductionUsers = observable<ToolboxTalkUserDTO>([]);

    @observable
    public talkerUser: { id: string; displayName: string } | null = null;

    @observable
    public inductionUser: { id: string; displayName: string } | null = null;

    @action
    public handleSetTalkerUser = (item: { id: string; displayName: string }) => {
        this.talkerUser = item;
        this.model.talkerUserId = item.id;
    };

    @action
    public handleSetInductionUser = (item: { id: string; displayName: string }) => {
        this.inductionUser = item;
        this.model.inductionUserId = item.id;
    };

    public server: ServerViewModel = new ServerViewModel();

    @observable
    public toolboxTalkProjectDetails: ToolboxTalkFormProjectDetailsDTO | null = null;

    @observable
    public toolboxTalk: ToolboxTalkDTO | null = null;

    @action
    public handleCancel(): void {
        const projectId: string | null = this.model.projectId;
        this.reset();

        if (projectId) {
            this.history.push(AppUrls.Client.Project.ConstructionHAndS.replace(":projectid", projectId) + "#toolbox");
        }
    }

    // #endregion Properties

    @computed
    public get getFiles(): ToolboxTalkFileModel[] {
        return this.model.toolboxTalkFiles.filter((d) => !d.isDeleted);
    }

    @computed
    public get getInductionUsers(): InductionListItemModel[] {
        return this.model.inductionUsersList.filter((d) => !d.isDeleted);
    }

    @computed
    public get getInductionUserViewModels(): InductionUserViewModel[] {
        return this.inductionUsersViewModels.filter((d) => !d.model.isDeleted);
    }

    /**
     * Handle a file being selected and process the data for upload.
     * @param event
     */
    @action
    public fileChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        if (event.target.files !== null && event.target.value !== null && event.target.files.length > 0) {
            let data: any = {
                fileName: event.target.files[0].name,
                formFile: event.target.files[0],
            };
            event.target.value = "";
            const apiResult = await this.fileUpload(data);
            if (apiResult && apiResult.wasSuccessful) {
                let fileToDisplay: ToolboxTalkFileDTO = {
                    id: null,
                    fileURL: apiResult.payload,
                    fileName: data.fileName,
                    isDeleted: false,
                    createdByUserId: null,
                    createdDate: null,
                };

                let model: ToolboxTalkFileModel = new ToolboxTalkFileModel();
                model.fromDto(fileToDisplay);
                runInAction(() => this.model.toolboxTalkFiles.push(model));
            }
        }
    };

    /**
     * Upload a file to azure.
     * @param data The data of the file to be uploaded.
     * @returns apiResult.
     */
    public fileUpload = async (data: any): Promise<ApiResult<any>> => {
        const formData = new FormData();
        formData.append("formFile", data.formFile);
        formData.append("fileName", data.fileName);
        const apiResult = await this.Post<any>(AppUrls.Server.File.UploadFile, formData);
        if (apiResult) {
            if (!apiResult.wasSuccessful) {
                console.log(apiResult.errors);
                runInAction(() => {
                    this.setSnackMessage("Error uploading file please try again.");
                    this.setSnackType(this.SNACKERROR);
                    this.setSnackbarState(true);
                });
            }
        }
        return apiResult;
    };

    /**
     * Download a file that exists in azure.
     * @param fileUrl The URL of the file to be downloaded.
     * @param fileName The name of the file to be downloaded.
     */
    public DownloadFile = async (fileUrl: string, fileName: string): Promise<void> => {
        try {
            const apiResult = await this.Post<Blob>(AppUrls.Server.File.DownloadFile, fileUrl, undefined, { responseType: "blob" });
            const response = apiResult as any;
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
        } catch (exception) {
            console.error(exception);
            this.setIsErrored(true);
        }
    };

    // #region Server Actions

    //related, for empty form
    public loadRelated = async (): Promise<void> => {
        this.setIsLoading(true);
        this.model.inductionUserModels.clear();
        return await this.server
            .query<ToolboxTalkFormRelatedResponseDTO>(
                () => this.Get(`${AppUrls.Server.Projects.Construction.ToolboxTalk.Load.Related}\\${this.model.projectId}`),
                (result) => {
                    runInAction(() => {
                        this.toolboxTalk = result.toolboxTalk;
                        this.toolboxTalkProjectDetails = result.toolboxTalkProjectDetails;
                        this.toolboxTalkTalkerUsers.replace(result.talkerUsers);
                        //this.inductionLookupUsers.replace(result.inductionUsers);
                        this.inductionLookupUsers.clear();
                        for (const inductionUser of result.inductionUsers) {
                            const model = new InductionUserLookupModel();
                            model.fromDto(inductionUser);
                            this.inductionLookupUsers.push(model);
                        }
                        this.model.inductionUserModels.push(new InductionUserModel());
                    });
                },
            )
            .finally(() => {
                this.setIsLoading(false);
                this.setHasLoaded(true);
            });
    };

    /**
     * Load an existing Permit with any related data.
     */
    public loadWithRelated = async (): Promise<void> => {
        this.setIsLoading(true);
        return await this.server
            .query<ToolboxTalkFormAndRelatedResponseDTO>(
                () => this.Get(`${AppUrls.Server.Projects.Construction.ToolboxTalk.Load.WithRelatedById}\\${this.model.id}`),
                (result: ToolboxTalkFormAndRelatedResponseDTO) => {
                    runInAction(() => {
                        this.handleSetLoadWithRelated(result);
                        this.model.toolboxTalkFiles.length = 0;
                        this.model.inductionUsersList.length = 0;
                        for (const file of result.toolboxTalkFiles) {
                            let model = new ToolboxTalkFileModel();
                            model.fromDto(file);
                            this.model.toolboxTalkFiles.push(model);
                        }
                        for (const inductionUser of result.inductionUsersList) {
                            let model = new InductionUserModel();
                            model.fromDto(inductionUser);
                            this.model.inductionUsersList.push(model);
                            const userToAssign = this.inductionLookupUsers.find((i: any) => i.id === model.inductionUserId);
                            if (userToAssign) {
                                model.inductionUser = userToAssign;
                                model.inductionUserId = userToAssign.id;
                            }
                            this.model.inductionUserModels.push(model);
                        }
                    });
                },
            )
            .finally(() => {
                this.setIsLoading(false);
                this.setHasLoaded(true);
                this.setIsViewOnly(false);
            });
    };

    private handleSetLoadWithRelated = (result: ToolboxTalkFormAndRelatedResponseDTO) => {
        this.model.fromDto(result);
        this.toolboxTalk = result.toolboxTalk;
        this.toolboxTalkProjectDetails = result.toolboxTalkProjectDetails;
        this.toolboxTalkTalkerUsers.replace(result.talkerUsers);
        this.inductionLookupUsers.replace(InductionUserLookupModel.fromDtos(result.inductionUsers));
        if (this.model.talkerUserId) {
            const userToAssign = this.toolboxTalkTalkerUsers.find((i) => i.id === this.model.talkerUserId);
            if (userToAssign) {
                this.handleSetTalkerUser(userToAssign);
            }
        }
    };

    public upsert = async (): Promise<void> => {
        this.setIsLoading(true);

        const model: ToolboxTalkFormDTO = this.model.toDto();
        const files: ToolboxTalkFileDTO[] = this.model.toolboxTalkFiles.map((p) => p.toDto());
        const inductionUsersList: InductionListItemDTO[] = this.model.inductionUserModels.map((p) => p.toDto());
        let toolboxTalkId: string = "";
        if (this.toolboxTalk) {
            toolboxTalkId = this.toolboxTalk.id ? this.toolboxTalk.id : "";
        }
        model.projectId = this.model.projectId;

        const request: UpsertToolboxTalkFormAndRelatedRequestDTO = {
            toolboxTalk: model,
            toolboxTalkFiles: files,
            toolboxTalkSignatures: inductionUsersList,
        };

        return await this.server
            .command<ToolboxTalkFormAndRelatedResponseDTO>(
                () => this.Post(AppUrls.Server.Projects.Construction.ToolboxTalk.Upsert, request),
                (result: ToolboxTalkFormAndRelatedResponseDTO) => {
                    runInAction(() => {
                        const projectId = this.model.projectId;
                        this.reset();
                        this.history.push(AppUrls.Client.Project.ConstructionHAndS.replace(":projectid", projectId!) + "#toolbox");
                    });
                },
                this.isMyModelValid,
                "There was an error trying to send the Toolbox Talk form",
            )
            .finally(() => this.setIsLoading(false));
    };

    /**
     * Custom model validation function. Validates child models and its children
     * @returns True if model is valid, false if not.
     */
    private isMyModelValid = async (): Promise<boolean> => {
        let isValid = true;

        if ((await this.isModelValid()) === false) {
            isValid = false;
        }

        return isValid;
    };

    @action
    public reset = () => {
        this.model.reset();
        this.server.reset();
        this.toolboxTalk = null;
    };

    @action
    public addAnotherInductionUser = () => {
        this.model.inductionUserModels.push(new InductionUserModel());
    };

    // #endregion Client Actions

    // #region Boilerplate

    // #region Vaildation

    public async isFieldValid(fieldName: keyof FieldType<ToolboxTalkFormModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        if (this.server.IsSubmitted) {
            switch (fieldName) {
                case "subject": {
                    const result = this.validateSubject;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "pointsDiscussed": {
                    const result = this.validatePointsDiscussed;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "comments": {
                    const result = this.validateComments;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "talkerUserId": {
                    const result = this.validateTalkerUserId;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
            }
        } else {
            // Do not validate if the properties of the model have not been
            // submitted to the server.
            errorMessage = "";
            isValid = true;
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    @computed
    private get validateSubject() {
        const errorMessage = this.model.validateSubject;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validatePointsDiscussed() {
        const errorMessage = this.model.validatePointsDiscussed;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateComments() {
        const errorMessage = this.model.validateComments;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateTalkerUserId() {
        const errorMessage = this.model.validateTalkerUserId;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    // #endregion

    // #region Snackbar

    @observable
    public snackbarState = false;

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public snackMessage = "";

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @observable
    public snackType = "";

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    @observable
    public SNACKSUCCESS = "success";

    @observable
    public SNACKERROR = "error";
    // #endregion

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}

enum PermitDocumentTypeEnum {
    Internal = 10,
    Client = 20,
}
