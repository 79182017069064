import { action, observable, computed } from "mobx";
import { ModelBase } from "@shoothill/core";
import moment from "moment";

export class AdminSiteAccessFilterModel extends ModelBase<AdminSiteAccessFilterModel, AdminSiteAccessFilterModelDTO> {
    @observable
    public includeDeleted: boolean = false;

    @observable
    public searchText: string = "";

    @observable
    public startDateFilter: Date | null = null;

    @observable
    public endDateFilter: Date | null = null;

    @observable
    public showOnSiteOnly: boolean = false;

    @computed
    public get getStartDateFilter() {
        return this.startDateFilter !== null ? moment(this.startDateFilter).toISOString() : null;
    }

    @computed
    public get getEndDateFilter() {
        return this.endDateFilter !== null ? moment(this.endDateFilter).toISOString() : null;
    }

    @action validateFilters = () => {
        let start: moment.Moment | null = null;
        let end: moment.Moment | null = null;

        if (this.startDateFilter !== null && this.startDateFilter !== undefined) {
            start = moment.utc(this.startDateFilter);
        }
        if (this.endDateFilter !== null && this.endDateFilter !== undefined) {
            end = moment.utc(this.endDateFilter);
        }

        if (start !== null && end !== null) {
            if (start < end) {
                return true;
            }
        } else {
            return true;
        }

        return false;
    };

    fromDto(model: AdminSiteAccessFilterModelDTO): void {
        this.searchText = model.searchText;
    }

    toDto(): AdminSiteAccessFilterModelDTO {
        let dto: AdminSiteAccessFilterModelDTO = {
            includeDeleted: this.includeDeleted,
            searchText: this.searchText,
            startDateFilter: this.startDateFilter,
            endDateFilter: this.endDateFilter,
            showOnSiteOnly: this.showOnSiteOnly,
            id: "",
        };
        return dto;
    }

    @action
    public reset = () => {
        this.searchText = "";
        this.startDateFilter = null;
        this.endDateFilter = null;
        this.showOnSiteOnly = false;
    };
}

export interface AdminSiteAccessFilterModelDTO {
    includeDeleted: boolean;
    searchText: string;
    startDateFilter: Date | null;
    endDateFilter: Date | null;
    showOnSiteOnly: boolean;
    id: string;
}
