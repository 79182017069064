import * as MobX from "mobx";
import { ModelBase, observable } from "@shoothill/core";
import { IsNotEmpty, ValidateIf } from "class-validator";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class RAMSChoiceModel extends ModelBase<RAMSChoiceModel, RAMSChoiceModelDTO> {
    @observable
    public firstName: string = "";

    // "has" fields need to be booleans when upserted

    @observable
    public id: string | null = null;

    @observable
    public rAMSId: string | null = null;

    @observable
    @IsNotEmpty({ message: "Please select pass or fail" })
    public hasPassedScopeOfWorks: string = "";

    @observable
    @ValidateIf((o) => o.hasPassedScopeOfWorks === "fail")
    @IsNotEmpty({ message: "Please enter more detail" })
    public reasonScopeOfWorks: string = "";

    @observable
    @IsNotEmpty({ message: "Please select pass or fail" })
    public hasPassedWhoPreparedDocument: string = "";

    @observable
    @ValidateIf((o) => o.hasPassedWhoPreparedDocument === "fail")
    @IsNotEmpty({ message: "Please enter more detail" })
    public reasonWhoPreparedDocument: string = "";

    @observable
    @IsNotEmpty({ message: "Please select pass or fail" })
    public hasPassedMethodStatement: string = "";

    @observable
    @ValidateIf((o) => o.hasPassedMethodStatement === "fail")
    @IsNotEmpty({ message: "Please enter more detail" })
    public reasonMethodStatement: string = "";

    @observable
    @IsNotEmpty({ message: "Please select pass or fail" })
    public hasPassedYoungPersonsRA: string = "";

    @observable
    @ValidateIf((o) => o.hasPassedYoungPersonsRA === "fail")
    @IsNotEmpty({ message: "Please enter more detail" })
    public reasonYoungPersonsRA: string = "";

    @observable
    @IsNotEmpty({ message: "Please select pass or fail" })
    public hasPassedRequiredPPE: string = "";

    @observable
    @ValidateIf((o) => o.hasPassedRequiredPPE === "fail")
    @IsNotEmpty({ message: "Please enter more detail" })
    public reasonRequiredPPE: string = "";

    @observable
    @IsNotEmpty({ message: "Please select pass or fail" })
    public hasPassedLocationOfWorks: string = "";

    @observable
    @ValidateIf((o) => o.hasPassedLocationOfWorks === "fail")
    @IsNotEmpty({ message: "Please enter more detail" })
    public reasonLocationOfWorks: string = "";

    @observable
    @IsNotEmpty({ message: "Please select pass or fail" })
    public hasPassedSequenceOfWorks: string = "";

    @observable
    @ValidateIf((o) => o.hasPassedSequenceOfWorks === "fail")
    @IsNotEmpty({ message: "Please enter more detail" })
    public reasonSequenceOfWorks: string = "";

    @observable
    @IsNotEmpty({ message: "Please select pass or fail" })
    public hasPassedProgrammeOfWorks: string = "";

    @observable
    @ValidateIf((o) => o.hasPassedProgrammeOfWorks === "fail")
    @IsNotEmpty({ message: "Please enter more detail" })
    public reasonProgrammeOfWorks: string = "";

    @observable
    @IsNotEmpty({ message: "Please select pass or fail" })
    public hasPassedSitePersonnel: string = "";

    @observable
    @ValidateIf((o) => o.hasPassedSitePersonnel === "fail")
    @IsNotEmpty({ message: "Please enter more detail" })
    public reasonSitePersonnel: string = "";

    @observable
    @IsNotEmpty({ message: "Please select pass or fail" })
    public hasPassedMaterialsInvolved: string = "";

    @observable
    @ValidateIf((o) => o.hasPassedMaterialsInvolved === "fail")
    @IsNotEmpty({ message: "Please enter more detail" })
    public reasonMaterialsInvolved: string = "";

    @observable
    @IsNotEmpty({ message: "Please select pass or fail" })
    public hasPassedHAVsAssessments: string = "";

    @observable
    @ValidateIf((o) => o.hasPassedHAVsAssessments === "fail")
    @IsNotEmpty({ message: "Please enter more detail" })
    public reasonHAVsAssessments: string = "";

    @observable
    @IsNotEmpty({ message: "Please select pass or fail" })
    public hasPassedCOSHHAssessment: string = "";

    @observable
    @ValidateIf((o) => o.hasPassedCOSHHAssessment === "fail")
    @IsNotEmpty({ message: "Please enter more detail" })
    public reasonCOSHHAssessment: string = "";

    @observable
    @IsNotEmpty({ message: "Please select pass or fail" })
    public hasPassedRiskAssessment: string = "";

    @observable
    @ValidateIf((o) => o.hasPassedRiskAssessment === "fail")
    @IsNotEmpty({ message: "Please enter more detail" })
    public reasonRiskAssessment: string = "";

    @observable
    @IsNotEmpty({ message: "Please select pass or fail" })
    public hasPassedManualHandlingAssessment: string = "";

    @observable
    @ValidateIf((o) => o.hasPassedManualHandlingAssessment === "fail")
    @IsNotEmpty({ message: "Please enter more detail" })
    public reasonManualHandlingAssessment: string = "";

    @observable
    @IsNotEmpty({ message: "Please select pass or fail" })
    public hasPassedPlantAndEquipmentRequired: string = "";

    @observable
    @ValidateIf((o) => o.hasPassedPlantAndEquipmentRequired === "fail")
    @IsNotEmpty({ message: "Please enter more detail" })
    public reasonPlantAndEquipmentRequired: string = "";

    @observable
    @IsNotEmpty({ message: "Please select pass or fail" })
    public hasPassedWorkingHours: string = "";

    @observable
    @ValidateIf((o) => o.hasPassedWorkingHours === "fail")
    @IsNotEmpty({ message: "Please enter more detail" })
    public reasonWorkingHours: string = "";

    @observable
    @IsNotEmpty({ message: "Please select pass or fail" })
    public hasPassedEmergencyRescuePlan: string = "";

    @observable
    @ValidateIf((o) => o.hasPassedEmergencyRescuePlan === "fail")
    @IsNotEmpty({ message: "Please enter more detail" })
    public reasonEmergencyRescuePlan: string = "";

    @observable
    @IsNotEmpty({ message: "Please select pass or fail" })
    public hasPassedShoringPlan: string = "";

    @observable
    @ValidateIf((o) => o.hasPassedShoringPlan === "fail")
    @IsNotEmpty({ message: "Please enter more detail" })
    public reasonShoringPlan: string = "";

    @observable
    @IsNotEmpty({ message: "Please select approved or declined" })
    public isApprovedRAMSRecommended: string = "";

    @observable
    @ValidateIf((o) => o.isApprovedRAMSRecommended === "fail")
    @IsNotEmpty({ message: "Please enter more detail" })
    public reasonApprovedRAMSRecommended: string = "";

    reset() {}

    fromDto(model: RAMSChoiceModelDTO): void {
        this.id = model.id;
        this.rAMSId = model.rAMSId;
        this.hasPassedScopeOfWorks = model.hasPassedScopeOfWorks === true ? "pass" : "fail";
        this.reasonScopeOfWorks = model.reasonScopeOfWorks;
        this.hasPassedWhoPreparedDocument = model.hasPassedWhoPreparedDocument === true ? "pass" : "fail";
        this.reasonWhoPreparedDocument = model.reasonWhoPreparedDocument;
        this.hasPassedMethodStatement = model.hasPassedMethodStatement === true ? "pass" : "fail";
        this.reasonMethodStatement = model.reasonMethodStatement;
        this.hasPassedYoungPersonsRA = model.hasPassedYoungPersonsRA === true ? "pass" : "fail";
        this.reasonYoungPersonsRA = model.reasonYoungPersonsRA;
        this.hasPassedRequiredPPE = model.hasPassedRequiredPPE === true ? "pass" : "fail";
        this.reasonRequiredPPE = model.reasonRequiredPPE;
        this.hasPassedLocationOfWorks = model.hasPassedLocationOfWorks === true ? "pass" : "fail";
        this.reasonLocationOfWorks = model.reasonLocationOfWorks;
        this.hasPassedSequenceOfWorks = model.hasPassedSequenceOfWorks === true ? "pass" : "fail";
        this.reasonSequenceOfWorks = model.reasonSequenceOfWorks;
        this.hasPassedProgrammeOfWorks = model.hasPassedProgrammeOfWorks === true ? "pass" : "fail";
        this.reasonProgrammeOfWorks = model.reasonProgrammeOfWorks;
        this.hasPassedSitePersonnel = model.hasPassedSitePersonnel === true ? "pass" : "fail";
        this.reasonSitePersonnel = model.reasonSitePersonnel;
        this.hasPassedMaterialsInvolved = model.hasPassedMaterialsInvolved === true ? "pass" : "fail";
        this.reasonMaterialsInvolved = model.reasonMaterialsInvolved;
        this.hasPassedHAVsAssessments = model.hasPassedHAVsAssessments === true ? "pass" : "fail";
        this.reasonHAVsAssessments = model.reasonHAVsAssessments;
        this.hasPassedCOSHHAssessment = model.hasPassedCOSHHAssessment === true ? "pass" : "fail";
        this.reasonCOSHHAssessment = model.reasonCOSHHAssessment;
        this.hasPassedRiskAssessment = model.hasPassedRiskAssessment === true ? "pass" : "fail";
        this.reasonRiskAssessment = model.reasonRiskAssessment;
        this.hasPassedManualHandlingAssessment = model.hasPassedManualHandlingAssessment === true ? "pass" : "fail";
        this.reasonManualHandlingAssessment = model.reasonManualHandlingAssessment;
        this.hasPassedPlantAndEquipmentRequired = model.hasPassedPlantAndEquipmentRequired === true ? "pass" : "fail";
        this.reasonPlantAndEquipmentRequired = model.reasonPlantAndEquipmentRequired;
        this.hasPassedWorkingHours = model.hasPassedWorkingHours === true ? "pass" : "fail";
        this.reasonWorkingHours = model.reasonWorkingHours;
        this.hasPassedEmergencyRescuePlan = model.hasPassedEmergencyRescuePlan === true ? "pass" : "fail";
        this.reasonEmergencyRescuePlan = model.reasonEmergencyRescuePlan;
        this.hasPassedShoringPlan = model.hasPassedShoringPlan === true ? "pass" : "fail";
        this.reasonShoringPlan = model.reasonShoringPlan;
        this.isApprovedRAMSRecommended = model.isApprovedRAMSRecommended === true ? "pass" : "fail";
        this.reasonApprovedRAMSRecommended = model.reasonApprovedRAMSRecommended;
    }

    toDto() {
        return {
            id: this.id,
            rAMSId: this.rAMSId,
            hasPassedScopeOfWorks: this.hasPassedScopeOfWorks === "pass" ? true : false,
            reasonScopeOfWorks: this.reasonScopeOfWorks,
            hasPassedWhoPreparedDocument: this.hasPassedWhoPreparedDocument === "pass" ? true : false,
            reasonWhoPreparedDocument: this.reasonWhoPreparedDocument,
            hasPassedMethodStatement: this.hasPassedMethodStatement === "pass" ? true : false,
            reasonMethodStatement: this.reasonMethodStatement,
            hasPassedYoungPersonsRA: this.hasPassedYoungPersonsRA === "pass" ? true : false,
            reasonYoungPersonsRA: this.reasonYoungPersonsRA,
            hasPassedRequiredPPE: this.hasPassedRequiredPPE === "pass" ? true : false,
            reasonRequiredPPE: this.reasonRequiredPPE,
            hasPassedLocationOfWorks: this.hasPassedLocationOfWorks === "pass" ? true : false,
            reasonLocationOfWorks: this.reasonLocationOfWorks,
            hasPassedSequenceOfWorks: this.hasPassedSequenceOfWorks === "pass" ? true : false,
            reasonSequenceOfWorks: this.reasonSequenceOfWorks,
            hasPassedProgrammeOfWorks: this.hasPassedProgrammeOfWorks === "pass" ? true : false,
            reasonProgrammeOfWorks: this.reasonProgrammeOfWorks,
            hasPassedSitePersonnel: this.hasPassedSitePersonnel === "pass" ? true : false,
            reasonSitePersonnel: this.reasonSitePersonnel,
            hasPassedMaterialsInvolved: this.hasPassedMaterialsInvolved === "pass" ? true : false,
            reasonMaterialsInvolved: this.reasonMaterialsInvolved,
            hasPassedHAVsAssessments: this.hasPassedHAVsAssessments === "pass" ? true : false,
            reasonHAVsAssessments: this.reasonHAVsAssessments,
            hasPassedCOSHHAssessment: this.hasPassedCOSHHAssessment === "pass" ? true : false,
            reasonCOSHHAssessment: this.reasonCOSHHAssessment,
            hasPassedRiskAssessment: this.hasPassedRiskAssessment === "pass" ? true : false,
            reasonRiskAssessment: this.reasonRiskAssessment,
            hasPassedManualHandlingAssessment: this.hasPassedManualHandlingAssessment === "pass" ? true : false,
            reasonManualHandlingAssessment: this.reasonManualHandlingAssessment,
            hasPassedPlantAndEquipmentRequired: this.hasPassedPlantAndEquipmentRequired === "pass" ? true : false,
            reasonPlantAndEquipmentRequired: this.reasonPlantAndEquipmentRequired,
            hasPassedWorkingHours: this.hasPassedWorkingHours === "pass" ? true : false,
            reasonWorkingHours: this.reasonWorkingHours,
            hasPassedEmergencyRescuePlan: this.hasPassedEmergencyRescuePlan === "pass" ? true : false,
            reasonEmergencyRescuePlan: this.reasonEmergencyRescuePlan,
            hasPassedShoringPlan: this.hasPassedShoringPlan === "pass" ? true : false,
            reasonShoringPlan: this.reasonShoringPlan,
            isApprovedRAMSRecommended: this.isApprovedRAMSRecommended === "pass" ? true : false,
            reasonApprovedRAMSRecommended: this.reasonApprovedRAMSRecommended,
        };
    }
}

export interface RAMSChoiceModelDTO {
    id: string | null;
    rAMSId: string | null;
    hasPassedScopeOfWorks: boolean;
    reasonScopeOfWorks: string;
    hasPassedWhoPreparedDocument: boolean;
    reasonWhoPreparedDocument: string;
    hasPassedMethodStatement: boolean;
    reasonMethodStatement: string;
    hasPassedYoungPersonsRA: boolean;
    reasonYoungPersonsRA: string;
    hasPassedRequiredPPE: boolean;
    reasonRequiredPPE: string;
    hasPassedLocationOfWorks: boolean;
    reasonLocationOfWorks: string;
    hasPassedSequenceOfWorks: boolean;
    reasonSequenceOfWorks: string;
    hasPassedProgrammeOfWorks: boolean;
    reasonProgrammeOfWorks: string;
    hasPassedSitePersonnel: boolean;
    reasonSitePersonnel: string;
    hasPassedMaterialsInvolved: boolean;
    reasonMaterialsInvolved: string;
    hasPassedHAVsAssessments: boolean;
    reasonHAVsAssessments: string;
    hasPassedCOSHHAssessment: boolean;
    reasonCOSHHAssessment: string;
    hasPassedRiskAssessment: boolean;
    reasonRiskAssessment: string;
    hasPassedManualHandlingAssessment: boolean;
    reasonManualHandlingAssessment: string;
    hasPassedPlantAndEquipmentRequired: boolean;
    reasonPlantAndEquipmentRequired: string;
    hasPassedWorkingHours: boolean;
    reasonWorkingHours: string;
    hasPassedEmergencyRescuePlan: boolean;
    reasonEmergencyRescuePlan: string;
    hasPassedShoringPlan: boolean;
    reasonShoringPlan: string;
    isApprovedRAMSRecommended: boolean;
    reasonApprovedRAMSRecommended: string;
}
