import { computed, observable } from "mobx";
import { FieldType, ViewModelBase } from "@shoothill/core";
import moment from "moment";
import { RDDItemModel } from "./RDDItemModel";

export class RDDItemViewModel extends ViewModelBase<RDDItemModel> {
    constructor(item: RDDItemModel) {
        super(item);
    }

    @observable
    public status: { id: string; displayName: string } | null = null;

    @computed
    public get id() {
        return this.model.id;
    }

    @computed
    public get supplierReference() {
        return this.model.supplierReference;
    }

    @computed
    public get clientReference() {
        return this.model.clientReference;
    }

    @computed
    public get supplierName() {
        return this.model.supplierName;
    }

    @computed
    public get numberOfItems() {
        return this.model.numberOfItems;
    }

    @computed
    public get requestedDateFormatted() {
        return this.model.requestedDate ? `${moment(this.model.requestedDate).format("DD/MM/YYYY")}` : "";
        //return this.model.requestedDate ? `${moment(this.model.requestedDate).format("DD/MM/YYYY")} @ ${moment(this.model.requestedDate).format("HH:mm")}` : "";
    }

    @computed
    public get requestedByName() {
        return this.model.requestedByName;
    }

    @computed
    public get discipline() {
        return this.model.discipline;
    }

    @computed
    public get requestedTo() {
        return this.model.requestedTo;
    }

    @computed
    public get responseRequiredByDateFormatted() {
        return this.model.responseRequiredByDate ? `${moment(this.model.responseRequiredByDate).format("DD/MM/YYYY")}` : "";
    }

    @computed
    public get isLate() {
        return this.model.responseRequiredByDate && moment(this.model.responseRequiredByDate).isBefore(moment.utc());
    }

    @computed
    public get numberOfFiles() {
        return this.model.numberOfFiles;
    }

    @computed
    public get priorityName() {
        return this.model.priorityName;
    }

    @computed
    public get statusName() {
        return this.model.statusName;
    }

    @computed
    public get statusId() {
        return this.model.designDataStatusId;
    }

    @computed
    public get latestResponseName() {
        return this.model.latestResponseName;
    }

    @computed
    public get clientReferenceFormatted() {
        if (this.model.clientReference === null) {
            return "";
        } else if (this.model.clientReference === "0" || (this.model.clientReference === null && this.model.revision === null)) {
            return "";
        } else {
            if (this.model.revision === 0 || this.model.revision === null) {
                return "CL" + this.model.clientReference;
            } else {
                return "CL" + this.model.clientReference + "." + this.model.revision.toString();
            }
        }
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public async isFieldValid(fieldName: keyof FieldType<RDDItemModel>): Promise<boolean> {
        return true;
    }
}
