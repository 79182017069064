import { TextFieldProps } from "@material-ui/core";
import { EditableInputProps, FieldType, IModel, styled } from "@shoothill/core";
import { pxToRem } from "Globals/Styles/AppTheme";
import { DarwinInput, DarwinTextField } from "Globals/Styles/Control/DarwinInput";
import { useObserver } from "mobx-react-lite";
import { getPath } from "ts-object-path";

type TModel<T> = IModel<T> & any;

// Just inherit the props from EditableInput.
interface Props<T> extends EditableInputProps<T> {
    /**
     * False if you want to hide the label and remove the spacing. True if you want to hide the label and keep the spacing.
     * Based on the hiddenLabel TextField prop.
     */
    hiddenLabel?: boolean;
}

/**
 * A wrapper for the DarwinInput that handles a disabled input in a better way.
 *
 * Done this way because all EditableInput returns is the value as a label if set to disabled, and this was easier than creating my own input.
 */
export function DarwinInputWrapper<T>(props: Props<T>) {
    let fieldName: keyof FieldType<T> = props.fieldName as any;

    // Process the fieldName because of typescript errors.
    if (typeof props.fieldName !== "string") {
        let p = getPath(fieldName as string);
        fieldName = p.join(".") as any;
    }

    /**
     * Return the standard DarwinInput if the input is not disabled.
     * @returns DarwinInput (EditableInput)
     */
    const renderEditableInput = () => {
        return <DarwinInput<T> {...props} />;
    };

    /**
     * Return a disabled TextField styled in the same way as the DarwinInput.
     * @returns DarwinTextField (TextField)
     */
    const renderDisabledInput = () => {
        return <DarwinTextField variant="standard" label={props.label} InputLabelProps={{ shrink: true }} value={props.viewModel.getValue(fieldName)} disabled />;
    };

    /**
     * Return a disabled input styled in the same way as the DarwinInput, but without a label.
     * Done this way because if you leave the label as blank, it automatically removes the spacing. the hiddenLabel prop doesn't seem to work as expected.
     * @returns DarwinTextFieldWithoutLabel (TextField)
     */
    const renderDisabledInputWithoutLabel = () => {
        return (
            <DarwinTextFieldWithoutLabel
                variant="standard"
                label={"Label"} // If you remove the label, it automatically removes the spacing... So use hiddenLabel prop and DarwinTextFieldWithoutLabel styled component to control this.
                hiddenLabel={props.hiddenLabel ? props.hiddenLabel : false}
                InputLabelProps={{ shrink: true }}
                InputProps={props.InputProps}
                inputProps={props.inputProps}
                value={props.viewModel.getValue(fieldName)}
                disabled
            />
        );
    };

    const renderInput = () => {
        if (props.editMode) {
            return renderEditableInput();
        } else {
            if (props.label && props.label !== "") {
                return renderDisabledInput();
            } else {
                return renderDisabledInputWithoutLabel();
            }
        }
    };

    return useObserver(() => renderInput());
}

// Different styled depending if you want the spacing from the label there or not.
// Used when one form control doesn't have a label but the others do, so this keeps the same spacing.
export const DarwinTextFieldWithoutLabel: any = styled(DarwinTextField)<TextFieldProps>`
    ${(props) =>
        props.hiddenLabel === false &&
        `
        label {
            display: none;
        }
        > div {
            margin-top: 0 !important;
        }
    `}

    ${(props) =>
        props.hiddenLabel === true &&
        `
        label {
            visibility: hidden;
            opacity: 0;
        }
    `}
`;
