import { ModelBase } from "@shoothill/core";
import { Equals, IsNotEmpty, Min, ValidateIf } from "class-validator";
import { action, computed, observable } from "mobx";

import { IRAMSResponse, RamsItemModel } from "./RamsItemModel";

export class RamsModel extends ModelBase<RamsModel> {
    // #region Defaults

    public static readonly DEFAULT_VISITORTYPENAME = "";
    public static readonly DEFAULT_SITEVIDEOLINK = null;
    public static readonly DEFAULT_SITEVIDEOFINISHED = false;
    public static readonly DEFAULT_DISTANCE = 0;
    public static readonly DEFAULT_QUESTION1 = "";
    public static readonly DEFAULT_QUESTION2 = "";
    public static readonly DEFAULT_QUESTION3 = "";
    public static readonly DEFAULT_QUESTION4 = "";
    public static readonly DEFAULT_RAMSID = "";
    public static readonly DEFAULT_RAMSCONFIRMED = "";
    public static readonly DEFAULT_RAMSS = [];

    // #endregion Defaults

    // #region Observables

    /**
     * The visitor type name is used to determine which RAMS
     * questions the user needs to answer to successfully sign in.
     */
    public visitorTypeName: string = RamsModel.DEFAULT_VISITORTYPENAME;

    /**
     * The site video link points to a video to be played before the
     * user is allowed to answer the RAMS questions.
     */
    @observable
    public siteVideoLink: string | null = RamsModel.DEFAULT_SITEVIDEOLINK;

    /**
     * States if the site video has finished playing.
     */
    @observable
    public siteVideoFinished: boolean = RamsModel.DEFAULT_SITEVIDEOFINISHED;

    @observable
    @ValidateIf((m) => m.distanceRequired)
    @IsNotEmpty({ message: "You must enter the distance travelled" })
    @Min(1, { message: "You must enter a distance greater than 0" })
    public distanceTravelled: number = RamsModel.DEFAULT_DISTANCE;

    @observable
    @ValidateIf((m) => m.q1Required)
    @Equals("true", { message: "You must select Yes to continue." })
    public q1: string = RamsModel.DEFAULT_QUESTION1;

    @observable
    @ValidateIf((m) => m.q2Required)
    @Equals("true", { message: "You must select Yes to continue." })
    public q2: string = RamsModel.DEFAULT_QUESTION2;

    @observable
    @ValidateIf((m) => m.q3Required)
    @Equals("true", { message: "You must select Yes to continue." })
    public q3: string = RamsModel.DEFAULT_QUESTION3;

    @observable
    @ValidateIf((m) => m.q4Required)
    @Equals("true", { message: "You must select Yes to continue." })
    public q4: string = RamsModel.DEFAULT_QUESTION4;

    @observable
    @ValidateIf((m) => m.q5RamsRequired)
    @IsNotEmpty({ message: "You must select a RAMS to continue." })
    public ramsId: string = RamsModel.DEFAULT_RAMSID;

    @observable
    @ValidateIf((m) => m.q5RamsRequired)
    @Equals("true", { message: "You must confirm you have reviewed the RAMS." })
    public ramsReviewConfirmed: string = RamsModel.DEFAULT_RAMSCONFIRMED;

    @observable
    public ramSs = observable<RamsItemModel>(RamsModel.DEFAULT_RAMSS);

    // #endregion Observables

    // #region

    @computed
    public get isVistorTypeVisitor() {
        switch (this.visitorTypeName) {
            case "Client Visitor":
            case "DGL Visitor":
                return true;

            default:
                return false;
        }
    }

    @computed
    public get distanceRequired() {
        return true;
    }

    @computed
    public get q1Required() {
        return !this.isVistorTypeVisitor;
    }

    @computed
    public get q2Required() {
        return !this.isVistorTypeVisitor;
    }

    @computed
    public get q3Required() {
        return !this.isVistorTypeVisitor;
    }

    @computed
    public get q4Required() {
        return true;
    }

    @computed
    public get q5RamsRequired() {
        return !this.isVistorTypeVisitor;
    }

    // #endregion

    // #region Actions

    @action
    public fromDto(dto: IInductionRAMSResponse): void {
        this.siteVideoLink = dto.siteVideoLink;

        for (const ramsDto of dto.ramSs) {
            const model = new RamsItemModel();

            model.fromDto(ramsDto);

            this.ramSs.push(model);
        }
    }

    public toDto(): void {
        throw new Error("Method not implemented.");
    }

    // #endregion Actions
}

export interface IInductionRAMSResponse {
    ramSs: IRAMSResponse[];
    siteVideoLink: string | null;
}
