import { ModelBase } from "@shoothill/core";
import { action, observable, computed } from "mobx";
import { DilapidationFormItemPhotoListItemDTO, DilapidationFormItemPhotoListItemModel } from "./DilapidationFormItemPhotoListItemModel";

export class DilapidationFormItemModel extends ModelBase<DilapidationFormItemModel> {
    public static readonly DEFAULT_ID = "";
    public static readonly DEFAULT_DILAPIDATION_REPORT_ID = "";
    public static readonly DEFAULT_INSPECTED_ITEM = "";
    public static readonly DEFAULT_COMMENTS = "";
    public static readonly DEFAULT_LAST_UPDATED_DATE = null;
    public static readonly DEFAULT_CREATED_BY_USER_ID = null;
    public static readonly DEFAULT_LAST_UPDATED_BY_USER_ID = null;
    public static readonly DEFAULT_COMPLETED_DATE = null;
    public static readonly DEFAULT_COMPLETED_BY_USER_ID = null;
    public static readonly DEFAULT_IS_DELETED = false;
    public static readonly DEFAULT_ROW_VERSION = null;
    public static readonly DEFAULT_DILAPIDATION_REPORT_ITEM_PHOTOS = [];

    @observable
    public id: string | null = DilapidationFormItemModel.DEFAULT_ID;
    @observable
    public dilapidationReportId: string = DilapidationFormItemModel.DEFAULT_DILAPIDATION_REPORT_ID;
    @observable
    public inspectedItem: string = DilapidationFormItemModel.DEFAULT_INSPECTED_ITEM;
    @observable
    public comments: string = DilapidationFormItemModel.DEFAULT_COMMENTS;
    @observable
    public createdByUserId: string | null = DilapidationFormItemModel.DEFAULT_CREATED_BY_USER_ID;
    @observable
    public lastUpdatedDate: string | null = DilapidationFormItemModel.DEFAULT_LAST_UPDATED_DATE;
    @observable
    public lastUpdatedByUserId: string | null = DilapidationFormItemModel.DEFAULT_LAST_UPDATED_BY_USER_ID;
    @observable
    public completedDate: string | null = DilapidationFormItemModel.DEFAULT_COMPLETED_DATE;
    @observable
    public completedByUserId: string | null = DilapidationFormItemModel.DEFAULT_COMPLETED_BY_USER_ID;
    @observable
    public isDeleted: boolean = DilapidationFormItemModel.DEFAULT_IS_DELETED;

    @observable
    public dilapidationReportItemPhotos: DilapidationFormItemPhotoListItemModel[] = DilapidationFormItemModel.DEFAULT_DILAPIDATION_REPORT_ITEM_PHOTOS;

    public reset = () => {
        this.id = DilapidationFormItemModel.DEFAULT_ID;
        this.dilapidationReportId = DilapidationFormItemModel.DEFAULT_DILAPIDATION_REPORT_ID;
        this.inspectedItem = DilapidationFormItemModel.DEFAULT_INSPECTED_ITEM;
        this.comments = DilapidationFormItemModel.DEFAULT_COMMENTS;
        this.lastUpdatedDate = DilapidationFormItemModel.DEFAULT_LAST_UPDATED_DATE;
        this.lastUpdatedByUserId = DilapidationFormItemModel.DEFAULT_LAST_UPDATED_BY_USER_ID;
        this.completedDate = DilapidationFormItemModel.DEFAULT_COMPLETED_DATE;
        this.completedByUserId = DilapidationFormItemModel.DEFAULT_COMPLETED_BY_USER_ID;
        this.createdByUserId = DilapidationFormItemModel.DEFAULT_CREATED_BY_USER_ID;
        this.isDeleted = DilapidationFormItemModel.DEFAULT_IS_DELETED;

        this.dilapidationReportItemPhotos.length = 0;
    };

    @action
    public fromDto(dto: DilapidationReportItemsDTO): void {
        for (let key in dto) {
            if (dto.hasOwnProperty(key)) {
                if (key === "dilapidationReportItemPhotos") {
                    // do nothing
                } else if (this[key] instanceof Date) {
                    this[key] = new Date(dto[key]);
                } else {
                    this[key] = dto[key];
                }
            }
        }

        // Manually process the child array otherwise we will end up with an array of dtos being stored in the array of models.
        let processedPhotos: DilapidationFormItemPhotoListItemModel[] = [];

        for (const photo of dto.dilapidationReportItemPhotos) {
            const photoToAdd = new DilapidationFormItemPhotoListItemModel();
            photoToAdd.fromDto(photo);
            processedPhotos.push(photoToAdd);
        }

        this.dilapidationReportItemPhotos = [...this.dilapidationReportItemPhotos, ...processedPhotos];
    }

    public toDto(): DilapidationReportItemsDTO {
        let photosToAdd: DilapidationFormItemPhotoListItemDTO[] = [];
        this.dilapidationReportItemPhotos.forEach((photo) => {
            const photoToAdd: DilapidationFormItemPhotoListItemDTO = photo.toDto();
            photosToAdd.push(photoToAdd);
        });

        const dilapidationFormItemModel: DilapidationReportItemsDTO = {
            id: this.id,
            dilapidationReportId: this.dilapidationReportId,
            inspectedItem: this.inspectedItem,
            comments: this.comments,
            completedByUserId: this.completedByUserId,
            lastUpdatedDate: this.lastUpdatedDate,
            lastUpdatedByUserId: this.lastUpdatedByUserId,
            completedDate: this.completedDate,
            isDeleted: this.isDeleted,
            createdByUserId: this.createdByUserId,
            dilapidationReportItemPhotos: photosToAdd,
        };

        return dilapidationFormItemModel;
    }

    @computed
    public get validateInspectedItem(): string {
        return this.inspectedItem === DilapidationFormItemModel.DEFAULT_INSPECTED_ITEM ? "Please provide an inspected item/area" : "";
    }

    @computed
    public get validateComments(): string {
        return this.comments === DilapidationFormItemModel.DEFAULT_COMMENTS ? "Please provide comments" : "";
    }
}

export interface DilapidationReportItemsDTO {
    id: string | null;
    dilapidationReportId: string | null;
    inspectedItem: string;
    comments: string;
    createdByUserId: string | null;
    lastUpdatedDate: string | null;
    lastUpdatedByUserId: string | null;
    completedDate: string | null;
    completedByUserId: string | null;
    isDeleted: boolean;
    dilapidationReportItemPhotos: DilapidationFormItemPhotoListItemDTO[];
}
