import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { RDDFilterViewModel } from "./RDDFilterViewModel";
import { RDDViewModel } from "./RDDViewModel";
import { RDDTable } from "./RDDTable";
import styled from "styled-components";
import { theme } from "../../../../Globals/Styles/AppTheme";
import { runInAction } from "mobx";

const Wrapper = styled.div`
    padding: 5px 30px;
`;

const Row = styled.div`
    display: grid;
    grid-template-columns: 55% 15% 15% 15%;
    padding-left: 15px;
    padding-right: 15px;
    cursor: pointer;
    .title {
        color: ${theme.palette.variationStatus.amendRequired};
        font-weight: normal;
    }
`;

interface Props {
    projectId: string;
    date: Date;
}
export const AllRDDsListView: React.FC<Props> = observer((props: Props) => {
    const [viewModel] = useState(() => new RDDViewModel());

    useEffect(() => {
        runInAction(async () => {
            await viewModel.loadDesignData(props.projectId, null);
        });
        return () => {
            viewModel.clean();
        };
    }, []);

    const renderListView = (
        <>
            {viewModel.hasLoaded && (
                <RDDTable
                    data={viewModel.getDesignDataItems}
                    isLoading={viewModel.IsLoading}
                    handleSearchChange={(searchText: string) => viewModel.handleSearchChange(props.projectId, searchText, null)}
                    isDarwinGroupFilterValue={RDDFilterViewModel.Instance.model.isDarwinGroup}
                    projectId={props.projectId}
                    isDarwin={null}
                    viewModel={viewModel}
                />
            )}
        </>
    );

    return <Wrapper>{renderListView}</Wrapper>;
});
