import { Typography } from "@material-ui/core";
import { Grid, useRouter } from "@shoothill/core";
import MaterialTable from "material-table";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

import { AddButton, DownloadButton } from "Components/Buttons/Buttons";
import { DashedSectionSplitter } from "Components/Form/SectionSplitter";
import { NavigationBarView } from "Components/Navigation/NavigationBar";
import { SnackBar } from "Components/SnackBar/SnackBar";
import { PatchedPagination } from "Components/Table/PatchedPagination";
import { TableListSearch } from "Components/Table/TableListSearch";
import * as Defaults from "Globals/Defaults/TableOptions";
import { DarwinPage, DarwinPageTitleSmall, DarwinTableButtonContainer, DarwinTablePageContent, DarwinTableSearchBox, DarwinTableToolbar } from "Globals/Styles/AppStyling";
import { StatusCell } from "Globals/Styles/Control/StatusCell.style";
import { DetailsHeader } from "Globals/Views/DetailsPage/DetailsHeader";
import { formatDate } from "Utils/Format";
import { DetailsCell, EditDetailsCell, HiddenDetailsCell, OutputsItemViewModel, OutputsViewModel } from ".";
import { AddOutputView } from "../AddEditOutput";
import { DrawingsView } from "../Drawings";
import { IssueView } from "../Issue";
import { DownloadDrawingView, EditView, IssueCheckboxView, MultipleDrawingsView, NoDrawingsView } from "../../Shared";

export const OutputsView: React.FunctionComponent = observer(() => {
    const { match } = useRouter();
    const { projectid, planofworkid } = match.params as any;
    const [viewModel] = useState(() => new OutputsViewModel(projectid, planofworkid));
    const tableOptions = Defaults.GetDarwinTableOptions() as any;

    const onRowClick = (event?: any, rowViewModel?: OutputsItemViewModel) => {
        if (rowViewModel) {
            viewModel.navigateToOutputs(rowViewModel);
        }
    };

    const renderDrawingsPanel = (rowData: OutputsItemViewModel) => {
        switch (true) {
            case rowData.canDisplayNoFiles:
                return (
                    <DetailsCell>
                        <NoDrawingsView>There are currently no drawings added to this stage.</NoDrawingsView>
                    </DetailsCell>
                );

            case rowData.canDisplaySingleFile:
                return (
                    <DetailsCell>
                        <DownloadDrawingView fileName={rowData.drawingFileName!} attachmentUrl={rowData.drawingAttachmentUrl!} inlineUrl={rowData.drawingInlineUrl!} />
                    </DetailsCell>
                );

            case rowData.canDisplayMultipleFiles:
                return (
                    <DetailsCell>
                        <MultipleDrawingsView fileCount={rowData.drawingFileCount} onClick={() => viewModel.displayDrawings(rowData)} />
                    </DetailsCell>
                );

            default:
                return <DetailsCell />;
        }
    };

    const renderEditPanel = (rowData: OutputsItemViewModel) => {
        return (
            <EditDetailsCell>
                <EditView onClick={() => viewModel.displayAddOutput(rowData)} />
            </EditDetailsCell>
        );
    };

    return (
        <React.Fragment>
            {/* Display Add Output Modal */}
            {viewModel.canDisplayAddOutput && <AddOutputView viewModel={viewModel.addOutputViewModel!} />}

            {/* Display Drawings Modal */}
            {viewModel.canDisplayDrawings && <DrawingsView viewModel={viewModel.drawingsViewModel!} />}

            {/* Display Issue Modal */}
            {viewModel.canDisplayIssue && <IssueView viewModel={viewModel.issueViewModel!} />}

            {/* Display Communication Modal */}
            {viewModel.canDisplayCommunication && <IssueView viewModel={viewModel.communicationViewModel!} />}

            <DarwinPage>
                <NavigationBarView action={viewModel.navigateBack} displayName="Back to output tracker" />
                <DashedSectionSplitter />
                <DetailsHeader viewModel={viewModel.general.getHeader} />
                <DarwinPageTitleSmall>
                    <Typography variant="h2" color="textPrimary" style={{ fontWeight: "600" }}>
                        {viewModel.displayName}
                    </Typography>
                </DarwinPageTitleSmall>
                <DarwinTableToolbar>
                    <Grid columnGap={30} dc={"1fr 1fr"} rowGap={15} tc={"1fr 1fr"}>
                        <DarwinTableSearchBox>
                            <TableListSearch searchString={viewModel.getSearchString()} setSearchString={viewModel.setSearchString} />
                        </DarwinTableSearchBox>
                        <DarwinTableButtonContainer>
                            <AddButton color="primary" displayName="Add new output" execute={viewModel.displayAddOutput} />
                            <DownloadButton canExecute={viewModel.canIssue} color="primary" displayName="Issue" execute={viewModel.displayIssue} />
                            <DownloadButton canExecute={viewModel.canAddCommunication} color="primary" displayName="Add communication" execute={viewModel.displayCommunication} />
                        </DarwinTableButtonContainer>
                    </Grid>
                </DarwinTableToolbar>
                <DarwinTablePageContent>
                    <MaterialTable
                        columns={[
                            {
                                title: "Issue",
                                field: "",
                                cellStyle: { ...tableOptions.cellStyle, width: "50px" },
                                render: (rowData: OutputsItemViewModel) => <IssueCheckboxView viewModel={rowData} />,
                                sorting: false,
                            },
                            {
                                title: "Output",
                                field: "name",
                                width: "auto",
                                render: (rowData: OutputsItemViewModel) => (
                                    <div>
                                        <div style={{ fontWeight: "bold" }}>{rowData.name}</div>
                                        {renderDrawingsPanel(rowData)}
                                    </div>
                                ),
                            },
                            {
                                title: "Category",
                                field: "categoryName",
                                cellStyle: {
                                    ...tableOptions.cellStyle,
                                    width: "250px",
                                },
                                render: (rowData: OutputsItemViewModel) => (
                                    <React.Fragment>
                                        <div>{rowData.categoryName}</div>
                                        <HiddenDetailsCell>Row 2</HiddenDetailsCell>
                                    </React.Fragment>
                                ),
                            },
                            {
                                title: "Required by",
                                field: "requiredByDate",
                                cellStyle: {
                                    ...tableOptions.cellStyle,
                                    width: "250px",
                                },
                                render: (rowData: OutputsItemViewModel) => (
                                    <React.Fragment>
                                        <div>{formatDate(rowData.requiredByDate)}</div>
                                        <HiddenDetailsCell>Row 2</HiddenDetailsCell>
                                    </React.Fragment>
                                ),
                            },
                            {
                                title: "Output status",
                                field: "statusName",
                                cellStyle: {
                                    ...tableOptions.cellStyle,
                                    width: "250px",
                                },
                                render: (rowData: OutputsItemViewModel) => (
                                    <React.Fragment>
                                        <StatusCell
                                            className="Unknown"
                                            style={{
                                                backgroundColor: rowData.statusColor,
                                                color: rowData.statusTextColor,
                                                margin: "3px 0",
                                                padding: "2px 0",
                                            }}
                                        >
                                            {rowData.statusName.toLocaleUpperCase()}
                                        </StatusCell>
                                        {renderEditPanel(rowData)}
                                    </React.Fragment>
                                ),
                            },
                        ]}
                        components={{
                            Pagination: PatchedPagination,
                        }}
                        data={viewModel.filteredOutputs}
                        onRowClick={onRowClick}
                        options={{ ...tableOptions, filtering: false, search: false, toolbar: false }}
                        title=""
                    />
                </DarwinTablePageContent>
                <SnackBar
                    messageText={viewModel.snackMessage}
                    messageType={viewModel.snackType}
                    active={viewModel.snackbarState}
                    closeOption={() => viewModel.setSnackbarState(false)}
                    autoHideDuration={5000}
                />
            </DarwinPage>
        </React.Fragment>
    );
});
