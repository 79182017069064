import { ViewModelBase, isEmptyOrWhitespace } from "@shoothill/core";
import { action, computed, observable, runInAction } from "mobx";

import { AppUrls } from "AppUrls";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { HistoryItemViewModel } from "./HistoryItemViewModel";

export class HistoryViewModel extends ViewModelBase<any> {
    public server: ServerViewModel = new ServerViewModel();

    private outputId: string;
    public historyItems = observable<HistoryItemViewModel>([]);
    private parentCloseAction: (refreshPage: boolean) => void;

    constructor(outputId: string, closeAction: (refreshPage: boolean) => void) {
        super();

        this.outputId = outputId;
        this.parentCloseAction = closeAction;

        // Load data.
        this.apiLoadDataAsync();
    }

    // #region Actions

    @action
    public cancel = () => this.parentCloseAction(false);

    // #endregion Actions

    // #region Api Actions

    @action
    public apiLoadDataAsync = async (): Promise<void> => {
        await this.server.query<any>(
            () => this.Get(AppUrls.Server.Projects.ProjectTrackers.OutputTracker.Drawings.GetHistoryByOutputId.replace("{outputid}", this.outputId)),
            (result) => {
                runInAction(() => {
                    this.historyItems.replace(
                        result.map((dto: any) => {
                            const historyItem = new HistoryItemViewModel();

                            historyItem.planOfWorkStageDisplayName = dto.planOfWorkStageDisplayName;
                            historyItem.planOfWorkStageOrdinal = dto.planOfWorkStageOrdinal;
                            historyItem.drawings = dto.drawings;

                            return historyItem;
                        }),
                    );
                });
            },
            "Error whilst loading the output data",
        );

        if (this.server.HaveValidationMessage) {
            this.setSnackMessage(this.server.ValidationMessage);
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }
    };

    // #endregion Api Actions

    // #region Snack Bar

    public SNACKERROR = "error";

    @observable
    public snackbarState = false;

    @observable
    public snackType = "";

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public snackMessage = "";

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    // #endregion Snack Bar

    // #region Boilerplate

    isFieldValid(fieldName: string | number | symbol, value: any): Promise<boolean> {
        throw new Error("Method not implemented.");
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boliderplate
}
