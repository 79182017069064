import { FieldType, ViewModelBase } from "@shoothill/core";
import { action, computed, observable, runInAction } from "mobx";

import { AppUrls } from "AppUrls";
import { InductionType } from "Globals/Models/Enums/InductionType";
import { viewModelModelLink } from "Utils/Utils";
import { Step2Model } from "./Step2Model";
import { QualificationModel } from "../Common/QualificationModel";
import { QualificationViewModel } from "../Common/QualificationViewModel";

export class Step2ViewModel extends ViewModelBase<Step2Model> {
    @observable public qualificationViewModels: QualificationViewModel[] = [];
    @observable public secondPage: boolean = false;
    @observable public allowNextStep: boolean = false;
    @observable public allowSecondPage: boolean = false;

    constructor() {
        super(new Step2Model());
    }

    // #region Step2

    public get isVisitorInductionType() {
        return this.model.inductionTypes.find((it) => it.id === this.model.inductionTypeId)?.IsOfType(InductionType.Visitor);
    }

    @action
    public nextStep = () => {
        this.setIsLoading(true);

        if (this.secondPage || this.isVisitorInductionType) {
            if (this.isVisitorInductionType) {
                this.history.push(AppUrls.Client.Induction.Step5);
            } else {
                this.history.push(AppUrls.Client.Induction.Step3);
            }
        } else {
            this.secondPage = true;
            this.isModelValid();
        }

        setTimeout(() => {
            this.setIsLoading(false);
        }, 100);
    };

    public link = viewModelModelLink(this.model.qualifications, this.qualificationViewModels, QualificationViewModel);

    @action
    public previousStep = () => {
        this.setIsLoading(true);
        if (this.secondPage) {
            this.secondPage = false;
        } else {
            this.history.push(AppUrls.Client.Induction.Step1);
        }
        setTimeout(() => {
            this.setIsLoading(false);
        }, 100);
    };

    @computed
    public get canExecuteNextStep() {
        //EN: Horrible way to do validation but there are some limitations with class-validator
        if (!this.secondPage) {
            this.isModelValid().then((isValid) => {
                runInAction(() => {
                    if (!this.allowSecondPage) {
                        this.allowSecondPage = isValid;
                    }
                });
            });
        }
        if (this.secondPage) {
            let valid = true;
            for (const item of this.qualificationViewModels) {
                item.isModelValid().then((isValid: boolean) => {
                    runInAction(() => {
                        if (valid) {
                            valid = isValid;
                        }
                        console.log("canExecuteNextStep", this.allowNextStep);
                    });
                });
            }
            setTimeout(() => {
                runInAction(() => {
                    this.allowNextStep = valid;
                });
            }, 100);
        }
        if (!this.secondPage) {
            return this.allowSecondPage;
        } else {
            return this.allowNextStep;
        }
    }

    // #endregion Step2

    @action
    public handleOver18Change = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.model.confirmOver18 = (event.target as HTMLInputElement).value;
    };
    @action
    public handleAdjustmentsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.model.confirmAdjustments = (event.target as HTMLInputElement).value;
    };
    @action
    public handlePrescribedMedicationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.model.prescribed = (event.target as HTMLInputElement).value;
    };
    @action
    public handleOverHealthProblemsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.model.healthProblems = (event.target as HTMLInputElement).value;
    };
    @action
    public handleAgreeNotifyManager = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.model.notifyManager = (event.target as HTMLInputElement).value;
        this.canExecuteNextStep;
    };
    @action
    public sectionChange = (fieldName: any, value: string) => {
        this.setValue(fieldName as any, value);
    };

    @action
    public addOther = (name: string) => {
        let qualificationModel = new QualificationModel();

        qualificationModel.name = name;
        qualificationModel.inductionSectionType = this.model.skillsTrainingInductionSectionType!.id;

        this.model.qualifications.push(qualificationModel);
    };

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<Step2Model>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        if (!this.secondPage) {
            if (fieldName.startsWith("qualifications")) {
                errorMessage = "";
                isValid = true;
            }
        } else {
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
