import React, { useEffect } from "react";
import { useObserver } from "@shoothill/core";
//import { PurchaseOrderListViewModel } from "./PurchaseOrderListViewModel";
//import { PurchaseOrderListFilterViewModel } from "./PurchaseOrderListFilterViewModel";
import { DarwinInput } from "Globals/Styles/Control/DarwinInput";
//import { FilterRow, FilterWrapper } from "./PurchaseOrderList.styles";
import { MultiSelectView } from "Components/Select/MultiSelect";
import { ProjectCellHelpers } from "Globals/Models/Domain/ProjectCellEnum";
import { PrimaryButton } from "Components/Buttons/Buttons";
import { StockListViewModel } from "./StockListViewModel";
import { StockListFilterViewModel } from "./StockListFilterViewModel";
import { FilterRow, FilterWrapper } from "./StockList.styles";

interface IStockListFilterViewProps {
    stockListViewModel: StockListViewModel;
    filtersViewModel: StockListFilterViewModel;
}

export const StockListFilterView: React.FunctionComponent<IStockListFilterViewProps> = (props: IStockListFilterViewProps) => {
    //useEffect below only gets run on initial render
    useEffect(() => {
        return () => {};
    }, []);

    const renderPage = () => {
        return (
            <>
                <FilterWrapper style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                    <FilterRow>
                        <MultiSelectView
                            style={{ maxWidth: "200px", marginRight: "20px" }}
                            display="Type"
                            fieldName="types"
                            values={props.filtersViewModel.model.types}
                            getOptions={props.filtersViewModel.getTypeOptions}
                            handleChange={props.stockListViewModel.handleTypeChange}
                        />

                        <MultiSelectView
                            style={{ maxWidth: "200px", marginRight: "20px" }}
                            display="Sub type"
                            fieldName="subType"
                            values={props.filtersViewModel.model.subTypes}
                            getOptions={props.filtersViewModel.getSubTypeOptions}
                            handleChange={props.stockListViewModel.handleSubTypeChange}
                        />

                        <MultiSelectView
                            style={{ maxWidth: "200px", marginRight: "20px" }}
                            display="UoM"
                            fieldName="uoms"
                            values={props.filtersViewModel.model.uoms}
                            getOptions={props.filtersViewModel.getUOMOptions}
                            handleChange={props.stockListViewModel.handleUOMChange}
                        />
                    </FilterRow>
                    {/* <FilterRow>
                        <DarwinInput
                            type="text"
                            label="Search"
                            validateOnBlur={true}
                            viewModel={props.filtersViewModel}
                            fieldName="searchText"
                            shrink={true}
                            maxLength={128}
                            onChange={(e: any) => props.stockListViewModel.handleSearchChange(e.target.value)}
                        />
                        <div className="filter-button-container">
                            <PrimaryButton
                                displayName={"Clear"}
                                execute={props.stockListViewModel.clear}
                                fullWidth={false}
                                canExecute={!props.stockListViewModel.IsLoading}
                            />
                        </div>
                    </FilterRow> */}
                </FilterWrapper>
            </>
        );
    };

    return useObserver(() => renderPage());
};
