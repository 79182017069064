import { ModelBase, observable } from "@shoothill/core";
import { action } from "mobx";

import { IInductionType } from "Globals/Models/Enums/InductionType";
import type { InductionAdminItemDTO } from "./Details/InductionAdminItemModel";
import { InductionAdminSiteAccessLogModel, InductionAdminSiteAccessLogModelDTO } from "./SiteAccessLog/InductionAdminSiteAccessLogModel";

export class InductionAdminModel extends ModelBase<InductionAdminModel, any> {
    // #region Properties

    @observable
    public id: string = "";

    @observable
    public siteAccessItems: InductionAdminSiteAccessLogModel[] = [];

    // #endregion Properties

    // #region Actions

    @action
    public fromDto(dto: InductionAdminItemDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself

        for (let key in dto) {
            if (dto.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(dto[key]);
                } else if (key === "inductionItems") {
                    // Do nothing.
                } else {
                    this[key] = dto[key];
                }
            }
        }
    }

    @action
    public fromSiteAccessDto(dto: InductionAdminSiteAccessDTO): void {
        // Process the list
        let processedPos: InductionAdminSiteAccessLogModel[] = [];

        for (const item of dto.siteAccessItems) {
            const itemToAdd = new InductionAdminSiteAccessLogModel();
            itemToAdd.fromDto(item);
            processedPos.push(itemToAdd);
        }

        this.siteAccessItems = processedPos;
    }

    public toDto() {}

    // #endregion Actions

    // #region Custom Validation

    // #endregion Custom Validation
}

export interface InductionAdminDetailsResultDTO {
    inductionItem: InductionAdminItemDTO;
    inductionStatuses: InductionStatusDTO[];
    inductionTypes: IInductionType[];
}

export interface InductionStatusDTO {
    id: string | null;
    displayName: string;
    ordinal: number;
    type: InductionStatusEnum;
    color: string;
    textColor: string;
}

export interface InductionAdminSiteAccessDTO {
    siteAccessItems: InductionAdminSiteAccessLogModelDTO[];
}

export enum InductionStatusEnum {
    Pending = 10,
    Verified = 20,
    AccessApproved = 30,
}
