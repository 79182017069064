import { ModelBase } from "@shoothill/core";
import { IsNotEmpty, ValidateIf } from "class-validator";
import { observable, runInAction } from "mobx";
import { AuditDocumentModel } from "../AuditDocument";

export class FirePointModel extends ModelBase<FirePointModel> {
    public id: string | null = null;
    @observable
    @IsNotEmpty({ message: "Please enter a location" })
    public location: string = "";
    @observable
    @IsNotEmpty({ message: "Please select yes or no" })
    public airHornsInWorkingCondition: string = "";
    @observable
    @ValidateIf((o) => o.airHornsInWorkingCondition === "no")
    @IsNotEmpty({ message: "Please enter more detail" })
    public airHornsInWorkingConditionDetail: string = "";
    @observable
    @IsNotEmpty({ message: "Please select yes or no" })
    public signageInPlace: string = "";
    @observable
    @ValidateIf((o) => o.signageInPlace === "no")
    @IsNotEmpty({ message: "Please enter more detail" })
    public signageInPlaceDetail: string = "";
    @observable
    @IsNotEmpty({ message: "Please select yes or no" })
    public unobstructedAndVisible: string = "";
    @observable
    @ValidateIf((o) => o.unobstructedAndVisible === "no")
    @IsNotEmpty({ message: "Please enter more detail" })
    public unobstructedAndVisibleDetail: string = "";
    @observable
    public auditId: string | null = null;
    @observable
    public auditDocuments: AuditDocumentModel[] = [];
    constructor() {
        super();
    }

    fromDto(model: any): void {
        this.id = model.id;
        this.location = model.location;
        this.airHornsInWorkingCondition = model.airHornsInWorkingCondition ? "yes" : "no";
        this.signageInPlace = model.signageInPlace ? "yes" : "no";
        this.unobstructedAndVisible = model.unobstructedAndVisible ? "yes" : "no";
        this.auditId = model.auditId;
        this.auditDocuments = model.auditDocuments;
        this.airHornsInWorkingConditionDetail = model.airHornsInWorkingConditionDetail;
        this.signageInPlaceDetail = model.signageInPlaceDetail;
        this.unobstructedAndVisibleDetail = model.unobstructedAndVisibleDetail;
        if (model.auditDocuments) {
            runInAction(() => {
                this.auditDocuments = model.auditDocuments.map((x: any) => {
                    const auditDocument = new AuditDocumentModel();
                    auditDocument.fromDto(x);
                    return auditDocument;
                });
            });
        }
    }

    toDto() {
        return {
            id: this.id,
            location: this.location,
            airHornsInWorkingCondition: this.airHornsInWorkingCondition === "yes" ? true : false,
            signageInPlace: this.signageInPlace === "yes" ? true : false,
            unobstructedAndVisible: this.unobstructedAndVisible === "yes" ? true : false,
            auditId: this.auditId,
            auditDocuments: this.auditDocuments,
            airHornsInWorkingConditionDetail: this.airHornsInWorkingConditionDetail,
            signageInPlaceDetail: this.signageInPlaceDetail,
            unobstructedAndVisibleDetail: this.unobstructedAndVisibleDetail,
        };
    }
}
