import { ModelBase } from "@shoothill/core";
import { observable, action, computed } from "mobx";

export class ApprovalDocumentModel extends ModelBase<ApprovalDocumentModel> {
    @observable
    public id: string | null = null;
    @observable
    public fileName: string = "";
    @observable
    public fileUrl: string = "";
    @observable
    public isDeleted: boolean = false;
    @observable
    public createdByUserId: string | null = "";
    @observable
    public createdDate: string | null = "";
    @observable
    public parentId: string | null = "";

    constructor() {
        super();
    }

    @computed
    public get hasId(): boolean {
        return this.id !== "" && this.id !== null && this.id !== undefined;
    }

    @action
    public handleDelete = (val: boolean) => {
        this.isDeleted = val;
    };

    fromDto(model: ApprovalDocumentDTO): void {
        this.id = model.id;
        this.fileName = model.fileName;
        this.fileUrl = model.fileUrl;
        this.isDeleted = model.isDeleted;
        this.createdByUserId = model.createdByUserId;
        this.createdDate = model.createdDate;
        this.parentId = model.parentId;
    }

    public static fromDtos(dtos: ApprovalDocumentDTO[]): ApprovalDocumentModel[] {
        const types: ApprovalDocumentModel[] = [];

        for (const dto of dtos) {
            const model = new ApprovalDocumentModel();

            model.fromDto(dto);
            types.push(model);
        }

        return types;
    }

    toDto(): ApprovalDocumentDTO {
        return {
            id: this.id,
            fileName: this.fileName,
            fileUrl: this.fileUrl,
            isDeleted: this.isDeleted,
            createdByUserId: this.createdByUserId,
            createdDate: this.createdDate,
            parentId: this.parentId,
        };
    }
}

export interface ApprovalDocumentDTO {
    id: string | null;
    fileName: string;
    fileUrl: string;
    isDeleted: boolean;
    createdByUserId: string | null;
    createdDate: string | null;
    parentId: string | null;
}
