import { FieldType, ViewModelBase } from "@shoothill/core";
import { observable, action } from "mobx";
import { DesignDataModel } from "./DesignData";
import type { DesignDataModelDTO } from "./DesignData";

export class DesignDataViewModel extends ViewModelBase<DesignDataModel> {
    private static _instance: DesignDataViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable
    public projectId = "";

    @action
    public setProjectId = (val: string) => {
        this.projectId = val;
    };

    @observable
    public hasLoaded = true;

    @action
    public setHasLoaded(val: boolean) {
        this.hasLoaded = val;
    }

    @observable public errorMessage: string = "";

    public constructor() {
        super(new DesignDataModel(), false);
        this.setDecorators(DesignDataModel);
    }

    @action
    public clean = () => {
        this.model.clearModel();
    };

    @action
    public loadModel(model: DesignDataModelDTO | null) {
        if (model !== null) {
            this.model.fromDto(model);
        }
    }

    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (await this.isMyModelValid()) {
            //Do stuff here
            this.errorMessage = "Form is valid";
        } else {
            this.errorMessage = "Form is not valid";
        }
    };

    public async isFieldValid(fieldName: keyof FieldType<DesignDataModel>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    private isMyModelValid = async (): Promise<boolean> => {
        let isValid = true;
        // this will automatically call isFieldValid.
        if ((await this.isModelValid()) === false) {
            isValid = false;
        }
        return isValid;
    };

    @action
    public populateProject(payload: DesignDataModelDTO) {
        this.model.fromDto(payload);
    }

    // #region Snackbar

    @observable
    public snackbarState = false;

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public snackMessage = "";

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @observable
    public snackType = "";

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    @observable
    public SNACKSUCCESS = "success";

    @observable
    public SNACKERROR = "error";
    // #endregion

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
