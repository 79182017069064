import { observable, runInAction } from "mobx";
import { FieldType, ViewModelBase } from "@shoothill/core";
import { LandingModel } from "./Landing/LandingModel";
import { LandingViewModel } from "./Landing/LandingViewModel";

export class AccessRoutesViewModel extends ViewModelBase<any> {
    public landingViewModel = new LandingViewModel();
    constructor() {
        super();
        (window as any).AccessRoutesViewModel = this;
    }

    public async isFieldValid(fieldName: keyof FieldType, value: any): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
