import * as React from "react";
import { Button, Paper, TextField } from "@material-ui/core";
import styled from "styled-components";

import { BaseModal } from "Components/Modal/BaseModal";
import { CancelButton } from "Globals/Styles/Control/Buttons";
import { useObserver } from "mobx-react-lite";
import { EditContactModalDialogPaper } from "Views/Contacts/EditContactModal.styles";
import { DarwinSelect } from "Components/AutoComplete/DarwinSelect";
import { theme } from "Globals/Styles/AppTheme";
import { PurchaseOrderView } from "Views/PurchaseOrder/Form/PurchaseOrderView";
import { Loader } from "@shoothill/core";

interface IInvoicePOModalProps {
    open?: boolean;
    onClose(): void;
    title: string;
    isLoading: boolean;
    poId: string | null;
}

export const InvoicePOModal: React.FunctionComponent<IInvoicePOModalProps> = ({ open, onClose, title, isLoading, poId }) => {
    return useObserver(() => (
        <BaseModal
            open={open}
            onClose={onClose}
            title={title}
            PaperComponent={InvoicePOModalDialogPaper}
            actions={
                <>
                    <CancelButton onClick={onClose}>Close</CancelButton>
                </>
            }
        >
            {poId && <PurchaseOrderView poId={poId} />}
            {!poId && <Loader />}
        </BaseModal>
    ));
};

export const InvoicePOModalDialogPaper = styled(Paper)`
    width: 100% !important;
    border-radius: 10px;
    max-width: 90%;
    margin: 0 auto;
    height: 100%;

    .darwin-dialog-title {
        background: ${theme.palette.primary.main};
        color: ${theme.palette.common.white};
        padding: 16px 20px;

        h2 {
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            font-weight: normal;

            span.modal-close {
                cursor: pointer;
                font-weight: bold;
            }
        }
    }

    .MuiDialogContent-root {
        padding: 16px 20px 0;
        height: 100%;

        /* p {
            font-size: 14px;
        } */

        > hr {
            border: none;
            height: 1px;
            background-color: ${theme.palette.primary.main};
        }
    }

    .MuiDialogActions-root {
        padding: 20px;
        justify-content: center;

        > :not(:first-child) {
            margin-left: 20px;
        }

        > button {
            width: 100%;
            max-width: 116px;
            height: 30px;
            font-size: 14px;
        }
    }
`;
