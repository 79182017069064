import { useRouter } from "@shoothill/core";
import MaterialTable, { MTableToolbar } from "material-table";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { PatchedPagination } from "Components/Table/PatchedPagination";
import { SHBox } from "Components/Box";
import { PrimaryButton } from "Components/Buttons/Buttons";
import * as Defaults from "Globals/Defaults/TableOptions";
import { DarwinTablePageContent } from "Globals/Styles/AppStyling";
import { formatDate, formatTime } from "Utils/Format";
import { ScaffoldingListItemModel } from "./ScaffoldingListItemModel";
import { ScaffoldingListViewModel } from "./ScaffoldingListViewModel";

const Wrapper = styled.div`
    padding: 5px 30px;
`;

const ToolbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
        padding-left: 0;
    }

    ${PrimaryButton} {
        min-width: 300px;
    }
`;

interface Props {
    projectId: string;
    projectName: string;
    projectRef: string;
    date: Date;
}

export const ScaffoldingListView: React.FC<Props> = observer((props: Props) => {
    const { match } = useRouter();
    const { projectid } = match.params as any;
    const [viewModel] = useState(() => new ScaffoldingListViewModel());

    useEffect(() => {
        viewModel.GetList(projectid, props.date);
    }, [props.date]);

    /**
     * We expose the server busy state here so Material Table will render
     * the custom toolbar correctly.
     */
    const canExecuteNavigation = !viewModel.server.IsBusy;

    const renderToolbar = (toolbarProps: any) => {
        return (
            <ToolbarContainer>
                <MTableToolbar {...toolbarProps} />
                <PrimaryButton canExecute={canExecuteNavigation} displayName={"Add new scaffold inspection"} execute={viewModel.navigateToScaffoldAddInspectionForm} />
            </ToolbarContainer>
        );
    };

    const renderListView = (
        <SHBox>
            <DarwinTablePageContent>
                <MaterialTable
                    columns={[
                        {
                            title: "Date / time added",
                            field: "createdDate",
                            render: (rowData: ScaffoldingListItemModel) => <SHBox>{`${formatDate(rowData.createdDate)} @ ${formatTime(rowData.createdDate)}`}</SHBox>,
                        },
                        {
                            title: "Scaffold inspector",
                            field: "scaffoldInspectorName",
                        },
                        {
                            title: "No. of scaffolds",
                            field: "numberOfScaffolds",
                        },
                        {
                            title: "Last updated",
                            field: "lastUpdatedDate",
                            render: (rowData: ScaffoldingListItemModel) => (
                                <SHBox>
                                    <SHBox>{`${formatDate(rowData.updatedDate)} @ ${formatTime(rowData.updatedDate)}`} by</SHBox>
                                    <SHBox>{rowData.updatedByName}</SHBox>
                                </SHBox>
                            ),
                        },
                    ]}
                    components={{
                        Pagination: PatchedPagination,
                        Toolbar: (toolbarProps) => renderToolbar(toolbarProps),
                    }}
                    options={Defaults.GetDarwinTableOptions()}
                    data={viewModel.scaffoldingListModels.slice()}
                    onRowClick={(e, rowData) => viewModel.navigateToScaffoldInspection(rowData!)}
                    title=""
                />
            </DarwinTablePageContent>
        </SHBox>
    );

    return <Wrapper>{renderListView}</Wrapper>;
});
