import { isNullOrUndefined, Loader, React, useEffect, useObserver, useRouter, useState } from "@shoothill/core";
import RoleViewModel from "Globals/ViewModels/RoleViewModel";
import { User } from "Globals/Models";
import UserViewModel from "Globals/ViewModels/UserViewModel";
import { StoresInstance } from "Globals/Stores";
import { AppUrls } from "AppUrls";

import {
    DetailPage,
    DetailContainer,
    DetailHeader,
    DetailContent,
    DetailBox,
    DetailBoxRow,
    DetailBoxItemRow,
    DetailBoxTitleItem,
    DetailBoxItem,
    DetailDivider,
} from "Globals/Styles/Control/DetailPage.style";
import { Typography } from "@material-ui/core";
import { formatDateTime } from "Utils/Format";
import { UserAddEditModal } from "./UserAddEditModal";
import { ResetAccessFailedLoginCountButton } from "Globals/Styles/Control/Buttons";
import { DeleteModal } from "Components/Modal/DeleteModal";

const domainStores = StoresInstance.Domain;

export const UserDetails: React.FC = () => {
    const [viewModel] = useState(() => UserViewModel.Instance);
    const { history, match } = useRouter();
    const [newUser, setNewUser] = useState(false);

    let { userid } = match.params as any;

    React.useEffect(() => {
        if (userid === "-1") {
            setNewUser(true);
        } else if (isNullOrUndefined(userid) === false) {
            setNewUser(false);
        }

        return () => {
            // Clean up after yourself
            viewModel.showAddEditModal(false);
            viewModel.setUser(new User("-1"), true);
        };
    }, []);

    React.useEffect(() => {
        if (history.location.pathname === AppUrls.Client.Admin.User.Add) {
            setNewUser(true);
        } else {
            setNewUser(false);
        }
    }, [history.location.pathname]);

    return useObserver(() => (
        <DetailPage>
            <DetailPanel newUser={newUser} id={userid} userViewModel={viewModel} />
        </DetailPage>
    ));
};

interface DetailPanelProps {
    newUser: boolean;
    id: string;
    userViewModel: UserViewModel;
}
export const DetailPanel: React.FC<DetailPanelProps> = (props) => {
    const { userViewModel } = props;
    const { match } = useRouter();
    const [roleViewModel] = useState(() => new RoleViewModel());
    const [resetOpen, setResetOpen] = useState(false);
    let user: User = new User("");

    let { userid } = match.params as any;
    //const [loader, setLoader] = useState(false);
    const { history } = useRouter();
    const [errorMessage, setErrorMessage] = useState("");

    const getUserRoles = async () => {
        const rolesResponse = await roleViewModel.loadRolesAsync();

        if (rolesResponse.wasSuccessful === false) {
            if (rolesResponse.errors) {
                setErrorMessage(rolesResponse.errors[0].message);
            } else {
                setErrorMessage("There was a problem getting the roles.");
            }
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        if (isNullOrUndefined(userid) === false) {
            getUserRoles();
            userViewModel.loadUserAsync(userid).then((result) => {});
            userViewModel.apiGetAllAndRelated();
        } else {
            userViewModel.setUser(user, true);
        }
    }, []);

    const lastLogged: string = formatDateTime(userViewModel.getValue<string>("lastLoginDate"));
    const createdAt: string = formatDateTime(userViewModel.getValue("createdAt"));

    const handleUserClose = () => {
        userViewModel.showAddEditModal(false);
        history.push(AppUrls.Client.Admin.User.List);
    };

    const handleAddedUser = (userId: string) => {};

    const resetFailedLoginAttempts = () => {
        userViewModel.resetFailedLoginAttempts();
        handleResetClose();
    };

    const handleResetCount = () => {
        setResetOpen(true);
    };

    const handleResetClose = () => {
        setResetOpen(false);
    };

    return useObserver(() => (
        <>
            {userViewModel.IsLoading && <Loader />}
            {!userViewModel.IsLoading && (
                <>
                    <UserAddEditModal onClose={handleUserClose} open={userViewModel.getShowAddEditModal} onUpsert={handleAddedUser} title="Edit user" confirmText="Save" />
                    <DetailContainer>
                        <DetailHeader>
                            <Typography variant={"h1"}>{userViewModel.model.getName}</Typography>
                        </DetailHeader>
                        <DetailContent>
                            <DetailBoxRow>
                                <DetailBox>
                                    <DetailBoxItemRow>
                                        <DetailBoxTitleItem>Name:</DetailBoxTitleItem>
                                        <DetailBoxItem>{userViewModel.model.getName}</DetailBoxItem>
                                    </DetailBoxItemRow>
                                    <DetailBoxItemRow>
                                        <DetailBoxTitleItem>Access level:</DetailBoxTitleItem>
                                        <DetailBoxItem>{userViewModel.getRoleListFormatted}</DetailBoxItem>
                                    </DetailBoxItemRow>
                                    <DetailBoxItemRow>
                                        <DetailBoxTitleItem>Email:</DetailBoxTitleItem>
                                        <DetailBoxItem>{userViewModel.getValue("emailAddress")}</DetailBoxItem>
                                    </DetailBoxItemRow>
                                    <DetailBoxItemRow>
                                        <DetailBoxTitleItem>Phone:</DetailBoxTitleItem>
                                        <DetailBoxItem>{userViewModel.getValue("phoneNumber")}</DetailBoxItem>
                                    </DetailBoxItemRow>
                                </DetailBox>
                                <DetailBox>
                                    <DetailBoxItemRow>
                                        <DetailBoxTitleItem>Added:</DetailBoxTitleItem>
                                        <DetailBoxItem>{createdAt}</DetailBoxItem>
                                    </DetailBoxItemRow>
                                    <DetailBoxItemRow>
                                        <DetailBoxTitleItem>Last Logged In:</DetailBoxTitleItem>
                                        <DetailBoxItem>{lastLogged}</DetailBoxItem>
                                    </DetailBoxItemRow>
                                    <DetailBoxItemRow>
                                        <DetailBoxTitleItem>Access Failed Count:</DetailBoxTitleItem>
                                        <DetailBoxItem>{userViewModel.getValue("accessFailedCount")}</DetailBoxItem>
                                        <ResetAccessFailedLoginCountButton onClick={handleResetCount} color="secondary" variant="contained" disabled={userViewModel?.IsLoading}>
                                            {userViewModel.IsLoading ? "Saving..." : "Reset access failed count"}
                                        </ResetAccessFailedLoginCountButton>
                                    </DetailBoxItemRow>
                                </DetailBox>
                            </DetailBoxRow>
                            <DetailDivider />
                        </DetailContent>
                    </DetailContainer>
                    <DeleteModal
                        open={resetOpen}
                        onClose={handleResetClose}
                        onDelete={resetFailedLoginAttempts}
                        title="Reset user access failed count?"
                        text="Are you sure you want to reset this users access failed count?"
                    />
                </>
            )}
        </>
    ));
};
