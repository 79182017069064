import * as MobX from "mobx";
import { ModelBase } from "@shoothill/core";

export class IEItemMaterial extends ModelBase<IEItemMaterial, IEItemMaterialDTO> {
    public id: string = "";
    public ieItemId: string = "";
    public description: string = "";
    public committedCost: number = 0;

    public fromDto(dto: IEItemMaterialDTO): void {
        this.id = dto.id;
        this.ieItemId = dto.ieItemId;
        this.description = dto.description;
        this.committedCost = dto.committedCost;
    }

    toDto(model: IEItemMaterial): void {
        throw new Error("Method not implemented.");
    }
}

export type IEItemMaterialDTO = {
    id: string;
    ieItemId: string;
    description: string;
    committedCost: number;
};
