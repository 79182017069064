import { ModelBase, observable } from "@shoothill/core";

export class NonConformanceListModel extends ModelBase<NonConformanceListModel> {
    public id: string = "";
    @observable
    public nonConformanceTypeName: string = "";
    @observable
    public nonConformanceNumber: string = "";
    @observable
    public completedByUserName: string = "";
    @observable
    public lastUpdatedDate: string = "";
    @observable
    public lastUpdatedByUserName: string = "";

    constructor() {
        super();
    }

    fromDto(model: NonConformanceListDTO): void {
        this.id = model.id;
        this.nonConformanceTypeName = model.nonConformanceTypeName;
        this.nonConformanceNumber = model.nonConformanceNumber;
        this.completedByUserName = model.completedByUserName;
        this.lastUpdatedDate = model.lastUpdatedDate;
        this.lastUpdatedByUserName = model.lastUpdatedByUserName;
    }
    toDto(model: NonConformanceListModel): void {
        throw new Error("Method not implemented.");
    }
}

export interface NonConformanceListDTO {
    id: string;
    nonConformanceTypeName: string;
    nonConformanceNumber: string;
    completedByUserName: string;
    lastUpdatedDate: string;
    lastUpdatedByUserName: string;
}

export interface NonConformanceTypeDTO {
    id: string;
    displayName: string;
    type: number;
    ordinal: number;
    isDeleted: boolean;
}

export interface NonConformanceListAndRelatedDTO {
    nonConformanceListItems: NonConformanceListDTO[];
    nonConformanceTypes: NonConformanceTypeDTO[];
}
