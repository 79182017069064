import { isNullOrUndefined } from "@shoothill/core";
import { action, observable } from "mobx";

import { ApprovalPanelModelBase } from "Globals/Models/ApprovalPanelModelBase";
import { VariationTypeEnum } from "./Form/VariationModel";

export class VariationApprovalPanelModel extends ApprovalPanelModelBase<VariationApprovalPanelModel> {
    // #region Properties
    @observable public variationTypeEnum: VariationTypeEnum = VariationTypeEnum.Variation;
    // #endregion Properties
    // #region Actions
    // #endregion Actions
}
