import { Link } from "@material-ui/core";
import { isNullOrUndefined, useRouter } from "@shoothill/core";
import { Link as RouterLink } from "react-router-dom";
import { AppUrls } from "AppUrls";
import { ClassNameMap } from "@material-ui/styles";
import { BreadcrumbViewModel } from "../BreadcrumbViewModel";
import { ProjectBreadCrumbSection } from "./ProjectBreadCrumbSection";

export const ProjectMatchSection1 = (
    projectId: string,
    ieId: string,
    viewModel: BreadcrumbViewModel,
    sections: ProjectBreadCrumbSection,
    classes: ClassNameMap<"root" | "common" | "prev" | "heading" | "main" | "secondary" | "lists" | "nested" | "parent" | "child">,
): any => {
    return (
        <div>
            <Link component={RouterLink} to={AppUrls.Client.Project.List} className={classes.heading}>
                Projects
                {!isNullOrUndefined(projectId) && (
                    <>
                        <br />
                        {viewModel.getProjectRef()}
                    </>
                )}
            </Link>
            {!isNullOrUndefined(projectId) && (
                <>
                    {!viewModel.isSiteManager && (
                        <>
                            <Link
                                component={RouterLink}
                                to={AppUrls.Client.Project.General.replace(":projectid", projectId)}
                                className={sections.projectGeneral ? "" : classes.prev}
                            >
                                {sections.projectGeneral ? (
                                    <div className={classes.main}>
                                        General
                                        <span className="triangle"></span>
                                    </div>
                                ) : (
                                    <>General</>
                                )}
                            </Link>
                            <Link
                                component={RouterLink}
                                to={AppUrls.Client.Project.Commercial.replace(":projectid", projectId)}
                                className={sections.projectCommercial ? "" : classes.prev}
                            >
                                {sections.projectCommercial ? (
                                    <div className={classes.main}>
                                        Commercial
                                        <span className="triangle"></span>
                                    </div>
                                ) : (
                                    <>Commercial</>
                                )}
                            </Link>
                            <Link
                                component={RouterLink}
                                to={AppUrls.Client.Project.Contractual.replace(":projectid", projectId)}
                                className={sections.projectContractual ? "" : classes.prev}
                            >
                                {sections.projectContractual ? (
                                    <div className={classes.main}>
                                        Contractual
                                        <span className="triangle"></span>
                                    </div>
                                ) : (
                                    <>Contractual</>
                                )}
                            </Link>
                        </>
                    )}
                    <Link
                        component={RouterLink}
                        to={AppUrls.Client.Project.Construction.replace(":projectid", projectId)}
                        className={sections.projectConstruction ? "" : classes.prev}
                    >
                        {sections.projectConstruction ? (
                            <div className={`${classes.main} ${classes.parent}`}>
                                <div>
                                    Construction
                                    <span className="triangle"></span>
                                </div>
                            </div>
                        ) : (
                            <>Construction</>
                        )}
                    </Link>
                    {sections.projectConstruction && (
                        <>
                            <div className={classes.child}>
                                <Link
                                    component={RouterLink}
                                    to={AppUrls.Client.Project.ConstructionHAndS.replace(":projectid", projectId)}
                                    className={sections.projectConstruction ? "" : classes.prev}
                                >
                                    <>H&S</>
                                </Link>
                            </div>
                            <div className={classes.child}>
                                <Link
                                    component={RouterLink}
                                    to={AppUrls.Client.Project.ConstructionQuality.replace(":projectid", projectId)}
                                    className={sections.projectConstruction ? "" : classes.prev}
                                >
                                    <>Quality</>
                                </Link>
                            </div>
                        </>
                    )}

                    {!viewModel.isSiteManager && (
                        <>
                            <Link
                                component={RouterLink}
                                to={AppUrls.Client.Project.Delivery.replace(":projectid", projectId)}
                                className={sections.projectDelivery ? "" : classes.prev}
                            >
                                {sections.projectDelivery ? (
                                    <div className={classes.main}>
                                        Delivery Schedule
                                        <span className="triangle"></span>
                                    </div>
                                ) : (
                                    <>Delivery Schedule</>
                                )}
                            </Link>
                        </>
                    )}
                    <Link component={RouterLink} to={AppUrls.Client.Project.SiteAccess.replace(":projectid", projectId)} className={sections.projectSiteAccess ? "" : classes.prev}>
                        {sections.projectSiteAccess ? (
                            <div className={classes.main}>
                                Site access
                                <span className="triangle"></span>
                            </div>
                        ) : (
                            <>Site access</>
                        )}
                    </Link>
                    <Link
                        component={RouterLink}
                        to={AppUrls.Client.Project.ProjectTrackers.replace(":projectid", projectId)}
                        className={sections.projectProjectTrackers ? "" : classes.prev}
                    >
                        {sections.projectProjectTrackers ? (
                            <div className={classes.main}>
                                Project trackers
                                <span className="triangle"></span>
                            </div>
                        ) : (
                            <>Project trackers</>
                        )}
                    </Link>
                    {sections.projectProjectTrackers && (
                        <>
                            <div className={classes.child}>
                                <Link
                                    component={RouterLink}
                                    to={AppUrls.Client.Project.RequestForInformation.replace(":projectid", projectId)}
                                    className={sections.projectProjectTrackers ? "" : classes.prev}
                                >
                                    <>Request for information</>
                                </Link>
                            </div>
                            <div className={classes.child}>
                                <Link
                                    component={RouterLink}
                                    to={AppUrls.Client.Project.DesignData.replace(":projectid", projectId)}
                                    className={sections.projectProjectTrackers ? "" : classes.prev}
                                >
                                    <>Reviewable design data</>
                                </Link>
                            </div>
                            <div className={classes.child}>
                                <Link
                                    component={RouterLink}
                                    to={AppUrls.Client.Project.OutputTracker.PlansOfWork.replace(":projectid", projectId)}
                                    className={sections.projectProjectTrackers ? "" : classes.prev}
                                >
                                    <>Output tracker</>
                                </Link>
                            </div>
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export const ProjectMatchSection2 = (
    projectId: string,
    viewModel: BreadcrumbViewModel,
    sections: ProjectBreadCrumbSection,
    classes: ClassNameMap<"root" | "common" | "prev" | "heading" | "main" | "secondary" | "lists" | "nested">,
    plusSign: any,
): any => {
    const router = useRouter();
    const { history, match } = useRouter();
    return (
        <>
            {sections.projectList && viewModel.canAddProject && !viewModel.isSiteManager && (
                <div className={classes.secondary}>
                    <Link underline="none" component={RouterLink} to={AppUrls.Client.Project.GeneralAdd}>
                        <img src={plusSign} className="add-image" alt="Add new project" />
                        <span className="add-text">Add new project</span>
                    </Link>
                </div>
            )}
            {sections.projectGeneral && projectId && viewModel.canAddProject && (
                <div className={classes.secondary}>
                    <Link component={RouterLink} to={AppUrls.Client.Project.GeneralEdit.replace(":projectid", projectId)}>
                        <img src={plusSign} className="add-image" alt="Edit project" />
                        <span className="add-text">Edit project</span>
                    </Link>
                </div>
            )}
            {sections.projectConstruction && projectId && viewModel.canEditProjectConstruction && (
                <div className={classes.secondary}>
                    <Link component={RouterLink} to={AppUrls.Client.Project.ConstructionEdit.replace(":projectid", projectId)}>
                        <img src={plusSign} className="add-image" alt="Edit project construction" />
                        <span className="add-text">Edit construction</span>
                    </Link>
                </div>
            )}

            {/* sections.projectCommercial && (
                <>
                    {projectId && (
                        <div className={classes.secondary}>
                            <div onClick={() => viewModel.setProjectView("edit")}>
                                <img src={plusSign} className="add-image" alt="Edit I & E Format" />
                                <span className="add-text">Edit I & E Format</span>
                            </div>
                        </div>
                    )}
                </>
            ) */}
            {sections.projectContractual && viewModel.canAddProject && (
                <>
                    {projectId && (
                        <div className={classes.secondary}>
                            <Link component={RouterLink} to={AppUrls.Client.Project.ContractualEdit.replace(":projectid", projectId)}>
                                <img src={plusSign} className="add-image" alt="Edit project contractual" />
                                <span className="add-text">Edit contractual</span>
                            </Link>
                        </div>
                    )}
                </>
            )}
            {/* sections.projectCommercial && projectId && (
                <>
                    <div className={classes.secondary}>
                        <div
                            onClick={() => {
                                viewModel.setProjectModal(true);
                                viewModel.setProjectView("master");
                            }}
                        >
                            <img src={plusSign} className="add-image" alt="Create new I & E" />
                            <span className="add-text">Create new I & E</span>
                        </div>
                    </div>
                </>
            ) */}
        </>
    );
};
