import React, { useContext } from "react";
import { Stores, StoresContext } from "Globals/Stores";
import { PrivateRoute } from "Globals/Views/PrivateRoute";
import * as RouterUtil from "Utils/Routing";
import { StoresInstance } from "Globals/Stores";
import { AppUrls } from "../../AppUrls";
import { DarwinLayout } from "Globals/Views/Layouts/DarwinLayout";
import { AllProjectsReportView } from "./AllProject/AllProjectsReportView";
import { IEReportView } from "./IE/IEReportView";
import { ProjectReportView } from "./Project/ProjectReportReportView";

export const ReportRoutes: React.FC = () => {
    function getRootLayout() {
        return DarwinLayout;
    }
    const store = useContext<Stores>(StoresContext);
    return (
        <>
            <PrivateRoute
                exact
                path={AppUrls.Client.Report.AllProject}
                component={AllProjectsReportView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn;
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Report.Project}
                component={ProjectReportView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn;
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Report.IE}
                component={IEReportView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn;
                }}
                layout={getRootLayout()}
            />
        </>
    );
};
