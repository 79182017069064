import { ModelBase } from "@shoothill/core";
import { IsNotEmpty, ValidateIf } from "class-validator";
import { observable, computed } from "mobx";

export class QualificationModel extends ModelBase<QualificationModel> {
    @observable
    public id: string = "";

    @observable
    public name: string = "";

    @observable
    @IsNotEmpty({ message: "You must select an option" })
    public passed: string = "";

    @observable
    @ValidateIf((o) => o.passed === "true" || o.passed === "false")
    @IsNotEmpty({ message: "You must select a value" })
    public typeLevel: string = "";

    @observable
    @ValidateIf((o) => o.passed === "true")
    @IsNotEmpty({ message: "You must select a value" })
    public idRollNumber: string = "";

    @observable
    @ValidateIf((o) => o.passed === "true")
    @IsNotEmpty({ message: "You must select a value" })
    public expiryDate: Date | null = null;

    @observable
    @ValidateIf((o) => o.passed === "true")
    @IsNotEmpty({ message: "You must select a value" })
    public photoEvidenceData: string = "";

    @observable
    public photoEvidenceFileName: string = "";

    @observable
    public photoEvidenceUrl: string = "";

    @observable
    public inductionSectionType: string = "";

    @observable
    public hasNAOption: boolean = false;

    constructor() {
        super();
    }

    @computed
    public get failedTypeLevelLabel(): string | null {
        // We may want to move this to be driven by the database if there are any more changes like this.
        if (this.name === "CSCS No.") {
            return `If no CSCS card is available you must list your experience and relevant qualifications to complete the work. The Site Manager will make the decision on whether or not it is acceptable.`;
        }
        return null;
    }

    public fromDto(dto: any): void {
        this.id = dto.id;
        this.name = dto.name;
        this.expiryDate = new Date();
        this.inductionSectionType = dto.inductionSectionType || "";
        this.hasNAOption = dto.hasNAOption;
    }

    public toDto(): any {
        let passed = this.passed === "na" ? null : this.passed;
        if (this.passed === "true") {
            return {
                id: this.id,
                name: this.name,
                passed: passed,
                typeLevel: this.typeLevel,
                inductionSectionType: this.inductionSectionType,
                idRollNumber: this.idRollNumber,
                expiryDate: this.expiryDate,
                photoEvidenceFileName: this.photoEvidenceFileName,
                photoEvidenceUrl: this.photoEvidenceUrl,
                photoEvidenceData: this.photoEvidenceData,
            };
        }

        return {
            id: this.id,
            name: this.name,
            passed: passed,
            typeLevel: this.typeLevel,
            inductionSectionType: this.inductionSectionType,
            idRollNumber: null,
            expiryDate: null,
            photoEvidenceFileName: null,
            photoEvidenceUrl: null,
            photoEvidenceData: null,
        };
    }
}
