import * as MobX from "mobx";
import { ModelBase } from "@shoothill/core";
import { IEGridModel } from "./IEmodels/IEGridModel";
import moment from "moment";
import { CommercialParentModelDTO } from "./Grid/CommercialGrid.Model";

export class CommercialModel extends ModelBase<CommercialModel, CommercialModelDTO> {
    public id: string = "";
    public createdDate: string = "";

    @MobX.observable
    public filterStartDate: string | null = null;

    @MobX.observable
    public filterEndDate: string | null = null;

    @MobX.action validateFilters = () => {
        let start: moment.Moment | null = null;
        let end: moment.Moment | null = null;

        if (this.filterStartDate !== null && this.filterStartDate !== undefined) {
            start = moment.utc(this.filterStartDate);
        }
        if (this.filterEndDate !== null && this.filterEndDate !== undefined) {
            end = moment.utc(this.filterEndDate);
        }

        if (start !== null && end !== null) {
            if (start > end) {
                this.filterStartDate = start.toISOString();
                this.filterEndDate = end.toISOString();
                return true;
            }
        } else {
            return true;
        }

        return false;
    };

    @MobX.action reset = () => {
        this.filterStartDate = null;
        this.filterEndDate = null;
    };

    fromDto(model: CommercialModelDTO): void {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    if (key === "commercialGrid") {
                        let ies: IEGridModel[] = [];
                        model.commercialGrid.ie.forEach((item) => {
                            let newIe: IEGridModel = new IEGridModel();
                            newIe.fromDto(item);
                            ies.push(newIe);
                        });
                        this[key] = ies;
                    } else {
                        this[key] = model[key];
                    }
                }
            }
        }
    }

    toDto(model: CommercialModel): void {}

    @MobX.action
    public clean = () => {
        this.filterStartDate = null;
        this.filterEndDate = null;
    };
}

export type CommercialModelDTO = {
    id: string;
    commercialGrid: CommercialParentModelDTO;
};
