import { FieldType, ViewModelBase } from "@shoothill/core";
import { CentralListModel } from "./CentralListModel";
import { observable, action, runInAction, computed } from "mobx";
import { AppUrls } from "AppUrls";
import { ProjectModel } from "Views/PurchaseOrder/Form/Supporting/ProjectModel";

export class CentralListViewModel extends ViewModelBase<CentralListModel> {
    private static _instance: CentralListViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    public constructor() {
        super(new CentralListModel(), false);
        this.setDecorators(CentralListModel);
    }

    @observable public isLoadingIE: boolean = false;

    @action
    public setIsLoadingIE = (val: boolean) => {
        this.isLoadingIE = val;
    };

    @computed
    public get getIsLoadingIE() {
        return this.isLoadingIE;
    }

    @observable public errorMessage: string = "";

    @observable public ieId: string | null = null;

    @action
    public setIEId = (val: string | null) => {
        this.ieId = val;
    };

    @computed
    public get hasIEId() {
        return this.ieId !== "" && this.ieId !== null && this.ieId !== undefined;
    }

    @observable public projectId: string | null = null;

    @observable
    public centralProjects = observable<ProjectModel>([]);

    @computed
    public get centralProjectOptions() {
        return this.centralProjects.filter((p) => p.isCentralProject === true).sort((a, b) => a.displayName.localeCompare(b.displayName));
    }

    @computed
    public get currentCentralProject() {
        const result = this.centralProjects.find((p) => p.id === this.projectId);

        return result ? result! : null;
    }

    @action
    public setCurrentCentralProject = async (value: ProjectModel | null, getData: boolean = true) => {
        this.projectId = value ? value.id : "";

        if (value && getData) {
            await this.getCentralProjectViewByProjectId(value.id);
        }
    };

    @action
    public getCentralProjectViewByProjectId = async (projectid: string | undefined | null): Promise<void> => {
        this.setIsLoadingIE(true);
        let apiResult = await this.Post<string>(AppUrls.Server.Central.GetCentralProjectViewByProjectId, { id: projectid });
        if (apiResult) {
            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.setIEId(null);
                    this.history.push(AppUrls.Client.Central.View.replace(":ieid", apiResult.payload));
                    this.setIEId(apiResult.payload);
                });
            } else {
                console.log(apiResult.errors);
            }
        }
        this.setIsLoadingIE(false);
    };

    @action
    public cleanUp = () => {
        // TODO Any Cleanup Code here. e.g. if  a user or project or client etc, wipe it from the instance on page shutdown
        this.setIEId(null);
    };

    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (await this.isModelValid()) {
            //Do stuff here
            this.errorMessage = "Form is valid";
        } else {
            this.errorMessage = "Form is not valid";
        }
    };

    @action
    public getLatestCentralProjectView = async (ieid: string | undefined | null): Promise<void> => {
        let apiResult = await this.Post<string>(AppUrls.Server.Central.GetLatestCentralProjectView);
        if (apiResult) {
            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    if (!ieid || ieid === "" || ieid === ":ieid") {
                        this.history.push(AppUrls.Client.Central.View.replace(":ieid", apiResult.payload));
                    }
                    this.setIEId(apiResult.payload);
                });
            } else {
                console.log(apiResult.errors);
            }
        }
    };

    public async isFieldValid(fieldName: keyof FieldType<CentralListModel>): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
