// Libraries
import React, { useState } from "react";
import { useObserver } from "mobx-react-lite";
import { Cell, Grid, useRouter } from "@shoothill/core";
import { Checkbox, FormControlLabel, InputAdornment, Link, TextField } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

// Styling & Images
import { DetailsHeader } from "Globals/Views/DetailsPage/DetailsHeader";
import { DetailsPage } from "Globals/Views/DetailsPage/DetailsPage.styles";
import { IEFutureSpendAmendmentViewModel } from "./IEFutureSpendAmendmentViewModel";
import { IEFutureSpendAmdnementDTO as IEFutureSpendAmendmentDTO, IEFutureSpendAmendmentModel } from "./IEFutureSpendAmendmentModel";
import { AppUrls } from "AppUrls";
import { formatCurrencyFromPounds, formatDateTime } from "Utils/Format";
import MoneySVG from "Content/Money.svg";
import { DarwinMoneyInput } from "Globals/Styles/Control/DarwinInput";
import {
    FutureSpendAmendmentAddButton,
    FutureSpendItem,
    FutureSpendItemContentRow,
    FutureSpendItemDetailsRow,
    FutureSpendItemNoteRow,
    FutureSpendPORefRow,
    NewFutureSpendContainer,
    NewFutureSpendLabel,
} from "./IEFutureSpendAmendment.styles";
import { BackButtonHolder } from "../IETables/IEGrid.Styles";
import { CustomArrow } from "Views/Project/CustomComponents";
import { theme } from "Globals/Styles/AppTheme";
import { IEGridItemViewModel } from "../IEmodels/IEGridItemViewModel";
import { UnapprovedPurchaseOrderLineValidationModal } from "Views/PurchaseOrder/Form/Supporting/UnapprovedPurchaseOrderLineValidationModal/UnapprovedPurchaseOrderLineValidationModal";
import { SnackBar } from "Components/SnackBar/SnackBar";

export const IEFutureSpendAmendmentView: React.FunctionComponent = () => {
    const { match } = useRouter();
    const { ieid, ieitemid } = match.params as any;
    const [viewModel] = useState(() => new IEFutureSpendAmendmentViewModel(ieid, ieitemid));
    const { history } = useRouter();

    const setShowMatchingUnapprovedRequisitionsModal = (val: boolean) => {
        viewModel.handleShowMatchingUnapprovedRequisitionsModalChange(val);
    };

    const COLUMNS8 = "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr";
    const COLUMNS12 = "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr";

    const renderProjectImpactFormatted = (projectImpact: number) => {
        let className = "value-orange";
        let icon = <>&#8594;</>;

        if (projectImpact > 0) {
            className = "value-green";
            icon = <>&#8593;</>;
        } else if (projectImpact < 0) {
            className = "value-red";
            icon = <>&#8595;</>;
        }

        return (
            <>
                <div>
                    Line impact:{" "}
                    <span className={className}>
                        {icon} {formatCurrencyFromPounds(projectImpact)}
                    </span>
                </div>
            </>
        );
    };

    const renderFutureSpendHistoryAmendment = (item: IEFutureSpendAmendmentDTO) => {
        return (
            <FutureSpendItem>
                <FutureSpendItemContentRow>
                    <div>{item.futureSpendType}</div>
                    <div></div>
                    <div className="future-spend-group">
                        <div>Previous: {formatCurrencyFromPounds(item.previousFutureSpend)}</div>
                        <div>New: {formatCurrencyFromPounds(item.futureSpend)}</div>
                        {renderProjectImpactFormatted(item.projectImpact)}
                    </div>
                </FutureSpendItemContentRow>
                <FutureSpendItemDetailsRow>
                    <div>
                        <div>
                            <strong>Created by:</strong>
                        </div>
                        <div>{item.amendmentCreatedByUserName}</div>
                    </div>
                    <div>
                        <div>
                            <strong>Created date:</strong>
                        </div>
                        <div>{formatDateTime(item.createdDate)}</div>
                    </div>
                </FutureSpendItemDetailsRow>
                <FutureSpendItemNoteRow>
                    <div>
                        <strong>Notes:</strong>
                    </div>
                    <div className={"keep-newline-formatting"}>{item.note}</div>
                </FutureSpendItemNoteRow>
            </FutureSpendItem>
        );
    };

    const renderFutureSpendHistoryPO = (item: IEFutureSpendAmendmentDTO) => {
        return (
            <FutureSpendItem>
                <FutureSpendItemContentRow>
                    <div>
                        <img src={MoneySVG} /> {item.futureSpendType}
                    </div>
                    <div>
                        <div>Line value: {formatCurrencyFromPounds(item.orderValue)}</div>
                        <div>Line value change: {formatCurrencyFromPounds(item.orderValueDifference)}</div>
                    </div>
                    <div className="future-spend-group">
                        <div>Previous: {formatCurrencyFromPounds(item.previousFutureSpend)}</div>
                        <div>New: {formatCurrencyFromPounds(item.futureSpend)}</div>
                        {renderProjectImpactFormatted(item.projectImpact)}
                    </div>
                </FutureSpendItemContentRow>
                <FutureSpendPORefRow>
                    Ref:{" "}
                    <Link component={RouterLink} to={AppUrls.Client.PurchaseOrder.Edit.replace(":poid", item.poId).replace(":approvalMode", "false")}>
                        {item.formattedPONumber}
                    </Link>
                </FutureSpendPORefRow>
                <FutureSpendItemDetailsRow>
                    <div>
                        <div>
                            <strong>Created by:</strong>
                        </div>
                        <div>{item.amendmentCreatedByUserName}</div>
                    </div>
                    <div>
                        <div>
                            <strong>Created date:</strong>
                        </div>
                        <div>{formatDateTime(item.createdDate)}</div>
                    </div>
                </FutureSpendItemDetailsRow>
                <FutureSpendItemNoteRow>
                    <div>
                        <strong>Notes:</strong>
                    </div>
                    <div>{item.note}</div>
                </FutureSpendItemNoteRow>
            </FutureSpendItem>
        );
    };

    const renderFutureSpendHistoryIEItem = (item: IEFutureSpendAmendmentDTO) => {
        return (
            <FutureSpendItem>
                <FutureSpendItemContentRow>
                    <div>{item.futureSpendType}</div>
                    <div>
                        <div>Line value: {formatCurrencyFromPounds(item.orderValue)}</div>
                        <div>Line value change: {formatCurrencyFromPounds(item.orderValueDifference)}</div>
                    </div>
                    <div className="future-spend-group">
                        <div>Previous: £0.00</div>
                        <div>New: {formatCurrencyFromPounds(item.futureSpend)}</div>
                        {renderProjectImpactFormatted(item.projectImpact)}
                    </div>
                </FutureSpendItemContentRow>
                <FutureSpendItemDetailsRow>
                    <div>
                        <div>
                            <strong>Created by:</strong>
                        </div>
                        <div>{item.amendmentCreatedByUserName}</div>
                    </div>
                    <div>
                        <div>
                            <strong>Created date:</strong>
                        </div>
                        <div>{formatDateTime(item.createdDate)}</div>
                    </div>
                </FutureSpendItemDetailsRow>
            </FutureSpendItem>
        );
    };

    const renderFutureSpendHistory = () => {
        return viewModel.ieFutureSpendAmendmentItemsFiltered.map((item, index) => {
            if (item.isPO) {
                return renderFutureSpendHistoryPO(item);
            } else if (item.isAmendment) {
                return renderFutureSpendHistoryAmendment(item);
            } else if (!item.isPO && !item.isAmendment) {
                return renderFutureSpendHistoryIEItem(item);
            }
        });
    };

    return useObserver(() => (
        <DetailsPage>
            <BackButtonHolder
                onClick={() => {
                    if (IEGridItemViewModel.Instance.isCentral) {
                        history.push(AppUrls.Client.Central.View.replace(":ieid", ieid ? ieid : viewModel.model.ieId));
                    } else {
                        history.push(AppUrls.Client.Project.IE.replace(":ieid", ieid ? ieid : viewModel.model.ieId));
                    }
                }}
                style={{ minHeight: "30px", paddingTop: "3px" }}
            >
                <div style={{ position: "relative", height: "30px", display: "flex", marginLeft: "10px" }}>
                    <div style={{ position: "absolute", top: "5px" }}>
                        <CustomArrow size={"8px"} color={theme.palette.blue.main} type={"left"} />
                    </div>
                    <div style={{ marginLeft: "20px", fontSize: "18px", fontWeight: "bold", color: theme.palette.blue.main }}>Back to {viewModel.getIETitle}</div>
                </div>
            </BackButtonHolder>
            <DetailsHeader viewModel={viewModel.getHeader} />
            <div style={{ margin: "0 30px 17.5px 30px" }}>
                <NewFutureSpendContainer>
                    <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
                        {/* Group of 8 columns */}
                        <Cell ts={1} ds={1}>
                            <NewFutureSpendLabel>New future spend</NewFutureSpendLabel>
                        </Cell>
                        <Cell ts={1} ds={1}>
                            <DarwinMoneyInput<IEFutureSpendAmendmentModel>
                                type="number"
                                label=""
                                placeholder=""
                                validateOnBlur={true}
                                viewModel={viewModel}
                                fieldName="futureSpend"
                                shrink={true}
                                maxLength={32}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                }}
                                editMode={viewModel.canAddFutureSpendAmendment}
                            />
                        </Cell>
                    </Grid>

                    <TextField
                        id="amendment_notes"
                        multiline
                        rows={6}
                        value={viewModel.model.note}
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => viewModel.setNote(event.target.value)}
                        fullWidth
                        className="textarea"
                        label="Notes"
                        placeholder="Notes"
                        disabled={!viewModel.canAddFutureSpendAmendment}
                    />

                    <FutureSpendAmendmentAddButton displayName="Add" execute={viewModel.handleSave} fullWidth={true} canExecute={viewModel.canAddFutureSpendAmendment} />
                </NewFutureSpendContainer>

                <FormControlLabel
                    control={<Checkbox checked={viewModel.filterNoImpactItems} onChange={viewModel.setFilterNoImpactItems} />}
                    label={"Remove revisions with no impact?"}
                    name={`filterNoImpactItems-checkbox`}
                    disabled={viewModel.IsLoading}
                />

                {renderFutureSpendHistory()}

                {/* <Grid columnGap={30} rowGap={20} tc={"1fr 1fr 1fr 1fr"} dc={"1fr 1fr 1fr 1fr"}>
                    {viewModel!.server.HaveValidationMessage && (
                        <Cell tabletSpan={4} desktopSpan={4}>
                            <Alert severity="error">
                                <AlertTitle>Error</AlertTitle>
                                {viewModel!.server.ValidationMessage}
                            </Alert>
                        </Cell>
                    )}
                </Grid> */}
            </div>
            <UnapprovedPurchaseOrderLineValidationModal
                onClose={() => setShowMatchingUnapprovedRequisitionsModal(false)}
                open={viewModel.getShowMatchingUnapprovedRequisitionsModal}
                title="There is an unapproved order against this line."
                message={"There is an unapproved order against this line. Please wait until that order has been approved."}
                items={viewModel.matchingUnapprovedRequisitions}
                isLoading={viewModel.IsLoading}
            />

            <SnackBar
                messageText={viewModel.snackMessage}
                messageType={viewModel.snackType}
                active={viewModel.snackbarState}
                closeOption={() => viewModel.setSnackbarState(false)}
                autoHideDuration={5000}
            />
        </DetailsPage>
    ));
};
