import { FieldType, ViewModelBase, isNullOrEmpty } from "@shoothill/core";
import { observable, action } from "mobx";
import { RAMSChoiceModel } from "./RAMSChoiceModel";
import { RAMSModel } from "./RAMSModel";

export class RAMSViewModel extends ViewModelBase<RAMSModel> {
    private static _instance: RAMSViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable public errorMessage: string = "";

    public constructor() {
        super(new RAMSModel(), false);
        this.setDecorators(RAMSModel);
    }

    @action
    public cleanUp = () => {
        // TODO Any Cleanup Code here. e.g. if  a user or project or client etc, wipe it from the instance on page shutdown
    };

    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (await this.isMyModelValid()) {
            //Do stuff here
            this.errorMessage = "Form is valid";
        } else {
            this.errorMessage = "Form is not valid";
        }
    };

    public async isFieldValid(fieldName: keyof FieldType<RAMSModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public get isFirstNameValid(): string {
        let error = "";

        // const field: string = this.getValue<string>("firstName");

        // if (isNullOrEmpty(field)) {
        //     error = "First name is required";
        // } else if (field!.length > 128) {
        //     error = "First name needs to be less than 128 characters";
        // }

        return error;
    }

    private isMyModelValid = async (): Promise<boolean> => {
        let isValid = true;

        // If you have a child form, you can call it's own viewmodels validation from here.
        /*if ((await this.addressFormViewModel.isModelValid()) === false) {
            isValid = false;
        }*/

        // Could also perform this in a loop if you have a list of child forms.
        /*for (let i = 0; i < this.shiftViewModels.length; i++) {
            let shift = this.shiftViewModels[i];

            if (shift.model.isEmptyForm === false) {
                if ((await shift.isModelValid()) === false) {
                    isValid = false;
                }
            }
        }*/

        // this will automatically call isFieldValid.
        if ((await this.isModelValid()) === false) {
            isValid = false;
        }
        return isValid;
    };

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
