import { IconButton, Typography } from "@material-ui/core";
import SystemUpdateTwoToneIcon from "@material-ui/icons/SystemUpdateTwoTone";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { FieldType } from "@shoothill/core/dist/Models";
import { uniqueId } from "lodash-es";
import { observer, useObserver } from "mobx-react-lite";
import React from "react";
import styled from "styled-components";

import { SHBox } from "Components/Box";
import { Uploader } from "Components/Uploader/Uploader";
import { DarwinInput } from "Globals/Styles/Control/DarwinInput";
import { ScaffoldInspectionDetailModel } from "./ScaffoldInspectionDetailModel";
import { ScaffoldInspectionDetailViewModel } from "./ScaffoldInspectionDetailViewModel";
import { ScaffoldInspectionViewModel } from "../ScaffoldInspectionViewModel";
import { ScaffoldInspectionRadioReadOnlyView, ScaffoldInspectionRadioView } from "./Components/Question";

interface Props {
    index: number;
    scaffoldInspectionDetailViewModel: ScaffoldInspectionDetailViewModel;
    scaffoldInspectionViewModel: ScaffoldInspectionViewModel;
}

const QuestionsContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const ExtendedQuestionRow = styled.div`
    margin-bottom: 15px;
    margin-top: 3px;

    &:not(:last-child) {
        border-bottom: 2px dashed #ced4da;
    }
`;

export const ScaffoldInspectionDetailView: React.FC<Props> = observer((props: Props) => {
    const { scaffoldInspectionDetailViewModel, scaffoldInspectionViewModel } = props;

    return (
        <>
            <SHBox key={uniqueId()}>
                <SHBox style={{ margin: "15px 0" }}>
                    <Typography variant={"h4"}>Scaffold {props.index + 1}:</Typography>
                </SHBox>
                {!scaffoldInspectionViewModel.editable && (
                    <SHBox grid dc={"282px 282px 282px"} mt={2} mb={3}>
                        <SHBox display="flex" flexDirection="column">
                            <Typography component="span" style={{ marginBottom: "5px" }} variant={"h5"}>
                                Name of company that erected the scaffold:{" "}
                            </Typography>
                            <Typography component="span" style={{ fontWeight: "normal" }} variant={"h5"}>
                                {scaffoldInspectionDetailViewModel.model.companyName}
                            </Typography>
                        </SHBox>
                        <SHBox display="flex" flexDirection="column">
                            <Typography component="span" style={{ marginBottom: "5px" }} variant={"h5"}>
                                TG20-Compliance sheet number used:{" "}
                            </Typography>
                            <Typography component="span" style={{ fontWeight: "normal" }} variant={"h5"}>
                                {scaffoldInspectionDetailViewModel.model.complianceSheetNum}
                            </Typography>
                        </SHBox>
                        <SHBox display="flex" flexDirection="column">
                            <Typography component="span" style={{ marginBottom: "5px" }} variant={"h5"}>
                                Scaffold plan used:{" "}
                            </Typography>
                            <Typography component="span" style={{ fontWeight: "normal" }} variant={"h5"}>
                                {scaffoldInspectionDetailViewModel.model.scaffoldPlan}
                            </Typography>
                        </SHBox>
                    </SHBox>
                )}
                {scaffoldInspectionViewModel.editable && (
                    <SHBox grid dc={"262px 262px 262px"} mt={2} mb={3}>
                        <DarwinInput<ScaffoldInspectionDetailViewModel>
                            type="text"
                            label="Name of company that erected the scaffold:"
                            placeholder="Please add"
                            validateOnBlur={false}
                            viewModel={scaffoldInspectionDetailViewModel}
                            fieldName={"companyName"}
                            shrink={true}
                            maxLength={4096}
                            editMode={scaffoldInspectionViewModel.editable}
                        />
                        <DarwinInput<ScaffoldInspectionDetailViewModel>
                            type="text"
                            label="TG20-Compliance sheet number used:"
                            placeholder="Please add"
                            validateOnBlur={false}
                            viewModel={scaffoldInspectionDetailViewModel}
                            fieldName={"complianceSheetNum"}
                            shrink={true}
                            maxLength={4096}
                            editMode={scaffoldInspectionViewModel.editable}
                        />
                        <DarwinInput<ScaffoldInspectionDetailViewModel>
                            type="text"
                            label="Scaffold plan used:"
                            placeholder="Please add"
                            validateOnBlur={false}
                            viewModel={scaffoldInspectionDetailViewModel}
                            fieldName={"scaffoldPlan"}
                            shrink={true}
                            maxLength={4096}
                            editMode={scaffoldInspectionViewModel.editable}
                        />
                    </SHBox>
                )}
            </SHBox>
            <QuestionsContainer>
                <ScaffoldInspectionCompoundComponent
                    fieldName={"numberOfTies"}
                    title={"Number of ties"}
                    scaffoldInspectionDetailViewModel={scaffoldInspectionDetailViewModel}
                    editable={scaffoldInspectionViewModel.editable}
                    isLoading={scaffoldInspectionViewModel.IsLoading}
                />
                <ScaffoldInspectionCompoundComponent
                    fieldName={"numberOfLifts"}
                    title={"Number of lifts"}
                    scaffoldInspectionDetailViewModel={scaffoldInspectionDetailViewModel}
                    editable={scaffoldInspectionViewModel.editable}
                    isLoading={scaffoldInspectionViewModel.IsLoading}
                />
                <ScaffoldInspectionCompoundComponent
                    fieldName={"groundFirm"}
                    title={"Ground firm enough to take weight"}
                    scaffoldInspectionDetailViewModel={scaffoldInspectionDetailViewModel}
                    editable={scaffoldInspectionViewModel.editable}
                    isLoading={scaffoldInspectionViewModel.IsLoading}
                />
                <ScaffoldInspectionCompoundComponent
                    fieldName={"soleBoards"}
                    title={"Sole boards- in correct place and in good condition"}
                    scaffoldInspectionDetailViewModel={scaffoldInspectionDetailViewModel}
                    editable={scaffoldInspectionViewModel.editable}
                    isLoading={scaffoldInspectionViewModel.IsLoading}
                />
                <ScaffoldInspectionCompoundComponent
                    fieldName={"basePlates"}
                    title={"Base plates in good condition and in centre of sole board"}
                    scaffoldInspectionDetailViewModel={scaffoldInspectionDetailViewModel}
                    editable={scaffoldInspectionViewModel.editable}
                    isLoading={scaffoldInspectionViewModel.IsLoading}
                />
                <ScaffoldInspectionCompoundComponent
                    fieldName={"scaffoldPlumb"}
                    title={"Scaffold plumb, square and level"}
                    scaffoldInspectionDetailViewModel={scaffoldInspectionDetailViewModel}
                    editable={scaffoldInspectionViewModel.editable}
                    isLoading={scaffoldInspectionViewModel.IsLoading}
                />
                <ScaffoldInspectionCompoundComponent
                    fieldName={"structureSame"}
                    title={"Structure the same as the original design"}
                    scaffoldInspectionDetailViewModel={scaffoldInspectionDetailViewModel}
                    editable={scaffoldInspectionViewModel.editable}
                    isLoading={scaffoldInspectionViewModel.IsLoading}
                />
                <ScaffoldInspectionCompoundComponent
                    fieldName={"standardsUpright"}
                    title={"Standards- upright members in good condition"}
                    scaffoldInspectionDetailViewModel={scaffoldInspectionDetailViewModel}
                    editable={scaffoldInspectionViewModel.editable}
                    isLoading={scaffoldInspectionViewModel.IsLoading}
                />
                <ScaffoldInspectionCompoundComponent
                    fieldName={"ledgersCondition"}
                    title={"Ledgers in good condition and in place"}
                    scaffoldInspectionDetailViewModel={scaffoldInspectionDetailViewModel}
                    editable={scaffoldInspectionViewModel.editable}
                    isLoading={scaffoldInspectionViewModel.IsLoading}
                />
                <ScaffoldInspectionCompoundComponent
                    fieldName={"transomsCondition"}
                    title={"Transoms in good condition and in place"}
                    scaffoldInspectionDetailViewModel={scaffoldInspectionDetailViewModel}
                    editable={scaffoldInspectionViewModel.editable}
                    isLoading={scaffoldInspectionViewModel.IsLoading}
                />
                <ScaffoldInspectionCompoundComponent
                    fieldName={"tieSpacing"}
                    title={"Tie spacing and capacity"}
                    scaffoldInspectionDetailViewModel={scaffoldInspectionDetailViewModel}
                    editable={scaffoldInspectionViewModel.editable}
                    isLoading={scaffoldInspectionViewModel.IsLoading}
                />
                <ScaffoldInspectionCompoundComponent
                    fieldName={"facadeBracings"}
                    title={"Facade bracings"}
                    scaffoldInspectionDetailViewModel={scaffoldInspectionDetailViewModel}
                    editable={scaffoldInspectionViewModel.editable}
                    isLoading={scaffoldInspectionViewModel.IsLoading}
                />
                <ScaffoldInspectionCompoundComponent
                    fieldName={"planBracings"}
                    title={"Plan bracings"}
                    scaffoldInspectionDetailViewModel={scaffoldInspectionDetailViewModel}
                    editable={scaffoldInspectionViewModel.editable}
                    isLoading={scaffoldInspectionViewModel.IsLoading}
                />
                <ScaffoldInspectionCompoundComponent
                    fieldName={"crossBracings"}
                    title={"Cross bracings"}
                    scaffoldInspectionDetailViewModel={scaffoldInspectionDetailViewModel}
                    editable={scaffoldInspectionViewModel.editable}
                    isLoading={scaffoldInspectionViewModel.IsLoading}
                />
                <ScaffoldInspectionCompoundComponent
                    fieldName={"guardRails"}
                    title={"Guard rails in place and at correct heights"}
                    scaffoldInspectionDetailViewModel={scaffoldInspectionDetailViewModel}
                    editable={scaffoldInspectionViewModel.editable}
                    isLoading={scaffoldInspectionViewModel.IsLoading}
                />
                <ScaffoldInspectionCompoundComponent
                    fieldName={"toeBoards"}
                    title={"Toe boards- all in place and correctly secured"}
                    scaffoldInspectionDetailViewModel={scaffoldInspectionDetailViewModel}
                    editable={scaffoldInspectionViewModel.editable}
                    isLoading={scaffoldInspectionViewModel.IsLoading}
                />
                <ScaffoldInspectionCompoundComponent
                    fieldName={"staircase"}
                    title={"Any staircases incorporated in good condition"}
                    scaffoldInspectionDetailViewModel={scaffoldInspectionDetailViewModel}
                    editable={scaffoldInspectionViewModel.editable}
                    isLoading={scaffoldInspectionViewModel.IsLoading}
                />
                <ScaffoldInspectionCompoundComponent
                    fieldName={"scaffoldBoards"}
                    title={"Scaffold boards free from defects and butted up to other boards"}
                    scaffoldInspectionDetailViewModel={scaffoldInspectionDetailViewModel}
                    editable={scaffoldInspectionViewModel.editable}
                    isLoading={scaffoldInspectionViewModel.IsLoading}
                />
                <ScaffoldInspectionCompoundComponent
                    fieldName={"scaffoldSigns"}
                    title={"Scaffold signs and tags"}
                    scaffoldInspectionDetailViewModel={scaffoldInspectionDetailViewModel}
                    editable={scaffoldInspectionViewModel.editable}
                    isLoading={scaffoldInspectionViewModel.IsLoading}
                />
                <ScaffoldInspectionCompoundComponent
                    fieldName={"loadingIn"}
                    title={"Loading in line with design and shown"}
                    scaffoldInspectionDetailViewModel={scaffoldInspectionDetailViewModel}
                    editable={scaffoldInspectionViewModel.editable}
                    isLoading={scaffoldInspectionViewModel.IsLoading}
                />
                <ScaffoldInspectionCompoundComponent
                    fieldName={"laddersGood"}
                    title={"Ladders in good condition, at correct angle and tied"}
                    scaffoldInspectionDetailViewModel={scaffoldInspectionDetailViewModel}
                    editable={scaffoldInspectionViewModel.editable}
                    isLoading={scaffoldInspectionViewModel.IsLoading}
                />
                <ScaffoldInspectionCompoundComponent
                    fieldName={"accessGood"}
                    title={"Access onto scaffolding"}
                    scaffoldInspectionDetailViewModel={scaffoldInspectionDetailViewModel}
                    editable={scaffoldInspectionViewModel.editable}
                    isLoading={scaffoldInspectionViewModel.IsLoading}
                />
            </QuestionsContainer>
        </>
    );
});

interface ScaffoldInspectionCompoundComponentProps {
    fieldName: keyof FieldType<ScaffoldInspectionDetailModel>;
    title: string;
    scaffoldInspectionDetailViewModel: ScaffoldInspectionDetailViewModel;
    editable: boolean;
    isLoading: boolean;
}

function ScaffoldInspectionCompoundComponent(props: ScaffoldInspectionCompoundComponentProps) {
    const { scaffoldInspectionDetailViewModel, editable, isLoading } = props;

    // This is the readonly view.
    if (!props.editable) {
        return useObserver(() => (
            <>
                <ScaffoldInspectionRadioReadOnlyView title={props.title} value={() => scaffoldInspectionDetailViewModel.getValue(props.fieldName)} />
                {scaffoldInspectionDetailViewModel.model[props.fieldName + "Detail"] !== "" && (
                    <ExtendedQuestionRow style={{ paddingBottom: "15px" }}>
                        <Typography style={{ marginBottom: "5px" }} variant={"h5"}>
                            {"Please give more details:"}
                        </Typography>
                        <Typography style={{ fontWeight: "normal", whiteSpace: "pre-wrap", wordWrap: "break-word" }} variant={"h5"}>
                            {scaffoldInspectionDetailViewModel.model[props.fieldName + "Detail"]}
                        </Typography>
                        {scaffoldInspectionDetailViewModel.model[props.fieldName + "FileName"] !== "" && (
                            <React.Fragment>
                                <Typography style={{ marginTop: "10px" }} variant={"h5"}>
                                    Photo:
                                </Typography>
                                <SHBox grid dc={"1fr 1fr"} className="uploadedFile">
                                    <div className="uploadedFile-box" key={uniqueId()}>
                                        <div className="uploadedFile-heading">
                                            <IconButton onClick={() => scaffoldInspectionDetailViewModel.DownloadFile(props.fieldName)}>
                                                <SystemUpdateTwoToneIcon />
                                            </IconButton>
                                            <h3>{scaffoldInspectionDetailViewModel.model[props.fieldName + "FileName"]}</h3>
                                        </div>
                                    </div>
                                </SHBox>
                            </React.Fragment>
                        )}
                    </ExtendedQuestionRow>
                )}
            </>
        ));
    }

    // This is the default editable view.
    return useObserver(() => (
        <>
            <ScaffoldInspectionRadioView
                title={props.title}
                value={() => scaffoldInspectionDetailViewModel.getValue(props.fieldName)}
                validationMessage={() => scaffoldInspectionDetailViewModel.getError(props.fieldName)}
                onChange={(e) => scaffoldInspectionDetailViewModel.radioChanged(e, props.fieldName, true)}
            />
            {scaffoldInspectionDetailViewModel.getValue(props.fieldName) === "fail" && (
                <ExtendedQuestionRow>
                    <DarwinInput<ScaffoldInspectionDetailViewModel>
                        type="text"
                        label="Please give more details"
                        multiLine={true}
                        validateOnBlur={false}
                        viewModel={scaffoldInspectionDetailViewModel}
                        fieldName={props.fieldName + "Detail"}
                        shrink={true}
                        maxLength={4096}
                        editMode={editable}
                    />
                    <SHBox grid dc={"1fr 1fr"} style={{ marginTop: "10px" }} showIf={scaffoldInspectionDetailViewModel.model[props.fieldName + "FileName"] === ""}>
                        <Uploader
                            handleSelectFile={(e: React.ChangeEvent<HTMLInputElement>) => scaffoldInspectionDetailViewModel.fileChange(e, props.fieldName)}
                            isFormDisabled={isLoading}
                            label="Photo"
                        />
                    </SHBox>
                    <SHBox
                        grid
                        dc={"1fr 1fr"}
                        style={{ marginTop: "10px" }}
                        showIf={scaffoldInspectionDetailViewModel.model[props.fieldName + "FileName"] !== ""}
                        pt={2}
                        pb={3}
                        className="uploadedFile"
                    >
                        <div className="uploadedFile-box" key={uniqueId()}>
                            <div className="uploadedFile-heading">
                                <IconButton onClick={() => scaffoldInspectionDetailViewModel.DownloadFile(props.fieldName)}>
                                    <SystemUpdateTwoToneIcon />
                                </IconButton>
                                <h3>{scaffoldInspectionDetailViewModel.model[props.fieldName + "FileName"]}</h3>
                            </div>
                            <IconButton onClick={() => scaffoldInspectionDetailViewModel.deleteDocument(props.fieldName)} disabled={false}>
                                <DeleteOutlinedIcon />
                            </IconButton>
                        </div>
                    </SHBox>
                </ExtendedQuestionRow>
            )}
        </>
    ));
}
