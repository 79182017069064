import { FieldType, observable, ViewModelBase } from "@shoothill/core";
import { ApprovalItem, NotificationBarModel } from "./NotificationBarModel";
import { action, computed } from "mobx";

export class NotificationBarViewModel extends ViewModelBase<NotificationBarModel> {
    private static _instance: NotificationBarViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    constructor() {
        super(new NotificationBarModel());
    }

    @observable
    public isActive = false;

    @action
    public setIsActive = (val: boolean) => {
        this.isActive = val;
    };

    @action
    public setItem = (val: ApprovalItem) => {
        this.model.item = val;
    };

    @computed
    public get getItem() {
        return this.model.item;
    }

    @action
    public setPath = (val: string) => {
        this.model.path = val;
    };

    @computed
    public get getPath() {
        return this.model.path;
    }

    //necessary boilerplate
    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public async isFieldValid(fieldName: keyof FieldType<NotificationBarModel>): Promise<boolean> {
        return true;
    }
}
