// Libraries
import React from "react";
import { useObserver } from "mobx-react-lite";

// Custom
import { TextField } from "@material-ui/core";

// Styling & Images
import { RowDash } from "Views/Project/CustomComponents";
import { VariationItemViewModel } from "./VariationItemViewModel";

interface ITableProps {
    itemViewModel: VariationItemViewModel;
    rowIndex: number;
    subCategoryIndex: number;
    categoryIndex: number;
    currentActiveCatIndex: number;
    currentActiveSubCatIndex: number;
    currentActiveDescIndex: number;
    isDisabled: boolean;
}

export const VariationItem: React.FunctionComponent<ITableProps> = (props) => {
    const { itemViewModel, rowIndex, categoryIndex, subCategoryIndex, currentActiveCatIndex, currentActiveSubCatIndex, currentActiveDescIndex, isDisabled } = props;

    const handleName = () => {
        if (itemViewModel.model.descriptionId === -2 && itemViewModel.model.descriptionOther !== null) {
            return itemViewModel.model.descriptionOther;
        } else {
            return itemViewModel.model.displayName;
        }
    };

    return useObserver(() => (
        <>
            <li className="item" key={"item_" + rowIndex}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div
                        style={{
                            color: `${
                                currentActiveCatIndex === categoryIndex && currentActiveSubCatIndex === subCategoryIndex && currentActiveDescIndex === rowIndex ? "#0095DA" : ""
                            }`,
                            paddingLeft: "35px",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                    >
                        <RowDash style={{ display: "inline-block", marginRight: "10px" }} />
                        <span style={{ display: "inline-block" }}>{handleName()}</span>
                    </div>
                </div>
                <div className="right">
                    <TextField
                        id=""
                        type="number"
                        value={itemViewModel.model.quantity}
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => itemViewModel.setQuantity(event.target.value)}
                        fullWidth
                        error={itemViewModel.getError("quantity") !== ""}
                        placeholder={"0"}
                        disabled={props.isDisabled}
                    />
                </div>
                <div className="right">
                    <TextField
                        id=""
                        type="text"
                        value={itemViewModel.model.variationUnitName}
                        onChange={(event: any) => itemViewModel.setValue("variationUnitName", event.target.value)}
                        fullWidth
                        error={itemViewModel.getError("variationUnitName") !== ""}
                        placeholder={"enter a unit name"}
                        disabled={props.isDisabled}
                    />
                </div>
                <div className="right">
                    <TextField
                        id=""
                        type="number"
                        value={itemViewModel.model.rate}
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => itemViewModel.setRate(event.target.value)}
                        fullWidth
                        error={itemViewModel.getError("rate") !== ""}
                        placeholder={"0.00"}
                        disabled={props.isDisabled}
                    />
                </div>
                <div className="right">{itemViewModel.lineTotalFormatted}</div>
                <div className="right">{itemViewModel.committedCostFormatted}</div>
                <div className="right">
                    <TextField
                        id=""
                        type="number"
                        value={itemViewModel.model.futureSpend}
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => itemViewModel.setFutureSpend(event.target.value)}
                        fullWidth
                        error={itemViewModel.getError("futureSpend") !== ""}
                        placeholder={"0.00"}
                        disabled={props.isDisabled}
                    />
                </div>
                <div className="right">{itemViewModel.varianceFormatted}</div>
                <div className="right"></div>
            </li>
        </>
    ));
};
