import { getHistory, FieldType, isEmptyOrWhitespace, ViewModelBase, ApiResult, isNullOrEmpty, KeyValuePair } from "@shoothill/core";
import { action, computed, observable, runInAction } from "mobx";

import { AppUrls } from "AppUrls";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import {
    InvoiceAndRelatedResponseDTO,
    InvoiceDocumentDTO,
    InvoiceDetailsModel,
    InvoiceRelatedResponseDTO,
    InvoiceStatusEnum,
    UpsertInvoiceAndRelatedRequestDTO,
    UpsertInvoiceDateRequestDTO as UpdateInvoiceDateRequestDTO,
    InvoiceStatusDTO,
    UpsertInvoiceAttachmentsRequestDTO,
} from "./InvoiceDetailsModel";
import { debounce } from "lodash-es";
import { SupplierDetailsModel } from "Views/PurchaseOrder/Form/Supporting/SupplierDetailsModel";
import { SupplierModel } from "Views/PurchaseOrder/Form/Supporting/SupplierModel";
import type { ValidationResponse } from "@shoothill/core";
import { ChangeEvent } from "react";
import { formatCurrencyFromPounds } from "Utils/Format";
import { InvoiceProjectViewModel } from "./Supporting/InvoiceProjectViewModel";
import { InvoiceProjectDTO, InvoiceProjectModel } from "./Supporting/InvoiceProjectModel";
import { InvoiceViewModel } from "../InvoiceViewModel";
import { InvoiceDisputedStatusCodeModel } from "../Match/Supporting/InvoiceDisputedStatusCodeModel";
import moment from "moment";
import { IEGridItemViewModel } from "Views/Project/Commercial/IEmodels/IEGridItemViewModel";
import { isNewTabType, openFileInNewTab } from "Utils/Utils";
import { StoresInstance } from "Globals/Stores";

export class InvoiceDetailsViewModel extends ViewModelBase<InvoiceDetailsModel> {
    // #region Constructors and Disposers

    constructor(id: string | null, ieid: string | null) {
        super(new InvoiceDetailsModel());
        this.setDecorators(InvoiceDetailsViewModel);

        this.model.id = id;
        this.model.ieId = ieid ? ieid : null;

        //isEmptyOrWhitespace(this.model.id) ? this.loadRelated() : this.loadWithRelated();
    }

    // #region Properties

    @computed
    public get getCanEditInvoice(): boolean {
        return InvoiceViewModel.GetInstance.getCanEditInvoice;
    }

    @observable
    public hasLoaded: boolean = false;

    @action
    public setHasLoaded = (val: boolean) => {
        this.hasLoaded = val;
    };

    @action
    public setFormIsLoading = (val: boolean) => {
        this.setIsLoading(val);
    };

    @action
    public updateVAT = () => {
        // Pre-populate 20% of the sub-total
        this.model.invoiceTax = (this.getNetValue * 0.2).toFixed(2);
    };

    @observable
    private showPostModal = false;

    public get getShowPostModal(): boolean {
        return this.showPostModal;
    }

    public get hasId(): boolean {
        return this.model.id !== "" && this.model.id !== undefined && this.model.id !== null;
    }

    @action
    public handleShowPostModalChange = (val: boolean) => {
        this.showPostModal = val;
    };

    @computed
    public get getInvoiceTaxNumber(): number {
        return this.model.invoiceTax ? Number(this.model.invoiceTax) : 0;
    }

    @computed
    public get getNetValue(): number {
        return this.invoiceProjects
            .filter((m) => !m.model.isDeleted)
            .reduce((acc, m) => {
                return parseFloat((acc + m.invoiceValueNumber).toFixed(2));
            }, 0);
    }

    @computed
    public get getNetValueFormatted(): string {
        return formatCurrencyFromPounds(this.getNetValue);
    }

    @computed
    public get getTotalValue(): number {
        return parseFloat((this.getNetValue + Number(this.getInvoiceTaxNumber)).toFixed(2));
    }

    @computed
    public get getTotalValueFormatted(): string {
        return formatCurrencyFromPounds(this.getTotalValue);
    }

    @observable
    public invoiceProjects: InvoiceProjectViewModel[] = [];

    /**
     * List of invoice projects for the invoice project list.
     */
    @computed
    public get getInvoiceProjects(): InvoiceProjectViewModel[] {
        return this.invoiceProjects; // Don't filter out deleted here so that the index deletion works.
    }

    @observable
    public isViewOnly: boolean = false;

    @computed
    public get getIsViewOnly(): boolean {
        return this.isViewOnly;
    }

    @action
    public setIsViewOnly = (val: boolean) => {
        this.isViewOnly = val;
    };

    @computed
    public get isFormDisabled(): boolean {
        let isDraft = false;

        if (!this.getCanEditInvoice) {
            return true;
        }

        // Can only post the variation if it is already draft, or is a new variation, or has a particular disputed code.
        if (InvoiceViewModel.GetInstance) {
            if (InvoiceViewModel.GetInstance.hasBeenResetToDetails) {
                return this.getIsViewOnly || false;
            } else {
                const draftStatus = InvoiceViewModel.GetInstance.invoiceStatuses.find((s) => s.type === InvoiceStatusEnum.Draft);

                if (draftStatus && (this.model.invoiceStatusId === draftStatus.id || isNullOrEmpty(this.model.id))) {
                    isDraft = true;
                }

                return this.getIsViewOnly || !isDraft;
            }
        }

        return isDraft;
    }

    @action
    public setIsUpfrontPayment = (event: ChangeEvent<HTMLInputElement>, checked: boolean): void => {
        this.model.isUpfrontPayment = checked;
    };

    @computed
    public get canEditInvoiceDate(): boolean {
        const isFinanceRole: boolean = StoresInstance.Domain.AccountStore.isFinanceRole;
        return !this.isFormDisabled || isFinanceRole;
    }

    @observable
    public hasInvoiceDateChanged: boolean = false;

    @action
    public setInvoiceDate = (value: string | null): void => {
        this.setValue("invoiceDate", value);
        this.hasInvoiceDateChanged = true;
    };

    @observable
    public hasDueDateChanged: boolean = false;

    @action
    public setDueDate = (value: string | null): void => {
        this.setValue("dueDate", value);
        this.hasDueDateChanged = true;
    };

    @computed
    public get isProjectDropdownDisabled(): boolean {
        return this.supplier === null;
    }

    @observable
    private ieTitle: string = "";

    @action setIETitle = (val: string) => {
        this.ieTitle = val;
    };

    @computed
    public get getIETitle(): string {
        return this.ieTitle;
    }

    @observable
    public suppliers = observable<SupplierModel>([]);

    @computed
    public get supplier() {
        const result = this.suppliers.find((p) => p.id === this.model.supplierId);

        return result ? result! : null;
    }

    @action
    public setSupplier = async (value: SupplierModel | null) => {
        this.model.supplierId = value ? value.id : InvoiceDetailsModel.DEFAULT_SUPPLIERID;

        // Side-effect
        // Having set the supplier. we need to see if we have the suppliers details available
        // locally and if not load them from the server.
        if (this.model.supplierId) {
            this.setSupplierDetails(this.model.supplierId);
        }
    };

    private readonly SD_DEBOUNCE_VALUE_MS = 200;

    @observable
    public supplierDetails = observable<SupplierDetailsModel>([]);

    @computed
    public get supplierDetailsForSupplier(): SupplierDetailsModel | null {
        const supplierDetails = this.supplierDetails.find((sd) => sd.id === this.model.supplierId);

        return supplierDetails ? supplierDetails : null;
    }

    @computed
    public get canDisplaySupplierDetails(): boolean {
        return this.supplierDetailsForSupplier !== null;
    }

    private setSupplierDetails = debounce(
        action(async (supplierId: string) => {
            const supplier = this.supplierDetails.find((sd) => sd.id === supplierId);
            if (supplier) {
                // do nothing
            } else {
                await this.loadSupplierDetails();
            }
        }),
        this.SD_DEBOUNCE_VALUE_MS,
    );

    @observable
    public projects = observable<ProjectItemDTO>([]);

    /**
     * List of projects for the project autocomplete/dropdown.
     */
    @computed
    public get projectOptions(): KeyValuePair[] {
        return this.projects.map(this.toProjectOptionsModel);
    }

    public toProjectOptionsModel = (item: ProjectItemDTO): any => {
        const project: KeyValuePair = {
            key: item.id,
            value: `${item.projectReference} - ${item.projectName}`,
        };

        return project;
    };

    /**
     * Determines whether a project is already linked to this invoice locally or not. Used to disable the project in the project list.
     * @param option The project to check.
     * @returns True if the project is already selected against this invoice locally.
     */
    @action
    public isProjectSelected = (option: KeyValuePair<any>): boolean => {
        if (this.invoiceProjects.filter((i) => !i.model.isDeleted).findIndex((i) => i.model.projectId === option.key) !== -1) {
            return true;
        }

        return false;
    };

    @computed
    public get hasInvoiceProjects() {
        return this.invoiceProjects.filter((p) => !p.model.isDeleted).length > 0;
    }

    /**
     * Handles adding a project to the local list of invoice projects. Re-loads the project list after an item has been selected.
     * @param event The event that triggered the function call.
     * @param item The project item to be added to the list.
     */
    @action
    public handleAddProject = (event: ChangeEvent<{}>, item: KeyValuePair | null) => {
        if (item) {
            let project: ProjectItemDTO | undefined = this.projects.find((i) => i.id === item.key);

            if (project !== undefined) {
                const ipo: InvoiceProjectDTO = {
                    id: InvoiceProjectModel.DEFAULT_ID,
                    invoiceId: null,
                    projectId: project.id,
                    invoiceValue: InvoiceProjectModel.DEFAULT_INVOICEVALUE,
                    projectName: project.projectName,
                    projectReference: project.projectReference,
                    isDeleted: InvoiceProjectModel.DEFAULT_ISDELETED,
                };

                let model = new InvoiceProjectModel();
                model.fromDto(ipo);
                const viewModel = new InvoiceProjectViewModel(model);

                this.model.invoiceProjects.push(model);
                this.invoiceProjects.push(viewModel);
            }
        }

        // Reset the project autocomplete/dropdown and re-load the list.
        runInAction(() => {
            this.searchText = "";
            this.selectedProject = null;
        });

        this.loadFilteredProjects("");
    };

    private readonly PROJECT_DEBOUNCE_VALUE_MS = 300;

    @observable
    public searchText: string = "";

    @observable
    public selectedProject: KeyValuePair | null = null;

    /**
     * Handles the search event triggered from the autocomplete dropdown for projects.
     * @param e The type of event that triggered the function to be called.
     * @param searchText The string that will be used to filter the list of projects.
     */
    @action
    public handleSearchProject = (e: React.ChangeEvent<{}>, searchText: string) => {
        // Prevent filtering the list of projects when an item is selected from the list.
        if (e && e.type !== "click") {
            this.searchText = searchText;
            this.handleSearchProjectsDebounce(searchText);
        }
    };

    /**
     * Debounce function for filtering the project dropdown.
     */
    public handleSearchProjectsDebounce = debounce(
        action((searchText: string) => {
            if (this.supplier) {
                this.loadFilteredProjects(searchText);
            }
        }),
        this.PROJECT_DEBOUNCE_VALUE_MS,
    );

    /**
     * Loads the filtered list of projects for the project dropdown.
     * @param searchText The string that the projects will be filtered by.
     */
    @action
    public loadFilteredProjects = async (searchText: string): Promise<void> => {
        const invoiceId: string | null = this.model.id;

        let url = `${AppUrls.Server.Invoice.Load.GetFilteredProjects}?searchText=${searchText ? searchText : ""}&invoiceId=${invoiceId ? invoiceId : ""}`;

        this.setIsLoading(true);
        return await this.server
            .query<ProjectItemDTO[]>(
                () => this.Get(url),
                (result) => {
                    runInAction(() => {
                        this.projects.replace(result);
                    });
                },
            )
            .finally(() => this.setIsLoading(false));
    };

    /**
     * Determines whether the invoice can be saved as draft. Enables/disables the save as draft button.
     */
    @computed
    public get canSaveAsDraft(): boolean {
        let isDraft = false;

        // Only finance role can save as draft
        if (!StoresInstance.Domain.AccountStore.isFinanceRole) {
            return false;
        }

        if (InvoiceViewModel.GetInstance) {
            // Can only save a variation as draft if it is already draft, or is a new variation.
            const draftStatus = InvoiceViewModel.GetInstance.invoiceStatuses.find((s) => s.type === InvoiceStatusEnum.Draft);

            if (draftStatus && (this.model.invoiceStatusId === draftStatus.id || isNullOrEmpty(this.model.id))) {
                isDraft = true;
            }

            return isDraft || isNullOrEmpty(this.model.invoiceStatusId);
        }

        return isDraft;
    }

    /**
     * Determines whether the invoice can be posted. Enables/disables the post button.
     */
    @computed
    public get canPost(): boolean {
        let isDraft = false;

        // Only finance role can post
        if (!StoresInstance.Domain.AccountStore.isFinanceRole) {
            return false;
        }

        // Can only post the variation if it is already draft, or is a new variation, or has a particular disputed code.
        if (InvoiceViewModel.GetInstance) {
            if (InvoiceViewModel.GetInstance.hasBeenResetToDetails) {
                return true;
            } else {
                // Finance role can post when disputed, which will send it back through the approval process.
                if (
                    InvoiceViewModel.GetInstance.invoiceStatus !== null &&
                    InvoiceViewModel.GetInstance.invoiceStatus.type === InvoiceStatusEnum.Disputed &&
                    StoresInstance.Domain.AccountStore.isFinanceRole
                ) {
                    return true;
                }
                const draftStatus = InvoiceViewModel.GetInstance.invoiceStatuses.find((s) => s.type === InvoiceStatusEnum.Draft);

                if (draftStatus && (this.model.invoiceStatusId === draftStatus.id || isNullOrEmpty(this.model.id))) {
                    isDraft = true;
                }

                return isDraft || isNullOrEmpty(this.model.invoiceStatusId);
            }
        }

        return isDraft;
    }

    public server: ServerViewModel = new ServerViewModel();

    @computed
    public get invoiceDocuments(): InvoiceDocumentDTO[] {
        return this.model.invoiceDocuments.filter((d) => !d.isDeleted);
    }

    @computed
    public get invoiceValue(): number {
        return this.model.invoiceValue ? Number(this.model.invoiceValue) : 0;
    }

    @computed
    public get invoiceValueMatches(): boolean {
        return this.invoiceValue === this.getNetValue;
    }

    @computed
    public get canSaveAttachments(): boolean {
        if (this.IsLoading) {
            return false;
        }

        const matchStatus: InvoiceStatusDTO | undefined = InvoiceViewModel.GetInstance.invoiceStatuses.find((s) => s.type === InvoiceStatusEnum.Match);

        if (this.model.invoiceStatusId === matchStatus?.id) {
            return true;
        }

        return !this.isFormDisabled;
    }

    @observable
    public hasInvoiceAttachmentsChanged: boolean = false;

    /**
     * Delete a document from the local array.
     * @param id The id of the document to be deleted
     */
    @action
    public handleDeleteDocument = (id: string | null): void => {
        // Used to delete by index but there's different types of documents separated into different lists.
        if (id !== null) {
            for (let i = 0; i < this.model.invoiceDocuments.length; i++) {
                if (this.model.invoiceDocuments[i].id === id) {
                    this.hasInvoiceAttachmentsChanged = true;
                    this.model.invoiceDocuments[i].isDeleted = true;
                }
            }
        }
    };

    /**
     * Handle deleting an invoice project locally.
     * @param index The index of the item to be deleted.
     */
    @action
    public handleDeleteInvoiceProject = (index: number) => {
        this.model.invoiceProjects[index].isDeleted = true;
        this.updateVAT();
    };

    @action
    private createViewModels() {
        for (const item of this.model.invoiceProjects) {
            this.invoiceProjects.push(new InvoiceProjectViewModel(item));
        }
    }

    // #endregion Properties

    // #region Server Actions

    @action
    public loadData = async () => {
        isEmptyOrWhitespace(this.model.id) ? await this.loadRelated() : await this.loadWithRelated();
    };

    //related, for empty form
    public loadRelated = async (): Promise<void> => {
        this.setIsLoading(true);
        const url = this.model.ieId ? `${AppUrls.Server.Invoice.Load.Related}\\${this.model.ieId}` : `${AppUrls.Server.Invoice.Load.Related}`;
        return await this.server
            .query<InvoiceRelatedResponseDTO>(
                () => this.Get(url),
                (result) => {
                    runInAction(() => {
                        InvoiceViewModel.GetInstance.invoiceStatuses = result.invoiceStatuses;
                        InvoiceViewModel.GetInstance.invoiceDisputedStatusCodes.replace(InvoiceDisputedStatusCodeModel.fromDtos(result.invoiceDisputedStatusCodes));
                        InvoiceViewModel.GetInstance.canViewSupplierEmailCheckbox = result.canViewSupplierEmailCheckbox;
                        this.suppliers.replace(SupplierModel.fromDtos(result.suppliers));
                        this.projects.replace(result.projects);
                        this.setIETitle(result.ieTitle);
                        this.model.invoiceDate = moment().toISOString();
                        this.model.dueDate = moment().toISOString();
                    });
                },
            )
            .finally(() => {
                this.setHasLoaded(true);
                this.setIsLoading(false);
            });
    };

    // req id
    public loadWithRelated = async (): Promise<void> => {
        this.setIsLoading(true);
        const url = this.model.ieId
            ? `${AppUrls.Server.Invoice.Load.WithRelatedById}\\${this.model.id}\\${this.model.ieId}`
            : `${AppUrls.Server.Invoice.Load.WithRelatedById}\\${this.model.id}`;
        return await this.server
            .query<InvoiceAndRelatedResponseDTO>(
                () => this.Get(url),
                (result) => {
                    runInAction(() => {
                        this.setLoadWithRelatedData(result);
                    });
                },
            )
            .finally(() => {
                this.setHasLoaded(true);
                this.setIsLoading(false);
            });
    };

    @action
    public setLoadWithRelatedData = (result: InvoiceAndRelatedResponseDTO) => {
        this.invoiceProjects.length = 0;
        this.model.invoiceProjects.length = 0;
        InvoiceViewModel.GetInstance.invoiceStatuses = result.invoiceStatuses;
        InvoiceViewModel.GetInstance.invoiceDisputedStatusCodes.replace(InvoiceDisputedStatusCodeModel.fromDtos(result.invoiceDisputedStatusCodes));
        InvoiceViewModel.GetInstance.canViewSupplierEmailCheckbox = result.canViewSupplierEmailCheckbox;
        this.model.fromDto({
            invoice: result.invoice,
            invoiceProjects: result.invoiceProjects,
            invoiceDocuments: result.invoiceDocuments,
        });
        this.suppliers.replace(SupplierModel.fromDtos(result.suppliers));
        this.projects.replace(result.projects);
        this.setIETitle(result.ieTitle);
        this.createViewModels();

        if (result.invoice.supplierId) {
            this.loadSupplierDetails();
        }
    };

    /**
     * Load the supplier details for the supplier card/tile, after selecting a supplier from the list.
     */
    public loadSupplierDetails = async (): Promise<void> => {
        return this.server.query<any>(
            () => this.Get(`${AppUrls.Server.PurchaseOrder.GetSupplierDetailBySupplierId}\\${this.model.supplierId}`),
            (result) => {
                runInAction(() => {
                    const supplierDetails = new SupplierDetailsModel();
                    supplierDetails.fromDto(result);
                    this.supplierDetails.push(supplierDetails);

                    // Reset the project autocomplete/dropdown and re-load the list.
                    this.searchText = "";
                    this.selectedProject = null;
                });
            },
        );
    };

    public handleSaveAsDraft = async (reset: boolean): Promise<void> => {
        if (InvoiceViewModel.GetInstance) {
            const draftStatus = InvoiceViewModel.GetInstance.invoiceStatuses.find((s) => s.type === InvoiceStatusEnum.Draft);

            if (draftStatus) {
                return this.upsert(draftStatus.id, reset);
            }
        }

        return Promise.reject();
    };

    public handlePost = async (reset: boolean): Promise<void> => {
        if (InvoiceViewModel.GetInstance) {
            const matchStatus = InvoiceViewModel.GetInstance.invoiceStatuses.find((s) => s.type === InvoiceStatusEnum.Match);

            if (matchStatus) {
                return this.upsert(matchStatus.id, reset);
            }
        }

        return Promise.reject();
    };

    public updateInvoiceDate = async (): Promise<void> => {
        this.setIsLoading(true);

        let dto: UpdateInvoiceDateRequestDTO = {
            id: this.model.id,
            invoiceDate: this.model.invoiceDate,
            rowVersion: this.model.rowVersion,
        };

        return this.server
            .command<InvoiceAndRelatedResponseDTO>(
                () => this.Post(AppUrls.Server.Invoice.UpdateInvoiceDate, dto),
                (result: InvoiceAndRelatedResponseDTO) => {
                    runInAction(() => {
                        this.reset();
                        this.goBackToList();
                    });
                },
                this.isMyModelValid,
                "There was an error trying to save the invoice date",
            )
            .finally(() => this.setIsLoading(false));
    };

    public updateInvoiceAttachments = async (): Promise<void> => {
        this.setIsLoading(true);

        let dto: UpsertInvoiceAttachmentsRequestDTO = this.model.toAttachmentsDto();

        return this.server
            .command<InvoiceAndRelatedResponseDTO>(
                () => this.Post(AppUrls.Server.Invoice.UpdateInvoiceAttachments, dto),
                (result: InvoiceAndRelatedResponseDTO) => {
                    runInAction(() => {
                        this.reset();
                        this.goBackToList();
                    });
                },
                this.isMyModelValid,
                "There was an error trying to save the invoice attachments",
            )
            .finally(() => this.setIsLoading(false));
    };

    public handleCancel = (): void => {
        this.goBackToList();
    };

    public goBackToList = (): void => {
        const ieid: string | null = this.model.ieId;
        let url: string = ieid ? AppUrls.Client.Project.IE.replace(":ieid", ieid) + "#inv" : AppUrls.Client.Invoicing.List;
        if (IEGridItemViewModel.Instance.isCentral && ieid) {
            url = AppUrls.Client.Central.View.replace(":ieid", ieid) + "#inv";
        }
        getHistory().push(url);
    };

    public upsert = async (statusId: string | null, reset: boolean): Promise<void> => {
        this.setIsLoading(true);

        let dto: UpsertInvoiceAndRelatedRequestDTO = this.model.toDto();
        dto.invoice.invoiceStatusId = statusId;

        return this.server
            .command<InvoiceAndRelatedResponseDTO>(
                () => this.Post(AppUrls.Server.Invoice.Upsert, dto),
                (result: InvoiceAndRelatedResponseDTO) => {
                    runInAction(() => {
                        if (reset) {
                            this.reset();
                        } else {
                            this.reset();
                            this.goBackToList();
                        }
                    });
                },
                this.isMyModelValid,
                "There was an error trying to send the invoice",
            )
            .finally(() => this.setIsLoading(false));
    };

    /**
     * Checks the invoicenumber against existing invoices.
     * @returns Invalid if invoice with matching invoiceNumber exists in the database.
     */
    public getIsInvoiceNumberValid = async (): Promise<ValidationResponse> => {
        let isValid: boolean = true;
        let errorMessage: string = "";

        const id: string | null = this.model.id ? this.model.id : null;
        const invoiceNumber: string = this.model.invoiceNumber;

        if (invoiceNumber.length > 0) {
            this.setIsLoading(true);

            // JC: Used post request as the get request wasn't handling "/" correctly. Even when manually encoding and decoding.
            const apiResult = await this.Post<boolean>(AppUrls.Server.Invoice.IsInvoiceNumberValid, { Id: id, Invoicenumber: invoiceNumber });

            runInAction(() => {
                if (!apiResult.wasSuccessful) {
                    isValid = false;
                    errorMessage = "Failed to validate, please try again.";
                } else if (!apiResult.payload) {
                    isValid = false;
                    errorMessage = "Number already used by another invoice.";
                }
            });
            this.setIsLoading(false);
        }

        const retVal: ValidationResponse = {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };

        return new Promise<ValidationResponse>((resolve) => {
            resolve(retVal);
        });
    };

    /**
     * Handle a file being selected and process the data for upload.
     * @param event
     */
    @action
    public fileChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        if (event.target.files !== null && event.target.value !== null && event.target.files.length > 0) {
            let data: any = {
                fileName: event.target.files[0].name,
                formFile: event.target.files[0],
            };
            event.target.value = "";
            const apiResult = await this.fileUpload(data);
            if (apiResult && apiResult.wasSuccessful) {
                let fileToDisplay: InvoiceDocumentDTO = {
                    id: Math.random().toString(16).substr(2, 8), // 6de5ccda
                    url: apiResult.payload,
                    fileName: data.fileName,
                    isDeleted: false,
                    canDeleteDocument: false,
                };

                runInAction(() => {
                    this.model.invoiceDocuments.push(fileToDisplay);
                    this.hasInvoiceAttachmentsChanged = true;
                });
            }
        }
    };

    /**
     * Upload a file to azure.
     * @param data The data of the file to be uploaded.
     * @returns apiResult.
     */
    public fileUpload = async (data: any): Promise<ApiResult<any>> => {
        const formData = new FormData();
        formData.append("formFile", data.formFile);
        formData.append("fileName", data.fileName);
        const apiResult = await this.Post<any>(AppUrls.Server.File.UploadFile, formData);
        if (apiResult) {
            if (!apiResult.wasSuccessful) {
                console.log(apiResult.errors);
                runInAction(() => {
                    this.setSnackMessage("Error uploading file please try again.");
                    this.setSnackType(this.SNACKERROR);
                    this.setSnackbarState(true);
                });
            }
        }
        return apiResult;
    };

    /**
     * Download a file that exists in azure.
     * @param fileUrl The URL of the file to be downloaded.
     * @param fileName The name of the file to be downloaded.
     */
    public DownloadFile = async (fileUrl: string, fileName: string): Promise<void> => {
        try {
            const apiResult = await this.Post<Blob>(AppUrls.Server.File.DownloadFile, fileUrl, undefined, { responseType: "blob" });
            const fileExtension: string = fileName.split(".").pop()!.toLowerCase();

            // Open the file in a new tab if supported.
            if (isNewTabType(fileExtension)) {
                openFileInNewTab(apiResult, fileName);
            } else {
                const response = apiResult as any;
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", fileName);
                document.body.appendChild(link);
                link.click();
            }
        } catch (exception) {
            console.error(exception);
            this.setIsErrored(true);
        }
    };

    /**
     * Custom model validation function. Validates child category models and its children
     * @returns True if model is valid, false if not.
     */
    private isMyModelValid = async (): Promise<boolean> => {
        let isValid = true;

        // JC: Changed forEach into for loop as the await seems to have issues with forEach.
        for (let i = 0; i < this.invoiceProjects.length; i++) {
            let item = this.invoiceProjects[i];

            // Validate each child item.
            if ((await item.isModelValid()) === false) {
                isValid = false;
            }
        }

        // Validate the invoice model.
        if ((await this.isModelValid()) === false) {
            isValid = false;
        }

        return isValid;
    };

    @action
    public reset = () => {
        this.model.reset();
        this.server.reset();
        this.invoiceProjects.length = 0;
        this.hasInvoiceDateChanged = false;
        this.hasDueDateChanged = false;
        this.hasInvoiceAttachmentsChanged = false;
    };

    // #endregion Client Actions

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<InvoiceDetailsModel>, overrideSubmitted: boolean = false): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        if (this.server.IsSubmitted || overrideSubmitted) {
            switch (fieldName) {
                case "invoiceNumber": {
                    const result = await this.getIsInvoiceNumberValid();
                    const result2 = this.validateInvoiceNumber;

                    errorMessage = result.isValid ? result2.errorMessage : result.errorMessage;
                    isValid = result.isValid ? result2.isValid : result.isValid;
                    break;
                }

                case "invoiceDate": {
                    const result = this.validateInvoiceDate;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }

                case "dueDate": {
                    const result = this.validateDueDate;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }

                case "invoiceValue": {
                    const result = this.validateInvoiceValue;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }

                case "supplierId": {
                    const result = this.validateSupplierId;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
            }
        } else {
            // Do not validate if the properties of the model have not been
            // submitted to the server.
            errorMessage = "";
            isValid = true;
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    @computed
    private get validateInvoiceNumber(): ValidationResponse {
        const errorMessage = this.model.validateInvoiceNumber;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateInvoiceDate(): ValidationResponse {
        const errorMessage = this.model.validateInvoiceDate;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateDueDate(): ValidationResponse {
        const errorMessage = this.model.validateDueDate;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateInvoiceValue(): ValidationResponse {
        const errorMessage = this.model.validateInvoiceValue;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateSupplierId(): ValidationResponse {
        const errorMessage = this.model.validateSupplierId;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    // #region Snackbar

    @observable
    public snackbarState = false;

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public snackMessage = "";

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @observable
    public snackType = "";

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    @observable
    public SNACKSUCCESS = "success";

    @observable
    public SNACKERROR = "error";
    // #endregion

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}

export interface ProjectItemDTO {
    id: string;
    projectReference: string;
    projectName: string;
}
