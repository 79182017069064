import { Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { AppUrls } from "AppUrls";
import { ClassNameMap } from "@material-ui/styles";
import { BreadcrumbViewModel } from "../BreadcrumbViewModel";

export const InvoiceMatchSection1 = (classes: ClassNameMap<"root" | "common" | "prev" | "heading" | "main" | "secondary" | "lists" | "nested">): any => {
    return (
        <Link component={RouterLink} to={AppUrls.Client.Invoicing.List}>
            <div className={classes.main}>
                Invoices
                <span className="triangle"></span>
            </div>
        </Link>
    );
};

export const InvoiceMatchSection2 = (
    viewModel: BreadcrumbViewModel,
    classes: ClassNameMap<"root" | "common" | "prev" | "heading" | "main" | "secondary" | "lists" | "nested">,
    plusSign: any,
): any => {
    return (
        <div className={classes.secondary}>
            {viewModel.canAddInvoice && (
                <div onClick={viewModel.onAddInvoice}>
                    <img src={plusSign} className="add-image" alt="Add new invoice" />
                    <span className="add-text">Add invoice</span>
                </div>
            )}
        </div>
    );
};

/* 
export const CentralDetailMatchSection1 = (classes: ClassNameMap<"root" | "common" | "prev" | "heading" | "main" | "secondary" | "lists" | "nested">, arrowSign: any): any => {
    return (
        <>
            <Link component={RouterLink} to={AppUrls.Client.Central.List} className={classes.prev}>
                Central
            </Link>

            <Link component={RouterLink} to={AppUrls.Client.Central.Detail} className="secondary-text">
                <div className={classes.main}>
                    Detail
                    <span className="triangle"></span>
                </div>
            </Link>
        </>
    );
};

export const CentralDetailMatchSection2 = (
    viewModel: BreadcrumbViewModel,
    classes: ClassNameMap<"root" | "common" | "prev" | "heading" | "main" | "secondary" | "lists" | "nested">,
    addEditImg: any,
    exportImg: any,
): any => {
    return (
        <div className={classes.secondary}>
            <div onClick={viewModel.onEditCentralProject}>
                <img src={addEditImg} className="add-image" alt="Edit central project" />
                <span className="add-text">Edit central project</span>
            </div>
            <div onClick={viewModel.onCentralProjectCSV}>
                <img src={exportImg} className="export-image" alt="Export central project" />
                <span className="add-text">Export as CSV</span>
            </div>
        </div>
    );
}; */
