// Libraries
import React, { useState } from "react";
import { useObserver } from "mobx-react-lite";
import { KeyValuePair } from "@shoothill/core";
import { Button, makeStyles, TextField } from "@material-ui/core";
// Custom
import { BaseModal } from "Components/Modal/BaseModal";
import { GenericAutoCompleteView } from "Components/AutoComplete/GenericAutoCompleteView";

// Styling & Images
import { PackagesViewModel } from "Views/Project/Commercial/PackagesViewModel";
import { VariationViewModel } from "../VariationViewModel";
import { IEItemLineModalStyle, Main } from "Views/Project/Modals/Modal.Styles";
import { ModalEditBox } from "Views/Project/Commercial/IETables/IEGrid.Styles";
import { VariationModalEditBox, VariationModalGridView } from "./VariationGrid.Styles";
import { VariationLineModalViewModel } from "./VariationLineModalViewModel";
import { VariationLineModalModel } from "./VariationLineModalModel";
import { DefaultButton } from "Components/Buttons/Buttons";

interface ICreatedModalProps {
    open?: boolean;
    onClose(): void;
    variationViewModel: VariationViewModel;
}

export const VariationLineModal: React.FC<ICreatedModalProps> = (props) => {
    const { open, onClose, variationViewModel } = props;
    const [lineViewModel, setLineViewModel] = useState<VariationLineModalViewModel>(new VariationLineModalViewModel(new VariationLineModalModel()));

    const packagesViewModel = PackagesViewModel.Instance;

    const classes = useStyles();

    const handleOtherDescriptionText = (event: React.ChangeEvent<HTMLInputElement>) => {
        lineViewModel.setValue<string>("descriptionOther", event.target.value);
    };

    const handleSubcategory = (val: KeyValuePair) => {
        let id: number | null = null;

        if (val.key !== "") {
            id = parseInt(val.key, 10);
        } else {
            id = null;
        }

        lineViewModel.setValue<number | null>("subCategory", id);
    };

    const handleCategory = (val: KeyValuePair) => {
        let id: number | null = null;

        if (val.key !== "") {
            id = parseInt(val.key, 10);
        } else {
            id = null;
        }

        lineViewModel.setValue<number | null>("category", id);
    };

    const onAddClick = async () => {
        if (await lineViewModel.isModelValid()) {
            variationViewModel.addNewLineItem(lineViewModel.model.toDto());
            onClose();
        }
    };

    return useObserver(() => (
        <BaseModal
            dialogClasses="project-dialog"
            open={open}
            onClose={onClose}
            title={"Add line description"}
            PaperComponent={IEItemLineModalStyle}
            actions={
                <>
                    <Button onClick={() => onAddClick()} color="primary" variant="contained" disableElevation style={{ maxWidth: "116px" }} disabled={false}>
                        <>Add</>
                    </Button>
                    <DefaultButton execute={onClose} displayName={"Cancel"} canExecute={true} style={{ maxWidth: "116px" }} />
                </>
            }
        >
            <Main>
                <ModalEditBox>
                    <div className="row">
                        <label>Category</label>
                        <label>Sub category</label>
                    </div>
                    <div className="row">
                        <GenericAutoCompleteView
                            allowMultiple={false}
                            currentId={lineViewModel.model.category ? lineViewModel.model.category.toString() : ""}
                            includeDeleted={false}
                            dataSet={packagesViewModel.getCategoryList}
                            returnPair={(val) => handleCategory(val)}
                            idVal={"category"}
                            allowAdd={false}
                            variant="standard"
                            placeHolder={"Select Category"}
                            autoSelect={false}
                            error={lineViewModel.getError("category") !== ""}
                        />

                        <GenericAutoCompleteView
                            allowMultiple={false}
                            currentId={lineViewModel.model.subCategory ? lineViewModel.model.subCategory.toString() : ""}
                            includeDeleted={false}
                            dataSet={packagesViewModel.getSubcategoryList(lineViewModel.model.category)}
                            returnPair={(val) => handleSubcategory(val)}
                            idVal={"subcategory"}
                            allowAdd={false}
                            variant="standard"
                            placeHolder={"Select Subcategory"}
                            autoSelect={false}
                            error={lineViewModel.getError("subCategory") !== ""}
                        />
                    </div>
                    <div className="row fullwidth">
                        <label>Line description</label>
                    </div>
                    <div className="row fullwidth">
                        <TextField
                            type="search"
                            onChange={handleOtherDescriptionText}
                            value={lineViewModel.model.descriptionOther}
                            error={lineViewModel.getError("descriptionOther") !== ""}
                        />
                    </div>
                    <br />
                </ModalEditBox>

                <VariationModalEditBox style={{}}>
                    <div className="row modelSmallTable">
                        <VariationModalGridView>
                            <li className="tableHeading">
                                <div>Quantity</div>
                                <div>Unit</div>
                                <div>Rate</div>
                                <div>Line total</div>
                                <div>Future spend</div>
                            </li>
                            <li className="item" key={"item_0"}>
                                <div className={`noPadding ${classes.input}`}>
                                    <TextField
                                        id=""
                                        type="number"
                                        value={lineViewModel.model.quantity}
                                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => lineViewModel.setQuantity(event.target.value)}
                                        fullWidth
                                        error={lineViewModel.getError("quantity") !== ""}
                                        placeholder={"0"}
                                    />
                                </div>
                                <div className={`noPadding ${classes.input}`}>
                                    <TextField
                                        id=""
                                        type="text"
                                        value={lineViewModel.model.variationUnitName}
                                        onChange={(event: any) => lineViewModel.setValue("variationUnitName", event.target.value)}
                                        fullWidth
                                        error={lineViewModel.getError("variationUnitName") !== ""}
                                        placeholder={"enter a unit name"}
                                    />
                                </div>
                                <div className={`noPadding ${classes.input}`}>
                                    <TextField
                                        id=""
                                        type="number"
                                        value={lineViewModel.model.rate}
                                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => lineViewModel.setRate(event.target.value)}
                                        fullWidth
                                        error={lineViewModel.getError("rate") !== ""}
                                        placeholder={"0.00"}
                                    />
                                </div>
                                <div className="right" style={{ justifyContent: "center" }}>
                                    {lineViewModel.lineTotalFormatted}
                                </div>
                                <div className={`noPadding ${classes.input}`}>
                                    <TextField
                                        id=""
                                        type="number"
                                        value={lineViewModel.model.futureSpend}
                                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => lineViewModel.setFutureSpend(event.target.value)}
                                        fullWidth
                                        error={lineViewModel.getError("futureSpend") !== ""}
                                        placeholder={"0.00"}
                                    />
                                </div>
                            </li>
                        </VariationModalGridView>
                    </div>
                </VariationModalEditBox>
            </Main>
        </BaseModal>
    ));
};

const useStyles = makeStyles({
    buttonGroup: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    autoCompleteInput: {
        display: "flex",
        justifyContent: "start",
        paddingRight: "30px !important",
        paddingLeft: "40px !important",
        "& div.MuiAutocomplete-inputRoot": {
            width: "90%",
            display: "flex",
            alignItems: "center",
        },
    },
    input: {
        "& .MuiInputBase-root": {
            display: "flex !important",
            alignItems: "center !important",
            justifyContent: "center !important",
            margin: "0 5px",
            backgroundColor: "gold",
            width: "90%",

            "& > .MuiInput-input": {
                textAlign: "right",
                fontSize: "12px",
            },
            "& > .MuiAutocomplete-input": {
                textAlign: "left",
            },
        },
    },
    inputIcon: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
});
