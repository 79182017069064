import styled from "styled-components";
import { Box, Typography } from "@material-ui/core";
import { theme } from "../../Globals/Styles/AppTheme";

export const ClickableCell = styled(Box)`
    text-align: right;
    display: flex;
    flex: 1 0 1;
    flex-direction: row;
    align-items: left;
    padding: 0 15px;

    @media screen and (max-width: 1024px) {
    }
    @media screen and (max-width: 640px) {
    }

    color: ${theme.palette.primary.light};

    img {
        padding-right: 15px;
    }

    &.delete {
        color: ${theme.palette.error.main};
    }
`;

export const CellImgText = styled(Typography)`
    font-size: 10px;
    letter-spacing: 0;
    text-align: left;
    padding-top: 3px;
`;
