import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { AuditQuestionViewModel } from "./AuditQuestionViewModel";
import { SHBox } from "../../../../../Components/Box";
import { FormControl, FormControlLabel, IconButton, Radio, Typography } from "@material-ui/core";
import { RadioGroup } from "../../../../../Globals/Styles/AdminStyling";
import { Uploader } from "../../../../../Components/Uploader/Uploader";
import SystemUpdateTwoToneIcon from "@material-ui/icons/SystemUpdateTwoTone";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { uniqueId } from "lodash-es";
import { AuditDocumentModel } from "../AuditDocument";

interface Props {
    viewModel: AuditQuestionViewModel;
    editable: boolean;
}
export const AuditQuestionView: React.FC<Props> = observer((props: Props) => {
    const { viewModel } = props;

    const handleOnChange = (e: any) => {
        if (props.editable) {
            viewModel.passFailChanged(e.target.value);
        }
        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <>
            <SHBox grid dc={"2fr 1fr"}>
                <Typography variant={"h5"}>{viewModel.model.checkTitle}</Typography>
                <SHBox>
                    <SHBox showIf={viewModel.model.showPassFail}>
                        <FormControl component="fieldset">
                            <RadioGroup row name={"radio-" + "" + "-group"} value={viewModel.model.answer} onChange={handleOnChange}>
                                <FormControlLabel value="pass" control={<Radio color={"secondary"} />} label="Pass" />
                                <FormControlLabel value="fail" control={<Radio color={"secondary"} />} label="Fail" />
                            </RadioGroup>
                            <Typography variant={"h5"} color={"error"}>
                                {viewModel.getError("answer")}
                            </Typography>
                        </FormControl>
                    </SHBox>
                    <SHBox showIf={viewModel.model.showPassFailNA}>
                        <FormControl component="fieldset">
                            <RadioGroup row name={"radio-" + "" + "-group"} value={viewModel.model.answer} onChange={handleOnChange}>
                                <FormControlLabel value="pass" control={<Radio color={"secondary"} />} label="Pass" />
                                <FormControlLabel value="fail" control={<Radio color={"secondary"} />} label="Fail" />
                                <FormControlLabel value="na" control={<Radio color={"secondary"} />} label="N/A" />
                            </RadioGroup>
                            <Typography variant={"h5"} color={"error"}>
                                {viewModel.getError("answer")}
                            </Typography>
                        </FormControl>
                    </SHBox>
                    <SHBox showIf={viewModel.model.showPhotoUpload && props.editable}>
                        <Uploader
                            handleSelectFile={(e: React.ChangeEvent<HTMLInputElement>) => viewModel.fileChange(e)}
                            isFormDisabled={viewModel.IsLoading}
                            label="Audit documents (required)"
                        />
                        <Typography variant={"h5"} color={"error"}>
                            {viewModel.getError("auditDocuments")}
                        </Typography>
                    </SHBox>
                    <SHBox showIf={viewModel.model.showSecondaryPhotoUpload && props.editable}>
                        <Uploader
                            handleSelectFile={(e: React.ChangeEvent<HTMLInputElement>) => viewModel.fileChange(e)}
                            isFormDisabled={viewModel.IsLoading}
                            label="Audit documents (optional)"
                        />
                    </SHBox>
                    <div className="uploadedFile">
                        {viewModel.auditDocuments.map((item: AuditDocumentModel, index: number) => {
                            return (
                                <div className="uploadedFile-box" key={uniqueId()}>
                                    <div className="uploadedFile-heading">
                                        <IconButton onClick={() => viewModel.DownloadFile(item.url, item.fileName)}>
                                            <SystemUpdateTwoToneIcon />
                                        </IconButton>
                                        <h3>{item.fileName}</h3>
                                    </div>
                                    <SHBox showIf={props.editable}>
                                        <IconButton onClick={() => item.deleteDocument()} disabled={false}>
                                            <DeleteOutlinedIcon />
                                        </IconButton>
                                    </SHBox>
                                </div>
                            );
                        })}
                    </div>
                </SHBox>
            </SHBox>
        </>
    );
});
