import React from "react";

import { ClearTextRoot } from "./Files.styles";

interface Props {
    action: () => void;
    canAct: boolean;
}

export const ClearText: React.FC<Props> = (props: Props) => {
    const onClick = () => {
        if (props.canAct) {
            props.action();
        }
    };

    return <ClearTextRoot onClick={onClick} />;
};
