import { FieldType, isEmptyOrWhitespace, ViewModelBase, ApiResult } from "@shoothill/core";
import { action, computed, observable, runInAction } from "mobx";

import { AppUrls } from "AppUrls";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import {
    IncidentFormWitnessStatementAndRelatedResponseDTO,
    IncidentFormWitnessStatementDTO,
    IncidentFormWitnessStatementModel,
    IncidentFormWitnessStatementProjectDetailsDTO,
    IncidentFormWitnessStatementRelatedResponseDTO,
    IncidentUserDTO,
    UpsertIncidentFormWitnessStatementAndRelatedRequestDTO,
} from "./IncidentFormWitnessStatementModel";
import moment from "moment";
import { IncidentDTO } from "../IncidentModel";
import { GenericPhotoListItemDTO, GenericPhotoListItemModel } from "../Supporting/GenericPhotoListItemModel";

export class IncidentFormWitnessStatementViewModel extends ViewModelBase<IncidentFormWitnessStatementModel> {
    // #region Constructors and Disposers

    constructor(id: string | null, incidentId: string | null) {
        super(new IncidentFormWitnessStatementModel());
        this.setDecorators(IncidentFormWitnessStatementViewModel);
        this.model.id = id;
        this.model.incidentId = incidentId!;

        isEmptyOrWhitespace(this.model.id) ? this.loadRelated() : this.loadWithRelated();
    }

    // #region Properties

    @observable
    public hasLoaded: boolean = false;

    @computed
    public get getHasLoaded(): boolean {
        return this.hasLoaded;
    }

    @action
    public setHasLoaded = (val: boolean) => {
        this.hasLoaded = val;
    };

    @observable
    public isViewOnly: boolean = false;

    @computed
    public get getIsViewOnly(): boolean {
        return this.isViewOnly;
    }

    @action
    public setIsViewOnly = (val: boolean) => {
        this.isViewOnly = val;
    };

    @computed
    public get isFormDisabled(): boolean {
        return this.isViewOnly || this.model.hasId;
    }

    @computed
    public get canSaveForm(): boolean {
        return !this.model.hasId ? true : false;
    }

    @computed
    public get getTodayDateFormatted(): string {
        return this.model.createdDate ? moment(this.model.createdDate).format("DD/MM/YYYY").toString() : moment().format("DD/MM/YYYY").toString();
    }

    @computed
    public get getWitnessSignatureDateFormatted(): string {
        return this.model.witnessSignatureDate
            ? `${moment(this.model.witnessSignatureDate).format("DD/MM/YYYY").toString()} @ ${moment(this.model.witnessSignatureDate).format("HH:mm").toString()}`
            : `${moment().format("DD/MM/YYYY").toString()} @ ${moment().format("HH:mm").toString()}`;
    }

    @computed
    public get getProjectReferenceFormatted(): string {
        if (!this.incidentProjectDetails) {
            return "";
        }

        return this.incidentProjectDetails.projectReference;
    }

    @computed
    public get getProjectName(): string {
        if (!this.incidentProjectDetails) {
            return "";
        }

        return this.incidentProjectDetails.projectName;
    }

    @observable
    public incidentUsers = observable<IncidentUserDTO>([]);

    @observable
    public witnessUser: { id: string; displayName: string } | null = null;

    @action
    public handleSetWitnessUser = (item: { id: string; displayName: string }) => {
        this.witnessUser = item;
        this.model.witnessUserId = item.id;

        if (item.id !== this.model.notInSystemOption.id) {
            this.model.witnessNameNotInSystem = IncidentFormWitnessStatementModel.DEFAULT_WITNESS_NAME_NOT_IN_SYSTEM;
        }
    };

    public server: ServerViewModel = new ServerViewModel();

    @observable
    public incidentProjectDetails: IncidentFormWitnessStatementProjectDetailsDTO | null = null;

    @observable
    public incident: IncidentDTO | null = null;

    @action
    public handleCancel(): void {
        const projectId: string = this.incident!.projectId;
        const incidentId: string = this.incident!.id!;
        this.reset();
        this.history.push(AppUrls.Client.Project.Incident.View.replace(":projectid", projectId).replace(":incidentid", incidentId));
    }

    @observable
    public inductionUser: { id: string; displayName: string } | null = null;

    // #endregion Properties

    @computed
    public get getPhotos(): GenericPhotoListItemModel[] {
        return this.model.incidentFormWitnessStatementPhotos.filter((d) => !d.isDeleted);
    }

    @computed
    public get canShowNotInSystemField(): boolean {
        return this.model.witnessUserId === this.model.notInSystemOption.id;
    }

    /**
     * Handle a file being selected and process the data for upload.
     * @param event
     */
    @action
    public fileChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        if (event.target.files !== null && event.target.value !== null && event.target.files.length > 0) {
            let data: any = {
                fileName: event.target.files[0].name,
                formFile: event.target.files[0],
            };
            event.target.value = "";
            const apiResult = await this.fileUpload(data);
            if (apiResult && apiResult.wasSuccessful) {
                let fileToDisplay: GenericPhotoListItemDTO = {
                    id: null,
                    photoURL: apiResult.payload,
                    fileName: data.fileName,
                    isDeleted: false,
                    createdByUserId: null,
                    createdDate: null,
                };

                let model: GenericPhotoListItemModel = new GenericPhotoListItemModel();
                model.fromDto(fileToDisplay);
                runInAction(() => this.model.incidentFormWitnessStatementPhotos.push(model));
            }
        }
    };

    /**
     * Upload a file to azure.
     * @param data The data of the file to be uploaded.
     * @returns apiResult.
     */
    public fileUpload = async (data: any): Promise<ApiResult<any>> => {
        const formData = new FormData();
        formData.append("formFile", data.formFile);
        formData.append("fileName", data.fileName);
        const apiResult = await this.Post<any>(AppUrls.Server.File.UploadFile, formData);
        if (apiResult) {
            if (!apiResult.wasSuccessful) {
                console.log(apiResult.errors);
                runInAction(() => {
                    this.setSnackMessage("Error uploading file please try again.");
                    this.setSnackType(this.SNACKERROR);
                    this.setSnackbarState(true);
                });
            }
        }
        return apiResult;
    };

    /**
     * Download a file that exists in azure.
     * @param fileUrl The URL of the file to be downloaded.
     * @param fileName The name of the file to be downloaded.
     */
    public DownloadFile = async (fileUrl: string, fileName: string): Promise<void> => {
        try {
            const apiResult = await this.Post<Blob>(AppUrls.Server.File.DownloadFile, fileUrl, undefined, { responseType: "blob" });
            const response = apiResult as any;
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
        } catch (exception) {
            console.error(exception);
            this.setIsErrored(true);
        }
    };

    // #region Server Actions

    //related, for empty form
    public loadRelated = async (): Promise<void> => {
        this.setIsLoading(true);
        return await this.server
            .query<IncidentFormWitnessStatementRelatedResponseDTO>(
                () => this.Get(`${AppUrls.Server.Projects.Construction.Incident.WitnessStatement.Load.Related}\\${this.model.incidentId}`),
                (result) => {
                    runInAction(() => {
                        this.incident = result.incident;
                        this.incidentProjectDetails = result.incidentProjectDetails;
                        result.incidentUsers.unshift(this.model.notInSystemOption);
                        this.incidentUsers.replace(result.incidentUsers);
                    });
                },
            )
            .finally(() => {
                this.setIsLoading(false);
                this.setHasLoaded(true);
            });
    };

    /**
     * Load an existing Permit with any related data.
     */
    public loadWithRelated = async (): Promise<void> => {
        this.setIsLoading(true);
        return await this.server
            .query<IncidentFormWitnessStatementAndRelatedResponseDTO>(
                () => this.Get(`${AppUrls.Server.Projects.Construction.Incident.WitnessStatement.Load.WithRelatedById}\\${this.model.id}`),
                (result: IncidentFormWitnessStatementAndRelatedResponseDTO) => {
                    runInAction(() => {
                        this.handleSetLoadWithRelated(result);
                    });
                },
            )
            .finally(() => {
                this.setIsLoading(false);
                this.setHasLoaded(true);
            });
    };

    private handleSetLoadWithRelated = (result: IncidentFormWitnessStatementAndRelatedResponseDTO) => {
        this.model.fromDto(result);
        this.incident = result.incident;
        this.incidentProjectDetails = result.incidentProjectDetails;
        result.incidentUsers.unshift(this.model.notInSystemOption);
        this.incidentUsers.replace(result.incidentUsers);
        if (this.model.witnessUserId && this.incidentUsers.length > 0) {
            const userToAssign = this.incidentUsers.find((i) => i.id === this.model.witnessUserId);
            if (userToAssign) {
                this.handleSetWitnessUser(userToAssign);
            } else if (!userToAssign && this.model.witnessNameNotInSystem !== IncidentFormWitnessStatementModel.DEFAULT_WITNESS_NAME_NOT_IN_SYSTEM) {
                this.handleSetWitnessUser(this.model.notInSystemOption);
            }
        } else if (
            this.model.witnessUserId === IncidentFormWitnessStatementModel.DEFAULT_WITNESS_USER_ID &&
            this.model.witnessNameNotInSystem !== IncidentFormWitnessStatementModel.DEFAULT_WITNESS_NAME_NOT_IN_SYSTEM
        ) {
            this.handleSetWitnessUser(this.model.notInSystemOption);
        }
    };

    public upsert = async (): Promise<void> => {
        this.setIsLoading(true);

        const model: IncidentFormWitnessStatementDTO = this.model.toDto();
        const photos: GenericPhotoListItemDTO[] = this.model.incidentFormWitnessStatementPhotos.map((p) => p.toDto());
        const projectId: string = this.incident!.projectId;
        const incidentId: string = this.incident!.id!;

        if (model.witnessUserId === this.model.notInSystemOption.id) {
            model.witnessUserId = null;
        }

        const request: UpsertIncidentFormWitnessStatementAndRelatedRequestDTO = {
            incident: this.incident!,
            incidentFormWitnessStatement: model,
            incidentFormWitnessStatementPhotos: photos,
        };

        return await this.server
            .command<IncidentFormWitnessStatementAndRelatedResponseDTO>(
                () => this.Post(AppUrls.Server.Projects.Construction.Incident.WitnessStatement.Upsert, request),
                (result: IncidentFormWitnessStatementAndRelatedResponseDTO) => {
                    runInAction(() => {
                        this.reset();
                        this.history.push(AppUrls.Client.Project.Incident.View.replace(":projectid", projectId).replace(":incidentid", incidentId));
                    });
                },
                this.isMyModelValid,
                "There was an error trying to send the witness statement form",
            )
            .finally(() => this.setIsLoading(false));
    };

    /**
     * Custom model validation function. Validates child models and its children
     * @returns True if model is valid, false if not.
     */
    private isMyModelValid = async (): Promise<boolean> => {
        let isValid = true;

        if ((await this.isModelValid()) === false) {
            isValid = false;
        }

        return isValid;
    };

    @action
    public reset = () => {
        this.model.reset();
        this.server.reset();
        this.incidentUsers.length = 0;
        this.incidentProjectDetails = null;
        this.incident = null;
    };

    // #endregion Client Actions

    // #region Boilerplate

    // #region Vaildation

    public async isFieldValid(fieldName: keyof FieldType<IncidentFormWitnessStatementModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        if (this.server.IsSubmitted) {
            switch (fieldName) {
                case "location": {
                    const result = this.validateLocation;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "whereWereYou": {
                    const result = this.validateWhereWereYou;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "whatDidYouSee": {
                    const result = this.validateWhatDidYouSee;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "whatDidYouObserve": {
                    const result = this.validateWhatDidYouObserve;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "witnessUserId": {
                    const result = this.validateWitnessUserId;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "witnessNameNotInSystem": {
                    const result = this.validateWitnessNameNotInSystem;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "witnessSignatureURL": {
                    const result = this.validateWitnessSignatureURL;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "witnessStatementDate": {
                    const result = this.validateWitnessStatementDate;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
            }
        } else {
            // Do not validate if the properties of the model have not been
            // submitted to the server.
            errorMessage = "";
            isValid = true;
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    @computed
    private get validateLocation() {
        const errorMessage = this.model.validateLocation;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateWhereWereYou() {
        const errorMessage = this.model.validateWhereWereYou;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateWhatDidYouSee() {
        const errorMessage = this.model.validateWhatDidYouSee;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateWhatDidYouObserve() {
        const errorMessage = this.model.validateWhatDidYouObserve;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateWitnessStatementDate() {
        const errorMessage = this.model.validateWitnessStatementDate;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateWitnessUserId() {
        const errorMessage = this.model.validateWitnessUserId;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateWitnessNameNotInSystem() {
        const errorMessage = this.model.validateWitnessNameNotInSystem;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateWitnessSignatureURL() {
        const errorMessage = this.model.validateWitnessSignatureURL;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    // #endregion

    // #region Snackbar

    @observable
    public snackbarState = false;

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public snackMessage = "";

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @observable
    public snackType = "";

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    @observable
    public SNACKSUCCESS = "success";

    @observable
    public SNACKERROR = "error";
    // #endregion

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}

enum PermitDocumentTypeEnum {
    Internal = 10,
    Client = 20,
}
