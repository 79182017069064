import { FieldType, ViewModelBase } from "@shoothill/core";
import { observable, runInAction, computed, action } from "mobx";

import { AppUrls } from "AppUrls";
import { IInductionSectionType, InductionSectionType } from "Globals/Models/Enums/InductionSectionType";
import { IInductionType, InductionType } from "Globals/Models/Enums/InductionType";
import { IPersonalProtectiveEquipment, PersonalProtectiveEquipment } from "Globals/Models/Enums/PersonalProtectiveEquipment";
import { IPersonalProtectiveEquipmentType, PersonalProtectiveEquipmentType } from "Globals/Models/Enums/PersonalProtectiveEquipmentType";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { LandingViewModel } from "./Landing/LandingViewModel";
import { Step1ViewModel } from "./Step1/Step1ViewModel";
import { Step2ViewModel } from "./Step2/Step2ViewModel";
import { Step3ViewModel } from "./Step3/Step3ViewModel";
import { Step4ViewModel } from "./Step4/Step4ViewModel";
import { Step5ViewModel } from "./Step5/Step5ViewModel";
import { Step6ViewModel } from "./Step6/Step6ViewModel";
import { VisitorTypeModel } from "./Landing/VisitorTypeModel";
import { QualificationModel } from "./Common/QualificationModel";
import { SupplierModel } from "../PurchaseOrder/Form/Supporting/SupplierModel";

export class InductionViewModel extends ViewModelBase<any> {
    @observable public ages: any = [];
    @observable public showHeaderBar: boolean = true;

    @observable public landingViewModel: LandingViewModel = new LandingViewModel((state: boolean) => {
        this.showHeaderBar = state;
    });
    @observable public step1ViewModel: Step1ViewModel = new Step1ViewModel();
    @observable public step2ViewModel: Step2ViewModel = new Step2ViewModel();
    @observable public step3ViewModel: Step3ViewModel = new Step3ViewModel();
    @observable public step4ViewModel: Step4ViewModel = new Step4ViewModel();
    @observable public step5ViewModel: Step5ViewModel = new Step5ViewModel();
    @observable public step6ViewModel: Step6ViewModel = new Step6ViewModel();

    public server: ServerViewModel = new ServerViewModel();

    constructor() {
        super(null);
        (window as any).inductionViewModel = this;
        this.loadRelated();
    }

    @computed
    public get isStageLoading() {
        return (
            this.IsLoading ||
            this.landingViewModel.IsLoading ||
            this.step1ViewModel.IsLoading ||
            this.step2ViewModel.IsLoading ||
            this.step3ViewModel.IsLoading ||
            this.step4ViewModel.IsLoading ||
            this.step5ViewModel.IsLoading ||
            this.step6ViewModel.IsLoading
        );
    }

    @action
    public setIsLoadingFlag = (val: boolean) => {
        this.setIsLoading(val);
    };

    public loadRelated = (): Promise<void> => {
        this.setIsLoading(true);
        return this.server
            .query<VisitorTypeModel>(
                () => this.Get(AppUrls.Server.Induction.GetRelated),
                (result: any) => {
                    runInAction(() => {
                        // Anything that can be used by more than one step in the wizard should
                        // be processed here first.
                        const inductionSectionTypes: InductionSectionType[] = result.inductionSectionTypes.map((dto: IInductionSectionType) => {
                            const enumType = new InductionSectionType();

                            enumType.fromDto(dto);

                            return enumType;
                        });

                        const inductionTypes: InductionType[] = result.inductionTypes.map((dto: IInductionType) => {
                            const enumType = new InductionType();

                            enumType.fromDto(dto);

                            return enumType;
                        });

                        // #region Landing Page Properties

                        result.visitorTypes.sort((a: any, b: any) => a.ordinal - b.ordinal);
                        for (const item of result.visitorTypes) {
                            let visitorTypeModel = new VisitorTypeModel();
                            visitorTypeModel.id = item.id.toUpperCase();
                            visitorTypeModel.displayName = item.name;
                            this.landingViewModel.visitorTypes.push(visitorTypeModel);
                        }

                        result.suppliers.sort((a: any, b: any) => a.name - b.name);
                        for (const item of result.suppliers) {
                            let supplierModel = new SupplierModel();
                            supplierModel.id = item.id.toUpperCase();
                            supplierModel.displayName = item.name;
                            this.landingViewModel.suppliers.push(supplierModel);
                        }

                        this.landingViewModel.model.inductionTypes.replace(inductionTypes);

                        // #endregion Landing Page Properties

                        // #region Step 1

                        this.step1ViewModel.model.inductionTypes.replace(inductionTypes);

                        // #endregion Step 1

                        // #region Step 2 Properties (Skills and Training)

                        this.step2ViewModel.model.skillsTrainingInductionSectionType = inductionSectionTypes.find((enumType) =>
                            enumType.IsOfType(InductionSectionType.SkillAndTraining),
                        )!;

                        this.step2ViewModel.model.qualifications.replace(
                            result.existingQualifications
                                .filter((dto: any) => {
                                    return dto.inductionSectionType === this.step2ViewModel.model.skillsTrainingInductionSectionType?.id;
                                })
                                .sort((dtolhs: any, dtorhs: any) => {
                                    return dtolhs.ordinal - dtorhs.ordinal;
                                })
                                .map((dto: any) => {
                                    const model = new QualificationModel();

                                    model.fromDto(dto);

                                    return model;
                                }),
                        );

                        this.step2ViewModel.model.inductionTypes.replace(inductionTypes);

                        // #endregion Step 2 Properties (Skills and Training)

                        // #region Step 3 Properties (Plants and Equipment)

                        this.step3ViewModel.model.plantEquipmentInductionSectionType = inductionSectionTypes.find((enumType) =>
                            enumType.IsOfType(InductionSectionType.PlantAndEquipment),
                        )!;

                        this.step3ViewModel.model.qualifications.replace(
                            result.existingQualifications
                                .filter((dto: any) => {
                                    return dto.inductionSectionType === this.step3ViewModel.model.plantEquipmentInductionSectionType?.id;
                                })
                                .sort((dtolhs: any, dtorhs: any) => {
                                    return dtolhs.ordinal - dtorhs.ordinal;
                                })
                                .map((dto: any) => {
                                    const model = new QualificationModel();

                                    model.fromDto(dto);

                                    return model;
                                }),
                        );

                        // #endregion Step 3 Properties (Plants and Equipment)

                        // #region Step 4 properties (Personal Protective Equipment)

                        this.step4ViewModel.model.personalProtectiveEquipment.replace(
                            result.personalProtectiveEquipment.map((dto: IPersonalProtectiveEquipment) => {
                                const enumType = new PersonalProtectiveEquipment();

                                enumType.fromDto(dto);

                                return enumType;
                            }),
                        );

                        this.step4ViewModel.model.personalProtectiveEquipmentTypes.replace(
                            result.personalProtectiveEquipmentTypes.map((dto: IPersonalProtectiveEquipmentType) => {
                                const enumType = new PersonalProtectiveEquipmentType();

                                enumType.fromDto(dto);

                                return enumType;
                            }),
                        );

                        // #endregion Step 4 properties (Personal Protective Equipment)

                        // #region Step 5

                        this.step5ViewModel.model.inductionTypes.replace(inductionTypes);

                        // #endregion Step 5
                    });
                },
            )
            .finally(() => this.setIsLoading(false));
    };

    public submitData = async (): Promise<void> => {
        this.setIsLoading(true);

        // APM: This is a kludge to get the wizard submit working for visitors.
        // The code below does not account for some steps of the wizard being
        // skipped, so we send invalid data.
        //
        //const postData = {
        //    landingModel: this.landingViewModel.model.toDto(),
        //    step1Model: this.step1ViewModel.model.toDto(),
        //    step2Model: this.step2ViewModel.model.toDto(),
        //    step3Model: this.step3ViewModel.model.toDto(),
        //    step4Model: this.step4ViewModel.model.toDto(),
        //    step5Model: this.step5ViewModel.model.toDto(),
        //    step6Model: this.step6ViewModel.model.toDto(),
        //};
        //
        // The toDto methods for steps 2, 3 and 4 need to know what data to
        // convert. Some steps will have internal data they can use, but others
        // will not.
        const inductionType = this.landingViewModel.inductionType;

        const postData = {
            landingModel: this.landingViewModel.model.toDto(),
            step1Model: this.step1ViewModel.model.toDto(),
            step2Model: this.step2ViewModel.model.toDependentDto(inductionType!),
            step3Model: this.step3ViewModel.model.toDependentDto(inductionType!),
            step4Model: this.step4ViewModel.model.toDependentDto(inductionType!),
            step5Model: this.step5ViewModel.model.toDto(),
            step6Model: this.step6ViewModel.model.toDto(),
        };

        let result = await this.Post(AppUrls.Server.Induction.Submit, postData);

        if (!result.wasSuccessful) {
            alert("There was an error creating this account. Please try again later or check if you have already been inducted.");
        } else {
            this.history.push(AppUrls.Client.Induction.Submitted);
        }
        this.setIsLoading(false);
    };

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<any>, value: any): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
