import { action, observable, computed } from "mobx";
import { ModelBase } from "@shoothill/core";
import { InvoiceGroupDTO, InvoiceGroupModel } from "./InvoiceGroupModel";

export class InvoicePurchaseOrderModel extends ModelBase<InvoicePurchaseOrderModel, any> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Constants and Defaults

    public static readonly DEFAULT_ID = null;
    public static readonly DEFAULT_REQUISITIONREQUESTID = "";
    public static readonly DEFAULT_PONUMBER = null;
    public static readonly DEFAULT_FORMATTEDPONUMBER = "";
    public static readonly DEFAULT_PROJECTNAME = "";
    public static readonly DEFAULT_PROJECTID = "";
    public static readonly DEFAULT_INVOICEVALUE = null;
    public static readonly DEFAULT_ALREADYALLOCATED = "";
    public static readonly DEFAULT_AVAILABLEBALANCE = "";
    public static readonly DEFAULT_AMOUNTALLOCATED = "";
    public static readonly DEFAULT_INVOICEGROUPS = [];
    public static readonly DEFAULT_ISDELETED = false;

    // #endregion Constants and Defaults

    // #region Properties

    @observable
    public id: string | null = InvoicePurchaseOrderModel.DEFAULT_ID;

    @observable
    public requisitionRequestId: string = InvoicePurchaseOrderModel.DEFAULT_REQUISITIONREQUESTID;

    @observable
    public poNumber: number | null = InvoicePurchaseOrderModel.DEFAULT_PONUMBER;

    @observable
    public formattedPONumber: string = InvoicePurchaseOrderModel.DEFAULT_FORMATTEDPONUMBER;

    @observable
    public projectName: string = InvoicePurchaseOrderModel.DEFAULT_PROJECTNAME;

    @observable
    public projectId: string = InvoicePurchaseOrderModel.DEFAULT_PROJECTID;

    @observable
    public invoiceValue: number | null = InvoicePurchaseOrderModel.DEFAULT_INVOICEVALUE;

    @observable
    public alreadyAllocated: string = InvoicePurchaseOrderModel.DEFAULT_ALREADYALLOCATED;

    @observable
    public availableBalance: string = InvoicePurchaseOrderModel.DEFAULT_AVAILABLEBALANCE;

    @observable
    public amountAllocated: string = InvoicePurchaseOrderModel.DEFAULT_AMOUNTALLOCATED;

    @observable
    public invoiceGroups: InvoiceGroupModel[] = InvoicePurchaseOrderModel.DEFAULT_INVOICEGROUPS;

    @observable
    public isDeleted: boolean = InvoicePurchaseOrderModel.DEFAULT_ISDELETED;

    // #endregion Properties

    // #region Actions

    @action
    public reset = () => {
        this.id = InvoicePurchaseOrderModel.DEFAULT_ID;
        this.requisitionRequestId = InvoicePurchaseOrderModel.DEFAULT_REQUISITIONREQUESTID;
        this.poNumber = InvoicePurchaseOrderModel.DEFAULT_PONUMBER;
        this.projectName = InvoicePurchaseOrderModel.DEFAULT_PROJECTNAME;
        this.projectId = InvoicePurchaseOrderModel.DEFAULT_PROJECTID;
        this.invoiceValue = InvoicePurchaseOrderModel.DEFAULT_INVOICEVALUE;
        this.alreadyAllocated = InvoicePurchaseOrderModel.DEFAULT_ALREADYALLOCATED;
        this.availableBalance = InvoicePurchaseOrderModel.DEFAULT_AVAILABLEBALANCE;
        this.amountAllocated = InvoicePurchaseOrderModel.DEFAULT_AMOUNTALLOCATED;
        this.invoiceGroups = InvoicePurchaseOrderModel.DEFAULT_INVOICEGROUPS;
        this.isDeleted = InvoicePurchaseOrderModel.DEFAULT_ISDELETED;
    };

    @action
    public fromDto(dto: InvoicePurchaseOrderDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself

        for (let key in dto) {
            if (dto.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(dto[key]);
                } else if (key === "invoiceGroups") {
                    // Do nothing.
                } else {
                    this[key] = dto[key];
                }
            }
        }

        // Process the list of purchase orders.
        let processedGroups: InvoiceGroupModel[] = [];

        for (const group of dto.invoiceGroups) {
            const groupToAdd = new InvoiceGroupModel();
            groupToAdd.fromDto(group);
            processedGroups.push(groupToAdd);
        }

        this.invoiceGroups = [...this.invoiceGroups, ...processedGroups];
    }

    public toDto(): any {}

    // #endregion Actions

    // #region Custom Validation

    // #endregion Custom Validation
}

export interface InvoicePurchaseOrderDTO {
    id: string | null;
    requisitionRequestId: string | null;
    poNumber: number;
    projectName: string | null;
    projectId: string | null;
    invoiceValue: number | null;
    alreadyAllocated: string | null;
    availableBalance: string | null;
    amountAllocated: string | null;
    invoiceGroups: InvoiceGroupDTO[];
    isDeleted: boolean;
}
