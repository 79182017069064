// Libraries
import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { ApiResult, generateID, isNullOrEmpty, KeyValuePair, useRouter } from "@shoothill/core";
import { Button, FormControl, FormControlLabel, Radio, RadioGroup, Select, MenuItem, makeStyles, withStyles } from "@material-ui/core";

// Custom
import { BaseModal } from "Components/Modal/BaseModal";
import { IEViewModel, EditIEModel } from "../IEViewModel";
import { ProjectGeneralViewModel } from "../../General/ProjectGeneralViewModel";
import { GenericAutoCompleteView } from "Components/AutoComplete/GenericAutoCompleteView";
import { CommercialViewModel } from "../Commercial.ViewModel";

// Styling & Images
import { IEModalStyle, Main } from "../../Modals/Modal.Styles";
import { ProjectEditBox } from "../../Project.styles";

interface ICreatedModalProps {
    open?: boolean;
    viewModel: IEViewModel;
    onClose(): void;
    saveResponse(saved: string): void;
    ieId: string;
}

export const IEVariationModal: React.FC<ICreatedModalProps> = (props) => {
    const projectGeneralViewModel = ProjectGeneralViewModel.Instance;
    const commercialViewModel = CommercialViewModel.Instance;
    const { open, onClose, saveResponse, viewModel } = props;

    const [currentCell, setCurrentCell] = useState(viewModel.getValue("cell"));

    const [editMode, setEditMode] = useState<boolean>(false);

    useEffect(() => {
        if (props.ieId !== null && props.ieId !== undefined) {
            const item = commercialViewModel.getIEForEdit(props.ieId, projectGeneralViewModel.model.id);
            if (item !== null && item !== undefined) {
                setModel(item);
                setEditMode(true);
            }
        }
    }, []);

    const setModel = (obj: EditIEModel) => {
        // Is it a master
        let ieType = "sub";
        viewModel.setIEtype(ieType);
        viewModel.setValue("id", obj.id);
        viewModel.setValue("parentId", obj!.parentId);
        viewModel.setValue("cell", obj!.cell);
        setCurrentCell(viewModel.getValue("cell"));
        viewModel.setValue("nameId", obj!.nameId);
        viewModel.setValue("projectId", obj!.projectId);
    };

    const handleSubCategory = (val: KeyValuePair) => {
        viewModel.setValue("parentId", val.key);
    };

    const handleIEName = (val: KeyValuePair) => {
        if (val.key === "NEW") {
            viewModel.setValue("name", val.value);
            viewModel.setValue("nameId", null);
        } else {
            viewModel.setValue("name", val.value);
            viewModel.setValue("nameId", val.key);
        }
    };

    function handleCellChange(event: any) {
        setCurrentCell(event.target.value);
        console.log(event.target.value);
        viewModel.setValue("cell", event.target.value);
    }

    const handleCreate = async () => {
        viewModel.setValue("projectId", projectGeneralViewModel.model.id);
        console.log("Validating " + viewModel.IEtype);

        viewModel.setValue("type", "VARIATION");
        if (await viewModel.isIEDtoValid()) {
            console.log("Validates");
            let upsertPromise = viewModel.apiUpsertIE();
            upsertPromise.then((response) => {
                if (response.wasSuccessful) {
                    saveResponse("successful");
                } else {
                    saveResponse("failed");
                }
            });
        } else {
            console.log("Invalid");
        }
    };

    return useObserver(() => (
        <BaseModal
            dialogClasses="project-dialog"
            open={open}
            onClose={onClose}
            title={editMode ? "Update Variation" : "Create new Variation"}
            PaperComponent={IEModalStyle}
            actions={
                <>
                    <Button onClick={() => handleCreate()} color="primary" autoFocus variant="contained" disableElevation disabled={true} style={{ maxWidth: "116px" }}>
                        {!editMode && <>Create</>}
                        {editMode && <>Update</>}
                    </Button>
                    <Button onClick={onClose} color="secondary" variant="contained" disableElevation style={{ maxWidth: "116px" }}>
                        Cancel
                    </Button>
                </>
            }
        >
            <Main>
                <ProjectEditBox style={{}}>
                    <div className="row">
                        <span style={{ fontSize: "12px", fontWeight: "bold", width: "50%" }}>Variation links to:</span>
                    </div>
                </ProjectEditBox>
                <ProjectEditBox style={{}}>
                    <GenericAutoCompleteView
                        allowMultiple={false}
                        currentId={viewModel.getValue<string>("parentId")}
                        includeDeleted={false}
                        dataSet={commercialViewModel.ieNameAndIeId}
                        returnPair={(val) => handleSubCategory(val)}
                        placeHolder={""}
                        idVal={"chosenSub"}
                        allowAdd={false}
                        variant="outlined"
                    />
                </ProjectEditBox>
                <ProjectEditBox style={{}}>
                    <div className="row">
                        <span style={{ fontSize: "12px", fontWeight: "bold", width: "50%" }}>I & E name:</span>
                        <span style={{ fontSize: "12px", fontWeight: "bold" }}>Cell assignment:</span>
                    </div>
                </ProjectEditBox>

                <ProjectEditBox style={{}}>
                    <div className="row">
                        <div style={{ margin: "0" }}>
                            <GenericAutoCompleteView
                                allowMultiple={false}
                                currentId={viewModel.getValue<string>("nameId")}
                                includeDeleted={false}
                                dataSet={commercialViewModel.ieNameAndIeId}
                                returnPair={(val) => handleIEName(val)}
                                placeHolder={"I & E Name"}
                                idVal={"chosenSub"}
                                allowAdd={true}
                                variant="outlined"
                            />
                        </div>
                        <Select
                            id="cells"
                            value={currentCell}
                            onChange={handleCellChange}
                            MenuProps={{
                                getContentAnchorEl: null,
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                            }}
                            fullWidth
                            disabled={false}
                        >
                            {viewModel.cellArr.map((c) => {
                                return (
                                    <MenuItem key={generateID()} value={c.value}>
                                        {c.key}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </div>
                </ProjectEditBox>
            </Main>
        </BaseModal>
    ));
};
