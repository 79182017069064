import { action, observable, computed } from "mobx";
import { ModelBase } from "@shoothill/core";
import { IsNotEmpty } from "class-validator";
import { DarwinModelBaseDTO, ProjectDocumentDTO } from "Globals/Models/Domain";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class NoteModel extends ModelBase<NoteModel, NoteModelDTO> {
    // DarwinModelBase
    public id: string = "";
    public createdDate: string = "";
    public originatorId: string = "";
    public originatorName: string = "";
    public rowVersion: string | null = null;
    public sourceId: string = "";
    public projectId: string = "";
    public projectReference: string = "";

    public isDeleted: boolean = false;

    @observable public documents: ProjectDocumentDTO[] = [];

    @observable public documentsToUpload: File[] = [];

    @computed public get getDocumentsToUpload() {
        return this.documentsToUpload.slice();
    }

    @action public clearUploadedDocuments = () => {
        this.documentsToUpload = [];
    };

    @observable
    public note: string = "";

    @action
    public clear() {
        this.fromDto(DefaultNoteModelDTO);
    }

    @computed get getDocuments() {
        return this.documents.slice().filter((a) => a.isDeleted === false);
    }

    //fromDto is required but you can leave it blank
    fromDto(model: NoteModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    @action
    fromObservable(obVersion: NoteModel) {
        this.id = obVersion.id;
        this.createdDate = obVersion.createdDate;
        this.isDeleted = obVersion.isDeleted;
        this.rowVersion = obVersion.rowVersion;
        this.originatorId = obVersion.originatorId;
        this.originatorName = obVersion.originatorName;
        this.note = obVersion.note;
        this.sourceId = obVersion.sourceId;
        this.documents = obVersion.documents;
        this.projectId = obVersion.projectId;
        this.projectReference = obVersion.projectReference;
        // don't save any documents to upload
    }

    //toDto is required but you can leave it blank
    toDto(model: NoteModel): void {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    model[key] = new Date(this[key]);
                } else {
                    model[key] = this[key];
                }
            }
        }
    }

    public toNoteDto = (): NoteModelDTO => {
        let retVal: NoteModelDTO = JSON.parse(JSON.stringify(DefaultNoteModelDTO));

        for (let key in retVal) {
            if (retVal.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    retVal[key] = new Date(this[key]);
                } else {
                    retVal[key] = this[key];
                }
            }
        }

        return retVal;
    };
}

export interface NoteModelDTO extends DarwinModelBaseDTO {
    isDeleted: boolean;
    rowVersion: string | undefined;
    originatorId: string | undefined;
    originatorName: string | undefined;
    note: string;
    sourceId: string;
    documents: ProjectDocumentDTO[];
    documentsToUpload: File[];
    projectId: string;
    projectReference: string;
}

export const DefaultNoteModelDTO: NoteModelDTO = {
    id: "",
    createdDate: "",
    isDeleted: false,
    rowVersion: "",
    originatorId: "",
    originatorName: "",
    note: "",
    sourceId: "",
    documents: [],
    documentsToUpload: [],
    projectId: "",
    projectReference: "",
};
