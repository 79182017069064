import { FieldType, ViewModelBase } from "@shoothill/core";
import { action, computed, observable } from "mobx";
import { InvoiceGroupModel } from "./InvoiceGroupModel";
import { InvoicePurchaseOrderItemViewModel } from "./InvoicePurchaseOrderItemViewModel";

export class InvoiceGroupViewModel extends ViewModelBase<InvoiceGroupModel> {
    // #region Constructors and Disposers

    constructor(model: InvoiceGroupModel) {
        super(model);
        this.setDecorators(InvoiceGroupViewModel);

        this.createViewModels();
    }

    @observable
    public invoicePurchaseOrderItems: InvoicePurchaseOrderItemViewModel[] = [];

    @computed
    public get getInvoicePurchaseOrderItems(): InvoicePurchaseOrderItemViewModel[] {
        return this.invoicePurchaseOrderItems;
    }

    @computed
    public get getInitialValue(): number {
        return this.getInvoicePurchaseOrderItems.map((item) => item.getInitialValue).reduce((prev, next) => parseFloat((prev + next).toFixed(2)), 0);
    }

    @computed
    public get getAlreadyAllocated(): number {
        return this.getInvoicePurchaseOrderItems.map((item) => item.getAlreadyAllocated).reduce((prev, next) => parseFloat((prev + next).toFixed(2)), 0);
    }

    @computed
    public get getAvailableBalance(): number {
        return this.getInvoicePurchaseOrderItems.map((item) => item.getAvailableBalance).reduce((prev, next) => parseFloat((prev + next).toFixed(2)), 0);
    }

    @computed
    public get getAmountAllocated(): number {
        return this.getInvoicePurchaseOrderItems.map((item) => item.getAmountAllocated).reduce((prev, next) => parseFloat((prev + next).toFixed(2)), 0);
    }

    @action
    private createViewModels() {
        for (const item of this.model.invoicePurchaseOrderItems) {
            this.invoicePurchaseOrderItems.push(new InvoicePurchaseOrderItemViewModel(item));
        }
    }

    @action
    public reset = () => {
        this.model.reset();
    };

    // #endregion Client Actions

    /**
     * Custom model validation function. Validates child category models and its children
     * @returns True if model is valid, false if not.
     */
    public isMyModelValid = async (): Promise<boolean> => {
        let isValid = true;

        // JC: Changed forEach into for loop as the await seems to have issues with forEach.
        for (let i = 0; i < this.invoicePurchaseOrderItems.length; i++) {
            let item = this.invoicePurchaseOrderItems[i];

            // Validate each child item.
            if ((await item.isModelValid()) === false) {
                isValid = false;
            }
        }

        return isValid;
    };

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<InvoiceGroupModel>): Promise<boolean> {
        return true;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
