import { Box, FormControlLabel, IconButton, RadioGroup, TextField, Typography } from "@material-ui/core";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import SystemUpdateTwoToneIcon from "@material-ui/icons/SystemUpdateTwoTone";
import { Cell, Grid, isEmptyOrWhitespace, useRouter } from "@shoothill/core";
import { useObserver } from "mobx-react-lite";
import moment from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { AppUrls } from "AppUrls";
import { DarwinSelect } from "Components/AutoComplete/DarwinSelect";
import { DefaultButton, PrimaryButton } from "Components/Buttons/Buttons";
import { DarwinDateSelect } from "Components/DateSelect/DarwinDateSelect";
import { Uploader } from "Components/Uploader/Uploader";
import { theme } from "Globals/Styles/AppTheme";
import { BackButtonHolder } from "Views/Project/Commercial/VariationTables/VariationGrid.Styles";
import { DashedDivider, SolidDivider } from "Views/PurchaseOrder/Form/Views/Dividers";
import { Form, FormSection } from "Views/PurchaseOrder/Form/Views/Forms";
import { CustomArrow, CustomRadio } from "Views/Project/CustomComponents";
import { InductionUserDTO, QualityPhotoFile, SupplierDTO } from "./QualityFormModel";
import { QualityFormViewModel } from "./QualityFormViewModel";
import NonConformanceFormHeader from "../NonConformanceFormHeader";
import { NonConformanceListViewModel } from "../NonConformanceListViewModel";

interface IProps {
    className?: string;
}

const QualityFormViewBase: React.FC<IProps> = (props) => {
    const { match } = useRouter();
    const { projectid, nonConformanceTypeId, nonConformanceId } = match.params as any;
    const [viewModel] = useState(() => new QualityFormViewModel(nonConformanceId, projectid, nonConformanceTypeId));
    const [listViewModel] = useState(() => new NonConformanceListViewModel());
    const [currentDateTime, setCurrentDateTime] = useState(moment(Date().toLocaleString()).format("D/M/YYYY @ H:mm"));
    const { history } = useRouter();

    useEffect(() => {
        return () => {
            viewModel.reset();
        };
    }, []);

    setInterval(() => {
        setCurrentDateTime(moment(Date().toLocaleString()).format("D/M/YYYY @ H:mm"));
    }, 1000);

    useEffect(() => {
        listViewModel.projectId = projectid;
        listViewModel.loadNonConformanceAndRelated();
    }, []);

    const COLUMNS8 = "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr";
    const COLUMNS6 = "1fr 1fr 1fr 1fr 1fr 1fr";
    const COLUMNS4 = "1fr 1fr 1fr 1fr";
    const COLUMNS2 = "1fr 1fr";
    const COLUMNS1 = "1fr";

    const onSubmit = (event: any) => {
        event.preventDefault();
    };

    const renderForm = () => {
        return (
            <FormBox>
                <FormSection>
                    <DashedDivider gutterBottom={true} borderThickness={1} borderOpacity={1} />

                    {/* Concerning quality */}
                    <Grid columnGap={30} rowGap={20} tc={COLUMNS4} dc={COLUMNS4} style={{ marginBottom: "20px" }}>
                        <DarwinSelect
                            canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                            displayName="Company issued To:"
                            error={viewModel.getError("supplierId") !== ""}
                            execute={viewModel.setSupplier}
                            fullWidth={false}
                            getOptionLabel={(option: SupplierDTO) => option.displayName}
                            onBlur={() => viewModel.isFieldValid("supplierId")}
                            options={viewModel.suppliers}
                            placeholder="Please select"
                            validationMessage={viewModel.getError("supplierId")}
                            value={viewModel.supplier}
                        />
                        <DarwinSelect
                            canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                            displayName="Worker:"
                            error={viewModel.getError("userId") !== ""}
                            execute={viewModel.setUser}
                            fullWidth={false}
                            groupBy={(option) => option.groupDisplayName}
                            getOptionLabel={(option: InductionUserDTO) => option.displayName}
                            onBlur={() => viewModel.isFieldValid("userId")}
                            options={viewModel.users}
                            placeholder="Please select"
                            validationMessage={viewModel.getError("userId")}
                            value={viewModel.user}
                        />
                        <TextField
                            disabled={viewModel.isFormDisabled || viewModel.IsLoading}
                            error={viewModel.getError("location") !== ""}
                            fullWidth
                            helperText={viewModel.getError("location")}
                            InputLabelProps={{ shrink: true }}
                            label={"Exact location of non-conformance:"}
                            onBlur={() => viewModel.isFieldValid("location")}
                            onChange={(event) => viewModel.setValue("location", event.target.value)}
                            placeholder="Please add"
                            type="text"
                            value={viewModel.getValue("location")}
                        />
                    </Grid>
                    <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
                        <Cell ts={8} ds={8}>
                            <TextField
                                className="textarea"
                                disabled={viewModel.isFormDisabled || viewModel.IsLoading}
                                error={viewModel.getError("description") !== ""}
                                fullWidth
                                helperText={viewModel.getError("description")}
                                id="Description-of-non-conformanc"
                                InputLabelProps={{ shrink: true }}
                                label="Description of non-conformance:"
                                minRows={4}
                                multiline
                                onBlur={() => viewModel.isFieldValid("description")}
                                onChange={(event) => viewModel.setValue<string>("description", event.target.value)}
                                placeholder={`Please add notes`}
                                value={viewModel.getValue("description")}
                            />
                        </Cell>
                    </Grid>
                    <Grid columnGap={30} rowGap={20} tc={COLUMNS2} dc={COLUMNS2}>
                        <Cell ts={1} ds={1}>
                            <FileUploader>
                                <Typography style={{ marginBottom: "8px", font: "normal normal 700 12px/16px Segoe UI", letterSpacing: "-0.18px", color: "#191919" }} variant="h5">
                                    Photo(s):
                                </Typography>
                                <Uploader handleSelectFile={(e: any) => viewModel.fileChange(e, false)} isFormDisabled={viewModel.isFormDisabled} label="" />
                                {viewModel.model.qualityPhotos.filter((el: any) => !el.isDeleted).length > 0 && (
                                    <Typography style={{ margin: "17px 0px 13px" }} variant="h5">
                                        Photo(s) added:
                                    </Typography>
                                )}
                                <Box className="uploadedFile">
                                    {viewModel.model.qualityPhotos.map((item: QualityPhotoFile, index: number) => {
                                        return (
                                            !item.isDeleted && (
                                                <Box className="uploadedFile-box">
                                                    <Box className="uploadedFile-heading">
                                                        <IconButton onClick={() => viewModel.DownloadFile(item.photoUrl, item.fileName)}>
                                                            <SystemUpdateTwoToneIcon />
                                                        </IconButton>
                                                        <h3>{item.fileName}</h3>
                                                    </Box>
                                                    <IconButton onClick={() => viewModel.deleteNonConformanceWorksFile(index)} disabled={viewModel.isFormDisabled}>
                                                        <DeleteOutlinedIcon />
                                                    </IconButton>
                                                </Box>
                                            )
                                        );
                                    })}
                                </Box>
                            </FileUploader>
                        </Cell>
                    </Grid>

                    {/* Concerning rectification */}
                    <Grid columnGap={30} rowGap={20} tc={COLUMNS6} dc={COLUMNS6}>
                        <Box>
                            <Typography style={{ marginTop: "15px" }} variant="h5">
                                Rectification required?
                            </Typography>
                            <RadioGroup
                                defaultValue="Yes"
                                name={"radio"}
                                onChange={(e) => viewModel.setRectificationRequiredChange(e.target.value)}
                                row
                                value={viewModel.getValue("rectificationRequired")}
                            >
                                <FormControlLabel value="true" control={<CustomRadio checked={viewModel.getValue("rectificationRequired") === true} />} label="Yes" />
                                <FormControlLabel value="false" control={<CustomRadio checked={viewModel.getValue("rectificationRequired") === false} />} label="No" />
                            </RadioGroup>
                        </Box>
                        {viewModel.getValue("rectificationRequired") && (
                            <>
                                <Box>
                                    <Typography style={{ marginTop: "15px" }} variant="h5">
                                        Open?
                                    </Typography>
                                    <RadioGroup
                                        defaultValue="Yes"
                                        name={"radio"}
                                        onChange={(e) => viewModel.setIsOpenChange(e.target.value)}
                                        row
                                        value={viewModel.getValue("isOpen")}
                                    >
                                        <FormControlLabel value="true" control={<CustomRadio checked={viewModel.getValue("isOpen") === true} />} label="Yes" />
                                        <FormControlLabel value="false" control={<CustomRadio checked={viewModel.getValue("isOpen") === false} />} label="No" />
                                    </RadioGroup>
                                </Box>
                                <Box style={{ marginTop: "15px" }}>
                                    <DarwinDateSelect
                                        canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                                        displayName="Required rectification date:"
                                        execute={(value: string | null) => viewModel.setValue("rectificationDate", value)}
                                        onBlur={() => viewModel.isFieldValid("rectificationDate")}
                                        placeholder="Please select"
                                        validationMessage={viewModel.getError("rectificationDate")}
                                        value={viewModel.getValue("rectificationDate")}
                                    />
                                </Box>
                            </>
                        )}
                    </Grid>

                    {/* Concerning open/closed state */}
                    {viewModel.getValue("rectificationRequired") === true && viewModel.getValue("isOpen") === false && (
                        <>
                            <Box gridColumnGap={30} gridRowGap={20} marginTop={1} display={"grid"} gridTemplateColumns={COLUMNS8}>
                                <Cell ts={8} ds={8}>
                                    <TextField
                                        id="exact-location-of-work"
                                        multiline
                                        minRows={4}
                                        value={viewModel.model.rectificationDescription}
                                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                                            viewModel.setValue<string>("rectificationDescription", event.target.value)
                                        }
                                        fullWidth
                                        className="textarea"
                                        label="Description of completed non-conformance works:"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        placeholder={`Please Add Description of completed non-conformance works`}
                                        disabled={viewModel.isFormDisabled || viewModel.IsLoading}
                                        error={viewModel.getError("rectificationDescription") !== ""}
                                        helperText={viewModel.getError("rectificationDescription")}
                                    />
                                </Cell>
                            </Box>
                            <Grid columnGap={30} rowGap={20} marginBottom={1} tc={COLUMNS2} dc={COLUMNS2}>
                                <Cell ts={1} ds={1}>
                                    <FileUploader>
                                        <Typography
                                            style={{ marginBottom: "8px", font: "normal normal 700 12px/16px Segoe UI", letterSpacing: "-0.18px", color: "#191919" }}
                                            variant="h5"
                                        >
                                            Photo(s):
                                        </Typography>
                                        <Uploader handleSelectFile={(e: any) => viewModel.fileChange(e, true)} isFormDisabled={viewModel.isFormDisabled} label="" />
                                        {viewModel.model.qualityRectificationPhotos.filter((el: any) => !el.isDeleted).length > 0 && (
                                            <Typography style={{ margin: "17px 0px 13px" }} variant="h5">
                                                Photo(s) added:
                                            </Typography>
                                        )}
                                        <Box className="uploadedFile">
                                            {viewModel.model.qualityRectificationPhotos.map((item: QualityPhotoFile, index: number) => {
                                                return (
                                                    !item.isDeleted && (
                                                        <Box className="uploadedFile-box">
                                                            <Box className="uploadedFile-heading">
                                                                <IconButton onClick={() => viewModel.DownloadFile(item.photoUrl, item.fileName)}>
                                                                    <SystemUpdateTwoToneIcon />
                                                                </IconButton>
                                                                <h3>{item.fileName}</h3>
                                                            </Box>
                                                            <IconButton onClick={() => viewModel.deleteQualityRectificationPhotosFile(index)} disabled={viewModel.isFormDisabled}>
                                                                <DeleteOutlinedIcon />
                                                            </IconButton>
                                                        </Box>
                                                    )
                                                );
                                            })}
                                        </Box>
                                    </FileUploader>
                                </Cell>
                            </Grid>
                        </>
                    )}
                    <ProjectDetailsContainer>
                        <Box mt={"15px"} mb={"20px"}>
                            <span>Date / time added:</span> {`${currentDateTime}`}
                        </Box>
                    </ProjectDetailsContainer>

                    <SolidDivider gutterBottom={true} borderThickness={1} borderOpacity={1} />

                    <ButtonsContainer>
                        <PrimaryButton displayName="Save" execute={() => viewModel.upsert()} fullWidth={true} canExecute={!viewModel.IsLoading} />
                        <DefaultButton displayName="Cancel" execute={() => viewModel.handleCancel(projectid)} fullWidth={true} canExecute={!viewModel.IsLoading} />
                    </ButtonsContainer>
                </FormSection>
            </FormBox>
        );
    };

    const renderReadOnlyView = () => {
        return (
            <FormBox>
                <FormSection>
                    <DashedDivider gutterBottom={true} borderThickness={1} borderOpacity={1} />

                    {/* Concerning quality */}
                    <Grid columnGap={20} rowGap={20} tc={COLUMNS4} dc={COLUMNS4} style={{ marginBottom: "20px" }}>
                        <DataList>
                            <span>Company issued to:</span> {viewModel.readOnlySupplierDisplayName}
                        </DataList>
                        <DataList>
                            <span>Worker:</span> {viewModel.readOnlyUserDisplayName}
                        </DataList>
                        <DataList>
                            <span>Exact location of non-conformance:</span> {viewModel.readOnlyLocationDisplayName}
                        </DataList>
                    </Grid>
                    <DataList style={{ marginBottom: "6px" }}>
                        <span>Description of non-conformance:</span>
                    </DataList>
                    <ProjectDetailsContainer>
                        <Box>{viewModel.readOnlyDescriptionDisplayName}</Box>
                    </ProjectDetailsContainer>
                    <Grid columnGap={30} rowGap={20} tc={COLUMNS1} dc={COLUMNS1}>
                        <Cell ts={1} ds={1}>
                            <FileUploader>
                                <Typography
                                    style={{
                                        marginBottom: "8px",
                                        marginTop: "5px",
                                        font: "normal normal 700 12px/16px Segoe UI",
                                        letterSpacing: "-0.18px",
                                        color: "#191919",
                                    }}
                                    variant="h5"
                                >
                                    Photo(s) added:
                                </Typography>
                                <Box className="uploadedFile">
                                    {viewModel.model.qualityPhotos.map((item: QualityPhotoFile, index: number) => {
                                        return (
                                            !item.isDeleted && (
                                                <Box className="uploadedFile-box">
                                                    <Box className="uploadedFile-heading">
                                                        <IconButton onClick={() => viewModel.DownloadFile(item.photoUrl, item.fileName)}>
                                                            <SystemUpdateTwoToneIcon />
                                                        </IconButton>
                                                        <h3>{item.fileName}</h3>
                                                    </Box>
                                                </Box>
                                            )
                                        );
                                    })}
                                </Box>
                            </FileUploader>
                        </Cell>
                    </Grid>

                    {/* Concerning rectification */}
                    <Grid columnGap={30} rowGap={20} tc={COLUMNS4} dc={COLUMNS4}>
                        <CustomCheckBox>
                            <Typography style={{ marginTop: "15px" }} variant="h5">
                                Rectification required?
                            </Typography>
                            <FormControlLabel
                                style={{ cursor: "default !important" }}
                                value="true"
                                control={<CustomRadio checked={true} />}
                                label={viewModel.readOnlyRectificationRequired ? "Yes" : "No"}
                            />
                        </CustomCheckBox>
                        {viewModel.readOnlyRectificationRequired && (
                            <>
                                <CustomCheckBox>
                                    <Typography style={{ marginTop: "15px" }} variant="h5">
                                        Open?
                                    </Typography>
                                    <FormControlLabel
                                        style={{ cursor: "default !important" }}
                                        value="true"
                                        control={<CustomRadio style={{ cursor: "default !important" }} checked={true} />}
                                        label={viewModel.readOnlyIsOpen ? "Yes" : "No"}
                                    />
                                </CustomCheckBox>
                                <Box>
                                    <Typography style={{ marginTop: "15px" }} variant="h5">
                                        Required rectification date:
                                    </Typography>
                                    <ProjectDetailsContainer>
                                        <Box style={{ marginTop: "10px" }}>{viewModel.readOnlyRectificationDate}</Box>
                                    </ProjectDetailsContainer>
                                </Box>
                            </>
                        )}
                    </Grid>

                    {/* Concerning open/closed state */}
                    {viewModel.readOnlyRectificationRequired && !viewModel.readOnlyIsOpen && (
                        <>
                            <DataList style={{ marginBottom: "6px", marginTop: "15px" }}>
                                <span>Description of completed non-conformance works:</span>
                            </DataList>
                            <ProjectDetailsContainer>
                                <Box>{viewModel.readOnlyRectificationDescription}</Box>
                            </ProjectDetailsContainer>
                            <Grid columnGap={30} rowGap={20} tc={COLUMNS1} dc={COLUMNS1}>
                                <Cell ts={1} ds={1}>
                                    <FileUploader>
                                        <Typography
                                            style={{
                                                marginBottom: "8px",
                                                marginTop: "5px",
                                                font: "normal normal 700 12px/16px Segoe UI",
                                                letterSpacing: "-0.18px",
                                                color: "#191919",
                                            }}
                                            variant="h5"
                                        >
                                            Photo(s) added:
                                        </Typography>
                                        <Box className="uploadedFile">
                                            {viewModel.model.qualityRectificationPhotos.map((item: QualityPhotoFile, index: number) => {
                                                return (
                                                    !item.isDeleted && (
                                                        <Box className="uploadedFile-box">
                                                            <Box className="uploadedFile-heading">
                                                                <IconButton onClick={() => viewModel.DownloadFile(item.photoUrl, item.fileName)}>
                                                                    <SystemUpdateTwoToneIcon />
                                                                </IconButton>
                                                                <h3>{item.fileName}</h3>
                                                            </Box>
                                                        </Box>
                                                    )
                                                );
                                            })}
                                        </Box>
                                    </FileUploader>
                                </Cell>
                            </Grid>
                        </>
                    )}
                    <ProjectDetailsContainer>
                        <Box style={{ marginTop: "15px" }}>
                            <span>Date/time added:</span> {viewModel.readOnlyCreatedDateAndTime}
                        </Box>
                    </ProjectDetailsContainer>
                </FormSection>
            </FormBox>
        );
    };

    return useObserver(() => {
        return (
            <Form className={props.className} onSubmit={onSubmit}>
                <BackButtonHolder
                    onClick={() => {
                        history.push(AppUrls.Client.Project.ConstructionQuality.replace(":projectid", projectid ? projectid : viewModel.model.projectId) + "#nonconformance");
                    }}
                    style={{ padding: "10px 0", marginTop: 0 }}
                >
                    <Box style={{ position: "relative", display: "flex", marginLeft: "10px", alignItems: "center" }}>
                        <Box style={{ position: "absolute", height: "15px", width: "8px" }}>
                            <CustomArrow size={"8px"} color={theme.palette.blue.main} type={"left"} />
                        </Box>
                        <Box style={{ marginLeft: "20px", font: "normal normal 600 18px/32px Open Sans", letterSpacing: "-0.36px", color: theme.palette.blue.main }}>
                            Back to Pre-use inspections {viewModel.projectDisplayName}
                        </Box>
                    </Box>
                </BackButtonHolder>
                <NonConformanceFormHeader
                    ProjectDetails={viewModel.qualityProjectDetails}
                    ProjectDate={viewModel.getTodayDateFormatted}
                    CompletedBy={"Emma Girlface"}
                    SafetyId={viewModel.model.id}
                    FormHeaderTitle={viewModel.nonConformanceTypeDisplayName(listViewModel)}
                    NonConformanceListItems={listViewModel.nonConformanceListModels}
                    item={viewModel.quality}
                    IsNewForm={isEmptyOrWhitespace(viewModel.model.id)}
                />
                {isEmptyOrWhitespace(viewModel.model.id) ? renderForm() : renderReadOnlyView()}
            </Form>
        );
    });
};

export const QualityFormView = styled(QualityFormViewBase)`
    display: flex;
    flex: 1;
    flex-direction: column;
    form {
        position: relative;
    }
`;

const FormBox = styled(Box)`
    .MuiFormLabel-root {
        font: normal normal 700 12px/16px Segoe UI;
        letter-spacing: -0.18px;
        color: #191919;
        margin: 0 0 7px;
    }
`;

const CustomCheckBox = styled(Box)`
    .MuiFormControlLabel-root span {
        cursor: auto;
    }
    .MuiRadio-root.MuiButtonBase-root {
        .MuiIconButton-label {
            div {
                height: 20px;
                width: 20px;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                flex: 20px 0 0;
                border: 2px solid #eb8d00;
                background-color: transparent;
                transition: all 0.2s ease;
            }
            input:checked + div {
                background-color: #eb8d00;
            }
        }
    }
`;

const FileUploader = styled(Box)`
    margin-top: 20px;
    .fileUpload {
        background: #6c93a626;
        border: 1px dashed #6c93a6;
        font: normal normal normal 12px/14px Open Sans;
        letter-spacing: 0px;
        color: #425b66;
        padding: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 90px;
        margin: 0 !important;
    }
`;

const ProjectDetailsContainer = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    max-width: 100%;

    > div {
        font: normal normal normal 12px/16px Segoe UI;
        letter-spacing: -0.18px;
        color: #191919;
    }

    > div > span {
        font-weight: bold;
        margin-right: 10px;
    }
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;

    > button {
        max-width: 262px;
    }

    > button:nth-child(1) {
        margin-right: 30px;
    }
`;

const DataList = styled(Box)`
    font: normal normal normal 12px/16px Segoe UI;
    letter-spacing: -0.18px;
    color: #191919;
    display: flex;
    align-items: center;
    gap: 12px;
    span {
        font-weight: bold;
        margin-right: 10px;
    }
`;

interface SubtitleProps {
    margin?: string;
}

export const SubtitleContainer = styled.div<SubtitleProps>`
    h2 {
        margin-bottom: ${(props) => (props.margin ? props.margin : "10px")};
    }
`;
