import { Button } from "@material-ui/core";
import MaterialTable from "material-table";
import { observer } from "mobx-react-lite";
import React from "react";

import { SHBox } from "Components/Box";
import { BaseModal } from "Components/Modal/BaseModal";
import { SnackBar } from "Components/SnackBar/SnackBar";
import * as Defaults from "Globals/Defaults/TableOptions";
import { pxToRem } from "Globals/Styles/AppTheme";
import { DarwinTablePageContent } from "Globals/Styles/AppStyling";
import { formatDate, formatTime, formatCurrencyFromPounds, formatIntegerNumber } from "Utils/Format";
import { StockHistoryModalStyle } from "Views/Project/Modals/Modal.Styles";
import { StockHistoryItemViewModel } from "./StockHistoryItemViewModel";
import { StockHistoryViewModel } from "./StockHistoryViewModel";
import { DetailPanel, DummyEntryLink, EntryLink } from "./StockHistoryView.styles";

interface IProps {
    viewModel: StockHistoryViewModel;
}

export const StockHistoryView: React.FC<IProps> = observer((props) => {
    const viewModel = props.viewModel;
    const tableOptions = Defaults.GetDarwinTablStockHistory() as any;

    return (
        <BaseModal
            open={viewModel !== null}
            onClose={viewModel.close}
            title="Stock history"
            PaperComponent={StockHistoryModalStyle}
            actions={
                <React.Fragment>
                    <Button onClick={viewModel.close} color="secondary" autoFocus variant="contained">
                        Close
                    </Button>
                </React.Fragment>
            }
        >
            <DarwinTablePageContent style={{ padding: "0 0 20px 0" }}>
                <MaterialTable
                    columns={[
                        {
                            title: "Changed on/by",
                            field: "updatedDate",
                            width: "auto",
                            render: (rowData: StockHistoryItemViewModel) => (
                                <SHBox>
                                    <SHBox>{`${formatDate(rowData.updatedDate)} @ ${formatTime(rowData.updatedDate)} by`}</SHBox>
                                    <SHBox>{rowData.createdByUser}</SHBox>
                                </SHBox>
                            ),
                            cellStyle: {
                                ...tableOptions.cellStyle,
                                fontSize: pxToRem(10),
                                letterSpacing: pxToRem(-0.2),
                            },
                        },
                        {
                            title: "SKU number",
                            field: "skuNumber",
                            width: "auto",
                            sorting: false,
                        },
                        {
                            title: "Entry type",
                            field: "stockTransactionType",
                            width: "auto",
                            sorting: false,
                            render: (rowData: StockHistoryItemViewModel) => (
                                <SHBox>
                                    <SHBox>{rowData.stockTransactionType}</SHBox>
                                    {rowData.canDisplayPurchaseOrder && (
                                        <EntryLink onClick={() => viewModel.navigateToPurchaseOrder(rowData.purchaseOrderId!)}>{rowData.purchaseOrderNumber}</EntryLink>
                                    )}
                                    {rowData.canDisplayStockTransactionRequestNumber && <DummyEntryLink>{rowData.stockTransactionRequestNumber}</DummyEntryLink>}
                                </SHBox>
                            ),
                        },
                        {
                            title: "From",
                            field: "stockTransactionFrom",
                            width: "auto",
                            sorting: false,
                            render: (rowData: StockHistoryItemViewModel) => (
                                <SHBox>
                                    <SHBox>{rowData.stockTransactionFrom}</SHBox>
                                </SHBox>
                            ),
                        },
                        {
                            title: "To",
                            field: "stockTransactionTo",
                            width: "auto",
                            sorting: false,
                            render: (rowData: StockHistoryItemViewModel) => (
                                <SHBox>
                                    <SHBox>{rowData.stockTransactionTo}</SHBox>
                                </SHBox>
                            ),
                        },
                        {
                            title: "Movement",
                            field: "unitChange",
                            align: "right",
                            width: "auto",
                            sorting: false,
                            render: (rowData: StockHistoryItemViewModel) => formatIntegerNumber(rowData.unitChange),
                            cellStyle: (all: StockHistoryItemViewModel[], rowData: StockHistoryItemViewModel) => {
                                return {
                                    ...tableOptions.cellStyle,
                                    fontWeight: "600",
                                    backgroundColor: rowData.unitChange < 0 ? "#ECB4B4" : "#CAEAD4",
                                };
                            },
                        },
                        {
                            title: "Stock cost/item",
                            field: "pricePerUnit",
                            align: "right",
                            width: "auto",
                            sorting: false,
                            render: (rowData: StockHistoryItemViewModel) => formatCurrencyFromPounds(rowData.pricePerUnit, 2, 4),
                        },
                        {
                            title: "Stock balance",
                            field: "stockQuantity",
                            align: "right",
                            width: "auto",
                            sorting: false,
                            render: (rowData: StockHistoryItemViewModel) => formatIntegerNumber(rowData.stockQuantity),
                        },
                        {
                            title: "Stock value",
                            field: "stockValue",
                            align: "right",
                            width: "auto",
                            sorting: false,
                            render: (rowData: StockHistoryItemViewModel) => formatCurrencyFromPounds(rowData.stockValue),
                            cellStyle: (all: StockHistoryItemViewModel[], rowData: StockHistoryItemViewModel) => {
                                return {
                                    ...tableOptions.cellStyle,
                                    borderRight: "none",
                                    fontWeight: "600",
                                    backgroundColor: rowData.unitChange < 0 ? "#ECB4B4" : "#CAEAD4",
                                };
                            },
                        },
                    ]}
                    isLoading={viewModel.IsLoading}
                    data={viewModel.filteredStockHistoryItems}
                    options={{ ...tableOptions }}
                    title=""
                    detailPanel={(rowData: StockHistoryItemViewModel) => {
                        return (
                            <DetailPanel>
                                <div>Notes</div>
                                <div>{rowData.note}</div>
                            </DetailPanel>
                        );
                    }}
                />
            </DarwinTablePageContent>
            <SnackBar
                messageText={viewModel.snackMessage}
                messageType={viewModel.snackType}
                active={viewModel.snackbarState}
                closeOption={() => viewModel.setSnackbarState(false)}
                autoHideDuration={5000}
            />
        </BaseModal>
    );
});
