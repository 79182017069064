import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { FireExtinguishersViewModel } from "./FireExtinguishersViewModel";
import { SHBox } from "../../../../../Components/Box";
import { RadioAuditView } from "./EndOfWeekView";
import { FireExitModel } from "./FireExitModel";
import { Uploader } from "../../../../../Components/Uploader/Uploader";
import { SectionSplitter } from "../../../../../Components/Form/SectionSplitter";
import { FireExitViewModel } from "./FireExitViewModel";
import { EndOfWeekViewModel } from "./EndOfWeekViewModel";
import { uniqueId } from "lodash-es";
import { IconButton, Typography } from "@material-ui/core";
import { FirePointViewModel } from "./FirePointViewModel";
import SystemUpdateTwoToneIcon from "@material-ui/icons/SystemUpdateTwoTone";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { AuditDocumentModel } from "../AuditDocument";
import { DarwinInput } from "../../../../../Globals/Styles/Control/DarwinInput";
interface Props {
    index: number;
    firePointViewModel: FirePointViewModel;
    endOfWeekViewModel: EndOfWeekViewModel;
}
export const FirePointView: React.FC<Props> = observer((props: Props) => {
    const { firePointViewModel, endOfWeekViewModel } = props;

    return (
        <>
            <SHBox mt={2} key={uniqueId()}>
                <Typography variant={"h4"}>Fire Point {props.index + 1}:</Typography>
                <SHBox grid dc={"262px"} mt={2} mb={2}>
                    <DarwinInput<FirePointViewModel>
                        type="text"
                        label="Location:"
                        placeholder="Please add"
                        validateOnBlur={false}
                        viewModel={firePointViewModel}
                        fieldName={"location"}
                        shrink={true}
                        editMode={endOfWeekViewModel.editable}
                        maxLength={4096}
                    />
                </SHBox>
                <SHBox grid dc={"2fr 1fr"} mt={1}>
                    <RadioAuditView
                        title={"Air horns in working condition"}
                        value={() => firePointViewModel.getValue("airHornsInWorkingCondition")}
                        validationMessage={() => firePointViewModel.getError("airHornsInWorkingCondition")}
                        onChange={(e) => firePointViewModel.radioChanged(e, "airHornsInWorkingCondition", endOfWeekViewModel.editable)}
                        canExecute={endOfWeekViewModel.editable}
                    />
                    <SHBox dcs={"2"} showIf={firePointViewModel.getValue("airHornsInWorkingCondition") === "no"}>
                        <DarwinInput<FirePointViewModel>
                            type="text"
                            label="Please give more details"
                            multiLine={true}
                            validateOnBlur={false}
                            viewModel={firePointViewModel}
                            fieldName={"airHornsInWorkingConditionDetail"}
                            shrink={true}
                            maxLength={4096}
                            editMode={endOfWeekViewModel.editable}
                        />
                    </SHBox>
                    <RadioAuditView
                        title={"Signage in place"}
                        value={() => firePointViewModel.getValue("signageInPlace")}
                        validationMessage={() => firePointViewModel.getError("signageInPlace")}
                        onChange={(e) => firePointViewModel.radioChanged(e, "signageInPlace", endOfWeekViewModel.editable)}
                        canExecute={endOfWeekViewModel.editable}
                    />
                    <SHBox dcs={"2"} showIf={firePointViewModel.getValue("signageInPlace") === "no"}>
                        <DarwinInput<FirePointViewModel>
                            type="text"
                            label="Please give more details"
                            multiLine={true}
                            validateOnBlur={false}
                            viewModel={firePointViewModel}
                            fieldName={"signageInPlaceDetail"}
                            shrink={true}
                            maxLength={4096}
                            editMode={endOfWeekViewModel.editable}
                        />
                    </SHBox>
                    <RadioAuditView
                        title={"Unobstructed and visible"}
                        value={() => firePointViewModel.getValue("unobstructedAndVisible")}
                        validationMessage={() => firePointViewModel.getError("unobstructedAndVisible")}
                        onChange={(e) => firePointViewModel.radioChanged(e, "unobstructedAndVisible", endOfWeekViewModel.editable)}
                        canExecute={endOfWeekViewModel.editable}
                    />
                    <SHBox dcs={"2"} showIf={firePointViewModel.getValue("unobstructedAndVisible") === "no"}>
                        <DarwinInput<FirePointViewModel>
                            type="text"
                            label="Please give more details"
                            multiLine={true}
                            validateOnBlur={false}
                            viewModel={firePointViewModel}
                            fieldName={"unobstructedAndVisibleDetail"}
                            shrink={true}
                            maxLength={4096}
                            editMode={endOfWeekViewModel.editable}
                        />
                    </SHBox>
                    <SHBox grid dc={"1fr"}>
                        <SHBox showIf={endOfWeekViewModel.editable}>
                            <Uploader
                                handleSelectFile={(e: React.ChangeEvent<HTMLInputElement>) => endOfWeekViewModel.fileChange(e, firePointViewModel.model.auditDocuments)}
                                isFormDisabled={endOfWeekViewModel.IsLoading}
                                label="Photos(s)"
                            />
                            <Typography variant={"h5"} color={"error"}>
                                {firePointViewModel.getError("auditDocuments")}
                            </Typography>
                        </SHBox>
                        <div className="uploadedFile">
                            {firePointViewModel.model.auditDocuments &&
                                firePointViewModel.model.auditDocuments.map((item: AuditDocumentModel, index: number) => {
                                    return (
                                        <div className="uploadedFile-box" key={uniqueId()}>
                                            <div className="uploadedFile-heading">
                                                <IconButton onClick={() => endOfWeekViewModel.DownloadFile(item.url, item.fileName)}>
                                                    <SystemUpdateTwoToneIcon />
                                                </IconButton>
                                                <h3>{item.fileName}</h3>
                                            </div>
                                            <IconButton onClick={() => item.deleteDocument()} disabled={false}>
                                                <DeleteOutlinedIcon />
                                            </IconButton>
                                        </div>
                                    );
                                })}
                        </div>
                    </SHBox>
                </SHBox>
                <SectionSplitter />
            </SHBox>
        </>
    );
});
