import { action, runInAction, computed } from "mobx";
import { FieldType, ViewModelBase, observable } from "@shoothill/core";
import { NonConformanceTypesLookupModel } from "./NonConformanceTypesLookupModel";
import { NonConformanceListAndRelatedDTO, NonConformanceListModel } from "./NonConformanceListModel";
import { AppUrls } from "AppUrls";

export class NonConformanceListViewModel extends ViewModelBase<any> {
    public projectId: string = "";
    @observable public nonConformanceFormType: NonConformanceTypesLookupModel | null = null;
    @observable public nonConformanceTypes: any = [];
    @observable public nonConformanceListModels: NonConformanceListModel[] = [];
    constructor() {
        super();
    }

    @computed
    public get nonConformanceListItems() {
        const list = this.nonConformanceListModels.sort((a: any, b: any) => b.nonConformanceNumber - a.nonConformanceNumber);
        return list;
    }
    @action
    public setNonConformanceForm = (type: NonConformanceTypesLookupModel) => {
        this.nonConformanceFormType = type;
    };

    @action
    public referenceNumber = (item: any) => {
        let formattedNumber = item.toLocaleString("en-US", {
            minimumIntegerDigits: 8,
            useGrouping: false,
        });
        return formattedNumber;
    };

    @action
    public loadNonConformanceAndRelated = async (): Promise<void> => {
        this.setIsLoading(true);
        const request: any = {
            id: this.projectId,
            dateFilter: null,
        };
        let apiResult = await this.Post<NonConformanceListAndRelatedDTO>(AppUrls.Server.Projects.Construction.NonConformance.GetNonConformanceAndRelated, request);
        if (apiResult) {
            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.nonConformanceTypes.replace(NonConformanceTypesLookupModel.fromDtos(apiResult.payload.nonConformanceTypes));
                    this.nonConformanceListModels = [];
                    for (const nonConformance of apiResult.payload.nonConformanceListItems) {
                        let model = new NonConformanceListModel();
                        model.fromDto(nonConformance);
                        this.nonConformanceListModels.push(model);
                    }
                });
            } else {
                console.log(apiResult.errors);
            }
        }
        this.setIsLoading(false);
    };

    @action
    public handleRowClick = (e: any, rowData: NonConformanceListModel) => {
        if (rowData.nonConformanceTypeName === "Housekeeping") {
            this.history.push(AppUrls.Client.Project.NonConformance.HouseKeeping.View.replace(":projectid", this.projectId).replace(":nonConformanceId", rowData.id!));
        } else if (rowData.nonConformanceTypeName === "Discipline") {
            this.history.push(AppUrls.Client.Project.NonConformance.Discipline.View.replace(":projectid", this.projectId).replace(":nonConformanceId", rowData.id!));
        } else if (rowData.nonConformanceTypeName === "Quality") {
            this.history.push(AppUrls.Client.Project.NonConformance.Quality.View.replace(":projectid", this.projectId).replace(":nonConformanceId", rowData.id!));
        } else if (rowData.nonConformanceTypeName === "Environment") {
            this.history.push(AppUrls.Client.Project.NonConformance.Environment.View.replace(":projectid", this.projectId).replace(":nonConformanceId", rowData.id!));
        } else {
            this.history.push(AppUrls.Client.Project.NonConformance.View.replace(":projectid", this.projectId).replace(":nonConformanceId", rowData.id!));
        }
    };
    @action
    public addNonConformanceForm = () => {
        if (this.nonConformanceFormType?.displayName === "Housekeeping") {
            this.history.push(
                AppUrls.Client.Project.NonConformance.HouseKeeping.Add.replace(":projectid", this.projectId).replace(":nonConformanceTypeId", this.nonConformanceFormType?.id!),
            );
        } else if (this.nonConformanceFormType?.displayName === "Discipline") {
            this.history.push(
                AppUrls.Client.Project.NonConformance.Discipline.Add.replace(":projectid", this.projectId).replace(":nonConformanceTypeId", this.nonConformanceFormType?.id!),
            );
        } else if (this.nonConformanceFormType?.displayName === "Quality") {
            this.history.push(
                AppUrls.Client.Project.NonConformance.Quality.Add.replace(":projectid", this.projectId).replace(":nonConformanceTypeId", this.nonConformanceFormType?.id!),
            );
        } else if (this.nonConformanceFormType?.displayName === "Environment") {
            this.history.push(
                AppUrls.Client.Project.NonConformance.Environment.Add.replace(":projectid", this.projectId).replace(":nonConformanceTypeId", this.nonConformanceFormType?.id!),
            );
        } else {
            this.history.push(AppUrls.Client.Project.NonConformance.Add.replace(":projectid", this.projectId).replace(":nonConformanceTypeId", this.nonConformanceFormType?.id!));
        }
    };

    public async isFieldValid(fieldName: keyof FieldType<any>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }
    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
