import { Link, Typography } from "@material-ui/core";
import { useObserver } from "mobx-react-lite";
import React from "react";
import { PurchaseOrderViewModel } from "./PurchaseOrderViewModel";
import { ApprovalPanelBodySection, ApprovalPanelDisabledBodySection } from "Components/Approval/ApprovalPanel.styles";
import { AppUrls } from "AppUrls";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { RequisitionRequestItemGroupViewModel } from "./Supporting/OrderLine/Tables/RequisitionRequestItemGroupViewModel";
import { ApprovalPanel } from "Components/Approval/ApprovalPanel";
import { ApprovalHistoryView } from "Components/ApprovalHistory/ApprovalHistoryView";

interface IProps {
    refs: React.RefObject<HTMLDivElement>[];
    viewModel: PurchaseOrderViewModel;
    sideOpen: boolean;
    isFromApprovalSection: boolean;
    primaryTitle: string;
    secondaryTitle: string | null;
    requisitionRequestId: string | null;
    additionalBodyComponent: () => JSX.Element;
}

export const PurchaseOrderApprovalPanel: React.FC<IProps> = (props: IProps) => {
    const { viewModel } = props;

    const location = useLocation();

    const upsertApprovalStatus = (isApproved: boolean, amendmentNote: string, requisitionStatusId: string) => {
        viewModel.upsertApprovalStatus(isApproved, amendmentNote, requisitionStatusId);
    };

    const scrollToRequisitionGroup = (val: string | undefined | null) => {
        if (val && props.refs) {
            if (props.refs[val].current) {
                const offset = 69;

                props.refs[val].current.scrollIntoView({
                    top: offset,
                    behavior: "smooth",
                    block: "start",
                });
            }
        }
    };

    const bodyNewApprovalPanel = () => {
        const groupLink = (item: RequisitionRequestItemGroupViewModel) => {
            const url: string = location.pathname.split("#group")[0] + `#group-${item.model.id}`;
            return (
                <div>
                    <Link component={RouterLink} to={url} className="secondary-text" onClick={() => scrollToRequisitionGroup(item.model.id)}>
                        {item.displayName}
                    </Link>
                </div>
            );
        };

        return (
            <div>
                {props.viewModel.approvalPanelViewModel.canShowRequisitionNotes && (
                    <ApprovalPanelBodySection>
                        <p>{viewModel.approvalPanelViewModel.model.requisitionNotes}</p>
                    </ApprovalPanelBodySection>
                )}
                {props.viewModel.getNotWithinSpendAllowanceGroupsInBudget.length > 0 && (
                    <ApprovalPanelBodySection>
                        <strong>
                            <Typography variant="body1" className="body-subtitle">
                                <strong>This requires approval due to the value being above the users allowance:</strong>
                            </Typography>
                        </strong>
                        {props.viewModel.getNotWithinSpendAllowanceGroupsInBudget.map((item) => {
                            return groupLink(item);
                        })}
                    </ApprovalPanelBodySection>
                )}
                {props.viewModel.getNotWithinSpendAllowanceGroupsOuOfBudget.length > 0 && (
                    <ApprovalPanelBodySection>
                        <strong>
                            <Typography variant="body1" className="body-subtitle">
                                <strong>This requires approval due to the value being above the users allowance:</strong>
                            </Typography>
                        </strong>
                        {props.viewModel.getNotWithinSpendAllowanceGroupsOuOfBudget.map((item) => {
                            return groupLink(item);
                        })}
                    </ApprovalPanelBodySection>
                )}
                {approvalHistorySection()}
                {props.additionalBodyComponent()}
            </div>
        );
    };

    const bodyAmenderPanel = () => {
        return (
            <div>
                {props.viewModel.approvalPanelViewModel.canShowApproverNotes && (
                    <ApprovalPanelBodySection>
                        <p>{viewModel.approvalPanelViewModel.model.approverNotes}</p>
                    </ApprovalPanelBodySection>
                )}
                <ApprovalPanelBodySection>
                    <p className="keep-newline-formatting">If you have made the changes above you can either save your work or resubmit for approval here</p>
                </ApprovalPanelBodySection>
                {approvalHistorySection()}
                {props.additionalBodyComponent()}
            </div>
        );
    };

    const bodyAmendedApprovalPanel = () => {
        return (
            <div>
                <div>
                    {props.viewModel.approvalPanelViewModel.canShowRequisitionNotes && (
                        <ApprovalPanelDisabledBodySection>{viewModel.approvalPanelViewModel.model.requisitionNotes}</ApprovalPanelDisabledBodySection>
                    )}
                </div>
                {approvalHistorySection()}
                {props.additionalBodyComponent()}
            </div>
        );
    };

    const bodyDefault = () => {
        return (
            <div>
                {approvalHistorySection()}
                {props.additionalBodyComponent()}
            </div>
        );
    };

    const approvalHistorySection = () => {
        return (
            <>
                {props.viewModel.approvalPanelViewModel.approvalHistoryViewModel.approvalHistoryItems.length > 0 && (
                    <ApprovalHistoryView viewModel={props.viewModel.approvalPanelViewModel.approvalHistoryViewModel} />
                )}
            </>
        );
    };

    return useObserver(() => {
        return (
            <ApprovalPanel
                sideOpen={props.sideOpen}
                upsertApprovalStatus={upsertApprovalStatus}
                bodyNewApprovalPanel={bodyNewApprovalPanel()}
                bodyAmenderPanel={bodyAmenderPanel()}
                bodyAmendedApprovalPanel={bodyAmendedApprovalPanel()}
                bodyDefault={bodyDefault()}
                canShowNewApprovalPanel={viewModel.approvalPanelViewModel.getCanShowNewApprovalPanel}
                canShowAmenderPanel={viewModel.approvalPanelViewModel.getCanShowAmenderPanel}
                canShowAmendedApprovalPanel={viewModel.approvalPanelViewModel.getCanShowAmendedApprovalPanel}
                isFromApprovalSection={props.isFromApprovalSection}
                canUpsertStatus={viewModel.approvalPanelViewModel.getCanUpsertStatus}
                primaryTitle={props.primaryTitle}
                secondaryTitle={props.secondaryTitle}
                approvedStatusId={viewModel.approvalPanelViewModel.getApprovedStatusId}
                amendRequiredStatusId={viewModel.approvalPanelViewModel.getAmendRequiredStatusId}
                rejectedStatusId={viewModel.approvalPanelViewModel.getRejectedStatusId}
                canViewFooter={
                    (viewModel.approvalPanelViewModel.getCanShowNewApprovalPanel || viewModel.approvalPanelViewModel.getCanShowAmendedApprovalPanel) && props.isFromApprovalSection
                }
                handleGeneratePreviewPDF={viewModel.handleGeneratePreviewPDF}
                downloadFile={viewModel.approvalPanelViewModel.downloadFile}
                fileChange={viewModel.approvalPanelViewModel.fileChange}
                approvalDocumentsToUpload={viewModel.approvalPanelViewModel.model.approvalDocuments}
                handleCancelAmend={viewModel.approvalPanelViewModel.handleCancelAmend}
            />
        );
    });
};
