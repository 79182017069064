/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";

import { ApiResult } from "@shoothill/core";
import { SupplierDetailViewModel } from "./SupplierDetailViewModel";
import { DetailsPage, DetailsPrimaryContact, DetailsTabs } from "Globals/Views/DetailsPage/DetailsPage.styles";
import { DetailsHeader } from "Globals/Views/DetailsPage/DetailsHeader";
import { ITab, Tabs } from "Components/Tabs/Tabs";
import { useHasRoutedTabs } from "Utils/UseHasRoutedTabs";
import { SupplierWithRelatedDTO } from "./SupplierWithRelatedDTO";
import { useRouter } from "@shoothill/core";

import { AddContactTitle } from "./SupplierDetail.styles";
import { AddEditContactModel } from "Views/Contacts/AddEditContactModel";
import { AddEditContactView } from "Views/Contacts/AddEditContactView";
import { AddEditSupplierModal } from "./AddEditSupplierModal";
import { ContactListView } from "Views/Contacts/ContactListView";
import { ContactType } from "Views/Contacts/ContactType";
import { ContactView } from "Views/Contacts/ContactView";
import { DetailsHeaderModel } from "Globals/Views/DetailsPage/DetailsHeaderModel";

import OrangeCloseImg from "Content/OrangeCross.svg";
import OrangeOpenImg from "Content/OrangePlus.svg";
import { SupplierContactDTO } from "./SupplierContactModel";
import { PurchaseOrderTableView } from "Views/PurchaseOrder/PurchaseOrderTableView";
import { AppUrls } from "AppUrls";
import { InvoiceTableView } from "Views/Invoice/InvoiceTableView";
import { AddEditContactViewModel } from "Views/Contacts/AddEditContactViewModel";
import { runInAction } from "mobx";
import { AddressModelDTO, SupplierStatusHelpers } from "Globals/Models/Domain";
import AddressViewModel from "Globals/ViewModels/AddressViewModel";
import { AddEditSupplierViewModel } from "./AddEditSupplierViewModel";
import { StoresInstance } from "Globals/Stores";
const TAB_Supplier = "supplier";
const TAB_CONTACTS = "contacts";
const TAB_PURCHASE_ORDERS = "purchaseOrders";
const TAB_INVOICES = "invoices";

const tabOptions: ITab[] = [
    { key: TAB_Supplier, title: "Supplier details", hash: "supplier" },
    { key: TAB_CONTACTS, title: "Supplier Contacts", hash: "contacts" },
    { key: TAB_PURCHASE_ORDERS, title: "Purchase Orders", hash: "purchaseOrders" },
    { key: TAB_INVOICES, title: "Invoices", hash: "invoices" },
];

interface IAddressViewProps {
    viewModels: AddressViewModel;
    reference?: React.MutableRefObject<HTMLButtonElement | undefined>;
    onUpdateModel: (model: AddressModelDTO) => any;
    isAddressValid?: (isValid: boolean) => any;
}

export const SupplierDetailView: React.FunctionComponent<IAddressViewProps> = () => {
    const [viewModel] = useState(() => SupplierDetailViewModel.Instance);
    const supplierFormViewModel: AddEditSupplierViewModel = AddEditSupplierViewModel.Instance;
    const router = useRouter();
    const hashTab = useHasRoutedTabs(tabOptions);
    const [selectedTab, setSelectedTab] = React.useState(hashTab?.key || TAB_Supplier);
    const [tabKey, setTabKey] = React.useState(0);
    const [contactViewModel] = useState(() => new AddEditContactViewModel());
    const [titleModel, setTitleModel] = React.useState<DetailsHeaderModel>(viewModel.getHeader);
    const [showAddContact, setShowAddContact] = React.useState(false);

    useEffect(() => {
        let { supplierid } = router.match.params as any;
        let promise = viewModel.loadSupplierAsync(supplierid);
        promise.then((result: ApiResult<SupplierWithRelatedDTO>) => {
            if (result.wasSuccessful === true) {
                setTitleModel(viewModel.getHeader);
                supplierFormViewModel.setSupplier(result.payload);
                supplierFormViewModel.setHasLoaded(true);
            }
        });

        return () => {
            viewModel.cleanUp();
            supplierFormViewModel.cleanUp();
        };
    }, []);

    const handleTabClick = (tab: ITab) => {
        setSelectedTab(tab.key);
        setTabKey(tabKey + 1);
    };

    const getTabKey = (): string => {
        return `clienthome-tab-${selectedTab}-${tabKey}`;
    };

    const onAddContact = (model: AddEditContactModel) => {
        runInAction(() => {
            const dto = model.toClientContactDto(model.sourceId);
            contactViewModel.model.fromClientContactDto(dto);
        });

        let promise = viewModel.addContact(model);
        promise.then((result: ApiResult<SupplierContactDTO>) => {
            if (result.wasSuccessful === true) {
                contactViewModel.model.clear();
            }
        });
    };

    const onEditContact = (model: AddEditContactModel) => {
        runInAction(() => {
            const dto = model.toClientContactDto(model.sourceId);
            contactViewModel.model.fromClientContactDto(dto);
        });

        let promise = viewModel.updateContact(model);
        promise.then((result: ApiResult<SupplierContactDTO>) => {
            if (result.wasSuccessful === true) {
                contactViewModel.model.clear();
            }
        });
        return promise;
    };

    const onContactListCleanup = () => {
        // TODO CMR
        contactViewModel.model.clear();
    };

    const onDeleteContact = (id: string) => {
        const promise = viewModel.deleteContact(id);

        promise.then((result: ApiResult<number>) => {});
    };

    const updatContactIsPrimary = (id: string, isPrimary: boolean) => {
        const promise = viewModel.updatContactIsPrimary(id, isPrimary);
        promise.then((result: ApiResult<SupplierContactDTO[]>) => {});
    };

    const loadContactAsync = (id: string): Promise<ApiResult<SupplierContactDTO>> => {
        const promise = viewModel.loadContactAsync(id);
        return promise;
    };

    const toggleAddContact = () => {
        setShowAddContact(!showAddContact);
    };

    const renderTabContent = () => {
        switch (selectedTab) {
            case TAB_CONTACTS:
                return (
                    <div>
                        <AddContactTitle onClick={toggleAddContact}>
                            {supplierFormViewModel.getCanEditSupplierForm && (
                                <span style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                                    <img src={showAddContact === true ? OrangeCloseImg : OrangeOpenImg} alt={showAddContact === true ? "Close add contact" : "Open Add Contact"} />
                                    Add Contact
                                </span>
                            )}
                        </AddContactTitle>
                        {showAddContact === true && (
                            <AddEditContactView
                                /* reference={addContactRef} */
                                viewModel={contactViewModel}
                                key={getTabKey()}
                                onUpsertContact={onAddContact}
                                isLoading={viewModel.IsLoading}
                                disabled={!supplierFormViewModel.getCanEditSupplierForm}
                            />
                        )}
                        <ContactListView
                            contactType={ContactType.Supplier}
                            contactList={viewModel.getContactList}
                            deleteContact={onDeleteContact}
                            loadContact={loadContactAsync}
                            upsertContact={onEditContact}
                            onCleanup={onContactListCleanup}
                            updatContactIsPrimary={updatContactIsPrimary}
                            showEditColumn={true}
                        />
                    </div>
                );
            case TAB_PURCHASE_ORDERS:
                return (
                    <div key={getTabKey()}>
                        <PurchaseOrderTableView data={viewModel.getPurchaseOrdersList} poViewURL={AppUrls.Client.PurchaseOrder.View} />
                    </div>
                );
            case TAB_INVOICES:
                return (
                    <div key={getTabKey()}>
                        <InvoiceTableView data={viewModel.getInvoices} invoiceViewURL={AppUrls.Client.Invoicing.Detail} showSearch={true} isLoading={viewModel.IsLoading} />
                    </div>
                );
            case TAB_Supplier:
                return (
                    <div key={getTabKey()}>
                        {!viewModel.IsLoading && supplierFormViewModel.hasLoaded && <AddEditSupplierModal onClose={onCloseModal} title="Add new Supplier" text="" />}
                    </div>
                );
        }
    };

    const onCloseModal = () => {
        viewModel.showAddEditModal(false);
    };

    const onSelect = async (value: any) => {
        if (await supplierFormViewModel.isMyModelValid()) {
            titleModel.setValue("status", value);
            titleModel.setValid("status", true);
            await viewModel.updateSupplierStatus(value);
        } else {
            titleModel.setError("status", "Supplier validation errors.");
            titleModel.setValid("status", false);
        }
    };

    return useObserver(() => (
        <DetailsPage>
            <DetailsHeader
                viewModel={titleModel}
                onSelect={onSelect}
                isShowStatus={false}
                isShowDropDown={StoresInstance.Domain.AccountStore.getCanEditSupplier}
                options={SupplierStatusHelpers.getAllOptions()}
            />
            <DetailsPrimaryContact>
                <ContactView model={viewModel.getPrimaryContact} key={getTabKey()} isLoading={viewModel.IsLoading} />
            </DetailsPrimaryContact>
            <DetailsTabs>
                <Tabs tabs={tabOptions} onTabClick={handleTabClick} selected={selectedTab} minTabWidth={132} tabPadding="11.5px 8px" />
                <div className="content">{renderTabContent()}</div>
            </DetailsTabs>
        </DetailsPage>
    ));
};
