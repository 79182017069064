import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import AddressViewModel from "Globals/ViewModels/AddressViewModel";
//import { AddressCell, AddressRow, AddressWrapper } from "./Address.styles";
import { DarwinInput } from "Globals/Styles/Control/DarwinInput";
import { AcceptButton } from "Globals/Styles/Control/Buttons";
import { AddButtonCell } from "Views/Contacts/AddEditContact.styles";
import { AddressModel, AddressModelDTO } from "Globals/Models/Domain";
import { Cell, Grid, EditableInput, styled } from "@shoothill/core";
import { DarwinInputWrapper } from "Components/Form/DarwinInputWrapper";
import DeleteLogo from "Content/Bin.svg";

interface IAddressViewProps {
    viewModel: AddressViewModel;
    reference?: React.MutableRefObject<HTMLButtonElement | undefined>;
    onUpdateModel: (model: AddressModelDTO) => any;
    showAddButton: boolean;
    isLoading: boolean;
    buttonLabel?: string;
    isAddressValid?: (isValid: boolean) => any;
    isDeliveryAddresses?: boolean;
    isShowCountry?: boolean;
    disabled?: boolean;
}

export const ConstructionAddressComponentView: React.FC<IAddressViewProps> = (props) => {
    const { viewModel, onUpdateModel, showAddButton, isLoading, buttonLabel, isAddressValid, isDeliveryAddresses, isShowCountry = true, disabled = false } = props;

    useEffect(() => {
        return () => {
            //viewModel.cleanUp();
        };
    }, []);

    const upsertAddress = async () => {
        if (await viewModel.isModelValid()) {
            console.log("model is valid");
            onUpdateModel(viewModel.model.toAddressDto());
            viewModel.model.clear();
        } else {
            console.log("model is NOT valid");
        }
    };

    return useObserver(() => (
        <>
            <Grid margin="10px 0px" columnGap={30} dc={"1fr 1fr 1fr 1fr"} rowGap={20} tc={"1fr 1fr 1fr 1fr"}>
                <Cell>
                    <DarwinInputWrapper<AddressModel>
                        type="text"
                        label="Address Name:"
                        validateOnBlur={true}
                        viewModel={viewModel}
                        fieldName="addressName"
                        shrink={true}
                        maxLength={128}
                        editMode={!disabled}
                    />
                </Cell>
                <Cell>
                    <DarwinInputWrapper<AddressModel>
                        type="text"
                        label="Address line 1:"
                        validateOnBlur={true}
                        viewModel={viewModel}
                        fieldName="addressLine1"
                        shrink={true}
                        maxLength={128}
                        editMode={!disabled}
                    />
                </Cell>
                <Cell>
                    <DarwinInputWrapper<AddressModel>
                        type="text"
                        label="Address line 2:"
                        validateOnBlur={true}
                        viewModel={viewModel}
                        fieldName="addressLine2"
                        shrink={true}
                        fullwidth
                        maxLength={128}
                        editMode={!disabled}
                    />
                </Cell>
                <Cell>
                    <DarwinInputWrapper<AddressModel>
                        type="text"
                        label="Address line 3:"
                        validateOnBlur={true}
                        viewModel={viewModel}
                        fieldName="addressLine3"
                        shrink={true}
                        maxLength={128}
                        editMode={!disabled}
                    />
                </Cell>
            </Grid>
            <Grid margin="10px 0px" columnGap={30} dc={"1fr 1fr 1fr 1fr"} rowGap={20} tc={"1fr 1fr 1fr 1fr"}>
                <Cell>
                    <DarwinInputWrapper<AddressModel>
                        type="text"
                        label="City/Town :"
                        validateOnBlur={true}
                        viewModel={viewModel}
                        fieldName="city"
                        shrink={true}
                        maxLength={128}
                        editMode={!disabled}
                    />
                </Cell>
                {isShowCountry && (
                    <Cell>
                        <DarwinInputWrapper<AddressModel>
                            type="text"
                            label="County :"
                            validateOnBlur={true}
                            viewModel={viewModel}
                            fieldName="county"
                            shrink={true}
                            maxLength={256}
                            editMode={!disabled}
                        />
                    </Cell>
                )}
                <Cell style={{ display: "flex" }}>
                    <div style={{ width: "50%", flexDirection: "row" }}>
                        <DarwinInputWrapper<AddressModel>
                            type="text"
                            label="Postcode"
                            validateOnBlur={true}
                            viewModel={viewModel}
                            fieldName="postcode"
                            shrink={true}
                            maxLength={64}
                            style={{ maxWidth: "95%" }}
                            editMode={!disabled}
                        />
                    </div>
                    {!isDeliveryAddresses && (
                        <div style={{ width: "50%", flexDirection: "row", paddingTop: "20px" }}>
                            {showAddButton && (
                                <AcceptButton onClick={upsertAddress} style={{ maxWidth: "90%", marginLeft: "20px", visibility: showAddButton ? "visible" : "hidden" }}>
                                    {isLoading ? <span>Please wait...</span> : <span>{buttonLabel != undefined ? buttonLabel : "Add address"}</span>}
                                </AcceptButton>
                            )}
                        </div>
                    )}
                </Cell>
            </Grid>

            {isDeliveryAddresses && (
                <>
                    <Grid margin="10px 0px" columnGap={30} dc={"1fr 1fr 1fr 1fr"} rowGap={20} tc={"1fr 1fr 1fr 1fr"}>
                        <Cell>
                            <DarwinInputWrapper<AddressModel>
                                type="text"
                                label="Delivery contact name:"
                                validateOnBlur={true}
                                viewModel={viewModel}
                                fieldName="deliveryContactName"
                                shrink={true}
                                maxLength={128}
                                editMode={!disabled}
                            />
                        </Cell>
                        <Cell>
                            <DarwinInputWrapper<AddressModel>
                                type="text"
                                label="Delivery contact number:"
                                validateOnBlur={true}
                                viewModel={viewModel}
                                fieldName="deliveryContactNumber"
                                shrink={true}
                                maxLength={128}
                                editMode={!disabled}
                            />
                        </Cell>
                    </Grid>
                    <Grid margin="10px 0px" columnGap={30} dc={"2fr 1fr 1fr 1fr"} rowGap={20} tc={"2fr 1fr 1fr 1fr"}>
                        <Cell>
                            <DeliveryInstructionsContainer>
                                <DarwinInputWrapper<AddressModel>
                                    type="text"
                                    multiLine={true}
                                    label="Standard delivery instructions:"
                                    validateOnBlur={true}
                                    viewModel={viewModel}
                                    fieldName="deliveryInstructions"
                                    shrink={true}
                                    editMode={!disabled}
                                />
                            </DeliveryInstructionsContainer>
                        </Cell>
                        <div style={{ width: "50%", flexDirection: "row", paddingTop: "20px" }}>
                            {showAddButton && (
                                <AcceptButton
                                    onClick={upsertAddress}
                                    style={{ maxWidth: "90%", marginLeft: "20px", visibility: showAddButton ? "visible" : "hidden" }}
                                    disabled={disabled}
                                >
                                    {isLoading ? <span>Please wait...</span> : <span>{buttonLabel != undefined ? buttonLabel : "Add address"}</span>}
                                </AcceptButton>
                            )}
                        </div>
                        <div>
                            <>
                                <div className="icon" onClick={() => viewModel.setIsDeleted(true)} style={{ cursor: "pointer" }}>
                                    <img src={DeleteLogo} />
                                </div>
                            </>
                        </div>
                    </Grid>
                </>
            )}
        </>
    ));
};

const DeliveryInstructionsContainer: any = styled.div`
    textarea {
        height: 75px !important;
    }
`;
