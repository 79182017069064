import { observable } from "mobx";
import { ModelBase } from "@shoothill/core";
import { RFICategoryDTO } from "./RFICategoryModel";
import { RFIStatusDTO, RFIFullStatusDTO } from "./RFIStatusModel";
import { RFIItemModel, RFIItemModelDTO } from "./RFIItemModel";
import { RFIItemResponseModel, RFIItemResponseModelDTO } from "./RFIItemResponseModel";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class RFIModel extends ModelBase<RFIModel, RFIModelDTO> {
    @observable
    public rfiItems: RFIItemModel[] = [];

    @observable
    public rfiItemResponses: RFIItemResponseModel[] = [];

    //fromDto is required but you can leave it blank
    fromDto(model: RFIModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else if (key === "rfiListItems") {
                    // Do nothing.
                } else if (key === "rfiResponses") {
                    // Do nothing.
                } else {
                    this[key] = model[key];
                }
            }
        }

        // Process the list
        let processedPos: RFIItemModel[] = [];

        for (const item of model.rfiListItems) {
            const itemToAdd = new RFIItemModel();
            itemToAdd.fromDto(item);
            processedPos.push(itemToAdd);
        }

        this.rfiItems = processedPos;

        // Process the responses
        let processedResponsePos: RFIItemResponseModel[] = [];

        for (const itemResponse of model.rfiResponses) {
            const itemResponseToAdd = new RFIItemResponseModel();
            itemResponseToAdd.fromDto(itemResponse);
            processedResponsePos.push(itemResponseToAdd);
        }

        this.rfiItemResponses = processedResponsePos;
    }

    //toDto is required but you can leave it blank
    toDto(model: RFIModel): void {}
}

export type RFIModelDTO = {
    rfiListItems: RFIItemModelDTO[];
    rfiCategories: RFICategoryDTO[];
    rfiStatuses: RFIStatusDTO[];
    rfiResponses: RFIItemResponseModelDTO[];
    fullStatuses: RFIFullStatusDTO[];
};
