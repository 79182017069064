// Libraries
import React, { useEffect } from "react";
import MaterialTable from "material-table";
import * as Defaults from "Globals/Defaults/TableOptions";
import { useObserver, useRouter } from "@shoothill/core";
import { DarwinTablePageContent, OrderTable } from "Globals/Styles/AppStyling";
import { RequisitionsTableModel } from "../Commercial/OrdersAndRequisitions/Requisitions.Model";
import { AppUrls } from "AppUrls";
import { formatCurrencyFromPounds, formatDate, formatEmptyValueForTableDash } from "Utils/Format";
import { PatchedPagination } from "../../../Components/Table/PatchedPagination";

interface IRequisitionTableViewProps {
    data: RequisitionsTableModel[];
    isLoading: boolean;
}

export const AllRequisitionsTableView: React.FunctionComponent<IRequisitionTableViewProps> = (props: IRequisitionTableViewProps) => {
    const { history } = useRouter();

    useEffect(() => {}, []);

    const handleRowClick = (e: any, rowData: RequisitionsTableModel | undefined) => {
        if (rowData !== undefined) {
            history.push(AppUrls.Client.PurchaseOrder.View.replace(":poid", rowData.id));
        }
    };

    return useObserver(() => (
        <div style={{ width: "100%", paddingTop: 30 }}>
            <DarwinTablePageContent>
                <OrderTable>
                    <MaterialTable
                        columns={[
                            {
                                title: "Req no.",
                                field: "poNumberFormatted",
                                width: "auto",
                                render: (rowData: RequisitionsTableModel) => <>{formatEmptyValueForTableDash(rowData.poNumberFormatted)}</>,
                            },
                            {
                                title: "Purchase order description",
                                field: "itemDescription",
                                width: "auto",
                                render: (rowData: RequisitionsTableModel) => <>{formatEmptyValueForTableDash(rowData.itemDescription)}</>,
                            },
                            {
                                title: "Project",
                                field: "projectName",
                                width: "auto",
                                render: (rowData: RequisitionsTableModel) => <>{formatEmptyValueForTableDash(rowData.projectName)}</>,
                            },
                            {
                                title: "Raised by",
                                field: "raisedBy",
                                width: "auto",
                                render: (rowData: RequisitionsTableModel) => <>{formatEmptyValueForTableDash(rowData.raisedBy)}</>,
                            },
                            {
                                title: "Date",
                                field: "createdDate",
                                width: "auto",
                                type: "date",
                                render: (rowData: RequisitionsTableModel) => (
                                    <>{formatEmptyValueForTableDash(formatDate(rowData.createdDate !== null ? rowData.createdDate.toString() : ""))}</>
                                ),
                            },
                            {
                                title: "Supplier",
                                field: "supplierName",
                                width: "auto",
                                render: (rowData: RequisitionsTableModel) => <>{formatEmptyValueForTableDash(rowData.supplierName)}</>,
                            },
                            {
                                title: "Committed cost",
                                field: "committedCost",
                                width: "auto",
                                render: (rowData: RequisitionsTableModel) => <div style={{ textAlign: "right" }}>{formatCurrencyFromPounds(rowData.committedCost)}</div>,
                            },
                            {
                                title: "Status",
                                field: "status",
                                width: "auto",
                                render: (rowData: RequisitionsTableModel) => <>{formatEmptyValueForTableDash(rowData.status)}</>,
                            },
                        ]}
                        components={{
                            Pagination: PatchedPagination,
                        }}
                        options={Defaults.GetDarwinTableOptionsNoSearch()}
                        data={props.data}
                        onRowClick={handleRowClick}
                        title=""
                        isLoading={props.isLoading}
                    />
                </OrderTable>
            </DarwinTablePageContent>
        </div>
    ));
};
