import { ModelBase, isNullOrEmpty } from "@shoothill/core";
import { IsNotEmpty, ValidateIf } from "class-validator";
import { action, observable, runInAction } from "mobx";
import { InductionUserLookupModel } from "../Inspections/InductionUserLookupModel";

export class InductionUserModel extends ModelBase<InductionUserModel> {
    @observable
    public id: string | null = null;
    @observable
    public toolboxTalkId: string | null = null;
    @observable
    public inductionUserId: string | null = null;
    @observable
    public inductionUser: InductionUserLookupModel = new InductionUserLookupModel();
    @observable
    public signatureURL: string = "";
    @observable
    public isDeleted: boolean = false;
    @observable
    public createdByUserId: string | null = "";
    @observable
    public createdDate: string | null = "";
    constructor() {
        super();
    }

    @action
    public handleDelete = (val: boolean) => {
        this.isDeleted = val;
    };

    fromDto(model: any): void {
        this.id = model.id;
        this.toolboxTalkId = model.toolboxTalkId;
        this.inductionUserId = model.inductionUserId;
        this.signatureURL = model.signatureURL;
        this.isDeleted = model.isDeleted;
        this.createdByUserId = model.createdByUserId;
        this.createdDate = model.createdDate;
    }

    toDto() {
        return {
            id: this.id,
            toolboxTalkId: this.toolboxTalkId,
            inductionUserId: this.inductionUserId,
            signatureURL: this.signatureURL,
            isDeleted: this.isDeleted,
            createdByUserId: this.createdByUserId,
            createdDate: this.createdDate,
        };
    }
}
