import { FieldType, ViewModelBase, isEmptyOrWhitespace } from "@shoothill/core";
import type { ValidationResponse } from "@shoothill/core";
import { IEGridItemModel, IEGridItemModelDTO } from "./IEGridItemModel";
import { observable, action, computed } from "mobx";

import { CommercialViewModel, IEItemLineUpsert } from "../Commercial.ViewModel";
import { VARGridItemModel } from "Views/Project/Variations/VARGridItemModel";
import { validateTwoDecimalPlaces } from "Utils/Utils";

export class IEGridItemViewModel extends ViewModelBase<IEGridItemModel> {
    private static _instance: IEGridItemViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    private ieCommercialViewModel = CommercialViewModel.Instance;

    @observable public errorMessage: string = "";

    public constructor() {
        super(new IEGridItemModel(), false);
        this.setDecorators(IEGridItemModel);
    }

    @observable public isCentral: boolean = false;

    @action
    public setIsCentral(val: boolean) {
        this.isCentral = val;
    }

    @action
    public loadModel(item: IEGridItemModel | null, parentId: string) {
        if (item !== null) {
            let dto = item.toDto();
            this.model.fromDto(dto);
        }
        this.model.parentId = parentId;
    }

    @computed
    private get validateEstimateCost(): ValidationResponse {
        const errorMessage = this.model.validateEstimateCost;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateIncome(): ValidationResponse {
        const errorMessage = this.model.validateIncome;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateFutureSpend(): ValidationResponse {
        const errorMessage = this.model.validateFutureSpend;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    public get isTaxonomyValid(): boolean {
        let retVal = this.isCategoryIdValid && this.isSubcategoryIdValid && this.isDescriptionIdValid && this.isDescriptionOtherValid;
        if (IEGridItemViewModel.Instance.isCentral) {
            retVal = this.isCategoryIdValid && this.isSubcategoryIdValid && this.isDescriptionIdValid;
        }
        return retVal;
    }

    @computed
    public get isCategoryIdValid(): boolean {
        return this.model.category !== undefined && this.model.category > 0 ? true : false;
    }

    @computed
    public get isSubcategoryIdValid(): boolean {
        return this.model.subCategory !== undefined && this.model.subCategory > 0 ? true : false;
    }

    @computed
    public get isDescriptionIdValid(): boolean {
        return this.model.descriptionId !== undefined && (this.model.descriptionId > 0 || this.model.descriptionId === -2) ? true : false;
    }

    @computed
    public get isDescriptionOtherValid(): boolean {
        return this.model.descriptionId === -2 && this.model.descriptionOther.length > 0 ? true : false;
    }

    public async isFieldValid(fieldName: keyof FieldType<IEGridItemModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        switch (fieldName) {
            case "income": {
                const result = this.validateIncome;

                errorMessage = result.errorMessage;
                isValid = result.isValid;
                break;
            }

            case "estimateCost": {
                const result = this.validateEstimateCost;
                errorMessage = result.errorMessage;
                isValid = result.isValid;
                break;
            }

            case "futureSpend": {
                const result = this.validateFutureSpend;

                errorMessage = result.errorMessage;
                isValid = result.isValid;
                break;
            }
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    /**
     * IEItem and Realated Guid for PO/PR
     */
    @observable
    public ieAndRelatedGuids: PopulateFromIEItemModel = {
        projectId: "",
        ieId: "",
        ieCategoryId: "",
        ieCategoryName: "",
        ieSubcategoryId: "",
        ieSubcategoryName: "",
        ieItemLineId: "",
        ieItemName: "",
    };

    /**
     * VariationItem and Realated Guid for PO/PR
     */
    @observable
    public variationAndRelatedGuids: PopulateFromVariationItemModel = {
        projectId: "",
        ieId: "",
        variationId: "",
        variationCategoryId: "",
        variationCategoryName: "",
        variationSubcategoryId: "",
        variationSubcategoryName: "",
        variationItemLineId: "",
        variationItemName: "",
    };

    @action
    public setIEAndRelatedGuids = (guids: PopulateFromIEItemModel) => {
        this.ieAndRelatedGuids = {
            projectId: guids.projectId,
            ieId: guids.ieId,
            ieCategoryId: guids.ieCategoryId,
            ieCategoryName: guids.ieCategoryName,
            ieSubcategoryId: guids.ieSubcategoryId,
            ieSubcategoryName: guids.ieSubcategoryName,
            ieItemLineId: guids.ieItemLineId,
            ieItemName: guids.ieItemName,
        };
    };

    @action
    public setVariationAndRelatedGuids = (guids: PopulateFromVariationItemModel) => {
        this.variationAndRelatedGuids = {
            projectId: guids.projectId,
            ieId: guids.ieId,
            variationId: guids.variationId,
            variationCategoryId: guids.variationCategoryId,
            variationCategoryName: guids.variationCategoryName,
            variationSubcategoryId: guids.variationSubcategoryId,
            variationSubcategoryName: guids.variationSubcategoryName,
            variationItemLineId: guids.variationItemLineId,
            variationItemName: guids.variationItemName,
        };
    };

    @computed
    public get getIEAndRelatedGuids(): PopulateFromIEItemModel {
        return this.ieAndRelatedGuids;
    }

    @computed
    public get getVariationAndRelatedGuids(): PopulateFromVariationItemModel {
        return this.variationAndRelatedGuids;
    }

    @action
    public resetRelatedGuids = () => {
        this.ieAndRelatedGuids = {
            projectId: "",
            ieId: "",
            ieCategoryId: "",
            ieCategoryName: "",
            ieSubcategoryId: "",
            ieSubcategoryName: "",
            ieItemLineId: "",
            ieItemName: "",
        };
        this.variationAndRelatedGuids = {
            projectId: "",
            ieId: "",
            variationId: "",
            variationCategoryId: "",
            variationCategoryName: "",
            variationSubcategoryId: "",
            variationSubcategoryName: "",
            variationItemLineId: "",
            variationItemName: "",
        };
    };

    private isMyModelValid = async (): Promise<boolean> => {
        let isValid = true;

        // If you have a child form, you can call it's own viewmodels validation from here.
        /*if ((await this.addressFormViewModel.isModelValid()) === false) {
            isValid = false;
        }*/

        // Could also perform this in a loop if you have a list of child forms.
        /*for (let i = 0; i < this.shiftViewModels.length; i++) {
            let shift = this.shiftViewModels[i];

            if (shift.model.isEmptyForm === false) {
                if ((await shift.isModelValid()) === false) {
                    isValid = false;
                }
            }
        }*/

        // this will automatically call isFieldValid.
        if ((await this.isModelValid()) === false) {
            isValid = false;
        }
        return isValid;
    };

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}

export interface IECategory {
    id: string;
    iEId: string;
    categoryId: number;
}

export interface IESubcategory {
    id: string;
    categoryId: string;
    subCategoryId: number;
}

export interface IEUpsertLineDTO {
    category: IECategory;
    subcategory: IESubcategory;
    ieItem: IEGridItemModelDTO;
}

export interface VARCategory {
    id: string;
    varId: string;
    categoryId: number;
}

export interface VARSubcategory {
    id: string;
    categoryId: string;
    subCategoryId: number;
}

export interface VARUpsertLineDTO {
    category: VARCategory;
    subcategory: VARSubcategory;
    varItem: VARGridItemModel;
}

/**
 * Use for pushing I&E item line to requisition form
 */
export interface PopulateFromIEItemModel {
    projectId: string;
    ieId: string;
    ieCategoryId: string;
    ieCategoryName: string | undefined;
    ieSubcategoryId: string;
    ieSubcategoryName: string | undefined;
    ieItemLineId: string;
    ieItemName: string;
}

export interface PopulateFromVariationItemModel {
    projectId: string;
    ieId: string;
    variationId: string;
    variationCategoryId: string;
    variationCategoryName: string | undefined;
    variationSubcategoryId: string;
    variationSubcategoryName: string | undefined;
    variationItemLineId: string;
    variationItemName: string;
}
