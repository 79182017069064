export class HistoryItemViewModel {
    // #region Properties

    public planOfWorkStageOrdinal: number = 0;
    public planOfWorkStageDisplayName: string = "";
    public drawings: IDrawings[] = [];

    // #endregion Properties
}

export interface IDrawings {
    latestDrawing: IDrawing;
    previousDrawings: IDrawing[];
}

export interface IDrawing {
    drawingId: string;
    drawingFileName: string;
    drawingAttachmentUrl: string;
    drawingInlineUrl: string;
    drawingRevisionNumber: number;
}
