import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { AccidentFormViewModel } from "./AccidentFormViewModel";
import { BackButtonHolder } from "../../../../Commercial/IETables/IEGrid.Styles";
import { AppUrls } from "../../../../../../AppUrls";
import { CustomArrow } from "../../../../CustomComponents";
import { pxToRem, theme } from "../../../../../../Globals/Styles/AppTheme";
import { DashedDivider, SolidDivider } from "../../../../../PurchaseOrder/Form/Views/Dividers";
import { FormHeader } from "../../../../../PurchaseOrder/Form/Views/FormHeaders";
import { formatIncidentNumber } from "../../../../../../Utils/Format";
import { Form, FormSection } from "../../../../../PurchaseOrder/Form/Views/Forms";
import styled from "styled-components";
import { Box, FormHelperText, FormLabel, IconButton, TextField, Typography } from "@material-ui/core";
import { Cell, FieldType, Grid, useRouter } from "@shoothill/core";
import { DarwinSelect } from "../../../../../../Components/AutoComplete/DarwinSelect";
import { DarwinDateSelect } from "../../../../../../Components/DateSelect/DarwinDateSelect";
import { Signature } from "../../../../../../Components/Signature/Signature";
import { LegalTextContainer } from "../WitnessStatement/IncidentFormWitnessStatementView";
import { SHBox } from "../../../../../../Components/Box";
import { DarwinTimeSelect } from "../../../../../../Components/TimeSelect/DarwinTimeSelect";
import { DefaultButton, PrimaryButton } from "../../../../../../Components/Buttons/Buttons";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { AccidentFormModel } from "./AccidentFormModel";
import { DarwinRadioButtons } from "Components/Form/DarwinRadioButtons";
import { RootCauseChecks } from "./RootCauseChecks";
import { GenericFreeTextListItemModel } from "../Supporting/GenericFreeTextListItemModel";
import { GenericListItemDTO } from "../WitnessStatement/IncidentFormWitnessStatementModel";
import { Alert, AlertTitle } from "@material-ui/lab";
import { DarwinInputWrapper } from "Components/Form/DarwinInputWrapper";
import { AccidentDocumentType, AccidentPhotoListItemModel } from "./Supporting/AccidentPhotoListItemModel";
import { Uploader } from "Components/Uploader/Uploader";
import SystemUpdateTwoToneIcon from "@material-ui/icons/SystemUpdateTwoTone";
import { AccidentGenericListItemModel, GenericViewListItemDTO } from "./Supporting/AccidentGenericListItemModel";

interface Props {
    className?: string;
}
export const AccidentFormView: React.FC<Props> = observer((props: Props) => {
    const { match } = useRouter();
    const { incidentaccidentid, incidentid } = match.params as any;
    const [viewModel] = useState(() => new AccidentFormViewModel(incidentaccidentid, incidentid));
    const [currentRootCause, setCurrentRootCause] = useState<string>("");

    useEffect(() => {
        return () => {
            viewModel.reset();
        };
    }, []);

    const COLUMNS8 = "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr";

    const onSubmit = (event: any) => {
        event.preventDefault();
    };

    /**
     * Renders a pair of radio buttons.
     * @param label The question or statement. The label for the form group.
     * @param fieldName The model fieldname.
     * @returns Radio button form group component.
     */
    const renderRadioButtons = (label: string, fieldName: keyof FieldType<AccidentFormModel>, isDisabled: boolean, onChange?: (value: boolean) => void): JSX.Element => {
        return (
            <DarwinRadioButtons<AccidentFormModel>
                error={viewModel.getError(fieldName) !== ""}
                validationMessage={viewModel.getError(fieldName)}
                execute={(val: boolean) => (onChange ? onChange(val) : viewModel.setValue(fieldName, val))}
                canExecute={!isDisabled}
                value={viewModel.getValue(fieldName)}
                fieldName={fieldName}
                label={label}
            />
        );
    };

    /**
     *
     * @param label The label of the form control.
     * @param fieldName The model fieldname.
     * @param isDisabled Whether the form control should be disabled.
     * @returns Text area form control.
     */
    const renderTexArea = (label: string, fieldName: keyof FieldType<AccidentFormModel>, isDisabled: boolean): JSX.Element => {
        return (
            <TextField
                type={"area"}
                value={viewModel.getValue(fieldName)}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => viewModel.setValue(fieldName, e.target.value)}
                error={viewModel.getError(fieldName) !== ""}
                disabled={isDisabled}
                label={label}
                InputLabelProps={{
                    shrink: true,
                }}
                multiline
                minRows={4}
                placeholder={"Please add notes"}
                helperText={viewModel.getError(fieldName)}
            />
        );
    };

    /**
     * Renders a list of generic items and its form controls. Uses a textbox to add to the list.
     * @param label The label of the form control.
     * @param currentItem The current item typed in the text box. Before the add button is clicked.
     * @param items The list of items that have been added.
     * @param onChange The change function for the text input.
     * @param handleAdd Function to add the current item in the text box to the list.
     * @param isDisabled Whether the form control should be disabled.
     * @returns A list of generic items and its form controls.
     */
    const renderGenericTextList = (
        label: string,
        currentItem: string,
        items: GenericFreeTextListItemModel[],
        onChange: (value: React.SetStateAction<string>) => void,
        handleAdd: (itemName: string) => void,
        isDisabled: boolean,
        validationMessage: string | null,
    ): JSX.Element => {
        return (
            <>
                <SHBox grid dc={"262px 116px"} alignItems={"flex-end"} mb={3}>
                    <TextField
                        type="text"
                        value={currentItem}
                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => onChange(e.target.value)}
                        fullWidth
                        disabled={isDisabled}
                        label={label}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        placeholder={"Please select"}
                    />
                    <PrimaryButton
                        displayName="Add"
                        execute={() => {
                            if (currentItem !== "") {
                                handleAdd(currentItem);
                                onChange("");
                            }
                        }}
                        fullWidth={true}
                        canExecute={!viewModel.IsLoading && currentItem !== "" && !isDisabled}
                    />
                </SHBox>
                <SHBox mb={3}>
                    <div className="uploadedFile">
                        {items.map((item: GenericFreeTextListItemModel, index: number) => {
                            return (
                                <div className="uploadedFile-box">
                                    <div className="uploadedFile-heading">
                                        <h3>{item.name}</h3>
                                    </div>
                                    <IconButton onClick={() => item.handleDelete(true)} disabled={isDisabled}>
                                        <DeleteOutlinedIcon />
                                    </IconButton>
                                </div>
                            );
                        })}
                    </div>
                </SHBox>
                <FormHelperText style={{ color: "#f44336" }}>{validationMessage ? validationMessage : ""}</FormHelperText>
            </>
        );
    };

    /**
     * Renders a list of generic items and its form controls. Used a dropdown to add to the list.
     * @param label The label of the form control.
     * @param currentItem The current item in the dropdown. Before the add button is clicked.
     * @param options The list of options for the dropdown.
     * @param items The list of items that have been added.
     * @param onChange The change function for the dropdown.
     * @param handleAdd Function to add the current item in the dropdown to the list.
     * @param isDisabled Whether the form control should be disabled.
     * @param handleDelete Function to addeleted the current item in the dropdown from the list.
     * @returns A list of generic items and its form controls.
     */
    const renderGenericDropdownList = (
        label: string,
        currentItem: {
            id: string;
            displayName: string;
        } | null,
        options: GenericListItemDTO[],
        items: AccidentGenericListItemModel[],
        onChange: (item: { id: string; displayName: string }) => void,
        handleAdd: () => void,
        isDisabled: boolean,
        handleDelete: (id: string | null) => void,
        notInSystemPlaceholder: string,
        validationMessage: string | null,
    ): JSX.Element => {
        return (
            <>
                <SHBox grid dc={"262px 116px"} alignItems={"flex-end"} mb={3}>
                    <DarwinSelect
                        displayName={label}
                        execute={(data: { id: string; displayName: string }) => onChange(data)}
                        fullWidth={true}
                        getOptionLabel={(option: any) => option.displayName}
                        options={options}
                        placeholder="Please select"
                        value={currentItem}
                        canExecute={!isDisabled}
                    />
                    <PrimaryButton displayName="Add" execute={() => handleAdd()} canExecute={!isDisabled && !viewModel.IsLoading && currentItem !== null} />
                </SHBox>
                <SHBox grid dc={"1fr 1fr"} mt={3}>
                    {items.map((item, index) => {
                        return (
                            <div className="uploadedFile-box" key={index}>
                                <div className="uploadedFile-heading">
                                    {item.isNotInSystem && (
                                        <TextField
                                            type="text"
                                            value={item.notInSystem}
                                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => item.setValue("notInSystem", e.target.value)}
                                            fullWidth
                                            error={item.getError("notInSystem") !== ""}
                                            disabled={isDisabled}
                                            label={""}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            placeholder={notInSystemPlaceholder}
                                            helperText={item.getError("notInSystem")}
                                        />
                                    )}
                                    {!item.isNotInSystem && <h3>{item.displayName}</h3>}
                                </div>
                                <IconButton onClick={() => handleDelete(item.typeId)} disabled={isDisabled} title={"Delete"}>
                                    <DeleteOutlinedIcon />
                                </IconButton>
                            </div>
                        );
                    })}
                </SHBox>
                <FormHelperText style={{ color: "#f44336" }}>{validationMessage ? validationMessage : ""}</FormHelperText>
            </>
        );
    };

    /**
     * Renders a document uploader and the list of uploaded documents.
     * @param label The label for the form control.
     * @param dropzoneMessage The message displayed in the uploader dropzone.
     * @param addedLabel The label to show above the added documents.
     * @param items The list of documents that have already been added.
     * @param documentType The type of document.
     * @param isDisabled Whether the form control should be disabled or not.
     * @returns A document uploader and the list of uploaded documents.
     */
    const renderDocumentUploaderAndList = (
        label: string,
        dropzoneMessage: string,
        addedLabel: string,
        items: AccidentPhotoListItemModel[],
        documentType: AccidentDocumentType,
        isDisabled: boolean,
    ) => {
        return (
            <SHBox mb={3}>
                {!isDisabled && (
                    <SHBox grid dc={"50%"}>
                        <Uploader
                            handleSelectFile={(e: React.ChangeEvent<HTMLInputElement>) => viewModel.fileChange(e, documentType)}
                            isFormDisabled={isDisabled}
                            label={label}
                            dropzoneMessage={dropzoneMessage}
                        />
                    </SHBox>
                )}

                {items.length > 0 && (
                    <SHBox>
                        {items.length > 0 && (
                            <FormLabel component="label">
                                <span className="control-label">{addedLabel}</span>
                            </FormLabel>
                        )}

                        <div className="uploadedFile">
                            {items.map((item: AccidentPhotoListItemModel, index: number) => {
                                return (
                                    <div className="uploadedFile-box">
                                        <div className="uploadedFile-heading">
                                            <IconButton onClick={() => viewModel.DownloadFile(item.photoURL, item.fileName)}>
                                                <SystemUpdateTwoToneIcon />
                                            </IconButton>
                                            <h3>{item.fileName}</h3>
                                        </div>
                                        <IconButton onClick={() => item.handleDelete(true)} disabled={isDisabled}>
                                            <DeleteOutlinedIcon />
                                        </IconButton>
                                    </div>
                                );
                            })}
                        </div>
                    </SHBox>
                )}
            </SHBox>
        );
    };

    return (
        <>
            <Form className={props.className} onSubmit={onSubmit}>
                <BackButtonHolder
                    onClick={() => {
                        viewModel.history.push(
                            AppUrls.Client.Project.Incident.View.replace(":projectid", viewModel.incident!.projectId).replace(":incidentid", viewModel.incident!.id!),
                        );
                    }}
                    style={{ minHeight: "30px", paddingTop: "3px" }}
                >
                    <div style={{ position: "relative", height: "30px", display: "flex", marginLeft: "10px" }}>
                        <div style={{ position: "absolute", top: "5px" }}>
                            <CustomArrow size={"8px"} color={theme.palette.blue.main} type={"left"} />
                        </div>
                        <div style={{ marginLeft: "20px", fontSize: "18px", fontWeight: "bold", color: theme.palette.blue.main }}>
                            Back to incident ({viewModel.incident ? formatIncidentNumber(viewModel.incident.incidentNumber) : ""})
                        </div>
                    </div>
                </BackButtonHolder>

                <DashedDivider gutterBottom={false} gutterTop={false} borderThickness={2} borderOpacity={0.5} />

                <HeaderContainer>
                    <FormHeader displayName={"New accident"} />
                </HeaderContainer>

                <FormSection>
                    <ProjectDetailsContainer>
                        <div>
                            <span>Date / time added:</span> {viewModel.getTodayDateFormatted}
                        </div>
                        <div>
                            <span>Incident No:</span> {viewModel.incident ? formatIncidentNumber(viewModel.incident.incidentNumber) : ""}
                        </div>
                        <div>
                            <span>Project reference:</span> {viewModel.getProjectReferenceFormatted}
                        </div>
                        <div>
                            <span>Project name:</span> {viewModel.getProjectName}
                        </div>
                    </ProjectDetailsContainer>
                </FormSection>
                <DashedDivider gutterBottom={true} gutterTop={false} />

                <FormSection>
                    <SubtitleContainer>
                        <Typography variant="h2">Casualty</Typography>
                    </SubtitleContainer>{" "}
                    <SHBox flexBox mb={3}>
                        <ShortFieldContainer>
                            <DarwinSelect
                                displayName="Casualty name:"
                                execute={(data: { id: string; displayName: string }) => viewModel.handleSetCasualtyUser(data)}
                                fullWidth={true}
                                getOptionLabel={(option: any) => option.displayName}
                                options={viewModel.incidentUsers}
                                placeholder="Please select user"
                                value={viewModel.casualtyUser}
                                canExecute={!viewModel.isFirstFormDisabled}
                                error={viewModel.getError("casualtyUserId") !== ""}
                                validationMessage={viewModel.getError("casualtyUserId")}
                            />
                        </ShortFieldContainer>
                        {viewModel.canShowNotInSystemField && (
                            <ShortFieldContainer>
                                <TextField
                                    type="text"
                                    value={viewModel.getValue("casualtyNotInSystem")}
                                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => viewModel.setValue("casualtyNotInSystem", e.target.value)}
                                    fullWidth
                                    error={viewModel.getError("casualtyNotInSystem") !== ""}
                                    disabled={viewModel.isFirstFormDisabled}
                                    label={"Casualty name not in system:"}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    placeholder={"Please add"}
                                    helperText={viewModel.getError("casualtyNotInSystem")}
                                />
                            </ShortFieldContainer>
                        )}
                        <DateSelectContainer>
                            <DarwinDateSelect
                                displayName="Date of birth:"
                                execute={(value: string | null) => viewModel.setValue("casualtyDateOfBirth", value)}
                                onBlur={() => viewModel.isFieldValid("casualtyDateOfBirth", viewModel.model.casualtyDateOfBirth)}
                                placeholder="dd/mm/yyyy"
                                validationMessage={viewModel.getError("casualtyDateOfBirth")}
                                value={viewModel.getValue("casualtyDateOfBirth")}
                                canExecute={!viewModel.isFirstFormDisabled}
                            />
                        </DateSelectContainer>
                    </SHBox>
                    <SHBox grid dc={"545px 545px"} mb={3}>
                        <TextField
                            value={viewModel.getValue("addressLine1")}
                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => viewModel.setValue("addressLine1", e.target.value)}
                            error={viewModel.getError("addressLine1") !== ""}
                            disabled={viewModel.isFirstFormDisabled}
                            label={"Address line 1:"}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            placeholder={"Please add"}
                            helperText={viewModel.getError("addressLine1")}
                        />
                        <TextField
                            value={viewModel.getValue("addressLine2")}
                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => viewModel.setValue("addressLine2", e.target.value)}
                            error={viewModel.getError("addressLine2") !== ""}
                            disabled={viewModel.isFirstFormDisabled}
                            label={"Address line 2:"}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            placeholder={"Please add"}
                            helperText={viewModel.getError("addressLine2")}
                        />
                    </SHBox>
                    <SHBox grid dc={"545px 262px 152px"} mb={3}>
                        <TextField
                            value={viewModel.getValue("addressLine3")}
                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => viewModel.setValue("addressLine3", e.target.value)}
                            error={viewModel.getError("addressLine3") !== ""}
                            disabled={viewModel.isFirstFormDisabled}
                            label={"Address line 3:"}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            placeholder={"Please add"}
                            helperText={viewModel.getError("addressLine3")}
                        />
                        <TextField
                            value={viewModel.getValue("cityTown")}
                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => viewModel.setValue("cityTown", e.target.value)}
                            error={viewModel.getError("cityTown") !== ""}
                            disabled={viewModel.isFirstFormDisabled}
                            label={"City/town:"}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            placeholder={"Please add"}
                            helperText={viewModel.getError("addressLine2")}
                        />
                        <TextField
                            value={viewModel.getValue("postcode")}
                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => viewModel.setValue("postcode", e.target.value)}
                            error={viewModel.getError("postcode") !== ""}
                            disabled={viewModel.isFirstFormDisabled}
                            label={"Postcode:"}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            placeholder={"Please add"}
                            helperText={viewModel.getError("postcode")}
                        />
                    </SHBox>
                    <DashedDivider />
                    {viewModel.canShowNotInSystemField && (
                        <SHBox grid dc={"262px 262px 262px"}>
                            <DarwinSelect
                                displayName="Status of casualty:"
                                execute={(data: { id: string; displayName: string }) => viewModel.handleSetStatusOfCasualty(data)}
                                fullWidth={true}
                                getOptionLabel={(option: any) => option.displayName}
                                options={viewModel.statusOfCasualtyTypes}
                                placeholder="Please select"
                                value={viewModel.statusOfCasualty}
                                canExecute={!viewModel.isFirstFormDisabled}
                                error={viewModel.getError("statusOfCasualtyId") !== ""}
                                validationMessage={viewModel.getError("statusOfCasualtyId")}
                            />
                            <TextField
                                value={viewModel.getValue("casualtyPhoneNumber")}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => viewModel.setValue("casualtyPhoneNumber", e.target.value)}
                                error={viewModel.getError("casualtyPhoneNumber") !== ""}
                                disabled={viewModel.isFirstFormDisabled}
                                label={"Phone number:"}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                placeholder={"Please add"}
                                helperText={viewModel.getError("casualtyPhoneNumber")}
                            />
                            <TextField
                                value={viewModel.getValue("casualtyEmailAddress")}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => viewModel.setValue("casualtyEmailAddress", e.target.value)}
                                error={viewModel.getError("casualtyEmailAddress") !== ""}
                                disabled={viewModel.isFirstFormDisabled}
                                label={"Email address:"}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                placeholder={"Please add"}
                                helperText={viewModel.getError("casualtyEmailAddress")}
                            />
                        </SHBox>
                    )}
                    {viewModel.canShowCasualtyDetails && (
                        <CasualtyUserDetailsContainer>
                            <div>
                                <span>Company:</span> {viewModel.getCasualtyUserCompany}
                            </div>
                            <div>
                                <span>Phone number:</span> {viewModel.getCasualtyUserPhoneNumber}
                            </div>
                            <div>
                                <span>Email address:</span> {viewModel.getCasualtyUserEmailAdress}
                            </div>
                        </CasualtyUserDetailsContainer>
                    )}
                </FormSection>
                <DashedDivider gutterBottom={true} />
                <FormSection>
                    <SubtitleContainer>
                        <Typography variant="h2">Accident details</Typography>
                    </SubtitleContainer>
                    <SHBox grid dc={"262px 262px 262px"} mb={3}>
                        <DateSelectContainer>
                            <DarwinDateSelect
                                displayName="Date:"
                                execute={(value: string | null) => viewModel.setValue("accidentDate", value)}
                                onBlur={() => viewModel.isFieldValid("accidentDate", viewModel.model.accidentDate)}
                                placeholder="dd/mm/yyyy"
                                validationMessage={viewModel.getError("accidentDate")}
                                value={viewModel.getValue("accidentDate")}
                                canExecute={!viewModel.isFirstFormDisabled}
                            />
                        </DateSelectContainer>
                        <DateSelectContainer>
                            <DarwinTimeSelect
                                displayName="Time:"
                                execute={(value: string | null) => viewModel.setValue("accidentDate", value)}
                                onBlur={() => viewModel.isFieldValid("accidentDate", viewModel.model.accidentDate)}
                                placeholder="00:00"
                                validationMessage={viewModel.getError("accidentDate")}
                                value={viewModel.getValue("accidentDate")}
                                canExecute={!viewModel.isFirstFormDisabled}
                            />
                        </DateSelectContainer>
                        <TextField
                            value={viewModel.getValue("location")}
                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => viewModel.setValue("location", e.target.value)}
                            error={viewModel.getError("location") !== ""}
                            disabled={viewModel.isFirstFormDisabled}
                            label={"Location:"}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            placeholder={"Please add"}
                            helperText={viewModel.getError("location")}
                        />
                    </SHBox>

                    <SHBox mb={3}>{renderTexArea("What happened?", "whatHappened", viewModel.isFirstFormDisabled)}</SHBox>

                    {renderGenericDropdownList(
                        "Witness/Involved Parties:",
                        viewModel.witnessUser,
                        viewModel.getWitnessesOptions,
                        viewModel.getSelectedWitnesses,
                        viewModel.handleSetWitness,
                        viewModel.addWitness,
                        viewModel.isFirstFormDisabled,
                        viewModel.deleteWitness,
                        "Name of witness/involved party not in system",
                        viewModel.witnessesValidationMessage,
                    )}
                </FormSection>

                <DashedDivider gutterBottom={true} />
                <FormSection>
                    <SubtitleContainer>
                        <Typography variant="h2">Injury details</Typography>
                    </SubtitleContainer>
                    {renderGenericDropdownList(
                        "Injury area:",
                        viewModel.injuryArea,
                        viewModel.getInjuryAreaOptions,
                        viewModel.getSelectedInjuryAreas,
                        viewModel.handleSetInjuryArea,
                        viewModel.addInjuryArea,
                        viewModel.isFirstFormDisabled,
                        viewModel.deleteInjuryArea,
                        "Please add other injury area",
                        viewModel.injuryAreasValidationMessage,
                    )}
                    <SHBox mb={3}>{renderTexArea("Description of injury:", "descriptionOfInjury", viewModel.isFirstFormDisabled)}</SHBox>
                    <SHBox mb={3}>{renderTexArea("Description of treatment given:", "descriptionOfTreatment", viewModel.isFirstFormDisabled)}</SHBox>

                    {renderDocumentUploaderAndList(
                        "Photo(s):",
                        "Click here to add photo/file",
                        "Photo(s) added",
                        viewModel.getInjuryDetailsDocuments,
                        AccidentDocumentType.InjuryDetails,
                        viewModel.isFirstFormDisabled,
                    )}
                </FormSection>
                <DashedDivider gutterBottom={true} />
                <FormSection>
                    <SolidDivider gutterBottom={true} borderThickness={2} borderOpacity={0.5} />

                    <SubtitleContainer>
                        <Typography variant="h2">Authorisation</Typography>
                    </SubtitleContainer>

                    <LegalTextContainer>
                        <Typography variant="body1">
                            "I hereby confirm that the information in this statement is correct to the best of my knowledge and consent to any photos being taken of the injury for
                            investigation purposes only."
                        </Typography>
                    </LegalTextContainer>

                    <SignatureContainer>
                        <div>
                            <div>
                                <Signature
                                    displayName={"Witness Signature:"}
                                    execute={(data: any) => viewModel.setValue<string>("casualtySignatureBase64", data)}
                                    value={viewModel.model.casualtySignatureBase64}
                                    canExecute={!viewModel.isFirstFormDisabled}
                                />
                                {viewModel.getError("casualtySignatureBase64") !== "" && (
                                    <FormHelperText style={{ color: "#f44336" }}>{viewModel.getError("casualtySignatureBase64")}</FormHelperText>
                                )}
                            </div>
                        </div>
                    </SignatureContainer>
                    <div style={{ display: "flex", flexDirection: "row", marginBottom: "15px" }}>
                        <FormLabel component="label">
                            <span className="control-label">Date / time added:</span>
                        </FormLabel>
                        <p style={{ fontSize: "12px", marginLeft: "10px" }}>{viewModel.getFirstAiderSignatureDateFormatted}</p>
                    </div>

                    <SignatureContainer>
                        <div>
                            <div>
                                <Signature
                                    displayName={"First aider signature:"}
                                    execute={(data: any) => viewModel.setValue<string>("firstAiderSignature", data)}
                                    value={viewModel.model.firstAiderSignature}
                                    canExecute={!viewModel.isFirstFormDisabled}
                                />
                                {viewModel.getError("firstAiderSignature") !== "" && (
                                    <FormHelperText style={{ color: "#f44336" }}>{viewModel.getError("firstAiderSignature")}</FormHelperText>
                                )}
                            </div>
                        </div>
                    </SignatureContainer>
                </FormSection>
                {viewModel.model.hasInvestigationStarted && (
                    <>
                        <FormSection>
                            <SHBox grid dc={"252px 120px"} alignItems={"flex-end"} mb={3}>
                                <Cell ts={2} ds={2}>
                                    <div style={{ display: "flex", flexDirection: "row", marginBottom: "15px" }}>
                                        <FormLabel component="label">
                                            <span className="control-label">Date added:</span>
                                        </FormLabel>
                                        <p style={{ fontSize: "12px", marginLeft: "10px" }}>{viewModel.getInvestigationDateFormatted}</p>
                                    </div>
                                </Cell>
                                <DarwinSelect
                                    displayName="Investigator:"
                                    execute={(data: { id: string; displayName: string }) => viewModel.handleSetInvestigatorUser(data)}
                                    fullWidth={true}
                                    getOptionLabel={(option: any) => option.displayName}
                                    options={viewModel.incidentUsers}
                                    placeholder="Please select user"
                                    value={viewModel.investigatorUser}
                                    canExecute={!viewModel.isSecondFormDisabled}
                                    error={viewModel.getError("investigatorUserId") !== ""}
                                    validationMessage={viewModel.getError("investigatorUserId")}
                                />
                            </SHBox>

                            <SolidDivider gutterBottom={true} />

                            <SubtitleContainer>
                                <Typography variant="h2">Further injury info</Typography>
                            </SubtitleContainer>

                            <SHBox mb={3}>{renderTexArea("Description of injury:", "descriptionOfInjuryFurtherInfo", viewModel.isSecondFormDisabled)}</SHBox>

                            {renderGenericDropdownList(
                                "Type of injury:",
                                viewModel.injuryType,
                                viewModel.getInjuryTypeOptions,
                                viewModel.getSelectedInjuryTypes,
                                viewModel.handleSetInjuryType,
                                viewModel.addInjuryType,
                                viewModel.isSecondFormDisabled,
                                viewModel.deleteInjuryType,
                                "Please add other injury type",
                                viewModel.injuryTypesValidationMessage,
                            )}

                            {renderRadioButtons("Fatality", "fatality", viewModel.isSecondFormDisabled)}
                            {renderRadioButtons("Did the individual become unconscious?", "unconscious", viewModel.isSecondFormDisabled)}
                            {renderRadioButtons("Did the individual need resuscitation?", "resuscitation", viewModel.isSecondFormDisabled)}
                            {renderRadioButtons(
                                "Was the injured person advised to see their doctor, go to a walk in centre or visit a hospital?",
                                "advisedToSeeDoctor",
                                viewModel.isSecondFormDisabled,
                            )}

                            {viewModel.model.advisedToSeeDoctor === true && (
                                <>
                                    <SHBox grid dc={"262px"}>
                                        <DarwinSelect
                                            displayName="Which medical practice did they attend?"
                                            execute={(data: { id: string; displayName: string }) => viewModel.handleSetMedicalPracticeType(data)}
                                            fullWidth={true}
                                            getOptionLabel={(option: any) => option.displayName}
                                            options={viewModel.medicalPracticeTypes}
                                            placeholder="Please select"
                                            value={viewModel.medicalPractice}
                                            canExecute={!viewModel.isSecondFormDisabled}
                                            error={viewModel.getError("whichMedicalPracticeId") !== ""}
                                            validationMessage={viewModel.getError("whichMedicalPracticeId")}
                                        />
                                    </SHBox>

                                    <DashedDivider gutterBottom={true} gutterTop={true} borderThickness={2} borderOpacity={0.5} />
                                </>
                            )}

                            {renderRadioButtons("Was alcohol or drug testing required?", "alcoholOrDrugTestingRequired", viewModel.isSecondFormDisabled)}

                            {viewModel.model.alcoholOrDrugTestingRequired === true && (
                                <>
                                    <SHBox grid dc={"262px"}>
                                        <DarwinSelect
                                            displayName="What was the result?"
                                            execute={(data: { id: string; displayName: string }) => viewModel.handleSetDrugTestResultType(data)}
                                            fullWidth={true}
                                            getOptionLabel={(option: any) => option.displayName}
                                            options={viewModel.drugTestResultTypes}
                                            placeholder="Please select"
                                            value={viewModel.drugTestResult}
                                            canExecute={!viewModel.isSecondFormDisabled}
                                            error={viewModel.getError("alcoholOrDrugTestingRequiredResultId") !== ""}
                                            validationMessage={viewModel.getError("alcoholOrDrugTestingRequiredResultId")}
                                        />
                                    </SHBox>

                                    <DashedDivider gutterBottom={true} gutterTop={true} borderThickness={2} borderOpacity={0.5} />
                                </>
                            )}

                            <SHBox mb={3} mt={1}>
                                {renderTexArea("Any additional injuries found or worsening of original injury:", "additionalInjuriesOrWorsening", viewModel.isSecondFormDisabled)}
                            </SHBox>
                        </FormSection>

                        <SolidDivider gutterBottom={true} />

                        <FormSection>
                            <SubtitleContainer>
                                <Typography variant="h2">Further accident Info</Typography>
                            </SubtitleContainer>

                            <SHBox grid dc={"262px 262px 262px 262px"} mb={3}>
                                <DarwinSelect
                                    displayName="Basic cause of accident:"
                                    execute={(data: { id: string; displayName: string }) => viewModel.handleSetCauseOfAccidentType(data)}
                                    fullWidth={true}
                                    getOptionLabel={(option: any) => option.displayName}
                                    options={viewModel.accidentCauses}
                                    placeholder="Please select"
                                    value={viewModel.causeOfAccident}
                                    canExecute={!viewModel.isSecondFormDisabled}
                                    error={viewModel.getError("causeOfAccidentTypeId") !== ""}
                                    validationMessage={viewModel.getError("causeOfAccidentTypeId")}
                                />

                                {viewModel.isOtherCauseOfAccident && (
                                    <TextField
                                        value={viewModel.getValue("otherCauseOfAccident")}
                                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => viewModel.setValue("otherCauseOfAccident", e.target.value)}
                                        error={viewModel.getError("otherCauseOfAccident") !== ""}
                                        disabled={viewModel.isFirstFormDisabled}
                                        label={"Other cause of accident:"}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        placeholder={"Please add"}
                                        helperText={viewModel.getError("otherCauseOfAccident")}
                                    />
                                )}

                                {viewModel.isFallHeightCauseOfAccident && (
                                    <DarwinInputWrapper<AccidentFormModel>
                                        shrink={true}
                                        validateOnBlur={true}
                                        viewModel={viewModel}
                                        editMode={!viewModel.isSecondFormDisabled}
                                        type={"number"}
                                        inputProps={{
                                            placeholder: "m",
                                        }}
                                        fieldName="fallHeight"
                                        label="List the fall height:"
                                    />
                                )}

                                {!viewModel.isOtherCauseOfAccident && !viewModel.isFallHeightCauseOfAccident && <div></div>}

                                <DarwinSelect
                                    displayName="Source of hazard:"
                                    execute={(data: { id: string; displayName: string }) => viewModel.handleSetSourceOfHazardType(data)}
                                    fullWidth={true}
                                    getOptionLabel={(option: any) => option.displayName}
                                    options={viewModel.hazardSources}
                                    placeholder="Please select"
                                    value={viewModel.sourceOfHazard}
                                    canExecute={!viewModel.isSecondFormDisabled}
                                    error={viewModel.getError("sourceOfHazardTypeId") !== ""}
                                    validationMessage={viewModel.getError("sourceOfHazardTypeId")}
                                />

                                {viewModel.isOtherSourceOfHazard && (
                                    <TextField
                                        value={viewModel.getValue("otherSourceOfHazard")}
                                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => viewModel.setValue("otherSourceOfHazard", e.target.value)}
                                        error={viewModel.getError("otherSourceOfHazard") !== ""}
                                        disabled={viewModel.isSecondFormDisabled}
                                        label={"Other source of hazard:"}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        placeholder={"Please add"}
                                        helperText={viewModel.getError("otherSourceOfHazard")}
                                    />
                                )}

                                {!viewModel.isOtherSourceOfHazard && <div></div>}
                            </SHBox>

                            <SHBox mb={3}>
                                {renderTexArea("What part of the work process was the individual doing?", "whichPartOfWorkProcess", viewModel.isSecondFormDisabled)}
                            </SHBox>
                            <SHBox mb={3}>{renderTexArea("Description of the environment:", "descriptionOfEnvironment", viewModel.isSecondFormDisabled)}</SHBox>
                            <SHBox mb={3}>
                                {renderTexArea("Description of any equipment being used at time of accident:", "descriptionOfEquipment", viewModel.isSecondFormDisabled)}
                            </SHBox>
                            <SHBox mb={3}>
                                {renderTexArea("Detail all PPE required by the risk assessment for the operation:", "ppeRequired", viewModel.isSecondFormDisabled)}
                            </SHBox>
                            <SHBox mb={3}>{renderTexArea("Detail all PPE worn at time of incident:", "ppeWorn", viewModel.isSecondFormDisabled)}</SHBox>

                            {renderDocumentUploaderAndList(
                                "Photo(s) of any relevant training documents:",
                                "Click here to add photo/file",
                                "Photo(s) added",
                                viewModel.getTrainingDocuments,
                                AccidentDocumentType.Training,
                                viewModel.isSecondFormDisabled,
                            )}

                            {renderDocumentUploaderAndList(
                                "Attach relevant documents for any pieces of equipment that required maintenance records:",
                                "Click here to add photo/file",
                                "Document(s) added",
                                viewModel.getEquipmentMaintenanceDocuments,
                                AccidentDocumentType.EquipmentMaintenance,
                                viewModel.isSecondFormDisabled,
                            )}

                            {renderRadioButtons("Was there a Risk Assessment in place covering these works?", "wasThereARiskAssessment", viewModel.isSecondFormDisabled)}

                            {viewModel.model.wasThereARiskAssessment &&
                                renderDocumentUploaderAndList(
                                    "Photo(s) of front sheet of RA and signed sheet:",
                                    "Click here to add photo/file",
                                    "Photo(s) added",
                                    viewModel.getRAAndSignedSheetDocuments,
                                    AccidentDocumentType.RAAndSignedSheet,
                                    viewModel.isSecondFormDisabled,
                                )}

                            {renderRadioButtons("Was there a Method Statement in place covering these works?", "wasThereAMethodStatement", viewModel.isSecondFormDisabled)}

                            {viewModel.model.wasThereAMethodStatement &&
                                renderDocumentUploaderAndList(
                                    "Photo(s) of front sheet of MS and signed sheet:",
                                    "Click here to add photo/file",
                                    "Photo(s) added",
                                    viewModel.getMSAndSignedSheetDocuments,
                                    AccidentDocumentType.MSAndSignedSheet,
                                    viewModel.isSecondFormDisabled,
                                )}

                            {renderRadioButtons("Was the accident RIDDOR reportable?", "wasTheAccidentRIDDORReportable", viewModel.isSecondFormDisabled, (val: boolean) => {
                                viewModel.setValue<boolean | null>("wasTheAccidentRIDDORReportable", val);
                                if (!val) {
                                    viewModel.setValue<Date | null>("dateRIDDORWasSent", null);
                                }
                            })}

                            {viewModel.model.wasTheAccidentRIDDORReportable && (
                                <SHBox grid dc={"150px 150px"} alignItems={"flex-end"} mb={3}>
                                    <DateSelectContainer>
                                        <DarwinDateSelect
                                            displayName="Date RIDDOR was sent:"
                                            execute={(value: string | null) => viewModel.setValue("dateRIDDORWasSent", value)}
                                            onBlur={() => viewModel.isFieldValid("dateRIDDORWasSent", viewModel.model.dateRIDDORWasSent)}
                                            placeholder="dd/mm/yyyy"
                                            validationMessage={viewModel.getError("dateRIDDORWasSent")}
                                            value={viewModel.getValue("dateRIDDORWasSent")}
                                            canExecute={!viewModel.isSecondFormDisabled && !viewModel.IsLoading}
                                        />
                                    </DateSelectContainer>
                                    <DateSelectContainer>
                                        <DarwinTimeSelect
                                            displayName="Time RIDDOR was sent:"
                                            execute={(value: string | null) => viewModel.setValue("dateRIDDORWasSent", value)}
                                            onBlur={() => viewModel.isFieldValid("dateRIDDORWasSent", viewModel.model.dateRIDDORWasSent)}
                                            placeholder="00:00"
                                            validationMessage={viewModel.getError("dateRIDDORWasSent")}
                                            value={viewModel.getValue("dateRIDDORWasSent")}
                                            canExecute={!viewModel.isSecondFormDisabled && !viewModel.IsLoading}
                                        />
                                    </DateSelectContainer>
                                </SHBox>
                            )}

                            {renderRadioButtons("Was any time lost?", "wasAnyTimeLost", viewModel.isSecondFormDisabled, (val: boolean) => {
                                viewModel.setValue<boolean | null>("wasAnyTimeLost", val);
                                if (!val) {
                                    viewModel.setValue<Date | null>("dateWorkFinished", null);
                                    viewModel.setValue<Date | null>("dateWorkRestarted", null);
                                    viewModel.setValue<number | null>("totalTimeLost", null);
                                }
                            })}

                            {viewModel.model.wasAnyTimeLost && (
                                <SHBox grid dc={"150px 150px 150px 150px 150px"} alignItems={"flex-end"} mb={3}>
                                    <DateSelectContainer>
                                        <DarwinDateSelect
                                            displayName="Date work finished:"
                                            execute={(value: string | null) => viewModel.setValue("dateWorkFinished", value)}
                                            onBlur={() => viewModel.isFieldValid("dateWorkFinished", viewModel.model.dateWorkFinished)}
                                            placeholder="dd/mm/yyyy"
                                            validationMessage={viewModel.getError("dateWorkFinished")}
                                            value={viewModel.getValue("dateWorkFinished")}
                                            canExecute={!viewModel.isSecondFormDisabled && !viewModel.IsLoading}
                                        />
                                    </DateSelectContainer>
                                    <DateSelectContainer>
                                        <DarwinTimeSelect
                                            displayName="Time work finished:"
                                            execute={(value: string | null) => viewModel.setValue("dateWorkFinished", value)}
                                            onBlur={() => viewModel.isFieldValid("dateWorkFinished", viewModel.model.dateWorkFinished)}
                                            placeholder="00:00"
                                            validationMessage={viewModel.getError("dateWorkFinished")}
                                            value={viewModel.getValue("dateWorkFinished")}
                                            canExecute={!viewModel.isSecondFormDisabled && !viewModel.IsLoading}
                                        />
                                    </DateSelectContainer>
                                    <DateSelectContainer>
                                        <DarwinDateSelect
                                            displayName="Date work restarted:"
                                            execute={(value: string | null) => viewModel.setValue("dateWorkRestarted", value)}
                                            onBlur={() => viewModel.isFieldValid("dateWorkRestarted", viewModel.model.dateWorkRestarted)}
                                            placeholder="dd/mm/yyyy"
                                            validationMessage={viewModel.getError("dateWorkRestarted")}
                                            value={viewModel.getValue("dateWorkRestarted")}
                                            canExecute={!viewModel.isSecondFormDisabled && !viewModel.IsLoading}
                                        />
                                    </DateSelectContainer>
                                    <DateSelectContainer>
                                        <DarwinTimeSelect
                                            displayName="Time work restarted:"
                                            execute={(value: string | null) => viewModel.setValue("dateWorkRestarted", value)}
                                            onBlur={() => viewModel.isFieldValid("dateWorkRestarted", viewModel.model.dateWorkRestarted)}
                                            placeholder="00:00"
                                            validationMessage={viewModel.getError("dateWorkRestarted")}
                                            value={viewModel.getValue("dateWorkRestarted")}
                                            canExecute={!viewModel.isSecondFormDisabled && !viewModel.IsLoading}
                                        />
                                    </DateSelectContainer>
                                    <DarwinInputWrapper<AccidentFormModel>
                                        shrink={true}
                                        validateOnBlur={true}
                                        viewModel={viewModel}
                                        editMode={!viewModel.isSecondFormDisabled && !viewModel.IsLoading}
                                        type={"number"}
                                        inputProps={{
                                            placeholder: "hrs",
                                        }}
                                        fieldName="totalTimeLost"
                                        label="Total time lost:"
                                    />
                                </SHBox>
                            )}
                        </FormSection>

                        <RootCauseChecks viewModel={viewModel} isDisabled={viewModel.isSecondFormDisabled} />

                        <FormSection>
                            {renderGenericTextList(
                                "If required give details of any other root causes:",
                                currentRootCause,
                                viewModel.getRootCauses,
                                setCurrentRootCause,
                                viewModel.addRootCause,
                                viewModel.isSecondFormDisabled,
                                viewModel.rootCausesValidationMessage,
                            )}
                        </FormSection>

                        <FormSection>
                            <SHBox mb={3}>
                                {renderTexArea(
                                    "Details of actions taken immediately following the accident to recover the situation:",
                                    "actionsToRecover",
                                    viewModel.isSecondFormDisabled,
                                )}
                            </SHBox>
                            <SHBox mb={3}>
                                {renderTexArea(
                                    "Action taken (or suggested) to prevent reoccurrence and to communicate lessons learnt from the accident:",
                                    "actionsToPrevent",
                                    viewModel.isSecondFormDisabled,
                                )}
                            </SHBox>
                        </FormSection>
                    </>
                )}

                <FormSection>
                    <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
                        {viewModel!.server.HaveValidationMessage && (
                            <>
                                <Cell ts={8} ds={8}>
                                    <Alert severity="error">
                                        <AlertTitle>Error</AlertTitle>
                                        {viewModel!.server.ValidationMessage}
                                    </Alert>
                                </Cell>
                            </>
                        )}
                    </Grid>
                </FormSection>

                {viewModel.canStartInvestigation && (
                    <>
                        <div style={{ margin: `0 ${pxToRem(30)} 0 ${pxToRem(30)}` }}>
                            <SolidDivider gutterBottom={true} borderThickness={2} borderOpacity={0.5} />
                        </div>
                        <FormSection>
                            {/* Buttons */}
                            <ButtonsContainer>
                                <PrimaryButton displayName="Continue to investigation" execute={() => viewModel.upsert(true)} fullWidth={true} canExecute={!viewModel.IsLoading} />
                                <DefaultButton displayName="Return to incidents" execute={() => viewModel.handleCancel()} fullWidth={true} canExecute={!viewModel.IsLoading} />
                            </ButtonsContainer>
                        </FormSection>
                    </>
                )}

                {viewModel.canSaveForm && (
                    <>
                        <div style={{ margin: `0 ${pxToRem(30)} 0 ${pxToRem(30)}` }}>
                            <SolidDivider gutterBottom={true} borderThickness={2} borderOpacity={0.5} />
                        </div>
                        <FormSection>
                            {/* Buttons */}
                            <ButtonsContainer>
                                <PrimaryButton displayName="Submit" execute={() => viewModel.upsert(false)} fullWidth={true} canExecute={!viewModel.IsLoading} />
                                <DefaultButton displayName="Cancel" execute={() => viewModel.handleCancel()} fullWidth={true} canExecute={!viewModel.IsLoading} />
                            </ButtonsContainer>
                        </FormSection>
                    </>
                )}
            </Form>
        </>
    );
});

const HeaderContainer = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 70px;

    // Header
    > div:nth-child(1) {
        margin: 4px 30px 13.5px 30px;
    }
`;

const ProjectDetailsContainer = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    max-width: 700px;

    > div {
        font-size: 12px;
    }

    > div > span {
        font-weight: bold;
    }
`;

const CasualtyUserDetailsContainer = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

    max-width: 700px;

    > div {
        font-size: 12px;
    }

    > div > span {
        font-weight: bold;
    }
`;

const DateSelectContainer = styled.div`
    .MuiInput-input {
        min-width: 70px;
    }
`;
interface SubtitleProps {
    margin?: string;
}

const SubtitleContainer = styled.div<SubtitleProps>`
    h2 {
        margin-bottom: ${(props) => (props.margin ? props.margin : "10px")};
    }
`;
const SignatureContainer = styled.div`
    display: flex;
    flex-direction: column;

    > div {
        display: flex;
        flex-direction: row;

        > div {
            max-width: 262px;
            margin-right: 30px;
            width: 100%;

            canvas {
                border: 1px solid #d4d4d4;
            }
        }
    }
`;
const ShortFieldContainer = styled(SHBox)`
    width: 262px;
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;

    > button {
        max-width: 262px;
    }

    > button:nth-child(1) {
        margin-right: 30px;
    }
`;
