import { ApiResult, FieldType, ViewModelBase, isEmptyOrWhitespace } from "@shoothill/core";
import { action, observable, runInAction, computed } from "mobx";
import { ApprovalDelegateModel } from "./ApprovalDelegateModel";
import { DelegateUserModel } from "./DelegateUserModel";
import { AppUrls } from "AppUrls";
import type { ValidationResponse } from "@shoothill/core";
import moment from "moment";

export class ApprovalDelegateViewModel extends ViewModelBase<ApprovalDelegateModel> {
    public constructor() {
        super(new ApprovalDelegateModel(), false);
        this.setDecorators(ApprovalDelegateModel);
    }

    @computed
    public get startDateFormatted(): string | null {
        return this.model.startDate ? moment(this.model.startDate).format("DD/MM/YYYY").toString() : null;
    }

    @computed
    private get validateStartDate(): ValidationResponse {
        const errorMessage = this.model.validateStartDate;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateDelegatedToUserId(): ValidationResponse {
        const errorMessage = this.model.validateDelegatedToUserId;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    public async isFieldValid(fieldName: keyof FieldType<ApprovalDelegateModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        switch (fieldName) {
            case "startDate": {
                const result = this.validateStartDate;

                errorMessage = result.errorMessage;
                isValid = result.isValid;
                break;
            }

            case "delegatedToUserId": {
                const result = this.validateDelegatedToUserId;

                errorMessage = result.errorMessage;
                isValid = result.isValid;
                break;
            }
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
