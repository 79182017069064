import React, { useEffect } from "react";
import { useObserver } from "@shoothill/core";
import { MultiSelectView } from "Components/Select/MultiSelect";
import { MaterialsListViewModel } from "./MaterialsListViewModel";
import { MaterialsListFilterViewModel } from "./MaterialsListFilterViewModel";
import { FilterRow, FilterWrapper } from "./MaterialsList.styles";

interface IMaterialsListFilterViewProps {
    materialsListViewModel: MaterialsListViewModel;
    filtersViewModel: MaterialsListFilterViewModel;
}

export const MaterialsListFilterView: React.FunctionComponent<IMaterialsListFilterViewProps> = (props: IMaterialsListFilterViewProps) => {
    //useEffect below only gets run on initial render
    useEffect(() => {
        return () => {};
    }, []);

    const renderPage = () => {
        return (
            <>
                <FilterWrapper style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                    <FilterRow>
                        <MultiSelectView
                            style={{ maxWidth: "200px", marginRight: "20px" }}
                            display="Type"
                            fieldName="types"
                            values={props.filtersViewModel.model.types}
                            getOptions={props.filtersViewModel.getTypeOptions}
                            handleChange={props.materialsListViewModel.handleTypeChange}
                        />

                        <MultiSelectView
                            style={{ maxWidth: "200px", marginRight: "20px" }}
                            display="Sub type"
                            fieldName="subType"
                            values={props.filtersViewModel.model.subTypes}
                            getOptions={props.filtersViewModel.getSubTypeOptions}
                            handleChange={props.materialsListViewModel.handleSubTypeChange}
                        />

                        <MultiSelectView
                            style={{ maxWidth: "200px", marginRight: "20px" }}
                            display="UoM"
                            fieldName="uoms"
                            values={props.filtersViewModel.model.uoms}
                            getOptions={props.filtersViewModel.getUOMOptions}
                            handleChange={props.materialsListViewModel.handleUOMChange}
                        />

                        <MultiSelectView
                            style={{ maxWidth: "200px", marginRight: "20px" }}
                            display="Supplier"
                            fieldName="suppliers"
                            values={props.filtersViewModel.model.suppliers}
                            getOptions={props.filtersViewModel.getSupplierOptions}
                            handleChange={props.materialsListViewModel.handleSupplierChange}
                        />
                    </FilterRow>
                    {/* <FilterRow>
                        <DarwinInput
                            type="text"
                            label="Search"
                            validateOnBlur={true}
                            viewModel={props.filtersViewModel}
                            fieldName="searchText"
                            shrink={true}
                            maxLength={128}
                            onChange={(e: any) => props.MaterialsListViewModel.handleSearchChange(e.target.value)}
                        />
                        <div className="filter-button-container">
                            <PrimaryButton
                                displayName={"Clear"}
                                execute={props.MaterialsListViewModel.clear}
                                fullWidth={false}
                                canExecute={!props.MaterialsListViewModel.IsLoading}
                            />
                        </div>
                    </FilterRow> */}
                </FilterWrapper>
            </>
        );
    };

    return useObserver(() => renderPage());
};
