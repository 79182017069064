import { action, observable } from "mobx";
import { ModelBase } from "@shoothill/core";

export class SupplierFilterParamsModel extends ModelBase<SupplierFilterParamsModel, SupplierFilterParamsModelDTO> {
    @observable
    public includeDeleted: boolean = false;

    @observable
    public statuses: string[] | null = []; //[100, 200, 300]

    fromDto(model: SupplierFilterParamsModelDTO): void {}

    toDto(): SupplierFilterParamsModelDTO {
        let dto: SupplierFilterParamsModelDTO = {
            includeDeleted: this.includeDeleted,
            statuses: this.statuses,
        };
        return dto;
    }

    @action
    public reset = () => {
        this.statuses = [];
    };
}

export interface SupplierFilterParamsModelDTO {
    includeDeleted: boolean;
    statuses: string[] | null;
}
