import { observable, runInAction, action } from "mobx";
import { FieldType, ViewModelBase } from "@shoothill/core";
import { AppUrls } from "../../../../AppUrls";
import { ServerViewModel } from "../../../../Globals/ViewModels/ServerViewModel";
import { PermitListAndRelatedDTO, PermitListModel } from "./PermitListModel";
import { PermitTypesLookupModel } from "./PermitTypesLookupModel";

export class PermitListViewModel extends ViewModelBase<any> {
    public server: ServerViewModel = new ServerViewModel();
    public projectId: string = "";
    @observable public permitFormType: PermitTypesLookupModel | null = null;
    @observable public permitTypes: any = [];
    @observable public permitListModels: PermitListModel[] = [];
    constructor() {
        super();
    }

    @action
    public setPermitForm = (type: PermitTypesLookupModel) => {
        this.permitFormType = type;
    };

    @action
    public loadPermitsAndRelated = async (): Promise<void> => {
        this.setIsLoading(true);
        const request: any = {
            id: this.projectId,
            dateFilter: null,
        };
        let apiResult = await this.Post<PermitListAndRelatedDTO>(AppUrls.Server.Projects.Construction.Permit.LoadPermitsAndRelated, request);
        if (apiResult) {
            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.permitTypes.replace(PermitTypesLookupModel.fromDtos(apiResult.payload.permitTypes));
                    this.permitListModels = [];
                    for (const permit of apiResult.payload.permitListItems) {
                        let model = new PermitListModel();
                        model.fromDto(permit);
                        this.permitListModels.push(model);
                    }
                });
            } else {
                console.log(apiResult.errors);
            }
        }
        this.setIsLoading(false);
    };

    @action
    public handleRowClick = (e: any, rowData: PermitListModel) => {
        this.history.push(AppUrls.Client.Project.Permit.View.replace(":projectid", this.projectId).replace(":permitId", rowData.id));
    };

    @action
    public addPermitForm = () => {
        this.history.push(AppUrls.Client.Project.Permit.Add.replace(":projectid", this.projectId).replace(":permitTypeId", this.permitFormType?.id!));
    };

    public async isFieldValid(fieldName: keyof FieldType<PermitListModel>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }
    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
