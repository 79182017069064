import { observable, runInAction, action, computed } from "mobx";
import { FieldType, ViewModelBase } from "@shoothill/core";
import { InspectionsModel } from "./InspectionsModel";
import { PurchaseOrderRelatedResponseDTO } from "../../../PurchaseOrder/Form/PurchaseOrderModel";
import { AppUrls } from "../../../../AppUrls";
import { PaymentTermsModel } from "../../../PurchaseOrder/Form/Supporting/PaymentTermsModel";
import { PaymentTypeModel } from "../../../PurchaseOrder/Form/Supporting/PaymentTypeModel";
import { ServerViewModel } from "../../../../Globals/ViewModels/ServerViewModel";
import { InspectionTypesLookupModel } from "./InspectionTypesLookupModel";
import { InspectionsAndRelated, InspectionsAndRelatedDTO } from "./InspectionsAndRelated";
import { InspectionsQuestionsDTO, InspectionsQuestionModel } from "./InspectionsQuestionModel";
import { InspectionsQuestionViewModel } from "./InspectionsQuestionViewModel";
import { InductionUserLookupModel } from "./InductionUserLookupModel";
import { isNullOrUndefined } from "../../../../Utils/Utils";

export class InspectionsViewModel extends ViewModelBase<InspectionsModel> {
    public server: ServerViewModel = new ServerViewModel();
    @observable
    public questionsViewModel: InspectionsQuestionViewModel[] = [];
    // @observable
    // public inductionUsers: any = [];
    constructor(model: InspectionsModel) {
        super(model);
        if (!model) {
            model = new InspectionsModel();
        }
        this.setDecorators(InspectionsModel);
        if (model.id) {
            this.loadInspection();
        } else if (model.inspectionTypeId) {
            this.loadQuestions();
        }
    }

    // @action
    // public setInspectionUser = (type: InductionUserLookupModel) => {
    //     this.model.inspectionUserId = type.id;
    //     this.model.inspectionUser = type;
    // };

    @action
    public setInspectionUser = (value: any | null): void => {
        this.setValue("inspectionLocalUserId", value?.id ?? null);
    };

    @computed
    public get inspectionUser(): any | null {
        return this.model.inspectionUsers.find((u) => u.id === this.model.inspectionLocalUserId) ?? null;
    }

    @computed
    public get inspectionUsers() {
        return this.model.inspectionUsers;
    }

    public loadQuestions = (): Promise<void> => {
        return this.server.query<any>(
            () => this.Get(`${AppUrls.Server.Projects.Construction.Inspections.LoadQuestions}?inspectionFormTypeId=${this.model.inspectionTypeId}`),
            (result) => {
                runInAction(() => {
                    for (const question of result.questions) {
                        let model = new InspectionsQuestionModel();
                        model.fromDto(question);
                        this.model.questionsModel.push(model);
                        this.questionsViewModel.push(new InspectionsQuestionViewModel(model));
                    }

                    //this.inductionUsers.replace(InductionUserLookupModel.fromDtos(result.inductionUsers));
                    this.model.inspectionInductionUsers.replace(result.inspectionInductionUsers);
                    this.model.inspectionWorkerUsers.replace(result.inspectionWorkerUsers);
                });
            },
        );
    };

    public loadInspection = (): Promise<void> => {
        return this.server.query<any>(
            () => this.Get(`${AppUrls.Server.Projects.Construction.Inspections.LoadInspection}?inspectionId=${this.model.id}`),
            (result) => {
                runInAction(() => {
                    //this.inspectionFormTypes.replace(InspectionTypesModel.fromDtos(result.inspectionTypes));
                    for (const question of result.inspection.questions) {
                        let model = new InspectionsQuestionModel();
                        model.fromDto(question);
                        this.model.questionsModel.push(model);
                        this.questionsViewModel.push(new InspectionsQuestionViewModel(model));
                    }

                    //this.inductionUsers.replace(InductionUserLookupModel.fromDtos(result.inductionUsers));
                    this.model.inspectionInductionUsers.replace(result.inspectionInductionUsers);
                    this.model.inspectionWorkerUsers.replace(result.inspectionWorkerUsers);

                    this.model.fromDto(result.inspection);
                    //this.model.inspectionUser = this.inductionUsers.find((x: any) => x.id === result.inspection.inspectionUserId);
                });
            },
        );
    };

    public updateSignature = (data: any) => {
        this.setValue("signatureBase64", data);
    };

    public async checkModelIsValid(): Promise<boolean> {
        let isValid = true;

        isValid = await this.isModelValid();

        for (const question of this.questionsViewModel) {
            let valid = await question.isModelValid();
            if (!valid) {
                isValid = false;
            }
        }

        return isValid;
    }

    public canSubmit = () => {
        return isNullOrUndefined(this.model.id);
    };

    public inputStyles = () => {
        if (this.canSubmit()) {
            return {};
        }

        return { pointerEvents: "none" };
    };

    public async isFieldValid(fieldName: keyof FieldType<InspectionsModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }
    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
