import { useObserver } from "mobx-react-lite";
import React, { useState } from "react";

import { BaseSidebar } from "Components/Sidebar/BaseSidebar";
import { ApprovalPanelInvoiceContainer } from "./ApprovalPanelInvoice.styles";
import { ApprovalHistoryInvoiceDTO } from "Components/ApprovalHistoryInvoice/ApprovalHistoryInvoiceDTO";
import { ApprovalHistoryInvoiceView } from "Components/ApprovalHistoryInvoice/ApprovalHistoryInvoiceView";
import { ApprovalHistoryInvoiceItemViewModel } from "Components/ApprovalHistoryInvoice/ApprovalHistoryInvoiceItemViewModel";

interface IProps {
    sideOpen: boolean;
    primaryTitle: string;
    secondaryTitle: string | null;
    canViewFooter: boolean;
    approvalHistoryInvoiceItemViewModels: ApprovalHistoryInvoiceItemViewModel[];
}

export const ApprovalPanelInvoice: React.FC<IProps> = (props: IProps) => {
    /**
     * Determines which body component needs to be shown.
     * @returns The component to be shown.
     */
    const getBody = () => {
        return approvalHistorySection();
    };

    const approvalHistorySection = () => {
        return (
            <>
                {props.approvalHistoryInvoiceItemViewModels.length > 0 && (
                    <ApprovalHistoryInvoiceView approvalHistoryInvoiceItemViewModels={props.approvalHistoryInvoiceItemViewModels} />
                )}
            </>
        );
    };

    const footer = () => {
        return <></>;
    };

    return useObserver(() => {
        return (
            <ApprovalPanelInvoiceContainer>
                <BaseSidebar
                    open={props.sideOpen}
                    primaryTitle={props.primaryTitle}
                    secondaryTitle={props.secondaryTitle}
                    onClose={() => {}}
                    bodyContent={getBody()}
                    footerContent={props.canViewFooter ? footer() : undefined}
                />
            </ApprovalPanelInvoiceContainer>
        );
    });
};
