import styled from "styled-components";

export const ContractualClient = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px 5px 15px 5px;

    /* background-color: orange; */
`;

export const ContractualContract = styled.div`
    position: relative;
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    width: 100%;
    padding: 0px 5px 15px 5px;
`;

export const ContractualEA = styled.div`
    position: relative;
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    width: 100%;
    padding: 0px 5px 15px 5px;

    /* background-color: green; */
`;

export const ContractRow = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 5px 30px;

    .MuiFormControl-marginNormal {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }

    .control-label {
        margin-bottom: 2px !important;
    }

    /*    background-color: yellow; */
`;

export const ContractCell = styled.div`
    position: relative;
    display: flex;
    flex: 1;

    .MuiFormControl-marginNormal {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        width: 175px !important;
    }

    .control-label {
        margin-bottom: 2px !important;
    }

    /* background-color: darkgoldenrod; */
`;
