import { ModelBase, observable } from "@shoothill/core";

export class InspectionsListModel extends ModelBase<InspectionsListModel> {
    public id: string = "";
    @observable
    public inspectionTypeDisplayName: string = "";
    @observable
    public firstName: string = "";
    @observable
    public lastName: string = "";
    @observable
    public serialRegistrationNo: string = "";
    @observable
    public createdDate: Date = new Date();
    constructor() {
        super();
    }

    fromDto(model: InspectionsListDTO): void {
        this.id = model.id;
        this.inspectionTypeDisplayName = model.inspectionTypeDisplayName;
        this.serialRegistrationNo = model.serialRegistrationNo;
        this.firstName = model.firstName;
        this.lastName = model.lastName;
        this.createdDate = model.createdDate;
    }
    toDto(model: InspectionsListModel): void {
        throw new Error("Method not implemented.");
    }
}

export interface InspectionsListDTO {
    id: string;
    inspectionTypeDisplayName: string;
    firstName: string;
    lastName: string;
    serialRegistrationNo: string;
    createdDate: Date;
}
