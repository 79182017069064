import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { PrimaryButton } from "../../../Components/Buttons/Buttons";
import { SHBox } from "../../../Components/Box";

interface Props {
    onAdd: (name: string) => void;
}
export const AddOtherView: React.FC<Props> = observer((props: Props) => {
    const [name, setName] = useState("");
    const [showName, setShowName] = useState(false);

    const onAdd = () => {
        setShowName(false);
        props.onAdd(name);
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    };

    return (
        <SHBox>
            <SHBox showIf={showName} flexBox width={"250px"}>
                <input onChange={onChange} />
                <PrimaryButton displayName={"Add"} execute={onAdd} fullWidth={true} canExecute={true} />
            </SHBox>
            <SHBox showIf={!showName} width={"150px"}>
                <PrimaryButton displayName={"Add other"} execute={() => setShowName(true)} fullWidth={true} canExecute={true} />
            </SHBox>
        </SHBox>
    );
});
