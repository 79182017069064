import { observable, runInAction, action, computed } from "mobx";
import { FieldType, ViewModelBase } from "@shoothill/core";
import { InspectionsModel } from "./InspectionsModel";
import { AppUrls } from "../../../../AppUrls";
import { ServerViewModel } from "../../../../Globals/ViewModels/ServerViewModel";
import { InspectionTypesLookupModel } from "./InspectionTypesLookupModel";
import { InspectionsAndRelatedDTO } from "./InspectionsAndRelated";
import { InspectionsViewModel } from "./InspectionsViewModel";
import { InspectionsListModel } from "./InspectionsListModel";
import { StoresInstance } from "../../../../Globals/Stores";

export class InspectionsListViewModel extends ViewModelBase<InspectionsModel> {
    public server: ServerViewModel = new ServerViewModel();
    public projectId: string = "";
    @observable public inspectionFormType: InspectionTypesLookupModel | null = null;
    @observable public inspectionFormTypes: any = [];
    @observable public showAddForm: boolean = false;
    @observable public inspectionViewModel: InspectionsViewModel = new InspectionsViewModel(new InspectionsModel());
    @observable public inspectionsListModels: InspectionsListModel[] = [];
    constructor(model = new InspectionsModel()) {
        super(model);
        this.setDecorators(InspectionsModel);
    }

    @action
    public setInspectionForm = (type: InspectionTypesLookupModel | null) => {
        this.inspectionFormType = type;
    };

    @computed
    public get getInspectionTypeName(): string {
        if (this.inspectionViewModel && this.inspectionViewModel.model.inspectionTypeName) {
            return this.inspectionViewModel.model.inspectionTypeName;
        } else if (this.inspectionFormType) {
            return this.inspectionFormType.displayName;
        }

        return "";
    }

    public loadRelated = (): Promise<void> => {
        return this.server.query<InspectionsAndRelatedDTO>(
            () => this.Get(`${AppUrls.Server.Projects.Construction.Inspections.LoadInspectionsAndRelated}?projectId=${this.projectId}`),
            (result) => {
                runInAction(() => {
                    this.inspectionFormTypes.replace(InspectionTypesLookupModel.fromDtos(result.inspectionTypes));
                    this.inspectionsListModels = [];
                    for (const inspection of result.inspections) {
                        let model = new InspectionsListModel();
                        model.fromDto(inspection);
                        this.inspectionsListModels.push(model);
                    }
                });
            },
        );
    };

    @action
    public handleRowClick = (e: any, rowData: InspectionsListModel) => {
        this.showAddForm = true;

        let model = new InspectionsModel();
        model.id = rowData.id;
        model.projectId = this.projectId;

        this.inspectionViewModel = new InspectionsViewModel(model);
    };

    @action
    public addInspectionForm = () => {
        this.showAddForm = true;
        let model = new InspectionsModel();
        model.projectId = this.projectId;
        model.inspectionTypeId = this.inspectionFormType!.id;
        this.inspectionViewModel = new InspectionsViewModel(model);
    };

    @action
    public showInspectionFormForSiteTablet = (inspectionTypeId: string) => {
        this.showAddForm = true;
        let model = new InspectionsModel();
        model.projectId = this.projectId;
        model.inspectionTypeId = inspectionTypeId;
        this.inspectionViewModel = new InspectionsViewModel(model);
    };

    @action
    public cancelInspectionForm = () => {
        if (!StoresInstance.Domain.AccountStore.isSiteTablet) {
            this.showAddForm = false;
            this.inspectionViewModel = new InspectionsViewModel(new InspectionsModel());
            this.setInspectionForm(null);
            this.loadRelated();
        } else {
            this.history.push(AppUrls.Client.Project.SiteTablet.Menu);
        }
    };

    @action
    public submitInspectionForm = async () => {
        const isValid = await this.inspectionViewModel!.checkModelIsValid();
        return this.server.command<InspectionsAndRelatedDTO>(
            () => this.Post(`${AppUrls.Server.Projects.Construction.Inspections.UpsertInspection}`, this.inspectionViewModel!.model.toDto()),
            (result) => {
                runInAction(() => {
                    this.cancelInspectionForm();
                });
            },
            () => Promise.resolve(isValid),
            "There was an error trying to add the amendment",
        );
    };

    public async isFieldValid(fieldName: keyof FieldType<InspectionsModel>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }
    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
