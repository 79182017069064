import { KeyValuePair } from "@shoothill/core";
export enum ContactType {
    Unknown = 0,
    PurchaseOrder = "3d81d77e-6d3f-48cf-bb71-eeb3720a6b4d",
    Invoice = "14bf02a0-2895-4ba3-bdc0-f6dcca0d8373",
}

export class ContactTypeHelpers {
    public static getText = (contactTypeId: string | null) => {
        switch (contactTypeId) {
            case ContactType.PurchaseOrder: {
                return "Purchase order";
            }
            case ContactType.Invoice: {
                return "Invoice";
            }
            default: {
                return "";
            }
        }
    };
}
