import styled from "styled-components";
import { BaseModalDialogPaper } from "Components/Modal/BaseModal.styles";
import { theme } from "../../Styles/AppTheme";

export const ChangelogModalDialogPaper = styled(BaseModalDialogPaper)`
    width: 75%;
    min-width: 300px;
    max-width: 640px;

    .MuiDialogContent-root {
        p {
            padding-bottom: 40px;
            font-size: 14px;
        }
    }

    display: flex;
    flex-direction: column;
    max-height: 90vh;

    .modal-changelog-body {
        flex: 1 1 auto;
        padding: 0 10px;
        overflow-y: auto;

        :not(:last-child) {
            margin-bottom: 10px;
        }

        .modal-changelog-body-version {
            padding-bottom: 20px;

            h4 > small {
                color: ${theme.palette.red.main};
                //background-color: yellow;
            }

            ul {
                list-style: none;
                padding: 0;
                margin: 0;

                li {
                    padding-left: 1em;
                    text-indent: -0.7em;
                }

                li::before {
                    content: "● ";
                    color: ${theme.palette.red.main};
                }
            }
        }
    }
`;
