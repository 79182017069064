import { debounce } from "@material-ui/core";
import { ViewModelBase, isEmptyOrWhitespace } from "@shoothill/core";
import { action, computed, observable, runInAction } from "mobx";

import { AppUrls } from "AppUrls";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { MaterialDetailsViewModel } from "../MaterialDetails/MaterialDetailsViewModel";
import { MaterialPriceDetailsViewModel } from "../MaterialPriceDetails/MaterialPriceDetailsViewModel";
import { MaterialPriceDetailsHistoryViewModel } from "../MaterialPriceDetailsHistory/MaterialPriceDetailsHistoryViewModel";
import { MaterialsListItemViewModel } from "./MaterialListItemViewModel";
import { StoresContext, StoresInstance } from "Globals/Stores";
import { MaterialsListFilterViewModel } from "./MaterialsListFilterViewModel";

export class MaterialsListViewModel extends ViewModelBase<any> {
    public server: ServerViewModel = new ServerViewModel();

    private readonly DEBOUNCE_VALUE_MS = 200;

    private materials = observable<MaterialsListItemViewModel>([]);

    constructor() {
        super({});

        // Load materials.
        this.apiLoadMaterialsAsync();
    }

    // #region Filtering

    @observable
    public searchString: string = "";

    @observable
    public filterSearchString: string = "";

    public getSearchString = () => {
        return computed(() => this.searchString);
    };

    @action
    public setSearchString = (value: string) => {
        this.searchString = value;
        this.setFilterSearchString(value);
    };

    private setFilterSearchString = debounce(
        action((value: string) => {
            this.filterSearchString = value;
        }),
        this.DEBOUNCE_VALUE_MS,
    );

    @computed
    public get filteredMaterials(): MaterialsListItemViewModel[] {
        if (isEmptyOrWhitespace(this.filterSearchString)) {
            return this.materials.slice();
        }

        return this.materials.filter((vm) => vm.matchesFilter(this.filterSearchString)).slice();
    }

    public handleTypeChange = (val: number[] | null) => {
        MaterialsListFilterViewModel.Instance.setValue("types", val);
        this.apiLoadMaterialsAsync();
    };

    public handleSubTypeChange = (val: number[] | null) => {
        MaterialsListFilterViewModel.Instance.setValue("subTypes", val);
        this.apiLoadMaterialsAsync();
    };

    public handleUOMChange = (val: number[] | null) => {
        MaterialsListFilterViewModel.Instance.setValue("uoms", val);
        this.apiLoadMaterialsAsync();
    };

    public handleSupplierChange = (val: number[] | null) => {
        MaterialsListFilterViewModel.Instance.setValue("suppliers", val);
        this.apiLoadMaterialsAsync();
    };

    // #endregion Filtering

    // #region New Material

    @action
    public navigateToNewMaterial = () => {
        this.history.push(AppUrls.Client.Stock.MaterialAdd);
    };

    // #endregion New Material

    // #region Edit Material Details

    @observable
    public materialDetailsViewModel: MaterialDetailsViewModel | null = null;

    @computed
    public get canDisplayDetails() {
        return this.materialDetailsViewModel !== null;
    }

    @computed
    public get canEditMaterialDetails() {
        return StoresInstance.Domain.AccountStore.isLevel5OrHigher;
    }

    @action
    public displayMaterialDetails = (rowData: any) => {
        if (this.canEditMaterialDetails) {
            this.materialDetailsViewModel = new MaterialDetailsViewModel(rowData.id, this.closeMaterialDetails);
        }
    };

    @action
    public closeMaterialDetails = (refreshPage: boolean) => {
        this.materialDetailsViewModel = null;

        if (refreshPage) {
            this.apiLoadMaterialsAsync();
        }
    };

    // #endregion Edit Material Details

    // #region Edit Material Price Details

    @observable
    public materialPriceDetailsViewModel: MaterialPriceDetailsViewModel | null = null;

    @computed
    public get canDisplayPriceDetails() {
        return this.materialPriceDetailsViewModel !== null;
    }

    @action
    public displayMaterialPriceDetails = (rowData: any) => {
        if (rowData.canEditPrice) {
            this.materialPriceDetailsViewModel = new MaterialPriceDetailsViewModel(rowData.id, this.closeMaterialPriceDetails);
        }
    };

    @action
    public closeMaterialPriceDetails = (refreshPage: boolean) => {
        this.materialPriceDetailsViewModel = null;

        if (refreshPage) {
            this.apiLoadMaterialsAsync();
        }
    };

    // #endregion Edit Material Price Details

    // #region Display Price Details History

    @observable
    public materialPriceDetailsHistoryViewModel: MaterialPriceDetailsHistoryViewModel | null = null;

    @computed
    public get canDisplayPriceDetailsHistory() {
        return this.materialPriceDetailsHistoryViewModel !== null;
    }

    @action
    public displayPriceDetailsHistory = (rowData: any) => {
        this.materialPriceDetailsHistoryViewModel = new MaterialPriceDetailsHistoryViewModel(rowData.id, this.closeMaterialPriceDetailsHistory);
    };

    @action
    public closeMaterialPriceDetailsHistory = () => {
        this.materialPriceDetailsHistoryViewModel = null;
    };

    // #endregion Display Price Details History

    // #region Api Actions

    // Used to ensure that only the results of the last request are used to populate the table.
    private lastRequestId = 0;

    @observable
    public hasLoaded: boolean = false;

    @action
    public setHasLoaded = (val: boolean) => {
        this.hasLoaded = val;
    };

    @action
    public apiLoadMaterialsAsync = async (): Promise<void> => {
        const currentRequestId = ++this.lastRequestId;
        let paramsViewModel = MaterialsListFilterViewModel.Instance;
        let params = paramsViewModel.model.toDto();
        params.initialLoad = !this.hasLoaded;

        const apiResult = await this.Post<any>(AppUrls.Server.Stock.Material.GetMaterialViews, params);

        if (currentRequestId === this.lastRequestId && apiResult.wasSuccessful) {
            runInAction(() => {
                this.materials.replace(
                    apiResult.payload.material.map((dto: any) => {
                        const material = new MaterialsListItemViewModel();

                        material.id = dto.id;
                        material.description = dto.description;
                        material.materialType = dto.materialType;
                        material.materialSubType = dto.materialSubType;
                        material.supplierCode = dto.supplierCode;
                        material.unitOfMeasure = dto.unitOfMeasure;
                        material.estimatedPrice = dto.estimatedPrice;
                        material.price = dto.price;
                        material.effectiveFromDate = dto.effectiveFromDate;
                        material.supplier = dto.supplier;
                        material.canEditPrice = dto.canEditPrice;

                        return material;
                    }),
                );
            });

            if (!this.hasLoaded) {
                MaterialsListFilterViewModel.Instance.setTypes(apiResult.payload.materialTypes, true);
                MaterialsListFilterViewModel.Instance.setSubTypes(apiResult.payload.materialSubTypes, true);
                MaterialsListFilterViewModel.Instance.setUOMs(apiResult.payload.unitsOfMeasure, true);
                MaterialsListFilterViewModel.Instance.setSuppliers(apiResult.payload.suppliers, true);
            }
        } else if (!apiResult.wasSuccessful) {
            if (this.server.HaveValidationMessage) {
                this.setSnackMessage(this.server.ValidationMessage);
                this.setSnackType(this.SNACKERROR);
                this.setSnackbarState(true);
            }
        }

        this.setHasLoaded(true);

        //return apiResult;
    };

    @action
    public apiDownloadMaterialsAsync = async (): Promise<void> => {
        await this.server.queryAuthenticatedFile(
            AppUrls.Server.Stock.Material.DownloadMaterialViews,
            this.getConfig,
            (data, filename) => {
                // Create a link element for the file and use the filename provided.
                const link = document.createElement("a");

                link.href = window.URL.createObjectURL(new Blob([data]));
                link.setAttribute("download", filename);

                document.body.appendChild(link);

                // Download.
                link.click();

                // Having clicked the link, delete the element otherwise it will
                // remain attached to the document.
                document.body.removeChild(link);
            },
            "Error whilst downloading the file",
        );

        if (this.server.HaveValidationMessage) {
            this.setSnackMessage(this.server.ValidationMessage);
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }
    };

    // #endregion Api Actions

    // #region Snack Bar

    public SNACKERROR = "error";

    @observable
    public snackbarState = false;

    @observable
    public snackType = "";

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public snackMessage = "";

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    // #endregion Snack Bar

    // #region Boilerplate

    isFieldValid(fieldName: string | number | symbol, value: any): Promise<boolean> {
        throw new Error("Method not implemented.");
    }
    beforeUpdate?(fieldName: string | number | symbol, value: any) {
        throw new Error("Method not implemented.");
    }
    afterUpdate?(fieldName: string | number | symbol, value: any): void {
        throw new Error("Method not implemented.");
    }

    // #endregion Boliderplate
}
