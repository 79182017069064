import { FieldType, ViewModelBase } from "@shoothill/core";
import { observable, action } from "mobx";

import { AppUrls } from "AppUrls";
import { Step6Model } from "./Step6Model";

export class Step6ViewModel extends ViewModelBase<Step6Model> {
    @observable public submitted: boolean = false;
    @observable public allowNextStep: boolean = false;

    constructor() {
        super(new Step6Model());
    }

    // #region Step1
    @action
    public nextStep = async () => {
        this.setIsLoading(true);
        this.submitted = true;
        setTimeout(() => {
            this.setIsLoading(false);
        }, 100);
    };
    public previousStep = () => {
        this.setIsLoading(true);
        this.history.push(AppUrls.Client.Induction.Step5);
        setTimeout(() => {
            this.setIsLoading(false);
        }, 100);
    };
    public get canExecuteNextStep() {
        return true;
    }
    public radioChange = (fieldName: keyof FieldType<Step6Model>, value: string) => {
        this.setValue(fieldName, value);
    };
    // #endregion Step1

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<Step6Model>, value: any): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    afterUpdate(fieldName: keyof FieldType<Step6Model>, value: any): void {
        //Do something like call an api or whatever/
        //Can use this instead of mobx reaction
        return;
    }
    //public beforeUpdate: undefined;
    beforeUpdate(fieldName: keyof FieldType<Step6Model>, value: any): any {
        //Do something and return the new value if you want or just use it to perform another action
        if (fieldName === "last3PhoneDigits") {
            if (value.toString().length > 3) {
                return parseInt(value.toString().substring(0, 3));
            }
        }
        return value;
    }

    // #endregion Boilerplate
}
