// @ts-nocheck
import { TextField } from "@material-ui/core";
import { EditableInput } from "@shoothill/core";
import styled from "styled-components";

import { pxToRem } from "../AppTheme";
import React from "react";

export const DarwinInput = styled(EditableInput)`
    //CMR This breaks all the inputs, please don't do this. padding-left: 0px !important;
    div {
        padding-left: 0;
    }

    input {
        width: 100%;
    }
` as React.ComponentType as new <T>() => EditableInput<T>;

/*export const DarwinInput: any = styled(EditableInput)`
    //CMR This breaks all the inputs, please don't do this. padding-left: 0px !important;
    div {
        padding-left: 0px;
    }

    input {
        width: 100%;
    }
`;*/

// Should be styled the same as the above DarwinInput.
export const DarwinTextField: any = styled(TextField)`
    //CMR This breaks all the inputs, please don't do this. padding-left: 0px !important;

    div {
        padding-left: 0px;
    }

    input {
        width: 100%;
    }
`;

// TODO: Update to use the DarwinInputWrapper so that the input can be styled when disabled. Will also need to be properly typed using the generic props.
export const DarwinMoneyInput: any = styled(DarwinInput)`
    & > .MuiInput-root {
        & > .MuiInputAdornment-root.MuiInputAdornment-positionStart {
            margin-right: ${pxToRem(-7.5)};
            padding-left: ${pxToRem(10)};

            & > p {
                font-size: ${pxToRem(12)};
                font-weight: bold;
            }
        }
    }
`;
