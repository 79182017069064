import { TextField } from "@material-ui/core";
import { useObserver } from "mobx-react-lite";
import React, { useState } from "react";
import { InvoiceMatchViewModel } from "../InvoiceMatchViewModel";
import { InvoiceMatchItemRow } from "../InvoiceMatch.Styles";
import { InvoicePurchaseOrderItemViewModel } from "./InvoicePurchaseOrderItemViewModel";
import { InvoiceMatchItemAlreadyAllocatedModal } from "../InvoiceMatchItemAlreadyAllocatedModal/InvoiceMatchItemAlreadyAllocatedModal";

interface IProps {
    className?: string;
    viewModel: InvoiceMatchViewModel;
    item: InvoicePurchaseOrderItemViewModel;
}

export const InvoiceMatchItem: React.FC<IProps> = (props: IProps) => {
    const { viewModel, item } = props;
    const [showAlreadyAllocatedModal, setShowAlreadyAllocatedModal] = React.useState<boolean>(false);

    return useObserver(() => {
        return (
            <InvoiceMatchItemRow>
                <div>{item.model.itemDescription}</div>
                <div></div>
                <div>{item.getInitialValueFormatted}</div>
                <div onClick={() => setShowAlreadyAllocatedModal(true)} className="pdf-link">
                    {item.getAlreadyAllocatedFormatted}
                </div>
                <div>{item.getAvailableBalanceFormatted}</div>
                <div>
                    <TextField
                        id=""
                        type="number"
                        value={item.model.amountAllocated}
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => item.setAmountAllocated(event.target.value)}
                        fullWidth
                        error={item.getError("amountAllocated") !== ""}
                        placeholder={"0.00"}
                        disabled={item.isFormDisabled || viewModel.isFormDisabled}
                        helperText={item.getError("amountAllocated")}
                    />
                </div>
                <div></div>
                <InvoiceMatchItemAlreadyAllocatedModal
                    onClose={() => setShowAlreadyAllocatedModal(false)}
                    open={showAlreadyAllocatedModal}
                    title="Already allocated invoices"
                    text="The following invoices are already allocated against this line:"
                    isLoading={viewModel.IsLoading}
                    allocatedInvoices={item.invoicePurchaseOrderItemsAllocatedFiltered}
                />
            </InvoiceMatchItemRow>
        );
    });
};
