import { EnumType, IEnumType } from "./Base/EnumType";

export class StockTransactionType extends EnumType {
    // #region Constants

    public static readonly Purchase = "PURCHASE";
    public static readonly PurchaseRevision = "PURCHASEREVISION";
    public static readonly PurchaseVoid = "PURCHASEVOID";
    public static readonly Adjustment = "ADJUSTMENT";
    public static readonly TransferToProject = "TRANSFERTOPROJECT";
    public static readonly TransferToStock = "TRANSFERTOSTOCK";

    // #endregion Constants

    // #region Properties
    // #endregion Properties

    // #region Methods

    public fromDto(dto: IStockTransactionType) {
        super.fromDto(dto);
    }

    // #endregion Methods
}

export interface IStockTransactionType extends IEnumType {}
