import { ModelBase } from "@shoothill/core";
import { computed, observable } from "mobx";
import { DailyDiaryQuestionModel, DailyDiaryQuestionModelDTO } from "./DailyDiaryQuestionModel";
import moment from "moment";

export class DailyDiaryModel extends ModelBase<DailyDiaryModel, DailyDiaryModelDTO> {
    public id: string | null = "";
    @observable
    public title: string = "";
    @observable
    public projectId: string = "";
    @observable
    public completedDate: Date | null = null;
    @observable
    public completedBy: string = "";
    @observable
    public complete: boolean = false;
    @observable
    public dailyDiaryQuestionModels = observable<DailyDiaryQuestionModel>([]);

    constructor() {
        super();
    }

    fromDto(dto: DailyDiaryModelDTO): void {
        this.id = dto.id;
        this.title = dto.title;
        this.projectId = dto.projectId;
        this.completedDate = dto.completedDate;
        this.completedBy = dto.completedBy;
        this.complete = dto.complete;
        this.dailyDiaryQuestionModels.clear();
        for (const question of dto.dailyDiaryQuestions) {
            const model = new DailyDiaryQuestionModel();
            model.fromDto(question);
            this.dailyDiaryQuestionModels.push(model);
        }
    }

    toDto(model: DailyDiaryModel): void {
        model.id = this.id;
        model.title = this.title;
        model.projectId = this.projectId;
        model.completedDate = this.completedDate;
        model.completedBy = this.completedBy;
        model.complete = this.complete;
    }
}

export class DailyDiaryModelDTO {
    public id: string | null = "";
    public title: string = "";
    public projectId: string = "";
    public completedDate: Date | null = null;
    public completedBy: string = "";
    public complete: boolean = false;
    public dailyDiaryQuestions: DailyDiaryQuestionModelDTO[] = [];
}
