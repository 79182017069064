import axios, * as Axios from "axios";
import { ApiResult, FieldType, ViewModelBase } from "@shoothill/core";
import { RFIModel, RFIModelDTO } from "./RFIModel";
import { observable, action, computed, runInAction } from "mobx";
import type { IObservableArray } from "mobx";
import { AppUrls } from "AppUrls";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { RFIItemViewModel } from "./RFIItemViewModel";
import { RFIItemResponseViewModel } from "./RFIItemResponseViewModel";
import { RFIFilterViewModel } from "./RFIFilterViewModel";
import debounce from "lodash/debounce";
import { RFIItemModelDTO } from "./RFIItemModel";

export class RFIViewModel extends ViewModelBase<RFIModel> {
    private static _instance: RFIViewModel;

    public constructor() {
        super(new RFIModel(), false);
        this.setDecorators(RFIModel);
    }

    @observable
    public searchString: string = "";

    @observable
    public hasLoaded: boolean = false;

    @observable public errorMessage: string = "";

    @observable
    public projectId: string | null = null;

    @observable
    public rfiId: string | null = null;

    @observable
    public selectedSupplierReference: string | null = null;

    @observable
    public rfiResponseId: string | null = null;

    @observable
    public rFIItemViewModels: IObservableArray<RFIItemViewModel> = observable([]);

    @observable
    public rFIItemResponseViewModels: IObservableArray<RFIItemResponseViewModel> = observable([]);

    @computed
    public get getDraftCount(): number {
        const listOfDrafts = this.rFIItemViewModels.filter((s) => s.statusId === this.fullStatuses.find((s) => s.type === 1)?.id);
        if (listOfDrafts) {
            return listOfDrafts.length;
        }
        return 0;
    }

    @computed
    public get getClosedCount(): number {
        const listOfClosed = this.rFIItemViewModels.filter((s) => s.statusId === this.fullStatuses.find((s) => s.type === 2)?.id);
        if (listOfClosed) {
            return listOfClosed.length;
        }
        return 0;
    }

    @computed
    public get getAwaitingIssueCount(): number {
        const listOfAwaitingIssue = this.rFIItemViewModels.filter((s) => s.statusId === this.fullStatuses.find((s) => s.type === 3)?.id);
        if (listOfAwaitingIssue) {
            return listOfAwaitingIssue.length;
        }
        return 0;
    }

    @computed
    public get getRFIItems(): RFIItemViewModel[] {
        return this.rFIItemViewModels;
    }

    @computed
    public get getRFIItemResponsesLength(): number {
        return this.rFIItemResponseViewModels.length;
    }

    @computed
    public get getRFIItemResponses(): RFIItemResponseViewModel[] {
        return this.rFIItemResponseViewModels;
    }

    @observable
    public categories = observable<RFICategoryDTO>([]);

    @observable
    public statuses = observable<RFIStatusDTO>([]);

    @observable
    public fullStatuses = observable<RFIFullStatusDTO>([]);

    @observable
    public addResponse: boolean = false;

    @observable
    public showFiles: boolean = false;

    @observable
    public showStatusChange: boolean = false;

    @action
    public setHasLoaded = (val: boolean) => {
        this.hasLoaded = val;
    };

    @action
    public clean = () => {
        this.rFIItemViewModels.replace([]);
    };

    @action
    public setBackGroundStatus(id: string | null): string {
        const status = this.fullStatuses.find((i: any) => i.id === id);
        const color = status?.color;
        return color ? color : "";
    }

    @action
    public setColorStatus(id: string | null): string {
        const status = this.fullStatuses.find((i: any) => i.id === id);
        const color = status?.textColor;
        return color ? color : "";
    }

    @action
    public handleAddResponseShow = (itemId: string | null, rfiId: string, projectId: string) => {
        this.rfiResponseId = itemId;
        this.rfiId = rfiId;
        this.projectId = projectId;
        this.addResponse = true;
    };

    @action
    public handleFilesShow = (itemId: string | null, rfiId: string, projectId: string) => {
        this.rfiResponseId = itemId;
        this.rfiId = rfiId;
        this.projectId = projectId;
        this.showFiles = true;
    };

    @action
    public handleStatusChangeShow = (itemId: string | null, rfiId: string, projectId: string) => {
        this.rfiResponseId = itemId;
        this.rfiId = rfiId;
        this.projectId = projectId;
        this.showStatusChange = true;
    };

    @action
    public handleAddResponseClose = (projectId: string) => {
        this.addResponse = false;
        this.loadRFIs(projectId);
    };

    @action
    public handleShowFilesClose = () => {
        this.showFiles = false;
    };

    @action
    public handleStatusChangeClose = () => {
        this.showStatusChange = false;
    };

    @action
    public handleReturnStatus = (rowData: RFIItemViewModel) => {
        return this.statuses.find((s) => s.id === rowData.statusId);
    };

    @action
    public handleReturnResponses = (rowData: RFIItemViewModel) => {
        return this.rFIItemResponseViewModels.find((s) => s.rfiId === rowData.id);
    };

    @action
    public handleReturnResponseCount = (rowData: RFIItemViewModel) => {
        const responses = this.rFIItemResponseViewModels.filter((s) => s.rfiId === rowData.id);
        if (responses) {
            return responses.length;
        }
        return 0;
    };

    @action
    public handleStatusId = async (e: React.MouseEvent<HTMLSelectElement, MouseEvent>, rowData: RFIItemViewModel, item: { id: string; displayName: string }, projectId: string) => {
        e.stopPropagation();
        e.preventDefault();
        await this.updateStatus(rowData.id, item.id, projectId);
    };

    @action
    public createViewModels = () => {
        for (const item of this.model.rfiItems) {
            this.rFIItemViewModels.push(new RFIItemViewModel(item));
        }
        for (const itemResponse of this.model.rfiItemResponses) {
            this.rFIItemResponseViewModels.push(new RFIItemResponseViewModel(itemResponse));
        }
    };

    public getSearchString = () => {
        return computed(() => this.searchString);
    };

    public server: ServerViewModel = new ServerViewModel();

    public loadRFIs = async (projectId: string): Promise<ApiResult<RFIModelDTO>> => {
        this.setIsLoading(true);
        let paramsViewModel = RFIFilterViewModel.Instance;
        let params = paramsViewModel.model.toDto();
        params.projectId = projectId;
        params.rfiId = null;
        params.rfiStatusId = null;
        params.searchText = this.searchString;

        const apiResult = await this.Post<RFIModelDTO>(AppUrls.Server.Projects.ProjectTrackers.RFI.GetRFIList, params);
        if (apiResult) {
            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.model.rfiItems = [];
                    this.rFIItemViewModels.clear();
                    this.rFIItemViewModels = observable([]);
                    this.model.rfiItemResponses = [];
                    this.rFIItemResponseViewModels.clear();
                    this.categories.replace(apiResult.payload.rfiCategories);
                    this.statuses.replace(apiResult.payload.rfiStatuses);
                    this.fullStatuses.replace(apiResult.payload.fullStatuses);
                    this.model.fromDto(apiResult.payload);
                    this.createViewModels();
                });
            } else {
                console.log(apiResult.errors);
            }
        }
        this.setHasLoaded(true);
        this.setIsLoading(false);
        return apiResult;
    };

    public updateStatus = async (id: string, statusId: string, projectId: string): Promise<ApiResult<RFIModelDTO>> => {
        this.setIsLoading(true);
        let paramsViewModel = RFIFilterViewModel.Instance;
        let params = paramsViewModel.model.toDto();
        params.projectId = projectId;
        params.rfiId = id;
        params.rfiStatusId = statusId;

        this.projectId = projectId;
        this.rfiId = id;

        const apiResult = await this.Post<RFIModelDTO>(AppUrls.Server.Projects.ProjectTrackers.RFI.UpdateStatus, params);
        if (apiResult) {
            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.model.rfiItems = [];
                    this.rFIItemViewModels.clear();
                    this.rFIItemViewModels = observable([]);
                    this.model.rfiItemResponses = [];
                    this.rFIItemResponseViewModels.clear();
                    this.statuses.replace(apiResult.payload.rfiStatuses);
                    this.fullStatuses.replace(apiResult.payload.fullStatuses);
                    this.model.fromDto(apiResult.payload);
                    this.createViewModels();

                    const selectedStatus: RFIStatusDTO | undefined = this.statuses.find((t) => t.id === statusId);
                    const updatedRFI: RFIItemModelDTO | undefined = apiResult.payload.rfiListItems.find((t) => t.id === id);
                    if (selectedStatus) {
                        if (updatedRFI) {
                            this.selectedSupplierReference = updatedRFI.supplierReference;
                        }
                    }
                });
            } else {
                console.log(apiResult.errors);
            }
        }
        this.setHasLoaded(true);
        this.setIsLoading(false);
        return apiResult;
    };

    @action
    public handleSearchChange = (projectId: string, val: string) => {
        console.log(val);
        this.searchString = val;
        RFIFilterViewModel.Instance.setValue("searchText", val);

        if (val.length > 3 || val.length === 0) {
            this.reloadDataDelayed(projectId);
        }
    };

    private reloadDataDelayed = debounce((projectId: string) => {
        this.loadRFIs(projectId);
    }, 300);

    @action
    public handleRowClick = (e: any, rowData: RFIItemViewModel, projectId: string) => {
        this.history.push(AppUrls.Client.Project.RFI.View.replace(":projectid", projectId).replace(":rfiid", rowData.id));
    };

    @action
    public handleCreateNew = (projectId: string) => {
        this.history.push(AppUrls.Client.Project.RFI.Add.replace(":projectid", projectId));
    };

    @action
    public exportAsCSV = async (projectId: string) => {
        this.setIsLoading(true);

        const requestBody = {
            projectId,
            rfiId: null,
            rfiStatusId: null,
            searchText: "",
        };

        let config: Axios.AxiosRequestConfig = {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
        };

        const response = await axios
            .post(AppUrls.Server.Projects.ProjectTrackers.RFI.ExportRFIList, requestBody, await this.getConfig(true, config))
            .then((response: any) => {
                if (response.status === 200) {
                    const headerFileName: string = response.headers["content-disposition"].split("filename=")[1].split(";")[0];
                    let fileName = "RFICSV.csv";
                    if (headerFileName.endsWith(".csv")) {
                        fileName = headerFileName;
                    }
                    const url_1 = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url_1;
                    link.setAttribute("download", fileName);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    window.URL.revokeObjectURL(url_1);
                }
            })
            .finally(() => this.setIsLoading(false));
    };

    @action
    public handleRevisionClick = (e: any, rowData: RFIItemViewModel, projectId: string) => {
        e.stopPropagation;
        e.preventDefault;
        this.history.push(AppUrls.Client.Project.RFI.AddWithRevision.replace(":projectid", projectId).replace(":rfiid", rowData.id).replace(":isrevision", "1"));
    };

    public async isFieldValid(fieldName: keyof FieldType<RFIModel>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}

export interface RFIUpdateStatusDTO {
    id: string;
    rFIStatusId: string;
}

export interface RFICategoryDTO {
    id: string;
    displayName: string;
}

export interface RFIStatusDTO {
    id: string;
    displayName: string;
}

export interface RFIFullStatusDTO {
    id: string;
    displayName: string;
    color: string;
    textColor: string;
    type: number;
}
