import { Box, FormControlLabel, FormHelperText, FormLabel, IconButton, RadioGroup, TextField, Typography } from "@material-ui/core";
import { Cell, Grid, useRouter } from "@shoothill/core";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Form, FormSection } from "Views/PurchaseOrder/Form/Views/Forms";
import { DashedDivider, SolidDivider } from "Views/PurchaseOrder/Form/Views/Dividers";
import { pxToRem, theme } from "Globals/Styles/AppTheme";
import { CustomRadio, CustomArrow } from "Views/Project/CustomComponents";
import { DefaultButton, PrimaryButton } from "Components/Buttons/Buttons";
import { Signature } from "Components/Signature/Signature";
import { DarwinSelect } from "Components/AutoComplete/DarwinSelect";
import { BackButtonHolder } from "Views/Project/Commercial/VariationTables/VariationGrid.Styles";
import { AppUrls } from "AppUrls";
import BuildingControlSiteVisitFormHeader from "./BuildingControlSiteVisitFormHeader";
import { BuildingControlSiteVisitViewModel } from "./BuildingControlSiteVisitViewModel";
import { Uploader } from "Components/Uploader/Uploader";
import SystemUpdateTwoToneIcon from "@material-ui/icons/SystemUpdateTwoTone";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { BuildingControlReportPhotosFile, BuildingControlSiteVisitPhotoFile } from "./BuildingControlSiteVisitModel";
import { isEmpty } from "Utils/Utils";
import moment from "moment";

interface IProps {
    className?: string;
}

const BuildingControlSiteVisitView: React.FC<IProps> = (props) => {
    const { match } = useRouter();
    const { history } = useRouter();
    const { projectid, date } = match.params as any;
    const [viewModel] = useState(() => new BuildingControlSiteVisitViewModel(projectid, date));

    useEffect(() => {
        return () => {
            viewModel.reset();
        };
    }, []);

    const COLUMNS8 = "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr";
    const COLUMNS4 = "1fr 1fr 1fr 1fr";
    const COLUMNS2 = "1fr 1fr";

    const onSubmit = (event: any) => {
        event.preventDefault();
    };

    const renderForm = () => {
        return (
            <FormBox>
                <Form className={props.className} onSubmit={onSubmit}>
                    <FormSection>
                        <DashedDivider gutterBottom={true} borderThickness={1} borderOpacity={1} />
                        <Grid columnGap={30} rowGap={20} tc={COLUMNS4} dc={COLUMNS4} style={{ marginBottom: "20px" }}>
                            <DarwinSelect
                                displayName="Inspection type:"
                                execute={(data: { id: string; displayName: string }) => viewModel.handleInspectionTypeId(data)}
                                fullWidth={true}
                                getOptionLabel={(option: any) => option.displayName}
                                options={viewModel.buildingControlInspectionType}
                                onBlur={() => viewModel.isFieldValid("buildingControlInspectionTypeId")}
                                placeholder="Please select"
                                value={viewModel.inspectionType}
                                error={viewModel.getError("buildingControlInspectionTypeId") !== ""}
                                validationMessage={viewModel.getError("buildingControlInspectionTypeId")}
                            />
                        </Grid>
                        <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8} style={{ marginBottom: "20px" }}>
                            <Cell ts={8} ds={8}>
                                <TextField
                                    id="areaOfWorkInspected-textarea"
                                    multiline
                                    minRows={4}
                                    value={viewModel.model.workInspectDetail}
                                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                                        viewModel.setValue<string>("workInspectDetail", event.target.value)
                                    }
                                    fullWidth
                                    className="textarea-forminput"
                                    label="Area of work inspected:"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    placeholder={`Please add the area of work inspected`}
                                    onBlur={() => viewModel.isFieldValid("workInspectDetail")}
                                    disabled={false}
                                    error={viewModel.getError("workInspectDetail") !== ""}
                                    helperText={viewModel.getError("workInspectDetail")}
                                />
                            </Cell>
                        </Grid>
                        <Grid columnGap={30} rowGap={20} tc={"1fr 1fr 1fr 1fr 1fr 1fr"} dc={"1fr 1fr 1fr 1fr 1fr 1fr"} mb={"18px"}>
                            <Box>
                                <FormLabel>Has the area passed inspection?</FormLabel>
                                <RadioGroup
                                    row
                                    defaultValue="Yes"
                                    name={"radio"}
                                    value={viewModel.getValue("hasPassedInspection")}
                                    onChange={(e) => viewModel.hasPassedInspection("hasPassedInspection", e.target.value)}
                                >
                                    <FormControlLabel value="true" control={<CustomRadio checked={viewModel.getValue("hasPassedInspection") === true} />} label="Yes" />
                                    <FormControlLabel value="false" control={<CustomRadio checked={viewModel.getValue("hasPassedInspection") === false} />} label="No" />
                                </RadioGroup>
                            </Box>
                        </Grid>
                        <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8} style={{ marginBottom: "20px" }}>
                            <Cell ts={8} ds={8}>
                                <TextField
                                    id="comments-textarea"
                                    multiline
                                    minRows={4}
                                    value={viewModel.model.comments}
                                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => viewModel.setValue<string>("comments", event.target.value)}
                                    fullWidth
                                    className="textarea-forminput"
                                    label="Comments"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    placeholder={`Please add any comments`}
                                    onBlur={() => viewModel.isFieldValid("comments")}
                                    disabled={false}
                                    error={viewModel.getError("comments") !== ""}
                                    helperText={viewModel.getError("comments")}
                                />
                            </Cell>
                        </Grid>
                        <Grid columnGap={30} rowGap={20} tc={"1fr"} dc={"1fr"}>
                            <Cell ts={1} ds={1}>
                                <FileUploader>
                                    <Grid columnGap={30} rowGap={20} tc={COLUMNS2} dc={COLUMNS2}>
                                        <Box>
                                            <Typography
                                                style={{ marginBottom: "8px", font: "normal normal 700 12px/16px Segoe UI", letterSpacing: "-0.18px", color: "#191919" }}
                                                variant="h5"
                                            >
                                                Photo(s):
                                            </Typography>
                                            <Uploader handleSelectFile={(e: any) => viewModel.fileChange(e, false)} isFormDisabled={false} label="" />
                                        </Box>
                                    </Grid>
                                    {viewModel.model.buildingControlSiteVisitPhotos.filter((el: any) => !el.isDeleted).length > 0 && (
                                        <Typography style={{ margin: "20px 0px 10px" }} variant="h5">
                                            Photo(s) added:
                                        </Typography>
                                    )}
                                    <Box className="uploadedFile">
                                        {viewModel.model.buildingControlSiteVisitPhotos.map((item: BuildingControlSiteVisitPhotoFile, index: number) => {
                                            return (
                                                !item.isDeleted && (
                                                    <Box className="uploadedFile-box">
                                                        <Box className="uploadedFile-heading">
                                                            <IconButton onClick={() => viewModel.DownloadFile(item.photoURL, item.fileName)}>
                                                                <SystemUpdateTwoToneIcon />
                                                            </IconButton>
                                                            <h3>{item.fileName}</h3>
                                                        </Box>
                                                        <IconButton onClick={() => viewModel.deleteBuildingControlSiteVisitFile(index)}>
                                                            <DeleteOutlinedIcon />
                                                        </IconButton>
                                                    </Box>
                                                )
                                            );
                                        })}
                                    </Box>
                                </FileUploader>
                            </Cell>
                        </Grid>
                    </FormSection>
                    <FormSection>
                        <DashedDivider gutterBottom={true} borderThickness={1} borderOpacity={1} />
                        <SubtitleContainer>
                            <Typography variant="h2" style={{ font: "normal normal 600 18px/40px Open Sans", letterSpacing: "-0.36px", color: "#191919" }}>
                                Authorisation
                            </Typography>
                            <Grid columnGap={30} rowGap={20} tc={COLUMNS4} dc={COLUMNS4} style={{ marginBottom: "20px" }}>
                                <TextField
                                    className="textarea-forminput"
                                    disabled={false}
                                    error={viewModel.getError("buildingInspectorName") !== ""}
                                    fullWidth
                                    helperText={viewModel.getError("buildingInspectorName")}
                                    id="inspectorname-textarea"
                                    InputLabelProps={{ shrink: true }}
                                    label="Building inspector:"
                                    onBlur={() => viewModel.isFieldValid("buildingInspectorName")}
                                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                                        viewModel.setValue<string>("buildingInspectorName", event.target.value)
                                    }
                                    placeholder={`Please enter a name`}
                                    value={viewModel.getValue("buildingInspectorName") ?? ""}
                                />
                            </Grid>
                            {viewModel.buildingControlSiteVisit !== null && (
                                <Grid columnGap={20} rowGap={20} tc={COLUMNS4} dc={COLUMNS4} style={{ marginBottom: "18px" }}>
                                    <DataList>
                                        <span>Auditor:</span>
                                        {viewModel.getUserName(viewModel.model.auditorUserId)}
                                    </DataList>
                                    <DataList>
                                        <span>Date / time added:</span>
                                        {viewModel.model.formattedAuditorCreationDate}
                                    </DataList>
                                </Grid>
                            )}
                            <Grid columnGap={30} rowGap={20} tc={COLUMNS4} dc={COLUMNS4}>
                                <SignatureContainer>
                                    <SubtitleContainer></SubtitleContainer>
                                    <Box>
                                        <Box>
                                            <Signature
                                                displayName={"Signature:"}
                                                execute={(data: any) => viewModel.setValue<string>("signatureIssuedByURL", data)}
                                                value={viewModel.model.signatureIssuedByURL}
                                                canExecute={!viewModel.model.hasId}
                                            />
                                            {viewModel.model.signatureIssuedByURL === null && viewModel.getError("signatureIssuedByURL") !== "" && (
                                                <FormHelperText style={{ color: "#f44336" }}>{viewModel.getError("signatureIssuedByURL")}</FormHelperText>
                                            )}
                                        </Box>
                                    </Box>
                                </SignatureContainer>
                            </Grid>
                        </SubtitleContainer>
                    </FormSection>
                    {viewModel.model.buildingInspectorName && viewModel.model.signatureIssuedByURL && (
                        <FormSection>
                            <DashedDivider gutterBottom={true} borderThickness={1} borderOpacity={1} />
                            <SubtitleContainer>
                                <Typography variant="h2" style={{ font: "normal normal 600 18px/24px Open Sans", letterSpacing: "-0.36px", color: "#191919", marginBottom: "0" }}>
                                    Submission
                                </Typography>
                            </SubtitleContainer>
                            <Grid columnGap={30} rowGap={20} tc={"1fr"} dc={"1fr"}>
                                <Cell ts={1} ds={1}>
                                    <FileUploader>
                                        <Grid columnGap={30} rowGap={20} tc={COLUMNS2} dc={COLUMNS2}>
                                            <Box>
                                                <Typography
                                                    style={{ marginBottom: "8px", font: "normal normal 700 12px/16px Segoe UI", letterSpacing: "-0.18px", color: "#191919" }}
                                                    variant="h5"
                                                >
                                                    Building control report:
                                                </Typography>
                                                <Uploader handleSelectFile={(e: any) => viewModel.fileChange(e, true)} isFormDisabled={false} label="" />
                                            </Box>
                                        </Grid>
                                        {viewModel.model.buildingControlReportPhotos.filter((el: any) => !el.isDeleted).length > 0 && (
                                            <Typography style={{ margin: "20px 0px 10px" }} variant="h5">
                                                Building control report:
                                            </Typography>
                                        )}
                                        <Box className="uploadedFile">
                                            {viewModel.model.buildingControlReportPhotos.map((item: BuildingControlReportPhotosFile, index: number) => {
                                                return (
                                                    !item.isDeleted && (
                                                        <Box className="uploadedFile-box">
                                                            <Box className="uploadedFile-heading">
                                                                <IconButton onClick={() => viewModel.DownloadFile(item.photoURL, item.fileName)}>
                                                                    <SystemUpdateTwoToneIcon />
                                                                </IconButton>
                                                                <h3>{item.fileName}</h3>
                                                            </Box>
                                                            <IconButton onClick={() => viewModel.deleteBuildingControlReportPhotosFile(index)}>
                                                                <DeleteOutlinedIcon />
                                                            </IconButton>
                                                        </Box>
                                                    )
                                                );
                                            })}
                                        </Box>
                                    </FileUploader>
                                </Cell>
                            </Grid>
                        </FormSection>
                    )}
                    <Box style={{ margin: `0 ${pxToRem(30)} 0 ${pxToRem(30)}` }}>
                        <SolidDivider gutterBottom={true} borderThickness={1} borderOpacity={1} />
                    </Box>
                    <FormSection>
                        <ButtonsContainer>
                            <PrimaryButton displayName="Save" execute={() => viewModel.upsert()} fullWidth={true} canExecute={!viewModel.IsLoading} />
                            <DefaultButton displayName="Cancel" execute={() => viewModel.handleCancel(projectid)} fullWidth={true} canExecute={!viewModel.IsLoading} />
                        </ButtonsContainer>
                    </FormSection>
                </Form>
            </FormBox>
        );
    };

    return useObserver(() => {
        return (
            <Form className={props.className} onSubmit={() => {}}>
                <BackButtonHolder
                    onClick={() => {
                        history.push(AppUrls.Client.Project.Construction.replace(":projectid", projectid ? projectid : viewModel.model.projectId) + "#programmeUpdates");
                    }}
                    style={{ padding: "10px 0", marginTop: 0 }}
                >
                    <Box style={{ position: "relative", display: "flex", marginLeft: "10px", alignItems: "center" }}>
                        <Box style={{ position: "absolute", height: "15px", width: "8px" }}>
                            <CustomArrow size={"8px"} color={theme.palette.blue.main} type={"left"} />
                        </Box>
                        <Box style={{ marginLeft: "20px", font: "normal normal 600 18px/40px Open Sans", letterSpacing: "-0.36px", color: theme.palette.blue.main }}>
                            Back to Programme updates{" "}
                            {`(${
                                viewModel.buildingControlSiteVisitProjectDetails?.projectReference !== undefined
                                    ? viewModel.buildingControlSiteVisitProjectDetails?.projectReference
                                    : "-"
                            } - ${
                                viewModel.buildingControlSiteVisitProjectDetails?.projectName !== undefined ? viewModel.buildingControlSiteVisitProjectDetails?.projectName : "-"
                            })`}
                        </Box>
                    </Box>
                </BackButtonHolder>
                <BuildingControlSiteVisitFormHeader
                    ProjectDetails={viewModel.buildingControlSiteVisitProjectDetails}
                    ProjectDate={viewModel.getTodayDateFormatted}
                    FormHeaderTitle={"Building control site visit"}
                />

                {renderForm()}
            </Form>
        );
    });
};

export const BuildingControlSiteVisitViewBase = styled(BuildingControlSiteVisitView)`
    display: flex;
    flex: 1;
    flex-direction: column;

    form {
        position: relative;
    }
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;

    > button {
        max-width: 262px;
    }

    > button:nth-child(1) {
        margin-right: 30px;
    }
`;

const FormBox = styled(Box)`
    .MuiFormLabel-root {
        font: normal normal 700 12px/16px Segoe UI;
        letter-spacing: -0.18px;
        color: #191919;
        margin: 0 0 7px;
    }
    .MuiFormLabel-root + div {
        margin-top: 25px !important;
        &.MuiFormGroup-root.MuiFormGroup-row {
            margin-top: 0 !important;
        }
    }
`;

interface SubtitleProps {
    margin?: string;
}

const DataList = styled(Box)`
    font: normal normal normal 12px/16px Segoe UI;
    letter-spacing: -0.18px;
    color: #191919;
    display: flex;
    align-items: center;
    gap: 12px;
    span {
        font-weight: bold;
    }
`;

export const SubtitleContainer = styled.div<SubtitleProps>`
    margin-top: 12px;
    h2 {
        margin-bottom: ${(props) => (props.margin ? props.margin : "10px")};
        font: normal normal 600 12px/16px Segoe UI;
        letter-spacing: -0.18px;
        color: #191919;
        span {
            font-weight: bold;
            margin-right: 10px;
        }
    }
`;

const SignatureContainer = styled.div`
    display: flex;
    flex-direction: column;
    grid-column-start: 1;
    grid-column-end: 3;

    > div:nth-child(2) {
        display: flex;
        flex-direction: row;

        > div {
            width: 100%;

            canvas {
                border: 1px solid #d4d4d4;
            }
        }
    }
    .MuiFormControl-root {
        width: 100% !important;
    }
`;

const FileUploader = styled(Box)`
    margin-top: 15px;
    .fileUpload {
        background: #6c93a626;
        border: 1px dashed #6c93a6;
        font: normal normal normal 12px/14px Open Sans;
        letter-spacing: 0px;
        color: #425b66;
        padding: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 90px;
        margin: 0 !important;
    }
`;
