import { ApiResult, FieldType, ViewModelBase } from "@shoothill/core";
import { action, computed, observable, runInAction } from "mobx";
import type { IObservableArray } from "mobx";

import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { InductionAdminDetailsResultDTO, InductionAdminModel, InductionAdminSiteAccessDTO, InductionStatusDTO, InductionStatusEnum } from "./InductionAdminModel";
import { InductionAdminItemViewModel } from "./Details/InductionAdminItemViewModel";
import { AppUrls } from "AppUrls";
import { InductionAdminSiteAccessLogViewModel } from "./SiteAccessLog/InductionAdminSiteAccessLogViewModel";
import { AdminSiteAccessFilterViewModel } from "./SiteAccessLog/AdminSiteAccessFilterViewModel";
import axios, * as Axios from "axios";
import debounce from "lodash-es/debounce";

export class InductionAdminViewModel extends ViewModelBase<InductionAdminModel> {
    public server: ServerViewModel = new ServerViewModel();

    // #region Constructors and Disposers

    @observable
    private static _instance: InductionAdminViewModel;

    @action
    public static Instance(id: string) {
        return this.GetInstance || (this._instance = new this(id));
    }
    @computed
    public static get GetInstance() {
        return this._instance;
    }
    @action
    public static ResetInstance() {
        this._instance = undefined as any;
    }

    constructor(id: string) {
        super(new InductionAdminModel());
        this.setDecorators(InductionAdminViewModel);
        this.model.id = id;
    }

    @observable
    public hasLoaded: boolean = false;

    @action
    public setHasLoaded = (val: boolean) => {
        this.hasLoaded = val;
    };

    @observable
    public inductionAdminItemViewModel: InductionAdminItemViewModel = new InductionAdminItemViewModel();

    @observable
    public inductionAdminSiteAccessViewModels: IObservableArray<InductionAdminSiteAccessLogViewModel> = observable([]);

    @computed
    public get getInductionItems() {
        return this.inductionAdminItemViewModel;
    }

    @computed
    public get canApprove() {
        const approvedStatus = this.inductionStatuses.find((s) => s.type === InductionStatusEnum.AccessApproved);

        if (approvedStatus) {
            return this.inductionAdminItemViewModel.model.inductionStatusId !== approvedStatus.id;
        }

        return false;
    }

    @observable
    public inductionStatuses: InductionStatusDTO[] = [];

    @computed
    public get inductionStatusOptions() {
        return this.inductionStatuses.map((s) => {
            return { value: s.id, label: s.displayName };
        });
    }

    // #endregion Properties

    // #region Server Actions

    public loadInductionDetails = async (): Promise<void> => {
        this.setIsLoading(true);

        try {
            let retVal = await this.server.query<InductionAdminDetailsResultDTO>(
                () => this.Get(`${AppUrls.Server.Induction.GetInductionDetailsById}\\${this.model.id}`),
                (result) => {
                    runInAction(() => {
                        this.inductionAdminItemViewModel.model.fromExtendedDto(result.inductionItem, result.inductionTypes);
                        this.inductionStatuses = result.inductionStatuses;
                    });
                },
            );

            if (this.server.HaveValidationMessage) {
                this.setSnackMessage("There was an error trying to load the induction");
                this.setSnackType(this.SNACKERROR);
                this.setSnackbarState(true);
            }

            return retVal;
        } finally {
            this.setHasLoaded(true);
            this.setIsLoading(false);
        }
    };

    public loadSiteAccess = async (id: string): Promise<ApiResult<InductionAdminSiteAccessDTO>> => {
        this.setIsLoading(true);
        let paramsViewModel = AdminSiteAccessFilterViewModel.Instance;
        let params = paramsViewModel.model.toDto();
        params.id = id;

        const apiResult = await this.Post<InductionAdminSiteAccessDTO>(AppUrls.Server.Induction.GetInductionSiteAccessByInductionUserId, params);
        if (apiResult) {
            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.model.siteAccessItems = [];
                    this.inductionAdminSiteAccessViewModels.clear();
                    this.model.fromSiteAccessDto(apiResult.payload);
                    this.createSiteAccessViewModels();
                    //this.inductionStatuses = apiResult.payload.inductionStatuses;
                });
            } else {
                console.log(apiResult.errors);
            }
        }
        this.setHasLoaded(true);
        this.setIsLoading(false);
        return apiResult;
    };

    @action
    public createSiteAccessViewModels = () => {
        for (const item of this.model.siteAccessItems) {
            this.inductionAdminSiteAccessViewModels.push(new InductionAdminSiteAccessLogViewModel(item));
        }
    };

    public approveInduction = async (): Promise<void> => {
        this.setIsLoading(true);
        let retVal = await this.server
            .command<any>(
                () => this.Post(AppUrls.Server.Induction.Approve, { id: this.model.id }),
                (result) => {
                    runInAction(() => {
                        this.reset();
                        this.history.push(AppUrls.Client.Induction.List);
                    });
                },
                this.isMyModelValid,
                "There was an error trying to approve the induction",
            )
            .finally(() => this.setIsLoading(false));

        if (this.server.HaveValidationMessage) {
            this.setSnackMessage("There was an error trying to approve the induction");
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }

        return retVal;
    };

    @computed
    public get getSiteAccessItems(): InductionAdminSiteAccessLogViewModel[] {
        return this.inductionAdminSiteAccessViewModels;
    }

    @observable
    public itemsToSignOut: IObservableArray<string> = observable([]);

    @computed
    public get getItemsToSignOut(): string[] {
        return this.itemsToSignOut;
    }

    @computed
    public get allItemsSelected(): boolean {
        return (
            this.itemsToSignOut.length === this.getSiteAccessItems.filter((i) => !i.model.hasSignedOut).length &&
            this.getSiteAccessItems.length > 0 &&
            this.getSiteAccessItems.filter((i) => !i.model.hasSignedOut).length > 0
        );
    }

    @action
    public changeItemToSignOut = (itemId: string) => {
        this.setIsLoading(true);
        const existingItem: string | undefined = this.itemsToSignOut.find((i) => i === itemId);
        if (existingItem) {
            this.itemsToSignOut.replace(this.itemsToSignOut.filter((i) => i !== itemId));
        } else {
            this.itemsToSignOut.push(itemId);
        }

        this.setIsLoading(false);
    };

    @action
    public handleSelectAll = (checked: boolean) => {
        this.setIsLoading(true);

        if (checked) {
            this.inductionAdminSiteAccessViewModels
                .filter((i) => !i.model.hasSignedOut)
                .forEach((item) => {
                    if (this.itemsToSignOut.findIndex((i) => i === item.id) === -1) {
                        this.itemsToSignOut.push(item.id);
                    }
                });
        } else {
            this.itemsToSignOut.replace([]);
        }

        this.setIsLoading(false);
    };

    @computed
    public get hasSignoutItems(): boolean {
        return this.inductionAdminSiteAccessViewModels.filter((i) => !i.model.hasSignedOut).length > 0;
    }

    @action
    public handleChangeStartDate = (id: string, date: string | null) => {
        AdminSiteAccessFilterViewModel.Instance.setStartDateFilter(date === null ? date : new Date(date));
        const isValid: boolean = AdminSiteAccessFilterViewModel.Instance.model.validateFilters();
        if (isValid) {
            this.loadSiteAccess(id);
        }
    };

    @action
    public handleChangeEndDate = (id: string, date: string | null) => {
        AdminSiteAccessFilterViewModel.Instance.setEndDateFilter(date === null ? date : new Date(date));
        const isValid: boolean = AdminSiteAccessFilterViewModel.Instance.model.validateFilters();
        if (isValid) {
            this.loadSiteAccess(id);
        }
    };

    @action
    public handleResetDateFilters = (id: string) => {
        AdminSiteAccessFilterViewModel.Instance.resetDateFilters();
        const isValid: boolean = AdminSiteAccessFilterViewModel.Instance.model.validateFilters();
        if (isValid) {
            this.loadSiteAccess(id);
        }
    };

    public forceInductionsSignOut = async (id: string): Promise<ApiResult<any>> => {
        this.setIsLoading(true);

        const ids: string[] = this.getItemsToSignOut;

        const apiResult = await this.Post<any>(AppUrls.Server.Projects.SiteAccess.ForceInductionsSignOut, { ids });
        if (apiResult) {
            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.itemsToSignOut.replace([]);
                    this.loadSiteAccess(id);
                });
            } else {
                console.log(apiResult.errors);
            }
        }
        this.setHasLoaded(true);
        this.setIsLoading(false);
        return apiResult;
    };

    public handleSearchChange = (id: string, val: string) => {
        AdminSiteAccessFilterViewModel.Instance.setValue("searchText", val);

        if (val.length > 3 || val.length === 0) {
            this.reloadDataDelayed(id);
        }
    };

    private reloadDataDelayed = debounce((id: string) => {
        this.loadSiteAccess(id);
    }, 300);

    @action
    public handleSignoutFilterChange = (id: string, val: boolean) => {
        AdminSiteAccessFilterViewModel.Instance.setValue("showOnSiteOnly", val);
        this.reloadDataDelayed(id);
    };

    @action
    public generateSiteAccessCSV = async (id: string) => {
        this.setIsLoading(true);

        // JC: Download a CSV file using a HTTP POST request.
        // Source: https://stackoverflow.com/a/55138366

        let paramsViewModel = AdminSiteAccessFilterViewModel.Instance;
        let params = paramsViewModel.model.toDto();
        params.id = id;

        let config: Axios.AxiosRequestConfig = {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
        };

        const response = await axios
            .post(AppUrls.Server.Induction.GenerateSiteAccessCSV, params, await this.getConfig(true, config))
            .then((response: any) => {
                if (response.status === 200) {
                    const headerFileName: string = response.headers["content-disposition"].split("filename=")[1].split(";")[0];
                    let fileName = "SiteAccessCSV.csv";
                    if (headerFileName.endsWith(".csv")) {
                        fileName = headerFileName;
                    }
                    const url_1 = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url_1;
                    link.setAttribute("download", fileName);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    window.URL.revokeObjectURL(url_1);
                }
            })
            .finally(() => this.setIsLoading(false));
    };

    public isMyModelValid = async (): Promise<boolean> => {
        return true;
    };

    @action
    public reset = () => {
        InductionAdminViewModel.ResetInstance();
    };

    @action
    public clean = () => {
        this.inductionAdminSiteAccessViewModels.replace([]);
        this.itemsToSignOut.replace([]);
        AdminSiteAccessFilterViewModel.Instance.resetDateFilters();
    };

    // #endregion Client Actions

    // #region Snackbar

    @observable
    public snackbarState = false;

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public snackMessage = "";

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @observable
    public snackType = "";

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    @observable
    public SNACKSUCCESS = "success";

    @observable
    public SNACKERROR = "error";
    // #endregion

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<InductionAdminModel>): Promise<boolean> {
        return true;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
