import { ModelBase } from "@shoothill/core";
import { IsNotEmpty, ValidateIf } from "class-validator";
import { action, observable } from "mobx";

import { InductionType } from "Globals/Models/Enums/InductionType";

export class LandingModel extends ModelBase<LandingModel> {
    // #region Defaults

    public static readonly DEFAULT_AGE = 0;
    public static readonly DEFAULT_INDUCTIONTYPEID = null;
    public static readonly DEFAULT_INDUCTIONTYPES = [];

    // #endregion Defaults

    // #region Properties

    public id: string = "";

    @observable
    public inductionTypes = observable<InductionType>(LandingModel.DEFAULT_INDUCTIONTYPES);

    @observable
    public otherEmployerName: boolean = false;

    @observable
    @IsNotEmpty({ message: "You must enter your Priority Employee name" })
    public priorityEmployerName: string = "";

    @observable
    @IsNotEmpty({ message: "You must enter your first name" })
    public firstName: string = "";

    @observable
    @IsNotEmpty({ message: "You must enter your last name name" })
    public lastName: string = "";

    @observable
    @IsNotEmpty({ message: "You must select an induction type" })
    public inductionTypeId: string | null = LandingModel.DEFAULT_INDUCTIONTYPEID;

    @observable
    @IsNotEmpty({ message: "You must select your inductee type" })
    public visitorType: string = "";

    @observable
    @ValidateIf((o) => !o.otherEmployerName)
    @IsNotEmpty({ message: "You must select a supplier" })
    public supplierId: string = "";

    @observable
    @ValidateIf((o) => !o.preferNotToSay)
    @IsNotEmpty({ message: "You must select your age" })
    public age: number = LandingModel.DEFAULT_AGE;

    @observable
    public preferNotToSay: boolean = false;

    // #endregion Properties

    constructor() {
        super();
    }

    // #region Actions

    @action
    public fromDto(dto: any): void {
        throw new Error("Method not implemented.");
    }

    public toDto(): any {
        return {
            id: this.id,
            priorityEmployerName: this.priorityEmployerName,
            supplierId: this.supplierId,
            firstName: this.firstName,
            lastName: this.lastName,
            age: this.age,
            visitorType: this.visitorType,
            inductionTypeId: this.inductionTypeId,
        };
    }

    // #endregion Actions
}
