import { action, observable } from "mobx";
import { ModelBase } from "@shoothill/core";

export class ProjectFilterParamsModel extends ModelBase<ProjectFilterParamsModel, ProjectFilterParamsModelDTO> {
    @observable
    public includeDeleted: boolean = false;

    @observable
    public statuses: string[] | null = [];

    @observable
    public cells: number[] | null = [];

    fromDto(model: ProjectFilterParamsModelDTO): void {}

    toDto(): ProjectFilterParamsModelDTO {
        let dto: ProjectFilterParamsModelDTO = {
            includeDeleted: this.includeDeleted,
            statuses: this.statuses,
            cells: this.cells,
        };
        return dto;
    }

    @action
    public reset = () => {
        this.statuses = [];
        this.cells = [];
    };
}

export interface ProjectFilterParamsModelDTO {
    includeDeleted: boolean;
    statuses: string[] | null;
    cells: number[] | null;
}
