import { observer } from "mobx-react-lite";
import React from "react";

import { TabItemViewModel } from "./TabItemViewModel";

interface IProps {
    viewModel: TabItemViewModel;
    setActiveViewModel: (vm: TabItemViewModel) => void;
}

export const TabItem: React.FC<IProps> = observer((props) => {
    const renderTitle = () => {
        return props.viewModel.canDisplayCount ? (
            <React.Fragment>
                {props.viewModel.title} <span>{props.viewModel.count}</span>
            </React.Fragment>
        ) : (
            props.viewModel.title
        );
    };

    return (
        <li className={props.viewModel.isActive ? "active" : ""} onClick={() => props.setActiveViewModel(props.viewModel)}>
            {renderTitle()}
        </li>
    );
});
