import { FieldType, isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { action, computed } from "mobx";
import { formatCurrencyFromPounds } from "Utils/Format";
import { InvoiceStatusEnum } from "../../Details/InvoiceDetailsModel";
import { InvoiceViewModel } from "../../InvoiceViewModel";
import { InvoicePurchaseOrderItemModel, InvoicePurchaseOrderItemsAllocatedDTO } from "./InvoicePurchaseOrderItemModel";
import type { ValidationResponse } from "@shoothill/core";
import { validateTwoDecimalPlaces } from "Utils/Utils";

export class InvoicePurchaseOrderItemViewModel extends ViewModelBase<InvoicePurchaseOrderItemModel> {
    // #region Constructors and Disposers

    constructor(model: InvoicePurchaseOrderItemModel) {
        super(model);
        this.setDecorators(InvoicePurchaseOrderItemViewModel);
    }

    @computed
    public get isFormDisabled(): boolean {
        if (InvoiceViewModel.GetInstance.hasBeenResetToMatch) {
            return false;
        }
        return (
            InvoiceViewModel.GetInstance.detailsViewModel.model.invoiceStatusId ===
                InvoiceViewModel.GetInstance.invoiceStatuses.find((s) => s.type === InvoiceStatusEnum.Disputed)!.id ||
            InvoiceViewModel.GetInstance.detailsViewModel.model.invoiceStatusId ===
                InvoiceViewModel.GetInstance.invoiceStatuses.find((s) => s.type === InvoiceStatusEnum.Approved)!.id ||
            InvoiceViewModel.GetInstance.detailsViewModel.model.invoiceStatusId === InvoiceViewModel.GetInstance.invoiceStatuses.find((s) => s.type === InvoiceStatusEnum.Paid)!.id
        );
    }

    @computed
    public get getInitialValue(): number {
        return this.model.initialValue ? Number(this.model.initialValue) : 0;
    }

    @computed
    public get getAlreadyAllocated(): number {
        return this.model.alreadyAllocated ? Number(this.model.alreadyAllocated) : 0;
    }

    @computed
    public get getAvailableBalance(): number {
        const value: Number = Number(this.getInitialValue.toFixed(2)) - Number(this.getAlreadyAllocated.toFixed(2)) - Number(this.getAmountAllocated.toFixed(2));
        return Number(value.toFixed(2));
    }

    /**
     * Used for validation.
     */
    @computed
    public get getAvailableBalanceAbsolute(): number {
        // Absolute used because then can enter negative values, but we want the validation to be the same as positive values.
        // Still need the return value to be negative though.
        const value: Number =
            Math.abs(Number(this.getInitialValue.toFixed(2))) - Math.abs(Number(this.getAlreadyAllocated.toFixed(2))) - Math.abs(Number(this.getAmountAllocated.toFixed(2)));
        return Number(value.toFixed(2));
    }

    @computed
    public get getAmountAllocated(): number {
        return this.model.amountAllocated ? Number(this.model.amountAllocated.toFixed(2)) : 0;
    }

    @computed
    public get getInitialValueFormatted(): string {
        if (this.getInitialValue !== 0) {
            return formatCurrencyFromPounds(this.getInitialValue);
        } else {
            return "---";
        }
    }

    @computed
    public get getAlreadyAllocatedFormatted(): string {
        if (this.getAlreadyAllocated !== 0) {
            return formatCurrencyFromPounds(this.getAlreadyAllocated);
        } else {
            return "---";
        }
    }

    @computed
    public get getAvailableBalanceFormatted(): string {
        if (Number(this.getAvailableBalance.toFixed(2)) !== 0) {
            return formatCurrencyFromPounds(Number(this.getAvailableBalance.toFixed(2)));
        } else {
            return "---";
        }
    }

    @computed
    public get getAmountAllocatedFormatted(): string {
        if (Number(this.getAmountAllocated.toFixed(2)) !== 0) {
            return formatCurrencyFromPounds(Number(this.getAmountAllocated.toFixed(2)));
        } else {
            return "---";
        }
    }

    @action
    public setAmountAllocated = (val: string) => {
        this.model.amountAllocated = val !== null && val !== "" ? Number(val) : null;
    };

    @computed
    public get invoicePurchaseOrderItemsAllocatedFiltered(): InvoicePurchaseOrderItemsAllocatedDTO[] {
        return this.model.invoicePurchaseOrderItemsAllocated.filter((i) => i.alreadyAllocated !== 0);
    }

    @action
    public reset = () => {
        this.model.reset();
    };

    // #endregion Client Actions

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<InvoicePurchaseOrderItemModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);
        switch (fieldName) {
            case "amountAllocated": {
                const result = this.validateAmountAllocated;

                errorMessage = result.errorMessage;
                isValid = result.isValid;
                break;
            }
        }
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    @computed
    private get validateAmountAllocated(): ValidationResponse {
        const errorMessage = this.validateAmountAllocatedRules;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    public get validateAmountAllocatedRules(): string {
        // RULES
        const amountAllocatedNumber: number = Number(this.model.amountAllocated);
        const initialValueNumber: number = Number(this.getInitialValue);

        if (initialValueNumber < 0 && amountAllocatedNumber > 0) {
            return "You can't allocate a positive amount when the initial value is negative";
        }

        if (initialValueNumber >= 0 && amountAllocatedNumber < 0) {
            return "You can't allocate a negative amount when the initial value is positive";
        }

        if (Number(this.getAvailableBalanceAbsolute.toFixed(2)) < 0) {
            return "You cannot allocate more than the available balance";
        }

        if (!validateTwoDecimalPlaces(amountAllocatedNumber)) {
            return "No more than two decimal places";
        }

        return "";
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
