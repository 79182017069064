import styled from "styled-components";

export const ConstructionDetail = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px 5px 15px 5px;
`;

export const ConstructionSite = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px 5px 15px 5px;
    justify-content: space-between;
`;
