import { ModelBase } from "@shoothill/core";
import { observable, computed } from "mobx";
import { InspectionsQuestionModel } from "./InspectionsQuestionModel";
import { InductionUserLookupModel } from "./InductionUserLookupModel";
import { IsNotEmpty } from "class-validator";

export class InspectionsModel extends ModelBase<InspectionsModel> {
    public id: string | null = null;
    @observable
    @IsNotEmpty({ message: "Please enter the make" })
    public make: string = "";
    @observable
    @IsNotEmpty({ message: "Please enter the model" })
    public modelType: string = "";
    @observable
    @IsNotEmpty({ message: "Please enter a serial number" })
    public serialRegistrationNo: string = "";
    @observable
    public projectId: string | null = null;
    @observable
    public inspectionTypeId: string | null = null;
    @observable
    @IsNotEmpty()
    public inspectionLocalUserId: string | null = null;
    @observable
    public inspectionNumber: string = "TBD";
    @observable
    public projectReference: string = "";
    @observable
    public projectName: string = "";
    @observable
    @IsNotEmpty()
    public signatureBase64: string = "";
    //@observable
    // @IsNotEmpty()
    // public inspectionUser: InductionUserLookupModel | null = null;
    @observable
    public questionsModel: InspectionsQuestionModel[] = [];

    @observable
    public inspectionTypeName: string | null = null;

    public get inspectionInductionUserId() {
        const result = this.inspectionUsers.find((u) => u.id === this.inspectionLocalUserId);

        return result?.groupType === "INDUCTION_USER" ? result!.id : null;
    }

    public get inspectionWorkerUserId() {
        const result = this.inspectionUsers.find((u) => u.id === this.inspectionLocalUserId);

        return result?.groupType === "WORKER_USER" ? result!.id : null;
    }

    @observable
    public inspectionInductionUsers = observable<InductionUserDTO>([]);

    @observable
    public inspectionWorkerUsers = observable<InductionUserDTO>([]);

    @computed
    public get inspectionUsers() {
        const groupInuductionUsers = this.inspectionInductionUsers.map((u) => {
            return {
                groupType: "INDUCTION_USER",
                groupDisplayName: "Inducted",
                ...u,
            };
        });

        const groupWorkerUsers = this.inspectionWorkerUsers.map((u) => {
            return {
                groupType: "WORKER_USER",
                groupDisplayName: "Darwin staff",
                ...u,
            };
        });

        return [...groupInuductionUsers, ...groupWorkerUsers];
    }

    constructor() {
        super();
    }

    fromDto(model: any): void {
        this.id = model.id;
        this.make = model.make;
        this.modelType = model.modelType;
        this.serialRegistrationNo = model.serialRegistrationNo;
        this.projectId = model.projectId;
        this.inspectionTypeId = model.inspectionTypeId;
        this.inspectionLocalUserId = model.inspectionInductionUserId ? model.inspectionInductionUserId : model.inspectionWorkerUserId;
        //this.inspectionInductionUserId = model.inspectionInductionUserId;
        //this.inspectionWorkerUserId = model.inspectionWorkerUserId;
        this.projectReference = model.projectReference;
        this.projectName = model.projectName;
        this.signatureBase64 = model.signatureBase64;
        this.inspectionTypeName = model.inspectionTypeName;
    }
    toDto(): any {
        return {
            id: this.id,
            make: this.make,
            modelType: this.modelType,
            serialRegistrationNo: this.serialRegistrationNo,
            projectId: this.projectId,
            inspectionTypeId: this.inspectionTypeId,
            inspectionInductionUserId: this.inspectionInductionUserId,
            inspectionWorkerUserId: this.inspectionWorkerUserId,
            inspectionNumber: this.inspectionNumber,
            projectReference: this.projectReference,
            projectName: this.projectName,
            signatureBase64: this.signatureBase64,
            questions: this.questionsModel.map((q) => q.toDto()),
        };
    }
}

export interface InductionUserDTO {
    id: string;
    displayName: string;
}
