import { IconButton } from "@material-ui/core";
import React from "react";

import EditIcon from "Content/EditIcon";

interface IProps {
    onClick?: () => void;
}

export const EditView: React.FC<IProps> = (props) => {
    /**
     * This is required should you host this component on a table row
     * that is clickable.
     */
    const onLinkClick = (event: any) => {
        event.stopPropagation();
        props.onClick?.();
    };

    return (
        <IconButton onClick={onLinkClick} size="small" title="Edit">
            <EditIcon />
        </IconButton>
    );
};
