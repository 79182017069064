import { computed } from "mobx";
import { FieldType, ViewModelBase } from "@shoothill/core";
import { InvoicingStatusTypeModel } from "./InvoicingStatusTypeModel";
import { InvoicingListModel } from "./InvoicingListModel";
import moment from "moment";
import { formatCurrencyFromPounds, formatDate } from "Utils/Format";
import { InvoiceStatusEnum } from "Views/Invoice/Form/Details/InvoiceDetailsModel";

export class InvoicingListItemViewModel extends ViewModelBase<InvoicingListModel> {
    constructor(item: InvoicingListModel, statusType: InvoicingStatusTypeModel) {
        super(item);
        this.statusTypeModel = statusType;
    }

    //private model: InvoicingListModel;
    private statusTypeModel: InvoicingStatusTypeModel;

    @computed
    public get id() {
        return this.model.id;
    }

    @computed
    public get invoiceNumber() {
        return this.model.invoiceNumber;
    }

    @computed
    public get invoiceDate() {
        return this.model.invoiceDate;
    }

    @computed
    public get invoiceDateFormatted() {
        return formatDate(this.model.invoiceDate !== null ? this.model.invoiceDate.toString() : "");
    }

    @computed
    public get supplierName() {
        return this.model.supplierName;
    }

    @computed
    public get invoiceValue() {
        return this.model.invoiceValue;
    }

    @computed
    public get approverRoleName() {
        return this.model.approverRoleName;
    }

    @computed
    public get projectName() {
        return this.model.projectName;
    }

    @computed
    public get approvedDate() {
        return this.model.approvedDate;
    }

    @computed
    public get postedDate() {
        return this.model.postedDate;
    }

    @computed
    public get approvedDateFormatted() {
        return this.model.approvedDate ? moment.utc(this.model.approvedDate).format("DD/MM/YY") : "";
    }

    @computed
    public get postedDateFormatted() {
        return this.model.postedDate ? moment.utc(this.model.postedDate).format("DD/MM/YY") : "";
    }

    @computed
    public get statusName() {
        return this.statusTypeModel.displayName;
    }

    @computed
    public get statusNameFormatted() {
        return this.statusTypeModel.type === InvoiceStatusEnum.Disputed
            ? `${this.model.disputedStatusCodeName} -  ${this.statusTypeModel.displayName.toUpperCase()}`
            : this.statusTypeModel.displayName.toUpperCase();
    }

    @computed
    public get formattedInvoiceValue() {
        return formatCurrencyFromPounds(this.model.invoiceValue);
    }

    @computed
    public get statusColor() {
        return this.statusTypeModel.color;
    }

    @computed
    public get statusTextColor() {
        return this.statusTypeModel.textColor;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public async isFieldValid(fieldName: keyof FieldType<InvoicingListModel>): Promise<boolean> {
        return true;
    }
}
