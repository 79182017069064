///RequisitionRequestItemGroupViewBase

import { Box, Button, Icon, IconButton, InputAdornment, Typography } from "@material-ui/core";
import { Cell, Grid } from "@shoothill/core";
import { pxToRem } from "Globals/Styles/AppTheme";
import { useObserver } from "mobx-react-lite";
import React from "react";
import styled from "styled-components";

import DeleteLogo from "Content/Bin.svg";
import { PrimaryButton } from "Components/Buttons/Buttons";
import { DarwinInput, DarwinMoneyInput } from "Globals/Styles/Control/DarwinInput";
import { DashedDivider, H2TextSolidDivider } from "Views/PurchaseOrder/Form/Views/Dividers";
import { RequisitionRequestItemGroupViewModel } from "./RequisitionRequestItemGroupViewModel";
import { RequisitionRequestItemGroupModel } from "./RequisitionRequestItemGroupModel";
import { RequisitionRequestItemModel } from "./RequisitionRequestItemModel";
import { BudgetForecastTable } from "./BudgetForecastTable";
import { SnackBar } from "Components/SnackBar/SnackBar";

interface IProps {
    className?: string;
    viewModel: RequisitionRequestItemGroupViewModel;
    isDisabled: boolean;
    canShowBudgetForecast: boolean;
    isCentral: boolean;
}

const RequisitionRequestItemGroupViewBase: React.FC<IProps> = (props) => {
    // #region Code Behind

    const COLUMNS8 = "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr";

    // #endregion Code Behind

    return useObserver(() => (
        <Box className={props.className} key={props.viewModel.key} id={props.viewModel.model.id ? `group-${props.viewModel.model.id}` : ""}>
            <GroupHeader>
                <Typography variant="h2">{props.viewModel.displayName}</Typography>
            </GroupHeader>
            <H2TextSolidDivider />
            <GroupTable>
                {props.viewModel.requisitionRequestItems.map((ri, index) => (
                    <GroupRow key={`order-line-row-${index}`}>
                        <Grid columnGap={30} rowGap={0} tc={COLUMNS8} dc={COLUMNS8} alignItems={"center"}>
                            {/* Group of 8 columns */}
                            <Cell ts={4} ds={4}>
                                <DarwinInput<RequisitionRequestItemModel>
                                    type="text"
                                    label=""
                                    placeholder="Description"
                                    validateOnBlur={true}
                                    viewModel={ri}
                                    fieldName="itemDescription"
                                    shrink={true}
                                    editMode={!props.isDisabled && !ri.isStockOrderLine}
                                />
                            </Cell>
                            <Cell ts={1} ds={1}>
                                {(() => {
                                    switch (true) {
                                        case !props.isDisabled && !ri.isStockOrderLine:
                                            return (
                                                <DarwinInput<RequisitionRequestItemModel>
                                                    type="number"
                                                    label=""
                                                    placeholder=""
                                                    validateOnBlur={true}
                                                    viewModel={ri}
                                                    fieldName="units"
                                                    shrink={true}
                                                    maxLength={32}
                                                    editMode={!props.isDisabled}
                                                    onChange={props.viewModel.handleLineChange}
                                                />
                                            );

                                        case !props.isDisabled && ri.isStockOrderLine:
                                            return (
                                                <GroupCell>
                                                    <PrimaryButton
                                                        canExecute={ri.canVoidItemUnits}
                                                        displayName="Void"
                                                        execute={ri.apiVoidItemUnits}
                                                        style={{ marginRight: "15px" }}
                                                    />
                                                    {ri.model.units}
                                                </GroupCell>
                                            );

                                        default:
                                            return <GroupCell>{ri.model.units}</GroupCell>;
                                    }
                                })()}
                            </Cell>
                            <Cell ts={1} ds={1}>
                                {(() => {
                                    switch (true) {
                                        case !props.isDisabled && !ri.isStockOrderLine:
                                            return (
                                                <DarwinMoneyInput<RequisitionRequestItemModel>
                                                    type="number"
                                                    label=""
                                                    placeholder=""
                                                    validateOnBlur={true}
                                                    viewModel={ri}
                                                    fieldName="unitPrice"
                                                    shrink={true}
                                                    maxLength={11}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                                    }}
                                                    editMode={!props.isDisabled && !ri.isStockOrderLine}
                                                    onChange={props.viewModel.handleLineChange}
                                                />
                                            );

                                        case props.isDisabled || ri.isStockOrderLine:
                                            return <GroupCell>{ri.unitPrice}</GroupCell>;
                                    }
                                })()}
                            </Cell>
                            <Cell ts={1} ds={1}>
                                <GroupCell>{ri.total}</GroupCell>
                            </Cell>
                            <Cell ts={1} ds={1}>
                                {ri.canDelete && (
                                    <ButtonGroupCell>
                                        <IconButton
                                            onClick={() => {
                                                ri.setDeleted();
                                                props.viewModel.handleLineChange();
                                                props.viewModel.handleLineDelete();
                                            }}
                                            disabled={props.isDisabled || !ri.canDelete}
                                        >
                                            <Icon>
                                                <img alt="edit" src={DeleteLogo} />
                                            </Icon>
                                        </IconButton>
                                    </ButtonGroupCell>
                                )}
                            </Cell>

                            {/* Group of 8 columns */}
                            <Cell ts={8} ds={8}>
                                <DashedDivider />
                            </Cell>
                        </Grid>
                        <SnackBar
                            messageText={ri.snackMessage}
                            messageType={ri.snackType}
                            active={ri.snackbarState}
                            closeOption={() => ri.setSnackbarState(false)}
                            autoHideDuration={5000}
                        />
                    </GroupRow>
                ))}
                <Grid columnGap={30} rowGap={0} tc={COLUMNS8} dc={COLUMNS8}>
                    {/* Group of 8 columns */}
                    <Cell ts={8} ds={8}>
                        <GroupTotal>Sub-Total: {props.viewModel.total}</GroupTotal>
                    </Cell>
                </Grid>
            </GroupTable>
            {!props.isCentral && (
                <NewFutureSpendSection>
                    <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
                        {/* Group of 8 columns */}
                        <Cell ts={1} ds={1}>
                            <NewFutureSpendLabel>Suggested new future spend</NewFutureSpendLabel>
                        </Cell>
                        <Cell ts={1} ds={1} style={{ display: props.isDisabled ? "flex" : "block", alignItems: props.isDisabled ? "center" : "initial" }}>
                            <DarwinMoneyInput<RequisitionRequestItemGroupModel>
                                type="number"
                                label=""
                                placeholder=""
                                validateOnBlur={true}
                                viewModel={props.viewModel}
                                fieldName="newFutureSpend"
                                shrink={true}
                                maxLength={32}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                }}
                                editMode={!props.isDisabled}
                            />
                        </Cell>
                        <Cell ts={6} ds={6} style={{ display: props.isDisabled ? "flex" : "block", alignItems: props.isDisabled ? "center" : "initial" }}>
                            <DarwinInput<RequisitionRequestItemGroupModel>
                                type="text"
                                label=""
                                placeholder="Notes"
                                validateOnBlur={true}
                                viewModel={props.viewModel}
                                fieldName="note"
                                shrink={true}
                                maxLength={256}
                                editMode={!props.isDisabled}
                            />
                        </Cell>
                        <Cell ts={1} ds={1}></Cell>
                    </Grid>
                </NewFutureSpendSection>
            )}

            {props.canShowBudgetForecast && <BudgetForecastTable budgetForecast={props.viewModel.getBudgetForecastTotalFormatted} isTotal={false} />}
        </Box>
    ));
};

export const RequisitionRequestItemGroupView = styled(RequisitionRequestItemGroupViewBase)`
    background-color: #ededed;
    display: flex;
    flex-direction: column;
`;

// #region Group Table

const GroupHeader = styled(Box)`
    margin: ${pxToRem(20)} ${pxToRem(16)} ${pxToRem(2)} ${pxToRem(16)};
`;

const GroupTable = styled(Box)`
    margin: ${pxToRem(0)} ${pxToRem(16)} ${pxToRem(16)} ${pxToRem(16)};
`;

const GroupRow = styled.div`
    .MuiFormHelperText-root.Mui-error {
        display: none; // Hide the error message to prevent the styling from breaking.
    }
`;

const GroupCell = styled(Box)`
    font-size: ${pxToRem(12)};
    padding: ${pxToRem(16)} 0;
`;

const ButtonGroupCell = styled(Box)`
    display: flex;
    justify-content: end;
`;

const GroupTotal = styled(Box)`
    background-color: #ced4da;
    display: flex;
    font-size: ${pxToRem(14)};
    font-weight: bold;
    justify-content: end;
    margin-top: ${pxToRem(-1)};
    padding: ${pxToRem(4)} ${pxToRem(16)};
`;

// #endregion Group Table

// #region New Future Spend

const NewFutureSpendSection = styled(Box)`
    margin: ${pxToRem(24)} ${pxToRem(16)} ${pxToRem(0)} ${pxToRem(16)};
`;

const NewFutureSpendLabel = styled(Box)`
    font-size: ${pxToRem(12)};
    font-weight: bold;
    padding: ${pxToRem(5.6)} 0;
`;

// #endregion New Future Spend
