import { FieldType, ViewModelBase } from "@shoothill/core";
import { action, computed, observable } from "mobx";

import { AppUrls } from "AppUrls";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { MaterialPriceDetailsModel } from "./MaterialPriceDetailsModel";
import { MaterialSupplier } from "../Shared/MaterialSupplier";
import { formatCurrencyFromPounds } from "Utils/Format";
import { isNullOrUndefined } from "Utils/Utils";

export class MaterialPriceDetailsViewModel extends ViewModelBase<MaterialPriceDetailsModel> {
    public server: ServerViewModel = new ServerViewModel();

    private materialId: string;
    private parentCloseAction: (refreshPage: boolean) => void;

    constructor(materialId: string, closeAction: (refreshPage: boolean) => void) {
        super(new MaterialPriceDetailsModel(), false);

        this.materialId = materialId;
        this.parentCloseAction = closeAction;

        this.setDecorators(MaterialPriceDetailsModel);

        // Load material price details.
        this.apiLoadPriceDetailsAsync();
    }

    // #region Formatted Labels

    @computed
    public get currentEstimatedPrice() {
        if (isNullOrUndefined(this.model.currentEstimatedPrice)) {
            return "Estimating price:";
        }

        return `Estimating price (${formatCurrencyFromPounds(this.model.currentEstimatedPrice!, 2, 4)}):`;
    }

    @computed
    public get currentPrice() {
        if (isNullOrUndefined(this.model.currentPrice)) {
            return "Purchase price:";
        }

        return `Purchase price (${formatCurrencyFromPounds(this.model.currentPrice!, 2, 4)}):`;
    }

    // #endregion Formatted Labels

    // #region Suppliers

    @computed
    public get supplier() {
        return this.model.supplier;
    }

    @computed
    public get suppliers() {
        return this.model.suppliers;
    }

    @action
    public setSupplier = (value: MaterialSupplier | null): void => {
        this.model.supplier = value ?? MaterialPriceDetailsModel.DEFAULT_SUPPLIER;
        this.isFieldValid("supplier");
    };

    // #endregion Suppliers

    // #region Actions

    @action
    public cancel = () => this.parentCloseAction(false);

    // #endregion Actions

    // #region Api Actions

    @action
    public apiLoadPriceDetailsAsync = async (): Promise<void> => {
        await this.server.query<any>(
            () => this.Get(AppUrls.Server.Stock.Material.GetMaterialPriceDetailsWithRelatedByMaterialId.replace("{materialid}", this.materialId)),
            (result) => this.model.fromDto(result),
            "Error whilst loading the material data",
        );

        if (this.server.HaveValidationMessage) {
            this.setSnackMessage(this.server.ValidationMessage);
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }
    };

    @action
    public apiSavePriceDetailsAsync = async (): Promise<void> => {
        await this.server.command<any>(
            () => this.Post(AppUrls.Server.Stock.Material.RequestUpdateMaterialPriceDetails.replace("{materialid}", this.materialId), this.model.toDto()),
            (result) => this.parentCloseAction(true),
            this.isModelValid,
            "Error whilst saving the material data",
        );

        if (this.server.HaveValidationMessage) {
            this.setSnackMessage(this.server.ValidationMessage);
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }
    };

    // #endregion Api Actions

    // #region Snack Bar

    public SNACKERROR = "error";

    @observable
    public snackbarState = false;

    @observable
    public snackType = "";

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public snackMessage = "";

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    // #endregion Snack Bar

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<MaterialPriceDetailsModel>): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boliderplate
}
