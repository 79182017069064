import { ModelBase } from "@shoothill/core";
import { action, observable } from "mobx";

import { InductionType } from "Globals/Models/Enums/InductionType";
import { InductionSectionType } from "Globals/Models/Enums/InductionSectionType";
import { QualificationModel } from "../Common/QualificationModel";

export class Step3Model extends ModelBase<Step3Model> {
    // #region Defaults

    public static readonly DEFAULT_INDUCTIONSECTIONTYPE = null;
    public static readonly DEFAULT_QUALIFICATIONS = [];

    // #endregion Defaults

    // #region Properties

    @observable
    public plantEquipmentInductionSectionType: InductionSectionType | null = Step3Model.DEFAULT_INDUCTIONSECTIONTYPE;

    @observable
    public qualifications = observable<QualificationModel>(Step3Model.DEFAULT_QUALIFICATIONS);

    // #endregion Properties

    constructor() {
        super();
    }

    // #region Methods

    @action
    public fromDto(dto: any): void {
        throw new Error("Method not implemented.");
    }

    public toDto(): any {
        throw new Error("Method not implemented.");
    }

    public toDependentDto(inductionType: InductionType): any {
        let qualifications = [];

        switch (true) {
            case inductionType.IsOfType(InductionType.Full):
                qualifications = this.qualifications.map((q) => q.toDto());
                break;

            case inductionType.IsOfType(InductionType.Visitor):
            default:
                qualifications = Step3Model.DEFAULT_QUALIFICATIONS;
                break;
        }

        return {
            qualifications: qualifications,
        };
    }

    // #endregion Methods
}
