import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";

import { CentralListViewModel } from "./CentralListViewModel";
import { DarwinPage } from "Globals/Styles/AppStyling";
import { IEView } from "Views/Project/Commercial/IE.View";
import { Loader, useRouter } from "@shoothill/core";

export const CentralListView: React.FunctionComponent = () => {
    const [viewModel] = useState(CentralListViewModel.Instance);

    const { match } = useRouter();
    const { ieid } = match.params as any;

    //useEffect below only gets run on initial render
    useEffect(() => {
        viewModel.getLatestCentralProjectView(ieid);
        return () => {
            viewModel.cleanUp();
        };
    }, []);

    return useObserver(() => (
        <DarwinPage>
            {viewModel.hasIEId && !viewModel.isLoadingIE && <IEView ieId={viewModel.ieId} isCentral={true} />}
            {!(viewModel.hasIEId && !viewModel.isLoadingIE) && <Loader />}
        </DarwinPage>
    ));
};
