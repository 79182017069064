import React from "react";
import { observer } from "mobx-react-lite";
import { Step3ViewModel } from "./Step3ViewModel";
import { SHBox } from "../../../Components/Box";
import { WizardButtonsView } from "../WizardButtons";
import { QualificationView } from "../Common/QualificationView";
import { DashedDivider } from "../../PurchaseOrder/Form/Views/Dividers";
import { AddOtherView } from "../Common/AddOtherView";
import { Loader } from "@shoothill/core";
import { InductionViewModel } from "../InductionViewModel";

interface Props {
    viewModel: Step3ViewModel;
    parentViewModel: InductionViewModel;
}
export const Step3View: React.FC<Props> = observer((props: Props) => {
    const { viewModel } = props;

    if (props.parentViewModel.isStageLoading) {
        return (
            <>
                <Loader />
            </>
        );
    }

    return (
        <SHBox height={"85vh"}>
            <SHBox m={3} pb={"100px"}>
                <p>What Plant & Equipment Training/Qualifications do you have (please do not add skills training here)?</p>
                {viewModel.qualificationViewModels.map((qualificationViewModel, index) => {
                    return (
                        <React.Fragment key={index}>
                            <QualificationView viewModel={qualificationViewModel} lastItem={index === viewModel.qualificationViewModels.length - 1} />
                            <DashedDivider gutterBottom />
                        </React.Fragment>
                    );
                })}
                <AddOtherView onAdd={viewModel.addOther} />
            </SHBox>
            <WizardButtonsView nextStep={viewModel.nextStep} previousStep={viewModel.previousStep} canExecute={viewModel.canExecuteNextStep} />
        </SHBox>
    );
});
