import React from "react";
import { Header } from "Views/Header/Header";
import { UnsupportedBrowserView } from "Views/UnsupportedBrowser/UnsupportedBrowserView";
import { DarwinLayoutWrapper, DarwinBreadcrumb, DarwinMain, Container } from "./DarwinLayout.style";
import { BreadcrumbView } from "Views/Breadcrumb/BreadcrumbView";
import { NotificationBar } from "Components/NotificationBar/NotificationBar";
import { StoresInstance } from "../../Stores";
import { RoleTypeEnum } from "../../Stores/Domain/Admin";

//EN: use empty generic type to get default react props
export const DarwinLayout: React.FC<{}> = (props) => {
    if (!props) return <>Props undefined</>;
    const isSiteTablet: boolean = StoresInstance.Domain.AccountStore.isInRole(RoleTypeEnum.SiteTablet);

    return (
        <>
            {window.IE11 ? (
                <UnsupportedBrowserView />
            ) : (
                <>
                    {isSiteTablet ? (
                        <Container>
                            <Header />
                            <NotificationBar type={"pending"} />
                            <DarwinLayoutWrapper>
                                <div className="pageTemplateBlock">{props && <DarwinMain>{props.children}</DarwinMain>}</div>
                            </DarwinLayoutWrapper>
                        </Container>
                    ) : (
                        <Container>
                            <Header />
                            <NotificationBar type={"pending"} />
                            <DarwinLayoutWrapper>
                                <DarwinBreadcrumb className="sideMenuBlock">
                                    <BreadcrumbView />
                                </DarwinBreadcrumb>
                                <div className="pageTemplateBlock">{props && <DarwinMain>{props.children}</DarwinMain>}</div>
                            </DarwinLayoutWrapper>
                        </Container>
                    )}
                </>
            )}
        </>
    );
};
