// Libraries
import React from "react";
import { useObserver } from "mobx-react-lite";
import { Icon, IconButton, TextField } from "@material-ui/core";

// Custom
import { VariationItemViewModel } from "./VariationItemViewModel";

// Styling & Images
import DeleteLogo from "Content/Bin.svg";
import styled from "styled-components";

interface ITableProps {
    itemViewModel: VariationItemViewModel;
    rowIndex: number;
    isDisabled: boolean;
}

export const VariationItemNonCollapsible: React.FunctionComponent<ITableProps> = (props) => {
    const { itemViewModel, rowIndex } = props;

    const handleName = (): string => {
        if (itemViewModel.model.descriptionId === -2 && itemViewModel.model.descriptionOther !== null) {
            return itemViewModel.model.descriptionOther;
        } else {
            return itemViewModel.model.displayName ? itemViewModel.model.displayName : "";
        }
    };

    return useObserver(() => (
        <>
            <li className="item" key={"item_" + rowIndex}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} title={handleName()}>
                    <ItemNameContainer>
                        <span>{handleName()}</span>
                    </ItemNameContainer>
                </div>
                <div className="right">
                    <TextField
                        id=""
                        type="number"
                        value={itemViewModel.model.quantity}
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => itemViewModel.setQuantity(event.target.value)}
                        fullWidth
                        error={itemViewModel.getError("quantity") !== ""}
                        placeholder={"0"}
                        disabled={props.isDisabled}
                    />
                </div>
                <div className="right">
                    <TextField
                        id=""
                        type="text"
                        value={itemViewModel.model.variationUnitName}
                        onChange={(event: any) => itemViewModel.setValue("variationUnitName", event.target.value)}
                        fullWidth
                        error={itemViewModel.getError("variationUnitName") !== ""}
                        placeholder={"enter a unit name"}
                        disabled={props.isDisabled}
                    />
                </div>
                <div className="right">
                    <TextField
                        id=""
                        type="number"
                        value={itemViewModel.model.rate}
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => itemViewModel.setRate(event.target.value)}
                        fullWidth
                        error={itemViewModel.getError("rate") !== ""}
                        placeholder={"0.00"}
                        disabled={props.isDisabled}
                    />
                </div>
                <div className="right">{itemViewModel.lineTotalFormatted}</div>
                <div className="right">{itemViewModel.committedCostFormatted}</div>
                <div className="right">
                    <TextField
                        id=""
                        type="number"
                        value={itemViewModel.model.futureSpend}
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => itemViewModel.setFutureSpend(event.target.value)}
                        fullWidth
                        error={itemViewModel.getError("futureSpend") !== ""}
                        placeholder={"0.00"}
                        disabled={props.isDisabled}
                    />
                </div>
                <div className="right">{itemViewModel.varianceFormatted}</div>
                <div className="right">
                    {!props.isDisabled && itemViewModel.canDelete && (
                        <IconButton
                            onClick={() => {
                                itemViewModel.setDeleted();
                            }}
                            disabled={props.isDisabled || !itemViewModel.canDelete}
                        >
                            <Icon>
                                <img alt="edit" src={DeleteLogo} />
                            </Icon>
                        </IconButton>
                    )}
                </div>
            </li>
        </>
    ));
};

export const ItemNameContainer = styled.div`
    display: "flex";
    flex-direction: "row";
    align-items: "center";

    > span {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
`;
