import { observable, runInAction } from "mobx";
import { ViewModelBase, FieldType } from "@shoothill/core";
import { DailyDiaryModel, DailyDiaryModelDTO } from "./DailyDiaryModel";
import { ServerViewModel } from "../../../../Globals/ViewModels/ServerViewModel";
import { AppUrls } from "../../../../AppUrls";
import { viewModelModelLink } from "../../../../Utils/Utils";
import { FireExitViewModel } from "../Audits/EndOfWeek/FireExitViewModel";
import { DailyDiaryQuestionViewModel } from "./DailyDiaryQuestionViewModel";

export class DailyDiaryViewModel extends ViewModelBase<DailyDiaryModel> {
    public server: ServerViewModel = new ServerViewModel();
    public date: Date | null = null;
    public dailyDiaryQuestionViewModels = observable<DailyDiaryQuestionViewModel>([]);
    constructor(model = new DailyDiaryModel()) {
        super(model);
        this.setDecorators(DailyDiaryModel);
    }

    public dailyDiaryLinkObserverDispose = viewModelModelLink(this.model.dailyDiaryQuestionModels, this.dailyDiaryQuestionViewModels, DailyDiaryQuestionViewModel);

    public getDailyDiary = async () => {
        return this.server.query<any>(
            () => this.Get<DailyDiaryModelDTO>(`${AppUrls.Server.Projects.Construction.DailyDiary.Load}?projectId=${this.model.projectId}&date=${this.date?.toISOString()}`),
            (result) => {
                runInAction(() => {
                    this.model.fromDto(result);
                });
            },
        );
    };
    public async isFieldValid(fieldName: keyof FieldType<DailyDiaryModel>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }
    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
