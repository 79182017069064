import { action, computed, observable } from "mobx";
import { ModelBase } from "@shoothill/core";
import { InvoicePurchaseOrderItemDTO, InvoicePurchaseOrderItemModel, UpsertInvoicePurchaseOrderItemDTO } from "./InvoicePurchaseOrderItemModel";

export class InvoiceGroupModel extends ModelBase<InvoiceGroupModel, any> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Constants and Defaults

    public static readonly DEFAULT_ID = null;
    public static readonly DEFAULT_REQUISITIONREQUESTID = "";
    public static readonly DEFAULT_GROUPNAME = "";
    public static readonly DEFAULT_INVOICEVALUE = "";
    public static readonly DEFAULT_ALREADYALLOCATED = "";
    public static readonly DEFAULT_AVAILABLEBALANCE = "";
    public static readonly DEFAULT_AMOUNTALLOCATED = "";
    public static readonly DEFAULT_INVOICEPURCHASEORDERITEM = [];
    // public static readonly DEFAULT_ISSUBMITTED = false;
    // public static readonly DEFAULT_ISDELETED = false;

    // #endregion Constants and Defaults

    // #region Properties

    @observable
    public id: string | null = InvoiceGroupModel.DEFAULT_ID;

    @observable
    public requisitionRequestId: string = InvoiceGroupModel.DEFAULT_REQUISITIONREQUESTID;

    @observable
    public groupName: string = InvoiceGroupModel.DEFAULT_GROUPNAME;

    @observable
    public invoiceValue: string = InvoiceGroupModel.DEFAULT_INVOICEVALUE;

    @observable
    public alreadyAllocated: string = InvoiceGroupModel.DEFAULT_INVOICEVALUE;

    @observable
    public availableBalance: string = InvoiceGroupModel.DEFAULT_INVOICEVALUE;

    @observable
    public amountAllocated: string = InvoiceGroupModel.DEFAULT_INVOICEVALUE;

    @observable
    public invoicePurchaseOrderItems: InvoicePurchaseOrderItemModel[] = InvoiceGroupModel.DEFAULT_INVOICEPURCHASEORDERITEM;

    // @observable
    // public isSubmitted: boolean = InvoicePurchaseOrderModel.DEFAULT_ISSUBMITTED;

    // @observable
    // public isDeleted: boolean = InvoicePurchaseOrderModel.DEFAULT_ISDELETED;

    // #endregion Properties

    // #region Actions

    @action
    public reset = () => {
        this.id = InvoiceGroupModel.DEFAULT_ID;
        this.requisitionRequestId = InvoiceGroupModel.DEFAULT_REQUISITIONREQUESTID;
        this.groupName = InvoiceGroupModel.DEFAULT_GROUPNAME;
        this.invoiceValue = InvoiceGroupModel.DEFAULT_INVOICEVALUE;
        this.alreadyAllocated = InvoiceGroupModel.DEFAULT_ALREADYALLOCATED;
        this.availableBalance = InvoiceGroupModel.DEFAULT_AVAILABLEBALANCE;
        this.amountAllocated = InvoiceGroupModel.DEFAULT_AMOUNTALLOCATED;
        this.invoicePurchaseOrderItems = InvoiceGroupModel.DEFAULT_INVOICEPURCHASEORDERITEM;
    };

    @action
    public fromDto(dto: InvoiceGroupDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself

        for (let key in dto) {
            if (dto.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(dto[key]);
                } else if (key === "invoicePurchaseOrderItems") {
                    // Do nothing.
                } else {
                    this[key] = dto[key];
                }
            }
        }

        let processedItems: InvoicePurchaseOrderItemModel[] = [];

        for (const item of dto.invoicePurchaseOrderItems) {
            const itemToAdd = new InvoicePurchaseOrderItemModel();
            itemToAdd.fromDto(item);
            processedItems.push(itemToAdd);
        }

        this.invoicePurchaseOrderItems = [...this.invoicePurchaseOrderItems, ...processedItems];
    }

    public toDto(): any {}

    // #endregion Actions

    // #region Custom Validation

    // @computed
    // public get validateInvoiceValue(): string {
    //     // RULES
    //     // The invoice value must be defined.
    //     return this.invoiceValue === InvoicePurchaseOrderModel.DEFAULT_INVOICEVALUE ? "Please provide an invoice value" : "";
    // }

    // #endregion Custom Validation
}

// export interface UpsertInvoicePurchaseOrderDTO {
//     id: string | null;
//     requisitionRequestId: string | null;
//     invoiceValue: string | null;
//     isSubmitted: boolean;
//     isDeleted: boolean;
// }

export interface InvoiceGroupDTO {
    id: string | null;
    requisitionRequestId: string | null;
    groupName: string | null;
    invoiceValue: string | null;
    alreadyAllocated: string | null;
    availableBalance: string | null;
    amountAllocated: string | null;
    invoicePurchaseOrderItems: InvoicePurchaseOrderItemDTO[];
    // isSubmitted: boolean;
    // isDeleted: boolean;
}
