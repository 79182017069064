import { useObserver } from "mobx-react-lite";
import React from "react";
import { VariationViewModel } from "./Form/VariationViewModel";
import { ApprovalPanel } from "Components/Approval/ApprovalPanel";
import { ApprovalPanelBodySection } from "Components/Approval/ApprovalPanel.styles";
import { ApprovalHistoryView } from "Components/ApprovalHistory/ApprovalHistoryView";

interface IProps {
    viewModel: VariationViewModel;
    sideOpen: boolean;
    isFromApprovalSection: boolean;
    primaryTitle: string;
    secondaryTitle: string;
}

export const VariationApprovalPanel: React.FC<IProps> = (props: IProps) => {
    const { viewModel } = props;

    const upsertApprovalStatus = (isApproved: boolean, amendmentNote: string, requisitionStatusId: string) => {
        viewModel.upsertApprovalStatus(isApproved, amendmentNote, requisitionStatusId);
    };

    const bodyNewApprovalPanel = () => {
        return (
            <div>
                <ApprovalHistoryView viewModel={props.viewModel.approvalPanelViewModel.approvalHistoryViewModel} />
            </div>
        );
    };

    const bodyAmenderPanel = () => {
        return (
            <div>
                {props.viewModel.approvalPanelViewModel.canShowApproverNotes && (
                    <ApprovalPanelBodySection>
                        <p>{viewModel.approvalPanelViewModel.model.approverNotes}</p>
                    </ApprovalPanelBodySection>
                )}
                <ApprovalPanelBodySection>
                    <p className="keep-newline-formatting">If you have made the changes above you can either save your work or resubmit for approval here</p>
                </ApprovalPanelBodySection>
                <ApprovalHistoryView viewModel={props.viewModel.approvalPanelViewModel.approvalHistoryViewModel} />
            </div>
        );
    };

    const bodyAmendedApprovalPanel = () => {
        return (
            <div>
                <ApprovalHistoryView viewModel={props.viewModel.approvalPanelViewModel.approvalHistoryViewModel} />
            </div>
        );
    };

    const bodyDefault = () => {
        return (
            <div>
                <ApprovalHistoryView viewModel={props.viewModel.approvalPanelViewModel.approvalHistoryViewModel} />
            </div>
        );
    };

    return useObserver(() => {
        return (
            <ApprovalPanel
                sideOpen={props.sideOpen}
                upsertApprovalStatus={upsertApprovalStatus}
                bodyNewApprovalPanel={bodyNewApprovalPanel()}
                bodyAmenderPanel={bodyAmenderPanel()}
                bodyAmendedApprovalPanel={bodyAmendedApprovalPanel()}
                bodyDefault={bodyDefault()}
                canShowNewApprovalPanel={viewModel.approvalPanelViewModel.getCanShowNewApprovalPanel}
                canShowAmenderPanel={viewModel.approvalPanelViewModel.getCanShowAmenderPanel}
                canShowAmendedApprovalPanel={viewModel.approvalPanelViewModel.getCanShowAmendedApprovalPanel}
                isFromApprovalSection={props.isFromApprovalSection}
                canUpsertStatus={viewModel.approvalPanelViewModel.getCanUpsertStatus}
                primaryTitle={props.primaryTitle}
                secondaryTitle={props.secondaryTitle}
                approvedStatusId={viewModel.approvalPanelViewModel.getApprovedStatusId}
                amendRequiredStatusId={viewModel.approvalPanelViewModel.getAmendRequiredStatusId}
                rejectedStatusId={viewModel.approvalPanelViewModel.getRejectedStatusId}
                canViewFooter={viewModel.approvalPanelViewModel.getCanShowNewApprovalPanel || viewModel.approvalPanelViewModel.getCanShowAmendedApprovalPanel}
            />
        );
    });
};
