import { Button } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { isEmptyOrWhitespace, isNullOrUndefined, useObserver } from "@shoothill/core";
import React, { ReactNode } from "react";
import styled from "styled-components";

import DownloadIcon from "Content/DownloadIcon";
import TimerWhiteIcon from "Content/TimerWhiteIcon";
import { pxToRem, theme } from "Globals/Styles/AppTheme";

export interface IButtonBaseProps {
    className?: string;
    displayName?: string;
    canExecute?: boolean;
    execute: () => void;
    fullWidth?: boolean;
    style?: React.CSSProperties | undefined;
    autoFocus?: boolean;
    stopPropagation?: boolean;
    startIcon?: ReactNode;
}

// #region Button Variants

const ButtonBase: React.FC<IButtonBaseProps> = (props) => {
    // #region Code Behind

    const onClick = (event: any) => {
        props.execute();
        if (props.stopPropagation) {
            event.stopPropagation();
        }
    };

    const getIsDisabled = () => {
        return isNullOrUndefined(props.canExecute) ? false : !props.canExecute;
    };

    const getDisplayName = () => {
        return !isEmptyOrWhitespace(props.displayName) ? props.displayName : "Add";
    };

    const getAutoFocus = () => {
        return isNullOrUndefined(props.autoFocus) ? false : props.autoFocus;
    };

    // #endregion Code Behind

    return useObserver(() => (
        <Button
            className={props.className}
            color="primary"
            disabled={getIsDisabled()}
            fullWidth={props.fullWidth}
            onClick={onClick}
            variant="contained"
            style={props.style}
            autoFocus={getAutoFocus()}
            startIcon={props.startIcon}
        >
            {getDisplayName()}
        </Button>
    ));
};

export const PrimaryButton: any = styled(ButtonBase)`
    background: #0095da 0% 0% no-repeat padding-box;
    height: ${pxToRem(30)};
    padding: ${pxToRem(4)} ${pxToRem(16)};

    & > .MuiButton-label {
        font-size: ${pxToRem(12)};

        & > .MuiButton-startIcon {
            & > .MuiSvgIcon-root {
                font-size: ${pxToRem(16)};
            }
        }
    }
`;

export const DefaultButton = styled(ButtonBase)<IButtonBaseProps>`
    background: #191919 0% 0% no-repeat padding-box;
    height: ${pxToRem(30)};
    padding: ${pxToRem(4)} ${pxToRem(16)};

    & > .MuiButton-label {
        font-size: ${pxToRem(12)};

        & > .MuiButton-startIcon {
            & > .MuiSvgIcon-root {
                font-size: ${pxToRem(16)};
            }
        }
    }
`;

export const RejectButton: any = styled(ButtonBase)<IButtonBaseProps>`
    background: ${theme.palette.error.main} 0% 0% no-repeat padding-box;
    height: ${pxToRem(30)};
    padding: ${pxToRem(4)} ${pxToRem(16)};

    & > .MuiButton-label {
        font-size: ${pxToRem(12)};

        & > .MuiButton-startIcon {
            & > .MuiSvgIcon-root {
                font-size: ${pxToRem(16)};
            }
        }
    }
`;

export const DownloadButton: any = styled(ButtonBase).attrs({
    startIcon: <DownloadIcon />,
})`
    height: ${pxToRem(30)};
    padding: ${pxToRem(4)} ${pxToRem(16)};

    & > .MuiButton-label {
        font-size: ${pxToRem(12)};

        & > .MuiButton-startIcon {
            & > .MuiSvgIcon-root {
                font-size: ${pxToRem(16)};
            }
        }
    }
`;

export const TimerButton: any = styled(ButtonBase).attrs({
    startIcon: <TimerWhiteIcon />,
})`
    height: ${pxToRem(30)};
    padding: ${pxToRem(4)} ${pxToRem(16)};

    & > .MuiButton-label {
        font-size: ${pxToRem(12)};

        & > .MuiButton-startIcon {
            & > .MuiSvgIcon-root {
                font-size: ${pxToRem(16)};
            }
        }
    }
`;

// #region Button Variants

// #region Add Button Variants

const AddButtonBase: React.FC<IButtonBaseProps> = (props) => {
    // #region Code Behind

    const onClick = (event: any) => {
        props.execute();
    };

    const getIsDisabled = () => {
        return isNullOrUndefined(props.canExecute) ? false : !props.canExecute;
    };

    const getDisplayName = () => {
        return !isEmptyOrWhitespace(props.displayName) ? props.displayName : "Add";
    };

    // #endregion Code Behind

    return useObserver(() => (
        <Button
            className={props.className}
            color="primary"
            disabled={getIsDisabled()}
            fullWidth={props.fullWidth}
            onClick={onClick}
            variant="contained"
            startIcon={<AddCircleIcon />}
        >
            {getDisplayName()}
        </Button>
    ));
};

export const AddButton: any = styled(AddButtonBase)`
    height: ${pxToRem(30)};
    padding: ${pxToRem(4)} ${pxToRem(16)};

    & > .MuiButton-label {
        font-size: ${pxToRem(12)};

        & > .MuiButton-startIcon {
            & > .MuiSvgIcon-root {
                font-size: ${pxToRem(16)};
            }
        }
    }
`;

// #endregion Add Button Variants
