import { CostCategory } from "Globals/Models/Domain/CostCategory";
import { observable, computed, action } from "mobx";
import { ModelBase } from "@shoothill/core";
import { IsNotEmpty, MaxLength } from "class-validator";
import { DarwinModelBaseDTO } from "Globals/Models/Domain";
import { DefaultAddSupplierModelDTO } from "./SupplierDetailModel";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class AddSupplierModel extends ModelBase<AddSupplierModel, AddSupplierModelDTO> {
    @observable
    @IsNotEmpty({ message: "Supplier name is required!" })
    @MaxLength(128, { message: "Name length must be less than 128 characters" })
    public name: string = "";

    @observable
    public reference: string = "";

    @observable
    public costCategory: CostCategory = CostCategory.All;

    @computed
    public get getCostCategory(): string {
        return this.costCategory.toString();
    }

    @action
    public cleanUp() {
        this.name = "";
        this.costCategory = CostCategory.All;
        this.reference = "";
    }

    //fromDto is required but you can leave it blank
    fromDto(model: AddSupplierModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: AddSupplierModel): void {}

    toSupplierDetailModelDto(): AddSupplierModelDTO {
        let retVal: AddSupplierModelDTO = DefaultAddSupplierModelDTO;
        for (let key in retVal) {
            if (retVal.hasOwnProperty(key)) {
                if (retVal[key] instanceof Date) {
                    retVal[key] = new Date(this[key]);
                } else {
                    retVal[key] = this[key];
                }
            }
        }
        return retVal;
    }
}

export interface AddSupplierModelDTO extends DarwinModelBaseDTO {
    name: string;
    costCategory: CostCategory;
    reference: string;
}
