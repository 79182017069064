import { BaseModalDialogPaper } from "Components/Modal/BaseModal.styles";
import styled from "styled-components";

export const InvoiceMatchMissingAllocationErrorModalDialogPaper = styled(BaseModalDialogPaper)`
    min-width: 450px;

    .MuiDialogContent-root {
        p {
            font-size: 14px;
        }

        ul {
            font-size: 14px;
            padding-bottom: 40px;
            padding-left: 18px;
        }
    }
`;
