import React, { useEffect } from "react";
import { useObserver, useRouter } from "@shoothill/core";
import { DarwinTablePageContent, OrderTable } from "Globals/Styles/AppStyling";
import { PurchaseOrderListTableModel2 } from "./PurchaseOrderListModel";
import { AppUrls } from "AppUrls";
import { formatCurrencyFromPounds, formatDate, formatEmptyValueForTableDash } from "Utils/Format";
import DownloadDocIcon from "Content/fileIcon.svg";
import { DarwinTableV2, PaginationDirection, SortOrderDirection } from "Components/Table/DarwinTableV2";
import { ColumnHelper, createColumnHelper } from "@tanstack/react-table";
interface IPurchaseOrderTableViewProps {
    data: PurchaseOrderListTableModel2[];
    isLoading: boolean;
    downloadPDF: (id: string) => Promise<void>;
    totalCount: number;
    pageNumber: number;
    pageCount: number;
    pageSize: number;
    handleSorting: (columnName: string, orderBy: SortOrderDirection) => Promise<void>;
    handlePageChange: (pageChange: PaginationDirection) => Promise<void>;
    handlePageValueChange: (pageNumber: number) => Promise<void>;
    handleRowsPerPageChange: (pageSize: number) => Promise<void>;
    defaultSortColumn: string;
    defaultSortDirection: SortOrderDirection | undefined;
}

export const AllPurchaseOrderTableView: React.FunctionComponent<IPurchaseOrderTableViewProps> = (props: IPurchaseOrderTableViewProps) => {
    const { history } = useRouter();
    //useEffect below only gets run on initial render
    useEffect(() => {
        // eslint-disable-next-line prettier/prettier
        return () => {};
    }, []);

    const handleRowClick = (rowId: string | number): void => {
        const id = rowId as string;
        history.push(AppUrls.Client.PurchaseOrder.View.replace(":poid", id));
    };

    const handleGenerateAllPurchaseOrderPDF = async (rowId: string | number, e: any): Promise<void> => {
        e.stopPropagation();
        e.preventDefault();
        const id = rowId as string;
        await props.downloadPDF(id);
    };

    const columnHelper: ColumnHelper<PurchaseOrderListTableModel2> = createColumnHelper<PurchaseOrderListTableModel2>();

    const columns = [
        columnHelper.accessor("poNumberFormatted", {
            header: () => "PO no.",
            cell: (info) => <>{formatEmptyValueForTableDash(info.getValue())}</>,
            enableResizing: false,
        }),
        columnHelper.accessor("revision", {
            header: () => "Revision",
            cell: (info) => <>{formatEmptyValueForTableDash(info.getValue())}</>,
            size: 20,
            enableResizing: false,
        }),
        columnHelper.accessor("itemDescription", {
            header: () => "Purchase order description",
            cell: (info) => <>{formatEmptyValueForTableDash(info.getValue())}</>,
            size: 300,
            enableResizing: false,
        }),
        columnHelper.accessor("projectName", {
            header: () => "Project",
            cell: (info) => <>{formatEmptyValueForTableDash(info.getValue())}</>,
            enableResizing: false,
        }),
        columnHelper.accessor("raisedBy", {
            header: () => "Raised by",
            cell: (info) => <>{formatEmptyValueForTableDash(info.getValue())}</>,
            enableResizing: false,
        }),
        columnHelper.accessor("createdDate", {
            header: () => "PO Date",
            cell: (info) => <>{formatEmptyValueForTableDash(formatDate(info.getValue() !== null ? info.getValue()!.toString() : ""))}</>,
            size: 100,
            enableResizing: false,
        }),
        columnHelper.accessor("dateFrom", {
            header: () => "Start Date",
            cell: (info) => <>{formatEmptyValueForTableDash(formatDate(info.getValue() !== null ? info.getValue()!.toString() : ""))}</>,
            size: 100,
            enableResizing: false,
        }),
        columnHelper.accessor("dateTo", {
            header: () => "Completion Date",
            cell: (info) => <>{formatEmptyValueForTableDash(formatDate(info.getValue() !== null ? info.getValue()!.toString() : ""))}</>,
            size: 100,
            enableResizing: false,
        }),
        columnHelper.accessor("supplierName", {
            header: () => "Supplier",
            cell: (info) => <>{formatEmptyValueForTableDash(info.getValue())}</>,
            enableResizing: false,
        }),
        columnHelper.accessor("committedCost", {
            header: () => "Value",
            cell: (info) => <div style={{ textAlign: "right" }}>{formatCurrencyFromPounds(info.getValue())}</div>,
            enableResizing: false,
        }),
        columnHelper.accessor("availableBalance", {
            header: () => "Available balance",
            cell: (info) => <div style={{ textAlign: "right" }}>{formatCurrencyFromPounds(info.getValue())}</div>,
            enableResizing: false,
        }),
        columnHelper.accessor("dateRequired", {
            header: () => "Delivery required",
            cell: (info) => <>{formatEmptyValueForTableDash(formatDate(info.getValue() !== null ? info.getValue()!.toString() : ""))}</>,
            size: 100,
            enableResizing: false,
        }),
        columnHelper.accessor("poDocument", {
            header: () => "",
            cell: (info) => (
                <div className="table-svg-container">
                    <img src={DownloadDocIcon} onClick={(e: any) => handleGenerateAllPurchaseOrderPDF(info.row.original.id, e)} />
                </div>
            ),
            enableSorting: false,
            size: 20,
            enableResizing: false,
        }),
    ];

    const renderPage = () => {
        return (
            <div style={{ width: "100%", paddingTop: 30 }}>
                <DarwinTablePageContent>
                    <OrderTable>
                        <DarwinTableV2
                            columns={columns}
                            data={props.data}
                            onRowClick={handleRowClick}
                            onSortChange={props.handleSorting}
                            onChangeRowPerPage={props.handleRowsPerPageChange}
                            onChangePage={props.handlePageChange}
                            onChangePageNumber={props.handlePageValueChange}
                            initialSortColumn={props.defaultSortColumn}
                            initialSortDirection={props.defaultSortDirection}
                            showPagination={true}
                            totalRowCount={props.totalCount}
                            totalPageCount={props.pageCount}
                            pageNumber={props.pageNumber}
                            pageSize={props.pageSize}
                            isProcessing={props.isLoading}
                        />
                    </OrderTable>
                </DarwinTablePageContent>
            </div>
        );
    };

    return useObserver(() => renderPage());
};
