import { ModelBase } from "@shoothill/core";
import { action, observable } from "mobx";

export class InvoiceDisputedStatusCodeModel extends ModelBase<InvoiceDisputedStatusCodeModel, InvoiceDisputedStatusCodeDTO> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Defaults and Constants

    public static readonly DEFAULT_ID = "";
    public static readonly DEFAULT_CODE = "";
    public static readonly DEFAULT_DISPLAYNAME = "";
    public static readonly DEFAULT_TYPE = 0;
    public static readonly DEFAULT_GROUPTYPE = 1;
    public static readonly DEFAULT_RESETTYPE = 1;

    // #endregion Defaults and Constants

    // #region Observables

    @observable
    public id: string = InvoiceDisputedStatusCodeModel.DEFAULT_ID;

    @observable
    public code: string = InvoiceDisputedStatusCodeModel.DEFAULT_CODE;

    @observable
    public displayName: string = InvoiceDisputedStatusCodeModel.DEFAULT_DISPLAYNAME;

    @observable
    public type: number = InvoiceDisputedStatusCodeModel.DEFAULT_TYPE;

    @observable
    public groupType: GroupTypeEnum = InvoiceDisputedStatusCodeModel.DEFAULT_GROUPTYPE;

    @observable
    public resetType: ResetTypeEnum = InvoiceDisputedStatusCodeModel.DEFAULT_RESETTYPE;

    // #endregion Observables

    // #region Actions

    @action
    public reset(): void {
        this.id = InvoiceDisputedStatusCodeModel.DEFAULT_ID;
        this.code = InvoiceDisputedStatusCodeModel.DEFAULT_CODE;
        this.displayName = InvoiceDisputedStatusCodeModel.DEFAULT_DISPLAYNAME;
        this.type = InvoiceDisputedStatusCodeModel.DEFAULT_TYPE;
        this.groupType = InvoiceDisputedStatusCodeModel.DEFAULT_GROUPTYPE;
        this.resetType = InvoiceDisputedStatusCodeModel.DEFAULT_RESETTYPE;
    }

    @action
    public fromDto(dto: InvoiceDisputedStatusCodeDTO): void {
        this.id = dto.id;
        this.code = dto.code;
        this.displayName = dto.code + " - " + dto.description;
        this.type = dto.type;
        this.groupType = dto.groupType;
        this.resetType = dto.resetType;
    }

    public toDto() {
        throw new Error("Method not implemented.");
    }

    // #endregion Actions

    // #region Helpers

    public static fromDtos(dtos: InvoiceDisputedStatusCodeDTO[]): InvoiceDisputedStatusCodeModel[] {
        const types: InvoiceDisputedStatusCodeModel[] = [];

        for (const dto of dtos) {
            const model = new InvoiceDisputedStatusCodeModel();

            model.fromDto(dto);
            types.push(model);
        }

        return types;
    }

    // #endregion Helpers
}

export interface InvoiceDisputedStatusCodeDTO {
    id: string;
    code: string;
    description: string;
    createdDate: string;
    isDeleted: boolean;
    type: number;
    groupType: GroupTypeEnum;
    resetType: ResetTypeEnum;
    invoiceDisputedStatusId: string | null;
    invoiceDisputedSubStatusId: string | null;
}

export enum GroupTypeEnum {
    CannotAllocateInvoice = 1,
    CanAllocateInvoice = 2,
    InDispute = 3,
    AllocatedAndAwaitingApproval = 4,
    HasBeenPaid = 5,
}

export enum ResetTypeEnum {
    ToDetailsForm = 1,
    ToMatchForm = 2,
}
