import { Box, FormControlLabel, FormHelperText, FormLabel, IconButton, TextField, Typography } from "@material-ui/core";
import { Cell, Grid, isEmptyOrWhitespace, useRouter } from "@shoothill/core";
import { useObserver } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Form, FormSection } from "Views/PurchaseOrder/Form/Views/Forms";
import { DashedDivider, SolidDivider } from "Views/PurchaseOrder/Form/Views/Dividers";
import { pxToRem, theme } from "Globals/Styles/AppTheme";
import { CustomRadio, CustomArrow } from "Views/Project/CustomComponents";
import { DefaultButton, PrimaryButton } from "Components/Buttons/Buttons";
import { DarwinSelect } from "Components/AutoComplete/DarwinSelect";
import { BackButtonHolder } from "Views/Project/Commercial/VariationTables/VariationGrid.Styles";
import { AppUrls } from "AppUrls";
import { formatDate, formatTime } from "Utils/Format";
import RAMSReviewFormHeader from "./RAMSReviewFormHeader";
import { Uploader } from "Components/Uploader/Uploader";
import SystemUpdateTwoToneIcon from "@material-ui/icons/SystemUpdateTwoTone";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { Signature } from "Components/Signature/Signature";
import { RAMSAttachment, RAMSReviewFormViewModel } from "./RAMSReviewFormViewModel";
import { DarwinDateSelect } from "Components/DateSelect/DarwinDateSelect";
import { RadioRAMSChoiceForm } from "./RadioRAMSChoiceForm";
import { SHBox } from "Components/Box";
import { DarwinInput } from "Globals/Styles/Control/DarwinInput";
import { RAMSChoiceViewModel } from "./RAMSChoiceViewModel";
import moment from "moment";
import { StoresContext, Stores, StoresInstance } from "Globals/Stores";
import { DarwinInputWrapper } from "Components/Form/DarwinInputWrapper";
import { RAMSModel } from "./RAMSModel";
import { RAMSReviewFormModel } from "./RAMSReviewFormModel";
interface IProps {
    className?: string;
}

export const RAMSReviewFormView: React.FC<IProps> = (props) => {
    const { match } = useRouter();
    const { history } = useRouter();
    const { projectid, ramsId } = match.params as any;
    const [viewModel] = useState(() => new RAMSReviewFormViewModel(projectid, ramsId));

    const storesContext = useContext<Stores>(StoresContext);

    useEffect(() => {
        isEmptyOrWhitespace(ramsId) ? viewModel.loadRelated(projectid) : viewModel.loadWithRelated(ramsId);
        // viewModel.load(projectid);
        return () => {
            viewModel.reset();
        };
    }, []);

    const COLUMNS8 = "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr";
    const COLUMNS4 = "1fr 1fr 1fr 1fr";
    const COLUMNS2 = "1fr 1fr";
    const onSubmit = (event: any) => {
        event.preventDefault();
    };

    const renderView = () => {
        return <></>;
    };

    const renderForm = () => {
        return (
            <FormBox>
                <Form className={props.className} onSubmit={onSubmit}>
                    <FormSection>
                        <DashedDivider gutterBottom={true} borderThickness={1} borderOpacity={1} />

                        <Grid columnGap={30} rowGap={20} marginBottom={1} tc={COLUMNS2} dc={COLUMNS2}>
                            <Cell ts={1} ds={1}>
                                <FileUploader>
                                    <Typography
                                        style={{ marginBottom: "8px", font: "normal normal 700 12px/16px Segoe UI", letterSpacing: "-0.18px", color: "#191919" }}
                                        variant="h5"
                                    >
                                        Copy of RA/MS:
                                    </Typography>
                                    <Box hidden={viewModel.isFormDisabled}>
                                        <Uploader handleSelectFile={(e: any) => viewModel.fileChange(e)} isFormDisabled={viewModel.isFormDisabled} label="" />
                                        {viewModel.model.rAMSAttachments.filter((el: any) => !el.isDeleted).length > 0 && (
                                            <Typography style={{ margin: "17px 0px 13px" }} variant="h5">
                                                Photo(s) added:
                                            </Typography>
                                        )}
                                    </Box>
                                    <Box className="uploadedFile">
                                        {viewModel.model.rAMSAttachments.map((item: RAMSAttachment, index: number) => {
                                            return (
                                                !item.isDeleted && (
                                                    <Box className="uploadedFile-box">
                                                        <Box className="uploadedFile-heading">
                                                            <IconButton onClick={() => viewModel.DownloadFile(item.fileURL, item.fileName)}>
                                                                <SystemUpdateTwoToneIcon />
                                                            </IconButton>
                                                            <h3>{item.fileName}</h3>
                                                        </Box>
                                                        <IconButton onClick={() => viewModel.deleteRAMSReviewPhotoFile(index)} disabled={viewModel.isFormDisabled}>
                                                            <DeleteOutlinedIcon />
                                                        </IconButton>
                                                    </Box>
                                                )
                                            );
                                        })}
                                    </Box>
                                </FileUploader>
                                <FormHelperText style={{ color: "#f44336" }}>{viewModel.model.getError("rAMSAttachments")}</FormHelperText>
                            </Cell>
                        </Grid>

                        <Grid columnGap={30} rowGap={20} tc={COLUMNS2} dc={COLUMNS2} style={{ marginBottom: "20px" }}>
                            <Cell ts={1} ds={1}>
                                <TextField
                                    multiline
                                    minRows={1}
                                    value={viewModel.RAMSViewModel.getValue("title")}
                                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                                        viewModel.RAMSViewModel.setValue<string>("title", event.target.value)
                                    }
                                    fullWidth
                                    className="textarea"
                                    label="RA/MS title and revision:"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    placeholder={`Please add`}
                                    onBlur={() => viewModel.RAMSViewModel.isFieldValid("title")}
                                    disabled={viewModel.isFormDisabled || viewModel.IsLoading}
                                    error={viewModel.RAMSViewModel.getError("title") !== ""}
                                    helperText={viewModel.RAMSViewModel.getError("title")}
                                />
                            </Cell>
                        </Grid>

                        <Grid columnGap={30} rowGap={20} tc={COLUMNS4} dc={COLUMNS4} style={{ marginBottom: "20px" }}>
                            <Cell ts={1} ds={1}>
                                <TextField
                                    multiline
                                    minRows={1}
                                    value={viewModel.RAMSViewModel.getValue("subContractor")}
                                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                                        viewModel.RAMSViewModel.setValue<string>("subContractor", event.target.value)
                                    }
                                    fullWidth
                                    className="textarea"
                                    label="Sub-contractor:"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    placeholder={`Please add`}
                                    onBlur={() => viewModel.RAMSViewModel.isFieldValid("subContractor")}
                                    disabled={viewModel.isFormDisabled || viewModel.IsLoading}
                                    error={viewModel.RAMSViewModel.getError("subContractor") !== ""}
                                    helperText={viewModel.RAMSViewModel.getError("subContractor")}
                                />
                            </Cell>
                            <Cell ts={1} ds={1}>
                                <DarwinInputWrapper<RAMSModel>
                                    type="text"
                                    label="Sub-contractor supervisor:"
                                    placeHolder="Sub-contractor supervisor"
                                    validateOnBlur={true}
                                    viewModel={viewModel.RAMSViewModel}
                                    fieldName="supervisorUserName"
                                    shrink={true}
                                    maxLength={256}
                                    editMode={!viewModel.isFormDisabled}
                                />
                            </Cell>
                            <DarwinDateSelect
                                displayName="Estimated start date:"
                                execute={(value: string | null) => viewModel.RAMSViewModel.setValue("estimatedStartDate", value)}
                                onBlur={() => viewModel.RAMSViewModel.isFieldValid("estimatedStartDate")}
                                placeholder="Please select"
                                validationMessage={viewModel.RAMSViewModel.getError("estimatedStartDate")}
                                value={viewModel.RAMSViewModel.getValue("estimatedStartDate")}
                                canExecute={!viewModel.isFormDisabled}
                                hideFormHelperText={true}
                            />
                        </Grid>
                        <SolidDivider gutterBottom={true} borderThickness={1} borderOpacity={1} />

                        {/*RAM Choices*/}

                        <SHBox grid dc={"1fr 1fr"} mt={1} style={{ alignItems: "center" }}>
                            <RadioRAMSChoiceForm
                                value={viewModel.RAMSChoiceViewModel.model.hasPassedScopeOfWorks}
                                validationMessage={() => viewModel.RAMSChoiceViewModel.getError("hasPassedScopeOfWorks")}
                                onChange={(e) => {
                                    viewModel.handleRadioChange(e, "hasPassedScopeOfWorks");
                                }}
                                title={"Scope of Works"}
                                canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                                label1={"Pass"}
                                label2={"Fail"}
                            />
                        </SHBox>
                        <SHBox dcs={"2"} showIf={viewModel.RAMSChoiceViewModel.getValue("hasPassedScopeOfWorks") === "fail"}>
                            <DarwinInput<RAMSChoiceViewModel>
                                type="text"
                                label="Please give more details"
                                multiLine={true}
                                validateOnBlur={false}
                                viewModel={viewModel.RAMSChoiceViewModel}
                                fieldName={"reasonScopeOfWorks"}
                                shrink={true}
                                maxLength={4096}
                                editMode={true}
                            />
                        </SHBox>

                        <SHBox grid dc={"1fr 1fr"} mt={1} style={{ alignItems: "center" }}>
                            <RadioRAMSChoiceForm
                                value={viewModel.RAMSChoiceViewModel.model.hasPassedWhoPreparedDocument}
                                validationMessage={() => viewModel.RAMSChoiceViewModel.getError("hasPassedWhoPreparedDocument")}
                                onChange={(e) => {
                                    viewModel.handleRadioChange(e, "hasPassedWhoPreparedDocument");
                                }}
                                title={"Who Prepared the Document"}
                                canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                                label1={"Pass"}
                                label2={"Fail"}
                            />
                        </SHBox>
                        <SHBox dcs={"2"} showIf={viewModel.RAMSChoiceViewModel.getValue("hasPassedWhoPreparedDocument") === "fail"}>
                            <DarwinInput<RAMSChoiceViewModel>
                                type="text"
                                label="Please give more details"
                                multiLine={true}
                                validateOnBlur={false}
                                viewModel={viewModel.RAMSChoiceViewModel}
                                fieldName={"reasonWhoPreparedDocument"}
                                shrink={true}
                                maxLength={4096}
                                editMode={true}
                                label1={"Pass"}
                                label2={"Fail"}
                            />
                        </SHBox>

                        <SHBox grid dc={"1fr 1fr"} mt={1} style={{ alignItems: "center" }}>
                            <RadioRAMSChoiceForm
                                value={viewModel.RAMSChoiceViewModel.model.hasPassedMethodStatement}
                                validationMessage={() => viewModel.RAMSChoiceViewModel.getError("hasPassedMethodStatement")}
                                onChange={(e) => {
                                    viewModel.handleRadioChange(e, "hasPassedMethodStatement");
                                }}
                                title={"Method Statement Communication"}
                                canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                                label1={"Pass"}
                                label2={"Fail"}
                            />
                        </SHBox>
                        <SHBox dcs={"2"} showIf={viewModel.RAMSChoiceViewModel.getValue("hasPassedMethodStatement") === "fail"}>
                            <DarwinInput<RAMSChoiceViewModel>
                                type="text"
                                label="Please give more details"
                                multiLine={true}
                                validateOnBlur={false}
                                viewModel={viewModel.RAMSChoiceViewModel}
                                fieldName={"reasonMethodStatement"}
                                shrink={true}
                                maxLength={4096}
                                editMode={true}
                            />
                        </SHBox>

                        <SHBox grid dc={"1fr 1fr"} mt={1} style={{ alignItems: "center" }}>
                            <RadioRAMSChoiceForm
                                value={viewModel.RAMSChoiceViewModel.model.hasPassedYoungPersonsRA}
                                validationMessage={() => viewModel.RAMSChoiceViewModel.getError("hasPassedYoungPersonsRA")}
                                onChange={(e) => {
                                    viewModel.handleRadioChange(e, "hasPassedYoungPersonsRA");
                                }}
                                title={"Young Persons RA (If Applicable)"}
                                canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                                label1={"Pass"}
                                label2={"Fail"}
                            />
                        </SHBox>
                        <SHBox dcs={"2"} showIf={viewModel.RAMSChoiceViewModel.getValue("hasPassedYoungPersonsRA") === "fail"}>
                            <DarwinInput<RAMSChoiceViewModel>
                                type="text"
                                label="Please give more details"
                                multiLine={true}
                                validateOnBlur={false}
                                viewModel={viewModel.RAMSChoiceViewModel}
                                fieldName={"reasonYoungPersonsRA"}
                                shrink={true}
                                maxLength={4096}
                                editMode={true}
                            />
                        </SHBox>

                        <SHBox grid dc={"1fr 1fr"} mt={1} style={{ alignItems: "center" }}>
                            <RadioRAMSChoiceForm
                                value={viewModel.RAMSChoiceViewModel.model.hasPassedRequiredPPE}
                                validationMessage={() => viewModel.RAMSChoiceViewModel.getError("hasPassedRequiredPPE")}
                                onChange={(e) => {
                                    viewModel.handleRadioChange(e, "hasPassedRequiredPPE");
                                }}
                                title={"Required PPE"}
                                canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                                label1={"Pass"}
                                label2={"Fail"}
                            />
                        </SHBox>
                        <SHBox dcs={"2"} showIf={viewModel.RAMSChoiceViewModel.getValue("hasPassedRequiredPPE") === "fail"}>
                            <DarwinInput<RAMSChoiceViewModel>
                                type="text"
                                label="Please give more details"
                                multiLine={true}
                                validateOnBlur={false}
                                viewModel={viewModel.RAMSChoiceViewModel}
                                fieldName={"reasonRequiredPPE"}
                                shrink={true}
                                maxLength={4096}
                                editMode={true}
                            />
                        </SHBox>

                        <SHBox grid dc={"1fr 1fr"} mt={1} style={{ alignItems: "center" }}>
                            <RadioRAMSChoiceForm
                                value={viewModel.RAMSChoiceViewModel.model.hasPassedLocationOfWorks}
                                validationMessage={() => viewModel.RAMSChoiceViewModel.getError("hasPassedLocationOfWorks")}
                                onChange={(e) => {
                                    viewModel.handleRadioChange(e, "hasPassedLocationOfWorks");
                                }}
                                title={"Location of Works"}
                                canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                                label1={"Pass"}
                                label2={"Fail"}
                            />
                        </SHBox>
                        <SHBox dcs={"2"} showIf={viewModel.RAMSChoiceViewModel.getValue("hasPassedLocationOfWorks") === "fail"}>
                            <DarwinInput<RAMSChoiceViewModel>
                                type="text"
                                label="Please give more details"
                                multiLine={true}
                                validateOnBlur={false}
                                viewModel={viewModel.RAMSChoiceViewModel}
                                fieldName={"reasonLocationOfWorks"}
                                shrink={true}
                                maxLength={4096}
                                editMode={true}
                            />
                        </SHBox>

                        <SHBox grid dc={"1fr 1fr"} mt={1} style={{ alignItems: "center" }}>
                            <RadioRAMSChoiceForm
                                value={viewModel.RAMSChoiceViewModel.model.hasPassedSequenceOfWorks}
                                validationMessage={() => viewModel.RAMSChoiceViewModel.getError("hasPassedSequenceOfWorks")}
                                onChange={(e) => {
                                    viewModel.handleRadioChange(e, "hasPassedSequenceOfWorks");
                                }}
                                title={"Sequence of Works"}
                                canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                                label1={"Pass"}
                                label2={"Fail"}
                            />
                        </SHBox>
                        <SHBox dcs={"2"} showIf={viewModel.RAMSChoiceViewModel.getValue("hasPassedSequenceOfWorks") === "fail"}>
                            <DarwinInput<RAMSChoiceViewModel>
                                type="text"
                                label="Please give more details"
                                multiLine={true}
                                validateOnBlur={false}
                                viewModel={viewModel.RAMSChoiceViewModel}
                                fieldName={"reasonSequenceOfWorks"}
                                shrink={true}
                                maxLength={4096}
                                editMode={true}
                            />
                        </SHBox>

                        <SHBox grid dc={"1fr 1fr"} mt={1} style={{ alignItems: "center" }}>
                            <RadioRAMSChoiceForm
                                value={viewModel.RAMSChoiceViewModel.model.hasPassedProgrammeOfWorks}
                                validationMessage={() => viewModel.RAMSChoiceViewModel.getError("hasPassedProgrammeOfWorks")}
                                onChange={(e) => {
                                    viewModel.handleRadioChange(e, "hasPassedProgrammeOfWorks");
                                }}
                                title={"Programme of Works"}
                                canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                                label1={"Pass"}
                                label2={"Fail"}
                            />
                        </SHBox>
                        <SHBox dcs={"2"} showIf={viewModel.RAMSChoiceViewModel.getValue("hasPassedProgrammeOfWorks") === "fail"}>
                            <DarwinInput<RAMSChoiceViewModel>
                                type="text"
                                label="Please give more details"
                                multiLine={true}
                                validateOnBlur={false}
                                viewModel={viewModel.RAMSChoiceViewModel}
                                fieldName={"reasonProgrammeOfWorks"}
                                shrink={true}
                                maxLength={4096}
                                editMode={true}
                            />
                        </SHBox>

                        <SHBox grid dc={"1fr 1fr"} mt={1} style={{ alignItems: "center" }}>
                            <RadioRAMSChoiceForm
                                value={viewModel.RAMSChoiceViewModel.model.hasPassedSitePersonnel}
                                validationMessage={() => viewModel.RAMSChoiceViewModel.getError("hasPassedSitePersonnel")}
                                onChange={(e) => {
                                    viewModel.handleRadioChange(e, "hasPassedSitePersonnel");
                                }}
                                title={"Site Personnel & Supervision"}
                                canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                                label1={"Pass"}
                                label2={"Fail"}
                            />
                        </SHBox>
                        <SHBox dcs={"2"} showIf={viewModel.RAMSChoiceViewModel.getValue("hasPassedSitePersonnel") === "fail"}>
                            <DarwinInput<RAMSChoiceViewModel>
                                type="text"
                                label="Please give more details"
                                multiLine={true}
                                validateOnBlur={false}
                                viewModel={viewModel.RAMSChoiceViewModel}
                                fieldName={"reasonSitePersonnel"}
                                shrink={true}
                                maxLength={4096}
                                editMode={true}
                            />
                        </SHBox>

                        <SHBox grid dc={"1fr 1fr"} mt={1} style={{ alignItems: "center" }}>
                            <RadioRAMSChoiceForm
                                value={viewModel.RAMSChoiceViewModel.model.hasPassedMaterialsInvolved}
                                validationMessage={() => viewModel.RAMSChoiceViewModel.getError("hasPassedMaterialsInvolved")}
                                onChange={(e) => {
                                    viewModel.handleRadioChange(e, "hasPassedMaterialsInvolved");
                                }}
                                title={"Materials Involved"}
                                canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                                label1={"Pass"}
                                label2={"Fail"}
                            />
                        </SHBox>
                        <SHBox dcs={"2"} showIf={viewModel.RAMSChoiceViewModel.getValue("hasPassedMaterialsInvolved") === "fail"}>
                            <DarwinInput<RAMSChoiceViewModel>
                                type="text"
                                label="Please give more details"
                                multiLine={true}
                                validateOnBlur={false}
                                viewModel={viewModel.RAMSChoiceViewModel}
                                fieldName={"reasonMaterialsInvolved"}
                                shrink={true}
                                maxLength={4096}
                                editMode={true}
                            />
                        </SHBox>

                        <SHBox grid dc={"1fr 1fr"} mt={1} style={{ alignItems: "center" }}>
                            <RadioRAMSChoiceForm
                                value={viewModel.RAMSChoiceViewModel.model.hasPassedHAVsAssessments}
                                validationMessage={() => viewModel.RAMSChoiceViewModel.getError("hasPassedHAVsAssessments")}
                                onChange={(e) => {
                                    viewModel.handleRadioChange(e, "hasPassedHAVsAssessments");
                                }}
                                title={"HAVs Assessments"}
                                canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                                label1={"Pass"}
                                label2={"Fail"}
                            />
                        </SHBox>
                        <SHBox dcs={"2"} showIf={viewModel.RAMSChoiceViewModel.getValue("hasPassedHAVsAssessments") === "fail"}>
                            <DarwinInput<RAMSChoiceViewModel>
                                type="text"
                                label="Please give more details"
                                multiLine={true}
                                validateOnBlur={false}
                                viewModel={viewModel.RAMSChoiceViewModel}
                                fieldName={"reasonHAVsAssessments"}
                                shrink={true}
                                maxLength={4096}
                                editMode={true}
                            />
                        </SHBox>

                        <SHBox grid dc={"1fr 1fr"} mt={1} style={{ alignItems: "center" }}>
                            <RadioRAMSChoiceForm
                                value={viewModel.RAMSChoiceViewModel.model.hasPassedCOSHHAssessment}
                                validationMessage={() => viewModel.RAMSChoiceViewModel.getError("hasPassedCOSHHAssessment")}
                                onChange={(e) => {
                                    viewModel.handleRadioChange(e, "hasPassedCOSHHAssessment");
                                }}
                                title={"COSHH Assessment"}
                                canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                                label1={"Pass"}
                                label2={"Fail"}
                            />
                        </SHBox>
                        <SHBox dcs={"2"} showIf={viewModel.RAMSChoiceViewModel.getValue("hasPassedCOSHHAssessment") === "fail"}>
                            <DarwinInput<RAMSChoiceViewModel>
                                type="text"
                                label="Please give more details"
                                multiLine={true}
                                validateOnBlur={false}
                                viewModel={viewModel.RAMSChoiceViewModel}
                                fieldName={"reasonCOSHHAssessment"}
                                shrink={true}
                                maxLength={4096}
                                editMode={true}
                            />
                        </SHBox>

                        <SHBox grid dc={"1fr 1fr"} mt={1} style={{ alignItems: "center" }}>
                            <RadioRAMSChoiceForm
                                value={viewModel.RAMSChoiceViewModel.model.hasPassedRiskAssessment}
                                validationMessage={() => viewModel.RAMSChoiceViewModel.getError("hasPassedRiskAssessment")}
                                onChange={(e) => {
                                    viewModel.handleRadioChange(e, "hasPassedRiskAssessment");
                                }}
                                title={"Risk Assessments"}
                                canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                                label1={"Pass"}
                                label2={"Fail"}
                            />
                        </SHBox>
                        <SHBox dcs={"2"} showIf={viewModel.RAMSChoiceViewModel.getValue("hasPassedRiskAssessment") === "fail"}>
                            <DarwinInput<RAMSChoiceViewModel>
                                type="text"
                                label="Please give more details"
                                multiLine={true}
                                validateOnBlur={false}
                                viewModel={viewModel.RAMSChoiceViewModel}
                                fieldName={"reasonRiskAssessment"}
                                shrink={true}
                                maxLength={4096}
                                editMode={true}
                            />
                        </SHBox>

                        <SHBox grid dc={"1fr 1fr"} mt={1} style={{ alignItems: "center" }}>
                            <RadioRAMSChoiceForm
                                value={viewModel.RAMSChoiceViewModel.model.hasPassedManualHandlingAssessment}
                                validationMessage={() => viewModel.RAMSChoiceViewModel.getError("hasPassedManualHandlingAssessment")}
                                onChange={(e) => {
                                    viewModel.handleRadioChange(e, "hasPassedManualHandlingAssessment");
                                }}
                                title={"Manual Handling Assessment"}
                                canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                                label1={"Pass"}
                                label2={"Fail"}
                            />
                        </SHBox>
                        <SHBox dcs={"2"} showIf={viewModel.RAMSChoiceViewModel.getValue("hasPassedManualHandlingAssessment") === "fail"}>
                            <DarwinInput<RAMSChoiceViewModel>
                                type="text"
                                label="Please give more details"
                                multiLine={true}
                                validateOnBlur={false}
                                viewModel={viewModel.RAMSChoiceViewModel}
                                fieldName={"reasonManualHandlingAssessment"}
                                shrink={true}
                                maxLength={4096}
                                editMode={true}
                            />
                        </SHBox>

                        <SHBox grid dc={"1fr 1fr"} mt={1} style={{ alignItems: "center" }}>
                            <RadioRAMSChoiceForm
                                value={viewModel.RAMSChoiceViewModel.model.hasPassedPlantAndEquipmentRequired}
                                validationMessage={() => viewModel.RAMSChoiceViewModel.getError("hasPassedPlantAndEquipmentRequired")}
                                onChange={(e) => {
                                    viewModel.handleRadioChange(e, "hasPassedPlantAndEquipmentRequired");
                                }}
                                title={"Plant & Equipment Required"}
                                canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                                label1={"Pass"}
                                label2={"Fail"}
                            />
                        </SHBox>
                        <SHBox dcs={"2"} showIf={viewModel.RAMSChoiceViewModel.getValue("hasPassedPlantAndEquipmentRequired") === "fail"}>
                            <DarwinInput<RAMSChoiceViewModel>
                                type="text"
                                label="Please give more details"
                                multiLine={true}
                                validateOnBlur={false}
                                viewModel={viewModel.RAMSChoiceViewModel}
                                fieldName={"reasonPlantAndEquipmentRequired"}
                                shrink={true}
                                maxLength={4096}
                                editMode={true}
                            />
                        </SHBox>

                        <SHBox grid dc={"1fr 1fr"} mt={1} style={{ alignItems: "center" }}>
                            <RadioRAMSChoiceForm
                                value={viewModel.RAMSChoiceViewModel.model.hasPassedWorkingHours}
                                validationMessage={() => viewModel.RAMSChoiceViewModel.getError("hasPassedWorkingHours")}
                                onChange={(e) => {
                                    viewModel.handleRadioChange(e, "hasPassedWorkingHours");
                                }}
                                title={"Working Hours"}
                                canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                                label1={"Pass"}
                                label2={"Fail"}
                            />
                        </SHBox>
                        <SHBox dcs={"2"} showIf={viewModel.RAMSChoiceViewModel.getValue("hasPassedWorkingHours") === "fail"}>
                            <DarwinInput<RAMSChoiceViewModel>
                                type="text"
                                label="Please give more details"
                                multiLine={true}
                                validateOnBlur={false}
                                viewModel={viewModel.RAMSChoiceViewModel}
                                fieldName={"reasonWorkingHours"}
                                shrink={true}
                                maxLength={4096}
                                editMode={true}
                            />
                        </SHBox>

                        <SHBox grid dc={"1fr 1fr"} mt={1} style={{ alignItems: "center" }}>
                            <RadioRAMSChoiceForm
                                value={viewModel.RAMSChoiceViewModel.model.hasPassedEmergencyRescuePlan}
                                validationMessage={() => viewModel.RAMSChoiceViewModel.getError("hasPassedEmergencyRescuePlan")}
                                onChange={(e) => {
                                    viewModel.handleRadioChange(e, "hasPassedEmergencyRescuePlan");
                                }}
                                title={"Emergency rescue plan (if required)"}
                                canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                                label1={"Pass"}
                                label2={"Fail"}
                            />
                        </SHBox>
                        <SHBox dcs={"2"} showIf={viewModel.RAMSChoiceViewModel.getValue("hasPassedEmergencyRescuePlan") === "fail"}>
                            <DarwinInput<RAMSChoiceViewModel>
                                type="text"
                                label="Please give more details"
                                multiLine={true}
                                validateOnBlur={false}
                                viewModel={viewModel.RAMSChoiceViewModel}
                                fieldName={"reasonEmergencyRescuePlan"}
                                shrink={true}
                                maxLength={4096}
                                editMode={true}
                            />
                        </SHBox>

                        <SHBox grid dc={"1fr 1fr"} mt={1} style={{ alignItems: "center" }}>
                            <RadioRAMSChoiceForm
                                value={viewModel.RAMSChoiceViewModel.model.hasPassedShoringPlan}
                                validationMessage={() => viewModel.RAMSChoiceViewModel.getError("hasPassedShoringPlan")}
                                onChange={(e) => {
                                    viewModel.handleRadioChange(e, "hasPassedShoringPlan");
                                }}
                                title={"Shoring plan (if required)"}
                                canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                                label1={"Pass"}
                                label2={"Fail"}
                            />
                        </SHBox>
                        <SHBox dcs={"2"} showIf={viewModel.RAMSChoiceViewModel.getValue("hasPassedShoringPlan") === "fail"}>
                            <DarwinInput<RAMSChoiceViewModel>
                                type="text"
                                label="Please give more details"
                                multiLine={true}
                                validateOnBlur={false}
                                viewModel={viewModel.RAMSChoiceViewModel}
                                fieldName={"reasonShoringPlan"}
                                shrink={true}
                                maxLength={4096}
                                editMode={true}
                            />
                        </SHBox>

                        <SolidDivider gutterBottom={true} borderThickness={1} borderOpacity={1} />

                        <SHBox grid dc={"1fr 1fr"} mt={1} style={{ alignItems: "center" }}>
                            <RadioRAMSChoiceForm
                                value={viewModel.RAMSChoiceViewModel.model.isApprovedRAMSRecommended}
                                validationMessage={() => viewModel.RAMSChoiceViewModel.getError("isApprovedRAMSRecommended")}
                                onChange={(e) => {
                                    viewModel.handleRadioChange(e, "isApprovedRAMSRecommended");
                                }}
                                title={"Are the RA/MS recommended for construction"}
                                canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                                label1={"Approved"}
                                label2={"Declined"}
                            />
                        </SHBox>
                        <SHBox dcs={"2"} pb={3} showIf={viewModel.RAMSChoiceViewModel.getValue("isApprovedRAMSRecommended") === "fail"}>
                            <DarwinInput<RAMSChoiceViewModel>
                                type="text"
                                label="Please give more details"
                                multiLine={true}
                                validateOnBlur={false}
                                viewModel={viewModel.RAMSChoiceViewModel}
                                fieldName={"reasonApprovedRAMSRecommended"}
                                shrink={true}
                                maxLength={4096}
                                editMode={true}
                            />
                        </SHBox>

                        <SolidDivider gutterBottom={true} borderThickness={1} borderOpacity={1} />
                    </FormSection>

                    <FormSection>
                        <SubtitleContainer>
                            <Typography variant="h2" style={{ font: "normal normal 600 18px/30px Open Sans", letterSpacing: "-0.36px", color: "#191919" }}>
                                Authorisations
                            </Typography>
                            <Grid columnGap={30} rowGap={20} marginBottom={1} tc={COLUMNS4} dc={COLUMNS4}>
                                <Cell ts={1} ds={1}>
                                    <DarwinSelect
                                        displayName="Review by:"
                                        execute={(data: { id: string; displayName: string }) => viewModel.handleReviewByUserName(data)}
                                        fullWidth={true}
                                        getOptionLabel={(option: any) => option.displayName}
                                        options={viewModel.getValue("reviewedByUsers")}
                                        onBlur={() => viewModel.RAMSViewModel.isFieldValid("reviewedByUserId")}
                                        placeholder="Please select"
                                        value={viewModel.ReviewBy}
                                        error={viewModel.RAMSViewModel.getError("reviewedByUserId") !== ""}
                                        validationMessage={viewModel.RAMSViewModel.getError("reviewedByUserId")}
                                        canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                                    />
                                </Cell>
                                <Cell>
                                    <Typography variant="h5">Date / time added: {viewModel.signatureAddedDate}</Typography>
                                </Cell>
                            </Grid>
                            <Grid columnGap={30} rowGap={20} tc={COLUMNS4} dc={COLUMNS4}>
                                <SignatureContainer>
                                    <SubtitleContainer></SubtitleContainer>
                                    <Box>
                                        <Box>
                                            <Signature
                                                displayName={"Signature:"}
                                                execute={(data: any) => viewModel.RAMSViewModel.setValue<string>("authorisationSignatureURL", data)}
                                                value={viewModel.RAMSViewModel.getValue("authorisationSignatureURL")}
                                                canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                                            />

                                            {viewModel.RAMSViewModel.getValue("authorisationSignatureURL") === "" &&
                                                viewModel.RAMSViewModel.getError("authorisationSignatureURL") !== "" && (
                                                    <FormHelperText style={{ color: "#f44336" }}>{viewModel.RAMSViewModel.getError("authorisationSignatureURL")}</FormHelperText>
                                                )}
                                        </Box>
                                    </Box>
                                    {!viewModel.isFormDisabled && <Typography variant="h5">Date / time added: {viewModel.signatureAddedDate}</Typography>}
                                </SignatureContainer>
                            </Grid>
                        </SubtitleContainer>
                    </FormSection>

                    <FormSection>
                        <ButtonsContainer>
                            <PrimaryButton
                                displayName="Submit"
                                execute={() => viewModel.handleSubmit()}
                                fullWidth={true}
                                canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                            />
                            <DefaultButton
                                displayName="Cancel"
                                execute={() => viewModel.handleCancel(projectid)}
                                fullWidth={true}
                                canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                            />
                        </ButtonsContainer>
                    </FormSection>
                </Form>
            </FormBox>
        );
    };

    return useObserver(() => {
        return (
            <Form className={props.className} onSubmit={() => {}}>
                <BackButtonHolder
                    onClick={() => {
                        history.push(AppUrls.Client.Project.Construction.replace(":projectid", projectid ? projectid : viewModel.model.projectId) + "#ramsReview");
                    }}
                    style={{ padding: "10px 0", marginTop: 0 }}
                >
                    <Box style={{ position: "relative", display: "flex", marginLeft: "10px", alignItems: "center" }}>
                        <Box style={{ position: "absolute", height: "15px", width: "8px" }}>
                            <CustomArrow size={"8px"} color={theme.palette.blue.main} type={"left"} />
                        </Box>
                        <Box style={{ marginLeft: "20px", font: "normal normal 600 18px/32px Open Sans", letterSpacing: "-0.36px", color: theme.palette.blue.main }}>
                            Back to RA/MS reviews and sign offs
                            {` (${viewModel.model?.ramsProjectDetails?.projectReference} - ${viewModel.model?.ramsProjectDetails?.projectName})`}
                        </Box>
                    </Box>
                </BackButtonHolder>
                <RAMSReviewFormHeader
                    ProjectDetails={viewModel.model.ramsProjectDetails}
                    ProjectDate={moment(new Date()).format("DD/MM/YYYY @ h:mm a")}
                    FormHeaderTitle={"New RA/MS review and sign off"}
                    IsNewForm={isEmptyOrWhitespace(viewModel.RAMSViewModel.getValue("id"))}
                    CompletedBy={viewModel.model.ramsProjectDetails.completedByUserName}
                />

                {renderForm()}
            </Form>
        );
    });
};

export const DilapidationFormView = styled(RAMSReviewFormView)`
    display: flex;
    flex: 1;
    flex-direction: column;

    form {
        position: relative;
    }
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;

    > button {
        max-width: 262px;
    }

    > button:nth-child(1) {
        margin-right: 30px;
    }
`;

const ContentBox = styled(Box)`
    margin: 18px 0;
    .MuiTypography-root {
        font: normal normal normal 12px/16px Open Sans;
        letter-spacing: -0.3px;
        color: #191919;
    }
`;

const FormBox = styled(Box)`
    .MuiFormLabel-root {
        font: normal normal 700 12px/16px Segoe UI;
        letter-spacing: -0.18px;
        color: #191919;
        margin: 0 0 7px;
    }
`;

interface SubtitleProps {
    margin?: string;
}

export const SubtitleContainer = styled.div<SubtitleProps>`
    margin-top: 12px;
    h2 {
        margin-bottom: ${(props) => (props.margin ? props.margin : "10px")};
        font: normal normal 600 12px/16px Segoe UI;
        letter-spacing: -0.18px;
        color: #191919;
        span {
            font-weight: bold;
            margin-right: 10px;
        }
    }
`;

const SignatureContainer = styled.div`
    display: flex;
    flex-direction: column;
    grid-column-start: 1;
    grid-column-end: 3;

    > div:nth-child(2) {
        display: flex;
        flex-direction: row;

        > div {
            width: 100%;

            canvas {
                border: 1px solid #d4d4d4;
            }
        }
    }
    .MuiFormControl-root {
        width: 100% !important;
    }
`;

const FileUploader = styled(Box)`
    margin-top: 20px;
    .fileUpload {
        background: #6c93a626;
        border: 1px dashed #6c93a6;
        font: normal normal normal 12px/14px Open Sans;
        letter-spacing: 0px;
        color: #425b66;
        padding: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 90px;
        margin: 0 !important;
    }
`;
