// Libraries
import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { Loader, styled } from "@shoothill/core";

// Custom
import { DetailsPage } from "Globals/Views/DetailsPage/DetailsPage.styles";
import { ControlLabel } from "Components/Form/ControlLabel";
import { Box } from "@material-ui/core";
import moment from "moment";
import { DarwinDateSelect } from "Components/DateSelect/DarwinDateSelect";
import { PrimaryButton } from "Components/Buttons/Buttons";
import { IEItemsWrapper } from "Views/Project/Commercial/IETables/IEItemsWrapper";
import { IEReportGrid } from "../Shared/IEReportGrid";
import { IECostReportViewModel } from "./IECostReportViewModel";
import { CVRGridHeader, CVRSummaryGridView } from "../CVRReport/IECVRReport.styles";
import { IEVariationReportGrid } from "../Shared/IEVariationReportGrid";
import { ReportDatePickerContainer, RunReportButtonContainer } from "Views/Report/Report.styles";
import { VariationTypeEnum } from "Views/Variation/Form/VariationModel";

// Styling & Images

moment.locale("en-GB", {
    week: {
        dow: 1,
    },
});

interface Props {
    ieId: string;
}

export const IECostReportView: React.FunctionComponent<Props> = (props: Props) => {
    const [viewModel] = useState(() => new IECostReportViewModel());

    useEffect(() => {
        return () => {
            viewModel.reset();
        };
    }, []);

    const onReportEndDateTopChanged = (date: string | null) => {
        viewModel.setValue("reportEndDateTop", date === null ? date : moment(date).startOf("day").toISOString());
        viewModel.setCanExportCSV(false);
    };

    const onReportEndDateBottomChanged = (date: string | null) => {
        viewModel.setValue("reportEndDateBottom", date === null ? date : moment(date).endOf("day").toISOString());
        viewModel.setCanExportCSV(false);
    };

    const runReport = () => {
        viewModel.runReport(props.ieId);
        viewModel.setCanExportCSV(true);
    };

    const exportCSV = () => {
        viewModel.generateIECostReportCSV(props.ieId);
    };

    return useObserver(() => (
        <DetailsPage>
            <ReportDatePickerContainer>
                <ControlLabel label="Date comparison:" htmlFor="start-date" />
                <Box>
                    <Box maxWidth="200px">
                        <DarwinDateSelect
                            execute={(value: string | null) => onReportEndDateTopChanged(value)}
                            placeholder="Please select"
                            value={viewModel.model.reportEndDateTop}
                            maxDate={moment.utc().toDate()}
                            clearable
                            format="DD/MM/YYYY"
                            canExecute={!viewModel.IsLoading}
                            defaultToStartOfDay={true}
                        />
                    </Box>
                    <Box maxWidth="200px">
                        <DarwinDateSelect
                            execute={(value: string | null) => onReportEndDateBottomChanged(value)}
                            placeholder="Please select"
                            value={viewModel.model.reportEndDateBottom}
                            maxDate={moment.utc().toDate()}
                            clearable
                            format="DD/MM/YYYY"
                            canExecute={!viewModel.IsLoading}
                            defaultToStartOfDay={false}
                        />
                    </Box>
                </Box>
            </ReportDatePickerContainer>

            <RunReportButtonContainer>
                <PrimaryButton displayName={"Run report"} execute={runReport} fullWidth={false} canExecute={!viewModel.IsLoading} />
                {viewModel.reportViewModel && viewModel.variationReportViewModel && (
                    <PrimaryButton displayName={"Export CSV"} execute={exportCSV} fullWidth={false} canExecute={!viewModel.IsLoading && viewModel.canExportCSV} />
                )}
            </RunReportButtonContainer>

            <IEItemsWrapper>
                <div className="content" style={{ paddingTop: "30px", position: "relative", minWidth: "1715px" }}>
                    <CVRGridHeader>
                        <CVRSummaryGridView>
                            <li className="heading heading-main">
                                <div style={{ maxWidth: "280px" }}>Category / Sub-category / Line Description</div>
                                <div>Committed Cost</div>
                                <div>Future Spend</div>
                                <div>Total Expected Spend</div>
                                <div>Risk/Opp</div>
                            </li>
                        </CVRSummaryGridView>
                        <CVRSummaryGridView>
                            <li className="heading">
                                <div></div>
                                <div>Previous</div>
                                <div>Current</div>
                                <div>Movement</div>
                                <div>Previous</div>
                                <div>Current</div>
                                <div>Movement</div>
                                <div>Previous</div>
                                <div>Current</div>
                                <div>Movement</div>
                                <div>Previous</div>
                                <div>Current</div>
                                <div>Movement</div>
                            </li>
                        </CVRSummaryGridView>
                    </CVRGridHeader>

                    {!viewModel.IsLoading && viewModel.reportViewModel && <IEReportGrid reportViewModel={viewModel.reportViewModel} />}
                    {viewModel.IsLoading && <Loader />}
                </div>
            </IEItemsWrapper>

            <IEItemsWrapper>
                <div className="content" style={{ paddingTop: "30px", position: "relative", minWidth: "1715px" }}>
                    <CVRGridHeader>
                        <CVRSummaryGridView>
                            <li className="heading heading-main">
                                <div style={{ maxWidth: "280px" }}>Variation / Category / Sub-category / Line Description</div>
                                <div>Committed Cost</div>
                                <div>Future Spend</div>
                                <div>Total Expected Spend</div>
                                <div>Risk/Opp</div>
                            </li>
                        </CVRSummaryGridView>
                        <CVRSummaryGridView>
                            <li className="heading">
                                <div></div>
                                <div>Previous</div>
                                <div>Current</div>
                                <div>Movement</div>
                                <div>Previous</div>
                                <div>Current</div>
                                <div>Movement</div>
                                <div>Previous</div>
                                <div>Current</div>
                                <div>Movement</div>
                                <div>Previous</div>
                                <div>Current</div>
                                <div>Movement</div>
                            </li>
                        </CVRSummaryGridView>
                    </CVRGridHeader>

                    {!viewModel.IsLoading && viewModel.variationReportViewModel && (
                        <IEVariationReportGrid reportViewModel={viewModel.variationReportViewModel} variationType={VariationTypeEnum.Variation} />
                    )}
                    {viewModel.IsLoading && <Loader />}
                </div>
            </IEItemsWrapper>

            <IEItemsWrapper>
                <div className="content" style={{ paddingTop: "30px", position: "relative", minWidth: "1715px" }}>
                    <CVRGridHeader>
                        <CVRSummaryGridView>
                            <li className="heading heading-main">
                                <div style={{ maxWidth: "280px" }}>Client Provisional Sum / Category / Sub-category / Line Description</div>
                                <div>Committed Cost</div>
                                <div>Future Spend</div>
                                <div>Total Expected Spend</div>
                                <div>Risk/Opp</div>
                            </li>
                        </CVRSummaryGridView>
                        <CVRSummaryGridView>
                            <li className="heading">
                                <div></div>
                                <div>Previous</div>
                                <div>Current</div>
                                <div>Movement</div>
                                <div>Previous</div>
                                <div>Current</div>
                                <div>Movement</div>
                                <div>Previous</div>
                                <div>Current</div>
                                <div>Movement</div>
                                <div>Previous</div>
                                <div>Current</div>
                                <div>Movement</div>
                            </li>
                        </CVRSummaryGridView>
                    </CVRGridHeader>

                    {!viewModel.IsLoading && viewModel.cpsReportViewModel && (
                        <IEVariationReportGrid reportViewModel={viewModel.cpsReportViewModel} variationType={VariationTypeEnum.ClientProvisionalSum} />
                    )}
                    {viewModel.IsLoading && <Loader />}
                </div>
            </IEItemsWrapper>
        </DetailsPage>
    ));
};
