import * as React from "react";
import { SVGProps } from "react";

export const UploadFileIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg id="Group_10107" data-name="Group 10107" xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 15 20" {...props}>
        <g id="Path_3233" data-name="Path 3233" transform="translate(0.014 2)" fill="#fff">
            <path
                d="M 14.48571586608887 17.5 L 0.5035463571548462 17.5 L 0.5035463571548462 3.572920083999634 L 0.4873206913471222 -1.5 L 9.777871131896973 -1.5 L 14.48571586608887 3.15031623840332 L 14.48571586608887 17.5 Z"
                stroke="none"
            />
            <path
                d="M 0.9889163970947266 -1 L 1.003546714782715 3.572919845581055 L 1.003546714782715 17 L 13.98571586608887 17 L 13.98571586608887 3.359231948852539 L 9.572555541992188 -1 L 0.9889163970947266 -1 M -0.01428318023681641 -2 L 9.983176231384277 -2 L 14.98571586608887 2.941410064697266 L 14.98571586608887 18 L 0.003546714782714844 18 L 0.003546714782714844 3.572919845581055 L -0.01428318023681641 -2 Z"
                stroke="none"
                fill="#707070"
            />
        </g>
        <g id="Group_10065" data-name="Group 10065" transform="translate(15.018 20) rotate(180)">
            <path id="Polygon_6" data-name="Polygon 6" d="M3.75,0,7.5,3.75H0Z" transform="translate(11.259 13.75) rotate(180)" fill="#0095da" />
            <rect id="Rectangle_1595" data-name="Rectangle 1595" width="2.5" height="3.75" transform="translate(6.259 6.25)" fill="#0095da" />
        </g>
        <g id="Path_3299" data-name="Path 3299" transform="translate(9.899)" fill="#d4d4d4">
            <path
                d="M 3.888359308242798 4.968754768371582 L 0.09374625235795975 4.968754768371582 L 0.09374625235795975 1.201059222221375 L 3.888359308242798 4.968754768371582 Z"
                stroke="none"
            />
            <path
                d="M 0.5937461853027344 2.402113437652588 L 0.5937461853027344 4.468745231628418 L 2.675142288208008 4.468745231628418 L 0.5937461853027344 2.402113437652588 M -0.4062538146972656 -4.76837158203125e-06 L 5.101566314697266 5.468745231628418 L -0.4062538146972656 5.468745231628418 L -0.4062538146972656 -4.76837158203125e-06 Z"
                stroke="none"
                fill="#707070"
            />
        </g>
    </svg>
);
