import { action, observable } from "mobx";
import { ModelBase } from "@shoothill/core";

export class IEModel extends ModelBase<IEModel, IEModelDTO> {
    public id: string = "";
    public createdDate: string = "";
    public isDeleted: boolean = false;
    public originatorId: string | null = null;
    public rowVersion: string | null = null;
    public projectId: string = "";
    @observable
    public parentId: string = "";
    @observable
    public cell: number = 0;
    @observable
    public nameId: string = "";
    @observable
    public name: string = "";
    @observable
    public type: string = "";

    fromDto(model: IEModelDTO): void {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    toDto(model: IEModel): void {}

    @action
    public clear() {
        this.fromDto(DefaultIEModelDTO);
    }
}

export type IEModelDTO = {
    id: string;
    createdDate: string;
    isDeleted: boolean;
    originatorId: string | null;
    rowVersion: string | null;
    projectId: string;
    parentId: string;
    nameId: string;
    name: string;
    cell: number;
    type: string;
};

export const DefaultIEModelDTO: IEModelDTO = {
    id: "",
    createdDate: "",
    isDeleted: false,
    originatorId: "",
    rowVersion: "",
    projectId: "",
    parentId: "",
    nameId: "",
    name: "",
    cell: -1,
    type: "",
};
