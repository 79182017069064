import * as React from "react";
import UserViewModel from "Globals/ViewModels/UserViewModel";
import { UserBox } from "./UserAddEdit.style";
import { DarwinInput } from "Globals/Styles/Control/DarwinInput";
import { useObserver } from "mobx-react-lite";
import { Role, User } from "Globals/Models";
import { Box, FormHelperText, MenuItem, Select } from "@material-ui/core";
import { FieldType, generateID } from "@shoothill/core";
import { ControlLabel } from "Components/Form/ControlLabel";
import { StoresInstance } from "../../../Globals/Stores";
import { runInAction } from "mobx";
import { SHBox } from "../../../Components/Box";
import { ProjectListItemViewModel } from "../../Project/ProjectListItemViewModel";
import { ProjectListModel } from "../../Project/ProjectListModel";
import { MultiSelectView } from "Components/Select/MultiSelect";

interface IProps {
    viewModel: UserViewModel;
    roles: Role[];
}

export const UserAddEditForm: React.FC<IProps> = ({ viewModel, roles }) => {
    // const handleRoleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    //     viewModel.set("roleId", event.target.value as any);
    //     viewModel.setError("roleId", "");
    //     viewModel.setValid("roleId", true);
    //     //find role in roles array
    //     viewModel.setRoleDisplayName(viewModel.model.roleId);
    // };

    // const handleProjectChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    //     viewModel.set("projectId", event.target.value as any);
    //     viewModel.setError("projectId", "");
    //     viewModel.setValid("projectId", true);
    //     //find role in roles array
    // };

    const handleProjectChange = (val: any[] | null) => {
        viewModel.setValue("projectIds", val);
        viewModel.setError("projectIds", "");
        viewModel.setValid("projectIds", true);
    };

    const handleRoleChange = (val: any[] | null) => {
        viewModel.setValue("roleIds", val);
        viewModel.setError("roleIds", "");
        viewModel.setValid("roleIds", true);
    };

    const handleSiteTabletProjectChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        viewModel.set("siteTabletProjectId", event.target.value as any);
        viewModel.setError("siteTabletProjectId", "");
        viewModel.setValid("siteTabletProjectId", true);
        //find role in roles array
    };

    const isInError = (fieldName: keyof FieldType<User>) => {
        let isValid = viewModel.getValid(fieldName);
        return !isValid;
    };

    return useObserver(() => (
        <>
            <UserBox className="AddEditUserForm">
                <DarwinInput<UserViewModel> type="text" label="First Name" validateOnBlur={true} viewModel={viewModel} fieldName="firstName" shrink={true} maxLength={128} />
                <DarwinInput<UserViewModel> type="text" label="Last Name" validateOnBlur={true} viewModel={viewModel} fieldName="lastName" shrink={true} maxLength={128} />
            </UserBox>
            <UserBox className="AddEditUserForm">
                <DarwinInput<UserViewModel> type="text" label="Email Address" validateOnBlur={true} viewModel={viewModel} fieldName="emailAddress" shrink={true} maxLength={256} />
                <DarwinInput<UserViewModel> type="text" label="Phone Number" validateOnBlur={true} viewModel={viewModel} fieldName="phoneNumber" shrink={true} maxLength={56} />
            </UserBox>
            <UserBox className="AddEditUserForm">
                <div>
                    <Box className="cell-right rcselect" width="100%">
                        {/* <ControlLabel label="Role" htmlFor="user-select">
                            <Box maxWidth="200px">
                                <Select
                                    id="user-select"
                                    value={viewModel.model.roleId}
                                    onChange={handleRoleChange}
                                    MenuProps={{
                                        getContentAnchorEl: null,
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left",
                                        },
                                    }}
                                    fullWidth
                                >
                                    {roles.map((role: Role, index: number) => {
                                        return (
                                            <MenuItem key={generateID()} value={role.id}>
                                                {role.displayName}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                <Box>{isInError("roleId") && <FormHelperText style={{ color: "red" }}>{viewModel.getError("roleId")}</FormHelperText>}</Box>
                            </Box>
                        </ControlLabel> */}
                        <MultiSelectView
                            style={{ maxWidth: "200px", marginRight: "20px" }}
                            display="Project"
                            fieldName="roles"
                            values={viewModel.model.roleIds}
                            getOptions={viewModel.getRoleOptions}
                            handleChange={handleRoleChange}
                        />
                        <Box>{isInError("roleIds") && <FormHelperText style={{ color: "red" }}>{viewModel.getError("roleIds")}</FormHelperText>}</Box>
                    </Box>
                </div>
                <SHBox className="cell-right rcselect" width="100%">
                    {viewModel.model.roleDisplayName === "Site Tablet" && (
                        <ControlLabel label="Site tablet project" htmlFor="user-select">
                            <Box maxWidth="200px">
                                <Select
                                    id="user-select"
                                    value={viewModel.model.siteTabletProjectId}
                                    onChange={handleSiteTabletProjectChange}
                                    MenuProps={{
                                        getContentAnchorEl: null,
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left",
                                        },
                                    }}
                                    fullWidth
                                >
                                    {viewModel.projectListModels.map((project: ProjectListModel, index: number) => {
                                        return (
                                            <MenuItem key={generateID()} value={project.id}>
                                                {project.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                <Box>
                                    {isInError("siteTabletProjectId") && (
                                        <FormHelperText style={{ color: "red", fontSize: "12px" }}>{viewModel.getError("siteTabletProjectId")}</FormHelperText>
                                    )}
                                </Box>
                            </Box>
                        </ControlLabel>
                    )}
                </SHBox>
                <SHBox className="cell-right rcselect" width="100%">
                    <MultiSelectView
                        style={{ maxWidth: "200px", marginRight: "20px" }}
                        display="Project"
                        fieldName="projects"
                        values={viewModel.model.projectIds}
                        getOptions={viewModel.getProjectOptions}
                        handleChange={handleProjectChange}
                    />
                    <Box>{isInError("projectIds") && <FormHelperText style={{ color: "red", fontSize: "12px" }}>{viewModel.getError("projectIds")}</FormHelperText>}</Box>
                </SHBox>
            </UserBox>
        </>
    ));
};
