import { Box, FormLabel, IconButton, TextField, Typography } from "@material-ui/core";
import { Cell, Grid, isNullOrEmpty, useRouter } from "@shoothill/core";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Form, FormSection } from "Views/PurchaseOrder/Form/Views/Forms";
import { FormHeader } from "Views/PurchaseOrder/Form/Views/FormHeaders";
import { DashedDivider, SolidDivider } from "Views/PurchaseOrder/Form/Views/Dividers";

import { pxToRem, theme } from "Globals/Styles/AppTheme";
import { BackButtonHolder } from "Views/Project/Commercial/IETables/IEGrid.Styles";
import { AppUrls } from "AppUrls";
import { CustomArrow } from "Views/Project/CustomComponents";
import { DefaultButton, PrimaryButton } from "Components/Buttons/Buttons";
import { Alert, AlertTitle } from "@material-ui/lab";
import { DarwinSelect } from "Components/AutoComplete/DarwinSelect";
import { ToolboxTalkFormViewModel } from "./ToolboxTalkFormViewModel";
import { Uploader } from "Components/Uploader/Uploader";
import SystemUpdateTwoToneIcon from "@material-ui/icons/SystemUpdateTwoTone";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { InductionUserView } from "./InductionUserView";
import { uniqueId } from "lodash-es";
import { ToolboxTalkFileModel } from "./ToolboxTalkFileModel";

interface IProps {
    projectId: string;
    className?: string;
}

const ToolboxTalkFormViewBase: React.FC<IProps> = (props) => {
    const { match } = useRouter();
    const { projectid, toolboxtalkid } = match.params as any;
    const [viewModel] = useState(() => new ToolboxTalkFormViewModel(toolboxtalkid, projectid));
    const { history } = useRouter();

    useEffect(() => {
        return () => {
            viewModel.reset();
        };
    }, []);

    const COLUMNS8 = "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr";

    const onSubmit = (event: any) => {
        event.preventDefault();
    };

    console.log("ToolboxTalkFromView Render");

    return useObserver(() => {
        return (
            <Form className={props.className} onSubmit={onSubmit}>
                <BackButtonHolder
                    onClick={() => {
                        history.push(AppUrls.Client.Project.ConstructionHAndS.replace(":projectid", projectid) + "#toolbox");
                    }}
                    style={{ minHeight: "30px", paddingTop: "3px" }}
                >
                    <div style={{ position: "relative", height: "30px", display: "flex", marginLeft: "10px" }}>
                        <div style={{ position: "absolute", top: "5px" }}>
                            <CustomArrow size={"8px"} color={theme.palette.blue.main} type={"left"} />
                        </div>
                        <div style={{ marginLeft: "20px", fontSize: "18px", fontWeight: "bold", color: theme.palette.blue.main }}>
                            Back to toolbox talk record ({viewModel.getProjectReferenceFormatted} {viewModel.getProjectName})
                        </div>
                    </div>
                </BackButtonHolder>

                <DashedDivider gutterBottom={false} gutterTop={false} borderThickness={2} borderOpacity={0.5} />

                <HeaderContainer>
                    <FormHeader displayName={isNullOrEmpty(viewModel.model.id) ? "New tool box talk record" : "New tool box talk record"} />
                </HeaderContainer>

                <FormSection>
                    <ProjectDetailsContainer>
                        <div>
                            <span>Date / time added:</span> {viewModel.getTodayDateFormatted}
                        </div>
                        <div>
                            <span>Project reference:</span> {viewModel.getProjectReferenceFormatted}
                        </div>
                        <div>
                            <span>Project name:</span> {viewModel.getProjectName}
                        </div>
                    </ProjectDetailsContainer>
                </FormSection>

                <FormSection>
                    {/* Default options */}
                    <DashedDivider gutterBottom={true} borderThickness={2} borderOpacity={0.5} />

                    <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
                        <Cell ts={4} ds={4}>
                            <TextField
                                type="text"
                                value={viewModel.getValue("subject")}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => viewModel.setValue("subject", e.target.value)}
                                fullWidth
                                error={viewModel.getError("subject") !== ""}
                                disabled={false}
                                label={"Subject:"}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                placeholder={"Please add the subject of the talk"}
                                helperText={viewModel.getError("subject")}
                            />
                        </Cell>
                        <Cell ts={2} ds={2}>
                            <DarwinSelect
                                displayName="Individual giving toolbox talk:"
                                execute={(data: { id: string; displayName: string }) => viewModel.handleSetTalkerUser(data)}
                                fullWidth={true}
                                getOptionLabel={(option: any) => option.displayName}
                                options={viewModel.toolboxTalkTalkerUsers}
                                placeholder="Please select user"
                                value={viewModel.talkerUser}
                                canExecute={!viewModel.isFormDisabled}
                                error={viewModel.getError("talkerUserId") !== ""}
                                validationMessage={viewModel.getError("talkerUserId")}
                            />
                        </Cell>

                        <Cell ts={8} ds={8}>
                            <TextField
                                id="pointsDiscussed-textarea"
                                multiline
                                minRows={3}
                                value={viewModel.getValue("pointsDiscussed")}
                                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => viewModel.setValue<string>("pointsDiscussed", event.target.value)}
                                fullWidth
                                className="textarea-forminput"
                                label="Points discussed:"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                placeholder={`Please describe the points discussed`}
                                disabled={false}
                                error={viewModel.getError("pointsDiscussed") !== ""}
                                helperText={viewModel.getError("pointsDiscussed")}
                            />
                        </Cell>

                        <Cell ts={4} ds={4}>
                            {!viewModel.isFormDisabled && (
                                <Uploader
                                    handleSelectFile={(e: React.ChangeEvent<HTMLInputElement>) => viewModel.fileChange(e)}
                                    isFormDisabled={viewModel.isFormDisabled || viewModel.IsLoading}
                                    label="Copy of toolbox talk"
                                    dropzoneMessage="Click here to add photo/file"
                                />
                            )}

                            {viewModel.getFiles.length > 0 && (
                                <FormLabel component="label">
                                    <span className="control-label">Copy of toolbox talk</span>
                                </FormLabel>
                            )}

                            <div className="uploadedFile">
                                {viewModel.getFiles.map((item: ToolboxTalkFileModel, index: number) => {
                                    return (
                                        <div className="uploadedFile-box">
                                            <div className="uploadedFile-heading">
                                                <IconButton onClick={() => viewModel.DownloadFile(item.fileURL, item.fileName)}>
                                                    <SystemUpdateTwoToneIcon />
                                                </IconButton>
                                                <h3>{item.fileName}</h3>
                                            </div>
                                            <IconButton onClick={() => item.handleDelete(true)} disabled={viewModel.isFormDisabled}>
                                                <DeleteOutlinedIcon />
                                            </IconButton>
                                        </div>
                                    );
                                })}
                            </div>
                        </Cell>
                        <Cell ts={8} ds={8}>
                            <TextField
                                id="comments-textarea"
                                multiline
                                minRows={5}
                                value={viewModel.getValue("comments")}
                                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => viewModel.setValue<string>("comments", event.target.value)}
                                fullWidth
                                className="textarea-forminput"
                                label="Comments and questions arising:"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                placeholder={`Please add any comments or questions`}
                                disabled={false}
                                error={viewModel.getError("comments") !== ""}
                                helperText={viewModel.getError("comments")}
                            />
                        </Cell>
                    </Grid>
                </FormSection>

                <FormSection>
                    <SolidDivider gutterBottom={true} borderThickness={2} borderOpacity={0.5} />

                    <SubtitleContainer>
                        <Typography variant="h2">Authorisation</Typography>
                    </SubtitleContainer>

                    <LegalTextContainer>
                        <Typography variant="body1">By signing this you are confirming you fully understood the tool box talk.</Typography>
                    </LegalTextContainer>

                    {/* Create a mapping on the array */}
                    {viewModel.getInductionUserViewModels.map((inductionUserViewModel, index) => {
                        return <InductionUserView key={uniqueId()} index={index} inductionUserViewModel={inductionUserViewModel} toolboxTalkFormViewModel={viewModel} />;
                    })}
                    {/*<InductionUserView key={uniqueId()} index={1} inductionUserViewModel={viewModel.getInductionUserViewModels[0]} toolboxTalkFormViewModel={viewModel} />*/}
                    <PrimaryButton execute={() => viewModel.addAnotherInductionUser()} displayName={"Add another"} canExecute={!viewModel.server.IsBusy} />
                </FormSection>

                <FormSection>
                    <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
                        {viewModel!.server.HaveValidationMessage && (
                            <>
                                <Cell ts={8} ds={8}>
                                    <Alert severity="error">
                                        <AlertTitle>Error</AlertTitle>
                                        {viewModel!.server.ValidationMessage}
                                    </Alert>
                                </Cell>
                            </>
                        )}
                    </Grid>
                </FormSection>

                {viewModel.canSaveForm && (
                    <>
                        <div style={{ margin: `0 ${pxToRem(30)} 0 ${pxToRem(30)}` }}>
                            <SolidDivider gutterBottom={true} borderThickness={2} borderOpacity={0.5} />
                        </div>
                        <FormSection>
                            {/* Buttons */}
                            <ButtonsContainer>
                                <PrimaryButton displayName="Submit" execute={() => viewModel.upsert()} fullWidth={true} canExecute={!viewModel.IsLoading} />
                                <DefaultButton displayName="Cancel" execute={() => viewModel.handleCancel()} fullWidth={true} canExecute={!viewModel.IsLoading} />
                            </ButtonsContainer>
                        </FormSection>
                    </>
                )}
            </Form>
        );
    });
};

export const ToolboxTalkFormView = styled(ToolboxTalkFormViewBase)`
    display: flex;
    flex: 1;
    flex-direction: column;

    form {
        position: relative;
    }
`;

const HeaderContainer = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 70px;

    // Header
    > div:nth-child(1) {
        margin: 4px 30px 13.5px 30px;
    }
`;

const ProjectDetailsContainer = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    max-width: 700px;

    > div {
        font-size: 12px;
    }

    > div > span {
        font-weight: bold;
    }
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;

    > button {
        max-width: 262px;
    }

    > button:nth-child(1) {
        margin-right: 30px;
    }
`;

interface SubtitleProps {
    margin?: string;
}

export const SubtitleContainer = styled.div<SubtitleProps>`
    h2 {
        margin-bottom: ${(props) => (props.margin ? props.margin : "10px")};
    }
`;

export const LegalTextContainer = styled.div`
    margin-bottom: 10px;

    p {
        font-size: 12px !important;
    }
`;

const SignatureContainer = styled.div`
    display: flex;
    flex-direction: column;

    > div {
        display: flex;
        flex-direction: row;

        > div {
            max-width: 262px;
            margin-right: 30px;
            width: 100%;

            canvas {
                border: 1px solid #d4d4d4;
            }
        }
    }
`;

const DateSelectContainer = styled.div`
    .MuiInput-input {
        min-width: 70px;
    }
`;
