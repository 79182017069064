import React, { useState } from "react";
import { useObserver } from "mobx-react-lite";
import { Box, IconButton, TextField, Typography } from "@material-ui/core";
import { BaseModal } from "Components/Modal/BaseModal";
import { CancelButton } from "Globals/Styles/Control/Buttons";
import { Button } from "@material-ui/core";
import { Cell, Grid, useRouter } from "@shoothill/core";
import { Uploader } from "Components/Uploader/Uploader";
import SystemUpdateTwoToneIcon from "@material-ui/icons/SystemUpdateTwoTone";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import styled from "styled-components";
import { ReportModalStyle } from "Views/Project/Modals/Modal.Styles";
import { MaterialStorageViewModel } from "./MaterialStorageViewModel";
import { MaterialStoragePhotosFile } from "./MaterialStorageModel";
import { DashedDivider } from "Views/PurchaseOrder/Form/Views/Dividers";
import { DarwinSelect } from "Components/AutoComplete/DarwinSelect";

interface ICreatedModalProps {
    open?: boolean;
    onClose(): void;
    date: Date;
    formId: string | null;
}

export const MaterialStorageView: React.FC<ICreatedModalProps> = (props) => {
    const { match } = useRouter();
    const { projectid } = match.params as any;
    const { open, onClose, date, formId } = props;
    const [viewModel] = useState(() => new MaterialStorageViewModel(projectid, date, formId));

    const COLUMNS8 = "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr";
    const COLUMNS2 = "1fr 1fr";
    const onSave = () => {
        let promise = viewModel.upsert();
        promise.then((response) => {
            if (response.wasSuccessful) {
                onClose();
            }
        });
    };

    return useObserver(() => (
        <BaseModal
            open={open}
            onClose={onClose}
            title={"Material storage"}
            PaperComponent={ReportModalStyle}
            actions={
                <>
                    <Button onClick={onSave} color="secondary" autoFocus variant="contained">
                        Add
                    </Button>
                    <CancelButton onClick={onClose}>Cancel</CancelButton>
                </>
            }
        >
            <DelaysModel>
                <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8} mb="35px">
                    <Cell ts={8} ds={8}>
                        <NotesText>
                            <TextField
                                id="note-textarea"
                                multiline
                                minRows={5}
                                value={viewModel.getValue("materialStorageNote")}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => viewModel.setValue("materialStorageNote", e.target.value)}
                                fullWidth
                                className="textarea-forminput"
                                label={"Note:"}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                placeholder={"Please add your note"}
                                disabled={false}
                                error={viewModel.getError("materialStorageNote") !== ""}
                                helperText={viewModel.getError("materialStorageNote")}
                                onBlur={() => viewModel.isFieldValid("materialStorageNote")}
                            />
                        </NotesText>
                    </Cell>
                    <Cell ts={8} ds={8}>
                        <FileUploader>
                            <Box gridTemplateColumns={"1fr 1fr"} display={"grid"} style={{ gap: "30px" }}>
                                <Box>
                                    <Typography
                                        style={{ marginBottom: "8px", font: "normal normal 700 12px/16px Segoe UI", letterSpacing: "-0.18px", color: "#191919" }}
                                        variant="h5"
                                    >
                                        Photo(s):
                                    </Typography>
                                    <Uploader handleSelectFile={(e: any) => viewModel.fileChange(e)} isFormDisabled={false} label="" />
                                </Box>
                                <Box>
                                    {viewModel.model.materialStoragePhotos.filter((el: any) => !el.isDeleted).length > 0 && (
                                        <Typography style={{ margin: "0 0px 8px" }} variant="h5">
                                            Photo(s) added
                                        </Typography>
                                    )}
                                    <Box className="uploadedFile">
                                        {viewModel.model.materialStoragePhotos.map((item: MaterialStoragePhotosFile, index: number) => {
                                            return (
                                                !item.isDeleted && (
                                                    <Box className="uploadedFile-box">
                                                        <Box className="uploadedFile-heading">
                                                            <IconButton onClick={() => viewModel.DownloadFile(item.photoURL, item.fileName)}>
                                                                <SystemUpdateTwoToneIcon />
                                                            </IconButton>
                                                            <h3>{item.fileName}</h3>
                                                        </Box>
                                                        <IconButton onClick={() => viewModel.deleteMaterialStoragePhotosFile(index)}>
                                                            <DeleteOutlinedIcon />
                                                        </IconButton>
                                                    </Box>
                                                )
                                            );
                                        })}
                                    </Box>
                                </Box>
                            </Box>
                        </FileUploader>
                    </Cell>
                </Grid>
                <DashedDivider gutterBottom={true} borderThickness={1} borderOpacity={1} />
                <Grid columnGap={30} rowGap={20} tc={"1fr"} dc={COLUMNS2} style={{ marginBottom: "50px" }}>
                    <DarwinSelect
                        displayName="Assign to:"
                        execute={(data: { id: string; displayName: string }) => viewModel.handleAssignToUserId(data)}
                        fullWidth={true}
                        getOptionLabel={(option: any) => option.displayName}
                        options={viewModel.assignToUserList}
                        onBlur={() => viewModel.isFieldValid("assignToUserId")}
                        placeholder="Please select"
                        value={viewModel.assignToUserType}
                        error={viewModel.getError("assignToUserId") !== ""}
                        validationMessage={viewModel.getError("assignToUserId")}
                    />
                </Grid>
            </DelaysModel>
        </BaseModal>
    ));
};

const FileUploader = styled(Box)`
    min-height: 180px;
    .fileUpload {
        background: #6c93a626;
        border: 1px dashed #6c93a6;
        font: normal normal normal 12px/14px Open Sans;
        letter-spacing: 0px;
        color: #425b66;
        padding: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 90px;
        margin: 0 !important;
    }
    .uploadedFile {
        grid-template-columns: 1fr;
        gap: 10px;
    }
`;

const NotesText = styled(Box)`
    label {
        margin-bottom: 8px !important;
        font: 700 12px / 16px "Segoe UI" !important;
        letter-spacing: -0.18px !important;
        color: rgb(25, 25, 25) !important;
        position: static;
    }
    .MuiInputBase-root {
        margin-top: 0 !important;
    }
`;

const DelaysModel = styled(Box)`
    hr {
        background-color: transparent !important;
        border-top: 1px dashed rgba(206, 212, 218, 1) !important;
    }
`;
