// Libraries
import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { KeyValuePair, Loader } from "@shoothill/core";
import { runInAction } from "mobx";

// Custom
import { DetailsPage } from "Globals/Views/DetailsPage/DetailsPage.styles";
import { ControlLabel } from "Components/Form/ControlLabel";
import { Box } from "@material-ui/core";
import moment from "moment";
import { DarwinDateSelect } from "Components/DateSelect/DarwinDateSelect";
import { PrimaryButton } from "Components/Buttons/Buttons";
import { AllProjectInvoiceReportViewModel } from "./AllProjectInvoiceReportViewModel";
import { IEItemsWrapper } from "Views/Project/Commercial/IETables/IEItemsWrapper";
import { AllProjectReportGrid } from "../Shared/AllProjectReportGrid";
import { InvoiceReportDatePickerContainer, ReportDatePickerContainer, RunReportButtonContainer } from "Views/Report/Report.styles";
import { CVRGridHeader, CVRSummaryGridView } from "../CVR/AllProjectCVRReport.styles";
import { MultiSelectView } from "Components/Select/MultiSelect";

// Styling & Images

moment.locale("en-GB", {
    week: {
        dow: 1,
    },
});

interface Props {
    getProjectStatusOptionsOptions: (includeDeleted: boolean) => KeyValuePair[];
}

export const AllProjectInvoiceReportView: React.FunctionComponent<Props> = (props: Props) => {
    const [viewModel] = useState(() => new AllProjectInvoiceReportViewModel());

    useEffect(() => {
        let options = props.getProjectStatusOptionsOptions(false);
        let statuses = options.filter((s) => s.key === "Full order");
        runInAction(() => {
            viewModel.model.projectStatusIds = statuses.map((s) => s.value);
        });
        return () => {
            viewModel.reset();
        };
    }, []);

    const onReportCurrentDateChanged = (date: string | null) => {
        viewModel.setValue("reportCurrentDate", date === null ? date : moment(date).endOf("day").toISOString());
        viewModel.setCanExportCSV(false);
    };

    const onReportPreviousDateChanged = (date: string | null) => {
        viewModel.setValue("reportPreviousDate", date === null ? date : moment(date).endOf("day").toISOString());
        viewModel.setCanExportCSV(false);
    };

    const runReport = () => {
        viewModel.runReport();
        viewModel.setCanExportCSV(true);
    };

    const exportCSV = () => {
        viewModel.generateAllProjectInvoiceReportCSV();
    };

    return useObserver(() => (
        <DetailsPage>
            <InvoiceReportDatePickerContainer>
                <ControlLabel label="Date comparison:" htmlFor="start-date" />
                <Box>
                    <Box maxWidth="300px">
                        <DarwinDateSelect
                            execute={(value: string | null) => onReportCurrentDateChanged(value)}
                            placeholder="Please select"
                            value={viewModel.model.reportCurrentDate}
                            maxDate={moment.utc().toDate()}
                            clearable
                            format="DD/MM/YYYY"
                            canExecute={!viewModel.IsLoading}
                            displayName="Current"
                            defaultToStartOfDay={false}
                        />
                    </Box>
                    <Box maxWidth="300px">
                        <DarwinDateSelect
                            execute={(value: string | null) => onReportPreviousDateChanged(value)}
                            placeholder="Please select"
                            value={viewModel.model.reportPreviousDate}
                            maxDate={moment.utc().toDate()}
                            clearable
                            format="DD/MM/YYYY"
                            canExecute={!viewModel.IsLoading}
                            displayName="Previous"
                            defaultToStartOfDay={false}
                        />
                    </Box>
                    <Box maxWidth="300px" width="100%">
                        <MultiSelectView
                            style={{ width: "100%", maxWidth: "300px", marginRight: "20px" }}
                            display="Status"
                            fieldName="projectStatusIds"
                            values={viewModel.model.projectStatusIds}
                            getOptions={props.getProjectStatusOptionsOptions}
                            handleChange={viewModel.handleProjectStatusChange}
                        />
                    </Box>
                </Box>
            </InvoiceReportDatePickerContainer>

            <RunReportButtonContainer>
                <PrimaryButton displayName={"Run report"} execute={runReport} fullWidth={false} canExecute={!viewModel.IsLoading} />
                {viewModel.reportViewModel && (
                    <PrimaryButton displayName={"Export CSV"} execute={exportCSV} fullWidth={false} canExecute={!viewModel.IsLoading && viewModel.canExportCSV} />
                )}
            </RunReportButtonContainer>

            <IEItemsWrapper>
                <div className="content" style={{ paddingTop: "30px", position: "relative", minWidth: "1715px" }}>
                    <CVRGridHeader>
                        <CVRSummaryGridView>
                            <li className="heading heading-main">
                                <div style={{ maxWidth: "280px" }}>Project</div>
                                <div>Awaiting Allocation</div>
                                <div>Awaiting Approval</div>
                                <div>Disputed</div>
                                <div>Approved</div>
                            </li>
                        </CVRSummaryGridView>
                        <CVRSummaryGridView>
                            <li className="heading">
                                <div></div>
                                <div>Previous</div>
                                <div>Current</div>
                                <div>Movement</div>
                                <div>Previous</div>
                                <div>Current</div>
                                <div>Movement</div>
                                <div>Previous</div>
                                <div>Current</div>
                                <div>Movement</div>
                                <div>Previous</div>
                                <div>Current</div>
                                <div>Movement</div>
                            </li>
                        </CVRSummaryGridView>
                    </CVRGridHeader>

                    {!viewModel.IsLoading && viewModel.reportViewModel && <AllProjectReportGrid reportViewModel={viewModel.reportViewModel} />}
                    {viewModel.IsLoading && <Loader />}
                </div>
            </IEItemsWrapper>
        </DetailsPage>
    ));
};
