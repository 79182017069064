import { ApiResult, FieldType, isNullOrUndefined, KeyValuePair, ViewModelBase } from "@shoothill/core";
import { observable, action, runInAction, computed } from "mobx";
import { IEModel, IEModelDTO } from "./IEmodels/IEModel";
import { IEListModel } from "./IEmodels/IEListModel";
import { ProjectCellHelpers } from "Globals/Models/Domain/ProjectCellEnum";
import { AppUrls } from "AppUrls";

//import { PackagesViewModel } from "./PackagesViewModel";

export class IEViewModel extends ViewModelBase<IEModel> {
    private static _instance: IEViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable public errorMessage: string = "";
    //private packagesViewModel = PackagesViewModel.Instance;

    public constructor() {
        super(new IEModel(), false);
        this.setDecorators(IEModel);
    }

    public cellArr = ProjectCellHelpers.getAllKeyValueSelectOptions();

    @action
    public loadModel(item: IEModelDTO | null) {
        if (item !== null) {
            this.model.fromDto(item);
        }
    }

    @action
    public cleanUp = () => {
        this.model.clear();
    };

    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (await this.isMyModelValid()) {
            //Do stuff here
            this.errorMessage = "Form is valid";
        } else {
            this.errorMessage = "Form is not valid";
        }
    };

    @observable
    public IEtype = "master";

    @computed
    public get ietypeValue(): string {
        // ToDo
        return this.IEtype;
    }

    @action
    public setIEtype(val: string) {
        this.IEtype = val;
    }

    @observable
    private IElink = "";

    @computed
    public get ielinkValue(): string {
        return this.ielinkValue;
    }

    @action
    public setIEvalue(val: string) {
        this.IElink = val;
    }

    public async isFieldValid(fieldName: keyof FieldType<IEModel>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    private isMyModelValid = async (): Promise<boolean> => {
        let isValid = true;

        // If you have a child form, you can call it's own viewmodels validation from here.
        /*if ((await this.addressFormViewModel.isModelValid()) === false) {
            isValid = false;
        }*/

        // Could also perform this in a loop if you have a list of child forms.
        /*for (let i = 0; i < this.shiftViewModels.length; i++) {
            let shift = this.shiftViewModels[i];

            if (shift.model.isEmptyForm === false) {
                if ((await shift.isModelValid()) === false) {
                    isValid = false;
                }
            }
        }*/

        // this will automatically call isFieldValid.
        if ((await this.isModelValid()) === false) {
            isValid = false;
        }
        return isValid;
    };

    public isIEDtoValid = async (): Promise<boolean> => {
        let isValid = false;

        if (this.isNameOrNameIdValid()) {
            console.log("isNameOrNameIdValid is valid");
            if (!isNaN(this.model.cell) && this.isThisValid(this.model.projectId)) {
                console.log("cell and projectId are valid");
                isValid = true;
            } else {
                console.log("cell and/or projectId are NOT valid");
            }
        } else {
            console.log("isNameOrNameIdValid is not valid");
        }

        return isValid;
    };

    @action
    public isNameOrNameIdValid() {
        //Either of these can be valid for it to submit
        let isNameValid = this.isThisValid(this.model.name);
        let isNameIdValid = this.isThisValid(this.model.nameId);

        let isNameOrNameIdValid = false;

        if (isNameValid || isNameIdValid) {
            isNameOrNameIdValid = true;
        }

        return isNameOrNameIdValid;
    }

    @action
    public isThisValid(val: string) {
        if (isNullOrUndefined(val)) {
            return false;
        }
        return true;
    }

    // IE List.
    @observable
    public ieListArr: IEListModel[] = [];

    @computed
    public get getIEList(): KeyValuePair[] {
        return this.ieListArr.map((c) => {
            return { value: c.description, key: c.id.toString() };
        });
    }
    // IE List end.

    @action
    public apiUpsertIE = async (): Promise<ApiResult<IEModelDTO>> => {
        let request: IEDTO = {
            id: this.model.id,
            isDeleted: false,
            originatorId: this.model.originatorId,
            projectId: this.model.projectId,
            parentId: this.model.parentId === "NEW" ? "" : this.model.parentId,
            cell: this.model.cell,
            name: this.model.name,
            nameId: this.model.nameId === "NEW" ? "" : this.model.nameId,
            type: this.model.type,
        };
        let apiResult = await this.Post<IEModelDTO>(AppUrls.Server.Projects.IncomeExpend.UpsertIE, request);
        if (apiResult.wasSuccessful) {
            runInAction(() => {
                this.model.fromDto(apiResult.payload);
            });
        } else {
            console.log(apiResult.errors);
        }
        return apiResult;
    };

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}

export interface IEDTO {
    id: string;
    isDeleted: boolean;
    originatorId: string | null;
    projectId: string | null;
    parentId: string | null;
    cell: number;
    name: string;
    nameId: string | null;
    type: string;
}

export interface EditIEModel {
    id: string;
    cell: number;
    projectId: string | null;
    parentId: string | null;
    nameId: string | null;
    name: string | null;
    isDeleted: boolean;
}
