import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { DefaultButton, PrimaryButton } from "../../Components/Buttons/Buttons";
import { SHBox } from "../../Components/Box";
import { getHistory } from "@shoothill/core";
import { AppUrls } from "../../AppUrls";

interface Props {
    //startAgain: () => void;
    nextStep: () => void;
    previousStep: () => void;
    canExecute: boolean;
    submit?: boolean;
}
export const WizardButtonsView: React.FC<Props> = observer((props: Props) => {
    return (
        <SHBox
            id={"wizardbuttons"}
            pt={"15px"}
            width={"100%"}
            height={"60px"}
            pl={"3"}
            pr={"3"}
            flexBox
            justifyContent={"center"}
            style={{ borderTop: "1px solid #707070", position: "fixed", bottom: "0", backgroundColor: "white" }}
        >
            <SHBox flexBox width={"600px"} pb={"30px"}>
                <DefaultButton displayName="Previous Step" execute={props.previousStep} fullWidth={true} canExecute={true} />
                <PrimaryButton displayName={props.submit ? "Submit" : "Next Step"} execute={props.nextStep} fullWidth={true} canExecute={props.canExecute} />
            </SHBox>
        </SHBox>
    );
});
