import { ModelBase } from "@shoothill/core";
import { IsNotEmpty, ValidateIf } from "class-validator";
import { observable } from "mobx";
import { nanoid } from "nanoid";

export class ScaffoldInspectionDetailModel extends ModelBase<ScaffoldInspectionDetailModel> {
    public KEY: string = nanoid();

    public id: string | null = null;
    @observable
    public scaffoldInspectionId: string = "";
    @observable
    public ordinal: number = 0;
    @observable
    @IsNotEmpty({ message: "Please enter a company name" })
    public companyName: string = "";
    @observable
    @IsNotEmpty({ message: "Please enter a compliance sheet number" })
    public complianceSheetNum: string = "";
    @observable
    @IsNotEmpty({ message: "Please enter a plan" })
    public scaffoldPlan: string = "";
    @observable
    @IsNotEmpty({ message: "Please select pass or fail" })
    public numberOfTies: string = "";
    @observable
    @ValidateIf((o) => o.numberOfTies === "fail")
    @IsNotEmpty({ message: "Please enter more detail" })
    public numberOfTiesDetail: string = "";
    @observable
    public numberOfTiesFileName: string = "";
    @observable
    public numberOfTiesUrl: string = "";
    @observable
    @IsNotEmpty({ message: "Please select pass or fail" })
    public numberOfLifts: string = "";
    @observable
    @ValidateIf((o) => o.numberOfLifts === "fail")
    @IsNotEmpty({ message: "Please enter more detail" })
    public numberOfLiftsDetail: string = "";
    @observable
    public numberOfLiftsFileName: string = "";
    @observable
    public numberOfLiftsUrl: string = "";
    @observable
    @IsNotEmpty({ message: "Please select pass or fail" })
    public groundFirm: string = "";
    @observable
    @ValidateIf((o) => o.groundFirm === "fail")
    @IsNotEmpty({ message: "Please enter more detail" })
    public groundFirmDetail: string = "";
    @observable
    public groundFirmFileName: string = "";
    @observable
    public groundFirmUrl: string = "";
    @observable
    @IsNotEmpty({ message: "Please select pass or fail" })
    public soleBoards: string = "";
    @observable
    @ValidateIf((o) => o.soleBoards === "fail")
    @IsNotEmpty({ message: "Please enter more detail" })
    public soleBoardsDetail: string = "";
    @observable
    public soleBoardsFileName: string = "";
    @observable
    public soleBoardsUrl: string = "";
    @observable
    @IsNotEmpty({ message: "Please select pass or fail" })
    public basePlates: string = "";
    @observable
    @ValidateIf((o) => o.basePlates === "fail")
    @IsNotEmpty({ message: "Please enter more detail" })
    public basePlatesDetail: string = "";
    @observable
    public basePlatesFileName: string = "";
    @observable
    public basePlatesUrl: string = "";
    @observable
    @IsNotEmpty({ message: "Please select pass or fail" })
    public scaffoldPlumb: string = "";
    @observable
    @ValidateIf((o) => o.scaffoldPlumb === "fail")
    @IsNotEmpty({ message: "Please enter more detail" })
    public scaffoldPlumbDetail: string = "";
    @observable
    public scaffoldPlumbFileName: string = "";
    @observable
    public scaffoldPlumbUrl: string = "";
    @observable
    @IsNotEmpty({ message: "Please select pass or fail" })
    public structureSame: string = "";
    @observable
    @ValidateIf((o) => o.structureSame === "fail")
    @IsNotEmpty({ message: "Please enter more detail" })
    public structureSameDetail: string = "";
    @observable
    public structureSameFileName: string = "";
    @observable
    public structureSameUrl: string = "";
    @observable
    @IsNotEmpty({ message: "Please select pass or fail" })
    public standardsUpright: string = "";
    @observable
    @ValidateIf((o) => o.standardsUpright === "fail")
    @IsNotEmpty({ message: "Please enter more detail" })
    public standardsUprightDetail: string = "";
    @observable
    public standardsUprightFileName: string = "";
    @observable
    public standardsUprightUrl: string = "";
    @observable
    @IsNotEmpty({ message: "Please select pass or fail" })
    public ledgersCondition: string = "";
    @observable
    @ValidateIf((o) => o.ledgersCondition === "fail")
    @IsNotEmpty({ message: "Please enter more detail" })
    public ledgersConditionDetail: string = "";
    @observable
    public ledgersConditionFileName: string = "";
    @observable
    public ledgersConditionUrl: string = "";
    @observable
    @IsNotEmpty({ message: "Please select pass or fail" })
    public transomsCondition: string = "";
    @observable
    @ValidateIf((o) => o.transomsCondition === "fail")
    @IsNotEmpty({ message: "Please enter more detail" })
    public transomsConditionDetail: string = "";
    @observable
    public transomsConditionFileName: string = "";
    @observable
    public transomsConditionUrl: string = "";
    @observable
    @IsNotEmpty({ message: "Please select pass or fail" })
    public tieSpacing: string = "";
    @observable
    @ValidateIf((o) => o.tieSpacing === "fail")
    @IsNotEmpty({ message: "Please enter more detail" })
    public tieSpacingDetail: string = "";
    @observable
    public tieSpacingFileName: string = "";
    @observable
    public tieSpacingUrl: string = "";
    @observable
    @IsNotEmpty({ message: "Please select pass or fail" })
    public facadeBracings: string = "";
    @observable
    @ValidateIf((o) => o.facadeBracings === "fail")
    @IsNotEmpty({ message: "Please enter more detail" })
    public facadeBracingsDetail: string = "";
    @observable
    public facadeBracingsFileName: string = "";
    @observable
    public facadeBracingsUrl: string = "";
    @observable
    @IsNotEmpty({ message: "Please select pass or fail" })
    public planBracings: string = "";
    @observable
    @ValidateIf((o) => o.planBracings === "fail")
    @IsNotEmpty({ message: "Please enter more detail" })
    public planBracingsDetail: string = "";
    @observable
    public planBracingsFileName: string = "";
    @observable
    public planBracingsUrl: string = "";
    @observable
    @IsNotEmpty({ message: "Please select pass or fail" })
    public crossBracings: string = "";
    @observable
    @ValidateIf((o) => o.crossBracings === "fail")
    @IsNotEmpty({ message: "Please enter more detail" })
    public crossBracingsDetail: string = "";
    @observable
    public crossBracingsFileName: string = "";
    @observable
    public crossBracingsUrl: string = "";
    @observable
    @IsNotEmpty({ message: "Please select pass or fail" })
    public guardRails: string = "";
    @observable
    @ValidateIf((o) => o.guardRails === "fail")
    @IsNotEmpty({ message: "Please enter more detail" })
    public guardRailsDetail: string = "";
    @observable
    public guardRailsFileName: string = "";
    @observable
    public guardRailsUrl: string = "";
    @observable
    @IsNotEmpty({ message: "Please select pass or fail" })
    public toeBoards: string = "";
    @observable
    @ValidateIf((o) => o.toeBoards === "fail")
    @IsNotEmpty({ message: "Please enter more detail" })
    public toeBoardsDetail: string = "";
    @observable
    public toeBoardsFileName: string = "";
    @observable
    public toeBoardsUrl: string = "";
    @observable
    @IsNotEmpty({ message: "Please select pass or fail" })
    public staircase: string = "";
    @observable
    @ValidateIf((o) => o.staircase === "fail")
    @IsNotEmpty({ message: "Please enter more detail" })
    public staircaseDetail: string = "";
    @observable
    public staircaseFileName: string = "";
    @observable
    public staircaseUrl: string = "";
    @observable
    @IsNotEmpty({ message: "Please select pass or fail" })
    public scaffoldBoards: string = "";
    @observable
    @ValidateIf((o) => o.scaffoldBoards === "fail")
    @IsNotEmpty({ message: "Please enter more detail" })
    public scaffoldBoardsDetail: string = "";
    @observable
    public scaffoldBoardsFileName: string = "";
    @observable
    public scaffoldBoardsUrl: string = "";
    @observable
    @IsNotEmpty({ message: "Please select pass or fail" })
    public scaffoldSigns: string = "";
    @observable
    @ValidateIf((o) => o.scaffoldSigns === "fail")
    @IsNotEmpty({ message: "Please enter more detail" })
    public scaffoldSignsDetail: string = "";
    @observable
    public scaffoldSignsFileName: string = "";
    @observable
    public scaffoldSignsUrl: string = "";
    @observable
    @IsNotEmpty({ message: "Please select pass or fail" })
    public loadingIn: string = "";
    @observable
    @ValidateIf((o) => o.loadingIn === "fail")
    @IsNotEmpty({ message: "Please enter more detail" })
    public loadingInDetail: string = "";
    @observable
    public loadingInFileName: string = "";
    @observable
    public loadingInUrl: string = "";
    @observable
    @IsNotEmpty({ message: "Please select pass or fail" })
    public laddersGood: string = "";
    @observable
    @ValidateIf((o) => o.laddersGood === "fail")
    @IsNotEmpty({ message: "Please enter more detail" })
    public laddersGoodDetail: string = "";
    @observable
    public laddersGoodFileName: string = "";
    @observable
    public laddersGoodUrl: string = "";
    @observable
    @IsNotEmpty({ message: "Please select pass or fail" })
    public accessGood: string = "";
    @observable
    @ValidateIf((o) => o.accessGood === "fail")
    @IsNotEmpty({ message: "Please enter more detail" })
    public accessGoodDetail: string = "";
    @observable
    public accessGoodFileName: string = "";
    @observable
    public accessGoodUrl: string = "";

    constructor() {
        super();
    }

    fromDto(dto: any): void {
        this.scaffoldInspectionId = dto.scaffoldInspectionId;
        this.ordinal = dto.ordinal;
        this.companyName = dto.companyName;
        this.complianceSheetNum = dto.complianceSheetNum;
        this.scaffoldPlan = dto.scaffoldPlan;

        this.numberOfTies = dto.numberOfTies ? "pass" : "fail";
        this.numberOfTiesDetail = dto.numberOfTiesDetail;
        this.numberOfTiesFileName = dto.numberOfTiesFileName;
        this.numberOfTiesUrl = dto.numberOfTiesUrl;

        this.numberOfLifts = dto.numberOfLifts ? "pass" : "fail";
        this.numberOfLiftsDetail = dto.numberOfLiftsDetail;
        this.numberOfLiftsFileName = dto.numberOfLiftsFileName;
        this.numberOfLiftsUrl = dto.numberOfLiftsUrl;

        this.groundFirm = dto.groundFirm ? "pass" : "fail";
        this.groundFirmDetail = dto.groundFirmDetail;
        this.groundFirmFileName = dto.groundFirmFileName;
        this.groundFirmUrl = dto.groundFirmUrl;

        this.soleBoards = dto.soleBoards ? "pass" : "fail";
        this.soleBoardsDetail = dto.soleBoardsDetail;
        this.soleBoardsFileName = dto.soleBoardsFileName;
        this.soleBoardsUrl = dto.soleBoardsUrl;

        this.basePlates = dto.basePlates ? "pass" : "fail";
        this.basePlatesDetail = dto.basePlatesDetail;
        this.basePlatesFileName = dto.basePlatesFileName;
        this.basePlatesUrl = dto.basePlatesUrl;

        this.scaffoldPlumb = dto.scaffoldPlumb ? "pass" : "fail";
        this.scaffoldPlumbDetail = dto.scaffoldPlumbDetail;
        this.scaffoldPlumbFileName = dto.scaffoldPlumbFileName;
        this.scaffoldPlumbUrl = dto.scaffoldPlumbUrl;

        this.structureSame = dto.structureSame ? "pass" : "fail";
        this.structureSameDetail = dto.structureSameDetail;
        this.structureSameFileName = dto.structureSameFileName;
        this.structureSameUrl = dto.structureSameUrl;

        this.standardsUpright = dto.standardsUpright ? "pass" : "fail";
        this.standardsUprightDetail = dto.standardsUprightDetail;
        this.standardsUprightFileName = dto.standardsUprightFileName;
        this.standardsUprightUrl = dto.standardsUprightUrl;

        this.ledgersCondition = dto.ledgersCondition ? "pass" : "fail";
        this.ledgersConditionDetail = dto.ledgersConditionDetail;
        this.ledgersConditionFileName = dto.ledgersConditionFileName;
        this.ledgersConditionUrl = dto.ledgersConditionUrl;

        this.transomsCondition = dto.transomsCondition ? "pass" : "fail";
        this.transomsConditionDetail = dto.transomsConditionDetail;
        this.transomsConditionFileName = dto.transomsConditionFileName;
        this.transomsConditionUrl = dto.transomsConditionUrl;

        this.tieSpacing = dto.tieSpacing ? "pass" : "fail";
        this.tieSpacingDetail = dto.tieSpacingDetail;
        this.tieSpacingFileName = dto.tieSpacingFileName;
        this.tieSpacingUrl = dto.tieSpacingUrl;

        this.facadeBracings = dto.facadeBracings ? "pass" : "fail";
        this.facadeBracingsDetail = dto.facadeBracingsDetail;
        this.facadeBracingsFileName = dto.facadeBracingsFileName;
        this.facadeBracingsUrl = dto.facadeBracingsUrl;

        this.planBracings = dto.planBracings ? "pass" : "fail";
        this.planBracingsDetail = dto.planBracingsDetail;
        this.planBracingsFileName = dto.planBracingsFileName;
        this.planBracingsUrl = dto.planBracingsUrl;

        this.crossBracings = dto.crossBracings ? "pass" : "fail";
        this.crossBracingsDetail = dto.crossBracingsDetail;
        this.crossBracingsFileName = dto.crossBracingsFileName;
        this.crossBracingsUrl = dto.crossBracingsUrl;

        this.guardRails = dto.guardRails ? "pass" : "fail";
        this.guardRailsDetail = dto.guardRailsDetail;
        this.guardRailsFileName = dto.guardRailsFileName;
        this.guardRailsUrl = dto.guardRailsUrl;

        this.toeBoards = dto.toeBoards ? "pass" : "fail";
        this.toeBoardsDetail = dto.toeBoardsDetail;
        this.toeBoardsFileName = dto.toeBoardsFileName;
        this.toeBoardsUrl = dto.toeBoardsUrl;

        this.staircase = dto.staircase ? "pass" : "fail";
        this.staircaseDetail = dto.staircaseDetail;
        this.staircaseFileName = dto.staircaseFileName;
        this.staircaseUrl = dto.staircaseUrl;

        this.scaffoldBoards = dto.scaffoldBoards ? "pass" : "fail";
        this.scaffoldBoardsDetail = dto.scaffoldBoardsDetail;
        this.scaffoldBoardsFileName = dto.scaffoldBoardsFileName;
        this.scaffoldBoardsUrl = dto.scaffoldBoardsUrl;

        this.scaffoldSigns = dto.scaffoldSigns ? "pass" : "fail";
        this.scaffoldSignsDetail = dto.scaffoldSignsDetail;
        this.scaffoldSignsFileName = dto.scaffoldSignsFileName;
        this.scaffoldSignsUrl = dto.scaffoldSignsUrl;

        this.loadingIn = dto.loadingIn ? "pass" : "fail";
        this.loadingInDetail = dto.loadingInDetail;
        this.loadingInFileName = dto.loadingInFileName;
        this.loadingInUrl = dto.loadingInUrl;

        this.laddersGood = dto.laddersGood ? "pass" : "fail";
        this.laddersGoodDetail = dto.laddersGoodDetail;
        this.laddersGoodFileName = dto.laddersGoodFileName;
        this.laddersGoodUrl = dto.laddersGoodUrl;

        this.accessGood = dto.accessGood ? "pass" : "fail";
        this.accessGoodDetail = dto.accessGoodDetail;
        this.accessGoodFileName = dto.accessGoodFileName;
        this.accessGoodUrl = dto.accessGoodUrl;
    }

    toDto() {
        return {
            scaffoldInspectionId: this.scaffoldInspectionId,
            ordinal: this.ordinal,
            companyName: this.companyName,
            complianceSheetNum: this.complianceSheetNum,
            scaffoldPlan: this.scaffoldPlan,

            numberOfTies: this.numberOfTies === "pass" ? true : false,
            numberOfTiesDetail: this.numberOfTiesDetail,

            numberOfLifts: this.numberOfLifts === "pass" ? true : false,
            numberOfLiftsDetail: this.numberOfLiftsDetail,

            groundFirm: this.groundFirm === "pass" ? true : false,
            groundFirmDetail: this.groundFirmDetail,

            soleBoards: this.soleBoards === "pass" ? true : false,
            soleBoardsDetail: this.soleBoardsDetail,

            basePlates: this.basePlates === "pass" ? true : false,
            basePlatesDetail: this.basePlatesDetail,

            scaffoldPlumb: this.scaffoldPlumb === "pass" ? true : false,
            scaffoldPlumbDetail: this.scaffoldPlumbDetail,

            structureSame: this.structureSame === "pass" ? true : false,
            structureSameDetail: this.structureSameDetail,

            standardsUpright: this.standardsUpright === "pass" ? true : false,
            standardsUprightDetail: this.standardsUprightDetail,

            ledgersCondition: this.ledgersCondition === "pass" ? true : false,
            ledgersConditionDetail: this.ledgersConditionDetail,

            transomsCondition: this.transomsCondition === "pass" ? true : false,
            transomsConditionDetail: this.transomsConditionDetail,

            tieSpacing: this.tieSpacing === "pass" ? true : false,
            tieSpacingDetail: this.tieSpacingDetail,

            facadeBracings: this.facadeBracings === "pass" ? true : false,
            facadeBracingsDetail: this.facadeBracingsDetail,

            planBracings: this.planBracings === "pass" ? true : false,
            planBracingsDetail: this.planBracingsDetail,

            crossBracings: this.crossBracings === "pass" ? true : false,
            crossBracingsDetail: this.crossBracingsDetail,

            guardRails: this.guardRails === "pass" ? true : false,
            guardRailsDetail: this.guardRailsDetail,

            toeBoards: this.toeBoards === "pass" ? true : false,
            toeBoardsDetail: this.toeBoardsDetail,

            staircase: this.staircase === "pass" ? true : false,
            staircaseDetail: this.staircaseDetail,

            scaffoldBoards: this.scaffoldBoards === "pass" ? true : false,
            scaffoldBoardsDetail: this.scaffoldBoardsDetail,

            scaffoldSigns: this.scaffoldSigns === "pass" ? true : false,
            scaffoldSignsDetail: this.scaffoldSignsDetail,

            loadingIn: this.loadingIn === "pass" ? true : false,
            loadingInDetail: this.loadingInDetail,

            laddersGood: this.laddersGood === "pass" ? true : false,
            laddersGoodDetail: this.laddersGoodDetail,

            accessGood: this.accessGood === "pass" ? true : false,
            accessGoodDetail: this.accessGoodDetail,

            numberOfTiesFileName: this.numberOfTiesFileName,
            numberOfTiesUrl: this.numberOfTiesUrl,

            numberOfLiftsFileName: this.numberOfLiftsFileName,
            numberOfLiftsUrl: this.numberOfLiftsUrl,

            groundFirmFileName: this.groundFirmFileName,
            groundFirmUrl: this.groundFirmUrl,

            soleBoardsFileName: this.soleBoardsFileName,
            soleBoardsUrl: this.soleBoardsUrl,

            basePlatesFileName: this.basePlatesFileName,
            basePlatesUrl: this.basePlatesUrl,

            scaffoldPlumbFileName: this.scaffoldPlumbFileName,
            scaffoldPlumbUrl: this.scaffoldPlumbUrl,

            structureSameFileName: this.structureSameFileName,
            structureSameUrl: this.structureSameUrl,

            standardsUprightFileName: this.standardsUprightFileName,
            standardsUprightUrl: this.standardsUprightUrl,

            ledgersConditionFileName: this.ledgersConditionFileName,
            ledgersConditionUrl: this.ledgersConditionUrl,

            transomsConditionFileName: this.transomsConditionFileName,
            transomsConditionUrl: this.transomsConditionUrl,

            tieSpacingFileName: this.tieSpacingFileName,
            tieSpacingUrl: this.tieSpacingUrl,

            facadeBracingsFileName: this.facadeBracingsFileName,
            facadeBracingsUrl: this.facadeBracingsUrl,

            planBracingsFileName: this.planBracingsFileName,
            planBracingsUrl: this.planBracingsUrl,

            crossBracingsFileName: this.crossBracingsFileName,
            crossBracingsUrl: this.crossBracingsUrl,

            guardRailsFileName: this.guardRailsFileName,
            guardRailsUrl: this.guardRailsUrl,

            toeBoardsFileName: this.toeBoardsFileName,
            toeBoardsUrl: this.toeBoardsUrl,

            staircaseFileName: this.staircaseFileName,
            staircaseUrl: this.staircaseUrl,

            scaffoldBoardsFileName: this.scaffoldBoardsFileName,
            scaffoldBoardsUrl: this.scaffoldBoardsUrl,

            scaffoldSignsFileName: this.scaffoldSignsFileName,
            scaffoldSignsUrl: this.scaffoldSignsUrl,

            loadingInLineFileName: this.loadingInFileName,
            loadingInLineUrl: this.loadingInUrl,

            laddersGoodFileName: this.laddersGoodFileName,
            laddersGoodUrl: this.laddersGoodUrl,

            accessGoodFileName: this.accessGoodFileName,
            accessGoodUrl: this.accessGoodUrl,
        };
    }
}
