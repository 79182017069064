import React, { useState } from "react";
import { useObserver } from "mobx-react-lite";
import { Box, IconButton, TextField, Typography } from "@material-ui/core";
import { BaseModal } from "Components/Modal/BaseModal";
import { CancelButton } from "Globals/Styles/Control/Buttons";
import { Button } from "@material-ui/core";
import { Cell, Grid, useRouter } from "@shoothill/core";
import { CleanlinessReportViewModel } from "./CleanlinessReportViewModel";
import { DarwinSelect } from "Components/AutoComplete/DarwinSelect";
import { Uploader } from "Components/Uploader/Uploader";
import SystemUpdateTwoToneIcon from "@material-ui/icons/SystemUpdateTwoTone";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import styled from "styled-components";
import { ReportModalStyle } from "Views/Project/Modals/Modal.Styles";
import { CleanlinessReportPhotosPhotoFile } from "./CleanlinessReportModel";

interface ICreatedModalProps {
    open?: boolean;
    onClose(): void;
    date: Date;
    formId: string | null;
}

export const CleanlinessReportView: React.FC<ICreatedModalProps> = (props) => {
    const { match } = useRouter();
    const { projectid } = match.params as any;
    const { open, onClose, date, formId } = props;
    const [viewModel] = useState(() => new CleanlinessReportViewModel(projectid, date, formId));
    const COLUMNS8 = "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr";
    const StatusBox = styled(Box)`
        .MuiFormControl-root {
            .MuiInputBase-root {
                background-color: ${viewModel.setBackGroundStatus(viewModel.model.cleanlinessReportStatusId)} !important;
                color: ${viewModel.setColorStatus(viewModel.model.cleanlinessReportStatusId)} !important;
            }
        }
    `;

    const onSave = () => {
        let promise = viewModel.upsert();
        promise.then((response) => {
            if (response.wasSuccessful) {
                onClose();
            }
        });
    };

    return useObserver(() => (
        <BaseModal
            open={open}
            onClose={onClose}
            title={"Cleanliness report"}
            PaperComponent={ReportModalStyle}
            actions={
                <>
                    <Button onClick={onSave} color="secondary" autoFocus variant="contained">
                        Add
                    </Button>
                    <CancelButton onClick={onClose}>Cancel</CancelButton>
                </>
            }
        >
            <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8} mb="35px">
                <Cell ts={4} ds={4}>
                    <TextField
                        type="text"
                        value={viewModel.getValue("cleanlinessSubContractorName")}
                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => viewModel.setValue("cleanlinessSubContractorName", e.target.value)}
                        error={viewModel.getError("cleanlinessSubContractorName") !== ""}
                        fullWidth
                        label={"Sub contractor"}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onBlur={() => viewModel.isFieldValid("cleanlinessSubContractorName")}
                        placeholder={"Sub contractor"}
                        helperText={viewModel.getError("cleanlinessSubContractorName")}
                    />
                </Cell>

                <Cell ts={2} ds={2}>
                    <StatusBox>
                        <DarwinSelect
                            displayName="Status:"
                            execute={(data: { id: string; displayName: string }) => viewModel.handleCleanlinessStatusId(data)}
                            fullWidth={true}
                            getOptionLabel={(option: any) => option.displayName}
                            options={viewModel.cleanlinessReportStatus}
                            placeholder="Please select"
                            value={viewModel.status}
                            onBlur={() => viewModel.isFieldValid("cleanlinessReportStatusId")}
                            error={viewModel.getError("cleanlinessReportStatusId") !== ""}
                            validationMessage={viewModel.getError("cleanlinessReportStatusId")}
                        />
                    </StatusBox>
                </Cell>

                <Cell ts={8} ds={8}>
                    <TextField
                        id="note-textarea"
                        multiline
                        minRows={5}
                        value={viewModel.getValue("cleanlinessNoteDetails")}
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => viewModel.setValue<string>("cleanlinessNoteDetails", event.target.value)}
                        fullWidth
                        className="textarea-forminput"
                        label="Note:"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        placeholder={`Please add your note`}
                        disabled={false}
                        error={viewModel.getError("cleanlinessNoteDetails") !== ""}
                        helperText={viewModel.getError("cleanlinessNoteDetails")}
                        onBlur={() => viewModel.isFieldValid("cleanlinessNoteDetails")}
                    />
                </Cell>
                <Cell ts={8} ds={8}>
                    <FileUploader>
                        <Box gridTemplateColumns={"1fr 1fr"} display={"grid"} style={{ gap: "30px" }}>
                            <Box>
                                <Typography style={{ marginBottom: "8px", font: "normal normal 700 12px/16px Segoe UI", letterSpacing: "-0.18px", color: "#191919" }} variant="h5">
                                    Photo(s):
                                </Typography>
                                <Uploader handleSelectFile={(e: any) => viewModel.fileChange(e)} isFormDisabled={false} label="" />
                            </Box>
                            <Box>
                                {viewModel.model.cleanlinessReportPhotos.filter((el: any) => !el.isDeleted).length > 0 && (
                                    <Typography style={{ margin: "0 0px 8px" }} variant="h5">
                                        Photo(s) added
                                    </Typography>
                                )}
                                <Box className="uploadedFile">
                                    {viewModel.model.cleanlinessReportPhotos.map((item: CleanlinessReportPhotosPhotoFile, index: number) => {
                                        return (
                                            !item.isDeleted && (
                                                <Box className="uploadedFile-box">
                                                    <Box className="uploadedFile-heading">
                                                        <IconButton onClick={() => viewModel.DownloadFile(item.photoURL, item.fileName)}>
                                                            <SystemUpdateTwoToneIcon />
                                                        </IconButton>
                                                        <h3>{item.fileName}</h3>
                                                    </Box>
                                                    <IconButton onClick={() => viewModel.deleteCleanlinessReportFile(index)}>
                                                        <DeleteOutlinedIcon />
                                                    </IconButton>
                                                </Box>
                                            )
                                        );
                                    })}
                                </Box>
                            </Box>
                        </Box>
                    </FileUploader>
                </Cell>
            </Grid>
        </BaseModal>
    ));
};

const FileUploader = styled(Box)`
    .fileUpload {
        background: #6c93a626;
        border: 1px dashed #6c93a6;
        font: normal normal normal 12px/14px Open Sans;
        letter-spacing: 0px;
        color: #425b66;
        padding: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 90px;
        margin: 0 !important;
    }
    .uploadedFile {
        grid-template-columns: 1fr;
        gap: 10px;
    }
`;
