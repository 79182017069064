import { Theme } from "@material-ui/core";

export enum ProjectValuationFrequency {
    FourteenDays = 100,
    TwentyEightDays = 200,
    XDays = 300,
    Monthly = 400,
}

export class ProjectValuationFrequencyHelpers {
    public static getText = (status: ProjectValuationFrequency) => {
        switch (status) {
            case ProjectValuationFrequency.Monthly: {
                return "Monthly on that date";
            }
            case ProjectValuationFrequency.XDays: {
                return "X Days";
            }
            case ProjectValuationFrequency.TwentyEightDays: {
                return "28 days";
            }
            case ProjectValuationFrequency.FourteenDays:
            default: {
                return "14 Days";
            }
        }
    };

    public static getClassName = (status: ProjectValuationFrequency) => {
        switch (status) {
            case ProjectValuationFrequency.Monthly: {
                return "projectfreq-month";
            }
            case ProjectValuationFrequency.XDays: {
                return "projectfreq-xdays";
            }
            case ProjectValuationFrequency.TwentyEightDays: {
                return "projectfreq-twentyeight";
            }
            case ProjectValuationFrequency.FourteenDays:
            default: {
                return "projectfreq-fourteen";
            }
        }
    };

    public static getAllOptions = (/* ignoreUnknown: boolean = false */) => {
        const options: {
            label: string;
            value: string;
        }[] = [];

        for (const key in ProjectValuationFrequency) {
            const keyAsInt = parseInt(key);

            if (isNaN(keyAsInt) === false) {
                const status: ProjectValuationFrequency = keyAsInt as ProjectValuationFrequency;

                /* if (ignoreUnknown === false || status !== ProjectValuationFrequency.Unknown) { */
                options.push({
                    label: ProjectValuationFrequencyHelpers.getText(status),
                    value: keyAsInt.toString(),
                });
                /*  } */
            }
        }
        return options;
    };

    public static getAllSelectOptions = (/* ignoreUnknown: boolean = false) */) => {
        const options: {
            label: string;
            value: string;
        }[] = [];

        for (const key in ProjectValuationFrequency) {
            const keyAsInt = parseInt(key);

            if (isNaN(keyAsInt) === false) {
                const status: ProjectValuationFrequency = keyAsInt as ProjectValuationFrequency;

                /*   if (ignoreUnknown === false || status !== ProjectCell.Unknown) { */
                options.push({
                    label: ProjectValuationFrequencyHelpers.getText(status),
                    value: keyAsInt.toString(),
                });
                /*   } */
            }
        }
        return options;
    };

    public static getProjectCellColour = (status: ProjectValuationFrequency, theme: Theme) => {
        switch (status) {
            case ProjectValuationFrequency.Monthly: {
                return theme.palette.common.white[300];
            }
            case ProjectValuationFrequency.XDays: {
                return theme.palette.common.white[300];
            }
            case ProjectValuationFrequency.TwentyEightDays: {
                return theme.palette.common.white[300];
            }
            case ProjectValuationFrequency.FourteenDays:
            default: {
                return theme.palette.common.white[300];
            }
        }
    };

    public static getCellTextColour = (status: ProjectValuationFrequency) => {
        /*         switch (status) {
            case ProjectStatus.All:
                return "#FFFFFF";
            case ProjectStatus.Unknown:
            default: */
        return "#000000";
        //}
    };

    public static getAllKeyValueSelectOptions = (/* ignoreUnknown: boolean = false */) => {
        const options: {
            key: string;
            value: string;
        }[] = [];

        for (const key in ProjectValuationFrequency) {
            const keyAsInt = parseInt(key);

            if (isNaN(keyAsInt) === false) {
                const status: ProjectValuationFrequency = keyAsInt as ProjectValuationFrequency;

                /*  if (ignoreUnknown === false || status !== ProjectCell.Unknown) { */
                options.push({
                    key: ProjectValuationFrequencyHelpers.getText(status),
                    value: keyAsInt.toString(),
                });
                /*  } */
            }
        }
        return options;
    };
}
