/* eslint-disable prettier/prettier */
import { Stores } from "Globals/Stores";
import { RoleTypeEnum } from "Globals/Stores/Domain/Admin/RoleStore";

export const requireAnyLoggedIn = (store: Stores): boolean => {
    let retVal = false;

    if (
        store.Domain.AccountStore.containsRoles([
            RoleTypeEnum.Admin,
            RoleTypeEnum.CellHead,
            RoleTypeEnum.Commdir,
            RoleTypeEnum.ExecTeam,
            RoleTypeEnum.Managers,
            RoleTypeEnum.General,
            RoleTypeEnum.CEOCOO,
            RoleTypeEnum.SiteManager,
            RoleTypeEnum.SiteTablet,
            RoleTypeEnum.Finance,
            RoleTypeEnum.Level1Commercial,
        ])
    ) {
        retVal = true;
    }

    return retVal;
};

export const requireAdminLogin = (store: Stores): boolean => {
    let retVal = false;

    if (store.Domain.AccountStore.containsRoles([RoleTypeEnum.Admin])) {
        retVal = true;
    }

    return retVal;
};

export const requireNoLoggedIn = (store: Stores): boolean => {
    return true;
};

export const requireNotSiteManagerLoggedIn = (store: Stores): boolean => {
    let retVal = false;

    if (requireAnyLoggedIn(store) && !store.Domain.AccountStore.containsRoles([RoleTypeEnum.SiteManager])) {
        retVal = true;
    }

    return retVal;
};

export const canViewAdminSettings = (store: Stores): boolean => {
    let retVal = false;

    if (requireAnyLoggedIn(store) && store.Domain.AccountStore.getCanViewSettings) {
        retVal = true;
    }

    return retVal;
};

export const canViewInductions = (store: Stores): boolean => {
    let retVal = false;

    if (requireAnyLoggedIn(store) && store.Domain.AccountStore.getCanViewInductions) {
        retVal = true;
    }

    return retVal;
};

export const canEditProjects = (store: Stores): boolean => {
    let retVal = false;

    if (requireAnyLoggedIn(store) && store.Domain.AccountStore.getCanEditProject) {
        retVal = true;
    }

    return retVal;
};
