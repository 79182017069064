import { ModelBase } from "@shoothill/core";
import { Equals, IsNotEmpty, ValidateIf } from "class-validator";
import { action, observable } from "mobx";

import { InductionSectionType } from "Globals/Models/Enums/InductionSectionType";
import { InductionType } from "Globals/Models/Enums/InductionType";
import { QualificationModel } from "../Common/QualificationModel";

export class Step2Model extends ModelBase<Step2Model> {
    // #region Defaults

    public static readonly DEFAULT_INDUCTIONTYPEID = null;
    public static readonly DEFAULT_INDUCTIONTYPES = [];
    public static readonly DEFAULT_QUALIFICATIONS = [];

    // #endregion Defaults

    // #region Properties

    public declinedAge: boolean = false;

    public inductionTypeId: string | null = Step2Model.DEFAULT_INDUCTIONTYPEID;

    @observable
    public inductionTypes = observable<InductionType>(Step2Model.DEFAULT_INDUCTIONTYPES);

    @observable
    public skillsTrainingInductionSectionType: InductionSectionType | null = null;

    @observable
    public qualifications = observable<QualificationModel>(Step2Model.DEFAULT_QUALIFICATIONS);

    @observable
    @IsNotEmpty({ message: "You must select a value" })
    @ValidateIf((o) => o.declinedAge)
    public confirmOver18: string = "";

    @observable
    // Has been removed as a form input, so default to true.
    public confirmAdjustments: string = "true";

    @observable
    @IsNotEmpty({ message: "You must select a value" })
    public prescribed: string = "";

    @observable
    @IsNotEmpty({ message: "You must select a value" })
    public healthProblems: string = "";

    @observable
    @IsNotEmpty({ message: "You must agree to continue" })
    @Equals("true", { message: "You must select Agree to continue." })
    public notifyManager: string = "";

    @observable
    @ValidateIf((o) => o.healthProblems === "true")
    @IsNotEmpty({ message: "You must add details about your health problems" })
    public provideHealthDetails: string = "";

    @observable
    @ValidateIf((o) => o.prescribed === "true")
    @IsNotEmpty({ message: "You must add details about your medication and its affects" })
    public providePrescribedDetails: string = "";

    @observable
    @ValidateIf((o) => o.confirmAdjustments === "true" && o.confirmOver18 === "false")
    @IsNotEmpty({ message: "You must add details about your adjustments" })
    public provideAdjustmentDetails: string = "";

    // #endregion Properties

    constructor() {
        super();
    }

    // #region Actions

    @action
    public fromDto(dto: any): void {
        throw new Error("Method not implemented.");
    }

    public toDto(): any {
        throw new Error("Method not implemented.");
    }

    public toDependentDto(inductionType: InductionType): any {
        let qualifications = [];

        switch (true) {
            case inductionType.IsOfType(InductionType.Full):
                qualifications = this.qualifications.map((q) => q.toDto());
                break;

            case inductionType.IsOfType(InductionType.Visitor):
            default:
                qualifications = Step2Model.DEFAULT_QUALIFICATIONS;
                break;
        }

        return {
            confirmOver18: this.confirmOver18 === "true",
            confirmAdjustments: this.confirmAdjustments === "true",
            healthProblems: this.healthProblems === "true",
            provideHealthDetails: this.provideHealthDetails,
            prescribed: this.prescribed === "true",
            providePrescribedDetails: this.providePrescribedDetails,
            provideAdjustmentDetails: this.provideAdjustmentDetails,
            qualifications: qualifications,
        };
    }

    // #endregion Actions
}
