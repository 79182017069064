import clsx from "clsx";
import { observer } from "mobx-react-lite";

import { DashedDivider } from "Views/PurchaseOrder/Form/Views/Dividers";
import { ApprovalHistoryInvoiceItemViewModel } from "./ApprovalHistoryInvoiceItemViewModel";
import { ApprovalHistoryInvoiceContainer } from "./ApprovalHistoryInvoiceView.styles";

interface IProps {
    //viewModel: ApprovalHistoryInvoiceViewModel;
    approvalHistoryInvoiceItemViewModels: ApprovalHistoryInvoiceItemViewModel[];
}

export const ApprovalHistoryInvoiceView: React.FC<IProps> = observer((props: IProps) => {
    const itemClassNames = (vm: ApprovalHistoryInvoiceItemViewModel, index: number) => {
        return clsx({
            ["item-odd"]: index % 2 !== 0,
            [`item-type-${vm.type}`]: true,
        });
    };

    return (
        <ApprovalHistoryInvoiceContainer>
            <h1>History</h1>
            <ol>
                {props.approvalHistoryInvoiceItemViewModels.map((vm, index) => {
                    return (
                        <>
                            <li className={itemClassNames(vm, index)} key={vm.updatedDate}>
                                <div className="item-header">
                                    <div>{vm.updateBy}</div>
                                    <div>{vm.formattedDateTime}</div>
                                </div>
                                <div className="item-body">
                                    <div className="item-body-status">{vm.formattedType}</div>
                                    {vm.canDisplayNotes && <div className="item-body-notes">{vm.notes}</div>}
                                </div>
                            </li>
                        </>
                    );
                })}
            </ol>
            <DashedDivider gutterTop />
        </ApprovalHistoryInvoiceContainer>
    );
});
