import { EnumType, IEnumType } from "./Base/EnumType";

export class StockTransactionRequestStatusType extends EnumType {
    // #region Constants

    public static readonly SubmittedForApproval = "SUBMITTEDFORAPPROVAL";
    public static readonly Approved = "APPROVED";
    public static readonly Rejected = "REJECTED";

    // #endregion Constants

    // #region Properties
    // #endregion Properties

    // #region Methods

    public fromDto(dto: IStockTransactionRequestStatusType) {
        super.fromDto(dto);
    }

    // #endregion Methods
}

export interface IStockTransactionRequestStatusType extends IEnumType {}
