import * as React from "react";
import { Button, TextField } from "@material-ui/core";
import styled from "styled-components";

import { BaseModal } from "Components/Modal/BaseModal";
import { CancelButton } from "Globals/Styles/Control/Buttons";
import { useObserver } from "mobx-react-lite";
import { EditContactModalDialogPaper } from "Views/Contacts/EditContactModal.styles";

interface ISendForApprovalProps {
    open?: boolean;
    onClose(): void;
    onSave(): any;
    title: string;
    confirmText: string;
    isDisabled: boolean;
    isLoading: boolean;
    value: string;
    setValue: (val: string) => void;
}

export const SendForApprovalModal: React.FunctionComponent<ISendForApprovalProps> = ({ open, onClose, onSave, title, confirmText, isLoading, isDisabled, setValue, value }) => {
    return useObserver(() => (
        <BaseModal
            open={open}
            onClose={onClose}
            title={title}
            PaperComponent={SendForApprovalModalDialogPaper}
            actions={
                <>
                    <Button onClick={onSave} color="secondary" autoFocus variant="contained" disabled={isDisabled || isLoading}>
                        {confirmText}
                    </Button>
                    <CancelButton onClick={onClose}>Cancel</CancelButton>
                </>
            }
        >
            <TextField
                id="amend-reason"
                multiline
                rows={10}
                value={value}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setValue(event.target.value)}
                fullWidth
                className="textarea"
                placeholder="Please add what has changed and the reason why it has been edited"
            />
        </BaseModal>
    ));
};

export const SendForApprovalModalDialogPaper = styled(EditContactModalDialogPaper)`
    width: 100% !important;

    .MuiDialogActions-root > button {
        max-width: 262px;
    }

    .MuiDialogContent-root {
        margin-bottom: 20px;
    }
`;
