import { Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { AppUrls } from "AppUrls";
import { ClassNameMap } from "@material-ui/styles";
import { BreadcrumbViewModel } from "../BreadcrumbViewModel";

export const adminClientMatchSection1 = (classes: ClassNameMap<"root" | "common" | "prev" | "heading" | "main" | "secondary" | "lists" | "nested">): any => {
    return (
        <>
            <Link component={RouterLink} to={AppUrls.Client.Admin.User.List}>
                <div className={classes.prev}>Users</div>
            </Link>

            <Link component={RouterLink} to={AppUrls.Client.Admin.Client.List}>
                <div className={classes.main}>
                    Clients
                    <span className="triangle"></span>
                </div>
            </Link>
        </>
    );
};

export const adminClientMatchSection2 = (
    viewModel: BreadcrumbViewModel,
    classes: ClassNameMap<"root" | "common" | "prev" | "heading" | "main" | "secondary" | "lists" | "nested">,
    plusSign: any,
): any => {
    return (
        <div className={classes.secondary}>
            <div onClick={viewModel.onAddClient}>
                <img src={plusSign} className="add-image" alt="Add new client" />
                <span className="add-text">Add client</span>
            </div>
        </div>
    );
};

export const adminClientDetailMatchSection1 = (classes: ClassNameMap<"root" | "common" | "prev" | "heading" | "main" | "secondary" | "lists" | "nested">, arrowSign: any): any => {
    return (
        <>
            <Link component={RouterLink} to={AppUrls.Client.Admin.User.List}>
                <div className={classes.prev}>Users</div>
            </Link>

            <Link component={RouterLink} to={AppUrls.Client.Admin.Client.List} className={classes.prev}>
                Clients
            </Link>

            <Link component={RouterLink} to={AppUrls.Client.Admin.Client.Detail} className="secondary-text">
                <div className={classes.main}>
                    <img src={arrowSign} className="add-image" alt="Detail" />
                    Detail
                    <span className="triangle"></span>
                </div>
            </Link>
        </>
    );
};

export const adminClientDetailMatchSection2 = (
    viewModel: BreadcrumbViewModel,
    classes: ClassNameMap<"root" | "common" | "prev" | "heading" | "main" | "secondary" | "lists" | "nested">,
    addEditImg: any,
    exportImg: any,
): any => {
    return (
        <div className={classes.secondary}>
            <div onClick={viewModel.onEditClient}>
                <img src={addEditImg} className="add-image" alt="Edit client" />
                <span className="add-text">Edit client</span>
            </div>
            <div onClick={viewModel.onClientCSV}>
                <img src={exportImg} className="add-image" alt="Export CSV" />
                <span className="add-text">Export as CSV</span>
            </div>
        </div>
    );
};
