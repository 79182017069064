import { FieldType, isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import type { ApiResult, ValidationResponse } from "@shoothill/core";
import { action, computed, observable, runInAction } from "mobx";

import { AppUrls } from "AppUrls";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { DetailsHeaderModel } from "Globals/Views/DetailsPage/DetailsHeaderModel";
import { IEFutureSpendAmdnementDTO, IEFutureSpendAmendmentAndRelatedResponseDTO, IEFutureSpendAmendmentModel } from "./IEFutureSpendAmendmentModel";
import { StoresInstance } from "Globals/Stores";
import { RoleLevelEnum } from "Globals/Stores/Domain/Admin";
import { ChangeEvent } from "react";

export class IEFutureSpendAmendmentViewModel extends ViewModelBase<IEFutureSpendAmendmentModel> {
    // #region Constructors and Disposers

    constructor(ieId: string, ieItemId: string) {
        super(new IEFutureSpendAmendmentModel());
        this.setDecorators(IEFutureSpendAmendmentModel);

        this.model.ieId = ieId;
        this.model.ieItemId = ieItemId;

        console.log(ieId);
        console.log(ieItemId);

        this.loadFutureSpendAndAmdnement(ieId, ieItemId);
    }

    // #endregion Constructors and Disposers

    @observable
    private ieTitle: string = "";

    @action setIETitle = (val: string) => {
        this.ieTitle = val;
    };

    @computed
    public get getIETitle(): string {
        return this.ieTitle;
    }

    /**
     * Header
     */
    @computed
    get getHeader(): DetailsHeaderModel {
        const retVal: DetailsHeaderModel = new DetailsHeaderModel();

        if (this.categoryName === "" || this.subCategoryName === "" || this.lineDescriptionName === "") {
            retVal.setValue("title", "");
        } else {
            retVal.setValue("title", `${this.categoryName} > ${this.subCategoryName} > ${this.lineDescriptionName}`);
        }

        retVal.setValue("subTitle", "Future spend");

        return retVal;
    }

    @computed
    public get canAddFutureSpendAmendment(): boolean {
        // Only users with role CommDirector and above can add amendments.
        const roleLevel = StoresInstance.Domain.AccountStore.UserRoleLevel;
        return roleLevel === null || roleLevel >= RoleLevelEnum.CommDir;
    }

    @observable
    private ieFutureSpendAmendmentItems = observable<IEFutureSpendAmdnementDTO>([]); // Should probably update to viewmodel.

    @computed
    public get ieFutureSpendAmendmentItemsFiltered(): IEFutureSpendAmdnementDTO[] {
        if (this.filterNoImpactItems) {
            return this.ieFutureSpendAmendmentItems.filter(
                (i) => !(i.revision !== null && i.revision !== "" && i.orderValueDifference === 0 && i.previousFutureSpend === i.futureSpend),
            );
        }
        return this.ieFutureSpendAmendmentItems;
    }

    @observable
    public filterNoImpactItems: boolean = false;

    @action
    public setFilterNoImpactItems = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        this.filterNoImpactItems = checked;
    };

    @observable
    public showMatchingUnapprovedRequisitionsModal: boolean = false;

    @observable
    public matchingUnapprovedRequisitions: { id: string; name: string; requestedByUserName: string }[] = [];

    @action
    public handleShowMatchingUnapprovedRequisitionsModalChange(show: boolean) {
        this.showMatchingUnapprovedRequisitionsModal = show;

        if (!show) {
            this.setMatchingUnapprovedRequisitions([]);
        }
    }

    @action
    public setMatchingUnapprovedRequisitions(items: { id: string; name: string; requestedByUserName: string }[]) {
        this.matchingUnapprovedRequisitions = items;
    }

    @computed
    public get getShowMatchingUnapprovedRequisitionsModal() {
        return this.showMatchingUnapprovedRequisitionsModal;
    }

    // #region Properties

    public server: ServerViewModel = new ServerViewModel();

    // #endregion Properties

    // #region Actions

    @action
    public setNote = (val: string) => {
        this.model.note = val;
    };

    @observable private categoryName: string = "";
    @observable private subCategoryName: string = "";
    @observable private lineDescriptionName: string = "";

    @action
    public loadFutureSpendAndAmdnement = (ieId: string, ieItemId: string): Promise<void> => {
        return this.server.query<IEFutureSpendAmendmentAndRelatedResponseDTO>(
            () => this.Get(`${AppUrls.Server.Projects.IncomeExpend.GetIEFutureSpendAmendmentAndRelated}\\${ieId}\\${ieItemId}`),
            (result) => {
                runInAction(() => {
                    this.ieFutureSpendAmendmentItems.replace(result.ieFutureSpendAmendmentItems);

                    this.categoryName = result.categoryName;
                    this.subCategoryName = result.subCategoryName;
                    this.lineDescriptionName = result.lineDescriptionName;

                    this.setIETitle(result.ieTitle);
                });
            },
        );
    };

    public handleSave = async (): Promise<void> => {
        if (this.canAddFutureSpendAmendment) {
            await this.save();
        }
    };

    private save = async (): Promise<ApiResult<IEFutureSpendAmendmentAndRelatedResponseDTO>> => {
        let dto = this.model.toDto();

        const result = await this.Post<IEFutureSpendAmendmentAndRelatedResponseDTO>(AppUrls.Server.Projects.IncomeExpend.UpsertFutureSpendAmendment, dto).then((apiResult) => {
            if (!apiResult.wasSuccessful) {
                // console.log(apiResult.errors);
                runInAction(() => {
                    console.log(apiResult.errors);
                    this.setSnackMessage("There was an error trying to add the amendment");
                    this.setSnackType(this.SNACKERROR);
                    this.setSnackbarState(true);
                });

                if (apiResult.errors.length > 0) {
                    if (apiResult.errors[0].message === "Lines match existing unapproved requisition." && apiResult.payload.matchingUnapprovedRequisitions.length > 0) {
                        runInAction(() => {
                            this.handleShowMatchingUnapprovedRequisitionsModalChange(true);
                            this.setMatchingUnapprovedRequisitions(apiResult.payload.matchingUnapprovedRequisitions);
                        });
                    }
                }
            } else {
                runInAction(() => {
                    this.ieFutureSpendAmendmentItems.replace(apiResult.payload.ieFutureSpendAmendmentItems);

                    this.categoryName = apiResult.payload.categoryName;
                    this.subCategoryName = apiResult.payload.subCategoryName;
                    this.lineDescriptionName = apiResult.payload.lineDescriptionName;

                    this.model.reset();
                });
            }

            return apiResult;
        });

        return result;
    };

    // #endregion Actions

    @computed
    private get validateFutureSpend(): ValidationResponse {
        const errorMessage = this.model.validateFutureSpend;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateNote(): ValidationResponse {
        const errorMessage = this.model.validateNote;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<IEFutureSpendAmendmentModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        if (this.server.IsSubmitted) {
            // Process the properties of the model that cannot be supported via
            // the use of decorators.
            switch (fieldName) {
                case "futureSpend": {
                    const result = this.validateFutureSpend;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }

                case "note": {
                    const result = this.validateNote;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
            }
        } else {
            // Do not validate if the properties of the model have not been
            // submitted to the server.
            errorMessage = "";
            isValid = true;
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    @observable
    public snackbarState = false;

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public snackMessage = "";

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @observable
    public snackType = "";

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    @observable
    public SNACKSUCCESS = "success";

    @observable
    public SNACKERROR = "error";

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Bolierplate
}
