import React, { useEffect } from "react";
import { useObserver } from "@shoothill/core";
import { ProjectListViewModel } from "./ProjectListViewModel";
import { ProjectListFilterViewModel } from "./ProjectListFilterViewModel";
import { FilterRow, FilterWrapper } from "./ProjectList.styles";
import { MultiSelectView } from "Components/Select/MultiSelect";
import { PrimaryButton } from "Components/Buttons/Buttons";
import { ProjectCellHelpers } from "Globals/Models/Domain";

interface IProjectListFilterViewProps {
    projectListViewModel: ProjectListViewModel;
    filtersViewModel: ProjectListFilterViewModel;
}

export const ProjectListFilterView: React.FunctionComponent<IProjectListFilterViewProps> = (props: IProjectListFilterViewProps) => {
    //useEffect below only gets run on initial render
    useEffect(() => {
        return () => {};
    }, []);

    const renderPage = () => {
        return (
            <>
                <FilterWrapper>
                    <FilterRow>
                        <MultiSelectView
                            style={{ maxWidth: "200px", marginRight: "20px" }}
                            display="Status"
                            fieldName="statuses"
                            values={props.filtersViewModel.model.statuses}
                            getOptions={props.filtersViewModel.getStatusOptions}
                            handleChange={props.projectListViewModel.handleStatusChange}
                        />
                        <MultiSelectView
                            style={{ maxWidth: "200px", marginRight: "20px" }}
                            display="Cell"
                            fieldName="cells"
                            values={props.filtersViewModel.model.cells}
                            getOptions={ProjectCellHelpers.getAllKeyValueSelectOptions}
                            handleChange={props.projectListViewModel.handleCellChange}
                        />
                    </FilterRow>
                </FilterWrapper>
            </>
        );
    };

    return useObserver(() => renderPage());
};
