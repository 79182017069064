// Libraries
import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { isNullOrUndefined, KeyValuePair, useRouter } from "@shoothill/core";
import "./Contractual.styles";

// Custom
import { DetailsHeader } from "Globals/Views/DetailsPage/DetailsHeader";
import { ProjectGeneralViewModel } from "Views/Project/General/ProjectGeneralViewModel";
import { DetailsGroup } from "Views/Project/DetailsGroup";

// Styling & Images
import { DetailsPage } from "Globals/Views/DetailsPage/DetailsPage.styles";
import { ContractCell, ContractRow } from "./Contractual.styles";
import { Box, FormControlLabel, Grid, Typography, Switch } from "@material-ui/core";
import { ClientSelectViewModel } from "Components/AutoComplete/ClientSelectViewModel";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ContractTypeViewModel } from "Components/AutoComplete/ContractTypeSelectViewModel";
import { ProjectContractualViewModel } from "./ProjectContractualViewModel";
import { ProjectEditBox } from "../Project.styles";
import { formatDate } from "Utils/Format";
import { CustomHr } from "../CustomComponents";
import { ContactViewDetails, ContactViewRow, ContactViewTitle, ContactViewWrapper, EmailCol } from "Views/Contacts/ContactView.styles";
import { styled } from "@material-ui/styles";
import EmailLogo from "Content/Email.svg";

export const DataTypeString = ({ children }: any) => {
    return (
        <Typography>
            <span className="control-label"> {children}</span>
        </Typography>
    );
};

export const DataString = ({ children }: any) => {
    return (
        <Typography variant="body1" color="textPrimary">
            {children}
        </Typography>
    );
};

const MaterialUISwitch = styled(Switch)(() => ({
    width: 184,
    height: 40,
    padding: 7,
    "& .MuiSwitch-switchBase": {
        marginTop: 6,
        padding: 0,
        transform: "translateX(7px)",
        "&.Mui-checked": {
            color: "#fff",
            transform: "translateX(87px)",
            "& .MuiSwitch-thumb:before": {},
            "& + .MuiSwitch-track": {
                opacity: 1,
            },
        },
    },
    "& .MuiSwitch-thumb": {
        width: 90,
        height: 26,
        borderRadius: 50,
        background: "#999",
        border: "1px solid #fff",
        textAlign: "center",
        marginTop: 1,
        "&:before": {
            content: "'Edit Mode'",
            position: "absolute",
            width: "100%",
            height: "100%",
            left: 0,
            top: 0,
            backgroundRepeat: "no-repeat",
            fontSize: 12,
            color: "#fff",
            whiteSpace: "nowrap",
            fontWeight: 500,
            lineHeight: "26px",
        },
    },
    "& .MuiSwitch-track": {
        opacity: 1,
        background: "#425b66",
        borderRadius: 50,
        display: "flex",
    },
    "& .MuiSwitch-track:after": {
        content: "'Off'",
        fontSize: 12,
        color: "#fff",
        whiteSpace: "nowrap",
        fontWeight: 500,
        lineHeight: "26px",
        width: 90,
        display: "inline-block",
        textAlign: "center",
    },
    "& .MuiSwitch-track:before": {
        content: "'On'",
        fontSize: 12,
        color: "#fff",
        whiteSpace: "nowrap",
        fontWeight: 500,
        lineHeight: "26px",
        width: 90,
        display: "inline-block",
        textAlign: "center",
    },
}));

export const ContractualView: React.FunctionComponent = () => {
    const router = useRouter();
    const [generalViewModel] = useState(() => ProjectGeneralViewModel.Instance);
    const [viewModel] = useState(new ProjectContractualViewModel());
    const [clientList, setClientList] = useState<KeyValuePair<any>[]>([]);
    const [contractTypes, setContractTypes] = useState<KeyValuePair<any>[]>([]);

    const { history } = useRouter();

    useEffect(() => {
        let { projectid } = router.match.params as any;
        let promise = generalViewModel.apiGetbyId(projectid);

        promise.then(() => {
            const model = generalViewModel.model.getProjectConstractual;
            // viewModel.populateProjectStatusTypes(generalViewModel?.projectStatusTypes);

            if (model !== null && model !== undefined) {
                if (model.projectId === null || model.projectId === undefined || model.projectId === "00000000-0000-0000-0000-000000000000") {
                    model.projectId = projectid;
                }
                // model.projectStatusTypeId = generalViewModel.model.projectStatusTypeId;

                viewModel.loadModel(model);
            } else {
                viewModel.model.projectId = projectid;
            }

            const clientVM: ClientSelectViewModel = ClientSelectViewModel.Instance;
            setClientList(clientVM.returnClientList);

            const contractTypesVM: ContractTypeViewModel = ContractTypeViewModel.Instance;
            setContractTypes(contractTypesVM.getContractTypes);
        });

        return () => {
            viewModel.clean();
        };
    }, []);

    let contractTypeString: string = "";
    const contractType: KeyValuePair | undefined = contractTypes.find((a) => a.value === viewModel.model.contractTypeId);
    if (contractType !== undefined) {
        contractTypeString = contractType.key;
    }

    let contractValidationDate = formatDate(String(viewModel.model.valuationDate));
    let contractValuationFrequency =
        viewModel.model.valuationFrequencyDays > 1 ? `${viewModel.model.valuationFrequencyDays} days` : `${viewModel.model.valuationFrequencyDays} day`;

    const mailto: string = "mailto:" + (isNullOrUndefined(viewModel.model) === false ? viewModel.model.eaContactEmail : "");
    const renderPage = () => {
        return (
            <>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DetailsPage className="cell-right rcselect">
                        <ProjectEditBox className="generalHeaderBox">
                            <div>
                                <DetailsHeader viewModel={generalViewModel.getHeader} />
                            </div>
                            <div className="generalHeaderBox-btn">
                                <div>
                                    <div className="w-full">
                                        <FormControlLabel control={<MaterialUISwitch defaultChecked />} label="" />
                                    </div>
                                </div>
                                <div className="generalstatus generalstatus-small" style={{}}>
                                    <DetailsGroup detailsData={generalViewModel.projectStatusTypeDisplayName} detailsType={""}></DetailsGroup>
                                </div>
                            </div>
                        </ProjectEditBox>
                        <ProjectEditBox>
                            <div className="row mb-0">
                                <ContractCell className="generalLabel">
                                    <DetailsGroup detailsType={"Project Type:"} detailsData={viewModel.model.clientName}></DetailsGroup>
                                </ContractCell>
                            </div>
                        </ProjectEditBox>
                        <Grid item xs={12}>
                            <CustomHr margin={"10px 0"} padding={"0px 30px"} />
                        </Grid>
                        <ContractRow>
                            <Typography variant="h3" className="mb-2">
                                Contract
                            </Typography>
                        </ContractRow>
                        <ProjectEditBox>
                            <div className="row mb-0">
                                <ContractCell className="generalLabel">
                                    <DetailsGroup detailsType={"Contract type:"} detailsData={contractTypeString}></DetailsGroup>
                                </ContractCell>
                                <ContractCell className="generalLabel generalLabel--price">
                                    <DetailsGroup detailsType={"Contract value:"} detailsData={viewModel.model.contractValue}></DetailsGroup>
                                </ContractCell>
                                <ContractCell className="generalLabel generalLabel--per">
                                    <DetailsGroup detailsType={"Overhead & profit:"} detailsData={viewModel.model.overheadPercentage}></DetailsGroup>
                                </ContractCell>
                                <ContractCell className="generalLabel generalLabel--per">
                                    <DetailsGroup detailsType={"Design percentage:"} detailsData={viewModel.model.designPercentage}></DetailsGroup>
                                </ContractCell>
                            </div>
                        </ProjectEditBox>
                        <ProjectEditBox>
                            <div className="row mb-0">
                                <ContractCell className="generalLabel">
                                    <DetailsGroup detailsType={"Valuation date :"} detailsData={contractValidationDate}></DetailsGroup>
                                </ContractCell>
                                <ContractCell className="generalLabel">
                                    <DetailsGroup detailsType={"Completion date :"} detailsData={contractValuationFrequency}></DetailsGroup>
                                </ContractCell>
                            </div>
                        </ProjectEditBox>
                        <Grid item xs={12}>
                            <CustomHr margin={"10px 0"} padding={"0px 30px"} />
                        </Grid>
                        <ContractRow>
                            <Typography variant="h3" className="mb-4">
                                Employers agent(E.A.)
                            </Typography>
                        </ContractRow>
                        <div className="employ-list">
                            <ContractRow className="mb-4 p-0">
                                <Typography variant="h3">{viewModel.model.employersAgent}</Typography>
                            </ContractRow>
                            <ProjectEditBox>
                                <ContactViewWrapper>
                                    <ContactViewRow className="employ-list-item">
                                        <ContactViewTitle>Contact name:</ContactViewTitle>
                                        <ContactViewDetails>{viewModel.model.eaContactName}</ContactViewDetails>
                                    </ContactViewRow>
                                    <ContactViewRow className="employ-list-item">
                                        <ContactViewTitle>Contact email:</ContactViewTitle>
                                        <ContactViewDetails>
                                            <Box>{viewModel.model.eaContactEmail}</Box>
                                            {viewModel.model.id !== "" && viewModel.model.id !== null && viewModel.model.id !== undefined && (
                                                <EmailCol>
                                                    <a href={mailto}>
                                                        <img src={EmailLogo} alt="Email contact" className="email" />
                                                    </a>
                                                </EmailCol>
                                            )}
                                        </ContactViewDetails>
                                    </ContactViewRow>
                                    <ContactViewRow className="employ-list-item">
                                        <ContactViewTitle>Contact phone:</ContactViewTitle>
                                        <ContactViewDetails>{viewModel.model.eaContactPhone}</ContactViewDetails>
                                    </ContactViewRow>
                                    <ContactViewRow className="employ-list-item">
                                        <ContactViewTitle>Contact mobile:</ContactViewTitle>
                                        <ContactViewDetails>{viewModel.model.eaContactMobile}</ContactViewDetails>
                                    </ContactViewRow>
                                </ContactViewWrapper>
                            </ProjectEditBox>
                        </div>
                    </DetailsPage>
                </MuiPickersUtilsProvider>
            </>
        );
    };

    return useObserver(() => renderPage());
};
