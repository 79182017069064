import { ModelBase } from "@shoothill/core";
import { IsInt, IsNotEmpty, Min, NotEquals } from "class-validator";
import { action, observable } from "mobx";

import { IStockTransactionType, StockTransactionType } from "Globals/Models/Enums/StockTransactionType";
import { CategoryModel } from "./Supporting/CategoryModel";
import { IncomeAndExpenditureModel } from "./Supporting/IncomeAndExpenditureModel";
import { LineDescriptionModel } from "./Supporting/LineDescriptionModel";
import { IProjectDTO, ProjectModel } from "./Supporting/ProjectModel";
import { SubCategoryModel } from "./Supporting/SubCategoryModel";

export class StockTransferModel extends ModelBase<StockTransferModel> {
    // #region Defaults

    public static readonly DEFAULT_STOCKTRANSACTIONTYPES = [];
    public static readonly DEFAULT_PROJECTS = [];
    public static readonly DEFAULT_INCOMEANDEXPENDITURES = [];
    public static readonly DEFAULT_CATEGORIES = [];
    public static readonly DEFAULT_SUBCATEGORIES = [];
    public static readonly DEFAULT_LINEDESCRIPTIONS = [];

    public static readonly DEFAULT_CURRENTUNITS = null;
    public static readonly DEFAULT_CURRENTVALUE = null;

    public static readonly DEFAULT_TRANSFERUNITS = 0;
    public static readonly DEFAULT_TRANSFERNOTE = "";
    public static readonly DEFAULT_STOCKTRANSACTIONTYPE = null;
    public static readonly DEFAULT_PROJECT = null;
    public static readonly DEFAULT_INCOMEANDEXPENDITURE = null;
    public static readonly DEFAULT_CATEGORY = null;
    public static readonly DEFAULT_SUBCATEGORY = null;
    public static readonly DEFAULT_LINEDESCRIPTION = null;
    public static readonly DEFAULT_SUGGESTEDFUTURESPEND = null;
    public static readonly DEFAULT_SUGGESTEDFUTURESPENDREASON = null;

    // #endregion Defaults

    // #region Related Properties

    @observable
    public stockTransactionTypes = observable<StockTransactionType>(StockTransferModel.DEFAULT_STOCKTRANSACTIONTYPES);

    @observable
    public projects = observable<ProjectModel>(StockTransferModel.DEFAULT_PROJECTS);

    @observable
    public incomeAndExpenditures = observable<IncomeAndExpenditureModel>(StockTransferModel.DEFAULT_INCOMEANDEXPENDITURES);

    @observable
    public categories = observable<CategoryModel>(StockTransferModel.DEFAULT_CATEGORIES);

    @observable
    public subCategories = observable<SubCategoryModel>(StockTransferModel.DEFAULT_SUBCATEGORIES);

    @observable
    public lineDescriptions = observable<LineDescriptionModel>(StockTransferModel.DEFAULT_LINEDESCRIPTIONS);

    @observable
    public currentUnits: number | null = StockTransferModel.DEFAULT_CURRENTUNITS;

    @observable
    public currentValue: number | null = StockTransferModel.DEFAULT_CURRENTVALUE;

    // #endregion Related Properties

    // #region Properties

    @observable
    @IsInt({ message: "Please provide an integer value" })
    @Min(1, { message: "Please provide at minimum 1 unit" })
    public transferUnits: number | string = StockTransferModel.DEFAULT_TRANSFERUNITS;

    @observable
    @IsNotEmpty({ message: "Please provide a note" })
    public transferNote: string = StockTransferModel.DEFAULT_TRANSFERNOTE;

    @observable
    @NotEquals(null, { message: "Please select an option" })
    public stockTransactionType: StockTransactionType | null = StockTransferModel.DEFAULT_STOCKTRANSACTIONTYPE;

    @observable
    @NotEquals(null, { message: "Please select an option" })
    public project: ProjectModel | null = StockTransferModel.DEFAULT_PROJECT;

    @observable
    @NotEquals(null, { message: "Please select an option" })
    public incomeAndExpenditure: IncomeAndExpenditureModel | null = StockTransferModel.DEFAULT_INCOMEANDEXPENDITURE;

    @observable
    @NotEquals(null, { message: "Please select an option" })
    public category: CategoryModel | null = StockTransferModel.DEFAULT_CATEGORY;

    @observable
    @NotEquals(null, { message: "Please select an option" })
    public subCategory: SubCategoryModel | null = StockTransferModel.DEFAULT_SUBCATEGORY;

    @observable
    @NotEquals(null, { message: "Please select an option" })
    public lineDescription: LineDescriptionModel | null = StockTransferModel.DEFAULT_LINEDESCRIPTION;

    @observable
    @NotEquals(null, { message: "Please provide a value" })
    public suggestedFutureSpend: number | null = StockTransferModel.DEFAULT_SUGGESTEDFUTURESPEND;

    @observable
    @NotEquals(null, { message: "Please provide a reason" })
    public suggestedFutureSpendReason: string | null = StockTransferModel.DEFAULT_SUGGESTEDFUTURESPENDREASON;

    // #endregion Properties

    // #region Methods

    @action
    public fromDto(dto: any): void {
        // Stock transaction types.
        this.stockTransactionTypes.replace(
            dto.stockTransactionTypes.map((stt: IStockTransactionType) => {
                const enumType = new StockTransactionType();

                enumType.fromDto(stt);

                return enumType;
            }),
        );

        // Projects.
        this.projects.replace(
            dto.projects.map((p: IProjectDTO) => {
                const model = new ProjectModel();

                model.fromDto(p);

                return model;
            }),
        );
    }

    public toDto(): any {
        return {
            stockTransactionTypeId: this.stockTransactionType?.id,
            units: this.transferUnits,
            note: this.transferNote,
            ieItemId: this.lineDescription?.id,
            suggestedFutureSpend: this.suggestedFutureSpend,
            suggestedFutureSpendReason: this.suggestedFutureSpendReason,
        };
    }

    // #endregion Methods
}
