import { Box, FormHelperText, MenuItem, Select, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useObserver } from "mobx-react-lite";
import { DetailsFirstRowWrapper, DetailsHeaderAddress, DetailsHeaderStatus, DetailsHeaderTitle, DetailsHeaderWrapper } from "./DetailsHeader.styles";
import { DetailsHeaderModel } from "./DetailsHeaderModel";
import { FieldType } from "@shoothill/core";

export interface DetailsHeaderProps {
    viewModel: DetailsHeaderModel;
    onSelect?: Function;
    isShowStatus?: boolean;
    isShowDropDown?: boolean;
    options?: any;
}

export const DetailsHeader: React.FC<DetailsHeaderProps> = (props) => {
    const { viewModel, onSelect, isShowStatus = true, isShowDropDown = false, options } = props;
    useEffect(() => {
        // useEffect only gets run on initial render
        return () => {};
    }, []);

    const isInError = (fieldName: keyof FieldType<DetailsHeaderModel>) => {
        let isValid = viewModel.getValid(fieldName);
        return !isValid;
    };

    return useObserver(() => (
        <DetailsHeaderWrapper>
            <span style={{ color: "transparent" }}></span>
            <DetailsFirstRowWrapper>
                <DetailsHeaderTitle>
                    <Typography variant="h1">{viewModel.getTitle}</Typography>
                </DetailsHeaderTitle>

                {isShowStatus && viewModel.getStatus !== undefined && viewModel.getStatus!.length > 0 && (
                    <DetailsHeaderStatus className={viewModel.getStatusClass}>{viewModel.getStatus!}</DetailsHeaderStatus>
                )}

                {isShowDropDown && (
                    <Box>
                        <Select value={viewModel.getStatus!} onChange={(event) => (onSelect ? onSelect(event.target.value) : {})}>
                            {options?.map((item: any, index: number) => {
                                return (
                                    <MenuItem key={index} value={item.value}>
                                        {item.label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        <>{isInError("status") && <FormHelperText style={{ color: "red", maxWidth: "180px" }}>{viewModel.getError("status")}</FormHelperText>}</>
                    </Box>
                )}
            </DetailsFirstRowWrapper>
            {viewModel.getAddress !== undefined && viewModel.getAddress!.length > 0 && (
                <DetailsHeaderAddress>
                    <Typography variant="h2">{viewModel.getAddress!}</Typography>
                </DetailsHeaderAddress>
            )}
            {viewModel.getSubTitle !== undefined && viewModel.getSubTitle!.length > 0 && <Typography variant="h3">{viewModel.getSubTitle!}</Typography>}
        </DetailsHeaderWrapper>
    ));
};
