import { ModelBase } from "@shoothill/core";
import { action, observable } from "mobx";

export class IncomeAndExpenditureCellModel extends ModelBase<IncomeAndExpenditureCellModel, IncomeAndExpenditureCellModelDTO> {
    // #region Defaults

    // #endregion Defaults

    public static readonly DEFAULT_ID = "";
    public static readonly DEFAULT_DISPLAYNAME = "";

    // #endregion Defaults

    // #region Observables

    @observable
    public id: string = IncomeAndExpenditureCellModel.DEFAULT_ID;

    @observable
    public displayName: string = IncomeAndExpenditureCellModel.DEFAULT_DISPLAYNAME;

    // #endregion Observables

    // #region Actions

    @action
    public reset(): void {
        this.id = IncomeAndExpenditureCellModel.DEFAULT_ID;
        this.displayName = IncomeAndExpenditureCellModel.DEFAULT_DISPLAYNAME;
    }

    @action
    public fromDto(dto: IncomeAndExpenditureCellModelDTO): void {
        this.id = dto.id;
        this.displayName = dto.displayName;
    }

    public toDto() {
        throw new Error("Method not implemented.");
    }

    // #endregion Actions

    // #region Helpers

    public static fromDtos(dtos: IncomeAndExpenditureCellModelDTO[]): IncomeAndExpenditureCellModel[] {
        const cells: IncomeAndExpenditureCellModel[] = [];

        for (const dto of dtos) {
            const model = new IncomeAndExpenditureCellModel();

            model.fromDto(dto);
            cells.push(model);
        }

        return cells;
    }

    // #endregion Helpers
}

export interface IncomeAndExpenditureCellModelDTO {
    id: string;
    displayName: string;
}
