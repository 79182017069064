import { observable } from "mobx";
import { GenericPhotoListItemDTO, GenericPhotoListItemModel } from "../Incidents/Indicent/Supporting/GenericPhotoListItemModel";

export class DilapidationFormItemPhotoListItemModel extends GenericPhotoListItemModel {
    @observable
    public dilapidationReportItemId: string | null = null;
    constructor() {
        super();
    }

    fromDto(model: DilapidationFormItemPhotoListItemDTO): void {
        super.fromDto(model);
        this.dilapidationReportItemId = model.dilapidationReportItemId;
    }

    public static fromDtos(dtos: DilapidationFormItemPhotoListItemDTO[]): DilapidationFormItemPhotoListItemModel[] {
        const types: DilapidationFormItemPhotoListItemModel[] = [];

        for (const dto of dtos) {
            const model = new DilapidationFormItemPhotoListItemModel();

            model.fromDto(dto);
            types.push(model);
        }

        return types;
    }

    toDto(): DilapidationFormItemPhotoListItemDTO {
        const dto: GenericPhotoListItemDTO = super.toDto();
        return {
            ...dto,
            dilapidationReportItemId: this.dilapidationReportItemId,
        };
    }
}

export interface DilapidationFormItemPhotoListItemDTO extends GenericPhotoListItemDTO {
    dilapidationReportItemId: string | null;
}
