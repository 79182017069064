import { action, observable, computed } from "mobx";
import { ModelBase } from "@shoothill/core";

export class StockFilterParamsModel extends ModelBase<StockFilterParamsModel, StockFilterParamsModelDTO> {
    @observable
    public includeDeleted: boolean = false;

    @observable
    public types: string[] | null = [];

    @observable
    public subTypes: string[] | null = [];

    @observable
    public uoms: string[] | null = [];

    // @observable
    // public searchText: string = "";

    fromDto(model: StockFilterParamsModelDTO): void {
        this.types = model.types;
        this.subTypes = model.subTypes;
        this.uoms = model.uoms;
    }

    toDto(): StockFilterParamsModelDTO {
        let dto: StockFilterParamsModelDTO = {
            includeDeleted: this.includeDeleted,
            types: this.types,
            subTypes: this.subTypes,
            uoms: this.uoms,
            initialLoad: true,
        };
        return dto;
    }

    @action
    public reset = () => {
        this.types = [];
        this.subTypes = [];
        this.uoms = [];
    };
}

export interface StockFilterParamsModelDTO {
    includeDeleted: boolean;
    types: string[] | null;
    subTypes: string[] | null;
    uoms: string[] | null;
    initialLoad: boolean;
}
