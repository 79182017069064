// Libraries
import React from "react";
import { useObserver } from "mobx-react-lite";
import { useRouter } from "@shoothill/core";

// Custom
import { AppUrls } from "AppUrls";
import { formatCurrencyForGrid, formatCurrencyNoSign } from "Utils/Format";
import { PurchaseOrderListItemModelDTO } from "../../IEmodels";

// Views

// Styling & Images
import MoneySVG from "../../../../../Content/Money.svg";
import { IEPOItemTitle } from "../IEGrid.Styles";

interface ITableProps {
    ieId: string;
    itemModel: PurchaseOrderListItemModelDTO;
    rowIndex: number;
    ieLineId: string;
}

export const IEPurchaseOrderItem: React.FunctionComponent<ITableProps> = (props) => {
    const { history } = useRouter();
    const handleRequisitionClick = (val: string) => {
        history.push(AppUrls.Client.PurchaseOrder.View.replace(":poid", val));
    };

    return useObserver(() => (
        <>
            <li className="item" key={"purreq_" + props.rowIndex} style={{ cursor: "pointer" }} onClick={() => handleRequisitionClick(props.itemModel.id)}>
                <IEPOItemTitle>
                    <img src={MoneySVG}></img>
                    <div>
                        <span style={{ marginLeft: "10px" }}>{props.itemModel.formattedPONumber}</span>
                        <span className="ie-row-name ie-po-name" style={{ marginLeft: "10px", whiteSpace: "normal" }} title={props.itemModel.itemDescription}>
                            {props.itemModel.itemDescription}
                        </span>
                    </div>
                </IEPOItemTitle>
                <div></div>
                <div></div>
                <div></div>
                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(props.itemModel.committedCost))}</div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </li>
        </>
    ));
};
