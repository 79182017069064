import styled from "styled-components";

export const HistoryTable = styled.div`
    padding: 0 0 0.75rem 0;
`;

export const HistoryPlanOfWorkDisplayName = styled.div`
    background-color: #eaf4f9;
    font-size: 12px;
    font-weight: bold;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0.5rem;
`;

export const LatestDrawing = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-left: 0.75rem;
    padding: 0.125rem 0rem;
`;

export const PreviousDrawing = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-left: 0.875rem;
    padding: 0.125rem 0rem;
`;

export const LastestDrawingRevision = styled.div`
    font-size: 12px;
    font-weight: bold;
`;

export const PreviousDrawingRevision = styled.div`
    font-size: 12px;
`;
