import { action, computed, observable } from "mobx";
import { isEmptyOrWhitespace, ModelBase } from "@shoothill/core";
import { validateTwoDecimalPlaces } from "Utils/Utils";

export class VariationRiskOppModel extends ModelBase<VariationRiskOppModel, VariationRiskOppUpsertResponseDTO> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Constants and Defaults

    public static readonly DEFAULT_ID = null;
    public static readonly DEFAULT_IEID = "";
    public static readonly DEFAULT_VARIATIONID = "";
    public static readonly DEFAULT_VARIATIONITEMID = "";
    public static readonly DEFAULT_RISKVALUE = "";
    public static readonly DEFAULT_NOTE = "";
    public static readonly DEFAULT_ROWVERSION = null;
    public static readonly DEFAULT_CREATEDBYUSERNAME = null;

    // #endregion Constants and Defaults

    // #region Properties

    @observable
    public id: string | null = null;

    @observable
    public ieId: string = VariationRiskOppModel.DEFAULT_IEID;

    @observable
    public variationId: string = VariationRiskOppModel.DEFAULT_VARIATIONID;

    @observable
    public variationItemId: string = VariationRiskOppModel.DEFAULT_VARIATIONITEMID;

    @observable
    public riskValue: string = VariationRiskOppModel.DEFAULT_RISKVALUE;

    @observable
    public note: string = VariationRiskOppModel.DEFAULT_NOTE;

    @observable
    public rowVersion: string | null = VariationRiskOppModel.DEFAULT_ROWVERSION;

    @observable
    public createdByUserName: string | null = VariationRiskOppModel.DEFAULT_CREATEDBYUSERNAME;

    // #endregion Properties

    // #region Actions

    @action
    public fromDto(dto: VariationRiskOppUpsertResponseDTO): void {
        this.id = dto.id;
        this.variationId = dto.variationId;
        this.variationItemId = dto.variationItemId;
        this.riskValue = dto.riskValue;
        this.note = dto.note;
        this.rowVersion = dto.rowVersion;
        this.createdByUserName = dto.createdByUserName;
    }

    public toDto(): VariationRiskOppUpsertRequestDTO {
        return {
            id: this.id,
            variationId: this.variationId,
            variationItemId: this.variationItemId,
            riskValue: Number(this.riskValue),
            note: this.note,
            rowVersion: this.rowVersion,
        };
    }

    public reset(): void {
        this.id = null;
        this.riskValue = VariationRiskOppModel.DEFAULT_RISKVALUE;
        this.note = VariationRiskOppModel.DEFAULT_NOTE;
        this.rowVersion = VariationRiskOppModel.DEFAULT_ROWVERSION;
        this.createdByUserName = VariationRiskOppModel.DEFAULT_CREATEDBYUSERNAME;
    }

    // #endregion Actions

    // #region Custom Validation

    @computed
    public get validateRiskValue(): string {
        // RULES
        const riskValueNumber: number = Number(this.riskValue);
        if (this.riskValue === VariationRiskOppModel.DEFAULT_RISKVALUE || isNaN(riskValueNumber)) {
            return "Please enter a valid value";
        } else if (!validateTwoDecimalPlaces(riskValueNumber)) {
            return "No more than two decimal places";
        }

        return "";
    }

    @computed
    public get validateRiskNote(): string {
        return this.note === VariationRiskOppModel.DEFAULT_NOTE ? "Please enter a note" : "";
    }

    // #endregion Custom Validation
}

export interface VariationRiskOppUpsertRequestDTO {
    id: string | null;
    variationId: string;
    variationItemId: string;
    riskValue: number;
    note: string;
    rowVersion: string | null;
}

export interface VariationRiskOppUpsertResponseDTO {
    id: string;
    variationId: string;
    variationItemId: string;
    riskValue: string;
    note: string;
    rowVersion: string | null;
    createdByUserName: string | null;
}

export interface VariationRiskOppAndRelatedResponseDTO {
    categoryName: string;
    subCategoryName: string;
    lineDescriptionName: string;
    ieTitle: string;
    variationRiskOppAmendmentItems: VariationRiskOppDTO[];
}

export interface VariationRiskOppDTO {
    id: string;
    note: string;
    riskValue: number;
    createdDate: string;
    isCurrent: boolean;
    createdByUserName: string | null;
}

//export interface IncomeAndExpenditureAndRelatedResponseDTO extends IncomeAndExpenditureUpsertResponseDTO, IncomeAndExpenditureRelatedResponseDTO {}
