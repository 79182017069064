import { ApiResult, FieldType, ViewModelBase, isEmptyOrWhitespace, observable } from "@shoothill/core";
import { runInAction, action, computed } from "mobx";
import moment from "moment";

import { AppUrls } from "AppUrls";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { formatDate, formatTime } from "Utils/Format";
import {
    QualityAndRelatedResponseDTO,
    QualityFormModel,
    QualityPhotoFile,
    QualityPhotosDTO,
    QualityProjectDetailsDTO,
    QualityRelatedResponseDTO,
    SupplierDTO,
    UpsertQualityAndRelatedRequestDTO,
    qualityDTO,
} from "./QualityFormModel";
import { NonConformanceListViewModel } from "../NonConformanceListViewModel";

export class QualityFormViewModel extends ViewModelBase<QualityFormModel> {
    public server: ServerViewModel = new ServerViewModel();

    constructor(id: string | null, projectId: string | null, nonConformanceTypeId: string | null) {
        super(new QualityFormModel());
        this.setDecorators(QualityFormModel);

        this.model.id = id;
        this.model.projectId = projectId!;
        this.model.nonConformanceTypeId = nonConformanceTypeId!;

        isEmptyOrWhitespace(this.model.id) ? this.loadRelated() : this.loadWithRelated();
    }

    // #region Shared

    @observable
    public qualityProjectDetails: QualityProjectDetailsDTO | null = null;

    @computed
    public get projectDisplayName() {
        return `(${!isEmptyOrWhitespace(this.qualityProjectDetails?.projectReference) ? this.qualityProjectDetails?.projectReference : "--"} - 
                 ${!isEmptyOrWhitespace(this.qualityProjectDetails?.projectName) ? this.qualityProjectDetails?.projectName : "--"})`;
    }

    public nonConformanceTypeDisplayName = (list: NonConformanceListViewModel): string => {
        const id = isEmptyOrWhitespace(this.model.id) ? this.model.nonConformanceTypeId : this.quality?.nonConformanceTypeId;

        return list.nonConformanceTypes.find((i: any) => i.id === id)?.displayName ?? "";
    };

    // #endregion Shared

    // #region Properties

    @action
    public setSupplier = (value: SupplierDTO | null): void => {
        this.setValue("supplierId", value?.id ?? QualityFormModel.DEFAULT_SUPPLIER_ID);
    };

    @computed
    public get supplier(): SupplierDTO | null {
        return this.model.suppliers.find((u) => u.id === this.model.supplierId) ?? null;
    }

    @computed
    public get suppliers() {
        return this.model.suppliers;
    }

    @action
    public setUser = (value: any | null): void => {
        this.setValue("userId", value?.id ?? QualityFormModel.DEFAULT_ISSUEDTO_USER_ID);
    };

    @computed
    public get user(): any | null {
        return this.model.users.find((u) => u.id === this.model.userId) ?? null;
    }

    @computed
    public get users() {
        return this.model.users;
    }

    @action
    public setRectificationRequiredChange = (value: string) => {
        this.setValue("rectificationRequired", value === "true");
    };

    @action
    public setIsOpenChange = (value: string) => {
        this.setValue("isOpen", value === "true");
    };

    // #endregion Properties

    // #region ReadOnly Properties

    @observable
    public quality: any = null;

    @computed
    public get readOnlySupplierDisplayName(): string {
        return this.suppliers.find((i: any) => i.id === this.quality?.supplierId)?.displayName ?? "";
    }

    @computed
    public get readOnlyUserDisplayName(): string {
        return this.users.find((i: any) => i.id === this.quality?.issuedToInductionUserId || i.id === this.quality?.workerUserId)?.displayName ?? "";
    }

    @computed
    public get readOnlyLocationDisplayName(): string {
        return this.quality?.location ?? "";
    }

    @computed
    public get readOnlyDescriptionDisplayName(): string {
        return this.quality?.description ?? "";
    }

    @computed
    public get readOnlyRectificationRequired(): boolean {
        return this.quality?.rectificationRequired ?? false;
    }

    @computed
    public get readOnlyRectificationDate(): string {
        return this.quality?.rectificationDate ? formatDate(this.quality?.rectificationDate) : "";
    }

    @computed
    public get readOnlyIsOpen(): boolean {
        return this.quality?.isOpen ?? false;
    }

    @computed
    public get readOnlyRectificationDescription(): string {
        return this.quality?.rectificationDescription ?? "";
    }

    @computed
    public get readOnlyCreatedDateAndTime(): string {
        return this.quality?.createdDate ? `${formatDate(this.quality?.createdDate)} @ ${formatTime(this.quality?.createdDate)}` : "";
    }

    // #endregion ReadOnly Properties

    @observable
    public qualityPhotos = observable<QualityPhotosDTO>([]);

    @observable
    public snackMessage = "";

    @observable
    public snackType = "";

    @observable
    public SNACKSUCCESS = "success";

    @observable
    public SNACKERROR = "error";

    @observable
    public snackbarState = false;

    @computed
    public get hasId(): boolean {
        return this.model.id !== null && this.model.id !== undefined && this.model.id !== "";
    }

    @computed
    public get getTodayDateFormatted(): string {
        return this.model.createdDate ? moment(this.model.createdDate).format("DD/MM/YYYY").toString() : moment().format("DD/MM/YYYY").toString();
    }

    @computed
    public get isFormDisabled(): boolean {
        return this.model.id !== null && this.model.id !== undefined && this.model.id !== "";
    }

    // Start Validation

    @computed
    private get validateUserId() {
        const errorMessage = this.model.validateUserId;
        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateLocation() {
        const errorMessage = this.model.validateLocation;
        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateDescription() {
        const errorMessage = this.model.validateDescription;
        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }
    //End Validation

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @action
    public setQualityPhotosFile = (file: QualityPhotoFile) => {
        this.model.qualityPhotos.push(file);
    };
    @action
    public setQualityRectificationPhotosFile = (file: QualityPhotoFile) => {
        this.model.qualityRectificationPhotos.push(file);
    };

    @action
    public deleteQualityPhotosFile = async (index: number): Promise<void> => {
        this.model.qualityPhotos[index].isDeleted = true;
    };

    @action
    public deleteQualityRectificationPhotosFile = async (index: number): Promise<void> => {
        this.model.qualityRectificationPhotos[index].isDeleted = true;
    };

    @action
    public deleteNonConformanceWorksFile = async (index: number): Promise<void> => {
        this.model.completeNonConformanceWorksFile[index].isDeleted = true;
    };

    @action
    public reset = () => {
        this.model.reset();
        this.server.reset();
        this.qualityProjectDetails = null;
    };

    @action
    public handleCancel(projectId: string | null): void {
        this.reset();
        this.history.push(AppUrls.Client.Project.ConstructionQuality.replace(":projectid", projectId ? projectId : this.model.projectId) + "#nonconformance");
    }

    public upsert = async (): Promise<void> => {
        // APM: Review Note.
        // Shouldn't need this. The server viewmodel has and automatically sets an IsBusy flag.
        this.setIsLoading(true);

        // Create the request body dto.
        const model: qualityDTO = this.model.toDto();

        let qualityPhoto: QualityPhotosDTO[] = [];
        let qualityRectificationPhoto: QualityPhotosDTO[] = [];

        // APM: Review Note.
        // Does this make sense? It implies that to upload any photos, I cannot have a quality
        // identifier. So uploads can only happen on new issues.
        if (!this.hasId) {
            this.model.qualityPhotos.forEach((qualityPhotos: any) => {
                const quality: QualityPhotosDTO = {
                    qualityId: qualityPhotos.qualityId,
                    fileName: qualityPhotos.fileName,
                    photoUrl: qualityPhotos.photoUrl,
                    createdByUserId: null,
                    isDeleted: false,
                };
                qualityPhoto.push(quality);
            });

            this.model.qualityRectificationPhotos.forEach((qualityPhoto) => {
                const qualityRectification: QualityPhotosDTO = {
                    qualityId: qualityPhoto.qualityId,
                    fileName: qualityPhoto.fileName,
                    photoUrl: qualityPhoto.photoUrl,
                    createdByUserId: null,
                    isDeleted: false,
                };
                qualityRectificationPhoto.push(qualityRectification);
            });
        }

        const request: UpsertQualityAndRelatedRequestDTO = {
            quality: model,
            qualityPhotos: qualityPhoto,
            qualityRectificationPhotos: qualityRectificationPhoto,
        };

        // POST it to the server.
        return await this.server
            .command<QualityAndRelatedResponseDTO>(
                () => this.Post(AppUrls.Server.Projects.Construction.NonConformance.Quality.Upsert, request),
                (result: QualityAndRelatedResponseDTO) => {
                    runInAction(() => {
                        if (result) {
                            this.handleCancel(result.quality.projectId);
                        }
                    });
                },
                this.isMyModelValid,
                "There was an error trying to send the safety",
            )
            .finally(() => this.setIsLoading(false));
    };

    private isMyModelValid = async (): Promise<boolean> => {
        let isValid = true;
        if ((await this.isModelValid()) === false) {
            isValid = false;
        }

        return isValid;
    };

    public loadRelated = async (): Promise<void> => {
        this.setIsLoading(true);

        return this.server
            .query<QualityRelatedResponseDTO>(
                () => this.Get(`${AppUrls.Server.Projects.Construction.NonConformance.Quality.Related}\\${this.model.projectId}\\${this.model.nonConformanceTypeId}`),
                (result) =>
                    runInAction(() => {
                        this.model.suppliers.replace(result.suppliers);
                        this.model.inductionUsers.replace(result.issuedToInductionUsers);
                        this.model.workerUsers.replace(result.workerUsers);
                        this.qualityProjectDetails = result.qualityProjectDetails;
                    }),
            )
            .finally(() => this.setIsLoading(false));
    };

    public loadWithRelated = async (): Promise<void> => {
        this.setIsLoading(true);

        return this.server
            .query<any>(
                () => this.Get(`${AppUrls.Server.Projects.Construction.NonConformance.Quality.WithRelatedById}\\${this.model.id}`),
                (result) =>
                    runInAction(() => {
                        this.model.suppliers.replace(result.suppliers);
                        this.model.inductionUsers.replace(result.issuedToInductionUsers);
                        this.model.workerUsers.replace(result.workerUsers);
                        this.qualityProjectDetails = result.qualityProjectDetails;
                        this.quality = result.quality;

                        result.qualityPhotos.map((el: any) => this.setQualityPhotosFile(el));
                        result.qualityRectificationPhotos.map((el: any) => this.setQualityRectificationPhotosFile(el));
                    }),
            )
            .finally(() => this.setIsLoading(false));
    };

    public fileUpload = async (data: any): Promise<ApiResult<any>> => {
        const formData = new FormData();
        formData.append("formFile", data.formFile);
        formData.append("fileName", data.fileName);
        const apiResult = await this.Post<any>(AppUrls.Server.File.UploadFile, formData);
        if (apiResult) {
            if (!apiResult.wasSuccessful) {
                console.log(apiResult.errors);
                runInAction(() => {
                    this.setSnackMessage("Error uploading file please try again.");
                    this.setSnackType(this.SNACKERROR);
                    this.setSnackbarState(true);
                });
            }
        }
        return apiResult;
    };

    public fileChange = async (event: any, isCompleted: boolean = false): Promise<void> => {
        if (event.target.files.length > 0) {
            let data: any = {
                fileName: event.target.files[0].name,
                formFile: event.target.files[0],
            };
            event.target.value = null;
            const apiResult = await this.fileUpload(data);
            if (apiResult && apiResult.wasSuccessful) {
                let fileToDisplay: QualityPhotoFile = {
                    qualityId: null,
                    fileName: data.fileName,
                    photoUrl: apiResult.payload,
                    isDeleted: false,
                    createdByUserId: "",
                };
                if (isCompleted) {
                    this.setQualityRectificationPhotosFile(fileToDisplay);
                } else {
                    this.setQualityPhotosFile(fileToDisplay);
                }
            }
        }
    };

    public DownloadFile = async (fileUrl: string, fileName: string): Promise<void> => {
        try {
            const apiResult = await this.Post<Blob>(AppUrls.Server.File.DownloadFile, fileUrl, undefined, { responseType: "blob" });
            const response = apiResult as any;
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
        } catch (exception) {
            console.error(exception);
            this.setIsErrored(true);
        }
    };

    // #region Bolierplate

    public async isFieldValid(fieldName: keyof FieldType<QualityFormModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        if (this.server.IsSubmitted) {
            switch (fieldName) {
                case "userId": {
                    const result = this.validateUserId;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "location": {
                    const result = this.validateLocation;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "description": {
                    const result = this.validateDescription;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
            }
        } else {
            errorMessage = "";
            isValid = true;
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Bolierplate
}
