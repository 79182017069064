import { action, observable } from "mobx";
import { ModelBase } from "@shoothill/core";
import { SupplierContactDTO } from "Views/Supplier/SupplierContactModel";
import { ContactBaseDTO } from "Globals/Models/Domain";
import { ClientContactModelDTO } from "Views/Admin/Client/ClientContactModel";
import { IsPhoneNumber, ValidateIf } from "class-validator";
import { ContactTypeDTO } from "./ContactTypeModel";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class AddEditContactModel extends ModelBase<AddEditContactModel, AddEditContactModelDTO> {
    @observable
    public id: string | undefined = undefined;
    public createdDate: string | undefined = undefined;
    public sourceId: string = "";

    public originatorId: string | undefined = undefined;
    public rowVersion: string = "";

    @observable
    public firstName: string = "";

    @observable
    public lastName: string = "";

    @observable
    public position: string = "";

    @observable
    public email: string = "";

    @observable
    @ValidateIf((o) => o.length > 0)
    @IsPhoneNumber("GB", { message: "Phone number is not valid" })
    public phone: string = "";

    @observable
    public isPrimary: boolean = false;

    @observable
    public isDeleted: boolean = false;

    @observable
    public contactTypeId: string | null = "3d81d77e-6d3f-48cf-bb71-eeb3720a6b4d";

    @action
    public clear() {
        this.id = "";
        this.createdDate = "";
        this.firstName = "";
        this.lastName = "";
        this.position = "";
        this.email = "";
        this.phone = "";
        this.sourceId = "";
        this.isPrimary = false;
        this.isDeleted = false;
        this.originatorId = undefined;
        this.rowVersion = "";
        this.contactTypeId = null;
    }

    //fromDto is required but you can leave it blank
    fromDto(model: AddEditContactModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: AddEditContactModel): void {}

    fromSupplierContactDto(model: SupplierContactDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toSupplierContactDto(supplierId: string): SupplierContactDTO {
        const retVal: SupplierContactDTO = {
            id: this.id,
            createdDate: this.createdDate,
            firstName: this.firstName,
            lastName: this.lastName,
            position: this.position,
            email: this.email,
            contactTypeId: this.contactTypeId,
            phone: this.phone,
            supplierId: supplierId,
            isDeleted: false,
            originatorId: this.originatorId,
            rowVersion: this.rowVersion,
            isPrimary: this.isPrimary,
        };
        return retVal;
    }

    fromClientContactDto(model: ClientContactModelDTO): void {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toClientContactDto(clientId: string): ClientContactModelDTO {
        const retVal: ClientContactModelDTO = {
            id: this.id,
            createdDate: this.createdDate,
            firstName: this.firstName,
            lastName: this.lastName,
            position: this.position,
            contactTypeId: this.contactTypeId,
            email: this.email,
            phone: this.phone,
            clientId: clientId,
            isDeleted: false,
            originatorId: this.originatorId,
            rowVersion: this.rowVersion,
            isPrimary: this.isPrimary,
        };
        return retVal;
    }

    fromContactBaseContactDto(model: ContactBaseDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    toContactContactDto(): ContactBaseDTO {
        const retVal: ContactBaseDTO = {
            id: this.id,
            createdDate: this.createdDate,
            firstName: this.firstName,
            lastName: this.lastName,
            position: this.position,
            contactTypeId: this.contactTypeId,
            email: this.email,
            phone: this.phone,
            isDeleted: false,
            originatorId: this.originatorId,
            rowVersion: this.rowVersion,
            isPrimary: this.isPrimary,
        };
        return retVal;
    }

    toBaseContactDto(clientId: string): ClientContactModelDTO {
        const retVal: ClientContactModelDTO = {
            id: this.id,
            createdDate: this.createdDate,
            firstName: this.firstName,
            lastName: this.lastName,
            position: this.position,
            email: this.email,
            contactTypeId: this.contactTypeId,
            phone: this.phone,
            clientId: clientId,
            isDeleted: false,
            originatorId: this.originatorId,
            rowVersion: this.rowVersion,
            isPrimary: this.isPrimary,
        };
        return retVal;
    }
}

export type ContactModelBaseDTO = {
    id: string | undefined;
    createdDate: string | undefined;
    firstName: string;
    lastName: string;
    position: string;
    email: string;
    phone: string;
    isPrimary: boolean;
    isDeleted: boolean;
    originatorId: string | undefined;
    rowVersion: string | undefined;
    contactTypeId: string | null;
    contactTypes: ContactTypeDTO[];
};

export interface AddEditContactModelDTO extends ContactModelBaseDTO {
    sourceId: string;
}
