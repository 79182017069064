import { appTheme, pxToRem, theme } from "Globals/Styles/AppTheme";
import styled from "styled-components";

export const DetailsHeaderWrapper: any = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 100%;
    padding: ${pxToRem(26)};
    border-bottom: 2px dashed ${theme.palette.mediumGrey.main};
    margin-bottom: 15px;
`;

export const DetailsFirstRowWrapper: any = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1 0 1;
    min-width: 100%;
    /* background-color: dodgerblue; */
    justify-content: space-evenly;
`;

export const DetailsHeaderTitle: any = styled.div`
    display: block;
    flex-direction: row;
    flex: 1 0 0;
    line-height: 30px;
    /* background-color: greenyellow; */
    text-align: left;
`;

export const DetailsHeaderAddress: any = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1 0 1;
    min-width: 100%;
    line-height: 20px;
    /* background-color: olivedrab; */
`;

export const DetailsHeaderStatus: any = styled.div`
    display: block;
    width: 100px;
    line-height: 20px;
    padding: 8px;
    text-align: center;
`;
