// Libraries
import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { useRouter } from "@shoothill/core";
import QRCode from "react-qr-code";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { runInAction } from "mobx";

// Custom
import { DetailsHeader } from "Globals/Views/DetailsPage/DetailsHeader";
import { ProjectGeneralViewModel } from "../General/ProjectGeneralViewModel";

// Styling & Images
import { DetailsPage } from "Globals/Views/DetailsPage/DetailsPage.styles";
import { ProjectEditBox } from "../Project.styles";
import { AppUrls } from "AppUrls";
import { SiteAccessViewModel } from "./SiteAccessViewModel";
import { SiteAccessTable } from "./SiteAccessTable";
import { SHBox } from "../../../Components/Box";
import styled from "styled-components";
import { PDFFileView } from "../../../Globals/Views/PDFFileView";
import { SiteAccessFilterViewModel } from "./SiteAccessFilterViewModel";
import moment from "moment";
import { SolidDivider } from "Views/PurchaseOrder/Form/Views/Dividers";
import { DarwinInput } from "Globals/Styles/Control/DarwinInput";
import { PrimaryButton } from "Components/Buttons/Buttons";

export const SiteAccessView: React.FunctionComponent = () => {
    const { match } = useRouter();
    const { projectid } = match.params as any;
    const [generalViewModel] = useState(() => ProjectGeneralViewModel.Instance);
    const [viewModel] = useState(() => SiteAccessViewModel.Instance);
    const svgRef = React.useRef<any>(null);
    const [svgURI, setSVGURI] = useState("");

    const encodeSvgString = () => {
        if (svgRef !== undefined && svgRef != null && svgRef.current !== null && svgRef.current !== undefined) {
            const svgElement = document.getElementById("QRCode")!; // select the SVG element
            const svgString = svgElement.outerHTML; // get the SVG data as a string
            const base64String = btoa(svgString); // convert the encoded SVG string to a base 64 string
            const source = `data:image/svg+xml;base64,${base64String}`;
            // NOTE: PDF doesn't seem to like base64 svg, or base64 jpg or blob url...
            return source;
        }
        return "";
    };

    // https://github.com/diegomura/react-pdf/issues/1250#issuecomment-834420806
    function svgToDataURI() {
        const canvas = document.createElement("canvas");
        canvas.setAttribute("id", "QRCodeCanvas");
        canvas.setAttribute("style", "display: none");
        document.body.appendChild(canvas);

        canvas.width = 168;
        canvas.height = 168;
        const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

        if (!ctx) {
            throw new Error("no canvas 2d context");
        }

        const img = document.createElement("img");
        img.setAttribute("src", encodeSvgString());

        img.onload = function load() {
            ctx.drawImage(img, 0, 0);
            const url = canvas.toDataURL("image/jpeg", 1.0);
            const el = document.getElementById("QRCodeCanvas");
            if (el) el.remove();
            setSVGURI(url);
        };
    }

    useEffect(() => {
        runInAction(async () => {
            // Default to today
            runInAction(() => {
                let todayDate = moment();
                todayDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
                SiteAccessFilterViewModel.Instance.setStartDateFilter(todayDate.toDate());
            });

            await viewModel.loadProjectSiteAccess(projectid);
        });
        return () => {
            viewModel.clean();
        };
    }, []);

    useEffect(() => {
        svgToDataURI();
        const svg = document.getElementById("QRCode")!;
        svg.addEventListener("onload", svgToDataURI);
        console.log(generalViewModel.model);
        // tidy up after yourself
        return () => {
            svg.removeEventListener("onload", svgToDataURI);
        };
    }, []);

    const renderPage = () => {
        return (
            <DetailsPage className="cell-right rcselect">
                <DetailsHeader viewModel={generalViewModel.getHeader} />
                <ProjectEditBox>
                    {/* <div className="row">
                        <ContractCell>
                            <Typography variant="h3">Daily delivery schedule</Typography>
                        </ContractCell>
                        <div className="double">
                        </div>
                        <ContractCell style={{ width: "0" }}></ContractCell>
                    </div> */}
                </ProjectEditBox>

                <SHBox grid dc={"200px 1fr"}>
                    <QRCode id="QRCode" value={`https://${location.host}${AppUrls.Client.Induction.Access.Landing.replace(":projectId", projectid)}`} size={168} ref={svgRef} />
                    {/* <Link to={`${AppUrls.Client.Induction.Access.Landing.replace(":projectId", projectid)}`}>Go to landing test</Link> */}
                    <div>
                        <p>Download site access QR Code</p>
                        <PDFFileView
                            fileName={viewModel.pdfFileName(generalViewModel.model.name)}
                            onClick={() => viewModel.downloadDocument(projectid, generalViewModel.model.name)}
                        />
                        <SiteVideoLinkContainer>
                            <DarwinInput<SiteAccessViewModel>
                                type="text"
                                label="Site video link:"
                                placeholder="Site video link"
                                validateOnBlur={true}
                                viewModel={viewModel}
                                fieldName="siteVideoLink"
                                shrink={true}
                                maxLength={256}
                                editMode={!viewModel.IsLoading}
                            />
                            <PrimaryButton
                                displayName="Save"
                                execute={() => viewModel.saveSiteVideoLink(projectid)}
                                fullWidth={true}
                                canExecute={!viewModel.IsLoading && viewModel.getDirty("siteVideoLink")}
                            />
                        </SiteVideoLinkContainer>
                    </div>
                </SHBox>

                <SHBox style={{ padding: "0px 30px 0px 30px" }}>
                    <SolidDivider gutterBottom={true} gutterTop={true} borderThickness={2} borderOpacity={0.5} />
                </SHBox>

                {viewModel.hasLoaded && (
                    <SiteAccessTable
                        data={viewModel.getSiteAccessItems}
                        isLoading={viewModel.IsLoading}
                        startDateValue={SiteAccessFilterViewModel.Instance.model.getStartDateFilter}
                        endDateValue={SiteAccessFilterViewModel.Instance.model.getEndDateFilter}
                        handleChangeStartDate={(startDate: string | null) => viewModel.handleChangeStartDate(projectid, startDate)}
                        handleChangeEndDate={(endDate: string | null) => viewModel.handleChangeEndDate(projectid, endDate)}
                        handleResetDateFilters={() => viewModel.handleResetDateFilters(projectid)}
                        handleForceSignoutInductions={() => viewModel.forceInductionsSignOut(projectid)}
                        handleSearchChange={(searchText: string) => viewModel.handleSearchChange(projectid, searchText)}
                        handleSignoutFilterChange={(val: boolean) => viewModel.handleSignoutFilterChange(projectid, val)}
                        onSiteFilterValue={SiteAccessFilterViewModel.Instance.model.showOnSiteOnly}
                        handleDownloadCSV={() => viewModel.generateSiteAccessCSV(projectid)}
                    />
                )}
            </DetailsPage>
        );
    };

    return useObserver(() => renderPage());
};

export const SiteVideoLinkContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    > div {
        margin-right: 20px;
    }

    button {
        max-width: 100px;
    }
`;
