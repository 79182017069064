import { FieldType, isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import type { ValidationResponse } from "@shoothill/core";
import { action, computed, observable, runInAction } from "mobx";

import { AppUrls } from "AppUrls";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import {
    IncomeAndExpenditureAndRelatedResponseDTO,
    IncomeAndExpenditureModel,
    IncomeAndExpenditureRelatedResponseDTO,
    IncomeAndExpenditureUpsertResponseDTO,
} from "./IncomeAndExpenditureModel";
import { IncomeAndExpenditureCellModel } from "./IncomeAndExpenditureCellModel";
import { IncomeAndExpenditureParentModel } from "./IncomeAndExpenditureParentModel";
import { IncomeAndExpenditureTypeModel } from "./IncomeAndExpenditureTypeModel";
import { IEAdministratorRolesViewModel } from "./IEAdministratorRolesViewModel";
import { IEAdministratorRolesModel } from "./IEAdministratorRolesModel";
import { UserModel } from "./UserModel";
import { RoleModel } from "./RoleModel";
import { IEAdministrationRoleUserModel } from "./IEAdministrationRoleUserModel";
import { IEAdministrationRoleUserViewModel } from "./IEAdministrationRoleUserViewModel";
import { DetailsHeaderModel } from "Globals/Views/DetailsPage/DetailsHeaderModel";
import { CommercialViewModel } from "../Commercial.ViewModel";

export class IncomeAndExpenditureViewModel extends ViewModelBase<IncomeAndExpenditureModel> {
    // #region Constructors and Disposers

    // private static _instance: IncomeAndExpenditureViewModel;
    // public static get Instance() {
    //     return this._instance || (this._instance = new this(null, ""));
    // }

    constructor(id: string | null, projectId: string) {
        super(new IncomeAndExpenditureModel());
        this.setDecorators(IncomeAndExpenditureModel);

        this.model.id = id;
        this.model.projectId = projectId;

        isEmptyOrWhitespace(this.model.id) ? this.loadRelated() : this.loadWithRelated();
    }

    @computed
    public get isViewOnly() {
        return !CommercialViewModel.Instance.canEditIE;
    }

    // #endregion Constructors and Disposers

    /**
     * Header
     */
    @computed
    get getHeader(): DetailsHeaderModel {
        const retVal: DetailsHeaderModel = new DetailsHeaderModel();
        if (this.model.name !== null && this.model.name !== undefined && this.model.name !== "") {
            retVal.setValue("title", this.model.name);
        } else {
            retVal.setValue("title", "New I & E");
        }

        return retVal;
    }

    @computed
    get getButtonText(): string {
        if (this.model.id !== null && this.model.id !== undefined && this.model.id !== "") {
            return "Update";
        } else {
            return "Create";
        }

        return "Create";
    }

    // #region Cells

    @observable
    public cells = observable<IncomeAndExpenditureCellModel>([]);

    @computed
    private get validateCell(): ValidationResponse {
        const errorMessage = this.model.validateCell;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    // #endregion Cells

    // #region Name

    @computed
    private get validateName(): ValidationResponse {
        const errorMessage = this.model.validateName(this.model.name, this.parents);

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    // #endregion Name

    // #region Parents

    @observable
    public parents = observable<IncomeAndExpenditureParentModel>([]);

    @computed
    public get canDisplayParents() {
        return this.model.typeId === IncomeAndExpenditureTypeModel.CONSTANT_TYPESUBID;
    }

    @computed
    private get validateParent(): ValidationResponse {
        const errorMessage = this.model.validateParentId;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    // #endregion Parents

    // #region Types

    @observable
    public types = observable<IncomeAndExpenditureTypeModel>([]);

    @action
    public setType = (type: string) => {
        this.setValue("typeId", type);

        // Side-effect.
        if (this.model.typeId === IncomeAndExpenditureTypeModel.CONSTANT_TYPEMASTERID) {
            this.setValue("parentId", IncomeAndExpenditureModel.DEFAULT_PARENTID);
        }
    };

    // #endregion Types

    // #region Properties

    public server: ServerViewModel = new ServerViewModel();

    @computed
    public get isNewIncomeAndExpenditure(): boolean {
        return this.model.id === null;
    }

    @computed
    public get title(): string {
        return this.isNewIncomeAndExpenditure ? "Create new I & E" : "Edit new I & E";
    }

    @computed
    public get ieAdministratorRoleUsers() {
        const items: IEAdministrationRoleUserViewModel[] = [];

        for (const roleUserItem of this.model.ieAdministrationRoleUsers) {
            const item = new IEAdministrationRoleUserViewModel(roleUserItem);

            items.push(item);
        }

        return items.filter((i) => !i.model.isDeleted);
    }

    @computed
    public get validateIEAdministratorRoleUsers(): ValidationResponse {
        const errorMessage = this.ieAdministratorRoleUsers.length < 3 ? "Please assign a user to every role." : "";

        // All roles must have a user assigned.
        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @action
    public addIEAdministratorRoleUser = (ieAdministrationRoleUsersModel: IEAdministratorRolesModel) => {
        this.model.ieAdministrationRoleUsers.push(IEAdministrationRoleUserModel.fromIEAdministrationRoleModel(ieAdministrationRoleUsersModel));
    };

    public ieAdministratorRole: IEAdministratorRolesViewModel = new IEAdministratorRolesViewModel(this.addIEAdministratorRoleUser);

    @observable
    public roles = observable<RoleModel>([]);

    @computed
    public get roleOptions(): RoleModel[] {
        return this.roles;
    }

    @computed
    public get role(): RoleModel | null {
        const result = this.roles.find((p) => p.id === this.ieAdministratorRole.model.roleId);

        return result ? result! : null;
    }

    @observable
    public users = observable<UserModel>([]);

    @computed
    public get userOptions(): UserModel[] {
        return this.users.filter((u) => this.role?.roleLevel === u.roleLevel).filter((u) => this.ieAdministratorRoleUsers.findIndex((ur) => ur.model.userId === u.id) === -1);
    }

    @computed
    public get user() {
        const result = this.users.find((p) => p.id === this.ieAdministratorRole.model.userId);

        return result ? result! : null;
    }

    // #endregion Properties

    // #region Actions

    public loadRelated = (): Promise<void> => {
        return this.server.query<IncomeAndExpenditureRelatedResponseDTO>(
            () => this.Get(`${AppUrls.Server.Projects.IncomeExpend.GetEditIERelated}\\${this.model.projectId}`),
            (result) => {
                runInAction(() => {
                    this.cells.replace(IncomeAndExpenditureCellModel.fromDtos(result.cells));
                    this.parents.replace(IncomeAndExpenditureParentModel.fromDtos(result.parents));
                    this.types.replace(IncomeAndExpenditureTypeModel.fromDtos(result.types));

                    this.users.replace(UserModel.fromDtos(result.users));
                    this.roles.replace(RoleModel.fromDtos(result.ieAdministrationRoles));

                    // Side-effect. Set default value for type.
                    if (this.types.findIndex((t) => t.id === IncomeAndExpenditureTypeModel.CONSTANT_TYPEMASTERID) !== -1) {
                        this.setType(IncomeAndExpenditureTypeModel.CONSTANT_TYPEMASTERID);
                    }
                });
            },
        );
    };

    public loadWithRelated = (): Promise<void> => {
        return this.server.query<IncomeAndExpenditureAndRelatedResponseDTO>(
            () => this.Get(`${AppUrls.Server.Projects.IncomeExpend.GetEditIEAndRelated}\\${this.model.id}`),
            (result) => {
                runInAction(() => {
                    this.cells.replace(IncomeAndExpenditureCellModel.fromDtos(result.cells));
                    this.parents.replace(IncomeAndExpenditureParentModel.fromDtos(result.parents));
                    this.types.replace(IncomeAndExpenditureTypeModel.fromDtos(result.types));

                    this.users.replace(UserModel.fromDtos(result.users));
                    this.roles.replace(RoleModel.fromDtos(result.ieAdministrationRoles));

                    this.model.ieAdministrationRoleUsers.replace(IEAdministrationRoleUserModel.fromDtos(result.ieAdministrativeRoleUsers));
                    this.model.fromDto(result);

                    // Side-effect. Set default value for type.
                    if (isEmptyOrWhitespace(this.model.parentId)) {
                        if (this.types.findIndex((t) => t.id === IncomeAndExpenditureTypeModel.CONSTANT_TYPEMASTERID) !== -1) {
                            this.setType(IncomeAndExpenditureTypeModel.CONSTANT_TYPEMASTERID);
                        }
                    } else {
                        if (this.types.findIndex((t) => t.id === IncomeAndExpenditureTypeModel.CONSTANT_TYPESUBID) !== -1) {
                            this.setType(IncomeAndExpenditureTypeModel.CONSTANT_TYPESUBID);
                        }
                    }
                });
            },
        );
    };

    public save = (): Promise<void> => {
        if (!this.isViewOnly) {
            return this.server.command<IncomeAndExpenditureUpsertResponseDTO>(
                () => this.Post(AppUrls.Server.Projects.IncomeExpend.UpsertIE, this.model.toDto()),
                (result) => {
                    runInAction(() => {
                        this.model.fromDto(result);

                        this.history.push(AppUrls.Client.Project.Commercial.replace(":projectid", this.model.projectId));
                    });
                },
                this.isMyModelValid,
                "There was an error trying to update the income and expenditure",
            );
        } else {
            return Promise.resolve();
        }
    };

    public cancel = (): void => {
        this.history.push(AppUrls.Client.Project.Commercial.replace(":projectid", this.model.projectId));
    };

    // #endregion Actions

    /**
     * Custom model validation function.
     * @returns True if model is valid, false if not.
     */
    public isMyModelValid = async (): Promise<boolean> => {
        let isValid = true;

        const userRoleValidation = this.validateIEAdministratorRoleUsers;

        if (!userRoleValidation.isValid) {
            isValid = false;
        }

        if ((await this.isModelValid()) === false) {
            isValid = false;
        }

        return isValid;
    };

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<IncomeAndExpenditureModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        if (this.server.IsSubmitted) {
            // Process the properties of the model that cannot be supported via
            // the use of decorators.
            switch (fieldName) {
                case "cell": {
                    const result = this.validateCell;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }

                case "name": {
                    const result = this.validateName;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }

                case "parentId": {
                    const result = this.validateParent;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
            }
        } else {
            // Do not validate if the properties of the model have not been
            // submitted to the server.
            errorMessage = "";
            isValid = true;
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Bolierplate
}
