import { action, observable } from "mobx";
import { ModelBase } from "@shoothill/core";

export class NotificationBarModel extends ModelBase<NotificationBarModel> {
    @observable public type: "pending" | "rejected" | "resolved" = "pending";
    @observable public active: boolean = false;
    @observable public content: string = "";
    @observable public path: string = ""; //describes the path you wish the notification to show up
    @observable public item: ApprovalItem = "item";
    @action
    public clearModel() {
        this.type = "pending";
        this.active = false;
        this.content = "";
        this.path = "";
        this.item = "item";
    }
    //fromDto is required but you can leave it blank
    fromDto(model: NotificationBarModelDTO): void {}

    //toDto is required but you can leave it blank
    toDto(model: NotificationBarModelDTO): void {}
}

export interface NotificationBarModelDTO {
    active: boolean;
    children?: any;
    path?: any; //describes the path you wish the notification to show up
    type: "pending" | "rejected" | "resolved";
}

export type ApprovalItem = "item" | "requisition" | "variation" | "invoice" | "client provisional sum" | "drawing";
