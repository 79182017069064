import * as React from "react";
import { Button, TextField } from "@material-ui/core";
import styled from "styled-components";

import { BaseModal } from "Components/Modal/BaseModal";
import { CancelButton } from "Globals/Styles/Control/Buttons";
import { useObserver } from "mobx-react-lite";
import { InvoiceDisputeModalViewModel } from "./InvoiceDisputeModalViewModel";
import { EditContactModalDialogPaper } from "Views/Contacts/EditContactModal.styles";
import { DarwinSelect } from "Components/AutoComplete/DarwinSelect";
import { theme } from "Globals/Styles/AppTheme";

interface IInvoiceDisputeModalProps {
    open?: boolean;
    onClose(): void;
    onSave(): any;
    title: string;
    confirmText: string;
    viewModel: InvoiceDisputeModalViewModel;
    isLoading: boolean;
    isDisputed: boolean;
}

export const InvoiceDisputeModal: React.FunctionComponent<IInvoiceDisputeModalProps> = ({ open, onClose, onSave, title, confirmText, viewModel, isLoading, isDisputed }) => {
    return useObserver(() => (
        <BaseModal
            open={open}
            onClose={onClose}
            title={title}
            PaperComponent={InvoiceDisputeModalDialogPaper}
            actions={
                <>
                    <Button style={{ backgroundColor: `${theme.palette.error.main}` }} onClick={onSave} color="secondary" autoFocus variant="contained" disabled={isLoading}>
                        {confirmText}
                    </Button>
                    <CancelButton onClick={onClose}>Cancel</CancelButton>
                </>
            }
        >
            <DarwinSelect
                displayName="Disputed status code:*"
                execute={viewModel.setInvoiceDisputedStatusCode}
                fullWidth={true}
                getOptionLabel={(option: any) => option.displayName}
                onBlur={() => viewModel.isFieldValid("invoiceDisputedStatusCodeId")}
                options={viewModel.invoiceDisputedStatusCodes}
                placeholder="Please select"
                validationMessage={viewModel.getError("invoiceDisputedStatusCodeId")}
                value={viewModel.invoiceDisputedStatusCode}
                canExecute={true}
            />

            <br />

            {!isDisputed && (
                <TextField
                    id="dispute-reason"
                    multiline
                    rows={10}
                    value={viewModel.model.invoiceDisputedReason}
                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => viewModel.setInvoiceDisputedReason(event.target.value)}
                    fullWidth
                    className="textarea"
                    label="Reason for disputing:*"
                    placeholder="Please add a reason for disputing."
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            )}
        </BaseModal>
    ));
};

export const InvoiceDisputeModalDialogPaper = styled(EditContactModalDialogPaper)`
    width: 100% !important;

    .MuiDialogActions-root > button {
        max-width: 262px;
    }

    .MuiDialogContent-root {
        margin-bottom: 20px;
    }
`;
