// Libraries
import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import styled from "styled-components";

// Custom

// Styling & Images
import { theme } from "Globals/Styles/AppTheme";
import { makeStyles } from "@material-ui/core";
import { formatCurrencyForGrid, formatCurrencyNoSign } from "Utils/Format";
import { RowDash } from "Views/Project/CustomComponents";
import { PackagesViewModel } from "Views/Project/Commercial/PackagesViewModel";
import { ToggleButton } from "Views/Project/Commercial/IETables/IEGrid.Styles";
import { formatCreditDebitReverseWithNeutralClass } from "Utils/Utils";
import { IEReportCategoryDTO, IEReportIEDTO, IEReportItemDTO, IEReportSubCategoryDTO } from "./IEReportModelBase";
import { CVRSummaryGridView } from "../CVRReport/IECVRReport.styles";

interface ITableProps {
    reportViewModel: IEReportIEDTO;
}

export const IEReportGrid: React.FunctionComponent<ITableProps> = (props) => {
    const { reportViewModel } = props;

    const packagesViewModel = PackagesViewModel.Instance;

    const [currentActiveCatIndex, setCurrentActiveCatIndex] = useState(-1);
    const [currentActiveSubCatIndex, setCurrentActiveSubCatIndex] = useState(-1);
    const [currentActiveDescIndex, setCurrentActiveDescIndex] = useState(-1);

    useEffect(() => {}, []);

    const setActiveDescription = (val: number): void => {
        setCurrentActiveDescIndex(val);
    };

    const setActiveSubCategory = (val: number): void => {
        setCurrentActiveSubCatIndex(val);
    };

    const setActiveCategory = (val: number): void => {
        setCurrentActiveCatIndex(val);
    };

    const classes = useStyles();

    const handleSubCategoryEnable = (categoryIndex: number, index: number) => {
        if (categoryIndex !== -1) {
            setActiveCategory(categoryIndex);
        }
        setActiveSubCategory(index);
        setActiveDescription(-1);
    };

    const handleCategoryEnable = (index: number) => {
        setActiveCategory(index);
        setActiveSubCategory(-1);
        setActiveDescription(-1);
    };

    const handleFormatDebit = (val: number) => {
        return formatCreditDebitReverseWithNeutralClass(val);
    };

    const handleCategoryName = (id: number) => {
        return packagesViewModel.getCategoryName(id);
    };

    const handleSubCategoryName = (id: number) => {
        return packagesViewModel.getSubcategoryName(id);
    };

    const handleDescriptionName = (id: number, descriptionOther: string) => {
        if (id === -2 && descriptionOther !== null) {
            return descriptionOther;
        } else {
            return packagesViewModel.getDescriptionName(id);
        }
    };

    const itemView = (obj: IEReportItemDTO[], categoryIndex: number, subCategoryIndex: number) => {
        return (
            <>
                {obj.map((i: IEReportItemDTO, index: number) => (
                    <>
                        <li className="item" key={"item_" + index}>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <div
                                    style={{
                                        color: `${
                                            currentActiveCatIndex === categoryIndex && currentActiveSubCatIndex === subCategoryIndex && currentActiveDescIndex === index
                                                ? "#0095DA"
                                                : ""
                                        }`,
                                        paddingLeft: "35px",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <RowDash style={{ display: "inline-block", marginRight: "10px" }} />
                                    <span className="ie-row-name ie-item-name" title={handleDescriptionName(i.descriptionId, i.descriptionOther)}>
                                        {handleDescriptionName(i.descriptionId, i.descriptionOther)}
                                    </span>
                                </div>
                            </div>
                            <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column1.previous))}</div>
                            <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column1.current))}</div>
                            <div className={`right ${i.column1.creditOrDebitClassName}`}>{formatCurrencyForGrid(formatCurrencyNoSign(i.column1.movement))}</div>
                            <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column2.previous))}</div>
                            <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column2.current))}</div>
                            <div className={`right ${i.column2.creditOrDebitClassName}`}>{formatCurrencyForGrid(formatCurrencyNoSign(i.column2.movement))}</div>
                            <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column3.previous))}</div>
                            <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column3.current))}</div>
                            <div className={`right ${i.column3.creditOrDebitClassName}`}>{formatCurrencyForGrid(formatCurrencyNoSign(i.column3.movement))}</div>
                            <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column4.previous))}</div>
                            <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column4.current))}</div>
                            <div className={`right ${i.column4.creditOrDebitClassName}`}>{formatCurrencyForGrid(formatCurrencyNoSign(i.column4.movement))}</div>
                        </li>
                    </>
                ))}
            </>
        );
    };

    const subcategoryView = (obj: IEReportSubCategoryDTO[], categoryIndex: number) => {
        return (
            <>
                {obj.map((i: IEReportSubCategoryDTO, index: number) => (
                    <>
                        <div className={currentActiveSubCatIndex === index && currentActiveCatIndex === categoryIndex ? classes.SubCatBorder : ""}>
                            <li className={"subcategory"} key={"subs_" + index}>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <div
                                        style={{
                                            color: `${currentActiveCatIndex === categoryIndex && currentActiveSubCatIndex === index ? "#0095DA" : ""}`,
                                            paddingLeft: "10px",
                                        }}
                                    >
                                        <RowDash style={{ display: "inline-block", marginRight: "10px" }} />
                                        <span className="ie-row-name ie-subcat-name" title={handleSubCategoryName(i.subCategoryId)}>
                                            {handleSubCategoryName(i.subCategoryId)}
                                        </span>
                                    </div>
                                    {currentActiveCatIndex === categoryIndex && currentActiveSubCatIndex === index ? (
                                        <ToggleButton>
                                            <span
                                                className={"open"}
                                                onClick={() => {
                                                    handleSubCategoryEnable(-1, -1);
                                                }}
                                            >
                                                &minus;
                                            </span>
                                        </ToggleButton>
                                    ) : (
                                        <ToggleButton>
                                            <span
                                                className={"close"}
                                                onClick={() => {
                                                    handleSubCategoryEnable(categoryIndex, index);
                                                }}
                                            >
                                                &#43;
                                            </span>
                                        </ToggleButton>
                                    )}
                                </div>
                                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column1.previous))}</div>
                                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column1.current))}</div>
                                <div className={`right ${i.column1.creditOrDebitClassName}`}>{formatCurrencyForGrid(formatCurrencyNoSign(i.column1.movement))}</div>
                                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column2.previous))}</div>
                                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column2.current))}</div>
                                <div className={`right ${i.column2.creditOrDebitClassName}`}>{formatCurrencyForGrid(formatCurrencyNoSign(i.column2.movement))}</div>
                                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column3.previous))}</div>
                                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column3.current))}</div>
                                <div className={`right ${i.column3.creditOrDebitClassName}`}>{formatCurrencyForGrid(formatCurrencyNoSign(i.column3.movement))}</div>
                                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column4.previous))}</div>
                                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column4.current))}</div>
                                <div className={`right ${i.column4.creditOrDebitClassName}`}>{formatCurrencyForGrid(formatCurrencyNoSign(i.column4.movement))}</div>
                            </li>
                            {currentActiveSubCatIndex === index && currentActiveCatIndex === categoryIndex && <>{itemView(i.items, categoryIndex, index)}</>}
                        </div>
                    </>
                ))}
            </>
        );
    };

    return useObserver(() => (
        <>
            <CVRSummaryGridView className={classes.IELineGroup}>
                {reportViewModel?.categories.map((i: IEReportCategoryDTO, index: number) => (
                    <>
                        <div className={currentActiveCatIndex === index ? classes.CatBorder : ""}>
                            <>
                                <li className={"ie"} key={"cat_" + index}>
                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <span
                                            style={{ color: `${currentActiveCatIndex === index ? "#0095DA" : ""}`, paddingLeft: "5px" }}
                                            className="ie-row-name ie-cat-name"
                                            title={handleCategoryName(i.categoryId)}
                                        >
                                            {handleCategoryName(i.categoryId)}
                                        </span>
                                        {currentActiveCatIndex === index ? (
                                            <ToggleButton>
                                                <span
                                                    className={"open"}
                                                    onClick={() => {
                                                        handleCategoryEnable(-1);
                                                    }}
                                                >
                                                    &minus;
                                                </span>
                                            </ToggleButton>
                                        ) : (
                                            <ToggleButton>
                                                <span
                                                    className={"opcloseen"}
                                                    onClick={() => {
                                                        handleCategoryEnable(index);
                                                    }}
                                                >
                                                    &#43;
                                                </span>
                                            </ToggleButton>
                                        )}
                                    </div>
                                    <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column1.previous))}</div>
                                    <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column1.current))}</div>
                                    <div className={`right ${i.column1.creditOrDebitClassName}`}>{formatCurrencyForGrid(formatCurrencyNoSign(i.column1.movement))}</div>
                                    <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column2.previous))}</div>
                                    <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column2.current))}</div>
                                    <div className={`right ${i.column2.creditOrDebitClassName}`}>{formatCurrencyForGrid(formatCurrencyNoSign(i.column2.movement))}</div>
                                    <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column3.previous))}</div>
                                    <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column3.current))}</div>
                                    <div className={`right ${i.column3.creditOrDebitClassName}`}>{formatCurrencyForGrid(formatCurrencyNoSign(i.column3.movement))}</div>
                                    <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column4.previous))}</div>
                                    <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column4.current))}</div>
                                    <div className={`right ${i.column4.creditOrDebitClassName}`}>{formatCurrencyForGrid(formatCurrencyNoSign(i.column4.movement))}</div>
                                </li>
                            </>
                            {currentActiveCatIndex === index && <>{subcategoryView(i.subCategories, index)}</>}
                        </div>
                    </>
                ))}
                <li className="ie">
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <span style={{ paddingLeft: "5px" }}>{"Totals"}</span>
                    </div>
                    <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(reportViewModel?.column1.previous ? reportViewModel?.column1.previous : 0.0))}</div>
                    <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(reportViewModel?.column1.current ? reportViewModel?.column1.current : 0.0))}</div>
                    <div className={`right ${reportViewModel.column1.creditOrDebitClassName}`}>
                        {formatCurrencyForGrid(formatCurrencyNoSign(reportViewModel?.column1.movement ? reportViewModel?.column1.movement : 0.0))}
                    </div>
                    <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(reportViewModel?.column2.previous ? reportViewModel?.column2.previous : 0.0))}</div>
                    <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(reportViewModel?.column2.current ? reportViewModel?.column2.current : 0.0))}</div>
                    <div className={`right ${reportViewModel.column2.creditOrDebitClassName}`}>
                        {formatCurrencyForGrid(formatCurrencyNoSign(reportViewModel?.column2.movement ? reportViewModel?.column2.movement : 0.0))}
                    </div>
                    <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(reportViewModel?.column3.previous ? reportViewModel?.column3.previous : 0.0))}</div>
                    <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(reportViewModel?.column3.current ? reportViewModel?.column3.current : 0.0))}</div>
                    <div className={`right ${reportViewModel.column3.creditOrDebitClassName}`}>
                        {formatCurrencyForGrid(formatCurrencyNoSign(reportViewModel?.column3.movement ? reportViewModel?.column3.movement : 0.0))}
                    </div>
                    <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(reportViewModel?.column4.previous ? reportViewModel?.column4.previous : 0.0))}</div>
                    <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(reportViewModel?.column4.current ? reportViewModel?.column4.current : 0.0))}</div>
                    <div className={`right ${reportViewModel.column4.creditOrDebitClassName}`}>
                        {formatCurrencyForGrid(formatCurrencyNoSign(reportViewModel?.column4.movement ? reportViewModel?.column4.movement : 0.0))}
                    </div>
                </li>
            </CVRSummaryGridView>
        </>
    ));
};

const useStyles = makeStyles({
    IELineGroup: {
        borderBottom: `4px solid ${theme.palette.detail.main}`,
    },
    CatBorder: {
        borderTop: `4px solid ${theme.palette.detail.main}`,
        borderBottom: `4px solid ${theme.palette.detail.main}`,
    },
    SubCatBorder: {
        borderTop: `2px solid ${theme.palette.detail.main}`,
        borderBottom: `2px solid ${theme.palette.detail.main}`,
    },
    ItemBorder: {
        borderTop: `1px solid ${theme.palette.detail.main}`,
        borderBottom: `1px solid ${theme.palette.detail.main}`,
    },
});

export const IESubItem = styled.div`
    > li {
        > div:nth-child(1) {
            margin-left: 50px;
        }
    }
`;
