import { observable } from "mobx";
import { GenericPhotoListItemDTO, GenericPhotoListItemModel } from "../../Supporting/GenericPhotoListItemModel";

export class AccidentPhotoListItemModel extends GenericPhotoListItemModel {
    @observable
    public documentType: AccidentDocumentType = AccidentDocumentType.Unknown;
    constructor() {
        super();
    }

    fromDto(model: AccidentPhotoListItemDTO): void {
        super.fromDto(model);
        this.documentType = model.documentType;
    }

    public static fromDtos(dtos: AccidentPhotoListItemDTO[]): AccidentPhotoListItemModel[] {
        const types: AccidentPhotoListItemModel[] = [];

        for (const dto of dtos) {
            const model = new AccidentPhotoListItemModel();

            model.fromDto(dto);
            types.push(model);
        }

        return types;
    }

    toDto(): AccidentPhotoListItemDTO {
        const dto: GenericPhotoListItemDTO = super.toDto();
        return {
            ...dto,
            documentType: this.documentType,
        };
    }
}

export interface AccidentPhotoListItemDTO extends GenericPhotoListItemDTO {
    documentType: AccidentDocumentType;
}

export enum AccidentDocumentType {
    Unknown = 0,
    InjuryDetails = 10,
    Training = 20,
    EquipmentMaintenance = 30,
    RAAndSignedSheet = 40,
    MSAndSignedSheet = 50,
}
