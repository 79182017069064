import { AddressModel, AddressModelDTO } from "Globals/Models/Domain";
import moment from "moment";
//import * as Models from "Custom/Models";
import * as StringUtils from "./String";
import { VariationTypeEnum } from "Views/Variation/Form/VariationModel";

export const dateFormat = "D/M/YYYY";
export const doubleDateFormat = "DD/MM/YYYY";
export const isoDateFormat = "YYYY-MM-DDT00:00:00[Z]";
export const timeFormat = "H:mm";
export const dateTimeFormat = `${doubleDateFormat} ${timeFormat}`;

export const formatDate = (date: string) => {
    //AJ: This first check might not be needed, and might be handled by momentDate.isValid().
    //AJ: But I don't have time to test and confirm.
    if (date == undefined || date.length == 0) return "";

    //AJ: Dates without times don't need UTC/local handling
    const momentDate = moment(date);

    if (momentDate.isValid() == false) return "";

    return momentDate.format(doubleDateFormat);
};

export const formatTime = (date: string) => {
    //AJ: This first check might not be needed, and might be handled by momentDate.isValid().
    //AJ: But I don't have time to test and confirm.
    if (date == undefined || date.length == 0) return "";

    //AJ: Times without dates don't need UTC/local handling
    const momentDate = moment(date);

    if (momentDate.isValid() == false) return "";

    return momentDate.format(timeFormat);
};

export const formatDateTime = (date: string) => {
    //AJ: This first check might not be needed, and might be handled by momentDate.isValid().
    //AJ: But I don't have time to test and confirm.
    if (date == undefined || date.length == 0) return "";

    //AJ: DateTimes do need UTC/local handling
    const momentDate = moment.utc(date).local();

    if (momentDate.isValid() == false) {
        return "";
    }

    return momentDate.format(dateTimeFormat);
};

export const formatBooleanYesNo = (value: boolean) => {
    return value == true ? "Yes" : "No";
};

export const formatBooleanYesNoDate = (value: boolean, date: string | undefined) => {
    let retVal: string = "Yes"; // assume yes, to make the logic below so much simpler

    if (value === true) {
        if (date !== undefined && date !== null && date.length > 0) {
            const momentDate = moment.utc(date);
            if (momentDate.isValid() === true) {
                return momentDate.format(doubleDateFormat);
            }
        }
    } else {
        retVal = "No";
    }

    return retVal;
};

export const formatAddress = (address: AddressModelDTO, withPostcode: boolean = false) => {
    let formattedAddress = "";

    if (address.addressLine1) formattedAddress += ` ${address.addressLine1},`;

    if (address.addressLine2) formattedAddress += ` ${address.addressLine2},`;

    if (address.city) formattedAddress += ` ${address.city},`;

    if (address.county) formattedAddress += ` ${address.county},`;

    if (withPostcode === true && address.postcode) formattedAddress += ` ${address.postcode},`;

    formattedAddress = StringUtils.trimChar(formattedAddress, ",").trim();

    return formattedAddress;
};

export const formatAddressAlternate = (address: AddressModel, withPostcode: boolean = false) => {
    let formattedAddress = "";

    if (address.addressLine1) formattedAddress += ` ${address.addressLine1},`;

    if (address.addressLine2) formattedAddress += ` ${address.addressLine2},`;

    if (address.city) formattedAddress += ` ${address.city},`;

    if (address.county) formattedAddress += ` ${address.county},`;

    if (withPostcode === true && address.postcode) formattedAddress += ` ${address.postcode},`;

    formattedAddress = StringUtils.trimChar(formattedAddress, ",").trim();

    return formattedAddress;
};

export const formatLowercaseAddress = (address: AddressModelDTO, withPostcode: boolean = false) => {
    let formattedAddress = formatAddress(address, withPostcode);
    return formattedAddress.toLocaleLowerCase();
};

/* export const formatShow = (state: boolean) => {
    return "TODO";
};

export const formatWarningCount = (warningCount: number) => {
    return "TODO";
}; */

/* export const formatMapAddressWithoutPostcode = (address: Microsoft.Maps.IAddress) => {
	let formattedAddress = "";

	if (address.addressLine) formattedAddress += ` ${address.addressLine},`;

	if (address.adminDistrict) formattedAddress += ` ${address.adminDistrict},`;

	if (address.countryRegion) formattedAddress += ` ${address.countryRegion},`;

	if (address.postalCode) formattedAddress += ` ${address.postalCode},`;

	formattedAddress = StringUtils.trimChar(formattedAddress, ",").trim();

	return formattedAddress;
}; */

export const formatValue = (value: string | number | undefined) => {
    if (value == null) {
        return "-";
    }

    if (typeof value === "string" && value.length > 0) {
        return `£ ${value}`;
    }

    if (typeof value === "number") {
        return `£ ${value.toFixed(2)}`;
    }

    return "-";
};

/**
 * Formats a currency value stored in pennies. 12345678 => £123,456.78.
 *
 * @param value Value stored in pennies. 12345678.
 * @param minimumFractionDigits In some circumstances we might need more than 2 decimal places.
 *
 * @returns Formatted string. £123,456.78.
 */
export const formatCurrency = (value: number, minimumFractionDigits?: number, maximumFractionDigits?: number): string => {
    let valuePounds: number = value / 100;
    let retVal = valuePounds.toLocaleString("en-GB", {
        style: "currency",
        currency: "GBP",
        minimumFractionDigits: minimumFractionDigits,
        maximumFractionDigits: maximumFractionDigits,
    });

    // If the value has a fractional penny, append a "p" symbol.
    if (minimumFractionDigits && minimumFractionDigits >= 2 && countDecimalPlaces(valuePounds) > minimumFractionDigits) {
        retVal += "p";
    }

    return retVal;
};

/**
 * Formats a currency value stored in pounds. 123456.78 => £123,456.78.
 *
 * @param valuePounds Value stored in pounds. 123456.78.
 * @param minimumFractionDigits In some circumstances we might need more than 2 decimal places.
 *
 * @returns Formatted string. £123,456.78.
 */
export const formatCurrencyFromPounds = (valuePounds: number | null, minimumFractionDigits?: number, maximumFractionDigits?: number): string => {
    if (valuePounds !== null) {
        let retVal = valuePounds.toLocaleString("en-GB", {
            style: "currency",
            currency: "GBP",
            minimumFractionDigits: minimumFractionDigits,
            maximumFractionDigits: maximumFractionDigits,
        });

        // If the value has a fractional penny, append a "p" symbol.
        if (minimumFractionDigits && minimumFractionDigits >= 2 && countDecimalPlaces(valuePounds) > minimumFractionDigits) {
            retVal += "p";
        }

        return retVal;
    }

    return "";
};

/**
 * Formats a currency value stored in pounds, without the currency sign. 123456.78 => 123,456.78.
 *
 * @param value Value stored in pounds. 123456.78.
 * @param minimumFractionDigits In some circumstances we might need more than 2 decimal places.
 *
 * @returns Formatted string without currency sign. 123,456.78.
 */
export const formatCurrencyNoSign = (value: number, minimumFractionDigits: number = 2): string => {
    if (value !== null) {
        let retVal = value.toLocaleString("en-GB", { minimumFractionDigits: minimumFractionDigits });

        return retVal;
    }

    return "";
};

/**
 * Returns the number of decimal places in a number.
 *
 * @param value The number.
 *
 * @returns The number of decimal places.
 */
export const countDecimalPlaces = (value: number): number => {
    const match = value.toString().match(/(?:\.(\d+))?(?:eE)?$/);

    if (!match) {
        return 0;
    }

    // Get the digits after the decimal point.
    const decimalPart = match[1] || "";

    return decimalPart.length;
};

/**
 * Formats an integer number (comma per thousand 1234 => 1,234).
 *
 * @param value Integer number.
 *
 * @returns Formmated string.
 */
export const formatIntegerNumber = (value: number): string => {
    return value.toLocaleString("en-GB");
};

/**
 * Formats the variation number for a variation. E.g. 5 => DG-VAR-00000005.
 * @param value Variation number integer. E.g. 5.
 * @returns Formatted purchase order number string. E.g. DG-VAR-00000005.
 */
export const formatVariationNumber = (variationNumber: number | null, variationTypeEnum: VariationTypeEnum): string => {
    if (variationNumber === null) {
        return "";
    }

    let variationNumberPrefix = "DG-VAR-";

    switch (variationTypeEnum) {
        case VariationTypeEnum.Variation:
            variationNumberPrefix = "DG-VAR-";
            break;
        case VariationTypeEnum.ClientProvisionalSum:
            variationNumberPrefix = "DG-CPS-";
            break;
        default:
            variationNumberPrefix = "DG-VAR-";
            break;
    }

    return `${variationNumberPrefix}${variationNumber.toString().padStart(8, "0")}`;
};

/**
 * Formats the permit number for a permit. E.g. 5 => P-00000005.
 * @param value Permit number integer. E.g. 5.
 * @returns Formatted purchase order number string. E.g. P-00000005.
 */
export const formatPermitNumber = (value: number | null): string => {
    if (value === null) {
        return "";
    }

    const variationNumberPrefix = "P-";

    return `${variationNumberPrefix}${value.toString().padStart(8, "0")}`;
};

/**
 * Formats the incident number for a incident. E.g. 5 => IDG-00000005.
 * @param value Incident number integer. E.g. 5.
 * @returns Formatted incident number string. E.g. IDG-00000005.
 */
export const formatIncidentNumber = (value: number | null): string => {
    if (value === null) {
        return "";
    }

    const variationNumberPrefix = "IDG-";

    return `${variationNumberPrefix}${value.toString().padStart(8, "0")}`;
};

/**
 * Handles showing "-" instead of "0.00" for grid values.
 * @param value The value as a string.
 * @returns Either the value, or "-" if the value equals "0.00".
 */
export const formatCurrencyForGrid = (value: string): string => {
    return value !== "0.00" ? value : "-";
};

/**
 * Handles showing "N/A" instead of "0.00" for grid values.
 * @param value The value as a string.
 * @returns Either the value, or "N/A" if the value equals "0.00".
 */
export const formatCurrencyForGridNA = (value: string): string => {
    return value !== "0.00" ? value : "N/A";
};

/**
 * Handles showing "-" instead of "" for table values.
 * @param value The value as a string.
 * @returns Either the value, or "-" if the value is missing.
 */
export const formatEmptyValueForTableDash = (value: string | null): string => {
    return value === "" || value === null || value === undefined ? "-" : value;
};
