import { action, computed, observable } from "mobx";
import { ModelBase } from "@shoothill/core";

export class VariationNoteModel extends ModelBase<VariationNoteModel, VariationNoteDTO> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Constants and Defaults

    public static readonly DEFAULT_ID = null;
    public static readonly DEFAULT_NOTE = "";
    public static readonly DEFAULT_VARIATIONID = null;
    public static readonly DEFAULT_VARIATIONNOTETYPEID = null;
    public static readonly DEFAULT_CREATEDBYUSERID = null;
    public static readonly DEFAULT_CREATEDDATE = null;
    public static readonly DEFAULT_CREATEDBYUSERNAME = null;

    // #endregion Constants and Defaults

    // #region Properties

    @observable
    public id: string | null = VariationNoteModel.DEFAULT_ID;

    @observable
    public note: string = VariationNoteModel.DEFAULT_NOTE;

    @observable
    public variationId: string | null = VariationNoteModel.DEFAULT_VARIATIONID;

    @observable
    public variationNoteTypeId: string | null = VariationNoteModel.DEFAULT_VARIATIONNOTETYPEID;

    @observable
    public createdByUserId: string | null = VariationNoteModel.DEFAULT_CREATEDBYUSERID;

    @observable
    public createdDate: string | null = VariationNoteModel.DEFAULT_CREATEDDATE;

    @observable
    public createdByUserName: string | null = VariationNoteModel.DEFAULT_CREATEDBYUSERNAME;

    // #endregion Properties

    // #region Actions

    @action
    public reset = () => {
        this.id = VariationNoteModel.DEFAULT_ID;
        this.note = VariationNoteModel.DEFAULT_NOTE;
        this.variationId = VariationNoteModel.DEFAULT_VARIATIONID;
        this.variationNoteTypeId = VariationNoteModel.DEFAULT_VARIATIONNOTETYPEID;
        this.createdByUserId = VariationNoteModel.DEFAULT_CREATEDBYUSERID;
        this.createdDate = VariationNoteModel.DEFAULT_CREATEDDATE;
        this.createdByUserName = VariationNoteModel.DEFAULT_CREATEDBYUSERNAME;
    };

    @action
    public fromDto(dto: VariationNoteDTO): void {
        for (let key in dto) {
            if (dto.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(dto[key]);
                } else {
                    this[key] = dto[key];
                }
            }
        }
    }

    public toDto(): VariationNoteDTO {
        const note: VariationNoteDTO = {
            id: this.id,
            note: this.note,
            variationId: this.variationId,
            variationNoteTypeId: this.variationNoteTypeId,
            createdByUserId: this.createdByUserId,
            createdDate: this.createdDate,
            createdByUserName: this.createdByUserName,
        };

        return note;
    }

    // #endregion Actions

    // #region Custom Validation

    @computed
    public get validateNote(): string {
        // RULES
        // The note must be defined.
        return this.note === VariationNoteModel.DEFAULT_NOTE || this.note === "" ? "Please provide a note" : "";
    }

    // #endregion Custom Validation
}

export interface VariationNoteDTO {
    id: string | null;
    note: string | null;
    variationId: string | null;
    variationNoteTypeId: string | null;
    createdByUserId: string | null;
    createdDate: string | null;
    createdByUserName: string | null;
}
