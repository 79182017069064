import * as MobX from "mobx";
import { InvoiceStatusEnum } from "Views/Invoice/Form/Details/InvoiceDetailsModel";

export class InductionStatusTypeModel {
    constructor(dto: InductionStatusTypeDTO | null = null) {
        if (dto) {
            this.id = dto.id;
            this.ordinal = dto.ordinal;
            this.displayName = dto.displayName;
            this.type = dto.type;
            this.color = dto.color;
            this.textColor = dto.textColor;
        }
    }

    @MobX.observable
    public id: string = "";

    @MobX.observable
    public ordinal: number = 0;

    @MobX.observable
    public displayName: string = "Not defined";

    @MobX.observable
    public type: InvoiceStatusEnum = InvoiceStatusEnum.Draft;

    @MobX.observable
    public color: string = "#000000";

    @MobX.observable
    public textColor: string = "#FFFFFF";
}

export type InductionStatusTypeDTO = {
    id: string;
    ordinal: number;
    displayName: string;
    type: InvoiceStatusEnum;
    color: string;
    textColor: string;
};
