import { DefaultButton } from "Components/Buttons/Buttons";
import styled from "styled-components";

export const ApprovalPanelInvoiceContainer = styled.div`
    .darwin-dialog-body {
        padding: 0px !important;
    }

    .secondary-row {
        line-height: 0.8;

        > span {
            white-space: pre-wrap;
        }
    }
`;

export const ApprovalPanelInvoiceBodySection = styled.div`
    border-bottom: 1px dotted #ced4da;
    padding: 15px;

    p {
        color: #191919;
        font-size: 12px;
        line-height: 1.33;
    }

    .body-subtitle {
        font-weight: bold;
        margin-bottom: 5px;
        letter-spacing: -0.24px;
    }
`;

export const ApprovalPanelInvoiceDisabledBodySection = styled(ApprovalPanelInvoiceBodySection)`
    background-color: #ededed;
    border-bottom: 1px solid #ced4da;

    p {
        font-style: italic;
    }
`;
