import { ApiResult, FieldType, isNullOrUndefined, ViewModelBase } from "@shoothill/core";
import { action, computed, observable } from "mobx";
import { AppUrls } from "AppUrls";
import { InvoicingListModel } from "./InvoicingListModel";
import { InvoicingListItemsAndRelatedDTO } from "./InvoicingListItemsAndRelatedDTO";
import { InvoicingStatusTypeModel } from "./InvoicingStatusTypeModel";
import { InvoicingListItemViewModel } from "./InvoicingListItemViewModel";
import { InvoicingListFilterViewModel } from "./InvoicingListFilterViewModel";
import { InvoicingListFilterParamsBaseModelDTO } from "./InvoiceListFilterParamsBaseModel";
import debounce from "lodash-es/debounce";

export class InvoicingListViewModel extends ViewModelBase<InvoicingListModel> {
    private static _instance: InvoicingListViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }
    constructor() {
        super(new InvoicingListModel(), false);
        this.setDecorators(InvoicingListModel);
    }

    @computed
    public get getSearchString() {
        return InvoicingListFilterViewModel.Instance.model.searchText;
    }

    public getSearchStringComputed = () => {
        return computed(() => InvoicingListFilterViewModel.Instance.model.searchText);
    };

    public handleSearchChange = (val: string) => {
        InvoicingListFilterViewModel.Instance.setValue("searchText", val);

        if (val.length > 3 || val.length === 0) {
            this.reloadDataDelayed();
        }
    };

    private reloadDataDelayed = debounce(() => {
        this.apiGetAll();
    }, 500);

    @action
    public cleanUp = () => {
        this.reloadDataDelayed.cancel();
        this.setHasLoaded(false);
    };

    @action
    public resetDateFilters = () => {
        this.model.filterEndDateINV = null;
        this.model.filterStartDateINV = null;
    };

    @observable
    public errorMessage: string = "";

    @observable
    public invoicingViewModels: InvoicingListItemViewModel[] = [];

    @action
    private populateListItemViewModels = (dto: InvoicingListItemsAndRelatedDTO) => {
        const invoicingViewModels2: InvoicingListItemViewModel[] = [];
        for (const invoicingListItem of dto.invoiceItem) {
            let itemModel = new InvoicingListModel();
            itemModel.fromDto(invoicingListItem);
            const statusTypeModel = new InvoicingStatusTypeModel(dto.invoiceStatusType.find((pst) => pst.id === invoicingListItem.invoiceStatusId));
            invoicingViewModels2.push(new InvoicingListItemViewModel(itemModel, statusTypeModel));
        }
        this.invoicingViewModels = invoicingViewModels2;
    };

    public handleStatusChange = (val: number[] | null) => {
        InvoicingListFilterViewModel.Instance.setValue("statuses", val);
        this.apiGetAll();
    };

    public handleProjectChange = (val: number[] | null) => {
        InvoicingListFilterViewModel.Instance.setValue("projects", val);
        this.apiGetAll();
    };

    public handleSupplierChange = (val: number[] | null) => {
        InvoicingListFilterViewModel.Instance.setValue("suppliers", val);
        this.apiGetAll();
    };

    public apiGetAll = async (): Promise<ApiResult<InvoicingListItemsAndRelatedDTO>> => {
        return this.apiGetAllInvoices();
    };

    @action
    public clear = () => {
        InvoicingListFilterViewModel.Instance.model.reset();
        this.apiGetAll();
    };

    // Api GetAll Record

    @action
    public setHasLoaded = (val: boolean) => {
        this.hasLoaded = val;
    };

    // Used to ensure that only the results of the last request are used to populate the table.
    private lastRequestId = 0;

    @observable
    public hasLoaded: boolean = false;

    public apiGetAllInvoices = async (): Promise<ApiResult<InvoicingListItemsAndRelatedDTO>> => {
        this.setIsLoading(true);
        const currentRequestId = ++this.lastRequestId;
        let paramsViewModel = InvoicingListFilterViewModel.Instance;
        let params = paramsViewModel.model.toDto();
        params.initialLoad = !this.hasLoaded;

        const apiResult = await this.Post<InvoicingListItemsAndRelatedDTO>(AppUrls.Server.Invoice.GetAllInvoiceRelated, params);
        if (apiResult) {
            if (currentRequestId === this.lastRequestId && apiResult.wasSuccessful) {
                this.populateListItemViewModels(apiResult.payload);
                InvoicingListFilterViewModel.Instance.setTotalCount(apiResult.payload.totalCount);

                if (!this.hasLoaded) {
                    InvoicingListFilterViewModel.Instance.setStatuses(apiResult.payload.invoiceStatusType, true);
                    InvoicingListFilterViewModel.Instance.setProjects(apiResult.payload.projects, true);
                    InvoicingListFilterViewModel.Instance.setSuppliers(apiResult.payload.suppliers, true);
                }
            } else {
                console.log(apiResult.errors);
            }
        }
        this.setIsLoading(false);
        this.setHasLoaded(true);
        return apiResult;
    };

    // End Api GetAll Record

    @action
    public setFromDateFilter = (val: Date | null) => {
        this.model.filterStartDateINV = val;
    };

    @action
    public setToDateFilter = (val: Date | null) => {
        this.model.filterEndDateINV = val;
    };

    // Invoice by Date

    public apiGetDateAllInvoices = async (val: string): Promise<ApiResult<InvoicingListItemsAndRelatedDTO>> => {
        this.setIsLoading(true);
        const currentRequestId = ++this.lastRequestId;
        let paramsViewModel = InvoicingListFilterViewModel.Instance;
        let params: InvoicingListFilterParamsBaseModelDTO = paramsViewModel.model.toDto();
        params.initialLoad = !this.hasLoaded;

        const request: InvoicingListAndRelatedRequest = {
            id: val,
            startDatefilter: this.model.filterStartDateINV,
            endDateFilter: this.model.filterEndDateINV,
            filterParams: params,
        };
        let apiResult = await this.Post<InvoicingListItemsAndRelatedDTO>(AppUrls.Server.Invoice.GetDateRelatedInvoice, request);
        if (apiResult) {
            if (currentRequestId === this.lastRequestId && apiResult.wasSuccessful) {
                this.populateListItemViewModels(apiResult.payload);
                InvoicingListFilterViewModel.Instance.setTotalCount(apiResult.payload.totalCount);

                if (!this.hasLoaded) {
                    InvoicingListFilterViewModel.Instance.setStatuses(apiResult.payload.invoiceStatusType, true);
                    InvoicingListFilterViewModel.Instance.setProjects(apiResult.payload.projects, true);
                    InvoicingListFilterViewModel.Instance.setSuppliers(apiResult.payload.suppliers, true);
                }
            } else {
                console.log(apiResult.errors);
            }
        }
        this.setIsLoading(false);
        this.setHasLoaded(true);
        return apiResult;
    };

    // end Invoice By Date

    public async isFieldValid(fieldName: keyof FieldType<InvoicingListModel>): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}

export interface RequestModel {
    Id: string;
}

export class InvoicingListAndRelatedRequest {
    id: string = "";
    startDatefilter?: Date | null = null;
    endDateFilter?: Date | null = null;
    filterParams: InvoicingListFilterParamsBaseModelDTO | null = null;
}
