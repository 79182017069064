import { action, observable } from "mobx";
import { ModelBase } from "@shoothill/core";

export class InductionListFilterParamsModel extends ModelBase<InductionListFilterParamsModel, InductionListFilterParamsModelDTO> {
    @observable
    public includeDeleted: boolean = false;

    @observable
    public searchText: string = "";

    fromDto(model: InductionListFilterParamsModelDTO): void {
        this.searchText = model.searchText;
    }

    toDto(): InductionListFilterParamsModelDTO {
        let dto: InductionListFilterParamsModelDTO = {
            includeDeleted: this.includeDeleted,
            searchText: this.searchText,
        };
        return dto;
    }

    @action
    public reset = () => {
        this.searchText = "";
    };
}

export interface InductionListFilterParamsModelDTO {
    includeDeleted: boolean;
    searchText: string;
}
