import styled from "styled-components";
import { theme } from "../../Globals/Styles/AppTheme";

export const BarContainer = styled.div`
    width: 100%;
    padding: 7px 0px;
    box-shadow: 0px 3px 6px #00000029;
    z-index: 1200; // Keep this on top of header (Header.style.ts).
`;

export const BarContent = styled.div`
    color: ${theme.palette.common.white};
    font-size: 10px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;
