export const Clock = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="14.25" height="14.25" viewBox="0 0 14.25 14.25">
                <g id="clock" transform="translate(-23.875 -976.237)">
                    <path
                        id="Path_3249"
                        data-name="Path 3249"
                        d="M31,976.362a7,7,0,1,0,7,7,7.008,7.008,0,0,0-7-7Zm0,1.077a5.923,5.923,0,1,1-5.923,5.923A5.915,5.915,0,0,1,31,977.439Zm0,1.884a.539.539,0,0,0-.538.539v3.5a.533.533,0,0,0,.16.378c.7.682,1.4,1.4,2.095,2.095a.535.535,0,1,0,.757-.757l-1.935-1.935v-3.281A.539.539,0,0,0,31,979.324Z"
                        transform="translate(0 0)"
                        fill="#fff"
                        stroke="#fff"
                        strokeWidth="0.25"
                    />
                </g>
            </svg>
        </>
    );
};
