import { ModelBase } from "@shoothill/core";
import { action, observable, computed } from "mobx";

export class DesignDataFormItemModel extends ModelBase<DesignDataFormItemModel> {
    public static readonly DEFAULT_ID = "";
    public static readonly DEFAULT_DESIGNDATA_ID = "";
    public static readonly DEFAULT_INSPECTED_ITEM = "";
    public static readonly DEFAULT_TITLE = "";
    public static readonly DEFAULT_DESCRIPTION = "";
    public static readonly DEFAULT_LAST_UPDATED_DATE = null;
    public static readonly DEFAULT_CREATED_BY_USER_ID = null;
    public static readonly DEFAULT_LAST_UPDATED_BY_USER_ID = null;
    public static readonly DEFAULT_COMPLETED_DATE = null;
    public static readonly DEFAULT_COMPLETED_BY_USER_ID = null;
    public static readonly DEFAULT_IS_DELETED = false;
    public static readonly DEFAULT_ROW_VERSION = null;

    @observable
    public id: string | null = DesignDataFormItemModel.DEFAULT_ID;
    @observable
    public designDataId: string = DesignDataFormItemModel.DEFAULT_DESIGNDATA_ID;
    @observable
    public title: string = DesignDataFormItemModel.DEFAULT_TITLE;
    @observable
    public description: string = DesignDataFormItemModel.DEFAULT_DESCRIPTION;
    @observable
    public createdByUserId: string | null = DesignDataFormItemModel.DEFAULT_CREATED_BY_USER_ID;
    @observable
    public lastUpdatedDate: string | null = DesignDataFormItemModel.DEFAULT_LAST_UPDATED_DATE;
    @observable
    public lastUpdatedByUserId: string | null = DesignDataFormItemModel.DEFAULT_LAST_UPDATED_BY_USER_ID;
    @observable
    public completedDate: string | null = DesignDataFormItemModel.DEFAULT_COMPLETED_DATE;
    @observable
    public completedByUserId: string | null = DesignDataFormItemModel.DEFAULT_COMPLETED_BY_USER_ID;
    @observable
    public isDeleted: boolean = DesignDataFormItemModel.DEFAULT_IS_DELETED;

    public reset = () => {
        this.id = DesignDataFormItemModel.DEFAULT_ID;
        this.designDataId = DesignDataFormItemModel.DEFAULT_DESIGNDATA_ID;
        this.title = DesignDataFormItemModel.DEFAULT_TITLE;
        this.description = DesignDataFormItemModel.DEFAULT_DESCRIPTION;
        this.lastUpdatedDate = DesignDataFormItemModel.DEFAULT_LAST_UPDATED_DATE;
        this.lastUpdatedByUserId = DesignDataFormItemModel.DEFAULT_LAST_UPDATED_BY_USER_ID;
        this.completedDate = DesignDataFormItemModel.DEFAULT_COMPLETED_DATE;
        this.completedByUserId = DesignDataFormItemModel.DEFAULT_COMPLETED_BY_USER_ID;
        this.createdByUserId = DesignDataFormItemModel.DEFAULT_CREATED_BY_USER_ID;
        this.isDeleted = DesignDataFormItemModel.DEFAULT_IS_DELETED;
    };

    @action
    public fromDto(dto: DesignDataItemDTO): void {
        for (let key in dto) {
            if (dto.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(dto[key]);
                } else {
                    this[key] = dto[key];
                }
            }
        }
    }

    public toDto(): DesignDataItemDTO {
        const designDataFormItemModel: DesignDataItemDTO = {
            id: this.id,
            designDataId: this.designDataId,
            title: this.title,
            description: this.description,
            completedByUserId: this.completedByUserId,
            lastUpdatedDate: this.lastUpdatedDate,
            lastUpdatedByUserId: this.lastUpdatedByUserId,
            completedDate: this.completedDate,
            isDeleted: this.isDeleted,
            createdByUserId: this.createdByUserId,
        };

        return designDataFormItemModel;
    }

    @computed
    public get validateTitle(): string {
        return this.title === DesignDataFormItemModel.DEFAULT_TITLE ? "Please provide a title" : "";
    }

    @computed
    public get validateDescription(): string {
        return this.description === DesignDataFormItemModel.DEFAULT_DESCRIPTION ? "Please provide a description" : "";
    }
}

export interface DesignDataItemDTO {
    id: string | null;
    designDataId: string | null;
    title: string;
    description: string;
    createdByUserId: string | null;
    lastUpdatedDate: string | null;
    lastUpdatedByUserId: string | null;
    completedDate: string | null;
    completedByUserId: string | null;
    isDeleted: boolean;
}
