// Libraries
import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { styled, useRouter } from "@shoothill/core";

// Custom
import { ProjectAllReportViewModel } from "./ProjectAllReportViewModel";
import { IEItemsWrapper } from "Views/Project/Commercial/IETables/IEItemsWrapper";
import { IEGridViewList } from "Views/Project/Commercial/IETables/IEGridView.List";

// Styling & Images
import { DetailsPage } from "Globals/Views/DetailsPage/DetailsPage.styles";
import { AppUrls } from "AppUrls";
import { ControlLabel } from "Components/Form/ControlLabel";
import { Box, Typography } from "@material-ui/core";
import moment from "moment";
import { DarwinDateSelect } from "Components/DateSelect/DarwinDateSelect";
import { PrimaryButton } from "Components/Buttons/Buttons";
import { ReportDatePickerContainer, RunReportButtonContainer } from "Views/Report/Report.styles";

moment.locale("en-GB", {
    week: {
        dow: 1,
    },
});

interface Props {
    projectId: string;
}

export const ProjectAllReportView: React.FunctionComponent<Props> = (props: Props) => {
    const router = useRouter();
    const [viewModel] = useState(() => new ProjectAllReportViewModel());

    const { history } = useRouter();

    useEffect(() => {
        return () => {
            viewModel.reset();
        };
    }, []);

    const handleIEItemsClick = (id: string) => {
        history.push(AppUrls.Client.Project.IE.replace(":ieid", id));
    };

    const onReportEndDateTopChanged = (date: string | null) => {
        viewModel.setValue("reportEndDateTop", date === null ? date : moment(date).startOf("day").toISOString());
        viewModel.setCanExportCSV(false);
    };

    const onReportEndDateBottomChanged = (date: string | null) => {
        viewModel.setValue("reportEndDateBottom", date === null ? date : moment(date).endOf("day").toISOString());
        viewModel.setCanExportCSV(false);
    };

    const runReport = () => {
        viewModel.runReport(props.projectId);
        viewModel.setCanExportCSV(true);
    };

    const exportCSV = () => {
        viewModel.generateAllProjectReportCSV(props.projectId);
    };

    return useObserver(() => (
        <DetailsPage>
            <ReportDatePickerContainer>
                <ControlLabel label="Date comparison:" htmlFor="start-date" />
                <Box>
                    <Box maxWidth="200px">
                        <DarwinDateSelect
                            execute={(value: string | null) => onReportEndDateTopChanged(value)}
                            placeholder="Please select"
                            value={viewModel.model.reportEndDateTop}
                            maxDate={moment.utc().toDate()}
                            clearable
                            format="DD/MM/YYYY"
                            canExecute={!viewModel.IsLoading}
                            defaultToStartOfDay={true}
                        />
                    </Box>
                    <Box maxWidth="200px">
                        <DarwinDateSelect
                            execute={(value: string | null) => onReportEndDateBottomChanged(value)}
                            placeholder="Please select"
                            value={viewModel.model.reportEndDateBottom}
                            maxDate={moment.utc().toDate()}
                            clearable
                            format="DD/MM/YYYY"
                            canExecute={!viewModel.IsLoading}
                            defaultToStartOfDay={false}
                        />
                    </Box>
                </Box>
            </ReportDatePickerContainer>

            <RunReportButtonContainer>
                <PrimaryButton displayName={"Run report"} execute={runReport} fullWidth={false} canExecute={!viewModel.IsLoading} />
                <PrimaryButton displayName={"Export CSV"} execute={exportCSV} fullWidth={false} canExecute={!viewModel.IsLoading && viewModel.canExportCSV} />
            </RunReportButtonContainer>

            <IEItemsWrapper>
                <div className="content" style={{ paddingTop: "30px", position: "relative" }}>
                    <Typography variant="h2" style={{ marginLeft: "10px" }}>
                        {viewModel.getTopDateFormatted}
                    </Typography>
                    <IEGridViewList gridViewModel={viewModel.topGridViewModel} handleRowClick={(i, t) => handleIEItemsClick(i)} isViewOnly={true} isLoading={viewModel.IsLoading} />

                    <Typography variant="h2" style={{ marginLeft: "10px" }}>
                        {viewModel.getBottomDateFormatted}
                    </Typography>
                    <IEGridViewList
                        gridViewModel={viewModel.bottomGridViewModel}
                        handleRowClick={(i, t) => handleIEItemsClick(i)}
                        isViewOnly={true}
                        isLoading={viewModel.IsLoading}
                    />
                </div>
            </IEItemsWrapper>
        </DetailsPage>
    ));
};
