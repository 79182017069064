// Libraries
import React, { useState } from "react";
import { useObserver } from "mobx-react-lite";
import { Cell, FieldType, Grid, isNullOrUndefined, useRouter } from "@shoothill/core";

// Styling & Images
import { FormControl, FormControlLabel, FormHelperText, FormLabel, InputLabel, MenuItem, RadioGroup, Select } from "@material-ui/core";
import { IncomeAndExpenditureViewModel } from "./IncomeAndExpenditureViewModel";
import { CustomRadio } from "Views/Project/CustomComponents";
import { DarwinInput } from "Globals/Styles/Control/DarwinInput";
import { IncomeAndExpenditureModel } from "./IncomeAndExpenditureModel";
import { IEAdministrationRolesView } from "./IEAdministrationRolesView";
import { DarwinTablePageContent } from "Globals/Styles/AppStyling";
import { IEAdministrationRoleUserViewModel } from "./IEAdministrationRoleUserViewModel";
import MaterialTable from "material-table";
import * as Defaults from "Globals/Defaults/TableOptions";
import DeleteLogo from "Content/Bin.svg";
import { Alert, AlertTitle } from "@material-ui/lab";
import { DefaultButton, PrimaryButton } from "Components/Buttons/Buttons";
import { DetailsHeader } from "Globals/Views/DetailsPage/DetailsHeader";
import { DetailsPage } from "Globals/Views/DetailsPage/DetailsPage.styles";
import { ProjectButtonContainer } from "../../Project.styles";
import { PatchedPagination } from "../../../../Components/Table/PatchedPagination";
import { DarwinInputWrapper } from "Components/Form/DarwinInputWrapper";

export const IncomeAndExpenditureView: React.FunctionComponent = () => {
    const { match } = useRouter();
    const { id, projectid } = match.params as any;
    const [viewModel] = useState(() => new IncomeAndExpenditureViewModel(id, projectid));

    const handleCancel = () => {
        viewModel!.cancel();
    };

    const handleSave = () => {
        viewModel!.save();
    };

    const handleCellChange = (event: any) => {
        viewModel!.setValue<number>("cell", event.target.value === "" ? -99 : event.target.value);
    };

    const handleParentChange = (event: any) => {
        viewModel!.setValue<string | null>("parentId", event.target.value === "" ? null : event.target.value);
    };

    const handleTypeChange = (event: any) => {
        // Use the explicit setter in the viewmodel as it needs to set a side-effect.
        viewModel!.setType(event.target.value);
    };

    const isInError = (fieldName: keyof FieldType<IncomeAndExpenditureModel>) => {
        return !viewModel!.getValid(fieldName);
    };

    // #endregion Code Behind

    const renderRoleUsersTable = () => {
        return (
            <>
                <DarwinTablePageContent>
                    <MaterialTable
                        columns={[
                            { title: "Role", field: "roleDisplayName" },
                            { title: "Name", field: "userDisplayName" },
                            {
                                title: "",
                                field: "",
                                render: (rowData: IEAdministrationRoleUserViewModel) =>
                                    !viewModel.isViewOnly && (
                                        <div className="table-svg-container">
                                            <span onClick={() => (viewModel.isViewOnly ? {} : rowData.setIsDeleted(true))} style={{ cursor: "pointer" }}>
                                                <img src={DeleteLogo} />
                                            </span>
                                        </div>
                                    ),
                            },
                        ]}
                        components={{
                            Pagination: PatchedPagination,
                        }}
                        options={Defaults.GetDarwinTableOptions()}
                        data={viewModel!.ieAdministratorRoleUsers}
                        title=""
                    />
                </DarwinTablePageContent>
            </>
        );
    };

    return useObserver(() => (
        <DetailsPage>
            <DetailsHeader viewModel={viewModel.getHeader} />
            <div style={{ margin: "0 30px 17.5px 30px" }}>
                <Grid columnGap={30} rowGap={20} tc={"2fr 1fr 1fr"} dc={"2fr 1fr 1fr"}>
                    <Cell>
                        <FormControl component="fieldset">
                            <FormLabel component="label">I & E type:</FormLabel>
                            <RadioGroup row value={viewModel!.model.typeId} onChange={handleTypeChange}>
                                {viewModel!.types.map((type) => (
                                    <FormControlLabel
                                        control={<CustomRadio checked={viewModel!.model.typeId === type.id} />}
                                        checked={viewModel!.model.typeId === type.id}
                                        label={type.displayName}
                                        value={type.id}
                                        disabled={viewModel.isViewOnly}
                                    />
                                ))}
                            </RadioGroup>
                        </FormControl>
                    </Cell>
                    <Cell tabletSpan={2} desktopSpan={2}>
                        {viewModel!.canDisplayParents && (
                            <FormControl fullWidth error={isInError("parentId")}>
                                <InputLabel htmlFor="parent-select" shrink>
                                    Link to:*
                                </InputLabel>
                                <Select
                                    id="parent-select"
                                    value={isNullOrUndefined(viewModel!.model.parentId) ? "" : viewModel!.model.parentId}
                                    onChange={handleParentChange}
                                    MenuProps={{
                                        getContentAnchorEl: null,
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left",
                                        },
                                    }}
                                    disabled={viewModel.isViewOnly}
                                >
                                    {viewModel!.parents.map((parent) => (
                                        <MenuItem key={parent.id} value={parent.id}>
                                            {parent.displayName}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {isInError("parentId") && <FormHelperText style={{ color: "red" }}>{viewModel!.getError("parentId")}</FormHelperText>}
                            </FormControl>
                        )}
                    </Cell>
                </Grid>
                <Grid columnGap={30} rowGap={20} tc={"2fr 1fr 1fr"} dc={"2fr 1fr 1fr"} style={{ borderTop: "dashed 1px #CED4DA", marginTop: "20px", paddingTop: "20px" }}>
                    <Cell>
                        <DarwinInputWrapper<IncomeAndExpenditureModel>
                            type="text"
                            label="I & E name:"
                            validateOnBlur={true}
                            viewModel={viewModel}
                            fieldName="name"
                            shrink={true}
                            maxLength={128}
                            editMode={!viewModel.isViewOnly}
                        />
                    </Cell>
                    <Cell>
                        <FormControl fullWidth error={isInError("cell")}>
                            <InputLabel htmlFor="cell-select" shrink>
                                Cell assignment:
                            </InputLabel>
                            <Select
                                id="cell-select"
                                value={viewModel!.model.cell === -99 ? "" : viewModel!.model.cell}
                                onChange={handleCellChange}
                                MenuProps={{
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left",
                                    },
                                }}
                                disabled={viewModel.isViewOnly}
                            >
                                {viewModel!.cells.map((cell) => (
                                    <MenuItem key={cell.id} value={cell.id}>
                                        {cell.displayName}
                                    </MenuItem>
                                ))}
                            </Select>
                            {isInError("cell") && <FormHelperText style={{ color: "red" }}>{viewModel!.getError("cell")}</FormHelperText>}
                        </FormControl>
                    </Cell>
                </Grid>

                {!viewModel.isViewOnly && <IEAdministrationRolesView viewModel={viewModel!.ieAdministratorRole} ieViewModel={viewModel}></IEAdministrationRolesView>}

                {renderRoleUsersTable()}

                {!viewModel.validateIEAdministratorRoleUsers.isValid && viewModel.server.IsSubmitted && (
                    <FormHelperText style={{ color: "red" }}>{viewModel.validateIEAdministratorRoleUsers.errorMessage}</FormHelperText>
                )}

                <Grid columnGap={30} rowGap={20} tc={"1fr 1fr 1fr 1fr"} dc={"1fr 1fr 1fr 1fr"}>
                    {viewModel!.server.HaveValidationMessage && (
                        <Cell tabletSpan={4} desktopSpan={4}>
                            <Alert severity="error">
                                <AlertTitle>Error</AlertTitle>
                                {viewModel!.server.ValidationMessage}
                            </Alert>
                        </Cell>
                    )}
                </Grid>
            </div>
            <ProjectButtonContainer>
                {!viewModel.isViewOnly && (
                    <PrimaryButton displayName={viewModel.getButtonText} canExecute={!viewModel!.server.IsBusy && !viewModel.isViewOnly} execute={handleSave} />
                )}
                <DefaultButton displayName="Cancel" canExecute={!viewModel!.server.IsBusy} execute={handleCancel} />
            </ProjectButtonContainer>
        </DetailsPage>
    ));
};
