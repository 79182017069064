import { ModelBase, observable } from "@shoothill/core";
import { action } from "mobx";
export class RFIStatusModel extends ModelBase<RFIStatusModel> {
    public static readonly DEFAULT_RFI_ID = "";

    @observable
    public rFIId: string = RFIStatusModel.DEFAULT_RFI_ID;

    @action
    public fromDto(dto: RFIStatusResponseDTO): void {
        for (let key in dto) {
            if (dto.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(dto[key]);
                } else if (key === "rFIItems") {
                    // Do nothing.
                } else {
                    this[key] = dto[key];
                }
            }
        }
    }

    @action
    toDto(model: RFIStatusModel): void {}
}

export interface RFIStatusResponseDTO {}

export interface RFIStatusRequestDTO {
    id: string | null;
}
