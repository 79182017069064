const SvgComponent = (props: any) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={19.212} height={18} {...props}>
        <g data-name="Group 9516">
            <g fill="#ededed" stroke="#707070" strokeWidth={0.9} data-name="Ellipse 202" transform="translate(0 13.286)">
                <ellipse cx={4.714} cy={2.357} stroke="none" rx={4.714} ry={2.357} />
                <ellipse cx={4.714} cy={2.357} fill="none" rx={4.264} ry={1.907} />
            </g>
            <g fill="#ededed" stroke="#707070" strokeWidth={0.9} data-name="Ellipse 203" transform="translate(0 11.382)">
                <ellipse cx={4.714} cy={2.357} stroke="none" rx={4.714} ry={2.357} />
                <ellipse cx={4.714} cy={2.357} fill="none" rx={4.264} ry={1.907} />
            </g>
            <g fill="#ededed" stroke="#707070" strokeWidth={0.9} data-name="Ellipse 204" transform="translate(0 9.668)">
                <ellipse cx={4.714} cy={2.357} stroke="none" rx={4.714} ry={2.357} />
                <ellipse cx={4.714} cy={2.357} fill="none" rx={4.264} ry={1.907} />
            </g>
            <g fill="#ededed" stroke="#707070" strokeWidth={0.9} data-name="Ellipse 205" transform="translate(0 7.954)">
                <ellipse cx={4.714} cy={2.357} stroke="none" rx={4.714} ry={2.357} />
                <ellipse cx={4.714} cy={2.357} fill="none" rx={4.264} ry={1.907} />
            </g>
            <g fill="#ededed" stroke="#707070" strokeWidth={0.9} data-name="Ellipse 206" transform="translate(0 6.24)">
                <ellipse cx={4.714} cy={2.357} stroke="none" rx={4.714} ry={2.357} />
                <ellipse cx={4.714} cy={2.357} fill="none" rx={4.264} ry={1.907} />
            </g>
            <g fill="#ededed" stroke="#707070" strokeWidth={0.9} data-name="Ellipse 207" transform="translate(0 4.525)">
                <ellipse cx={4.714} cy={2.357} stroke="none" rx={4.714} ry={2.357} />
                <ellipse cx={4.714} cy={2.357} fill="none" rx={4.264} ry={1.907} />
            </g>
            <g data-name="Group 9506">
                <g fill="#52bb71" data-name="Group 9505" transform="translate(8.895)">
                    <path d="m3.857 0 3.857 4.286H0Z" data-name="Polygon 26" />
                    <rect width={1.714} height={8.571} data-name="Rectangle 2978" rx={0.857} transform="translate(3.001 1.713)" />
                </g>
                <g fill="#df3a3a" data-name="Group 9504" transform="translate(11.497 7.714)">
                    <path d="M3.857 10.284 0 5.998h7.714Z" data-name="Polygon 27" />
                    <rect width={1.714} height={8.571} data-name="Rectangle 2980" rx={0.857} transform="rotate(180 2.356 4.285)" />
                </g>
            </g>
        </g>
    </svg>
);
export default SvgComponent;
