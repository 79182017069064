import { ModelBase, isEmptyOrWhitespace, observable } from "@shoothill/core";
import { IsNotEmpty } from "class-validator";
import { action, computed } from "mobx";
import moment from "moment";

export class WeatherReportModel extends ModelBase<WeatherReportModel> {
    public static readonly DEFAULT_ID = null;
    public static readonly DEFAULT_PROJECT_ID = "";
    public static readonly DEFAULT_WEATHER_REPORT_DATE = null;
    public static readonly DEFAULT_WEATHER_REPORT_NOTE = "";
    public static readonly DEFAULT_CREATED_DATE = null;
    public static readonly DEFAULT_CREATED_BY_USER_ID = null;
    public static readonly DEFAULT_LAST_UPDATED_DATE = null;
    public static readonly DEFAULT_LAST_UPDATED_BY_USER_ID = null;
    public static readonly DEFAULT_IS_DELETED = false;
    public static readonly DEFAULT_ROW_VERSION = "";

    @observable
    public id: string | null = WeatherReportModel.DEFAULT_ID;
    @observable
    public projectId: string = WeatherReportModel.DEFAULT_PROJECT_ID;
    @observable
    public weatherReportDate: any = WeatherReportModel.DEFAULT_WEATHER_REPORT_DATE;
    @observable
    @IsNotEmpty({ message: "Please enter a note" })
    public weatherReportNote: string = WeatherReportModel.DEFAULT_WEATHER_REPORT_NOTE;
    @observable
    public createdDate: string | null = WeatherReportModel.DEFAULT_CREATED_DATE;
    @observable
    public createdByUserId: string | null = WeatherReportModel.DEFAULT_CREATED_BY_USER_ID;
    @observable
    public lastUpdatedDate: string | null = WeatherReportModel.DEFAULT_LAST_UPDATED_DATE;
    @observable
    public lastUpdatedByUserId: string | null = WeatherReportModel.DEFAULT_LAST_UPDATED_BY_USER_ID;
    @observable
    public isDeleted: boolean = WeatherReportModel.DEFAULT_IS_DELETED;
    @observable
    public rowVersion: string | null = WeatherReportModel.DEFAULT_ROW_VERSION;

    @computed
    public get hasId(): boolean {
        return !isEmptyOrWhitespace(this.id);
    }

    @computed
    public get formattedLastUpdatedDate(): string {
        const date = !isEmptyOrWhitespace(this.lastUpdatedDate) ? this.lastUpdatedDate : this.createdDate;

        return moment(date).format("DD/MM/YYYY @ HH:mm");
    }

    @computed
    public get updatedByUserId(): string {
        const userId = !isEmptyOrWhitespace(this.lastUpdatedByUserId) ? this.lastUpdatedByUserId : this.createdByUserId;

        return userId ?? "";
    }

    @action
    public reset(): void {
        this.id = WeatherReportModel.DEFAULT_ID;
        this.projectId = WeatherReportModel.DEFAULT_PROJECT_ID;
        this.weatherReportDate = WeatherReportModel.DEFAULT_WEATHER_REPORT_DATE;
        this.weatherReportNote = WeatherReportModel.DEFAULT_WEATHER_REPORT_NOTE;
        this.createdDate = WeatherReportModel.DEFAULT_CREATED_DATE;
        this.createdByUserId = WeatherReportModel.DEFAULT_CREATED_BY_USER_ID;
        this.lastUpdatedDate = WeatherReportModel.DEFAULT_LAST_UPDATED_DATE;
        this.lastUpdatedByUserId = WeatherReportModel.DEFAULT_LAST_UPDATED_BY_USER_ID;
        this.isDeleted = WeatherReportModel.DEFAULT_IS_DELETED;
        this.rowVersion = WeatherReportModel.DEFAULT_ROW_VERSION;
    }

    @action
    public fromDto(dto: WeatherReportDTO | null): void {
        if (dto !== null) {
            this.id = dto.id;
            // projectId is already set.
            // weatherReportDate is already set.
            this.weatherReportNote = dto.weatherReportNote;
            this.createdDate = dto.createdDate;
            this.createdByUserId = dto.createdByUserId;
            this.lastUpdatedDate = dto.lastUpdatedDate;
            this.lastUpdatedByUserId = dto.lastUpdatedByUserId;
            this.isDeleted = dto.isDeleted;
            this.rowVersion = dto.rowVersion;
        }
    }

    public toDto(): WeatherReportDTO {
        return {
            id: this.id,
            projectId: this.projectId,
            weatherReportDate: this.weatherReportDate.toISOString(),
            weatherReportNote: this.weatherReportNote,
            createdDate: this.createdDate,
            createdByUserId: this.createdByUserId,
            lastUpdatedDate: this.lastUpdatedDate,
            lastUpdatedByUserId: this.lastUpdatedByUserId,
            isDeleted: this.isDeleted,
            rowVersion: this.rowVersion,
        };
    }
}

export interface WeatherReportDTO {
    id: string | null;
    projectId: string;
    weatherReportDate: string;
    weatherReportNote: string;
    createdDate: string | null;
    createdByUserId: string | null;
    lastUpdatedDate: string | null;
    lastUpdatedByUserId: string | null;
    isDeleted: boolean;
    rowVersion: string | null;
}

export interface WeatherReportResponseDTO {
    weatherReport: WeatherReportDTO | null;
}

export interface FormRequestDTO {
    // Weather report id.
    formId: string | null;

    // Project id.
    projectId: string | null;

    // Weather report date.
    date: string | null;
}
