import { Box, FormHelperText, Grid, MenuItem, Select } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";

import { ClientEditBox, ModalContent } from "Views/Admin/Client/ClientAddEdit.style";
import { DarwinInput } from "Globals/Styles/Control/DarwinInput";

import { FieldType, generateID, useRouter } from "@shoothill/core";
import { CostCategoryHelpers } from "Globals/Models/Domain";
import { ControlLabel } from "Components/Form/ControlLabel";
import { AddSupplierModel } from "./AddSupplierModel";
import { AddSupplierViewModel } from "./AddSupplierViewModel";
interface IAddSupplierModalProps {
    //ref: any;
}

export const AddSupplierView: React.FunctionComponent<IAddSupplierModalProps> = ({}) => {
    const [viewModel, setViewModel] = useState<AddSupplierViewModel>(AddSupplierViewModel.Instance);
    const router = useRouter();

    useEffect(() => {
        return () => {
            viewModel.cleanUp();
        };
    }, []);

    //useEffect below only gets run on initial render
    useEffect(() => {
        return () => {
            viewModel.cleanUp();
        };
    }, []);

    function handleCostCategoryChange(event: any) {
        viewModel.setValue<number>("costCategory", event.target.value);
    }

    const isInError = (fieldName: keyof FieldType<AddSupplierModel>) => {
        let isValid = viewModel.getValid(fieldName);
        return !isValid;
    };

    const costCategories = CostCategoryHelpers.getOptions(false);

    return useObserver(() => (
        <ModalContent className="addSupplierModel">
            <ClientEditBox className="supplierNameAdd">
                <Grid container spacing={2}>
                    <h5>To create a new Supplier we require the following pieces of information</h5>
                </Grid>
                <div className="formGroup">
                    <DarwinInput<AddSupplierModel> type="text" label="Supplier reference:" validateOnBlur={true} viewModel={viewModel} fieldName="reference" shrink={true} />
                </div>
                <div className="formGroup">
                    <DarwinInput<AddSupplierModel> type="text" label="Supplier name:" validateOnBlur={true} viewModel={viewModel} fieldName="name" shrink={true} />
                </div>
                <div className="formGroup">
                    <Box className="cell-right rcselect" marginTop="-3px" width="100%">
                        <ControlLabel label="Cost category:" htmlFor="costcategory-select">
                            <Box maxWidth="200px">
                                <Select
                                    id="costcategory-select"
                                    value={viewModel.model.getCostCategory}
                                    onChange={handleCostCategoryChange}
                                    MenuProps={{
                                        getContentAnchorEl: null,
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left",
                                        },
                                    }}
                                    fullWidth
                                >
                                    {costCategories.map((category, index) => {
                                        return (
                                            <MenuItem key={generateID()} value={category.value}>
                                                {category.label}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                <Box>{isInError("costCategory") && <FormHelperText style={{ color: "red" }}>{viewModel.getError("costCategory")}</FormHelperText>}</Box>
                            </Box>
                        </ControlLabel>
                    </Box>
                </div>
            </ClientEditBox>
        </ModalContent>
    ));
};
