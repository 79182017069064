import { ModelBase } from "@shoothill/core";
import { action, computed, observable } from "mobx";

import { InductionType } from "Globals/Models/Enums/InductionType";
import { PersonalProtectiveEquipment } from "Globals/Models/Enums/PersonalProtectiveEquipment";
import { PersonalProtectiveEquipmentType } from "Globals/Models/Enums/PersonalProtectiveEquipmentType";

export class Step4Model extends ModelBase<Step4Model> {
    // #region Defaults

    public static readonly DEFAULT_PERSONALPROTECTIVEEQUIPMENT = [];
    public static readonly DEFAULT_PERSONALPROTECTIVEEQUIPMENTTYPES = [];
    public static readonly DEFAULT_SELECTED_PERSONALPROTECTIVEEQUIPMENTTYPES = [];

    // #endregion Defaults

    // #region Properties

    @observable
    public personalProtectiveEquipment = observable<PersonalProtectiveEquipment>(Step4Model.DEFAULT_PERSONALPROTECTIVEEQUIPMENT);

    @observable
    public personalProtectiveEquipmentTypes = observable<PersonalProtectiveEquipmentType>(Step4Model.DEFAULT_PERSONALPROTECTIVEEQUIPMENTTYPES);

    @observable
    public selectedPersonalProtectiveEquipmentTypes = observable<PersonalProtectiveEquipmentType>(Step4Model.DEFAULT_SELECTED_PERSONALPROTECTIVEEQUIPMENTTYPES);

    @computed
    public get isValid() {
        for (const ppe of this.personalProtectiveEquipment) {
            if (this.selectedPersonalProtectiveEquipmentTypes.findIndex((ppeType: PersonalProtectiveEquipmentType) => ppeType.personalProtectiveEquipmentId === ppe.id) === -1) {
                return false;
            }
        }

        return true;
    }

    // #endregion Properties

    // #region Actions

    @action
    public fromDto(dto: any): void {
        throw new Error("Method not implemented.");
    }

    public toDto(): any {
        throw new Error("Method not implemented.");
    }

    public toDependentDto(inductionType: InductionType): any {
        let personalProtectiveEquipmentTypeIds = [];

        switch (true) {
            case inductionType.IsOfType(InductionType.Full):
                personalProtectiveEquipmentTypeIds = this.selectedPersonalProtectiveEquipmentTypes.map((ppeType) => ppeType.id);
                break;

            case inductionType.IsOfType(InductionType.Visitor):
            default:
                personalProtectiveEquipmentTypeIds = Step4Model.DEFAULT_SELECTED_PERSONALPROTECTIVEEQUIPMENTTYPES;
                break;
        }

        return {
            personalProtectiveEquipmentTypeIds: personalProtectiveEquipmentTypeIds,
        };
    }

    // #endregion Actions
}
