import * as React from "react";
import styled from "styled-components";

import { BaseModal } from "Components/Modal/BaseModal";
import { CancelButton } from "Globals/Styles/Control/Buttons";
import { useObserver } from "mobx-react-lite";
import { EditContactModalDialogPaper } from "Views/Contacts/EditContactModal.styles";

interface IUnapprovedPurchaseOrderLineValidationModalProps {
    open?: boolean;
    onClose(): void;
    title: string;
    message: string;
    isLoading: boolean;
    items: { id: string; name: string; requestedByUserName: string }[];
}

// May want separate component for PurchaseOrders and Invoices.
export const UnapprovedPurchaseOrderLineValidationModal: React.FunctionComponent<IUnapprovedPurchaseOrderLineValidationModalProps> = ({
    open,
    onClose,
    title,
    isLoading,
    message,
    items,
}) => {
    const renderBody = () => {
        return (
            <>
                <div>{message}</div>
                <ul style={{ paddingLeft: "20px" }}>
                    {items.map((item) => {
                        return (
                            <li key={item.id}>
                                {item.requestedByUserName} - {item.name}
                            </li>
                        );
                    })}
                </ul>
            </>
        );
    };

    return useObserver(() => (
        <BaseModal
            open={open}
            onClose={onClose}
            title={title}
            PaperComponent={(props) => <UnapprovedPurchaseOrderLineValidationModalDialogPaper {...props} />}
            actions={
                <>
                    <CancelButton onClick={onClose}>Close</CancelButton>
                </>
            }
        >
            <LineValidationContainer>{renderBody()}</LineValidationContainer>
        </BaseModal>
    ));
};

export const UnapprovedPurchaseOrderLineValidationModalDialogPaper = styled(EditContactModalDialogPaper)`
    width: 100% !important;
    max-width: 1000px;
    max-width: "1000px";

    .MuiDialogActions-root > button {
        max-width: 262px;
    }

    .MuiDialogContent-root {
        margin-bottom: 20px;
    }

    .MuiDivider-root {
        background-color: rgba(0, 0, 0, 0.12) !important;
    }
`;

const LineValidationContainer = styled.div``;
