import { ApiResult, FieldType, ViewModelBase } from "@shoothill/core";
import { AppUrls } from "AppUrls";
import { observable, action, runInAction, computed } from "mobx";
import { ProjectContractualModel, ProjectContractualModelDTO } from "./ProjectContractual";
import validator from "validator";
import { ProjectStatusTypeDTO, ProjectStatusTypeModel } from "../ProjectStatusTypeModel";

export class ProjectContractualViewModel extends ViewModelBase<ProjectContractualModel> {
    private static _instance: ProjectContractualViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable public errorMessage: string = "";

    public constructor() {
        super(new ProjectContractualModel(), false);
        this.setDecorators(ProjectContractualModel);
    }

    @action
    public clean = () => {
        // Any Cleanup Code here. e.g. if  a user or project or client etc, wipe it from the instance on page shutdown
        this.model.cleanup();
    };

    @action
    public loadModel(projectConstruction: ProjectContractualModelDTO | null) {
        if (projectConstruction !== null) {
            this.model.fromDto(projectConstruction);
        }
    }

    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (await this.isMyModelValid()) {
            //Do stuff here
            this.errorMessage = "Form is valid";
        } else {
            this.errorMessage = "Form is not valid";
        }
    };

    public get isContactEmailValid(): string {
        let error = "";

        const field: string | null = this.getValue<string | null>("eaContactEmail");

        /*         if (isNullOrEmpty(field)) {
            error = "Contact email is required";
        } else { */
        if (!validator.isEmail(field!)) {
            error = "Email address is invalid";
        }
        /* } */

        return error;
    }

    public get isOverheadPercentageValid(): string {
        let error = "";

        if (this.model.overheadPercentage === null || this.model.overheadPercentage === undefined || isNaN(this.model.overheadPercentage)) {
            error = "Overhead percentage is invalid";
        }

        return error;
    }

    public get isDesignPercentageValid(): string {
        let error = "";

        if (this.model.designPercentage === null || this.model.designPercentage === undefined || isNaN(this.model.designPercentage)) {
            error = "Design percentage is invalid";
        }

        return error;
    }

    /*     public get isContactMobileNumberValid(): string {
                let error = "";

                const field: string | null = this.getValue<string | null>("eaContactMobile");

                if (!isNullOrEmpty(field)) { */
    // if (field!.length < 9 || field!.length > 11) {
    //     error = "Phone number is invalid";
    // }
    /* 
            if (!validator.isMobilePhone("en-GB")) {
                error = "Mobile number is invalid";
            }
        }

        return error;
    }

    public get isContactPhoneNumberValid(): string {
        let error = "";

        const field: string | null = this.getValue<string | null>("eaContactPhone");

        if (!isNullOrEmpty(field)) { */
    // if (field!.length < 9 || field!.length > 11) {
    //     error = "Phone number is invalid";
    // }
    /*           if (!validator..IsPhoneNumber("en-GB")) {
                error = "Phone number is invalid";
            } */
    /*         }

        return error;
    } */

    public async isFieldValid(fieldName: keyof FieldType<ProjectContractualModel>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        if (fieldName === "eaContactEmail") {
            if (value.length > 0) {
                // then we need to ensure the value is a valid email
                errorMessage = this.isContactEmailValid;
                isValid = errorMessage === "";
            }
        }

        if (fieldName === "overheadPercentage") {
            // then we need to ensure the value is valid
            errorMessage = this.isOverheadPercentageValid;
            isValid = errorMessage === "";
        }

        if (fieldName === "designPercentage") {
            // then we need to ensure the value is valid
            errorMessage = this.isDesignPercentageValid;
            isValid = errorMessage === "";
        }

        /*  CMR Why does validator.IsPhoneNumber("en-GB") fail for valid phone numbers
        if (fieldName === "eaContactMobile") {
            if (value.length > 0) {
                // then we need to ensure the value is a valid email
                errorMessage = this.isContactMobileNumberValid;
                isValid = errorMessage === "";
            }
        }

        if (fieldName === "eaContactPhone") {
            if (value.length > 0) {
                // then we need to ensure the value is a valid email
                errorMessage = this.isContactPhoneNumberValid;
                isValid = errorMessage === "";
            }
        } */

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    private isMyModelValid = async (): Promise<boolean> => {
        let isValid = true;

        // If you have a child form, you can call it's own viewmodels validation from here.
        /*if ((await this.addressFormViewModel.isModelValid()) === false) {
            isValid = false;
        }*/

        // Could also perform this in a loop if you have a list of child forms.
        /*for (let i = 0; i < this.shiftViewModels.length; i++) {
            let shift = this.shiftViewModels[i];

            if (shift.model.isEmptyForm === false) {
                if ((await shift.isModelValid()) === false) {
                    isValid = false;
                }
            }
        }*/

        // this will automatically call isFieldValid.
        if ((await this.isModelValid()) === false) {
            isValid = false;
        }
        return isValid;
    };

    @action public upsertContractual = async (): Promise<ApiResult<ProjectContractualModelDTO>> => {
        this.setIsLoading(true);
        let request = this.model.toUpsertDTO();

        let apiResult = await this.Post<ProjectContractualModelDTO>(AppUrls.Server.Projects.Contractual.Upsert, request);
        if (apiResult) {
            runInAction(() => {
                if (apiResult.wasSuccessful) {
                    this.model.fromDto(apiResult.payload);
                    this.setSnackMessage("Details saved/updated");
                    this.setSnackType(this.SNACKSUCCESS);
                    this.setSnackbarState(true);
                } else {
                    this.setSnackMessage("Error saving/updating details");
                    this.setSnackType(this.SNACKERROR);
                    this.setSnackbarState(true);
                }
            });
        }
        this.setIsLoading(false);
        return apiResult;
    };

    @observable
    public snackbarState = false;

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public snackMessage = "";

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @observable
    public snackType = "";

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    @observable
    public SNACKSUCCESS = "success";

    @observable
    public SNACKERROR = "error";

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
