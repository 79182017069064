import { action, computed, observable } from "mobx";
import { isEmptyOrWhitespace, ModelBase } from "@shoothill/core";

export class DeleteIncomeAndExpenditureModel extends ModelBase<DeleteIncomeAndExpenditureModel, IncomeAndExpenditureDeleteResponseDTO> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Constants and Defaults

    public static readonly DEFAULT_ID = null;
    public static readonly DEFAULT_NAME = "";
    public static readonly DEFAULT_ISDELETED = false;
    public static readonly DEFAULT_ROWVERSION = null;

    // #endregion Constants and Defaults

    // #region Properties

    @observable
    public id: string | null = DeleteIncomeAndExpenditureModel.DEFAULT_ID;

    @observable
    public name: string = DeleteIncomeAndExpenditureModel.DEFAULT_NAME;

    @observable
    public isDeleted: boolean = DeleteIncomeAndExpenditureModel.DEFAULT_ISDELETED;

    @observable
    public rowVersion: string | null = DeleteIncomeAndExpenditureModel.DEFAULT_ROWVERSION;

    // #endregion Properties

    // #region Actions

    @action
    public fromDto(dto: IncomeAndExpenditureDeleteResponseDTO): void {
        this.id = dto.id;
        this.name = dto.name;
        this.isDeleted = dto.isDeleted;
        this.rowVersion = dto.rowVersion;
    }

    public toDto(): IncomeAndExpenditureDeleteRequestDTO {
        return {
            id: this.id!,
            rowVersion: this.rowVersion!,
        };
    }

    // #endregion Actions

    // #region Custom Validation

    @computed
    public get validateId(): string {
        return this.id === DeleteIncomeAndExpenditureModel.DEFAULT_ID ? "Please provide an Id" : "";
    }

    @computed
    public get validateRowVersion(): string {
        return this.rowVersion === DeleteIncomeAndExpenditureModel.DEFAULT_ROWVERSION ? "Please provide a row version" : "";
    }

    // #endregion Custom Validation
}

export interface IncomeAndExpenditureDeleteRequestDTO {
    id: string;
    rowVersion: string;
}

export interface IncomeAndExpenditureDeleteResponseDTO {
    id: string;
    isDeleted: boolean;
    name: string;
    rowVersion: string;
}
