import { getHistory, FieldType, ViewModelBase, KeyValuePair } from "@shoothill/core";
import { action, computed, observable, runInAction } from "mobx";

import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { AllProjectsReportModel, AllProjectsReportRelatedResponseDTO } from "./AllProjectsReportModel";
import { AppUrls } from "AppUrls";
import { ProjectStatusTypeDTO } from "Views/Project/ProjectStatusTypeModel";

export enum ReportType {
    All = "All",
    CVR = "CVR",
    Cost = "Cost",
    Invoice = "Invoice",
}

export class AllProjectsReportViewModel extends ViewModelBase<AllProjectsReportModel> {
    // #region Constructors and Disposers

    constructor() {
        super(new AllProjectsReportModel());

        this.setDecorators(AllProjectsReportViewModel);
    }

    @observable
    public reportTypeOptions = observable<{ id: string; displayName: string }>([
        { id: "", displayName: "Please select" },
        { id: ReportType.All, displayName: ReportType.All },
        { id: ReportType.CVR, displayName: ReportType.CVR },
        { id: ReportType.Cost, displayName: ReportType.Cost },
        { id: ReportType.Invoice, displayName: ReportType.Invoice },
    ]);

    @computed
    public get reportTypeOption() {
        return this.reportTypeOptions.find((o) => o.id === this.model.reportType);
    }

    @action
    public setReportType = (reportType: string) => {
        this.model.reportType = reportType;
    };

    @observable
    public readonly projectStatusOptions: KeyValuePair[] = [];

    public getProjectStatusOptions = (): KeyValuePair[] => {
        return this.projectStatusOptions === undefined ? [] : this.projectStatusOptions.slice();
    };

    @action
    public setStatuses(statusOptions: ProjectStatusTypeDTO[]) {
        if (this.projectStatusOptions !== null && this.projectStatusOptions.length === 0) {
            if (statusOptions.length > 0) {
                this.projectStatusOptions.push(
                    ...statusOptions.map((item: ProjectStatusTypeDTO) => {
                        return { key: item.name, value: item.id };
                    }),
                );
            }
        }
    }

    public server: ServerViewModel = new ServerViewModel();

    public loadRelated = async (): Promise<void> => {
        return await this.server.query<AllProjectsReportRelatedResponseDTO>(
            () => this.Get(`${AppUrls.Server.Report.AllProjects.LoadRelated}`),
            (result) => {
                runInAction(() => {
                    this.setStatuses(result.projectStatuses);
                });
            },
        );
    };

    @action
    public reset = () => {
        this.model.reset();
        this.server.reset();
    };

    @action
    public handleCancel = (): void => {
        getHistory().goBack();
    };

    // #endregion Client Actions

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<AllProjectsReportModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    // #region Snackbar

    @observable
    public snackbarState = false;

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public snackMessage = "";

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @observable
    public snackType = "";

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    @observable
    public SNACKSUCCESS = "success";

    @observable
    public SNACKERROR = "error";
    // #endregion

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
