import { action, observable, runInAction } from "mobx";
import { FieldType, ViewModelBase } from "@shoothill/core";
import { AuditListModel } from "./AuditListModel";
import { AppUrls } from "../../../../AppUrls";
import { AuditQuestionModel } from "./EndOfDay/AuditQuestionModel";
import { InductionUserLookupModel } from "../Inspections/InductionUserLookupModel";
import { ServerViewModel } from "../../../../Globals/ViewModels/ServerViewModel";
import moment from "moment";

export class AuditListViewModel extends ViewModelBase<AuditListModel> {
    public server: ServerViewModel = new ServerViewModel();
    public projectId: string = "";
    @observable
    public showList: boolean = true;
    @observable
    public showEndOfDay: boolean = false;
    @observable
    public showWeekly: boolean = false;
    public date: Date = new Date();
    constructor(projectId: string, date: Date, model = new AuditListModel()) {
        super(model);
        this.setDecorators(AuditListModel);
        this.projectId = projectId;
        this.date = date;
    }

    public getLastUpdatedDate = (date: Date | null): string => {
        if (!date) return "-";
        return moment(date).format("DD/MM/YYYY @ HH:mm") + " by";
    };
    public getFormattedDate = (date: Date | null): string => {
        if (!date) return "-";
        return moment(date).format("DD/MM/YYYY");
    };
    public getStatus = (projectId: string, date: Date): Promise<void> => {
        return this.server.query<any>(
            () => this.Get(`${AppUrls.Server.Projects.Construction.Audits.GetAuditStatus}?projectId=${projectId}&date=${date?.toISOString()}`),
            (result) => {
                runInAction(() => {
                    if (result.endOfDayStatus) {
                        this.setValue("endOfDayId", result.endOfDayStatus!.id);
                        this.setValue("endOfDayValidUntil", result.endOfDayStatus!.createdDate);
                        this.setValue("endOfDayLastUpdatedDate", result.endOfDayStatus!.lastUpdatedDate);
                        this.setValue("endOfDayLastUpdatedBy", result.endOfDayStatus!.lastUpdatedBy);
                        this.setValue("endOfDayCreatedDate", result.endOfDayStatus!.createdDate);
                    } else {
                        this.setValue("endOfDayId", "");
                        this.setValue("endOfDayValidUntil", null);
                        this.setValue("endOfDayLastUpdatedDate", null);
                        this.setValue("endOfDayLastUpdatedBy", "");
                        this.setValue("endOfDayCreatedDate", null);
                    }
                    if (result.endOfWeekStatus) {
                        const [firstDayOfWeek, lastDayOfWeek] = this.getFirstAndLastDayOfWeek(new Date(result.endOfWeekStatus!.createdDate));
                        this.setValue("endOfWeekId", result.endOfWeekStatus!.id);
                        this.setValue("endOfWeekLastUpdatedDate", result.endOfWeekStatus!.lastUpdatedDate);
                        this.setValue("endOfWeekValidUntil", `${firstDayOfWeek} - ${lastDayOfWeek}`);
                        this.setValue("endOfWeekLastUpdatedBy", result.endOfWeekStatus!.lastUpdatedBy);
                        this.setValue("endOfWeekCreatedDate", result.endOfWeekStatus!.createdDate);
                    } else {
                        this.setValue("endOfWeekId", "");
                        this.setValue("endOfWeekValidUntil", null);
                        this.setValue("endOfWeekLastUpdatedDate", null);
                        this.setValue("endOfWeekLastUpdatedBy", "");
                        this.setValue("endOfWeekCreatedDate", null);
                    }
                });
            },
        );
    };

    @action
    public handleShowList = () => {
        this.showList = true;
        this.showEndOfDay = false;
        this.showWeekly = false;
        this.getStatus(this.projectId, this.date);
    };
    @action
    public handleShowEndOfDay = () => {
        if (!this.server.IsBusy) {
            this.showList = false;
            this.showEndOfDay = true;
            this.showWeekly = false;
        }
    };
    @action
    public handleShowWeekly = () => {
        if (!this.server.IsBusy) {
            this.showList = false;
            this.showEndOfDay = false;
            this.showWeekly = true;
        }
    };

    public getFirstAndLastDayOfWeek(date: Date) {
        const day = date.getDay();
        const firstDayOfWeek = new Date(date.setDate(date.getDate() - day));
        const lastDayOfWeek = new Date(firstDayOfWeek);
        lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);
        return [moment(firstDayOfWeek).add(1, "days").format("DD/MM/YYYY"), moment(lastDayOfWeek).add(-1, "days").format("DD/MM/YYYY")];
    }

    public async isFieldValid(fieldName: keyof FieldType<AuditListModel>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }
    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
