import { ModelBase } from "@shoothill/core";
import { action, observable, computed } from "mobx";

export class HouseKeepingFormModel extends ModelBase<HouseKeepingFormModel> {
    // #region Constants

    public static readonly DEFAULT_ID = null;
    public static readonly DEFAULT_PROJECT_ID = "";
    public static readonly DEFAULT_NON_CONFORMANCE_TYPE_ID = "";

    // Concerning Housekeeping
    public static readonly DEFAULT_SUPPLIER_ID = null;
    public static readonly DEFAULT_ISSUED_BY_USER_ID = null;
    public static readonly DEFAULT_SIGNATURE_ISSUED_BY_URL = null;
    public static readonly DEFAULT_SIGNATURE_ISSUED_BY_DATE = null;

    // Concerning audit
    public static readonly DEFAULT_CREATED_DATE = null;
    public static readonly DEFAULT_CREATED_BY_USER_ID = null;
    public static readonly DEFAULT_LAST_UPDATED_DATE = null;
    public static readonly DEFAULT_LAST_UPDATED_USER_ID = null;
    public static readonly DEFAULT_COMPLETED_DATE = null;
    public static readonly DEFAULT_COMPLETED_BY_USER_ID = null;

    public static readonly DEFAULT_IS_DELETED = false;
    public static readonly DEFAULT_ROW_VERSION = null;

    // #endregion Constants

    // #region Related Properties

    @observable
    public suppliers = observable<SupplierDTO>([]);

    @observable
    public issuedByUsers = observable<IssuedByUsersDTO>([]);

    @observable
    public workerUsers = observable<HouseKeepingProjectDetailsDTO>([]);

    // #endregion Related Properties

    // #region Form Properties

    @observable
    public id: string | null = HouseKeepingFormModel.DEFAULT_ID;

    @observable
    public projectId: string = HouseKeepingFormModel.DEFAULT_PROJECT_ID;

    @observable
    public nonConformanceTypeId: string = HouseKeepingFormModel.DEFAULT_NON_CONFORMANCE_TYPE_ID;

    // Concerning housekeeping
    @observable
    public supplierId: string | null = HouseKeepingFormModel.DEFAULT_SUPPLIER_ID;

    @observable
    public issuedByUserId: string | null = HouseKeepingFormModel.DEFAULT_ISSUED_BY_USER_ID;

    @observable
    public signatureIssuedByURL: string | null = HouseKeepingFormModel.DEFAULT_SIGNATURE_ISSUED_BY_URL;

    @observable
    public signatureIssuedByDate: string | null = HouseKeepingFormModel.DEFAULT_SIGNATURE_ISSUED_BY_DATE;

    // Concerning audit

    @observable
    public createdDate: string | null = HouseKeepingFormModel.DEFAULT_CREATED_DATE;

    @observable
    public createdByUserId: string | null = HouseKeepingFormModel.DEFAULT_CREATED_BY_USER_ID;

    @observable
    public lastUpdatedDate: string | null = HouseKeepingFormModel.DEFAULT_LAST_UPDATED_DATE;

    @observable
    public lastUpdatedByUserId: string | null = HouseKeepingFormModel.DEFAULT_LAST_UPDATED_USER_ID;

    @observable
    public completedDate: string | null = HouseKeepingFormModel.DEFAULT_CREATED_BY_USER_ID;

    @observable
    public completedByUserId: string | null = HouseKeepingFormModel.DEFAULT_COMPLETED_BY_USER_ID;

    @observable
    public isDeleted: boolean = HouseKeepingFormModel.DEFAULT_IS_DELETED;

    @observable
    public rowVersion: string | null = HouseKeepingFormModel.DEFAULT_ROW_VERSION;

    // #endregion Form Properties

    // #Start Custome Validation

    @computed
    public get validateSupplierId(): string {
        return this.supplierId === HouseKeepingFormModel.DEFAULT_SUPPLIER_ID ? "Please Select User" : "";
    }

    @computed
    public get validateIssuedByUserId(): string {
        return this.issuedByUserId === HouseKeepingFormModel.DEFAULT_ISSUED_BY_USER_ID ? "Please Select Worker User" : "";
    }
    @computed
    public get validateSignatureIssuedByURL(): string {
        return this.signatureIssuedByURL === HouseKeepingFormModel.DEFAULT_SIGNATURE_ISSUED_BY_URL ? "Please provide a signature for the person issuing the housekeeping" : "";
    }

    // #End Custome Validation

    @action
    public fromRelatedDto(dto: HouseKeepingRelatedResponseDTO): void {}

    @action
    public reset = () => {
        this.id = HouseKeepingFormModel.DEFAULT_ID;
        this.projectId = HouseKeepingFormModel.DEFAULT_PROJECT_ID;
        this.nonConformanceTypeId = HouseKeepingFormModel.DEFAULT_NON_CONFORMANCE_TYPE_ID;

        // Concerning housekeeping
        this.supplierId = HouseKeepingFormModel.DEFAULT_SUPPLIER_ID;
        this.issuedByUserId = HouseKeepingFormModel.DEFAULT_ISSUED_BY_USER_ID;
        this.signatureIssuedByURL = HouseKeepingFormModel.DEFAULT_SIGNATURE_ISSUED_BY_URL;
        this.signatureIssuedByDate = HouseKeepingFormModel.DEFAULT_SIGNATURE_ISSUED_BY_DATE;

        // Concerning audit
        this.createdDate = HouseKeepingFormModel.DEFAULT_CREATED_DATE;
        this.createdByUserId = HouseKeepingFormModel.DEFAULT_CREATED_BY_USER_ID;
        this.lastUpdatedByUserId = HouseKeepingFormModel.DEFAULT_LAST_UPDATED_USER_ID;
        this.completedDate = HouseKeepingFormModel.DEFAULT_COMPLETED_DATE;
        this.completedByUserId = HouseKeepingFormModel.DEFAULT_COMPLETED_BY_USER_ID;
        this.isDeleted = HouseKeepingFormModel.DEFAULT_IS_DELETED;
        this.rowVersion = HouseKeepingFormModel.DEFAULT_ROW_VERSION;
    };

    public fromDto(dto: any): void {
        //throw new Error("Method not implemented.");
    }

    public toDto() {
        return {
            id: this.id,
            projectId: this.projectId,
            nonConformanceTypeId: this.nonConformanceTypeId,

            SupplierId: this.supplierId,
            IssuedByUserId: this.issuedByUserId,
            SignatureIssuedByURL: this.signatureIssuedByURL,
            SignatureIssuedByDate: this.signatureIssuedByDate,

            LastUpdatedByUserId: this.lastUpdatedByUserId,
            CreatedByUserId: this.createdByUserId,
            CompletedDate: this.completedDate,
            CompletedByUserId: this.completedByUserId,
            isDeleted: this.isDeleted,
            rowVersion: this.rowVersion,
        };
    }
}

export interface HouseKeepingRelatedResponseDTO {
    suppliers: SupplierDTO[];
    issuedByUsers: IssuedByUsersDTO[];
    housekeepingProjectDetails: HouseKeepingProjectDetailsDTO;
}

export interface HouseKeepingProjectDetailsDTO {
    projectReference: string;
    projectName: string;
}

export interface SupplierDTO {
    id: string;
    displayName: string;
}

export interface issuedToUsersDTO {
    id: string;
    displayName: string;
}

export interface IssuedByUsersDTO {
    id: string;
    displayName: string;
}

export interface IDisplayFile {
    id: string;
    displayName: string;
}

export interface HouseKeepingAndRelatedResponseDTO {
    housekeeping: HouseKeepingDTO;
}

export interface HouseKeepingDTO {
    id: string | null;
    projectId: string;
    nonConformanceTypeId: string;

    SupplierId: string | null;
    IssuedByUserId: string | null;
    SignatureIssuedByURL: string | null;
    SignatureIssuedByDate: string | null;

    LastUpdatedByUserId: string | null;
    CreatedByUserId: string | null;
    CompletedDate: string | null;
    CompletedByUserId: string | null;
    isDeleted: boolean;
    rowVersion: string | null;
}

export interface UpsertHouseKeepingAndRelatedRequestDTO {
    housekeeping: HouseKeepingDTO;
}
