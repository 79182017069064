import { Typography } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React from "react";

import { InformationBarContainer } from "./InformationBar.styles";

interface IProps {
    displayName: string;
}

export const InformationBarView: React.FC<IProps> = observer((props) => {
    return (
        <InformationBarContainer>
            <Typography variant={"h1"}>{props.displayName}</Typography>
        </InformationBarContainer>
    );
});
