import * as MobX from "mobx";
import { ModelBase } from "@shoothill/core";
import moment from "moment";
export class ProjectSiteAccessItemModel extends ModelBase<ProjectSiteAccessItemModel, ProjectSiteAccessItemModelDTO> {
    @MobX.observable
    public id: string = "";

    @MobX.observable
    public visitorFullName: string = "";

    @MobX.observable
    public photographUrl: string = "";

    @MobX.observable
    public priorityEmployerName: string = "";

    @MobX.observable
    public lastThreePhoneDigits: number = 0;

    @MobX.observable
    public visitorTypeName: string = "";

    @MobX.observable
    public healthProblems: boolean = false;

    @MobX.observable
    public visitDate: Date | null = null;

    @MobX.observable
    public isSignIn: boolean = false;

    @MobX.observable
    public hasSignedOut: boolean = false;

    @MobX.observable
    public ramsTitle: string = "";

    fromDto(model: ProjectSiteAccessItemModelDTO): void {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    toDto(model: ProjectSiteAccessItemModel): void {}
}

export type ProjectSiteAccessItemModelDTO = {
    id: string;
    visitorFullName: string;
    photographUrl: string;
    priorityEmployerName: string;
    lastThreePhoneDigits: number;
    visitorTypeName: string;
    healthProblems: boolean;
    visitDate: Date | null;
    isSignIn: boolean;
    hasSignedOut: boolean;
    ramsTitle: string | null;
};
