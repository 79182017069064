import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { DailyDiaryQuestionViewModel } from "./DailyDiaryQuestionViewModel";
import { SHBox } from "../../../../Components/Box";
import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import { DarwinPage, DarwinTablePageContent } from "../../../../Globals/Styles/AppStyling";
import { Centered } from "@shoothill/core";

interface Props {
    viewModel: DailyDiaryQuestionViewModel;
    disabled: boolean;
}
export const DailyDiaryQuestionView: React.FC<Props> = observer((props: Props) => {
    const { viewModel } = props;

    return (
        <SHBox>
            <SHBox grid dc={"1fr 100px 150px"} alignItems={"center"}>
                <Typography variant={"h4"} style={{ color: viewModel.model.completed ? "green" : "black" }}>
                    {viewModel.model.title}
                </Typography>
                <SHBox flexBox justifyContent={"center"}>
                    <FormControlLabel control={<Checkbox checked={viewModel.model.completed} onChange={viewModel.updateCompleted} />} label={""} disabled={props.disabled} />
                </SHBox>
                <SHBox showIf={viewModel.model.completed} grid dc={"1fr"} alignItems={"center"} justifyContent={"center"} style={{ gap: 0 }}>
                    <Typography variant={"h5"} style={{ color: "green" }}>
                        {viewModel.model.formattedCompletedDate}
                    </Typography>
                    <Typography variant={"h5"} style={{ color: "green" }}>
                        {viewModel.model.completedBy}
                    </Typography>
                </SHBox>
            </SHBox>
        </SHBox>
    );
});
