import { Box, FormControlLabel, FormHelperText, FormLabel, IconButton, TextField, Typography } from "@material-ui/core";
import { Cell, Grid, isEmptyOrWhitespace, useRouter } from "@shoothill/core";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Form, FormSection } from "Views/PurchaseOrder/Form/Views/Forms";
import { DashedDivider, SolidDivider } from "Views/PurchaseOrder/Form/Views/Dividers";
import { pxToRem, theme } from "Globals/Styles/AppTheme";
import { CustomRadio, CustomArrow } from "Views/Project/CustomComponents";
import { DefaultButton, PrimaryButton } from "Components/Buttons/Buttons";
import { DarwinSelect } from "Components/AutoComplete/DarwinSelect";
import { BackButtonHolder } from "Views/Project/Commercial/VariationTables/VariationGrid.Styles";
import { AppUrls } from "AppUrls";
import { formatDate, formatTime } from "Utils/Format";
import DilapidationHeader from "./DilapidationHeader";
import { Uploader } from "Components/Uploader/Uploader";
import { DilapidationPhotosFile } from "./DilapidationFormModel";
import SystemUpdateTwoToneIcon from "@material-ui/icons/SystemUpdateTwoTone";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { Signature } from "Components/Signature/Signature";
import { DilapidationFormViewModel } from "./DilapidationFormViewModel";
import { DilapidationReportItemsDTO } from "./DilapidationFormItemModel";
import { DilapidationFormItemViewModel } from "./DilapidationFormItemViewModel";
import { DilapidationFormItemPhotoListItemModel } from "./DilapidationFormItemPhotoListItemModel";
import { minHeight } from "styled-system";
interface IProps {
    className?: string;
}

const DilapidationFormViewBase: React.FC<IProps> = (props) => {
    const { match } = useRouter();
    const { history } = useRouter();
    const { projectid, dilapidationReportId } = match.params as any;
    const [viewModel] = useState(() => new DilapidationFormViewModel(dilapidationReportId, projectid));

    useEffect(() => {
        return () => {
            viewModel.reset();
        };
    }, []);

    const COLUMNS8 = "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr";
    const COLUMNS4 = "1fr 1fr 1fr 1fr";
    const COLUMNS2 = "1fr 1fr";
    const onSubmit = (event: any) => {
        event.preventDefault();
    };

    const renderItem = (dilapidationFormItemViewModel: DilapidationFormItemViewModel, index: number) => {
        return (
            <>
                <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8} style={{ marginBottom: "20px" }}>
                    <FormLabel>Inspected item/area {index + 1}:</FormLabel>
                    <Cell ts={8} ds={8}>
                        <TextAreaContainer>
                            <TextField
                                value={dilapidationFormItemViewModel.getValue("inspectedItem")}
                                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                                    dilapidationFormItemViewModel.setValue<string>("inspectedItem", event.target.value)
                                }
                                fullWidth
                                className="textarea"
                                label="Inspected item/area:"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                placeholder={`Please add Inspected item/area`}
                                onBlur={() => dilapidationFormItemViewModel.isFieldValid("inspectedItem")}
                                disabled={viewModel.isFormDisabled || viewModel.IsLoading}
                                error={dilapidationFormItemViewModel.getError("inspectedItem") !== ""}
                                helperText={dilapidationFormItemViewModel.getError("inspectedItem")}
                            />
                        </TextAreaContainer>
                    </Cell>
                </Grid>
                <Grid columnGap={30} rowGap={20} marginBottom={1} tc={COLUMNS2} dc={COLUMNS2}>
                    <Cell ts={1} ds={1}>
                        <FileUploader>
                            <Typography style={{ marginBottom: "8px", font: "normal normal 700 12px/16px Segoe UI", letterSpacing: "-0.18px", color: "#191919" }} variant="h5">
                                Photo(s):
                            </Typography>
                            <Uploader handleSelectFile={(e: any) => dilapidationFormItemViewModel.fileChange(e)} isFormDisabled={viewModel.isFormDisabled} label="" />
                            {dilapidationFormItemViewModel.model.dilapidationReportItemPhotos.filter((el: any) => !el.isDeleted).length > 0 && (
                                <Typography style={{ margin: "17px 0px 13px" }} variant="h5">
                                    Photo(s) added:
                                </Typography>
                            )}
                            <Box className="uploadedFile">
                                {dilapidationFormItemViewModel.model.dilapidationReportItemPhotos.map((item: DilapidationFormItemPhotoListItemModel, index: number) => {
                                    return (
                                        !item.isDeleted && (
                                            <Box className="uploadedFile-box">
                                                <Box className="uploadedFile-heading">
                                                    <IconButton onClick={() => dilapidationFormItemViewModel.DownloadFile(item.photoURL, item.fileName)}>
                                                        <SystemUpdateTwoToneIcon />
                                                    </IconButton>
                                                    <h3>{item.fileName}</h3>
                                                </Box>
                                                <IconButton onClick={() => item.handleDelete(true)} disabled={viewModel.isFormDisabled}>
                                                    <DeleteOutlinedIcon />
                                                </IconButton>
                                            </Box>
                                        )
                                    );
                                })}
                            </Box>
                        </FileUploader>
                    </Cell>
                </Grid>
                <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8} style={{ marginBottom: "20px" }}>
                    <Cell ts={8} ds={8}>
                        <TextAreaContainer>
                            <TextField
                                multiline
                                minRows={4}
                                value={dilapidationFormItemViewModel.getValue("comments")}
                                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                                    dilapidationFormItemViewModel.setValue<string>("comments", event.target.value)
                                }
                                fullWidth
                                className="textarea"
                                label="Comments"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                placeholder={`Please add additional comments`}
                                onBlur={() => dilapidationFormItemViewModel.isFieldValid("comments")}
                                disabled={viewModel.isFormDisabled || viewModel.IsLoading}
                                error={dilapidationFormItemViewModel.getError("comments") !== ""}
                                helperText={dilapidationFormItemViewModel.getError("comments")}
                            />
                        </TextAreaContainer>
                    </Cell>
                </Grid>
                <SolidDivider gutterBottom={true} borderThickness={1} borderOpacity={1} />
            </>
        );
    };

    const renderForm = () => {
        return (
            <FormBox>
                <Form className={props.className} onSubmit={onSubmit}>
                    <FormSection>
                        <DashedDivider gutterBottom={true} borderThickness={1} borderOpacity={1} />
                        <Grid columnGap={30} rowGap={20} tc={COLUMNS4} dc={COLUMNS4} style={{ marginBottom: "20px" }}>
                            <DarwinSelect
                                displayName="Type of report:"
                                execute={(data: { id: string; displayName: string }) => viewModel.handleDilapidationReportTypeId(data)}
                                fullWidth={true}
                                getOptionLabel={(option: any) => option.displayName}
                                options={viewModel.dilapidationTypes}
                                onBlur={() => viewModel.isFieldValid("dilapidationReportTypeId")}
                                placeholder="Please select"
                                value={viewModel.dilapidationReportType}
                                error={viewModel.getError("dilapidationReportTypeId") !== ""}
                                validationMessage={viewModel.getError("dilapidationReportTypeId")}
                                canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                            />
                        </Grid>
                        {viewModel.dilapidationReportItemViewModels.map((item, index) => {
                            return renderItem(item, index);
                        })}
                        {!viewModel.isFormDisabled && (
                            <>
                                <PrimaryButton
                                    execute={() => viewModel.addAnotherInspectedItemArea()}
                                    displayName={"Add another inspected item/area"}
                                    value={"Add"}
                                    canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                                />
                                <SolidDivider gutterBottom={false} borderThickness={1} borderOpacity={1} />
                            </>
                        )}
                    </FormSection>
                    <FormSection>
                        <SubtitleContainer>
                            <Typography variant="h2" style={{ font: "normal normal 600 18px/30px Open Sans", letterSpacing: "-0.36px", color: "#191919" }}>
                                Authorisations
                            </Typography>
                            <DataList>
                                <span>Completed by: {viewModel.model.completedByUserName ? viewModel.model.completedByUserName : ""}</span>
                            </DataList>
                            <DataList>
                                <span>Date / time added: {viewModel.model.authorisationSignatureDate ? formatDate(viewModel.model.authorisationSignatureDate) : ""}</span>
                            </DataList>
                            <Grid columnGap={30} rowGap={20} tc={COLUMNS4} dc={COLUMNS4}>
                                <SignatureContainer>
                                    <SubtitleContainer></SubtitleContainer>
                                    <Box>
                                        <Box>
                                            <Signature
                                                displayName={"Signature:"}
                                                execute={(data: any) => viewModel.setValue<string>("authorisationSignatureURL", data)}
                                                value={viewModel.model.authorisationSignatureURL}
                                                canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                                            />
                                            {viewModel.model.authorisationSignatureURL === null && viewModel.getError("authorisationSignatureURL") !== "" && (
                                                <FormHelperText style={{ color: "#f44336" }}>{viewModel.getError("authorisationSignatureURL")}</FormHelperText>
                                            )}
                                        </Box>
                                    </Box>
                                </SignatureContainer>
                            </Grid>
                        </SubtitleContainer>
                    </FormSection>
                    <Box style={{ margin: `0 ${pxToRem(30)} 0 ${pxToRem(30)}` }}>
                        <SolidDivider gutterBottom={true} borderThickness={1} borderOpacity={1} />
                    </Box>

                    {!viewModel.isFormDisabled && (
                        <FormSection>
                            <ButtonsContainer>
                                <PrimaryButton
                                    displayName="Submit"
                                    execute={() => viewModel.upsert()}
                                    fullWidth={true}
                                    canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                                />
                                <DefaultButton
                                    displayName="Cancel"
                                    execute={() => viewModel.handleCancel(projectid)}
                                    fullWidth={true}
                                    canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                                />
                            </ButtonsContainer>
                        </FormSection>
                    )}
                </Form>
            </FormBox>
        );
    };

    return useObserver(() => {
        return (
            <Form className={props.className} onSubmit={() => {}}>
                <BackButtonHolder
                    onClick={() => {
                        history.push(AppUrls.Client.Project.ConstructionQuality.replace(":projectid", projectid ? projectid : viewModel.model.projectId) + "#dilapidation");
                    }}
                    style={{ padding: "10px 0", marginTop: 0 }}
                >
                    <Box style={{ position: "relative", display: "flex", marginLeft: "10px", alignItems: "center" }}>
                        <Box style={{ position: "absolute", height: "15px", width: "8px" }}>
                            <CustomArrow size={"8px"} color={theme.palette.blue.main} type={"left"} />
                        </Box>
                        <Box style={{ marginLeft: "20px", font: "normal normal 600 18px/32px Open Sans", letterSpacing: "-0.36px", color: theme.palette.blue.main }}>
                            Back to dilapidation reports {`(${viewModel.dilapidationProjectDetails?.projectReference} - ${viewModel.dilapidationProjectDetails?.projectName})`}
                        </Box>
                    </Box>
                </BackButtonHolder>
                <DilapidationHeader
                    ProjectDetails={viewModel.dilapidationProjectDetails}
                    ProjectDate={viewModel.getTodayDateFormatted}
                    SafetyId={viewModel.model.id}
                    FormHeaderTitle={""}
                    CompletedBy={""}
                />

                {renderForm()}
            </Form>
        );
    });
};

export const DilapidationFormView = styled(DilapidationFormViewBase)`
    display: flex;
    flex: 1;
    flex-direction: column;

    form {
        position: relative;
    }
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;

    > button {
        max-width: 262px;
    }

    > button:nth-child(1) {
        margin-right: 30px;
    }
`;

const DataList = styled(Box)`
    font: normal normal normal 12px/16px Segoe UI;
    letter-spacing: -0.18px;
    color: #191919;
    display: flex;
    align-items: center;
    gap: 12px;
    span {
        font-weight: 700;
    }
`;

const FormBox = styled(Box)`
    .MuiFormLabel-root {
        font: normal normal 700 12px/16px Segoe UI;
        letter-spacing: -0.18px;
        color: #191919;
        margin: 0 0 7px;
    }
`;

interface SubtitleProps {
    margin?: string;
}

export const SubtitleContainer = styled.div<SubtitleProps>`
    margin-top: 12px;
    h2 {
        margin-bottom: ${(props) => (props.margin ? props.margin : "10px")};
        font: normal normal 600 12px/16px Segoe UI;
        letter-spacing: -0.18px;
        color: #191919;
        span {
            font-weight: bold;
            margin-right: 10px;
        }
    }
`;

const SignatureContainer = styled.div`
    display: flex;
    flex-direction: column;
    grid-column-start: 1;
    grid-column-end: 3;

    > div:nth-child(2) {
        display: flex;
        flex-direction: row;

        > div {
            width: 100%;

            canvas {
                border: 1px solid #d4d4d4;
            }
        }
    }
    .MuiFormControl-root {
        width: 100% !important;
    }
`;

const ProjectDetailsContainer = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    font: normal normal normal 12px/16px Segoe UI;
    letter-spacing: -0.18px;
    color: #191919;
    max-width: 100%;

    > div {
        font-size: 12px;
    }

    > div > span {
        font-weight: bold;
    }
`;

const FileUploader = styled(Box)`
    margin-top: 20px;
    .fileUpload {
        background: #6c93a626;
        border: 1px dashed #6c93a6;
        font: normal normal normal 12px/14px Open Sans;
        letter-spacing: 0px;
        color: #425b66;
        padding: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 90px;
        margin: 0 !important;
    }
`;

// I don't know why, but the textarea sometimes has a default height of 0px...
export const TextAreaContainer = styled.div`
    textarea.MuiInputBase-input {
        min-height: 60px;
    }
`;
