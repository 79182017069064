import { observable, runInAction, action, computed } from "mobx";
import { FieldType, ViewModelBase } from "@shoothill/core";
import { InductionUserModel } from "./InductionUserModel";

export class InductionUserViewModel extends ViewModelBase<InductionUserModel> {
    constructor(model = new InductionUserModel()) {
        super(model);
        this.setDecorators(InductionUserModel);
    }
    @action
    public handleSetInductionUser = (inductionUser: any) => {
        this.model.inductionUserId = inductionUser.id;
        this.model.inductionUser = inductionUser;
    };
    @computed
    public get inductionUser() {
        const retval = this.model.inductionUser;
        return retval;
    }

    public updateSignature = (signature: string) => {
        this.model.setValue("signatureURL", signature);
    };
    public async isFieldValid(fieldName: keyof FieldType<InductionUserModel>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        if (!isValid) {
            console.log("Error: ", fieldName, value, errorMessage);
        }

        return isValid;
    }
    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
