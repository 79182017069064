import * as MobX from "mobx";
import { ModelBase, observable } from "@shoothill/core";
import { PurchaseOrderItemModel, PurchaseOrderItemModelDTO, PurchaseOrderListItemModelDTO } from "./IEGrid.PurchaseOrderModel";
import { PackagesViewModel } from "../PackagesViewModel";
import { getTotalPrice, validateTwoDecimalPlaces } from "Utils/Utils";
import { IEItemMaterial, IEItemMaterialDTO } from "./IEGrid.IEItemMaterialModel";

export class IEGridItemModel extends ModelBase<IEGridItemModel, IEGridItemModelDTO> {
    public id: string = "";
    public createdDate: string = "";
    public sourceId: number = 0;
    public parentId: string = "";

    @MobX.observable
    public name: string = "";

    @MobX.observable
    public descriptionId: number = 0;

    @MobX.observable
    public income: number = 0;

    @MobX.observable
    public estimateCost: number = 0;

    @MobX.observable
    public estimatedMargin: number = 0;

    @MobX.observable
    public futureSpend: number = 0;

    @MobX.observable
    public targetCost: number = 0;

    @MobX.observable
    public category: number = 0;

    @MobX.observable
    public subCategory: number = 0;

    @MobX.observable
    public committedCost: number = 0;

    @MobX.observable
    public totalExpectedSpend: number = 0;

    @MobX.observable
    public variance: number = 0;

    @MobX.observable
    public changePeriod: number = 0;

    @MobX.observable
    public margin: number = 0;

    @MobX.observable
    public roValue: number = 0;

    @MobX.observable
    public isAudit: boolean = false;

    @MobX.observable
    public purchaseOrderItem: PurchaseOrderItemModel[] = [];

    @MobX.observable
    public ieItemMaterials = observable<IEItemMaterial>([]);

    /**
     * Group the po items by poid. So we end up with a list of purchase orders.
     */
    @MobX.computed
    public get groupedPOItems(): PurchaseOrderListItemModelDTO[] {
        let groupedList: PurchaseOrderListItemModelDTO[] = [];
        for (let i = 0; i < this.purchaseOrderItem.length; i++) {
            const po = this.purchaseOrderItem[i];
            const index = groupedList.findIndex((e) => e.id === po.id);
            if (index > -1) {
                groupedList[index].committedCost += getTotalPrice(po.quantity, po.unitPrice);
            } else {
                groupedList.push(po.toListItemDto());
            }
        }

        return groupedList;
    }

    public getName = (): string => {
        const packagesViewModel = PackagesViewModel.Instance;
        if (this.sourceId === -2 && this.descriptionOther !== null) {
            return this.descriptionOther;
        } else {
            return packagesViewModel.getDescriptionName(this.sourceId);
        }
    };

    fromDto(model: IEGridItemModelDTO): void {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else if (key === "purchaseOrderItem") {
                    let pos: PurchaseOrderItemModel[] = [];
                    model[key].forEach((element) => {
                        let newModel = new PurchaseOrderItemModel();
                        newModel.fromDto(element);
                        pos.push(newModel);
                    });
                    this.purchaseOrderItem = pos;
                } else if (key === "ieItemMaterialItems") {
                    const ieItemMaterials: IEItemMaterial[] = [];

                    model[key].forEach((dto: IEItemMaterialDTO) => {
                        const model = new IEItemMaterial();

                        model.fromDto(dto);

                        ieItemMaterials.push(model);
                    });

                    this.ieItemMaterials.replace(ieItemMaterials);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    public toDto(): IEGridItemModelDTO {
        const dto: IEGridItemModelDTO = {
            id: this.id,
            parentId: this.parentId,
            name: this.name,
            sourceId: this.sourceId,
            descriptionId: this.descriptionId,
            createdDate: this.createdDate,
            income: this.income,
            estimateCost: this.estimateCost,
            estimatedMargin: this.estimatedMargin,
            futureSpend: this.futureSpend,
            targetCost: this.targetCost,
            category: this.category,
            subCategory: this.subCategory,
            committedCost: this.committedCost,
            totalExpectedSpend: this.totalExpectedSpend,
            variance: this.variance,
            roValue: this.roValue,
            changePeriod: this.changePeriod,
            purchaseOrderItem: this.purchaseOrderItem.map((c) => {
                return c.toDto();
            }),
            descriptionOther: this.descriptionOther,
            isAudit: this.isAudit,
        };

        return dto;
    }

    @MobX.observable
    public descriptionOther: string = "";

    @MobX.computed
    public get validateEstimateCost(): string {
        // RULES
        if (isNaN(this.estimateCost)) {
            return "Please enter a target cost";
        } else if (!validateTwoDecimalPlaces(this.estimateCost)) {
            return "No more than two decimal places";
        }

        return "";
    }

    @MobX.computed
    public get validateIncome(): string {
        // RULES
        if (isNaN(this.income)) {
            return "Please enter an income";
        } else if (!validateTwoDecimalPlaces(this.income)) {
            return "No more than two decimal places";
        }

        return "";
    }

    @MobX.computed
    public get validateFutureSpend(): string {
        // RULES
        if (isNaN(this.futureSpend)) {
            return "Please enter a future spend";
        } else if (!validateTwoDecimalPlaces(this.futureSpend)) {
            return "No more than two decimal places";
        }

        return "";
    }
}

export type IEGridItemModelDTO = {
    id: string;
    parentId: string;
    name: string;
    sourceId: number;
    descriptionId: number;
    createdDate: string;
    income: number;
    estimateCost: number;
    estimatedMargin: number;
    futureSpend: number;
    targetCost: number;
    category: number;
    subCategory: number;
    committedCost: number;
    totalExpectedSpend: number;
    variance: number;
    roValue: number;
    changePeriod: number;
    purchaseOrderItem: PurchaseOrderItemModelDTO[];
    descriptionOther: string;
    isAudit: boolean;
};
