import { formatAddress } from "Utils/Format";
import { GenericId } from "Globals/Models/GenericId";
import { ApiResult, FieldType, isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import type { ValidationResponse } from "@shoothill/core";
import { SupplierDetailModel, SupplierDetailModelDTO } from "./SupplierDetailModel";
import { observable, action, runInAction, computed } from "mobx";
import type { IObservableArray } from "mobx";
import { AppUrls } from "AppUrls";
import type { SupplierWithRelatedDTO } from "./SupplierWithRelatedDTO";
import { DetailsHeaderModel } from "Globals/Views/DetailsPage/DetailsHeaderModel";
import { AddressModel, UpdateContactIsPrimaryRequest } from "Globals/Models/Domain";
import type { AddressModelDTO, ContactBaseDTO } from "Globals/Models/Domain";
import { SupplierContactDTO } from "./SupplierContactModel";
import { AddEditContactModel } from "Views/Contacts/AddEditContactModel";
import { DeleteContactRequest } from "Globals/Models/Domain/DeleteContactRequest";
import { PaymentTermsModel } from "Views/PurchaseOrder/Form/Supporting/PaymentTermsModel";
import { PaymentTypeModel } from "Views/PurchaseOrder/Form/Supporting/PaymentTypeModel";
import AddressViewModel from "Globals/ViewModels/AddressViewModel";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { PurchaseOrderRelatedResponseDTO } from "Views/PurchaseOrder/Form/PurchaseOrderModel";
import { PurchaseOrderTableModel } from "Views/Project/Commercial/OrdersAndRequisitions/PurchaseOrder.Model";
import { PurchaseOrderViewModel } from "Views/Project/Commercial/OrdersAndRequisitions/PurchaseOrder.ViewModel";
import { InvoicingListItemViewModel } from "Views/Invoices/InvoicingListItemViewModel";
import { InvoicingListItemsAndRelatedDTO } from "Views/Invoices/InvoicingListItemsAndRelatedDTO";
import { InvoicingListModel } from "Views/Invoices/InvoicingListModel";
import { InvoicingStatusTypeModel } from "Views/Invoices/InvoicingStatusTypeModel";

export class SupplierDetailViewModel extends ViewModelBase<SupplierDetailModel> {
    private static _instance: SupplierDetailViewModel;
    public server: ServerViewModel = new ServerViewModel();
    viewModel: any;
    projectTypes: any;
    static projectTypes: any;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }
    @observable public errorMessage: string = "";

    @observable public showAEModal: boolean = false;

    @observable
    public addressFormViewModel = new AddressViewModel(new AddressModel());

    @action
    public showAddEditModal(show: boolean) {
        this.showAEModal = show;
    }

    @computed
    public get getInvoices(): InvoicingListItemViewModel[] {
        return this.invoicingViewModels;
    }

    @observable
    private invoicingViewModels: InvoicingListItemViewModel[] = [];

    @action
    private populateInvoiceListItemViewModels = (dto: InvoicingListItemsAndRelatedDTO) => {
        const invoicingViewModels2: InvoicingListItemViewModel[] = [];
        for (const invoicingListItem of dto.invoiceItem) {
            let itemModel = new InvoicingListModel();
            itemModel.fromDto(invoicingListItem);
            const statusTypeModel = new InvoicingStatusTypeModel(dto.invoiceStatusType.find((pst) => pst.id === invoicingListItem.invoiceStatusId));
            invoicingViewModels2.push(new InvoicingListItemViewModel(itemModel, statusTypeModel));
        }
        this.invoicingViewModels = invoicingViewModels2;
    };

    public constructor() {
        super(new SupplierDetailModel(), false);
        this.setDecorators(SupplierDetailModel);
        //this.loadRelated();
    }

    @action
    public updateAddress(address: AddressModelDTO) {
        this.model.setAddress(0, address);
    }

    @action
    public cleanUp = () => {
        // TODO Any Cleanup Code here. e.g. if  a user or project or client etc, wipe it from the instance on page shutdown
        this.model.reset();
    };

    @computed
    get getHeader(): DetailsHeaderModel {
        const retVal: DetailsHeaderModel = new DetailsHeaderModel();
        retVal.setValue("title", this.model.reference + " - " + this.model.name);
        if (this.model.addresses.length > 0) {
            const addModel: AddressModel = new AddressModel();
            // https://stackoverflow.com/questions/61482664/mobx-methods-object-is-not-a-function
            addModel.fromObservable(this.model.addresses[0]);
            const address: AddressModelDTO = addModel.toAddressDto();
            retVal.setValue("address", formatAddress(address));
        } else {
            retVal.setValue("address", "");
        }
        retVal.setValue("status", this.model.status);
        retVal.setValue("statusClass", this.model.status);

        return retVal;
    }

    @computed get getContactList(): ContactBaseDTO[] {
        let retVal: ContactBaseDTO[] = [];

        const contacts: SupplierContactDTO[] = this.model.contacts.slice().filter((a) => a.isDeleted === false);
        retVal.push(...contacts);

        return retVal;
    }

    @computed get getPrimaryContact(): ContactBaseDTO {
        let retVal: ContactBaseDTO = {
            id: undefined,
            createdDate: undefined,
            isDeleted: false,
            rowVersion: undefined,
            originatorId: undefined,
            firstName: "",
            contactTypeId: null,
            lastName: "",
            email: "",
            isPrimary: false,
            position: "",
            phone: "",
        };

        const contacts: SupplierContactDTO[] = this.model.contacts.slice();

        if (contacts.length > 0) {
            const prime: SupplierContactDTO | undefined = contacts.find((a) => a.isPrimary === true && a.isDeleted === false);

            if (prime === undefined) {
                retVal = contacts[0];
            } else {
                retVal = prime;
            }
        }

        return retVal;
    }

    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (await this.isModelValid()) {
            //Do stuff here
            this.errorMessage = "Form is valid";
        } else {
            this.errorMessage = "Form is not valid";
        }
    };

    public async isFieldValid(fieldName: keyof FieldType<SupplierDetailModel>): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    @action
    public async loadSupplierAsync(supplierId: string): Promise<ApiResult<SupplierWithRelatedDTO>> {
        this.setIsLoading(true);
        const request: GenericId = {
            id: supplierId,
        };

        let apiResult = await this.Post<SupplierWithRelatedDTO>(AppUrls.Server.Supplier.GetDetails, request);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                this.setSupplier(apiResult.payload);
            });
        }

        this.setIsLoading(false);
        return apiResult;
    }

    @observable
    private purchaseOrdersArr: IObservableArray<PurchaseOrderViewModel> = observable<PurchaseOrderViewModel>([]);

    @computed
    public get getPurchaseOrdersList(): PurchaseOrderTableModel[] {
        return this.purchaseOrdersArr.map((item: PurchaseOrderViewModel) => {
            console.log(item.model);
            return item.model.toTableModel();
        });
    }

    public setSupplier(payload: SupplierWithRelatedDTO) {
        this.model.fromDto(payload.supplier);

        const po: PurchaseOrderViewModel[] = [];

        for (const item of payload.purchaseOrders) {
            let vm = new PurchaseOrderViewModel();
            vm.model.fromDto(item);
            po.push(vm);
        }

        this.purchaseOrdersArr.replace(po);

        this.populateInvoiceListItemViewModels({
            invoiceItem: payload.invoiceItem,
            invoiceStatusType: payload.invoiceStatusType,
            projects: payload.projects,
            suppliers: payload.suppliers,
            totalCount: 0,
        });
    }

    @action
    public updateSupplier(editedSupplier: SupplierWithRelatedDTO) {
        this.setSupplier(editedSupplier);
    }

    public async deleteContact(id: string): Promise<ApiResult<number>> {
        const supplierId: string = this.model.id!;
        const request: DeleteContactRequest = {
            id: id,
            sourceId: supplierId,
        };

        let apiResult = await this.Post<number>(AppUrls.Server.Supplier.DeleteContact, request);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                if (apiResult.payload === 0) {
                    this.model.removeContact(id);
                }
            });
        }
        return apiResult;
    }

    public async updatContactIsPrimary(id: string, isPrimary: boolean): Promise<ApiResult<SupplierContactDTO[]>> {
        const supplierId: string = this.model.id!;
        const request: UpdateContactIsPrimaryRequest = {
            id: id,
            sourceId: supplierId,
            isPrimary: isPrimary,
        };

        let apiResult = await this.Post<SupplierContactDTO[]>(AppUrls.Server.Supplier.UpdateSupplierContactIsPrimary, request);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                this.model.replaceContacts(apiResult.payload);
            });
        }
        return apiResult;
    }

    public async addContact(addEdit: AddEditContactModel): Promise<ApiResult<SupplierContactDTO>> {
        if (await this.isModelValid()) {
            const request: SupplierContactDTO = addEdit.toSupplierContactDto(this.model.id!);

            /*         const request = {
            supplierId: this.model.id,
            createdDate: addEdit.createdDate,
            firstName: addEdit.firstName,
            lastName: addEdit.lastName,
            position: addEdit.position,
            email: addEdit.email,
            phone: addEdit.phone,
            isDeleted: false,
            originatorId: addEdit.originatorId,
            rowVersion: addEdit.rowVersion,
            isPrimary: addEdit.isPrimary,
        }; */

            let apiResult = await this.Post<SupplierContactDTO>(AppUrls.Server.Supplier.UpsertContact, request);

            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.model.addContact(apiResult.payload);
                });
            }
            return apiResult;
        } else {
            this.errorMessage = "Form is not valid";
            return Promise.reject();
        }
    }

    public async updateContact(addEdit: AddEditContactModel): Promise<ApiResult<SupplierContactDTO>> {
        if (await this.isModelValid()) {
            const request: SupplierContactDTO = addEdit.toSupplierContactDto(this.model.id!);

            let apiResult = await this.Post<SupplierContactDTO>(AppUrls.Server.Supplier.UpsertContact, request);

            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.model.replaceContact(apiResult.payload);
                });
            }
            return apiResult;
        } else {
            this.errorMessage = "Form is not valid";
            return Promise.reject();
        }
    }

    @action
    public async loadContactAsync(contactId: string): Promise<ApiResult<SupplierContactDTO>> {
        const supplierId: string = this.model.id!;
        const request: DeleteContactRequest = {
            id: contactId,
            sourceId: supplierId,
        };

        let apiResult = await this.Post<SupplierContactDTO>(AppUrls.Server.Supplier.GetSupplierContact, request);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                // TODO CMR
            });
        }
        return apiResult;
    }

    @computed
    private get validatePaymentTerms(): ValidationResponse {
        const errorMessage = this.model.validatePaymentTermsId;
        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validatePaymentType(): ValidationResponse {
        const errorMessage = this.model.validatePaymentTypeId;
        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    public async isFieldValids(fieldName: keyof FieldType<SupplierDetailModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);
        if (this.server.IsSubmitted) {
            switch (fieldName) {
                case "paymentTerms": {
                    const result = this.validatePaymentTerms;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "paymentGroup": {
                    const result = this.validatePaymentType;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
            }
        } else {
            errorMessage = "";
            isValid = true;
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    // public loadRelated = (): Promise<void> => {
    //     return this.server.query<PurchaseOrderRelatedResponseDTO>(
    //         () => this.Get(`${AppUrls.Server.PurchaseOrder.Load.Related}`),
    //         (result) => {
    //             runInAction(() => {
    //                 this.paymentTerms.replace(PaymentTermsModel.fromDtos(result.paymentTerms));
    //                 this.paymentTypes.replace(PaymentTypeModel.fromDtos(result.paymentTypes));
    //             });
    //         },
    //     );
    // };

    public updateSupplierStatus = async (status: any) => {
        const request = { id: this.model.id, status: status, rowVersion: this.model.rowVersion };
        await this.Post<SupplierDetailModelDTO>(AppUrls.Server.Supplier.UpdateSupplierStatus, request).then((result: ApiResult<SupplierDetailModelDTO>) => {
            if (result.wasSuccessful) {
                this.history.push(AppUrls.Client.Supplier.List);
            } else {
                this.errorMessage = "Failed to update the supplier status.";
            }
        });
    };
}
