import { FormControlLabel, Checkbox, Typography, IconButton } from "@material-ui/core";
import SystemUpdateTwoToneIcon from "@material-ui/icons/SystemUpdateTwoTone";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { Cell, Grid } from "@shoothill/core";
import { observer } from "mobx-react-lite";

import { DarwinSelect } from "Components/AutoComplete/DarwinSelect";
import { DarwinInputWrapper } from "Components/Form/DarwinInputWrapper";
import { SolidDivider } from "../Dividers";
import { PurchaseOrderModel } from "../../PurchaseOrderModel";
import { PurchaseOrderViewModel } from "../../PurchaseOrderViewModel";
import { SupplierDetailView } from "../../Supporting/SupplierDetailsView";

interface IProps {
    viewModel: PurchaseOrderViewModel;
}

export const SupplierSectionView: React.FC<IProps> = observer((props) => {
    // #region Code Behind

    const COLUMNS8 = "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr";
    const viewModel = props.viewModel;

    // #endregion Code Behind

    return (
        <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
            {/* Group of 8 columns */}
            <Cell ts={4} ds={4}>
                <DarwinSelect
                    displayName="Supplier:*"
                    execute={viewModel.setSupplierAsync}
                    fullWidth={true}
                    getOptionLabel={(option: any) => option.displayName}
                    onBlur={() => viewModel.isFieldValid("supplierId")}
                    options={viewModel.suppliers}
                    placeholder="Please select"
                    validationMessage={viewModel.getError("supplierId")}
                    value={viewModel.supplier}
                    canExecute={viewModel.canExecuteAmendingField && !viewModel.isPrePaymentChild}
                />
            </Cell>

            <Cell display={{ xs: "none", md: "block" }} ts={4} ds={4} />

            {viewModel.canDisplaySupplierDetails && (
                <>
                    {/* Group of 8 columns */}
                    <Cell ts={4} ds={4}>
                        <SupplierDetailView supplierDetails={viewModel.supplierDetailsForSupplier!} />
                    </Cell>

                    <Cell display={{ xs: "none", md: "block" }} ts={4} ds={4} />

                    {/* Group of 8 columns */}
                    <Cell ts={4} ds={4}>
                        <DarwinInputWrapper<PurchaseOrderModel>
                            type="text"
                            label="Supplier emails:"
                            placeholder="Supplier emails"
                            validateOnBlur={true}
                            viewModel={viewModel}
                            fieldName="supplierContactEmails"
                            shrink={true}
                            editMode={!viewModel.isFormDisabled && !viewModel.isPrePaymentChild}
                        />
                    </Cell>

                    <Cell display={{ xs: "none", md: "block" }} ts={4} ds={4} />

                    {/* Group of 8 columns */}
                    <Cell ts={4} ds={4}>
                        <DarwinInputWrapper<PurchaseOrderModel>
                            type="text"
                            label="Additional emails to cc:"
                            placeholder="Additional emails to cc"
                            validateOnBlur={true}
                            viewModel={viewModel}
                            fieldName="additionalContactEmailAddress"
                            shrink={true}
                            editMode={!viewModel.isFormDisabled && !viewModel.isPrePaymentChild}
                        />
                    </Cell>

                    <Cell display={{ xs: "none", md: "block" }} ts={4} ds={4} />
                </>
            )}

            {/* Group of 8 columns */}
            <Cell ts={2} ds={2}>
                <DarwinSelect
                    displayName="Select terms and conditions:*"
                    execute={viewModel.setTermsAndConditionsSubContractor}
                    fullWidth={true}
                    getOptionLabel={(option: any) => option.displayName}
                    onBlur={() => viewModel.isFieldValid("termsAndConditionsId")}
                    options={viewModel.termsAndConditions}
                    placeholder="Please select"
                    validationMessage={viewModel.getError("termsAndConditionsId")}
                    value={viewModel.termsAndConditionSubContractor}
                    canExecute={viewModel.canExecuteAmendingField && !viewModel.isPrePaymentChild}
                />
            </Cell>

            <Cell ts={1} ds={1} style={{ display: viewModel.isFormDisabled ? "flex" : "block", alignItems: viewModel.isFormDisabled ? "center" : "initial" }}>
                <DarwinInputWrapper<PurchaseOrderModel>
                    type="text"
                    label="Payment terms(days):"
                    validateOnBlur={true}
                    viewModel={viewModel}
                    fieldName="paymentTermsInDays"
                    shrink={true}
                    maxLength={3}
                    editMode={false}
                />
            </Cell>

            <Cell ts={1} ds={1}>
                <DarwinSelect
                    displayName="Payment terms:*"
                    execute={viewModel.setPaymentTerms}
                    fullWidth={true}
                    getOptionLabel={(option: any) => option.displayName}
                    onBlur={() => viewModel.isFieldValid("paymentTermsId")}
                    options={viewModel.paymentTerms}
                    placeholder="Please select"
                    validationMessage={viewModel.getError("paymentTermsId")}
                    value={viewModel.paymentTerm}
                    canExecute={false}
                />
            </Cell>

            <Cell ts={2} ds={2}>
                <DarwinSelect
                    displayName="Payment type:*"
                    execute={viewModel.setPaymentType}
                    fullWidth={true}
                    getOptionLabel={(option: any) => option.displayName}
                    onBlur={() => viewModel.isFieldValid("paymentTypeId")}
                    options={viewModel.paymentTypes}
                    placeholder="Please select"
                    validationMessage={viewModel.getError("paymentTypeId")}
                    value={viewModel.paymentType}
                    canExecute={false}
                />
            </Cell>

            {viewModel.isPaymentTypeNonBacs && (
                <Cell ts={2} ds={2} style={{ display: "flex", alignItems: "flex-end", marginBottom: "-10px" }}>
                    <FormControlLabel
                        control={<Checkbox checked={viewModel.model.sendEmailToSupplier} onChange={viewModel.setSendEmailToSupplier} />}
                        label={"Send email to supplier?"}
                        name={`sendEmailToSupplier-checkbox`}
                        disabled={!viewModel.canExecuteAmendingField || viewModel.isPrePaymentChild}
                    />
                </Cell>
            )}

            {!viewModel.isPaymentTypeNonBacs && <Cell display={{ xs: "none", md: "block" }} ts={2} ds={2} />}

            {/* Group of 8 columns */}
            {viewModel.model.isSubcontractorAgreement === true && (
                <Cell ts={8} ds={8}>
                    <Typography>Attachment</Typography>
                    {!viewModel.hasTermsAndconditionsFile && (
                        <div className="fileUpload">
                            <input
                                type="file"
                                className="upload"
                                onChange={(event) => viewModel.termsconditionsFileChange(event)}
                                disabled={viewModel.isFormDisabled || viewModel.isPrePaymentChild}
                            />
                            <span>Click to add or drag your file to attach</span>
                        </div>
                    )}

                    {viewModel.hasTermsAndconditionsFile && (
                        <div className="uploadedFile">
                            <div className="uploadedFile-box">
                                <div className="uploadedFile-heading">
                                    <IconButton
                                        onClick={() =>
                                            viewModel.DownloadFile(
                                                viewModel.model.termsAndConditionsFileUrl != undefined ? viewModel.model.termsAndConditionsFileUrl : "",
                                                viewModel.model.termsAndConditionsFileName != undefined ? viewModel.model.termsAndConditionsFileName : "",
                                            )
                                        }
                                    >
                                        <SystemUpdateTwoToneIcon />
                                    </IconButton>
                                    <h3>{viewModel.model.termsAndConditionsFileName}</h3>
                                </div>
                                <IconButton onClick={() => viewModel.removeTermsconditionsFile()} disabled={viewModel.isFormDisabled || viewModel.isPrePaymentChild}>
                                    <DeleteOutlinedIcon />
                                </IconButton>
                            </div>
                        </div>
                    )}
                </Cell>
            )}

            {viewModel.model.isSubcontractorAgreement && (
                <>
                    <Cell ts={2} ds={2}>
                        <DarwinSelect
                            displayName="Select terms and conditions:*"
                            execute={viewModel.setTermsAndConditions}
                            fullWidth={true}
                            getOptionLabel={(option: any) => option.displayName}
                            onBlur={() => viewModel.isFieldValid("termsAndConditionsId")}
                            options={viewModel.termsAndConditionsWithoutSubcontractorOptions}
                            placeholder="Please select"
                            validationMessage={viewModel.getError("termsAndConditionsId")}
                            value={viewModel.termsAndCondition}
                            canExecute={viewModel.canExecuteAmendingField && !viewModel.isPrePaymentChild}
                        />
                    </Cell>
                    <Cell display={{ xs: "none", md: "block" }} ts={6} ds={6} />
                </>
            )}

            {/* Group of 8 columns */}
            <Cell ts={8} ds={8}>
                <SolidDivider gutterBottom />
            </Cell>
        </Grid>
    );
});
