import React from "react";
import { Box, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { useObserver } from "@shoothill/core";
import { StoresInstance } from "Globals/Stores";

const Alert = (props: any) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};

export const CommonInjects: React.FC = () => {
    return useObserver(() => (
        <Box id={"main-snackbar"}>
            <Snackbar
                open={StoresInstance.Domain.openSnackBar}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                autoHideDuration={6000}
                onClose={() => StoresInstance.Domain.CloseSnackBar()}
            >
                <Alert onClose={() => StoresInstance.Domain.CloseSnackBar()} severity={StoresInstance.Domain.snackBarSeverity}>
                    {StoresInstance.Domain.snackBarContent}
                </Alert>
            </Snackbar>
        </Box>
    ));
};
