import * as React from "react";

import { CancelButton } from "Globals/Styles/Control/Buttons";
import { BaseModal } from "Components/Modal/BaseModal";
import { InvoiceMatchProjectAllocationWarningModalDialogPaper } from "./InvoiceMatchProjectAllocationWarningModal.styles";
import { formatCurrencyFromPounds } from "Utils/Format";

interface IProps {
    open?: boolean;
    onClose(): void;
    title: string;
    text: string;
    isLoading?: boolean;
    projects: {
        projectId: string;
        name: string;
        exceededByAmount: number;
    }[];
}

export const InvoiceMatchProjectAllocationWarningModal: React.FC<IProps> = ({ open, onClose, title, text, isLoading = false, projects }) => (
    <BaseModal
        open={open}
        onClose={onClose}
        title={title}
        PaperComponent={InvoiceMatchProjectAllocationWarningModalDialogPaper}
        actions={
            <>
                <CancelButton onClick={onClose}>Ok</CancelButton>
            </>
        }
    >
        <p className="client-modal-description">{text}</p>
        <ul>
            {projects.map((p) => {
                return (
                    <li>
                        {p.name} is over allocated by {formatCurrencyFromPounds(Number(p.exceededByAmount.toFixed(2)))}
                    </li>
                );
            })}
        </ul>
    </BaseModal>
);
