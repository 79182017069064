import React, { useContext, useEffect, useState } from "react";
import { Stores, StoresContext } from "Globals/Stores";
import { PrivateRoute } from "Globals/Views/PrivateRoute";
import * as RouterUtil from "Utils/Routing";
import { StoresInstance } from "Globals/Stores";
import { AppUrls } from "../../../AppUrls";
import { DarwinLayout, DefaultLayout } from "Globals/Views/Layouts";
import { SiteTabletMenuView } from "./SiteTabletMenuView";
import { SiteTabletPermitView } from "./SiteTabletPermitView";
import { SiteTabletInspectionView } from "./SiteTabletInspectionView";
import { ProjectListViewModel } from "../ProjectListViewModel";
import { ProjectListModelWithAddress } from "../ProjectListModelWithAddress";
import { SHBox } from "../../../Components/Box";
import { SiteTabletNearMissView } from "./SiteTabletNearMissView";
import { SiteTabletScaffoldView } from "./SiteTabletScaffoldView";

export const SiteTabletProjectRoutes: React.FC = () => {
    const [viewModel] = useState(() => new ProjectListViewModel());
    const [projectDetails, setProjectDetails] = useState<ProjectListModelWithAddress>({} as ProjectListModelWithAddress);
    const store = useContext<Stores>(StoresContext);
    function getRootLayout() {
        return DarwinLayout;
    }

    useEffect(() => {
        //PackagesViewModel.Instance.apiGetRelated();
        viewModel.apiGetSiteTabletProjectDetailsByUserId().then((response) => {
            if (response) {
                setProjectDetails(response);
            }
        });
        return () => {};
    }, []);

    return (
        <SHBox id={"siteTableRootContainer"}>
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.SiteTablet.Menu}
                component={() => <SiteTabletMenuView viewModel={viewModel} projectDetails={projectDetails} />}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />

            <PrivateRoute
                exact
                path={AppUrls.Client.Project.SiteTablet.Permit}
                component={() => <SiteTabletPermitView viewModel={viewModel} projectDetails={projectDetails} />}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />

            <PrivateRoute
                exact
                path={AppUrls.Client.Project.SiteTablet.Inspection}
                component={() => <SiteTabletInspectionView viewModel={viewModel} projectDetails={projectDetails} />}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />

            <PrivateRoute
                exact
                path={AppUrls.Client.Project.SiteTablet.NearMiss}
                component={() => <SiteTabletNearMissView viewModel={viewModel} projectDetails={projectDetails} />}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />

            <PrivateRoute
                exact
                path={AppUrls.Client.Project.SiteTablet.Scaffold}
                component={() => <SiteTabletScaffoldView viewModel={viewModel} projectDetails={projectDetails} />}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
        </SHBox>
    );
};
