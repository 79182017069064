import { ModelBase } from "@shoothill/core";
import { observable, runInAction } from "mobx";
import { FireExitModel } from "./FireExitModel";
import { FireExtinguishersModel } from "./FireExtinguishersModel";
import { FirePointModel } from "./FirePointModel";
import { SpillKitModel } from "./SpillKitModel";
import { FirstAidKitModel } from "./FirstAidKitModel";
import { ProjectUserLookUpModel } from "./ProjectUserLookup";

export class EndOfWeekModel extends ModelBase<EndOfWeekModel> {
    public id: string | null = null;
    @observable
    public auditUser: ProjectUserLookUpModel = new ProjectUserLookUpModel();
    @observable
    public auditUserId: string | null = null;
    @observable
    public createdDate: Date | null = null;
    public projectId: string | null = null;
    public signatureBase64: string = "";
    public fireExitModels = observable<FireExitModel>([]);
    public fireExtinguisherModels = observable<FireExtinguishersModel>([]);
    public firePointModels = observable<FirePointModel>([]);
    public spillKitModels = observable<SpillKitModel>([]);
    public firstAidKitModels = observable<FirstAidKitModel>([]);

    constructor() {
        super();
    }

    fromDto(model: any): void {
        this.id = model.id;
        this.auditUserId = model.auditUserId;
        this.projectId = model.projectId;
        this.signatureBase64 = model.signatureBase64;
        this.createdDate = new Date(model.createdDate);
    }
    toDto() {
        return {
            id: this.id,
            auditUserId: this.auditUserId,
            projectId: this.projectId,
            signatureBase64: this.signatureBase64,
            fireExits: this.fireExitModels.map((x) => x.toDto()),
            fireExtinguishers: this.fireExtinguisherModels.map((x) => x.toDto()),
            firePoints: this.firePointModels.map((x) => x.toDto()),
            spillKits: this.spillKitModels.map((x) => x.toDto()),
            firstAidKits: this.firstAidKitModels.map((x) => x.toDto()),
        };
    }
}
