import { Box, Grid } from "@material-ui/core";
import styled from "styled-components";
import { ModalGridView } from "Views/Project/Commercial/IETables/IEGrid.Styles";

export const VariationModalGridView: any = styled(ModalGridView)`
    li {
        grid-template-columns: repeat(auto-fit, 200px);
        padding: 0px 5px;

        > div {
            display: flex;
            align-items: center;
            justify-content: center;

            /* .MuiAutocomplete-root {
                width: 100% !important;
                margin: 0px !important;
            } */
        }
    }
`;

const GridViewBase: any = styled.ul`
    list-style: none;
    margin: 0px 10px 0px;
    li {
        width: 100%;
        border-bottom: solid 1px #dddddd;
        display: grid;
        grid-template-columns: minmax(280px, 1fr) repeat(auto-fit, minmax(100px, 1fr));
        grid-template-rows: 35px;
        gap: 0px 0px;
        grid-auto-flow: column;
        height: 50px;

        > div {
            height: 50px;
            padding: 10px 20px;
            font-size: 12px;
            font-weight: bold;
            border-right: solid 1px #dddddd;
        }

        > div:first-of-type {
            //border-left: solid 1px #dddddd;
        }

        > div:last-of-type {
            border-right: none;
        }

        > .noPadding {
            padding: 0px;
        }
    }

    .heading,
    .footing,
    .ie,
    .category,
    .subcategory,
    .item {
        & > * {
            display: flex;
            align-items: center;
        }
        .right {
            /* text-align: right !important; */
            justify-content: flex-end;
        }

        .centre {
            justify-content: center;
        }

        .credit {
            background-color: #caead4;
        }

        .debit {
            background-color: #ecb4b4;
        }

        .notdebitorcredit {
            background-color: #efe350bf;
        }

        .italic {
            font-style: italic;
        }
    }

    .subcategory {
        > div {
            > div {
                display: flex;
                align-items: center;
            }
        }
    }

    .subcategory div.MuiInput-root,
    .subcategory div.MuiFormControl-root,
    .subcategory div.MuiAutocomplete-root {
        padding-left: 0px;
    }

    .subcategory div.MuiAutocomplete-root {
        padding-right: 20px;
    }

    .item,
    .title {
        .MuiAutocomplete-root,
        .MuiBox-root {
            padding: 0px;
            font-size: 12px;
            font-weight: bold;
        }
    }

    .title {
        grid-template-rows: none;
        height: 80px;

        > div:nth-child(1) {
            font-size: 16px;
        }

        > div {
            padding: 30px 20px 10px 20px;
            height: 100%;
        }
    }

    .heading {
        background-color: #ced4da;
        height: 60px;
        line-height: 1.25;
        padding: 5px 0;
        > div {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
        }
    }

    .inputIERow {
        border-bottom: dashed 1px #dddddd;
    }
`;

export const SummaryGridView: any = styled(GridViewBase)``;

export const VariationModalEditBox: any = styled(Box)`
    .row {
        display: flex;
        width: 100%;
        flex-direction: row;

        label,
        .MuiAutocomplete-root {
            //width: 50%;
            display: inline-flex;
            padding: 0;
            margin: 0;
            //max-width: 389px;
        }

        .MuiAutocomplete-root {
            //margin-bottom: 16px;
            //height: 30px;
        }

        /* label:first-of-type,
        .MuiAutocomplete-root:first-of-type {
            margin-right: 30px;
        } */

        label {
            font-size: 12px;
            letter-spacing: -0.18px;
            color: #191919;
        }
    }

    .fullwidth {
        label,
        .MuiAutocomplete-root {
            width: 100%;
            max-width: 808px;
        }

        /* label:first-of-type,
        .MuiAutocomplete-root:first-of-type {
            margin-right: 0px;
        } */

        .MuiAutocomplete-root {
            margin-bottom: 0px;
        }
    }
`;

export const VariationGridSummary: any = styled(Grid)`
    ul {
        list-style: none;
        margin: 0px 10px 30px;
        border-bottom: 4px solid #425b66;

        > div {
            > li {
                width: 100%;
                border-bottom: solid 1px #dddddd;
                display: grid;
                grid-template-columns: minmax(280px, 1fr) repeat(auto-fit, minmax(100px, 1fr));
                grid-template-rows: 35px;
                gap: 0px 0px;
                grid-auto-flow: column;
                height: 50px;

                > div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 50px;
                    padding: 10px 20px;
                    font-size: 12px;
                    font-weight: bold;
                }
            }

            > li {
                > div:nth-child(1) {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
            }
        }

        > div:nth-child(1) {
            > li {
                border-bottom: 4px solid #425b66;
            }
        }

        > div:nth-child(2) {
            > li {
                > div:nth-child(1) {
                    font-size: 16px;
                }
            }
        }

        > div:nth-child(3),
        > div:nth-child(4) {
            /* > li {
                > div:nth-child(3),
                > div:nth-child(4) {
                    background-color: #425b66;
                }
            } */
        }

        > div:nth-child(5) {
            > li {
                border-top: 4px solid #425b66;

                > div:nth-child(1) {
                    font-size: 16px;
                }
            }
        }
    }
`;
