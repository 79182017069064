// Libs
import { ApiResult, FieldType, generateID, isNullOrUndefined } from "@shoothill/core";
import { action, computed, observable, runInAction } from "mobx";

// App
import { GeneralModel } from "./GeneralModel";
import type { GeneralModelDTO } from "./GeneralModel";
import { AddressModel, NoteModelDTO, NoteModel, ProjectDocumentDTO } from "Globals/Models/Domain";
import type { AddressModelDTO } from "Globals/Models/Domain";
import AddressViewModel from "Globals/ViewModels/AddressViewModel";
import NoteViewModel from "Globals/ViewModels/NoteViewModel";
import { ClientNameAndIdDTO } from "Globals/Models";
import { GenericId, IsReferenceInUseRequest } from "Globals/Models";
import { AppUrls } from "AppUrls";
import { formatAddressAlternate } from "Utils/Format";
import { DetailsHeaderModel } from "Globals/Views/DetailsPage/DetailsHeaderModel";
import { ClientSelectViewModel } from "Components/AutoComplete/ClientSelectViewModel";
import { ContractTypeViewModel } from "Components/AutoComplete/ContractTypeSelectViewModel";
import { GeneralRelatedModelDTO } from "./GeneralRelatedModel";
import { ContractTypeModelDTO } from "Globals/Models/ContractTypeModel";
import { DarwinViewModelBase } from "Globals/ViewModels";
import { ProjectStatusTypeDTO, ProjectStatusTypeModel } from "../ProjectStatusTypeModel";
import { ProjectTypeDTO, ProjectTypeModel } from "../ProjectTypeModel";

export class ProjectGeneralViewModel extends DarwinViewModelBase<GeneralModel> {
    // #region Constructors and Disposers

    constructor() {
        super(new GeneralModel(), false);
        this.setDecorators(GeneralModel);
    }

    // #endregion Constructors and Disposers

    // #region Constants

    NEWITEM = "DELETEME_";
    DEFAULTVIEW = "master";
    EDITVIEW = "edit";
    MODALSTATE = false;
    VARIATIONMODALSTATE = false;

    // #endregion Constants

    private static _instance: ProjectGeneralViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable public errorMessage: string = "";

    @observable
    public hasLoaded = true;

    @action
    public setHasLoaded(val: boolean) {
        this.hasLoaded = val;
    }

    // #region Addresses

    @observable
    private addressViewModels: AddressViewModel[] = [];

    @computed
    public get getAddressViewModels(): AddressViewModel[] {
        return this.addressViewModels; //.filter((address) => address.model.isDeleted === false);
    }

    @action
    private populateProjectAddresses() {
        // clear any addresses currently in model
        this.model.addresses = [];
        // (Re)build addresses model from addressViewModels[]
        // Exclude deleted addresses that don't exist in the database.
        this.addressViewModels.forEach((address) => {
            if (address.model.isDeleted) {
                if (address.model.id !== "") {
                    let _address = new AddressModel();
                    _address.fromDto(address.model);
                    this.model.addresses.push(_address);
                }
            } else {
                let _address = new AddressModel();
                _address.fromDto(address.model);
                this.model.addresses.push(_address);
            }
        });
    }

    @action
    public resetAddressViewModel() {
        this.addressViewModels = [];
    }

    @action
    private createAddressViewModels(): void {
        this.resetAddressViewModel();
        for (const address of this.model.addresses) {
            this.addressViewModels.push(new AddressViewModel(address));
        }
    }

    @action
    public clearAddressViewModelArray() {
        this.addressViewModels = [];
    }

    @observable
    private addressModelArr: AddressModel[] = [];

    @computed
    public get getAddressModelArr(): AddressModel[] {
        // clear array
        this.addressModelArr = [];

        // Rebuild array from AddressViewModel[]
        this.getAddressViewModels.forEach((address) => {
            this.addressModelArr.push(address.model);
        });

        // Moved deletion logic to the component so that I can utilize the index when deleting.
        return this.addressModelArr; //.filter((address) => address.isDeleted === false);
    }

    @action
    public getModelAddress() {
        return new AddressModel();
    }

    @action
    public getAddressById(val: string) {
        const address = this.addressViewModels.find((address) => address.model.id === val);
        if (address !== undefined) {
            return address.model;
        } else {
            return new AddressModel();
        }
    }

    @action
    public getAddressByIndex(index: number) {
        const address = this.addressViewModels[index];
        if (address !== undefined) {
            return address.model;
        } else {
            return new AddressModel();
        }
    }

    @action
    public addAddress(address: AddressModelDTO) {
        if (isNullOrUndefined(address.id) === true) {
            address.id = this.NEWITEM + generateID();
        }

        if (this.addressViewModels.length < 0) {
            address.isPrimary = true;
            console.log("first address so set to Primary");
        } else {
            address.isPrimary = false;
        }

        let _address = new AddressModel();
        _address.fromDto(address);
        this.addressViewModels.push(new AddressViewModel(_address));
    }

    @action
    public setMainAddress(val: AddressModel, indexToSet: number) {
        // Clear/reset all address primary
        this.addressViewModels.forEach((address, index) => {
            this.addressViewModels[index].model.isPrimary = false;
            if (index === indexToSet) {
                this.addressViewModels[index].model.isPrimary = true;
            }
        });
    }

    @action
    public deleteAddress(val: AddressModel, indexToDelete: number) {
        // set isDeleted on address
        this.addressViewModels.forEach((address, index) => {
            if (index === indexToDelete) {
                this.addressViewModels[index].model.isDeleted = true;
            }
        });
    }

    @observable
    public addressModel = new AddressModel();

    @action
    public setAddressModel(val: string) {
        this.addressModel = this.getAddressById(val);
    }
    /**
     * Single Address ViewModel
     */
    @observable
    public addressViewModel = new AddressViewModel(new AddressModel());

    @action
    public setAddressViewModel(val: AddressModel) {
        this.addressViewModel = new AddressViewModel(val);
    }

    @computed
    public get returnAddresses(): AddressModel[] {
        return this.model.addresses.filter((a: any) => !a.isDeleted).slice();
    }

    // #endregion Addresses

    // #region Notes

    @action
    public resetNotesViewModel() {
        this.noteViewModels = [];
    }

    @observable
    private noteViewModels: NoteViewModel[] = [];

    @computed
    public get getNoteViewModels(): NoteViewModel[] {
        return this.noteViewModels.filter((note) => note.model.isDeleted === false).sort((a, b) => b.noteCreatedDate.getTime() - a.noteCreatedDate.getTime());
    }

    @action
    private populateProjectNotes() {
        // clear any notes currently in model
        this.model.notes = [];
        // (Re)build notes model from noteViewModels[]

        this.noteViewModels.forEach((note) => {
            let _note = new NoteModel();
            _note.fromObservable(note.model);
            this.model.notes.push(_note);
        });
    }

    @action
    private createNoteViewModels(): void {
        this.noteViewModels = [];
        for (const note of this.model.notes) {
            this.noteViewModels.push(new NoteViewModel(note));
        }
    }

    @action
    public getModelNotes() {
        return new NoteModel();
    }

    @action
    public getNoteById(val: string) {
        const note = this.noteViewModels.find((note) => note.model.id === val);
        if (note !== undefined) {
            return note.model;
        } else {
            return new NoteModel();
        }
    }

    @action
    public deleteNote(val: NoteModel) {
        // set isDeleted on address
        this.noteViewModels.forEach((note, index) => {
            if (this.noteViewModels[index].model.note === val.note) {
                this.noteViewModels[index].model.isDeleted = true;
            }
        });
    }

    @observable
    public noteModel = new NoteModel();

    @action
    public setNoteModel(val: NoteModel) {
        this.noteModel = val;
    }

    @computed
    public get returnNotes(): NoteModel[] {
        return this.model.notes;
    }

    // #endregion Notes

    // #region Project Status Types

    @observable
    public projectStatusTypes = observable<ProjectStatusTypeModel>([]);

    @computed
    public get pstId(): string {
        return this.model.projectStatusTypeId;
    }

    @computed
    public get projectStatusType(): ProjectStatusTypeModel | null {
        const result = this.projectStatusTypes.find((pst) => pst.id === this.model.projectStatusTypeId);
        return result ? result! : null;
    }

    @computed
    public get projectStatusTypeDisplayName(): string {
        return this.projectStatusType !== null ? this.projectStatusType!.name : "";
    }

    @action
    public getStatusDisplayNameById = (val: string) => {
        const result = this.projectStatusTypes.find((pst) => pst.id === val);
        if (result != null || result != undefined) {
            return result.name.toUpperCase();
        } else {
            return "DRAFT";
        }
    };

    @computed
    public get canSetProjectStatusType(): boolean {
        let result = false;

        if (!this.projectStatusType) {
            return result;
        }

        switch (this.projectStatusType.type) {
            case "CAPPEDLETTER":
            case "FULLORDER":
            case "COMPLETED12":
            case "COMPLETEDRETENTION":
                result = true;
                break;
        }

        return result;
    }

    @action
    public setProjectStatusType = (projectStatusTypeId: string | null): void => {
        this.model.projectStatusTypeId = projectStatusTypeId;
    };

    @action
    private populateProjectStatusTypes = (dtos: ProjectStatusTypeDTO[]): void => {
        const projectStatusTypes: ProjectStatusTypeModel[] = [];

        for (const dto of dtos) {
            projectStatusTypes.push(new ProjectStatusTypeModel(dto));
        }

        this.projectStatusTypes.replace(projectStatusTypes);

        // Side-effect
        if (!this.model.projectStatusTypeId) {
            const defaultProjectStatusType = this.projectStatusTypes.find((pst) => pst.type === "DRAFT");

            this.setProjectStatusType(defaultProjectStatusType ? defaultProjectStatusType.id : GeneralModel.DEFAULT_PROJECTSTATUSTYPEID);
        }
    };

    // #endregion Project Status Types

    // #region Project Types

    @observable
    public projectTypes = observable<ProjectTypeModel>([]);

    @computed
    public get projectType(): ProjectTypeModel | null {
        const result = this.projectTypes.find((pt) => pt.id === this.model.projectTypeId);

        return result ? result! : null;
    }

    @computed
    public get projectTypeDisplayName(): string {
        return this.projectType ? this.projectType!.name : "";
    }

    @action
    public setProjectType = (projectTypeId: string | null): void => {
        this.model.projectTypeId = projectTypeId;
    };

    @action
    private populateProjectTypes = (dtos: ProjectTypeDTO[]): void => {
        const projectTypes: ProjectTypeModel[] = [];

        for (const dto of dtos) {
            projectTypes.push(new ProjectTypeModel(dto));
        }

        this.projectTypes.replace(projectTypes);
    };

    // #endregion Project Types

    @action
    public clean = () => {
        this.model.clearModel();
        this.resetNotesViewModel();
        this.resetAddressViewModel();
    };

    @action
    public populateClientList(payload: ClientNameAndIdDTO[]) {
        const clientListVM = ClientSelectViewModel.Instance;
        clientListVM.populateClientNameAndId(payload);
    }

    @action
    public populateContractTypeList(payload: ContractTypeModelDTO[]) {
        const contractListVM = ContractTypeViewModel.Instance;
        contractListVM.populateContractNameAndId(payload);
    }

    @action
    public populateProject(payload: GeneralModelDTO) {
        this.model.fromDto(payload);

        this.populateClientList(payload.clientList);

        this.populateContractTypeList(payload.contractTypes);

        // Handle Addresses
        this.model.addresses = [] as AddressModel[];
        payload.addresses.forEach((address) => {
            let _address = new AddressModel();
            _address.fromDto(address);
            this.model.addresses.push(_address);
        });
        this.createAddressViewModels();

        // Notes and Contacts to follow
        this.model.notes = [] as NoteModel[];
        payload.notes.forEach((noteDTO) => {
            let note = new NoteModel();
            note.fromDto(noteDTO);
            this.model.notes.push(note);
        });
        this.createNoteViewModels();

        // Project Status Types
        this.populateProjectStatusTypes(payload.projectStatusTypes);

        // Project Types
        this.populateProjectTypes(payload.projectTypes);
    }

    /**
     * Returns whether the status of the project is draft.
     */
    @computed
    public get isDraft(): boolean {
        if (this.projectStatusType !== null && this.projectStatusType !== undefined) {
            return this.projectStatusType.type === "DRAFT";
        } else {
            return false;
        }
    }

    /**
     * Returns the background colour (status) for current project
     */
    @computed
    public get projectStatusColour(): string {
        if (this.projectStatusTypes.length > 0) {
            let result = this.projectStatusTypes.find((pst) => pst.id === this.model.projectStatusTypeId);
            return result ? result!.color : "unset";
        }
        return "unset";
    }

    /**
     * Returns the status type for current project - defaults to DRAFT
     */
    @computed
    public get projectStatus(): string {
        if (this.projectStatusTypes.length > 0) {
            let result = this.projectStatusTypes.find((pst) => pst.id === this.model.projectStatusTypeId);
            return result ? result!.name.toLocaleUpperCase() : "DRAFT";
        } else {
            return "DRAFT";
        }
    }

    @observable
    public projectId = "";

    /**
     * Header
     */
    @computed
    get getHeader(): DetailsHeaderModel {
        const retVal: DetailsHeaderModel = new DetailsHeaderModel();
        if (this.model.reference.length > 0 && this.model.name.length > 0) {
            retVal.setValue("title", this.model.reference + " - " + this.model.name);
        } else {
            retVal.setValue("title", "New project");
        }
        if (this.model.addresses.length > 0) {
            retVal.setValue("address", formatAddressAlternate(this.model.addresses[0]));
        } else {
            retVal.setValue("address", "");
        }
        retVal.setValue("status", this.model.status);
        retVal.setValue("statusClass", this.model.status);

        return retVal;
    }

    @computed
    get getShortHeaderAsString(): string {
        let retVal = "";
        if (this.model.reference.length > 0 && this.model.name.length > 0) {
            retVal = this.model.reference + " - " + this.model.name;
        }
        return retVal;
    }
    /**
     * Header: end
     */

    /**
     * ViewState: General, Commercial etc
     */
    @observable
    public currentViewState = "GENERAL";

    @action
    public setCurrentViewState(val: string) {
        this.currentViewState = val;
    }

    // #region Server Actions

    @action
    public apiGetbyId = async (val: string): Promise<void> => {
        this.setIsLoading(true);
        this.projectId = val;
        const request: GenericId = {
            id: val,
        };

        let apiResult = await this.Post<GeneralModelDTO>(AppUrls.Server.Projects.GetDetails, request);
        if (apiResult) {
            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.populateProject(apiResult.payload);
                });
            } else {
                console.log(apiResult.errors);
            }
            this.setHasLoaded(true);
        }
        this.setIsLoading(false);
    };

    @action
    public apiGetRelated = async (): Promise<void> => {
        this.setIsLoading(true);
        let apiResult = await this.Post<GeneralRelatedModelDTO>(AppUrls.Server.Projects.GetRelated);
        if (apiResult) {
            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.populateClientList(apiResult.payload.clientList);
                    this.populateProjectStatusTypes(apiResult.payload.projectStatusTypes);
                    this.populateProjectTypes(apiResult.payload.projectTypes);
                });
            } else {
                console.log(apiResult.errors);
            }
            this.setHasLoaded(true);
        }
        this.setIsLoading(false);
    };

    public upsertProject = async (): Promise<ApiResult<GeneralModelDTO>> => {
        this.setIsLoading(true);
        // Add addresses from array
        this.populateProjectAddresses();

        // Add notes from array
        this.populateProjectNotes();

        let project = this.model;

        project.addresses.forEach((address: any) => {
            if (address.id?.startsWith(this.NEWITEM) === true) {
                address.id = "";
            }
        });

        project.notes.forEach((note: any) => {
            if (note.id?.startsWith(this.NEWITEM) === true) {
                note.id = "";
                note.sourceId = project.id;
            }
        });

        let apiResult = await this.Post<GeneralModelDTO>(AppUrls.Server.Projects.Upsert, project);
        if (apiResult) {
            if (apiResult.wasSuccessful) {
                this.populateProject(apiResult.payload);
            } else {
                console.log(apiResult.errors);
            }
            this.setHasLoaded(true);
        }
        this.setIsLoading(false);
        return apiResult;
    };

    @action
    public addNote = async (note: NoteModelDTO): Promise<void> => {
        this.setIsLoading(true);
        if (isNullOrUndefined(note.id) === true) {
            note.id = this.NEWITEM + generateID();
        }
        let _note = new NoteModel();
        _note.fromDto(note);

        if (note.documentsToUpload !== null && note.documentsToUpload !== undefined && note.documentsToUpload.length > 0) {
            // then we need to upload the documents and add the returning project document to the documents array
            let successful: boolean = true;

            note.documentsToUpload.forEach(async (document) => {
                const request = {
                    projectReference: "documents",
                };

                let apiResult = await this.postFormWithFile<ProjectDocumentDTO>(AppUrls.Server.Projects.UploadDocument, document, request);

                if (apiResult) {
                    runInAction(() => {
                        if (apiResult.wasSuccessful) {
                            _note.documents.push(apiResult.payload);
                            // this.getNoteViewModels;
                            //this.setSnackMessage("Project saved/updated");
                            //this.setSnackType(this.SNACKSUCCESS);
                            //this.setSnackbarState(true);
                        } else {
                            this.setSnackMessage("Error saving/updating project");
                            this.setSnackType(this.SNACKERROR);
                            this.setSnackbarState(true);
                        }
                    });
                }
            });

            if (successful === true) {
                _note.documentsToUpload = [];
            }
        }

        this.noteViewModels.push(new NoteViewModel(_note));
        this.setIsLoading(false);
    };

    public isReferenceInUse = async (id: string, reference: string): Promise<boolean> => {
        this.setIsLoading(true);
        const request: IsReferenceInUseRequest = {
            id: id,
            reference: reference,
        };

        const apiResult = await this.Post<boolean>(AppUrls.Server.Projects.IsReferenceInUse, request);

        if (apiResult.wasSuccessful === true && apiResult.payload === true) {
            runInAction(() => {
                this.IsErrored = true;
                this.setError("reference", "The reference address is already in use.");
                this.setValid("reference", false);
            });
        }

        this.setIsLoading(false);

        return apiResult.payload;
    };

    // #endregion Server Actions

    // #region Viewmodel Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<GeneralModel>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        if (fieldName === "reference") {
            const refExisits: boolean = await this.isReferenceInUse(this.model.id, this.model.reference);

            if (refExisits === true) {
                isValid = false;
            }
        }
        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Viewmodel Boilerplate

    // #region Snackbar

    @observable
    public snackbarState = false;

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public snackMessage = "";

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @observable
    public snackType = "";

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    @observable
    public SNACKSUCCESS = "success";

    @observable
    public SNACKERROR = "error";
    // #endregion

    @action
    public generalAddNote = async (note: NoteModelDTO): Promise<void> => {
        this.setIsLoading(true);
        if (isNullOrUndefined(note.id) === true) {
            note.id = this.NEWITEM + generateID();
        }
        let _note = new NoteModel();
        _note.fromDto(note);
        if (note.documentsToUpload !== null && note.documentsToUpload !== undefined && note.documentsToUpload.length > 0) {
            let apiResult = await this.postFormWithFiles<NoteModelDTO>(AppUrls.Server.Projects.UploadProjectNote, "files", note.documentsToUpload, _note, undefined);
            if (apiResult) {
                runInAction(() => {
                    if (apiResult.wasSuccessful) {
                        _note.fromDto(apiResult.payload);
                        _note.documents = apiResult.payload.documents;
                    } else {
                        this.setSnackMessage("Error saving/updating project");
                    }
                });
            }
        }

        this.noteViewModels.push(new NoteViewModel(_note));
        this.setIsLoading(false);
    };
}
