// Libraries
import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import styled from "styled-components";

// Custom
import { IEGridCategoryModel, IEGridSubcategoryModel, IEGridItemModel, PurchaseOrderListItemModelDTO } from "../IEmodels";
import { IEViewModel } from "../IE.ViewModel";
import { IEItemModalRequest } from "../IEmodels/IEGridModel";
import { PackagesViewModel } from "../PackagesViewModel";
import { IEItem } from "./Rows/IEItem";
import { IECat } from "./Rows/IECatRow";
import { IESubcat } from "./Rows/IESubcatRow";
import { IEPurchaseOrderItem } from "./Rows/IEPurchaseOrderRow";

import { PopulateFromIEItemModel } from "Views/PurchaseOrder/Form/Supporting/PopulateFromIEItemModel";

// Styling & Images
import { SummaryGridView, ToggleButton } from "./IEGrid.Styles";
import { theme } from "Globals/Styles/AppTheme";
import { makeStyles } from "@material-ui/core";
import MoneySVG from "Content/Money.svg";
import { formatCurrencyForGrid, formatCurrencyNoSign } from "Utils/Format";
import { CommercialViewModel } from "../Commercial.ViewModel";
import { IEGridItemViewModel } from "../IEmodels/IEGridItemViewModel";
import { formatCreditDebitClass, formatCreditDebitReverseClass } from "Utils/Utils";
import { IEItemMaterialRow } from "./Rows/IEItemMaterialRow";
import { IEItemMaterial } from "../IEmodels/IEGrid.IEItemMaterialModel";
import { formatDebit } from "Utils/Utils";

interface ITableProps {
    IEid: string;
    viewModel: IEViewModel;
    rowToEdit?(iemodel: IEGridItemModel, parent: string, packageItem: IEItemModalRequest): void;
    IEType: string;
    commercialViewModel?: CommercialViewModel;
    isReport: boolean;
}

export const IEGridViewCatSubDesc: React.FunctionComponent<ITableProps> = (props) => {
    const { viewModel, IEid, commercialViewModel, isReport } = props;

    const packagesViewModel = PackagesViewModel.Instance;

    //const commercialViewModel = CommercialViewModel.Instance;

    const getFormatDebit = (val: number) => {
        return formatDebit(val);
    };

    // Define the initial state
    const initialActiveIEItem = {
        activeProject: null as string | null,
        activeIE: null as string | null,
        activeCategory: -1,
        activeSubCategory: -1,
        activeDescription: -1,
        isPurchaseOrderActive: false,
        isIEItemMaterialActive: false,
    };

    // Use useState to create a state variable and setter function
    const [activeIEItem, setActiveIEItem] = useState(initialActiveIEItem);

    useEffect(() => {
        if (commercialViewModel) {
            setActiveIEItem({
                activeProject: commercialViewModel.getActiveProject || null,
                activeIE: commercialViewModel.getActiveIE || null,
                activeCategory: commercialViewModel.getActiveCategory,
                activeSubCategory: commercialViewModel.getActiveSubCategory,
                activeDescription: commercialViewModel.getActiveDescription,
                isPurchaseOrderActive: commercialViewModel.getIsPurchaseOrderActive,
                isIEItemMaterialActive: commercialViewModel.isIEIemMaterialActive,
            });
        }
    }, []);

    const setActiveCategory = (newActiveCategory: number) => {
        setActiveIEItem((prevActiveIEItem) => ({
            ...prevActiveIEItem,
            activeCategory: newActiveCategory,
        }));

        // Grid is used on the IE tab and on the report. We don't want the report to remember which items are expanded.
        if (commercialViewModel) {
            commercialViewModel.setActiveCategory(newActiveCategory);
        }
    };

    const setActiveSubCategory = (newActiveSubCategory: number) => {
        setActiveIEItem((prevActiveIEItem) => ({
            ...prevActiveIEItem,
            activeSubCategory: newActiveSubCategory,
        }));

        if (commercialViewModel) {
            commercialViewModel.setActiveSubCategory(newActiveSubCategory);
        }
    };

    const setActiveDescription = (newActiveDescription: number) => {
        setActiveIEItem((prevActiveIEItem) => ({
            ...prevActiveIEItem,
            activeDescription: newActiveDescription,
        }));

        if (commercialViewModel) {
            commercialViewModel.setActiveDescription(newActiveDescription);
        }
    };

    const setIsPurchaseOrderActive = (newIsPurchaseOrderActive: boolean) => {
        setActiveIEItem((prevActiveIEItem) => ({
            ...prevActiveIEItem,
            isPurchaseOrderActive: newIsPurchaseOrderActive,
        }));

        if (commercialViewModel) {
            commercialViewModel.setIsPurchaseOrderActive(newIsPurchaseOrderActive);
        }
    };

    const setIEItemMaterialActive = (newIsIEItemMaterialActive: boolean) => {
        setActiveIEItem((prevActiveIEItem) => ({
            ...prevActiveIEItem,
            isIEItemMaterialActive: newIsIEItemMaterialActive,
        }));

        if (commercialViewModel) {
            commercialViewModel.setIEItemMaterialActive(newIsIEItemMaterialActive);
        }
    };

    const classes = useStyles();

    const handleDescriptionEnable = (categoryIndex: number, subCategoryIndex: number, index: number) => {
        setActiveDescription(index);

        if (subCategoryIndex !== -1) {
            setActiveSubCategory(subCategoryIndex);
        }
        if (categoryIndex !== -1) {
            setActiveCategory(categoryIndex);
        }
        setIsPurchaseOrderActive(false);
        setIEItemMaterialActive(false);
    };

    const handleSubCatagoryEnable = (categoryIndex: number, index: number) => {
        if (categoryIndex !== -1) {
            setActiveCategory(categoryIndex);
        }
        setActiveSubCategory(index);
        setActiveDescription(-1);
        setIsPurchaseOrderActive(false);
        setIEItemMaterialActive(false);
    };

    const handleCategoryEnable = (index: number) => {
        setActiveCategory(index);
        setActiveSubCategory(-1);
        setActiveDescription(-1);
        setIsPurchaseOrderActive(false);
        setIEItemMaterialActive(false);
    };

    const handleIEItemClick = (item: IEGridItemModel, parentId: string, categoryId: number, subcategoryId: number) => {
        const packageRequest: IEItemModalRequest = {
            categoryId: categoryId,
            subCategoryId: subcategoryId,
            descriptionId: item.sourceId,
            itemId: item.id,
        };

        if (props.rowToEdit) {
            props.rowToEdit(item, parentId, packageRequest);
        }
    };

    const itemRowHistory = (item: IEGridItemModel, catGuid: string, catName: string | undefined, subcatName: string | undefined) => {
        let retVal: PopulateFromIEItemModel = {
            projectId: props.viewModel.projectId,
            ieId: props.IEid,
            ieCategoryId: catGuid,
            ieCategoryName: catName,
            ieSubcategoryId: item.parentId,
            ieSubcategoryName: subcatName,
            ieItemLineId: item.id,
            ieItemName: "",
        };

        return retVal;
    };

    const purchaseOrderView = (index: number, obj: PurchaseOrderListItemModelDTO[], ielineId: string) => {
        return (
            <IESubItem>
                <li>
                    <div style={{ display: "flex", alignItems: "center", backgroundColor: "#EDEDED" }}>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <img src={MoneySVG}></img>
                            <span style={{ display: "inline-block", marginLeft: "12px" }}>Purchase Orders</span>
                        </div>
                        {activeIEItem.isPurchaseOrderActive ? (
                            <ToggleButton>
                                <span
                                    className={"open"}
                                    onClick={() => {
                                        setIsPurchaseOrderActive(false);
                                    }}
                                >
                                    &minus;
                                </span>
                            </ToggleButton>
                        ) : (
                            <ToggleButton>
                                <span
                                    className={"close"}
                                    onClick={() => {
                                        setIsPurchaseOrderActive(true);
                                    }}
                                >
                                    &#43;
                                </span>
                            </ToggleButton>
                        )}
                    </div>
                </li>
                {activeIEItem.isPurchaseOrderActive &&
                    obj.map((req: PurchaseOrderListItemModelDTO, index: number) => (
                        <>
                            <IEPurchaseOrderItem itemModel={req} rowIndex={index} ieLineId={ielineId} ieId={IEid} />
                        </>
                    ))}
            </IESubItem>
        );
    };

    const ieItemMaterialsView = (items: IEItemMaterial[]) => {
        return (
            <IESubItem>
                <li>
                    <div style={{ display: "flex", alignItems: "center", backgroundColor: "#EDEDED" }}>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <img src={MoneySVG}></img>
                            <span style={{ display: "inline-block", marginLeft: "12px" }}>Materials</span>
                        </div>
                        {activeIEItem.isIEItemMaterialActive ? (
                            <ToggleButton>
                                <span
                                    className={"open"}
                                    onClick={() => {
                                        setIEItemMaterialActive(false);
                                    }}
                                >
                                    &minus;
                                </span>
                            </ToggleButton>
                        ) : (
                            <ToggleButton>
                                <span
                                    className={"close"}
                                    onClick={() => {
                                        setIEItemMaterialActive(true);
                                    }}
                                >
                                    &#43;
                                </span>
                            </ToggleButton>
                        )}
                    </div>
                </li>
                {activeIEItem.isIEItemMaterialActive && items.map((item: IEItemMaterial) => <IEItemMaterialRow key={item.id} itemModel={item} />)}
            </IESubItem>
        );
    };

    const itemView = (
        obj: IEGridItemModel[],
        sourceId: number,
        catIndex: number,
        subIndex: number,
        catGuid: string,
        parentId: string,
        categoryId: number,
        subcategoryId: number,
        categoryName: string | undefined,
        subcategoryName: string | undefined,
    ) => {
        return (
            <>
                {obj.map((i: IEGridItemModel, index: number) => (
                    <>
                        <IEItem
                            itemModel={i}
                            sourceId={sourceId}
                            rowIndex={index}
                            rowEnable={(ci, sci, i) => handleDescriptionEnable(ci, sci, i)}
                            rowEdit={(row) => handleIEItemClick(i, parentId, categoryId, subcategoryId)}
                            ieId={IEid}
                            parentId={parentId}
                            thisGuid={i.id}
                            categoryIndex={catIndex}
                            subCategoryIndex={subIndex}
                            currentActiveCatIndex={activeIEItem.activeCategory}
                            currentActiveSubCatIndex={activeIEItem.activeSubCategory}
                            currentActiveDescIndex={activeIEItem.activeDescription}
                            guidHistory={itemRowHistory(i, catGuid, categoryName, subcategoryName)}
                            isReport={isReport}
                        />

                        {activeIEItem.activeDescription === index &&
                            activeIEItem.activeSubCategory === subIndex &&
                            activeIEItem.activeCategory === catIndex &&
                            i.groupedPOItems.length > 0 && <>{purchaseOrderView(index, i.groupedPOItems, i.id)}</>}

                        {activeIEItem.activeDescription === index &&
                            activeIEItem.activeSubCategory === subIndex &&
                            activeIEItem.activeCategory === catIndex &&
                            i.ieItemMaterials.length > 0 && <React.Fragment key={`ieItemMaterials_${i.id}`}>{ieItemMaterialsView(i.ieItemMaterials)}</React.Fragment>}
                    </>
                ))}
            </>
        );
    };

    const subcategoryView = (obj: IEGridSubcategoryModel[], sourceId: number, catIndex: number, catGuid: string, categoryId: number, categoryName: string | undefined) => {
        return (
            <>
                {obj.map((s: IEGridSubcategoryModel, index: number) => (
                    <>
                        <div className={activeIEItem.activeSubCategory === index && activeIEItem.activeCategory === catIndex ? classes.SubCatBorder : ""}>
                            <IESubcat
                                itemModel={s}
                                categoryGuid={catGuid}
                                categoryIndex={catIndex}
                                rowIndex={index}
                                rowEnable={(p, i) => handleSubCatagoryEnable(p, i)}
                                rowType={"subcategory"}
                                iEId={IEid}
                                currentActiveCatIndex={activeIEItem.activeCategory}
                                currentActiveSubCatIndex={activeIEItem.activeSubCategory}
                            />
                            {activeIEItem.activeSubCategory === index && activeIEItem.activeCategory === catIndex && (
                                <>
                                    {itemView(
                                        s.getGridItems,
                                        s.sourceId,
                                        catIndex,
                                        index,
                                        catGuid,
                                        s.id,
                                        categoryId,
                                        s.sourceId,
                                        categoryName,
                                        packagesViewModel.getSubcategoryName(s.sourceId),
                                    )}
                                </>
                            )}
                        </div>
                    </>
                ))}
            </>
        );
    };

    return useObserver(() => (
        <>
            <SummaryGridView className={classes.IELineGroup}>
                {viewModel.categoriesAndRelated.map((c: IEGridCategoryModel, index: number) => (
                    <>
                        <div className={activeIEItem.activeCategory === index ? classes.CatBorder : ""}>
                            <IECat
                                itemModel={c}
                                rowIndex={index}
                                iEId={IEid}
                                sourceId={c.sourceId}
                                rowType={"ie"}
                                rowEnable={(i) => handleCategoryEnable(i)}
                                thisGuid={c.id}
                                currentActiveCatIndex={activeIEItem.activeCategory}
                            />
                            {activeIEItem.activeCategory === index && (
                                <>{subcategoryView(c.subcategories, c.sourceId, index, c.id, c.sourceId, packagesViewModel.getCategoryName(c.sourceId))}</>
                            )}
                        </div>
                    </>
                ))}
                <li className="ie">
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <span style={{ paddingLeft: "5px" }}>{IEGridItemViewModel.Instance.isCentral ? "Total" : "Subtotal"}</span>
                    </div>
                    <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(viewModel.ieGridViewModel.model.income))}</div>
                    <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(viewModel.ieGridViewModel.model.estimateCost))}</div>
                    <div className="right">{viewModel.ieGridViewModel.model.margin}</div>
                    <div className={"right" + getFormatDebit(viewModel.ieGridViewModel.model.committedCost)}>
                        {formatCurrencyForGrid(formatCurrencyNoSign(viewModel.ieGridViewModel.model.committedCost))}
                    </div>
                    <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(viewModel.ieGridViewModel.model.futureSpend))}</div>
                    <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(viewModel.ieGridViewModel.model.totalExpectedSpend))}</div>
                    <div className={"right" + formatCreditDebitClass(viewModel.ieGridViewModel.model.variance)}>
                        {formatCurrencyForGrid(formatCurrencyNoSign(viewModel.ieGridViewModel.model.variance))}
                    </div>
                    <div className={"right" + formatCreditDebitReverseClass(viewModel.ieGridViewModel.model.roValue)}>
                        {formatCurrencyForGrid(formatCurrencyNoSign(viewModel.ieGridViewModel.model.roValue))}
                    </div>
                    <div className="right" style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}></div>
                </li>
            </SummaryGridView>
            {viewModel.categoriesAndRelated.length === 0 && props.IEType === "MASTER" && (
                <>
                    <div style={{ fontSize: "12px", letterSpacing: "-0.24px", marginLeft: "10px", marginTop: "30px" }}>
                        There are currently no lines in this I & E. Click <span style={{ color: "#0095da" }}>Add new line</span> to add one to this I & E
                    </div>
                </>
            )}
        </>
    ));
};

const useStyles = makeStyles({
    IELineGroup: {
        borderBottom: `4px solid ${theme.palette.detail.main}`,
    },
    CatBorder: {
        borderTop: `4px solid ${theme.palette.detail.main}`,
        borderBottom: `4px solid ${theme.palette.detail.main}`,
    },
    SubCatBorder: {
        borderTop: `2px solid ${theme.palette.detail.main}`,
        borderBottom: `2px solid ${theme.palette.detail.main}`,
    },
    ItemBorder: {
        borderTop: `1px solid ${theme.palette.detail.main}`,
        borderBottom: `1px solid ${theme.palette.detail.main}`,
    },
});

export const IESubItem = styled.div`
    > li {
        > div:nth-child(1) {
            margin-left: 50px;
        }
    }
`;
