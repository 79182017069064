import { ModelBase } from "@shoothill/core";
import { action, observable } from "mobx";

export enum StockTypeEnum {
    FromStock = 1,
    ToStock = 2,
}

export class StockTypeModel extends ModelBase<StockTypeModel, StockTypeDTO> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Defaults and Constants

    public static readonly DEFAULT_ID = "";
    public static readonly DEFAULT_DISPLAYNAME = "";
    public static readonly DEFAULT_TYPE = StockTypeEnum.FromStock;

    // #endregion Defaults and Constants

    // #region Observables

    @observable
    public id: string = StockTypeModel.DEFAULT_ID;

    @observable
    public displayName: string = StockTypeModel.DEFAULT_DISPLAYNAME;

    @observable
    public type: StockTypeEnum = StockTypeModel.DEFAULT_TYPE;

    // #endregion Observables

    // #region Actions

    @action
    public reset(): void {
        this.id = StockTypeModel.DEFAULT_ID;
        this.displayName = StockTypeModel.DEFAULT_DISPLAYNAME;
        this.type = StockTypeModel.DEFAULT_TYPE;
    }

    @action
    public fromDto(dto: StockTypeDTO): void {
        this.id = dto.id;
        this.displayName = dto.displayName;
        this.type = dto.type;
    }

    public toDto() {
        throw new Error("Method not implemented.");
    }

    // #endregion Actions

    // #region Helpers

    public static fromDtos(dtos: StockTypeDTO[]): StockTypeModel[] {
        const types: StockTypeModel[] = [];

        for (const dto of dtos) {
            const model = new StockTypeModel();

            model.fromDto(dto);
            types.push(model);
        }

        return types;
    }

    // #endregion Helpers
}

export interface StockTypeDTO {
    id: string;
    displayName: string;
    type: StockTypeEnum;
}
