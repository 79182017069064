import { observer } from "mobx-react-lite";
import React from "react";

import { SolidSectionSplitter } from "Components/Form/SectionSplitter";
import { BaseModal } from "Components/Modal/BaseModal";
import { SnackBar } from "Components/SnackBar/SnackBar";
import { ApproveButton, CancelButton, RejectButton } from "Globals/Styles/Control/Buttons";
import { ReportModalStyle } from "Views/Project/Modals/Modal.Styles";
import { FormModalSection, SummaryLabel, SummaryLabelValueSection, SummaryRow, SummaryValue, SummaryLabelValueSectionStack } from "Views/Stock/Shared/Shared.styles";
import { StockTransferApprovalViewModel } from "./StockTransferApprovalViewModel";

interface IProps {
    viewModel: StockTransferApprovalViewModel;
}

export const StockTransferApprovalView: React.FC<IProps> = observer((props) => {
    const viewModel = props.viewModel;

    const renderButtons = () => {
        return (
            <React.Fragment>
                {viewModel.canApprove && (
                    <ApproveButton disabled={viewModel.server.IsBusy} onClick={viewModel.approve}>
                        Approve
                    </ApproveButton>
                )}
                {viewModel.canReject && (
                    <RejectButton disabled={viewModel.server.IsBusy} onClick={viewModel.reject}>
                        Reject
                    </RejectButton>
                )}
                {viewModel.canCancel ? <CancelButton onClick={viewModel.cancel}>Cancel</CancelButton> : <CancelButton onClick={viewModel.cancel}>Close</CancelButton>}
            </React.Fragment>
        );
    };

    return (
        <BaseModal open={viewModel !== null} onClose={viewModel.cancel} title={viewModel.displayName} PaperComponent={ReportModalStyle} actions={renderButtons()}>
            <FormModalSection>
                <SummaryRow grid dc={"1fr"}>
                    <SummaryLabelValueSectionStack>
                        <SummaryLabel>Item description:</SummaryLabel>
                        <SummaryValue>{viewModel.model.description}</SummaryValue>
                    </SummaryLabelValueSectionStack>
                </SummaryRow>
            </FormModalSection>
            <SolidSectionSplitter mb="0.625rem" />
            <FormModalSection>
                <SummaryRow grid dc={"1fr 1fr"}>
                    <SummaryLabelValueSection>
                        <SummaryLabel>Project:</SummaryLabel>
                        <SummaryValue>{viewModel.model.projectDisplayName}</SummaryValue>
                    </SummaryLabelValueSection>
                    <SummaryLabelValueSection>
                        <SummaryLabel>I & E:</SummaryLabel>
                        <SummaryValue>{viewModel.model.ieDisplayName}</SummaryValue>
                    </SummaryLabelValueSection>
                </SummaryRow>
                <SummaryRow grid dc={"1fr 1fr"}>
                    <SummaryLabelValueSection>
                        <SummaryLabel>Category:</SummaryLabel>
                        <SummaryValue>{viewModel.model.categoryDisplayName}</SummaryValue>
                    </SummaryLabelValueSection>
                    <SummaryLabelValueSection>
                        <SummaryLabel>Sub-category:</SummaryLabel>
                        <SummaryValue>{viewModel.model.subCategoryDisplayName}</SummaryValue>
                    </SummaryLabelValueSection>
                </SummaryRow>
                <SummaryRow grid dc={"1fr"}>
                    <SummaryLabelValueSection>
                        <SummaryLabel>Line:</SummaryLabel>
                        <SummaryValue>{viewModel.model.lineDescriptionDisplayName}</SummaryValue>
                    </SummaryLabelValueSection>
                </SummaryRow>
            </FormModalSection>
            <SolidSectionSplitter mb="0.625rem" />
            <FormModalSection>
                <SummaryRow grid dc={"1fr 1fr 1fr 1fr"}>
                    <SummaryLabelValueSection>
                        <SummaryLabel>From/To:</SummaryLabel>
                        <SummaryValue>{viewModel.model.stockTransactionTypeDisplayName}</SummaryValue>
                    </SummaryLabelValueSection>
                    <SummaryLabelValueSection>
                        <SummaryLabel>Quantity:</SummaryLabel>
                        <SummaryValue>{viewModel.model.units} units</SummaryValue>
                    </SummaryLabelValueSection>
                    {viewModel.canDisplayCostOfTransfer && (
                        <SummaryLabelValueSection>
                            <SummaryLabel>Stock value:</SummaryLabel>
                            <SummaryValue>{viewModel.costOfTransfer}</SummaryValue>
                        </SummaryLabelValueSection>
                    )}
                </SummaryRow>
                <SummaryRow grid dc={"1fr 1fr"}>
                    <SummaryLabelValueSectionStack>
                        <SummaryLabel>Suggested future spend:</SummaryLabel>
                        <SummaryValue>{viewModel.model.suggestedFutureSpend}</SummaryValue>
                    </SummaryLabelValueSectionStack>
                    <SummaryLabelValueSectionStack>
                        <SummaryLabel>Reason:</SummaryLabel>
                        <SummaryValue>{viewModel.model.suggestedFutureSpendReason}</SummaryValue>
                    </SummaryLabelValueSectionStack>
                </SummaryRow>
                <SummaryRow grid dc={"1fr"}>
                    <SummaryLabelValueSectionStack>
                        <SummaryLabel>Notes:</SummaryLabel>
                        <SummaryValue>{viewModel.model.note}</SummaryValue>
                    </SummaryLabelValueSectionStack>
                </SummaryRow>
            </FormModalSection>
            <SolidSectionSplitter mb="0.625rem" />
            <FormModalSection>
                <SummaryRow grid dc={"1fr 1fr"}>
                    <SummaryLabelValueSection>
                        <SummaryLabel>Created by:</SummaryLabel>
                        <SummaryValue>
                            {viewModel.model.createdByUser}&nbsp;&nbsp;&nbsp;{viewModel.createdDate}
                        </SummaryValue>
                    </SummaryLabelValueSection>
                    {viewModel.canDisplayLastUpdated && (
                        <SummaryLabelValueSection>
                            <SummaryLabel>Last updated by:</SummaryLabel>
                            <SummaryValue>
                                {viewModel.model.lastUpdatedByUser}&nbsp;&nbsp;&nbsp;{viewModel.lastUpdatedDate}
                            </SummaryValue>
                        </SummaryLabelValueSection>
                    )}
                </SummaryRow>
            </FormModalSection>
            <SnackBar
                messageText={viewModel.snackMessage}
                messageType={viewModel.snackType}
                active={viewModel.snackbarState}
                closeOption={() => viewModel.setSnackbarState(false)}
                autoHideDuration={5000}
            />
        </BaseModal>
    );
});
