import { FieldType, ViewModelBase } from "@shoothill/core";
import { AppUrls } from "AppUrls";
import { action, computed, observable } from "mobx";
import { VariationDeleteRequestDTO, VariationDeleteResponseDTO, VariationTypeEnum } from "Views/Variation/Form/VariationModel";
import { RequestModel, VariationRequestModel, VariationsTabsDTO } from "../Commercial/IE.Model";
import { ApprovedVariationViewModel } from "./ApprovedVariation.ViewModel";
import { ApprovedVariationListItemDTO, ApprovedVariationTableModel } from "./ApprovedVariationListModel";
import { VariationViewModel } from "./Variation.ViewModel";
import { VariationListAndRelatedRequest, VariationListModel, VariationTableModel } from "./VariationListModel";

export class VariationListViewModel extends ViewModelBase<VariationListModel> {
    private static _instance: VariationListViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    constructor() {
        super(new VariationListModel(), false);
        this.setDecorators(VariationListModel);
    }

    @observable public status: number = 0;

    @observable
    public workingId = "";

    @observable
    public variationType: VariationTypeEnum = VariationTypeEnum.Variation;

    @observable
    public projectId = "";

    @observable
    public projectTitle = "";

    @observable
    public clientResponseBy = "";

    @observable
    public projectStatusType = "DRAFT";

    @observable
    private variationArr: VariationViewModel[] = observable<VariationViewModel>([]);

    @observable
    private approvedVariationArr: ApprovedVariationViewModel[] = observable<ApprovedVariationViewModel>([]);

    @computed
    public get returnVariations(): VariationTableModel[] {
        return this.variationArr.map((item: VariationViewModel) => {
            return item.model.toTableModel();
        });
    }

    @computed
    public get returnApprovedVariations(): ApprovedVariationTableModel[] {
        return this.approvedVariationArr.map((item: ApprovedVariationViewModel) => {
            return item.model.toTableModel();
        });
    }

    @action
    public setVariationType = (variationType: VariationTypeEnum) => {
        this.variationType = variationType;
    };

    @action
    public resetDateFilters = () => {
        this.model.filterEndDateVAR = null;
        this.model.filterStartDateVAR = null;
    };

    @observable
    public variationIdToDelete: string | null = null;

    @action
    public setVariationIdToDelete = (val: string | null) => {
        this.variationIdToDelete = val;
    };

    @computed
    public get getVariationToDeleteNumber(): string {
        const variationToDelete = this.returnVariations.find((v) => v.id === this.variationIdToDelete);
        return variationToDelete ? variationToDelete.variationNumberFormatted : "unknown";
    }

    // GetVariantlist Start

    @action
    private populateViewModels = (dto: VariationsTabsDTO) => {
        const po: VariationViewModel[] = [];
        for (const item of dto.variationsLists) {
            let vm = new VariationViewModel();
            vm.model.fromDto(item);
            po.push(vm);
        }
        this.variationArr.splice(0, this.variationArr.length, ...po);
    };

    @action
    public GetVariationList = async (val: string): Promise<void> => {
        this.setIsLoading(true);
        const request: VariationRequestModel = {
            Id: val,
            variationType: this.variationType,
        };
        let apiResult = await this.Post<VariationsTabsDTO>(AppUrls.Server.Variation.GetVariationList, request);
        if (apiResult) {
            if (apiResult.wasSuccessful) {
                this.populateViewModels(apiResult.payload);
            } else {
                console.log(apiResult.errors);
            }
        }
        this.setIsLoading(false);
    };

    // GetVariantlist end

    // Approved variations Start

    @action
    private populateApprovedViewModels = (dto: ApprovedVariationListItemDTO[]) => {
        const po: ApprovedVariationViewModel[] = [];
        for (const item of dto) {
            let vm = new ApprovedVariationViewModel();
            vm.model.fromDto(item);
            po.push(vm);
        }
        this.approvedVariationArr.splice(0, this.approvedVariationArr.length, ...po);
    };

    @action
    public GetApprovedVariationsList = async (val: string): Promise<void> => {
        this.setIsLoading(true);
        const toDate = this.model.filterEndDateVAR;
        const request: VariationListAndRelatedRequest = {
            id: val,
            fromDate: null,
            toDate: toDate,
            variationType: this.variationType,
        };
        let apiResult = await this.Post<ApprovedVariationListItemDTO[]>(AppUrls.Server.Variation.GetApprovedVariationList, request);
        if (apiResult) {
            if (apiResult.wasSuccessful) {
                this.populateApprovedViewModels(apiResult.payload);
            } else {
                console.log(apiResult.errors);
            }
        }
        this.setIsLoading(false);
    };

    // Approved variations End

    public deleteVariation = async (variationId: string, ieId: string): Promise<void> => {
        const variationToDelete = this.variationArr.find((v) => v.model.id === variationId);

        if (variationToDelete) {
            const request: VariationDeleteRequestDTO = {
                id: variationId,
                rowVersion: variationToDelete.model.rowVersion,
            };
            let apiResult = await this.Post<VariationDeleteResponseDTO>(AppUrls.Server.Variation.Delete, request);
            if (apiResult) {
                if (apiResult.wasSuccessful) {
                    this.GetVariationList(ieId);
                    this.GetApprovedVariationsList(ieId);
                } else {
                    console.log(apiResult.errors);
                }
            }
        }
    };

    public async isFieldValid(fieldName: keyof FieldType<VariationListModel>): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
