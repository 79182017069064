import { action, observable } from "mobx";
import { ModelBase } from "@shoothill/core";

/**
 * Common model for all approvals.
 * Base model used for approval panel by Requisition requests (purchase orders), variations and invoices.
 * When updating this, ensure you're not breaking any other sections that use the approval panel.
 */
export class ApprovalPanelModelBase<T = any> extends ModelBase<T, any> {
    // #region Properties

    @observable
    public id: string | null = null;

    @observable
    public requesterName: string | null = null;

    @observable
    public requestedDate: string | null = null;

    @observable
    public approvalStatusId: string | null = null;

    @observable
    public isResolved: boolean = false;

    @observable
    public requisitionNotes: string | null = null;

    @observable
    public requesterNotes: string | null = null;

    @observable
    public approverNotes: string | null = null;

    @observable
    public isApprover: boolean = false;

    @observable
    public isRequester: boolean = false;

    @observable
    public isCentral: boolean = false;

    @observable
    public rowVersion: string | null = null;

    // #endregion Properties

    // #region Actions

    @action
    public reset = () => {};

    @action
    public fromDto(model: ApprovalPanelBaseDTO | null): void {
        if (model !== null) {
            //this just iterates through every key assigning it to the model
            //Should only use if there is a direct mapping between dto and domain model
            //otherwise just map them yourself
            for (let key in model) {
                if (model.hasOwnProperty(key)) {
                    if (this[key] instanceof Date) {
                        this[key] = new Date(model[key]);
                    } else {
                        this[key] = model[key];
                    }
                }
            }
        }
    }

    public toDto(): ApprovalPanelBaseDTO {
        return {
            id: this.id,
            requesterName: this.requesterName,
            requestedDate: this.requestedDate,
            approvalStatusId: this.approvalStatusId,
            isResolved: this.isResolved,
            requisitionNotes: this.requisitionNotes,
            requesterNotes: this.requesterNotes,
            approverNotes: this.approverNotes,
            isApprover: this.isApprover,
            isRequester: this.isRequester,
            isCentral: this.isCentral,
            rowVersion: this.rowVersion,
        };
    }

    // #endregion Actions
}

/**
 * Common interface for all approvals.
 */
export interface ApprovalPanelBaseDTO {
    id: string | null;
    requesterName: string | null;
    requestedDate: string | null;
    approvalStatusId: string | null;
    isResolved: boolean;
    requisitionNotes: string | null;
    requesterNotes: string | null;
    approverNotes: string | null;
    isApprover: boolean;
    isRequester: boolean;
    isCentral: boolean;
    rowVersion: string | null;
}

/**
 * Common interface for upserting an approval.
 */
export interface ApprovalStatusBaseDTO {
    requisitionPOId: string | null;
    variationId: string | null;
    invoiceId: string | null;
    drawingId: string | null;
    requisitionStatusId: string;
    requesterNotes: string | null;
    approverNotes: string | null;
    isApproved: boolean;
    rowVersion: string | null;
}
