import { isEmptyOrWhitespace, observable, ViewModelBase } from "@shoothill/core";
import type { ValidationResponse } from "@shoothill/core";
import { action, computed, runInAction } from "mobx";

import { formatCurrencyFromPounds } from "Utils/Format";
import { RequisitionRequestItemModel } from "./RequisitionRequestItemModel";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { AppUrls } from "AppUrls";

export class RequisitionRequestItemViewModel extends ViewModelBase<RequisitionRequestItemModel> {
    // #region Constructors and Disposers

    constructor(requisitionRequestItemModel: RequisitionRequestItemModel) {
        super(requisitionRequestItemModel);
        this.setDecorators(RequisitionRequestItemModel);
    }

    // #region Constructors and Disposers

    // #region Properties

    @computed
    public get hasId(): boolean {
        return this.model.id !== "" && this.model.id !== undefined && this.model.id !== null;
    }

    @computed
    public get itemDescription(): string {
        return this.model.itemDescription;
    }

    @computed
    public get units(): number {
        return this.model.units;
    }

    @computed
    public get unitPrice(): string {
        return formatCurrencyFromPounds(this.model.unitPrice, 2, 4);
    }

    @computed
    public get total(): string {
        return formatCurrencyFromPounds(this.model.total);
    }

    @computed
    public get isStockOrderLine() {
        return !isEmptyOrWhitespace(this.model.materialId);
    }

    // #endregion Properties

    // #region Actions

    @action
    public voidItemUnits = () => {
        this.model.units = 0;
    };

    @computed
    public get canVoidItemUnits() {
        return !isEmptyOrWhitespace(this.model.id) && this.model.units > 0 && this.isStockOrderLine;
    }

    @action
    public setDeleted = () => {
        this.model.isDeleted = true;
    };

    @computed
    public get canDelete(): boolean {
        return this.model.id === "" || this.model.id === null || this.model.id === undefined || this.model.canDeleteItem;
    }

    // #endregion Actions

    // #region Api Actions

    public server: ServerViewModel = new ServerViewModel();

    public apiVoidItemUnits = async () => {
        await this.server.query<any>(
            () => this.Get(AppUrls.Server.PurchaseOrder.CanVoidOrderLine.replace("{orderLineId}", this.model.id!)),
            (result) => {
                if (result) {
                    this.voidItemUnits();
                } else {
                    this.setSnackMessage("This order line cannot be voided as it has associated stock in use.");
                    this.setSnackType(this.SNACKERROR);
                    this.setSnackbarState(true);
                }
            },
        );

        if (this.server.HaveValidationMessage) {
            this.setSnackMessage(this.server.ValidationMessage);
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }
    };

    // #endregion Api Actions

    @computed
    private get validateItemDescription(): ValidationResponse {
        const errorMessage = this.model.validateItemDescription;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateUnits(): ValidationResponse {
        const errorMessage = this.model.validateUnits;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateUnitPrice(): ValidationResponse {
        const errorMessage = this.model.validateUnitPrice;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    // #region Snack Bar

    public SNACKERROR = "error";

    @observable
    public snackbarState = false;

    @observable
    public snackType = "";

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public snackMessage = "";

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    // #endregion Snack Bar

    // #region Boilerplate

    public async isFieldValid(fieldName: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        switch (fieldName) {
            case "itemDescription": {
                const result = this.validateItemDescription;

                errorMessage = result.errorMessage;
                isValid = result.isValid;
                break;
            }

            case "units": {
                const result = this.validateUnits;

                errorMessage = result.errorMessage;
                isValid = result.isValid;
                break;
            }

            case "unitPrice": {
                const result = this.validateUnitPrice;

                errorMessage = result.errorMessage;
                isValid = result.isValid;
                break;
            }
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
