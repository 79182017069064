import styled from "styled-components";

export const IssueTable = styled.div`
    padding: 0 0 0 0;

    > p.MuiFormHelperText-root {
        color: #f44336;
        margin-bottom: 0.75rem;
    }
`;

export const IssueOutputDisplayName = styled.div`
    background-color: #eaf4f9;
    font-size: 12px;
    font-weight: bold;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0.5rem;
`;

export const OutputDrawingContainer = styled.div`
    margin: 0.5rem 0 0.5rem 0.625rem;
`;

export const OutputDrawing = styled.div`
    align-items: center;
    display: flex;

    > span {
        padding: 2px 9px 2px 0px;
    }
`;
