import { action, observable } from "mobx";
import { ModelBase } from "@shoothill/core";

export class IncidentModel extends ModelBase<IncidentModel, any> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Constants and Defaults

    public static readonly DEFAULT_ID = null;
    public static readonly DEFAULT_PROJECT_ID = "";
    public static readonly DEFAULT_INCIDENT_NUMBER = null;
    public static readonly DEFAULT_IS_DELETED = false;
    public static readonly DEFAULT_CREATED_BY_USER_ID = null;
    public static readonly DEFAULT_CREATED_DATE = null;
    public static readonly DEFAULT_ROW_VERSION = null;
    public static readonly DEFAULT_LAST_UPDATED_BY_USER_ID = null;
    public static readonly DEFAULT_LAST_UPDATED_DATE = null;

    // #endregion Constants and Defaults

    // #region Properties

    @observable
    public id: string | null = IncidentModel.DEFAULT_ID;

    @observable
    public projectId: string = IncidentModel.DEFAULT_PROJECT_ID;

    @observable
    public incidentNumber: number | null = IncidentModel.DEFAULT_INCIDENT_NUMBER;

    @observable
    public isDeleted: boolean = IncidentModel.DEFAULT_IS_DELETED;

    @observable
    public createdByUserId: number | null = IncidentModel.DEFAULT_CREATED_BY_USER_ID;

    @observable
    public createdDate: string | null = IncidentModel.DEFAULT_CREATED_DATE;

    @observable
    public rowVersion: string | null = IncidentModel.DEFAULT_ROW_VERSION;

    @observable
    public lastUpdatedByUserId: number | null = IncidentModel.DEFAULT_LAST_UPDATED_BY_USER_ID;

    @observable
    public lastUpdatedDate: string | null = IncidentModel.DEFAULT_LAST_UPDATED_DATE;

    // #endregion Properties

    // #endregion Properties

    // #region Actions

    @action
    public reset = () => {
        this.id = IncidentModel.DEFAULT_ID;
        this.projectId = IncidentModel.DEFAULT_PROJECT_ID;
        this.incidentNumber = IncidentModel.DEFAULT_INCIDENT_NUMBER;
        this.isDeleted = IncidentModel.DEFAULT_IS_DELETED;
        this.createdByUserId = IncidentModel.DEFAULT_CREATED_BY_USER_ID;
        this.createdDate = IncidentModel.DEFAULT_CREATED_DATE;
        this.rowVersion = IncidentModel.DEFAULT_ROW_VERSION;
        this.lastUpdatedByUserId = IncidentModel.DEFAULT_LAST_UPDATED_BY_USER_ID;
        this.lastUpdatedDate = IncidentModel.DEFAULT_LAST_UPDATED_DATE;
    };

    @action
    public fromDto(dto: IncidentAndRelatedResponseDTO): void {
        const incidentDto = dto.incident;

        for (let key in incidentDto) {
            if (incidentDto.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(incidentDto[key]);
                } else {
                    this[key] = incidentDto[key];
                }
            }
        }
    }

    public toDto() {
        throw new Error("Method not implemented.");
    }

    // #endregion Actions

    // #region Custom Validation

    // #endregion Custom Validation
}

export interface IncidentDTO {
    id: string | null;
    projectId: string;
    incidentNumber: number | null;
    isDeleted: boolean;
    createdByUserId: number | null;
    createdDate: string | null;
    rowVersion: string | null;
    lastUpdatedByUserId: number | null;
    lastUpdatedDate: string | null;
}

export interface IncidentUpsertAndRelatedResponseDTO {
    incident: IncidentDTO;
}

export interface IncidentAndRelatedResponseDTO {
    incident: IncidentDTO;
    incidentFormTypes: IncidentFormTypeDTO[];
    incidentFormListItems: IncidentFormItemDTO[];
    incidentProjectDetails: IncidentProjectDetailsDTO;
}

export interface IncidentProjectDetailsDTO {
    projectReference: string;
    projectName: string;
}

export interface IncidentFormTypeDTO {
    id: string;
    displayName: string;
    type: number;
    ordinal: number;
    isDeleted: boolean;
}

export interface IncidentFormItemDTO {
    id: string;
    incidentFormTypeName: string;
    incidentFormType: number;
    location: number;
    isInvestigationReportAdded: string;
    lastUpdatedDate: boolean;
    lastUpdatedByUserName: string;
    createdDate: string;
}

export interface IncidentProjectDetailsDTO {
    projectReference: string;
    projectName: string;
}
