import { isEmptyOrWhitespace } from "@shoothill/core";

export class PlansOfWorkItemViewModel {
    // #region Properties

    public id: string = "";
    public name: string = "";
    public statusName: string = "";
    public statusColor: string = "";
    public statusTextColor: string = "";

    // #endrgion Properties

    // #region Filtering

    public matchesFilter = (filterString: string): boolean => {
        if (isEmptyOrWhitespace(filterString)) {
            return true;
        }

        const filterStringUpperCase = filterString.toUpperCase();

        return this.name.toUpperCase().includes(filterStringUpperCase);
    };

    // #endregion Filtering
}
