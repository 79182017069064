import { getHistory, FieldType, ViewModelBase, ApiResult } from "@shoothill/core";
import { action, computed, observable, runInAction } from "mobx";

import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { ProjectCostReportModel, ProjectCostReportRequestDTO } from "./ProjectCostReportModel";
import moment from "moment";
import { AppUrls } from "AppUrls";
import { ProjectReportProjectDTO, ProjectReportResponseDTO } from "../Shared/ProjectReportModelBase";
import { csvAxiosRequestConfig, exportCSV } from "Utils/Utils";

export class ProjectCostReportViewModel extends ViewModelBase<ProjectCostReportModel> {
    // #region Constructors and Disposers

    constructor() {
        super(new ProjectCostReportModel());

        this.setDecorators(ProjectCostReportViewModel);
    }

    @observable public reportViewModel: ProjectReportProjectDTO | null = null;

    @observable public canExportCSV: boolean = false;

    @action
    public setCanExportCSV(val: boolean) {
        this.canExportCSV = val;
    }

    @computed
    public get getTopDateFormatted(): string {
        return this.model.reportEndDateTop ? moment(this.model.reportEndDateTop).format("DD/MM/YYYY").toString() : "Please select a date";
    }

    @computed
    public get getBottomDateFormatted(): string {
        return this.model.reportEndDateBottom ? moment(this.model.reportEndDateBottom).format("DD/MM/YYYY").toString() : "Please select a date";
    }

    // #region Client Actions

    @action
    public runReport = async (projectId: string): Promise<ApiResult<ProjectReportResponseDTO>> => {
        this.setIsLoading(true);
        const toDateBottom = this.model.reportEndDateBottom;
        const toDateTop = this.model.reportEndDateTop;
        const request: ProjectCostReportRequestDTO = {
            projectId: projectId,
            toDateBottom: toDateBottom,
            toDateTop: toDateTop,
        };
        let apiResult = await this.Post<ProjectReportResponseDTO>(AppUrls.Server.Report.Project.GetProjectCostReport, request);
        if (apiResult) {
            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.reportViewModel = apiResult.payload.project;
                });
            } else {
                console.log(apiResult.errors);
            }
        }
        this.setIsLoading(false);
        return apiResult;
    };

    @action
    public generateProjectCostReportCSV = async (projectId: string) => {
        this.setIsLoading(true);

        // JC: Download a CSV file using a HTTP POST request.
        // Source: https://stackoverflow.com/a/55138366

        const toDateBottom = this.model.reportEndDateBottom;
        const toDateTop = this.model.reportEndDateTop;
        const request: ProjectCostReportRequestDTO = {
            projectId: projectId,
            toDateBottom: toDateBottom,
            toDateTop: toDateTop,
        };

        await exportCSV(AppUrls.Server.Report.Project.GenerateProjectCostReportCSV, request, await this.getConfig(true, csvAxiosRequestConfig)).finally(() =>
            this.setIsLoading(false),
        );
    };

    public server: ServerViewModel = new ServerViewModel();

    @action
    public reset = () => {
        this.model.reset();
        this.server.reset();
    };

    @action
    public handleCancel = (): void => {
        getHistory().goBack();
    };

    // #endregion Client Actions

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<ProjectCostReportModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    // #region Snackbar

    @observable
    public snackbarState = false;

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public snackMessage = "";

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @observable
    public snackType = "";

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    @observable
    public SNACKSUCCESS = "success";

    @observable
    public SNACKERROR = "error";
    // #endregion

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
