import React, { useEffect, useState } from "react";
import { useObserver, useRouter } from "@shoothill/core";
import MaterialTable from "material-table";
import * as Defaults from "Globals/Defaults/TableOptions";
import { DarwinTablePageContent } from "Globals/Styles/AppStyling";
import { InvoicingListItemViewModel } from "Views/Invoices/InvoicingListItemViewModel";
import { StatusCell } from "Globals/Styles/Control/StatusCell.style";
import { AppUrls } from "AppUrls";
import { PatchedPagination } from "../../Components/Table/PatchedPagination";

interface IInvoiceTableViewProps {
    invoiceViewURL: string;
    data: InvoicingListItemViewModel[];
    showSearch: boolean;
    isLoading: boolean;
}

export const InvoiceTableView: React.FunctionComponent<IInvoiceTableViewProps> = (props: IInvoiceTableViewProps) => {
    const { history } = useRouter();
    //useEffect below only gets run on initial render
    useEffect(() => {
        return () => {};
    }, []);

    const handleRowClick = (e: any, rowData: InvoicingListItemViewModel | undefined) => {
        if (rowData) {
            const url: string = AppUrls.Client.Invoicing.Edit.replace(":invoiceid", rowData.id).replace(":approvalMode", "false");
            history.push(url);
        }
    };

    const renderPage = () => {
        return (
            <DarwinTablePageContent>
                <MaterialTable
                    columns={[
                        { title: "Invoice number", field: "invoiceNumber" },
                        { title: "Invoice date", field: "invoiceDate", type: "date", render: (rowData: InvoicingListItemViewModel) => <>{rowData.invoiceDateFormatted}</> },
                        { title: "Supplier", field: "supplierName" },
                        {
                            title: "Status",
                            field: "statusName",
                            render: (rowData: InvoicingListItemViewModel) => (
                                <StatusCell
                                    className="Unknown"
                                    style={{
                                        backgroundColor: rowData.statusColor,
                                        color: rowData.statusTextColor,
                                        margin: "3px 0",
                                        padding: "2px 0",
                                    }}
                                >
                                    {rowData.statusNameFormatted}
                                </StatusCell>
                            ),
                        },
                        {
                            title: "Value",
                            field: "invoiceValue",
                            render: (rowData: InvoicingListItemViewModel) => <>{rowData.formattedInvoiceValue}</>,
                        },
                        { title: "Approved date", field: "approvedDate", render: (rowData: InvoicingListItemViewModel) => <>{rowData.approvedDateFormatted}</> },
                        { title: "Posted Date", field: "postedDate", render: (rowData: InvoicingListItemViewModel) => <>{rowData.postedDateFormatted}</> },
                    ]}
                    components={{
                        Pagination: PatchedPagination,
                    }}
                    options={props.showSearch ? Defaults.GetDarwinTableOptions() : Defaults.GetDarwinTableOptionsNoSearch()}
                    data={props.data}
                    onRowClick={handleRowClick}
                    title=""
                    isLoading={props.isLoading}
                />
            </DarwinTablePageContent>
        );
    };

    return useObserver(() => renderPage());
};
