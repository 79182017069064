import { FieldType, isNullOrEmpty, ViewModelBase } from "@shoothill/core";
import { DeliveryScheduleModel } from "./DeliveryScheduleModel";
import { observable, action } from "mobx";

export class DeliveryScheduleViewModel extends ViewModelBase<DeliveryScheduleModel> {
    private static _instance: DeliveryScheduleViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable public errorMessage: string = "";

    public constructor() {
        super(new DeliveryScheduleModel(), false);
        this.setDecorators(DeliveryScheduleModel);
    }

    @action
    public clean = () => {
        // TODO Any Cleanup Code here. e.g. if  a user or project or client etc, wipe it from the instance on page shutdown
    };

    @action public loadModel(model: any) {
        if (model !== null) {
            this.model.fromDto(model);
        }
    }

    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (await this.isMyModelValid()) {
            //Do stuff here
            this.errorMessage = "Form is valid";
        } else {
            this.errorMessage = "Form is not valid";
        }
    };

    public async isFieldValid(fieldName: keyof FieldType<DeliveryScheduleModel>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        /*         if (fieldName === "firstName") {
            errorMessage = this.isFirstNameValid;
            isValid = errorMessage === "";
        } */

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    /*     public get isFirstNameValid(): string {
        let error = "";

        const field: string = this.getValue<string>("firstName");

        if (isNullOrEmpty(field)) {
            error = "First name is required";
        } else if (field!.length > 128) {
            error = "First name needs to be less than 128 characters";
        }

        return error;
    } */

    private isMyModelValid = async (): Promise<boolean> => {
        let isValid = true;

        // If you have a child form, you can call it's own viewmodels validation from here.
        /*if ((await this.addressFormViewModel.isModelValid()) === false) {
            isValid = false;
        }*/

        // Could also perform this in a loop if you have a list of child forms.
        /*for (let i = 0; i < this.shiftViewModels.length; i++) {
            let shift = this.shiftViewModels[i];

            if (shift.model.isEmptyForm === false) {
                if ((await shift.isModelValid()) === false) {
                    isValid = false;
                }
            }
        }*/

        // this will automatically call isFieldValid.
        if ((await this.isModelValid()) === false) {
            isValid = false;
        }
        return isValid;
    };

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
