import { Typography } from "@material-ui/core";
import { Grid, useRouter } from "@shoothill/core";
import MaterialTable from "material-table";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

import { AddButton } from "Components/Buttons/Buttons";
import { SnackBar } from "Components/SnackBar/SnackBar";
import { PatchedPagination } from "Components/Table/PatchedPagination";
import { TableListSearch } from "Components/Table/TableListSearch";
import * as Defaults from "Globals/Defaults/TableOptions";
import { DarwinPage, DarwinPageTitleSmall, DarwinTableButtonContainer, DarwinTablePageContent, DarwinTableSearchBox, DarwinTableToolbar } from "Globals/Styles/AppStyling";
import { StatusCell } from "Globals/Styles/Control/StatusCell.style";
import { DetailsHeader } from "Globals/Views/DetailsPage/DetailsHeader";
import { PlansOfWorkItemViewModel, PlansOfWorkViewModel } from ".";
import { AddPlanOfWorkView } from "../AddPlanOfWork";

export const PlansOfWorkView: React.FunctionComponent = observer(() => {
    const { match } = useRouter();
    const { projectid } = match.params as any;
    const [viewModel] = useState(() => new PlansOfWorkViewModel(projectid));
    const tableOptions = Defaults.GetDarwinTableOptions() as any;

    const onRowClick = (event?: any, rowViewModel?: PlansOfWorkItemViewModel) => {
        if (rowViewModel) {
            viewModel.navigateToOutputs(rowViewModel);
        }
    };

    return (
        <React.Fragment>
            {/* Display Add Plan of Work Modal */}
            {viewModel.canDisplayAddPlanOfWork && <AddPlanOfWorkView viewModel={viewModel.addPlanOfWorkViewModel!} />}

            <DarwinPage>
                <DetailsHeader viewModel={viewModel.general.getHeader} />
                <DarwinPageTitleSmall>
                    <Typography variant="h2" color="textPrimary" style={{ fontWeight: "600" }}>
                        Output tracker
                    </Typography>
                </DarwinPageTitleSmall>
                <DarwinTableToolbar>
                    <Grid columnGap={30} dc={"1fr 1fr"} rowGap={15} tc={"1fr 1fr"}>
                        <DarwinTableSearchBox>
                            <TableListSearch searchString={viewModel.getSearchString()} setSearchString={viewModel.setSearchString} />
                        </DarwinTableSearchBox>
                        <DarwinTableButtonContainer>
                            <AddButton color="primary" displayName="Add new stage" execute={viewModel.displayAddPlanOfWork} />
                        </DarwinTableButtonContainer>
                    </Grid>
                </DarwinTableToolbar>
                <DarwinTablePageContent>
                    <MaterialTable
                        columns={[
                            {
                                title: "RIBA stage",
                                field: "name",
                                width: "auto",
                            },
                            {
                                title: "Stage status",
                                field: "statusName",
                                cellStyle: {
                                    ...tableOptions.cellStyle,
                                    width: "250px",
                                },
                                render: (rowData: PlansOfWorkItemViewModel) => (
                                    <StatusCell
                                        className="Unknown"
                                        style={{
                                            backgroundColor: rowData.statusColor,
                                            color: rowData.statusTextColor,
                                            margin: "3px 0",
                                            padding: "2px 0",
                                        }}
                                    >
                                        {rowData.statusName.toLocaleUpperCase()}
                                    </StatusCell>
                                ),
                            },
                        ]}
                        components={{
                            Pagination: PatchedPagination,
                        }}
                        data={viewModel.filteredPlansOfWork}
                        onRowClick={onRowClick}
                        options={{ ...tableOptions, filtering: false, search: false, toolbar: false }}
                        title=""
                    />
                </DarwinTablePageContent>
                <SnackBar
                    messageText={viewModel.snackMessage}
                    messageType={viewModel.snackType}
                    active={viewModel.snackbarState}
                    closeOption={() => viewModel.setSnackbarState(false)}
                    autoHideDuration={5000}
                />
            </DarwinPage>
        </React.Fragment>
    );
});
