import { ApiResult, FieldType, ViewModelBase, isEmptyOrWhitespace, observable } from "@shoothill/core";
import {
    RFICategoryDTO,
    RFIStatusDTO,
    RFISupplierDTO,
    RFIRequestedByDTO,
    RFIRelatedResultResponseDTO,
    UpsertCategoryRFIsAndRelatedRequestDTO,
    RFICreateFormItemsModel,
    RFIProjectDetailsDTO,
} from "./RFICreateFormItemsModel";
import moment from "moment";
import { uniqueId } from "lodash-es";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { AppUrls } from "AppUrls";
import { runInAction, action, computed } from "mobx";
import { RFIDTO } from "../Edit/RFIFormModel";
import { RFIFormFileModel, RFIFileDTO } from "../Common/RFIFormFileModel";
import { RFIFormDocumentModel, RFIDocumentDTO } from "../Common/RFIFormDocumentModel";
import { RFIFormDocumentViewModel } from "../Common/RFIFormDocumentViewModel";
import { RFIFormCategoryDocumentViewModel } from "../Common/RFIFormCategoryDocumentViewModel";
import { RFIFormFileViewModel } from "../Common/RFIFormFileViewModel";
import { RFICreateFormModel } from "./RFICreateFormModel";
import { RFICreateFormItemViewModel } from "./RFICreateFormItemViewModel";

export class RFICreateFormViewModel extends ViewModelBase<RFICreateFormItemsModel> {
    constructor(projectId: string | null) {
        super(new RFICreateFormItemsModel());
        this.setDecorators(RFICreateFormItemsModel);
        this.model.projectId = projectId!;
        this.loadRelated();
    }

    public server: ServerViewModel = new ServerViewModel();

    @observable
    public snackMessage = "";

    @observable
    public snackType = "";

    @observable
    public SNACKSUCCESS = "success";

    @observable
    public SNACKERROR = "error";

    @observable
    public snackbarState = false;

    @observable
    public title: any = null;

    @observable
    public description: any = null;

    @observable
    public uniqueId: string = "";

    @observable
    public statuses = observable<RFIStatusDTO>([]);

    @observable
    public supplier: { id: string; displayName: string } | null = null;

    @observable
    public category: { id: string; displayName: string } | null = null;

    @observable
    public status: { id: string; displayName: string } | null = null;

    @observable
    public rfiTempViewModel: RFICreateFormItemViewModel | null = null;

    @observable
    public requestedBy: { id: string; displayName: string } | null = null;

    @observable
    public clientReference: { id: string; displayName: string } | null = null;

    @observable
    public suppliers = observable<RFISupplierDTO>([]);

    @observable
    public categories = observable<RFICategoryDTO>([]);

    @observable
    public requestedByUsers = observable<RFIRequestedByDTO>([]);

    @observable
    public rfiProjectDetails: RFIProjectDetailsDTO | null = null;

    @observable
    public rfiCreateFormModels: RFICreateFormModel[] = [];

    @observable
    public rfiCreateFormItemViewModels: RFICreateFormItemViewModel[] = [];

    @observable
    public rfiTempCreateFormItemViewModels: RFICreateFormItemViewModel[] = [];

    @observable
    public rfiFileViewModels: RFIFormFileViewModel[] = [];

    @observable
    public rfiCategoryDocumentViewModels: RFIFormCategoryDocumentViewModel[] = [];

    @observable
    public rfiDocumentViewModels: RFIFormDocumentViewModel[] = [];

    @observable
    public hasLoaded: boolean = false;

    @computed
    public get getHasLoaded(): boolean {
        return this.hasLoaded;
    }

    @action
    public getFormTitle(isrevision: string): string {
        return "New RFI";
    }

    @action
    public getSupplier(supplierId: string | null): string | undefined {
        return this.suppliers.find((s) => s.id === supplierId)?.displayName;
    }

    @action
    public setHasLoaded = (val: boolean) => {
        this.hasLoaded = val;
    };

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @action
    public addTempRFI = () => {
        this.uniqueId = uniqueId();
        this.rfiTempViewModel = null;
        let model = new RFICreateFormModel();
        model.uniqueId = this.uniqueId;
        this.rfiTempViewModel = new RFICreateFormItemViewModel(model);
        this.rfiTempCreateFormItemViewModels.push(this.rfiTempViewModel);
    };

    @action
    public addRFIItem = async () => {
        if ((await this.rfiTempViewModel?.isModelValid()) === true) {
            let model = new RFICreateFormModel();
            model.projectId = this.model.projectId;
            model.uniqueId = this.rfiTempViewModel?.getValue("uniqueId") ? this.rfiTempViewModel?.getValue("uniqueId") : "";
            model.rfiCategoryId = this.getValue("rfiCategoryId") ? this.getValue("rfiCategoryId") : "";
            model.title = this.rfiTempViewModel?.getValue("title") ? this.rfiTempViewModel?.getValue("title") : "";
            model.description = this.rfiTempViewModel?.getValue("description") ? this.rfiTempViewModel?.getValue("description") : "";
            model.supplierReference = this.rfiTempViewModel?.getValue("supplierReference") ? this.rfiTempViewModel?.getValue("supplierReference") : "";
            model.supplierId = this.rfiTempViewModel?.getValue("supplierId") ? this.rfiTempViewModel?.getValue("supplierId") : "";
            model.rfiStatusId = this.rfiTempViewModel?.getValue("rfiStatusId") ? this.rfiTempViewModel?.getValue("rfiStatusId") : "";
            model.requestedDate = this.rfiTempViewModel?.getValue("requestedDate") ? this.rfiTempViewModel?.getValue("requestedDate") : null;
            model.responseRequiredByDate = this.rfiTempViewModel?.getValue("responseRequiredByDate") ? this.rfiTempViewModel?.getValue("responseRequiredByDate") : null;
            runInAction(() => {
                if (this.rfiTempViewModel) {
                    this.rfiTempViewModel.rfiFileViewModels.forEach((attachment) => {
                        let attachmentToAdd = new RFIFormFileModel();
                        attachmentToAdd.uniqueId = model.uniqueId;
                        attachmentToAdd.fileName = attachment.model.fileName;
                        attachmentToAdd.fileURL = attachment.model.fileURL;
                        this.model.rfiAttachments.push(attachmentToAdd);
                        let attachmentToAddViewModel = new RFIFormFileViewModel(attachmentToAdd);
                        this.rfiFileViewModels.push(attachmentToAddViewModel);
                    });
                }
                this.model.rfiItems.push(model);
                let viewModel = new RFICreateFormItemViewModel(model);
                this.rfiCreateFormItemViewModels.push(viewModel);
                this.rfiTempCreateFormItemViewModels.pop();
                this.addTempRFI();
            });
            return true;
        }
        return false;
    };

    @action
    public deleteRFIItem(indexToDelete: number) {
        this.rfiCreateFormItemViewModels.forEach((rfi, index) => {
            if (index === indexToDelete) {
                this.rfiCreateFormItemViewModels.splice(index, 1);
            }
        });
    }

    @action
    public handleCategoryId = (item: { id: string; displayName: string }) => {
        this.category = item;
        this.setValue("rfiCategoryId", item.id);
    };

    @action
    public reset = () => {
        this.model.reset();
        this.server.reset();
    };

    @action
    public handleCancel(projectId: string | null): void {
        this.reset();
        this.history.push(AppUrls.Client.Project.RequestForInformation.replace(":projectid", projectId ? projectId : this.model.projectId));
    }

    @action
    public fileChangeForCategory = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        if (event.target.files !== null && event.target.value !== null && event.target.files.length > 0) {
            let data: any = {
                fileName: event.target.files[0].name,
                formFile: event.target.files[0],
            };
            event.target.value = "";
            const apiResult = await this.fileUpload(data);
            if (apiResult && apiResult.wasSuccessful) {
                runInAction(() => {
                    let model = new RFIFormDocumentModel();
                    model.rfiId = "";
                    model.rfiCategoryId = this.model.rfiCategoryId ? this.model.rfiCategoryId : "";
                    model.fileName = data.fileName;
                    model.fileURL = apiResult.payload;
                    model.isDeleted = false;
                    model.createdByUserId = null;
                    this.model.rfiDocuments.push(model);
                    let viewModel = new RFIFormDocumentViewModel(model);
                    this.rfiDocumentViewModels.push(viewModel);
                });
            }
        }
    };

    /**
     * Upload a file to azure.
     * @param data The data of the file to be uploaded.
     * @returns apiResult.
     */
    public fileUpload = async (data: any): Promise<ApiResult<any>> => {
        const formData = new FormData();
        formData.append("formFile", data.formFile);
        formData.append("fileName", data.fileName);
        const apiResult = await this.Post<any>(AppUrls.Server.File.UploadFile, formData);
        if (apiResult) {
            if (!apiResult.wasSuccessful) {
                console.log(apiResult.errors);
                runInAction(() => {
                    this.setSnackMessage("Error uploading file please try again.");
                    this.setSnackType(this.SNACKERROR);
                    this.setSnackbarState(true);
                });
            }
        }
        return apiResult;
    };
    /**
     * Download a file that exists in azure.
     * @param fileUrl The URL of the file to be downloaded.
     * @param fileName The name of the file to be downloaded.
     */
    public DownloadFile = async (fileUrl: string, fileName: string): Promise<void> => {
        try {
            const apiResult = await this.Post<Blob>(AppUrls.Server.File.DownloadFile, fileUrl, undefined, { responseType: "blob" });
            const response = apiResult as any;
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
        } catch (exception) {
            console.error(exception);
            this.setIsErrored(true);
        }
    };

    @action
    public deleteRFIAttachment = async (index: number): Promise<void> => {
        this.rfiFileViewModels[index].model.isDeleted = true;
    };

    @action
    public deleteRFIDocument = async (index: number): Promise<void> => {
        this.rfiDocumentViewModels[index].model.isDeleted = true;
    };

    @computed
    public get getTodayDateFormatted(): string {
        return moment().format("DD/MM/YYYY @ HH:mm").toString();
    }

    @computed
    public get getProjectReferenceFormatted(): string {
        if (!this.rfiProjectDetails) {
            return "";
        }
        return this.rfiProjectDetails.projectReference;
    }

    @computed
    public get getProjectName(): string {
        if (!this.rfiProjectDetails) {
            return "";
        }
        return this.rfiProjectDetails.projectName;
    }

    public loadRelated = async (): Promise<void> => {
        this.setIsLoading(true);
        return await this.server
            .query<RFIRelatedResultResponseDTO>(
                () => this.Get(`${AppUrls.Server.Projects.ProjectTrackers.RFI.GetRFIRelated}\\${this.model.projectId}`),
                (result) => {
                    runInAction(() => {
                        this.categories.replace(result.categories);
                        this.suppliers.replace(result.suppliers);
                        this.statuses.replace(result.statuses);
                        this.rfiProjectDetails = result.rfiProjectDetails;
                        this.addTempRFI();
                    });
                },
            )
            .finally(() => {
                this.setIsLoading(false);
                this.setHasLoaded(true);
            });
    };

    public upsert = async (): Promise<void> => {
        this.setIsLoading(true);
        let rfisToAdd: RFIDTO[] = [];
        let attachmentsToAdd: RFIFileDTO[] = [];
        let documentsToAdd: RFIDocumentDTO[] = [];

        this.rfiCreateFormItemViewModels.forEach((item) => {
            const rfiToAdd: RFIDTO = item.model.toDto();
            rfiToAdd.rfiCategoryId = this.model.rfiCategoryId;
            rfiToAdd.clientReference = 0;
            rfisToAdd.push(rfiToAdd);
        });

        this.rfiFileViewModels.forEach((attachment) => {
            const attachmentToAdd: RFIFileDTO = attachment.model.toDto();
            attachmentsToAdd.push(attachmentToAdd);
        });

        this.rfiDocumentViewModels.forEach((item) => {
            const documentToAdd: RFIDocumentDTO = item.model.toDto();
            documentToAdd.rfiId = "";
            documentToAdd.rfiCategoryId = this.model.rfiCategoryId;
            documentsToAdd.push(documentToAdd);
        });

        const request: UpsertCategoryRFIsAndRelatedRequestDTO = {
            projectId: this.model.projectId,
            rfiCategoryId: this.model.rfiCategoryId,
            rfiItems: rfisToAdd,
            rfiDocuments: documentsToAdd,
            rfiAttachments: attachmentsToAdd,
        };

        return await this.server
            .command<RFIRelatedResultResponseDTO>(
                () => this.Post(AppUrls.Server.Projects.ProjectTrackers.RFI.Create, request),
                (result: RFIRelatedResultResponseDTO) => {
                    runInAction(() => {
                        if (result) {
                            this.handleCancel(this.model.projectId);
                        }
                    });
                },
                this.isMyModelValid,
                "There was an error trying to send the Request for information",
            )
            .finally(() => this.setIsLoading(false));
    };

    private isMyModelValid = async (): Promise<boolean> => {
        let isValid = true;

        for (let i = 0; i < this.rfiCreateFormItemViewModels.length; i++) {
            let item = this.rfiCreateFormItemViewModels[i];

            // Validate each child.
            if ((await item.isModelValid()) === false) {
                isValid = false;
            }
        }

        if ((await this.isModelValid()) === false) {
            isValid = false;
        }

        return isValid;
    };

    public async isFieldValid(fieldName: keyof FieldType<RFICreateFormItemsModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);
        if (this.server.IsSubmitted) {
            switch (fieldName) {
                case "rfiCategoryId": {
                    const result = this.validateRFICategoryId;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
            }
        } else {
            errorMessage = "";
            isValid = true;
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    @computed
    private get validateRFICategoryId() {
        const errorMessage = this.model.validateRFICategoryId;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
