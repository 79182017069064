import { pxToRem } from "Globals/Styles/AppTheme";

// Default Table options
export const GetDarwinTableOptions = (): object => {
    return {
        cellStyle: {
            color: "#191919",
            fontFamily: ["Open Sans", "Roboto Condensed", "Helvetica", "Arial", "sans-serif"].join(","),
            fontSize: pxToRem(12),
            letterSpacing: pxToRem(-0.24),
            padding: `${pxToRem(6)} ${pxToRem(16)}`,
        },
        pageSize: 50,
        pageSizeOptions: [10, 20, 50, 100],
        emptyRowsWhenPaging: false,
        headerStyle: {
            backgroundColor: window.theme.palette.mediumGrey.main,
            color: "#191919",
            fontFamily: ["Open Sans", "Roboto Condensed", "Helvetica", "Arial", "sans-serif"].join(","),
            fontSize: pxToRem(12),
            fontWeight: "600",
            letterSpacing: pxToRem(-0.24),
            lineHeight: pxToRem(14),
            padding: `${pxToRem(8)} ${pxToRem(16)}`,
        },
        searchFieldAlignment: "left",
        searchFieldStyle: {
            //border: "1px solid " + window.theme.palette.primary.light,
            width: "400px",
            //marginLeft: "-35px",
            showTitle: false,
        },
        draggable: false,
    };
};

export const GetDarwinTableOptionsNoSearch = (): object => {
    return {
        cellStyle: {
            color: "#191919",
            fontFamily: ["Open Sans", "Roboto Condensed", "Helvetica", "Arial", "sans-serif"].join(","),
            fontSize: pxToRem(12),
            letterSpacing: pxToRem(-0.24),
            padding: `${pxToRem(6)} ${pxToRem(16)}`,
        },
        pageSize: 50,
        pageSizeOptions: [10, 20, 50, 100],
        emptyRowsWhenPaging: false,
        headerStyle: {
            backgroundColor: window.theme.palette.mediumGrey.main,
            color: window.theme.palette.mediumGrey.contrastText,
            fontFamily: ["Open Sans", "Roboto Condensed", "Helvetica", "Arial", "sans-serif"].join(","),
            fontSize: "12px",
            height: "40px",
            padding: "0 15px",
        },
        search: false,
        toolbar: false,
        tableLayout: "fixed",
        draggable: false,
    };
};

export const GetDarwinTableOptionsNoSearchV2 = (): object => {
    return {
        cellStyle: {
            color: "#191919",
            fontFamily: ["Open Sans", "Roboto Condensed", "Helvetica", "Arial", "sans-serif"].join(","),
            fontSize: pxToRem(12),
            letterSpacing: pxToRem(-0.24),
            padding: `${pxToRem(6)} ${pxToRem(16)}`,
        },
        pageSize: 50,
        pageSizeOptions: [10, 20, 50, 100],
        emptyRowsWhenPaging: false,
        headerStyle: {
            backgroundColor: window.theme.palette.mediumGrey.main,
            color: "#191919",
            fontFamily: ["Open Sans", "Roboto Condensed", "Helvetica", "Arial", "sans-serif"].join(","),
            fontSize: pxToRem(12),
            fontWeight: "600",
            letterSpacing: pxToRem(-0.24),
            padding: `${pxToRem(8)} ${pxToRem(16)}`,
        },
        search: false,
        toolbar: false,
        draggable: false,
    };
};

export const GetDarwinTableMaterialPriceDetailsHistory = (): object => {
    return {
        cellStyle: {
            borderRight: "1px solid #E0E0E0",
            color: "#191919",
            fontFamily: ["Open Sans", "Roboto Condensed", "Helvetica", "Arial", "sans-serif"].join(","),
            fontSize: pxToRem(12),
            letterSpacing: pxToRem(-0.24),
            lineHeight: pxToRem(14),
            padding: `${pxToRem(6)} ${pxToRem(12)}`,
        },
        emptyRowsWhenPaging: false,
        headerStyle: {
            backgroundColor: window.theme.palette.mediumGrey.main,
            color: "#191919",
            fontFamily: ["Open Sans", "Roboto Condensed", "Helvetica", "Arial", "sans-serif"].join(","),
            fontSize: pxToRem(12),
            fontWeight: "600",
            letterSpacing: pxToRem(-0.24),
            lineHeight: pxToRem(14),
            padding: `${pxToRem(8)} ${pxToRem(12)}`,
        },
        filtering: false,
        paging: false,
        search: false,
        toolbar: false,
        draggable: false,
    };
};

export const GetDarwinTablStockHistory = (): object => {
    return {
        cellStyle: {
            borderRight: "1px solid #E0E0E0",
            color: "#191919",
            fontFamily: ["Open Sans", "Roboto Condensed", "Helvetica", "Arial", "sans-serif"].join(","),
            fontSize: pxToRem(12),
            letterSpacing: pxToRem(-0.24),
            lineHeight: pxToRem(14),
            padding: `${pxToRem(6)} ${pxToRem(12)}`,
        },
        emptyRowsWhenPaging: false,
        headerStyle: {
            backgroundColor: window.theme.palette.mediumGrey.main,
            color: "#191919",
            fontFamily: ["Open Sans", "Roboto Condensed", "Helvetica", "Arial", "sans-serif"].join(","),
            fontSize: pxToRem(12),
            fontWeight: "600",
            letterSpacing: pxToRem(-0.24),
            lineHeight: pxToRem(14),
            padding: `${pxToRem(8)} ${pxToRem(12)}`,
        },
        filtering: false,
        paging: false,
        search: false,
        toolbar: false,
        draggable: false,
    };
};

export const GetDarwinTableNoPagination = (): object => {
    return {
        cellStyle: {
            color: "#191919",
            fontFamily: ["Open Sans", "Roboto Condensed", "Helvetica", "Arial", "sans-serif"].join(","),
            fontSize: pxToRem(12),
            letterSpacing: pxToRem(-0.24),
            padding: `${pxToRem(6)} ${pxToRem(16)}`,
        },
        headerStyle: {
            backgroundColor: window.theme.palette.mediumGrey.main,
            color: "#191919",
            fontFamily: ["Open Sans", "Roboto Condensed", "Helvetica", "Arial", "sans-serif"].join(","),
            fontSize: pxToRem(12),
            fontWeight: "600",
            letterSpacing: pxToRem(-0.24),
            lineHeight: pxToRem(14),
            padding: `${pxToRem(8)} ${pxToRem(16)}`,
        },
        searchFieldAlignment: "left",
        searchFieldStyle: {
            //border: "1px solid " + window.theme.palette.primary.light,
            width: "400px",
            //marginLeft: "-35px",
            showTitle: false,
        },
        paging: false,
        draggable: false,
    };
};
