import { FieldType, ViewModelBase } from "@shoothill/core";
import { ContactViewModel } from "./ContactViewModel";
import { observable, action } from "mobx";

export class ContactViewViewModel extends ViewModelBase<ContactViewModel> {
    private static _instance: ContactViewViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    public constructor() {
        super(new ContactViewModel(), false);
        this.setDecorators(ContactViewModel);
    }

    @action
    public cleanUp = () => {
        // TODO Any Cleanup Code here. e.g. if  a user or project or client etc, wipe it from the instance on page shutdown
    };

    public async isFieldValid(fieldName: keyof FieldType<ContactViewModel>): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
