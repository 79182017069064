import { FieldType, isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { action, observable, computed } from "mobx";
import type { ValidationResponse } from "@shoothill/core";

import { AppUrls } from "AppUrls";
import { FilesViewModel } from "Components/Files/Files/FilesViewModel";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { UpdateDrawingModel } from ".";
import { GenericIdWithDisplayName } from "Globals/Models/GenericIdWithDisplayName";

export class UpdateDrawingViewModel extends ViewModelBase<UpdateDrawingModel> {
    public server: ServerViewModel = new ServerViewModel();
    public filesViewModel = new FilesViewModel("image/*, application/pdf", false);

    private drawingId: string;
    private parentCloseAction: (refreshPage: boolean) => void;

    constructor(drawingId: string, closeAction: (refreshPage: boolean) => void, approverUsers: GenericIdWithDisplayName[]) {
        super(new UpdateDrawingModel());

        this.drawingId = drawingId;
        this.parentCloseAction = closeAction;
        this.approverUsers.replace(approverUsers);

        this.setDecorators(UpdateDrawingModel);

        // Load data.
        this.apiLoadWithRelatedAsync();
    }

    // #region Actions

    @action
    public cancel = () => this.parentCloseAction(false);

    // #endregion Actions

    @observable
    public approverUsers = observable<GenericIdWithDisplayName>([]);

    @computed
    public get approverUser() {
        return this.approverUsers.find((p) => p.id === this.model.approverUserId) ?? null;
    }

    @action
    public setApproverUserAsync = async (value: GenericIdWithDisplayName | null) => {
        this.model.approverUserId = value?.id ?? UpdateDrawingModel.DEFAULT_APPROVERUSERID;
    };

    // #region Api Actions

    @action
    public apiUpdateDrawingAsync = async (): Promise<void> => {
        const dto = this.model.toDto();

        const formData = new FormData();

        // A collection of drawings.
        for (const file of this.filesViewModel.model?.files) {
            if (file.file) {
                formData.append("Files", file.file);
            }
        }

        const description = dto.description ?? "";
        const approverUserId = dto.approverUserId ?? "";
        const rowVersion = dto.rowVersion ?? "";

        formData.append("Description", description);
        formData.append("ApproverUserId", approverUserId);
        formData.append("RowVersion", rowVersion);

        await this.server.commandForm<any>(
            AppUrls.Server.Projects.ProjectTrackers.OutputTracker.Drawings.Update.replace("{drawingid}", this.drawingId),
            formData,
            this.getConfig,
            (result) => this.parentCloseAction(true),
            this.isMyModelValid,
            "Error whilst saving the drawing",
        );

        if (this.server.HaveValidationMessage) {
            this.setSnackMessage(this.server.ValidationMessage);
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }
    };

    @action
    public apiLoadWithRelatedAsync = async (): Promise<void> => {
        await this.server.query<any>(
            () => this.Get(AppUrls.Server.Projects.ProjectTrackers.OutputTracker.Drawings.GetDrawing.replace("{drawingid}", this.drawingId)),
            (result) => this.model.fromDto(result),
            "Error whilst loading the output data",
        );

        if (this.server.HaveValidationMessage) {
            this.setSnackMessage(this.server.ValidationMessage);
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }
    };

    // #endregion Api Actions

    private isMyModelValid = async (): Promise<boolean> => {
        let isValid = true;

        if ((await this.filesViewModel.isFilesModelValidAsync()) === false) {
            isValid = false;
        }

        // Validate the permit model.
        if ((await this.isModelValid()) === false) {
            isValid = false;
        }

        return isValid;
    };

    // #region Snack Bar

    public SNACKERROR = "error";

    @observable
    public snackbarState = false;

    @observable
    public snackType = "";

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public snackMessage = "";

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    // #endregion Snack Bar

    @computed
    private get validateDescription(): ValidationResponse {
        const errorMessage = this.model.validateDescription;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateApproverUserId(): ValidationResponse {
        const errorMessage = this.model.validateApproverUserId;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<UpdateDrawingModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        switch (fieldName) {
            case "description": {
                const result = this.validateDescription;

                errorMessage = result.errorMessage;
                isValid = result.isValid;
                break;
            }

            case "approverUserId": {
                const result = this.validateApproverUserId;

                errorMessage = result.errorMessage;
                isValid = result.isValid;
                break;
            }
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boliderplate
}
