import { ModelBase } from "@shoothill/core";
import { observable } from "mobx";
import { nanoid } from "nanoid";

export class FileModel extends ModelBase<FileModel> {
    public KEY: string = nanoid();
    public id: string | null = null;

    @observable
    public file: File | null = null;

    @observable
    public fileName: string = "";

    @observable
    public fileSizeBytes: number = 0;

    @observable
    public fileUrl: string = "";

    @observable
    public thumbnailFileUrl: string | null = null;

    @observable
    public mimeType: string = "";

    constructor() {
        super();
    }

    // #region Helpers

    public get isImage() {
        return this.mimeType.startsWith("image") && !this.mimeType.endsWith("tiff");
    }

    public get isVideo() {
        return this.mimeType.startsWith("video");
    }

    public copy = (excludeKey: boolean = false): FileModel => {
        const model = new FileModel();

        model.KEY = excludeKey ? model.KEY : this.KEY;
        model.id = this.id;
        model.file = this.file;
        model.fileName = this.fileName;
        model.fileSizeBytes = this.fileSizeBytes;
        model.fileUrl = this.fileUrl;
        model.thumbnailFileUrl = this.thumbnailFileUrl;
        model.mimeType = this.mimeType;

        return model;
    };

    // #endregion Helpers

    fromDto(model: any): void {
        throw new Error("Method not implemented.");
    }
    toDto(model: FileModel): void {
        throw new Error("Method not implemented.");
    }
}
