// Libs
import { FieldType, isNullOrUndefined, KeyValuePair, ViewModelBase } from "@shoothill/core";
import { action } from "mobx";
import validator from "validator";

// App
import { AddressModel } from "Globals/Models/Domain";

export default class AddressViewModel extends ViewModelBase<AddressModel> {
    constructor(address: AddressModel) {
        super(address);
        this.setDecorators(AddressModel);
    }

    @action
    public cleanUp = () => {
        // TODO Any Cleanup Code here. e.g. if  a user or project or client etc, wipe it from the instance on page shutdown
        this.model.cleanUp();
    };

    @action
    public setIsDeleted = (val: boolean) => {
        this.model.isDeleted = val;
    };

    public addressViewModel: AddressViewModel | null = null;

    public async isFieldValid(fieldName: keyof FieldType<AddressModel>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        if ((fieldName as any) === "addressLine1") {
            if (validator.isEmpty(value)) {
                errorMessage = "Address line 1 is required";
                isValid = false;
            } else if (value.toString().length > 128) {
                errorMessage = "Address line 1 needs to be less than 128 characters";
                isValid = false;
            }
        }

        if ((fieldName as any) === "addressLine2") {
            if (isNullOrUndefined(value) !== false) {
                if (validator.isEmpty(value) !== true && value.toString().length > 128) {
                    errorMessage = "Address line 2 needs to be less than 128 characters";
                    isValid = false;
                }
            }
        }

        if ((fieldName as any) === "city") {
            if (value.toString().length > 128) {
                errorMessage = "City needs to be less than 128 characters";
                isValid = false;
            }
        }

        if ((fieldName as any) === "county") {
            if (value.toString().length > 128) {
                errorMessage = "County needs to be less than 128 characters";
                isValid = false;
            }
        }

        if ((fieldName as any) === "postcode") {
            if (validator.isEmpty(value)) {
                errorMessage = "Postcode is required";
                isValid = false;
            } else if (!validator.isPostalCode(value, "GB")) {
                errorMessage = "Postcode is not valid";
                isValid = false;
            }
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
