import { Box, Checkbox, FormControlLabel } from "@material-ui/core";
import { Loader, styled, useRouter } from "@shoothill/core";
import { AppUrls } from "AppUrls";
import { ITab, Tabs } from "Components/Tabs/Tabs";
import { theme } from "Globals/Styles/AppTheme";
import { StatusCell } from "Globals/Styles/Control/StatusCell.style";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { BackButtonHolder } from "Views/Project/Commercial/IETables/IEGrid.Styles";
import { CustomArrow } from "Views/Project/CustomComponents";
import { FormHeader } from "Views/PurchaseOrder/Form/Views/FormHeaders";
import { InvoiceView } from "./Details/InvoiceView";
import { InvoiceMatchView } from "./Match/InvoiceMatchView";
import { InvoiceViewModel } from "./InvoiceViewModel";
import { DashedDivider } from "Views/PurchaseOrder/Form/Views/Dividers";
import { runInAction } from "mobx";
import { NotificationBarViewModel } from "Components/NotificationBar/NotificationBarViewModel";
import { DarwinSelect } from "Components/AutoComplete/DarwinSelect";
import { ApprovalPanelInvoice } from "Components/Approval/ApprovalPanelInvoice";

interface IProps {
    className?: string;
}

export const TAB_DETAILS = "details";
export const TAB_MATCH = "match";

const InvoiceViewContainerBase: React.FC<IProps> = (props) => {
    // #region Code Behind
    const { match } = useRouter();
    const { invoiceid } = match.params as any;
    const { ieid } = match.params as any;
    const [viewModel] = useState(() => InvoiceViewModel.Instance(invoiceid, ieid));
    const { history } = useRouter();
    const [selectedTab, setSelectedTab] = React.useState(TAB_DETAILS);
    const [isApprovalMode, setIsApprovalMode] = React.useState(false);

    const tabOptions: ITab[] = [
        { key: TAB_DETAILS, title: "Details", hash: "details" },
        { key: TAB_MATCH, title: "Match", hash: "match" },
    ];

    const handleTabClick = (tab: ITab) => {
        setSelectedTab(tab.key);
    };

    useEffect(() => {
        runInAction(async () => {
            if (!InvoiceViewModel.GetInstance) {
                InvoiceViewModel.Instance(invoiceid, ieid);
            }
            await viewModel.detailsViewModel.loadData();
            await viewModel.matchViewModel.loadData();
        });
        return () => {
            // if (
            //     location.pathname !== AppUrls.Client.Invoicing.Add &&
            //     location.pathname !== AppUrls.Client.Invoicing.AddIE &&
            //     location.pathname !== AppUrls.Client.Invoicing.Edit &&
            //     location.pathname !== AppUrls.Client.Invoicing.EditIE
            // ) {
            // }
            if (InvoiceViewModel.GetInstance) {
                InvoiceViewModel.GetInstance.reset();
            }
            //viewModel.reset();
        };
    }, []);

    useEffect(() => {
        runInAction(async () => {
            if (!InvoiceViewModel.GetInstance) {
                InvoiceViewModel.Instance(invoiceid, ieid);
            }
        });
        // Check if the user has navigated from the approvals list.
        const { approvalMode } = match.params as any;
        if (approvalMode && approvalMode === "true") {
            setIsApprovalMode(true);
        } else {
            setIsApprovalMode(false);
        }

        return () => {
            NotificationBarViewModel.Instance.setIsActive(false);
            NotificationBarViewModel.Instance.model.clearModel();
            viewModel.reset();
        };
    }, []);

    const renderForm = () => {
        switch (selectedTab) {
            case TAB_DETAILS:
                return renderDetailsForm();
            case TAB_MATCH:
                return renderMatchForm();
            default:
                return renderDetailsForm();
        }
    };

    const renderDetailsForm = () => {
        return <InvoiceView viewModel={viewModel.detailsViewModel} isApprovalMode={isApprovalMode} />;
    };

    const renderMatchForm = () => {
        return <InvoiceMatchView viewModel={viewModel.matchViewModel} isApprovalMode={isApprovalMode} />;
    };

    const renderStatus = () => {
        const renderStatusCell = () => {
            return (
                <div>
                    <StatusCell
                        className="Unknown"
                        style={{
                            backgroundColor: viewModel.invoiceStatusColor,
                            color: viewModel.invoiceStatusTextColor,
                            margin: "3px 0",
                            padding: "2px 0",
                            width: 275,
                        }}
                    >
                        <div>{viewModel.invoiceStatusNameFormatted}</div>
                    </StatusCell>
                </div>
            );
        };

        const renderApprovedStatusDropdown = () => {
            return (
                <div style={{ maxWidth: "250px", width: "100%" }}>
                    <DarwinSelect
                        displayName=""
                        execute={viewModel.updateInvoiceStatus}
                        fullWidth={true}
                        getOptionLabel={(option: any) => option.displayName}
                        options={viewModel.approvedInvoiceStatusDropdownOptions}
                        placeholder="Please select"
                        value={viewModel.invoiceStatus}
                        canExecute={!viewModel.isPageLoading && viewModel.isApprovedStatus && viewModel.invoiceStatuses.length > 0 && viewModel.canSetPaidStatus}
                    />
                </div>
            );
        };

        return viewModel.isApprovedStatus ? renderApprovedStatusDropdown() : renderStatusCell();
    };

    const sendEmailToSupplierEmailCheckbox = () => {
        return (
            <>
                {viewModel.canViewSupplierEmailCheckbox && (
                    <FormControlLabel
                        control={<Checkbox checked={viewModel.detailsViewModel.model.sendEmailToSupplier} onChange={viewModel.setSendEmailToSupplier} />}
                        label={"Send email to supplier?"}
                        name={`sendEmailToSupplier-checkbox`}
                        disabled={
                            viewModel.isPageLoading ||
                            viewModel.isPaidStatus ||
                            !viewModel.detailsViewModel.hasId ||
                            !viewModel.canViewSupplierEmailCheckbox ||
                            !viewModel.getCanEditInvoice
                        }
                    />
                )}
            </>
        );
    };

    const renderPage = () => {
        return (
            <InvoicePageContainer>
                <BackButtonHolder
                    onClick={() => {
                        viewModel.goBack();
                    }}
                    style={{ minHeight: "30px", paddingTop: "3px" }}
                >
                    <div style={{ position: "relative", height: "30px", display: "flex", marginLeft: "10px" }}>
                        <div style={{ position: "absolute", top: "5px" }}>
                            <CustomArrow size={"8px"} color={theme.palette.blue.main} type={"left"} />
                        </div>
                        <div style={{ marginLeft: "20px", fontSize: "18px", fontWeight: "bold", color: theme.palette.blue.main }}>{viewModel.getBackButtonText}</div>
                    </div>
                </BackButtonHolder>
                <HeaderContainer>
                    <div>
                        <FormHeader displayName={viewModel.getPageTitle} />
                        {renderStatus()}
                    </div>
                    {viewModel.isDisputed && (
                        <DisputeReason>
                            <div>Reason for dispute</div>
                            <div>{viewModel.detailsViewModel.model.invoiceDisputedReason}</div>
                        </DisputeReason>
                    )}
                </HeaderContainer>
                {viewModel.canViewTabs && (
                    <Tabs
                        tabs={tabOptions}
                        onTabClick={handleTabClick}
                        selected={selectedTab}
                        minTabWidth={132}
                        tabPadding="11.5px 8px"
                        extraComponent={sendEmailToSupplierEmailCheckbox}
                    />
                )}
                {!viewModel.canViewTabs && <DashedDivider gutterBottom={true} borderThickness={2} borderOpacity={0.5} />}
                {viewModel.canViewTabs && <br />}
                {renderForm()}

                <ApprovalPanelInvoice
                    sideOpen={viewModel.canShowApprovalPanel}
                    primaryTitle={""}
                    secondaryTitle={""}
                    canViewFooter={false}
                    approvalHistoryInvoiceItemViewModels={viewModel.approvalPanelViewModel.approvalHistoryViewModel.approvalHistoryInvoiceItems}
                />
            </InvoicePageContainer>
        );
    };

    return useObserver(() => (viewModel ? renderPage() : <Loader />));
};

export const InvoiceViewContainer = styled(InvoiceViewContainerBase)``;

export const InvoicePageContainer = styled(Box)`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

const HeaderContainer = styled(Box)`
    > div:nth-child(1) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-right: 70px;

        // Header
        > div:nth-child(1) {
            margin: 4px 30px 13.5px 30px;
        }
    }
`;

const DisputeReason = styled.div`
    margin: 4px 30px 13.5px 30px;
`;
