import { PackageCategoryBase } from "Views/PurchaseOrder/PackageCategoryBase";
import { FieldType, KeyValuePair, observable, ViewModelBase } from "@shoothill/core";
import type { PackageCategoriesResult } from "Views/PurchaseOrder/PackageCategoriesResult";
import { REFilterParamsModel } from "./REFilterParamsModel";
import { action, computed } from "mobx";
import { OrderType, ProjectDTO, RequisitionPOStatus, UserDTO } from "Views/Approval/PurchaseListViewModel";

export class RequisitionsListFilterViewModel extends ViewModelBase<REFilterParamsModel> {
    private static _intsance: RequisitionsListFilterViewModel;
    public static get Instance() {
        return this._intsance || (this._intsance = new this());
    }

    constructor() {
        super(new REFilterParamsModel(), false);
        this.setDecorators(REFilterParamsModel);
    }

    @observable
    public readonly categoryOptions: KeyValuePair[] = [];

    @observable
    public subCategoryOptions: KeyValuePair[] = [];

    @observable
    public readonly statusOptions: KeyValuePair[] = [];

    @observable
    public readonly orderTypeOptions: KeyValuePair[] = [];

    @observable
    public readonly raisedByOptions: KeyValuePair[] = [];

    @observable
    public readonly projectOptions: KeyValuePair[] = [];

    public getStatusOptions = (includeUnKnown?: boolean): KeyValuePair[] => {
        return this.statusOptions === undefined ? [] : this.statusOptions.slice();
    };

    public getCategoryOptions = (includeDeleted: boolean): KeyValuePair[] => {
        return this.categoryOptions === undefined ? [] : this.categoryOptions.slice();
    };

    public getSubCategoryOptions = (includeDeleted: boolean): KeyValuePair[] => {
        return this.subCategoryOptions === undefined ? [] : this.subCategoryOptions.slice();
    };

    public getOrderTypeOptions = (includeUnKnown?: boolean): KeyValuePair[] => {
        return this.orderTypeOptions === undefined ? [] : this.orderTypeOptions.slice();
    };

    public getRaisedByOptions = (includeDeleted: boolean): KeyValuePair[] => {
        return this.raisedByOptions === undefined ? [] : this.raisedByOptions.slice();
    };

    public getProjectOptions = (includeUnKnown?: boolean): KeyValuePair[] => {
        return this.projectOptions === undefined ? [] : this.projectOptions.slice();
    };

    @action
    public setCategories(result: PackageCategoriesResult, resetFilters: boolean) {
        if (this.categoryOptions !== null && this.categoryOptions.length === 0) {
            if (result.categories.length > 0) {
                this.categoryOptions.push(
                    ...result.categories.map((item: PackageCategoryBase) => {
                        return { key: item.displayName, value: item.id };
                    }),
                );
            }
        }

        this.subCategoryOptions = [];
        if (result.subCategories.length > 0) {
            this.subCategoryOptions.push(
                ...result.subCategories.map((item: PackageCategoryBase) => {
                    return { key: item.displayName, value: item.id };
                }),
            );
        }

        if (resetFilters === true) {
            //this.model.setCategoriesAndSubFilters(result);
        }
    }

    @action
    public setStatuses(statusOptions: RequisitionPOStatus[], resetFilters: boolean) {
        if (this.statusOptions !== null && this.statusOptions.length === 0) {
            if (statusOptions.length > 0) {
                this.statusOptions.push(
                    ...statusOptions.map((item: RequisitionPOStatus) => {
                        return { key: item.name, value: item.id };
                    }),
                );
            }
        }
    }

    @action
    public setOrderTypes(orderTypeOptions: OrderType[], resetFilters: boolean) {
        if (this.orderTypeOptions !== null && this.orderTypeOptions.length === 0) {
            if (orderTypeOptions.length > 0) {
                this.orderTypeOptions.push(
                    ...orderTypeOptions.map((item: OrderType) => {
                        return { key: item.displayName, value: item.id };
                    }),
                );
            }
        }
    }

    @action
    public setRaisedByTypes(userOptions: UserDTO[], resetFilters: boolean) {
        if (this.raisedByOptions !== null && this.raisedByOptions.length === 0) {
            if (userOptions.length > 0) {
                this.raisedByOptions.push(
                    ...userOptions.map((item: UserDTO) => {
                        return { key: item.displayName, value: item.id };
                    }),
                );
            }
        }
    }

    @action
    public setProjects(projectOptions: ProjectDTO[], resetFilters: boolean) {
        if (this.projectOptions !== null && this.projectOptions.length === 0) {
            if (projectOptions.length > 0) {
                this.projectOptions.push(
                    ...projectOptions.map((item: ProjectDTO) => {
                        return { key: item.displayName, value: item.id };
                    }),
                );
            }
        }
    }

    public async isFieldValid(fieldName: keyof FieldType<REFilterParamsModel>): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
