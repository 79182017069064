import styled from "styled-components";
import { theme } from "../../Globals/Styles/AppTheme";

export const ContactViewWrapper = styled.div`
    position: relative;
    display: flex;
    flex: 1 0 0;
    flex-direction: column;

    /* background-color: orangered; */
    @media screen and (max-width: 1024px) {
    }

    @media screen and (max-width: 640px) {
        flex-direction: column;
    }

    border: 1px solid ${theme.palette.primary.light};
    border-radius: 3px;
    margin: 0 30px;
    padding: 15px;
`;

export const ContactViewRow = styled.div`
    position: relative;
    display: flex;
    flex: 1 0 0;
    flex-direction: row;
    width: 100%;

    // background-color: orange
    @media screen and (max-width: 1024px) {
    }

    @media screen and (max-width: 640px) {
        flex-direction: column;
    }
`;

export const ContactViewTitle = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: row;
    font-weight: 700;

    // background-color: orange
    @media screen and (max-width: 1024px) {
    }

    @media screen and (max-width: 640px) {
        flex-direction: column;
    }
`;

export const ContactViewDetails = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: row;
    font-weight: 300;
    align-items: space-around;

    // background-color: orange
    @media screen and (max-width: 1024px) {
    }

    @media screen and (max-width: 640px) {
        flex-direction: column;
    }
`;

export const EmailCol = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: row;
    width: 50px;
    vertical-align: middle;
    padding-left: 30px;

    // background-color: orange
    @media screen and (max-width: 1024px) {
    }

    @media screen and (max-width: 640px) {
        flex-direction: column;
    }
`;
