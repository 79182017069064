import * as React from "react";
import { ClientDetailViewModel } from "./ClientDetailViewModel";
import { ClientAddressBox, ClientContactBox, ClientEditBox, ModalContent } from "./ClientAddEdit.style";
import { DarwinInput } from "Globals/Styles/Control/DarwinInput";
import { useObserver } from "mobx-react-lite";
import { Typography } from "@material-ui/core";
import { ContactListView } from "Views/Contacts/ContactListView";
import { ContactType } from "Views/Contacts/ContactType";
import { SectionSplitter } from "Components/Form/SectionSplitter";
import { AddEditContactModel } from "Views/Contacts/AddEditContactModel";
import { AddEditContactView } from "Views/Contacts/AddEditContactView";
import { ClientAddEditViewModel, ClientWithRelatedDTO } from ".";
import { ApiResult, isNullOrUndefined, useEffect, useRouter } from "@shoothill/core";
import { AddressComponentView } from "Views/Address/AddressComponentView";
import { AddressModel, AddressModelDTO } from "Globals/Models/Domain";
import { runInAction } from "mobx";

interface IProps {
    viewModel: ClientAddEditViewModel;
}

export const ClientAddEditForm: React.FC<IProps> = ({ viewModel }) => {
    /*     const isInError = (fieldName: string) => {
        let isValid = viewModel.getValid(fieldName);
        return !isValid;
    }; */

    const router = useRouter();

    useEffect(() => {
        let { clientid } = router.match.params as any;

        if (isNullOrUndefined(clientid) === false) {
            let promise = viewModel.loadClientAsync(clientid);

            promise.then((result: ApiResult<ClientWithRelatedDTO>) => {
                if (result.wasSuccessful === true) {
                    runInAction(() => {
                        // Setting address here so that if there can only ever be one address, then the form pre-populates.
                        const addModel: AddressModel = viewModel.getModelAddress;
                        viewModel.addressFormViewModel.model.fromDto(addModel.toAddressDto());
                    });
                }
            });
        }

        return () => {
            viewModel.cleanUp();
        };
    }, []);

    const onUpsertContact = (contact: AddEditContactModel): any => {
        viewModel.addContact(contact);
    };

    // Needs to be this const loadContact = (id: string): Promise<ApiResult<SupplierContactDTO>> => {
    const loadContact = (id: string): any => {
        // not editing contact so redundant function
    };

    const onCleanup = () => {
        viewModel.cleanUp();
    };

    const updatContactIsPrimary = (id: string, isPrimary: boolean) => {
        viewModel.updatContactIsPrimary(id, isPrimary);
    };

    const onDeleteContact = (id: string) => {
        const promise = viewModel.deleteContact(id);

        // TODO CMR promise.then((result: ApiResult<number>) => {});
    };

    const onUpdateAddress = (address: AddressModelDTO): any => {
        viewModel.updateAddress(address);
    };

    return useObserver(() => (
        <>
            <ModalContent>
                <ClientEditBox className="clientNameAdd">
                    <DarwinInput<ClientDetailViewModel>
                        type="text"
                        label="Reference"
                        validateOnBlur={true}
                        viewModel={viewModel}
                        fieldName="reference"
                        shrink={true}
                        maxLength={30}
                    />
                    <DarwinInput<ClientDetailViewModel> type="text" label="Name" validateOnBlur={true} viewModel={viewModel} fieldName="name" shrink={true} maxLength={128} />
                </ClientEditBox>
                <ClientAddressBox className="clientAddressAdd">
                    <Typography variant="h2">Client Address</Typography>
                    <AddressComponentView onUpdateModel={onUpdateAddress} viewModel={viewModel.addressFormViewModel} isLoading={false} showAddButton={false} />
                </ClientAddressBox>
                <ClientContactBox>
                    <Typography variant="h1">Add Contact</Typography>
                    <AddEditContactView viewModel={viewModel.contactFormViewModel} onUpsertContact={onUpsertContact} isLoading={false} />
                    <SectionSplitter />
                    <ContactListView
                        contactType={ContactType.Client}
                        contactList={viewModel.model.getContacts}
                        deleteContact={onDeleteContact}
                        loadContact={loadContact}
                        upsertContact={onUpsertContact}
                        onCleanup={onCleanup}
                        updatContactIsPrimary={updatContactIsPrimary}
                        showEditColumn={true}
                    />
                </ClientContactBox>
            </ModalContent>
        </>
    ));
};
