import { action, observable, computed } from "mobx";
import { ModelBase } from "@shoothill/core";
import { validateTwoDecimalPlaces } from "Utils/Utils";

export class VariationLineModalModel extends ModelBase<VariationLineModalModel, VariationLineModalDTO> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Constants and Defaults

    public static readonly DEFAULT_DESCRIPTIONID = -2;
    public static readonly DEFAULT_DESCRIPTIONOTHER = null;
    public static readonly DEFAULT_FUTURESPEND = null;
    public static readonly DEFAULT_QUANTITY = null;
    public static readonly DEFAULT_RATE = null;
    public static readonly DEFAULT_VARIATIONUNITTYPEID = null;
    public static readonly DEFAULT_VARIATIONUNITNAME = null;
    public static readonly DEFAULT_CATEGORY = null;
    public static readonly DEFAULT_SUBCATEGORY = null;

    // #endregion Constants and Defaults

    // #region Properties

    @observable
    public descriptionId: number | null = VariationLineModalModel.DEFAULT_DESCRIPTIONID;

    @observable
    public descriptionOther: string | null = VariationLineModalModel.DEFAULT_DESCRIPTIONOTHER;

    @observable
    public rate: number | null = VariationLineModalModel.DEFAULT_RATE;

    @observable
    public variationUnitName: string | null = VariationLineModalModel.DEFAULT_VARIATIONUNITNAME;

    @observable
    public quantity: number | null = VariationLineModalModel.DEFAULT_QUANTITY;

    @observable
    public futureSpend: number | null = VariationLineModalModel.DEFAULT_FUTURESPEND;

    @observable
    public category: number | null = VariationLineModalModel.DEFAULT_CATEGORY;

    @observable
    public subCategory: number | null = VariationLineModalModel.DEFAULT_SUBCATEGORY;

    // #endregion Properties

    // #region Actions

    @action
    public reset = () => {
        this.descriptionId = VariationLineModalModel.DEFAULT_DESCRIPTIONID;
        this.descriptionOther = VariationLineModalModel.DEFAULT_DESCRIPTIONOTHER;
        this.futureSpend = VariationLineModalModel.DEFAULT_FUTURESPEND;
        this.quantity = VariationLineModalModel.DEFAULT_QUANTITY;
        this.rate = VariationLineModalModel.DEFAULT_RATE;
        this.variationUnitName = VariationLineModalModel.DEFAULT_VARIATIONUNITNAME;
        this.category = VariationLineModalModel.DEFAULT_CATEGORY;
        this.subCategory = VariationLineModalModel.DEFAULT_SUBCATEGORY;
    };

    @action
    public fromDto(dto: VariationLineModalDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in dto) {
            if (dto.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(dto[key]);
                } else {
                    this[key] = dto[key];
                }
            }
        }
    }

    public toDto(): VariationItemToAdd {
        return {
            descriptionId: this.descriptionId!,
            descriptionOther: this.descriptionOther,
            rate: this.rate,
            variationUnitName: this.variationUnitName,
            quantity: this.quantity,
            futureSpend: this.futureSpend,
            categoryId: this.category!,
            subCategoryId: this.subCategory!,
        };
    }

    // #endregion Actions

    // #region Custom Validation

    @computed
    public get validateRate(): string {
        // RULES
        if (this.rate === VariationLineModalModel.DEFAULT_RATE || isNaN(this.rate)) {
            return "Please enter a rate";
        } else if (!validateTwoDecimalPlaces(this.rate)) {
            return "No more than two decimal places";
        }

        return "";
    }

    @computed
    public get validateVariationUnitName(): string {
        // RULES
        // The variationUnitTypeName must be defined.
        return this.variationUnitName === VariationLineModalModel.DEFAULT_VARIATIONUNITNAME ? "Please enter a unit Name" : "";
    }

    @computed
    public get validateQuantity(): string {
        // RULES
        // The quantity must be defined.
        return this.quantity === VariationLineModalModel.DEFAULT_QUANTITY || isNaN(this.quantity) ? "Please enter a quantity" : "";
    }

    @computed
    public get validateFutureSpend(): string {
        // RULES
        if (this.futureSpend === VariationLineModalModel.DEFAULT_FUTURESPEND || isNaN(this.futureSpend)) {
            return "Please enter a future spend";
        } else if (!validateTwoDecimalPlaces(this.futureSpend)) {
            return "No more than two decimal places";
        }

        return "";
    }

    @computed
    public get validateCategory(): string {
        // RULES
        // The category must be defined.
        return this.category === VariationLineModalModel.DEFAULT_CATEGORY ? "Please select a category" : "";
    }

    @computed
    public get validateSubCategory(): string {
        // RULES
        // The subcategory must be defined.
        return this.subCategory === VariationLineModalModel.DEFAULT_SUBCATEGORY ? "Please select a subcategory" : "";
    }

    @computed
    public get validateDescriptionId(): string {
        // RULES
        // The descriotionId must be defined.
        //return this.descriptionId === VariationLineModalModel.DEFAULT_DESCRIPTIONID ? "Please select a description" : "";
        return "";
    }

    @computed
    public get validateDescriptionOther(): string {
        // RULES
        // The description other must be defined if the description other option is chosen.
        //if (this.descriptionId !== VariationLineModalModel.DEFAULT_DESCRIPTIONID && this.descriptionId === -2) {
        if (this.descriptionId === -2) {
            return this.descriptionOther === "" || this.descriptionOther === VariationLineModalModel.DEFAULT_DESCRIPTIONOTHER ? "Please enter other text" : "";
        } else {
            return "";
        }
    }

    // #endregion Custom Validation
}

export interface VariationLineModalDTO {
    descriptionId: number | null;
    descriptionOther: string | null;
    rate: number | null;
    variationUnitName: string | null;
    quantity: number | null;
    futureSpend: number | null;
    category: number;
    subCategory: number;
}

export interface VariationItemToAdd {
    categoryId: number;
    subCategoryId: number;
    descriptionId: number;
    descriptionOther: string | null;
    rate: number | null;
    variationUnitName: string | null;
    quantity: number | null;
    futureSpend: number | null;
}
