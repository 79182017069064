// Libs
import React from "react";
import { useObserver } from "mobx-react-lite";
import { generateID } from "@shoothill/core";

// App
import { AddressModel } from "Globals/Models/Domain";

// Styling
import DeleteLogo from "Content/Bin.svg";

interface IAddressLIProps {
    addressModel: AddressModel;
    addressRowClicked: (val: string) => any;
    addressPrimaryText?: string;
    addressDelete: (model: AddressModel, index: number) => any;
    addressDeleteText?: string;
    index: number;
}

export const AddressListItem: React.FC<IAddressLIProps> = (props) => {
    const { addressModel, addressRowClicked, addressDelete, addressDeleteText, addressPrimaryText } = props;
    const postcode: string = addressModel.getValue("postcode") as string;
    const addressInfo = [
        addressModel.getValue("addressName"),
        addressModel.getValue("addressLine1"),
        addressModel.getValue("addressLine2"),
        addressModel.getValue("addressLine3"),
        addressModel.getValue("city"),
        addressModel.getValue("county"),
        postcode?.toUpperCase(),
    ];
    return useObserver(() => (
        <>
            <li key={"key_" + generateID()} style={{ margin: "10px 0", display: "flex", justifyContent: "space-between", paddingRight: "20px" }}>
                <div className="content" onClick={() => addressRowClicked(addressModel.getValue("id"))}>
                    {addressInfo.filter((x) => x).join(", ")} {addressModel.getValue("deliveryContactName") ? `- ${addressModel.getValue("deliveryContactName")}` : ""}{" "}
                    {addressModel.getValue("deliveryContactNumber") ? `- ${addressModel.getValue("deliveryContactNumber")}` : ""}
                    {addressModel.getValue("deliveryInstructions") ? `- ${addressModel.getValue("deliveryInstructions")}` : ""}
                </div>
                {addressPrimaryText != undefined && (
                    <>
                        <div className="icon">
                            <span style={addressModel.getValue("isPrimary") ? { fontWeight: "bold" } : { fontWeight: "normal" }}>{addressPrimaryText}</span>
                        </div>
                    </>
                )}
                {addressDeleteText != undefined && (
                    <>
                        <div className="icon" onClick={() => addressDelete(addressModel, props.index)}>
                            <img src={DeleteLogo} />
                        </div>
                    </>
                )}
            </li>
        </>
    ));
};
