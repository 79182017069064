import { ModelBase } from "@shoothill/core";
import { action, observable } from "mobx";

export class UserModel extends ModelBase<UserModel, UserDTO> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Defaults and Constants

    public static readonly DEFAULT_ID = "";
    public static readonly DEFAULT_DISPLAYNAME = "";
    public static readonly DEFAULT_ROLELEVEL = 2;

    // #endregion Defaults and Constants

    // #region Observables

    @observable
    public id: string = UserModel.DEFAULT_ID;

    @observable
    public displayName: string = UserModel.DEFAULT_DISPLAYNAME;

    @observable
    public roleLevel: number = UserModel.DEFAULT_ROLELEVEL;

    // #endregion Observables

    // #region Actions

    @action
    public reset(): void {
        this.id = UserModel.DEFAULT_ID;
        this.displayName = UserModel.DEFAULT_DISPLAYNAME;
        this.roleLevel = UserModel.DEFAULT_ROLELEVEL;
    }

    @action
    public fromDto(dto: UserDTO): void {
        this.id = dto.id;
        this.displayName = dto.displayName;
        this.roleLevel = dto.roleLevel;
    }

    public toDto() {
        throw new Error("Method not implemented.");
    }

    // #endregion Actions

    // #region Helpers

    public static fromDtos(dtos: UserDTO[]): UserModel[] {
        const types: UserModel[] = [];

        for (const dto of dtos) {
            const model = new UserModel();

            model.fromDto(dto);
            types.push(model);
        }

        return types;
    }

    // #endregion Helpers
}

export interface UserDTO {
    id: string;
    displayName: string;
    roleLevel: number;
}
