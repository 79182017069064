import { ModelBase, observable } from "@shoothill/core";
import { action, computed } from "mobx";
import moment from "moment";
export class OutstandingDesignInformationModel extends ModelBase<OutstandingDesignInformationModel> {
    public static readonly DEFAULT_ID = null;
    public static readonly DEFAULT_PROJECT_ID = "";
    public static readonly DEFAULT_OUTSTANDING_DESIGN_INFORMATION_NOTE = "";
    public static readonly DEFAULT_OUTSTANDING_DESIGN_INFORMATION_PHOTOS = [];
    public static readonly DEFAULT_ASSIGN_TO_USER_ID = "";
    public static readonly DEFAULT_CREATED_BY_USER_ID = "";
    public static readonly DEFAULT_CREATED_DATE = new Date();
    public static readonly DEFAULT_IS_DELETED = false;
    public static readonly DEFAULT_LAST_UPDATED_BY_USER_ID = "";
    public static readonly DEFAULT_LAST_UPDATED_DATE = "";
    public static readonly DEFAULT_ROW_VERSION = "";

    @observable
    public id: string | null = OutstandingDesignInformationModel.DEFAULT_ID;

    @observable
    public projectId: string | null = OutstandingDesignInformationModel.DEFAULT_PROJECT_ID;

    @observable
    public outstandingDesignInformationNote: string | null = OutstandingDesignInformationModel.DEFAULT_OUTSTANDING_DESIGN_INFORMATION_NOTE;

    @observable
    public outstandingDesignInformationPhotos: OutstandingDesignInformationPhotosFile[] = OutstandingDesignInformationModel.DEFAULT_OUTSTANDING_DESIGN_INFORMATION_PHOTOS;

    @observable
    public assignToUserId: string | null = OutstandingDesignInformationModel.DEFAULT_ASSIGN_TO_USER_ID;

    @observable
    public createdByUserId: string | null = OutstandingDesignInformationModel.DEFAULT_CREATED_BY_USER_ID;

    @observable
    public createdDate: any = OutstandingDesignInformationModel.DEFAULT_CREATED_DATE;

    @observable
    public isDeleted: boolean = OutstandingDesignInformationModel.DEFAULT_IS_DELETED;

    @observable
    public lastUpdatedByUserId: string | null = OutstandingDesignInformationModel.DEFAULT_LAST_UPDATED_BY_USER_ID;

    @observable
    public lastUpdatedDate: string | null = OutstandingDesignInformationModel.DEFAULT_LAST_UPDATED_DATE;

    @observable
    public rowVersion: string | null = OutstandingDesignInformationModel.DEFAULT_ROW_VERSION;

    @computed
    public get hasId(): boolean {
        return this.id !== null && this.id !== undefined && this.id !== "";
    }

    @computed
    public get formattedLastUpdatedDate(): string {
        return moment(this.lastUpdatedDate).format("DD/MM/YYYY @ HH:mm");
    }

    @action
    public fromDto(dto: OutstandingDesignInformationDTO): void {
        for (let key in dto) {
            if (dto.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(dto[key]);
                } else {
                    this[key] = dto[key];
                }
            }
        }
    }

    @action
    public fromOutstandingDesignInformationPhotosDTO(dto: OutstandingDesignInformationPhotosFile[]): void {
        let processedPhotos: OutstandingDesignInformationPhotosFile[] = [];
        for (const photo of dto) {
            processedPhotos.push(photo);
        }
        this.outstandingDesignInformationPhotos = processedPhotos;
    }

    @action
    public toDto() {
        const OutstandingDesignInformationModel: OutstandingDesignInformationDTO = {
            id: this.id,
            projectId: this.projectId,
            outstandingDesignInformationNote: this.outstandingDesignInformationNote,
            assignToUserId: this.assignToUserId,
            createdByUserId: this.createdByUserId,
            createdDate: this.createdDate,
            isDeleted: this.isDeleted,
            lastUpdatedByUserId: this.lastUpdatedByUserId,
            lastUpdatedDate: this.lastUpdatedDate,
            rowVersion: this.rowVersion,
        };

        return OutstandingDesignInformationModel;
    }

    @action
    public reset = () => {
        this.id = OutstandingDesignInformationModel.DEFAULT_ID;
        this.projectId = OutstandingDesignInformationModel.DEFAULT_PROJECT_ID;
        this.outstandingDesignInformationNote = OutstandingDesignInformationModel.DEFAULT_OUTSTANDING_DESIGN_INFORMATION_NOTE;
        this.assignToUserId = OutstandingDesignInformationModel.DEFAULT_ASSIGN_TO_USER_ID;
        this.outstandingDesignInformationPhotos = OutstandingDesignInformationModel.DEFAULT_OUTSTANDING_DESIGN_INFORMATION_PHOTOS;
    };

    // #Start Custome Validation

    @computed
    public get validateOutstandingDesignInformationNote(): string {
        return this.outstandingDesignInformationNote === OutstandingDesignInformationModel.DEFAULT_OUTSTANDING_DESIGN_INFORMATION_NOTE ? "Please enter a note" : "";
    }

    @computed
    public get validateAssignToUserId(): string {
        return this.assignToUserId === OutstandingDesignInformationModel.DEFAULT_ASSIGN_TO_USER_ID ? "Please select a assign to" : "";
    }

    // #End Custome Validation
}

export interface OutstandingDesignInformationDTO {
    id: string | null;
    projectId: string | null;
    outstandingDesignInformationNote: string | null;
    assignToUserId: string | null;
    createdByUserId: string | null;
    createdDate: string | null;
    isDeleted: boolean;
    lastUpdatedByUserId: string | null;
    lastUpdatedDate: string | null;
    rowVersion: string | null;
}

export interface OutstandingDesignInformationPhotosFile {
    id: string | null;
    outstandingDesignInformationId: string | null;
    fileName: string;
    photoURL: string;
    createdByUserId: string | null;
    isDeleted: boolean;
    createdDate: string | null;
}

export interface AssignToUsersDTO {
    id: string;
    displayName: string;
}

export interface UpsertOutstandingDesignInformationRequestDTO {
    outstandingDesignInformation: OutstandingDesignInformationDTO;
    outstandingDesignInformationPhotos: OutstandingDesignInformationPhotosFile[];
}

export interface UpsertOutstandingDesignInformationResponseDTO {
    outstandingDesignInformation: OutstandingDesignInformationDTO;
    outstandingDesignInformationUsers: AssignToUsersDTO[];
    outstandingDesignInformationPhotos: OutstandingDesignInformationPhotosFile[];
}

export interface OutstandingDesignInformationByIdResponseDTO {
    outstandingDesignInformation: OutstandingDesignInformationDTO;
    outstandingDesignInformationList: OutstandingDesignInformationDTO[];
    outstandingDesignInformationUsers: AssignToUsersDTO[];
    outstandingDesignInformationPhotos: OutstandingDesignInformationPhotosFile[];
}

export interface OutstandingDesignInformationResponseDTO {
    outstandingDesignInformation: OutstandingDesignInformationDTO;
    outstandingDesignInformationList: OutstandingDesignInformationDTO[];
    outstandingDesignInformationUsers: AssignToUsersDTO[];
    outstandingDesignInformationPhotos: OutstandingDesignInformationPhotosFile[];
}

export interface FormRequestDTO {
    date: string | null;
    projectId: string | null;
    formId: string | null;
}
