import { ModelBase } from "@shoothill/core";
import { action, observable } from "mobx";

export class ContactTypeModel extends ModelBase<ContactTypeModel, ContactTypeDTO> {
    public static readonly DEFAULT_ID = "";
    public static readonly DEFAULT_DISPLAYNAME = "";

    @observable
    public id: string = ContactTypeModel.DEFAULT_ID;

    @observable
    public displayName: string = ContactTypeModel.DEFAULT_DISPLAYNAME;

    @action
    public reset(): void {
        this.id = ContactTypeModel.DEFAULT_ID;
        this.displayName = ContactTypeModel.DEFAULT_DISPLAYNAME;
    }

    @action
    public fromDto(dto: ContactTypeDTO): void {
        this.id = dto.id;
        this.displayName = dto.displayName;
    }

    public toDto() {
        throw new Error("Method not implemented.");
    }

    public static fromDtos(dtos: ContactTypeDTO[]): ContactTypeModel[] {
        const types: ContactTypeModel[] = [];
        for (const dto of dtos) {
            const model = new ContactTypeModel();
            model.fromDto(dto);
            types.push(model);
        }

        return types;
    }
}

export interface ContactTypeDTO {
    id: string;
    displayName: string;
}
