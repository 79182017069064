import * as MobX from "mobx";
import { ModelBase } from "@shoothill/core";
import { IEGridSubcategoryModel, IEGridSubcategoryModelDTO } from "./IEGridSubcategoryModel";

export class IEGridCategoryModel extends ModelBase<IEGridCategoryModel, IEGridCategoryModelDTO> {
    public id: string = "";
    public createdDate: string = "";
    public sourceId: number = 0;
    public parentId: string | null = null;

    @MobX.observable
    public name: string = "";

    @MobX.observable
    public income: number = 0;

    @MobX.observable
    public estimateCost: number = 0;

    @MobX.observable
    public estimatedMargin: number = 0;

    @MobX.observable
    public futureSpend: number = 0;

    @MobX.observable
    public targetCost: number = 0;

    @MobX.observable
    public committedCost: number = 0;

    @MobX.observable
    public totalExpectedSpend: number = 0;

    @MobX.observable
    public variance: number = 0;

    @MobX.observable
    public changePeriod: number = 0;

    @MobX.observable
    public margin: number = 0;

    @MobX.observable
    public roValue: number = 0;

    @MobX.observable
    public subcategories: IEGridSubcategoryModel[] = [];

    fromDto(model: IEGridCategoryModelDTO): void {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else if (key === "subcategories") {
                    let items: IEGridSubcategoryModel[] = [];
                    model[key].forEach((element) => {
                        let newModel = new IEGridSubcategoryModel();
                        newModel.fromDto(element);
                        items.push(newModel);
                    });
                    this.subcategories = items;
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    toDto(model: IEGridCategoryModel): void {}
}

export type IEGridCategoryModelDTO = {
    id: string;
    name: string;
    sourceId: number;
    createdDate: string;
    income: number;
    estimateCost: number;
    estimatedMargin: number;
    futureSpend: number;
    targetCost: number;
    committedCost: number;
    totalExpectedSpend: number;
    variance: number;
    changePeriod: number;
    margin: number;
    roValue: number;
    subcategories: IEGridSubcategoryModelDTO[];
};
