import { Box, Checkbox, CircularProgress, FormControlLabel, IconButton, TextField, Typography } from "@material-ui/core";
import { Cell, Grid, Loader } from "@shoothill/core";
import { useObserver } from "mobx-react-lite";
import React, { useState } from "react";
import styled from "styled-components";
import { Form, FormSection } from "Views/PurchaseOrder/Form/Views/Forms";
import { SolidDivider } from "Views/PurchaseOrder/Form/Views/Dividers";
import { DarwinDateSelect } from "Components/DateSelect/DarwinDateSelect";
import { DarwinSelect } from "Components/AutoComplete/DarwinSelect";
import { DefaultButton, PrimaryButton } from "Components/Buttons/Buttons";
import SystemUpdateTwoToneIcon from "@material-ui/icons/SystemUpdateTwoTone";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { Uploader } from "Components/Uploader/Uploader";
import { InvoiceDocumentDTO, InvoiceDetailsModel } from "./InvoiceDetailsModel";
import { SupplierDetailView } from "Views/PurchaseOrder/Form/Supporting/SupplierDetailsView";
import { Alert, AlertTitle, Autocomplete, AutocompleteRenderInputParams } from "@material-ui/lab";
import DeleteLogo from "Content/Bin.svg";
import SearchIcon from "@material-ui/icons/Search";
import { InvoiceDetailsViewModel } from "./InvoiceDetailsViewModel";
import { DarwinInputWrapper } from "Components/Form/DarwinInputWrapper";
import { InvoiceProjectModel } from "./Supporting/InvoiceProjectModel";
import { InvoiceDetailsPostModal } from "./InvoiceDetailsPostModal";
import { SupplierModel } from "Views/PurchaseOrder/Form/Supporting/SupplierModel";
import moment from "moment";

interface IProps {
    className?: string;
    viewModel: InvoiceDetailsViewModel;
    isApprovalMode: boolean;
}

const InvoiceViewBase: React.FC<IProps> = (props: IProps) => {
    const { viewModel } = props;
    const [isDraft, setIsDraft] = useState<boolean | null>(null);
    const [resetForm, setResetForm] = useState<boolean | null>(null);

    const COLUMNS8 = "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr";

    const onSubmit = (event: any) => {
        event.preventDefault();
    };

    const handleDeleteProject = (e: React.MouseEvent<HTMLImageElement, MouseEvent>, index: number) => {
        e.stopPropagation();
        if (!viewModel.isFormDisabled) {
            viewModel.handleDeleteInvoiceProject(index);
        }
    };

    const handleShowPostModalChange = (val: boolean) => {
        viewModel.handleShowPostModalChange(val);
        setResetForm(null);
    };

    const handleSaveClick = async (isDraftClick: boolean, resetForm: boolean) => {
        const handleSave: (reset: boolean) => Promise<void> = isDraftClick ? viewModel.handleSaveAsDraft : viewModel.handlePost;

        const invoiceValueMatches: boolean = viewModel.invoiceValueMatches;

        if (invoiceValueMatches) {
            await handleSave(resetForm);
        } else {
            setResetForm(resetForm);
            setIsDraft(isDraftClick);
            viewModel.handleShowPostModalChange(true);
        }
    };

    const handleSave = async (resetForm: boolean) => {
        const handleSave: (reset: boolean) => Promise<void> = isDraft ? viewModel.handleSaveAsDraft : viewModel.handlePost;

        await handleSave(resetForm).finally(() => {
            setIsDraft(null);
            setResetForm(null);
            viewModel.handleShowPostModalChange(false);
        });
    };

    const handleSaveInvoiceDate = async () => {
        await viewModel.updateInvoiceDate();
    };

    const handleSaveInvoiceAttachments = async () => {
        await viewModel.updateInvoiceAttachments();
    };

    const handleSetSupplier = async (value: SupplierModel | null) => {
        viewModel.setSupplier(value);

        if (value) {
            handleSetDueDate();
        }
    };

    const handleSetInvoiceDate = (value: string | null) => {
        viewModel.setInvoiceDate(value);

        if (value && value !== "" && viewModel.model.supplierId && viewModel.model.supplierId !== "") {
            handleSetDueDate();
        }
    };

    const handleSetDueDate = () => {
        const supplier = viewModel.suppliers.find((sd) => sd.id === viewModel.model.supplierId);

        if (supplier && viewModel.model.invoiceDate && viewModel.model.invoiceDate !== "") {
            if (supplier.paymentTermsName !== "" && supplier.paymentTermsName === "From end of month (60 days)") {
                const invoiceDate = moment(viewModel.model.invoiceDate);

                const dueDate = invoiceDate.add(2, "month").endOf("month");

                viewModel.setDueDate(dueDate.toISOString());
            } else if (supplier.paymentTermsName !== "" && supplier.paymentTermsName === "From end of month (30 days)") {
                const invoiceDate = moment(viewModel.model.invoiceDate);

                const dueDate = invoiceDate.add(1, "month").endOf("month");

                viewModel.setDueDate(dueDate.toISOString());
            } else {
                const invoiceDate = moment(viewModel.model.invoiceDate);
                const paymentTermDays = supplier.paymentTermsInDays;

                const dueDate = invoiceDate.add(paymentTermDays, "days");

                viewModel.setDueDate(dueDate.toISOString());
            }
        }
    };

    const renderPage = () => {
        return (
            <Form className={props.className} onSubmit={onSubmit}>
                <FormSection>
                    <Typography variant="h2" color="textPrimary">
                        Invoice details
                    </Typography>
                    <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
                        {/* Group of 8 columns */}
                        <Cell ts={2} ds={2} className={`${viewModel.isFormDisabled ? "form-control-disabled" : ""}`}>
                            <DarwinInputWrapper<InvoiceDetailsModel>
                                type="text"
                                label="Invoice number:*"
                                placeHolder="INV DRV2345"
                                validateOnBlur={true}
                                viewModel={viewModel}
                                fieldName="invoiceNumber"
                                shrink={true}
                                maxLength={256}
                                editMode={!viewModel.isFormDisabled}
                            />
                        </Cell>

                        <Cell ts={1} ds={1}>
                            <DarwinDateSelect
                                displayName="Invoice date:*"
                                execute={(value: string | null) => handleSetInvoiceDate(value)}
                                onBlur={() => viewModel.isFieldValid("invoiceDate", true)}
                                placeholder="Please select"
                                validationMessage={viewModel.getError("invoiceDate")}
                                value={viewModel.getValue("invoiceDate")}
                                canExecute={viewModel.canEditInvoiceDate}
                            />
                        </Cell>
                        <Cell ts={1} ds={1}>
                            <DarwinDateSelect
                                displayName="Due date:*"
                                execute={(value: string | null) => viewModel.setDueDate(value)}
                                onBlur={() => viewModel.isFieldValid("dueDate", true)}
                                placeholder="Please select"
                                validationMessage={viewModel.getError("dueDate")}
                                value={viewModel.getValue("dueDate")}
                                canExecute={viewModel.canEditInvoiceDate}
                            />
                        </Cell>

                        {viewModel.canEditInvoiceDate && viewModel.isFormDisabled && viewModel.hasInvoiceDateChanged && (
                            <Cell ts={1} ds={1} style={{ display: "flex", alignItems: "flex-end" }}>
                                <PrimaryButton
                                    displayName="Save date"
                                    execute={() => handleSaveInvoiceDate()}
                                    fullWidth={true}
                                    canExecute={viewModel.canEditInvoiceDate && viewModel.isFormDisabled && viewModel.hasInvoiceDateChanged}
                                />
                            </Cell>
                        )}

                        <Cell ts={2} ds={2} className={`${viewModel.isFormDisabled ? "form-control-disabled" : ""}`}>
                            <DarwinInputWrapper<InvoiceDetailsModel>
                                type="number"
                                label="Invoice value:*"
                                placeHolder="0.00"
                                validateOnBlur={true}
                                viewModel={viewModel}
                                fieldName="invoiceValue"
                                shrink={true}
                                maxLength={256}
                                editMode={!viewModel.isFormDisabled}
                            />
                        </Cell>

                        <Cell ts={2} ds={2} style={{ display: "flex", alignItems: "flex-end" }}>
                            <FormControlLabel
                                control={<Checkbox checked={viewModel.model.isUpfrontPayment} onChange={viewModel.setIsUpfrontPayment} />}
                                label={"Upfront cost?"}
                                name={`upfrontCost-checkbox`}
                                disabled={viewModel.isFormDisabled}
                            />
                        </Cell>

                        {!(viewModel.canEditInvoiceDate && viewModel.isFormDisabled && viewModel.hasInvoiceDateChanged) && (
                            <Cell display={{ xs: "none", md: "block" }} ts={1} ds={1} />
                        )}
                        {viewModel.canEditInvoiceDate && viewModel.isFormDisabled && viewModel.hasInvoiceDateChanged && (
                            <Cell display={{ xs: "none", md: "block" }} ts={2} ds={2} />
                        )}

                        {/* Group of 8 columns */}
                        <Cell ts={8} ds={8}>
                            <Uploader
                                handleSelectFile={(e: React.ChangeEvent<HTMLInputElement>) => viewModel.fileChange(e)}
                                isFormDisabled={!viewModel.canSaveAttachments}
                                label="Invoice attachments"
                            />

                            <div className="uploadedFile">
                                {viewModel.invoiceDocuments.map((item: InvoiceDocumentDTO, index: number) => {
                                    return (
                                        <div className="uploadedFile-box">
                                            <div className="uploadedFile-heading">
                                                <IconButton onClick={() => viewModel.DownloadFile(item.url, item.fileName)}>
                                                    <SystemUpdateTwoToneIcon />
                                                </IconButton>
                                                <h3>{item.fileName}</h3>
                                            </div>
                                            {((item.id !== null && item.id.length < 10) || item.canDeleteDocument) && (
                                                <IconButton onClick={() => viewModel.handleDeleteDocument(item.id)} disabled={!item.canDeleteDocument}>
                                                    <DeleteOutlinedIcon />
                                                </IconButton>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        </Cell>
                        {viewModel.canSaveAttachments && viewModel.hasInvoiceAttachmentsChanged && (
                            <Cell ts={1} ds={1} style={{ display: "flex", alignItems: "flex-end" }}>
                                <PrimaryButton
                                    displayName="Save attachments"
                                    execute={() => handleSaveInvoiceAttachments()}
                                    fullWidth={true}
                                    canExecute={viewModel.canSaveAttachments && viewModel.hasInvoiceAttachmentsChanged && !viewModel.IsLoading}
                                />
                            </Cell>
                        )}
                    </Grid>
                </FormSection>

                <FormSection>
                    <SolidDivider gutterBottom={true} borderThickness={2} borderOpacity={0.5} />

                    <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
                        <Cell ts={4} ds={4}>
                            <DarwinSelect
                                displayName="Select supplier:*"
                                execute={handleSetSupplier}
                                fullWidth={true}
                                getOptionLabel={(option: any) => option.displayName}
                                options={viewModel.suppliers}
                                placeholder="Please select"
                                validationMessage={viewModel.getError("supplierId")}
                                value={viewModel.supplier}
                                canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading && !viewModel.hasInvoiceProjects}
                            />
                        </Cell>
                        <Cell display={{ xs: "none", md: "block" }} ts={4} ds={4} />

                        {viewModel.canDisplaySupplierDetails && (
                            <>
                                {/* Group of 8 columns */}
                                <Cell ts={4} ds={4}>
                                    <SupplierDetailView supplierDetails={viewModel.supplierDetailsForSupplier!} />
                                </Cell>
                                <Cell display={{ xs: "none", md: "block" }} ts={4} ds={4} />
                            </>
                        )}
                    </Grid>
                </FormSection>

                <FormSection>
                    <SolidDivider gutterBottom={true} borderThickness={2} borderOpacity={0.5} />

                    <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
                        <Cell ts={4} ds={4}>
                            <Autocomplete
                                key={"po-autocomplete"}
                                onChange={viewModel.handleAddProject}
                                onInputChange={viewModel.handleSearchProject}
                                options={viewModel.projectOptions}
                                getOptionLabel={(option) => option.value.toString()}
                                getOptionDisabled={(option) => viewModel.isProjectSelected(option)}
                                filterOptions={(x) => x} //  Disable the build in filtering.
                                value={viewModel.selectedProject}
                                inputValue={viewModel.searchText}
                                loading={viewModel.IsLoading}
                                clearOnBlur={false}
                                disableClearable={false}
                                disabled={viewModel.isFormDisabled || viewModel.isProjectDropdownDisabled}
                                renderInput={(params: AutocompleteRenderInputParams) => (
                                    <TextField
                                        {...params}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {viewModel.IsLoading ? <CircularProgress color="inherit" size={20} /> : <SearchIcon />}
                                                    {params.InputProps.endAdornment}
                                                </>
                                            ),
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        label="Associated Project / PO number / central spend line"
                                        placeholder={"Please select"}
                                        disabled={viewModel.isFormDisabled || viewModel.isProjectDropdownDisabled}
                                    />
                                )}
                            />
                        </Cell>
                        <Cell display={{ xs: "none", md: "block" }} ts={4} ds={4} />
                        <Cell ts={8} ds={8}>
                            <POList>
                                <div className="po-table-header">
                                    <div className="po-table-cell">Project / Central spend line</div>
                                    <div className="po-table-cell">Invoice value</div>
                                    <div className="po-table-cell"></div>
                                </div>
                                <div className="po-table-body">
                                    {viewModel.getInvoiceProjects.map((item, index) => {
                                        return (
                                            !item.model.isDeleted && (
                                                <div className="po-table-item">
                                                    <div className="po-table-cell">{item.projectDescriptionFormatted}</div>
                                                    <div className="po-table-cell">
                                                        <DarwinInputWrapper<InvoiceProjectModel>
                                                            type="number"
                                                            label=""
                                                            placeHolder="0.00"
                                                            validateOnBlur={true}
                                                            viewModel={item}
                                                            fieldName="invoiceValue"
                                                            shrink={true}
                                                            maxLength={256}
                                                            editMode={!viewModel.isFormDisabled}
                                                            hiddenLabel={false}
                                                            onChange={() => viewModel.updateVAT()}
                                                        />
                                                    </div>
                                                    <div className="po-table-cell">
                                                        {!viewModel.isFormDisabled && <img src={DeleteLogo} onClick={(e) => handleDeleteProject(e, index)} />}
                                                    </div>
                                                </div>
                                            )
                                        );
                                    })}
                                </div>
                            </POList>
                        </Cell>
                    </Grid>
                </FormSection>

                <FormSection>
                    <SolidDivider gutterBottom={true} borderThickness={2} borderOpacity={0.5} />

                    <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
                        {/* Group of 8 columns */}
                        <Cell display={{ xs: "none", md: "block" }} ts={6} ds={6} />
                        <Cell ts={2} ds={2}>
                            <SummaryCalculations>
                                <div className="invoice-value-summary">
                                    <div className="invoice-value-summary-row">
                                        <span>Net:</span> <div>{viewModel.getNetValueFormatted}</div>
                                    </div>
                                    <div className="invoice-value-summary-row">
                                        <span>Tax:</span>
                                        <div>
                                            <DarwinInputWrapper<InvoiceDetailsModel>
                                                type="number"
                                                label=""
                                                placeHolder="0.00"
                                                validateOnBlur={true}
                                                viewModel={viewModel}
                                                fieldName="invoiceTax"
                                                shrink={true}
                                                maxLength={256}
                                                editMode={!viewModel.isFormDisabled}
                                                hiddenLabel={false}
                                            />
                                        </div>
                                    </div>
                                    <div className="invoice-value-summary-row">
                                        <span>Total:</span> <div>{viewModel.getTotalValueFormatted}</div>
                                    </div>
                                </div>
                            </SummaryCalculations>
                        </Cell>
                    </Grid>
                </FormSection>

                <FormSection>
                    <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
                        {viewModel!.server.HaveValidationMessage && (
                            <>
                                <Cell ts={8} ds={8}>
                                    <Alert severity="error">
                                        <AlertTitle>Error</AlertTitle>
                                        {viewModel!.server.ValidationMessage}
                                    </Alert>
                                </Cell>
                            </>
                        )}
                    </Grid>
                </FormSection>

                <FormSection>
                    <SolidDivider gutterBottom={true} borderThickness={2} borderOpacity={0.5} />

                    <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
                        {/* Group of 8 columns */}
                        <Cell ts={2} ds={2}>
                            <PrimaryButton
                                displayName="Post"
                                execute={() => handleSaveClick(false, false)}
                                fullWidth={true}
                                canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading && viewModel.canPost}
                            />
                        </Cell>
                        <Cell ts={2} ds={2}>
                            <PrimaryButton
                                displayName="Post and create new invoice"
                                execute={() => handleSaveClick(false, true)}
                                fullWidth={true}
                                canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading && viewModel.canPost}
                            />
                        </Cell>
                        <Cell ts={2} ds={2}>
                            <PrimaryButton
                                displayName="Save as draft"
                                execute={() => handleSaveClick(true, false)}
                                fullWidth={true}
                                canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading && viewModel.canSaveAsDraft}
                            />
                        </Cell>
                        <Cell ts={2} ds={2}>
                            <DefaultButton displayName="Cancel" execute={viewModel.handleCancel} fullWidth={true} canExecute={!viewModel.IsLoading} />
                        </Cell>
                    </Grid>
                </FormSection>

                <InvoiceDetailsPostModal
                    onClose={() => handleShowPostModalChange(false)}
                    open={viewModel.getShowPostModal}
                    title="Are you sure?"
                    confirmText="Proceed"
                    onSave={() => handleSave(resetForm!)}
                    isLoading={viewModel.IsLoading}
                />
            </Form>
        );
    };

    return useObserver(() => (viewModel.hasLoaded ? renderPage() : <Loader />));
};

export const InvoiceView = styled(InvoiceViewBase)`
    form {
        position: relative;
    }
`;

export const DarwinDateSelectCustom = styled(DarwinDateSelect)`
    label {
        white-space: nowrap;
    }
`;

export const POList = styled(Box)`
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: bold;

    .po-table-header {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding: 12px 30px;
        background-color: #ced4da;
    }

    .po-table-body {
        display: flex;
        flex-direction: column;
        padding: 10px 30px;

        > div.po-table-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            padding: 10px 0px;

            img {
                cursor: pointer;
            }

            .po-table-cell:nth-child(6) {
                text-align: right;
            }
        }
    }

    .po-table-cell:nth-child(1) {
        width: 60%;
    }

    .po-table-cell:nth-child(2) {
        width: 30%;
    }

    .po-table-cell:nth-child(3) {
        width: 10%;
        text-align: right;
    }

    .po-table-cell:nth-child(4) {
        width: 15%;
    }

    .po-table-cell:nth-child(5) {
        width: 10%;
    }

    .po-table-cell:nth-child(6) {
        width: 5%;
    }
`;

export const SummaryCalculations = styled(Box)`
    > .invoice-value-summary {
        display: flex;
        flex-direction: column;
        max-width: 214px;

        > .invoice-value-summary-row {
            display: flex;
            flex-direction: row;
            font-weight: bold;
            margin: 5px 0px;

            > span {
                margin-right: 10px;
                width: 40px;
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
            }

            > div {
                display: flex;
                justify-content: flex-end;
                flex: 1 0 auto;

                input {
                    text-align: right;
                }
            }
        }

        > .invoice-value-summary-row:nth-child(1) {
            color: #7f7f7f;

            > span {
                color: #191919;
            }
        }
    }
`;
