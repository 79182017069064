import { ModelBase } from "@shoothill/core";
import { IsNotEmpty } from "class-validator";
import { computed, observable } from "mobx";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class DetailsHeaderModel extends ModelBase<DetailsHeaderModel, DetailsHeaderModelDTO> {
    // DarwinModelBase
    // public id: string = "";
    // public createdDate: string = "";

    @observable
    public title: string = "";

    @observable
    public address: string = "";

    @observable
    public status: string = "";

    @observable
    public statusClass: string = "";

    @observable
    public subTitle: string = "";

    @computed get getTitle() {
        return this.title;
    }

    @computed get getAddress() {
        return this.address;
    }

    @computed get getStatus() {
        return this.status;
    }

    @computed get getStatusClass() {
        return this.statusClass;
    }

    @computed get getSubTitle() {
        return this.subTitle;
    }

    //fromDto is required but you can leave it blank
    fromDto(model: DetailsHeaderModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: DetailsHeaderModel): void {}
}

export type DetailsHeaderModelDTO = {
    title: string;
    address?: string;
    status?: string;
    statusClass: string;
};
