import React from "react";
import styled from "styled-components";

export const FilterWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px 5px 15px 5px;
`;

export const FilterRow = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 8px 0;
    align-items: flex-start;
`;

export const SearchBarContainer = styled.div`
    max-width: 400px;
`;
