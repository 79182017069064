import { isEmptyOrWhitespace } from "@shoothill/core";
import { action, computed, observable } from "mobx";

export class ClientViewModel {
    // #region Defaults

    public static readonly DEFAULT_ISBUSY = false;
    public static readonly DEFAULT_ISREQUESTSUCCESSFUL = false;
    public static readonly DEFAULT_ISSUBMITTED = false;
    public static readonly DEFAULT_VALIDATIONMESSAGE = "";

    // #endregion Defaults

    // #region Reset

    @action
    public reset = (): void => {
        this.resetIsBusy();
        this.resetIsSubmitted();
        this.resetRequestSuccessful();
        this.resetValidationMessage();
    };

    // #endrgion Reset

    // #region Busy

    @observable
    private isBusy = ClientViewModel.DEFAULT_ISBUSY;

    @computed
    public get IsBusy(): boolean {
        return this.isBusy;
    }

    @action
    public resetIsBusy = (): void => {
        this.isBusy = ClientViewModel.DEFAULT_ISBUSY;
    };

    @action
    public setIsBusy = (value: boolean): void => {
        this.isBusy = value;
    };

    // #endregion Busy

    // #region Error

    @observable
    private validationMessage = ClientViewModel.DEFAULT_VALIDATIONMESSAGE;

    @computed
    public get ValidationMessage(): string {
        return this.validationMessage;
    }

    @action
    public resetValidationMessage = (): void => {
        this.validationMessage = ClientViewModel.DEFAULT_VALIDATIONMESSAGE;
    };

    @computed
    public get HaveValidationMessage(): boolean {
        return !isEmptyOrWhitespace(this.validationMessage);
    }

    @action
    public setValidationMessage = (value: string): void => {
        this.validationMessage = value;
    };

    // #endregion Error

    // #region Submitted

    @observable
    private isSubmitted = ClientViewModel.DEFAULT_ISSUBMITTED;

    @computed
    public get IsSubmitted(): boolean {
        return this.isSubmitted;
    }

    @action
    public resetIsSubmitted = (): void => {
        this.isSubmitted = ClientViewModel.DEFAULT_ISSUBMITTED;
    };

    @action
    public setIsSubmitted = (value: boolean): void => {
        this.isSubmitted = value;
    };

    // #endregion Submitted

    // #region Request Succeeded

    @observable
    private isRequestSuccessful = ClientViewModel.DEFAULT_ISREQUESTSUCCESSFUL;

    @computed
    public get IsRequestSuccessful(): boolean {
        return this.isRequestSuccessful;
    }

    @action
    public resetRequestSuccessful = (): void => {
        this.isRequestSuccessful = ClientViewModel.DEFAULT_ISREQUESTSUCCESSFUL;
    };

    @action
    public setRequestSuccessful = (value: boolean): void => {
        this.isRequestSuccessful = value;
    };

    // #endregion Request Succeeded

    public command = async <bool>(commandAction: () => bool, result: () => void, isModelValid: () => Promise<boolean>, errorMessage: string = ""): Promise<void> => {
        const DEFAULT_CLIENTVALIDATIONMESSAGE = isEmptyOrWhitespace(errorMessage) ? "There was an error trying to process the request." : errorMessage;

        try {
            this.reset();
            this.setIsSubmitted(true);

            if (await isModelValid()) {
                this.setIsBusy(true);

                const commandResult = commandAction();

                if (commandResult) {
                    result();
                    this.setRequestSuccessful(true);
                } else {
                    this.setValidationMessage(DEFAULT_CLIENTVALIDATIONMESSAGE);
                }
            }
        } catch (exception) {
            this.setValidationMessage(DEFAULT_CLIENTVALIDATIONMESSAGE);
        } finally {
            this.resetIsBusy();
        }
    };
}
