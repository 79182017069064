/* eslint-disable prettier/prettier */
import React, { useState } from "react";
import { Box, Typography,  } from "@material-ui/core";
import { ClickableCell, CellImgText} from "./ClickableImgCell.style";

export interface IClickableImgCellProps {
    id: string;
    logo: any;
    text: string;
    className? : string;
    showText?: boolean;
    onClick: (id: string) => any;
}

export const ClickableImgCell: React.FC<IClickableImgCellProps> = (props: IClickableImgCellProps) => {

    const cn: string = props.className !== undefined && props.className !== null ? props.className! : "";

    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        props.onClick(props.id);
    }

    const showItemText: boolean = props.showText === undefined ? true :  props.showText;

    return (
            <>
                <ClickableCell className={cn} onClick={handleClick}>
                    <img src={props.logo}/>
                    {showItemText === true && 
                        <Box><CellImgText>{props.text}</CellImgText></Box>
                    }
                </ClickableCell> 
            </>

    );
};
