import { Box, FormControlLabel, FormHelperText, FormLabel, IconButton, TextField, Typography } from "@material-ui/core";
import { ApiResult, Cell, Grid, isEmptyOrWhitespace, isNullOrEmpty, useRouter } from "@shoothill/core";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Form, FormSection } from "Views/PurchaseOrder/Form/Views/Forms";
import { formatDateTime } from "../../../../../Utils/Format";
import { FormHeader } from "Views/PurchaseOrder/Form/Views/FormHeaders";
import { ProjectGeneralViewModel } from "../../../General/ProjectGeneralViewModel";
import { DashedDivider, SolidDivider } from "Views/PurchaseOrder/Form/Views/Dividers";
import { pxToRem, theme } from "Globals/Styles/AppTheme";
import { CustomRadio, CustomArrow } from "Views/Project/CustomComponents";
import { DefaultButton, PrimaryButton } from "Components/Buttons/Buttons";
import { DarwinSelect } from "Components/AutoComplete/DarwinSelect";
import { DarwinDateSelect } from "Components/DateSelect/DarwinDateSelect";
import { BackButtonHolder } from "Views/Project/Commercial/VariationTables/VariationGrid.Styles";
import { AppUrls } from "AppUrls";
import { formatDate, formatTime } from "Utils/Format";
import { Uploader } from "Components/Uploader/Uploader";
import { CustomHr } from "../../../CustomComponents";
import { DesignDataFormViewModel } from "./DesignDataFormViewModel";
import { DesignDataItemDTO } from "./DesignDataFormItemModel";
import { DesignDataFormItemViewModel } from "./DesignDataFormItemViewModel";
import { DesignDataFormFileViewModel } from "./DesignDataFormFileViewModel";
import { DesignDataFormFileModel } from "./DesignDataFormFileModel";
import { minHeight } from "styled-system";

// Styling
import DeleteLogo from "Content/Bin.svg";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import SystemUpdateTwoToneIcon from "@material-ui/icons/SystemUpdateTwoTone";

interface IProps {
    className?: string;
}

export const DesignDataFormViewBase: React.FC<IProps> = (props) => {
    const { match } = useRouter();
    const { history } = useRouter();
    const { projectid, designdataid, isdarwinrdd, ref, isrevision } = match.params as any;
    const [viewModel] = useState(() => new DesignDataFormViewModel(designdataid, projectid, ref, isrevision));
    const [generalViewModel] = useState(() => ProjectGeneralViewModel.Instance);

    useEffect(() => {
        return () => {
            viewModel.reset();
        };
    }, []);

    const COLUMNS8 = "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr";
    const COLUMNS4 = "1fr 1fr 1fr 1fr";
    const COLUMNS2 = "1fr 1fr";
    const onSubmit = (event: any) => {
        event.preventDefault();
    };

    const renderItem = (designDataFormItemViewModel: DesignDataFormItemViewModel, index: number) => {
        return (
            <>
                <DesignDataItem>
                    <Grid columnGap={30} rowGap={0} tc={COLUMNS4} dc={COLUMNS4} style={{ marginTop: "0px", marginBottom: "0px" }}>
                        <Cell ts={1} ds={1}>
                            <ItemTextContainer>
                                <Typography variant="body1">{designDataFormItemViewModel.getValue("title")}</Typography>
                            </ItemTextContainer>
                        </Cell>
                        <Cell ts={2} ds={2}>
                            <ItemTextContainer>
                                <Typography variant="body1">{designDataFormItemViewModel.getValue("description")}</Typography>
                            </ItemTextContainer>
                        </Cell>
                        <Cell ts={1} ds={1}>
                            <div className="icon" onClick={() => viewModel.deleteDesignDataItem(index)}>
                                <img src={DeleteLogo} />
                            </div>
                        </Cell>
                    </Grid>
                </DesignDataItem>
            </>
        );
    };

    const renderForm = () => {
        return (
            <FormBox>
                <Form className={props.className} onSubmit={onSubmit}>
                    <FormSection>
                        <DashedDivider gutterBottom={true} borderThickness={1} borderOpacity={1} />
                        {isrevision === "1" && (
                            <>
                                <Grid columnGap={30} rowGap={20} tc={COLUMNS4} dc={COLUMNS4} style={{ marginBottom: "20px" }}>
                                    <Cell ts={1} ds={1}>
                                        <RevisionDetailsContainer>
                                            <div>
                                                <span>RDD supplier ref:</span> {viewModel.getValue("supplierReference")}
                                            </div>
                                        </RevisionDetailsContainer>
                                    </Cell>
                                    <Cell ts={1} ds={1}>
                                        <RevisionDetailsContainer>
                                            <div>
                                                <span>Client ref:</span> {viewModel.getValue("clientReference") === "0" ? "" : viewModel.clientReferenceFormatted}
                                            </div>
                                        </RevisionDetailsContainer>
                                    </Cell>
                                </Grid>
                                <Grid columnGap={30} rowGap={20} tc={COLUMNS4} dc={COLUMNS4} style={{ marginBottom: "20px" }}>
                                    {isdarwinrdd !== "0" && (
                                        <Cell ts={1} ds={1}>
                                            <DarwinSelect
                                                displayName="Supplier:"
                                                execute={(data: { id: string; displayName: string }) => viewModel.handleSupplierId(data)}
                                                fullWidth={true}
                                                getOptionLabel={(option: any) => option.displayName}
                                                options={viewModel.suppliers}
                                                onBlur={() => viewModel.isFieldValid("supplierId")}
                                                placeholder="Please select"
                                                value={viewModel.supplier}
                                                error={viewModel.getError("supplierId") !== ""}
                                                validationMessage={viewModel.getError("supplierId")}
                                                canExecute={!viewModel.IsLoading}
                                            />
                                        </Cell>
                                    )}
                                    <Cell ts={1} ds={1}>
                                        <DarwinSelect
                                            displayName="Status:"
                                            execute={(data: { id: string; displayName: string }) => viewModel.handleStatusId(data)}
                                            fullWidth={true}
                                            getOptionLabel={(option: any) => option.displayName}
                                            options={viewModel.statuses}
                                            onBlur={() => viewModel.isFieldValid("designDataStatusId")}
                                            placeholder="Please select"
                                            value={viewModel.status}
                                            error={viewModel.getError("designDataStatusId") !== ""}
                                            validationMessage={viewModel.getError("designDataStatusId")}
                                            canExecute={!viewModel.IsLoading}
                                        />
                                    </Cell>
                                </Grid>
                            </>
                        )}
                        {isrevision !== "1" && (
                            <Grid columnGap={30} rowGap={20} tc={COLUMNS4} dc={COLUMNS4} style={{ marginBottom: "20px" }}>
                                <Cell ts={1} ds={1}>
                                    {isdarwinrdd === "1" && (
                                        <TextField
                                            type="text"
                                            value={viewModel.getValue("supplierReference")}
                                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => viewModel.setValue("supplierReference", e.target.value)}
                                            fullWidth
                                            error={viewModel.getError("supplierReference") !== ""}
                                            disabled={viewModel.IsLoading}
                                            label={"RDD supplier ref:"}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            placeholder={"Please add"}
                                            helperText={viewModel.getError("supplierReference")}
                                        />
                                    )}
                                    {isdarwinrdd === "0" && (
                                        <DarwinSelect
                                            displayName="RDD supplier ref:"
                                            execute={(data: { id: string; displayName: string }) => viewModel.handleDarwinGroupSupplierId(data)}
                                            fullWidth={true}
                                            getOptionLabel={(option: any) => option.displayName}
                                            options={viewModel.darwinGroupSupplierReferences}
                                            onBlur={() => viewModel.isFieldValid("supplierReference")}
                                            placeholder="Please select"
                                            value={viewModel.darwinGroupClientReference}
                                            error={viewModel.getError("supplierReference") !== ""}
                                            validationMessage={viewModel.getError("supplierReference")}
                                            canExecute={!viewModel.IsLoading}
                                        />
                                    )}
                                </Cell>
                                {isdarwinrdd === "1" && (
                                    <Cell ts={1} ds={1}>
                                        <DarwinSelect
                                            displayName="Supplier:"
                                            execute={(data: { id: string; displayName: string }) => viewModel.handleSupplierId(data)}
                                            fullWidth={true}
                                            getOptionLabel={(option: any) => option.displayName}
                                            options={viewModel.suppliers}
                                            onBlur={() => viewModel.isFieldValid("supplierId")}
                                            placeholder="Please select"
                                            value={viewModel.supplier}
                                            error={viewModel.getError("supplierId") !== ""}
                                            validationMessage={viewModel.getError("supplierId")}
                                            canExecute={!viewModel.IsLoading}
                                        />
                                    </Cell>
                                )}
                                <Cell ts={1} ds={1}>
                                    <DarwinSelect
                                        displayName="Status:"
                                        execute={(data: { id: string; displayName: string }) => viewModel.handleStatusId(data)}
                                        fullWidth={true}
                                        getOptionLabel={(option: any) => option.displayName}
                                        options={viewModel.statuses}
                                        onBlur={() => viewModel.isFieldValid("designDataStatusId")}
                                        placeholder="Please select"
                                        value={viewModel.status}
                                        error={viewModel.getError("designDataStatusId") !== ""}
                                        validationMessage={viewModel.getError("designDataStatusId")}
                                        canExecute={!viewModel.IsLoading}
                                    />
                                </Cell>
                            </Grid>
                        )}
                        <Typography variant="h2">Adding items</Typography>
                        <div style={{ padding: "0px 0px", marginTop: "10px", marginBottom: "10px" }}>
                            <CustomHr />
                        </div>
                        <Grid columnGap={30} rowGap={20} tc={COLUMNS4} dc={COLUMNS4} style={{ marginBottom: "10px" }}>
                            <Cell ts={1} ds={1}>
                                <TextField
                                    type="text"
                                    value={viewModel.designDataItemTempViewModel?.getValue("title")}
                                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                                        viewModel.designDataItemTempViewModel?.setValue("title", e.target.value)
                                    }
                                    fullWidth
                                    error={false}
                                    disabled={viewModel.IsLoading}
                                    label={"Item:"}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    placeholder={"Please add"}
                                    helperText={viewModel.designDataItemTempViewModel?.getError("title")}
                                />
                            </Cell>
                            <Cell ts={2} ds={2}>
                                <TextField
                                    type="text"
                                    value={viewModel.designDataItemTempViewModel?.getValue("description")}
                                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                                        viewModel.designDataItemTempViewModel?.setValue("description", e.target.value)
                                    }
                                    fullWidth
                                    error={false}
                                    disabled={viewModel.IsLoading}
                                    label={"Item description:"}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    placeholder={"Please add description"}
                                    helperText={viewModel.designDataItemTempViewModel?.getError("description")}
                                />
                            </Cell>
                            <Cell ts={1} ds={1} alignItems={"center"}>
                                <PrimaryButton execute={() => viewModel.addDesignDataItem()} displayName={"Add"} value={"Add"} canExecute={!viewModel.IsLoading} />
                            </Cell>
                        </Grid>
                        {viewModel.designDataItemViewModels.length === 0 && (
                            <NoItemsTextContainer>
                                <Typography variant="body1">No items added</Typography>
                            </NoItemsTextContainer>
                        )}
                        {viewModel.designDataItemViewModels
                            .filter((d) => d.model.isDeleted === false)
                            .map((item, index) => {
                                return renderItem(item, index);
                            })}
                        <DashedDivider gutterBottom={true} borderThickness={1} borderOpacity={1} />
                    </FormSection>
                    <FormSection>
                        <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8} style={{ marginBottom: "20px" }}>
                            <Cell ts={2} ds={2}>
                                <DarwinSelect
                                    displayName="Requested by:"
                                    execute={(data: { id: string; displayName: string }) => viewModel.handleRequestedById(data)}
                                    fullWidth={true}
                                    getOptionLabel={(option: any) => option.displayName}
                                    options={viewModel.requestedByUsers}
                                    onBlur={() => viewModel.isFieldValid("requestedByUserId")}
                                    placeholder="Please select"
                                    value={viewModel.requestedBy}
                                    error={viewModel.getError("requestedByUserId") !== ""}
                                    validationMessage={viewModel.getError("requestedByUserId")}
                                    canExecute={!viewModel.IsLoading}
                                />
                            </Cell>
                            <Cell ts={1} ds={1}>
                                <DarwinDateSelect
                                    displayName="Request date:"
                                    execute={(value: string | null) => viewModel.setRequestedDate(value)}
                                    onBlur={() => viewModel.isFieldValid("requestedDate")}
                                    placeholder="Please select"
                                    validationMessage={viewModel.getError("requestedDate")}
                                    value={viewModel.getValue("requestedDate")}
                                    canExecute={!viewModel.IsLoading}
                                />
                            </Cell>
                            <Cell ts={2} ds={2}>
                                <TextField
                                    type="text"
                                    value={viewModel.getValue("discipline")}
                                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => viewModel.setValue("discipline", e.target.value)}
                                    fullWidth
                                    error={viewModel.getError("discipline") !== ""}
                                    disabled={viewModel.IsLoading}
                                    label={"Discipline:"}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    placeholder={"Please add"}
                                    helperText={viewModel.getError("discipline")}
                                />
                            </Cell>
                        </Grid>
                        <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8} style={{ marginBottom: "20px" }}>
                            <Cell ts={2} ds={2}>
                                <TextField
                                    type="text"
                                    value={viewModel.getValue("requestedTo")}
                                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => viewModel.setValue("requestedTo", e.target.value)}
                                    fullWidth
                                    error={viewModel.getError("requestedTo") !== ""}
                                    disabled={viewModel.IsLoading}
                                    label={"Requested to:"}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    placeholder={"Please add"}
                                    helperText={viewModel.getError("requestedTo")}
                                />
                            </Cell>
                            <Cell ts={1} ds={1}>
                                <DarwinDateSelect
                                    displayName="Response require by:"
                                    execute={(value: string | null) => viewModel.setResponseRequiredByDate(value)}
                                    onBlur={() => viewModel.isFieldValid("responseRequiredByDate")}
                                    placeholder="Please select"
                                    validationMessage={viewModel.getError("responseRequiredByDate")}
                                    value={viewModel.getValue("responseRequiredByDate")}
                                    canExecute={!viewModel.IsLoading}
                                />
                            </Cell>
                            <Cell ts={2} ds={2}>
                                <DarwinSelect
                                    displayName="Priority:"
                                    execute={(data: { id: string; displayName: string }) => viewModel.handlePriorityId(data)}
                                    fullWidth={true}
                                    getOptionLabel={(option: any) => option.displayName}
                                    options={viewModel.priorities}
                                    onBlur={() => viewModel.isFieldValid("designDataPriorityId")}
                                    placeholder="Please select"
                                    value={viewModel.priority}
                                    error={viewModel.getError("designDataPriorityId") !== ""}
                                    validationMessage={viewModel.getError("designDataPriorityId")}
                                    canExecute={!viewModel.IsLoading}
                                />
                            </Cell>
                        </Grid>
                        <DashedDivider gutterBottom={true} borderThickness={1} borderOpacity={1} />
                    </FormSection>

                    <FormSection>
                        <Grid columnGap={30} rowGap={20} marginBottom={1} tc={COLUMNS2} dc={COLUMNS2}>
                            <Cell ts={1} ds={1}>
                                <FileUploader>
                                    <Typography
                                        style={{ marginBottom: "8px", font: "normal normal 700 12px/16px Segoe UI", letterSpacing: "-0.18px", color: "#191919" }}
                                        variant="h5"
                                    >
                                        Attachments:
                                    </Typography>
                                    <Box>
                                        <Uploader handleSelectFile={(e: any) => viewModel.fileChange(e)} isFormDisabled={false} label="" />
                                        {viewModel.model.designDataFiles.filter((el: any) => !el.isDeleted).length > 0 && (
                                            <Typography style={{ margin: "17px 0px 13px" }} variant="h5">
                                                Photo(s) added:
                                            </Typography>
                                        )}
                                    </Box>
                                    <Box className="uploadedFile">
                                        {viewModel.designDataFileViewModels.map((item: DesignDataFormFileViewModel, index: number) => {
                                            return (
                                                !item.model.isDeleted && (
                                                    <Box className="uploadedFile-box">
                                                        <Box className="uploadedFile-heading">
                                                            <IconButton onClick={() => viewModel.DownloadFile(item.model.fileURL, item.model.fileName)}>
                                                                <SystemUpdateTwoToneIcon />
                                                            </IconButton>
                                                            <h3>{item.model.fileName}</h3>
                                                        </Box>
                                                        <IconButton onClick={() => viewModel.deleteDesignDataFile(index)} disabled={!viewModel.isFormDisabled}>
                                                            <DeleteOutlinedIcon />
                                                        </IconButton>
                                                    </Box>
                                                )
                                            );
                                        })}
                                    </Box>
                                    {viewModel.designDataFileViewModels.length === 0 && (
                                        <NoItemsTextContainer>
                                            <Typography variant="body1">No attachments added</Typography>
                                        </NoItemsTextContainer>
                                    )}
                                </FileUploader>
                            </Cell>
                        </Grid>
                    </FormSection>

                    <Box style={{ margin: `0 ${pxToRem(30)} 0 ${pxToRem(30)}` }}>
                        <SolidDivider gutterBottom={true} borderThickness={1} borderOpacity={1} />
                    </Box>

                    <FormSection>
                        <ButtonsContainer>
                            <PrimaryButton displayName="Submit" execute={() => viewModel.upsert(isdarwinrdd, isrevision)} fullWidth={true} canExecute={!viewModel.IsLoading} />
                            <DefaultButton displayName="Cancel" execute={() => viewModel.handleCancel(projectid)} fullWidth={true} canExecute={!viewModel.IsLoading} />
                        </ButtonsContainer>
                    </FormSection>
                </Form>
            </FormBox>
        );
    };

    return useObserver(() => {
        return (
            <Form className={props.className} onSubmit={() => {}}>
                <BackButtonHolder
                    onClick={() => {
                        history.push(AppUrls.Client.Project.DesignData.replace(":projectid", projectid ? projectid : viewModel.model.projectId));
                    }}
                    style={{ padding: "10px 0", marginTop: 0 }}
                >
                    <Box style={{ position: "relative", display: "flex", marginLeft: "10px", alignItems: "center" }}>
                        <Box style={{ position: "absolute", height: "15px", width: "8px" }}>
                            <CustomArrow size={"8px"} color={theme.palette.blue.main} type={"left"} />
                        </Box>
                        <Box style={{ marginLeft: "20px", font: "normal normal 600 18px/32px Open Sans", letterSpacing: "-0.36px", color: theme.palette.blue.main }}>
                            Back to Reviewable design data
                        </Box>
                    </Box>
                </BackButtonHolder>

                <DashedDivider gutterBottom={false} gutterTop={false} borderThickness={2} borderOpacity={0.5} />

                <HeaderContainer>
                    <FormHeader displayName={viewModel.getFormTitle(isdarwinrdd, isrevision)} />
                </HeaderContainer>

                <FormSection>
                    <ProjectDetailsContainer>
                        <div>
                            <span>Date / time added:</span> {viewModel.getTodayDateFormatted}
                        </div>
                        <div>
                            <span>Project reference:</span> {viewModel.getProjectReferenceFormatted}
                        </div>
                        <div>
                            <span>Project name:</span> {viewModel.getProjectName}
                        </div>
                    </ProjectDetailsContainer>
                </FormSection>

                {renderForm()}
            </Form>
        );
    });
};

export const DesignDataFormView = styled(DesignDataFormViewBase)`
    display: flex;
    flex: 1;
    flex-direction: column;

    form {
        position: relative;
    }
`;

const HeaderContainer = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 70px;

    // Header
    > div:nth-child(1) {
        margin: 4px 30px 13.5px 30px;
    }
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;

    > button {
        max-width: 262px;
    }

    > button:nth-child(1) {
        margin-right: 30px;
    }
`;

export const NoItemsTextContainer = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;

    p {
        font-size: 12px !important;
    }
`;

export const ItemTextContainer = styled.div`
    margin-left: 5px;

    p {
        font-size: 12px !important;
    }
`;

export const DesignDataItem: any = styled.li`
    position: relative;
    list-style-type: none;
    background-color: #eaf4f9;
    padding: 5px;
    margin-bottom: 10px;
`;

const DataList = styled(Box)`
    font: normal normal normal 12px/16px Segoe UI;
    letter-spacing: -0.18px;
    color: #191919;
    display: flex;
    align-items: center;
    gap: 12px;
    span {
        font-weight: 700;
    }
`;

const FormBox = styled(Box)`
    .MuiFormLabel-root {
        font: normal normal 700 12px/16px Segoe UI;
        letter-spacing: -0.18px;
        color: #191919;
        margin: 0 0 7px;
    }
`;

interface SubtitleProps {
    margin?: string;
}

export const SubtitleContainer = styled.div<SubtitleProps>`
    margin-top: 12px;
    h2 {
        margin-bottom: ${(props) => (props.margin ? props.margin : "10px")};
        font: normal normal 600 12px/16px Segoe UI;
        letter-spacing: -0.18px;
        color: #191919;
        span {
            font-weight: bold;
            margin-right: 10px;
        }
    }
`;

const SignatureContainer = styled.div`
    display: flex;
    flex-direction: column;
    grid-column-start: 1;
    grid-column-end: 3;

    > div:nth-child(2) {
        display: flex;
        flex-direction: row;

        > div {
            width: 100%;

            canvas {
                border: 1px solid #d4d4d4;
            }
        }
    }
    .MuiFormControl-root {
        width: 100% !important;
    }
`;

const ProjectDetailsContainer = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    max-width: 700px;

    > div {
        font-size: 12px;
    }

    > div > span {
        font-weight: bold;
    }
`;

const RevisionDetailsContainer = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > div {
        font-size: 12px;
    }

    > div > span {
        font-weight: bold;
    }
`;

const FileUploader = styled(Box)`
    margin-top: 20px;
    .fileUpload {
        background: #6c93a626;
        border: 1px dashed #6c93a6;
        font: normal normal normal 12px/14px Open Sans;
        letter-spacing: 0px;
        color: #425b66;
        padding: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 90px;
        margin: 0 !important;
    }
`;

// I don't know why, but the textarea sometimes has a default height of 0px...
export const TextAreaContainer = styled.div`
    textarea.MuiInputBase-input {
        min-height: 60px;
    }
`;
