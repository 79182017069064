import { ModelBase } from "@shoothill/core";
import { NotEquals } from "class-validator";
import { action, observable } from "mobx";

import { IPlanOfWorkStage, PlanOfWorkStage } from "Globals/Models/Enums/PlanOfWorkStage";

export class AddPlanOfWorkModel extends ModelBase<AddPlanOfWorkModel> {
    // #region Defaults

    public static readonly DEFAULT_PLANOFWORKSTAGES = [];

    public static readonly DEFAULT_PLANOFWORKSTAGE = null;

    // #endregion Defaults

    // #region Related Properties

    @observable
    public planOfWorkStages = observable<PlanOfWorkStage>(AddPlanOfWorkModel.DEFAULT_PLANOFWORKSTAGES);

    // #endregion Related Properties

    // #region Properties

    @observable
    @NotEquals(null, { message: "Please select an option" })
    public planOfWorkStage: PlanOfWorkStage | null = AddPlanOfWorkModel.DEFAULT_PLANOFWORKSTAGE;

    // #endregion Properties

    // #region Methods

    @action
    public fromDto(dto: any): void {
        // Plan of Work Stages.
        this.planOfWorkStages.replace(
            dto.map((pows: IPlanOfWorkStage) => {
                const enumType = new PlanOfWorkStage();

                enumType.fromDto(pows);

                return enumType;
            }),
        );
    }

    public toDto(): any {
        return {
            planOfWorkStageId: this.planOfWorkStage?.id,
        };
    }

    // #endregion Methods
}
