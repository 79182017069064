import { FieldType, isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import type { ValidationResponse } from "@shoothill/core";
import { action, computed } from "mobx";
import { InvoiceDisputeModalModel } from "./InvoiceDisputeModalModel";
import { InvoiceViewModel } from "../../InvoiceViewModel";
import { InvoiceDisputedStatusCodeModel } from "../Supporting/InvoiceDisputedStatusCodeModel";

export class InvoiceDisputeModalViewModel extends ViewModelBase<InvoiceDisputeModalModel> {
    // #region Constructors and Disposers

    constructor() {
        super(new InvoiceDisputeModalModel());
        this.setDecorators(InvoiceDisputeModalViewModel);
    }

    // #region Properties
    @computed
    public get invoiceDisputedStatusCodes() {
        return InvoiceViewModel.GetInstance.invoiceDisputedStatusCodes;
    }

    @computed
    public get invoiceDisputedStatusCode() {
        const result = this.invoiceDisputedStatusCodes.find((c) => c.id === this.model.invoiceDisputedStatusCodeId);

        return result ? result! : null;
    }

    @action
    public setInvoiceDisputedStatusCode = (value: InvoiceDisputedStatusCodeModel | null) => {
        this.model.invoiceDisputedStatusCodeId = value ? value.id : InvoiceDisputeModalModel.DEFAULT_INVOICEDISPUTEDSTATUSCODEID;
    };

    @action
    public setInvoiceDisputedReason = (value: string) => {
        this.model.invoiceDisputedReason = value ? value : InvoiceDisputeModalModel.DEFAULT_INVOICEDISPUTEDREASON;
    };

    // #endregion Properties

    // #region Server Actions

    @action
    public reset = () => {
        this.model.reset();
    };

    // #endregion Client Actions

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<InvoiceDisputeModalModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        switch (fieldName) {
            case "invoiceDisputedReason": {
                const result = this.validateInvoiceDisputedReason;

                errorMessage = result.errorMessage;
                isValid = result.isValid;
                break;
            }

            case "invoiceDisputedStatusCodeId": {
                const result = this.validateInvoiceDisputedStatusCodeId;

                errorMessage = result.errorMessage;
                isValid = result.isValid;
                break;
            }
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    @computed
    private get validateInvoiceDisputedReason(): ValidationResponse {
        const errorMessage = this.model.validateInvoiceDisputedReason;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateInvoiceDisputedStatusCodeId(): ValidationResponse {
        const errorMessage = this.model.validateInvoiceDisputedStatusCodeId;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
