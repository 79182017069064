import * as MobX from "mobx";
import { ModelBase } from "@shoothill/core";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class PurchaseListModel extends ModelBase<PurchaseListModel, PurchaseListModelDTO> {
    constructor(model: PurchaseListModelDTO | null = null) {
        super();

        if (model) {
            this.fromDto(model);
        }
    }

    @MobX.observable
    public id: string = "";

    @MobX.observable
    public approvalSectionId: string = "";

    @MobX.observable
    public approvalSectionName: string = "";

    @MobX.observable
    public reference: string = "";

    @MobX.observable
    public name: string = "";

    @MobX.observable
    public approvalTypeId: string = "";

    @MobX.observable
    public approvalTypeName: string = "";

    @MobX.observable
    public requestedDate: Date | null = null;

    @MobX.observable
    public requestedBy: string = "";

    @MobX.observable
    public requisitionOrPOId: string | null = null;

    @MobX.observable
    public materialTransactionRequestId: string | null = null;

    @MobX.observable
    public stockTransactionRequestId: string | null = null;

    @MobX.observable
    public drawingId: string | null = null;

    @MobX.observable
    public variationId: string | null = null;

    @MobX.observable
    public invoiceId: string | null = null;

    @MobX.observable
    public itemDescription: string | null = null;

    @MobX.observable
    public itemCost: number | null = null;

    @MobX.observable
    public approverRoleName: string | null = null;

    @MobX.observable
    public supplierName: string | null = null;

    //fromDto is required but you can leave it blank
    fromDto(model: PurchaseListModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: PurchaseListModel): void {}
}

export type PurchaseListModelDTO = {
    id: string | null;
    approvalSectionId: string;
    approvalSectionName: string;
    reference: string;
    name: string;
    approvalTypeId: string;
    approvalTypeName: string;
    requestedDate: Date;
    requestedBy: string;
    requisitionOrPOId: string | null;
    materialTransactionRequestId: string | null;
    stockTransactionRequestId: string | null;
    drawingId: string | null;
    variationId: string | null;
    invoiceId: string | null;
    itemDescription: string | null;
    itemCost: number | null;
    approverRoleName: string | null;
    supplierName: string | null;
};
