import { FormControlLabel, FormLabel, RadioGroup } from "@material-ui/core";
import { Loader } from "@shoothill/core";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";

import { SHBox } from "Components/Box";
import { DarwinInput } from "Globals/Styles/Control/DarwinInput";
import { Step2Model } from "./Step2Model";
import { Step2ViewModel } from "./Step2ViewModel";
import { QualificationView } from "../Common/QualificationView";
import { AddOtherView } from "../Common/AddOtherView";
import { InductionViewModel } from "../InductionViewModel";
import { WizardButtonsView } from "../WizardButtons";
import { CustomRadio } from "../../Project/CustomComponents";
import { DashedDivider } from "../../PurchaseOrder/Form/Views/Dividers";

interface Props {
    declinedAge: boolean;
    inductionTypeId: string | null;
    parentViewModel: InductionViewModel;
    viewModel: Step2ViewModel;
}

export const Step2View: React.FC<Props> = observer((props: Props) => {
    const { viewModel } = props;

    useEffect(() => {
        runInAction(() => {
            viewModel.secondPage = false;
            viewModel.model.inductionTypeId = props.inductionTypeId;
            viewModel.model.declinedAge = props.declinedAge;
        });
    }, []);

    const step2a = (
        <SHBox id={"step2a"} m={3} pb={6}>
            <SHBox showIf={props.declinedAge}>
                <p>As you declined to add your age please could you answer the following questions</p>
                <SHBox mt={3} grid dc={"1fr"} mc={"1fr"} style={{ borderBottom: "1px solid #707070" }}>
                    <SHBox>
                        <FormLabel>Please confirm you are over the age of 18</FormLabel>
                        <RadioGroup row defaultValue="Yes" name="radio-confirm18-group" value={viewModel.model.confirmOver18} onChange={viewModel.handleOver18Change}>
                            <FormControlLabel value="true" control={<CustomRadio checked={viewModel.model.confirmOver18 === "true"} />} label="Yes" />
                            <FormControlLabel value="false" control={<CustomRadio checked={viewModel.model.confirmOver18 === "false"} />} label="No" />
                        </RadioGroup>
                    </SHBox>
                </SHBox>
                <SHBox showIf={viewModel.model.confirmOver18 === "false"} mt={3} grid dc={"1fr"} mc={"1fr"} style={{ borderBottom: "1px solid #707070" }} pb={3}>
                    <SHBox>
                        <SHBox showIf={viewModel.model.confirmAdjustments === "true"} grid dc={"600px"} mc={"1fr"}>
                            <DarwinInput<Step2Model>
                                type="text"
                                label="Please provide any adjustments need to be made for you based on your age"
                                placeholder="Please provide the adjustments"
                                multiLine={true}
                                validateOnBlur={true}
                                viewModel={viewModel}
                                fieldName={"provideAdjustmentDetails"}
                                shrink={true}
                                maxLength={1024}
                            />
                        </SHBox>
                    </SHBox>
                </SHBox>
            </SHBox>
            <SHBox mt={3} grid dc={"1fr"} style={{ borderBottom: "1px solid #707070" }}>
                <SHBox>
                    <FormLabel>Do you have any health problems that could affect your ability to carry out your tasks or visit the site safely?</FormLabel>
                    <RadioGroup row defaultValue="Yes" name="radio-confirm18-group" value={viewModel.model.healthProblems} onChange={viewModel.handleOverHealthProblemsChange}>
                        <FormControlLabel value="true" control={<CustomRadio checked={viewModel.model.healthProblems === "true"} />} label="Yes" />
                        <FormControlLabel value="false" control={<CustomRadio checked={viewModel.model.healthProblems === "false"} />} label="No" />
                    </RadioGroup>
                </SHBox>
                <SHBox showIf={viewModel.model.healthProblems === "true"} grid dc={"600px"} mc={"1fr"}>
                    <DarwinInput<Step2Model>
                        type="text"
                        label="Please provide brief details of the potential risks"
                        placeholder="Please provide the potential risks"
                        multiLine={true}
                        validateOnBlur={true}
                        viewModel={viewModel}
                        fieldName="provideHealthDetails"
                        shrink={true}
                        maxLength={1024}
                    />
                </SHBox>
            </SHBox>
            <SHBox mt={3} grid dc={"1fr"} style={{ borderBottom: "1px solid #707070" }}>
                <SHBox>
                    <FormLabel>Have you been prescribed or take medication that can potentially affect your ability to work or visit safely?</FormLabel>
                    <RadioGroup row defaultValue="Yes" name="radio-confirm18-group" value={viewModel.model.prescribed} onChange={viewModel.handlePrescribedMedicationChange}>
                        <FormControlLabel value="true" control={<CustomRadio checked={viewModel.model.prescribed === "true"} />} label="Yes" />
                        <FormControlLabel value="false" control={<CustomRadio checked={viewModel.model.prescribed === "false"} />} label="No" />
                    </RadioGroup>
                </SHBox>
                <SHBox showIf={viewModel.model.prescribed === "true"} grid dc={"600px"} mc={"1fr"}>
                    <DarwinInput<Step2Model>
                        type="text"
                        label="Please provide brief details of the medication and its affects"
                        placeholder="Please provide the details"
                        multiLine={true}
                        validateOnBlur={true}
                        viewModel={viewModel}
                        fieldName="providePrescribedDetails"
                        shrink={true}
                        maxLength={1024}
                    />
                </SHBox>
            </SHBox>

            <SHBox mt={3} grid dc={"1fr"} style={{ borderBottom: "1px solid #707070" }}>
                <SHBox>
                    <FormLabel>
                        If at any point you are prescribed or take medication that can potentially affect your ability to work or visit safely, it is essential that you notify the
                        site manager immediately.
                    </FormLabel>
                    <RadioGroup row defaultValue="Yes" name="radio-confirm18-group" value={viewModel.model.notifyManager} onChange={viewModel.handleAgreeNotifyManager}>
                        <FormControlLabel value="true" control={<CustomRadio checked={viewModel.model.notifyManager === "true"} />} label="I Agree" />
                        {/*<FormControlLabel value="false" control={<CustomRadio checked={viewModel.model.healthProblems === "false"} />} label="No" />*/}
                    </RadioGroup>
                </SHBox>
            </SHBox>
        </SHBox>
    );

    const step2b = (
        <SHBox m={3} pb={"100px"}>
            <p>What Skills & Training/Qualifications do you have (please do not add plant or equipment training here)?</p>
            {viewModel.qualificationViewModels.map((qualificationViewModel, index) => {
                return (
                    <React.Fragment key={index}>
                        <QualificationView viewModel={qualificationViewModel} lastItem={index === viewModel.qualificationViewModels.length - 1} />
                        <DashedDivider gutterBottom />
                    </React.Fragment>
                );
            })}
            <AddOtherView onAdd={viewModel.addOther} />
        </SHBox>
    );

    const whatPageToShow = () => {
        return !viewModel.secondPage ? step2a : step2b;
    };

    if (props.parentViewModel.isStageLoading) {
        return (
            <>
                <Loader />
            </>
        );
    }

    return (
        <>
            {whatPageToShow()}
            <WizardButtonsView nextStep={viewModel.nextStep} previousStep={viewModel.previousStep} canExecute={viewModel.canExecuteNextStep} />
        </>
    );
});
