import { FieldType, KeyValuePair, observable, ViewModelBase } from "@shoothill/core";
import { ProjectFilterParamsModel } from "./ProjectFilterParamsModel";
import { action } from "mobx";
import { RequisitionPOStatus } from "Views/Approval/PurchaseListViewModel";
import { ProjectStatusTypeDTO } from "./ProjectStatusTypeModel";

export class ProjectListFilterViewModel extends ViewModelBase<ProjectFilterParamsModel> {
    private static _intsance: ProjectListFilterViewModel;
    public static get Instance() {
        return this._intsance || (this._intsance = new this());
    }

    constructor() {
        super(new ProjectFilterParamsModel(), false);
        this.setDecorators(ProjectFilterParamsModel);

        //this.setStatuses(Object.values(ProjectStatus).filter((s) => typeof s === "number") as ProjectStatus[], false);
    }

    @observable
    public readonly statusOptions: KeyValuePair[] = [];

    public getStatusOptions = (includeUnKnown?: boolean): KeyValuePair[] => {
        return this.statusOptions === undefined ? [] : this.statusOptions.slice();
    };

    @action
    public setStatuses(statusOptions: ProjectStatusTypeDTO[], resetFilters: boolean) {
        if (this.statusOptions !== null && this.statusOptions.length === 0) {
            if (statusOptions.length > 0) {
                this.statusOptions.push(
                    ...statusOptions.map((item: ProjectStatusTypeDTO) => {
                        return { key: item.name, value: item.id };
                    }),
                );
            }
        }
    }

    public async isFieldValid(fieldName: keyof FieldType<ProjectFilterParamsModel>): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
