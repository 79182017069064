import { ModelBase } from "@shoothill/core";
import { action, observable } from "mobx";

export class RoleModel extends ModelBase<RoleModel, RoleDTO> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Defaults and Constants

    public static readonly DEFAULT_ID = "";
    public static readonly DEFAULT_DISPLAYNAME = "";
    public static readonly DEFAULT_ROLELEVEL = 2;

    // #endregion Defaults and Constants

    // #region Observables

    @observable
    public id: string = RoleModel.DEFAULT_ID;

    @observable
    public displayName: string = RoleModel.DEFAULT_DISPLAYNAME;

    @observable
    public roleLevel: number = RoleModel.DEFAULT_ROLELEVEL;

    // #endregion Observables

    // #region Actions

    @action
    public reset(): void {
        this.id = RoleModel.DEFAULT_ID;
        this.displayName = RoleModel.DEFAULT_DISPLAYNAME;
        this.roleLevel = RoleModel.DEFAULT_ROLELEVEL;
    }

    @action
    public fromDto(dto: RoleDTO): void {
        this.id = dto.id;
        this.displayName = dto.displayName;
        this.roleLevel = dto.roleLevel;
    }

    public toDto() {
        throw new Error("Method not implemented.");
    }

    // #endregion Actions

    // #region Helpers

    public static fromDtos(dtos: RoleDTO[]): RoleModel[] {
        const types: RoleModel[] = [];

        for (const dto of dtos) {
            const model = new RoleModel();

            model.fromDto(dto);
            types.push(model);
        }

        return types;
    }

    // #endregion Helpers
}

export interface RoleDTO {
    id: string;
    displayName: string;
    roleLevel: number;
}
