import styled from "styled-components";
import { BaseModalDialogPaper } from "Components/Modal/BaseModal.styles";
import { theme } from "../../Globals/Styles/AppTheme";

export const EditContactModalDialogPaper = styled(BaseModalDialogPaper)`
    min-width: 420px;
    max-width: 1280px;
    width: 100%;

    .MuiDialogContent-root {
        p {
            //padding-bottom: 40px;
            font-size: 14px;
        }
    }
`;

export const Main = styled.section`
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    // background-color: green;

    @media screen and (max-width: 1024px) {
    }

    @media screen and (max-width: 640px) {
        flex-direction: column;
    }

    /* label.MuiInputLabel-shrink {
        font-size: 19px !important;
        color: ${theme.palette.text.primary} !important;
    }

    label + .MuiInput-formControl {
        margin-top: 21px;
    } */

    p.Mui-error {
        font-size: 12px;
    }
`;
