import React, { useContext } from "react";
import { Stores, StoresContext } from "Globals/Stores";
import { LogoutView } from "Views/Logout/LogoutView";
import { LoginView } from "Views/Login/LoginView";
import { ResetPasswordView } from "Views/ResetPassword/ResetPasswordView";
import { SessionExpiredView } from "Views/SessionExpired/SessionExpiredView";
import { LayoutNoHeader } from "Globals/Views/Layouts/LayoutNoHeader";
import { PrivateRoute } from "Globals/Views";
import { ForgotPasswordView } from "Views/ForgotPassword/ForgotPasswordView";
import { ForgotPasswordSuccessView } from "Views/ForgotPassword/ForgotPasswordSuccessView";
import { RouteWithLayout as Route } from "Globals/Views/RouteWithLayout";
import { AppUrls } from "AppUrls";
import { StoresInstance } from "Globals/Stores";
import * as RouterUtil from "Utils/Routing";

export const AccountRoutes: React.FC = () => {
    const store = useContext<Stores>(StoresContext);
    return (
        <>
            <Route exact path={AppUrls.Client.Account.Login} layout={LayoutNoHeader} component={LoginView} />
            <PrivateRoute
                exact
                path={AppUrls.Client.Account.Logout}
                component={LogoutView}
                isAllowed={() => {
                    return StoresInstance.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
            />
            <Route exact path={AppUrls.Client.Account.ForgotPassword} layout={LayoutNoHeader} component={ForgotPasswordView} />
            <Route exact path={AppUrls.Client.Account.ForgotPasswordSuccess} layout={LayoutNoHeader} component={ForgotPasswordSuccessView} />
            <Route exact path={AppUrls.Client.Account.SessionExpired} layout={LayoutNoHeader} component={SessionExpiredView} />
            <Route exact path={AppUrls.Client.Account.ResetPassword} layout={LayoutNoHeader} component={ResetPasswordView} />
        </>
    );
};
