import { observer } from "mobx-react-lite";

import { theme } from "Globals/Styles/AppTheme";
import { BackButtonHolder } from "Views/Project/Commercial/IETables/IEGrid.Styles";
import { CustomArrow } from "Views/Project/CustomComponents";
import { NavigationBarArrow, NavigationBarContainer, NavigationBarDisplayName } from "./NavigationBar.styles";

interface IProps {
    action: () => void;
    displayName: string;
}

export const NavigationBarView: React.FC<IProps> = observer((props) => {
    return (
        <BackButtonHolder onClick={props.action}>
            <NavigationBarContainer>
                <NavigationBarArrow>
                    <CustomArrow size={"8px"} color={theme.palette.blue.main} type={"left"} />
                </NavigationBarArrow>
                <NavigationBarDisplayName>{props.displayName}</NavigationBarDisplayName>
            </NavigationBarContainer>
        </BackButtonHolder>
    );
});
