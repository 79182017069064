import React from "react";
import { useObserver } from "mobx-react-lite";

import { AddEditContactViewWrapper, AddEditContactViewRow, ContactCell, AddButtonCell } from "./AddEditContact.styles";

import { AddEditContactViewModel } from "./AddEditContactViewModel";
import { DarwinInput } from "Globals/Styles/Control/DarwinInput";
import { AcceptButton } from "Globals/Styles/Control/Buttons";
import { AddEditContactModel } from "./AddEditContactModel";
import { DarwinSelect } from "Components/AutoComplete/DarwinSelect";

interface IAddEditContactViewProps {
    viewModel: AddEditContactViewModel;
    // reference?: React.MutableRefObject<HTMLButtonElement | undefined>;
    onUpsertContact: (model: AddEditContactModel) => any;
    isLoading: boolean;
    disabled?: boolean;
    //showAdd: boolean;
}

export const AddEditContactView: React.FC<IAddEditContactViewProps> = ({ viewModel, onUpsertContact, isLoading, disabled = false }) => {
    // const [viewModel] = useState(() => new AddEditContactViewModel());

    //useEffect below only gets run on initial render
    // useEffect(() => {
    //     viewModel.setModel(model);
    //     return () => {
    //         viewModel.cleanUp();
    //     };
    // }, []);

    // useEffect(() => {
    //     viewModel.setModel(model);
    // }, [model]);

    const upsertContact = () => {
        onUpsertContact(viewModel.getModel);
    };

    return useObserver(() => (
        <AddEditContactViewWrapper className="AddEditContactForm">
            <AddEditContactViewRow>
                <ContactCell>
                    <DarwinInput<AddEditContactViewModel>
                        type="text"
                        label="First name"
                        validateOnBlur={true}
                        viewModel={viewModel}
                        fieldName="firstName"
                        shrink={true}
                        maxLength={128}
                    />
                </ContactCell>
                <ContactCell>
                    <DarwinInput<AddEditContactViewModel>
                        type="text"
                        label="Last name"
                        validateOnBlur={true}
                        viewModel={viewModel}
                        fieldName="lastName"
                        shrink={true}
                        maxLength={128}
                    />
                </ContactCell>
                <ContactCell>
                    <DarwinInput<AddEditContactViewModel>
                        type="text"
                        label="Position"
                        validateOnBlur={true}
                        viewModel={viewModel}
                        fieldName="position"
                        shrink={true}
                        maxLength={128}
                    />
                </ContactCell>
            </AddEditContactViewRow>
            <AddEditContactViewRow>
                <ContactCell>
                    <DarwinInput<AddEditContactViewModel>
                        type="text"
                        label="Contact Email"
                        validateOnBlur={true}
                        viewModel={viewModel}
                        fieldName="email"
                        shrink={true}
                        maxLength={256}
                    />
                </ContactCell>
                <ContactCell>
                    <DarwinInput<AddEditContactViewModel>
                        type="text"
                        label="Contact Phone Number"
                        validateOnBlur={true}
                        viewModel={viewModel}
                        fieldName="phone"
                        shrink={true}
                        maxLength={128}
                    />
                </ContactCell>
                <ContactCell>
                    <DarwinSelect
                        displayName="Type"
                        execute={viewModel.setContactType}
                        fullWidth={true}
                        getOptionLabel={(option: any) => option.displayName}
                        options={viewModel.contactTypes}
                        placeholder="Please select"
                        value={viewModel.contactType}
                    />
                </ContactCell>
                <AddButtonCell className="model-none">
                    {/* ref={reference} */}
                    <AcceptButton
                        onClick={upsertContact}
                        style={{ visibility: viewModel.model.id === undefined || viewModel.model.id.length === 0 ? "visible" : "hidden" }}
                        disabled={disabled}
                    >
                        {isLoading ? <span>Please wait...</span> : <span>Add</span>}
                    </AcceptButton>
                </AddButtonCell>
            </AddEditContactViewRow>
        </AddEditContactViewWrapper>
    ));
};
