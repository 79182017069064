import styled from "styled-components";
import { BaseModalDialogPaper } from "Components/Modal/BaseModal.styles";

export const UserAddModalDialogPaper = styled(BaseModalDialogPaper)`
    /*
    width: 100%;
    max-width: 600px;
    margin: 10px;
    */
    min-width: 420px;
    max-width: 640px /* 1280px */;
    width: 100%;

    .MuiDialogActions-root {
        width: 378px;
        margin: auto;
        padding-bottom: 40px;
    }
`;
