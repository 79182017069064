import { FieldType, KeyValuePair, observable, ViewModelBase } from "@shoothill/core";
import { action } from "mobx";
import { MaterialsFilterParamsModel } from "./MaterialsListFilterParamsModel";

export class MaterialsListFilterViewModel extends ViewModelBase<MaterialsFilterParamsModel> {
    private static _intsance: MaterialsListFilterViewModel;
    public static get Instance() {
        return this._intsance || (this._intsance = new this());
    }

    constructor() {
        super(new MaterialsFilterParamsModel(), false);
        this.setDecorators(MaterialsFilterParamsModel);
    }

    @observable
    public readonly typeOptions: KeyValuePair[] = [];

    @observable
    public readonly subTypeOptions: KeyValuePair[] = [];

    @observable
    public readonly uomOptions: KeyValuePair[] = [];

    @observable
    public readonly supplierOptions: KeyValuePair[] = [];

    public getTypeOptions = (includeUnKnown?: boolean): KeyValuePair[] => {
        return this.typeOptions === undefined ? [] : this.typeOptions.slice();
    };

    public getSubTypeOptions = (includeDeleted: boolean): KeyValuePair[] => {
        return this.subTypeOptions === undefined ? [] : this.subTypeOptions.slice();
    };

    public getUOMOptions = (includeDeleted: boolean): KeyValuePair[] => {
        return this.uomOptions === undefined ? [] : this.uomOptions.slice();
    };

    public getSupplierOptions = (includeDeleted: boolean): KeyValuePair[] => {
        return this.supplierOptions === undefined ? [] : this.supplierOptions.slice();
    };

    @action
    public setTypes(typeOptions: any[], resetFilters: boolean) {
        if (this.typeOptions !== null && this.typeOptions.length === 0) {
            if (typeOptions.length > 0) {
                this.typeOptions.push(
                    ...typeOptions.map((item: any) => {
                        return { key: item.displayName, value: item.id };
                    }),
                );
            }
        }
    }

    @action
    public setSubTypes(subTypeOptions: any[], resetFilters: boolean) {
        if (this.subTypeOptions !== null && this.subTypeOptions.length === 0) {
            if (subTypeOptions.length > 0) {
                this.subTypeOptions.push(
                    ...subTypeOptions.map((item: any) => {
                        return { key: item.displayName, value: item.id };
                    }),
                );
            }
        }
    }

    @action
    public setUOMs(uomOptions: any[], resetFilters: boolean) {
        if (this.uomOptions !== null && this.uomOptions.length === 0) {
            if (uomOptions.length > 0) {
                this.uomOptions.push(
                    ...uomOptions.map((item: any) => {
                        return { key: item.displayName, value: item.id };
                    }),
                );
            }
        }
    }

    @action
    public setSuppliers(supplierOptions: any[], resetFilters: boolean) {
        if (this.supplierOptions !== null && this.supplierOptions.length === 0) {
            if (supplierOptions.length > 0) {
                this.supplierOptions.push(
                    ...supplierOptions.map((item: any) => {
                        return { key: item.displayName, value: item.id };
                    }),
                );
            }
        }
    }

    public async isFieldValid(fieldName: keyof FieldType<MaterialsFilterParamsModel>): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
