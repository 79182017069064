import styled from "styled-components";

export const InformationBarContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 14px 30px 12px 30px;
`;

export const ProjectInformationContainer = styled.div`
    margin: 7px 0px 4px 0px;
    display: grid;
    grid-template-columns: 262px 262px;
    gap: 24px;
`;
