import { Divider } from "@material-ui/core";
import { useObserver } from "mobx-react-lite";
import React, { useState } from "react";
import styled from "styled-components";

import { pxToRem } from "Globals/Styles/AppTheme";

interface IProps {
    className?: string;
    gutterBottom?: boolean;
    gutterTop?: boolean;
    borderThickness?: number;
    borderOpacity?: number;
}

const DividerViewBase: React.FC<IProps> = (props) => {
    // #region Code Behind
    // #endregion Code Behind

    return useObserver(() => {
        return <Divider className={props.className} />;
    });
};

export const DashedDivider = styled(DividerViewBase)`
    background-color: transparent;
    border-top: ${(props) => (props.borderThickness ? props.borderThickness : 1)}px dashed
        ${(props) => (props.borderOpacity ? `rgba(206, 212, 218, ${props.borderOpacity})` : "#ced4da")};
    margin-bottom: ${(props) => (props.gutterBottom ? pxToRem(20) : 0)};
    margin-top: ${(props) => (props.gutterTop ? pxToRem(20) : 0)};
`;

export const SolidDivider = styled(DividerViewBase)`
    background-color: transparent;
    border-top: ${(props) => (props.borderThickness ? props.borderThickness : 1)}px solid
        ${(props) => (props.borderOpacity ? `rgba(206, 212, 218, ${props.borderOpacity})` : "#ced4da")};
    margin-bottom: ${(props) => (props.gutterBottom ? pxToRem(20) : 0)};
    margin-top: ${(props) => (props.gutterTop ? pxToRem(20) : 0)};
`;

export const H2TextSolidDivider = styled(DividerViewBase)`
    background-color: transparent;
    border-top: 1px solid #ced4da;
    margin-bottom: ${(props) => (props.gutterBottom ? pxToRem(20) : 0)};
    margin-top: ${pxToRem(6)};
`;
