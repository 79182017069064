import { FormHelperText, TextField, Typography } from "@material-ui/core";
import { isEmptyOrWhitespace, useRouter } from "@shoothill/core";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import styled from "styled-components";

import { SHBox } from "Components/Box";
import { DefaultButton, PrimaryButton } from "Components/Buttons/Buttons";
import { DashedSectionSplitter, SectionSplitter, SolidSectionSplitter } from "Components/Form/SectionSplitter";
import { Signature } from "Components/Signature/Signature";
import { ProjectButtonContainer } from "Views/Project/Project.styles";
import { Form } from "Views/PurchaseOrder/Form/Views/Forms";
import { InformationBarView } from "./Components/InformationBar/InformationBar";
import { NavigationBarView } from "./Components/NavigationBar/NavigationBar";
import { ScaffoldInspectionDetailView } from "./InspectionDetail/ScaffoldInspectionDetailView";
import { ScaffoldInspectionViewModel } from "./ScaffoldInspectionViewModel";

interface Props {
    className?: string;
    id: string | null;
    projectId: string;
}

const SignatureContainer = styled(SHBox)`
    display: flex;
    flex-direction: column;
    margin-top: 15px;

    > div {
        max-width: 262px;
        margin-right: 30px;
        width: 100%;

        canvas {
            border: 1px solid #d4d4d4;
        }
    }
`;

export const ScaffoldInspectionView: React.FC<Props> = observer((props: Props) => {
    const { match } = useRouter();
    const { projectid, scaffoldingId } = match.params as any;
    const [viewModel] = useState(() => new ScaffoldInspectionViewModel(projectid, scaffoldingId));

    const onSubmit = (event: any) => {
        event.preventDefault();
    };

    return (
        <Form className={props.className} onSubmit={onSubmit}>
            <NavigationBarView action={viewModel.navigateToScaffoldInspections} displayName={viewModel.navigationDisplayName} />
            <DashedSectionSplitter />

            <InformationBarView displayName={viewModel.displayName} projectName={viewModel.projectName} projectReference={viewModel.projectReference} />
            <DashedSectionSplitter />

            {/* Name of the inspector. */}
            {(() => {
                switch (viewModel.editable) {
                    case true:
                        return (
                            <SHBox style={{ margin: "17px 30px 20px 30px", width: "262px" }}>
                                <TextField
                                    className="textarea-forminput"
                                    disabled={viewModel.isFormDisabled}
                                    error={viewModel.getError("scaffoldInspectorName") !== ""}
                                    fullWidth
                                    helperText={viewModel.getError("scaffoldInspectorName")}
                                    id="inspectorname-textarea"
                                    InputLabelProps={{ shrink: true }}
                                    label="Scaffold inspector:"
                                    onBlur={() => viewModel.isFieldValid("scaffoldInspectorName")}
                                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                                        viewModel.setValue<string>("scaffoldInspectorName", event.target.value)
                                    }
                                    placeholder={`Please enter a name`}
                                    value={viewModel.getValue("scaffoldInspectorName") ?? ""}
                                />
                            </SHBox>
                        );

                    case false:
                        return (
                            <SHBox style={{ margin: "12px 30px 18px 30px" }}>
                                <Typography component="span" variant={"h5"}>
                                    Scaffold inspector:{" "}
                                </Typography>
                                <Typography component="span" style={{ fontWeight: "normal" }} variant={"h5"}>
                                    {viewModel.getValue("scaffoldInspectorName") ?? ""}
                                </Typography>
                            </SHBox>
                        );
                }
            })()}

            {/* List of scaffold details. */}
            <SHBox style={{ margin: "0px 30px 19px 30px", maxWidth: "1136px" }}>
                <SolidSectionSplitter />

                <SHBox style={{ margin: "17px 0px 0px 0px" }}>
                    <Typography variant={"h2"}>Scaffolds:</Typography>
                </SHBox>

                {viewModel.scaffoldInspectionDetailsViewModel.map((inspection, index) => {
                    return (
                        <ScaffoldInspectionDetailView index={index} key={inspection.KEY} scaffoldInspectionViewModel={viewModel} scaffoldInspectionDetailViewModel={inspection} />
                    );
                })}

                {viewModel.editable && (
                    <React.Fragment>
                        <SectionSplitter />
                        <PrimaryButton execute={viewModel.addNewScaffold} displayName={"Add another scaffold"} canExecute={!viewModel.server.IsBusy} />
                    </React.Fragment>
                )}
            </SHBox>

            {/* Authorisation */}
            <SHBox style={{ margin: "0px 30px 15px 30px", maxWidth: "1136px" }}>
                <SolidSectionSplitter />

                <SHBox style={{ margin: "17px 0px 0px 0px" }}>
                    <Typography variant={"h3"}>Authorisation</Typography>
                </SHBox>
                <SHBox style={{ margin: "14px 0px 7px 0px" }}>
                    <Typography style={{ fontWeight: "normal" }} variant={"h5"}>
                        Checks should only be carried out by individuals who are fully competent and trained in scaffold inspection.
                    </Typography>
                </SHBox>

                {/* If the form has been submitted, we want to show the audit information. */}
                {viewModel.isFormDisabled && (
                    <SHBox mt={3} mb={3} grid dc={"1fr 1fr 1fr"}>
                        <div>
                            <Typography component="span" variant={"h5"}>
                                Auditor:{" "}
                            </Typography>
                            <Typography component="span" style={{ fontWeight: "normal" }} variant={"h5"}>
                                {viewModel.getUserName(viewModel.model.auditorUserId) ?? ""}
                            </Typography>
                        </div>
                        <div>
                            <Typography component="span" variant={"h5"}>
                                Date / time added:{" "}
                            </Typography>
                            <Typography component="span" style={{ fontWeight: "normal" }} variant={"h5"}>
                                {viewModel.model.formattedAuditorCreationDate}
                            </Typography>
                        </div>
                    </SHBox>
                )}

                {/* Add a signature, or display it as readonly if the form is disabled. */}
                <SignatureContainer grid dc="1fr 1fr">
                    <div>
                        <Signature
                            displayName={"Auditor signature:"}
                            execute={viewModel.updateSignature}
                            value={viewModel.model.signatureBase64}
                            canExecute={!viewModel.isFormDisabled}
                        />
                        {!isEmptyOrWhitespace(viewModel.getError("signatureBase64")) && (
                            <FormHelperText style={{ color: "#f44336" }}>{viewModel.getError("signatureBase64")}</FormHelperText>
                        )}
                    </div>
                </SignatureContainer>
            </SHBox>

            {/* Form buttons */}
            <SHBox showIf={viewModel.editable}>
                <SolidSectionSplitter />

                <ProjectButtonContainer style={{ marginTop: "20px", paddingBottom: "20px" }}>
                    <PrimaryButton execute={viewModel.submit} displayName={"Submit"} disabled={viewModel.server.IsBusy} />
                    <DefaultButton execute={viewModel.navigateToScaffoldInspections} displayName={"Cancel"} canExecute={!viewModel.server.IsBusy} />
                </ProjectButtonContainer>
            </SHBox>
        </Form>
    );
});
