import React, { useState } from "react";
import { useObserver } from "mobx-react-lite";
import { BaseModal } from "Components/Modal/BaseModal";
import { CancelButton } from "Globals/Styles/Control/Buttons";
import { Box, Button, Typography } from "@material-ui/core";
import { Cell, Grid } from "@shoothill/core";
import { RFIStatusViewModel } from "./RFIStatusViewModel";
import { ReportModalStyle } from "Views/Project/Modals/Modal.Styles";

interface ICreatedModalProps {
    open?: boolean;
    onClose(): void;
    onYes(): void;
    rFIId: string | null;
}

export const RFIStatusView: React.FC<ICreatedModalProps> = (props) => {
    const { open, onClose, onYes, rFIId } = props;
    const [viewModel] = useState(() => new RFIStatusViewModel(rFIId));
    const COLUMNS8 = "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr";

    const onYesClicked = () => {
        onYes();
    };

    return useObserver(() => (
        <BaseModal
            open={open}
            onClose={onClose}
            title={"Status change"}
            PaperComponent={ReportModalStyle}
            actions={
                <>
                    <Button onClick={onYesClicked} color="secondary" autoFocus variant="contained">
                        Yes
                    </Button>
                    <CancelButton onClick={onClose}>No</CancelButton>
                </>
            }
        >
            <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8} mb="35px">
                <Cell ts={8} ds={8}>
                    <Box>
                        <Typography style={{ margin: "17px 0px 13px" }} variant="h5">
                            Some text
                        </Typography>
                    </Box>
                </Cell>
            </Grid>
        </BaseModal>
    ));
};
