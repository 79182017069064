import { ModelBase } from "@shoothill/core";
import { IsNotEmpty, ValidateIf } from "class-validator";
import { observable, runInAction } from "mobx";
import { AuditDocumentModel } from "../AuditDocument";
import { FireExtinguisherTypeLookupModel } from "./FireExtinguisherTypeLookupModel";

export class FireExtinguishersModel extends ModelBase<FireExtinguishersModel> {
    public id: string | null = null;
    @observable
    @IsNotEmpty({ message: "Please enter a location" })
    public location: string = "";
    @observable
    @IsNotEmpty({ message: "Please select yes or no" })
    public typeOfExtinguisher: string = "";

    @IsNotEmpty({ message: "Please select yes or no" })
    public typeOfExtinguisherModel: FireExtinguisherTypeLookupModel = new FireExtinguisherTypeLookupModel();
    @observable
    @IsNotEmpty({ message: "Please select yes or no" })
    public servicedWithinLast12Months: string = "";
    @observable
    @ValidateIf((o) => o.servicedWithinLast12Months === "no")
    @IsNotEmpty({ message: "Please enter more detail" })
    public servicedWithinLast12MonthsDetail: string = "";
    @observable
    @IsNotEmpty({ message: "Please select yes or no" })
    public pinAndSecurityTagsInPlace: string = "";
    @observable
    @ValidateIf((o) => o.pinAndSecurityTagsInPlace === "no")
    @IsNotEmpty({ message: "Please enter more detail" })
    public pinAndSecurityTagsInPlaceDetail: string = "";
    @IsNotEmpty({ message: "Please select yes or no" })
    public eitherOnABracketOrStand: string = "";
    @observable
    @ValidateIf((o) => o.eitherOnABracketOrStand === "no")
    @IsNotEmpty({ message: "Please enter more detail" })
    public eitherOnABracketOrStandDetail: string = "";
    @observable
    @IsNotEmpty({ message: "Please select yes or no" })
    public extinguisherClean: string = "";
    @observable
    @ValidateIf((o) => o.extinguisherClean === "no")
    @IsNotEmpty({ message: "Please enter more detail" })
    public extinguisherCleanDetail: string = "";
    @observable
    public auditId: string | null = null;
    @observable
    public auditDocuments: AuditDocumentModel[] = [];
    constructor() {
        super();
    }

    fromDto(model: any): void {
        this.id = model.id;
        this.location = model.location;
        this.typeOfExtinguisher = model.typeOfExtinguisher;
        this.servicedWithinLast12Months = model.servicedWithinLast12Months ? "yes" : "no";
        this.pinAndSecurityTagsInPlace = model.pinAndSecurityTagsInPlace ? "yes" : "no";
        this.eitherOnABracketOrStand = model.eitherOnABracketOrStand ? "yes" : "no";
        this.extinguisherClean = model.extinguisherClean ? "yes" : "no";
        this.auditId = model.auditId;
        this.servicedWithinLast12MonthsDetail = model.servicedWithinLast12MonthsDetail;
        this.pinAndSecurityTagsInPlaceDetail = model.pinAndSecurityTagsInPlaceDetail;
        this.eitherOnABracketOrStandDetail = model.eitherOnABracketOrStandDetail;
        this.extinguisherCleanDetail = model.extinguisherCleanDetail;
        if (model.auditDocuments) {
            runInAction(() => {
                this.auditDocuments = model.auditDocuments.map((x: any) => {
                    const auditDocument = new AuditDocumentModel();
                    auditDocument.fromDto(x);
                    return auditDocument;
                });
            });
        }
    }

    toDto() {
        return {
            id: this.id,
            location: this.location,
            typeOfExtinguisher: this.typeOfExtinguisher,
            servicedWithinLast12Months: this.servicedWithinLast12Months === "yes" ? true : false,
            pinAndSecurityTagsInPlace: this.pinAndSecurityTagsInPlace === "yes" ? true : false,
            eitherOnABracketOrStand: this.eitherOnABracketOrStand === "yes" ? true : false,
            extinguisherClean: this.extinguisherClean === "yes" ? true : false,
            auditId: this.auditId,
            auditDocuments: this.auditDocuments,
            servicedWithinLast12MonthsDetail: this.servicedWithinLast12MonthsDetail,
            pinAndSecurityTagsInPlaceDetail: this.pinAndSecurityTagsInPlaceDetail,
            eitherOnABracketOrStandDetail: this.eitherOnABracketOrStandDetail,
            extinguisherCleanDetail: this.extinguisherCleanDetail,
        };
    }
}
