import { ModelBase } from "@shoothill/core";
import { computed, observable, action } from "mobx";
import { IncidentUserDTO, GenericListItemDTO } from "../WitnessStatement/IncidentFormWitnessStatementModel";
import { IncidentDTO } from "../IncidentModel";
import { GenericPhotoListItemDTO, GenericPhotoListItemModel } from "../Supporting/GenericPhotoListItemModel";
import { GenericFreeTextListItemDTO, GenericFreeTextListItemModel } from "../Supporting/GenericFreeTextListItemModel";
import { AccidentPhotoListItemDTO, AccidentPhotoListItemModel } from "./Supporting/AccidentPhotoListItemModel";
import { AccidentInjuryAreaItemDTO, AccidentInjuryAreaItemModel } from "./Supporting/AccidentInjuryAreaItemModel";
import { AccidentInjuryTypeItemDTO, AccidentInjuryTypeItemModel } from "./Supporting/AccidentInjuryTypeItemModel";
import { AccidentWitnessItemDTO, AccidentWitnessItemModel } from "./Supporting/AccidentWitnessItemModel";

export class AccidentFormModel extends ModelBase<AccidentFormModel> {
    public static readonly DEFAULT_WHICH_PART_OF_WORK_PROCESS: string | null = null;
    public static readonly DEFAULT_DESCRIPTION_OF_ENVIRONMENT: string | null = null;
    public static readonly DEFAULT_DESCRIPTION_OF_EQUIPMENT: string | null = null;
    public static readonly DEFAULT_PPE_REQUIRED: string | null = null;
    public static readonly DEFAULT_PPE_WORN: string | null = null;
    public static readonly DEFAULT_DEFECTIVE_WORKPLACE: boolean | null = null;
    public static readonly DEFAULT_LIGHTING: boolean | null = null;
    public static readonly DEFAULT_DESIGN_OR_LAYOUT: boolean | null = null;
    public static readonly DEFAULT_NOISE_OR_DISTRACTION: boolean | null = null;
    public static readonly DEFAULT_HOUSEKEEPING: boolean | null = null;
    public static readonly DEFAULT_WEATHER: boolean | null = null;
    public static readonly DEFAULT_LACK_OF_ROOM: boolean | null = null;
    public static readonly DEFAULT_ACCESS_OR_EGRESS: boolean | null = null;
    public static readonly DEFAULT_FAILURE_TO_FOLLOW_RULES: boolean | null = null;
    public static readonly DEFAULT_LACK_OF_EXPERIENCE: boolean | null = null;
    public static readonly DEFAULT_FAILURE_TO_ADHERE_TO_RISK_ASSESSMENT: boolean | null = null;
    public static readonly DEFAULT_FATIGUE: boolean | null = null;
    public static readonly DEFAULT_INSTRUCTIONS_MISUNDERSTOOD: boolean | null = null;
    public static readonly DEFAULT_UNSAFE_ATTITUDE: boolean | null = null;
    public static readonly DEFAULT_HORSEPLAY: boolean | null = null;
    public static readonly DEFAULT_WORKING_WITHOUT_AUTHORISATION: boolean | null = null;
    public static readonly DEFAULT_ERROR_OF_JUDGEMENT: boolean | null = null;
    public static readonly DEFAULT_UNDUE_HASTE: boolean | null = null;
    public static readonly DEFAULT_LAPSE_OF_CONCENTRATION: boolean | null = null;
    public static readonly DEFAULT_DESIGN: boolean | null = null;
    public static readonly DEFAULT_POORLY_MAINTAINED_OR_DEFECTIVE: boolean | null = null;
    public static readonly DEFAULT_NOT_USED: boolean | null = null;
    public static readonly DEFAULT_WRONG_TYPE_USED: boolean | null = null;
    public static readonly DEFAULT_NOT_PROVIDED_OR_UNAVAILABLE: boolean | null = null;
    public static readonly DEFAULT_NON_COMMUNICATION_OF_RISK: boolean | null = null;
    public static readonly DEFAULT_SUPERVISION: boolean | null = null;
    public static readonly DEFAULT_TRAINING: boolean | null = null;
    public static readonly DEFAULT_SYSTEM_FAILURE: boolean | null = null;
    public static readonly DEFAULT_CONSTRUCTION_OR_DESIGN: boolean | null = null;
    public static readonly DEFAULT_SAFETY_DEVICE: boolean | null = null;
    public static readonly DEFAULT_MECHANICAL_FAILURE: boolean | null = null;
    public static readonly DEFAULT_INSTALLATION: boolean | null = null;
    public static readonly DEFAULT_OPERATION_OR_USE: boolean | null = null;
    public static readonly DEFAULT_THIRD_PARTY: boolean | null = null;
    public static readonly DEFAULT_UNDER_INVESTIGATION: boolean | null = null;
    public static readonly DEFAULT_ACTIONS_TO_RECOVER: string | null = null;
    public static readonly DEFAULT_ACTIONS_TO_PREVENT: string | null = null;
    public static readonly DEFAULT_LAST_UPDATED_BY_USER_ID: string | null = null;
    public static readonly DEFAULT_LAST_UPDATED_DATE: Date | null = null;

    @observable
    public id: string | null = null;
    @observable
    public createdDate: Date | null = null;
    @observable
    public createdByUserId: string | null = null;
    @observable
    public isDeleted: boolean = false;
    @observable
    public investigationDate: Date | null = null;
    @observable
    public incidentId: string = "";
    @observable
    public casualtyUserId: string = "";
    @observable
    public casualtyNotInSystem: string | null = null;
    @observable
    public casualtySignatureBase64: string = "";
    @observable
    public casualtySignatureDate: Date | null = null;
    @observable
    public casualtyDateOfBirth: Date | null = null;
    @observable
    public firstAiderUserId: string | null = null;
    @observable
    public firstAiderNameNotInSystem: string = "";
    @observable
    public firstAiderSignature: string = "";
    @observable
    public firstAiderSignatureDate: Date | null = null;
    @observable
    public statusOfCasualtyId: string | null = null;
    @observable
    public casualtyPhoneNumber: string | null = null;
    @observable
    public casualtyEmailAddress: string | null = null;
    @observable
    public addressLine1: string = "";
    @observable
    public addressLine2: string = "";
    @observable
    public addressLine3: string = "";
    @observable
    public cityTown: string = "";
    @observable
    public postcode: string = "";
    // @observable
    // public accidentLocation: string = "";
    @observable
    public accidentDate: Date | null = null;
    @observable
    public location: string = "";
    @observable
    public whatHappened: string = "";
    @observable
    public descriptionOfInjury: string = "";
    @observable
    public descriptionOfTreatment: string = "";
    public witnessIds = observable<string>([]);
    public witnessNotInSystem = observable<string>([]);
    //public injuryIds = observable<string>([]);

    @observable
    public hasInvestigationStarted: boolean = false;

    @observable
    public investigatorUserId: string | null = null;

    @observable
    public hasInvestigationEnded: boolean = false;

    @observable
    public investigationEndDate: Date | null = null;

    @observable
    public rowVersion: string | null = null;

    @observable
    public descriptionOfInjuryFurtherInfo: string | null = null;

    @observable
    public additionalInjuriesOrWorsening: string | null = null;

    @observable
    public fatality: boolean | null = null;

    @observable
    public unconscious: boolean | null = null;

    @observable
    public resuscitation: boolean | null = null;

    @observable
    public advisedToSeeDoctor: boolean | null = null;

    @observable
    public whichMedicalPracticeId: string | null = null;

    @observable
    public alcoholOrDrugTestingRequired: boolean | null = null;

    @observable
    public alcoholOrDrugTestingRequiredResultId: string | null = null;

    @observable
    public causeOfAccidentTypeId: string | null = null;

    @observable
    public otherCauseOfAccident: string | null = null;

    @observable
    public fallHeight: number | null = null;

    @observable
    public sourceOfHazardTypeId: string | null = null;

    @observable
    public otherSourceOfHazard: string | null = null;

    @observable
    public wasThereARiskAssessment: boolean | null = null;

    @observable
    public wasThereAMethodStatement: boolean | null = null;

    @observable
    public wasTheAccidentRIDDORReportable: boolean | null = null;

    @observable
    public dateRIDDORWasSent: Date | null = null;

    @observable
    public wasAnyTimeLost: boolean | null = null;

    @observable
    public dateWorkFinished: Date | null = null;

    @observable
    public dateWorkRestarted: Date | null = null;

    @observable
    public totalTimeLost: number | null = null;

    @observable
    public whichPartOfWorkProcess: string | null = AccidentFormModel.DEFAULT_WHICH_PART_OF_WORK_PROCESS;

    @observable
    public descriptionOfEnvironment: string | null = AccidentFormModel.DEFAULT_DESCRIPTION_OF_ENVIRONMENT;

    @observable
    public descriptionOfEquipment: string | null = AccidentFormModel.DEFAULT_DESCRIPTION_OF_EQUIPMENT;

    @observable
    public ppeRequired: string | null = AccidentFormModel.DEFAULT_PPE_REQUIRED;

    @observable
    public ppeWorn: string | null = AccidentFormModel.DEFAULT_PPE_WORN;

    @observable
    public defectiveWorkplace: boolean | null = AccidentFormModel.DEFAULT_DEFECTIVE_WORKPLACE;

    @observable
    public lighting: boolean | null = AccidentFormModel.DEFAULT_LIGHTING;

    @observable
    public designOrLayout: boolean | null = AccidentFormModel.DEFAULT_DESIGN_OR_LAYOUT;

    @observable
    public noiseOrDistraction: boolean | null = AccidentFormModel.DEFAULT_NOISE_OR_DISTRACTION;

    @observable
    public housekeeping: boolean | null = AccidentFormModel.DEFAULT_HOUSEKEEPING;

    @observable
    public weather: boolean | null = AccidentFormModel.DEFAULT_WEATHER;

    @observable
    public lackOfRoom: boolean | null = AccidentFormModel.DEFAULT_LACK_OF_ROOM;

    @observable
    public accessOrEgress: boolean | null = AccidentFormModel.DEFAULT_ACCESS_OR_EGRESS;

    @observable
    public failureToFollowRules: boolean | null = AccidentFormModel.DEFAULT_FAILURE_TO_FOLLOW_RULES;

    @observable
    public lackOfExperience: boolean | null = AccidentFormModel.DEFAULT_LACK_OF_EXPERIENCE;

    @observable
    public failureToAdhereToRiskAssessment: boolean | null = AccidentFormModel.DEFAULT_FAILURE_TO_ADHERE_TO_RISK_ASSESSMENT;

    @observable
    public fatigue: boolean | null = AccidentFormModel.DEFAULT_FATIGUE;

    @observable
    public instructionsMisunderstood: boolean | null = AccidentFormModel.DEFAULT_INSTRUCTIONS_MISUNDERSTOOD;

    @observable
    public unsafeAttitude: boolean | null = AccidentFormModel.DEFAULT_UNSAFE_ATTITUDE;

    @observable
    public horseplay: boolean | null = AccidentFormModel.DEFAULT_HORSEPLAY;

    @observable
    public workingWithoutAuthorisation: boolean | null = AccidentFormModel.DEFAULT_WORKING_WITHOUT_AUTHORISATION;

    @observable
    public errorOfJudgement: boolean | null = AccidentFormModel.DEFAULT_ERROR_OF_JUDGEMENT;

    @observable
    public undueHaste: boolean | null = AccidentFormModel.DEFAULT_UNDUE_HASTE;

    @observable
    public lapseOfConcentration: boolean | null = AccidentFormModel.DEFAULT_LAPSE_OF_CONCENTRATION;

    @observable
    public design: boolean | null = AccidentFormModel.DEFAULT_DESIGN;

    @observable
    public poorlyMaintainedOrDefective: boolean | null = AccidentFormModel.DEFAULT_POORLY_MAINTAINED_OR_DEFECTIVE;

    @observable
    public notUsed: boolean | null = AccidentFormModel.DEFAULT_NOT_USED;

    @observable
    public wrongTypeUsed: boolean | null = AccidentFormModel.DEFAULT_WRONG_TYPE_USED;

    @observable
    public notProvidedOrUnavailable: boolean | null = AccidentFormModel.DEFAULT_NOT_PROVIDED_OR_UNAVAILABLE;

    @observable
    public nonCommunicationOfRisk: boolean | null = AccidentFormModel.DEFAULT_NON_COMMUNICATION_OF_RISK;

    @observable
    public supervision: boolean | null = AccidentFormModel.DEFAULT_SUPERVISION;

    @observable
    public training: boolean | null = AccidentFormModel.DEFAULT_TRAINING;

    @observable
    public systemFailure: boolean | null = AccidentFormModel.DEFAULT_SYSTEM_FAILURE;

    @observable
    public constructionOrDesign: boolean | null = AccidentFormModel.DEFAULT_CONSTRUCTION_OR_DESIGN;

    @observable
    public safetyDevice: boolean | null = AccidentFormModel.DEFAULT_SAFETY_DEVICE;

    @observable
    public mechanicalFailure: boolean | null = AccidentFormModel.DEFAULT_MECHANICAL_FAILURE;

    @observable
    public installation: boolean | null = AccidentFormModel.DEFAULT_INSTALLATION;

    @observable
    public operationOrUse: boolean | null = AccidentFormModel.DEFAULT_OPERATION_OR_USE;

    @observable
    public thirdParty: boolean | null = AccidentFormModel.DEFAULT_THIRD_PARTY;

    @observable
    public underInvestigation: boolean | null = AccidentFormModel.DEFAULT_UNDER_INVESTIGATION;

    @observable
    public actionsToRecover: string | null = AccidentFormModel.DEFAULT_ACTIONS_TO_RECOVER;

    @observable
    public actionsToPrevent: string | null = AccidentFormModel.DEFAULT_ACTIONS_TO_PREVENT;

    @observable
    public lastUpdatedByUserId: string | null = AccidentFormModel.DEFAULT_LAST_UPDATED_BY_USER_ID;

    @observable
    public lastUpdatedDate: Date | null = AccidentFormModel.DEFAULT_LAST_UPDATED_DATE;

    @observable
    public incidentFormAccidentPhotos: AccidentPhotoListItemModel[] = [];

    @observable
    public incidentFormAccidentRootCauses: GenericFreeTextListItemModel[] = [];

    @observable
    public incidentFormAccidentInjuryAreas: AccidentInjuryAreaItemModel[] = [];

    @observable
    public incidentFormAccidentInjuryTypes: AccidentInjuryTypeItemModel[] = [];

    @observable
    public incidentFormAccidentWitnesses: AccidentWitnessItemModel[] = [];

    public readonly notInSystemOption: CasualtyUserDTO = { id: "notInSystem", displayName: "Not in system" };

    @computed
    public get hasId(): boolean {
        return this.id !== null && this.id !== undefined && this.id !== "";
    }
    constructor() {
        super();
    }

    @action
    public reset = () => {
        this.id = null;
        this.incidentId = "";
        this.createdByUserId = null;
        this.createdDate = null;
        this.isDeleted = false;
        this.rowVersion = null;
        this.statusOfCasualtyId = null;
        this.casualtyPhoneNumber = null;
        this.casualtyEmailAddress = null;
        this.location = "";
        this.whatHappened = "";
        this.hasInvestigationStarted = false;
        this.investigationDate = null;
        this.investigatorUserId = null;
        this.hasInvestigationEnded = false;
        this.investigationEndDate = null;
        this.descriptionOfInjuryFurtherInfo = null;
        this.additionalInjuriesOrWorsening = null;
        this.fatality = null;
        this.unconscious = null;
        this.resuscitation = null;
        this.advisedToSeeDoctor = null;
        this.whichMedicalPracticeId = null;
        this.alcoholOrDrugTestingRequired = null;
        this.alcoholOrDrugTestingRequiredResultId = null;
        this.causeOfAccidentTypeId = null;
        this.otherCauseOfAccident = null;
        this.fallHeight = null;
        this.sourceOfHazardTypeId = null;
        this.otherSourceOfHazard = null;
        this.wasThereARiskAssessment = null;
        this.wasThereAMethodStatement = null;
        this.wasTheAccidentRIDDORReportable = null;
        this.dateRIDDORWasSent = null;
        this.wasAnyTimeLost = null;
        this.dateWorkFinished = null;
        this.dateWorkRestarted = null;
        this.totalTimeLost = null;
        this.whichPartOfWorkProcess = AccidentFormModel.DEFAULT_WHICH_PART_OF_WORK_PROCESS;
        this.descriptionOfEnvironment = AccidentFormModel.DEFAULT_DESCRIPTION_OF_ENVIRONMENT;
        this.descriptionOfEquipment = AccidentFormModel.DEFAULT_DESCRIPTION_OF_EQUIPMENT;
        this.ppeRequired = AccidentFormModel.DEFAULT_PPE_REQUIRED;
        this.ppeWorn = AccidentFormModel.DEFAULT_PPE_WORN;
        this.defectiveWorkplace = AccidentFormModel.DEFAULT_DEFECTIVE_WORKPLACE;
        this.lighting = AccidentFormModel.DEFAULT_LIGHTING;
        this.designOrLayout = AccidentFormModel.DEFAULT_DESIGN_OR_LAYOUT;
        this.noiseOrDistraction = AccidentFormModel.DEFAULT_NOISE_OR_DISTRACTION;
        this.housekeeping = AccidentFormModel.DEFAULT_HOUSEKEEPING;
        this.weather = AccidentFormModel.DEFAULT_WEATHER;
        this.lackOfRoom = AccidentFormModel.DEFAULT_LACK_OF_ROOM;
        this.accessOrEgress = AccidentFormModel.DEFAULT_ACCESS_OR_EGRESS;
        this.failureToFollowRules = AccidentFormModel.DEFAULT_FAILURE_TO_FOLLOW_RULES;
        this.lackOfExperience = AccidentFormModel.DEFAULT_LACK_OF_EXPERIENCE;
        this.failureToAdhereToRiskAssessment = AccidentFormModel.DEFAULT_FAILURE_TO_ADHERE_TO_RISK_ASSESSMENT;
        this.fatigue = AccidentFormModel.DEFAULT_FATIGUE;
        this.instructionsMisunderstood = AccidentFormModel.DEFAULT_INSTRUCTIONS_MISUNDERSTOOD;
        this.unsafeAttitude = AccidentFormModel.DEFAULT_UNSAFE_ATTITUDE;
        this.horseplay = AccidentFormModel.DEFAULT_HORSEPLAY;
        this.workingWithoutAuthorisation = AccidentFormModel.DEFAULT_WORKING_WITHOUT_AUTHORISATION;
        this.errorOfJudgement = AccidentFormModel.DEFAULT_ERROR_OF_JUDGEMENT;
        this.undueHaste = AccidentFormModel.DEFAULT_UNDUE_HASTE;
        this.lapseOfConcentration = AccidentFormModel.DEFAULT_LAPSE_OF_CONCENTRATION;
        this.design = AccidentFormModel.DEFAULT_DESIGN;
        this.poorlyMaintainedOrDefective = AccidentFormModel.DEFAULT_POORLY_MAINTAINED_OR_DEFECTIVE;
        this.notUsed = AccidentFormModel.DEFAULT_NOT_USED;
        this.wrongTypeUsed = AccidentFormModel.DEFAULT_WRONG_TYPE_USED;
        this.notProvidedOrUnavailable = AccidentFormModel.DEFAULT_NOT_PROVIDED_OR_UNAVAILABLE;
        this.nonCommunicationOfRisk = AccidentFormModel.DEFAULT_NON_COMMUNICATION_OF_RISK;
        this.supervision = AccidentFormModel.DEFAULT_SUPERVISION;
        this.training = AccidentFormModel.DEFAULT_TRAINING;
        this.systemFailure = AccidentFormModel.DEFAULT_SYSTEM_FAILURE;
        this.constructionOrDesign = AccidentFormModel.DEFAULT_CONSTRUCTION_OR_DESIGN;
        this.safetyDevice = AccidentFormModel.DEFAULT_SAFETY_DEVICE;
        this.mechanicalFailure = AccidentFormModel.DEFAULT_MECHANICAL_FAILURE;
        this.installation = AccidentFormModel.DEFAULT_INSTALLATION;
        this.operationOrUse = AccidentFormModel.DEFAULT_OPERATION_OR_USE;
        this.thirdParty = AccidentFormModel.DEFAULT_THIRD_PARTY;
        this.underInvestigation = AccidentFormModel.DEFAULT_UNDER_INVESTIGATION;
        this.actionsToRecover = AccidentFormModel.DEFAULT_ACTIONS_TO_RECOVER;
        this.actionsToPrevent = AccidentFormModel.DEFAULT_ACTIONS_TO_PREVENT;
        this.lastUpdatedByUserId = AccidentFormModel.DEFAULT_LAST_UPDATED_BY_USER_ID;
        this.lastUpdatedDate = AccidentFormModel.DEFAULT_LAST_UPDATED_DATE;

        this.incidentFormAccidentPhotos.length = 0;
        this.incidentFormAccidentRootCauses.length = 0;
        this.incidentFormAccidentInjuryAreas.length = 0;
        this.incidentFormAccidentInjuryTypes.length = 0;
        this.incidentFormAccidentWitnesses.length = 0;
    };

    @action
    public fromDto(dto: IncidentFormAccidentAndRelatedResponseDTO): void {
        const accidentDto = dto.incidentFormAccident;

        for (let key in accidentDto) {
            if (accidentDto.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(accidentDto[key]);
                } else {
                    this[key] = accidentDto[key];
                }
            }
        }

        this.incidentFormAccidentPhotos.length = 0;
        this.incidentFormAccidentRootCauses.length = 0;
        this.incidentFormAccidentInjuryAreas.length = 0;
        this.incidentFormAccidentInjuryTypes.length = 0;
        this.incidentFormAccidentWitnesses.length = 0;

        // Manually process the child array otherwise we will end up with an array of dtos being stored in the array of models.
        let processedPhotos: AccidentPhotoListItemModel[] = [];
        for (const photo of dto.incidentFormAccidentDocuments) {
            const photoToAdd = new AccidentPhotoListItemModel();
            photoToAdd.fromDto(photo);
            processedPhotos.push(photoToAdd);
        }
        this.incidentFormAccidentPhotos = [...this.incidentFormAccidentPhotos, ...processedPhotos];

        let processedCauses: GenericFreeTextListItemModel[] = [];
        for (const cause of dto.incidentFormAccidentRootCauses) {
            const causeToAdd = new GenericFreeTextListItemModel();
            causeToAdd.fromDto(cause);
            processedCauses.push(causeToAdd);
        }
        this.incidentFormAccidentRootCauses = [...this.incidentFormAccidentRootCauses, ...processedCauses];

        let processedInjuryAreas: AccidentInjuryAreaItemModel[] = [];
        for (const injuryArea of dto.incidentFormAccidentInjuryAreas) {
            const areaToAdd = new AccidentInjuryAreaItemModel();
            areaToAdd.fromDto(injuryArea);
            processedInjuryAreas.push(areaToAdd);
        }
        this.incidentFormAccidentInjuryAreas = [...this.incidentFormAccidentInjuryAreas, ...processedInjuryAreas];

        let processedInjuryTypes: AccidentInjuryTypeItemModel[] = [];
        for (const injuryType of dto.incidentFormAccidentInjuryTypes) {
            const injuryTypeToAdd = new AccidentInjuryTypeItemModel();
            injuryTypeToAdd.fromDto(injuryType);
            processedInjuryTypes.push(injuryTypeToAdd);
        }
        this.incidentFormAccidentInjuryTypes = [...this.incidentFormAccidentInjuryTypes, ...processedInjuryTypes];

        let processedWitnesses: AccidentWitnessItemModel[] = [];
        for (const witness of dto.incidentFormAccidentWitnesses) {
            const witnessToAdd = new AccidentWitnessItemModel();
            witnessToAdd.fromDto(witness);
            processedWitnesses.push(witnessToAdd);
        }
        this.incidentFormAccidentWitnesses = [...this.incidentFormAccidentWitnesses, ...processedWitnesses];
    }

    public toDto() {
        const nearMissModel: IncidentFormAccidentDTO = {
            id: this.id,
            incidentId: this.incidentId,
            createdByUserId: this.createdByUserId,
            createdDate: this.createdDate,
            isDeleted: this.isDeleted,
            lastUpdatedByUserId: this.lastUpdatedByUserId,
            lastUpdatedDate: this.lastUpdatedDate,
            hasInvestigationStarted: this.hasInvestigationStarted,
            investigationDate: this.investigationDate,
            investigatorUserId: this.investigatorUserId,
            hasInvestigationEnded: this.hasInvestigationEnded,
            investigationEndDate: this.investigationEndDate,
            rowVersion: this.rowVersion,
            location: this.location,
            whatHappened: this.whatHappened,
            casualtyUserId: this.casualtyUserId,
            casualtyDateOfBirth: this.casualtyDateOfBirth,
            statusOfCasualtyId: this.statusOfCasualtyId,
            casualtyPhoneNumber: this.casualtyPhoneNumber,
            casualtyEmailAddress: this.casualtyEmailAddress,
            addressLine1: this.addressLine1,
            addressLine2: this.addressLine2,
            addressLine3: this.addressLine3,
            cityTown: this.cityTown,
            postcode: this.postcode,
            casualtyNotInSystem: this.casualtyNotInSystem,
            accidentDate: this.accidentDate,
            //accidentDetails: this.accidentDetails,
            descriptionOfInjury: this.descriptionOfInjury,
            descriptionOfTreatment: this.descriptionOfTreatment,
            casualtySignatureBase64: this.casualtySignatureBase64,
            firstAiderSignature: this.firstAiderSignature,
            descriptionOfInjuryFurtherInfo: this.descriptionOfInjuryFurtherInfo,
            additionalInjuriesOrWorsening: this.additionalInjuriesOrWorsening,
            fatality: this.fatality,
            unconscious: this.unconscious,
            resuscitation: this.resuscitation,
            advisedToSeeDoctor: this.advisedToSeeDoctor,
            whichMedicalPracticeId: this.whichMedicalPracticeId,
            alcoholOrDrugTestingRequired: this.alcoholOrDrugTestingRequired,
            alcoholOrDrugTestingRequiredResultId: this.alcoholOrDrugTestingRequiredResultId,
            causeOfAccidentTypeId: this.causeOfAccidentTypeId,
            otherCauseOfAccident: this.otherCauseOfAccident,
            fallHeight: this.fallHeight,
            sourceOfHazardTypeId: this.sourceOfHazardTypeId,
            otherSourceOfHazard: this.otherSourceOfHazard,
            wasThereARiskAssessment: this.wasThereARiskAssessment,
            wasThereAMethodStatement: this.wasThereAMethodStatement,
            wasTheAccidentRIDDORReportable: this.wasTheAccidentRIDDORReportable,
            dateRIDDORWasSent: this.dateRIDDORWasSent,
            wasAnyTimeLost: this.wasAnyTimeLost,
            dateWorkFinished: this.dateWorkFinished,
            dateWorkRestarted: this.dateWorkRestarted,
            totalTimeLost: this.totalTimeLost,
            whichPartOfWorkProcess: this.whichPartOfWorkProcess,
            descriptionOfEnvironment: this.descriptionOfEnvironment,
            descriptionOfEquipment: this.descriptionOfEquipment,
            ppeRequired: this.ppeRequired,
            ppeWorn: this.ppeWorn,
            defectiveWorkplace: this.defectiveWorkplace,
            lighting: this.lighting,
            designOrLayout: this.designOrLayout,
            noiseOrDistraction: this.noiseOrDistraction,
            housekeeping: this.housekeeping,
            weather: this.weather,
            lackOfRoom: this.lackOfRoom,
            accessOrEgress: this.accessOrEgress,
            failureToFollowRules: this.failureToFollowRules,
            lackOfExperience: this.lackOfExperience,
            failureToAdhereToRiskAssessment: this.failureToAdhereToRiskAssessment,
            fatigue: this.fatigue,
            instructionsMisunderstood: this.instructionsMisunderstood,
            unsafeAttitude: this.unsafeAttitude,
            horseplay: this.horseplay,
            workingWithoutAuthorisation: this.workingWithoutAuthorisation,
            errorOfJudgement: this.errorOfJudgement,
            undueHaste: this.undueHaste,
            lapseOfConcentration: this.lapseOfConcentration,
            design: this.design,
            poorlyMaintainedOrDefective: this.poorlyMaintainedOrDefective,
            notUsed: this.notUsed,
            wrongTypeUsed: this.wrongTypeUsed,
            notProvidedOrUnavailable: this.notProvidedOrUnavailable,
            nonCommunicationOfRisk: this.nonCommunicationOfRisk,
            supervision: this.supervision,
            training: this.training,
            systemFailure: this.systemFailure,
            constructionOrDesign: this.constructionOrDesign,
            safetyDevice: this.safetyDevice,
            mechanicalFailure: this.mechanicalFailure,
            installation: this.installation,
            operationOrUse: this.operationOrUse,
            thirdParty: this.thirdParty,
            underInvestigation: this.underInvestigation,
            actionsToRecover: this.actionsToRecover,
            actionsToPrevent: this.actionsToPrevent,
        };

        return nearMissModel;
    }

    @computed
    public get validateDefectiveWorkplace(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.defectiveWorkplace === AccidentFormModel.DEFAULT_DEFECTIVE_WORKPLACE ? "Please indicate whether there were any defects in the workplace" : "";
    }

    @computed
    public get validateLighting(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.lighting === AccidentFormModel.DEFAULT_LIGHTING ? "Please indicate whether lighting was a factor" : "";
    }

    @computed
    public get validateDesignOrLayout(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.designOrLayout === AccidentFormModel.DEFAULT_DESIGN_OR_LAYOUT ? "Please indicate whether design or layout was a factor" : "";
    }

    @computed
    public get validateNoiseOrDistraction(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.noiseOrDistraction === AccidentFormModel.DEFAULT_NOISE_OR_DISTRACTION ? "Please indicate whether noise or distraction was a factor" : "";
    }

    @computed
    public get validateHousekeeping(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.housekeeping === AccidentFormModel.DEFAULT_HOUSEKEEPING ? "Please indicate whether housekeeping was a factor" : "";
    }

    @computed
    public get validateWeather(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.weather === AccidentFormModel.DEFAULT_WEATHER ? "Please indicate whether weather was a factor" : "";
    }

    @computed
    public get validateLackOfRoom(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.lackOfRoom === AccidentFormModel.DEFAULT_LACK_OF_ROOM ? "Please indicate whether there was a lack of room" : "";
    }

    @computed
    public get validateAccessOrEgress(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.accessOrEgress === AccidentFormModel.DEFAULT_ACCESS_OR_EGRESS ? "Please indicate whether access or egress was a factor" : "";
    }

    @computed
    public get validateFailureToFollowRules(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.failureToFollowRules === AccidentFormModel.DEFAULT_FAILURE_TO_FOLLOW_RULES ? "Please indicate whether there was a failure to follow rules" : "";
    }

    @computed
    public get validateLackOfExperience(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.lackOfExperience === AccidentFormModel.DEFAULT_LACK_OF_EXPERIENCE ? "Please indicate whether lack of experience was a factor" : "";
    }

    @computed
    public get validateFailureToAdhereToRiskAssessment(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.failureToAdhereToRiskAssessment === AccidentFormModel.DEFAULT_FAILURE_TO_ADHERE_TO_RISK_ASSESSMENT
            ? "Please indicate whether there was a failure to adhere to risk assessment"
            : "";
    }

    @computed
    public get validateFatigue(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.fatigue === AccidentFormModel.DEFAULT_FATIGUE ? "Please indicate whether fatigue was a factor" : "";
    }

    @computed
    public get validateInstructionsMisunderstood(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.instructionsMisunderstood === AccidentFormModel.DEFAULT_INSTRUCTIONS_MISUNDERSTOOD ? "Please indicate whether instructions were misunderstood" : "";
    }

    @computed
    public get validateUnsafeAttitude(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.unsafeAttitude === AccidentFormModel.DEFAULT_UNSAFE_ATTITUDE ? "Please indicate whether an unsafe attitude was a factor" : "";
    }

    @computed
    public get validateHorseplay(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.horseplay === AccidentFormModel.DEFAULT_HORSEPLAY ? "Please indicate whether horseplay was a factor" : "";
    }

    @computed
    public get validateWorkingWithoutAuthorisation(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.workingWithoutAuthorisation === AccidentFormModel.DEFAULT_WORKING_WITHOUT_AUTHORISATION
            ? "Please indicate whether working without authorisation was a factor"
            : "";
    }

    @computed
    public get validateErrorOfJudgement(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.errorOfJudgement === AccidentFormModel.DEFAULT_ERROR_OF_JUDGEMENT ? "Please indicate whether an error of judgement was a factor" : "";
    }

    @computed
    public get validateUndueHaste(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.undueHaste === AccidentFormModel.DEFAULT_UNDUE_HASTE ? "Please indicate whether undue haste was a factor" : "";
    }

    @computed
    public get validateLapseOfConcentration(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.lapseOfConcentration === AccidentFormModel.DEFAULT_LAPSE_OF_CONCENTRATION ? "Please indicate whether a lapse of concentration was a factor" : "";
    }

    @computed
    public get validateDesign(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.design === AccidentFormModel.DEFAULT_DESIGN ? "Please indicate whether a design issue was a factor" : "";
    }

    @computed
    public get validatePoorlyMaintainedOrDefective(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.poorlyMaintainedOrDefective === AccidentFormModel.DEFAULT_POORLY_MAINTAINED_OR_DEFECTIVE
            ? "Please indicate whether poor maintenance or defective equipment was a factor"
            : "";
    }

    @computed
    public get validateNotUsed(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.notUsed === AccidentFormModel.DEFAULT_NOT_USED ? "Please indicate whether a required item was not used" : "";
    }

    @computed
    public get validateWrongTypeUsed(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.wrongTypeUsed === AccidentFormModel.DEFAULT_WRONG_TYPE_USED ? "Please indicate whether the wrong type of equipment was used" : "";
    }

    @computed
    public get validateNotProvidedOrUnavailable(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.notProvidedOrUnavailable === AccidentFormModel.DEFAULT_NOT_PROVIDED_OR_UNAVAILABLE
            ? "Please indicate whether a required item was not provided or unavailable"
            : "";
    }

    @computed
    public get validateNonCommunicationOfRisk(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.nonCommunicationOfRisk === AccidentFormModel.DEFAULT_NON_COMMUNICATION_OF_RISK ? "Please indicate whether there was a failure to communicate risk" : "";
    }

    @computed
    public get validateSupervision(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.supervision === AccidentFormModel.DEFAULT_SUPERVISION ? "Please indicate whether there was a failure in supervision" : "";
    }

    @computed
    public get validateTraining(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.training === AccidentFormModel.DEFAULT_TRAINING ? "Please indicate whether there was a failure in training" : "";
    }

    @computed
    public get validateSystemFailure(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.systemFailure === AccidentFormModel.DEFAULT_SYSTEM_FAILURE ? "Please indicate whether a system failure was a factor" : "";
    }

    @computed
    public get validateConstructionOrDesign(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.constructionOrDesign === AccidentFormModel.DEFAULT_CONSTRUCTION_OR_DESIGN ? "Please indicate whether a construction or design issue was a factor" : "";
    }

    @computed
    public get validateSafetyDevice(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.safetyDevice === AccidentFormModel.DEFAULT_SAFETY_DEVICE ? "Please indicate whether a safety device was missing or not functioning" : "";
    }

    @computed
    public get validateMechanicalFailure(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.mechanicalFailure === AccidentFormModel.DEFAULT_MECHANICAL_FAILURE ? "Please indicate whether a mechanical failure was a factor" : "";
    }

    @computed
    public get validateInstallation(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.installation === AccidentFormModel.DEFAULT_INSTALLATION ? "Please indicate whether there was a problem during installation" : "";
    }

    @computed
    public get validateOperationOrUse(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.operationOrUse === AccidentFormModel.DEFAULT_OPERATION_OR_USE ? "Please indicate whether there was a problem during operation or use" : "";
    }

    @computed
    public get validateThirdParty(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.thirdParty === AccidentFormModel.DEFAULT_THIRD_PARTY ? "Please indicate whether a third party was involved" : "";
    }

    @computed
    public get validateUnderInvestigation(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.underInvestigation === AccidentFormModel.DEFAULT_UNDER_INVESTIGATION ? "Please indicate whether the incident is under investigation" : "";
    }

    @computed
    public get validateActionsToRecover(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.actionsToRecover === AccidentFormModel.DEFAULT_ACTIONS_TO_RECOVER || this.actionsToRecover?.trim() === "" ? "Please provide actions to recover" : "";
    }

    @computed
    public get validateActionsToPrevent(): string {
        if (!this.hasInvestigationStarted) {
            return "";
        }

        return this.actionsToPrevent === AccidentFormModel.DEFAULT_ACTIONS_TO_PREVENT || this.actionsToPrevent?.trim() === "" ? "Please provide actions to prevent" : "";
    }
}

export interface IncidentFormAccidentDTO {
    id: string | null;
    incidentId: string | null;
    createdByUserId: string | null;
    createdDate: Date | null;
    isDeleted: boolean;
    lastUpdatedByUserId: string | null;
    lastUpdatedDate: Date | null;
    hasInvestigationStarted: boolean;
    investigationDate: Date | null;
    investigatorUserId: string | null;
    hasInvestigationEnded: boolean;
    investigationEndDate: Date | null;
    rowVersion: string | null;

    location: string | null;
    whatHappened: string | null;
    casualtyUserId: string | null;
    casualtyDateOfBirth: Date | null;
    statusOfCasualtyId: string | null;
    casualtyPhoneNumber: string | null;
    casualtyEmailAddress: string | null;
    addressLine1: string | null;
    addressLine2: string | null;
    addressLine3: string | null;
    cityTown: string | null;
    postcode: string | null;
    casualtyNotInSystem: string | null;
    accidentDate: Date | null;
    //accidentDetails: string | null;
    descriptionOfInjury: string | null;
    descriptionOfTreatment: string | null;
    casualtySignatureBase64: string | null;
    firstAiderSignature: string | null;

    descriptionOfInjuryFurtherInfo: string | null;
    additionalInjuriesOrWorsening: string | null;
    fatality: boolean | null;
    unconscious: boolean | null;
    resuscitation: boolean | null;
    advisedToSeeDoctor: boolean | null;
    whichMedicalPracticeId: string | null;
    alcoholOrDrugTestingRequired: boolean | null;
    alcoholOrDrugTestingRequiredResultId: string | null;
    causeOfAccidentTypeId: string | null;
    otherCauseOfAccident: string | null;
    fallHeight: number | null;
    sourceOfHazardTypeId: string | null;
    otherSourceOfHazard: string | null;

    wasThereARiskAssessment: boolean | null;
    wasThereAMethodStatement: boolean | null;
    wasTheAccidentRIDDORReportable: boolean | null;
    dateRIDDORWasSent: Date | null;
    wasAnyTimeLost: boolean | null;
    dateWorkFinished: Date | null;
    dateWorkRestarted: Date | null;
    totalTimeLost: number | null;

    whichPartOfWorkProcess: string | null;
    descriptionOfEnvironment: string | null;
    descriptionOfEquipment: string | null;
    ppeRequired: string | null;
    ppeWorn: string | null;
    defectiveWorkplace: boolean | null;
    lighting: boolean | null;
    designOrLayout: boolean | null;
    noiseOrDistraction: boolean | null;
    housekeeping: boolean | null;
    weather: boolean | null;
    lackOfRoom: boolean | null;
    accessOrEgress: boolean | null;
    failureToFollowRules: boolean | null;
    lackOfExperience: boolean | null;
    failureToAdhereToRiskAssessment: boolean | null;
    fatigue: boolean | null;
    instructionsMisunderstood: boolean | null;
    unsafeAttitude: boolean | null;
    horseplay: boolean | null;
    workingWithoutAuthorisation: boolean | null;
    errorOfJudgement: boolean | null;
    undueHaste: boolean | null;
    lapseOfConcentration: boolean | null;
    design: boolean | null;
    poorlyMaintainedOrDefective: boolean | null;
    notUsed: boolean | null;
    wrongTypeUsed: boolean | null;
    notProvidedOrUnavailable: boolean | null;
    nonCommunicationOfRisk: boolean | null;
    supervision: boolean | null;
    training: boolean | null;
    systemFailure: boolean | null;
    constructionOrDesign: boolean | null;
    safetyDevice: boolean | null;
    mechanicalFailure: boolean | null;
    installation: boolean | null;
    operationOrUse: boolean | null;
    thirdParty: boolean | null;
    underInvestigation: boolean | null;
    actionsToRecover: string | null;
    actionsToPrevent: string | null;
}

export interface IncidentFormAccidentRelatedResponseDTO {
    incident: IncidentDTO;
    incidentProjectDetails: IncidentFormAccidentProjectDetailsDTO;
    incidentUsers: IncidentUserDTO[];
    injuryAreas: GenericListItemDTO[];
    injuryTypes: GenericListItemDTO[];
    statusOfCasualtyTypes: GenericListItemDTO[];
}
export interface IncidentFormAccidentAndRelatedResponseDTO extends IncidentFormAccidentRelatedResponseDTO {
    incidentFormAccident: IncidentFormAccidentDTO;
    incidentFormAccidentDocuments: AccidentPhotoListItemDTO[];
    incidentFormAccidentRootCauses: GenericFreeTextListItemDTO[];
    incidentFormAccidentInjuryAreas: AccidentInjuryAreaItemDTO[];
    incidentFormAccidentInjuryTypes: AccidentInjuryTypeItemDTO[];
    incidentFormAccidentWitnesses: AccidentWitnessItemDTO[];
    causeOfAccidentTypes: GenericListItemDTO[];
    sourcesOfHazardTypes: GenericListItemDTO[];
    medicalPracticeTypes: GenericListItemDTO[];
    drugTestResultTypes: GenericListItemDTO[];
    casualtyUserDetails: CasualtyUserDetailsDTO;
}
export interface CasualtyUserDTO {
    id: string;
    displayName: string;
}

export interface IncidentFormAccidentProjectDetailsDTO {
    projectReference: string;
    projectName: string;
}

export interface UpsertIncidentFormAccidentAndRelatedRequestDTO {
    incident: IncidentDTO;
    incidentFormAccident: IncidentFormAccidentDTO;
    incidentFormAccidentDocuments: AccidentPhotoListItemDTO[];
    incidentFormAccidentRootCauses: GenericFreeTextListItemDTO[];
    incidentFormAccidentInjuryAreas: AccidentInjuryAreaItemDTO[];
    incidentFormAccidentInjuryTypes: AccidentInjuryTypeItemDTO[];
    incidentFormAccidentWitnesses: AccidentWitnessItemDTO[];
}

export interface CasualtyUserDetailsDTO {
    id: string;
    phoneNumber: string;
    emailAddress: string;
    company: string;
}
