import { observable, runInAction, action } from "mobx";
import { FieldType, ViewModelBase } from "@shoothill/core";
import { AppUrls } from "../../../../AppUrls";
import { ServerViewModel } from "../../../../Globals/ViewModels/ServerViewModel";
import { ToolboxTalkListAndRelatedDTO, ToolboxTalkListModel } from "./ToolboxTalkListModel";

export class ToolboxTalkListViewModel extends ViewModelBase<any> {
    public server: ServerViewModel = new ServerViewModel();
    public projectId: string = "";
    @observable public toolboxTalkListModels: ToolboxTalkListModel[] = [];
    constructor() {
        super();
    }

    @action
    public loadToolboxTalksAndRelated = async (): Promise<void> => {
        this.setIsLoading(true);
        const request: any = {
            id: this.projectId,
        };
        let apiResult = await this.Post<ToolboxTalkListAndRelatedDTO>(AppUrls.Server.Projects.Construction.ToolboxTalk.LoadToolboxTalksAndRelated, request);
        if (apiResult) {
            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.toolboxTalkListModels = [];
                    for (const toolboxTalk of apiResult.payload.toolboxTalkListItems) {
                        let model = new ToolboxTalkListModel();
                        model.fromDto(toolboxTalk);
                        this.toolboxTalkListModels.push(model);
                    }
                });
            } else {
                console.log(apiResult.errors);
            }
        }
        this.setIsLoading(false);
    };

    // public upsert = async (): Promise<void> => {
    //     this.setIsLoading(true);
    //     //const model: PermitDTO = this.model.toDto();

    //     const request: any = {
    //         incident: {
    //             id: null,
    //             projectId: this.projectId,
    //             incidentnumber: null,
    //             isDeleted: false,
    //             createdByUserId: null,
    //             rowVersion: null,
    //             lastUpdatedByUserId: null,
    //             lastUpdatedDate: null,
    //         },
    //     };

    //     return await this.server
    //         .command<IncidentAndRelatedResponseDTO>(
    //             () => this.Post(AppUrls.Server.Projects.Construction.Incident.Upsert, request),
    //             (result: IncidentAndRelatedResponseDTO) => {
    //                 runInAction(() => {
    //                     this.history.push(AppUrls.Client.Project.Incident.View.replace(":projectid", result.incident.projectId).replace(":incidentid", result.incident.id!));
    //                 });
    //             },
    //             this.isModelValid,
    //             "There was an error trying to send the incident",
    //         )
    //         .finally(() => this.setIsLoading(false));
    // };

    @action
    public handleRowClick = (e: any, rowData: ToolboxTalkListModel) => {
        this.history.push(AppUrls.Client.Project.ToolboxTalk.Edit.replace(":projectid", this.projectId).replace(":toolboxtalkid", rowData.id));
    };

    @action
    public addToolboxTalkForm = async () => {
        this.history.push(AppUrls.Client.Project.ToolboxTalk.Add.replace(":projectid", this.projectId));
        //await this.upsert();
    };

    public async isFieldValid(fieldName: keyof FieldType<ToolboxTalkListModel>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }
    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
