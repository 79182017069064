import { FieldType, isNullOrEmpty, KeyValuePair, ViewModelBase } from "@shoothill/core";
import { observable, action, runInAction, computed } from "mobx";
import { PackagesModel, PackagesModelDTO } from "./PackagesModel";
import { AppUrls } from "AppUrls";
import { GenericIncludeDeleted } from "Globals/Models";
import { StringLiteralLike } from "typescript";

export class PackagesViewModel extends ViewModelBase<PackagesModel> {
    private static _instance: PackagesViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable public errorMessage: string = "";

    public constructor() {
        super(new PackagesModel(), false);
        this.setDecorators(PackagesModel);
    }

    public cellArr = [
        { key: "Cell 1", value: "Cell 1" },
        { key: "Cell 2", value: "Cell 2" },
        { key: "Cell 3", value: "Cell 3" },
    ];

    @computed get getSummary(): PackagesModel[] {
        return [];
    }

    @computed
    public get getCategories(): KeyValuePair[] {
        return this.model.categories.map((c) => {
            return { value: c.displayName, key: c.id.toString() };
        });
    }

    @action
    public getCategoryName = (val: number) => {
        const m = this.model.categories.find((c) => c.id === val);
        if (!isNullOrEmpty(m?.displayName)) {
            return m?.displayName;
        } else {
            return "";
        }
    };

    @computed
    public get getCategoryList(): KeyValuePair[] {
        let retVal = this.model.categories
            .filter((c) => !c.isVariationDefault)
            .map((c) => {
                return { value: c.displayName, key: c.id.toString() };
            });
        let _: KeyValuePair = { key: "", value: "Select Category" };
        let prepended: KeyValuePair[] = [_];
        retVal.unshift(...prepended);
        return retVal;
    }

    @action
    public getCategory = (val: number) => {
        const category = this.model.categories.find((c) => c.id === val);
        return category;
    };

    @action
    public getSubcategoryName = (val: number) => {
        const m = this.model.subCategories.find((c) => c.id === val);
        if (!isNullOrEmpty(m?.displayName)) {
            return m?.displayName;
        } else {
            return "** unknown";
        }
    };

    @action
    public getSubcategoryList = (val: number | null): KeyValuePair[] => {
        const retVal = this.model.subCategories
            .filter((c) => c.parentId === val)
            .map((c) => {
                return { value: c.displayName, key: c.id.toString() };
            });

        let _: KeyValuePair = { key: "", value: "Select Subcategory" };
        let prepended: KeyValuePair[] = [_];
        retVal.unshift(...prepended);
        return retVal;
    };

    @action
    public getDescriptionName = (val: number) => {
        const m = this.model.descriptions.find((c) => c.id === val);
        if (m) {
            if (!isNullOrEmpty(m.displayName)) {
                return m.nominalCode ? `${m.displayName} - ${m.nominalCode}` : m.displayName;
            }
        }
        return "*";
    };

    // @action
    // public getDescriptionList = (val: number): KeyValuePair[] => {
    //     const retVal =
    //         val > 0
    //             ? this.model.descriptions
    //                   .filter((c) => c.parentId === val)
    //                   .map((c) => {
    //                       return { value: c.displayName, key: c.id.toString() };
    //                   })
    //             : this.model.descriptions.map((c) => {
    //                   return { value: c.displayName, key: c.id.toString() };
    //               });
    //     retVal.push({ key: "-2", value: "Other" });
    //     let _: KeyValuePair = { key: "", value: "Select line description" };
    //     let prepended: KeyValuePair[] = [_];
    //     retVal.unshift(...prepended);
    //     return retVal;
    // };

    @action
    public cleanUp = () => {
        // TODO Any Cleanup Code here. e.g. if  a user or project or client etc, wipe it from the instance on page shutdown
    };

    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (await this.isMyModelValid()) {
            //Do stuff here
            this.errorMessage = "Form is valid";
        } else {
            this.errorMessage = "Form is not valid";
        }
    };

    @observable
    private IEtype = "master";

    @computed
    public get ietypeValue(): string {
        // ToDo
        return this.IEtype;
    }

    @action
    public setIEtype(val: string) {
        this.IEtype = val;
    }

    @observable
    private IElink = "";

    @computed
    public get ielinkValue(): string {
        return this.ielinkValue;
    }

    @action
    public setIEvalue(val: string) {
        this.IElink = val;
    }

    @action
    public apiGetRelated = async (ieid: string | null = null): Promise<void> => {
        let apiResult = await this.Post<PackagesModelDTO>(AppUrls.Server.Projects.IncomeExpend.GetCategories, { id: ieid, includeDeleted: false });
        if (apiResult) {
            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.model.fromDto(apiResult.payload);
                });
            } else {
                console.log(apiResult.errors);
            }
        }
    };

    public async isFieldValid(fieldName: keyof FieldType<PackagesModel>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        /*         
        if (fieldName === "firstName") {
            errorMessage = this.isFirstNameValid;
            isValid = errorMessage === "";
        } 
        */

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    /*     
    public get isFirstNameValid(): string {
        let error = "";

        const field: string = this.getValue<string>("firstName");

        if (isNullOrEmpty(field)) {
            error = "First name is required";
        } else if (field!.length > 128) {
            error = "First name needs to be less than 128 characters";
        }

        return error;
    } 
    */

    private isMyModelValid = async (): Promise<boolean> => {
        let isValid = true;

        // If you have a child form, you can call it's own viewmodels validation from here.
        /*if ((await this.addressFormViewModel.isModelValid()) === false) {
            isValid = false;
        }*/

        // Could also perform this in a loop if you have a list of child forms.
        /*for (let i = 0; i < this.shiftViewModels.length; i++) {
            let shift = this.shiftViewModels[i];

            if (shift.model.isEmptyForm === false) {
                if ((await shift.isModelValid()) === false) {
                    isValid = false;
                }
            }
        }*/

        // this will automatically call isFieldValid.
        if ((await this.isModelValid()) === false) {
            isValid = false;
        }
        return isValid;
    };

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
