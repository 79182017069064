import React, { useState } from "react";
import { useObserver } from "mobx-react-lite";
import { BaseModal } from "Components/Modal/BaseModal";
import { CancelButton } from "Globals/Styles/Control/Buttons";
import { Box, IconButton } from "@material-ui/core";
import { Cell, Grid } from "@shoothill/core";
import { DesignDataFileViewModel } from "./DesignDataFileViewModel";
import { ReportModalStyle } from "Views/Project/Modals/Modal.Styles";
import SystemUpdateTwoToneIcon from "@material-ui/icons/SystemUpdateTwoTone";

interface ICreatedModalProps {
    open?: boolean;
    onClose(): void;
    designDataId: string | null;
}

export const DesignDataFileView: React.FC<ICreatedModalProps> = (props) => {
    const { open, onClose, designDataId } = props;
    const [viewModel] = useState(() => new DesignDataFileViewModel(designDataId));
    const COLUMNS8 = "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr";
    const COLUMNS4 = "1fr 1fr 1fr 1fr";

    return useObserver(() => (
        <BaseModal
            open={open}
            onClose={onClose}
            title={"Items"}
            PaperComponent={ReportModalStyle}
            actions={
                <>
                    <CancelButton style={{ background: "#0095da" }} onClick={onClose}>
                        Ok
                    </CancelButton>
                </>
            }
        >
            <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8} mb="35px">
                <Cell ts={8} ds={8}>
                    <Box className="uploadedFile">
                        {viewModel.files.map((item, index) => {
                            return (
                                !item.isDeleted && (
                                    <Box className="uploadedFile-box">
                                        <Box className="uploadedFile-heading">
                                            <IconButton onClick={() => viewModel.DownloadFile(item.fileURL, item.fileName)}>
                                                <SystemUpdateTwoToneIcon />
                                            </IconButton>
                                            <h3>{item.fileName}</h3>
                                        </Box>
                                    </Box>
                                )
                            );
                        })}
                    </Box>
                </Cell>
            </Grid>
        </BaseModal>
    ));
};
