import { Box, Button, Fade, Link, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";

import { generateID, Grid } from "@shoothill/core";
import { EditableInput } from "@shoothill/core";
import { ResetPasswordModel } from "./ResetPasswordModel";
import { ResetPasswordViewModel } from "./ResetPasswordViewModel";
import { runInAction } from "mobx";
import { useObserver } from "mobx-react-lite";
import { AppUrls } from "AppUrls";
import { AccountImageBox, AccountPage, BoxAccount, BoxPage } from "Globals/Styles/AccountStyling";
import { AccountForm, AccountTitleBox } from "Views/Login/LoginView.style";
import { ReactComponent as Logo } from "../../Content/dg_logo_full_cmyk_primary.svg";
export interface IWindowState {
    width: number;
    height: number;
}

export const ResetPasswordView: React.FunctionComponent = () => {
    const [viewModel] = useState(() => new ResetPasswordViewModel());
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const componentRef: any = useRef(generateID());

    const [dimensions, setDimensions] = React.useState<IWindowState>({
        width: 0,
        height: 0,
    });

    const updateWindowDimensions = () => {
        if (componentRef !== undefined && componentRef != null && componentRef.current !== null && componentRef.current !== undefined) {
            setDimensions({
                width: componentRef!.current!.offsetWidth,
                height: componentRef.current!.offsetHeight!,
            });
        }
    };

    useEffect(() => {
        viewModel.getTokenFromUrl();
    }, []);

    const doSubmit = async (e: any) => {
        e.preventDefault();

        if (await viewModel.isModelValid()) {
            runInAction(() => {
                setIsLoading(true);
            });

            const apiResult = await viewModel.resetPasswordAsync();

            runInAction(() => {
                setIsLoading(false);

                if (apiResult) {
                    if (apiResult.wasSuccessful) {
                        setErrorMessage("");
                        setIsSuccess(true);
                    } else {
                        setErrorMessage("* " + apiResult.errors[0].message);
                        setIsSuccess(false);
                    }
                } else {
                    setErrorMessage("* Unknown error has occurred.");
                    setIsSuccess(false);
                }
            });
        }
    };

    const goToLoginPage = async () => {
        viewModel.history.push(AppUrls.Client.Account.Login);
    };

    useEffect(() => {
        updateWindowDimensions();
        window.addEventListener("resize", updateWindowDimensions);

        // tidy up after yourself
        return () => {
            window.removeEventListener("resize", updateWindowDimensions);
        };
    }, []);

    const goToForgotPassword = async (e: any) => {
        viewModel.history.push(AppUrls.Client.Account.ForgotPassword);
    };

    const inPortrait: boolean = dimensions.height > dimensions.width;
    const triangleHeight: string = (inPortrait === true ? (dimensions.width * 0.765).toString() : (dimensions.height * 0.765).toString()) + "px";

    return useObserver(() => (
        <Fade in timeout={1000}>
            <AccountPage triangleheight={triangleHeight} ref={componentRef}>
                <div className="triangle"></div>
                <BoxPage>
                    <BoxAccount>
                        <AccountImageBox>
                            <Logo />
                        </AccountImageBox>
                        <AccountTitleBox>{/* <span>Project Management System</span> */}</AccountTitleBox>
                        <Box padding="5px">
                            {viewModel.tokenValid === true ? (
                                <>
                                    {!isSuccess && (
                                        <Box textAlign="left" margin="20px 0px 0px 88px">
                                            <Typography component="h1" variant="h3" style={{ marginBottom: "10px", marginTop: "25px" }}>
                                                Reset password
                                            </Typography>
                                            <Typography variant="body1" gutterBottom>
                                                Please type a new password.
                                            </Typography>
                                        </Box>
                                    )}
                                    <AccountForm onSubmit={doSubmit}>
                                        {!isSuccess && (
                                            <Grid>
                                                {errorMessage !== "" && (
                                                    <Typography variant="caption" style={{ color: "red" }}>
                                                        {errorMessage}
                                                    </Typography>
                                                )}
                                                <EditableInput<ResetPasswordModel>
                                                    type="password"
                                                    label="New Password"
                                                    validateOnBlur={true}
                                                    viewModel={viewModel}
                                                    fieldName="newPassword"
                                                    shrink={true}
                                                />
                                                <EditableInput<ResetPasswordModel>
                                                    type="password"
                                                    label="Confirm Password"
                                                    validateOnBlur={true}
                                                    viewModel={viewModel}
                                                    fieldName="confirmPassword"
                                                    shrink={true}
                                                />
                                            </Grid>
                                        )}

                                        <Box margin="50px 0 0 0" textAlign="left">
                                            {!isSuccess && (
                                                <Button type="submit" fullWidth variant="contained" color="primary">
                                                    {isLoading ? "Please Wait..." : "Reset password"}
                                                </Button>
                                            )}

                                            {isSuccess && <Link onClick={goToLoginPage}>Return to login page</Link>}
                                            {errorMessage !== "" && <Link onClick={goToForgotPassword}>Return to forgot password</Link>}
                                            {isSuccess && (
                                                <Typography variant="h6" color="secondary">
                                                    Successfully Reset Password
                                                </Typography>
                                            )}
                                        </Box>
                                    </AccountForm>
                                </>
                            ) : viewModel.tokenValid === false ? (
                                <>
                                    <Box textAlign="left" margin="20px 0px 0px 88px">
                                        <Typography component="h1" variant="h3" style={{ marginBottom: "10px", marginTop: "25px" }}>
                                            Reset password
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            Url is expired or invalid, please try again.
                                        </Typography>
                                        <Link onClick={goToForgotPassword}>Return to forgot password</Link>
                                    </Box>
                                </>
                            ) : (
                                <>
                                    <Box textAlign="left" margin="20px 0px 0px 88px">
                                        <Typography component="h1" variant="h3" style={{ marginBottom: "10px", marginTop: "25px" }}>
                                            Reset password
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            Loading...
                                        </Typography>
                                    </Box>
                                </>
                            )}
                        </Box>
                    </BoxAccount>
                </BoxPage>
            </AccountPage>
        </Fade>
    ));
};
