import styled from "styled-components";
import { BaseModalDialogPaper } from "Components/Modal/BaseModal.styles";

export const AddPlanOfWorkModalStyle: any = styled(BaseModalDialogPaper)`
    max-width: 456px;
    width: 100%;
    /* height: 293px; */
    .MuiDialogTitle-root {
        padding: 12px 20px;
        h2 {
            font: normal normal 600 18px/40px Open Sans;
            letter-spacing: -0.36px;
            color: #ffffff;
        }
    }
`;

export const ReportModalStyle: any = styled(BaseModalDialogPaper)`
    max-width: 848px;
    width: 100%;
    /* height: 293px; */
    .MuiDialogTitle-root {
        padding: 12px 20px;
        h2 {
            font: normal normal 600 18px/40px Open Sans;
            letter-spacing: -0.36px;
            color: #ffffff;
        }
    }
`;

export const StockHistoryModalStyle: any = styled(BaseModalDialogPaper)`
    max-width: 1020px;
    width: 100%;
    /* height: 293px; */
    .MuiDialogTitle-root {
        padding: 12px 20px;
        h2 {
            font: normal normal 600 18px/40px Open Sans;
            letter-spacing: -0.36px;
            color: #ffffff;
        }
    }
`;

export const ProjectModalStyle: any = styled(BaseModalDialogPaper)`
    max-width: 620px;
    width: 100%;
    height: 293px;

    .MuiDialogContent-root {
        p {
            padding-bottom: 40px;
            font-size: 14px;
        }
    }

    button {
        max-width: 262px !important;
        width: 100%;
        font-size: 13px !important;
    }
`;

export const IEModalStyle: any = styled(BaseModalDialogPaper)`
    max-width: 594px;
    width: 100%;
    min-height: 293px;

    .MuiDialogContent-root {
        p {
            padding-bottom: 40px;
            font-size: 14px;
        }
    }

    button {
        max-width: 116px !important;
        width: 100%;
        font-size: 13px !important;
    }
`;

export const IEItemLineModalStyle: any = styled(BaseModalDialogPaper)`
    max-width: 965px;
    width: 100%;
    height: 415px;

    .MuiDialogContent-root {
        p {
            padding-bottom: 40px;
            font-size: 14px;
        }
    }

    button {
        max-width: 116px !important;
        width: 100%;
        font-size: 13px !important;
    }
`;

export const Main: any = styled.section`
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;

    p {
        font-size: 12px;
    }
`;
