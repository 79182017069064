import { Checkbox, FormControlLabel } from "@material-ui/core";
import { Loader } from "@shoothill/core";
import { observer } from "mobx-react-lite";
import React from "react";
import styled from "styled-components";

import { SHBox } from "Components/Box";
import { DarwinSelect } from "Components/AutoComplete/DarwinSelect";
import { PrimaryButton } from "Components/Buttons/Buttons";
import image from "Content/inductionimage2.png";
import DarwinLogo from "Content/darwinlogoInduction.svg";
import { DarwinInput } from "Globals/Styles/Control/DarwinInput";
import { InductionViewModel } from "../InductionViewModel";
import { LandingModel } from "./LandingModel";
import { LandingViewModel } from "./LandingViewModel";
import { InductionType } from "Globals/Models/Enums/InductionType";

const Wrapper = styled(SHBox)`
    height: 100vh;
    .root {
        height: 100%;
    }
    .left-panel {
        display: flex;
        box-shadow: 3px 0 6px #00000029;
        .content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            row-gap: 30px;
            img {
                height: 50px;
                width: 262px;
                flex: 0;
            }
            .text {
                font-size: 12px;
                color: #171716;
            }
        }
    }
    .image {
        background: url(${image});
        background-position: left center;
        background-repeat: no-repeat;
        background-size: cover;
    }
`;

interface Props {
    viewModel: LandingViewModel;
    parentViewModel: InductionViewModel;
}

export const LandingView: React.FC<Props> = observer((props: Props) => {
    if (props.parentViewModel.isStageLoading) {
        return <Loader />;
    }

    const setInductionType = (value: InductionType | null): void => {
        props.viewModel.setInductionType(value);

        // User will need to watch a different video if the induction type changes.
        props.parentViewModel.step5ViewModel.setIsVideoFinished(false);

        if (value) {
            props.parentViewModel.step5ViewModel.setInductionTypeId(value.id);
        }
    };

    return (
        <Wrapper className={"overflowContainer"}>
            <SHBox grid dc={"758px 1fr"} mc={"1fr"} className={"root"} style={{ gap: 0 }}>
                <SHBox className={"left-panel"} p={"30px"} style={{ overflow: props.viewModel.isMobile ? "initial" : "hidden" }}>
                    <SHBox className={"content"}>
                        <img src={DarwinLogo} alt="Darwin Group" className="logoDarwin" />
                        <SHBox className={"text"}>
                            <p>
                                Welcome to the Darwin Group Induction Hub. Please ensure you have enough time to complete this induction, as you won't be able to return to it and
                                will have to start from the beginning.
                            </p>
                            <br />
                            <p>
                                For a visitor, please take Visitor Induction and this will only be around 15 minutes. For a Worker, Supervisor or Manager, please take Full
                                Induction and this will take around 25 minutes.
                            </p>
                            <br />
                            <p>Please ensure that you have your skills/qualifications cards with you, or photographs of them in your camera roll on your device.</p>
                            <br />
                            <p>Let's get started!</p>
                        </SHBox>
                        <SHBox grid dc={"1fr 1fr"} mc={"1fr"} style={{ width: "100%" }}>
                            <SHBox grid mc={"1fr"} dcs={props.viewModel.isMobile ? "" : "2"} showIf={!props.viewModel.model.otherEmployerName}>
                                <DarwinSelect
                                    displayName="Priority Employer name:"
                                    execute={props.viewModel.setSupplierType}
                                    fullWidth={true}
                                    getOptionLabel={(option: any) => option.displayName}
                                    options={props.viewModel.suppliers}
                                    placeholder="Please select"
                                    validationMessage={props.viewModel.getError("supplierId")}
                                    value={props.viewModel.supplierType}
                                />
                            </SHBox>
                            <SHBox grid mc={"1fr"} dcs={props.viewModel.isMobile ? "" : "2"} showIf={props.viewModel.model.otherEmployerName}>
                                <DarwinInput<LandingModel>
                                    type={"text"}
                                    label="Priority Employer name:"
                                    shrink={true}
                                    validateOnBlur={true}
                                    viewModel={props.viewModel}
                                    fieldName="priorityEmployerName"
                                    inputProps={{ disabled: !props.viewModel.model.otherEmployerName }}
                                />
                            </SHBox>
                            <SHBox>
                                <FormControlLabel
                                    control={<Checkbox checked={props.viewModel.model.otherEmployerName} onChange={props.viewModel.setOtherEmployerName} />}
                                    label={"Other employer name?"}
                                    name={`other-employer-name-checkbox`}
                                />
                            </SHBox>
                        </SHBox>
                        <SHBox grid dc={"1fr 1fr"} mc={"1fr"} style={{ width: "100%" }}>
                            <DarwinInput<LandingModel> label="Your first name:" shrink={true} validateOnBlur={true} viewModel={props.viewModel} fieldName="firstName" />
                            <DarwinInput<LandingModel> label="Your last name:" shrink={true} validateOnBlur={true} viewModel={props.viewModel} fieldName="lastName" />
                            <DarwinSelect
                                displayName="Induction type:"
                                execute={setInductionType}
                                fullWidth={true}
                                getOptionLabel={(option: any) => option.displayName}
                                options={props.viewModel.inductionTypes}
                                placeholder="Please select"
                                validationMessage={props.viewModel.getError("inductionTypeId")}
                                value={props.viewModel.inductionType}
                            />
                            <DarwinSelect
                                displayName="Inductee type:"
                                execute={props.viewModel.setVisitorType}
                                fullWidth={true}
                                getOptionLabel={(option: any) => option.displayName}
                                options={props.viewModel.visitorTypes}
                                placeholder="Please select"
                                validationMessage={props.viewModel.getError("visitorType")}
                                value={props.viewModel.visitorType}
                            />
                            <SHBox grid dc={"100px 1fr"} mc={"100px 1fr"}>
                                <DarwinInput<LandingModel>
                                    type={"number"}
                                    label="Your age:"
                                    shrink={true}
                                    validateOnBlur={true}
                                    viewModel={props.viewModel}
                                    fieldName="age"
                                    inputProps={{ disabled: !props.viewModel.canSetAge }}
                                />
                                <SHBox mt={"12px"}>
                                    <FormControlLabel
                                        control={<Checkbox checked={props.viewModel.model.preferNotToSay} onChange={props.viewModel.setPreferNotToStateAge} />}
                                        label={"Prefer not to say"}
                                        name={`cost-to-be-agreed-checkbox`}
                                    />
                                </SHBox>
                            </SHBox>
                        </SHBox>
                        <SHBox width={"304px"}>
                            <PrimaryButton
                                displayName="Start your induction"
                                execute={props.viewModel.startYourInduction}
                                fullWidth={true}
                                canExecute={props.viewModel.startYourInductionCanExecute}
                            />
                        </SHBox>
                    </SHBox>
                </SHBox>
                <SHBox showIf={!props.viewModel.isMobile} id={"laptop"} className={"image"}></SHBox>
            </SHBox>
            {/*<Footer />*/}
        </Wrapper>
    );
});
