import * as MobX from "mobx";
import { ModelBase } from "@shoothill/core";

export class PurchaseOrderModel extends ModelBase<PurchaseOrderModel, PurchaseOrderModelDTO> {
    public id: string = "";

    @MobX.observable
    public poNumber: number | null = null;

    @MobX.observable
    public formattedPONumber: string | null = null;

    @MobX.observable
    public itemDescription: string = "";

    @MobX.observable
    public committedCost: number = 0;

    @MobX.observable
    public availableBalance: number = 0;

    @MobX.observable
    public supplierName: string = "";

    @MobX.observable
    public raisedBy: string = "";

    @MobX.observable
    public status: string = "";

    @MobX.observable
    public dateFrom: Date | null = null;

    @MobX.observable
    public dateTo: Date | null = null;

    @MobX.observable
    public deliveryRequired: boolean = false;

    @MobX.observable
    public query: string = "";

    @MobX.observable
    public requiredNoteImageUrl: string = "";

    @MobX.observable
    public requiredNoteName: string = "";

    @MobX.observable
    public revision: string = "";

    @MobX.observable
    public createdDate: Date | null = null;

    fromDto(model: PurchaseOrderModelDTO): void {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    toDto(model: PurchaseOrderModel): void {}

    public toTableModel = (): PurchaseOrderTableModel => {
        const po: PurchaseOrderTableModel = {
            id: this.id,
            poNumberFormatted: this.formattedPONumber ? this.formattedPONumber : "",
            itemDescription: this.itemDescription,
            committedCost: this.committedCost,
            availableBalance: this.availableBalance,
            supplierName: this.supplierName,
            raisedBy: this.raisedBy,
            status: this.status,
            dateFrom: this.dateFrom,
            dateTo: this.dateTo,
            deliveryRequired: this.deliveryRequired,
            query: this.query,
            revision: this.revision,
            createdDate: this.createdDate,
        };

        return po;
    };
}

export type PurchaseOrderTableModel = {
    id: string;
    poNumberFormatted: string;
    itemDescription: string;
    committedCost: number;
    availableBalance: number;
    supplierName: string;
    raisedBy: string;
    status: string;
    dateFrom: Date | null;
    dateTo: Date | null;
    deliveryRequired: boolean;
    query: string;
    revision: string;
    createdDate: Date | null;
};

export type PurchaseOrderModelDTO = {
    id: string;
    poNumber: number | null;
    formattedPONumber: string | null;
    itemDescription: string;
    committedCost: number;
    supplierName: string;
    raisedBy: string;
    status: string;
    dateFrom: Date | null;
    dateTo: Date | null;
    deliveryRequired: boolean;
    query: string;
    requiredNoteImageUrl: string;
    requiredNoteName: string;
    revision: string;
    createdDate: Date | null;
};
