import { ApiResult, ViewModelBase, FieldType } from "@shoothill/core";
import { runInAction, action } from "mobx";

import { AppUrls } from "AppUrls";
import { ScaffoldInspectionDetailModel } from "./ScaffoldInspectionDetailModel";

export class ScaffoldInspectionDetailViewModel extends ViewModelBase<ScaffoldInspectionDetailModel> {
    constructor(model = new ScaffoldInspectionDetailModel()) {
        super(model);

        this.setDecorators(ScaffoldInspectionDetailModel);

        (window as any).ScaffoldInspectionDetailViewModel = this;
    }

    public get KEY() {
        return this.model.KEY;
    }

    @action
    public radioChanged = async (value: string, fieldName: string, editable: boolean) => {
        if (!editable) return;

        this.model[fieldName] = value;

        // SIDE-EFFECT.
        // If the value is set to "pass", any details or photos associated with
        // this field are no longer valid and should be reset. The only issue is
        // that if a file has been uploaded, it will become a stray so upload followed
        // by "pass" is something you don't want to do too much.
        if (value === "pass") {
            this.model[fieldName + "Detail"] = "";
            this.model[fieldName + "FileName"] = "";
            this.model[fieldName + "Url"] = "";
        }

        await this.isFieldValid(fieldName as any, value);
    };

    @action
    public fileChange = async (event: React.ChangeEvent<HTMLInputElement>, fieldName: keyof FieldType<ScaffoldInspectionDetailModel>): Promise<void> => {
        if (event.target.files !== null && event.target.value !== null && event.target.files.length > 0) {
            let data: any = {
                fileName: event.target.files[0].name,
                formFile: event.target.files[0],
            };
            event.target.value = "";

            const apiResult = await this.fileUpload(data);

            if (apiResult && apiResult.wasSuccessful) {
                runInAction(() => {
                    this.model[fieldName + "FileName"] = data.fileName;
                    this.model[fieldName + "Url"] = apiResult.payload;
                });
            }
        }
    };

    @action
    public deleteDocument = async (fieldName: keyof FieldType<ScaffoldInspectionDetailModel>): Promise<void> => {
        runInAction(() => {
            this.model[fieldName + "FileName"] = "";
            this.model[fieldName + "Url"] = "";
        });
    };
    /**
     * Download a file that exists in azure.
     * @param fileUrl The URL of the file to be downloaded.
     * @param fileName The name of the file to be downloaded.
     */
    public DownloadFile = async (fieldName: any): Promise<void> => {
        try {
            const apiResult = await this.Post<Blob>(AppUrls.Server.File.DownloadFile, this.model[fieldName + "Url"], undefined, { responseType: "blob" });
            const response = apiResult as any;
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", this.model[fieldName + "FileName"]);
            document.body.appendChild(link);
            link.click();
        } catch (exception) {
            console.error(exception);
            this.setIsErrored(true);
        }
    };

    /**
     * Upload a file to azure.
     * @param data The data of the file to be uploaded.
     * @returns apiResult.
     */
    public fileUpload = async (data: any): Promise<ApiResult<any>> => {
        const formData = new FormData();
        formData.append("formFile", data.formFile);
        formData.append("fileName", data.fileName);
        const apiResult = await this.Post<any>(AppUrls.Server.File.UploadFile, formData);
        if (apiResult) {
            if (!apiResult.wasSuccessful) {
                console.log(apiResult.errors);
                runInAction(() => {
                    /*this.setSnackMessage("Error uploading file please try again.");
                    this.setSnackType(this.SNACKERROR);
                    this.setSnackbarState(true);*/
                });
            }
        }
        return apiResult;
    };

    public async isFieldValid(fieldName: keyof FieldType<ScaffoldInspectionDetailModel>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
