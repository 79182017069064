import { FieldType, ViewModelBase, isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import { action, computed, runInAction } from "mobx";

import { AppUrls } from "AppUrls";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { RamsItemModel } from "./RamsItemModel";
import { IInductionRAMSResponse, RamsModel } from "./RamsModel";
import { UserModel } from "../Common/UserModel";

export class RamsViewModel extends ViewModelBase<RamsModel> {
    public server: ServerViewModel = new ServerViewModel();
    public userModel: UserModel = new UserModel();

    public lastName: string = "";
    public lastThreeDigits: string = "";
    public projectId: string = "";

    constructor(projectId: string) {
        super(new RamsModel());

        this.setDecorators(this.model);
        this.userModel = JSON.parse(localStorage.getItem("user") as any);

        if (this.userModel === null) {
            this.userModel = new UserModel();
        }

        this.model.visitorTypeName = this.userModel.visitorTypeName;
        this.projectId = projectId;

        this.getRamsByProjectId();
    }

    // #region Site Video

    @action
    public setSiteVideoFinished = () => {
        this.model.siteVideoFinished = true;
    };

    // /**
    //  * Unless validation ensures only an id is provided, a youtube url
    //  * can come in many forms (embdeded, shorts etc). So we should try
    //  * and anticipate that and improve the chances the video will play.
    //  */
    // @computed
    // public get siteVideoId() {
    //     if (!isEmptyOrWhitespace(this.model.siteVideoLink)) {
    //         const regex = /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm;
    //         const result = regex.exec(this.model.siteVideoLink!);

    //         return result ? result[3] : this.model.siteVideoLink!;
    //     }

    //     return "";
    // }

    @computed
    public get siteVideoLink(): string {
        if (!isEmptyOrWhitespace(this.model.siteVideoLink) && this.model.siteVideoLink != null) {
            return this.model.siteVideoLink;
        }

        return "";
    }

    @computed
    public get canDisplaySiteVideo() {
        return !isEmptyOrWhitespace(this.siteVideoLink) && !this.model.siteVideoFinished;
    }

    @computed
    public get canDisplayRAMSQuestions() {
        return isEmptyOrWhitespace(this.siteVideoLink) || this.model.siteVideoFinished;
    }

    // #endregion Site Video

    // #region Distances Travelled

    @computed
    public get canDisplayDistance() {
        return this.model.distanceRequired;
    }

    // #endregion Distances Travelled

    // #region Question 1: Risk Assessment

    @action
    public setRiskAssessmentConfirmed = (value: string) => {
        this.model.q1 = value;
    };

    @computed
    public get canDisplayRiskAssessment() {
        return this.model.q1Required;
    }

    // #endregion Question 1: Risk Assessment

    // #region Question 2: Working Practices

    @action
    public setWorkingPracticesConfirmed = (value: string) => {
        this.model.q2 = value;
    };

    @computed
    public get canDisplayWorkinigPractices() {
        return this.model.q2Required;
    }

    // #endregion Question 2: Working Practices

    // #region Question 3: Tools and Equipment

    @action
    public setToolsAndEquipmentConfirmed = (value: string) => {
        this.model.q3 = value;
    };

    @computed
    public get canDisplayToolsAndEquipment() {
        return this.model.q3Required;
    }

    // #endregion Question 3: Tools and Equipment

    // #region Question 4: Personal Protective Equipment

    @action
    public setPersonalProtectiveEquipmentConfirmed = (value: string) => {
        this.model.q4 = value;
    };

    @computed
    public get canDisplayPersonalProtectiveEquipment() {
        return this.model.q4Required;
    }

    // #endregion Question 4: Personal Protective Equipment

    // #region Question 5: RAMS selection

    @action
    public setRamsReviewConfirmed = (value: string) => {
        this.model.ramsReviewConfirmed = value;
    };

    @action
    public setRams = (value: RamsItemModel | null): void => {
        this.model.ramsId = value ? value.id : RamsItemModel.DEFAULT_ID;

        // SIDE-EFFECT:
        // Set the confirmation to default if the user selects an option
        // as we want them to reconfirm if the selection has changed.
        this.model.ramsReviewConfirmed = RamsModel.DEFAULT_RAMSCONFIRMED;
    };

    @computed
    public get rams(): RamsItemModel | null {
        const result = this.model.ramSs.find((r) => r.id === this.model.ramsId);

        return result ? result! : null;
    }

    @computed
    public get ramSs() {
        return this.model.ramSs;
    }

    @computed
    public get canDisplayRamsDownload() {
        const attachmentCount = this.model.ramSs.find((r) => r.id === this.model.ramsId)?.attachmentCount;
        const haveAttachments = !isNullOrUndefined(attachmentCount) && attachmentCount! > 0;

        return !isEmptyOrWhitespace(this.model.ramsId) && haveAttachments && this.model.ramsReviewConfirmed !== "true";
    }

    @computed
    public get canSetRams() {
        return this.model.ramsReviewConfirmed !== "true";
    }

    @computed
    public get canDisplayRamsReviewConfirmation() {
        return !isEmptyOrWhitespace(this.model.ramsId);
    }

    @computed
    public get canDisplayRams() {
        return this.model.q5RamsRequired;
    }

    // #endregion Question 5: RAMS selection

    // #region Navigation

    public goBack = () => {
        this.history.push(AppUrls.Client.Induction.Access.Landing.replace(":projectId", this.projectId));
    };

    // #endregion Navigation

    // #region Api Actions

    public getRamsByProjectId = (): Promise<void> => {
        return this.server.query<IInductionRAMSResponse>(
            () => this.Get(AppUrls.Server.Induction.GetRAMSsByProjectId.replace(":projectId", this.projectId)),
            (result) => this.model.fromDto(result),
            "There was an error trying to get RAMSs for this project",
        );
    };

    public getRamsDocumentByRAMSId = async (): Promise<void> => {
        return this.server.queryFile(
            AppUrls.Server.Induction.GetRAMSAttachmentsByRAMSId.replace(":ramsId", this.model.ramsId),
            (data, filename) => {
                // Create a link element for the file and use the filename provided.
                const link = document.createElement("a");

                link.href = window.URL.createObjectURL(new Blob([data]));
                link.setAttribute("download", filename);

                document.body.appendChild(link);

                // Download.
                link.click();

                // Having clicked the link, delete the element otherwise it will
                // remain attached to the document.
                document.body.removeChild(link);
            },
            "There was an error trying to get the file for this RAMS option",
        );
    };

    public signIn = async () => {
        if (this.server.IsBusy) {
            return;
        }

        return this.server.command<UserModel>(
            () =>
                this.Post(`${AppUrls.Server.Induction.SignIn}`, {
                    lastName: this.lastName,
                    lastThreeDigits: this.lastThreeDigits,
                    ramsAnswered: true,
                    ramsId: this.model.ramsId,
                    distanceTravelled: this.model.distanceTravelled,
                    projectId: this.projectId,
                }),
            (result: any) => {
                runInAction(() => {
                    this.userModel.id = result.id;
                    this.userModel.fullName = result.firstName + " " + result.lastName;
                    this.userModel.priorityEmployerName = result.priorityEmployerName;
                    this.userModel.photographUrl = result.photographUrl;

                    localStorage.setItem("user", JSON.stringify(this.userModel));

                    this.userModel.signedIn = true;

                    localStorage.setItem("signedIn", "true");
                    localStorage.setItem("signedInDate", new Date().toLocaleDateString());

                    this.history.push(AppUrls.Client.Induction.Access.SignedIn);
                });
            },
            this.isModelValid,
        );
    };

    // #endregion Api Actions

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<RamsModel>): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
