import { FormControlLabel, RadioGroup } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import React from "react";

import { SHBox } from "Components/Box";
import { DarwinDateSelect } from "Components/DateSelect/DarwinDateSelect";
import { Uploader } from "Components/Uploader/Uploader";
import DeleteLogo from "Content/Bin.svg";
import { DarwinInput } from "Globals/Styles/Control/DarwinInput";
import { isEmpty } from "Utils/Utils";
import { QualificationViewModel } from "./QualificationViewModel";
import { CustomRadio } from "../../Project/CustomComponents";
import { DashedDivider } from "../../PurchaseOrder/Form/Views/Dividers";
import { DarwinInputWrapper } from "Components/Form/DarwinInputWrapper";
import moment from "moment";

const Title = styled.p`
    font-weight: 600;
    font-size: 14px;
    letter-spacing: -0.02em;
    line-height: 18px;
    text-align: left;
    color: #171716;
`;

const SubTitle = styled.p`
    font-weight: 600;
    font-size: 12px;
    letter-spacing: -0.02em;
    line-height: 18px;
    text-align: left;
    color: #171716;
`;

interface SectionProps {
    viewModel: QualificationViewModel;
    lastItem?: boolean;
}

export const QualificationView: React.FC<SectionProps> = observer((props: SectionProps) => {
    const { viewModel } = props;
    const divRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (divRef.current && props.lastItem) {
            divRef.current.scrollIntoView({ behavior: "smooth" });
        }
    });

    return (
        <>
            <SHBox mt={3} pb={3}>
                <SHBox>
                    <Title>{viewModel.getValue("name")}</Title>
                    <SHBox flexBox alignItems={"center"}>
                        <SubTitle>Passed:</SubTitle>
                        <RadioGroup
                            row
                            defaultValue="Yes"
                            name={"radio-" + "" + "-group"}
                            value={viewModel.getValue("passed")}
                            onChange={(e) => viewModel.sectionChange("passed", e.target.value)}
                        >
                            <FormControlLabel value="true" control={<CustomRadio checked={viewModel.getValue("passed") === "true"} />} label="Yes" />
                            <FormControlLabel value="false" control={<CustomRadio checked={viewModel.getValue("passed") === "false"} />} label="No" />
                            {viewModel.getValue("hasNAOption") && (
                                <FormControlLabel value="na" control={<CustomRadio checked={viewModel.getValue("passed") === "na"} />} label="N/A" />
                            )}
                        </RadioGroup>
                    </SHBox>
                </SHBox>
            </SHBox>

            {/* Display divider if user selects passed or failed */}
            <SHBox showIf={viewModel.canDisplayPassedFormItems || viewModel.canDisplayFailedFormItems}>
                <DashedDivider gutterBottom />
            </SHBox>

            {/* Section to display if the user selects passed */}
            <SHBox showIf={viewModel.canDisplayPassedFormItems} grid dc={"1fr 1fr"} mc={"1fr"} mb={3}>
                <SHBox>
                    <SHBox>
                        <DarwinInput
                            fieldName={"typeLevel"}
                            label="Type/level:"
                            maxLength={1024}
                            multiLine={true}
                            shrink={true}
                            type="text"
                            validateOnBlur={true}
                            viewModel={viewModel}
                        />
                    </SHBox>
                    <SHBox grid dc={"1fr 1fr"} mc={"1fr"} mt={3}>
                        <DarwinInput fieldName={"idRollNumber"} label="ID/Roll number:" shrink={true} validateOnBlur={true} viewModel={props.viewModel} />
                        <DarwinDateSelect
                            displayName="Expiry date:"
                            execute={(value: string | null) => viewModel.setValue(`expiryDate`, value)}
                            minDate={moment.utc().toDate()}
                            format="DD/MM/YYYY"
                            onBlur={() => viewModel.isFieldValid("expiryDate")}
                            placeholder="Please select"
                            validationMessage={viewModel.getError("expiryDate")}
                            value={viewModel.getValue("expiryDate")}
                        />
                    </SHBox>
                </SHBox>
                <div ref={divRef}>
                    <SHBox showIf={isEmpty(viewModel.model.photoEvidenceData)}>
                        <Uploader
                            style={{ height: "150px" }}
                            handleSelectFile={(e: React.ChangeEvent<HTMLInputElement>) => viewModel.fileChange(e)}
                            isFormDisabled={viewModel.IsLoading}
                            label="Photo evidence"
                        />
                    </SHBox>
                    <SHBox showIf={!isEmpty(viewModel.model.photoEvidenceData)}>
                        <SubTitle>Photo Evidence:</SubTitle>
                        <SHBox flexBox style={{ cursor: "pointer" }}>
                            <img width={"85%"} src={viewModel.model.photoEvidenceData} alt="Photo evidence" style={{ cursor: "default" }} />
                            <div title={"Remove picture"} className="icon" onClick={viewModel.deletePicture}>
                                <img src={DeleteLogo} />
                            </div>
                        </SHBox>
                    </SHBox>
                </div>
            </SHBox>

            {/* Section to display if the user selects failed */}
            <SHBox showIf={viewModel.canDisplayFailedFormItems} grid dc={"1fr 1fr"} mc={"1fr"} mb={3}>
                <SHBox>
                    <SHBox>
                        {viewModel.model.failedTypeLevelLabel && (
                            <>
                                <SubTitle>If no CSCS card is available you must list your experience and relevant qualifications to complete the work.</SubTitle>
                                <SubTitle>The Site Manager will make the decision on whether or not it is acceptable.</SubTitle>
                            </>
                        )}
                        <DarwinInputWrapper
                            fieldName={"typeLevel"}
                            label={!viewModel.model.failedTypeLevelLabel ? "Type/level:" : ""}
                            maxLength={1024}
                            multiLine={true}
                            shrink={true}
                            type="text"
                            validateOnBlur={true}
                            viewModel={viewModel}
                            editMode={true}
                        />
                    </SHBox>
                </SHBox>
            </SHBox>
        </>
    );
});
