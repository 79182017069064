import { ApiResult, FieldType, ViewModelBase } from "@shoothill/core";
import { SiteAccessModel, SiteAccessModelDTO } from "./SiteAccessModel";
import { observable, action, computed, runInAction } from "mobx";
import type { IObservableArray } from "mobx";
import { AppUrls } from "AppUrls";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { ProjectSiteAccessItemViewModel } from "./ProjectSiteAccessItemViewModel";
import { SiteAccessFilterViewModel } from "./SiteAccessFilterViewModel";
import debounce from "lodash/debounce";
import axios, * as Axios from "axios";

export class SiteAccessViewModel extends ViewModelBase<SiteAccessModel> {
    private static _instance: SiteAccessViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable
    public hasLoaded: boolean = false;

    @action
    public setHasLoaded = (val: boolean) => {
        this.hasLoaded = val;
    };

    @observable public errorMessage: string = "";

    public constructor() {
        super(new SiteAccessModel(), false);
        this.setDecorators(SiteAccessModel);
    }

    @action
    public clean = () => {
        this.siteAccessItemViewModels.replace([]);
        this.itemsToSignOut.replace([]);
    };

    @observable
    public siteAccessItemViewModels: IObservableArray<ProjectSiteAccessItemViewModel> = observable([]);

    /**
     * List of invoice projects for the invoice project list.
     */
    @computed
    public get getSiteAccessItems(): ProjectSiteAccessItemViewModel[] {
        return this.siteAccessItemViewModels;
    }

    @observable
    public itemsToSignOut: IObservableArray<string> = observable([]);

    @computed
    public get getItemsToSignOut(): string[] {
        return this.itemsToSignOut;
    }

    @computed
    public get allItemsSelected(): boolean {
        return (
            this.itemsToSignOut.length === this.getSiteAccessItems.filter((i) => !i.model.hasSignedOut).length &&
            this.getSiteAccessItems.length > 0 &&
            this.getSiteAccessItems.filter((i) => !i.model.hasSignedOut).length > 0
        );
    }

    @action
    public changeItemToSignOut = (itemId: string) => {
        this.setIsLoading(true);
        const existingItem: string | undefined = this.itemsToSignOut.find((i) => i === itemId);
        if (existingItem) {
            this.itemsToSignOut.replace(this.itemsToSignOut.filter((i) => i !== itemId));
        } else {
            this.itemsToSignOut.push(itemId);
        }

        this.setIsLoading(false);
    };

    @action
    public handleSelectAll = (checked: boolean) => {
        this.setIsLoading(true);

        if (checked) {
            this.siteAccessItemViewModels
                .filter((i) => !i.model.hasSignedOut)
                .forEach((item) => {
                    if (this.itemsToSignOut.findIndex((i) => i === item.id) === -1) {
                        this.itemsToSignOut.push(item.id);
                    }
                });
        } else {
            this.itemsToSignOut.replace([]);
        }

        this.setIsLoading(false);
    };

    @computed
    public get hasSignoutItems(): boolean {
        return this.siteAccessItemViewModels.filter((i) => !i.model.hasSignedOut).length > 0;
    }

    @action
    public createViewModels = () => {
        for (const item of this.model.siteAccessItems) {
            this.siteAccessItemViewModels.push(new ProjectSiteAccessItemViewModel(item));
        }
    };

    @computed
    public get qrCodePDFFileName(): string {
        return "filename.pdf";
    }

    public server: ServerViewModel = new ServerViewModel();

    public loadProjectSiteAccess = async (id: string): Promise<ApiResult<SiteAccessModelDTO>> => {
        this.setIsLoading(true);
        let paramsViewModel = SiteAccessFilterViewModel.Instance;
        let params = paramsViewModel.model.toDto();
        params.id = id;

        const apiResult = await this.Post<SiteAccessModelDTO>(AppUrls.Server.Projects.SiteAccess.GetProjectSiteAccessById, params);
        if (apiResult) {
            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.model.siteAccessItems = [];
                    this.siteAccessItemViewModels.clear();
                    this.model.fromDto(apiResult.payload);
                    this.createViewModels();
                });
            } else {
                console.log(apiResult.errors);
            }
        }
        this.setHasLoaded(true);
        this.setIsLoading(false);
        return apiResult;
    };

    public saveSiteVideoLink = async (id: string): Promise<void> => {
        this.setIsLoading(true);

        let dto: { id: string; siteVideoLink: string | null } = { id: id, siteVideoLink: this.model.siteVideoLink };

        return this.server
            .command<any>(
                () => this.Post(AppUrls.Server.Projects.SiteAccess.UpdateSiteVideoLink, dto),
                (result: any) => {
                    runInAction(() => {
                        // Show snack bar to confirm if it's saved.
                        this.setDirty("siteVideoLink", false);
                    });
                },
                this.isModelValid,
                "There was an error trying to save the site video url.",
            )
            .finally(() => this.setIsLoading(false));
    };

    public downloadDocument = async (projectId: string, name: string) => {
        let response = (await this.Get(`/api/induction/createfromurl/${projectId}/${name}`, true, { responseType: "blob" })) as any;
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "qrdocument.pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
    };

    public pdfFileName(siteName: string) {
        const clean = siteName.replace(/[^a-zA-Z0-9]/g, "");
        return `${clean}SiteAccessQRCode.pdf`;
    }

    @action
    public handleChangeStartDate = (id: string, date: string | null) => {
        SiteAccessFilterViewModel.Instance.setStartDateFilter(date === null ? date : new Date(date));
        const isValid: boolean = SiteAccessFilterViewModel.Instance.model.validateFilters();
        if (isValid) {
            this.loadProjectSiteAccess(id);
        }
    };

    @action
    public handleChangeEndDate = (id: string, date: string | null) => {
        SiteAccessFilterViewModel.Instance.setEndDateFilter(date === null ? date : new Date(date));
        const isValid: boolean = SiteAccessFilterViewModel.Instance.model.validateFilters();
        if (isValid) {
            this.loadProjectSiteAccess(id);
        }
    };

    @action
    public handleResetDateFilters = (id: string) => {
        SiteAccessFilterViewModel.Instance.resetDateFilters();
        const isValid: boolean = SiteAccessFilterViewModel.Instance.model.validateFilters();
        if (isValid) {
            this.loadProjectSiteAccess(id);
        }
    };

    public forceInductionsSignOut = async (id: string): Promise<ApiResult<SiteAccessModelDTO>> => {
        this.setIsLoading(true);

        const ids: string[] = this.getItemsToSignOut;

        const apiResult = await this.Post<SiteAccessModelDTO>(AppUrls.Server.Projects.SiteAccess.ForceInductionsSignOut, { ids });
        if (apiResult) {
            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.itemsToSignOut.replace([]);
                    this.loadProjectSiteAccess(id);
                });
            } else {
                console.log(apiResult.errors);
            }
        }
        this.setHasLoaded(true);
        this.setIsLoading(false);
        return apiResult;
    };

    public handleSearchChange = (id: string, val: string) => {
        SiteAccessFilterViewModel.Instance.setValue("searchText", val);

        if (val.length > 3 || val.length === 0) {
            this.reloadDataDelayed(id);
        }
    };

    private reloadDataDelayed = debounce((id: string) => {
        this.loadProjectSiteAccess(id);
    }, 300);

    @action
    public handleSignoutFilterChange = (id: string, val: boolean) => {
        SiteAccessFilterViewModel.Instance.setValue("showOnSiteOnly", val);
        this.reloadDataDelayed(id);
    };

    @action
    public generateSiteAccessCSV = async (id: string) => {
        this.setIsLoading(true);

        // JC: Download a CSV file using a HTTP POST request.
        // Source: https://stackoverflow.com/a/55138366

        let paramsViewModel = SiteAccessFilterViewModel.Instance;
        let params = paramsViewModel.model.toDto();
        params.id = id;

        let config: Axios.AxiosRequestConfig = {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
        };

        const response = await axios
            .post(AppUrls.Server.Projects.SiteAccess.GenerateProjectSiteAccessCSV, params, await this.getConfig(true, config))
            .then((response: any) => {
                if (response.status === 200) {
                    const headerFileName: string = response.headers["content-disposition"].split("filename=")[1].split(";")[0];
                    let fileName = "ProjectSiteAccessCSV.csv";
                    if (headerFileName.endsWith(".csv")) {
                        fileName = headerFileName;
                    }
                    const url_1 = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url_1;
                    link.setAttribute("download", fileName);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    window.URL.revokeObjectURL(url_1);
                }
            })
            .finally(() => this.setIsLoading(false));
    };

    public async isFieldValid(fieldName: keyof FieldType<SiteAccessModel>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
