import { ModelBase, isEmptyOrWhitespace } from "@shoothill/core";
import { formatDate, formatTime } from "Utils/Format";
import { action, observable, computed } from "mobx";

export class BuildingControlSiteVisitModel extends ModelBase<BuildingControlSiteVisitModel> {
    public static readonly DEFAULT_ID = null;
    public static readonly DEFAULT_PROJECT_ID = "";
    public static readonly DEFAULT_BUILDING_CONTROL_SITE_VISIT_DATE = null;
    public static readonly DEFAULT_BUILDING_CONTROL_INSPECTION_TYPE_ID = "";
    public static readonly DEFAULT_WORK_INSPECT_DETAIL = "";
    public static readonly DEFAULT_HAS_PASSED_INSPECTION = false;
    public static readonly DEFAULT_COMMENTS = "";
    public static readonly DEFAULT_BUILDING_CONTROL_SITE_VISIT_PHOTOS = [];
    public static readonly DEFAULT_BUILDING_INSPECTOR_NAME = "";
    public static readonly DEFAULT_SIGNATURE_ISSUED_BY_URL = null;
    public static readonly DEFAULT_CREATED_DATE = null;
    public static readonly DEFAULT_CREATED_BY_USER_ID = null;
    public static readonly DEFAULT_LAST_UPDATED_DATE = null;
    public static readonly DEFAULT_LAST_UPDATED_BY_USER_ID = null;
    public static readonly DEFAULT_IS_DELETED = false;
    public static readonly DEFAULT_ROW_VERSION = "";

    @observable
    public id: string | null = BuildingControlSiteVisitModel.DEFAULT_ID;
    @observable
    public projectId: string = BuildingControlSiteVisitModel.DEFAULT_PROJECT_ID;
    @observable
    public buildingControlSiteVisitDate: any = BuildingControlSiteVisitModel.DEFAULT_BUILDING_CONTROL_SITE_VISIT_DATE;
    @observable
    public buildingControlInspectionTypeId: string | null = BuildingControlSiteVisitModel.DEFAULT_BUILDING_CONTROL_INSPECTION_TYPE_ID;
    @observable
    public workInspectDetail: string = BuildingControlSiteVisitModel.DEFAULT_WORK_INSPECT_DETAIL;
    @observable
    public hasPassedInspection: boolean = BuildingControlSiteVisitModel.DEFAULT_HAS_PASSED_INSPECTION;
    @observable
    public comments: string = BuildingControlSiteVisitModel.DEFAULT_COMMENTS;
    @observable
    public buildingControlSiteVisitPhotos: BuildingControlSiteVisitPhotoFile[] = BuildingControlSiteVisitModel.DEFAULT_BUILDING_CONTROL_SITE_VISIT_PHOTOS;
    @observable
    public buildingControlReportPhotos: BuildingControlReportPhotosFile[] = BuildingControlSiteVisitModel.DEFAULT_BUILDING_CONTROL_SITE_VISIT_PHOTOS;
    @observable
    public buildingInspectorName: string | null = BuildingControlSiteVisitModel.DEFAULT_BUILDING_INSPECTOR_NAME;
    @observable
    public signatureIssuedByURL: string | null = BuildingControlSiteVisitModel.DEFAULT_SIGNATURE_ISSUED_BY_URL;
    @observable
    public createdDate: any = BuildingControlSiteVisitModel.DEFAULT_CREATED_DATE;
    @observable
    public createdByUserId: string | null = BuildingControlSiteVisitModel.DEFAULT_CREATED_BY_USER_ID;
    @observable
    public lastUpdatedDate: string | null = BuildingControlSiteVisitModel.DEFAULT_LAST_UPDATED_DATE;
    @observable
    public lastUpdatedByUserId: string | null = BuildingControlSiteVisitModel.DEFAULT_LAST_UPDATED_BY_USER_ID;
    @observable
    public isDeleted: boolean = BuildingControlSiteVisitModel.DEFAULT_IS_DELETED;
    @observable
    public rowVersion: string | null = BuildingControlSiteVisitModel.DEFAULT_ROW_VERSION;

    @computed
    public get hasId(): boolean {
        return !isEmptyOrWhitespace(this.id);
    }

    @computed
    public get formattedLastUpdatedDate(): string {
        const date = !isEmptyOrWhitespace(this.lastUpdatedDate) ? this.lastUpdatedDate : this.createdDate;

        return `${formatDate(date)} @ ${formatTime(date)}`;
    }

    @computed
    public get updatedByUserId(): string {
        const userId = !isEmptyOrWhitespace(this.lastUpdatedByUserId) ? this.lastUpdatedByUserId : this.createdByUserId;

        return userId ?? "";
    }

    @computed
    public get formattedAuditorCreationDate(): string {
        return `${formatDate(this.createdDate)} @ ${formatTime(this.createdDate)}`;
    }

    @computed
    public get auditorUserId(): string {
        return this.createdByUserId ?? "";
    }

    @action
    public reset = () => {
        this.id = BuildingControlSiteVisitModel.DEFAULT_ID;
        this.projectId = BuildingControlSiteVisitModel.DEFAULT_PROJECT_ID;
        this.buildingControlSiteVisitDate = BuildingControlSiteVisitModel.DEFAULT_BUILDING_CONTROL_SITE_VISIT_DATE;
        this.buildingControlInspectionTypeId = BuildingControlSiteVisitModel.DEFAULT_BUILDING_CONTROL_INSPECTION_TYPE_ID;
        this.workInspectDetail = BuildingControlSiteVisitModel.DEFAULT_WORK_INSPECT_DETAIL;
        this.hasPassedInspection = BuildingControlSiteVisitModel.DEFAULT_HAS_PASSED_INSPECTION;
        this.comments = BuildingControlSiteVisitModel.DEFAULT_COMMENTS;
        this.buildingControlSiteVisitPhotos = BuildingControlSiteVisitModel.DEFAULT_BUILDING_CONTROL_SITE_VISIT_PHOTOS;
        this.buildingInspectorName = BuildingControlSiteVisitModel.DEFAULT_BUILDING_INSPECTOR_NAME;
        this.signatureIssuedByURL = BuildingControlSiteVisitModel.DEFAULT_SIGNATURE_ISSUED_BY_URL;
        this.buildingControlReportPhotos = BuildingControlSiteVisitModel.DEFAULT_BUILDING_CONTROL_SITE_VISIT_PHOTOS;
        this.createdByUserId = BuildingControlSiteVisitModel.DEFAULT_CREATED_BY_USER_ID;
    };

    @action
    public fromDto(dto: BuildingControlSiteVisitDTO): void {
        for (let key in dto) {
            if (dto.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(dto[key]);
                } else {
                    this[key] = dto[key];
                }
            }
        }
    }

    public toDto() {
        const BuildingControlSiteVisitModel: BuildingControlSiteVisitDTO = {
            id: this.id,
            projectId: this.projectId,
            buildingControlSiteVisitDate: this.buildingControlSiteVisitDate,
            buildingControlInspectionTypeId: this.buildingControlInspectionTypeId,
            workInspectDetail: this.workInspectDetail,
            hasPassedInspection: this.hasPassedInspection,
            comments: this.comments,
            buildingInspectorName: this.buildingInspectorName,
            signatureIssuedByURL: this.signatureIssuedByURL,
            createdByUserId: this.createdByUserId,
            createdDate: this.createdDate,
            isDeleted: this.isDeleted,
            lastUpdatedByUserId: this.lastUpdatedByUserId,
            lastUpdatedDate: this.lastUpdatedDate,
            rowVersion: this.rowVersion,
        };

        return BuildingControlSiteVisitModel;
    }

    @action
    public fromRelatedDto(dto: BuildingControlSiteVisitResponseDTO): void {}

    // #Start Custome Validation

    @computed
    public get validateInspectionTypeId(): string {
        return this.buildingControlInspectionTypeId === BuildingControlSiteVisitModel.DEFAULT_BUILDING_CONTROL_INSPECTION_TYPE_ID ? "Please select a inspection Type" : "";
    }

    @computed
    public get validateAreaOfWorkInspected(): string {
        return this.workInspectDetail === BuildingControlSiteVisitModel.DEFAULT_WORK_INSPECT_DETAIL ? "Please enter a area of work inspected" : "";
    }

    @computed
    public get validateComments(): string {
        return this.comments === BuildingControlSiteVisitModel.DEFAULT_COMMENTS ? "Please enter a comments" : "";
    }

    @computed
    public get validateBuildingInspectorName(): string {
        return isEmptyOrWhitespace(this.buildingInspectorName) ? "Please enter the name of the inspector" : "";
    }

    @computed
    public get validateSignatureIssuedByURL(): string {
        return this.signatureIssuedByURL === BuildingControlSiteVisitModel.DEFAULT_SIGNATURE_ISSUED_BY_URL ? "Please enter a  signature" : "";
    }

    // #End Custome Validation
}

export interface BuildingControlSiteVisitResponseDTO {
    buildingControlInspectionType: buildingControlInspectionTypeDTO[];
    buildingControlSiteVisitProjectDetails: BuildingControlSiteVisitProjectDetailsDTO;
}

export interface BuildingControlSiteVisitProjectDetailsDTO {
    projectReference: string;
    projectName: string;
}

export interface buildingControlInspectionTypeDTO {
    id: string;
    displayName: string;
    type: number;
    Ordinal: number;
}

export interface BuildingControlSiteVisitAuditUserDTO {
    id: string;
    displayName: string;
}

export interface BuildingControlSiteVisitDTO {
    id: string | null;
    projectId: string;
    buildingControlSiteVisitDate: string;
    buildingControlInspectionTypeId: string | null;
    workInspectDetail: string;
    hasPassedInspection: boolean;
    comments: string;
    buildingInspectorName: string | null;
    signatureIssuedByURL: string | null;
    createdByUserId: string | null;
    createdDate: string | null;
    isDeleted: boolean;
    lastUpdatedByUserId: string | null;
    lastUpdatedDate: string | null;
    rowVersion: string | null;
}

export interface BuildingControlSiteVisitPhotoFile {
    id: string | null;
    buildingControlSiteVisitId: string | null;
    fileName: string;
    photoURL: string;
    createdByUserId: string | null;
    isDeleted: boolean;
    createdDate: string | null;
}

export interface BuildingControlReportPhotosFile {
    id: string | null;
    buildingControlSiteVisitId: string | null;
    fileName: string;
    photoURL: string;
    createdByUserId: string | null;
    isDeleted: boolean;
    createdDate: string | null;
}

export interface UpsertBuildingControlSiteVisitAndRelatedRequestDTO {
    buildingControlSiteVisit: BuildingControlSiteVisitDTO;
    buildingControlSiteVisitPhotos: BuildingControlSiteVisitPhotoFile[];
    buildingControlReportPhotos: BuildingControlReportPhotosFile[];
}

export interface BuildingControlSiteVisitByIdResponseDTO {
    buildingControlSiteVisit: BuildingControlSiteVisitDTO;
    buildingControlInspectionType: buildingControlInspectionTypeDTO[];
    buildingControlSiteVisitPhotos: BuildingControlSiteVisitPhotoFile[];
    buildingControlReportPhotos: BuildingControlReportPhotosFile[];
    buildingControlSiteVisitProjectDetails: BuildingControlSiteVisitProjectDetailsDTO;
}

export interface BuildingControlSiteVisitAndRelatedResponseDTO {
    buildingControlSiteVisit: BuildingControlSiteVisitDTO;
    buildingControlSiteVisitPhotos: BuildingControlSiteVisitPhotoFile[];
    buildingControlReportPhotos: BuildingControlReportPhotosFile[];
}

export interface FormRequestDTO {
    date: any;
    projectId: string | null;
    formId: string | null;
}
