import { FieldType, ViewModelBase, isEmptyOrWhitespace, observable } from "@shoothill/core";
import { runInAction, action, computed } from "mobx";
import moment from "moment";

import { AppUrls } from "AppUrls";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import {
    HouseKeepingAndRelatedResponseDTO,
    HouseKeepingFormModel,
    HouseKeepingProjectDetailsDTO,
    HouseKeepingRelatedResponseDTO,
    UpsertHouseKeepingAndRelatedRequestDTO,
    HouseKeepingDTO,
    IssuedByUsersDTO,
    SupplierDTO,
} from "./HouseKeepingFormModel";
import { NonConformanceListViewModel } from "../NonConformanceListViewModel";

export class HouseKeepingFormViewModel extends ViewModelBase<HouseKeepingFormModel> {
    public server: ServerViewModel = new ServerViewModel();

    constructor(id: string | null, projectId: string | null, nonConformanceTypeId: string | null) {
        super(new HouseKeepingFormModel());
        this.setDecorators(HouseKeepingFormModel);

        this.model.id = id;
        this.model.projectId = projectId!;
        this.model.nonConformanceTypeId = nonConformanceTypeId!;

        isEmptyOrWhitespace(this.model.id) ? this.loadRelated() : this.loadWithRelated();
    }

    // #region Shared

    @observable
    public housekeepingProjectDetails: HouseKeepingProjectDetailsDTO | null = null;

    @computed
    public get projectDisplayName() {
        return `(${!isEmptyOrWhitespace(this.housekeepingProjectDetails?.projectReference) ? this.housekeepingProjectDetails?.projectReference : "--"} - 
                 ${!isEmptyOrWhitespace(this.housekeepingProjectDetails?.projectName) ? this.housekeepingProjectDetails?.projectName : "--"})`;
    }

    public nonConformanceTypeDisplayName = (list: NonConformanceListViewModel): string => {
        const id = isEmptyOrWhitespace(this.model.id) ? this.model.nonConformanceTypeId : this.houseKeeping?.nonConformanceTypeId;

        return list.nonConformanceTypes.find((i: any) => i.id === id)?.displayName ?? "";
    };

    // #endregion Shared

    // #region Properties

    @action
    public setSupplier = (value: SupplierDTO | null): void => {
        this.setValue("supplierId", value?.id ?? HouseKeepingFormModel.DEFAULT_SUPPLIER_ID);
    };

    @computed
    public get supplier(): SupplierDTO | null {
        return this.model.suppliers.find((u) => u.id === this.model.supplierId) ?? null;
    }

    @computed
    public get suppliers() {
        return this.model.suppliers;
    }

    @action
    public setIssuedByUser = (value: IssuedByUsersDTO | null): void => {
        this.setValue("issuedByUserId", value?.id ?? HouseKeepingFormModel.DEFAULT_ISSUED_BY_USER_ID);
    };

    @computed
    public get issuedByUser(): IssuedByUsersDTO | null {
        return this.model.issuedByUsers.find((u) => u.id === this.model.issuedByUserId) ?? null;
    }

    @computed
    public get issuedByUsers() {
        return this.model.issuedByUsers;
    }

    @action
    public setSignature = (data: string) => {
        this.setValue("signatureIssuedByURL", data);
        this.setValue("signatureIssuedByDate", new Date().toISOString());
    };

    // #endregion Properties

    // #region ReadOnly Properties

    @observable
    public houseKeeping: any = null;

    public get readOnlySupplierDisplayName(): string {
        return this.suppliers.find((i: any) => i.id === this.houseKeeping?.supplierId)?.displayName ?? "";
    }

    // #endregion ReadOnly Properties

    @observable
    public snackMessage = "";

    @observable
    public snackType = "";

    @observable
    public SNACKSUCCESS = "success";

    @observable
    public SNACKERROR = "error";

    @observable
    public snackbarState = false;

    @computed
    public get hasId(): boolean {
        return this.model.id !== null && this.model.id !== undefined && this.model.id !== "";
    }

    @computed
    public get getTodayDateFormatted(): string {
        return this.model.createdDate ? moment(this.model.createdDate).format("DD/MM/YYYY").toString() : moment().format("DD/MM/YYYY").toString();
    }

    @computed
    public get isFormDisabled(): boolean {
        return this.model.id !== null && this.model.id !== undefined && this.model.id !== "";
    }

    // Start Validation

    @computed
    private get validateSupplierId() {
        const errorMessage = this.model.validateSupplierId;
        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }
    @computed
    private get validateIssuedByUserId() {
        const errorMessage = this.model.validateIssuedByUserId;
        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }
    @computed
    private get validateSignatureIssuedByURL() {
        const errorMessage = this.model.validateSignatureIssuedByURL;
        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    //End Validation

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @action
    public reset = () => {
        this.model.reset();
        this.server.reset();
        this.housekeepingProjectDetails = null;
    };

    @action
    public handleCancel(projectId: string | null): void {
        this.reset();
        this.history.push(AppUrls.Client.Project.ConstructionQuality.replace(":projectid", projectId ? projectId : this.model.projectId) + "#nonconformance");
    }

    public upsert = async (): Promise<void> => {
        // APM: Review Note.
        // Shouldn't need this. The server viewmodel has and automatically sets an IsBusy flag.
        this.setIsLoading(true);

        // Create the request body dto.
        const model: HouseKeepingDTO = this.model.toDto();

        const request: UpsertHouseKeepingAndRelatedRequestDTO = {
            housekeeping: model,
        };

        return await this.server
            .command<HouseKeepingAndRelatedResponseDTO>(
                () => this.Post(AppUrls.Server.Projects.Construction.NonConformance.Housekeeping.Upsert, request),
                (result: HouseKeepingAndRelatedResponseDTO) => {
                    runInAction(() => {
                        if (result) {
                            this.handleCancel(result.housekeeping.projectId);
                        }
                    });
                },
                this.isMyModelValid,
                "There was an error trying to send the non-conformance",
            )
            .finally(() => this.setIsLoading(false));
    };

    private isMyModelValid = async (): Promise<boolean> => {
        let isValid = true;
        if ((await this.isModelValid()) === false) {
            isValid = false;
        }

        return isValid;
    };

    public loadRelated = async (): Promise<void> => {
        this.setIsLoading(true);

        return await this.server
            .query<HouseKeepingRelatedResponseDTO>(
                () => this.Get(`${AppUrls.Server.Projects.Construction.NonConformance.Housekeeping.Related}\\${this.model.projectId}\\${this.model.nonConformanceTypeId}`),
                (result) => {
                    runInAction(() => {
                        this.model.suppliers.replace(result.suppliers);
                        this.model.issuedByUsers.replace(result.issuedByUsers);
                        this.housekeepingProjectDetails = result.housekeepingProjectDetails;
                    });
                },
            )
            .finally(() => this.setIsLoading(false));
    };

    public loadWithRelated = async (): Promise<void> => {
        this.setIsLoading(true);

        return await this.server
            .query<any>(
                () => this.Get(`${AppUrls.Server.Projects.Construction.NonConformance.Housekeeping.WithRelatedById}\\${this.model.id}`),
                (result) => {
                    runInAction(() => {
                        this.model.suppliers.replace(result.suppliers);
                        this.model.issuedByUsers.replace(result.issuedByUsers);
                        this.housekeepingProjectDetails = result.housekeepingProjectDetails;
                        this.houseKeeping = result.housekeeping;
                    });
                },
            )
            .finally(() => this.setIsLoading(false));
    };

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<HouseKeepingFormModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);
        if (this.server.IsSubmitted) {
            switch (fieldName) {
                case "supplierId": {
                    const result = this.validateSupplierId;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "issuedByUserId": {
                    const result = this.validateIssuedByUserId;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "signatureIssuedByURL": {
                    const result = this.validateSignatureIssuedByURL;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
            }
        } else {
            errorMessage = "";
            isValid = true;
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
