import { ModelBase } from "@shoothill/core";
import { IsNotEmpty } from "class-validator";
import { observable, computed } from "mobx";

export class InspectionsQuestionModel extends ModelBase<InspectionsQuestionModel> {
    public id: string | null = null;
    @observable
    public question: string = "";
    @observable
    public ordinal: number = 0;
    @observable
    @IsNotEmpty({ message: "Please select a Pass or Fail" })
    public pass: boolean = false;
    public answerId: string | null = null;
    @observable
    public failDescription: string | null = null;

    constructor() {
        super();
    }

    fromDto(model: InspectionsQuestionsDTO): void {
        this.id = model.id;
        this.question = model.question;
        this.ordinal = model.ordinal;
        this.pass = model.pass;
        this.answerId = model.answerId;
        this.failDescription = model.failDescription;
    }
    toDto(): any {
        if (this.pass === null) {
            this.setValue("pass", false);
        }

        if (this.pass === true) {
            this.setValue("failDescription", null);
        }

        return {
            id: this.id,
            question: this.question,
            ordinal: this.ordinal,
            pass: this.pass,
            answerId: this.answerId,
            failDescription: this.failDescription,
        };
    }

    public get radioValue() {
        if (this.pass === null) return ""; //This ensures that no radio button is selected when the value is null

        return this.pass ? "pass" : "fail" || "";
    }

    public static fromDtos(dtos: InspectionsQuestionsDTO[]): InspectionsQuestionsDTO[] {
        const types: InspectionsQuestionsDTO[] = [];

        for (const dto of dtos) {
            const model = new InspectionsQuestionModel();

            model.fromDto(dto);
            types.push(model as any);
        }

        return types;
    }

    @computed
    public get validateFailDescription(): string {
        return this.pass === false && (this.failDescription === null || this.failDescription === "") ? "Please provide a failure description" : "";
    }
}

export interface InspectionsQuestionsDTO {
    id: string;
    ordinal: number;
    pass: boolean;
    question: string;
    answerId: string;
    failDescription: string | null;
}
