import { ModelBase } from "@shoothill/core";
import { IsNotEmpty, Min, NotEquals } from "class-validator";
import { action, observable } from "mobx";

import { IMaterialSupplier, MaterialSupplier } from "../Shared/MaterialSupplier";

export class MaterialPriceDetailsModel extends ModelBase<MaterialPriceDetailsModel> {
    // #region Defaults

    public static readonly DEFAULT_SUPPLIERS = [];

    public static readonly DEFAULT_SUPPLIER = null;
    public static readonly DEFAULT_CURRENTESTIMATEDPRICE = null;
    public static readonly DEFAULT_CURRENTPRICE = null;
    public static readonly DEFAULT_ESTIMATEDPRICE = 0;
    public static readonly DEFAULT_PRICE = 0;
    public static readonly DEFAULT_EFFECTIVEFROMDATE = "";
    public static readonly DEFAULT_NOTE = "";

    // #endregion Defaults

    // #region Related Properties

    @observable
    public suppliers = observable<MaterialSupplier>(MaterialPriceDetailsModel.DEFAULT_SUPPLIERS);

    @observable
    public currentEstimatedPrice: number | null = MaterialPriceDetailsModel.DEFAULT_CURRENTESTIMATEDPRICE;

    @observable
    public currentPrice: number | null = MaterialPriceDetailsModel.DEFAULT_CURRENTPRICE;

    // #endregion Related Properties

    // #region Properties

    @observable
    @NotEquals(null, { message: "Please select an option" })
    public supplier: MaterialSupplier | null = MaterialPriceDetailsModel.DEFAULT_SUPPLIER;

    @observable
    @Min(0.01, { message: "Please provide a price" })
    public estimatedPrice: number = MaterialPriceDetailsModel.DEFAULT_ESTIMATEDPRICE;

    @observable
    @Min(0.01, { message: "Please provide a price" })
    public price: number = MaterialPriceDetailsModel.DEFAULT_PRICE;

    @observable
    @IsNotEmpty({ message: "Please provide a date" })
    public effectiveFromDate: string = MaterialPriceDetailsModel.DEFAULT_EFFECTIVEFROMDATE;

    @observable
    @IsNotEmpty({ message: "Please provide a note" })
    public note: string = MaterialPriceDetailsModel.DEFAULT_NOTE;

    // #endregion Properties

    // #region Methods

    @action
    public fromDto(dto: any): void {
        // Suppliers.
        this.suppliers.replace(
            dto.suppliers.map((s: IMaterialSupplier) => {
                const type = new MaterialSupplier();

                type.fromDto(s);

                return type;
            }),
        );

        this.supplier = this.suppliers.find((s) => s.id === dto.materialPriceDetails.supplierId) ?? MaterialPriceDetailsModel.DEFAULT_SUPPLIER;
        this.currentEstimatedPrice = dto.materialPriceDetails.estimatedPrice;
        this.currentPrice = dto.materialPriceDetails.price;
        this.estimatedPrice = dto.materialPriceDetails.estimatedPrice;
        this.price = dto.materialPriceDetails.price;
        this.effectiveFromDate = dto.materialPriceDetails.effectiveFromDate;
        this.note = dto.materialPriceDetails.note;
    }

    public toDto(): any {
        return {
            supplierId: this.supplier?.id,
            estimatedPrice: this.estimatedPrice,
            price: this.price,
            effectiveFromDate: this.effectiveFromDate,
            note: this.note,
        };
    }

    // #region Methods
}
