import { isEmptyOrWhitespace, ModelBase } from "@shoothill/core";
import { action, computed, observable } from "mobx";

import { OrderLineModel } from "./../Form/OrderLineModel";
import { getTotalPrice, validateFourDecimalPlaces } from "Utils/Utils";

export class RequisitionRequestItemModel extends ModelBase<RequisitionRequestItemModel, RequisitionRequestItemResponseDTO> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Defaults and Constants

    public static readonly DEFAULT_ID = null;
    public static readonly DEFAULT_ROWVERSION = null;

    public static readonly DEFAULT_REQUISITIONREQUESTITEMGROUPID = null;

    public static readonly DEFAULT_ITEMDESCRIPTION = "";
    public static readonly DEFAULT_UNITS = 0;
    public static readonly DEFAULT_UNITPRICE = 0;
    public static readonly DEFAULT_ISDELETED = false;
    public static readonly DEFAULT_ORDINAL = 1;
    public static readonly DEFAULT_CANDELETEITEM = true;
    public static readonly DEFAULT_HASBEENAPPROVED = false;
    public static readonly DEFAULT_MATERIALID = null;

    // #endregion Defaults and Constants

    // #region Observables

    @observable
    public id: string | null = RequisitionRequestItemModel.DEFAULT_ID;

    @observable
    public rowVersion: string | null = RequisitionRequestItemModel.DEFAULT_ROWVERSION;

    @observable
    public requisitionRequestItemGroupId: string | null = RequisitionRequestItemModel.DEFAULT_REQUISITIONREQUESTITEMGROUPID;

    @observable
    public itemDescription: string = RequisitionRequestItemModel.DEFAULT_ITEMDESCRIPTION;

    @observable
    public units: number = RequisitionRequestItemModel.DEFAULT_UNITS;

    @observable
    public unitPrice: number = RequisitionRequestItemModel.DEFAULT_UNITPRICE;

    @observable
    public isDeleted: boolean = RequisitionRequestItemModel.DEFAULT_ISDELETED;

    @observable
    public ordinal: number = RequisitionRequestItemModel.DEFAULT_ORDINAL;

    @observable
    public unitsSnapshot: number = RequisitionRequestItemModel.DEFAULT_UNITS;

    @observable
    public unitPriceSnapshot: number = RequisitionRequestItemModel.DEFAULT_UNITPRICE;

    @observable
    public canDeleteItem: boolean = RequisitionRequestItemModel.DEFAULT_CANDELETEITEM;

    @observable
    public hasBeenApproved: boolean = RequisitionRequestItemModel.DEFAULT_HASBEENAPPROVED;

    @observable
    public materialId: string | null = RequisitionRequestItemModel.DEFAULT_MATERIALID;

    // #endregion Observables

    // #region Actions

    @action
    public fromDto(dto: RequisitionRequestItemResponseDTO): void {
        this.id = dto.id;
        this.rowVersion = dto.rowVersion;
        this.requisitionRequestItemGroupId = dto.requisitionRequestItemGroupId;
        this.itemDescription = dto.itemDescription;
        this.units = dto.units;
        this.unitPrice = dto.unitPrice;
        this.isDeleted = false;
        this.ordinal = dto.ordinal;
        this.canDeleteItem = dto.canDeleteItem;
        this.hasBeenApproved = dto.hasBeenApproved;
        this.materialId = dto.materialId;
        // Override the units and unit price snapshot for amending.
        this.unitsSnapshot = dto.lastApprovedQuantity;
        this.unitPriceSnapshot = dto.lastApprovedUnitPrice;
    }

    public toDto(): RequisitionRequestItemRequestDTO {
        return {
            id: this.id,
            rowVersion: this.rowVersion,
            requisitionRequestItemGroupId: this.requisitionRequestItemGroupId,
            itemDescription: this.itemDescription,
            units: this.units,
            unitPrice: this.unitPrice,
            canDeleteItem: this.canDeleteItem,
            hasBeenApproved: this.hasBeenApproved,
            materialId: this.materialId,
        };
    }

    // #endregion Actions

    // #region Helpers

    public static fromDtos(dtos: RequisitionRequestItemResponseDTO[]): RequisitionRequestItemModel[] {
        const types: RequisitionRequestItemModel[] = [];

        for (const dto of dtos) {
            const model = new RequisitionRequestItemModel();

            model.fromDto(dto);
            types.push(model);
        }

        return types;
    }

    public static fromOrderLineModel(requisitionRequestItemGroupId: string | null, orderLineModel: OrderLineModel) {
        const model = new RequisitionRequestItemModel();

        model.requisitionRequestItemGroupId = requisitionRequestItemGroupId;
        model.itemDescription = orderLineModel.itemDescription;
        model.units = orderLineModel.units;
        model.unitPrice = orderLineModel.unitPrice;
        model.materialId = orderLineModel.materialId;

        return model;
    }

    // #endregion Helpers

    // #region Business Logic

    @computed
    public get total(): number {
        return getTotalPrice(isNaN(this.units) ? 0 : this.units, isNaN(this.unitPrice) ? 0 : this.unitPrice);
    }

    @computed
    public get totalSnapshot(): number {
        return getTotalPrice(this.unitsSnapshot, this.unitPriceSnapshot);
    }

    @computed
    public get totalChange(): number {
        return this.isDeleted ? 0 - this.total : this.total - this.totalSnapshot;
    }

    // #endregion Business Logic

    @computed
    public get validateItemDescription(): string {
        // RULES
        // The description must not be null or whitespace.
        return isEmptyOrWhitespace(this.itemDescription) ? "Please provide a description" : "";
    }

    @computed
    public get validateUnits(): string {
        // RULES
        // The number of units must be greater than zero.
        return isNaN(this.units) ? "Please set the number of units" : "";
    }

    @computed
    public get validateUnitPrice(): string {
        // RULES
        if (this.unitPrice === RequisitionRequestItemModel.DEFAULT_UNITPRICE || isNaN(this.unitPrice)) {
            return "Please set the unit price";
        } else if (!validateFourDecimalPlaces(this.unitPrice)) {
            return "No more than four decimal places";
        }

        return "";
    }
}

export interface RequisitionRequestItemRequestDTO {
    id: string | null;
    rowVersion: string | null;
    requisitionRequestItemGroupId: string | null;
    itemDescription: string;
    units: number;
    unitPrice: number;
    canDeleteItem: boolean;
    hasBeenApproved: boolean;
    materialId: string | null;
}

export interface RequisitionRequestItemResponseDTO {
    id: string;
    rowVersion: string;
    requisitionRequestItemGroupId: string;
    itemDescription: string;
    units: number;
    unitPrice: number;
    ordinal: number;
    canDeleteItem: boolean;
    hasBeenApproved: boolean;
    lastApprovedQuantity: number;
    lastApprovedUnitPrice: number;
    materialId: string | null;
}
