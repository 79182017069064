interface ITickIconProps {
    colour: string;
}

export const TickIcon = (props: ITickIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={18.123} height={14} {...props}>
            <path
                d="M6.758 13L1 7.242l1.624-1.624 4.134 4.123L15.5 1l1.624 1.635z"
                fill={props.colour}
                stroke={props.colour}
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
            />
        </svg>
    );
};
