import { observable } from "mobx";

export class UserModel {
    public id: string = "";
    @observable
    public fullName: string = "";
    @observable
    public priorityEmployerName: string = "";
    @observable
    public photographUrl: string = "";
    @observable
    public signedIn: boolean = false;
    @observable
    public visitorTypeName: string = "";
    public ramsAnswered: boolean = false;
    public projectId: string = "";
}
