import clsx from "clsx";
import { observer } from "mobx-react-lite";

import { ApprovalHistoryItemViewModel } from "./ApprovalHistoryItemViewModel";
import { ApprovalHistoryViewModel } from "./ApprovalHistoryViewModel";
import { ApprovalHistoryContainer } from "./ApprovalHistoryView.styles";
import { DashedDivider } from "Views/PurchaseOrder/Form/Views/Dividers";
import { IconButton } from "@material-ui/core";
import SystemUpdateTwoToneIcon from "@material-ui/icons/SystemUpdateTwoTone";
import { ApprovalDocumentDTO } from "Components/Approval/ApprovalDocumentModel";
import { formatDate, formatTime } from "Utils/Format";

interface IProps {
    viewModel: ApprovalHistoryViewModel;
}

export const ApprovalHistoryView: React.FC<IProps> = observer((props: IProps) => {
    const itemClassNames = (vm: ApprovalHistoryItemViewModel, index: number) => {
        return clsx({
            ["item-odd"]: index % 2 !== 0,
            [`item-type-${vm.type}`]: true,
        });
    };

    const renderRequirementItems = (vm: ApprovalHistoryItemViewModel, index: number) => {
        return (
            <>
                {vm.approvalRequirementItems.map((item, itemIndex) => {
                    const roleLevelDisplayName: string = item.roleLevelDisplayName ? item.roleLevelDisplayName : "";
                    return (
                        <li className={itemClassNames(vm, index)} key={item.actionedDate}>
                            <div className="item-header">
                                <div>{`${item.roleTypeName ? `${item.roleTypeName} ${roleLevelDisplayName}` : roleLevelDisplayName} (${item.actionedByUserName})`}</div>
                                <div>{`${formatDate(item.actionedDate!)} @ ${formatTime(item.actionedDate!)}`}</div>
                            </div>
                            <div className="item-body">
                                <div className="item-body-status">{item.approvalStatusName}</div>
                            </div>
                        </li>
                    );
                })}
            </>
        );
    };

    return (
        <ApprovalHistoryContainer>
            <h1>History</h1>
            <ol>
                {props.viewModel.approvalHistoryItems.map((vm, index) => {
                    return (
                        <>
                            {renderRequirementItems(vm, index)}
                            <li className={itemClassNames(vm, index)} key={vm.updatedDate}>
                                <div className="item-header">
                                    <div>{vm.updateBy}</div>
                                    <div>{vm.formattedDateTime}</div>
                                </div>
                                <div className="item-body">
                                    <div className="item-body-status">{vm.formattedType}</div>
                                    {vm.canDisplayNotes && <div className="item-body-notes">{vm.notes}</div>}
                                </div>
                                {vm.approvalDocuments.length > 0 && (
                                    <div className="uploadedFile">
                                        {vm.approvalDocuments.map((item: ApprovalDocumentDTO, index: number) => {
                                            return (
                                                !item.isDeleted && (
                                                    <div className="uploadedFile-box">
                                                        <div className="uploadedFile-heading">
                                                            <IconButton onClick={() => vm.DownloadFile(item.fileUrl, item.fileName)}>
                                                                <SystemUpdateTwoToneIcon />
                                                            </IconButton>
                                                            <h3>{item.fileName}</h3>
                                                        </div>
                                                    </div>
                                                )
                                            );
                                        })}
                                    </div>
                                )}
                            </li>
                        </>
                    );
                })}
            </ol>
            <DashedDivider gutterTop />
        </ApprovalHistoryContainer>
    );
});
