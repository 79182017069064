import { action, observable } from "mobx";
import { ModelBase } from "@shoothill/core";
import { IsNotEmpty } from "class-validator";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class RAMSModel extends ModelBase<RAMSModel, RAMSModelDTO> {
    public static readonly DEFAULT_ID = "";

    public static readonly DEFAULT_PROJECT_ID = "";
    public static readonly DEFAULT_FILE_NAME = "";
    public static readonly DEFAULT_FILE_URL = "";
    public static readonly DEFAULT_TITLE = "";
    public static readonly DEFAULT_SUB_CONTRACTOR = "";
    public static readonly DEFAULT_SUPERVISOR_USER_NAME = "";
    public static readonly DEFAULT_ESTIMATED_START_DATE = "";
    public static readonly DEFAULT_REVIEWED_BY_USER_ID = "";
    public static readonly DEFAULT_AUTHORISATION_SIGNATURE_URL = "";
    public static readonly DEFAULT_AUTHORISATION_SIGNATURE_DATE = "";
    public static readonly DEFAULT_CREATED_BY_USER_ID = "";
    public static readonly DEFAULT_LAST_UPDATED_DATE = "";
    public static readonly DEFAULT_LAST_UPDATED_BY_USER_ID = "";
    public static readonly DEFAULT_COMPLETED_DATE = "";
    public static readonly DEFAULT_COMPLETED_BY_USER_ID = "";
    public static readonly DEFAULT_IS_DELETED = false;
    public static readonly DEFAULT_ROW_VERSION = "";

    @observable
    public id: string | null = RAMSModel.DEFAULT_ID;
    @observable
    public projectId: string = RAMSModel.DEFAULT_PROJECT_ID;
    @observable
    public fileName: string = RAMSModel.DEFAULT_FILE_NAME;
    @observable
    public fileURL: string = RAMSModel.DEFAULT_FILE_URL;
    @observable
    @IsNotEmpty({ message: "Please enter an RA/MS title & revision" })
    public title: string = RAMSModel.DEFAULT_TITLE;
    @observable
    @IsNotEmpty({ message: "Please enter a sub-contractor" })
    public subContractor: string = RAMSModel.DEFAULT_SUB_CONTRACTOR;
    @observable
    @IsNotEmpty({ message: "Please choose a sub-contractor supervisor" })
    public supervisorUserName: string | null = RAMSModel.DEFAULT_SUPERVISOR_USER_NAME;
    @observable
    @IsNotEmpty({ message: "Please select an estimated start date" })
    public estimatedStartDate: string = RAMSModel.DEFAULT_ESTIMATED_START_DATE;
    @observable
    @IsNotEmpty({ message: "Please select a reviewer" })
    public reviewedByUserId: string = RAMSModel.DEFAULT_REVIEWED_BY_USER_ID;
    @observable
    @IsNotEmpty({ message: "Please sign" })
    public authorisationSignatureURL: string = RAMSModel.DEFAULT_AUTHORISATION_SIGNATURE_URL;
    @observable
    public authorisationSignatureDate: string | null = RAMSModel.DEFAULT_AUTHORISATION_SIGNATURE_DATE;
    @observable
    public createdByUserId: string | null = RAMSModel.DEFAULT_CREATED_BY_USER_ID;
    @observable
    public lastUpdatedDate: string | null = RAMSModel.DEFAULT_LAST_UPDATED_DATE;
    @observable
    public lastUpdatedByUserId: string | null = RAMSModel.DEFAULT_LAST_UPDATED_BY_USER_ID;
    @observable
    public completedDate: string | null = RAMSModel.DEFAULT_COMPLETED_DATE;
    @observable
    public completedByUserId: string | null = RAMSModel.DEFAULT_COMPLETED_BY_USER_ID;
    @observable
    public isDeleted: boolean = RAMSModel.DEFAULT_IS_DELETED;
    @observable
    public rowVersion: string | null = RAMSModel.DEFAULT_ROW_VERSION;

    @action
    public reset = () => {
        this.id = RAMSModel.DEFAULT_ID;
        this.projectId = RAMSModel.DEFAULT_PROJECT_ID;
        this.fileName = RAMSModel.DEFAULT_FILE_NAME;
        this.fileURL = RAMSModel.DEFAULT_FILE_URL;
        this.title = RAMSModel.DEFAULT_TITLE;
        this.subContractor = RAMSModel.DEFAULT_SUB_CONTRACTOR;
        this.supervisorUserName = RAMSModel.DEFAULT_SUPERVISOR_USER_NAME;
        this.estimatedStartDate = RAMSModel.DEFAULT_ESTIMATED_START_DATE;
        this.reviewedByUserId = RAMSModel.DEFAULT_REVIEWED_BY_USER_ID;
        this.authorisationSignatureURL = RAMSModel.DEFAULT_AUTHORISATION_SIGNATURE_URL;
        this.authorisationSignatureDate = RAMSModel.DEFAULT_AUTHORISATION_SIGNATURE_DATE;
        this.createdByUserId = RAMSModel.DEFAULT_CREATED_BY_USER_ID;
        this.lastUpdatedDate = RAMSModel.DEFAULT_LAST_UPDATED_DATE;
        this.lastUpdatedByUserId = RAMSModel.DEFAULT_LAST_UPDATED_BY_USER_ID;
        this.completedDate = RAMSModel.DEFAULT_COMPLETED_DATE;
        this.completedByUserId = RAMSModel.DEFAULT_COMPLETED_BY_USER_ID;
        this.isDeleted = RAMSModel.DEFAULT_IS_DELETED;
        this.rowVersion = RAMSModel.DEFAULT_ROW_VERSION;
    };

    // fromDto(model: any): void {}

    public toDto() {
        const RAMSModel: RAMSModelDTO = {
            id: this.id,
            projectId: this.projectId,
            fileName: this.fileName,
            fileURL: this.fileURL,
            title: this.title,
            subContractor: this.subContractor,
            supervisorUserName: this.supervisorUserName,
            estimatedStartDate: this.estimatedStartDate,
            reviewedByUserId: this.reviewedByUserId,
            authorisationSignatureURL: this.authorisationSignatureURL,
            authorisationSignatureDate: this.authorisationSignatureDate,
            createdByUserId: this.createdByUserId,
            lastUpdatedDate: this.lastUpdatedDate,
            lastUpdatedByUserId: this.lastUpdatedByUserId,
            completedDate: this.completedDate,
            completedByUserId: this.completedByUserId,
            isDeleted: this.isDeleted,
            rowVersion: this.rowVersion,
        };

        return RAMSModel;
    }

    //fromDto is required but you can leave it blank
    fromDto(model: RAMSModelDTO): void {
        this.id = model.id;
        this.projectId = model.projectId;
        this.fileName = model.fileName;
        this.fileURL = model.fileURL;
        this.title = model.title;
        this.subContractor = model.subContractor;
        this.supervisorUserName = model.supervisorUserName;
        this.estimatedStartDate = model.estimatedStartDate;
        this.reviewedByUserId = model.reviewedByUserId;
        this.authorisationSignatureURL = model.authorisationSignatureURL;
        this.authorisationSignatureDate = model.authorisationSignatureDate;
        this.createdByUserId = model.createdByUserId;
        this.lastUpdatedDate = model.lastUpdatedDate;
        this.lastUpdatedByUserId = model.lastUpdatedByUserId;
        this.completedDate = model.completedDate;
        this.completedByUserId = model.completedByUserId;
        this.isDeleted = model.isDeleted;
        this.rowVersion = model.rowVersion;
    }
}

export type RAMSModelDTO = {
    id: string | null;
    projectId: string;
    fileName: string;
    fileURL: string;
    title: string;
    subContractor: string;
    supervisorUserName: string | null;
    estimatedStartDate: string;
    reviewedByUserId: string;
    authorisationSignatureURL: string;
    authorisationSignatureDate: string | null;
    createdByUserId: string | null;
    lastUpdatedDate: string | null;
    lastUpdatedByUserId: string | null;
    completedDate: string | null;
    completedByUserId: string | null;
    isDeleted: boolean;
    rowVersion: string | null;
};
