import styled from "styled-components";

export const DrawingTable = styled.div`
    padding: 0 0 0.75rem 0;
`;

export const DrawingRow = styled.div`
    background-color: #eaf4f9;
    margin: 0.5rem 0;
    padding: 0.25rem 0.625rem;
`;
