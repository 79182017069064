import { TabItem } from "./TabItem";
import { TabsContainer } from "./TabsV2.styles";
import { TabsViewModel } from "./TabsViewModel";

interface IProps {
    viewModel: TabsViewModel;
}

export const Tabsv2: React.FC<IProps> = (props) => {
    return (
        <TabsContainer>
            <ol>
                {props.viewModel.tabItemViewModels.map((vm) => {
                    return <TabItem key={vm.id} viewModel={vm} setActiveViewModel={props.viewModel.setActiveTabItemViewModel} />;
                })}
            </ol>
        </TabsContainer>
    );
};
