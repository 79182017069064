import { Box, Link, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { StoresInstance } from "Globals/Stores";
import { EditableCopyViewModel } from "Globals/ViewModels/EditableCopyViewModel";
import { EditableInput, generateID } from "@shoothill/core";
import { LoginViewModel } from "./LoginViewModel";
import { useRouter } from "@shoothill/core";
import { Grid } from "@shoothill/core";
import { Link as RouterLink } from "react-router-dom";
import { useObserver } from "mobx-react-lite";
import { AccountPage, AccountImageBox, BoxAccount, BoxPage } from "Globals/Styles/AccountStyling";
import { AccountTitleBox, AccountForm } from "./LoginView.style";
import { LoginButton } from "Globals/Styles/Control/Buttons";

import { ReactComponent as Logo } from "../../Content/dg_logo_full_cmyk_primary.svg";

const domainStores = StoresInstance.Domain;

export interface IWindowState {
    width: number;
    height: number;
}

export const LoginView: React.FunctionComponent = () => {
    const componentRef: any = useRef(generateID());
    const [dimensions, setDimensions] = React.useState<IWindowState>({
        width: 0,
        height: 0,
    });

    const [viewModel] = useState(() => new LoginViewModel());
    const [viewEditableCopyModel] = useState(() => new EditableCopyViewModel());
    const { history, location, match } = useRouter();
    const [errorMessage, setErrorMessage] = useState("");
    ///
    /// updateWindowDimensions
    ///
    const updateWindowDimensions = () => {
        if (componentRef !== undefined && componentRef != null && componentRef.current !== null && componentRef.current !== undefined) {
            setDimensions({
                width: componentRef!.current!.offsetWidth,
                height: componentRef.current!.offsetHeight!,
            });
        }
    };

    useEffect(() => {
        if (StoresInstance.Domain.AccountStore.IsLoggedIn) {
            history.push(StoresInstance.Domain.AccountStore.getDefaultRoute());
        }
        updateWindowDimensions();
        window.addEventListener("resize", updateWindowDimensions);

        // tidy up after yourself
        return () => {
            window.removeEventListener("resize", updateWindowDimensions);
        };
    }, []);

    const doSubmit = async (e: any) => {
        e.preventDefault();
        if (await viewModel.isModelValid()) {
            const apiResult = await viewModel.login();
            if (apiResult !== null) {
                if (apiResult.wasSuccessful) {
                    await domainStores.AccountStore.getLoginState(apiResult.payload);
                    const { from } = (location.state as any) || { from: { pathname: StoresInstance.Domain.AccountStore.getDefaultRoute() } };
                    if (from) {
                        if (from.pathname === "/") {
                            history.push(StoresInstance.Domain.AccountStore.getDefaultRoute());
                        } else {
                            history.push(from.pathname);
                        }
                    }
                } else {
                    if (apiResult && apiResult.errors && apiResult.errors.length > 0) {
                        setErrorMessage("* " + apiResult.errors[0].message);
                    } else {
                        setErrorMessage("* Unknown error has occurred.");
                    }
                }
            }
        }
    };

    const inPortrait: boolean = dimensions.height > dimensions.width;
    const triangleHeight: string = (inPortrait ? (dimensions.width * 0.765).toString() : (dimensions.height * 0.765).toString()) + "px";

    return useObserver(() => (
        <>
            <AccountPage triangleheight={triangleHeight} ref={componentRef}>
                <div className="triangle" />
                <BoxPage>
                    <BoxAccount>
                        <AccountImageBox>
                            <Logo />
                        </AccountImageBox>
                        <AccountTitleBox>{/* <span>Project Management System</span> */}</AccountTitleBox>
                        <AccountForm onSubmit={doSubmit}>
                            {errorMessage !== "" && (
                                <Typography variant="caption" style={{ color: "red" }}>
                                    {errorMessage}
                                </Typography>
                            )}
                            <Grid desktopColumns={"275px"}>
                                <EditableInput type="email" label="Login:" shrink={true} validateOnBlur={true} viewModel={viewModel} fieldName="emailAddress" />
                                <Box>
                                    <EditableInput type="password" label="Password:" viewModel={viewModel} fieldName="password" shrink={true} />
                                    <Link component={RouterLink} to="forgotpassword">
                                        Forgotten password?
                                    </Link>
                                </Box>
                            </Grid>
                            <Box margin="50px 0 0 0" textAlign="left">
                                <LoginButton type="submit" size="large">
                                    {viewModel.IsLoading ? <span>Please wait...</span> : <span>Login</span>}
                                </LoginButton>
                            </Box>
                        </AccountForm>
                    </BoxAccount>
                </BoxPage>
            </AccountPage>
        </>
    ));
};
