// Libraries
import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";

// Custom
import { RequisitionsListViewModel } from "../OrdersAndRequisitions/RequisitionsList.ViewModel";
import * as Defaults from "Globals/Defaults/TableOptions";
import { isNullOrUndefined } from "Utils/Utils";

// Styling & Images
import { Grid, useObserver, useRouter } from "@shoothill/core";
import { TableListSearch } from "Components/Table/TableListSearch";
import { DarwinTableToolbar, DarwinTableSearchBox, DarwinTablePageContent } from "Globals/Styles/AppStyling";
import { AppUrls } from "AppUrls";
import { RequisitionsTableModel } from "../OrdersAndRequisitions/Requisitions.Model";
import { formatCurrencyFromPounds, formatDate, formatEmptyValueForTableDash } from "Utils/Format";
import { PatchedPagination } from "../../../../Components/Table/PatchedPagination";

interface ITableProps {
    ieId: string;
}

export const PRTab: React.FunctionComponent<ITableProps> = ({ ieId }) => {
    const tableOptions = Defaults.GetDarwinTableOptions() as any;
    const [viewModel] = useState(() => RequisitionsListViewModel.Instance);
    const { history } = useRouter();

    useEffect(() => {
        if (isNullOrUndefined(ieId) === false) {
            viewModel.apiGetRequisitions(ieId);
        } else {
            viewModel.GetAllRequisitions();
        }
    }, []);

    const setSearchString = (value: string) => {
        viewModel.setSearchString(value);
    };

    const handleRowClick = (e: any, rowData: RequisitionsTableModel | undefined) => {
        if (rowData !== undefined) {
            history.push(AppUrls.Client.PurchaseOrder.View.replace(":poid", rowData.id));
        }
    };

    return useObserver(() => (
        <div style={{ width: "100%", paddingTop: 30 }}>
            <DarwinTableToolbar>
                <Grid columnGap={30} dc={"1fr 1fr"} rowGap={15} tc={"1fr 1fr"}>
                    <DarwinTableSearchBox>
                        <TableListSearch searchString={viewModel.getSearchString()} setSearchString={setSearchString} />
                    </DarwinTableSearchBox>
                </Grid>
            </DarwinTableToolbar>

            <DarwinTablePageContent>
                <MaterialTable
                    isLoading={viewModel.IsLoading}
                    columns={[
                        {
                            title: "Req no.",
                            field: "poNumberFormatted",
                            width: "auto",
                            render: (rowData: RequisitionsTableModel) => <>{formatEmptyValueForTableDash(rowData.poNumberFormatted)}</>,
                        },
                        {
                            title: "Purchase order description",
                            field: "itemDescription",
                            width: "auto",
                            render: (rowData: RequisitionsTableModel) => <>{formatEmptyValueForTableDash(rowData.itemDescription)}</>,
                        },
                        {
                            title: "Raised by",
                            field: "raisedBy",
                            width: "auto",
                            render: (rowData: RequisitionsTableModel) => <>{formatEmptyValueForTableDash(rowData.raisedBy)}</>,
                        },
                        {
                            title: "Date",
                            field: "createdDate",
                            width: "auto",
                            type: "date",
                            render: (rowData: RequisitionsTableModel) => (
                                <>{formatEmptyValueForTableDash(formatDate(rowData.createdDate !== null ? rowData.createdDate.toString() : ""))}</>
                            ),
                        },
                        {
                            title: "Supplier",
                            field: "supplierName",
                            width: "auto",
                            render: (rowData: RequisitionsTableModel) => <>{formatEmptyValueForTableDash(rowData.supplierName)}</>,
                        },
                        {
                            title: "Committed cost",
                            field: "committedCost",
                            width: "auto",
                            render: (rowData: RequisitionsTableModel) => <div style={{ textAlign: "right" }}>{formatCurrencyFromPounds(rowData.committedCost)}</div>,
                        },
                        {
                            title: "Status",
                            field: "status",
                            width: "auto",
                            render: (rowData: RequisitionsTableModel) => <>{formatEmptyValueForTableDash(rowData.status)}</>,
                        },
                    ]}
                    components={{
                        Pagination: PatchedPagination,
                    }}
                    data={viewModel.returnRequisitions}
                    onRowClick={handleRowClick}
                    options={{ ...tableOptions, filtering: false, search: false, toolbar: false }}
                    title=""
                />
            </DarwinTablePageContent>
        </div>
    ));
};
