import { FieldType, isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import type { ValidationResponse } from "@shoothill/core";
import { action, computed, observable, runInAction } from "mobx";

import { AppUrls } from "AppUrls";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { DetailsHeaderModel } from "Globals/Views/DetailsPage/DetailsHeaderModel";
import { VariationRiskOppDTO, VariationRiskOppAndRelatedResponseDTO, VariationRiskOppModel } from "./VariationRiskOppModel";
import { StoresInstance } from "Globals/Stores";
import { RoleLevelEnum, RoleTypeEnum } from "Globals/Stores/Domain/Admin";

export class VariationRiskOppViewModel extends ViewModelBase<VariationRiskOppModel> {
    // #region Constructors and Disposers

    constructor(ieId: string, variationId: string, variationItemId: string) {
        super(new VariationRiskOppModel());
        this.setDecorators(VariationRiskOppModel);

        this.model.ieId = ieId;
        this.model.variationId = variationId;
        this.model.variationItemId = variationItemId;

        this.loadRiskOpp(variationId, variationItemId);
    }

    // #endregion Constructors and Disposers

    @observable
    private ieTitle: string = "";

    @action setIETitle = (val: string) => {
        this.ieTitle = val;
    };

    @computed
    public get getIETitle(): string {
        return this.ieTitle;
    }

    /**
     * Header
     */
    @computed
    get getHeader(): DetailsHeaderModel {
        const retVal: DetailsHeaderModel = new DetailsHeaderModel();

        if (this.categoryName === "" || this.subCategoryName === "" || this.lineDescriptionName === "") {
            retVal.setValue("title", "");
        } else {
            retVal.setValue("title", `${this.categoryName} > ${this.subCategoryName} > ${this.lineDescriptionName}`);
        }

        retVal.setValue("subTitle", "Risk/opportunity");

        return retVal;
    }

    @computed
    public get canAddRiskOppAmendment(): boolean {
        if (
            StoresInstance.Domain.AccountStore.isInRole(RoleTypeEnum.Admin) ||
            StoresInstance.Domain.AccountStore.isInRole(RoleTypeEnum.Level1Commercial) ||
            StoresInstance.Domain.AccountStore.isInRole(RoleTypeEnum.Managers) ||
            StoresInstance.Domain.AccountStore.isInRole(RoleTypeEnum.CellHead) ||
            StoresInstance.Domain.AccountStore.isInRole(RoleTypeEnum.Commdir) ||
            StoresInstance.Domain.AccountStore.isInRole(RoleTypeEnum.CEOCOO) ||
            StoresInstance.Domain.AccountStore.isInRole(RoleTypeEnum.ExecTeam) ||
            StoresInstance.Domain.AccountStore.isInRole(RoleTypeEnum.Finance) ||
            StoresInstance.Domain.AccountStore.isInRole(RoleTypeEnum.Variation)
        ) {
            return true;
        }

        return false;
    }

    @observable
    public variationRiskOppAmendmentItems = observable<VariationRiskOppDTO>([]); // Should probably update to viewmodel.

    // #region Properties

    public server: ServerViewModel = new ServerViewModel();

    // #endregion Properties

    // #region Actions

    @action
    public setNote = (val: string) => {
        this.model.note = val;
    };

    @observable private categoryName: string = "";
    @observable private subCategoryName: string = "";
    @observable private lineDescriptionName: string = "";

    @action
    public loadRiskOpp = (variationId: string, variationItemId: string): Promise<void> => {
        return this.server.query<VariationRiskOppAndRelatedResponseDTO>(
            () => this.Get(`${AppUrls.Server.Variation.GetVariationRiskOppAmendmentAndRelated}\\${variationId}\\${variationItemId}`),
            (result) => {
                runInAction(() => {
                    this.variationRiskOppAmendmentItems.replace(result.variationRiskOppAmendmentItems);

                    this.categoryName = result.categoryName;
                    this.subCategoryName = result.subCategoryName;
                    this.lineDescriptionName = result.lineDescriptionName;

                    this.setIETitle(result.ieTitle);
                });
            },
        );
    };

    public handleSave = async (): Promise<void> => {
        if (this.canAddRiskOppAmendment) {
            await this.save();
        }
    };

    private save = (): Promise<void> => {
        return this.server.command<VariationRiskOppAndRelatedResponseDTO>(
            () => this.Post(AppUrls.Server.Variation.UpsertRiskOppAmendment, this.model.toDto()),
            (result) => {
                runInAction(() => {
                    this.variationRiskOppAmendmentItems.replace(result.variationRiskOppAmendmentItems);

                    this.categoryName = result.categoryName;
                    this.subCategoryName = result.subCategoryName;
                    this.lineDescriptionName = result.lineDescriptionName;

                    this.model.reset();
                });
            },
            this.isModelValid,
            "There was an error trying to add the variation risk and opportunity",
        );
    };

    // #endregion Actions

    @computed
    private get validateRiskValue(): ValidationResponse {
        const errorMessage = this.model.validateRiskValue;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateRiskNote(): ValidationResponse {
        const errorMessage = this.model.validateRiskNote;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<VariationRiskOppModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        if (this.server.IsSubmitted) {
            // Process the properties of the model that cannot be supported via
            // the use of decorators.
            switch (fieldName) {
                case "riskValue": {
                    const result = this.validateRiskValue;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }

                case "note": {
                    const result = this.validateRiskNote;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
            }
        } else {
            // Do not validate if the properties of the model have not been
            // submitted to the server.
            errorMessage = "";
            isValid = true;
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Bolierplate
}
