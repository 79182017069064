import { ProjectSystemType } from "./../../../Globals/Models/Domain/ProjectSystemTypeEnum";
import { DarwinModelBaseDTO } from "Globals/Models/Domain";
import { action, observable } from "mobx";
import { ModelBase } from "@shoothill/core";
import { IsPhoneNumber, ValidateIf, IsPostalCode, IsArray } from "class-validator";
import { AddressModelDTO, AddressModel, NoteModelDTO, NoteModel } from "Globals/Models/Domain";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class ProjectConstructionModel extends ModelBase<ProjectConstructionModel, ProjectConstructionModelDTO> {
    // DarwinModelBase
    public id: string = "";
    public createdDate: string = "";

    @observable
    public systemTypeId: ProjectSystemType = ProjectSystemType.Standard; // St

    @observable
    public gifa: number = 0;

    @observable
    public siteManagerName: string = "";

    @observable
    public smContactEmail: string = "";

    @observable
    @ValidateIf((o) => o.length > 0)
    @IsPhoneNumber("GB", { message: "Phone number is not valid" })
    public smContactPhone: string = "";

    @observable
    public siteAddress: string = "";

    @observable
    @ValidateIf((o) => o.length > 0)
    @IsPostalCode("GB", { message: "Postcode is not valid" })
    public sitePostcode: string = "";

    @observable
    public noModules: number = 0;

    @observable
    public projectId: string = "";

    private rowVersion: string | null = null;

    private originatorId: string = "";

    @observable
    @IsArray()
    public addresses: AddressModel[] = [];

    //fromDto is required but you can leave it blank
    fromDto(model: ProjectConstructionModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: ProjectConstructionModel): void {}

    @action public clearModel() {
        this.id = "";
        this.createdDate = "";
        this.rowVersion = null;
        this.originatorId = "";
        this.systemTypeId = ProjectSystemType.Standard;
        this.gifa = 0;
        this.siteManagerName = "";
        this.smContactEmail = "";
        this.smContactPhone = "";
        this.siteAddress = "";
        this.sitePostcode = "";
        this.noModules = 0;
        this.projectId = "";
        this.addresses = [];
    }
}

export interface ProjectConstructionModelDTO extends DarwinModelBaseDTO {
    rowVersion: string | null;
    originatorId: string;
    systemTypeId: ProjectSystemType;
    gifa: number;
    siteManagerName: string;
    smContactEmail: string;
    smContactPhone: string;
    siteAddress: string;
    sitePostcode: string;
    noModules: number;
    projectId: string;
    addresses: AddressModelDTO[];
}
