import { FieldType, ViewModelBase, isEmptyOrWhitespace, observable } from "@shoothill/core";
import { runInAction, action, computed } from "mobx";
import moment from "moment";

import { AppUrls } from "AppUrls";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import {
    CardIssuedByUserDTO,
    DisciplineAndRelatedResponseDTO,
    DisciplineCardTypeDTO,
    DisciplineCardTypeEnum,
    DisciplineDTO,
    DisciplineFormModel,
    DisciplineProjectDetailsDTO,
    DisciplineRelatedResponseDTO,
    SeniorTeamUserDTO,
    UpsertDisciplineAndRelatedRequestDTO,
} from "./DisciplineFormModel";
import { NonConformanceListViewModel } from "../NonConformanceListViewModel";

export class DisciplineFormViewModel extends ViewModelBase<DisciplineFormModel> {
    public server: ServerViewModel = new ServerViewModel();

    constructor(id: string | null, projectId: string | null, nonConformanceTypeId: string | null) {
        super(new DisciplineFormModel());
        this.setDecorators(DisciplineFormModel);

        this.model.id = id;
        this.model.projectId = projectId!;
        this.model.nonConformanceTypeId = nonConformanceTypeId!;

        isEmptyOrWhitespace(this.model.id) ? this.loadRelated() : this.loadWithRelated();
    }

    // #region Shared

    @observable
    public disciplineProjectDetails: DisciplineProjectDetailsDTO | null = null;

    @computed
    public get projectDisplayName() {
        return `(${!isEmptyOrWhitespace(this.disciplineProjectDetails?.projectReference) ? this.disciplineProjectDetails?.projectReference : "--"} - 
                 ${!isEmptyOrWhitespace(this.disciplineProjectDetails?.projectName) ? this.disciplineProjectDetails?.projectName : "--"})`;
    }

    public nonConformanceTypeDisplayName = (list: NonConformanceListViewModel): string => {
        const id = isEmptyOrWhitespace(this.model.id) ? this.model.nonConformanceTypeId : this.discipline?.nonConformanceTypeId;

        return list.nonConformanceTypes.find((i: any) => i.id === id)?.displayName ?? "";
    };

    // #endregion Shared

    // #region Properties

    @action
    public setCardReceivedByUser = (value: any | null): void => {
        this.setValue("cardReceivedByUserId", value?.id ?? DisciplineFormModel.DEFAULT_CARD_ISSUED_BY_USER_ID);
    };

    @computed
    public get cardReceivedByUser(): any | null {
        return this.model.cardReceivedByUsers.find((u) => u.id === this.model.cardReceivedByUserId) ?? null;
    }

    @computed
    public get cardReceivedByUsers() {
        return this.model.cardReceivedByUsers;
    }

    // #endregion Properties

    // #region ReadOnly Properties

    @observable
    public discipline: any = null;

    @computed
    public get readOnlyReceivedByUserDisplayName(): string {
        return (
            this.cardReceivedByUsers.find((i: any) => i.id === this.discipline?.cardReceivedByInductionUserId || i.id === this.discipline?.cardReceivedByWorkerUserId)
                ?.displayName ?? ""
        );
    }

    @computed
    public get readOnlyCompanyDisplayName(): string {
        return this.discipline?.companyName ?? "";
    }

    // #endregion ReadOnly Properties

    @observable
    public disciplineCardTypes = observable<DisciplineCardTypeDTO>([]);

    @observable
    public cardIssuedByUsers = observable<CardIssuedByUserDTO>([]);

    @observable
    public seniorTeamUsers = observable<SeniorTeamUserDTO>([]);

    @observable
    public disciplineCardType: { id: string; displayName: string } | null = null;

    @observable
    public cardIssuedByUser: { id: string; displayName: string } | null = null;

    @observable
    public seniorTeamUser: { id: string; displayName: string } | null = null;

    @action
    public handleDisciplineCardTypeId = (item: { id: string; displayName: string }) => {
        this.disciplineCardType = item;
        this.setValue("disciplineCardTypeId", item.id);
    };

    @action
    public handleCardIssuedByUserId = (item: { id: string; displayName: string }) => {
        this.cardIssuedByUser = item;
        this.setValue("cardIssuedByUserId", item.id);
    };

    @action
    public handleSeniorTeamTypeId = (item: { id: string; displayName: string }) => {
        this.seniorTeamUser = item;
        this.setValue("seniorTeamTypeId", item.id);
    };

    @action
    public nearMissCompleted = (fieldName: any, value: string) => {
        const val = value === "true" ? true : false;
        this.setValue(fieldName as any, val);
    };

    @computed
    public get authorisationsText() {
        const type = this.disciplineCardTypes.find((t) => t.id === this.discipline?.disciplineCardTypeId);
        if (type && type.type === DisciplineCardTypeEnum.YellowCard) {
            return '"Any further repetition of this non-compliance of site rules, or breach of any health and safety requirement may result in expulsion from this site and all other Darwin Group construction sites for a period of time to be determined by Darwin Group management. Further offences beyond that may result in permanent expulsion."';
        }

        return '"This expulsion will remain in place on all Darwin Group construction sites for a period of 30 days. Any repeat offences on any sites thereafter may result in permanent expulsion from all sites. A copy of this notice will be sent to the management of the Company employing the offender, Darwin Group Management, Human resources Dept. and Health and Safety Dept. This notice will remain on site record until practical completion."';
    }

    @action
    public reset = () => {
        this.model.reset();
        this.server.reset();
        this.disciplineProjectDetails = null;
    };

    @action
    public handleCancel(projectId: string | null): void {
        this.reset();
        this.history.push(AppUrls.Client.Project.ConstructionQuality.replace(":projectid", projectId ? projectId : this.model.projectId) + "#nonconformance");
    }

    @action
    public displayCardIssuedByUserId(id: string): string {
        const cardIssuedByUsers = this.cardIssuedByUsers?.find((i: any) => i.id === id);
        const title = cardIssuedByUsers?.displayName;
        return title ? title : "";
    }

    @action
    public displayDisciplineCardTypeId(id: string): string {
        const disciplineCardTypes = this.disciplineCardTypes.find((i: any) => i.id === id);
        const title = disciplineCardTypes?.displayName;
        return title ? title : "";
    }

    @action
    public displayCardSeniorTeamTypeId(id: string): string {
        const seniorTeamUsers = this.seniorTeamUsers.find((i: any) => i.id === id);
        const title = seniorTeamUsers?.displayName;
        return title ? title : "";
    }

    @action
    public displayCardReceivedByUserId(id: string): string {
        const cardReceivedByUsers = this.cardReceivedByUsers.find((i: any) => i.id === id);
        const title = cardReceivedByUsers?.displayName;
        return title ? title : "";
    }

    @computed
    public get isFormDisabled(): boolean {
        return this.model.id !== null && this.model.id !== undefined && this.model.id !== "";
    }

    @computed
    private get validateDisciplineCardTypeId() {
        const errorMessage = this.model.validateDisciplineCardTypeId;
        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateDateOfOccurance() {
        const errorMessage = this.model.validateDateOfOccurance;
        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateNotes() {
        const errorMessage = this.model.validateNotes;
        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateCardIssuedByUserId() {
        const errorMessage = this.model.validateCardIssuedByUserId;
        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateSeniorTeamTypeId() {
        const errorMessage = this.model.validateSeniorTeamTypeId;
        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateCardReceivedByUserId() {
        const errorMessage = this.model.validateCardReceivedByUserId;
        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateCompanyName() {
        const errorMessage = this.model.validateCompanyName;
        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateSignatureIssuedByURL() {
        const errorMessage = this.model.validateSignatureIssuedByURL;
        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateSignatureReceivedByURL() {
        const errorMessage = this.model.validateSignatureReceivedByURL;
        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    public get getTodayDateFormatted(): string {
        return this.model.createdDate ? moment(this.model.createdDate).format("DD/MM/YYYY").toString() : moment().format("DD/MM/YYYY").toString();
    }

    public loadRelated = async (): Promise<void> => {
        this.setIsLoading(true);
        return await this.server
            .query<DisciplineRelatedResponseDTO>(
                () => this.Get(`${AppUrls.Server.Projects.Construction.NonConformance.Discipline.Related}\\${this.model.projectId}\\${this.model.nonConformanceTypeId}`),
                (result) => {
                    runInAction(() => {
                        this.model.cardReceivedByInductionUsers.replace(result.cardReceivedByInductionUsers);
                        this.model.cardReceivedByWorkerUsers.replace(result.cardReceivedByWorkerUsers);

                        this.disciplineCardTypes.replace(result.disciplineCardTypes);
                        this.cardIssuedByUsers.replace(result.cardIssuedByUsers);
                        this.seniorTeamUsers.replace(result.seniorTeamUsers);
                        this.disciplineProjectDetails = result.disciplineProjectDetails;
                    });
                },
            )
            .finally(() => this.setIsLoading(false));
    };

    public loadWithRelated = async (): Promise<void> => {
        this.setIsLoading(true);
        return await this.server
            .query<any>(
                () => this.Get(`${AppUrls.Server.Projects.Construction.NonConformance.Discipline.WithRelatedById}\\${this.model.id}`),
                (result) => {
                    runInAction(() => {
                        this.model.cardReceivedByInductionUsers.replace(result.cardReceivedByInductionUsers);
                        this.model.cardReceivedByWorkerUsers.replace(result.cardReceivedByWorkerUsers);

                        this.discipline = result.discipline;
                        this.disciplineCardTypes = result.disciplineCardTypes;
                        this.cardIssuedByUsers = result.cardIssuedByUsers;
                        this.seniorTeamUsers = result.seniorTeamUsers;
                        this.disciplineProjectDetails = result.disciplineProjectDetails;
                    });
                },
            )
            .finally(() => this.setIsLoading(false));
    };

    public upsert = async (): Promise<void> => {
        // APM: Review Note.
        // Shouldn't need this. The server viewmodel has and automatically sets an IsBusy flag.
        this.setIsLoading(true);

        const model: DisciplineDTO = this.model.toDto();

        const request: UpsertDisciplineAndRelatedRequestDTO = {
            discipline: model,
        };

        // POST it to the server.
        return await this.server
            .command<DisciplineAndRelatedResponseDTO>(
                () => this.Post(AppUrls.Server.Projects.Construction.NonConformance.Discipline.Upsert, request),
                (result: DisciplineAndRelatedResponseDTO) => {
                    runInAction(() => {
                        if (result) {
                            this.handleCancel(result.discipline.projectId);
                        }
                    });
                },
                this.isMyModelValid,
                "There was an error trying to send the permit",
            )
            .finally(() => this.setIsLoading(false));
    };

    private isMyModelValid = async (): Promise<boolean> => {
        let isValid = true;
        if ((await this.isModelValid()) === false) {
            isValid = false;
        }

        return isValid;
    };

    // #region Bolierplate

    public async isFieldValid(fieldName: keyof FieldType<DisciplineFormModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        if (this.server.IsSubmitted) {
            switch (fieldName) {
                case "disciplineCardTypeId": {
                    const result = this.validateDisciplineCardTypeId;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "dateOfOccurance": {
                    const result = this.validateDateOfOccurance;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "notes": {
                    const result = this.validateNotes;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "cardIssuedByUserId": {
                    const result = this.validateCardIssuedByUserId;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "seniorTeamTypeId": {
                    const result = this.validateSeniorTeamTypeId;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "cardReceivedByUserId": {
                    const result = this.validateCardReceivedByUserId;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "companyName": {
                    const result = this.validateCompanyName;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "signatureIssuedByURL": {
                    const result = this.validateSignatureIssuedByURL;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "signatureReceivedByURL": {
                    const result = this.validateSignatureReceivedByURL;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
            }
        } else {
            errorMessage = "";
            isValid = true;
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Bolierplate
}
