import { ModelBase } from "@shoothill/core";
import { IsNotEmpty, NotEquals } from "class-validator";
import { action, observable } from "mobx";

import { IMaterialSubType, MaterialSubType } from "Globals/Models/Enums/MaterialSubType";
import { IMaterialType, MaterialType } from "Globals/Models/Enums/MaterialType";
import { IUnitOfMeasure, UnitOfMeasure } from "Globals/Models/Enums/UnitOfMeasure";

export class MaterialDetailsModel extends ModelBase<MaterialDetailsModel> {
    // #region Defaults

    public static readonly DEFAULT_MATERIALTYPES = [];
    public static readonly DEFAULT_MATERIALSUBTYPES = [];
    public static readonly DEFAULT_UNITSOFMEASURE = [];

    public static readonly DEFAULT_MATERIALTYPE = null;
    public static readonly DEFAULT_MATERIALSUBTYPE = null;
    public static readonly DEFAULT_UNITOFMEASURE = null;
    public static readonly DEFAULT_SUPPLIERCODE = "";
    public static readonly DEFAULT_DESCRIPTION = "";

    // #endregion Defaults

    // #region Related Properties

    @observable
    public materialTypes = observable<MaterialType>(MaterialDetailsModel.DEFAULT_MATERIALTYPES);

    @observable
    public materialSubTypes = observable<MaterialSubType>(MaterialDetailsModel.DEFAULT_MATERIALSUBTYPES);

    @observable
    public unitsOfMeasure = observable<UnitOfMeasure>(MaterialDetailsModel.DEFAULT_UNITSOFMEASURE);

    // #endregion Related Properties

    // #region Properties

    @observable
    @NotEquals(null, { message: "Please select an option" })
    public materialType: MaterialType | null = MaterialDetailsModel.DEFAULT_MATERIALTYPE;

    @observable
    @NotEquals(null, { message: "Please select an option" })
    public materialSubType: MaterialSubType | null = MaterialDetailsModel.DEFAULT_MATERIALSUBTYPE;

    @observable
    @NotEquals(null, { message: "Please select an option" })
    public unitOfMeasure: UnitOfMeasure | null = MaterialDetailsModel.DEFAULT_UNITOFMEASURE;

    @observable
    @IsNotEmpty({ message: "Please provide a code" })
    public supplierCode: string = MaterialDetailsModel.DEFAULT_SUPPLIERCODE;

    @observable
    @IsNotEmpty({ message: "Please provide a description" })
    public description: string = MaterialDetailsModel.DEFAULT_DESCRIPTION;

    // #endregion Properties

    // #region Methods

    @action
    public fromDto(dto: any): void {
        // Material types.
        this.materialTypes.replace(
            dto.materialTypes.map((mt: IMaterialType) => {
                const enumType = new MaterialType();

                enumType.fromDto(mt);

                return enumType;
            }),
        );

        // Material subtypes.
        this.materialSubTypes.replace(
            dto.materialSubTypes.map((mst: IMaterialSubType) => {
                const enumType = new MaterialSubType();

                enumType.fromDto(mst);

                return enumType;
            }),
        );

        // Units of measure.
        this.unitsOfMeasure.replace(
            dto.unitsOfMeasure.map((uom: IUnitOfMeasure) => {
                const enumType = new UnitOfMeasure();

                enumType.fromDto(uom);

                return enumType;
            }),
        );

        this.materialType = this.materialTypes.find((mt) => mt.id === dto.materialDetails.materialTypeId) ?? MaterialDetailsModel.DEFAULT_MATERIALTYPE;
        this.materialSubType = this.materialSubTypes.find((mst) => mst.id === dto.materialDetails.materialSubTypeId) ?? MaterialDetailsModel.DEFAULT_MATERIALSUBTYPE;
        this.unitOfMeasure = this.unitsOfMeasure.find((uom) => uom.id === dto.materialDetails.unitOfMeasureId) ?? MaterialDetailsModel.DEFAULT_UNITOFMEASURE;
        this.supplierCode = dto.materialDetails.supplierCode;
        this.description = dto.materialDetails.description;
    }

    public toDto(): any {
        return {
            materialTypeId: this.materialType?.id,
            materialSubTypeId: this.materialSubType?.id,
            unitOfMeasureId: this.unitOfMeasure?.id,
            description: this.description,
            supplierCode: this.supplierCode,
        };
    }

    // #region Methods
}
