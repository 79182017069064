import { ModelBase, isEmptyOrWhitespace } from "@shoothill/core";
import { IsNotEmpty, NotEquals } from "class-validator";
import { action, observable } from "mobx";

import { IOutputCategory, OutputCategory } from "Globals/Models/Enums/OutputCategory";
import { IOutputType, OutputType } from "../../Shared";

export class AddEditOutputModel extends ModelBase<AddEditOutputModel> {
    // #region Defaults

    public static readonly DEFAULT_OUTPUTCATEGORIES = [];
    public static readonly DEFULAT_OUTPUTTYES = [];

    public static readonly DEFAULT_OUTPUTCATEGORY = null;
    public static readonly DEFAULT_OUTPUTTYPE = null;

    public static readonly DEFAULT_REQUIREDBYDATE = "";

    // #endregion Defaults

    // #region Related Properties

    @observable
    public outputCategories = observable<OutputCategory>(AddEditOutputModel.DEFAULT_OUTPUTCATEGORIES);

    @observable
    public outputTypes = observable<OutputType>(AddEditOutputModel.DEFULAT_OUTPUTTYES);

    // #endregion Related Properties

    // #region Properties

    public outputId: string | null = null;

    public planOfWorkId: string | null = null;

    @observable
    @NotEquals(null, { message: "Please select an option" })
    public outputCategory: OutputCategory | null = AddEditOutputModel.DEFAULT_OUTPUTCATEGORY;

    @observable
    @NotEquals(null, { message: "Please select an option" })
    public outputType: OutputType | null = AddEditOutputModel.DEFAULT_OUTPUTTYPE;

    @observable
    @IsNotEmpty({ message: "Please provide a date" })
    public requiredByDate: string = AddEditOutputModel.DEFAULT_REQUIREDBYDATE;

    public rowVersion: string | null = null;

    // #endregion Properties

    // #region Methods

    @action
    public fromDto(dto: any): void {
        this.outputCategories.replace(
            dto.outputCategories.map((oc: IOutputCategory) => {
                const enumType = new OutputCategory();

                enumType.fromDto(oc);

                return enumType;
            }),
        );

        this.outputTypes.replace(
            dto.outputTypes.map((oc: IOutputType) => {
                const outputType = new OutputType();

                outputType.fromDto(oc);

                return outputType;
            }),
        );

        if (!isEmptyOrWhitespace(dto.outputId)) {
            this.outputId = dto.outputId;
            this.planOfWorkId = dto.planOfWorkId;
            this.outputCategory = this.outputCategories.find((oc) => oc.id === dto.outputCategoryId) ?? AddEditOutputModel.DEFAULT_OUTPUTCATEGORY;
            this.outputType = this.outputTypes.find((ot) => ot.id === dto.outputTypeId) ?? AddEditOutputModel.DEFAULT_OUTPUTTYPE;
            this.requiredByDate = dto.requiredByDate;
            this.rowVersion = dto.rowVersion;
        }
    }

    public toDto(): any {
        return {
            outputId: this.outputId,
            planOfWorkId: this.planOfWorkId,
            outputCategoryId: this.outputCategory?.id,
            outputTypeId: !isEmptyOrWhitespace(this.outputType?.id) ? this.outputType?.id : null,
            newOutputTypeTitle: isEmptyOrWhitespace(this.outputType?.id) ? this.outputType?.displayName : null,
            requiredByDate: this.requiredByDate,
            rowVersion: this.rowVersion,
        };
    }

    // #endregion Methods
}
