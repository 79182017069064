import { action, computed, observable } from "mobx";
import { ModelBase, isEmptyOrWhitespace } from "@shoothill/core";
import { PermitQuestionAnswerDTO, PermitQuestionAnswerModel } from "./PermitQuestionAnswerModel";

export class PermitModel extends ModelBase<PermitModel, any> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Constants and Defaults

    public static readonly DEFAULT_ID = null;
    public static readonly DEFAULT_PROJECT_ID = "";
    public static readonly DEFAULT_PERMIT_NUMBER = null;
    public static readonly DEFAULT_PERMIT_TYPE_ID = "";
    public static readonly DEFAULT_ESTIMATED_START_TIME = null;
    public static readonly DEFAULT_ESTIMATED_END_TIME = null;
    public static readonly DEFAULT_NUM_WORKING_UNDER_PERMIT = null;
    public static readonly DEFAULT_NUM_WORKING_IN_AREA = null;
    public static readonly DEFAULT_EXACT_LOCATION_OF_WORK = null;
    public static readonly DEFAULT_SIGNAGE_NOTICES_LOCATIONS = null;
    public static readonly DEFAULT_DESCRIPTION_OF_WORKS_BEING_UNDERTAKEN = null;
    public static readonly DEFAULT_ISSUED_BY_USER_NAME = null;
    public static readonly DEFAULT_ISSUED_BY_SIGNATURE_URL = null;
    public static readonly DEFAULT_ISSUED_BY_DATE = null;
    public static readonly DEFAULT_ISSUED_TO_INDUCTION_USER_ID = null;
    public static readonly DEFAULT_ISSUED_TO_SIGNATURE_URL = null;
    public static readonly DEFAULT_ISSUED_TO_DATE = null;
    public static readonly DEFAULT_SURRENDERED_BY_USER_NAME = null;
    public static readonly DEFAULT_SURRENDERED_BY_SIGNATURE_URL = null;
    public static readonly DEFAULT_SURRENDERED_BY_DATE = null;
    public static readonly DEFAULT_CANCELLED_BY_USER_NAME = null;
    public static readonly DEFAULT_CANCELLED_BY_SIGNATURE_URL = null;
    public static readonly DEFAULT_CANCELLED_BY_DATE = null;
    public static readonly DEFAULT_IS_DELETED = false;
    public static readonly DEFAULT_CREATED_BY_USER_ID = null;
    public static readonly DEFAULT_CREATED_DATE = null;
    public static readonly DEFAULT_ROW_VERSION = null;

    // #endregion Constants and Defaults

    // #region Properties

    @observable
    public id: string | null = PermitModel.DEFAULT_ID;

    @observable
    public projectId: string = PermitModel.DEFAULT_PROJECT_ID;

    @observable
    public permitNumber: number | null = PermitModel.DEFAULT_PERMIT_NUMBER;

    @observable
    public permitTypeId: string = PermitModel.DEFAULT_PERMIT_TYPE_ID;

    @observable
    public estimatedStartTime: string | null = PermitModel.DEFAULT_ESTIMATED_START_TIME;

    @observable
    public estimatedEndTime: string | null = PermitModel.DEFAULT_ESTIMATED_END_TIME;

    @observable
    public numWorkingUnderPermit: number | null = PermitModel.DEFAULT_NUM_WORKING_UNDER_PERMIT;

    @observable
    public numWorkingInArea: number | null = PermitModel.DEFAULT_NUM_WORKING_IN_AREA;

    @observable
    public exactLocationOfWork: string | null = PermitModel.DEFAULT_EXACT_LOCATION_OF_WORK;

    @observable
    public signageNoticesLocations: string | null = PermitModel.DEFAULT_SIGNAGE_NOTICES_LOCATIONS;

    @observable
    public descriptionOfWorksBeingUndertaken: string | null = PermitModel.DEFAULT_DESCRIPTION_OF_WORKS_BEING_UNDERTAKEN;

    @observable
    public issuedByUserName: string | null = PermitModel.DEFAULT_ISSUED_BY_USER_NAME;

    @observable
    public issuedBySignatureURL: string | null = PermitModel.DEFAULT_ISSUED_BY_SIGNATURE_URL;

    @observable
    public issuedByDate: string | null = PermitModel.DEFAULT_ISSUED_BY_DATE;

    @observable
    public issuedToInductionUserId: string | null = PermitModel.DEFAULT_ISSUED_TO_INDUCTION_USER_ID;

    @observable
    public issuedToSignatureURL: string | null = PermitModel.DEFAULT_ISSUED_TO_SIGNATURE_URL;

    @observable
    public issuedToDate: string | null = PermitModel.DEFAULT_ISSUED_TO_DATE;

    @observable
    public surrenderedByUserName: string | null = PermitModel.DEFAULT_SURRENDERED_BY_USER_NAME;

    @observable
    public surrenderedBySignatureURL: string | null = PermitModel.DEFAULT_SURRENDERED_BY_SIGNATURE_URL;

    @observable
    public surrenderedByDate: string | null = PermitModel.DEFAULT_SURRENDERED_BY_DATE;

    @observable
    public cancelledByUserName: string | null = PermitModel.DEFAULT_CANCELLED_BY_USER_NAME;

    @observable
    public cancelledBySignatureURL: string | null = PermitModel.DEFAULT_CANCELLED_BY_SIGNATURE_URL;

    @observable
    public cancelledByDate: string | null = PermitModel.DEFAULT_CANCELLED_BY_DATE;

    @observable
    public isDeleted: boolean = PermitModel.DEFAULT_IS_DELETED;

    @observable
    public createdByUserId: number | null = PermitModel.DEFAULT_CREATED_BY_USER_ID;

    @observable
    public createdDate: string | null = PermitModel.DEFAULT_CREATED_DATE;

    @observable
    public rowVersion: string | null = PermitModel.DEFAULT_ROW_VERSION;

    // #endregion Properties

    @observable
    public permitQuestionAnswers: PermitQuestionAnswerModel[] = [];

    // #endregion Properties

    // #region Actions

    @action
    public reset = () => {
        this.id = PermitModel.DEFAULT_ID;
        this.projectId = PermitModel.DEFAULT_PROJECT_ID;
        this.permitNumber = PermitModel.DEFAULT_PERMIT_NUMBER;
        this.permitTypeId = PermitModel.DEFAULT_PERMIT_TYPE_ID;
        this.estimatedStartTime = PermitModel.DEFAULT_ESTIMATED_START_TIME;
        this.estimatedEndTime = PermitModel.DEFAULT_ESTIMATED_END_TIME;
        this.numWorkingUnderPermit = PermitModel.DEFAULT_NUM_WORKING_UNDER_PERMIT;
        this.numWorkingInArea = PermitModel.DEFAULT_NUM_WORKING_IN_AREA;
        this.exactLocationOfWork = PermitModel.DEFAULT_EXACT_LOCATION_OF_WORK;
        this.signageNoticesLocations = PermitModel.DEFAULT_SIGNAGE_NOTICES_LOCATIONS;
        this.descriptionOfWorksBeingUndertaken = PermitModel.DEFAULT_DESCRIPTION_OF_WORKS_BEING_UNDERTAKEN;
        this.issuedByUserName = PermitModel.DEFAULT_ISSUED_BY_USER_NAME;
        this.issuedBySignatureURL = PermitModel.DEFAULT_ISSUED_BY_SIGNATURE_URL;
        this.issuedByDate = PermitModel.DEFAULT_ISSUED_BY_DATE;
        this.issuedToInductionUserId = PermitModel.DEFAULT_ISSUED_TO_INDUCTION_USER_ID;
        this.issuedToSignatureURL = PermitModel.DEFAULT_ISSUED_TO_SIGNATURE_URL;
        this.issuedToDate = PermitModel.DEFAULT_ISSUED_TO_DATE;
        this.surrenderedByUserName = PermitModel.DEFAULT_SURRENDERED_BY_USER_NAME;
        this.surrenderedBySignatureURL = PermitModel.DEFAULT_SURRENDERED_BY_SIGNATURE_URL;
        this.surrenderedByDate = PermitModel.DEFAULT_SURRENDERED_BY_DATE;
        this.cancelledByUserName = PermitModel.DEFAULT_CANCELLED_BY_USER_NAME;
        this.cancelledBySignatureURL = PermitModel.DEFAULT_CANCELLED_BY_SIGNATURE_URL;
        this.cancelledByDate = PermitModel.DEFAULT_CANCELLED_BY_DATE;
        this.isDeleted = PermitModel.DEFAULT_IS_DELETED;
        this.createdByUserId = PermitModel.DEFAULT_CREATED_BY_USER_ID;
        this.createdDate = PermitModel.DEFAULT_CREATED_DATE;
        this.rowVersion = PermitModel.DEFAULT_ROW_VERSION;

        this.permitQuestionAnswers.length = 0;
    };

    @action
    public fromDto(dto: PermitAndRelatedResponseDTO): void {
        const permitDto = dto.permit;

        for (let key in permitDto) {
            if (permitDto.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(permitDto[key]);
                } else {
                    this[key] = permitDto[key];
                }
            }
        }

        // Manually process the child array otherwise we will end up with an array of dtos being stored in the array of models.
        let processedQuestionAnswers: PermitQuestionAnswerModel[] = [];

        for (const questionAnswer of dto.permitQuestionAnswers) {
            const questionAnswerToAdd = new PermitQuestionAnswerModel();
            questionAnswerToAdd.fromDto(questionAnswer);
            processedQuestionAnswers.push(questionAnswerToAdd);
        }

        this.permitQuestionAnswers = [...this.permitQuestionAnswers, ...processedQuestionAnswers];
    }

    @action
    public fromRelatedDto(dto: PermitRelatedResponseDTO): void {
        // Manually process the child array otherwise we will end up with an array of dtos being stored in the array of models.
        let processedQuestionAnswers: PermitQuestionAnswerModel[] = [];

        for (const questionAnswer of dto.permitQuestionAnswers) {
            const questionAnswerToAdd = new PermitQuestionAnswerModel();
            questionAnswerToAdd.fromDto(questionAnswer);
            processedQuestionAnswers.push(questionAnswerToAdd);
        }

        this.permitQuestionAnswers = [...this.permitQuestionAnswers, ...processedQuestionAnswers];
    }

    public toDto() {
        const permitModel: PermitDTO = {
            id: this.id,
            projectId: this.projectId,
            permitNumber: this.permitNumber,
            permitTypeId: this.permitTypeId,
            estimatedStartTime: this.estimatedStartTime,
            estimatedEndTime: this.estimatedEndTime,
            numWorkingUnderPermit: this.numWorkingUnderPermit,
            numWorkingInArea: this.numWorkingInArea,
            exactLocationOfWork: this.exactLocationOfWork,
            signageNoticesLocations: this.signageNoticesLocations,
            descriptionOfWorksBeingUndertaken: this.descriptionOfWorksBeingUndertaken,
            issuedByUserName: this.issuedByUserName,
            issuedBySignatureURL: this.issuedBySignatureURL,
            issuedByDate: this.issuedByDate,
            issuedToInductionUserId: this.issuedToInductionUserId,
            issuedToSignatureURL: this.issuedToSignatureURL,
            issuedToDate: this.issuedToDate,
            surrenderedByUserName: this.surrenderedByUserName,
            surrenderedBySignatureURL: this.surrenderedBySignatureURL,
            surrenderedByDate: this.surrenderedByDate,
            cancelledByUserName: this.cancelledByUserName,
            cancelledBySignatureURL: this.cancelledBySignatureURL,
            cancelledByDate: this.cancelledByDate,
            isDeleted: this.isDeleted,
            createdByUserId: this.createdByUserId,
            createdDate: this.createdDate,
            rowVersion: this.rowVersion,
        };

        return permitModel;
    }

    // #endregion Actions

    // #region Custom Validation

    @computed
    public get validateEstimatedStartTime(): string {
        return this.estimatedStartTime === PermitModel.DEFAULT_ESTIMATED_START_TIME ? "Please provide a start time" : "";
    }

    @computed
    public get validateEstimatedEndTime(): string {
        return this.estimatedEndTime === PermitModel.DEFAULT_ESTIMATED_END_TIME ? "Please provide an end time" : "";
    }

    @computed
    public get validateNumWorkingUnderPermit(): string {
        return this.numWorkingUnderPermit === PermitModel.DEFAULT_NUM_WORKING_UNDER_PERMIT ? "Please provide a value" : "";
    }

    @computed
    public get validateNumWorkingInArea(): string {
        return this.numWorkingInArea === PermitModel.DEFAULT_NUM_WORKING_IN_AREA ? "Please provide a value" : "";
    }

    @computed
    public get validateExactLocationOfWork(): string {
        return this.exactLocationOfWork === PermitModel.DEFAULT_EXACT_LOCATION_OF_WORK ? "Please provide an exact location of work" : "";
    }

    @computed
    public get validateSignageNoticesLocations(): string {
        return this.signageNoticesLocations === PermitModel.DEFAULT_SIGNAGE_NOTICES_LOCATIONS ? "Please provide signage notices locations" : "";
    }

    @computed
    public get validateDescriptionOfWorksBeingUndertaken(): string {
        return this.descriptionOfWorksBeingUndertaken === PermitModel.DEFAULT_DESCRIPTION_OF_WORKS_BEING_UNDERTAKEN ? "Please provide a description of works being undertaken" : "";
    }

    @computed
    public get validateIssuedByUserName(): string {
        return this.issuedByUserName === PermitModel.DEFAULT_ISSUED_BY_USER_NAME ? "Please provide the name of the person issuing the permit" : "";
    }

    @computed
    public get validateIssuedBySignatureURL(): string {
        return this.issuedBySignatureURL === PermitModel.DEFAULT_ISSUED_BY_SIGNATURE_URL ? "Please provide a signature for the person issuing the permit" : "";
    }

    @computed
    public get validateIssuedToInductionUserId(): string {
        return this.issuedToInductionUserId === PermitModel.DEFAULT_ISSUED_TO_INDUCTION_USER_ID ? "Please provide the induction user ID of the person the permit is issued to" : "";
    }

    @computed
    public get validateIssuedToSignatureURL(): string {
        return this.issuedToSignatureURL === PermitModel.DEFAULT_ISSUED_TO_SIGNATURE_URL ? "Please provide a signature for the person the permit is issued to" : "";
    }

    @computed
    public get validateSurrenderedByUserName(): string {
        switch (true) {
            case this.hasIntendedToSurrenderBy === this.hasIntendedToCancelBy && this.hasIntendedToCancelBy:
                return "You only need to provide a name for surrendering or cancelling, not both";

            case this.hasIntendedToSurrenderBy === this.hasIntendedToCancelBy:
                return "If intending to surrender, please provide the name of the person surrendering the permit";

            case !this.hasIntendedToCancelBy && isEmptyOrWhitespace(this.surrenderedByUserName):
                return "Please provide the name of the person surrendering the permit";

            default:
                return "";
        }
    }

    @computed
    public get validateSurrenderedBySignatureURL(): string {
        switch (true) {
            case this.hasIntendedToSurrenderBy === this.hasIntendedToCancelBy && this.hasIntendedToCancelBy:
                return "You only need to provide a signature for surrendering or cancelling, not both";

            case this.hasIntendedToSurrenderBy === this.hasIntendedToCancelBy:
                return "If intending to surrender, please provide the signature of the person surrendering the permit";

            case !this.hasIntendedToCancelBy && isEmptyOrWhitespace(this.surrenderedBySignatureURL):
                return "Please provide the signature for the person surrendering the permit";

            default:
                return "";
        }
    }

    @computed
    public get validateCancelledByUserName(): string {
        switch (true) {
            case this.hasIntendedToSurrenderBy === this.hasIntendedToCancelBy && this.hasIntendedToSurrenderBy:
                return "You only need to provide a name for surrendering or cancelling, not both";

            case this.hasIntendedToSurrenderBy === this.hasIntendedToCancelBy:
                return "If intending to cancel, please provide the name of the person cancelling the permit";

            case !this.hasIntendedToSurrenderBy && isEmptyOrWhitespace(this.cancelledByUserName):
                return "Please provide the name of the person cancelling the permit";

            default:
                return "";
        }
    }

    @computed
    public get validateCancelledBySignatureURL(): string {
        switch (true) {
            case this.hasIntendedToSurrenderBy === this.hasIntendedToCancelBy && this.hasIntendedToSurrenderBy:
                return "You only need to provide a signature for surrendering or cancelling, not both";

            case this.hasIntendedToSurrenderBy === this.hasIntendedToCancelBy:
                return "If intending to cancel, please provide the signature of the person cancelling the permit";

            case !this.hasIntendedToSurrenderBy && isEmptyOrWhitespace(this.cancelledBySignatureURL):
                return "Please provide the signature of the person cancelling the permit";

            default:
                return "";
        }
    }

    @computed
    private get hasIntendedToSurrenderBy() {
        return !isEmptyOrWhitespace(this.surrenderedByUserName) || !isEmptyOrWhitespace(this.surrenderedBySignatureURL);
    }

    @computed
    private get hasIntendedToCancelBy() {
        return !isEmptyOrWhitespace(this.cancelledByUserName) || !isEmptyOrWhitespace(this.cancelledBySignatureURL);
    }

    // #endregion Custom Validation
}

export interface PermitDTO {
    id: string | null;
    projectId: string;
    permitNumber: number | null;
    permitTypeId: string;
    estimatedStartTime: string | null;
    estimatedEndTime: string | null;
    numWorkingUnderPermit: number | null;
    numWorkingInArea: number | null;
    exactLocationOfWork: string | null;
    signageNoticesLocations: string | null;
    descriptionOfWorksBeingUndertaken: string | null;
    issuedByUserName: string | null;
    issuedBySignatureURL: string | null;
    issuedByDate: string | null;
    issuedToInductionUserId: string | null;
    issuedToSignatureURL: string | null;
    issuedToDate: string | null;
    surrenderedByUserName: string | null;
    surrenderedBySignatureURL: string | null;
    surrenderedByDate: string | null;
    cancelledByUserName: string | null;
    cancelledBySignatureURL: string | null;
    cancelledByDate: string | null;
    isDeleted: boolean;
    createdByUserId: number | null;
    createdDate: string | null;
    rowVersion: string | null;
}

export interface PermitUpsertAndRelatedResponseDTO {
    permit: PermitDTO;
}

export interface PermitRelatedResponseDTO {
    permitQuestionAnswers: PermitQuestionAnswerDTO[];
    permitTypes: PermitTypeDTO[];
    permitProjectDetails: PermitProjectDetailsDTO;
    inductionUsers: InductionUserDTO[];
}

export interface PermitAndRelatedResponseDTO extends PermitRelatedResponseDTO {
    permit: PermitDTO;
}

export interface PermitProjectDetailsDTO {
    projectReference: string;
    projectName: string;
}

export interface InductionUserDTO {
    id: string;
    displayName: string;
}

export interface PermitTypeDTO {
    id: string;
    displayName: string;
    type: number;
    ordinal: number;
    isDeleted: boolean;
}

export interface PermitAnswerDTO {
    id: string | null;
    permitId: string | null;
    permitQuestionId: string | null;
    answerText: string | null;
    answerYesNo: boolean | null;
    answerRadio: string | null;
    answerCheckbox: boolean | undefined;
    createdDate: string | null;
    createdByUserId: string | null;
}

export interface UpsertPermitAndRelatedRequestDTO {
    permit: PermitDTO;
    permitAnswers: PermitAnswerDTO[];
}
