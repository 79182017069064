import { Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useObserver } from "@shoothill/core";
import { IEReportViewModel, ReportType } from "./IEReportViewModel";
import { DarwinPage, DarwinPageContent, DarwinPageTitle } from "Globals/Styles/AppStyling";
import { DarwinSelect } from "Components/AutoComplete/DarwinSelect";
import { AllIEReportView } from "./AllReport/AllIEReportView";
import { IECVRReportView } from "./CVRReport/IECVRReportView";
import { ReportControlContainer } from "../Report.styles";
import { IECostReportView } from "./CostReport/IECostReportView";
import { PackagesViewModel } from "Views/Project/Commercial/PackagesViewModel";

export const IEReportView: React.FunctionComponent = () => {
    const [viewModel] = useState(() => new IEReportViewModel());

    useEffect(() => {
        PackagesViewModel.Instance.apiGetRelated();
        viewModel.loadRelated();
        return () => {};
    }, []);

    const renderReport = () => {
        const reportType: string = viewModel.reportTypeOption ? viewModel.reportTypeOption.id : "";

        switch (reportType) {
            case ReportType.All:
                return <AllIEReportView ieId={viewModel.model.ieId} />;
            case ReportType.CVR:
                return <IECVRReportView ieId={viewModel.model.ieId} />;
            case ReportType.Cost:
                return <IECostReportView ieId={viewModel.model.ieId} />;
            default:
                return <>Please select a report</>;
        }
    };

    const renderPage = () => {
        return (
            <DarwinPage>
                <DarwinPageTitle>
                    <Typography variant="h1" color="textPrimary">
                        IE
                    </Typography>
                </DarwinPageTitle>
                <DarwinPageContent>
                    <ReportControlContainer>
                        <DarwinSelect
                            displayName="Project:"
                            execute={(val: any) => viewModel.setProjectAsync(val)}
                            fullWidth={true}
                            getOptionLabel={(option: any) => option.displayName || ""}
                            onBlur={() => viewModel.isFieldValid("projectId")}
                            options={viewModel.projectOptions}
                            placeholder="Please select"
                            validationMessage={viewModel.getError("projectId")}
                            value={viewModel.project}
                            canExecute={!viewModel.IsLoading}
                        />

                        <DarwinSelect
                            displayName="I&E:"
                            execute={(val: any) => viewModel.setIncomeAndExpenditureAsync(val)}
                            fullWidth={true}
                            getOptionLabel={(option: any) => option.displayName || ""}
                            onBlur={() => viewModel.isFieldValid("ieId")}
                            options={viewModel.incomeAndExpendituresForProject}
                            placeholder="Please select"
                            validationMessage={viewModel.getError("ieId")}
                            value={viewModel.incomeAndExpenditure}
                            canExecute={!viewModel.IsLoading}
                        />
                        {viewModel.model.ieId !== "" && (
                            <DarwinSelect
                                displayName="Report type:"
                                execute={(val: any) => viewModel.setReportType(val.id)}
                                fullWidth={true}
                                getOptionLabel={(option: any) => option.displayName || ""}
                                onBlur={() => viewModel.isFieldValid("reportType")}
                                options={viewModel.reportTypeOptions}
                                placeholder="Please select"
                                validationMessage={viewModel.getError("reportType")}
                                value={viewModel.reportTypeOption}
                                canExecute={!viewModel.IsLoading}
                            />
                        )}
                    </ReportControlContainer>

                    {viewModel.model.ieId !== "" && <>{renderReport()}</>}
                </DarwinPageContent>
            </DarwinPage>
        );
    };

    return useObserver(() => renderPage());
};
