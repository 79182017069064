import styled from "styled-components";
import { theme } from "../../Styles/AppTheme";

export const ChangeLogWrapper = styled.div`
    position: fixed;
    bottom: 0;
`;

export const Footer = styled.div`
    display: flex;
    height: 35px;
    width: 100%;
    flex: 1 0 0;
    justify-content: flex-start;
    /*     position: absolute;
        bottom: 0; */

    //background-color: green;
    padding: 10px 15px;

    color: ${theme.palette.common.white};

    a {
        text-decoration: underline;
        cursor: default;
        font-weight: 700;
        font-family: "Mont-Bold", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif";
    }

    a:hover {
        cursor: pointer;
    }
`;
