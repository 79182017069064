import { FieldType, ViewModelBase } from "@shoothill/core";
import { action, observable, runInAction } from "mobx";

import { AppUrls } from "AppUrls";
import { FormRequestDTO, WeatherReportModel, WeatherReportResponseDTO } from "./WeatherReportModel";

export class WeatherReportViewModel extends ViewModelBase<WeatherReportModel> {
    constructor(projectId: string, weatherReportDate: Date) {
        super(new WeatherReportModel());

        this.setDecorators(WeatherReportModel);

        this.model.projectId = projectId;
        this.model.weatherReportDate = weatherReportDate;

        // Load the weather report.
        this.loadWithRelated();
    }

    // #region API

    public loadWithRelated = async () => {
        const request: FormRequestDTO = {
            date: this.model.weatherReportDate,
            projectId: this.model.projectId,
            formId: null,
        };

        const apiResult = await this.Post<WeatherReportResponseDTO>(
            AppUrls.Server.Projects.Construction.ProgrammeUpdates.WeatherReport.GetCustomerVisitsSiteInstructionsAndRelatedByProjectId,
            request,
        );

        // Decorated actions do not work with asynchronous code.
        runInAction(() => {
            if (apiResult.wasSuccessful) {
                this.model.fromDto(apiResult.payload.weatherReport);
            } else {
                this.setSnackMessage("Error whilst loading the weather report");
                this.setSnackType(this.SNACKERROR);
                this.setSnackbarState(true);
            }
        });

        return apiResult;
    };

    public upsertWeatherReport = async () => {
        if (await this.isModelValid()) {
            const apiResult = await this.Post<any>(AppUrls.Server.Projects.Construction.ProgrammeUpdates.WeatherReport.UpsertCustomerVisitsSiteInstructions, this.model.toDto());

            // Decorated actions do not work with asynchronous code.
            runInAction(() => {
                if (apiResult.wasSuccessful) {
                    // Nothing to process.
                } else {
                    this.setSnackMessage("Error whilst saving the weather report");
                    this.setSnackType(this.SNACKERROR);
                    this.setSnackbarState(true);
                }
            });

            return apiResult;
        } else {
            return Promise.reject();
        }
    };

    // #endregion API

    // #region Snack Bar

    public SNACKERROR = "error";

    @observable
    public snackbarState = false;

    @observable
    public snackType = "";

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public snackMessage = "";

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    // #endregion Snack Bar

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<WeatherReportModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
