import { pxToRem } from "Globals/Styles/AppTheme";
import styled from "styled-components";
import { Box } from "@material-ui/core";

export const DarwinLayoutWrapper: any = styled(Box)`
    display: flex;
    width: 100%;
`;

export type DarwinBreadcrumbProps = {
    width?: number;
};

export const DarwinBreadcrumb: any = styled(Box)<DarwinBreadcrumbProps & DarwinBreadcrumbProps>`
    display: flex;
    width: ${(props) => (props.width ? pxToRem(props.width) : pxToRem(168))};
    background-color: #425b66;
`;

export const DarwinMain: any = styled(Box)`
    display: flex;
    width: 100%;
    flex: 1 0 0;
`;

// APM - I'm adding alternative styling options here as I cannot seem to get proper control of
//       styling elements that should be consistent between layouts.

// A container is common to both forms and table pages.
export const Container: any = styled(Box)`
    display: flex;
    flex-direction: column;
    //height: 100vh; // JC: Removed 100vh to fix issue where header would hide when scrolling beyond 100vh.
`;

// A workspace sits inside a container and below the header. It is not scrollable.
export const Workspace: any = styled(Box)`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

// A scollable workspace sits inside a container and below the header.
export const ScrollableWorkspace: any = styled(Box)`
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
`;
