import * as MobX from "mobx";
import { ModelBase } from "@shoothill/core";

export class IEGridRiskOpsModel extends ModelBase<IEGridRiskOpsModel, IEGridRiskOpsModelDTO> {
    public id: string = "";
    public createdDate: string = "";
    public sourceId: string | null = null;

    @MobX.observable
    public riskValue: number = 0;

    @MobX.observable
    public riskNote: string = "";

    fromDto(model: IEGridRiskOpsModelDTO): void {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    toDto(model: IEGridRiskOpsModel): void {}
}

export type IEGridRiskOpsModelDTO = {
    id: string;
    sourceId: string | null;
    createdDate: string;
    riskValue: number;
    riskNote: string;
};
