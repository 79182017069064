import { observable } from "mobx";

import { ApprovalPanelModelBase, ApprovalStatusBaseDTO, ApprovalPanelBaseDTO } from "Globals/Models/ApprovalPanelModelBase";
import { ApprovalDocumentDTO, ApprovalDocumentModel } from "Components/Approval/ApprovalDocumentModel";

export class PurchaseOrderApprovalPanelModel extends ApprovalPanelModelBase<PurchaseOrderApprovalPanelModel> {
    // #region Properties

    @observable
    public budgetHolderName: string | null = null;

    @observable
    public approvalDocuments: ApprovalDocumentModel[] = [];

    // #endregion Properties

    // #region Actions
    // #endregion Actions
}

export interface PurchaseOrderApprovalPanelDTO extends ApprovalPanelBaseDTO {
    budgetHolderName: string | null;
}

export interface PurchaseOrderApprovalStatusDTO extends ApprovalStatusBaseDTO {
    sendEmailToSupplier: boolean;
    sendWithAttachments: boolean;
    approvalDocuments: ApprovalDocumentDTO[];
}
