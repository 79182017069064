import * as MobX from "mobx";
import { ModelBase } from "@shoothill/core";
import moment from "moment";
export class RDDItemResponseModel extends ModelBase<RDDItemResponseModel, RDDItemResponseModelDTO> {
    @MobX.observable
    public id: string = "";

    @MobX.observable
    public designDataId: string = "";

    @MobX.observable
    public comment: string = "";

    @MobX.observable
    public receivedDate: Date | null = null;

    @MobX.observable
    public createdDate: Date | null = null;

    @MobX.observable
    public createdByUserId: string = "";

    @MobX.observable
    public createdByName: string = "";

    @MobX.observable
    public isDeleted: boolean = false;

    @MobX.computed
    public get receivedDateFormatted() {
        return this.receivedDate ? `${moment(this.receivedDate).format("DD/MM/YYYY")}` : "";
    }

    fromDto(model: RDDItemResponseModelDTO): void {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    toDto(model: RDDItemResponseModel): void {}
}

export type RDDItemResponseModelDTO = {
    id: string;
    designDataId: string;
    comment: string;
    receivedDate: Date | null;
    createdDate: Date | null;
    createdByUserId: string;
    createdByName: string;
    isDeleted: boolean;
};
