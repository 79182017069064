import { observable, action, computed } from "mobx";
import { KeyValuePair, ModelBase } from "@shoothill/core";
import { ProjectSiteAccessItemModel, ProjectSiteAccessItemModelDTO } from "./ProjectSiteAccessItemModel";
import moment from "moment";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class SiteAccessModel extends ModelBase<SiteAccessModel, SiteAccessModelDTO> {
    // DarwinModelBase
    // public id: string = "";
    // public createdDate: string = "";

    @observable
    public siteAccessItems: ProjectSiteAccessItemModel[] = [];

    @observable
    public siteVideoLink: string | null = null;

    //fromDto is required but you can leave it blank
    fromDto(model: SiteAccessModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else if (key === "siteAccessItems") {
                    // Do nothing.
                } else {
                    this[key] = model[key];
                }
            }
        }

        // Process the list
        let processedPos: ProjectSiteAccessItemModel[] = [];

        for (const item of model.siteAccessItems) {
            const itemToAdd = new ProjectSiteAccessItemModel();
            itemToAdd.fromDto(item);
            processedPos.push(itemToAdd);
        }

        this.siteAccessItems = processedPos;
    }

    //toDto is required but you can leave it blank
    toDto(model: SiteAccessModel): void {}
}

export type SiteAccessModelDTO = {
    siteAccessItems: ProjectSiteAccessItemModelDTO[];
    siteVideoLink: string | null;
};
