import { ApiResult, FieldType, ViewModelBase, isEmptyOrWhitespace } from "@shoothill/core";
import { runInAction, observable, computed, action } from "mobx";
import {
    CleanlinessReportModel,
    CleanlinessReportPhotosPhotoFile,
    FormRequestDTO,
    UpsertCleanlinessReportRequestDTO,
    UpsertCleanlinessReportResponseDTO,
} from "./CleanlinessReportModel";
import { AppUrls } from "AppUrls";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";

export class CleanlinessReportViewModel extends ViewModelBase<CleanlinessReportModel> {
    public constructor(projectId: string | null, date: any, formId: string | null) {
        super(new CleanlinessReportModel(), false);
        this.setDecorators(CleanlinessReportModel);
        this.model.projectId = projectId!;
        this.model.id = formId!;
        this.model.createdDate = date.toISOString();
        if (!isEmptyOrWhitespace(this.model.id) || this.model.id === null) {
            this.loadRelated();
        }
    }

    @observable public errorMessage: string = "";

    public server: ServerViewModel = new ServerViewModel();

    @observable
    public status: { id: string; displayName: string } | null = null;

    @observable
    public cleanlinessReportStatus = observable<any>([]);

    @computed
    public get isFormDisabled(): boolean {
        return this.model.hasId;
    }

    @action
    public handleCleanlinessStatusId = (item: { id: string; displayName: string }) => {
        if (item && item.id !== undefined) {
            this.status = item;
            this.model.cleanlinessReportStatusId = item.id;
        }
    };

    @action
    public reset = () => {
        this.model.reset();
        this.server.reset();
    };

    @action
    public setCleanlinessReportFile = (file: CleanlinessReportPhotosPhotoFile) => {
        this.model.cleanlinessReportPhotos.push(file);
    };

    @action
    public deleteCleanlinessReportFile = async (index: number): Promise<void> => {
        this.model.cleanlinessReportPhotos[index].isDeleted = true;
    };

    @action
    public cleanUp = () => {
        this.model.reset();
    };

    @action
    public setBackGroundStatus(id: string | null): string {
        const cleanlinessReportStatus = this.cleanlinessReportStatus.find((i: any) => i.id === id);
        const color = cleanlinessReportStatus?.color;
        return color ? color : "";
    }

    @action
    public setColorStatus(id: string | null): string {
        const cleanlinessReportStatus = this.cleanlinessReportStatus.find((i: any) => i.id === id);
        const color = cleanlinessReportStatus?.textColor;
        return color ? color : "";
    }

    public fileChange = async (event: any): Promise<void> => {
        if (event.target.files.length > 0) {
            let data: any = {
                fileName: event.target.files[0].name,
                formFile: event.target.files[0],
            };
            event.target.value = null;
            const apiResult = await this.fileUpload(data);
            if (apiResult && apiResult.wasSuccessful) {
                let fileToDisplay: CleanlinessReportPhotosPhotoFile = {
                    id: null,
                    cleanlinessReportId: null,
                    fileName: data.fileName,
                    photoURL: apiResult.payload,
                    isDeleted: false,
                    createdByUserId: "",
                    createdDate: "",
                };
                this.setCleanlinessReportFile(fileToDisplay);
            }
        }
    };

    public fileUpload = async (data: any): Promise<ApiResult<any>> => {
        const formData = new FormData();
        formData.append("formFile", data.formFile);
        formData.append("fileName", data.fileName);
        const apiResult = await this.Post<any>(AppUrls.Server.File.UploadFile, formData);
        if (apiResult) {
            if (!apiResult.wasSuccessful) {
                console.log(apiResult.errors);
            }
        }
        return apiResult;
    };

    public DownloadFile = async (fileUrl: string, fileName: string): Promise<void> => {
        try {
            const apiResult = await this.Post<Blob>(AppUrls.Server.File.DownloadFile, fileUrl, undefined, { responseType: "blob" });
            const response = apiResult as any;
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
        } catch (exception) {
            console.error(exception);
            this.setIsErrored(true);
        }
    };

    @action
    public loadRelated = async (): Promise<void> => {
        const request: FormRequestDTO = {
            date: this.model.createdDate,
            projectId: null,
            formId: this.model.id,
        };

        let apiResult = await this.Post<any>(AppUrls.Server.Projects.Construction.ProgrammeUpdates.CleanlinessReport.GetCleanlinessReportAndRelatedById, request);
        if (apiResult) {
            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.model.fromDto(apiResult.payload.cleanlinessReport);
                    this.cleanlinessReportStatus.replace(apiResult.payload.cleanlinessReportStatus);
                    this.handleCleanlinessStatusId(this.cleanlinessReportStatus.find((i) => i.id === this.model.cleanlinessReportStatusId)!);
                    apiResult.payload.cleanlinessReportPhotos.map((el: any) => this.setCleanlinessReportFile(el));
                });
            } else {
                console.log(apiResult.errors);
            }
        }
    };

    @action
    public upsert = async (e?: any): Promise<ApiResult<UpsertCleanlinessReportRequestDTO>> => {
        e?.preventDefault();

        if (await this.isMyModelValid()) {
            const model: any = this.model.toDto();
            let cleanlinessReportPhotos: CleanlinessReportPhotosPhotoFile[] = [];
            this.model.cleanlinessReportPhotos.forEach((cleanlinessReportPhoto) => {
                const cleanlinessReport: CleanlinessReportPhotosPhotoFile = {
                    id: cleanlinessReportPhoto.id,
                    cleanlinessReportId: cleanlinessReportPhoto.cleanlinessReportId,
                    fileName: cleanlinessReportPhoto.fileName,
                    photoURL: cleanlinessReportPhoto.photoURL,
                    createdByUserId: cleanlinessReportPhoto.createdByUserId,
                    isDeleted: cleanlinessReportPhoto.isDeleted,
                    createdDate: cleanlinessReportPhoto.createdDate,
                };
                cleanlinessReportPhotos.push(cleanlinessReport);
            });

            const request: UpsertCleanlinessReportRequestDTO = {
                cleanlinessReport: model,
                cleanlinessReportPhotos: cleanlinessReportPhotos,
            };

            let apiResult = await this.Post<UpsertCleanlinessReportResponseDTO>(
                AppUrls.Server.Projects.Construction.ProgrammeUpdates.CleanlinessReport.UpsertCleanlinessReport,
                request,
            );

            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.model.fromDto(apiResult.payload.cleanlinessReport);
                    this.cleanlinessReportStatus.replace(apiResult.payload.cleanlinessReportStatus);
                    apiResult.payload.cleanlinessReportPhotos.map((el: any) => this.setCleanlinessReportFile(el));
                });
            }
            return apiResult;
        } else {
            this.errorMessage = "Form is not valid";
            return Promise.reject();
        }
    };

    // Validation Start

    @computed
    private get validateCleanlinessSubContractorName() {
        const errorMessage = this.model.validateCleanlinessSubContractorName;
        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateCleanlinessStatusId() {
        const errorMessage = this.model.validateCleanlinessStatusId;
        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateCleanlinessNoteDetails() {
        const errorMessage = this.model.validateCleanlinessNoteDetails;
        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    // End Validation

    private isMyModelValid = async (): Promise<boolean> => {
        let isValid = true;
        if ((await this.isModelValid()) === false) {
            isValid = false;
        }

        return isValid;
    };

    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (await this.isModelValid()) {
            this.errorMessage = "Form is valid";
        } else {
            this.errorMessage = "Form is not valid";
        }
    };

    public async isFieldValid(fieldName: keyof FieldType<CleanlinessReportModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        if (fieldName) {
            switch (fieldName) {
                case "cleanlinessSubContractorName": {
                    const result = this.validateCleanlinessSubContractorName;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "cleanlinessReportStatusId": {
                    const result = this.validateCleanlinessStatusId;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "cleanlinessNoteDetails": {
                    const result = this.validateCleanlinessNoteDetails;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
            }
        } else {
            errorMessage = "";
            isValid = true;
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
