import MaterialTable from "material-table";
import * as Defaults from "Globals/Defaults/TableOptions";
import React, { useImperativeHandle, useEffect, useState } from "react";
import { DarwinTablePageContent } from "Globals/Styles/AppStyling";
import { ContactListViewModel } from "./ContactListViewModel";
import { ContactType } from "./ContactType";
import { ClickableImgCell } from "Components/Table/ClickableImgCell";

import EmailLogo from "Content/Email.svg";
import EditLogo from "Content/AddEdit.svg";
import DeleteLogo from "Content/Bin.svg";
import StarLogo from "Content/star.svg";
import SolidStarLogo from "Content/solidstar.svg";
import { useObserver } from "mobx-react-lite";
import { ContactBaseDTO } from "Globals/Models/Domain";
import { ApiResult, isNullOrUndefined } from "@shoothill/core";
import { DeleteModal } from "Components/Modal/DeleteModal";
import { EditContactModal } from "./EditContactModal";
import { AddEditContactModel } from "./AddEditContactModel";
import { ContactTypeHelpers } from "./ContactTypeEnum";

interface IContactListViewProps {
    contactType: ContactType;
    contactList: ContactBaseDTO[];
    showEditColumn?: boolean;
    showEmailColumn?: boolean;
    showDeleteColumn?: boolean;

    loadContact: (id: string) => Promise<ApiResult<ContactBaseDTO>>;
    deleteContact: (id: string) => void;
    upsertContact: (contact: AddEditContactModel) => any;
    updatContactIsPrimary: (id: string, isPrimary: boolean) => any;
    onCleanup: () => any;
}

export const ContactListView = React.forwardRef((props: IContactListViewProps, ref: any) => {
    //export const ContactListView: React.FC<IContactListViewProps> = (
    const { contactType, contactList, deleteContact, loadContact, updatContactIsPrimary, showEditColumn, showDeleteColumn, showEmailColumn, upsertContact, onCleanup } = props;
    const [viewModel] = useState(() => new ContactListViewModel(contactType));
    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [editOpen, setEditOpen] = React.useState(false);
    const [selectedContact, selectContact] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState("");
    const [editContact, setEditContact] = React.useState<ContactBaseDTO | undefined>(undefined);
    const [count, setCounter] = React.useState<number>(0); //Hack to update the isPrimary, without making everything observable.

    //useEffect below only gets run on initial render
    useEffect(() => {
        return () => {
            viewModel.cleanUp();
        };
    }, []);

    // https://www.fabiofranchino.com/blog/call-child-method-from-parent-rect-component/
    useImperativeHandle(
        ref,
        () => ({
            cleanUp() {
                viewModel.cleanUp();
            },
        }),
        [],
    );

    const editClick = (id: string) => {
        selectContact(id);

        const promise = loadContact(id);

        if (promise) {
            promise.then((result: ApiResult<ContactBaseDTO>) => {
                // TODO Pass into form
                if (result.wasSuccessful === true) {
                    setEditOpen(true);
                    setEditContact(result.payload);
                } else {
                    setEditOpen(true);
                    setErrorMessage("Failed to find contact. Please try again later.");
                }
            });
        }
    };

    const deleteClick = (id: string) => {
        selectContact(id);
        setDeleteOpen(true);
    };

    const formatEmail = (id: string): any => {
        const contact: ContactBaseDTO | undefined = contactList.find((a) => a.id === id);
        const mailto: string = "mailto:" + (isNullOrUndefined(contact) === false ? contact?.email : "");
        return (
            <a href={mailto} className="selectable-cell">
                <img src={EmailLogo} alt="Email contact" /* onClick={deleteClick} */ id={id} className="delete" /* showText={false} */ />
            </a>
        );
    };

    const formatEdit = (id: string): any => {
        return <ClickableImgCell logo={EditLogo} text={"Edit contact"} onClick={editClick} id={id} className="selectable-cell" showText={false} />;
    };

    const formatDelete = (id: string): any => {
        return <ClickableImgCell logo={DeleteLogo} text={"Delete contact"} onClick={deleteClick} id={id} className="delete selectable-cell" showText={false} />;
    };

    const formatStar = (rowData: ContactBaseDTO): any => {
        return (
            <ClickableImgCell
                logo={StarLogo}
                text={"Is Primary Contact"}
                onClick={(e: any) => {
                    onIsPrimaryChange(rowData);
                }}
                id={rowData.id!}
                className="selectable-cell starimg"
                showText={false}
            />
        );
    };

    const formatSolidStar = (rowData: ContactBaseDTO): any => {
        return (
            <ClickableImgCell
                logo={SolidStarLogo}
                text={"Is Primary Contact"}
                onClick={(e: any) => {
                    onIsPrimaryChange(rowData);
                }}
                id={rowData.id!}
                className="selectable-cell starimg"
                showText={false}
            />
        );
    };

    const onIsPrimaryChange = (rowData: ContactBaseDTO): any => {
        if (rowData.isPrimary === false) {
            updatContactIsPrimary(rowData.id!, true);
            setCounter(count + 1);
        }
    };

    const handleDeleteClose = () => {
        setDeleteOpen(false);
        selectContact("");
    };

    const handleEditClose = () => {
        setEditOpen(false);
        selectContact("");
        setErrorMessage("");
        setEditContact(undefined);
        onCleanup();
    };

    const handleDeleteContact = () => {
        deleteContact(selectedContact);
        setDeleteOpen(false);
    };

    const handleEditContact = async (editedContact: AddEditContactModel) => {
        if (await viewModel.isModelValid()) {
            const promise = upsertContact(editedContact);
            promise.then((result: any) => {
                if (result.wasSuccessful === true) {
                    setEditOpen(false);
                }
            });
        }
    };

    let columns: any[] = [
        { title: "First name", field: "firstName" },
        { title: "Last name", field: "lastName" },
        { title: "Position", field: "position" },
        { title: "Contact email", field: "email" },
        { title: "Contact phone", field: "phone" },
        { title: "Type", field: "contactTypeId", render: (rowData: ContactBaseDTO) => <div>{ContactTypeHelpers.getText(rowData.contactTypeId)}</div> },
        {
            title: "Primary Contact?",
            field: "isPrimary",
            render: (rowData: ContactBaseDTO) => (
                <div
                    className="selectable-cell "
                    onClick={(e: any) => {
                        onIsPrimaryChange(rowData);
                    }}
                >
                    {rowData.isPrimary === true ? formatSolidStar(rowData) : formatStar(rowData)}
                </div>
            ),
            width: 20,
        },
    ];

    if (showEmailColumn === undefined || showEmailColumn === true) {
        columns.push({
            title: "",
            field: "id",
            sorting: false,
            filtering: false,
            width: 15,
            render: (rowData: any) => {
                if (rowData !== null && rowData !== undefined) {
                    return formatEmail(rowData.id);
                }
            },
        });
    }

    if (showEditColumn === undefined || showEditColumn === true) {
        columns.push({
            title: "",
            field: "id",
            sorting: false,
            filtering: false,
            width: 15,
            render: (rowData: any) => {
                if (rowData !== null && rowData !== undefined) {
                    return formatEdit(rowData.id);
                }
            },
        });
    }

    if (showDeleteColumn === undefined || showDeleteColumn === true) {
        columns.push({
            title: "",
            field: "id",
            sorting: false,
            filtering: false,
            width: "15px",
            render: (rowData: any) => {
                if (rowData !== null && rowData !== undefined) {
                    return formatDelete(rowData.id);
                }
            },
        });
    }

    return useObserver(() => (
        <DarwinTablePageContent>
            <DeleteModal
                open={deleteOpen}
                onClose={handleDeleteClose}
                onDelete={handleDeleteContact}
                title="Delete contact?"
                text="Are you sure you want to delete this contact?"
            />
            <EditContactModal contact={editContact} open={editOpen} onClose={handleEditClose} onSave={handleEditContact} title={"Edit Contact"} text={errorMessage} />
            <MaterialTable columns={columns} options={Defaults.GetDarwinTableOptionsNoSearch()} data={contactList} title="" />
        </DarwinTablePageContent>
    ));
});
