import { debounce } from "@material-ui/core";
import { FieldType, ViewModelBase } from "@shoothill/core";
import { action, computed, IObservableArray, observable, runInAction } from "mobx";
import { AppUrls } from "AppUrls";
import { GenericId, GenericIncludeDeleted, UserDTO, UserWithProjectDTO } from "Globals/Models";
import { ProjectApprovalTypeModel } from "./ProjectApprovalTypeModel";
import { ProjectListModel } from "./ProjectListModel";
import { ProjectListItemsAndRelatedDTO } from "./ProjectListItemsAndRelatedDTO";
import { ProjectListItemViewModel } from "./ProjectListItemViewModel";
import { ProjectStatusTypeModel } from "./ProjectStatusTypeModel";
import { StoresInstance } from "Globals/Stores";
import { RoleLevelEnum, RoleTypeEnum } from "Globals/Stores/Domain/Admin";
import { GeneralModelDTO } from "./General/GeneralModel";
import { ProjectListModelWithAddress } from "./ProjectListModelWithAddress";
import { PermitTypesDTO } from "./Construction/Permit/PermitTypesLookupModel";
import { InspectionTypesDTO } from "./Construction/Inspections/InspectionTypesLookupModel";
import { ServerViewModel } from "../../Globals/ViewModels/ServerViewModel";
import { IncidentAndRelatedResponseDTO } from "./Construction/Incidents/Indicent/IncidentModel";
import { ProjectListFilterViewModel } from "./ProjectListFilterViewModel";

export class ProjectListViewModel extends ViewModelBase<ProjectListModel> {
    constructor() {
        super(new ProjectListModel(), false);
        this.setDecorators(ProjectListModel);
    }

    private readonly DEBOUNCE_VALUE_MS = 200;
    public server: ServerViewModel = new ServerViewModel();

    @computed
    public get canAddProject(): boolean {
        const isAdmin: boolean = StoresInstance.Domain.AccountStore.isInRole(RoleTypeEnum.Admin);
        // Only users with role CommDirector and above can add projects.
        const roleLevel: RoleLevelEnum | null = StoresInstance.Domain.AccountStore.UserRoleLevel;
        return isAdmin || (roleLevel !== null && roleLevel >= RoleLevelEnum.CommDir);
    }

    @observable
    public searchString: string = "";

    @observable
    public filterSearchString: string = "";

    public getSearchString = () => {
        return computed(() => this.searchString);
    };

    @action
    public setSearchString = (value: string) => {
        this.searchString = value;
        this.setFilterSearchString(value);
    };

    private setFilterSearchString = debounce(
        action((value: string) => {
            this.filterSearchString = value;
        }),
        this.DEBOUNCE_VALUE_MS,
    );

    @action
    public handleStatusChange = (val: any) => {
        ProjectListFilterViewModel.Instance.model.statuses = val;
        //this.apiGetAllAndRelated();
    };

    @action
    public handleCellChange = (val: number[] | null) => {
        ProjectListFilterViewModel.Instance.model.cells = val;
        //this.apiGetAllAndRelated();
    };

    @action
    public clear = () => {
        ProjectListFilterViewModel.Instance.model.reset();
        this.apiGetAllAndRelated();
    };

    @observable
    public errorMessage: string = "";

    @observable
    public hasLoaded = true;

    @computed
    public get filteredProjectViewModels(): ProjectListItemViewModel[] {
        const statuses = ProjectListFilterViewModel.Instance.model.statuses || [];
        const cells = ProjectListFilterViewModel.Instance.model.cells || [];
        return this.projectViewModels.filter((vm) => vm.matchesFilter(this.filterSearchString, statuses, cells));
    }

    private projectViewModels: IObservableArray<ProjectListItemViewModel> = observable<ProjectListItemViewModel>([]);

    @action
    public navigateToAddNewProject = () => {
        this.history.push(AppUrls.Client.Project.GeneralAdd);
    };

    @action
    private setHasLoaded(val: boolean) {
        this.hasLoaded = val;
    }

    @action
    private populateListItemViewModels = (dto: ProjectListItemsAndRelatedDTO) => {
        const projectViewModels: ProjectListItemViewModel[] = [];

        for (const projectListItem of dto.projectListItems) {
            const itemModel = new ProjectListModel(projectListItem);
            const approvalTypeModel = new ProjectApprovalTypeModel(dto.projectApprovalTypes.find((pat) => pat.id === projectListItem.projectApprovalTypeId));
            const statusTypeModel = new ProjectStatusTypeModel(dto.projectStatusTypes.find((pst) => pst.id === projectListItem.projectStatusTypeId));

            projectViewModels.push(new ProjectListItemViewModel(itemModel, approvalTypeModel, statusTypeModel));
        }

        this.projectViewModels.replace(projectViewModels);
    };

    public apiGetAllAndRelated = async (): Promise<void> => {
        const request: GenericIncludeDeleted = {
            includeDeleted: false,
        };

        const apiResult = await this.Post<ProjectListItemsAndRelatedDTO>(AppUrls.Server.Projects.GetAllAndRelated, request);

        if (apiResult) {
            if (apiResult.wasSuccessful) {
                this.populateListItemViewModels(apiResult.payload);
                ProjectListFilterViewModel.Instance.setStatuses(apiResult.payload.projectStatusTypes, true);
            } else {
                console.log(apiResult.errors);
            }
            this.setHasLoaded(true);
        }
    };

    @action
    public apiGetSiteTabletProjectDetailsByUserId = async (): Promise<ProjectListModelWithAddress | null> => {
        this.setIsLoading(true);

        let apiResult = await this.Get<any>(AppUrls.Server.Projects.GetProjectDetailsByUserId);
        if (apiResult) {
            if (apiResult.wasSuccessful) {
                runInAction(() => {});
            } else {
                console.log(apiResult.errors);
            }
            this.setHasLoaded(true);
        }
        this.setIsLoading(false);
        return apiResult.payload.projectListItem;
    };

    @action
    public apiGetPermitTypes = async (): Promise<PermitTypesDTO[]> => {
        this.setIsLoading(true);

        let apiResult = await this.Get<any>(AppUrls.Server.Projects.GetPermitTypes);
        if (apiResult) {
            if (apiResult.wasSuccessful) {
                runInAction(() => {});
            } else {
                console.log(apiResult.errors);
            }
            this.setHasLoaded(true);
        }
        this.setIsLoading(false);
        return apiResult.payload;
    };

    @action
    public apiAddNearMiss = async (projectDetailsId: string) => {
        this.setIsLoading(true);
        //const model: PermitDTO = this.model.toDto();

        const request: any = {
            incident: {
                id: null,
                projectId: projectDetailsId,
                incidentnumber: null,
                isDeleted: false,
                createdByUserId: null,
                rowVersion: null,
                lastUpdatedByUserId: null,
                lastUpdatedDate: null,
            },
        };

        const retval = await this.server
            .command<IncidentAndRelatedResponseDTO>(
                () => this.Post(AppUrls.Server.Projects.Construction.Incident.Upsert, request),
                (result: IncidentAndRelatedResponseDTO) => {
                    runInAction(() => {
                        //https://localhost:44394/project/construction/3208ccdd-da01-48fc-972a-06e1cc80de6f/incident/b817d3d0-cddb-48ac-84e9-78aeaad73e98/nearmiss/add
                        this.history.push(
                            AppUrls.Client.Project.Incident.NearMiss.Add.replace(":projectid", result.incident.projectId).replace(":incidentid", result.incident.id!),
                        );
                    });
                },
                this.isModelValid,
                "There was an error trying to send the incident",
            )
            .finally(() => this.setIsLoading(false));
    };

    @action
    public apiAddScaffold = async (projectDetailsId: string) => {
        this.history.push(AppUrls.Client.Project.Scaffolding.Add.replace(":projectid", projectDetailsId));
    };

    @action
    public apiGetInspectionTypes = async (): Promise<InspectionTypesDTO[]> => {
        this.setIsLoading(true);

        let apiResult = await this.Get<any>(AppUrls.Server.Projects.GetProjectInspectionTypes);
        if (apiResult) {
            if (apiResult.wasSuccessful) {
                runInAction(() => {});
            } else {
                console.log(apiResult.errors);
            }
            this.setHasLoaded(true);
        }
        this.setIsLoading(false);
        return apiResult.payload;
    };

    public async isFieldValid(fieldName: keyof FieldType<ProjectListModel>): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
