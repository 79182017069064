import { ApiResult, FieldType, ViewModelBase, isEmptyOrWhitespace, observable } from "@shoothill/core";
import { runInAction, action, computed } from "mobx";
import moment from "moment";

import { AppUrls } from "AppUrls";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { formatDate, formatTime } from "Utils/Format";
import {
    SafetyAndRelatedResponseDTO,
    SafetyFormModel,
    SafetyPhotoFile,
    SafetyPhotosDTO,
    SafetyProjectDetailsDTO,
    SafetyRelatedResponseDTO,
    SupplierDTO,
    UpsertSafetyAndRelatedRequestDTO,
    safetyDTO,
} from "./SafetyFormModel";
import { NonConformanceListViewModel } from "../NonConformanceListViewModel";

export class SafetyFormViewModel extends ViewModelBase<SafetyFormModel> {
    public server: ServerViewModel = new ServerViewModel();

    constructor(id: string | null, projectId: string | null, nonConformanceTypeId: string | null) {
        super(new SafetyFormModel());
        this.setDecorators(SafetyFormModel);

        this.model.id = id;
        this.model.projectId = projectId!;
        this.model.nonConformanceTypeId = nonConformanceTypeId!;

        isEmptyOrWhitespace(this.model.id) ? this.loadRelated() : this.loadWithRelated();
    }

    // #region Shared

    @observable
    public safetyProjectDetails: SafetyProjectDetailsDTO | null = null;

    @computed
    public get projectDisplayName() {
        return `(${!isEmptyOrWhitespace(this.safetyProjectDetails?.projectReference) ? this.safetyProjectDetails?.projectReference : "--"} - 
                 ${!isEmptyOrWhitespace(this.safetyProjectDetails?.projectName) ? this.safetyProjectDetails?.projectName : "--"})`;
    }

    public nonConformanceTypeDisplayName = (list: NonConformanceListViewModel): string => {
        const id = isEmptyOrWhitespace(this.model.id) ? this.model.nonConformanceTypeId : this.safety?.nonConformanceTypeId;

        return list.nonConformanceTypes.find((i: any) => i.id === id)?.displayName ?? "";
    };

    // #endregion Shared

    // #region Properties

    @action
    public setSupplier = (value: SupplierDTO | null): void => {
        this.setValue("supplierId", value?.id ?? SafetyFormModel.DEFAULT_SUPPLIER_ID);
    };

    @computed
    public get supplier(): SupplierDTO | null {
        return this.model.suppliers.find((u) => u.id === this.model.supplierId) ?? null;
    }

    @computed
    public get suppliers() {
        return this.model.suppliers;
    }

    @action
    public setUser = (value: any | null): void => {
        this.setValue("userId", value?.id ?? SafetyFormModel.DEFAULT_ISSUEDTO_USER_ID);
    };

    @computed
    public get user(): any | null {
        return this.model.users.find((u) => u.id === this.model.userId) ?? null;
    }

    @computed
    public get users() {
        return this.model.users;
    }

    @action
    public setRectificationRequiredChange = (value: string) => {
        this.setValue("rectificationRequired", value === "true");
    };

    @action
    public setIsOpenChange = (value: string) => {
        this.setValue("isOpen", value === "true");
    };

    // #endregion Properties

    // #region ReadOnly Properties

    @observable
    public safety: any = null;

    @computed
    public get readOnlySupplierDisplayName(): string {
        return this.suppliers.find((i: any) => i.id === this.safety?.supplierId)?.displayName ?? "";
    }

    @computed
    public get readOnlyUserDisplayName(): string {
        return this.users.find((i: any) => i.id === this.safety?.issuedToInductionUserId || i.id === this.safety?.workerUserId)?.displayName ?? "";
    }

    @computed
    public get readOnlyLocationDisplayName(): string {
        return this.safety?.location ?? "";
    }

    @computed
    public get readOnlyDescriptionDisplayName(): string {
        return this.safety?.description ?? "";
    }

    @computed
    public get readOnlyRectificationRequired(): boolean {
        return this.safety?.rectificationRequired ?? false;
    }

    @computed
    public get readOnlyRectificationDate(): string {
        return this.safety?.rectificationDate ? formatDate(this.safety?.rectificationDate) : "";
    }

    @computed
    public get readOnlyIsOpen(): boolean {
        return this.safety?.isOpen ?? false;
    }

    @computed
    public get readOnlyRectificationDescription(): string {
        return this.safety?.rectificationDescription ?? "";
    }

    @computed
    public get readOnlyCreatedDateAndTime(): string {
        return this.safety?.createdDate ? `${formatDate(this.safety?.createdDate)} @ ${formatTime(this.safety?.createdDate)}` : "";
    }

    // #endregion ReadOnly Properties

    @observable
    public safetyPhotos = observable<SafetyPhotosDTO>([]);

    @observable
    public snackMessage = "";

    @observable
    public snackType = "";

    @observable
    public SNACKSUCCESS = "success";

    @observable
    public SNACKERROR = "error";

    @observable
    public snackbarState = false;

    @computed
    public get hasId(): boolean {
        return this.model.id !== null && this.model.id !== undefined && this.model.id !== "";
    }

    @computed
    public get getTodayDateFormatted(): string {
        return this.model.createdDate ? moment(this.model.createdDate).format("DD/MM/YYYY").toString() : moment().format("DD/MM/YYYY").toString();
    }

    @computed
    public get isFormDisabled(): boolean {
        return this.model.id !== null && this.model.id !== undefined && this.model.id !== "";
    }

    // Start Validation

    @computed
    private get validateUserId() {
        const errorMessage = this.model.validateUserId;
        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateLocation() {
        const errorMessage = this.model.validateLocation;
        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateDescription() {
        const errorMessage = this.model.validateDescription;
        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }
    //End Validation

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @action
    public setSafetyPhotosFile = (file: SafetyPhotoFile) => {
        this.model.safetyPhotos.push(file);
    };

    @action
    public setSafetyRectificationPhotosFile = (file: SafetyPhotoFile) => {
        this.model.safetyRectificationPhotos.push(file);
    };

    @action
    public deleteSafetyPhotosFile = async (index: number): Promise<void> => {
        this.model.safetyPhotos[index].isDeleted = true;
    };

    @action
    public deleteSafetyRectificationPhotosFile = async (index: number): Promise<void> => {
        this.model.safetyRectificationPhotos[index].isDeleted = true;
    };

    @action
    public reset = () => {
        this.model.reset();
        this.server.reset();
        this.safetyProjectDetails = null;
    };

    @action
    public handleCancel(projectId: string | null): void {
        this.reset();
        this.history.push(AppUrls.Client.Project.ConstructionQuality.replace(":projectid", projectId ? projectId : this.model.projectId) + "#nonconformance");
    }

    public upsert = async (): Promise<void> => {
        // APM: Review Note.
        // Shouldn't need this. The server viewmodel has and automatically sets an IsBusy flag.
        this.setIsLoading(true);

        const model: safetyDTO = this.model.toDto();

        let safetyPhotos: SafetyPhotosDTO[] = [];
        let safetyRectificationPhotos: SafetyPhotosDTO[] = [];

        // APM: Review Note.
        // Does this make sense? It implies that to upload any photos, I cannot have a safety
        // identifier. So uploads can only happen on new issues.
        if (!this.hasId) {
            this.model.safetyPhotos.forEach((safetyPhoto) => {
                const safety: SafetyPhotosDTO = {
                    safetyId: safetyPhoto.safetyId,
                    fileName: safetyPhoto.fileName,
                    photoUrl: safetyPhoto.photoUrl,
                    createdByUserId: null,
                    isDeleted: false,
                };
                safetyPhotos.push(safety);
            });
            this.model.safetyRectificationPhotos.forEach((safetyPhoto) => {
                const completeNonConformanceWorks: SafetyPhotosDTO = {
                    safetyId: safetyPhoto.safetyId,
                    fileName: safetyPhoto.fileName,
                    photoUrl: safetyPhoto.photoUrl,
                    createdByUserId: null,
                    isDeleted: false,
                };
                safetyRectificationPhotos.push(completeNonConformanceWorks);
            });
        }

        const request: UpsertSafetyAndRelatedRequestDTO = {
            safety: model,
            safetyPhotos: safetyPhotos,
            safetyRectificationPhotos: safetyRectificationPhotos,
        };

        // POST it to the server.
        return await this.server
            .command<SafetyAndRelatedResponseDTO>(
                () => this.Post(AppUrls.Server.Projects.Construction.NonConformance.Upsert, request),
                (result: SafetyAndRelatedResponseDTO) => {
                    runInAction(() => {
                        if (result) {
                            this.handleCancel(result.safety.projectId);
                        }
                    });
                },
                this.isMyModelValid,
                "There was an error trying to send the safety",
            )
            .finally(() => this.setIsLoading(false));
    };

    private isMyModelValid = async (): Promise<boolean> => {
        let isValid = true;
        if ((await this.isModelValid()) === false) {
            isValid = false;
        }

        return isValid;
    };

    public loadRelated = async (): Promise<void> => {
        this.setIsLoading(true);

        return await this.server
            .query<SafetyRelatedResponseDTO>(
                () => this.Get(`${AppUrls.Server.Projects.Construction.NonConformance.Load.Related}\\${this.model.projectId}\\${this.model.nonConformanceTypeId}`),
                (result) =>
                    runInAction(() => {
                        this.model.suppliers.replace(result.suppliers);
                        this.model.inductionUsers.replace(result.issuedToInductionUsers);
                        this.model.workerUsers.replace(result.workerUsers);
                        this.safetyProjectDetails = result.safetyProjectDetails;
                    }),
            )
            .finally(() => this.setIsLoading(false));
    };

    public loadWithRelated = async (): Promise<void> => {
        this.setIsLoading(true);

        return await this.server
            .query<any>(
                () => this.Get(`${AppUrls.Server.Projects.Construction.NonConformance.Load.WithRelatedById}\\${this.model.id}`),
                (result) =>
                    runInAction(() => {
                        this.model.suppliers.replace(result.suppliers);
                        this.model.inductionUsers.replace(result.issuedToInductionUsers);
                        this.model.workerUsers.replace(result.workerUsers);
                        this.safetyProjectDetails = result.safetyProjectDetails;
                        this.safety = result.safety;

                        result.safetyPhotos.map((el: any) => this.setSafetyPhotosFile(el));
                        result.safetyRectificationPhotos.map((el: any) => this.setSafetyRectificationPhotosFile(el));
                    }),
            )
            .finally(() => this.setIsLoading(false));
    };

    public fileUpload = async (data: any): Promise<ApiResult<any>> => {
        const formData = new FormData();
        formData.append("formFile", data.formFile);
        formData.append("fileName", data.fileName);
        const apiResult = await this.Post<any>(AppUrls.Server.File.UploadFile, formData);
        if (apiResult) {
            if (!apiResult.wasSuccessful) {
                console.log(apiResult.errors);
                runInAction(() => {
                    this.setSnackMessage("Error uploading file please try again.");
                    this.setSnackType(this.SNACKERROR);
                    this.setSnackbarState(true);
                });
            }
        }
        return apiResult;
    };

    public fileChange = async (event: any, isCompleted: boolean = false): Promise<void> => {
        if (event.target.files.length > 0) {
            let data: any = {
                fileName: event.target.files[0].name,
                formFile: event.target.files[0],
            };
            event.target.value = null;
            const apiResult = await this.fileUpload(data);
            if (apiResult && apiResult.wasSuccessful) {
                let fileToDisplay: SafetyPhotoFile = {
                    safetyId: null,
                    fileName: data.fileName,
                    photoUrl: apiResult.payload,
                    isDeleted: false,
                    createdByUserId: "",
                };
                if (isCompleted) {
                    this.setSafetyRectificationPhotosFile(fileToDisplay);
                } else {
                    this.setSafetyPhotosFile(fileToDisplay);
                }
            }
        }
    };

    public DownloadFile = async (fileUrl: string, fileName: string): Promise<void> => {
        try {
            const apiResult = await this.Post<Blob>(AppUrls.Server.File.DownloadFile, fileUrl, undefined, { responseType: "blob" });
            const response = apiResult as any;
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
        } catch (exception) {
            console.error(exception);
            this.setIsErrored(true);
        }
    };

    // #region Bolierplate

    public async isFieldValid(fieldName: keyof FieldType<SafetyFormModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);
        if (this.server.IsSubmitted) {
            switch (fieldName) {
                case "userId": {
                    const result = this.validateUserId;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "location": {
                    const result = this.validateLocation;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "description": {
                    const result = this.validateDescription;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
            }
        } else {
            errorMessage = "";
            isValid = true;
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Bolierplate
}
