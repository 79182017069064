import { Box } from "@material-ui/core";
import { DashedDivider } from "Views/PurchaseOrder/Form/Views/Dividers";
import { FormSection } from "Views/PurchaseOrder/Form/Views/Forms";
import styled from "styled-components";
import { FormHeader } from "Views/PurchaseOrder/Form/Views/FormHeaders";

const HeaderContainer = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 70px;
    padding: 10px 0;
    > div:nth-child(1) {
        margin: 0 30px;
        h1 {
            font: normal normal 600 28px/38px Open Sans;
            letter-spacing: -0.56px;
            color: #191919;
        }
    }
`;
const ProjectDetailsContainer = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;

    > div {
        font: normal normal normal 12px/16px Segoe UI;
        letter-spacing: -0.18px;
        color: #191919;
    }

    > div > span {
        font-weight: bold;
        margin-right: 10px;
    }
`;
interface FormHeaderProps {
    ProjectDetails: any;
    FormHeaderTitle: string;
    ProjectDate: string;
    CompletedBy: string;
    SafetyId?: string | null;
}

const DilapidationHeader: React.FC<FormHeaderProps> = ({ ProjectDetails, FormHeaderTitle, ProjectDate }) => {
    return (
        <>
            <DashedDivider gutterBottom={false} gutterTop={false} borderThickness={1} borderOpacity={0.5} />

            <HeaderContainer>
                <FormHeader displayName={`${FormHeaderTitle}`} />
            </HeaderContainer>

            <FormSection>
                <ProjectDetailsContainer>
                    <div>
                        <span>Date / time added:</span> {ProjectDate}
                    </div>
                    <div>
                        <span>Project reference:</span> {ProjectDetails?.projectReference}
                    </div>
                    <div>
                        <span>Project name:</span> {ProjectDetails?.projectName}
                    </div>
                    {/* {SafetyId && (
                        <div>
                            <span>Completed by:</span> {CompletedBy}
                        </div>
                    )} */}
                </ProjectDetailsContainer>
            </FormSection>
        </>
    );
};

export default DilapidationHeader;
