import { getHistory, FieldType, ViewModelBase, ApiResult } from "@shoothill/core";
import { action, computed, observable, runInAction } from "mobx";

import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { ProjectAllReportModel, IECommercialReportRequestDTO, IECommercialReportResponseDTO } from "./ProjectAllReportModel";
import { ProjectGeneralViewModel } from "Views/Project/General/ProjectGeneralViewModel";
import { StoresInstance } from "Globals/Stores/Stores";
import moment from "moment";
import { AppUrls } from "AppUrls";
import { CommercialGridViewModel } from "Views/Project/Commercial/Grid/CommercialGrid.ViewModel";
import { csvAxiosRequestConfig, exportCSV } from "Utils/Utils";

export class ProjectAllReportViewModel extends ViewModelBase<ProjectAllReportModel> {
    // #region Constructors and Disposers

    constructor() {
        super(new ProjectAllReportModel());

        this.setDecorators(ProjectAllReportViewModel);
    }

    @observable public topGridViewModel: CommercialGridViewModel = new CommercialGridViewModel();
    @observable public bottomGridViewModel: CommercialGridViewModel = new CommercialGridViewModel();

    @observable public canExportCSV: boolean = false;

    @action
    public setCanExportCSV(val: boolean) {
        this.canExportCSV = val;
    }

    @computed
    public get canDeleteIE(): boolean {
        return ProjectGeneralViewModel.Instance.isDraft && StoresInstance.Domain.AccountStore.getCanEditIE;
    }

    @computed
    public get canEditIE(): boolean {
        return StoresInstance.Domain.AccountStore.getCanEditIE;
    }

    @computed
    public get getTopDateFormatted(): string {
        return this.model.reportEndDateTop ? moment(this.model.reportEndDateTop).format("DD/MM/YYYY").toString() : "Please select a date";
    }

    @computed
    public get getBottomDateFormatted(): string {
        return this.model.reportEndDateBottom ? moment(this.model.reportEndDateBottom).format("DD/MM/YYYY").toString() : "Please select a date";
    }

    // #region Server Actions

    @action
    public runReport = async (projectId: string): Promise<ApiResult<IECommercialReportResponseDTO>> => {
        this.setIsLoading(true);
        const toDateBottom = this.model.reportEndDateBottom;
        const toDateTop = this.model.reportEndDateTop;
        const request: IECommercialReportRequestDTO = {
            projectId: projectId,
            toDateBottom: toDateBottom,
            toDateTop: toDateTop,
        };
        let apiResult = await this.Post<IECommercialReportResponseDTO>(AppUrls.Server.Report.Project.GetProjectReport, request);
        if (apiResult) {
            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.topGridViewModel = new CommercialGridViewModel();
                    this.bottomGridViewModel = new CommercialGridViewModel();
                    this.topGridViewModel.model.fromDto(apiResult.payload.projectGridTop);
                    this.bottomGridViewModel.model.fromDto(apiResult.payload.projectGridBottom);
                    this.model.fromDto(apiResult.payload);
                    this.topGridViewModel.populateInvoiceListItemViewModels({
                        invoiceItem: apiResult.payload.projectGridTop.invoiceItem,
                        invoiceStatusType: apiResult.payload.projectGridTop.invoiceStatusType,
                        projects: [],
                        suppliers: [],
                        totalCount: 0,
                    });
                    this.bottomGridViewModel.populateInvoiceListItemViewModels({
                        invoiceItem: apiResult.payload.projectGridBottom.invoiceItem,
                        invoiceStatusType: apiResult.payload.projectGridBottom.invoiceStatusType,
                        projects: [],
                        suppliers: [],
                        totalCount: 0,
                    });
                });
            } else {
                console.log(apiResult.errors);
            }
        }
        this.setIsLoading(false);
        return apiResult;
    };

    @action
    public generateAllProjectReportCSV = async (projectId: string) => {
        this.setIsLoading(true);

        // JC: Download a CSV file using a HTTP POST request.
        // Source: https://stackoverflow.com/a/55138366

        const toDateBottom = this.model.reportEndDateBottom;
        const toDateTop = this.model.reportEndDateTop;
        const request: IECommercialReportRequestDTO = {
            projectId: projectId,
            toDateBottom: toDateBottom,
            toDateTop: toDateTop,
        };

        await exportCSV(AppUrls.Server.Report.Project.GenerateProjectReportCSV, request, await this.getConfig(true, csvAxiosRequestConfig)).finally(() => this.setIsLoading(false));
    };

    public server: ServerViewModel = new ServerViewModel();

    @action
    public reset = () => {
        this.model.reset();
        this.server.reset();
    };

    @action
    public handleCancel = (): void => {
        getHistory().goBack();
    };

    // #endregion Client Actions

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<ProjectAllReportModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    // #region Snackbar

    @observable
    public snackbarState = false;

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public snackMessage = "";

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @observable
    public snackType = "";

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    @observable
    public SNACKSUCCESS = "success";

    @observable
    public SNACKERROR = "error";
    // #endregion

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
