import { DarwinModelBaseDTO, ProjectValuationFrequency } from "Globals/Models/Domain";
import * as MobX from "mobx";
import { action, computed, observable } from "mobx";
import { ModelBase, ViewModelBase } from "@shoothill/core";
import { IsNotEmpty, IsPhoneNumber, ValidateIf } from "class-validator";
//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class ProjectContractualModel extends ModelBase<ProjectContractualModel, ProjectContractualModelDTO> {
    // DarwinModelBase
    public id: string = "";
    public createdDate: string = "";

    @observable
    public clientName: string = "";
    public projectId: string = "";

    private rowVersion: string | null = null;

    private originatorId: string = "";

    @observable
    @IsNotEmpty({ message: "Contract Type is required" })
    public contractTypeId: string = "";

    @observable
    public contractValue: number = 0;

    @observable
    public valuationDate: string | null = null;

    @observable
    public valuationFrequencyId: ProjectValuationFrequency = ProjectValuationFrequency.FourteenDays;

    @observable
    public valuationFrequencyDays: number = 14;

    @observable
    public contractStartDate: string | null = null;

    @observable
    public contractCompletionDate: string | null = null;

    @observable
    public employersAgent: string = "";

    @observable
    public eaContactName: string = "";

    @observable
    public eaContactEmail: string = "";

    @observable
    @ValidateIf((o) => o.length > 0)
    @IsPhoneNumber("GB", { message: "Phone number is not valid" })
    public eaContactPhone: string = "";

    @observable
    @ValidateIf((o) => o.length > 0)
    @IsPhoneNumber("GB", { message: "Phone number is not valid" })
    public eaContactMobile: string = "";

    @observable
    public overheadPercentage: number | null = null;

    @observable
    public designPercentage: number | null = null;

    @computed get isXDays() {
        return this.valuationFrequencyId === ProjectValuationFrequency.XDays;
    }

    @action public cleanup() {
        this.id = "";
        this.createdDate = "";
        this.clientName = "";
        this.projectId = "";
        this.rowVersion = null;
        this.originatorId = "";
        this.contractTypeId = "3635c09e-177a-4114-b04e-26d5462f983f";
        this.contractValue = 0;
        this.valuationDate = null;
        this.valuationFrequencyId = 0;
        this.valuationFrequencyDays = 14;
        this.contractStartDate = null;
        this.contractCompletionDate = null;
        this.employersAgent = "";
        this.eaContactName = "";
        this.eaContactEmail = "";
        this.eaContactPhone = "";
        this.eaContactMobile = "";
        this.overheadPercentage = null;
        this.designPercentage = null;
    }

    //fromDto is required but you can leave it blank
    fromDto(model: ProjectContractualModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    if (key === "overheadPercentage" || key === "overheadPercentage") {
                        if ((model[key] === null || model[key] === undefined) && model.id !== null && model.id !== undefined && model.id !== "") {
                            this[key] = null;
                        } else {
                            this[key] = 15.0;
                        }
                    } else {
                        this[key] = model[key];
                    }
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: ProjectContractualModel): void {}

    public toUpsertDTO = (): ProjectContractualModelDTO => {
        let retVal: ProjectContractualModelDTO = {
            id: this.id,
            createdDate: this.createdDate,
            clientName: this.clientName,
            projectId: this.projectId,
            rowVersion: this.rowVersion,
            originatorId: this.originatorId,
            contractTypeId: this.contractTypeId,
            contractValue: this.contractValue,
            valuationDate: this.valuationDate,
            valuationFrequencyId: this.valuationFrequencyId,
            valuationFrequencyDays: this.valuationFrequencyDays,
            contractStartDate: this.contractStartDate,
            contractCompletionDate: this.contractCompletionDate,
            employersAgent: this.employersAgent,
            eaContactName: this.eaContactName,
            eaContactEmail: this.eaContactEmail,
            eaContactPhone: this.eaContactPhone,
            eaContactMobile: this.eaContactMobile,
            overheadPercentage: this.overheadPercentage ? this.overheadPercentage : 15.0,
            designPercentage: this.designPercentage ? this.designPercentage : 15.0,
        };

        return retVal;
    };
}

export interface ProjectContractualModelDTO extends DarwinModelBaseDTO {
    clientName: string;
    projectId: string;
    rowVersion: string | null;
    originatorId: string;
    contractTypeId: string;
    contractValue: number;
    valuationDate: string | null;
    valuationFrequencyId: number;
    valuationFrequencyDays: number;
    contractStartDate: string | null;
    contractCompletionDate: string | null;
    employersAgent: string;
    eaContactName: string;
    eaContactEmail: string;
    eaContactPhone: string;
    eaContactMobile: string;
    overheadPercentage: number;
    designPercentage: number;
}
