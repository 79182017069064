import { computed } from "mobx";
import { AccidentGenericListItemDTO, AccidentGenericListItemModel } from "./AccidentGenericListItemModel";

export class AccidentWitnessItemModel extends AccidentGenericListItemModel {
    constructor() {
        super();
    }

    fromDto(model: AccidentWitnessItemDTO): void {
        super.fromDto(model);
        this.typeId = model.witnessUserId;
    }

    @computed
    public get witnessUserId() {
        return this.typeId;
    }

    public static fromDtos(dtos: AccidentWitnessItemDTO[]): AccidentWitnessItemModel[] {
        const types: AccidentWitnessItemModel[] = [];

        for (const dto of dtos) {
            const model = new AccidentWitnessItemModel();

            model.fromDto(dto);
            types.push(model);
        }

        return types;
    }

    toDto(): AccidentWitnessItemDTO {
        const dto: AccidentGenericListItemDTO = super.toDto();
        return {
            ...dto,
            witnessUserId: this.witnessUserId,
        };
    }
}

export interface AccidentWitnessItemDTO extends AccidentGenericListItemDTO {
    witnessUserId: string | null;
}
