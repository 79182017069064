import { ModelBase } from "@shoothill/core";
import { action, observable } from "mobx";

export class RFIFormDocumentModel extends ModelBase<RFIFormDocumentModel> {
    public static readonly DEFAULT_ID = "";
    public static readonly DEFAULT_RFI_ID = "";
    public static readonly DEFAULT_RFI_CATEGORY_ID = "";
    public static readonly DEFAULT_FILENAME = "";
    public static readonly DEFAULT_FILEURL = "";
    public static readonly DEFAULT_CREATED_BY_USER_ID = null;
    public static readonly DEFAULT_IS_DELETED = false;

    @observable
    public id: string | null = RFIFormDocumentModel.DEFAULT_ID;
    @observable
    public rfiId: string = RFIFormDocumentModel.DEFAULT_RFI_ID;
    @observable
    public rfiCategoryId: string = RFIFormDocumentModel.DEFAULT_RFI_CATEGORY_ID;
    @observable
    public fileName: string = RFIFormDocumentModel.DEFAULT_FILENAME;
    @observable
    public fileURL: string = RFIFormDocumentModel.DEFAULT_FILEURL;
    @observable
    public createdByUserId: string | null = RFIFormDocumentModel.DEFAULT_CREATED_BY_USER_ID;
    @observable
    public isDeleted: boolean = RFIFormDocumentModel.DEFAULT_IS_DELETED;

    @action
    public reset = () => {
        this.id = RFIFormDocumentModel.DEFAULT_ID;
        this.rfiId = RFIFormDocumentModel.DEFAULT_RFI_ID;
        this.rfiCategoryId = RFIFormDocumentModel.DEFAULT_RFI_CATEGORY_ID;
        this.fileName = RFIFormDocumentModel.DEFAULT_FILENAME;
        this.fileURL = RFIFormDocumentModel.DEFAULT_FILEURL;
        this.createdByUserId = RFIFormDocumentModel.DEFAULT_CREATED_BY_USER_ID;
        this.isDeleted = RFIFormDocumentModel.DEFAULT_IS_DELETED;
    };

    @action
    public fromDto(dto: RFIDocumentDTO): void {
        for (let key in dto) {
            if (dto.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(dto[key]);
                } else {
                    this[key] = dto[key];
                }
            }
        }
    }

    public toDto(): RFIDocumentDTO {
        const rFIFormFileModel: RFIDocumentDTO = {
            id: this.id,
            rfiId: this.rfiId,
            rfiCategoryId: this.rfiCategoryId,
            fileName: this.fileName,
            fileURL: this.fileURL,
            createdByUserId: this.createdByUserId,
            isDeleted: this.isDeleted,
        };

        return rFIFormFileModel;
    }
}

export interface RFIDocumentDTO {
    id: string | null;
    rfiId: string | null;
    rfiCategoryId: string | null;
    fileName: string;
    fileURL: string;
    createdByUserId: string | null;
    isDeleted: boolean;
}
