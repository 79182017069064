import * as MobX from "mobx";

export class ProjectTypeModel {
    // #region Constructors and Disposers

    constructor(dto: ProjectTypeDTO | null = null) {
        if (dto) {
            this.id = dto.id;
            this.ordinal = dto.ordinal;
            this.name = dto.name;
            this.type = dto.type;
            this.color = dto.color;
            this.textColor = dto.textColor;
        }
    }

    // #endregion Constructors and Disposers

    // #region Properties

    @MobX.observable
    public id: string = "";

    @MobX.observable
    public ordinal: number = 0;

    @MobX.observable
    public name: string = "Not defined";

    @MobX.observable
    public type: string = "NOTDEFINED";

    @MobX.observable
    public color: string = "#000000";

    @MobX.observable
    public textColor: string = "#FFFFFF";

    // #endregion Properties
}

export type ProjectTypeDTO = {
    id: string;
    ordinal: number;
    name: string;
    type: string;
    color: string;
    textColor: string;
};
