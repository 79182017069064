import { Box } from "@material-ui/core";
import styled from "styled-components/macro";
import { pxToRem } from "./AppTheme";
import { SHBox } from "../../Components/Box";

export const OrderTable: any = styled(Box)`
    table {
        table-layout: auto !important;
        thead th {
            font-weight: 600;
        }
    }
`;

export const DarwinPage: any = styled(SHBox)`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
`;

export const DarwinPageTitle: any = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: ${pxToRem(26)};
`;

export const DarwinPageTitleSmall: any = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.625rem 1.625rem 1.625rem 1.625rem;
`;

type PreviewImageProps = {
    width?: number;
    height?: number;
};
export const PreviewImage = styled.img<PreviewImageProps>`
    width: ${(props) => props.width || 64}px;
    height: ${(props) => props.height || 64}px;
    border-radius: 100%;
    border: 2px solid #6c93a6;
`;

export const DarwinPageContent: any = styled(Box)`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 15px;
`;

export const DarwinTablePageContent: any = styled(Box)`
    display: flex;

    flex-direction: column;
    padding: 0 5px;
    width: 100%;

    > div {
        box-shadow: unset !important;
    }
`;

export const DarwinTableToolbar: any = styled(Box)`
    padding: 0 ${pxToRem(26)} ${pxToRem(26)} ${pxToRem(26)};
`;

export const DarwinTableSearchBox: any = styled(Box)``;

export const DarwinTableAddButtonBox: any = styled(Box)`
    display: flex;
    justify-content: end;
`;

export const DarwinTableButtonContainer = styled(Box)`
    display: flex;
    justify-content: end;

    button {
        margin-left: 10px;
        width: 280px;

        span {
            width: auto !important;
        }
    }
`;
