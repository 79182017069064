import { FieldType, ViewModelBase } from "@shoothill/core";
import { action, computed, observable } from "mobx";
import moment from "moment";

import { RequisitionPOStatus } from "Views/Approval/PurchaseListViewModel";
import { RequisitionPOStatusEnum } from "Globals/Models";
import { NotificationBarViewModel } from "Components/NotificationBar/NotificationBarViewModel";
import { ApprovalPanelModelBase } from "Globals/Models/ApprovalPanelModelBase";

/**
 * Common viewmodel for all approvals.
 * Base viewmodel used for approval panel by Requisition requests (purchase orders), variations and invoices.
 * When updating this, ensure you're not breaking any other sections that use the approval panel.
 */
export class ApprovalPanelViewModelBase<T extends ApprovalPanelModelBase<T>> extends ViewModelBase<T> {
    // #region Constructors and Disposers

    notificationBarViewModel = NotificationBarViewModel.Instance;

    constructor(type: { new (): T }) {
        super(new type());
        this.setDecorators(ApprovalPanelViewModelBase);
    }

    // #endregion Constructors and Disposers

    @observable
    public approvalHistoryItems: ApprovalHistoryItemDTO[] = [];

    @action
    public populateApprovalHistory = (dto: ApprovalHistoryItemDTO[]): void => {
        this.approvalHistoryItems = dto;
    };

    @observable
    protected requisitionPOStatuses: RequisitionPOStatus[] = [];

    @computed
    public get hasApproval(): boolean {
        return this.model.id !== "" && this.model.id !== undefined && this.model.id !== null;
    }

    @computed
    public get getIsRejectedOrApproved(): boolean {
        const isRejected = this.model.approvalStatusId === this.getRejectedStatusId;
        const isApproved = this.model.approvalStatusId === this.getApprovedStatusId;

        return isRejected || isApproved;
    }

    /**
     * Approver will see when approving.
     */
    @computed
    public get getCanShowNewApprovalPanel(): boolean {
        // True if the approver needs to perform an action on a new requisition request.
        const isSubmittedForApproval = this.model.approvalStatusId === this.getSubmittedForApprovalStatusId;
        const isDraft = this.model.approvalStatusId === this.getDraftStatusId;

        const isApprover = this.model.isApprover;
        const isNotResolved = !this.model.isResolved;

        const approvalStatusValid = isSubmittedForApproval || isDraft;

        const isMissingRequesterNotes = this.model.requesterNotes === null || this.model.requesterNotes === "";

        if (isApprover) {
            return isNotResolved && isMissingRequesterNotes && approvalStatusValid;
        }

        return false;
    }

    /**
     * Approver will see after requester has amended.
     */
    @computed
    public get getCanShowAmendedApprovalPanel(): boolean {
        // True if the approver needs to perform an action on an amended requisition request.
        const isSubmittedForApproval = this.model.approvalStatusId === this.getSubmittedForApprovalStatusId;
        const isDraft = this.model.approvalStatusId === this.getDraftStatusId;

        const isApprover = this.model.isApprover;
        const isNotResolved = !this.model.isResolved;

        const approvalStatusValid = isSubmittedForApproval || isDraft;

        const hasRequesterNotes = this.model.requesterNotes !== null && this.model.requesterNotes !== "";

        if (isApprover) {
            return isNotResolved && hasRequesterNotes && approvalStatusValid;
        }

        return false;
    }

    /**
     * Requester will see when amending.
     */
    @computed
    public get getCanShowAmenderPanel(): boolean {
        // True if the requester needs to amend a requisition request.
        const isAmendRequired = this.model.approvalStatusId === this.getAmendRequiredStatusId;

        return this.model.isRequester && isAmendRequired;
    }

    /**
     * True if the user has permission to view the panel, regardless of it's content.
     */
    @computed
    public get getCanShowPanel(): boolean {
        return this.getCanShowNewApprovalPanel || this.getCanShowAmendedApprovalPanel || this.getCanShowAmenderPanel;
    }

    @computed
    public get getTitle(): string {
        if (this.getCanShowNewApprovalPanel) {
            return "New approval";
        } else if (this.getCanShowAmendedApprovalPanel) {
            return "Amended";
        } else if (this.getCanShowAmenderPanel) {
            return "Amends required";
        } else {
            return "New approval";
        }
    }

    @computed
    public get hasRequisitionNotes(): boolean {
        return this.model.requisitionNotes !== "" && this.model.requisitionNotes !== null && this.model.requisitionNotes !== undefined;
    }

    @computed
    public get hasApproverNotes(): boolean {
        return this.model.approverNotes !== "" && this.model.approverNotes !== null && this.model.approverNotes !== undefined;
    }

    @computed
    public get hasRequesterNotes(): boolean {
        return this.model.requesterNotes !== "" && this.model.requesterNotes !== null && this.model.requesterNotes !== undefined;
    }

    @computed
    public get canShowRequisitionNotes(): boolean {
        return this.hasRequisitionNotes && !this.hasApproverNotes;
    }

    @computed
    public get canShowApproverNotes(): boolean {
        return this.hasApproverNotes;
    }

    @computed
    public get canShowRequesterNotes(): boolean {
        return this.hasRequesterNotes;
    }

    @computed
    public get getDraftStatusId(): string {
        const id = this.requisitionPOStatuses.find((r) => r.type === RequisitionPOStatusEnum.Draft)?.id;
        return id ? id : "";
    }

    @computed
    public get getApprovedStatusId(): string {
        const id = this.requisitionPOStatuses.find((r) => r.type === RequisitionPOStatusEnum.Approved)?.id;
        return id ? id : "";
    }

    @computed
    public get getAmendRequiredStatusId(): string {
        const id = this.requisitionPOStatuses.find((r) => r.type === RequisitionPOStatusEnum.AmendRequired)?.id;
        return id ? id : "";
    }

    @computed
    public get getSubmittedForApprovalStatusId(): string {
        const id = this.requisitionPOStatuses.find((r) => r.type === RequisitionPOStatusEnum.SubmittedForApproval)?.id;
        return id ? id : "";
    }

    @computed
    public get getRejectedStatusId(): string {
        const id = this.requisitionPOStatuses.find((r) => r.type === RequisitionPOStatusEnum.Rejected)?.id;
        return id ? id : "";
    }

    @action
    public populateRequisitionPOStatuses = (dto: RequisitionPOStatus[]): void => {
        this.requisitionPOStatuses = dto;
    };

    @computed
    public get getRequestedDateFormatted(): string {
        return moment(this.model.requestedDate).format("DD/MM/YY").toString();
    }

    /**
     * Determines whether the panel can be submitted.
     */
    @computed
    public get getCanUpsertStatus(): boolean {
        const isSubmittedForApproval = this.model.approvalStatusId === this.getSubmittedForApprovalStatusId;
        const isDraft = this.model.approvalStatusId === this.getDraftStatusId;

        const isApprover = this.model.isApprover;

        if (isApprover) {
            return !this.model.isResolved && (isSubmittedForApproval || isDraft);
        }

        return false;
    }

    // #region Server Actions

    // #endregion Server Actions

    // #region Client Actions

    // #endregion Client Actions

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<any>): Promise<boolean> {
        return true;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}

export interface ApprovalHistoryItemDTO {
    id: string;
    roleLevel: number;
    roleTypeName: string | null;
    roleLevelDisplayName: string | null;
    isRequired: boolean;
    approvalStatusName: string | null;
    actionedByUserName: string | null;
    actionedByDelegateUserName: string | null;
    actionedDate: string | null;
    isOverRide: boolean;
    approvalHistoryRoleUsers: ApprovalHistoryUserDTO[];
}

export interface ApprovalHistoryUserDTO {
    id: string;
    roleLevel: number;
    roleType: string | null;
    administratorUserName: string | null;
    userId: string;
}
