// Libraries
import { FormHelperText, TextField } from "@material-ui/core";
import Datetime from "react-datetime";
import { isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import { useObserver } from "mobx-react-lite";
import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "react-datetime/css/react-datetime.css";
import EventIcon from "@material-ui/icons/Event";

interface IProps {
    canExecute?: boolean;
    className?: string;
    displayName?: string;
    execute: (value: string | null) => void;
    fullWidth?: boolean;
    onBlur?: () => void;
    placeholder?: string;
    validationMessage?: string;
    value: string | null;
    minDate?: Date;
    maxDate?: Date;
    hideFormHelperText?: boolean;
    clearable?: boolean;
    format?: string;
    defaultToStartOfDay?: boolean;
}

// Updates the week to start on Monday.
moment.locale("en-GB", {
    week: {
        dow: 1,
    },
});

const DarwinDateSelectBase: React.FC<IProps> = (props: IProps) => {
    // #region Code Behind
    const { defaultToStartOfDay = true } = props;

    const getFormat = (): string => {
        return props.format ? props.format : "DD/MM/YYYY";
    };

    const [selectedDate, setSelectedDate] = useState<string | Moment | Date | undefined>(props.value ? moment(props.value) : undefined);
    const [internalString, setInternalString] = React.useState<string | null>(props.value ? moment(props.value).format(getFormat()) : null);
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        // Update the local state if the observable in the parent changes.
        if (!moment(props.value).isSame(moment(selectedDate))) {
            handleDateChange(props.value ? moment(props.value).toDate() : undefined, false);
        }
    }, [props.value]);

    /**
     * Handle changing the date when an action is performed.
     * @param date The new date.
     * @param canChangeParent Whether the date in the parent component should be changed.
     */
    const handleDateChange = (date: string | Moment | Date | undefined, canChangeParent: boolean = true): void => {
        setSelectedDate(date);
        setInternalString(date ? moment(date).format(getFormat()) : "");

        if (canChangeParent) {
            onChange(date);
        }
    };

    /**
     * Handles changing the date when the user types in the text box.
     * @param val The users input.
     */
    const handleTextChange = (val: string): void => {
        setInternalString(val);

        if (moment(val, getFormat(), true).isValid()) {
            setSelectedDate(moment(val, getFormat()));
            onChange(moment(val, getFormat()));
        }
    };

    /**
     * Handles changing the date in the parent component.
     * @param date The new date.
     */
    const onChange = (date: string | Moment | Date | undefined): void => {
        if (date !== null && date !== undefined) {
            // Determine whether to use startOf or endOf based on defaultToStartOfDay
            const momentMethod: "startOf" | "endOf" = defaultToStartOfDay && defaultToStartOfDay === true ? "startOf" : "endOf";

            // A helper function to process the date and return an ISO string or null
            const processDate = (dateInput: string | Moment | Date): string | null => {
                if (typeof dateInput === "string") {
                    // Parse the string to a moment object
                    const momentDate = moment(dateInput, true);
                    return momentDate.isValid() ? momentDate[momentMethod]("day").toISOString() : null;
                } else if (dateInput instanceof Date || moment.isMoment(dateInput)) {
                    // Convert Date or Moment to ISO string
                    return moment(dateInput)[momentMethod]("day").toISOString();
                }
                return null;
            };

            // Process the date and execute the parent component's function
            const isoString = processDate(date);
            props.execute(isoString);
        }
    };

    /**
     * Handles clearing the date.
     */
    const handleDateClear = (): void => {
        setSelectedDate(undefined);
        setInternalString("");
        props.execute(null);
    };

    const getPlaceholder = (): string => {
        return !isEmptyOrWhitespace(props.placeholder) ? props.placeholder! : "";
    };

    const getValidationMessage = (): string => {
        return !isEmptyOrWhitespace(props.validationMessage) ? props.validationMessage! : "";
    };

    const isDisabled = (): boolean => {
        return !isNullOrUndefined(props.canExecute) ? !props.canExecute : false;
    };

    const isInError = (): boolean => {
        return !isEmptyOrWhitespace(props.validationMessage);
    };

    /**
     * Handles date validation.
     * @param currentDate
     * @param selected
     * @returns True if the date is valid, false if not.
     */
    const isValidDate = (currentDate: any, selected: any): boolean => {
        if (props.minDate && moment(selected).isBefore(props.minDate, "day")) {
            return false;
        }

        if (props.maxDate && moment(selected).isAfter(props.maxDate, "day")) {
            return false;
        }

        if (props.minDate && moment(currentDate).isBefore(props.minDate, "day")) {
            return false;
        }

        if (props.maxDate && moment(currentDate).isAfter(props.maxDate, "day")) {
            return false;
        }

        return true;
    };

    /**
     * Handles setting the ate and calling the parent onBlur function when onBlur is triggered for the datepicker input.
     */
    const onBlur = (): void => {
        setSelectedDate(moment(selectedDate));

        if (moment(selectedDate, getFormat(), true).isValid()) {
            setInternalString(moment(selectedDate).format(getFormat()));
        }
        if (props.onBlur) {
            props.onBlur();
        }
    };

    /**
     * Renders the textfield/input for the datepicker.
     * @param props
     * @param openCalendar
     * @param closeCalendar
     * @returns JSX element.
     */
    const renderInput = (props: any, openCalendar: Function, closeCalendar: Function): JSX.Element => {
        return (
            <>
                <TextField
                    {...props}
                    value={internalString}
                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleTextChange(event.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </>
        );
    };

    /**
     * Renders the calender for the datepicker.
     * @param viewMode
     * @param renderCalendar
     * @returns JSX element.
     */
    const renderView = (viewMode: string, renderCalendar: Function): JSX.Element => {
        // Only for years, months and days view
        if (viewMode === "time") return renderCalendar();

        return (
            <div className="wrapper-outer" onClick={() => setOpen(false)}>
                <div
                    className="wrapper-inner"
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                >
                    <span role="button" onClick={() => setOpen(false)} className="close-button">
                        🗙
                    </span>
                    {renderCalendar()}
                    {props.clearable && !isDisabled() && (
                        <div className={"clickable"}>
                            <div onClick={() => handleDateClear()}>Clear</div>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    /**
     * Renders each day tile in the calendar for the datepicker.
     * @param props
     * @param currentDate
     * @param selectedDate
     * @returns JSX element.
     */
    const renderDay = (props: any, currentDate: any, selectedDate: any): JSX.Element => {
        return (
            <td
                {...props}
                onClick={(e) => {
                    // Don't do anything if it's disabled.
                    if (props?.className?.split(" ")?.[2] === "rdtDisabled" || props?.className?.split(" ")?.[1] === "rdtDisabled") return;

                    const day: Number = props?.["data-value"];
                    const month: Number = Number(props?.["data-month"]) + 1; // Zero-indexed...
                    const year: Number = props?.["data-year"];

                    const newDate: Moment = moment(`${day}/${month}/${year}`, getFormat());

                    handleDateChange(newDate);
                }}
            >
                {currentDate.date()}
            </td>
        );
    };

    const inputProps: React.HTMLProps<HTMLInputElement> = {
        placeholder: getPlaceholder(),
        disabled: isDisabled(),
        label: props.displayName,
        onBlur: onBlur,
    };

    // #endregion Code Behind

    return useObserver(() => (
        <>
            <DatePickerContainer>
                <div className="datepicker-input-container">
                    <Datetime
                        inputProps={inputProps}
                        value={selectedDate}
                        dateFormat={getFormat()}
                        timeFormat={false}
                        open={open}
                        className={`darwin-datepicker ${props.className}`}
                        isValidDate={isValidDate}
                        renderView={(viewMode: string, renderCalendar: Function) => renderView(viewMode, renderCalendar)}
                        renderInput={(props: any, openCalendar: Function, closeCalendar: Function) => renderInput(props, openCalendar, closeCalendar)}
                        initialValue={selectedDate}
                        renderDay={(props: any, currentDate: any, selectedDate: any) => renderDay(props, currentDate, selectedDate)}
                    />
                    <div className={`calendar-icon ${!isDisabled() ? "clickable" : ""}`}>
                        <EventIcon
                            onClick={() => {
                                if (!isDisabled()) {
                                    setOpen(true);
                                }
                            }}
                        />
                    </div>
                </div>

                {isInError() && props.hideFormHelperText !== true && <FormHelperText style={{ color: "#f44336" }}>{getValidationMessage()}</FormHelperText>}
            </DatePickerContainer>
        </>
    ));
};

export const DarwinDateSelect = styled(DarwinDateSelectBase)``;

export const DatePickerContainer: any = styled.div`
    display: flex;
    flex-direction: column;

    > label {
        position: relative !important;
        margin-bottom: 2px;
    }

    .darwin-datepicker {
        display: flex;

        > div:nth-child(1) {
            width: 100%;
        }
    }

    input {
        max-width: 100%;
    }

    .close-button {
        cursor: pointer;
    }

    .datepicker-input-container {
        position: relative;

        .calendar-icon {
            position: absolute;
            right: 4px;
            bottom: -5px; // Using bottom instead of top because of label.
        }

        .calendar-icon.clickable {
            cursor: pointer;
        }
    }

    .rdtPicker {
        background-color: transparent;
        height: 0px;
        width: 0px;
        border: none;
        box-shadow: none;
        padding: 0px;
        margin: 0px;

        .wrapper-outer {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
            z-index: 9999; /* Make sure it appears above other elements */
        }

        .wrapper-inner {
            text-align: right;
            position: absolute;
            min-width: 250px;
            margin-top: 1px;
            z-index: 99999 !important;
            background: #fff;
            border: 1px solid #f9f9f9;
            padding: 20px;
            border-radius: 8px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .clickable {
        cursor: pointer;
    }
`;
