import { ApiResult, FieldType, generateID, isNullOrUndefined, ViewModelBase } from "@shoothill/core";
import { AppUrls } from "AppUrls";
import { observable, action, runInAction, computed } from "mobx";
import { ProjectConstructionModel } from "./ProjectConstruction";
import type { ProjectConstructionModelDTO } from "./ProjectConstruction";
import validator from "validator";
import { AddressModel } from "Globals/Models/Domain";
import type { AddressModelDTO } from "Globals/Models/Domain";
import AddressViewModel from "Globals/ViewModels/AddressViewModel";
import { GenericId } from "Globals/Models";

export class ProjectConstructionViewModel extends ViewModelBase<ProjectConstructionModel> {
    private static _instance: ProjectConstructionViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable
    public projectId = "";

    @action
    public setProjectId = (val: string) => {
        this.projectId = val;
    };

    @observable
    public hasLoaded = true;

    @action
    public setHasLoaded(val: boolean) {
        this.hasLoaded = val;
    }

    // #region Constants
    NEWITEM = "DELETEME_";

    @observable public errorMessage: string = "";

    public constructor() {
        super(new ProjectConstructionModel(), false);
        this.setDecorators(ProjectConstructionModel);
    }

    @action
    public clean = () => {
        // TODO Any Cleanup Code here. e.g. if  a user or project or client etc, wipe it from the instance on page shutdown
        this.model.clearModel();
    };

    @action
    public loadModel(model: ProjectConstructionModelDTO | null) {
        if (model !== null) {
            this.model.fromDto(model);
        }
    }

    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (await this.isMyModelValid()) {
            //Do stuff here
            this.errorMessage = "Form is valid";
        } else {
            this.errorMessage = "Form is not valid";
        }
    };

    public get isContactEmailValid(): string {
        let error = "";

        const field: string | null = this.getValue<string | null>("smContactEmail");
        if (!validator.isEmail(field!)) {
            error = "Email address is invalid";
        }

        return error;
    }

    /**
     * Custom model validation function. Validates child models and its children
     * @returns True if model is valid, false if not.
     */
    public isMyModelValid = async (): Promise<boolean> => {
        let isValid = true;

        // JC: Changed forEach into for loop as the await seems to have issues with forEach.
        for (let i = 0; i < this.addressViewModels.length; i++) {
            let item = this.addressViewModels[i];

            // Validate each child item.
            if ((await item.isModelValid()) === false) {
                isValid = false;
            }
        }

        // Validate the model.
        if ((await this.isModelValid()) === false) {
            isValid = false;
        }

        return isValid;
    };

    public async isFieldValid(fieldName: keyof FieldType<ProjectConstructionModel>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        if (fieldName === "smContactEmail") {
            if (value.length > 0) {
                // then we need to ensure the value is a valid email
                errorMessage = this.isContactEmailValid;
                isValid = errorMessage === "";
            }
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    /*     public get isFirstNameValid(): string {
        let error = "";

        const field: string = this.getValue<string>("firstName");

        if (isNullOrEmpty(field)) {
            error = "First name is required";
        } else if (field!.length > 128) {
            error = "First name needs to be less than 128 characters";
        }

        return error;
    } */

    // #region Addresses

    @observable
    private addressViewModels: AddressViewModel[] = [];

    @computed
    public get getAddressViewModels(): AddressViewModel[] {
        return this.addressViewModels; //.filter((address) => address.model.isDeleted === false);
    }

    @action
    private populateAddresses() {
        // clear any addresses currently in model
        this.model.addresses = [];
        // (Re)build addresses model from addressViewModels[]
        // Exclude deleted addresses that don't exist in the database.
        this.addressViewModels.forEach((address) => {
            if (address.model.isDeleted) {
                if (address.model.id !== "") {
                    let _address = new AddressModel();
                    _address.fromDto(address.model);
                    this.model.addresses.push(_address);
                }
            } else {
                let _address = new AddressModel();
                _address.fromDto(address.model);
                this.model.addresses.push(_address);
            }
        });
    }

    @action
    public resetAddressViewModel() {
        this.addressViewModels = [];
    }

    @action
    private createAddressViewModels(): void {
        this.resetAddressViewModel();
        for (const address of this.model.addresses) {
            this.addressViewModels.push(new AddressViewModel(address));
        }
    }

    @action
    public clearAddressViewModelArray() {
        this.addressViewModels = [];
    }

    @observable
    private addressModelArr: AddressModel[] = [];

    // @computed
    // public get getAddressModelArr(): AddressModel[] {
    //     // clear array
    //     this.addressModelArr = [];

    //     // Rebuild array from AddressViewModel[]
    //     this.getAddressViewModels.forEach((address) => {
    //         this.addressModelArr.push(address.model);
    //     });

    //     // Moved deletion logic to the component so that I can utilize the index when deleting.
    //     return this.addressModelArr; //.filter((address) => address.isDeleted === false);
    // }

    @computed
    public get getAddressViewModelArr(): AddressViewModel[] {
        return this.addressViewModels.filter((a) => !a.model.isDeleted);
    }

    @action
    public getModelAddress() {
        return new AddressModel();
    }

    @action
    public getAddressById(val: string) {
        const address = this.addressViewModels.find((address) => address.model.id === val);
        if (address !== undefined) {
            return address.model;
        } else {
            return new AddressModel();
        }
    }

    @action
    public getAddressByIndex(index: number) {
        const address = this.addressViewModels[index];
        if (address !== undefined) {
            return address.model;
        } else {
            return new AddressModel();
        }
    }

    @action
    public addAddress(address: AddressModelDTO) {
        console.log(address);
        if (isNullOrUndefined(address.id) === true) {
            address.id = this.NEWITEM + generateID();
        }

        if (this.addressViewModels.length < 0) {
            address.isPrimary = true;
            console.log("first address so set to Primary");
        } else {
            address.isPrimary = false;
        }

        let _address = new AddressModel();
        _address.fromDto(address);
        this.addressViewModels.push(new AddressViewModel(_address));

        console.log("addaddress length");
        console.log(this.model.addresses.length);
    }

    @action
    public setMainAddress(val: AddressModel, indexToSet: number) {
        // Clear/reset all address primary
        this.addressViewModels.forEach((address, index) => {
            this.addressViewModels[index].model.isPrimary = false;
            if (index === indexToSet) {
                this.addressViewModels[index].model.isPrimary = true;
            }
        });
    }

    @action
    public deleteAddress(val: AddressModel, indexToDelete: number) {
        // set isDeleted on address
        this.addressViewModels.forEach((address, index) => {
            if (index === indexToDelete) {
                this.addressViewModels[index].model.isDeleted = true;
            }
        });
    }

    @observable
    public addressModel = new AddressModel();

    @action
    public setAddressModel(val: string) {
        this.addressModel = this.getAddressById(val);
    }

    /**
     * Single Address ViewModel
     */
    @observable
    public addressViewModel = new AddressViewModel(new AddressModel());

    @action
    public setAddressViewModel(val: AddressModel) {
        this.addressViewModel = new AddressViewModel(val);
    }

    // @observable
    // public addressViewModels: AddressViewModel[] = [];

    @computed
    public get returnAddresses(): AddressModel[] {
        const retVal = this.model.addresses.slice();
        return retVal.filter((a) => !a.isDeleted); //this.model.addresses.slice();
    }

    // #endregion Addresses

    @action
    public populateProject(payload: ProjectConstructionModelDTO) {
        this.model.fromDto(payload);

        // Handle Addresses
        if (payload != null) {
            this.model.addresses = [] as AddressModel[];
            payload.addresses.forEach((address) => {
                let _address = new AddressModel();
                _address.fromDto(address);
                this.model.addresses.push(_address);
            });
            this.createAddressViewModels();
        }
    }

    @action
    public apiGetbyId = async (val: string): Promise<void> => {
        const request: GenericId = {
            id: val,
        };

        let apiResult = await this.Post<ProjectConstructionModelDTO>(AppUrls.Server.Projects.Construction.GetByProjectId, request);
        if (apiResult) {
            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.populateProject(apiResult.payload);
                });
            } else {
                console.log(apiResult.errors);
            }
            this.setHasLoaded(true);
        }
    };

    @action public upsertConstruction = async (): Promise<ApiResult<ProjectConstructionModelDTO>> => {
        //let request = this.model.toUpsertDTO();
        let request = this.model;

        // Add addresses from array
        this.populateAddresses();

        request.addresses.forEach((address: any) => {
            if (address.id?.startsWith(this.NEWITEM) === true) {
                address.id = "";
            }
        });

        let apiResult = await this.Post<ProjectConstructionModelDTO>(AppUrls.Server.Projects.Construction.Upsert, request);
        if (apiResult) {
            runInAction(() => {
                if (apiResult.wasSuccessful) {
                    this.model.fromDto(apiResult.payload);
                    this.setSnackMessage("Details saved/updated");
                    this.setSnackType(this.SNACKSUCCESS);
                    this.setSnackbarState(true);
                } else {
                    this.setSnackMessage("Error saving/updating details");
                    this.setSnackType(this.SNACKERROR);
                    this.setSnackbarState(true);
                }
            });
        }
        return apiResult;
    };

    // #region Snackbar

    @observable
    public snackbarState = false;

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public snackMessage = "";

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @observable
    public snackType = "";

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    @observable
    public SNACKSUCCESS = "success";

    @observable
    public SNACKERROR = "error";
    // #endregion

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
