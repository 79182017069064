import { FieldType, ViewModelBase } from "@shoothill/core";
import { action, observable, runInAction } from "mobx";

import { Step3Model } from "./Step3Model";
import { AppUrls } from "../../../AppUrls";
import { QualificationViewModel } from "../Common/QualificationViewModel";
import { viewModelModelLink } from "../../../Utils/Utils";
import { QualificationModel } from "../Common/QualificationModel";

export class Step3ViewModel extends ViewModelBase<Step3Model> {
    @observable public qualificationViewModels: QualificationViewModel[] = [];
    @observable public allowNextStep: boolean = false;

    constructor() {
        super(new Step3Model());
    }

    public link = viewModelModelLink(this.model.qualifications, this.qualificationViewModels, QualificationViewModel);

    // #region Step1
    public nextStep = () => {
        this.setIsLoading(true);
        this.history.push(AppUrls.Client.Induction.Step4);
        setTimeout(() => {
            this.setIsLoading(false);
        }, 100);
    };
    public previousStep = () => {
        this.setIsLoading(true);
        this.history.push(AppUrls.Client.Induction.Step2);
        setTimeout(() => {
            this.setIsLoading(false);
        }, 100);
    };
    public get canExecuteNextStep() {
        let valid = true;
        for (const item of this.qualificationViewModels) {
            item.isModelValid().then((isValid: boolean) => {
                runInAction(() => {
                    if (valid) {
                        valid = isValid;
                    }
                    console.log("canExecuteNextStep", this.allowNextStep);
                });
            });
        }
        setTimeout(() => {
            runInAction(() => {
                this.allowNextStep = valid;
            });
        }, 100);
        return this.allowNextStep;
    }
    @action
    public sectionChange = (fieldName: any, value: string) => {
        this.setValue(fieldName as any, value);
    };
    // #endregion Step1
    @action
    public addOther = (name: string) => {
        let qualificationModel = new QualificationModel();

        qualificationModel.name = name;
        qualificationModel.inductionSectionType = this.model.plantEquipmentInductionSectionType!.id;

        this.model.qualifications.push(qualificationModel);
    };

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<Step3Model>, value: any): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
