import { ApiResult, FieldType, ViewModelBase, isEmptyOrWhitespace } from "@shoothill/core";
import { runInAction, observable, computed, action } from "mobx";
import { AddEditResponseModel, RFIResponseRequestDTO, UpsertAddEditResponseRequestDTO, UpsertAddEditResponseResponseDTO } from "./AddEditResponseModel";
import { AppUrls } from "AppUrls";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";

export class AddEditResponseViewModel extends ViewModelBase<AddEditResponseModel> {
    public constructor(projectId: string | null, rFIId: string | null, id: string | null) {
        super(new AddEditResponseModel(), false);
        this.setDecorators(AddEditResponseModel);
        this.model.projectId = projectId!;
        this.model.id = id!;
        this.model.rFIId = rFIId!;
        this.model.createdDate = new Date().toISOString();
        if (!isEmptyOrWhitespace(this.model.id)) {
            this.loadRFIResponse();
        }
    }

    @observable public errorMessage: string = "";

    public server: ServerViewModel = new ServerViewModel();

    @observable
    public hasReceivedDateChanged: boolean = false;

    @observable
    public status: { id: string; displayName: string } | null = null;

    @computed
    public get isFormDisabled(): boolean {
        return this.model.hasId;
    }

    @computed
    public get hasId(): boolean {
        return this.model.id !== null && this.model.id !== undefined && this.model.id !== "";
    }

    @action
    public setReceivedDate = (value: string | null): void => {
        this.setValue("receivedDate", value);
        this.hasReceivedDateChanged = true;
    };

    @action
    public handleStatusId = (item: { id: string; displayName: string }) => {
        this.status = item;
        this.setValue("statusId", item.id);
    };

    @action
    public reset = () => {
        this.model.reset();
        this.server.reset();
    };

    @action
    public cleanUp = () => {
        this.model.reset();
    };

    @action
    public loadRFIResponse = async (): Promise<void> => {
        const request: RFIResponseRequestDTO = {
            id: this.model.id,
        };
        let apiResult = await this.Post<any>(AppUrls.Server.Projects.ProjectTrackers.RFI.GetRFIResponseById, request);
        if (apiResult) {
            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.model.fromDto(apiResult.payload);
                    const data = {
                        id: apiResult.payload.statusId,
                        displayName: apiResult.payload.statusName,
                    };
                    this.handleStatusId(data);
                });
            } else {
                console.log(apiResult.errors);
            }
        }
    };

    @action
    public upsert = async (e?: any): Promise<ApiResult<UpsertAddEditResponseRequestDTO>> => {
        e?.preventDefault();

        if (await this.isMyModelValid()) {
            const model: any = this.model.toDto();
            const request: UpsertAddEditResponseRequestDTO = {
                rFIResponse: model,
            };
            let apiResult = await this.Post<UpsertAddEditResponseResponseDTO>(AppUrls.Server.Projects.ProjectTrackers.RFI.UpsertRFIResponse, request);
            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.model.fromDto(apiResult.payload.rFIResponse);
                    this.history.push(AppUrls.Client.Project.RequestForInformation.replace(":projectid", this.model.projectId));
                });
            }
            return apiResult;
        } else {
            this.errorMessage = "Form is not valid";
            return Promise.reject();
        }
    };

    private isMyModelValid = async (): Promise<boolean> => {
        let isValid = true;
        if ((await this.isModelValid()) === false) {
            isValid = false;
        }

        return isValid;
    };

    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (await this.isModelValid()) {
            this.errorMessage = "Form is valid";
        } else {
            this.errorMessage = "Form is not valid";
        }
    };

    public async isFieldValid(fieldName: keyof FieldType<AddEditResponseModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        errorMessage = "";
        isValid = true;

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
