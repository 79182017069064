import React, { useContext } from "react";

import { AppUrls } from "AppUrls";
import { Stores, StoresContext, StoresInstance } from "Globals/Stores";
import { DarwinLayout, PrivateRoute } from "Globals/Views";
import * as RouterUtil from "Utils/Routing";
import { DrawingsView } from "./Drawing";
import { OutputsView } from "./Output";
import { PlansOfWorkView } from "./PlanOfWork";
import { ApproveDrawingView } from "./Drawing/ApproveDrawing";

export const OuputTrackerRoutes: React.FC = () => {
    function getRootLayout() {
        return DarwinLayout;
    }

    const store = useContext<Stores>(StoresContext);

    return (
        <>
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.OutputTracker.PlansOfWork}
                component={PlansOfWorkView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.OutputTracker.Outputs}
                component={OutputsView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.OutputTracker.Drawings.List}
                component={DrawingsView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={[AppUrls.Client.Project.OutputTracker.Drawings.Edit, AppUrls.Client.Project.OutputTracker.Drawings.View]}
                component={ApproveDrawingView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
        </>
    );
};
