import { action, computed, observable, runInAction } from "mobx";
import { ModelBase } from "@shoothill/core";

import { VariationCategoryDTO, VariationCategoryModel } from "./CategoryGrid/VariationCategoryModel";
import { VariationNoteDTO, VariationNoteModel } from "./Notes/VariationNoteModel";
import { ApprovalPanelBaseDTO } from "Globals/Models/ApprovalPanelModelBase";
import { RequisitionPOStatus } from "Views/Approval/PurchaseListViewModel";
import { ApprovalHistoryDTO } from "Components/ApprovalHistory/ApprovalHistoryDTO";

export class VariationModel extends ModelBase<VariationModel, any> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Constants and Defaults

    public static readonly DEFAULT_ID = null;
    public static readonly DEFAULT_VARIATIONNUMBER = null;
    public static readonly DEFAULT_REVISION = null;
    public static readonly DEFAULT_IEID = null;
    public static readonly DEFAULT_EMPLOYERSAGENTEMAILADDRESS = "";
    public static readonly DEFAULT_EMPLOYERSAGENTRECIPIENT = "";
    public static readonly DEFAULT_EMAILBODYTOEMPLOYERSAGENT = "";
    public static readonly DEFAULT_ADDITIONALCONTACTEMAILADDRESS = "";
    public static readonly DEFAULT_REQUESTEDDATE = null;
    public static readonly DEFAULT_REQUESTEDBYUSER = "";
    public static readonly DEFAULT_CLIENTRESPONSEREQUIREDDATE = null;
    public static readonly DEFAULT_DESCRIPTION = "";
    public static readonly DEFAULT_VARIATIONSTATUSID = null;
    public static readonly DEFAULT_VARIATIONTYPEID = null;
    public static readonly DEFAULT_ROWVERSION = null;
    public static readonly DEFAULT_OVERHEADPERCENTAGE = 0;
    public static readonly DEFAULT_DESIGNPERCENTAGE = 0;
    public static readonly DEFAULT_VARIATIONCATEGORIES = [];
    public static readonly DEFAULT_VARIATIONNOTES = [];
    public static readonly DEFAULT_VARIATIONDOCUMENTS = [];
    public static readonly DEFAULT_CLIENTNOTE = null;
    public static readonly DEFAULT_CLIENTAPPROVALREFERENCE = null;
    public static readonly DEFAULT_COSTTOBEAGREED = false;
    public static readonly DEFAULT_PROGRAMMEIMPACT = null;
    public static readonly DEFAULT_LASTREVISIONEDBYUSERID = null;

    public static readonly DEFAULT_REQUESTERNOTE = "";
    public static readonly DEFAULT_HASDEFAULTS = false;

    // #endregion Constants and Defaults

    // #region Properties

    @observable
    public id: string | null = VariationModel.DEFAULT_ID;

    @observable
    public variationNumber: number | null = VariationModel.DEFAULT_VARIATIONNUMBER;

    @observable
    public revision: string | null = VariationModel.DEFAULT_REVISION;

    @observable
    public ieId: string | null = VariationModel.DEFAULT_IEID;

    @observable
    public requestedBy: string = VariationModel.DEFAULT_REQUESTEDBYUSER;

    @observable
    public requestedDate: string | null = VariationModel.DEFAULT_REQUESTEDDATE;

    @observable
    public clientResponseRequiredDate: string | null = VariationModel.DEFAULT_CLIENTRESPONSEREQUIREDDATE;

    @observable
    public description: string = VariationModel.DEFAULT_DESCRIPTION;

    @observable
    public employersAgentRecipient: string = VariationModel.DEFAULT_EMPLOYERSAGENTRECIPIENT;

    @observable
    public employersAgentEmailAddress: string = VariationModel.DEFAULT_EMPLOYERSAGENTEMAILADDRESS;

    @observable
    public emailBodyToEmployersAgent: string = VariationModel.DEFAULT_EMAILBODYTOEMPLOYERSAGENT;

    @observable
    public additionalContactEmailAddress: string = VariationModel.DEFAULT_ADDITIONALCONTACTEMAILADDRESS;

    @observable
    public variationStatusId: string | null = VariationModel.DEFAULT_VARIATIONSTATUSID;

    @observable
    public variationTypeId: string | null = VariationModel.DEFAULT_VARIATIONTYPEID;

    @observable
    public rowVersion: string | null = VariationModel.DEFAULT_ROWVERSION;

    @observable
    public overheadPercentage: number = VariationModel.DEFAULT_OVERHEADPERCENTAGE;

    @observable
    public designPercentage: number = VariationModel.DEFAULT_DESIGNPERCENTAGE;

    @observable
    public variationCategories: VariationCategoryModel[] = VariationModel.DEFAULT_VARIATIONCATEGORIES;

    @observable
    public variationNotes: VariationNoteModel[] = VariationModel.DEFAULT_VARIATIONNOTES;

    @observable
    public variationDocuments: VariationDocumentDTO[] = VariationModel.DEFAULT_VARIATIONDOCUMENTS;

    @observable
    public clientNote: string | null = VariationModel.DEFAULT_CLIENTNOTE;

    @observable
    public clientApprovalReference: string | null = VariationModel.DEFAULT_CLIENTAPPROVALREFERENCE;

    @observable
    public requesterNotes: string = VariationModel.DEFAULT_REQUESTERNOTE;

    @observable
    public costToBeAgreed: boolean = VariationModel.DEFAULT_COSTTOBEAGREED;

    @observable
    public programmeImpact: string | null = VariationModel.DEFAULT_PROGRAMMEIMPACT;

    @observable
    public lastRevisionedByUserId: string | null = VariationModel.DEFAULT_LASTREVISIONEDBYUSERID;

    // #endregion Properties

    // #region Actions

    @action
    public reset = () => {
        this.id = VariationModel.DEFAULT_ID;
        this.variationNumber = VariationModel.DEFAULT_VARIATIONNUMBER;
        this.revision = VariationModel.DEFAULT_REVISION;
        this.requestedDate = VariationModel.DEFAULT_REQUESTEDDATE;
        this.clientResponseRequiredDate = VariationModel.DEFAULT_CLIENTRESPONSEREQUIREDDATE;
        this.requestedBy = VariationModel.DEFAULT_REQUESTEDBYUSER;
        this.description = VariationModel.DEFAULT_DESCRIPTION;
        this.employersAgentRecipient = VariationModel.DEFAULT_EMPLOYERSAGENTRECIPIENT;
        this.employersAgentEmailAddress = VariationModel.DEFAULT_EMPLOYERSAGENTEMAILADDRESS;
        this.emailBodyToEmployersAgent = VariationModel.DEFAULT_EMAILBODYTOEMPLOYERSAGENT;
        this.additionalContactEmailAddress = VariationModel.DEFAULT_ADDITIONALCONTACTEMAILADDRESS;
        this.variationStatusId = VariationModel.DEFAULT_VARIATIONSTATUSID;
        this.variationTypeId = VariationModel.DEFAULT_VARIATIONTYPEID;
        this.rowVersion = VariationModel.DEFAULT_ROWVERSION;
        this.overheadPercentage = VariationModel.DEFAULT_OVERHEADPERCENTAGE;
        this.designPercentage = VariationModel.DEFAULT_DESIGNPERCENTAGE;
        this.variationCategories = VariationModel.DEFAULT_VARIATIONCATEGORIES;
        this.variationNotes = VariationModel.DEFAULT_VARIATIONNOTES;
        this.clientNote = VariationModel.DEFAULT_CLIENTNOTE;
        this.clientApprovalReference = VariationModel.DEFAULT_CLIENTAPPROVALREFERENCE;
        this.requesterNotes = VariationModel.DEFAULT_REQUESTERNOTE;
        this.costToBeAgreed = VariationModel.DEFAULT_COSTTOBEAGREED;
        this.programmeImpact = VariationModel.DEFAULT_PROGRAMMEIMPACT;
        this.lastRevisionedByUserId = VariationModel.DEFAULT_LASTREVISIONEDBYUSERID;
    };

    @action
    public fromDto(dto: VariationUpsertAndRelatedResponseDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        const variationDto = dto.variation;

        for (let key in variationDto) {
            if (variationDto.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(variationDto[key]);
                } else {
                    this[key] = variationDto[key];
                }
            }
        }

        // Manually process the child array otherwise we will end up with an array of dtos being stored in the array of models.
        let processedCategories: VariationCategoryModel[] = [];

        for (const category of dto.variationCategories) {
            const categoryToAdd = new VariationCategoryModel();
            categoryToAdd.fromDto(category);
            processedCategories.push(categoryToAdd);
        }

        this.variationCategories = [...this.variationCategories, ...processedCategories];

        let processedNotes: VariationNoteModel[] = [];

        for (const note of dto.variationNotes) {
            const noteToAdd = new VariationNoteModel();
            noteToAdd.fromDto(note);
            processedNotes.push(noteToAdd);
        }

        this.variationNotes = [...this.variationNotes, ...processedNotes];

        this.variationDocuments = dto.variationDocuments;
    }

    public toDto(): UpsertVariationAndRelatedRequestDTO {
        let categoriesToAdd: VariationCategoryDTO[] = [];

        this.variationCategories.forEach((c) => {
            categoriesToAdd.push(c.toDto());
        });

        let notesToAdd: VariationNoteDTO[] = [];

        this.variationNotes.forEach((n) => {
            notesToAdd.push(n.toDto());
        });

        const variation: VariationUpsertRequestDTO = {
            id: this.id,
            variationNumber: this.variationNumber,
            revision: this.revision,
            ieId: this.ieId,
            requestedDate: this.requestedDate,
            ClientResponseRequiredDate: this.clientResponseRequiredDate,
            requestedBy: this.requestedBy,
            description: this.description,
            employersAgentRecipient: this.employersAgentRecipient,
            employersAgentEmailAddress: this.employersAgentEmailAddress,
            emailBodyToEmployersAgent: this.emailBodyToEmployersAgent,
            additionalContactEmailAddress: this.additionalContactEmailAddress,
            variationStatusId: this.variationStatusId,
            variationTypeId: this.variationTypeId,
            overheadPercentage: this.overheadPercentage,
            designPercentage: this.designPercentage,
            rowVersion: this.rowVersion,
            clientNote: this.clientNote,
            clientApprovalReference: this.clientApprovalReference,
            requesterNotes: this.requesterNotes,
            costToBeAgreed: this.costToBeAgreed,
            programmeImpact: this.programmeImpact,
            lastRevisionedByUserId: this.lastRevisionedByUserId,
        };

        let documentsToAdd: VariationDocumentDTO[] = this.variationDocuments.slice();

        runInAction(() => {
            // Remove the temp ids before upserting.
            for (let i = 0; i < documentsToAdd.length; i++) {
                if (documentsToAdd[i].id !== null && documentsToAdd[i].id!.length < 10) {
                    documentsToAdd[i].id = null;
                }
            }
        });

        // Don't send deleted documents if they don't already exist on the server.
        // We still want to send deleted documents, as they may have just been deleted on the client and still need to be deleted on the server.
        const model: UpsertVariationAndRelatedRequestDTO = {
            variation: variation,
            variationCategories: categoriesToAdd,
            variationNotes: notesToAdd,
            variationDocuments: documentsToAdd.filter((d) => !(d.id === null && d.isDeleted === true)),
        };

        return model;
    }

    public toDeleteDto(): VariationDeleteRequestDTO {
        return {
            id: this.id!,
            rowVersion: this.rowVersion!,
        };
    }

    // #endregion Actions

    // #region Custom Validation

    @computed
    public get validateDescription(): string {
        // RULES
        // The description must be defined.
        return this.description === VariationModel.DEFAULT_DESCRIPTION ? "Please provide a description" : "";
    }

    @computed
    public get validateRequestedBy(): string {
        // RULES
        // The requested user must be defined.
        return this.requestedBy === VariationModel.DEFAULT_REQUESTEDBYUSER ? "Please provide a requested by" : "";
    }

    @computed
    public get validateRequestedDate(): string {
        // RULES
        // The date must be defined.
        return this.requestedDate === VariationModel.DEFAULT_REQUESTEDDATE ? "Please provide a date" : "";
    }

    @computed
    public get validateClientResponseRequiredDate(): string {
        // RULES
        // The date must be defined.
        return this.clientResponseRequiredDate === VariationModel.DEFAULT_CLIENTRESPONSEREQUIREDDATE ? "Please provide a date" : "";
    }

    @computed
    public get validateEmployersAgentRecipient(): string {
        // RULES
        // must be defined.
        return this.employersAgentRecipient === VariationModel.DEFAULT_EMPLOYERSAGENTRECIPIENT ||
            this.employersAgentRecipient === undefined ||
            this.employersAgentRecipient === null
            ? "Please provide a recipient"
            : "";
    }

    @computed
    public get validateEmployersAgentEmailAddress(): string {
        // RULES
        // Must be defined.
        return this.employersAgentEmailAddress === VariationModel.DEFAULT_EMPLOYERSAGENTEMAILADDRESS ||
            this.employersAgentEmailAddress === undefined ||
            this.employersAgentEmailAddress === null
            ? "Please provide an email address"
            : "";
    }

    @computed
    public get validateOverheadPercentage(): string {
        // RULES
        // The overhead percentage is required.
        return this.overheadPercentage === null || this.overheadPercentage === undefined || isNaN(this.overheadPercentage) ? "Please provide an Ohp %" : "";
    }

    @computed
    public get validateDesignPercentage(): string {
        // RULES
        // The design percentage is required.
        return this.designPercentage === null || this.designPercentage === undefined || isNaN(this.designPercentage) ? "Please provide a Design %" : "";
    }

    // #endregion Custom Validation
}

export interface VariationUpsertRequestDTO {
    id: string | null;
    variationNumber: number | null;
    revision: string | null;
    ieId: string | null;
    requestedDate: string | null;
    ClientResponseRequiredDate: string | null;
    requestedBy: string;
    description: string | null;
    employersAgentRecipient: string | null;
    employersAgentEmailAddress: string | null;
    emailBodyToEmployersAgent: string | null;
    additionalContactEmailAddress: string | null;
    variationStatusId: string | null;
    variationTypeId: string | null;
    overheadPercentage: number;
    designPercentage: number;
    rowVersion: string | null;
    clientNote: string | null;
    clientApprovalReference: string | null;
    requesterNotes: string | null;
    costToBeAgreed: boolean;
    programmeImpact: string | null;
    lastRevisionedByUserId: string | null;
}

export interface UpsertVariationAndRelatedRequestDTO {
    variation: VariationUpsertRequestDTO;
    variationCategories: VariationCategoryDTO[];
    variationNotes: VariationNoteDTO[];
    variationDocuments: VariationDocumentDTO[];
}

export interface VariationUpsertResponseDTO {
    id: string | null;
    variationNumber: number | null;
    revision: string | null;
    ieId: string | null;
    requestedDate: string | null;
    ClientResponseRequiredDate: string | null;
    requestedBy: string;
    description: string | null;
    employersAgentRecipient: string | null;
    employersAgentEmailAddress: string | null;
    emailBodyToEmployersAgent: string | null;
    additionalContactEmailAddress: string | null;
    variationStatusId: string | null;
    variationTypeId: string | null;
    overheadPercentage: number;
    designPercentage: number;
    rowVersion: string | null;
    clientNote: string | null;
    clientApprovalReference: string | null;
    costToBeAgreed: boolean;
    programmeImpact: string | null;
    lastRevisionedByUserId: string | null;
}
export interface VariationUpsertAndRelatedResponseDTO {
    variation: VariationUpsertResponseDTO;
    variationCategories: VariationCategoryDTO[];
    variationNotes: VariationNoteDTO[];
    variationDocuments: VariationDocumentDTO[];
}

export interface VariationRelatedResponseDTO {
    variationStatuses: VariationStatusDTO[];
    overheadPercentage: number;
    designPercentage: number;
    variationNoteTypes: VariationNoteTypeDTO[];
    variationDocumentTypes: VariationDocumentTypeDTO[];
    ieTitle: string;
    eaContactName: string;
    eaContactEmail: string;
    variationTypes: VariationTypeDTO[];
}

export interface VariationAndRelatedResponseDTO extends VariationRelatedResponseDTO {
    variation: VariationUpsertResponseDTO;
    variationCategories: VariationCategoryDTO[];
    variationNotes: VariationNoteDTO[];
    variationDocuments: VariationDocumentDTO[];
    approvalPanel: ApprovalPanelBaseDTO;
    requisitionPOStatuses: RequisitionPOStatus[];
    approvalHistory: ApprovalHistoryDTO[];
}

export interface VariationStatusDTO {
    id: string | null;
    displayName: string;
    ordinal: number;
    type: VariationStatusEnum;
    isDeleted: boolean;
    requisitionPOStatusId: string | null;
}

export interface VariationNoteTypeDTO {
    id: string | null;
    name: string;
    ordinal: number;
    type: VariationNoteTypeEnum;
    isDeleted: boolean;
}

export interface VariationTypeDTO {
    id: string | null;
    displayName: string;
    ordinal: number;
    type: VariationTypeEnum;
    isDeleted: boolean;
    createdDate: Date;
}

export enum VariationStatusEnum {
    Draft = 10,
    PendingInternalApproval = 20,
    PendingClientApproval = 30,
    RejectedInternally = 40,
    RejectedByClient = 50,
    ApprovedByClient = 60,
    RepairRequired = 70,
    Approved = 80,
    AmendRequired = 90,
}

export enum VariationNoteTypeEnum {
    Internal = 10,
    Client = 20,
    EmployersAgent = 30,
}

export interface VariationDocumentDTO {
    id: string | null;
    variationDocumentTypeId: string | null;
    fileName: string;
    url: string;
    isDeleted: boolean;
}

export interface VariationDocumentTypeDTO {
    id: string | null;
    name: string;
    ordinal: number;
    type: VariationDocumentTypeEnum;
    isDeleted: boolean;
}

export enum VariationDocumentTypeEnum {
    Internal = 10,
    Client = 20,
}

export interface VariationDeleteRequestDTO {
    id: string;
    rowVersion: string;
}

export interface VariationDeleteResponseDTO {
    id: string;
    rowVersion: string;
    name: string;
    isDeleted: boolean;
}

export enum VariationTypeEnum {
    Variation = 10,
    ClientProvisionalSum = 20,
}
