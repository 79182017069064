import styled from "styled-components";

export const ReportControlContainer: any = styled.div`
    display: flex;
    flex-direction: row;

    > div {
        max-width: 250px;
        margin-bottom: 10px;
        margin-left: 10px;
    }
`;

export const RunReportButtonContainer: any = styled.div`
    margin-top: 10px;
    margin-left: 10px;
    max-width: 300px;

    > button {
        margin-right: 20px;
    }
`;

export const ReportDatePickerContainer: any = styled.div`
    margin-left: 10px;

    > div {
        display: flex;
        flex-direction: row;

        > div {
            margin-right: 20px;
        }
    }
`;

export const InvoiceReportDatePickerContainer: any = styled.div`
    margin-left: 10px;

    > div {
        display: flex;
        flex-direction: column;

        > div {
            margin-right: 20px;
            margin-bottom: 10px;

            > div > label > div {
                max-width: 300px !important;
            }
        }
    }
`;
