import { ModelBase } from "@shoothill/core";
import { action, observable, computed } from "mobx";

export class POTypeModel extends ModelBase<POTypeModel, POTypeDTO> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Defaults and Constants

    public static readonly DEFAULT_ID = "";
    public static readonly DEFAULT_PONUMBER = 0;
    public static readonly DEFAULT_FORMATTEDPONUMBER = "";
    public static readonly DEFAULT_DESCRIPTION = "";
    public static readonly DEFAULT_SUPPLIERID = null;
    public static readonly DEFAULT_TERMSANDCONDITIONSID = null;
    public static readonly DEFAULT_PAYMENTTERMSID = null;
    public static readonly DEFAULT_PAYMENTTYPEID = null;
    public static readonly DEFAULT_PAYMENTTERMSINDAYS = 0;

    // #endregion Defaults and Constants

    // #region Observables

    @observable
    public id: string = POTypeModel.DEFAULT_ID;

    @observable
    public poNumber: number = POTypeModel.DEFAULT_PONUMBER;

    @observable
    public formattedPONumber: string = POTypeModel.DEFAULT_FORMATTEDPONUMBER;

    @observable
    public description: string = POTypeModel.DEFAULT_DESCRIPTION;

    @observable
    public supplierId: string | null = POTypeModel.DEFAULT_SUPPLIERID;

    @observable
    public paymentTermsInDays: number = POTypeModel.DEFAULT_PAYMENTTERMSINDAYS;

    @observable
    public termsAndConditionsId: string | null = POTypeModel.DEFAULT_TERMSANDCONDITIONSID;

    @observable
    public paymentTermsId: string | null = POTypeModel.DEFAULT_PAYMENTTERMSID;

    @observable
    public paymentTypeId: string | null = POTypeModel.DEFAULT_PAYMENTTYPEID;

    @observable
    public termsAndConditionsFileName: string | undefined = undefined;

    @observable
    public termsAndConditionsFileUrl: string | undefined = undefined;

    @computed
    public get displayName() {
        return this.formattedPONumber + " - " + this.description;
    }

    // #endregion Observables

    // #region Actions

    @action
    public reset(): void {
        this.id = POTypeModel.DEFAULT_ID;
        this.poNumber = POTypeModel.DEFAULT_PONUMBER;
        this.formattedPONumber = POTypeModel.DEFAULT_FORMATTEDPONUMBER;
        this.description = POTypeModel.DEFAULT_DESCRIPTION;
        this.supplierId = POTypeModel.DEFAULT_SUPPLIERID;
        this.paymentTermsInDays = POTypeModel.DEFAULT_PAYMENTTERMSINDAYS;
        this.termsAndConditionsId = POTypeModel.DEFAULT_TERMSANDCONDITIONSID;
        this.paymentTermsId = POTypeModel.DEFAULT_PAYMENTTERMSID;
        this.paymentTypeId = POTypeModel.DEFAULT_PAYMENTTYPEID;
        this.termsAndConditionsFileName = undefined;
        this.termsAndConditionsFileUrl = undefined;
    }

    @action
    public fromDto(dto: POTypeDTO): void {
        this.id = dto.id;
        this.poNumber = dto.poNumber;
        this.formattedPONumber = dto.formattedPONumber;
        this.description = dto.description;
        this.supplierId = dto.supplierId;
        this.paymentTermsInDays = dto.paymentTermsInDays;
        this.termsAndConditionsId = dto.termsAndConditionsId;
        this.paymentTermsId = dto.paymentTermsId;
        this.paymentTypeId = dto.paymentTypeId;
        this.termsAndConditionsFileName = dto.termsAndConditionsFileName;
        this.termsAndConditionsFileUrl = dto.termsAndConditionsFileUrl;
    }

    public toDto() {
        throw new Error("Method not implemented.");
    }

    // #endregion Actions

    // #region Helpers

    public static fromDtos(dtos: POTypeDTO[]): POTypeModel[] {
        const types: POTypeModel[] = [];

        for (const dto of dtos) {
            const model = new POTypeModel();

            model.fromDto(dto);
            types.push(model);
        }

        return types;
    }

    // #endregion Helpers
}

export interface POTypeDTO {
    id: string;
    poNumber: number;
    formattedPONumber: string;
    description: string;
    supplierId: string;
    paymentTermsInDays: number;
    termsAndConditionsId: string | null;
    paymentTermsId: string | null;
    paymentTypeId: string | null;
    termsAndConditionsFileName: string | undefined;
    termsAndConditionsFileUrl: string | undefined;
}
