import { FieldType, isEmptyOrWhitespace, ViewModelBase, ApiResult } from "@shoothill/core";
import { action, computed, observable, runInAction } from "mobx";

import { AppUrls } from "AppUrls";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import {
    IncidentFormNearMissAndRelatedResponseDTO,
    IncidentFormNearMissDTO,
    IncidentFormNearMissModel,
    IncidentFormNearMissProjectDetailsDTO,
    IncidentFormNearMissRelatedResponseDTO,
    IncidentUserDTO,
    UpsertIncidentFormNearMissAndRelatedRequestDTO,
} from "./IncidentFormNearMissModel";
import moment from "moment";
import { IncidentDTO } from "../IncidentModel";
import { GenericPhotoListItemDTO, GenericPhotoListItemModel } from "../Supporting/GenericPhotoListItemModel";
import { GenericFreeTextListItemDTO, GenericFreeTextListItemModel } from "../Supporting/GenericFreeTextListItemModel";
import { StoresInstance } from "../../../../../../Globals/Stores";

export class IncidentFormNearMissViewModel extends ViewModelBase<IncidentFormNearMissModel> {
    // #region Constructors and Disposers

    constructor(id: string | null, incidentId: string | null) {
        super(new IncidentFormNearMissModel());
        this.setDecorators(IncidentFormNearMissViewModel);
        this.model.id = id;
        this.model.incidentId = incidentId!;

        isEmptyOrWhitespace(this.model.id) ? this.loadRelated() : this.loadWithRelated();
    }

    // #region Properties

    @observable
    public hasLoaded: boolean = false;

    @computed
    public get getHasLoaded(): boolean {
        return this.hasLoaded;
    }

    @action
    public setHasLoaded = (val: boolean) => {
        this.hasLoaded = val;
    };

    @observable
    public isViewOnly: boolean = false;

    @computed
    public get getIsViewOnly(): boolean {
        return this.isViewOnly;
    }

    @action
    public setIsViewOnly = (val: boolean) => {
        this.isViewOnly = val;
    };

    @computed
    public get isFirstFormDisabled(): boolean {
        return this.isFormDisabled || (this.model.id !== null && this.model.id !== undefined && this.model.id !== "");
    }

    @computed
    public get isFormDisabled(): boolean {
        return this.isViewOnly || this.model.hasInvestigationEnded;
    }

    @computed
    public get canSaveForm(): boolean {
        if (!this.model.hasId) {
            return true;
        } else if (this.model.hasId && this.model.hasInvestigationStarted && !this.model.hasInvestigationEnded) {
            return true;
        } else {
            return false;
        }
    }

    @computed
    public get canStartInvestigation(): boolean {
        return this.model.hasId && !this.model.hasInvestigationStarted;
    }

    @computed
    public get getTodayDateFormatted(): string {
        return this.model.createdDate ? moment(this.model.createdDate).format("DD/MM/YYYY").toString() : moment().format("DD/MM/YYYY").toString();
    }

    @computed
    public get getInvestigationDateFormatted(): string {
        return this.model.investigationDate ? moment(this.model.investigationDate).format("DD/MM/YYYY").toString() : moment().format("DD/MM/YYYY").toString();
    }

    @computed
    public get getProjectReferenceFormatted(): string {
        if (!this.incidentProjectDetails) {
            return "";
        }

        return this.incidentProjectDetails.projectReference;
    }

    @computed
    public get getProjectName(): string {
        if (!this.incidentProjectDetails) {
            return "";
        }

        return this.incidentProjectDetails.projectName;
    }

    @observable
    public incidentUsers = observable<IncidentUserDTO>([]);

    @observable
    public investigatorUser: { id: string; displayName: string } | null = null;

    @action
    public handleSetInvestigatorUser = (item: { id: string; displayName: string }) => {
        this.investigatorUser = item;
        this.model.investigatorUserId = item.id;
    };

    public server: ServerViewModel = new ServerViewModel();

    @observable
    public incidentProjectDetails: IncidentFormNearMissProjectDetailsDTO | null = null;

    @observable
    public incident: IncidentDTO | null = null;

    @action
    public handleCancel(): void {
        this.reset();
        this.history.push(AppUrls.Client.Project.SiteTablet.Menu);
    }

    @observable
    public inductionUser: { id: string; displayName: string } | null = null;

    // #endregion Properties

    @computed
    public get getRootCauses(): GenericFreeTextListItemModel[] {
        return this.model.incidentFormNearMissRootCauses.filter((d) => !d.isDeleted);
    }

    @action
    public addRootCause = (rootCauseName: string): void => {
        let rootCauseToAdd: GenericFreeTextListItemDTO = {
            id: null,
            name: rootCauseName,
            isDeleted: false,
            createdByUserId: null,
            createdDate: null,
        };

        let model: GenericFreeTextListItemModel = new GenericFreeTextListItemModel();
        model.fromDto(rootCauseToAdd);
        this.model.incidentFormNearMissRootCauses.push(model);
    };

    @computed
    public get getPhotos(): GenericPhotoListItemModel[] {
        return this.model.incidentFormNearMissPhotos.filter((d) => !d.isDeleted);
    }

    /**
     * Handle a file being selected and process the data for upload.
     * @param event
     */
    @action
    public fileChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        if (event.target.files !== null && event.target.value !== null && event.target.files.length > 0) {
            let data: any = {
                fileName: event.target.files[0].name,
                formFile: event.target.files[0],
            };
            event.target.value = "";
            const apiResult = await this.fileUpload(data);
            if (apiResult && apiResult.wasSuccessful) {
                let fileToDisplay: GenericPhotoListItemDTO = {
                    id: null,
                    photoURL: apiResult.payload,
                    fileName: data.fileName,
                    isDeleted: false,
                    createdByUserId: null,
                    createdDate: null,
                };

                let model: GenericPhotoListItemModel = new GenericPhotoListItemModel();
                model.fromDto(fileToDisplay);
                runInAction(() => this.model.incidentFormNearMissPhotos.push(model));
            }
        }
    };

    /**
     * Upload a file to azure.
     * @param data The data of the file to be uploaded.
     * @returns apiResult.
     */
    public fileUpload = async (data: any): Promise<ApiResult<any>> => {
        const formData = new FormData();
        formData.append("formFile", data.formFile);
        formData.append("fileName", data.fileName);
        const apiResult = await this.Post<any>(AppUrls.Server.File.UploadFile, formData);
        if (apiResult) {
            if (!apiResult.wasSuccessful) {
                console.log(apiResult.errors);
                runInAction(() => {
                    this.setSnackMessage("Error uploading file please try again.");
                    this.setSnackType(this.SNACKERROR);
                    this.setSnackbarState(true);
                });
            }
        }
        return apiResult;
    };

    /**
     * Download a file that exists in azure.
     * @param fileUrl The URL of the file to be downloaded.
     * @param fileName The name of the file to be downloaded.
     */
    public DownloadFile = async (fileUrl: string, fileName: string): Promise<void> => {
        try {
            const apiResult = await this.Post<Blob>(AppUrls.Server.File.DownloadFile, fileUrl, undefined, { responseType: "blob" });
            const response = apiResult as any;
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
        } catch (exception) {
            console.error(exception);
            this.setIsErrored(true);
        }
    };

    // #region Server Actions

    //related, for empty form
    public loadRelated = async (): Promise<void> => {
        this.setIsLoading(true);
        return await this.server
            .query<IncidentFormNearMissRelatedResponseDTO>(
                () => this.Get(`${AppUrls.Server.Projects.Construction.Incident.NearMiss.Load.Related}\\${this.model.incidentId}`),
                (result) => {
                    runInAction(() => {
                        this.incident = result.incident;
                        this.incidentProjectDetails = result.incidentProjectDetails;
                    });
                },
            )
            .finally(() => {
                this.setIsLoading(false);
                this.setHasLoaded(true);
            });
    };

    /**
     * Load an existing Permit with any related data.
     */
    public loadWithRelated = async (): Promise<void> => {
        this.setIsLoading(true);
        return await this.server
            .query<IncidentFormNearMissAndRelatedResponseDTO>(
                () => this.Get(`${AppUrls.Server.Projects.Construction.Incident.NearMiss.Load.WithRelatedById}\\${this.model.id}`),
                (result: IncidentFormNearMissAndRelatedResponseDTO) => {
                    runInAction(() => {
                        this.handleSetLoadWithRelated(result);
                    });
                },
            )
            .finally(() => {
                this.setIsLoading(false);
                this.setHasLoaded(true);
            });
    };

    private handleSetLoadWithRelated = (result: IncidentFormNearMissAndRelatedResponseDTO) => {
        this.model.fromDto(result);
        this.incident = result.incident;
        this.incidentProjectDetails = result.incidentProjectDetails;
        this.incidentUsers.replace(result.incidentUsers);
        if (this.model.investigatorUserId && this.incidentUsers.length > 0) {
            const userToAssign = this.incidentUsers.find((i) => i.id === this.model.investigatorUserId);
            if (userToAssign) {
                this.handleSetInvestigatorUser(userToAssign);
            }
        }
    };

    public upsert = async (continueToInvestigation: boolean): Promise<void> => {
        this.setIsLoading(true);

        const model: IncidentFormNearMissDTO = this.model.toDto();
        const photos: GenericPhotoListItemDTO[] = this.model.incidentFormNearMissPhotos.map((p) => p.toDto());
        const rootCauses: GenericFreeTextListItemDTO[] = this.model.incidentFormNearMissRootCauses.map((r) => r.toDto());
        const projectId: string = this.incident!.projectId;
        const incidentId: string = this.incident!.id!;

        const request: UpsertIncidentFormNearMissAndRelatedRequestDTO = {
            incident: this.incident!,
            incidentFormNearMiss: model,
            incidentFormNearMissPhotos: photos,
            incidentFormNearMissRootCauses: rootCauses,
        };

        if (continueToInvestigation === true) {
            model.hasInvestigationStarted = true;
        }

        if (model.hasInvestigationStarted && model.investigationDate !== null) {
            model.hasInvestigationEnded = true;
        }

        return await this.server
            .command<IncidentFormNearMissAndRelatedResponseDTO>(
                () => this.Post(AppUrls.Server.Projects.Construction.Incident.NearMiss.Upsert, request),
                (result: IncidentFormNearMissAndRelatedResponseDTO) => {
                    runInAction(() => {
                        if (!continueToInvestigation) {
                            this.reset();
                            if (!StoresInstance.Domain.AccountStore.isSiteTablet) {
                                this.history.push(AppUrls.Client.Project.Incident.View.replace(":projectid", projectId).replace(":incidentid", incidentId));
                            } else {
                                this.history.push(AppUrls.Client.Project.SiteTablet.Menu);
                            }
                        } else {
                            this.handleSetLoadWithRelated(result);
                        }
                    });
                },
                this.isMyModelValid,
                "There was an error trying to send the near miss form",
            )
            .finally(() => this.setIsLoading(false));
    };

    /**
     * Custom model validation function. Validates child models and its children
     * @returns True if model is valid, false if not.
     */
    private isMyModelValid = async (): Promise<boolean> => {
        let isValid = true;

        if ((await this.isModelValid()) === false) {
            isValid = false;
        }

        return isValid;
    };

    @action
    public reset = () => {
        this.model.reset();
        this.server.reset();
        this.incidentUsers.length = 0;
        this.incidentProjectDetails = null;
        this.incident = null;
    };

    // #endregion Client Actions

    // #region Boilerplate

    // #region Vaildation

    public async isFieldValid(fieldName: keyof FieldType<IncidentFormNearMissModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        if (this.server.IsSubmitted) {
            switch (fieldName) {
                case "location": {
                    const result = this.validateLocation;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "whatHappened": {
                    const result = this.validateWhatHappened;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "investigatorUserId": {
                    const result = this.validateInvestigatorUserId;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "whichPartOfWorkProcess": {
                    const result = this.validateWhichPartOfWorkProcess;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "descriptionOfEnvironment": {
                    const result = this.validateDescriptionOfEnvironment;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "descriptionOfEquipment": {
                    const result = this.validateDescriptionOfEquipment;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "ppeRequired": {
                    const result = this.validatePpeRequired;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "ppeWorn": {
                    const result = this.validatePpeWorn;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "defectiveWorkplace": {
                    const result = this.validateDefectiveWorkplace;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "lighting": {
                    const result = this.validateLighting;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "designOrLayout": {
                    const result = this.validateDesignOrLayout;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "noiseOrDistraction": {
                    const result = this.validateNoiseOrDistraction;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "housekeeping": {
                    const result = this.validateHousekeeping;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "weather": {
                    const result = this.validateWeather;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "lackOfRoom": {
                    const result = this.validateLackOfRoom;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "accessOrEgress": {
                    const result = this.validateAccessOrEgress;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "failureToFollowRules": {
                    const result = this.validateFailureToFollowRules;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "lackOfExperience": {
                    const result = this.validateLackOfExperience;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "failureToAdhereToRiskAssessment": {
                    const result = this.validateFailureToAdhereToRiskAssessment;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "fatigue": {
                    const result = this.validateFatigue;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "instructionsMisunderstood": {
                    const result = this.validateInstructionsMisunderstood;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "unsafeAttitude": {
                    const result = this.validateUnsafeAttitude;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "horseplay": {
                    const result = this.validateHorseplay;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "workingWithoutAuthorisation": {
                    const result = this.validateWorkingWithoutAuthorisation;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "errorOfJudgement": {
                    const result = this.validateErrorOfJudgement;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "undueHaste": {
                    const result = this.validateUndueHaste;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "lapseOfConcentration": {
                    const result = this.validateLapseOfConcentration;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "design": {
                    const result = this.validateDesign;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "poorlyMaintainedOrDefective": {
                    const result = this.validatePoorlyMaintainedOrDefective;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "notUsed": {
                    const result = this.validateNotUsed;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "wrongTypeUsed": {
                    const result = this.validateWrongTypeUsed;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "notProvidedOrUnavailable": {
                    const result = this.validateNotProvidedOrUnavailable;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "nonCommunicationOfRisk": {
                    const result = this.validateNonCommunicationOfRisk;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "supervision": {
                    const result = this.validateSupervision;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "training": {
                    const result = this.validateTraining;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "systemFailure": {
                    const result = this.validateSystemFailure;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "constructionOrDesign": {
                    const result = this.validateConstructionOrDesign;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "safetyDevice": {
                    const result = this.validateSafetyDevice;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "mechanicalFailure": {
                    const result = this.validateMechanicalFailure;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "installation": {
                    const result = this.validateInstallation;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "operationOrUse": {
                    const result = this.validateOperationOrUse;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "thirdParty": {
                    const result = this.validateThirdParty;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "underInvestigation": {
                    const result = this.validateUnderInvestigation;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "actionsToRecover": {
                    const result = this.validateActionsToRecover;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "actionsToPrevent": {
                    const result = this.validateActionsToPrevent;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
            }
        } else {
            // Do not validate if the properties of the model have not been
            // submitted to the server.
            errorMessage = "";
            isValid = true;
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    @computed
    private get validateLocation() {
        const errorMessage = this.model.validateLocation;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateWhatHappened() {
        const errorMessage = this.model.validateWhatHappened;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateInvestigatorUserId() {
        const errorMessage = this.model.validateInvestigatorUserId;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateWhichPartOfWorkProcess() {
        const errorMessage = this.model.validateWhichPartOfWorkProcess;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateDescriptionOfEnvironment() {
        const errorMessage = this.model.validateDescriptionOfEnvironment;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateDescriptionOfEquipment() {
        const errorMessage = this.model.validateDescriptionOfEquipment;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validatePpeRequired() {
        const errorMessage = this.model.validatePpeRequired;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validatePpeWorn() {
        const errorMessage = this.model.validatePpeWorn;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateDefectiveWorkplace() {
        const errorMessage = this.model.validateDefectiveWorkplace;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateLighting() {
        const errorMessage = this.model.validateLighting;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateDesignOrLayout() {
        const errorMessage = this.model.validateDesignOrLayout;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateNoiseOrDistraction() {
        const errorMessage = this.model.validateNoiseOrDistraction;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateHousekeeping() {
        const errorMessage = this.model.validateHousekeeping;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateWeather() {
        const errorMessage = this.model.validateWeather;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateLackOfRoom() {
        const errorMessage = this.model.validateLackOfRoom;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateAccessOrEgress() {
        const errorMessage = this.model.validateAccessOrEgress;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateFailureToFollowRules() {
        const errorMessage = this.model.validateFailureToFollowRules;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateLackOfExperience() {
        const errorMessage = this.model.validateLackOfExperience;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateFailureToAdhereToRiskAssessment() {
        const errorMessage = this.model.validateFailureToAdhereToRiskAssessment;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateFatigue() {
        const errorMessage = this.model.validateFatigue;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateInstructionsMisunderstood() {
        const errorMessage = this.model.validateInstructionsMisunderstood;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateUnsafeAttitude() {
        const errorMessage = this.model.validateUnsafeAttitude;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateHorseplay() {
        const errorMessage = this.model.validateHorseplay;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateWorkingWithoutAuthorisation() {
        const errorMessage = this.model.validateWorkingWithoutAuthorisation;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateErrorOfJudgement() {
        const errorMessage = this.model.validateErrorOfJudgement;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateUndueHaste() {
        const errorMessage = this.model.validateUndueHaste;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateLapseOfConcentration() {
        const errorMessage = this.model.validateLapseOfConcentration;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateDesign() {
        const errorMessage = this.model.validateDesign;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validatePoorlyMaintainedOrDefective() {
        const errorMessage = this.model.validatePoorlyMaintainedOrDefective;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateNotUsed() {
        const errorMessage = this.model.validateNotUsed;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateWrongTypeUsed() {
        const errorMessage = this.model.validateWrongTypeUsed;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateNotProvidedOrUnavailable() {
        const errorMessage = this.model.validateNotProvidedOrUnavailable;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateNonCommunicationOfRisk() {
        const errorMessage = this.model.validateNonCommunicationOfRisk;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateSupervision() {
        const errorMessage = this.model.validateSupervision;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateTraining() {
        const errorMessage = this.model.validateTraining;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateSystemFailure() {
        const errorMessage = this.model.validateSystemFailure;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateConstructionOrDesign() {
        const errorMessage = this.model.validateConstructionOrDesign;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateSafetyDevice() {
        const errorMessage = this.model.validateSafetyDevice;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateMechanicalFailure() {
        const errorMessage = this.model.validateMechanicalFailure;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateInstallation() {
        const errorMessage = this.model.validateInstallation;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateOperationOrUse() {
        const errorMessage = this.model.validateOperationOrUse;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateThirdParty() {
        const errorMessage = this.model.validateThirdParty;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateUnderInvestigation() {
        const errorMessage = this.model.validateUnderInvestigation;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateActionsToRecover() {
        const errorMessage = this.model.validateActionsToRecover;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateActionsToPrevent() {
        const errorMessage = this.model.validateActionsToPrevent;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    // #endregion

    // #region Snackbar

    @observable
    public snackbarState = false;

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public snackMessage = "";

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @observable
    public snackType = "";

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    @observable
    public SNACKSUCCESS = "success";

    @observable
    public SNACKERROR = "error";
    // #endregion

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}

enum PermitDocumentTypeEnum {
    Internal = 10,
    Client = 20,
}
