import { FieldType, KeyValuePair, observable, ViewModelBase } from "@shoothill/core";
import { action } from "mobx";
import { InvoiceStatusDTO } from "Views/Invoice/Form/Details/InvoiceDetailsModel";
import { InvoicingListFilterParamsModel } from "./InvoicingListFilterParamsModel";
import { InvoicingStatusTypeDTO } from "./InvoicingStatusTypeModel";
import { GenericIdWithDisplayNameDTO } from "./InvoicingListItemsAndRelatedDTO";

export class InvoicingListFilterViewModel extends ViewModelBase<InvoicingListFilterParamsModel> {
    private static _intsance: InvoicingListFilterViewModel;
    public static get Instance() {
        return this._intsance || (this._intsance = new this());
    }

    constructor() {
        super(new InvoicingListFilterParamsModel(), false);
        this.setDecorators(InvoicingListFilterParamsModel);
    }

    @observable
    public readonly statusOptions: KeyValuePair[] = [];

    @observable
    public readonly projectOptions: KeyValuePair[] = [];

    @observable
    public readonly supplierOptions: KeyValuePair[] = [];

    public getStatusOptions = (includeUnKnown?: boolean): KeyValuePair[] => {
        return this.statusOptions === undefined ? [] : this.statusOptions.slice();
    };

    @action
    public setStatuses(statusOptions: InvoicingStatusTypeDTO[], resetFilters: boolean) {
        if (this.statusOptions !== null && this.statusOptions.length === 0) {
            if (statusOptions.length > 0) {
                this.statusOptions.push(
                    ...statusOptions.map((item: InvoicingStatusTypeDTO) => {
                        return { key: item.displayName, value: item.id };
                    }),
                );
            }
        }
    }

    public getProjectOptions = (includeUnKnown?: boolean): KeyValuePair[] => {
        return this.projectOptions === undefined ? [] : this.projectOptions.slice();
    };

    @action
    public setProjects(projectOptions: GenericIdWithDisplayNameDTO[], resetFilters: boolean) {
        if (this.projectOptions !== null && this.projectOptions.length === 0) {
            if (projectOptions.length > 0) {
                this.projectOptions.push(
                    ...projectOptions.map((item: GenericIdWithDisplayNameDTO) => {
                        return { key: item.displayName, value: item.id };
                    }),
                );
            }
        }
    }

    public getSupplierOptions = (includeUnKnown?: boolean): KeyValuePair[] => {
        return this.supplierOptions === undefined ? [] : this.supplierOptions.slice();
    };

    @action
    public setSuppliers(supplierOptions: GenericIdWithDisplayNameDTO[], resetFilters: boolean) {
        if (this.supplierOptions !== null && this.supplierOptions.length === 0) {
            if (supplierOptions.length > 0) {
                this.supplierOptions.push(
                    ...supplierOptions.map((item: GenericIdWithDisplayNameDTO) => {
                        return { key: item.displayName, value: item.id };
                    }),
                );
            }
        }
    }

    @action
    public handleSorting(sortCol: string, sortDir: string) {
        this.model.sortBy = sortCol;
        this.model.sortDirection = sortDir;
    }

    @action
    public handlePageChange(page: number, pageSize: number) {
        this.model.pageNumber = page;
        this.model.pageSize = pageSize;
    }

    @action
    public handleRowsPerPageChange(pageSize: number) {
        this.model.pageSize = pageSize;
    }

    @action
    public setTotalCount = (val: number) => {
        this.model.totalCount = val;
    };

    @action
    public setPageNumber = (val: number) => {
        this.model.pageNumber = val;
    };

    public async isFieldValid(fieldName: keyof FieldType<InvoicingListFilterParamsModel>): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
