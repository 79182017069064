import { observer } from "mobx-react-lite";
import React from "react";

import { CalculatedAdjustedStockViewModel } from "./CalculatedAdjustedStockViewModel";
import { AdjustmentCell, AdjustmentCellLabel, AdjustmentTable } from "./CalculatedAdjustedStockView.styles";

interface IProps {
    viewModel: CalculatedAdjustedStockViewModel;
}

export const CalculatedAdjustedStockView: React.FC<IProps> = observer((props) => {
    const viewModel = props.viewModel;

    return (
        <AdjustmentTable grid dc={"1fr 1fr 1fr"} gap={"0px"}>
            <AdjustmentCell></AdjustmentCell>
            <AdjustmentCellLabel>Stock quantity</AdjustmentCellLabel>
            <AdjustmentCellLabel>Stock value</AdjustmentCellLabel>
            <AdjustmentCellLabel>Previous stock</AdjustmentCellLabel>
            <AdjustmentCell>{viewModel.previousStockUnitsFormatted}</AdjustmentCell>
            <AdjustmentCell>{viewModel.previousStockValueFormatted}</AdjustmentCell>
            <AdjustmentCellLabel>Adjustment</AdjustmentCellLabel>
            <AdjustmentCell $value={viewModel.model.adjustedUnits}>{viewModel.adjustmentStockUnitsFormatted}</AdjustmentCell>
            <AdjustmentCell $value={viewModel.model.adjustedUnits}>{viewModel.adjustmentStockValueFormatted}</AdjustmentCell>
            <AdjustmentCellLabel>New stock</AdjustmentCellLabel>
            <AdjustmentCell>{viewModel.newStockUnitsFormatted}</AdjustmentCell>
            <AdjustmentCell>{viewModel.newStockValueFormatted}</AdjustmentCell>
        </AdjustmentTable>
    );
});
