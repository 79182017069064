import { observable, computed, action } from "mobx";
import type { IObservableArray } from "mobx";
import { isNullOrUndefined, ModelBase, sortByString } from "@shoothill/core";
import { IsNotEmpty } from "class-validator";
import { ClientContactModel, ClientContactModelDTO } from "./ClientContactModel";
import { AddressModel, ContactBaseDTO } from "Globals/Models/Domain";
import type { AddressModelDTO } from "Globals/Models/Domain";
import { AddEditContactModel } from "Views/Contacts/AddEditContactModel";
import type { ClientDetailModelDTO } from "./ClientDetailModel";
import { DefaultClientDetailModelDTO } from "./ClientDetailModel";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class ClientAddEditModel extends ModelBase<ClientAddEditModel, ClientAddEditModelDTO> {
    public id: string = "";
    @observable
    @IsNotEmpty({ message: "You must give a client reference!!" })
    public reference: string = "";
    @observable
    @IsNotEmpty({ message: "You must give a client name!!" })
    public name: string = "";
    @observable public isDeleted: boolean = false;
    public createdDate: string | undefined = undefined;
    @observable public rowVersion: string | undefined = undefined;
    @observable public originatorId: string | undefined = undefined;
    @observable public originatorName: string = "";

    @observable public addresses: IObservableArray<AddressModel> = observable([]);
    @observable public contacts: IObservableArray<ClientContactModelDTO> = observable([]);

    @computed
    public get getContacts(): ClientContactModelDTO[] {
        return this.contacts.slice().filter((a) => a.isDeleted === false);
    }

    @computed get getAddresses(): AddressModel[] {
        return this.addresses.slice();
    }

    @action
    public addContact(contact: AddEditContactModel) {
        const retVal = contact.toBaseContactDto(this.id);
        this.contacts.push(retVal);
    }

    @action
    public deleteContact(contactId: string) {
        const item: ClientContactModelDTO | undefined = this.contacts.slice().find((a) => a.id === contactId);
        if (isNullOrUndefined(item) === false) {
            this.contacts.remove(item!);
        }
    }

    @action
    public updatContactIsPrimary(contactId: string, isPrimary: boolean) {
        this.contacts.forEach((contact) => {
            contact.isPrimary = false;
        });

        const item: ClientContactModelDTO | undefined = this.contacts.slice().find((a) => a.id === contactId);
        if (isNullOrUndefined(item) === false) {
            item!.isPrimary = isPrimary;
        }
    }

    @action
    public setAddress(index: number, address: AddressModelDTO) {
        let newArray: AddressModel[] = [];

        // Currently a client will only have one address assigned to them.

        // if (address.id !== undefined) {
        //     newArray = this.addresses.slice().filter((a) => a.id !== address.id);
        //     const changedModel: AddressModel = new AddressModel();
        //     changedModel.fromDto(address);
        //     newArray.push(changedModel);

        //     newArray.sort((a: AddressModel, b: AddressModel) => {
        //         return sortByString(a.addressLine1, b.addressLine1);
        //     });
        // }

        const changedModel: AddressModel = new AddressModel();
        changedModel.fromDto(address);
        newArray.push(changedModel);

        this.addresses.replace(newArray);
    }

    @computed
    public get getAddress(): AddressModel {
        if (this.addresses.length > 0) {
            /*             const changedModel: AddressModel = new AddressModel();
            changedModel.fromDto(this.addresses[0]);
            return changedModel; */
            return this.addresses[0];
        } else {
            /*             const retVal: AddressModel = new AddressModel();
            retVal.fromDto(DefaultAddressModelDTO);
            return retVal; */

            return new AddressModel();
        }
    }

    @action
    public reset() {
        this.id = "";
        this.reference = "";
        this.name = "";
        this.isDeleted = false;
        this.createdDate = undefined;
        this.rowVersion = undefined;
        this.originatorId = undefined;
        this.originatorName = "";

        this.addresses.clear();
        this.contacts.clear();
    }

    //fromDto is required but you can leave it blank
    fromDto(model: ClientAddEditModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: ClientAddEditModel): void {}

    @action
    fromClientWithRelatedDto(model: ClientDetailModelDTO) {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (key === "addresses") {
                    this.addresses.replace([]);
                    model[key].forEach((address: AddressModelDTO) => {
                        const changedModel: AddressModel = new AddressModel();
                        changedModel.fromDto(address);
                        this.addresses.push(changedModel);
                    });
                } else if (key === "contacts") {
                    this.contacts.replace([]);
                    model[key].forEach((contact: ClientContactModelDTO) => {
                        const changedModel: AddEditContactModel = new AddEditContactModel();
                        changedModel.fromClientContactDto(contact);
                        this.contacts.push(contact);
                    });
                } else if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    toClientDetailModelDto(): ClientDetailModelDTO {
        let retVal: ClientDetailModelDTO = DefaultClientDetailModelDTO;
        for (let key in retVal) {
            if (retVal.hasOwnProperty(key)) {
                if (key === "addresses") {
                    retVal.addresses = [];
                    this[key].forEach((address: AddressModel) => {
                        const changedModel: AddressModelDTO = address.toAddressDto();
                        retVal.addresses.push(changedModel);
                    });
                } else if (retVal[key] instanceof Date) {
                    retVal[key] = new Date(this[key]);
                } else {
                    retVal[key] = this[key];
                }
            }
        }
        return retVal;
    }
}

export type ClientAddEditModelDTO = {
    id: string | undefined;
    reference: string;
    name: string;
    isDeleted: boolean;
    createdDate: string | undefined;
    rowVersion: string | undefined;
    originatorId: string | undefined;
    originatorName: string;

    addresses: AddressModelDTO[];
    contacts: ClientContactModelDTO[];
};
