import { isEmptyOrWhitespace } from "@shoothill/core";
import { observer } from "mobx-react-lite";
import React from "react";

import { FileViewModel } from "./FileViewModel";
import { ClearText } from "../Shared/ClearText";
import { FileRoot } from "../Shared/Files.styles";

interface IProps {
    className?: string;
    viewModel: FileViewModel;
}

export const File: React.FC<IProps> = observer((props) => {
    // #region Code Behind

    /**
     * Display a link, but only if it is not from a local file, which would be
     * the case if the url starts with data: or blob:.
     */
    const canDisplayLink = (): boolean => {
        return !isEmptyOrWhitespace(props.viewModel.model.fileUrl) && !(props.viewModel.model.fileUrl.startsWith("data") || props.viewModel.model.fileUrl.startsWith("blob"));
    };

    // #endregion Code Behind

    return (
        <FileRoot>
            <div className="fileinformation">
                <div className="image-container">
                    {(() => {
                        switch (true) {
                            case props.viewModel.model.isImage:
                                return <img src={props.viewModel.availableThumbnailFileUrl} />;

                            case props.viewModel.model.isVideo:
                                return <video src={props.viewModel.availableThumbnailFileUrl} />;
                        }
                    })()}
                </div>
                <div className="information-container">
                    {canDisplayLink() ? (
                        <a className="documenturl" href={props.viewModel.model.fileUrl} target="_blank">
                            {props.viewModel.displayName}
                        </a>
                    ) : (
                        <p>{props.viewModel.displayName}</p>
                    )}
                    <p className="filesizebytes">{props.viewModel.fileSizeBytes}</p>
                </div>
            </div>
            {props.viewModel.canRemove && <ClearText action={props.viewModel.remove} canAct={props.viewModel.canRemove} />}
        </FileRoot>
    );
});
