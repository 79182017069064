import { FieldType, isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import type { ValidationResponse } from "@shoothill/core";
import { action, computed } from "mobx";
import { formatCurrencyFromPounds } from "Utils/Format";
import { InvoiceProjectModel } from "./InvoiceProjectModel";

export class InvoiceProjectViewModel extends ViewModelBase<InvoiceProjectModel> {
    // #region Constructors and Disposers

    constructor(model: InvoiceProjectModel) {
        super(model);
        this.setDecorators(InvoiceProjectViewModel);
    }

    @computed
    public get invoiceValueNumber(): number {
        return this.model.invoiceValue ? Number(this.model.invoiceValue) : 0;
    }

    @computed
    public get projectDescriptionFormatted(): string {
        return `${this.model.projectReference} - ${this.model.projectName}`;
    }

    @action
    public reset = () => {
        this.model.reset();
    };

    // #endregion Client Actions

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<InvoiceProjectModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        switch (fieldName) {
            case "invoiceValue": {
                const result = this.validateInvoiceValue;

                errorMessage = result.errorMessage;
                isValid = result.isValid;
                break;
            }
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    @computed
    private get validateInvoiceValue(): ValidationResponse {
        const errorMessage = this.model.validateInvoiceValue;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
