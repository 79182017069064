import { Button, Typography } from "@material-ui/core";
import * as React from "react";
import { UserAddModalDialogPaper } from "./UserAddModal.styles";
import { CancelButton } from "Globals/Styles/Control/Buttons";
import { UserAddEditForm } from "./UserAddEditForm";
import UserViewModel from "Globals/ViewModels/UserViewModel";
import { BaseModal } from "Components/Modal/BaseModal";
import { Main } from "./UserAddEdit.style";
import { Observer } from "mobx-react-lite";
import { StoresInstance } from "Globals/Stores";
import RoleViewModel from "Globals/ViewModels/RoleViewModel";
import { RoleDTO } from "Globals/Models";
import { ApiResult } from "@shoothill/core";

interface IProps {
    open?: boolean;
    onClose(): void;
    onUpsert?: (id: string) => void;
    title: string;
    confirmText: string;
}

export const UserAddEditModal: React.FC<IProps> = ({ open, onClose, onUpsert, title, confirmText }) => {
    const [viewModel, setViewModel] = React.useState<UserViewModel | null>(null);
    const [errorMessage, setErrorMessage] = React.useState<string>("");
    const roleViewModel = new RoleViewModel();

    React.useEffect(() => {}, []);

    React.useEffect(() => {
        const response = roleViewModel.loadRolesAsync().then((apiResult: ApiResult<RoleDTO[]>) => {
            if (apiResult.wasSuccessful) {
            }
        });

        if (open) {
            setViewModel(UserViewModel.Instance);
            if (!UserViewModel.Instance?.projectListModels || UserViewModel.Instance?.projectListModels.length === 0) {
                UserViewModel.Instance?.apiGetAllAndRelated();
            }
        }
    }, [open]);

    const handleSubmit = () => {
        if (viewModel) {
            viewModel.isEmailInUse(viewModel.get("id"), viewModel.get("emailAddress")).then((result: boolean) => {
                if (result === true) {
                    setErrorMessage("Please fix the validation errors and re-submit.");
                } else {
                    setErrorMessage("");
                    viewModel.doSubmit(undefined, false).then((apiResult) => {
                        if (apiResult && apiResult.wasSuccessful) {
                            if (onUpsert) {
                                onUpsert(apiResult.payload.id);
                            }
                            onClose();
                        }
                    });
                }
            });
        }
    };

    return (
        <BaseModal
            open={open}
            onClose={onClose}
            title={title}
            PaperComponent={UserAddModalDialogPaper}
            actions={
                <Observer>
                    {() => (
                        <>
                            <Button onClick={handleSubmit} color="secondary" variant="contained" disabled={viewModel?.IsLoading}>
                                {viewModel?.IsLoading ? "Saving..." : confirmText}
                            </Button>
                            <CancelButton onClick={onClose} disabled={viewModel?.IsLoading}>
                                Cancel
                            </CancelButton>
                        </>
                    )}
                </Observer>
            }
        >
            {viewModel !== null && (
                <Main>
                    {errorMessage !== "" && (
                        <Typography variant="caption" style={{ color: "red" }}>
                            {errorMessage}
                        </Typography>
                    )}
                    <UserAddEditForm viewModel={viewModel!} roles={StoresInstance.Domain.RoleStore.getRoles} />
                </Main>
            )}
        </BaseModal>
    );
};
