import { ViewModelBase, isEmptyOrWhitespace } from "@shoothill/core";
import { computed } from "mobx";

import { FileModel } from "./FileModel";

export class FileViewModel extends ViewModelBase<FileModel> {
    private parentRemoveFileModel: (model: FileModel) => void;

    constructor(fileModel: FileModel = new FileModel(), removeFileModel: (model: FileModel) => void) {
        super(fileModel);

        this.parentRemoveFileModel = removeFileModel;
    }

    // #region Properties

    public get KEY() {
        return this.model.KEY;
    }

    @computed
    public get displayName() {
        return this.model.fileName;
    }

    public get fileSizeBytes() {
        const fileSizeKiloBytes = new Intl.NumberFormat("en-GB", { maximumSignificantDigits: 3 }).format(this.model.fileSizeBytes / 1024);

        return `${fileSizeKiloBytes} KB`;
    }

    @computed
    public get fileUrl() {
        return this.model.fileUrl;
    }

    public get thumbnailFileUrl() {
        return this.model.thumbnailFileUrl;
    }

    public get availableThumbnailFileUrl() {
        switch (true) {
            case !isEmptyOrWhitespace(this.model.thumbnailFileUrl):
                return this.model.thumbnailFileUrl!;

            case !isEmptyOrWhitespace(this.model.fileUrl):
                return this.model.fileUrl!;

            default:
                return undefined;
        }
    }

    // #endregion Properties

    // #region Commands

    public remove = () => {
        this.parentRemoveFileModel(this.model);
    };

    @computed
    public get canRemove() {
        return true;
    }

    // #endregion Commands

    isFieldValid(
        fieldName: "id" | "KEY" | "file" | "fileName" | "fileSizeBytes" | "fileUrl" | "thumbnailFileUrl" | "mimeType" | "isImage" | "isVideo" | "copy",
        value: any,
    ): Promise<boolean> {
        throw new Error("Method not implemented.");
    }
    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
