import { ModelBase, observable } from "@shoothill/core";

export class RAMSReviewListModel extends ModelBase<RAMSReviewListModel> {
    public id: string = "";

    @observable
    public title: string = "";
    @observable
    public subContractor: string = "";
    @observable
    public supervisorUserName: string = "";
    @observable
    public reviewedByUserName: string = "";
    @observable
    public completedByUserName: string = "";
    @observable
    public lastUpdatedDate: string = "";
    @observable
    public lastUpdatedByUserName: string = "";
    @observable
    public createdDate: string = "";
    @observable
    public isApproved: boolean = false;

    constructor() {
        super();
    }

    fromDto(model: RAMSReviewListDTO): void {
        this.id = model.id;
        this.title = model.title;
        this.subContractor = model.subContractor;
        this.supervisorUserName = model.supervisorUserName;
        this.reviewedByUserName = model.reviewedByUserName;
        this.completedByUserName = model.completedByUserName;
        this.lastUpdatedDate = model.lastUpdatedDate;
        this.lastUpdatedByUserName = model.lastUpdatedByUserName;
        this.createdDate = model.createdDate;
        this.isApproved = model.isApproved;
    }
    toDto(model: RAMSReviewListModel): void {}
}

export interface RAMSReviewListDTO {
    id: string;

    title: string;
    subContractor: string;
    supervisorUserName: string;
    reviewedByUserName: string;
    completedByUserName: string;
    lastUpdatedDate: string;
    lastUpdatedByUserName: string;
    createdDate: string;
    isApproved: boolean;
}
export interface RAMSReviewListAndRelatedDTO {
    ramsListItems: RAMSReviewListDTO[];
}
