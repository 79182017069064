import React from "react";
import { theme } from "../../Globals/Styles/AppTheme";
import { Clock } from "../../Content/Clock";
import { useRouteMatch } from "react-router-dom";

//custom
import { BarContainer, BarContent } from "./NotificationBar.styles";
import { useObserver } from "mobx-react-lite";
import { NotificationBarViewModel } from "./NotificationBarViewModel";

interface INotificationBar {
    children?: any;
    type: "pending" | "rejected" | "resolved";
}

export const NotificationBar: React.FC<INotificationBar> = (props) => {
    const viewModel = NotificationBarViewModel.Instance;

    const match = useRouteMatch(viewModel.getPath);

    const display = () => {
        let display: string = "";
        if (viewModel.getPath) {
            display = viewModel.isActive && match?.isExact ? "block" : "none";
        } else {
            display = viewModel.isActive ? "block" : "none";
        }
        return display;
    };

    //Alex M. added the ability to change colors based on status if the requirements change a such. Currently using only the pending case.

    const handleType = (snackType: string | undefined) => {
        switch (snackType) {
            case "pending":
                return `${theme.palette.projectStatus.pendingApproval}`;

            case "rejected":
                return "#ff9800";

            case "resolved":
                return "#4caf50";

            default:
                return "#2196f3";
        }
    };

    // Alex M. added the ability to change icons based on status if the requirements change a such. Currently using only the pending case.

    const handleIcon = (snackType: string | undefined) => {
        switch (snackType) {
            case "pending":
                return <Clock />;
            case "rejected":
                return "";

            case "resolved":
                return "";

            default:
                return "";
        }
    };

    return useObserver(() => (
        <>
            <BarContainer style={{ display: `${display()}`, background: `${handleType(props.type)}` }}>
                <BarContent>
                    {handleIcon(props.type)}
                    <span style={{ marginLeft: 10 }}>
                        This {viewModel.getItem} is <span style={{ textDecoration: "underline" }}>pending approval</span>
                    </span>
                </BarContent>
            </BarContainer>
        </>
    ));
};
