import { ModelBase, observable } from "@shoothill/core";
import { action, computed } from "mobx";
import moment from "moment";
export class CleanlinessReportModel extends ModelBase<CleanlinessReportModel> {
    public static readonly DEFAULT_ID = null;
    public static readonly DEFAULT_PROJECT_ID = "";
    public static readonly DEFAULT_CLEANLINESS_SUB_CONTRACTOR_NAME = "";
    public static readonly DEFAULT_CLEANLINESS_STATUS_ID = "";
    public static readonly DEFAULT_CLEANLINESS_NOTE_DETAILS = "";
    public static readonly DEFAULT_CLEANLINESS_REPORT_PHOTOS = [];
    public static readonly DEFAULT_CREATED_DATE = new Date();
    public static readonly DEFAULT_CREATED_BY_USER_ID = "";
    public static readonly DEFAULT_IS_DELETED = false;
    public static readonly DEFAULT_LAST_UPDATED_BY_USER_ID = "";
    public static readonly DEFAULT_LAST_UPDATED_DATE = "";
    public static readonly DEFAULT_ROW_VERSION = "";

    @observable
    public id: string | null = CleanlinessReportModel.DEFAULT_ID;

    @observable
    public projectId: string | null = CleanlinessReportModel.DEFAULT_PROJECT_ID;

    @observable
    public cleanlinessSubContractorName: string | null = CleanlinessReportModel.DEFAULT_CLEANLINESS_SUB_CONTRACTOR_NAME;

    @observable
    public cleanlinessReportStatusId: string | null = CleanlinessReportModel.DEFAULT_CLEANLINESS_STATUS_ID;

    @observable
    public cleanlinessNoteDetails: string = CleanlinessReportModel.DEFAULT_CLEANLINESS_NOTE_DETAILS;

    @observable
    public cleanlinessReportPhotos: CleanlinessReportPhotosPhotoFile[] = CleanlinessReportModel.DEFAULT_CLEANLINESS_REPORT_PHOTOS;

    @observable
    public createdByUserId: string | null = CleanlinessReportModel.DEFAULT_CREATED_BY_USER_ID;

    @observable
    public createdDate: any = CleanlinessReportModel.DEFAULT_CREATED_DATE;

    @observable
    isDeleted: boolean = CleanlinessReportModel.DEFAULT_IS_DELETED;

    @observable
    lastUpdatedByUserId: string | null = CleanlinessReportModel.DEFAULT_LAST_UPDATED_BY_USER_ID;

    @observable
    lastUpdatedDate: string | null = CleanlinessReportModel.DEFAULT_LAST_UPDATED_DATE;

    @observable
    rowVersion: string | null = CleanlinessReportModel.DEFAULT_ROW_VERSION;

    @computed
    public get hasId(): boolean {
        return this.id !== null && this.id !== undefined && this.id !== "";
    }

    @computed
    public get formattedLastUpdatedDate(): string {
        return moment(this.lastUpdatedDate).format("DD/MM/YYYY @ HH:mm");
    }

    @action
    public fromDto(dto: CleanlinessReportDTO): void {
        for (let key in dto) {
            if (dto.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(dto[key]);
                } else {
                    this[key] = dto[key];
                }
            }
        }
    }

    @action
    public toDto() {
        const CleanlinessReportModel: CleanlinessReportDTO = {
            id: this.id,
            projectId: this.projectId,
            cleanlinessSubContractorName: this.cleanlinessSubContractorName,
            cleanlinessReportStatusId: this.cleanlinessReportStatusId,
            cleanlinessNoteDetails: this.cleanlinessNoteDetails,
            createdByUserId: this.createdByUserId,
            createdDate: this.createdDate,
            isDeleted: this.isDeleted,
            lastUpdatedByUserId: this.lastUpdatedByUserId,
            lastUpdatedDate: this.lastUpdatedDate,
            rowVersion: this.rowVersion,
        };

        return CleanlinessReportModel;
    }

    @action
    public reset = () => {
        this.id = CleanlinessReportModel.DEFAULT_ID;
        this.projectId = CleanlinessReportModel.DEFAULT_PROJECT_ID;
        this.cleanlinessSubContractorName = CleanlinessReportModel.DEFAULT_CLEANLINESS_SUB_CONTRACTOR_NAME;
        this.cleanlinessReportStatusId = CleanlinessReportModel.DEFAULT_CLEANLINESS_STATUS_ID;
        this.cleanlinessNoteDetails = CleanlinessReportModel.DEFAULT_CLEANLINESS_NOTE_DETAILS;
        this.cleanlinessReportPhotos = CleanlinessReportModel.DEFAULT_CLEANLINESS_REPORT_PHOTOS;
    };

    // #Start Custome Validation

    @computed
    public get validateCleanlinessSubContractorName(): string {
        return this.cleanlinessSubContractorName === CleanlinessReportModel.DEFAULT_CLEANLINESS_SUB_CONTRACTOR_NAME ? "Please enter a sub contractor" : "";
    }

    @computed
    public get validateCleanlinessStatusId(): string {
        return this.cleanlinessReportStatusId === CleanlinessReportModel.DEFAULT_CLEANLINESS_STATUS_ID ? "Please select a status" : "";
    }

    @computed
    public get validateCleanlinessNoteDetails(): string {
        return this.cleanlinessNoteDetails === CleanlinessReportModel.DEFAULT_CLEANLINESS_NOTE_DETAILS ? "Please enter a notes" : "";
    }

    // #End Custome Validation
}

export type CleanlinessReportStatusDTO = {
    id: string;
    displayName: string;
    type: number;
    ordinal: number;
    isDeleted: boolean;
    color: string;
    textColor: string;
};

export interface CleanlinessReportDTO {
    id: string | null;
    projectId: string | null;
    cleanlinessSubContractorName: string | null;
    cleanlinessReportStatusId: string | null;
    cleanlinessNoteDetails: string | null;
    createdByUserId: string | null;
    createdDate: string | null;
    isDeleted: boolean;
    lastUpdatedByUserId: string | null;
    lastUpdatedDate: string | null;
    rowVersion: string | null;
}

export interface CleanlinessReportPhotosPhotoFile {
    id: string | null;
    cleanlinessReportId: string | null;
    fileName: string;
    photoURL: string;
    createdByUserId: string | null;
    isDeleted: boolean;
    createdDate: string | null;
}

export interface UpsertCleanlinessReportRequestDTO {
    cleanlinessReport: CleanlinessReportDTO;
    cleanlinessReportPhotos: CleanlinessReportPhotosPhotoFile[];
}

export interface UpsertCleanlinessReportResponseDTO {
    cleanlinessReport: CleanlinessReportDTO;
    cleanlinessReportPhotos: CleanlinessReportPhotosPhotoFile[];
    cleanlinessReportStatus: CleanlinessReportStatusDTO[];
}

export interface CleanlinessReportByIdResponseDTO {
    cleanlinessReport: CleanlinessReportDTO;
    cleanlinessReportList: CleanlinessReportDTO[];
    cleanlinessReportPhotos: CleanlinessReportPhotosPhotoFile[];
    cleanlinessReportStatus: CleanlinessReportStatusDTO[];
}

export interface CleanlinessReportResponseDTO {
    cleanlinessReport: CleanlinessReportDTO;
    cleanlinessReportList: CleanlinessReportDTO[];
    cleanlinessReportPhotos: CleanlinessReportPhotosPhotoFile[];
    cleanlinessReportStatus: CleanlinessReportStatusDTO[];
}

export interface FormRequestDTO {
    date: string | null;
    projectId: string | null;
    formId: string | null;
}
