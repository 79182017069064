import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { DarwinSelect } from "../../../../Components/AutoComplete/DarwinSelect";
import styled from "styled-components";
import { DefaultButton, PrimaryButton } from "../../../../Components/Buttons/Buttons";
import { SHBox } from "../../../../Components/Box";
import { FormControl, FormControlLabel, FormLabel, Radio, Typography, TextField } from "@material-ui/core";
import { DarwinInput } from "../../../../Globals/Styles/Control/DarwinInput";
import { InspectionsListViewModel } from "./InspectionsListViewModel";
import { RadioGroup } from "../../../../Globals/Styles/AdminStyling";
import { SectionSplitter } from "../../../../Components/Form/SectionSplitter";
import { ProjectButtonContainer } from "../../Project.styles";
import { PatchedPagination } from "../../../../Components/Table/PatchedPagination";
import * as Defaults from "../../../../Globals/Defaults/TableOptions";
import { DarwinTablePageContent } from "../../../../Globals/Styles/AppStyling";
import MaterialTable from "material-table";
import { formatDateTime } from "../../../../Utils/Format";
import { InspectionsListModel } from "./InspectionsListModel";
import moment from "moment";
import { Signature } from "../../../../Components/Signature/Signature";
import { InspectionsViewModel } from "./InspectionsViewModel";
import { FieldType, isNullOrEmpty, useRouter } from "@shoothill/core";
import { InductionUserDTO, InspectionsModel } from "./InspectionsModel";
import { InspectionsQuestionModel } from "./InspectionsQuestionModel";
import { InspectionsQuestionViewModel } from "./InspectionsQuestionViewModel";
import { Stores, StoresContext, StoresInstance } from "../../../../Globals/Stores";
import { DarwinLayout, PrivateRoute } from "../../../../Globals/Views";
import { FormHeader } from "Views/PurchaseOrder/Form/Views/FormHeaders";

const Wrapper = styled.div`
    padding: 5px 30px;
    .sigCanvas {
        border: 1px solid #cdcdcd;
    }
    .MuiFormControlLabel-root {
        .MuiIconButton-label {
            input[type="radio"]:checked + div svg {
                fill: #eb8d00;
            }
        }
    }
    .submitted {
        .MuiFormControlLabel-root {
            .MuiIconButton-label {
                input[type="radio"] + div svg {
                    fill: #eb8d00;
                }
            }
        }
    }
`;

interface Props {
    projectId: string;
    projectName: string;
    projectReference: string;
}
export const InspectionsView: React.FC<Props> = observer((props: Props) => {
    const [listViewModel] = useState(() => new InspectionsListViewModel());
    const store = useContext<Stores>(StoresContext);
    const { match } = useRouter();
    const { projectid, inspectionTypeId } = match.params as any;
    function getRootLayout() {
        return DarwinLayout;
    }

    useEffect(() => {
        listViewModel.projectId = props.projectId || projectid;
        const doAsync = async () => {
            await listViewModel.loadRelated();
            if (!isNullOrEmpty(inspectionTypeId)) {
                listViewModel.showInspectionFormForSiteTablet(inspectionTypeId);
            }
        };
        const _ = doAsync();
    }, []);

    const renderListView = (
        <SHBox>
            <SHBox grid dc={"300px 100px"} alignItems={"flex-end"}>
                <DarwinSelect
                    displayName="Add inspection form"
                    execute={listViewModel.setInspectionForm}
                    fullWidth={true}
                    getOptionLabel={(option: any) => option.displayName}
                    options={listViewModel.inspectionFormTypes}
                    placeholder="Please select type"
                    value={listViewModel.inspectionFormType}
                />
                <PrimaryButton canExecute={listViewModel.inspectionFormType !== null} execute={listViewModel.addInspectionForm} value={"Add"} />
            </SHBox>
            {/*{listViewModel.inspectionsListModels.map((inspectionListModel) => {
                return <div>{inspectionListModel.inspectionTypeDisplayName}</div>;
            })}*/}
            <DarwinTablePageContent>
                <MaterialTable
                    columns={[
                        { title: "Inspection Type", field: "inspectionTypeDisplayName" },
                        { title: "Serial/Registration/Id No.", field: "serialRegistrationNo" },
                        {
                            title: "Added by",
                            field: "firstName",
                            render: (rowData: InspectionsListModel) => (
                                <SHBox>
                                    <SHBox>{formatDateTime(rowData.createdDate.toString())} by</SHBox>
                                    <SHBox>{`${rowData.firstName} ${rowData.lastName}`}</SHBox>
                                </SHBox>
                            ),
                        },
                    ]}
                    components={{
                        Pagination: PatchedPagination,
                    }}
                    options={Defaults.GetDarwinTableOptions()}
                    data={listViewModel.inspectionsListModels}
                    onRowClick={(e, rowData) => {
                        listViewModel.handleRowClick(e, rowData!);
                    }}
                    title=""
                />
            </DarwinTablePageContent>
        </SHBox>
    );

    const renderAddForm = (
        <>
            <SHBox pt={3} pb={4}>
                <Typography variant="h2">
                    {listViewModel.inspectionViewModel.model.id ? listViewModel.getInspectionTypeName : `New inspection - ${listViewModel.getInspectionTypeName} inspection`}
                </Typography>
            </SHBox>
            <SHBox grid dc={"250px 250px 300px"} mt={2}>
                <Typography variant="h5">Date: {moment(new Date()).format("DD/MM/YYYY")}</Typography>
                <Typography variant="h5">Project reference: {props.projectReference}</Typography>
                <Typography variant="h5">Project name: {props.projectName}</Typography>
            </SHBox>
            <SectionSplitter />
            <SHBox grid dc={"250px 250px 250px"}>
                {/*<DarwinInput<AddressModel> type="text" label="City/Town :" validateOnBlur={true} listViewModel={listViewModel} fieldName="city" shrink={true} maxLength={128} />*/}

                <InspectionDarwinInputView viewModel={listViewModel.inspectionViewModel} label="Make:" placeholder="Please add" fieldName={"make"} />
                <InspectionDarwinInputView viewModel={listViewModel.inspectionViewModel} label="Model/type:" placeholder="Please add" fieldName={"modelType"} />
                <InspectionDarwinInputView
                    viewModel={listViewModel.inspectionViewModel}
                    label="Serial/registration/Id no."
                    placeholder="Please add"
                    fieldName={"serialRegistrationNo"}
                />
            </SHBox>
            <SectionSplitter />
            <SHBox>
                <SHBox pt={3} pb={4}>
                    <Typography variant="h2">Checks</Typography>
                    <Typography variant="h5">Have you checked the following:</Typography>
                </SHBox>
                {listViewModel.inspectionViewModel &&
                    listViewModel.inspectionViewModel!.model &&
                    listViewModel.inspectionViewModel!.questionsViewModel.map((questionViewModel, index) => {
                        return (
                            <div>
                                <InspectionDarwinRadioView
                                    viewModel={questionViewModel}
                                    key={index}
                                    label={questionViewModel.model.question}
                                    fieldName={"pass"}
                                    canSubmit={listViewModel.inspectionViewModel!.canSubmit()}
                                />
                                {questionViewModel.model.pass === false && (
                                    <SHBox grid dc={"2fr 1fr"}>
                                        <div></div>
                                        <TextField
                                            id={`fail-description-${index}`}
                                            multiline
                                            minRows={4}
                                            value={questionViewModel.model.failDescription}
                                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => questionViewModel.handleFailDescriptionChange(event)}
                                            fullWidth
                                            className="textarea"
                                            label="Failure description:"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            placeholder={`Please describe the issue`}
                                            disabled={!listViewModel.inspectionViewModel!.canSubmit()}
                                            error={questionViewModel.getError("failDescription") !== ""}
                                            helperText={questionViewModel.getError("failDescription")}
                                        />
                                    </SHBox>
                                )}
                            </div>
                        );
                    })}
            </SHBox>
            <SectionSplitter />
            <SHBox grid>
                <Typography variant="h2">Authorisations</Typography>
                <Typography variant="h5">
                    Checks should only be carried out by operatives who have completed the required training for the piece of equipment. Any faults found should be rectified prior
                    to the commencement of any work
                </Typography>
            </SHBox>
            <SectionSplitter />
            <Typography variant="h2">Pre-Use Inspection by</Typography>
            <SHBox grid dc={"300px 600px"} pt={2}>
                {/* <DarwinSelect
                    displayName="Name"
                    execute={listViewModel.inspectionViewModel!.setInspectionUser}
                    fullWidth={false}
                    getOptionLabel={(option: any) => option.displayName}
                    options={listViewModel.inspectionViewModel!.inductionUsers}
                    placeholder="Please select user"
                    value={listViewModel.inspectionViewModel!.model.inspectionUser}
                    canExecute={listViewModel.inspectionViewModel!.canSubmit()}
                /> */}
                <DarwinSelect
                    canExecute={listViewModel.inspectionViewModel!.canSubmit()}
                    displayName="Name:"
                    error={listViewModel.inspectionViewModel!.getError("inspectionLocalUserId") !== ""}
                    execute={listViewModel.inspectionViewModel!.setInspectionUser}
                    fullWidth={false}
                    groupBy={(option) => option.groupDisplayName}
                    getOptionLabel={(option: InductionUserDTO) => option.displayName}
                    onBlur={() => listViewModel.inspectionViewModel!.isFieldValid("inspectionLocalUserId")}
                    options={listViewModel.inspectionViewModel!.inspectionUsers}
                    placeholder="Please select"
                    validationMessage={listViewModel.inspectionViewModel!.getError("inspectionLocalUserId")}
                    value={listViewModel.inspectionViewModel!.inspectionUser}
                />
                <Signature
                    displayName={"Your signature:"}
                    execute={listViewModel.inspectionViewModel!.updateSignature}
                    value={listViewModel.inspectionViewModel!.model.signatureBase64}
                    canExecute={listViewModel.inspectionViewModel!.canSubmit()}
                />
            </SHBox>
            <SectionSplitter />
            <ProjectButtonContainer>
                {listViewModel.inspectionViewModel!.canSubmit() && <PrimaryButton execute={listViewModel.submitInspectionForm} displayName={"Submit"} />}
                <DefaultButton execute={listViewModel.cancelInspectionForm} displayName={"Cancel"} />
            </ProjectButtonContainer>
        </>
    );

    return <Wrapper>{listViewModel.showAddForm ? renderAddForm : renderListView}</Wrapper>;
});

interface InspectionDarwinInputProps {
    viewModel: InspectionsViewModel;
    label: string;
    placeholder: string;
    fieldName: keyof FieldType<InspectionsModel>;
}

export const InspectionDarwinInputView: React.FC<InspectionDarwinInputProps> = observer((props: InspectionDarwinInputProps) => {
    return (
        <>
            {props.viewModel.canSubmit() ? (
                <DarwinInput viewModel={props.viewModel} label={props.label} placeholder={props.placeholder} fieldName={props.fieldName} shrink={true} validateOnBlur={true} />
            ) : (
                <SHBox flexBox>
                    <Typography variant={"h5"}>
                        <strong>{props.label}</strong> {props.viewModel.getValue(props.fieldName)}
                    </Typography>
                </SHBox>
            )}
        </>
    );
});

interface InspectionDarwinRadioProps {
    viewModel: InspectionsQuestionViewModel;
    label: string;
    fieldName: keyof FieldType<InspectionsQuestionModel>;
    canSubmit: boolean;
}

export const InspectionDarwinRadioView: React.FC<InspectionDarwinRadioProps> = observer((props: InspectionDarwinRadioProps) => {
    return (
        <>
            <SHBox className={props.canSubmit ? "" : "submitted"} grid dc={"2fr 1fr"}>
                <FormLabel component="legend">
                    <Typography variant="h5">{props.viewModel.model.question}</Typography>
                </FormLabel>
                <FormControl component="fieldset">
                    {props.canSubmit ? (
                        <RadioGroup aria-label="passFail" name="passFail" value={props.viewModel.model.radioValue} onChange={props.viewModel.handleChange}>
                            <FormControlLabel value="pass" control={<Radio color={"secondary"} />} label="Pass" />
                            <FormControlLabel value="fail" control={<Radio color={"secondary"} />} label="Fail" />
                        </RadioGroup>
                    ) : (
                        <RadioGroup aria-label="passFail" name="passFail" value={props.viewModel.model.radioValue} onChange={props.viewModel.handleChange}>
                            {props.viewModel.model.radioValue === "pass" && <FormControlLabel value="pass" control={<Radio color={"secondary"} />} label="Pass" />}
                            {props.viewModel.model.radioValue === "fail" && <FormControlLabel value="fail" control={<Radio color={"secondary"} />} label="Fail" />}
                        </RadioGroup>
                    )}
                    <Typography color={"error"} variant="h5">
                        {props.viewModel.getError("pass")}
                    </Typography>
                </FormControl>
            </SHBox>
        </>
    );
});
