import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { AuditListViewModel } from "./AuditListViewModel";
import { SHBox } from "../../../../Components/Box";
import { Typography } from "@material-ui/core";
import { SectionSplitter } from "../../../../Components/Form/SectionSplitter";
import styled from "styled-components";
import { theme } from "../../../../Globals/Styles/AppTheme";
import { EndOfDayView } from "./EndOfDay/EndOfDayView";
import moment from "moment";
import { EndOfWeekView } from "./EndOfWeek/EndOfWeekView";

const Row = styled.div`
    display: grid;
    grid-template-columns: 55% 15% 15% 15%;
    padding-left: 15px;
    padding-right: 15px;
    cursor: pointer;
    .title {
        color: ${theme.palette.variationStatus.amendRequired};
        font-weight: normal;
    }
`;

interface Props {
    projectId: string;
    date: Date;
}
export const AuditListView: React.FC<Props> = observer((props: Props) => {
    const [viewModel] = useState(() => new AuditListViewModel(props.projectId, props.date));
    useEffect(() => {
        viewModel.getStatus(props.projectId, props.date);
    }, [props.date]);

    const renderList = (
        <SHBox pt={"15px"}>
            <Row>
                <Typography variant={"h5"}></Typography>
                <Typography variant={"h5"}>Date / time added</Typography>
                <Typography variant={"h5"}>Valid from / until</Typography>
                <Typography variant={"h5"}>Last Updated</Typography>
            </Row>
            <SectionSplitter />
            <Row onClick={() => viewModel.handleShowEndOfDay()}>
                <Typography className={"title"} variant={"h5"}>
                    <strong>End of day audit</strong>
                </Typography>
                <Typography variant={"h5"}>{viewModel.getFormattedDate(viewModel.model.endOfDayCreatedDate)}</Typography>
                <Typography variant={"h5"}>{viewModel.getFormattedDate(viewModel.model.endOfDayValidUntil)}</Typography>
                <SHBox>
                    <Typography variant={"h5"}>{viewModel.getLastUpdatedDate(viewModel.model.endOfDayLastUpdatedDate)}</Typography>
                    <Typography variant={"h5"}>{viewModel.model.endOfDayLastUpdatedBy}</Typography>
                </SHBox>
            </Row>
            <SectionSplitter />
            <Row onClick={() => viewModel.handleShowWeekly()}>
                <Typography className={"title"} variant={"h5"}>
                    <strong>Weekly audit</strong>
                </Typography>
                <Typography variant={"h5"}>{viewModel.getFormattedDate(viewModel.model.endOfWeekCreatedDate)}</Typography>
                <Typography variant={"h5"}>{viewModel.model.endOfWeekValidUntil}</Typography>
                <SHBox>
                    <Typography variant={"h5"}>{viewModel.getLastUpdatedDate(viewModel.model.endOfWeekLastUpdatedDate)}</Typography>
                    <Typography variant={"h5"}>{viewModel.model.endOfWeekLastUpdatedBy}</Typography>
                </SHBox>
            </Row>
            <SectionSplitter />
        </SHBox>
    );

    const renderEndOfDay = (
        <>
            <EndOfDayView projectId={props.projectId} id={viewModel.model.endOfDayId} handleClose={viewModel.handleShowList} />
        </>
    );
    const renderWeekly = (
        <>
            <EndOfWeekView projectId={props.projectId} id={viewModel.model.endOfWeekId} handleClose={viewModel.handleShowList} />
        </>
    );

    return (
        <>
            <SHBox showIf={viewModel.showList}>{renderList}</SHBox>
            <SHBox showIf={viewModel.showEndOfDay} style={{ cursor: "pointer" }}>
                {renderEndOfDay}
            </SHBox>
            <SHBox showIf={viewModel.showWeekly} style={{ cursor: "pointer" }}>
                {renderWeekly}
            </SHBox>
        </>
    );
});
