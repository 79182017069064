// Libraries
import * as MobX from "mobx";

// Core
import { FieldType, ViewModelBase } from "@shoothill/core";
import { StoresInstance } from "../Stores";

export class EditableCopyViewModel extends ViewModelBase {
    constructor() {
        super(null);
    }
    public getEditableCopyByRouteAndPlaceholderNumber = (route: string, placeholderNumber: number) => {
        let editableCopyRoute = StoresInstance.Domain.editableCopyRoutes.find((r) => r.route === route);

        if (editableCopyRoute !== undefined) {
            let editableCopyRouteId: string = editableCopyRoute.id;
            return StoresInstance.Domain.editableCopies.find((c) => c.routeId === editableCopyRouteId && c.placeholderNumber === placeholderNumber)?.copyValue as string;
        }

        return "";
    };

    // #region Viewmodel Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public async isFieldValid(fieldName: keyof FieldType<EditableCopyViewModel>): Promise<boolean> {
        return true;
    }

    // #endregion Viewmodel Boilerplate
}
