// Libs
import React from "react";
import { useObserver } from "mobx-react-lite";

// App
import { AddressModel } from "Globals/Models/Domain";
import { AddressListItem } from "./AddressListItem";

// Styling
import { AddressStyledList } from "./Address.styles";

interface IAddressLIProps {
    addressModel: AddressModel[];
    addressRowClicked: (val: string) => any;
    addressPrimaryText?: string;
    addressDelete: (model: AddressModel, index: number) => any;
    addressDeleteText?: string;
}

export const AddressListContainer: React.FC<IAddressLIProps> = (props) => {
    const { addressModel, addressRowClicked, addressDelete, addressDeleteText, addressPrimaryText } = props;

    return useObserver(() => (
        <>
            <AddressStyledList>
                {addressModel.map((address: AddressModel, index) => {
                    return (
                        <>
                            {!address.isDeleted && (
                                <AddressListItem
                                    addressModel={address}
                                    addressRowClicked={addressRowClicked}
                                    addressPrimaryText={addressPrimaryText}
                                    addressDeleteText={addressDeleteText}
                                    addressDelete={addressDelete}
                                    index={index}
                                />
                            )}
                        </>
                    );
                })}
            </AddressStyledList>
        </>
    ));
};
