import _get from "lodash/get";
import { SortDirection, Sorting } from "Globals/Models";

export const contains = <T>(array: T[], item: T): boolean => {
    if (array == undefined) {
        return false;
    }

    const index: number = array.indexOf(item);
    const hasIndex: boolean = index >= 0;

    return hasIndex;
};

export const isArrayNullOrUndefinedOrEmpty = (object: any[] | undefined | null): boolean => {
    return object === undefined || object === null || object.length == 0;
};

export type SortValueResolver<TItem = any, TValue = any> = (item: TItem, key: string) => TValue;
export const defaultValueResolver: SortValueResolver = (item, key) => _get(item, key);

export const createSorter = <TArray, TValue = any>(sortBy: string, sortDirection: SortDirection, valueResolver: SortValueResolver<TArray, TValue> = defaultValueResolver) => {
    return (a: TArray, b: TArray) => {
        let valueA: TValue | string = valueResolver(a, sortBy);
        let valueB: TValue | string = valueResolver(b, sortBy);

        if (valueA !== null && typeof valueA === "string") {
            valueA = valueA.toLocaleLowerCase();
        }

        if (valueB !== null && typeof valueB === "string") {
            valueB = valueB.toLocaleLowerCase();
        }

        let sortValue = 0;

        if (valueA < valueB || (valueA === null && valueB !== null)) {
            sortValue = -1;
        } else if (valueA > valueB || (valueA !== null && valueB === null)) {
            sortValue = 1;
        }

        if (sortValue != 0 && sortDirection === SortDirection.Desc) {
            sortValue *= -1;
        }

        return sortValue;
    };
};

export const sortBy = <TArray>(array: TArray[], sortBy: string, sortDirection: SortDirection) => {
    const sorter = createSorter<TArray>(sortBy, sortDirection);
    const sortedArray = array.sort(sorter);

    return sortedArray;
};

export const sortByIncludingChildren = <TArray>(
    array: TArray[],
    sortBy: string,
    sortDirection: SortDirection,
    callback: (sortBy: string, a: TArray, b: TArray) => { A: TArray; B: TArray },
) => {
    const sorter = (a: TArray, b: TArray) => {
        //TODO: AJ: Type detection for "smart" sorting
        //AJ: https://stackoverflow.com/questions/32968332/how-do-i-prevent-the-error-index-signature-of-object-type-implicitly-has-an-an
        const inputs = callback(sortBy, a, b);
        let valueA: TArray | string = inputs.A;
        let valueB: TArray | string = inputs.B;

        let sortValue = 0;

        if (valueA !== null && typeof valueA === "string") {
            valueA = valueA.toLocaleLowerCase();
        }

        if (valueB !== null && typeof valueB === "string") {
            valueB = valueB.toLocaleLowerCase();
        }

        if (valueA < valueB || (valueA === null && valueB !== null)) {
            sortValue = -1;
        } else if (valueA > valueB || (valueA !== null && valueB === null)) {
            sortValue = 1;
        }

        if (sortValue != 0 && sortDirection === SortDirection.Desc) {
            sortValue *= -1;
        }

        return sortValue;
    };

    const sortedArray = array.sort(sorter);

    return sortedArray;
};

/* export const multiSortBy = <TArray, TValue = any>(
    array: any[],
    sortBys: string[],
    sortDirections: { [Key: string]: SortDirection },
    valueResolver: SortValueResolver<TArray, TValue> = defaultValueResolver,
) => {
    const sorter = createMultiSorter(sortBys, sortDirections);
    const sortedArray = array.sort(sorter);

    return sortedArray;
}; */

/* export const createMultiSorter = <TArray, TValue = any>(
    sortBys: string[],
    sortDirections: { [Key: string]: SortDirection },
    valueResolver: SortValueResolver<TArray, TValue> = defaultValueResolver,
) => {
    const sortings = sortBys.map(sortBy => ({
        sortBy: sortBy,
        sortDirection: sortDirections[sortBy],
    }));

    return createSortingSorter(sortings, valueResolver);
};

export const createSortingSorter = <TArray, TValue = any>(
    sortings: Sorting<TValue>[],
    valueResolver: SortValueResolver<TArray, TValue> = defaultValueResolver,
) => (a: any, b: any) => {
        let result = 0;

        for (const sorting of sortings) {
            let valueA: TValue | string = valueResolver(a, sorting.sortBy);
            let valueB: TValue | string = valueResolver(b, sorting.sortBy);

            if (valueA !== null && typeof valueA === "string") {
                valueA = valueA.toLocaleLowerCase();
            }

            if (valueB !== null && typeof valueB === "string") {
                valueB = valueB.toLocaleLowerCase();
            }

            result =
            valueA > valueB || (valueA !== null && valueB === null)
                ? 1
                : valueA < valueB || (valueA === null && valueB !== null)
                    ? -1
                    : 0;

            result =
            sorting.sortDirection === SortDirection.Asc
                ? result
                : sorting.sortDirection === SortDirection.Desc
                    ? result * -1
                    : 0; //AJ: SortDirection.None

            if (result !== 0) break;
        }

        return result;
    }; */
