import { Theme } from "@material-ui/core";

export enum VariationStatus {
    Draft = "DRAFT",
    PendingInternalApproval = "PENDING INTERNAL APPROVAL",
    PendingClientApproval = "PENDING CLIENT APPROVAL",
    RejectedInternally = "REJECTED (INTERNALLY)",
    RejectedByClient = "REJECTED (BY CLIENT)",
    ApprovedByClient = "APPROVED BY CLIENT",
    RepairRequired = "REPAIR REQUIRED",
    Approved = "APPROVED",
    AmendRequired = "AMEND REQUIRED",
}

export class VariationStatusHelpers {
    public static getText = (status: VariationStatus) => {
        switch (status) {
            case VariationStatus.Approved: {
                return "APPROVED";
            }
            case VariationStatus.PendingInternalApproval: {
                return "PENDING INTERNAL APPROVAL";
            }
            case VariationStatus.PendingClientApproval: {
                return "PENDING CLIENT APPROVAL";
            }
            case VariationStatus.RejectedInternally: {
                return "REJECTED (INTERNALLY)";
            }
            case VariationStatus.RejectedByClient: {
                return "REJECTED (BY CLIENT)";
            }
            case VariationStatus.ApprovedByClient: {
                return "APPROVED BY CLIENT";
            }
            case VariationStatus.RepairRequired: {
                return "REPAIR REQUIRED";
            }
            case VariationStatus.AmendRequired: {
                return "AMEND REQUIRED";
            }
            case VariationStatus.Draft:
            default: {
                return "DRAFT";
            }
        }
    };

    public static getClassName = (status: VariationStatus) => {
        switch (status) {
            case VariationStatus.Approved: {
                return "variation-approved";
            }
            case VariationStatus.PendingInternalApproval: {
                return "variation-pendingInternalApproval";
            }
            case VariationStatus.PendingClientApproval: {
                return "variation-pendingClientApproval";
            }
            case VariationStatus.RejectedInternally: {
                return "variation-rejectedInternally";
            }
            case VariationStatus.RejectedByClient: {
                return "variation-rejectedByClient";
            }
            case VariationStatus.ApprovedByClient: {
                return "variation-approvedByClient";
            }
            case VariationStatus.RepairRequired: {
                return "variation-repairRequired";
            }
            case VariationStatus.AmendRequired: {
                return "variation-amendRequired";
            }
            case VariationStatus.Draft:
            default: {
                return "variation-draft";
            }
        }
    };

    public static getCellColour = (status: VariationStatus, theme: Theme): string => {
        switch (status) {
            case VariationStatus.Approved: {
                return theme.palette.variationStatus.approved.toString();
            }
            case VariationStatus.PendingInternalApproval: {
                return theme.palette.variationStatus.pendingInternalApproval.toString();
            }
            case VariationStatus.PendingClientApproval: {
                return theme.palette.variationStatus.pendingClientApproval.toString();
            }
            case VariationStatus.RejectedInternally: {
                return theme.palette.variationStatus.rejectedInternally.toString();
            }
            case VariationStatus.RejectedByClient: {
                return theme.palette.variationStatus.rejectedByClient.toString();
            }
            case VariationStatus.ApprovedByClient: {
                return theme.palette.variationStatus.approvedByClient.toString();
            }
            case VariationStatus.RepairRequired: {
                return theme.palette.variationStatus.repairRequired.toString();
            }
            case VariationStatus.AmendRequired: {
                return theme.palette.variationStatus.amendRequired.toString();
            }
            case VariationStatus.Draft:
            default: {
                return theme.palette.variationStatus.draft.toString();
            }
        }
    };

    public static getCellTextColour = (status: VariationStatus): string => {
        /*         switch (status) {
            case ProjectStatus.All:
                return "#FFFFFF";
            case ProjectStatus.Unknown:
            default: */
        return "#FFFFFF";
        //}
    };

    public static getAllOptions = (ignoreUnknown: boolean = false) => {
        const options: {
            label: string;
            value: string;
        }[] = [];

        for (const key in VariationStatus) {
            const keyAsInt = parseInt(key);
            // const keyAsInt = parseInt(key).toString();
            if (isNaN(keyAsInt) === false) {
                const status: VariationStatus = keyAsInt as unknown as VariationStatus;
                if (ignoreUnknown === false || status !== VariationStatus.Approved) {
                    options.push({
                        label: VariationStatusHelpers.getText(status),
                        value: keyAsInt.toString(),
                    });
                }
            }
        }
        return options;
    };
}
