export class DrawingsItemViewModel {
    // #region Properties

    public id: string = "";
    public name: string = "";
    public inlineUrl: string | null = null;
    public attachmentUrl: string | null = null;
    public revisionNumber: number = 0;
    public statusName: string = "";
    public statusColor: string = "";
    public statusTextColor: string = "";
    public updateDate: string = "";
    public updateByName: string = "";
    public canReviseDrawing: boolean = false;

    // #endregion Properties
}
