import { Link } from "@material-ui/core";
import React from "react";

import { DownloadDrawingContainer } from ".";

interface IProps {
    fileCount: number;
    onClick?: () => void;
}

export const MultipleDrawingsView: React.FC<IProps> = (props) => {
    /**
     * This is required should you host this component on a table row
     * that is clickable.
     */
    const onLinkClick = (event: any) => {
        event.stopPropagation();
        props.onClick?.();
    };

    return (
        <DownloadDrawingContainer>
            <Link className="downloaddrawing-view" onClick={onLinkClick} title={`View drawings`}>
                {props.fileCount} drawings
            </Link>
        </DownloadDrawingContainer>
    );
};
