// Libraries
import { FormControl, FormControlLabel, FormHelperText, RadioGroup } from "@material-ui/core";
import { FieldType, IModel, isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import { CustomRadio } from "Views/Project/CustomComponents";
import { useObserver } from "mobx-react-lite";
import React from "react";
import styled from "styled-components";

type TModel<T> = IModel<T> & any;

interface Props<T> {
    /**
     * Determines whether the radio buttons are editable.
     */
    canExecute?: boolean;
    /**
     * Additional classname added to the parent container of the form group.
     */
    className?: string;
    execute: (value: any) => void;
    /**
     * The validation message to be show if the form has an error.
     */
    validationMessage?: string;
    /**
     * The current value of the field.
     */
    value: any;
    /**
     * Whether or not the field has an error.
     */
    error?: boolean;
    /**
     * The field name that stores the result of the radio buttons.
     */
    fieldName: keyof FieldType<TModel<T>>;
    /**
     * The label for the radio button form group.
     */
    label: string;
    /**
     * Determines what type of radio form group this is. Yes/No, Pass/Fail etc.
     */
    radioType?: RadioType;
}

/**
 * Determines what type of radio form group this is. Yes/No, Pass/Fail etc.
 */
export enum RadioType {
    YesNo = 10,
    PassFail = 20,
}

/**
 * Custom radio button form control component.
 * @param props Custom radio button props.
 * @returns Radio button form control component.
 */
export function DarwinRadioButtons<T>(props: Props<T>) {
    const { fieldName, value, label, radioType = RadioType.YesNo, className = "" } = props;

    const getName = (): string => {
        return `radio-${fieldName.toString()}-group`;
    };

    const getValidationMessage = (): string => {
        return !isEmptyOrWhitespace(props.validationMessage) ? props.validationMessage! : "";
    };

    const isDisabled = (): boolean => {
        return !isNullOrUndefined(props.canExecute) ? !props.canExecute : false;
    };

    const hasError = (): boolean => {
        return !isEmptyOrWhitespace(props.validationMessage) || props.error === true;
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement>, value: boolean): void => {
        props.execute(value);
    };

    const getOptionLabel = (positive: boolean): string => {
        let retVal = "";

        switch (radioType) {
            case RadioType.YesNo:
                retVal = positive ? "Yes" : "No";
                break;
            case RadioType.PassFail:
                retVal = positive ? "Pass" : "Fail";
                break;
            default:
                retVal = positive ? "Yes" : "No";
                break;
        }

        return retVal;
    };

    // #endregion Code Behind

    return useObserver(() => (
        <DarwinRadioContainer className={className}>
            <p>{label}</p>
            <FormControl error={hasError()} variant="standard">
                <RadioGroup
                    row
                    name={getName()}
                    value={value}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>, value: string) => onChange(e, e.target.value === "true" ? true : false)}
                >
                    <FormControlLabel value={true} control={<CustomRadio checked={value === true} />} label={getOptionLabel(true)} disabled={isDisabled()} />
                    <FormControlLabel value={false} control={<CustomRadio checked={value === false} />} label={getOptionLabel(false)} disabled={isDisabled()} />
                </RadioGroup>
                <FormHelperText>{getValidationMessage()}</FormHelperText>
            </FormControl>
        </DarwinRadioContainer>
    ));
}

const DarwinRadioContainer = styled.div`
    margin-bottom: 1.25rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    > div,
    > p {
        display: flex;
        flex: 1 1 50%;
    }

    > p {
        font-size: 12px;
        font-weight: bold;
        letter-spacing: -0.24px;
    }
`;
