// Libraries
import React, { useEffect, useState } from "react";
import { Checkbox, makeStyles, TextField } from "@material-ui/core";
import { useObserver, isNullOrUndefined, KeyValuePair } from "@shoothill/core";
import Autocomplete, { AutocompleteChangeReason } from "@material-ui/lab/Autocomplete";

// Custom
import { ClientSelectViewModel } from "./ClientSelectViewModel";
import { BooleanLiteral } from "typescript";

// Styling
interface IClientListProps {
    currentId: string;
    includeDeleted: boolean;
    dataSet: KeyValuePair[];
    returnPair: (valPair: KeyValuePair) => any;
    placeHolder: string;
    variant: "standard" | "filled" | "outlined" | undefined;
    allowAdd: boolean;
    allowMultiple: boolean;
    idVal: string;
    autoSelect?: boolean;
    disabled?: boolean;
    error?: boolean;
}

const useStyles = makeStyles({
    input: {
        ["& .MuiInputBase-input"]: {
            fontSize: "12px !important",
            marginLeft: "10px",
            height: "90%",
        },
    },
});

/**
 *
 * @param props current Client Id
 * @returns autocomplete form component
 */
export const GenericAutoCompleteView: React.FC<IClientListProps> = (props) => {
    const { currentId, returnPair, includeDeleted, variant, allowMultiple, dataSet, placeHolder, idVal, allowAdd, autoSelect = true, disabled = false } = props;
    const viewModel = ClientSelectViewModel.Instance;
    const classes = useStyles();

    const handleReturnValue = (val: any) => {
        if (!isNullOrUndefined(val)) {
            if (isNullOrUndefined(val.key)) {
                let retVal: KeyValuePair = { key: "NEW", value: val };
                returnPair(retVal);
            } else {
                let retVal: KeyValuePair = { key: val.key, value: val.value };
                returnPair(retVal);
            }
        } else {
            let retVal: KeyValuePair = { key: "undefined", value: "undefined" };
            returnPair(retVal);
        }
        //setCounter(counter + 1);
    };

    const chosenRow = (val: string) => {
        let _val = !isNullOrUndefined(val) ? val : currentId;
        let _client = dataSet.find((c) => c.key === _val);
        return !isNullOrUndefined(_client) ? _client : undefined;
    };

    const withFreeSolo = () => {
        return (
            <>
                <Autocomplete
                    id={idVal}
                    freeSolo
                    autoSelect={autoSelect}
                    multiple={allowMultiple}
                    options={dataSet}
                    blurOnSelect
                    onChange={(event, value) => handleReturnValue(value)}
                    getOptionLabel={(option) => option.value}
                    getOptionSelected={(option, value) => option.key === currentId}
                    value={chosenRow(currentId)}
                    renderOption={(option, { selected }) => (
                        <React.Fragment>
                            <Checkbox style={{ marginRight: "10px" }} checked={option.key === currentId} />
                            <span style={{ fontSize: "12px" }}>{option.value}</span>
                        </React.Fragment>
                    )}
                    className={classes.input}
                    style={{ minWidth: "220px" }}
                    renderInput={(params) => <TextField {...params} placeholder={placeHolder} error={props.error} />}
                    disabled={disabled}
                />
            </>
        );
    };

    const withoutFreeSolo = () => {
        return (
            <>
                <Autocomplete
                    id={idVal}
                    multiple={allowMultiple}
                    options={dataSet}
                    blurOnSelect
                    onChange={(event, value) => handleReturnValue(value)}
                    getOptionLabel={(option) => option.value}
                    getOptionSelected={(option, value) => option.key === currentId}
                    value={chosenRow(currentId)}
                    renderOption={(option, { selected }) => (
                        <React.Fragment>
                            <Checkbox style={{ marginRight: "10px" }} checked={option.key === currentId} />
                            <span style={{ fontSize: "12px" }}>{option.value}</span>
                        </React.Fragment>
                    )}
                    className={classes.input}
                    style={{ minWidth: "220px" }}
                    renderInput={(params) => <TextField {...params} placeholder={placeHolder} />}
                />
            </>
        );
    };

    return useObserver(() => <>{allowAdd != undefined || allowAdd != false ? withFreeSolo() : withoutFreeSolo()}</>);
};
