import { ModelBase } from "@shoothill/core";
import { action, observable } from "mobx";

export class LineDescriptionModel extends ModelBase<LineDescriptionModel, LineDescriptionDTO> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Defaults and Constants

    public static readonly DEFAULT_ID = "";
    public static readonly DEFAULT_DISPLAYNAME = "";
    public static readonly DEFAULT_SUBCATEGORYID = "";

    // #endregion Defaults and Constants

    // #region Observables

    @observable
    public id: string = LineDescriptionModel.DEFAULT_ID;

    @observable
    public displayName: string = LineDescriptionModel.DEFAULT_DISPLAYNAME;

    @observable
    public subCategoryId: string = LineDescriptionModel.DEFAULT_SUBCATEGORYID;

    // #endregion Observables

    // #region Actions

    @action
    public reset(): void {
        this.id = LineDescriptionModel.DEFAULT_ID;
        this.displayName = LineDescriptionModel.DEFAULT_DISPLAYNAME;
        this.subCategoryId = LineDescriptionModel.DEFAULT_SUBCATEGORYID;
    }

    @action
    public fromDto(dto: LineDescriptionDTO): void {
        this.id = dto.id;
        this.displayName = dto.label;
        this.subCategoryId = dto.parentId;
    }

    public toDto() {
        throw new Error("Method not implemented.");
    }

    // #endregion Actions

    // #region Helpers

    public static fromDtos(dtos: LineDescriptionDTO[]): LineDescriptionModel[] {
        const types: LineDescriptionModel[] = [];

        for (const dto of dtos) {
            const model = new LineDescriptionModel();

            model.fromDto(dto);
            types.push(model);
        }

        return types;
    }

    // #endregion Helpers
}

export interface LineDescriptionDTO {
    id: string;
    label: string;
    parentId: string;
}
