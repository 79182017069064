import { observable, computed, action } from "mobx";
import type { IObservableArray } from "mobx";
import { isNullOrUndefined, ModelBase, sortByString } from "@shoothill/core";
import type { ClientContactModelDTO } from "./ClientContactModel";
import { AddressModel, AddressModelDTO } from "Globals/Models/Domain";
import { ProjectListModelDTO } from "Views/Project/ProjectListModel";
import { PurchaseOrderListModel } from "Views/PurchaseOrder/PurchaseOrderListModel";
import { ClientWithRelatedDTO } from "./ClientWithRelatedDTO";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class ClientDetailModel extends ModelBase<ClientDetailModel, ClientWithRelatedDTO> {
    public id: string | undefined = undefined;
    @observable
    public reference: string = "";
    @observable
    public name: string = "";
    @observable public isDeleted: boolean = false;
    public createdDate: string | undefined = undefined;
    @observable public rowVersion: string | undefined = undefined;
    @observable public originatorId: string | undefined = undefined;
    @observable public originatorName: string = "";

    @observable
    public contacts: IObservableArray<ClientContactModelDTO> = observable([]);

    @observable
    public addresses: IObservableArray<AddressModel> = observable([]);

    @observable
    public purchaseOrders: IObservableArray<PurchaseOrderListModel> = observable([]);

    @observable
    public currentProjects: IObservableArray<ProjectListModelDTO> = observable([]);

    // @computed get getContacts(): ContactBaseDTO[] {
    //     return (
    //         this.contacts
    //             .slice()
    //             //.filter((a) => a.isDeleted === false)
    //             .map((item) => {
    //                 return item.toBaseContact();
    //             })
    //     );
    // }

    @computed get getAddresses(): AddressModel[] {
        return this.addresses.slice();
    }

    @action
    public reset() {
        this.id = undefined;
        this.reference = "";
        this.name = "";
        this.isDeleted = false;
        this.createdDate = undefined;
        this.rowVersion = undefined;
        this.originatorId = undefined;
        this.originatorName = "";

        this.addresses.clear();
        this.contacts.clear();

        this.currentProjects.clear();
        this.purchaseOrders.clear();
    }

    //fromDto is required but you can leave it blank
    fromDto(model: ClientWithRelatedDTO): void {
        this.id = model.client.id;
        this.reference = model.client.reference;
        this.name = model.client.name;
        this.isDeleted = model.client.isDeleted;
        this.createdDate = model.client.createdDate;
        this.rowVersion = model.client.rowVersion;
        this.originatorId = model.client.originatorId;
        this.originatorName = model.client.originatorName;

        let addressModels: AddressModel[] = [];

        model.client.addresses.forEach((dto) => {
            let addressModel = new AddressModel();
            addressModel.fromDto(dto);
            addressModels.push(addressModel);
        });

        this.addresses.replace(addressModels);
        this.contacts.replace(model.client.contacts);

        this.currentProjects.replace(model.currentProjects);
        this.purchaseOrders.replace(model.purchaseOrders);
    }

    //toDto is required but you can leave it blank
    toDto(model: ClientDetailModel): void {}

    @action
    public addContact(contact: ClientContactModelDTO) {
        const newArray: ClientContactModelDTO[] = this.contacts.slice();
        newArray.push(contact);

        newArray.sort((a: ClientContactModelDTO, b: ClientContactModelDTO) => {
            return sortByString(a.lastName + " " + a.firstName, b.lastName + " " + b.firstName);
        });

        this.contacts.replace(newArray);
    }

    @action
    public replaceContacts(contacts: ClientContactModelDTO[]) {
        this.contacts.clear();

        contacts.sort((a: ClientContactModelDTO, b: ClientContactModelDTO) => {
            return sortByString(a.lastName + " " + a.firstName, b.lastName + " " + b.firstName);
        });

        this.contacts.push(...contacts);
    }

    @action
    public replaceContact(contact: ClientContactModelDTO) {
        const newArray: ClientContactModelDTO[] = this.contacts.slice().filter((a) => a.id !== contact.id);
        newArray.push(contact);

        newArray.sort((a: ClientContactModelDTO, b: ClientContactModelDTO) => {
            return sortByString(a.lastName + " " + a.firstName, b.lastName + " " + b.firstName);
        });

        this.contacts.replace(newArray);
    }

    @action
    public removeContact(contactId: string) {
        const item: ClientContactModelDTO | undefined = this.contacts.slice().find((a) => a.id === contactId);
        if (isNullOrUndefined(item) === false) {
            item!.isDeleted = true;
        }
    }
}

export type ClientDetailModelDTO = {
    id: string | undefined;
    reference: string;
    name: string;
    isDeleted: boolean;
    createdDate: string | undefined;
    rowVersion: string | undefined;
    originatorId: string | undefined;
    originatorName: string;

    addresses: AddressModelDTO[];
    contacts: ClientContactModelDTO[];
};

export const DefaultClientDetailModelDTO: ClientDetailModelDTO = {
    id: "",
    createdDate: "",
    reference: "",
    name: "",
    isDeleted: false,
    rowVersion: undefined,
    originatorId: undefined,
    originatorName: "",
    contacts: [],
    addresses: [],
};
