// Libraries
import React from "react";
import { useObserver } from "mobx-react-lite";
import styled from "styled-components";

// Custom
import { VariationViewModel } from "../VariationViewModel";

// Styling & Images
import { theme } from "Globals/Styles/AppTheme";
import { makeStyles } from "@material-ui/core";
import { IEButton } from "Views/Project/Commercial/IETables/IEGrid.Styles";
import { VariationGridViewCatSubDesc } from "./VariationGridView.CatSubDesc";
import { SummaryGridView } from "./VariationGrid.Styles";
import { VariationItemNonCollapsible } from "./VariationItemNonCollapsible";

interface ITableProps {
    IEid: string;
    viewModel: VariationViewModel;
    IEType: string;
    addNewVariationLineClick: () => void;
    isDisabled: boolean;
}

export const VariationGridViewCatSubDescContainer: React.FunctionComponent<ITableProps> = (props) => {
    const { viewModel, addNewVariationLineClick, isDisabled } = props;
    const classes = useStyles();

    return useObserver(() => (
        <>
            <SummaryGridView className={classes.IELineGroup}>
                <li className="heading">
                    <div>Item description</div>
                    <div>Quantity</div>
                    <div>Unit</div>
                    <div>Rate</div>
                    <div>Line total</div>
                    <div>Committed cost</div>
                    <div>Future spend</div>
                    <div>Variance</div>
                    <div></div>
                </li>
                {viewModel.hasDefaults && (
                    <>
                        <VariationGridViewCatSubDesc
                            viewModel={viewModel}
                            variationCategoryViewModels={viewModel.getDefaultVariationCategoryViewModels}
                            IEType={"MASTER"}
                            isDisabled={isDisabled}
                        />

                        <div>
                            <li className="item title">
                                <div>Additional sub contractor costs</div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </li>
                        </div>
                    </>
                )}

                {viewModel.getAdditionalVariationItemViewModels.map((item, index) => {
                    return <VariationItemNonCollapsible itemViewModel={item} rowIndex={index} isDisabled={isDisabled} />;
                })}

                <IEButton onClick={() => addNewVariationLineClick()} style={{ margin: "20px 0px 20px 10px" }}>
                    Add new line
                </IEButton>
            </SummaryGridView>
        </>
    ));
};

const useStyles = makeStyles({
    IELineGroup: {
        borderBottom: `4px solid ${theme.palette.detail.main}`,
    },
    CatBorder: {
        borderTop: `4px solid ${theme.palette.detail.main}`,
        borderBottom: `4px solid ${theme.palette.detail.main}`,
    },
    SubCatBorder: {
        borderTop: `2px solid ${theme.palette.detail.main}`,
        borderBottom: `2px solid ${theme.palette.detail.main}`,
    },
    ItemBorder: {
        borderTop: `1px solid ${theme.palette.detail.main}`,
        borderBottom: `1px solid ${theme.palette.detail.main}`,
    },
});

export const IESubItem = styled.div`
    > li {
        > div:nth-child(1) {
            margin-left: 50px;
        }
    }
`;
