import { ModelBase } from "@shoothill/core";
import { IsNotEmpty, ValidateIf } from "class-validator";
import { observable, runInAction } from "mobx";
import { AuditDocumentModel } from "../AuditDocument";

export class SpillKitModel extends ModelBase<SpillKitModel> {
    public id: string | null = null;
    @observable
    @IsNotEmpty({ message: "Please enter a location" })
    public location: string = "";
    @observable
    @IsNotEmpty({ message: "Please select yes or no" })
    public correctSignageInPlace: string = "";
    @observable
    @ValidateIf((o) => o.correctSignageInPlace === "no")
    @IsNotEmpty({ message: "Please enter more detail" })
    public correctSignageInPlaceDetail: string = "";
    @observable
    @IsNotEmpty({ message: "Please select yes or no" })
    public allContentsInPlace: string = "";
    @observable
    @ValidateIf((o) => o.allContentsInPlace === "no")
    @IsNotEmpty({ message: "Please enter more detail" })
    public allContentsInPlaceDetail: string = "";
    @observable
    @IsNotEmpty({ message: "Please select yes or no" })
    public allContentsInGoodCondition: string = "";
    @observable
    @ValidateIf((o) => o.allContentsInGoodCondition === "no")
    @IsNotEmpty({ message: "Please enter more detail" })
    public allContentsInGoodConditionDetail: string = "";
    @observable
    public auditId: string | null = null;
    @observable
    public auditDocuments: AuditDocumentModel[] = [];
    constructor() {
        super();
    }

    fromDto(model: any): void {
        this.id = model.id;
        this.location = model.location;
        this.correctSignageInPlace = model.correctSignageInPlace ? "yes" : "no";
        this.allContentsInPlace = model.allContentsInPlace ? "yes" : "no";
        this.allContentsInGoodCondition = model.allContentsInGoodCondition ? "yes" : "no";
        this.auditId = model.auditId;
        this.correctSignageInPlaceDetail = model.correctSignageInPlaceDetail;
        this.allContentsInPlaceDetail = model.allContentsInPlaceDetail;
        this.allContentsInGoodConditionDetail = model.allContentsInGoodConditionDetail;
        if (model.auditDocuments) {
            runInAction(() => {
                this.auditDocuments = model.auditDocuments.map((x: any) => {
                    const auditDocument = new AuditDocumentModel();
                    auditDocument.fromDto(x);
                    return auditDocument;
                });
            });
        }
    }

    toDto() {
        return {
            id: this.id,
            location: this.location,
            correctSignageInPlace: this.correctSignageInPlace === "yes" ? true : false,
            allContentsInPlace: this.allContentsInPlace === "yes" ? true : false,
            allContentsInGoodCondition: this.allContentsInGoodCondition === "yes" ? true : false,
            auditId: this.auditId,
            auditDocuments: this.auditDocuments,
            correctSignageInPlaceDetail: this.correctSignageInPlaceDetail,
            allContentsInPlaceDetail: this.allContentsInPlaceDetail,
            allContentsInGoodConditionDetail: this.allContentsInGoodConditionDetail,
        };
    }
}
