import * as React from "react";
import { SVGProps } from "react";

export const RightArrowCircle = (props: SVGProps<SVGSVGElement>) => (
    <svg data-name="Group 8336" xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <rect data-name="Rectangle 62" width={30} height={30} rx={15} fill="#0095da" />
        <path
            data-name="Path 3323"
            d="M12.174 21.659a1.012 1.012 0 0 1-.174-.683V8.854a1.285 1.285 0 0 1 .348-.683A.77.77 0 0 1 12.87 8a1.328 1.328 0 0 1 .7.341l6.082 5.976A.882.882 0 0 1 20 15a1.285 1.285 0 0 1-.348.683l-6.261 5.976A.909.909 0 0 1 12.7 22c-.174-.171-.348-.171-.522-.341Zm0 0"
            fill="#fff"
        />
    </svg>
);
