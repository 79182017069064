import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { SHBox } from "../../Components/Box";
import { DownloadFileIcon } from "../../Content/DownloadFileIcon";

const Wrapper = styled.div`
    display: inline-block;
    font-size: 12px;
    padding: 10px;
    background-color: #eaf4f9;
    width: auto;
    cursor: pointer;
    icon {
        margin-right: 10px;
    }
`;
interface Props {
    onClick: () => void;
    fileName: string;
}
export const PDFFileView: React.FC<Props> = observer((props: Props) => {
    return (
        <Wrapper onClick={props.onClick}>
            <SHBox grid dc={"10px 1fr"}>
                <DownloadFileIcon className={"icon"} />
                {props.fileName}
            </SHBox>
        </Wrapper>
    );
});
