import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useObserver } from "mobx-react-lite";
import NoteViewModel from "Globals/ViewModels/NoteViewModel";
//import { AddressCell, AddressRow, AddressWrapper } from "./Address.styles";
import { DarwinInput } from "Globals/Styles/Control/DarwinInput";
import { AcceptButton } from "Globals/Styles/Control/Buttons";
import { AddButtonCell } from "Views/Contacts/AddEditContact.styles";
import { NoteModelDTO, NoteModel } from "Globals/Models/Domain";
import DownloadDocIcon from "@material-ui/icons/FileCopy";
import { NoteWrapper, NoteRow, DropzoneContainer, NoteDocuments, NoteDocument } from "./Notes.styles";
import styled from "styled-components";
import { EditableInput } from "@shoothill/core";
import { CustomHr } from "Views/Project/CustomComponents";

const TextAreaInput: any = styled(EditableInput)`
    //CMR This breaks all the inputs, please don't do this. padding-left: 0px !important;

    div {
        padding-left: 0px;
    }

    input {
        width: 100%;
    }
`;

interface INoteViewProps {
    model: NoteModel;
    reference?: React.MutableRefObject<HTMLButtonElement | undefined>;
    onUpdateModel: (model: NoteModelDTO) => any;
    showAddButton: boolean;
    showUploadFacility: boolean;
    isLoading: boolean;
    buttonLabel?: string;
    isNoteValid?: (isValid: boolean) => any;
}

export const NoteComponentView: React.FC<INoteViewProps> = (props) => {
    const { model, onUpdateModel, showAddButton, isLoading, reference, buttonLabel, isNoteValid, showUploadFacility } = props;
    const [viewModel] = useState(() => new NoteViewModel(model));
    const [count, setCounter] = useState<number>(0);

    //useEffect below only gets run on initial render
    useEffect(() => {
        return () => {
            viewModel.cleanUp();
        };
    }, []);

    const upsertNote = async () => {
        if (await viewModel.isModelValid()) {
            console.log("model is valid");
            onUpdateModel(viewModel.getModel.toNoteDto());
            viewModel.model.clearUploadedDocuments();
            setCounter(count + 1);
            // TODO onUpdateModel needs to be a promise. If it works, it needs to clear the model.
            viewModel.cleanUp();
        } else {
            console.log("model is NOT valid");
        }
    };

    const onDrop = useCallback((acceptedFiles) => {
        // Do something with the files
        viewModel.addDocuments(acceptedFiles);
        setCounter(count + 1);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const files = viewModel.model.getDocumentsToUpload.map((file) => <NoteDocument key={file.name}>{file.name}</NoteDocument>);

    return useObserver(() => (
        <NoteWrapper>
            <NoteRow>
                <DarwinInput
                    maxLength={1280}
                    multiLine={true}
                    rows={10}
                    type="text"
                    // label="Note"
                    viewModel={viewModel}
                    placeHolder="Please add your note..."
                    shrink={true}
                    fieldName="note"
                />
            </NoteRow>
            {showUploadFacility && (
                <NoteRow>
                    <div className="container">
                        <DropzoneContainer {...getRootProps({ className: "dropzone" })}>
                            <input {...getInputProps()} />
                            <div>Click to add or drag your files to attach</div>
                        </DropzoneContainer>
                        {files.length > 0 && (
                            <>
                                <p>
                                    <strong>To upload</strong>
                                </p>
                                <NoteDocuments>{files}</NoteDocuments>
                            </>
                        )}
                    </div>
                </NoteRow>
            )}
            <NoteRow>
                {showAddButton && (
                    <AddButtonCell>
                        <AcceptButton onClick={upsertNote} style={{ visibility: showAddButton ? "visible" : "hidden" }} disabled={isLoading}>
                            {isLoading ? <span>Please wait...</span> : <span>{buttonLabel != undefined ? buttonLabel : "Add"}</span>}
                        </AcceptButton>
                    </AddButtonCell>
                )}
            </NoteRow>
            <CustomHr padding={"10px 0"} />
        </NoteWrapper>
    ));
};
