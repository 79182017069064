import { Box } from "@material-ui/core";
import styled from "styled-components";

export type DetailsPageProps = {};

export const DetailsPage: any = styled.div<DetailsPageProps & DetailsPageProps>`
    display: flex;
    flex-direction: column;
    flex: 0 0 0;
    min-width: 100%;
`;

export const DetailsHeader: any = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 100%;
    height: 50px;
`;

export const DetailsPrimaryContact: any = styled(Box)`
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    width: 100%;
    max-width: 640px;
    height: auto;
    padding-bottom: 30px;
`;

export const DetailsTabs: any = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    width: 100%;
    min-width: 100%;
    height: 100px;
`;
