import { FieldType, ViewModelBase, isEmptyOrWhitespace } from "@shoothill/core";

import { RequisitionPOStatusEnum } from "Globals/Models";
import { formatDate, formatTime } from "Utils/Format";
import { AppUrls } from "AppUrls";
import { ApprovalDocumentDTO } from "Components/Approval/ApprovalDocumentModel";
import { ApprovalHistoryItemDTO } from "Globals/ViewModels/ApprovalPanelViewModelBase";

export class ApprovalHistoryItemViewModel extends ViewModelBase<any> {
    public id: string | null;
    public type: number;
    public updateBy: string;
    public updatedDate: string;
    public notes: string | null;

    public approvalDocuments: ApprovalDocumentDTO[] = [];

    public approvalRequirementItems: ApprovalHistoryItemDTO[] = [];

    public constructor(
        id: string | null,
        type: number,
        updateBy: string,
        updatedDate: string,
        notes: string | null,
        approvalDocuments: ApprovalDocumentDTO[],
        approvalRequirementItems: ApprovalHistoryItemDTO[],
    ) {
        super();
        this.id = id;
        this.type = type;
        this.updateBy = updateBy;
        this.updatedDate = updatedDate;
        this.notes = notes;
        this.approvalDocuments = approvalDocuments;
        this.approvalRequirementItems = approvalRequirementItems;
    }

    public get canDisplayNotes() {
        return !isEmptyOrWhitespace(this.notes);
    }

    public get formattedDateTime() {
        return `${formatDate(this.updatedDate)} @ ${formatTime(this.updatedDate)}`;
    }

    public get formattedType() {
        switch (this.type) {
            case RequisitionPOStatusEnum.Draft:
            case RequisitionPOStatusEnum.SubmittedForApproval:
                return "Sent for approval";

            case RequisitionPOStatusEnum.Rejected:
                return "Rejected";

            case RequisitionPOStatusEnum.AmendRequired:
                return "Sent for amends";

            case RequisitionPOStatusEnum.Approved:
                return "Approved";

            default:
                return "Unknown";
        }
    }

    /**
     * Download a file that exists in azure.
     * @param fileUrl The URL of the file to be downloaded.
     * @param fileName The name of the file to be downloaded.
     */
    public DownloadFile = async (fileUrl: string, fileName: string): Promise<void> => {
        try {
            const apiResult = await this.Post<Blob>(AppUrls.Server.File.DownloadFile, fileUrl, undefined, { responseType: "blob" });
            const response = apiResult as any;
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
        } catch (exception) {
            console.error(exception);
            this.setIsErrored(true);
        }
    };

    public async isFieldValid(fieldName: keyof FieldType, value: any): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
