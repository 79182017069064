import * as React from "react";
import styled from "styled-components";

import { BaseModal } from "Components/Modal/BaseModal";
import { CancelButton } from "Globals/Styles/Control/Buttons";
import { useObserver } from "mobx-react-lite";
import { EditContactModalDialogPaper } from "Views/Contacts/EditContactModal.styles";
import moment from "moment";
import { DashedDivider, SolidDivider } from "../../Views/Dividers";
import { ApprovalHistoryItemDTO } from "Globals/ViewModels/ApprovalPanelViewModelBase";
import { Tooltip } from "@material-ui/core";

interface IApprovalHistoryModalProps {
    open?: boolean;
    onClose(): void;
    title: string;
    approvalHistoryItems: ApprovalHistoryItemDTO[];
    isLoading: boolean;
    isCentral: boolean;
}

// May want separate component for PurchaseOrders and Invoices.
export const ApprovalHistoryModal: React.FunctionComponent<IApprovalHistoryModalProps> = ({ open, onClose, title, approvalHistoryItems, isLoading, isCentral }) => {
    const getRoleNameFormatted = (item: ApprovalHistoryItemDTO): string => {
        let roleLevelDisplayName: string = item.roleLevelDisplayName ? item.roleLevelDisplayName : "";
        return item.roleTypeName ? `${item.roleTypeName} ${roleLevelDisplayName}` : roleLevelDisplayName;
    };

    const getTooltipUserNames = (item: ApprovalHistoryItemDTO): string => {
        if (item.approvalHistoryRoleUsers) {
            const names = item.approvalHistoryRoleUsers.map((user) => user.administratorUserName);
            return names.join(", ");
        }

        return "";
    };

    const getActionedByUserName = (item: ApprovalHistoryItemDTO): string => {
        if (item.actionedByUserName) {
            if (item.actionedByDelegateUserName) {
                return item.actionedByDelegateUserName;
            } else {
                return item.actionedByUserName;
            }
        }
        return "-";
    };

    const renderStandard = () => {
        return (
            <>
                <div className="ah-columns">
                    <div className="ah-column-cell">Role level</div>
                    <div className="ah-column-cell">Is required?</div>
                    <div className="ah-column-cell">Approval status name</div>
                    <div className="ah-column-cell">Actioned by user name</div>
                    <div className="ah-column-cell">Actioned date</div>
                </div>
                <SolidDivider gutterBottom={true} gutterTop={true} borderThickness={2} borderOpacity={0.5} />
                <div className="ah-rows">
                    {approvalHistoryItems.map((item: ApprovalHistoryItemDTO, index: number) => {
                        return (
                            <>
                                <div className="ah-row" key={"ah-row-" + index}>
                                    <div className="ah-row-cell">
                                        <Tooltip title={getTooltipUserNames(item)} placement="bottom-start">
                                            <>{getRoleNameFormatted(item)}</>
                                        </Tooltip>
                                    </div>
                                    <div className="ah-row-cell">{item.isRequired ? "Yes" : "No"}</div>
                                    <div className="ah-row-cell">{item.approvalStatusName ? item.approvalStatusName : "-"}</div>
                                    <div className="ah-row-cell">{getActionedByUserName(item)}</div>
                                    <div className="ah-row-cell">{item.actionedDate ? moment(item.actionedDate).format("DD/MM/YYYY") : "-"}</div>
                                </div>
                                {approvalHistoryItems.length - 1 !== index && <DashedDivider gutterBottom={true} borderThickness={2} borderOpacity={0.5} />}
                            </>
                        );
                    })}
                </div>
            </>
        );
    };

    // const renderCentral = () => {
    //     return <>{budgetHolderName}</>;
    // };

    return useObserver(() => (
        <BaseModal
            open={open}
            onClose={onClose}
            title={title}
            PaperComponent={(props) => <ApprovalHistoryModalDialogPaper {...props} isCentral={isCentral} />}
            actions={
                <>
                    <CancelButton onClick={onClose}>Close</CancelButton>
                </>
            }
        >
            <ApprovalHistoryContainer>{renderStandard()}</ApprovalHistoryContainer>
        </BaseModal>
    ));
};

export const ApprovalHistoryModalDialogPaper = styled(EditContactModalDialogPaper)<{ isCentral: boolean }>`
    width: 100% !important;
    max-width: 1000px;
    max-width: ${(props) => (props.isCentral ? "600px" : "1000px")};

    .MuiDialogActions-root > button {
        max-width: 262px;
    }

    .MuiDialogContent-root {
        margin-bottom: 20px;
    }

    .MuiDivider-root {
        background-color: rgba(0, 0, 0, 0.12) !important;
    }
`;

const ApprovalHistoryContainer = styled.div`
    .ah-columns {
        display: flex;
        flex-direction: row;
        //border-bottom: 1px solid grey;
    }
    .ah-rows {
        display: flex;
        flex-direction: column;

        .ah-row {
            display: flex;
            flex-direction: row;
            margin-bottom: 20px;

            .ah-row-cell {
            }
        }

        .ah-row:last-child {
            margin-bottom: 0px;
        }
    }

    .ah-column-cell {
        font-weight: bold;
    }

    .ah-column-cell,
    .ah-row-cell {
        margin-right: 20px;
        max-width: 50px;
        width: 100%;
    }

    .ah-column-cell:nth-child(1),
    .ah-row-cell:nth-child(1) {
        max-width: 153px;
    }

    /* .ah-column-cell:nth-child(2),
    .ah-row-cell:nth-child(2) {
        min-width: 117px;
    } */

    .ah-row-cell:nth-child(1) {
        text-transform: capitalize;
    }

    .ah-column-cell:nth-child(2),
    .ah-row-cell:nth-child(2) {
        max-width: 87px;
    }

    .ah-column-cell:nth-child(3),
    .ah-row-cell:nth-child(3) {
        max-width: 204px;
    }

    .ah-column-cell:nth-child(4),
    .ah-row-cell:nth-child(4) {
        max-width: 211px;
    }

    .ah-column-cell:nth-child(5),
    .ah-row-cell:nth-child(5) {
        max-width: 105px;
    }
`;
