import { BaseModalDialogPaper } from "Components/Modal/BaseModal.styles";
import styled from "styled-components";

export const InvoiceMatchSubmittedWarningModalDialogPaper = styled(BaseModalDialogPaper)`
    min-width: 420px;

    .MuiDialogContent-root {
        p {
            padding-bottom: 40px;
            font-size: 14px;
        }
    }
`;
