import { observable, runInAction, action } from "mobx";
import { FieldType, ViewModelBase } from "@shoothill/core";
import { FireExitModel } from "./FireExitModel";

export class FireExitViewModel extends ViewModelBase<FireExitModel> {
    constructor(model = new FireExitModel()) {
        super(model);
        this.setDecorators(FireExitModel);
    }
    @action
    public radioChanged = async (value: string, fieldName: string, editable: boolean) => {
        if (!editable) return;
        this.model[fieldName] = value;
        await this.isFieldValid(fieldName as any, value);
    };
    public async isFieldValid(fieldName: keyof FieldType<FireExitModel>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        if (!isValid) {
            console.log("Error: ", fieldName, value, errorMessage);
        }

        return isValid;
    }
    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
