import { FieldType, ViewModelBase } from "@shoothill/core";
import { ContactListModel } from "./ContactListModel";
import { observable, action, IObservableArray, computed } from "mobx";
import { ContactType } from "./ContactType";

export class ContactListViewModel extends ViewModelBase<ContactListModel> {
    private static _instance: ContactListViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this(ContactType.Unknown));
    }

    @observable public errorMessage: string = "";

    public contactType: ContactType = ContactType.Unknown;

    public contacts: IObservableArray<ContactListModel> = observable<ContactListModel>([]);

    @computed get getContacts(): ContactListModel[] {
        // return this.contacts.slice().filter((a) => a.isDeleted === false);
        return this.contacts.slice();
    }

    public constructor(contactType: ContactType) {
        super(new ContactListModel(), false);
        this.setDecorators(ContactListModel);
        this.contactType = contactType;
    }

    public setContactType = (contactType: ContactType) => {
        this.contactType = contactType;
    };

    @action
    public cleanUp = () => {
        // TODO Any Cleanup Code here. e.g. if  a user or project or client etc, wipe it from the instance on page shutdown
        this.contactType = ContactType.Unknown;
        this.contacts.clear();
    };

    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (await this.isModelValid()) {
            //Do stuff here
            this.errorMessage = "Form is valid";
        } else {
            this.errorMessage = "Form is not valid";
        }
    };

    public async isFieldValid(fieldName: keyof FieldType<ContactListModel>): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
