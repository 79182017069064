// Libraries
import React, { useState } from "react";
import { useObserver } from "mobx-react-lite";

// Custom
import { IEGridModel } from "../IEmodels";
import { CommercialViewModel } from "../Commercial.ViewModel";
import { formatCurrencyNoSign } from "Utils/Format";

// Styling & Images
import { SummaryGridView } from "./IEGrid.Styles";
import { makeStyles } from "@material-ui/core";
import { RowDash } from "Views/Project/CustomComponents";
import EditLogo from "Content/AddEdit.svg";
import DeleteLogo from "Content/Bin.svg";
import { formatCreditDebitClass, formatCreditDebitReverseClass } from "Utils/Utils";
import { StoresInstance } from "Globals/Stores";
import { CommercialGridViewModel } from "../Grid/CommercialGrid.ViewModel";
import { Loader } from "@shoothill/core/dist/Components/Loader";

interface ITableProps {
    gridViewModel: CommercialGridViewModel;
    rowEdit?(row: string): void;
    rowDelete?(row: string): void;
    handleRowClick(id: string, title: string | undefined): void;
    isViewOnly: boolean;
    isLoading: boolean;
}

export const IEGridViewList: React.FunctionComponent<ITableProps> = (props) => {
    const { gridViewModel, isViewOnly = false, isLoading } = props;
    const classes = useStyles();

    const DEFAULTPADDINGLEFT = 20;

    const [commercialViewModel] = useState(() => CommercialViewModel.Instance);

    const getNewPaddingLeftValue = (val: number): number => {
        return val <= 160 ? val + DEFAULTPADDINGLEFT : val;
    };

    const formatDebit = (val: number) => {
        return val < 0 ? " debit" : "";
    };

    const handleRowClick = (id: string, title: string | undefined) => {
        props.handleRowClick(id, title);
    };

    interface ISubsProps {
        parentId: string;
        paddingLeft: number;
    }

    /**
     * Recursive component that renders the sub I&Es of sub I&Es.
     * This section could do with improving so that it's more performant.
     * This was implemented as a quick fix.
     * @param subProps parentId and paddingLeft values.
     * @returns A list of sub I&Es for an I&E.
     */
    const RenderSubs: React.FunctionComponent<ISubsProps> = (subProps) => {
        const { parentId, paddingLeft } = subProps;

        return (
            <>
                {gridViewModel.model.getIEs
                    .filter((subs) => subs.parentId === parentId)
                    .filter((subs) => subs.type === "SUB")
                    .map((obj: IEGridModel, index: number) => (
                        <>
                            <li className="ie" key={"ie_" + index}>
                                <div onClick={() => handleRowClick(obj.id, obj.name)} style={{ cursor: "pointer" }} title={obj.name}>
                                    <div className="ie-row-name">
                                        <RowDash style={{ display: "inline-block", marginRight: "10px", paddingLeft: `${paddingLeft}px` }} />
                                        {obj.name}
                                    </div>
                                </div>
                                <div className="centre">Sub</div>
                                <div className="right">{formatCurrencyNoSign(obj.income)}</div>
                                <div className="right">{formatCurrencyNoSign(obj.estimateCost)}</div>
                                <div className={"right" + formatDebit(obj.committedCost)}>{formatCurrencyNoSign(obj.committedCost)}</div>
                                <div className="right">{formatCurrencyNoSign(obj.futureSpend)}</div>
                                <div className="right">{formatCurrencyNoSign(obj.totalExpectedSpend)}</div>
                                <div className={"right" + formatCreditDebitClass(obj.variance)}>{formatCurrencyNoSign(obj.variance)}</div>
                                {/* <div className="right">0</div> */}
                                <div className={"right" + formatCreditDebitReverseClass(obj.roValue)}>{formatCurrencyNoSign(obj.roValue)}</div>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>
                                    {!isViewOnly && (
                                        <span onClick={() => (props.rowEdit ? props.rowEdit(obj.id) : {})} className={classes.inputIcon}>
                                            <img src={EditLogo} />
                                        </span>
                                    )}
                                    {StoresInstance.Domain.AccountStore.getCanEditIE && commercialViewModel.canDeleteIE && !isViewOnly && (
                                        <span onClick={() => (props.rowDelete ? props.rowDelete(obj.id) : {})} className={classes.inputIcon}>
                                            <img src={DeleteLogo} />
                                        </span>
                                    )}
                                </div>
                            </li>
                            <RenderSubs parentId={obj.id} paddingLeft={getNewPaddingLeftValue(paddingLeft)} />
                        </>
                    ))}
            </>
        );
    };

    const RenderGrid = () => {
        return (
            <SummaryGridView>
                <li className="heading">
                    <div>{"I & E name"}</div>
                    <div>{"I & E type"}</div>
                    <div>Income</div>
                    <div>Target cost</div>
                    <div>Committed cost</div>
                    <div>Future spend</div>
                    <div>Total expected spend</div>
                    <div>Variance</div>
                    {/* <div>
                        Change
                        <br />
                        in period
                    </div> */}
                    <div>
                        Risk/
                        <br />
                        opportunity
                    </div>
                    <div></div>
                </li>
                {gridViewModel.model.getIEs
                    .filter((masters) => masters.parentId === null || masters.parentId.length === 0)
                    .filter((masters) => masters.type === "MASTER")
                    .map((obj: IEGridModel, index: number) => (
                        <>
                            <li className="ie" key={"ie_" + index}>
                                <div onClick={() => handleRowClick(obj.id, obj.name)} style={{ cursor: "pointer", display: "flex" }} className="ie-row-name" title={obj.name}>
                                    {obj.name}
                                </div>
                                <div className="centre">Master</div>
                                <div className="right">{formatCurrencyNoSign(obj.income)}</div>
                                <div className="right">{formatCurrencyNoSign(obj.estimateCost)}</div>
                                <div className={"right" + formatDebit(obj.committedCost)}>{formatCurrencyNoSign(obj.committedCost)}</div>
                                <div className="right">{formatCurrencyNoSign(obj.futureSpend)}</div>
                                <div className="right">{formatCurrencyNoSign(obj.totalExpectedSpend)}</div>
                                <div className={"right" + formatCreditDebitClass(obj.variance)}>{formatCurrencyNoSign(obj.variance)}</div>
                                {/* <div className="right">0</div> */}
                                <div className={"right" + formatCreditDebitReverseClass(obj.roValue)}>{formatCurrencyNoSign(obj.roValue)}</div>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>
                                    {!isViewOnly && (
                                        <span onClick={() => (props.rowEdit ? props.rowEdit(obj.id) : {})} className={classes.inputIcon}>
                                            <img src={EditLogo} />
                                        </span>
                                    )}
                                    {commercialViewModel.canDeleteIE && !isViewOnly && (
                                        <span onClick={() => (props.rowDelete ? props.rowDelete(obj.id) : {})} className={classes.inputIcon}>
                                            <img src={DeleteLogo} />
                                        </span>
                                    )}
                                </div>
                            </li>
                            <RenderSubs parentId={obj.id} paddingLeft={DEFAULTPADDINGLEFT} />
                        </>
                    ))}
                {gridViewModel.model.getIEs.filter((masters) => masters.parentId === null || masters.parentId.length === 0).filter((masters) => masters.type === "MASTER").length >
                    0 && (
                    <>
                        <li className="footing">
                            <div className="right"></div>
                            <div className="right">Project total</div>
                            <div className="right">{formatCurrencyNoSign(gridViewModel.model.income)}</div>
                            <div className="right">{formatCurrencyNoSign(gridViewModel.model.estimateCost)}</div>
                            <div className="right">{formatCurrencyNoSign(gridViewModel.model.committedCost)}</div>
                            <div className="right">{formatCurrencyNoSign(gridViewModel.model.futureSpend)}</div>
                            <div className="right">{formatCurrencyNoSign(gridViewModel.model.totalExpectedSpend)}</div>
                            <div className={"right" + formatCreditDebitClass(gridViewModel.model.variance)}>{formatCurrencyNoSign(gridViewModel.model.variance)}</div>
                            {/* <div></div> */}
                            <div className={"right" + formatCreditDebitReverseClass(gridViewModel.model.roValue)}>{formatCurrencyNoSign(gridViewModel.model.roValue)}</div>
                            <div></div>
                        </li>
                    </>
                )}
                {gridViewModel.getInvoices.length > 0 && (
                    <>
                        <li className="footing">
                            <div className="right"></div>
                            <div className="centre">Invoices</div>
                            <div className="right"></div>
                            <div className="right"></div>
                            <div className="right">{formatCurrencyNoSign(gridViewModel.getInvoicesTotal)}</div>
                            <div className="right"></div>
                            <div className="right"></div>
                            <div className="right"></div>
                            {/* <div></div> */}
                            <div className="right"></div>
                            <div></div>
                        </li>
                    </>
                )}

                <li className="footing">
                    <div className="right"></div>
                    <div className="centre">Total</div>
                    <div className="right"></div>
                    <div className="right"></div>
                    <div className="right">{formatCurrencyNoSign(gridViewModel.getTotal)}</div>
                    <div className="right"></div>
                    <div className="right"></div>
                    <div className="right"></div>
                    {/* <div></div> */}
                    <div className="right"></div>
                    <div></div>
                </li>

                {gridViewModel.model.getIEs.filter((masters) => masters.parentId === null || masters.parentId.length === 0).filter((masters) => masters.type === "MASTER").length ==
                    0 &&
                    !isViewOnly && (
                        <>
                            <div style={{ fontSize: "12px", letterSpacing: "-0.24px", marginLeft: "10px" }}>
                                There are currently no I & E's in this project. Click <span style={{ color: "#0095da" }}>create new I & E</span> to add one to this project or
                                <span style={{ color: "#0095da" }}>import an existing project I & E</span>.
                            </div>
                        </>
                    )}
            </SummaryGridView>
        );
    };

    return useObserver(() => (
        <>
            {isLoading && <Loader />}
            {!isLoading && RenderGrid()}
        </>
    ));
};

const useStyles = makeStyles({
    inputIcon: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
});
