import { FieldType, ViewModelBase, isEmptyOrWhitespace, observable } from "@shoothill/core";
import {
    DilapidationReportTypesDTO,
    UpsertDilapidationAndRelatedRequestDTO,
    DilapidationDTO,
    DilapidationAndRelatedResponseDTO,
    DilapidationFormModel,
    DilapidationRelatedResultResponseDTO,
    DilapidationProjectDetailsDTO,
} from "./DilapidationFormModel";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { AppUrls } from "AppUrls";
import { runInAction, action, computed } from "mobx";
import { DilapidationFormItemModel, DilapidationReportItemsDTO } from "./DilapidationFormItemModel";
import { DilapidationFormItemViewModel } from "./DilapidationFormItemViewModel";
import moment from "moment";

export class DilapidationFormViewModel extends ViewModelBase<DilapidationFormModel> {
    constructor(id: string | null, projectId: string | null) {
        super(new DilapidationFormModel());
        this.setDecorators(DilapidationFormModel);
        this.model.id = id;
        this.model.projectId = projectId!;
        isEmptyOrWhitespace(this.model.id) ? this.loadRelated() : this.loadWithRelated();
    }

    public server: ServerViewModel = new ServerViewModel();

    @observable
    public snackMessage = "";

    @observable
    public snackType = "";

    @observable
    public SNACKSUCCESS = "success";

    @observable
    public SNACKERROR = "error";

    @observable
    public snackbarState = false;

    @observable
    public discipline: any = null;

    @observable
    public dilapidationTypes = observable<DilapidationReportTypesDTO>([]);

    @observable
    public dilapidationProjectDetails: DilapidationProjectDetailsDTO | null = null;

    @observable
    public dilapidationReportType: { id: string; displayName: string } | null = null;

    @observable
    public dilapidationReportItemViewModels: DilapidationFormItemViewModel[] = [];

    @computed
    public get getTodayDateFormatted(): string {
        return this.model.createdDate ? moment(this.model.createdDate).format("DD/MM/YYYY").toString() : moment().format("DD/MM/YYYY").toString();
    }

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @action
    public handleDilapidationReportTypeId = (item: { id: string; displayName: string }) => {
        this.dilapidationReportType = item;
        this.setValue("dilapidationReportTypeId", item.id);
    };

    @action
    public reset = () => {
        this.model.reset();
        this.server.reset();
    };

    @action
    public handleCancel(projectId: string | null): void {
        this.reset();
        this.history.push(AppUrls.Client.Project.ConstructionQuality.replace(":projectid", projectId ? projectId : this.model.projectId) + "#dilapidation");
    }

    @action
    public addAnotherInspectedItemArea = () => {
        let model = new DilapidationFormItemModel();
        this.model.dilapidationReportItems.push(model);
        let viewModel = new DilapidationFormItemViewModel(model);
        this.dilapidationReportItemViewModels.push(viewModel);
    };

    @computed
    public get isFormDisabled(): boolean {
        return this.model.id !== null && this.model.id !== undefined && this.model.id !== "";
    }

    @computed
    public get hasId(): boolean {
        return this.model.id !== null && this.model.id !== undefined && this.model.id !== "";
    }

    @action
    private createViewModels() {
        for (const item of this.model.dilapidationReportItems) {
            this.dilapidationReportItemViewModels.push(new DilapidationFormItemViewModel(item));
        }
    }

    public loadRelated = async (): Promise<void> => {
        this.setIsLoading(true);
        return await this.server
            .query<DilapidationRelatedResultResponseDTO>(
                () => this.Get(`${AppUrls.Server.Projects.Construction.Dilapidation.GetDilapidationRelated}\\${this.model.projectId}`),
                (result) => {
                    runInAction(() => {
                        // this.model.fromRelatedDto({
                        //     dilapidationProjectDetails: result.dilapidationProjectDetails,
                        //     dilapidationTypes: result.dilapidationTypes,
                        // });
                        this.dilapidationTypes.replace(result.dilapidationTypes);
                        this.dilapidationProjectDetails = result.dilapidationProjectDetails;
                        this.addAnotherInspectedItemArea();
                    });
                },
            )
            .finally(() => this.setIsLoading(false));
    };

    public loadWithRelated = async (): Promise<void> => {
        this.setIsLoading(true);
        return await this.server
            .query<DilapidationAndRelatedResponseDTO>(
                () => this.Get(`${AppUrls.Server.Projects.Construction.Dilapidation.GetDilapidationAndRelated}\\${this.model.id}`),
                (result) => {
                    runInAction(() => {
                        // this.model.fromDto({
                        //     discipline: result.discipline,
                        //     disciplineCardTypes: result.disciplineCardTypes,
                        //     cardIssuedByUsers: result.cardIssuedByUsers,
                        //     seniorTeamUsers: result.seniorTeamUsers,
                        //     cardReceivedByUsers: result.cardReceivedByUsers,
                        //     disciplineProjectDetails: result.disciplineProjectDetails,
                        // });
                        this.model.fromDto(result);
                        this.dilapidationTypes.replace(result.dilapidationTypes);
                        this.dilapidationProjectDetails = result.dilapidationProjectDetails;
                        this.createViewModels();
                        const selectedReportType: DilapidationReportTypesDTO | undefined = this.dilapidationTypes.find((t) => t.id === this.model.dilapidationReportTypeId);

                        if (selectedReportType) {
                            this.handleDilapidationReportTypeId(selectedReportType);
                        }
                    });
                },
            )
            .finally(() => this.setIsLoading(false));
    };

    public upsert = async (): Promise<void> => {
        this.setIsLoading(true);
        const model: DilapidationDTO = this.model.toDto();

        let itemsToAdd: DilapidationReportItemsDTO[] = [];

        if (!this.hasId) {
            this.dilapidationReportItemViewModels.forEach((item) => {
                const itemToAdd: DilapidationReportItemsDTO = item.model.toDto();
                itemsToAdd.push(itemToAdd);
            });
        }

        const request: UpsertDilapidationAndRelatedRequestDTO = {
            dilapidation: model,
            dilapidationReportItems: itemsToAdd,
        };
        return await this.server
            .command<DilapidationAndRelatedResponseDTO>(
                () => this.Post(AppUrls.Server.Projects.Construction.Dilapidation.Upsert, request),
                (result: DilapidationAndRelatedResponseDTO) => {
                    runInAction(() => {
                        if (result) {
                            this.handleCancel(result.dilapidation.projectId);
                        }
                    });
                },
                this.isMyModelValid,
                "There was an error trying to send the dilapidation report",
            )
            .finally(() => this.setIsLoading(false));
    };

    private isMyModelValid = async (): Promise<boolean> => {
        let isValid = true;

        for (let i = 0; i < this.dilapidationReportItemViewModels.length; i++) {
            let item = this.dilapidationReportItemViewModels[i];

            // Validate each child.
            if ((await item.isModelValid()) === false) {
                isValid = false;
            }
        }

        if ((await this.isModelValid()) === false) {
            isValid = false;
        }

        return isValid;
    };

    public async isFieldValid(fieldName: keyof FieldType<DilapidationFormModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);
        if (this.server.IsSubmitted) {
            switch (fieldName) {
                case "dilapidationReportTypeId": {
                    const result = this.validateDilapidationReportTypeId;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "authorisationSignatureURL": {
                    const result = this.validateAuthorisationSignatureURL;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
            }
        } else {
            errorMessage = "";
            isValid = true;
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    @computed
    private get validateDilapidationReportTypeId() {
        const errorMessage = this.model.validateDilapidationReportTypeId;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateAuthorisationSignatureURL() {
        const errorMessage = this.model.validateAuthorisationSignatureURL;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
