import { ModelBase, observable } from "@shoothill/core";
import { action } from "mobx";

import { IInductionType, InductionType } from "Globals/Models/Enums/InductionType";

export class InductionAdminItemModel extends ModelBase<InductionAdminItemModel, any> {
    // #region Defaults

    public static readonly DEFAULT_INDUCTIONTYPEID = null;
    public static readonly DEFAULT_INDUCTIONTYPES = [];

    // #endregion Defaults

    // #region Properties

    public inductionTypeId: string | null = InductionAdminItemModel.DEFAULT_INDUCTIONTYPEID;

    @observable
    public inductionTypes = observable<InductionType>(InductionAdminItemModel.DEFAULT_INDUCTIONTYPES);

    @observable
    public id: string = "";

    @observable
    public fullName: string = "";
    @observable
    public firstName: string = "";
    @observable
    public lastName: string = "";
    @observable
    public priorityEmployerName: string = "";
    @observable
    public photographUrl: string = "";
    @observable
    public visitorTypeId: string = "";
    @observable
    public visitorTypeName: string = "";

    @observable
    public createdAt: string = "";

    @observable
    public isDeleted: boolean = false;

    @observable
    public inductionStatusId: string = "";

    @observable
    public age: number = 0;

    @observable
    public confirmOver18: boolean = false;

    @observable
    public confirmAdjustments: boolean = false;

    @observable
    public adjustmentDetails: string | null = null;

    @observable
    public healthProblems: boolean = false;

    @observable
    public healthDetails: string | null = null;

    @observable
    public skillsTrainingOrQualifications: InductionQualificationDTO[] = [];

    @observable
    public plantAndEquipmentTrainingOrQualifications: InductionQualificationDTO[] = [];

    @observable
    public personalProtectiveEquipment = observable<InductionPersonalProtectiveEquipmentDTO>([]);

    @observable
    public general: boolean = false;

    @observable
    public trainingAndEquipment: boolean = false;

    @observable
    public ppe: boolean = false;

    @observable
    public rams: boolean = false;

    @observable
    public housekeeping: boolean = false;

    @observable
    public rulesDiscipline: boolean = false;

    @observable
    public siteSpecificBriefing: boolean = false;

    @observable
    public signatureURL: string = "";

    @observable
    public approvedByUserName: string | null = null;

    @observable
    public approvedDate: string | null = null;

    // #endregion Properties

    // #region Actions

    @action
    public fromDto(dto: InductionAdminItemDTO): void {
        throw new Error("Method not implemented.");
    }

    @action
    public fromExtendedDto(dto: InductionAdminItemDTO, inductionTypes: IInductionType[]): void {
        this.inductionTypes.replace(
            inductionTypes.map((dto: IInductionType) => {
                const enumType = new InductionType();

                enumType.fromDto(dto);

                return enumType;
            }),
        );

        // We don't need to process the lists differently here are we're just using DTOs instead of models.
        for (let key in dto) {
            if (dto.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(dto[key]);
                } else {
                    this[key] = dto[key];
                }
            }
        }
    }

    public toDto() {}

    // #endregion Actions

    // #region Custom Validation

    // #endregion Custom Validation
}

export interface InductionAdminItemDTO {
    id: string;
    fullName: string;
    firstName: string;
    lastName: string;
    priorityEmployerName: string;
    photographUrl: string;
    visitorTypeId: string;
    visitorTypeName: string;
    createdAt: string;
    isDeleted: boolean;
    inductionStatusId: string;
    age: number;
    confirmOver18: boolean;
    confirmAdjustments: boolean;
    cdjustmentDetails: string | null;
    healthProblems: boolean;
    healthDetails: string | null;
    skillsTrainingOrQualifications: InductionQualificationDTO[];
    plantAndEquipmentTrainingOrQualifications: InductionQualificationDTO[];
    personalProtectiveEquipment: InductionPersonalProtectiveEquipmentDTO[];
    general: boolean;
    trainingAndEquipment: boolean;
    ppe: boolean;
    rams: boolean;
    housekeeping: boolean;
    rulesDiscipline: boolean;
    siteSpecificBriefing: boolean;
    signatureURL: string;
    approvedByUserName: string | null;
    approvedDate: string | null;
    inductionTypeId: string | null;
}

export interface InductionQualificationDTO {
    id: string;
    inductionUserId: string;
    inductionSectionType: string;
    idRollNumber: string;
    name: string;
    passed: boolean | null;
    photoEvidenceData: string;
    photoEvidenceFileName: string;
    photoEvidenceUrl: string;
    typeLevel: string;
    expiryDate: string;
}

export interface InductionPersonalProtectiveEquipmentDTO {
    id: string;
    displayName: string;
    types: string;
}
