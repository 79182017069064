import * as MobX from "mobx";
import { ModelBase } from "@shoothill/core";

export class RequisitionItemModel extends ModelBase<RequisitionItemModel, RequisitionItemModelDTO> {
    public id: string = "";
    public iELineId: string | null = null;
    public iEId: string | null = null;

    @MobX.observable
    public itemDescription: string = "";

    @MobX.observable
    public unitPrice: number = 0;

    @MobX.observable
    public quantity: number = 0;

    @MobX.observable
    public poNumber: number | null = null;

    @MobX.observable
    public formattedPONumber: string | null = null;

    fromDto(model: RequisitionItemModelDTO): void {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    public toDto(): RequisitionItemModelDTO {
        const dto: RequisitionItemModelDTO = {
            id: this.id,
            iELineId: this.id,
            iEId: this.id,
            itemDescription: this.id,
            unitPrice: this.unitPrice,
            quantity: this.quantity,
            poNumber: this.poNumber,
            formattedPONumber: this.formattedPONumber,
        };

        return dto;
    }
}

export type RequisitionItemModelDTO = {
    id: string;
    iELineId: string;
    iEId: string;
    itemDescription: string;
    unitPrice: number;
    quantity: number;
    poNumber: number | null;
    formattedPONumber: string | null;
};
