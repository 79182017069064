import * as MobX from "mobx";
import { ModelBase } from "@shoothill/core";
import { formatCurrencyFromPounds } from "Utils/Format";

export class ApprovedVariationListModel extends ModelBase<ApprovedVariationListModel, ApprovedVariationListItemDTO> {
    public id: string = "";

    @MobX.observable
    public variationNumber: number | null = null;

    @MobX.observable
    public variationNumberFormatted: string = "";

    @MobX.observable
    public income: number | null = null;

    @MobX.observable
    public targetCost: number | null = null;

    @MobX.observable
    public margin: number | null = null;

    @MobX.observable
    public committedCost: number | null = null;

    @MobX.observable
    public futureSpend: number | null = null;

    @MobX.observable
    public totalExpectedSpend: number | null = null;

    @MobX.observable
    public variance: number | null = null;

    @MobX.observable
    public changeInPeriod: number | null = null;

    @MobX.observable
    public riskAndOpportunity: number | null = null;

    @MobX.observable
    public description: string = "";

    fromDto(model: ApprovedVariationListItemDTO): void {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    toDto(model: ApprovedVariationListItemDTO): void {}

    @MobX.action
    public reset = () => {};

    public toTableModel = (): ApprovedVariationTableModel => {
        const po: ApprovedVariationTableModel = {
            id: this.id,
            variationNumberFormatted: this.variationNumberFormatted,
            income: this.income ? formatCurrencyFromPounds(this.income) : "-",
            targetCost: this.targetCost ? formatCurrencyFromPounds(this.targetCost) : "-",
            margin: this.margin ? this.margin.toString() : "-",
            committedCost: this.committedCost ? formatCurrencyFromPounds(this.committedCost) : "-",
            futureSpend: this.futureSpend ? formatCurrencyFromPounds(this.futureSpend) : "-",
            totalExpectedSpend: this.totalExpectedSpend ? formatCurrencyFromPounds(this.totalExpectedSpend) : "-",
            variance: this.variance,
            changeInPeriod: this.changeInPeriod ? formatCurrencyFromPounds(this.changeInPeriod) : "-",
            riskAndOpportunity: this.riskAndOpportunity ? formatCurrencyFromPounds(this.riskAndOpportunity) : "-",
        };
        return po;
    };
}

export type ApprovedVariationTableModel = {
    id: string;
    variationNumberFormatted: string;
    income: string;
    targetCost: string;
    margin: string;
    committedCost: string;
    futureSpend: string;
    totalExpectedSpend: string;
    variance: number | null;
    changeInPeriod: string;
    riskAndOpportunity: string;
};

export interface ApprovedVariationListItemDTO {
    id: string;
    variationNumber: number | null;
    income: number | null;
    targetCost: number | null;
    margin: number | null;
    committedCost: number | null;
    futureSpend: number | null;
    totalExpectedSpend: number | null;
    variance: number | null;
    changeInPeriod: number | null;
    riskAndOpportunity: number | null;
    description: string;
}
