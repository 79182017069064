import { ApiResult, FieldType, KeyValuePair, ViewModelBase } from "@shoothill/core";
import { CommercialModel, CommercialModelDTO } from "./Commercial.Model";
import { observable, action, runInAction, computed } from "mobx";
import { AppUrls } from "AppUrls";
import { EditIEModel } from "./IEViewModel";
import { IEGridCategoryModel, IEGridItemModel, IEGridModel, IEGridSubcategoryModel } from "./IEmodels";
import { IEGridItemViewModel } from "./IEmodels/IEGridItemViewModel";
import { ITab } from "Components/Tabs/Tabs";

import { DeleteIncomeAndExpenditureViewModel } from "./IncomeAndExpenditure/DeleteIncomeAndExpenditureViewModel";
import { ProjectGeneralViewModel } from "../General/ProjectGeneralViewModel";
import { VARGridItemModel } from "../Variations/VARGridItemModel";
import { StoresInstance } from "Globals/Stores";
import { CommercialGridViewModel } from "./Grid/CommercialGrid.ViewModel";
import { formatDebit } from "Utils/Utils";

export interface ActiveIEItem {
    activeProject: string | null;
    activeIE: string | null;
    activeCategory: number;
    activeSubCategory: number;
    activeDescription: number;
    isPurchaseOrderActive: boolean;
    isIEItemMaterialActive: boolean;
}

interface ActiveVariationItem extends ActiveIEItem {
    activeVariation: number;
}
export class CommercialViewModel extends ViewModelBase<CommercialModel> {
    private static _instance: CommercialViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable public errorMessage: string = "";

    @observable public commercialGridViewModel: CommercialGridViewModel = new CommercialGridViewModel();

    private readonly defaultActiveIEItem: ActiveIEItem = {
        activeProject: null,
        activeIE: null,
        activeCategory: -1,
        activeSubCategory: -1,
        activeDescription: -1,
        isPurchaseOrderActive: false,
        isIEItemMaterialActive: false,
    };

    private readonly defaultActiveVariationItem: ActiveVariationItem = {
        activeProject: null,
        activeIE: null,
        activeVariation: -1,
        activeCategory: -1,
        activeSubCategory: -1,
        activeDescription: -1,
        isPurchaseOrderActive: false,
        isIEItemMaterialActive: false,
    };

    private readonly defaultActiveCPSItem: ActiveVariationItem = {
        activeProject: null,
        activeIE: null,
        activeVariation: -1,
        activeCategory: -1,
        activeSubCategory: -1,
        activeDescription: -1,
        isPurchaseOrderActive: false,
        isIEItemMaterialActive: false,
    };

    // Assigning defaultActiveIEItem as default doesn't work. Leaves it as undefined...
    @observable
    private activeIEItem: ActiveIEItem = {
        activeProject: null,
        activeIE: null,
        activeCategory: -1,
        activeSubCategory: -1,
        activeDescription: -1,
        isPurchaseOrderActive: false,
        isIEItemMaterialActive: false,
    };

    @observable
    private activeVariationItem: ActiveVariationItem = {
        activeProject: null,
        activeIE: null,
        activeVariation: -1,
        activeCategory: -1,
        activeSubCategory: -1,
        activeDescription: -1,
        isPurchaseOrderActive: false,
        isIEItemMaterialActive: false,
    };

    @observable
    private activeCPSItem: ActiveVariationItem = {
        activeProject: null,
        activeIE: null,
        activeVariation: -1,
        activeCategory: -1,
        activeSubCategory: -1,
        activeDescription: -1,
        isPurchaseOrderActive: false,
        isIEItemMaterialActive: false,
    };

    public constructor() {
        super(new CommercialModel(), false);
        this.setDecorators(CommercialModel);
    }

    // #region Grid selections

    @computed
    public get getActiveDescription(): number {
        return this.activeIEItem.activeDescription;
    }

    @computed
    public get getActiveSubCategory(): number {
        return this.activeIEItem.activeSubCategory;
    }

    @computed
    public get getActiveCategory(): number {
        return this.activeIEItem.activeCategory;
    }

    @computed
    public get getActiveIE(): string | null {
        return this.activeIEItem.activeIE;
    }

    @computed
    public get getActiveProject(): string | null {
        return this.activeIEItem.activeProject;
    }

    @computed
    public get getIsPurchaseOrderActive(): boolean {
        return this.activeIEItem.isPurchaseOrderActive;
    }

    @computed
    public get isIEIemMaterialActive(): boolean {
        return this.activeIEItem.isIEItemMaterialActive;
    }

    @action
    public setActiveDescription = (val: number): void => {
        this.activeIEItem.activeDescription = val;
    };

    @action
    public setActiveSubCategory = (val: number): void => {
        this.activeIEItem.activeSubCategory = val;
    };

    @action
    public setActiveCategory = (val: number): void => {
        this.activeIEItem.activeCategory = val;
    };

    @action
    private setActiveIE = (val: string | null): void => {
        this.activeIEItem.activeIE = val;
    };

    @action
    private setActiveProject = (val: string | null): void => {
        this.activeIEItem.activeProject = val;
    };

    @action
    public setIsPurchaseOrderActive = (val: boolean): void => {
        this.activeIEItem.isPurchaseOrderActive = val;
    };

    @action
    public setIEItemMaterialActive = (val: boolean): void => {
        this.activeIEItem.isIEItemMaterialActive = val;
    };

    @action
    private resetActiveIEGridIds = (): void => {
        this.activeIEItem = this.defaultActiveIEItem;
    };

    @action
    public setActiveProjectData = (ieId: string | null, projectId: string | null) => {
        this.setActiveProject(projectId);
        this.setActiveVariationProject(projectId);
        this.setActiveCPSProject(projectId);
        this.setActiveIE(ieId);
        this.setActiveVariationIE(ieId);
        this.setActiveCPSIE(ieId);
    };

    @computed
    public get getActiveVariationDescription(): number {
        return this.activeVariationItem.activeDescription;
    }

    @computed
    public get getActiveVariationSubCategory(): number {
        return this.activeVariationItem.activeSubCategory;
    }

    @computed
    public get getActiveVariationCategory(): number {
        return this.activeVariationItem.activeCategory;
    }

    @computed
    public get getActiveVariationIE(): string | null {
        return this.activeVariationItem.activeIE;
    }

    @computed
    public get getActiveVariationProject(): string | null {
        return this.activeVariationItem.activeProject;
    }

    @computed
    public get getActiveVariation(): number {
        return this.activeVariationItem.activeVariation;
    }

    @computed
    public get getIsPurchaseOrderActiveVariation(): boolean {
        return this.activeVariationItem.isPurchaseOrderActive;
    }

    @action
    public setActiveVariationDescription = (val: number): void => {
        this.activeVariationItem.activeDescription = val;
    };

    @action
    public setActiveVariationSubCategory = (val: number): void => {
        this.activeVariationItem.activeSubCategory = val;
    };

    @action
    public setActiveVariationCategory = (val: number): void => {
        this.activeVariationItem.activeCategory = val;
    };

    @action
    private setActiveVariationIE = (val: string | null): void => {
        this.activeVariationItem.activeIE = val;
    };

    @action
    private setActiveVariationProject = (val: string | null): void => {
        this.activeVariationItem.activeProject = val;
    };

    @action
    public setActiveVariation = (val: number): void => {
        this.activeVariationItem.activeVariation = val;
    };

    @action
    public setIsPurchaseOrderActiveVariation = (val: boolean): void => {
        this.activeVariationItem.isPurchaseOrderActive = val;
    };

    @action
    private resetActiveVariationGridIds = (): void => {
        this.activeVariationItem = this.defaultActiveVariationItem;
    };

    @computed
    public get getActiveCPSDescription(): number {
        return this.activeCPSItem.activeDescription;
    }

    @computed
    public get getActiveCPSSubCategory(): number {
        return this.activeCPSItem.activeSubCategory;
    }

    @computed
    public get getActiveCPSCategory(): number {
        return this.activeCPSItem.activeCategory;
    }

    @computed
    public get getActiveCPSIE(): string | null {
        return this.activeCPSItem.activeIE;
    }

    @computed
    public get getActiveCPSProject(): string | null {
        return this.activeCPSItem.activeProject;
    }

    @computed
    public get getActiveCPS(): number {
        return this.activeCPSItem.activeVariation;
    }

    @computed
    public get getIsPurchaseOrderActiveCPS(): boolean {
        return this.activeCPSItem.isPurchaseOrderActive;
    }

    @action
    public setActiveCPSDescription = (val: number): void => {
        this.activeCPSItem.activeDescription = val;
    };

    @action
    public setActiveCPSSubCategory = (val: number): void => {
        this.activeCPSItem.activeSubCategory = val;
    };

    @action
    public setActiveCPSCategory = (val: number): void => {
        this.activeCPSItem.activeCategory = val;
    };

    @action
    private setActiveCPSIE = (val: string | null): void => {
        this.activeCPSItem.activeIE = val;
    };

    @action
    private setActiveCPSProject = (val: string | null): void => {
        this.activeCPSItem.activeProject = val;
    };

    @action
    public setActiveCPS = (val: number): void => {
        this.activeCPSItem.activeVariation = val;
    };

    @action
    public setIsPurchaseOrderActiveCPS = (val: boolean): void => {
        this.activeCPSItem.isPurchaseOrderActive = val;
    };

    @action
    private resetActiveCPSGridIds = (): void => {
        this.activeCPSItem = this.defaultActiveCPSItem;
    };

    @action
    public resetActiveGridData = () => {
        this.resetActiveIEGridIds();
        this.resetActiveVariationGridIds();
        this.resetActiveCPSGridIds();
    };

    // #endregion Grid selections

    // #region Create or Edit Income and Expenditure

    @action
    public addIncomeAndExpenditure = () => {
        if (StoresInstance.Domain.AccountStore.getCanAddIE) {
            this.history.push(AppUrls.Client.Project.CommercialIEAdd.replace(":projectid", ProjectGeneralViewModel.Instance.model.id));
        }
    };

    @action
    public editIncomeAndExpenditure = (id: string) => {
        this.history.push(AppUrls.Client.Project.CommercialIEEdit.replace(":projectid", ProjectGeneralViewModel.Instance.model.id).replace(":id", id));
    };

    // #endregion Create or Edit Income and Expenditure

    // #region Delete Income and Expenditure

    @observable
    public deleteIncomeAndExpenditureViewModel: DeleteIncomeAndExpenditureViewModel | null = null;

    @action
    public displayDeleteIncomeAndExpenditure = (id: string) => {
        this.deleteIncomeAndExpenditureViewModel = new DeleteIncomeAndExpenditureViewModel(id, this.cancelDeleteIncomeAndExpenditure, this.deleteIncomeAndExpenditure);
    };

    @action
    public cancelDeleteIncomeAndExpenditure = () => {
        this.deleteIncomeAndExpenditureViewModel = null;
    };

    @action
    public deleteIncomeAndExpenditure = (incomeAndExpenditureId: string) => {
        this.deleteIncomeAndExpenditureViewModel = null;

        // Get table row or entire table from sever...
        this.apiGetIECommercial(ProjectGeneralViewModel.Instance.model.id);
    };

    @computed
    public get canDeleteIE(): boolean {
        return ProjectGeneralViewModel.Instance.isDraft && StoresInstance.Domain.AccountStore.getCanEditIE;
    }

    @computed
    public get canEditIE(): boolean {
        return StoresInstance.Domain.AccountStore.getCanEditIE;
    }

    // #endregion Income and Expenditure

    @action
    public reset = () => {
        this.model.reset();
    };

    @action
    public getIEForEdit = (id: string, projectId: string) => {
        let model = this.commercialGridViewModel.model.getIEs.find((i) => i.id === id);
        if (model !== undefined) {
            let retVal: EditIEModel = {
                id: id,
                cell: model.cell,
                projectId: projectId,
                parentId: model.parentId,
                nameId: model.nameId,
                name: model.displayName,
                isDeleted: false,
            };
            return retVal;
        }
        return null;
    };

    /**
     * region: handle IE Items/Lines as ViewModel
     */
    @observable
    private ieGridItemsViewModels: IEGridItemViewModel[] = [];

    @computed
    public get ieGridItems(): IEGridItemViewModel[] {
        return this.ieGridItemsViewModels;
    }
    /**
     * end-region: handle IE Items/Lines as ViewModel
     */

    /**
     * region: get IE and Package(IE) name
     */
    @computed
    public get ieNameAndIeId(): KeyValuePair<any>[] {
        return this.commercialGridViewModel.model.getIEs.map((obj: IEGridModel) => {
            let name = obj.name;
            let nameKey = name != undefined ? name : "";
            const retVal: KeyValuePair<any> = {
                key: obj.id,
                value: nameKey,
            };
            return retVal;
        });
    }
    /**
     * end-region: get IE and Package(IE) name
     */

    @action
    public formatDebit(val: number) {
        return formatDebit(val);
    }

    /**
     * IE Grid helpers - end
     */

    @action
    public apiGetIECommercial = async (val: string): Promise<ApiResult<CommercialModelDTO>> => {
        const fromDate = new Date();
        const toDate = this.model.filterEndDate;
        const request: IEandRelatedRequest = {
            projectId: val,
            fromDate: null, //GetStartOfWeek(fromDate).toISOString(),
            toDate: toDate,
        };
        let apiResult = await this.Post<CommercialModelDTO>(AppUrls.Server.Projects.Commercial.GetIECommercial, request);
        if (apiResult) {
            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.commercialGridViewModel.model.fromDto(apiResult.payload.commercialGrid);
                    this.model.fromDto(apiResult.payload);
                    this.commercialGridViewModel.populateInvoiceListItemViewModels({
                        invoiceItem: apiResult.payload.commercialGrid.invoiceItem,
                        invoiceStatusType: apiResult.payload.commercialGrid.invoiceStatusType,
                        projects: [],
                        suppliers: [],
                        totalCount: 0,
                    });
                });
            } else {
                console.log(apiResult.errors);
            }
        }
        return apiResult;
    };

    public async isFieldValid(fieldName: keyof FieldType<CommercialModel>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    @observable
    private IEtype = "master";

    @computed
    public get ietypeValue(): string {
        return this.IEtype;
    }

    @action
    public setIEtype(val: string) {
        this.IEtype = val;
    }

    @observable
    private IElink = "";

    @computed
    public get ielinkValue(): string {
        return this.ielinkValue;
    }

    @action
    public setIEvalue(val: string) {
        this.IElink = val;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    /**
     * Modal view status
     */
    DEFAULTVIEW = "master";
    EDITVIEW = "edit";
    MODALSTATE = false;
    VARIATIONMODALSTATE = false;
    DELETEIEMODALSTATE = false;

    @observable
    public showIEDeleteModal = this.DELETEIEMODALSTATE;

    @action
    public setIEDeleteModal(val: boolean) {
        this.showIEDeleteModal = val;
    }

    @observable
    public showModal = this.MODALSTATE;

    @action
    public setAddEditModal(val: boolean) {
        this.showModal = val;
    }

    @observable
    public showVariationModal = this.VARIATIONMODALSTATE;

    @action
    public setVariationModal(val: boolean) {
        this.showVariationModal = val;
    }

    @observable
    public showView = this.DEFAULTVIEW;

    @action
    public setShowView(val: string) {
        this.showView = val;
    }
    /**
     * Modal view status - end
     */

    /**
     * Tabs view status
     */
    @observable
    public TAB_IE = "ie";

    @observable
    public TAB_VAR = "var";

    @observable
    public TAB_PO = "po";

    @observable
    public TAB_INV = "inv";

    @observable
    public TAB_REP = "rep";

    @observable
    public tabOptions: ITab[] = [
        { key: this.TAB_IE, title: "I & E", hash: "ie" },
        { key: this.TAB_VAR, title: "Variations", hash: "var" },
        { key: this.TAB_PO, title: "Purchase orders", hash: "po" },
        /* { key: this.TAB_INV, title: "Invoices", hash: "inv" }, */
        { key: this.TAB_REP, title: "Reports", hash: "rep" },
    ];

    @observable
    public TAB_Default = this.TAB_IE;
    /**
     * Tabs view status - end
     */
}

export interface IEandRelatedRequest {
    projectId: string;
    fromDate: string | null;
    toDate: string | null;
}

export interface IEItemLineUpsert {
    item: IEGridItemModel;
    catIndex: number;
    subIndex: number;
    ieId: string;
}

export interface VARItemLineUpsert {
    item: VARGridItemModel;
    catIndex: number;
    subIndex: number;
    ieId: string;
}

export interface IESubcategoryUpsert {
    item: IEGridSubcategoryModel;
    catIndex: number;
    catGuid: string;
    ieId: string;
}

export interface IECategoryUpsert {
    item: IEGridCategoryModel;
    ieId: string;
}

export interface IEItemModalRequest {
    categoryId: number;
    subCategoryId: number;
    descriptionId: number;
    itemId: string;
}

export interface VARItemModalRequest {
    categoryId: number;
    subCategoryId: number;
    descriptionId: number;
    itemId: string;
}
