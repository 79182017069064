import { action, observable, computed } from "mobx";
import { ModelBase } from "@shoothill/core";
import { ToolboxTalkDTO } from "./ToolboxTalkModel";
import { GenericPhotoListItemDTO, GenericPhotoListItemModel } from "../Incidents/Indicent/Supporting/GenericPhotoListItemModel";
import { InductionListItemDTO, InductionListItemModel } from "./InductionUserListItemModel";
import { InductionUserModel } from "./InductionUserModel";
import { InductionUserLookupDTO } from "../Inspections/InductionUserLookupModel";
import { ToolboxTalkFileDTO, ToolboxTalkFileModel } from "./ToolboxTalkFileModel";

export class ToolboxTalkFormModel extends ModelBase<ToolboxTalkFormModel, any> {
    public static readonly DEFAULT_ID = null;
    public static readonly DEFAULT_PROJECTID: string | null = null;
    public static readonly DEFAULT_CREATED_BY_USER_ID: string | null = null;
    public static readonly DEFAULT_CREATED_DATE: string | null = null;
    public static readonly DEFAULT_SUBJECT: string = "";
    public static readonly DEFAULT_IS_DELETED: boolean = false;
    public static readonly DEFAULT_ROW_VERSION: string | null = null;
    public static readonly DEFAULT_POINTS_DISCUSSED: string = "";
    public static readonly DEFAULT_COMMENTS: string = "";
    public static readonly DEFAULT_TALKER_USER_ID: string | null = null;
    public static readonly DEFAULT_LAST_UPDATED_BY_USER_ID: string | null = null;
    public static readonly DEFAULT_LAST_UPDATED_DATE: Date | null = null;

    // #endregion Constants and Defaults

    // #region Properties

    @observable
    public id: string | null = ToolboxTalkFormModel.DEFAULT_ID;

    @observable
    public projectId: string | null = ToolboxTalkFormModel.DEFAULT_PROJECTID;

    @observable
    public subject: string = ToolboxTalkFormModel.DEFAULT_SUBJECT;

    @observable
    public createdByUserId: string | null = ToolboxTalkFormModel.DEFAULT_CREATED_BY_USER_ID;

    @observable
    public createdDate: string | null = ToolboxTalkFormModel.DEFAULT_CREATED_DATE;

    @observable
    public isDeleted: boolean = ToolboxTalkFormModel.DEFAULT_IS_DELETED;

    @observable
    public rowVersion: string | null = ToolboxTalkFormModel.DEFAULT_ROW_VERSION;

    @observable
    public talkerUserId: string | null = ToolboxTalkFormModel.DEFAULT_TALKER_USER_ID;

    @observable
    public inductionUserId: string | null = ToolboxTalkFormModel.DEFAULT_TALKER_USER_ID;

    @observable
    public pointsDiscussed: string = ToolboxTalkFormModel.DEFAULT_POINTS_DISCUSSED;

    @observable
    public comments: string = ToolboxTalkFormModel.DEFAULT_COMMENTS;

    @observable
    public lastUpdatedByUserId: string | null = ToolboxTalkFormModel.DEFAULT_LAST_UPDATED_BY_USER_ID;

    @observable
    public lastUpdatedDate: Date | null = ToolboxTalkFormModel.DEFAULT_LAST_UPDATED_DATE;

    @observable
    public toolboxTalkFiles: ToolboxTalkFileModel[] = [];

    @observable
    public inductionUsersList: InductionListItemModel[] = [];

    public inductionUserModels = observable<InductionUserModel>([]);

    public readonly notInSystemOption: ToolboxTalkUserDTO = { id: "notInSystem", displayName: "Not in system" };

    // #endregion Properties

    // #endregion Properties

    @computed
    public get hasId(): boolean {
        return this.id !== null && this.id !== undefined && this.id !== "";
    }

    // #region Actions

    @action
    public reset = () => {
        this.id = ToolboxTalkFormModel.DEFAULT_ID;
        this.projectId = ToolboxTalkFormModel.DEFAULT_PROJECTID;
        this.createdByUserId = ToolboxTalkFormModel.DEFAULT_CREATED_BY_USER_ID;
        this.createdDate = ToolboxTalkFormModel.DEFAULT_CREATED_DATE;
        this.isDeleted = ToolboxTalkFormModel.DEFAULT_IS_DELETED;
        this.rowVersion = ToolboxTalkFormModel.DEFAULT_ROW_VERSION;
        this.talkerUserId = ToolboxTalkFormModel.DEFAULT_TALKER_USER_ID;
        this.subject = ToolboxTalkFormModel.DEFAULT_SUBJECT;
        this.talkerUserId = ToolboxTalkFormModel.DEFAULT_TALKER_USER_ID;
        this.pointsDiscussed = ToolboxTalkFormModel.DEFAULT_POINTS_DISCUSSED;
        this.comments = ToolboxTalkFormModel.DEFAULT_COMMENTS;
        this.lastUpdatedByUserId = ToolboxTalkFormModel.DEFAULT_LAST_UPDATED_BY_USER_ID;
        this.lastUpdatedDate = ToolboxTalkFormModel.DEFAULT_LAST_UPDATED_DATE;

        this.toolboxTalkFiles.length = 0;
    };

    @action
    public fromDto(dto: ToolboxTalkFormAndRelatedResponseDTO): void {
        const ToolboxTalkDto = dto.toolboxTalk;

        for (let key in ToolboxTalkDto) {
            if (ToolboxTalkDto.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(ToolboxTalkDto[key]);
                } else {
                    this[key] = ToolboxTalkDto[key];
                }
            }
        }

        this.toolboxTalkFiles.length = 0;
        this.inductionUsersList.length = 0;

        // Manually process the child array otherwise we will end up with an array of dtos being stored in the array of models.
        let processedFiles: ToolboxTalkFileModel[] = [];
        let processedInductionUsers: InductionListItemModel[] = [];

        for (const file of dto.toolboxTalkFiles) {
            const fileToAdd = new ToolboxTalkFileModel();
            fileToAdd.fromDto(file);
            processedFiles.push(fileToAdd);
        }

        for (const inductionUser of dto.inductionUsersList) {
            const inductionUserToAdd = new InductionListItemModel();
            inductionUserToAdd.fromDto(inductionUser);
            processedInductionUsers.push(inductionUserToAdd);
        }

        this.toolboxTalkFiles = [...this.toolboxTalkFiles, ...processedFiles];
        this.inductionUsersList = [...this.inductionUsersList, ...processedInductionUsers];
    }

    public toDto() {
        return {
            id: this.id,
            projectId: this.projectId,
            createdByUserId: this.createdByUserId,
            createdDate: this.createdDate,
            isDeleted: this.isDeleted,
            rowVersion: this.rowVersion,
            subject: this.subject,
            talkerUserId: this.talkerUserId,
            pointsDiscussed: this.pointsDiscussed,
            comments: this.comments,
            lastUpdatedByUserId: this.lastUpdatedByUserId,
            lastUpdatedDate: this.lastUpdatedDate,
        };
    }

    // #endregion Actions

    // #region Custom Validation

    @computed
    public get validateSubject(): string {
        return this.subject === ToolboxTalkFormModel.DEFAULT_SUBJECT || this.subject?.trim() === "" ? "Please provide a subject" : "";
    }

    @computed
    public get validatePointsDiscussed(): string {
        return this.pointsDiscussed === ToolboxTalkFormModel.DEFAULT_POINTS_DISCUSSED || this.pointsDiscussed?.trim() === "" ? "Please provide the points discussed" : "";
    }

    @computed
    public get validateComments(): string {
        return this.comments === ToolboxTalkFormModel.DEFAULT_COMMENTS || this.comments?.trim() === "" ? "Please provide comments" : "";
    }

    @computed
    public get validateTalkerUserId(): string {
        return this.talkerUserId === ToolboxTalkFormModel.DEFAULT_TALKER_USER_ID ? "Please provide a talker" : "";
    }
}

export interface ToolboxTalkFormDTO {
    id: string | null;
    projectId: string | null;
    createdByUserId: string | null;
    createdDate: string | null;
    isDeleted: boolean;
    rowVersion: string | null;
    talkerUserId: string | null;
    subject: string | null;
    pointsDiscussed: string | null;
    comments: string | null;
    lastUpdatedByUserId: string | null;
    lastUpdatedDate: Date | null;
}

export interface ToolboxTalkFormRelatedResponseDTO {
    toolboxTalk: ToolboxTalkDTO;
    toolboxTalkProjectDetails: ToolboxTalkFormProjectDetailsDTO;
    talkerUsers: ToolboxTalkUserDTO[];
    inductionUsers: InductionUserLookupDTO[];
}

export interface ToolboxTalkFormAndRelatedResponseDTO extends ToolboxTalkFormRelatedResponseDTO {
    toolboxTalkFiles: ToolboxTalkFileDTO[];
    inductionUsersList: InductionListItemDTO[];
}

export interface ToolboxTalkFormProjectDetailsDTO {
    projectReference: string;
    projectName: string;
}

export interface ToolboxTalkUserDTO {
    id: string;
    displayName: string;
}

export interface IncidentFormWitnessStatementPhotoDTO {
    id: string | null;
    toolboxTalkId: string | null;
    fileName: string | null;
    photoURL: string | null;
    isDeleted: boolean;
    createdDate: string | null;
    createdByUserId: string | null;
}

export interface UpsertToolboxTalkFormAndRelatedRequestDTO {
    toolboxTalk: ToolboxTalkFormDTO;
    toolboxTalkFiles: ToolboxTalkFileDTO[];
    toolboxTalkSignatures: InductionListItemDTO[];
}
