import { DefaultButton } from "Components/Buttons/Buttons";
import styled from "styled-components";

export const ApprovalPanelContainer = styled.div`
    .darwin-dialog-body {
        padding: 0px !important;
    }

    .secondary-row {
        line-height: 0.8;

        > span {
            white-space: pre-wrap;
        }
    }
`;

export const ApprovalPanelFooter = styled.div`
    .amend-reason-container {
        .amend-reason-button-container {
            align-items: center;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;

            > button {
                width: 130px;
            }
        }
    }

    .uploadedFile {
        grid-template-columns: 1fr !important;
    }
`;

export const ApprovalPanelButtonBase = styled(DefaultButton)`
    margin: 5px 0px;
`;

export const ApprovalPanelApproveButton = styled(ApprovalPanelButtonBase)`
    background-color: #52bb71;
`;

export const ApprovalPanelAmendButton = styled(ApprovalPanelButtonBase)`
    background-color: #ffcc00;
    color: black;
`;

export const ApprovalPanelRejectButton = styled(ApprovalPanelButtonBase)`
    background-color: #df3a3a;
`;

export const ApprovalPanelSendButton = styled(ApprovalPanelButtonBase)`
    background-color: #0095da;
`;

export const ApprovalPanelBodySection = styled.div`
    border-bottom: 1px dotted #ced4da;
    padding: 15px;

    p {
        color: #191919;
        font-size: 12px;
        line-height: 1.33;
    }

    .body-subtitle {
        font-weight: bold;
        margin-bottom: 5px;
        letter-spacing: -0.24px;
    }
`;

export const ApprovalPanelDisabledBodySection = styled(ApprovalPanelBodySection)`
    background-color: #ededed;
    border-bottom: 1px solid #ced4da;

    p {
        font-style: italic;
    }
`;
