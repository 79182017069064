// Libraries
import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { styled } from "@shoothill/core";

// Custom

// Styling & Images
import { Box, Typography } from "@material-ui/core";
import moment from "moment";
import { ProjectReportViewModel, ReportType } from "./ProjectReportViewModel";
import { ProjectAllReportView } from "./AllReport/ProjectAllReportView";
import { DarwinPage, DarwinPageContent, DarwinPageTitle } from "Globals/Styles/AppStyling";
import { DarwinSelect } from "Components/AutoComplete/DarwinSelect";
import { ProjectCVRReportView } from "./CVR/ProjectCVRReportView";
import { ReportControlContainer } from "../Report.styles";
import { ProjectCostReportView } from "./Cost/ProjectCostReportView";

moment.locale("en-GB", {
    week: {
        dow: 1,
    },
});

export const ProjectReportView: React.FunctionComponent = () => {
    const [viewModel] = useState(() => new ProjectReportViewModel());

    useEffect(() => {
        viewModel.loadRelated();
        return () => {};
    }, []);

    const renderReport = () => {
        const reportType: string = viewModel.reportTypeOption ? viewModel.reportTypeOption.id : "";

        switch (reportType) {
            case ReportType.All:
                return <ProjectAllReportView projectId={viewModel.model.projectId} />;
            case ReportType.CVR:
                return <ProjectCVRReportView projectId={viewModel.model.projectId} />;
            case ReportType.Cost:
                return <ProjectCostReportView projectId={viewModel.model.projectId} />;
            default:
                return <>Please select a report</>;
        }
    };

    const renderPage = () => {
        return (
            <DarwinPage>
                <DarwinPageTitle>
                    <Typography variant="h1" color="textPrimary">
                        Project
                    </Typography>
                </DarwinPageTitle>
                <DarwinPageContent>
                    <ReportControlContainer>
                        <DarwinSelect
                            displayName="Project:"
                            execute={(val: any) => viewModel.setProjectAsync(val)}
                            fullWidth={true}
                            getOptionLabel={(option: any) => option.displayName || ""}
                            onBlur={() => viewModel.isFieldValid("projectId")}
                            options={viewModel.projectOptions}
                            placeholder="Please select"
                            validationMessage={viewModel.getError("projectId")}
                            value={viewModel.project}
                            canExecute={!viewModel.IsLoading}
                        />

                        {viewModel.model.projectId !== "" && (
                            <>
                                <DarwinSelect
                                    displayName="Report type:"
                                    execute={(val: any) => viewModel.setReportType(val.id)}
                                    fullWidth={true}
                                    getOptionLabel={(option: any) => option.displayName || ""}
                                    onBlur={() => viewModel.isFieldValid("reportType")}
                                    options={viewModel.reportTypeOptions}
                                    placeholder="Please select"
                                    validationMessage={viewModel.getError("reportType")}
                                    value={viewModel.reportTypeOption}
                                    canExecute={!viewModel.IsLoading}
                                />
                            </>
                        )}
                    </ReportControlContainer>
                    {viewModel.model.projectId !== "" && <>{renderReport()}</>}
                </DarwinPageContent>
            </DarwinPage>
        );
    };

    return useObserver(() => renderPage());
};

export const RunReportButtonContainer: any = styled.div`
    margin-top: 10px;
    margin-left: 10px;
    max-width: 300px;
`;

export const DatePickerContainer: any = styled.div`
    margin-left: 10px;

    > div {
        display: flex;
        flex-direction: row;

        > div {
            margin-right: 20px;
        }
    }
`;
