import styled from "styled-components";
import { AppBar, Button } from "@material-ui/core";

export const DarwinAppBar: any = styled(AppBar)`
    background-color: #fff;
    img {
        display: inline-block;
        vertical-align: top;
        max-width: 131px;
    }

    .admin-cog {
        vertical-align: middle;
    }

    svg {
        display: inline-block;
        vertical-align: top;
        max-width: 131px;
    }

    .MuiButton-root {
        border-radius: unset;
        padding: 22px 27px;
        border-left: solid 1px #d4d4d4;
        &:last-of-type {
            border-right: solid 1px #d4d4d4;
            margin-right: 10px;
        }
    }

    .MuiTypography-button {
        font-size: 12px;
        font-weight: bold;
        line-height: 1.75;
        text-transform: unset;
    }
`;

export const NavLinkButton: any = styled(Button)`
    padding: 0px !important;

    > span {
        > a,
        > div {
            padding: 22px 27px;
        }
    }

    @media screen and (max-width: 767px) {
        padding: 6px 8px !important;

        > span {
            > a,
            > div {
                padding: 0px 0px;
            }
        }
    }

    .header-item {
    }

    &.header-item.active {
        .MuiButton-label {
            background: #fcfcfc;
            cursor: pointer;
            margin-bottom: -1rem;
            padding-bottom: 1rem;

            .MuiLink-root {
                span {
                    border-bottom: 2px solid #0095da;
                    padding-bottom: 1px;
                }
            }
        }
    }
`;
