import { FieldType, ViewModelBase } from "@shoothill/core";
import { BreadcrumbModel } from "./BreadcrumbModel";
import { observable, computed } from "mobx";
import UserViewModel from "Globals/ViewModels/UserViewModel";

import { ClientDetailViewModel } from "Views/Admin/Client/ClientDetailViewModel";
import { ClientListViewModel } from "Views/Admin/Client/ClientListViewModel";

import { SupplierListViewModel } from "Views/Supplier/SupplierListViewModel";
import { SupplierDetailViewModel } from "Views/Supplier/SupplierDetailViewModel";
import { ProjectBreadcrumbViewModel } from "Views/Project/Breadcrumb/ProjectBreadcrumbViewModel";
import { AppUrls } from "AppUrls";
import { StoresInstance } from "Globals/Stores";
import { RoleLevelEnum, RoleTypeEnum } from "Globals/Stores/Domain/Admin";
import { InvoiceViewModel } from "Views/Invoice/Form/InvoiceViewModel";

export class BreadcrumbViewModel extends ViewModelBase<BreadcrumbModel> {
    private userViewModel = UserViewModel.Instance;
    private clientListViewModel = ClientListViewModel.Instance;
    private clientViewModel = ClientDetailViewModel.Instance;

    private supplierListViewModel = SupplierListViewModel.Instance;
    private supplierViewModel = SupplierDetailViewModel.Instance;

    private projectViewModel = ProjectBreadcrumbViewModel.Instance;

    //Singleton instance of class
    private static _instance: BreadcrumbViewModel;

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable public errorMessage: string = "";

    constructor() {
        super(new BreadcrumbModel(), false);
        this.setDecorators(BreadcrumbModel);
    }

    public async isFieldValid(fieldName: keyof FieldType<BreadcrumbModel>): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public getProjectRef = (): string => {
        return this.projectViewModel.getProjectRef;
    };

    public onAddUser = () => {
        this.userViewModel.setNewUser();
        this.userViewModel.showAddEditModal(true);
    };

    public onEditUser = () => {
        this.userViewModel.showAddEditModal(true);
    };

    public onAddClient = () => {
        this.clientViewModel.setNewClient();
        this.clientListViewModel.showAddEditModal(true);
    };

    public onEditClient = () => {
        this.clientViewModel.showAddEditModal(true);
    };

    public onClientCSV = () => {
        this.clientListViewModel.exportAsCSV();
    };

    public onAddInvoice = () => {
        // Check to prevent undefined error in InvoiceViewModel.
        if (this.history.location.pathname !== AppUrls.Client.Invoicing.Add) {
            InvoiceViewModel.ResetInstance();
            this.history.push(AppUrls.Client.Invoicing.Add);
        }
    };

    @computed
    public get canAddInvoice() {
        return StoresInstance.Domain.AccountStore.getCanAddInvoice;
    }

    public onEditInvoice = () => {
        alert("TODO onEditInvoice");
    };

    public onAddPurchaseOrder = () => {
        this.history.push(AppUrls.Client.PurchaseOrder.Add);
    };

    public onEditPurchaseOrder = () => {
        alert("TODO on Edit Purchase Request");
    };

    public onAddProject = () => {
        alert("TODO onAddProject");
    };

    public onEditProject = () => {
        alert("TODO onEditProject");
    };

    // Related to stock
    public onRaiseNewPO = () => {
        this.history.push(AppUrls.Client.PurchaseOrder.Add);
    };

    // Related to stock
    public onEditStock = () => {
        alert("TODO onEditStock");
    };

    public onAddNewMaterial = () => {
        this.history.push(AppUrls.Client.Stock.MaterialAdd);
    };

    public onAddSupplier = () => {
        this.supplierListViewModel.showAddEditModal(true);
    };

    @computed
    public get canAddSupplier() {
        return StoresInstance.Domain.AccountStore.getCanAddSupplier;
    }

    public onEditSupplier = () => {
        this.supplierViewModel.showAddEditModal(true);
    };

    public onSupplierCSV = () => {
        this.supplierListViewModel.exportAsCSV();
    };

    public onEditCentralProject = () => {
        // this.centralProjectViewModel.showAddEditModal(true);
        alert("TODO onEditCentralProject");
    };

    public onCentralProjectCSV = () => {
        alert("TODO onCentralProjectCSV");
        // this.supplierListViewModel.exportAsCSV();
    };

    @computed
    public get canAddProject(): boolean {
        return StoresInstance.Domain.AccountStore.getCanAddProject;
    }

    @computed
    public get canEditProjectConstruction(): boolean {
        return StoresInstance.Domain.AccountStore.canEditProjectConstruction;
    }

    @computed
    public get canViewCommercialReport(): boolean {
        return StoresInstance.Domain.AccountStore.isAdminRole;
    }

    @computed
    public get isSiteManager(): boolean {
        const isSiteManager: boolean = StoresInstance.Domain.AccountStore.isInRole(RoleTypeEnum.SiteManager);
        return isSiteManager;
    }
}
