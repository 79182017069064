import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Typography } from "@material-ui/core";
import { Loader } from "@shoothill/core";
import { observer } from "mobx-react-lite";
import React from "react";
import styled from "styled-components";

import { Step4ViewModel } from "./Step4ViewModel";
import { InductionViewModel } from "../InductionViewModel";
import { WizardButtonsView } from "../WizardButtons";
import { DashedDivider } from "../../PurchaseOrder/Form/Views/Dividers";

interface Props {
    viewModel: Step4ViewModel;
    parentViewModel: InductionViewModel;
}

const Step4Container = styled.div`
    .root {
        margin: 15px;
        padding-bottom: 75px;
    }

    .form-controls {
        display: flex;
        flex-direction: column;
        margin: 16px 0;
    }

    .form-label {
        font-size: 14px !important;
        font-weight: 600;
        letter-spacing: -0.02em;
        line-height: 18px;
        text-align: left;
    }

    .form-helpertext {
        font-size: 12px;
        line-height: 1.33;
    }

    .form-group {
        flex-direction: row;
        margin-bottom: 16px;
    }
`;

export const Step4View: React.FC<Props> = observer((props: Props) => {
    const { viewModel } = props;

    if (props.parentViewModel.isStageLoading) {
        return <Loader />;
    }

    const onSelectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        viewModel.togglePersonalProtectiveEquipmentType(event.target.name);
    };

    return (
        <Step4Container>
            <div className="root">
                <Typography>What Personal Protective Equipment have you brought with you (please do not add skills training here)?</Typography>
                <div className="form-controls">
                    {viewModel.personalProtectiveEquipment.map((ppe) => {
                        const ppeTypes = viewModel.getPersonalProtectiveEquipmentTypesByGroupId(ppe.id);

                        return (
                            <FormControl className="form-control" component="fieldset" key={ppe.id}>
                                <FormLabel className="form-label" component="legend">
                                    {ppe.displayName}
                                </FormLabel>
                                <FormHelperText className="form-helpertext">{ppe.statement}</FormHelperText>
                                <FormGroup className="form-group">
                                    {ppeTypes.map((ppeType) => {
                                        return (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={viewModel.isPersonalProtectiveEquipmentTypeSelected(ppeType.id)}
                                                        name={ppeType.id}
                                                        onChange={onSelectionChange}
                                                    />
                                                }
                                                label={ppeType.displayName}
                                                key={ppeType.id}
                                            />
                                        );
                                    })}
                                </FormGroup>
                                <DashedDivider gutterBottom />
                            </FormControl>
                        );
                    })}
                </div>
            </div>
            <WizardButtonsView canExecute={viewModel.canExecuteNextStep} nextStep={viewModel.nextStep} previousStep={viewModel.previousStep} />
        </Step4Container>
    );
});
