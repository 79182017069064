import { observable, action, computed } from "mobx";
import { KeyValuePair, ModelBase } from "@shoothill/core";
import { DesignDataStatusModel, DesignDataStatusDTO, DesignDataFullStatusDTO } from "./DesignDataStatusModel";
import { RDDItemModel, RDDItemModelDTO } from "./RDDItemModel";
import { RDDItemResponseModel, RDDItemResponseModelDTO } from "./RDDItemResponseModel";
import moment from "moment";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class RDDModel extends ModelBase<RDDModel, RDDModelDTO> {
    // DarwinModelBase
    // public id: string = "";
    // public createdDate: string = "";

    @observable
    public designDataItems: RDDItemModel[] = [];

    @observable
    public designDataItemResponses: RDDItemResponseModel[] = [];

    //fromDto is required but you can leave it blank
    fromDto(model: RDDModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else if (key === "designDataItems") {
                    // Do nothing.
                } else if (key === "designDataResponses") {
                    // Do nothing.
                } else {
                    this[key] = model[key];
                }
            }
        }

        // Process the list
        let processedPos: RDDItemModel[] = [];

        for (const item of model.designDataItems) {
            const itemToAdd = new RDDItemModel();
            itemToAdd.fromDto(item);
            processedPos.push(itemToAdd);
        }

        this.designDataItems = processedPos;

        // Process the responses
        let processedResponsePos: RDDItemResponseModel[] = [];

        for (const itemResponse of model.designDataResponses) {
            const itemResponseToAdd = new RDDItemResponseModel();
            itemResponseToAdd.fromDto(itemResponse);
            processedResponsePos.push(itemResponseToAdd);
        }

        this.designDataItemResponses = processedResponsePos;
    }

    //toDto is required but you can leave it blank
    toDto(model: RDDModel): void {}
}

export type RDDModelDTO = {
    designDataItems: RDDItemModelDTO[];
    designDataStatuses: DesignDataStatusDTO[];
    designDataResponses: RDDItemResponseModelDTO[];
    fullStatuses: DesignDataFullStatusDTO[];
};
