// Libraries
import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { Cell, FieldType, Grid, isNullOrUndefined, useRouter } from "@shoothill/core";
import { Button, FormControl, FormHelperText, InputAdornment, InputLabel, MenuItem, Select, Typography } from "@material-ui/core";

// Custom
import { DetailsHeader } from "Globals/Views/DetailsPage/DetailsHeader";
import { GeneralModel } from "./GeneralModel";
import { ProjectGeneralViewModel } from "./ProjectGeneralViewModel";
import { AddressComponentView } from "Views/Address/AddressComponentView";
import { NoteComponentView } from "Views/Notes/NoteComponentView";
import { AddressModel, AddressModelDTO, NoteModel, NoteModelDTO } from "Globals/Models/Domain";
import { AddressListContainer } from "Views/Address/AddressListContainer";
import { NotesListContainer } from "Views/Notes/NotesListContainer";
import { GeneralCreatedModal } from "./GeneralCreatedModal";
import { SnackBar } from "Components/SnackBar/SnackBar";

// Styling & Images
import { DetailsPage } from "Globals/Views/DetailsPage/DetailsPage.styles";
import { DarwinInput, DarwinMoneyInput } from "Globals/Styles/Control/DarwinInput";
import { ProjectAddressEditBox, ProjectNoteEditBox, ProjectButtonContainer } from "../Project.styles";
import NoteViewModel from "Globals/ViewModels/NoteViewModel";
import { CancelButton } from "Globals/Styles/Control/Buttons";
import { AppUrls } from "AppUrls";
import { ProjectCellHelpers } from "Globals/Models/Domain/ProjectCellEnum";
import { CustomHr } from "../CustomComponents";

export const GeneralAddEditView: React.FunctionComponent = () => {
    const viewModel = ProjectGeneralViewModel.Instance;
    const [isNewProject, setIsNewProject] = useState(true);

    const [addresses, setAddresses] = useState<AddressModel[]>([]);
    const [notes, setNotes] = useState<NoteViewModel[]>([]);

    const [selectedAddress, setSelectedAddress] = useState<AddressModel>(viewModel.getModelAddress);
    const [modalStatus, setModalStatus] = useState(false);
    const [add, setAdd] = useState<number[]>([1, 2, 3, 5, 6, 544, 45, 23, 1, 234, 234]);

    const { history, match } = useRouter();
    const [isDrafStatus, setIsDrafStatus] = useState(false);
    useEffect(() => {
        let { projectid } = match.params as any;
        viewModel.clean();
        setNotes(viewModel.getNoteViewModels);

        if (isNullOrUndefined(projectid) === false) {
            // TODO This really needs to not return a void.
            let promise = viewModel.apiGetbyId(projectid);
            promise.then(() => {
                // TODO should only do this on success
                setNotes(viewModel.getNoteViewModels);
                if (viewModel.projectStatus !== "DRAFT") setIsDrafStatus(true);
                setAddresses(viewModel.getAddressModelArr);
            });

            setIsNewProject(false);
        } else {
            viewModel.apiGetRelated();
            viewModel.setValue("projectId", projectid);
            setAddresses([]);
        }

        return () => {};
    }, []);

    const handleProjectTypeChange = (event: any) => {
        viewModel.setProjectType(event.target.value ? event.target.value : null);
    };

    const handleProjectStatusChange = (event: any) => {
        viewModel.setProjectStatusType(event.target.value ? event.target.value : null);
    };

    const handleProjectCellChange = (event: any) => {
        viewModel.setValue<number>("cell", event.target.value);
    };

    const isInError = (fieldName: keyof FieldType<GeneralModel>) => {
        let isValid = viewModel.getValid(fieldName);
        return !isValid;
    };

    const renderProjectForm = () => {
        return (
            <Grid margin="0 30px 17.5px 30px" columnGap={30} dc={"1fr 1fr 1fr 1fr"} rowGap={20} tc={"1fr 1fr 1fr 1fr"}>
                <Cell>
                    <DarwinInput<GeneralModel>
                        type="text"
                        label="Project Reference:"
                        validateOnBlur={true}
                        viewModel={viewModel}
                        fieldName="reference"
                        shrink={true}
                        maxLength={12}
                    />
                </Cell>
                <Cell>
                    <FormControl fullWidth error={isInError("projectTypeId")}>
                        <InputLabel htmlFor="projecttype-select" shrink>
                            Project type:
                        </InputLabel>
                        <Select
                            id="projecttype-select"
                            value={viewModel.projectType ? viewModel.projectType.id : ""}
                            onChange={handleProjectTypeChange}
                            MenuProps={{
                                getContentAnchorEl: null,
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                            }}
                        >
                            {viewModel.projectTypes.map((pt) => (
                                <MenuItem key={pt.id} value={pt.id}>
                                    {pt.name}
                                </MenuItem>
                            ))}
                        </Select>
                        {isInError("projectTypeId") && <FormHelperText style={{ color: "red" }}>{viewModel.getError("projectTypeId")}</FormHelperText>}
                    </FormControl>
                </Cell>
                <Cell>
                    <FormControl fullWidth error={isInError("cell")}>
                        <InputLabel htmlFor="projectcell-select" shrink>
                            Project cell:
                        </InputLabel>
                        <Select
                            id="projectcell-select"
                            value={viewModel.model.cell}
                            onChange={handleProjectCellChange}
                            MenuProps={{
                                getContentAnchorEl: null,
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                            }}
                        >
                            {ProjectCellHelpers.getAllSelectOptions(true).map((pc) => (
                                <MenuItem key={pc.value} value={pc.value}>
                                    {pc.label}
                                </MenuItem>
                            ))}
                        </Select>
                        {isInError("cell") && <FormHelperText style={{ color: "red" }}>{viewModel.getError("cell")}</FormHelperText>}
                    </FormControl>
                </Cell>
                <Cell>
                    <FormControl fullWidth error={isInError("projectStatusTypeId")}>
                        <InputLabel htmlFor="projectstatus-select" shrink>
                            Project status:
                        </InputLabel>
                        <Select
                            id="projectstatus-select"
                            disabled={isDrafStatus}
                            value={viewModel.projectStatusType ? viewModel.projectStatusType.id : ""}
                            onChange={handleProjectStatusChange}
                            MenuProps={{
                                getContentAnchorEl: null,
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                            }}
                        >
                            {viewModel.projectStatusTypes.map((pst) => (
                                <MenuItem key={pst.id} value={pst.id}>
                                    {pst.name}
                                </MenuItem>
                            ))}
                        </Select>
                        {isInError("projectStatusTypeId") && <FormHelperText style={{ color: "red" }}>{viewModel.getError("projectStatusTypeId")}</FormHelperText>}
                    </FormControl>
                </Cell>
                <Cell desktopSpan={2}>
                    <DarwinInput<GeneralModel> type="text" label="Project name:" validateOnBlur={true} viewModel={viewModel} fieldName="name" shrink={true} maxLength={128} />
                </Cell>
                <Cell>
                    <DarwinMoneyInput<GeneralModel>
                        type="number"
                        label="Contract (or LOI) value:"
                        validateOnBlur={true}
                        viewModel={viewModel}
                        fieldName="contractLoiValue"
                        shrink={true}
                        maxLength={12}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">£</InputAdornment>,
                        }}
                    />
                </Cell>
                <Cell desktopSpan={4}>
                    <DarwinInput<GeneralModel>
                        multiLine={true}
                        rows={10}
                        type="text"
                        label="Project description:"
                        validateOnBlur={true}
                        viewModel={viewModel}
                        fieldName="description"
                        shrink={true}
                        placeHolder="Add project description..."
                    />
                </Cell>
            </Grid>
        );
    };

    const onUpdateAddress = (address: AddressModelDTO): any => {
        viewModel.addAddress(address);
        setAddresses(viewModel.getAddressModelArr);
    };

    const addressRowClicked = (val: string): any => {
        viewModel.setAddressModel(val);
        setSelectedAddress(viewModel.getAddressById(val));
        //viewModel.setAddressViewModel(val);
    };

    const addressMakePrimary = (index: number): any => {
        viewModel.setMainAddress(viewModel.getAddressByIndex(index), index);
        setAddresses(viewModel.getAddressModelArr);
    };

    const addressDelete = (val: AddressModel, index: number): any => {
        viewModel.deleteAddress(val, index);
        setAddresses(viewModel.getAddressModelArr);
    };

    const renderAddressform = (
        <>
            <ProjectAddressEditBox>
                <Typography variant="h2">Addresses</Typography>
                <AddressComponentView onUpdateModel={onUpdateAddress} viewModel={viewModel.addressViewModel} isLoading={false} showAddButton={true} />
            </ProjectAddressEditBox>

            <AddressListContainer
                addressModel={addresses}
                addressRowClicked={addressRowClicked}
                //addressMakePrimary={addressMakePrimary}
                addressPrimaryText={""}
                addressDeleteText={"Delete"}
                addressDelete={addressDelete}
            />
        </>
    );

    const onAddNote = (note: NoteModelDTO): any => {
        viewModel.addNote(note);
        setNotes(viewModel.getNoteViewModels);
    };

    const noteRowClicked = (val: NoteModel): any => {
        viewModel.setNoteModel(val);
    };

    const noteDelete = (val: NoteModel): any => {
        viewModel.deleteNote(val);
        setNotes(viewModel.getNoteViewModels);
    };

    const renderNotesForm = () => {
        return (
            <div className="editProjectBlock">
                <ProjectNoteEditBox>
                    <Typography variant="h2">Project notes</Typography>
                    <NoteComponentView
                        onUpdateModel={onAddNote}
                        model={viewModel.noteModel}
                        isLoading={viewModel.IsLoading}
                        showAddButton={true}
                        buttonLabel="Add note"
                        showUploadFacility={true}
                    />
                </ProjectNoteEditBox>
                <NotesListContainer notesViewModel={notes} noteRowClicked={noteRowClicked} noteDeleteText={"Delete"} noteDelete={noteDelete} showUploaded={true} />
            </div>
        );
    };

    const onSaveClick = async () => {
        if ((await viewModel.isModelValid()) === true) {
            const promise = viewModel.upsertProject();
            promise.then((result: any) => {
                if (result.wasSuccessful === true) {
                    setModalStatus(true);
                }
            });
        } else {
            window.scrollTo(0, 0);
        }
    };

    const handleCancelCLick = () => {
        history.push(AppUrls.Client.Project.General.replace(":projectid", viewModel.model.projectId));
    };

    const onContinue = () => {
        const url: string = AppUrls.Client.Project.General.replace(":projectid", viewModel.getValue<string>("id"));
        history.push(url);
    };

    const onSaveDraft = () => {
        history.push(AppUrls.Client.Project.List);
    };

    return useObserver(() => (
        <>
            <DetailsPage>
                <DetailsHeader viewModel={viewModel.getHeader} />
                {renderProjectForm()}
                {renderAddressform}
                {renderNotesForm()}
                <div style={{ padding: "0 30px", marginTop: "20px", marginBottom: "20px" }}>
                    <CustomHr />
                </div>
                <ProjectButtonContainer>
                    <Button onClick={onSaveClick} color="primary" autoFocus variant="contained" disabled={viewModel.IsLoading}>
                        {isNewProject ? <>Create project</> : <>Update project</>}
                    </Button>
                    <CancelButton onClick={() => handleCancelCLick()}>Cancel</CancelButton>
                </ProjectButtonContainer>
                <SnackBar
                    messageText={viewModel.snackMessage}
                    messageType={viewModel.snackType}
                    active={viewModel.snackbarState}
                    closeOption={() => viewModel.setSnackbarState(false)}
                    autoHideDuration={5000}
                />
            </DetailsPage>
            {modalStatus && <GeneralCreatedModal open={modalStatus} onClose={() => setModalStatus(false)} onSaveDraft={() => onSaveDraft()} onContinue={() => onContinue()} />}
        </>
    ));
};
