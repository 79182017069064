import { computed } from "mobx";
import { FieldType, isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import moment from "moment";
import { InductionAdminSiteAccessLogModel } from "./InductionAdminSiteAccessLogModel";

export class InductionAdminSiteAccessLogViewModel extends ViewModelBase<InductionAdminSiteAccessLogModel> {
    constructor(item: InductionAdminSiteAccessLogModel) {
        super(item);
    }

    @computed
    public get id() {
        return this.model.id;
    }

    @computed
    public get isSignIn() {
        return this.model.isSignIn;
    }

    @computed
    public get visitDate() {
        return this.model.visitDate;
    }

    @computed
    public get visitDateFormatted() {
        return this.model.visitDate ? `${moment(this.model.visitDate).format("DD/MM/YYYY")} @ ${moment(this.model.visitDate).format("HH:mm")}` : "";
    }

    @computed
    public get visitorFullName() {
        return this.model.visitorFullName;
    }

    @computed
    public get priorityEmployerName() {
        return this.model.priorityEmployerName;
    }

    @computed
    public get lastThreePhoneDigits() {
        return this.model.lastThreePhoneDigits;
    }

    @computed
    public get visitorTypeName() {
        return this.model.visitorTypeName;
    }

    @computed
    public get healthProblemsFormatted() {
        return this.model.healthProblems ? "Y" : "N";
    }

    @computed
    public get lastThreePhoneDigitsFormatted() {
        return this.model.lastThreePhoneDigits.toString().padStart(3, "0");
    }

    @computed
    public get siteName() {
        return this.model.siteName;
    }

    @computed
    public get ramsTitle() {
        return this.model.ramsTitle;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public async isFieldValid(fieldName: keyof FieldType<InductionAdminSiteAccessLogModel>): Promise<boolean> {
        return true;
    }
}
