import { action, observable, computed } from "mobx";
import { ModelBase } from "@shoothill/core";
import { InvoiceViewModel } from "../../InvoiceViewModel";

export class InvoiceDisputeModalModel extends ModelBase<InvoiceDisputeModalModel, any> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Constants and Defaults

    public static readonly DEFAULT_INVOICEDISPUTEDSTATUSCODEID = "";
    public static readonly DEFAULT_INVOICEDISPUTEDREASON = "";

    // #endregion Constants and Defaults

    // #region Properties

    @observable
    public invoiceDisputedStatusCodeId: string | null = InvoiceDisputeModalModel.DEFAULT_INVOICEDISPUTEDSTATUSCODEID;

    @observable
    public invoiceDisputedReason: string | null = InvoiceDisputeModalModel.DEFAULT_INVOICEDISPUTEDREASON;

    // #endregion Properties

    // #region Actions

    @action
    public reset = () => {
        this.invoiceDisputedStatusCodeId = InvoiceDisputeModalModel.DEFAULT_INVOICEDISPUTEDSTATUSCODEID;
        this.invoiceDisputedReason = InvoiceDisputeModalModel.DEFAULT_INVOICEDISPUTEDREASON;
    };

    @action
    public fromDto(): void {}

    public toDto() {
        const model: InvoiceDisputedRequestDTO = {
            id: null,
            invoiceDisputedStatusCodeId: this.invoiceDisputedStatusCodeId,
            invoiceDisputedReason: this.invoiceDisputedReason,
            invoiceStatusId: null,
            rowVersion: InvoiceViewModel.GetInstance.detailsViewModel.model.rowVersion,
        };

        return model;
    }

    public toStatusCodeDto() {
        const model: UpdateInvoiceDisputedStatusCodeDTO = {
            id: null,
            invoiceDisputedStatusCodeId: this.invoiceDisputedStatusCodeId,
            invoiceDisputedReason: this.invoiceDisputedReason,
            rowVersion: InvoiceViewModel.GetInstance.detailsViewModel.model.rowVersion,
        };

        return model;
    }

    @computed
    public get isDisputed() {
        return InvoiceViewModel.GetInstance.isDisputed;
    }

    // #endregion Actions

    // #region Custom Validation

    @computed
    public get validateInvoiceDisputedReason(): string {
        // RULES
        // The status code must be selected
        if (this.isDisputed) {
            return "";
        }
        return this.invoiceDisputedReason === InvoiceDisputeModalModel.DEFAULT_INVOICEDISPUTEDREASON ? "Please provide a reason for disputing" : "";
    }

    @computed
    public get validateInvoiceDisputedStatusCodeId(): string {
        // RULES
        // The status code must be selected
        return this.invoiceDisputedStatusCodeId === InvoiceDisputeModalModel.DEFAULT_INVOICEDISPUTEDSTATUSCODEID ? "Please select a disputed status code" : "";
    }

    // #endregion Custom Validation
}

export interface InvoiceDisputedRequestDTO {
    id: string | null;
    invoiceDisputedStatusCodeId: string | null;
    invoiceDisputedReason: string | null;
    invoiceStatusId: string | null;
    rowVersion: string | null;
}

export interface UpdateInvoiceDisputedStatusCodeDTO {
    id: string | null;
    invoiceDisputedStatusCodeId: string | null;
    invoiceDisputedReason: string | null;
    rowVersion: string | null;
}
