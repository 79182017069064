import { FieldType, isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import type { ValidationResponse } from "@shoothill/core";
import { action, computed } from "mobx";

import { VariationNoteModel } from "./VariationNoteModel";
import { formatDate } from "Utils/Format";
import moment from "moment";

export class VariationNoteViewModel extends ViewModelBase<VariationNoteModel> {
    // #region Constructors and Disposers

    constructor(variationNote: VariationNoteModel | null) {
        super(variationNote ? variationNote : new VariationNoteModel());
        this.setDecorators(VariationNoteViewModel);
    }

    @computed
    public get createdDateFormatted() {
        return this.model.createdDate ? moment(this.model.createdDate).format("DD/MM/YY").toString() : formatDate(moment().toString());
    }

    @computed
    public get createdByUserName() {
        return this.model.createdByUserName;
    }

    @action
    public reset = () => {
        this.model.reset();
    };

    // #endregion Client Actions

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<VariationNoteModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        switch (fieldName) {
            case "note": {
                const result = this.validateNote;

                errorMessage = result.errorMessage;
                isValid = result.isValid;
                break;
            }
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    @computed
    private get validateNote(): ValidationResponse {
        const errorMessage = this.model.validateNote;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
