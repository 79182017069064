import { Box } from "@material-ui/core";
import styled from "styled-components";
import { theme } from "./AppTheme";

export const accountpageHeight = window.screen.availHeight;

export type AccountPageProps = {
    triangleheight: string;
};

export const AccountPage: any = styled(Box)<AccountPageProps & AccountPageProps>`
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    min-height: 100vh;
    height: auto;
    background-origin: content-box, content-box;
    background-size: cover;
    background-position: center center;
    background-color: ${theme.palette.background.paper};
    background-repeat: no-repeat;
    font-family: "Open Sans";
    background-repeat: no-repeat;
    margin: auto;
    text-align: center;
    vertical-align: center;

    .triangle {
        position: absolute;
        bottom: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: ${(props) => (props.triangleheight ? props.triangleheight : "588px")} 0 0 ${(props) => (props.triangleheight ? props.triangleheight : "588px")};
        border-color: transparent transparent transparent #0095da;
    }
`;

export const BoxPage: any = styled(Box)`
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    min-height: 100vh;
`;

export const BoxAccount: any = styled(Box)`
    margin: auto;
    width: 410px;
    .darwin-logo {
        max-width: 456px;
        height: auto;
    }
    z-index: 1;
`;

export const AccountImageBox: any = styled(Box)`
    justify-content: center;
    min-width: 100%;
    text-align: center;
    padding-bottom: 60px;

    img {
        //transform: translateX(-150px);
    }
`;
