import * as MobX from "mobx";
import { ModelBase } from "@shoothill/core";
import moment from "moment";
export class RDDItemModel extends ModelBase<RDDItemModel, RDDItemModelDTO> {
    @MobX.observable
    public id: string = "";

    @MobX.observable
    public supplierReference: string = "";

    @MobX.observable
    public clientReference: string = "";

    @MobX.observable
    public supplierName: string = "";

    @MobX.observable
    public numberOfItems: number = 0;

    @MobX.observable
    public requestedDate: Date | null = null;

    @MobX.observable
    public requestedByUserId: string = "";

    @MobX.observable
    public requestedByName: string = "";

    @MobX.observable
    public discipline: string = "";

    @MobX.observable
    public requestedTo: string = "";

    @MobX.observable
    public responseRequiredByDate: Date | null = null;

    @MobX.observable
    public numberOfFiles: number = 0;

    @MobX.observable
    public priorityName: string = "";

    @MobX.observable
    public designDataStatusId: string = "";

    @MobX.observable
    public statusName: string = "";

    @MobX.observable
    public latestResponseName: string = "";

    @MobX.observable
    public isDarwinGroup: boolean = false;

    @MobX.observable
    public revision: number = 0;

    fromDto(model: RDDItemModelDTO): void {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    toDto(model: RDDItemModel): void {}
}

export type RDDItemModelDTO = {
    id: string;
    supplierReference: string;
    clientReference: string;
    supplierName: string;
    numberOfItems: number;
    requestedDate: Date | null;
    requestedByUserId: string;
    requestedByName: string;
    discipline: string;
    requestedTo: string;
    responseRequiredByDate: Date | null;
    numberOfFiles: number;
    priorityName: string;
    designDataStatusId: string;
    statusName: string;
    latestResponseName: string;
    isDarwinGroup: boolean;
    revision: number;
};
