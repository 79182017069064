import { ModelBase } from "@shoothill/core";
import { observable } from "mobx";
import { AuditQuestionViewModel } from "./AuditQuestionViewModel";
import { AuditQuestionModel } from "./AuditQuestionModel";
import { UserLookUpModel } from "../../Inspections/UserLookUpModel";

export class EndOfDayModel extends ModelBase<EndOfDayModel> {
    public id: string | null = null;
    @observable
    public auditUser: UserLookUpModel = new UserLookUpModel();
    @observable
    public auditUserId: string | null = null;
    @observable
    public createdDate: Date | null = null;
    public projectId: string | null = null;
    public signatureBase64: string = "";
    public questionModels = observable<AuditQuestionModel>([]);

    constructor() {
        super();
    }

    fromDto(model: any): void {
        this.id = model.id;
        this.auditUserId = model.auditUserId;
        this.projectId = model.projectId;
        this.signatureBase64 = model.signatureBase64;
        this.createdDate = new Date(model.createdDate);
    }
    toDto() {
        return {
            id: this.id,
            auditUserId: this.auditUserId,
            projectId: this.projectId,
            signatureBase64: this.signatureBase64,
            questions: this.questionModels.map((q) => q.toDto()),
            endOfDay: true,
            endOfWeek: false,
        };
    }
}
