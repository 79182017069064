import { Footer, ChangeLogWrapper } from "./Footer.styles";

import { ChangeLog as ChangeLogData } from "Globals/Changelog";
import * as React from "react";
import { ChangelogModal } from "./ChangelogModal";

export interface ChangeLogProps {}

interface State {
    isOpen: boolean;
}

export class ChangeLog extends React.Component<ChangeLogProps, State> {
    state: State = {
        isOpen: false,
    };

    public render() {
        const { isOpen } = this.state;
        const { currentVersion, versions } = ChangeLogData;

        //AJ: Don't show versions with no items.
        const validVersions = versions.filter((version) => version.items && version.items.length > 0);

        return (
            <ChangeLogWrapper>
                <Footer>
                    <span>
                        v{currentVersion} <a onClick={this.open}>view change log</a>
                    </span>
                </Footer>
                <ChangelogModal open={isOpen} onClose={this.close} title="Change log">
                    <div className="modal-changelog">
                        <div className="modal-changelog-header">
                            <h4>Change Log</h4>
                        </div>
                        <div className="modal-changelog-body">
                            {validVersions.map((version) => {
                                return (
                                    <div key={`${version.version}`} className="modal-changelog-body-version">
                                        <h4>
                                            v{version.version} (<small>{this.formatDate(version.date)}</small>)
                                        </h4>
                                        <ul>
                                            {version.items.map((item, index) => {
                                                return <li key={`${version.version}_${index}`}>{item.description}</li>;
                                            })}
                                        </ul>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </ChangelogModal>
            </ChangeLogWrapper>
        );
    }

    private open = () => {
        this.setState({ isOpen: true });
    };

    private close = () => {
        this.setState({ isOpen: false });
    };

    private formatDate = (date: string) => {
        if (date == null || date.length === 0) {
            return "Pre-Release";
        }

        return date;
    };
}
