import { action, observable } from "mobx";
import { ModelBase } from "@shoothill/core";
import { VariationItemModel, VariationItemDTO } from "./VariationItemModel";

export class VariationSubCategoryModel extends ModelBase<VariationSubCategoryModel, any> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Constants and Defaults

    public static readonly DEFAULT_ID = null;
    public static readonly DEFAULT_SUBCATEGORYID = null;
    public static readonly DEFAULT_CREATEDDATE = null;
    public static readonly DEFAULT_CREATEDBYUSERID = null;
    public static readonly DEFAULT_DISPLAYNAME = null;
    public static readonly DEFAULT_VARIATIONCATEGORYID = null;
    public static readonly DEFAULT_VARIATIONITEMS = [];
    public static readonly DEFAULT_ISDELETED = false;

    // #endregion Constants and Defaults

    // #region Properties

    @observable
    public id: string | null = VariationSubCategoryModel.DEFAULT_ID;

    @observable
    public createdDate: string | null = VariationSubCategoryModel.DEFAULT_CREATEDDATE;

    @observable
    public createdByUserId: string | null = VariationSubCategoryModel.DEFAULT_CREATEDBYUSERID;

    @observable
    public variationCategoryId: string | null = VariationSubCategoryModel.DEFAULT_VARIATIONCATEGORYID;

    @observable
    public subCategoryId: number | null = VariationSubCategoryModel.DEFAULT_SUBCATEGORYID;

    @observable
    public isDeleted: boolean = VariationSubCategoryModel.DEFAULT_ISDELETED;

    @observable
    public displayName: string | null = VariationSubCategoryModel.DEFAULT_DISPLAYNAME;

    @observable
    public variationItems: VariationItemModel[] = VariationSubCategoryModel.DEFAULT_VARIATIONITEMS;

    // #endregion Properties

    // #region Actions

    @action
    public reset = () => {
        this.id = VariationSubCategoryModel.DEFAULT_ID;
        this.subCategoryId = VariationSubCategoryModel.DEFAULT_SUBCATEGORYID;
        this.displayName = VariationSubCategoryModel.DEFAULT_DISPLAYNAME;
        this.variationCategoryId = VariationSubCategoryModel.DEFAULT_VARIATIONCATEGORYID;
        this.variationItems = VariationSubCategoryModel.DEFAULT_VARIATIONITEMS;
    };

    @action
    public fromDto(dto: VariationSubCategoryDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in dto) {
            if (dto.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(dto[key]);
                } else if (key === "variationItems") {
                    // Manually process the child array otherwise we will end up with an array of dtos being stored in the array of models.
                    let processedItems: VariationItemModel[] = [];

                    const items = dto.variationItems.sort((a, b) => (a !== null && b !== null ? a.displayName!.localeCompare(b.displayName!) : 1));

                    for (const item of items) {
                        const itemToAdd = new VariationItemModel();
                        itemToAdd.fromDto(item);
                        processedItems.push(itemToAdd);
                    }

                    this.variationItems = [...this.variationItems, ...processedItems];
                } else {
                    this[key] = dto[key];
                }
            }
        }
    }

    public toDto(): VariationSubCategoryDTO {
        let itemsToAdd: VariationItemDTO[] = [];

        this.variationItems.forEach((i) => {
            if (i.isDeleted && i.id !== "" && i.id !== null && i.id !== undefined) {
                itemsToAdd.push(i.toDto());
            } else if (!i.isDeleted) {
                itemsToAdd.push(i.toDto());
            }
        });

        const model: VariationSubCategoryDTO = {
            id: this.id,
            createdDate: this.createdDate,
            createdByUserId: this.createdByUserId,
            variationCategoryId: this.variationCategoryId,
            subCategoryId: this.subCategoryId,
            isDeleted: this.isDeleted,
            displayName: this.displayName,
            variationItems: itemsToAdd,
        };

        return model;
    }

    // #endregion Actions

    // #region Custom Validation

    // #endregion Custom Validation
}

// export interface VariationSubCategoryUpsertRequestDTO {
//     id: string | null;
//     packageCategoryId: number | null;
//     displayName: string | null;
//     variationCategoryId: string | null;
// }

export interface VariationSubCategoryDTO {
    id: string | null;
    createdDate: string | null;
    createdByUserId: string | null;
    variationCategoryId: string | null;
    subCategoryId: number | null;
    isDeleted: boolean;
    displayName: string | null;
    variationItems: VariationItemDTO[];
}
