import { Typography, IconButton } from "@material-ui/core";
import SystemUpdateTwoToneIcon from "@material-ui/icons/SystemUpdateTwoTone";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { Cell, Grid } from "@shoothill/core";
import { observer } from "mobx-react-lite";

import { Uploader } from "Components/Uploader/Uploader";
import { DarwinInput } from "Globals/Styles/Control/DarwinInput";
import { H2TextSolidDivider, SolidDivider } from "../Dividers";
import { IDisplayFile, PurchaseOrderModel } from "../../PurchaseOrderModel";
import { PurchaseOrderViewModel } from "../../PurchaseOrderViewModel";

interface IProps {
    viewModel: PurchaseOrderViewModel;
}

export const InstructionsToSupplierView: React.FC<IProps> = observer((props) => {
    // #region Code Behind

    const COLUMNS8 = "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr";
    const viewModel = props.viewModel;

    // #endregion Code Behind

    return (
        <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
            {/* Group of 8 columns */}
            <Cell ts={8} ds={8}>
                <Typography variant="h2">Purchase order instructions to supplier</Typography>
                <H2TextSolidDivider />
            </Cell>

            {/* Group of 8 columns */}
            <Cell ts={8} ds={8}>
                {!viewModel.isFormDisabled && (
                    <DarwinInput<PurchaseOrderModel>
                        type="text"
                        label="Add requisition note"
                        multiLine={true}
                        validateOnBlur={true}
                        viewModel={viewModel}
                        fieldName="note"
                        shrink={true}
                        // maxLength={256}
                        editMode={!viewModel.isFormDisabled}
                    />
                )}

                {viewModel.isFormDisabled && (
                    <div className="keep-newline-formatting" style={{ fontSize: "12px" }}>
                        {viewModel.model.note}
                    </div>
                )}

                <Uploader handleSelectFile={(e: any) => viewModel.fileChange(e, false)} isFormDisabled={viewModel.isFormDisabled} label="" />

                {viewModel.model.purchaseFile && (
                    <div className="uploadedFile">
                        {viewModel.model.purchaseFile.map((item: IDisplayFile, index: number) => {
                            return (
                                !item.isDeleted && (
                                    <div className="uploadedFile-box">
                                        <div className="uploadedFile-heading">
                                            <IconButton onClick={() => viewModel.DownloadFile(item.fileUrl, item.fileName)}>
                                                <SystemUpdateTwoToneIcon />
                                            </IconButton>
                                            <h3>{item.fileName}</h3>
                                        </div>
                                        <IconButton onClick={() => viewModel.deletePurchaseFile(index)} disabled={viewModel.isFormDisabled}>
                                            <DeleteOutlinedIcon />
                                        </IconButton>
                                    </div>
                                )
                            );
                        })}
                    </div>
                )}
            </Cell>

            {/* Group of 8 columns */}
            <Cell ts={8} ds={8}>
                <SolidDivider gutterBottom={true} />
            </Cell>
        </Grid>
    );
});
