import { ApiResult, FieldType, ViewModelBase } from "@shoothill/core";
import { observable, action } from "mobx";
import { AppUrls } from "AppUrls";
import { AddSupplierModelDTO } from "./SupplierDetailModel";
import { get as _get } from "lodash-es";
import validator from "validator";
import { AddSupplierModel } from "./AddSupplierModel";
import { IsReferenceInUseRequest } from "Globals/Models";
import { runInAction } from "mobx";

export class AddSupplierViewModel extends ViewModelBase<AddSupplierModel> {
    private static _instance: AddSupplierViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable public errorMessage: string = "";

    private constructor() {
        super(new AddSupplierModel(), false);
        this.setDecorators(AddSupplierModel);
    }

    @action
    public cleanUp = () => {
        this.model.cleanUp();
    };

    /**
     * Custom model validation function. Validates child models.
     * @returns True if model is valid, false if not.
     */
    public isMyModelValid = async (): Promise<boolean> => {
        let isValid = true;

        // Validate Contact

        if ((await this.isModelValid()) === false) {
            isValid = false;
        }

        const refExisits: boolean = await this.isReferenceInUse(null, this.model.reference);

        if (refExisits === true) {
            isValid = false;
        }

        return isValid;
    };

    public isReferenceInUse = async (id: string | null, reference: string): Promise<boolean> => {
        const request: IsReferenceInUseRequest = {
            id: id,
            reference: reference,
        };

        const apiResult = await this.Post<boolean>(AppUrls.Server.Supplier.IsReferenceInUse, request);

        if (apiResult.wasSuccessful === true && apiResult.payload === true) {
            runInAction(() => {
                this.IsErrored = true;
                this.setError("reference", "The reference address is already in use.");
                this.setValid("reference", false);
            });
        }

        return apiResult.payload;
    };

    public insert = async (): Promise<ApiResult<AddSupplierModelDTO>> => {
        this.setIsLoading(true);

        if (await this.isMyModelValid()) {
            const request: AddSupplierModelDTO = this.model.toSupplierDetailModelDto();
            let apiResult = await this.Post<AddSupplierModelDTO>(AppUrls.Server.Supplier.Upsert, request);
            this.setIsLoading(false);
            return apiResult;
        } else {
            this.setIsLoading(false);
            this.errorMessage = "Form is not valid";
            return Promise.reject();
        }
    };

    public async isFieldValid(fieldName: keyof FieldType<AddSupplierModel>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        if (fieldName === "name") {
            errorMessage = this.isNameValid(value);
            isValid = errorMessage === "";
        }

        if (fieldName === "reference") {
            errorMessage = this.isReferenceValid(value);
            isValid = errorMessage === "";
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public isNameValid(value: string): string {
        let error = "";

        const field: string | null = this.getValue<string | null>("name");

        if (validator.isEmpty(value) === true) {
            error = "Name is required";
        } else if (field!.length > 128) {
            error = "Name needs to be less than 128 characters";
        }

        return error;
    }

    public isReferenceValid(value: string): string {
        let error = "";

        const field: string | null = this.getValue<string | null>("reference");

        if (validator.isEmpty(value) === true) {
            error = "Reference is required";
        } else if (field!.length > 128) {
            error = "Reference can be no longer than 128 characters";
        }

        return error;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
