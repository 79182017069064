import React, { ReactElement } from "react";
import { Box, makeStyles, Radio, styled, withStyles } from "@material-ui/core";
import { theme } from "Globals/Styles/AppTheme";

// custom

import Tick from "../../Content/Tick.svg";
import TimerIcon from "../../Content/TimerIcon.svg";

export interface ICustomHrProps {
    padding?: string;
    margin?: string;
    border?: string;
    style?: any;
}

export const CustomHr = (props: ICustomHrProps) => {
    const padding: string = props.padding === undefined ? "0px" : props.padding;
    const margin: string = props.margin === undefined ? "0px" : props.margin;
    const border: string = props.border === undefined ? "solid 1px #d4d4d4" : props.border;

    const Line: any = styled("hr")({
        borderTop: `${border} !important`,
        margin: `${margin}`,
        color: `#d4d4d4`,
    });

    return (
        <Box margin={props.margin} padding={padding}>
            <Line />
        </Box>
    );
};

export const CustomRadio = withStyles({
    colorSecondary: {
        "&$checked": {
            color: `${theme.palette.orange.main}!important`,
            "& > div.PrivateRadioButtonIcon-checked > .MuiSvgIcon-root": {
                color: `green`,
                transform: "scale(1.2) !important",
            },
        },
    },
    checked: {},
})(Radio);

interface ICustomArrowProps {
    color?: string;
    size?: string;
    type?: string;
    onClick?: any;
}

export const CustomArrow = (props: ICustomArrowProps) => {
    const color: string = props.color === undefined ? "black" : props.color;
    const size: string = props.size === undefined ? "20px" : props.size;
    const type: any = props.size === undefined ? "up" : props.type;
    const classes: string = `arrow-${type}` ?? "arrow-up";
    const ArrowContainer: any = styled("div")({
        width: "0px",
        height: "0px",
        color: "transparent",

        "& > div.arrow-up": {
            borderLeft: `${size} solid transparent`,
            borderRight: `${size} solid transparent`,
            borderBottom: `${size} solid ${color}`,
        },
        "& > div.arrow-down": {
            borderLeft: `${size} solid transparent`,
            borderRight: `${size} solid transparent`,
            borderTop: `${size} solid ${color}`,
        },
        "& > div.arrow-right": {
            borderTop: `${size} solid transparent`,
            borderBottom: `${size} solid transparent`,
            borderLeft: `${size} solid ${color}`,
        },
        "& > div.arrow-left": {
            borderTop: `${size} solid transparent`,
            borderBottom: `${size} solid transparent`,
            borderRight: `${size} solid ${color}`,
        },
    });

    return (
        <ArrowContainer>
            <div className={`${classes}`} style={{ width: "0px", height: "0px" }}>
                &nbsp;
            </div>
        </ArrowContainer>
    );
};

interface IRowDashProps {
    style?: any;
}

export const RowDash = (props: IRowDashProps) => {
    const { style } = props;

    return <span style={style}>&#8627;</span>;
};

interface IApprovalStatusIcon {
    status: string;
}

export const ApprovalStatusIcon = (props: IApprovalStatusIcon) => {
    const { status } = props;
    const icon = () => {
        switch (status) {
            case "DRAFT": {
                return <span>&nbsp;</span>;
            }
            case "AWAITINGAPPROVAL": {
                return <img src={TimerIcon} alt="" />;
            }
            case "APPROVED": {
                return <img src={Tick} alt="" />;
            }
            default: {
                return <span>&nbsp;</span>;
            }
        }
    };
    return <div style={{ display: "flex", justifyContent: "center", width: "15px", marginLeft: "15px" }}>{icon()}</div>;
};

interface ICheckBoxIcon {
    isPrimary: any;
    color: string;
    style?: any;
}

const useStyles = makeStyles({
    checkBox: {
        width: "20px",
        height: "20px",
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
});

export const CheckBoxIcon = (props: ICheckBoxIcon) => {
    const { isPrimary, color, style } = props;
    const classes = useStyles();
    return (
        <div className={classes.checkBox} style={style}>
            <span>
                {isPrimary() === true ? (
                    <svg xmlns="http://www.w3.org/2000/svg" width="12.408" height="9.746" viewBox="0 0 12.408 9.746">
                        <path
                            id="tick"
                            d="M3.717,7.746,0,4.029,1.048,2.981,3.717,5.643,9.36,0l1.048,1.056Z"
                            transform="translate(1 1)"
                            fill={color}
                            stroke={color}
                            stroke-linejoin="round"
                            stroke-width="2"
                        />
                    </svg>
                ) : (
                    ""
                )}
            </span>
        </div>
    );
};
