import React from "react";
import { observer } from "mobx-react-lite";
import { UserModel } from "../Common/UserModel";
import { PreviewImage } from "../../../../Globals/Styles/AppStyling";
import { SHBox } from "../../../../Components/Box";
import { Loader, useRouter } from "@shoothill/core";
import { InductionViewModel } from "Views/Induction/InductionViewModel";
import { Button } from "@material-ui/core";
import { AppUrls } from "AppUrls";

interface Props {
    parentViewModel: InductionViewModel;
}

export const SignedInView: React.FC<Props> = observer((props: Props) => {
    const [user, setUser] = React.useState({} as UserModel);
    const { history } = useRouter();

    React.useEffect(() => {
        const userModel = localStorage.getItem("user");
        if (userModel) {
            setUser(JSON.parse(userModel) as UserModel);
        }
    }, []);

    if (props.parentViewModel.isStageLoading) {
        return (
            <>
                <Loader />
            </>
        );
    }

    const navigateToMenu = () => {
        history.push(AppUrls.Client.Project.SiteTablet.Menu);
    };

    return (
        <SHBox grid dc={"1fr"} mc={"1fr"} height={"70vh"} pl={3} pr={3}>
            <SHBox grid dc={"1fr"} mc={"1fr"} justifyItems={"center"} alignContent={"flex-start"}>
                <SHBox mt={3}>
                    <PreviewImage width={128} height={128} src={user.photographUrl} alt={"Profile Image"} />
                </SHBox>
                <SHBox textAlign={"center"}>
                    <p style={{ fontSize: "24px", fontWeight: 600 }}>{user.fullName}</p>
                    <p>{user.priorityEmployerName}</p>
                </SHBox>
                <SHBox textAlign={"center"}>
                    <p style={{ fontSize: "18px", fontWeight: 600 }}>You are signed {localStorage.getItem("signedIn") === "true" ? "IN" : "OUT"}</p>
                    <p>Thank you, have a great day</p>
                </SHBox>
            </SHBox>
            <SHBox textAlign={"center"}>
                <p style={{ fontSize: "18px", fontWeight: 600 }}>You can now close this window</p>
            </SHBox>
            <SHBox textAlign={"center"}>
                <Button onClick={navigateToMenu} color="secondary" autoFocus variant="contained" disabled={props.parentViewModel.IsLoading}>
                    {"Go to menu"}
                </Button>
            </SHBox>
        </SHBox>
    );
});
