import { Box } from "@material-ui/core";
import { PrimaryButton } from "Components/Buttons/Buttons";
import { pxToRem } from "Globals/Styles/AppTheme";
import React from "react";
import styled from "styled-components";

export const NewFutureSpendLabel = styled(Box)`
    font-size: ${pxToRem(12)};
    font-weight: bold;
    padding: ${pxToRem(5.6)} 0;
`;

export const NewFutureSpendContainer = styled(Box)`
    //margin: ${pxToRem(24)} ${pxToRem(16)} ${pxToRem(0)} ${pxToRem(16)};
`;

export const FutureSpendAmendmentAddButton = styled(PrimaryButton)`
    max-width: 70px;
    margin-top: 20px;
`;

export const FutureSpendItem: any = styled.div`
    display: flex;
    flex-direction: column;
    padding: 30px 0px;
    border-bottom: 1px dotted grey;
    font-size: 12px;
    font-weight: 600;

    a {
        text-decoration: underline;
    }

    .value-orange {
        color: #eb8d00;
    }

    .value-green {
        color: #52bb71;
    }

    .value-red {
        color: #df3a3a;
    }

    .future-spend-group {
        max-width: 450px;
        display: flex;
        flex-direction: row;

        > div {
            min-width: 150px;
        }
    }
`;

export const FutureSpendItemContentRow: any = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 0px;

    > div:nth-of-type(1) {
        display: flex;
        width: 100%;
        align-items: flex-start;
    }

    > div:nth-of-type(2) {
        margin-right: 260px;
        white-space: nowrap;
        text-align: right;
    }

    > div:nth-of-type(3) {
        max-width: 425px;
        width: 100%;
    }

    > div > img {
        max-width: 20px;
        margin-right: 10px;
    }
`;

export const FutureSpendPORefRow: any = styled.div`
    padding: 5px 0px;
`;

export const FutureSpendItemNoteRow: any = styled.div`
    padding: 5px 0px;
`;

export const FutureSpendItemDetailsRow: any = styled.div`
    padding: 5px 0px;

    display: flex;
    flex-direction: row;

    > div {
        display: flex;
        flex-direction: row;
        margin-right: 30px;

        > div:nth-child(1) {
            margin-right: 8px;
        }
    }
`;
