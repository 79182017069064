import { getHistory, FieldType, ViewModelBase, ApiResult, KeyValuePair } from "@shoothill/core";
import { action, computed, observable, runInAction } from "mobx";

import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { AllProjectInvoiceReportModel, AllProjectInvoiceReportRequestDTO } from "./AllProjectInvoiceReportModel";
import moment from "moment";
import { AppUrls } from "AppUrls";
import { AllProjectReportAllProjectDTO, AllProjectReportResponseDTO } from "../Shared/AllProjectReportModelBase";
import { csvAxiosRequestConfig, exportCSV } from "Utils/Utils";

export class AllProjectInvoiceReportViewModel extends ViewModelBase<AllProjectInvoiceReportModel> {
    // #region Constructors and Disposers

    constructor() {
        super(new AllProjectInvoiceReportModel());

        this.setDecorators(AllProjectInvoiceReportViewModel);
    }

    @observable public reportViewModel: AllProjectReportAllProjectDTO | null = null;

    public handleProjectStatusChange = (val: number[] | null) => {
        this.model.setValue("projectStatusIds", val);
    };

    @observable public canExportCSV: boolean = false;

    @action
    public setCanExportCSV(val: boolean) {
        this.canExportCSV = val;
    }

    @computed
    public get getTopDateFormatted(): string {
        return this.model.reportCurrentDate ? moment(this.model.reportCurrentDate).format("DD/MM/YYYY").toString() : "Please select a date";
    }

    @computed
    public get getBottomDateFormatted(): string {
        return this.model.reportPreviousDate ? moment(this.model.reportPreviousDate).format("DD/MM/YYYY").toString() : "Please select a date";
    }

    // #region Client Actions

    @action
    public runReport = async (): Promise<ApiResult<AllProjectReportResponseDTO>> => {
        this.setIsLoading(true);

        const request: AllProjectInvoiceReportRequestDTO = {
            previousDate: this.model.reportPreviousDate,
            currentDate: this.model.reportCurrentDate,
            projectStatusIds: this.model.projectStatusIds,
        };
        let apiResult = await this.Post<AllProjectReportResponseDTO>(AppUrls.Server.Report.AllProjects.GetAllProjectsInvoiceReport, request);
        if (apiResult) {
            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.reportViewModel = apiResult.payload.allProject;
                });
            } else {
                console.log(apiResult.errors);
            }
        }
        this.setIsLoading(false);
        return apiResult;
    };

    @action
    public generateAllProjectInvoiceReportCSV = async () => {
        this.setIsLoading(true);

        const request: AllProjectInvoiceReportRequestDTO = {
            previousDate: this.model.reportPreviousDate,
            currentDate: this.model.reportCurrentDate,
            projectStatusIds: this.model.projectStatusIds,
        };

        await exportCSV(AppUrls.Server.Report.AllProjects.GenerateAllProjectsInvoiceReportCSV, request, await this.getConfig(true, csvAxiosRequestConfig)).finally(() =>
            this.setIsLoading(false),
        );
    };

    public server: ServerViewModel = new ServerViewModel();

    @action
    public reset = () => {
        this.model.reset();
        this.server.reset();
    };

    @action
    public handleCancel = (): void => {
        getHistory().goBack();
    };

    // #endregion Client Actions

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<AllProjectInvoiceReportModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    // #region Snackbar

    @observable
    public snackbarState = false;

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public snackMessage = "";

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @observable
    public snackType = "";

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    @observable
    public SNACKSUCCESS = "success";

    @observable
    public SNACKERROR = "error";
    // #endregion

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
