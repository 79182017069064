import styled from "styled-components";

export const ApprovalHistoryContainer = styled.div`
    background-color: #fcfcfc;
    display: flex;
    flex-direction: column;
    padding: 8px;

    // Header
    h1 {
        font-size: 14px;
        line-height: 1.33;
        margin: 8px 8px 5px 8px;
    }

    // History list.
    ol {
        display: flex;
        flex: 1;
        flex-direction: column;
        list-style-type: none;
    }

    // History list item.
    li {
        font-size: 10px;
        line-height: 1.33;
        margin: 8px;

        .item-header {
            display: flex;
            font-weight: bold;
            justify-content: space-between;
        }

        .item-body {
            background-color: white;
            border: 1px solid #dbe0e4;
            margin-top: 8px;
            padding: 5px 10px;
            position: relative;

            .item-body-status {
                font-weight: bold;
            }

            .item-body-notes {
                margin-top: 2px;
                white-space: pre-wrap;
            }

            &:after,
            &:before {
                bottom: 100%;
                left: 0%;
                content: "";
                height: 0px;
                width: 0px;
                position: absolute;
                pointer-events: none;
                border-style: solid;
                border-image: none;
                border-width: 7px;
                margin-left: 6px;
            }

            &:after {
                border-color: transparent transparent white;
                margin-bottom: -2px;
            }

            &:before {
                border-color: transparent transparent #dbe0e4;
            }
        }
    }

    // Odd-valued history list item.
    li.item-odd {
        .item-header {
            flex-direction: row-reverse;
        }

        .item-body {
            background-color: #eaf4f9;
            border: 1px solid #b0def3;

            &:after,
            &:before {
                left: 100%;
                margin-left: -20px;
            }

            &:after {
                border-color: transparent transparent #eaf4f9;
            }

            &:before {
                border-color: transparent transparent #b0def3;
            }
        }
    }

    li.item-type-1 {
    }

    li.item-type-2 {
    }

    li.item-type-3 {
        .item-body {
            background-color: #faefef;
            border: 1px solid #df3a3a;

            &:after {
                border-color: transparent transparent #faefef;
            }

            &:before {
                border-color: transparent transparent #df3a3a;
            }
        }
    }

    li.item-type-4 {
    }

    li.item-type-5 {
        .item-body {
            background-color: #f1f8f3;
            border: 1px solid #52bb71;

            &:after {
                border-color: transparent transparent #f1f8f3;
            }

            &:before {
                border-color: transparent transparent #52bb71;
            }
        }
    }

    .uploadedFile {
        grid-template-columns: 1fr !important;
    }

    // Document names
    h3 {
        font-size: 11px;
    }
`;
