import { Button, TextField } from "@material-ui/core";
import { isEmptyOrWhitespace } from "@shoothill/core/dist/Utils";
import { observer } from "mobx-react-lite";
import React from "react";
import { NumericFormat } from "react-number-format";

import { SolidSectionSplitter } from "Components/Form/SectionSplitter";
import { BaseModal } from "Components/Modal/BaseModal";
import { SnackBar } from "Components/SnackBar/SnackBar";
import { CancelButton } from "Globals/Styles/Control/Buttons";
import { isTypeOfNumber } from "Utils/Utils";
import { ReportModalStyle } from "Views/Project/Modals/Modal.Styles";
import { StockAdjustmentViewModel } from "./StockAdjustmentViewModel";
import { FormModalSection, FormRow } from "Views/Stock/Shared/Shared.styles";
import { CalculatedAdjustedStockView } from "../Shared/CalculatedAdjustedStock/CalculatedAdjustedStockView";

interface IProps {
    viewModel: StockAdjustmentViewModel;
}

export const StockAdjustmentView: React.FC<IProps> = observer((props) => {
    const viewModel = props.viewModel;

    return (
        <BaseModal
            open={viewModel !== null}
            onClose={viewModel.cancel}
            title="Stock adjustment"
            PaperComponent={ReportModalStyle}
            actions={
                <React.Fragment>
                    <Button autoFocus color="secondary" disabled={viewModel.server.IsBusy} onClick={viewModel.apiSaveStockAdjustmentAsync} variant="contained">
                        Save
                    </Button>
                    <CancelButton onClick={viewModel.cancel}>Cancel</CancelButton>
                </React.Fragment>
            }
        >
            <FormModalSection>
                <FormRow grid dc={"1fr 1fr 1fr 1fr"}>
                    <NumericFormat
                        customInput={TextField}
                        InputLabelProps={{ shrink: true }}
                        label="Adjustment:"
                        onBlur={() => {
                            viewModel.isFieldValid("adjustmentUnits");
                            // SIDE-EFFECT
                            // Having set the line-description, we need to try and evaluate the number
                            // of available units and the stock price.
                            viewModel.apiLoadAdjustableStockAsync();
                        }}
                        onValueChange={(values: any) => {
                            viewModel.setValue("adjustmentUnits", isTypeOfNumber(values.floatValue) ? values.floatValue : values.value);
                        }}
                        allowNegative={true}
                        decimalScale={0}
                        thousandSeparator={true}
                        suffix={" units"}
                        helperText={viewModel.getError("adjustmentUnits")}
                        error={!isEmptyOrWhitespace(viewModel.getError("adjustmentUnits"))}
                        value={viewModel.getValue("adjustmentUnits")}
                    />
                </FormRow>
                <FormRow grid dc={"1fr"}>
                    <TextField
                        className="textarea-forminput"
                        InputLabelProps={{ shrink: true }}
                        label="Notes:"
                        onBlur={() => viewModel.isFieldValid("adjustmentNote")}
                        onChange={(event) => viewModel.setValue("adjustmentNote", event.target.value)}
                        helperText={viewModel.getError("adjustmentNote")}
                        error={!isEmptyOrWhitespace(viewModel.getError("adjustmentNote"))}
                        value={viewModel.getValue("adjustmentNote")}
                    />
                </FormRow>
            </FormModalSection>
            {viewModel.canDisplayCalculatedAdjustedStock && (
                <React.Fragment>
                    <SolidSectionSplitter />
                    <FormModalSection>
                        <CalculatedAdjustedStockView viewModel={viewModel.calculatedAdjustedStock!} />
                    </FormModalSection>
                </React.Fragment>
            )}
            <SnackBar
                messageText={viewModel.snackMessage}
                messageType={viewModel.snackType}
                active={viewModel.snackbarState}
                closeOption={() => viewModel.setSnackbarState(false)}
                autoHideDuration={5000}
            />
        </BaseModal>
    );
});
