import React, { useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { InductionUserViewModel } from "./InductionUserViewModel";
import { SHBox } from "../../../../Components/Box";
import { Uploader } from "../../../../Components/Uploader/Uploader";
import { SectionSplitter } from "../../../../Components/Form/SectionSplitter";
import { ToolboxTalkFormViewModel } from "./ToolboxTalkFormViewModel";
import { uniqueId } from "lodash-es";
import { FormHelperText, IconButton, Typography } from "@material-ui/core";
import SystemUpdateTwoToneIcon from "@material-ui/icons/SystemUpdateTwoTone";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { DarwinInput } from "../../../../Globals/Styles/Control/DarwinInput";
import { DarwinSelect } from "../../../../Components/AutoComplete/DarwinSelect";
import { Signature } from "Components/Signature/Signature";
import { Cell, Grid } from "@shoothill/core";
import styled from "styled-components";
import { isEmpty } from "../../../../Utils/Utils";
import SignatureCanvas from "react-signature-canvas";

interface Props {
    index: number;
    inductionUserViewModel: InductionUserViewModel;
    toolboxTalkFormViewModel: ToolboxTalkFormViewModel;
}
export const InductionUserView: React.FC<Props> = observer((props: Props) => {
    const { inductionUserViewModel, toolboxTalkFormViewModel } = props;
    const padRef = useRef<SignatureCanvas>(null);

    const handleOnEnd = (data: any): void => {
        inductionUserViewModel.updateSignature(padRef.current!.getTrimmedCanvas().toDataURL("image/png"));
    };

    return (
        <>
            <Grid columnGap={30} rowGap={20} tc={"1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr"} dc={"1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr"} key={uniqueId()}>
                <Cell ts={2} ds={2}>
                    <DarwinSelect
                        displayName="Individual receiving toolbox talk:"
                        execute={inductionUserViewModel.handleSetInductionUser}
                        fullWidth={true}
                        getOptionLabel={(option: any) => option.displayName}
                        options={toolboxTalkFormViewModel.inductionLookupUsers}
                        placeholder="Please select"
                        value={inductionUserViewModel.model.inductionUser}
                        canExecute={!inductionUserViewModel.IsLoading}
                        error={inductionUserViewModel.model.getError("inductionUserId") !== ""}
                        validationMessage={inductionUserViewModel.model.getError("inductionUserId")}
                    />
                </Cell>
                <Cell ts={4} ds={4}></Cell>
                <Cell ts={4} ds={4}>
                    <SignatureContainer>
                        <div>
                            <div>
                                <Signature
                                    displayName={"Your signature:"}
                                    execute={inductionUserViewModel.updateSignature}
                                    value={inductionUserViewModel.model.signatureURL}
                                    canExecute={!inductionUserViewModel.IsLoading}
                                />
                                {inductionUserViewModel.getError("signatureURL") !== "" && (
                                    <FormHelperText style={{ color: "#f44336" }}>{inductionUserViewModel.getError("signatureURL")}</FormHelperText>
                                )}
                            </div>
                        </div>
                    </SignatureContainer>
                </Cell>
                <Cell ts={4} ds={4}>
                    <IconButton onClick={() => inductionUserViewModel.model.handleDelete(true)}>
                        <DeleteOutlinedIcon />
                    </IconButton>
                </Cell>
            </Grid>
        </>
    );
});

const SignatureContainer = styled.div`
    display: flex;
    flex-direction: column;

    > div {
        display: flex;
        flex-direction: row;

        > div {
            max-width: 262px;
            margin-right: 30px;
            width: 100%;

            canvas {
                border: 1px solid #d4d4d4;
            }
        }
    }
`;
