import { FieldType, KeyValuePair, observable, ViewModelBase } from "@shoothill/core";
import { action } from "mobx";
import { InvoiceStatusDTO } from "Views/Invoice/Form/Details/InvoiceDetailsModel";
import { InductionListFilterParamsModel } from "./InductionListFilterParamsModel";
import { InductionStatusTypeDTO } from "./InductionStatusTypeModel";

export class InductionListFilterViewModel extends ViewModelBase<InductionListFilterParamsModel> {
    private static _intsance: InductionListFilterViewModel;
    public static get Instance() {
        return this._intsance || (this._intsance = new this());
    }

    constructor() {
        super(new InductionListFilterParamsModel(), false);
        this.setDecorators(InductionListFilterParamsModel);
    }

    public async isFieldValid(fieldName: keyof FieldType<InductionListFilterParamsModel>): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
