// Libraries
import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { Cell, Grid, generateID, KeyValuePair, useRouter, FieldType } from "@shoothill/core";
import "../Construction/Construction.styles";

// Custom
import { DetailsHeader } from "Globals/Views/DetailsPage/DetailsHeader";
import { ProjectGeneralViewModel } from "../General/ProjectGeneralViewModel";
import { AddressModel, AddressModelDTO } from "Globals/Models/Domain";

// Styling & Images
import { DetailsPage } from "Globals/Views/DetailsPage/DetailsPage.styles";
import { ContractRow } from "../Contractual/Contractual.styles";
import { Button, MenuItem, Select, FormControl, InputLabel, Typography } from "@material-ui/core";
import { DarwinInput } from "Globals/Styles/Control/DarwinInput";
import { ProjectConstructionViewModel } from "./ProjectConstructionViewModel";
import { ProjectConstructionModel } from "./ProjectConstruction";
import { ProjectSystemTypeHelpers } from "Globals/Models/Domain";
import { ProjectAddressEditBox, ProjectButtonContainer } from "../Project.styles";
import { CancelButton } from "Globals/Styles/Control/Buttons";
import { AppUrls } from "AppUrls";
import { AddressComponentView } from "Views/Address/AddressComponentView";
import { AddressListContainer } from "Views/Address/AddressListContainer";
import { SnackBar } from "Components/SnackBar/SnackBar";
import { GeneralModel } from "../General/GeneralModel";
import { ConstructionAddressListContainer } from "./ConstructionAddressListContainer";

export const ConstructionAddEditView: React.FunctionComponent = () => {
    const router = useRouter();
    const [generalViewModel] = useState(() => ProjectGeneralViewModel.Instance);
    const [viewModel] = useState(new ProjectConstructionViewModel());
    const [systemTypes, setSystemTypes] = useState<KeyValuePair<any>[]>([]);

    //const [addresses, setAddresses] = useState<AddressModel[]>([]);
    const [selectedAddress, setSelectedAddress] = useState<AddressModel>(viewModel.getModelAddress);

    const { history } = useRouter();

    useEffect(() => {
        let { projectid } = router.match.params as any;
        viewModel.apiGetbyId(projectid).then(() => {
            setSystemTypes(ProjectSystemTypeHelpers.getAllKeyValueSelectOptions());
            //setAddresses(viewModel.getAddressModelArr);
            viewModel.setValue("projectId", projectid);
        });

        return () => {
            viewModel.clean();
        };
    }, []);

    const onUpdateAddress = (address: AddressModelDTO): any => {
        viewModel.addAddress(address);
        //setAddresses(viewModel.getAddressModelArr);
    };

    const addressRowClicked = (val: string): any => {
        viewModel.setAddressModel(val);
        setSelectedAddress(viewModel.getAddressById(val));
        //viewModel.setAddressViewModel(val);
    };

    const addressMakePrimary = (index: number): any => {
        viewModel.setMainAddress(viewModel.getAddressByIndex(index), index);
        //setAddresses(viewModel.getAddressModelArr);
    };

    const addressDelete = (val: AddressModel, index: number): any => {
        viewModel.deleteAddress(val, index);
        //setAddresses(viewModel.getAddressModelArr);
    };

    // const renderAddressform = (
    //     <>

    //     </>
    // );

    const handleSystemTypeIdChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        viewModel.setValue<string>("systemTypeId", event.target.value as string);
    };

    const isInError = (fieldName: keyof FieldType<GeneralModel>) => {
        let isValid = generalViewModel.getValid(fieldName);
        return !isValid;
    };

    const onSaveClick = async () => {
        if ((await viewModel.isMyModelValid()) === true) {
            const promise = await viewModel.upsertConstruction().then((result: any) => {
                if (result.wasSuccessful === true) {
                    handleCancelCLick();
                }
            });
        } else {
            //window.scrollTo(0, 0);
        }
    };

    const handleCancelCLick = () => {
        history.push(AppUrls.Client.Project.Construction.replace(":projectid", viewModel.model.projectId));
    };

    const renderDetailRow = () => {
        return (
            <Grid margin="0 30px 17.5px 30px" columnGap={30} dc={"1fr 1fr 1fr 1fr"} rowGap={20} tc={"1fr 1fr 1fr 1fr"}>
                <Cell>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="systemtype-select" shrink>
                            Project type:
                        </InputLabel>
                        <Select
                            id="system-select"
                            value={viewModel.model.systemTypeId}
                            onChange={handleSystemTypeIdChange}
                            MenuProps={{
                                getContentAnchorEl: null,
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                            }}
                            fullWidth
                        >
                            {systemTypes.map((client: KeyValuePair<any>, index: number) => {
                                return (
                                    <MenuItem key={generateID()} value={client.value}>
                                        {client.key}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Cell>
                <Cell>
                    <DarwinInput<ProjectConstructionModel>
                        type="number"
                        label="No. modules:"
                        validateOnBlur={true}
                        viewModel={viewModel}
                        fieldName="noModules"
                        shrink={true}
                        maxLength={12}
                    />
                </Cell>
                <Cell>
                    <DarwinInput<ProjectConstructionModel>
                        type="number"
                        label="G.I.F.A (Gross Internal Floor Area):"
                        validateOnBlur={true}
                        viewModel={viewModel}
                        fieldName="gifa"
                        shrink={true}
                        maxLength={12}
                    />
                </Cell>
                <Cell></Cell>
            </Grid>
        );
    };

    const renderSiteRow = () => {
        return (
            <>
                <Grid margin="10px 30px" columnGap={30} dc={"1fr 1fr 1fr 1fr"} rowGap={20} tc={"1fr 1fr 1fr 1fr"}>
                    <Cell desktopSpan={2}>
                        <DarwinInput<ProjectConstructionModel>
                            type="text"
                            label="Site address:"
                            validateOnBlur={true}
                            viewModel={viewModel}
                            fieldName="siteAddress"
                            shrink={true}
                            maxLength={1028}
                        />
                    </Cell>
                    <Cell>
                        <DarwinInput<ProjectConstructionModel>
                            type="text"
                            label="Site postcode:"
                            validateOnBlur={true}
                            viewModel={viewModel}
                            fieldName="sitePostcode"
                            shrink={true}
                            maxLength={15}
                        />
                    </Cell>
                </Grid>
                <Grid margin="10px 30px" columnGap={30} dc={"1fr 1fr 1fr 1fr"} rowGap={20} tc={"1fr 1fr 1fr 1fr"}>
                    <Cell>
                        <DarwinInput<ProjectConstructionModel>
                            type="text"
                            label="Site manager:"
                            validateOnBlur={true}
                            viewModel={viewModel}
                            fieldName="siteManagerName"
                            shrink={true}
                            maxLength={15}
                        />
                    </Cell>
                    <Cell>
                        <DarwinInput<ProjectConstructionModel>
                            type="text"
                            label="S.M. contact email:"
                            validateOnBlur={true}
                            viewModel={viewModel}
                            fieldName="smContactEmail"
                            shrink={true}
                            maxLength={256}
                        />
                    </Cell>
                    <Cell>
                        <DarwinInput<ProjectConstructionModel>
                            type="text"
                            label="S.M. contact phone:"
                            validateOnBlur={true}
                            viewModel={viewModel}
                            fieldName="smContactPhone"
                            shrink={true}
                            maxLength={128}
                        />
                    </Cell>
                    <Cell></Cell>
                </Grid>
            </>
        );
    };

    return useObserver(() => (
        <DetailsPage className="cell-right rcselect">
            <DetailsHeader viewModel={generalViewModel.getHeader} />
            <ContractRow>
                <Typography variant="h3">Detail...</Typography>
            </ContractRow>
            {renderDetailRow()}
            <ContractRow>
                <Typography variant="h3">Site</Typography>
            </ContractRow>
            {renderSiteRow()}
            <ProjectAddressEditBox>
                <Typography variant="h2">Delivery Addresses</Typography>
                <AddressComponentView onUpdateModel={onUpdateAddress} viewModel={viewModel.addressViewModel} isLoading={false} showAddButton={true} isDeliveryAddresses={true} />
            </ProjectAddressEditBox>

            <ConstructionAddressListContainer
                addressViewModels={viewModel.getAddressViewModelArr /* viewModel.getAddressModelArr */}
                addressRowClicked={addressRowClicked}
                addressPrimaryText={""}
                addressDeleteText={"Delete"}
                addressDelete={addressDelete}
            />
            <ProjectButtonContainer>
                <Button onClick={onSaveClick} color="primary" autoFocus variant="contained">
                    Update Construction
                </Button>
                <CancelButton onClick={() => handleCancelCLick()}>Cancel</CancelButton>
            </ProjectButtonContainer>
            <SnackBar
                messageText={viewModel.snackMessage}
                messageType={viewModel.snackType}
                active={viewModel.snackbarState}
                closeOption={() => viewModel.setSnackbarState(false)}
                autoHideDuration={5000}
            />
        </DetailsPage>
    ));
};
