import styled from "styled-components";

export const NoteWrapper = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;

    > div {
        > div {
            max-width: 1124px;
            width: 100%;
            padding: 0px;
            margin: 0px 15px;
            &:first-of-type {
                margin: 0px 15px 0px 0px;
            }

            .MuiBox-root {
                width: 100%;
            }
        }
    }

    // background-color: orange
    @media screen and (max-width: 1024px) {
    }

    @media screen and (max-width: 640px) {
        flex-direction: column;
    }
`;

export const NoteRow = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: row;
    width: 100%;
    padding: 8px 0;
    align-items: flex-start;

    /* background-color: orange; */

    @media screen and (max-width: 1024px) {
    }

    @media screen and (max-width: 640px) {
        flex-direction: row;
    }

    .container {
        display: flex;
        flex: 1;
        width: 100%;
        flex-direction: column;
    }
`;
export const NoteListRow = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
    font-size: 12px;
    /* background-color: red; */
    padding: 10px 0;
    margin: 0 0 5px 0;
`;

export const NoteListRowContent = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;

    .who {
        display: flex;
        flex: 1;
        margin-bottom: 15px;
    }

    .contentRow {
        display: flex;
        flex: 1;
        width: 100%;
        flex-direction: row;
    }

    .content {
        display: flex;
        flex: 1;
    }

    .icon {
        max-width: 40px;

        &:hover {
            cursor: pointer;
        }
    }
`;

export const NotesStyledList = styled.div`
    margin: 0px 30px 30px 30px;
    list-style: none;

    /* li {


        > div:not(.icon) {
            display: inline-flex;
            flex-direction: column;
        }

        > div.content {
            width: 80%;
        }

        > div.icon {
            display: inline-flex;
            flex-direction: column;
            img {
                width: 16px;
                vertical-align: middle;
            }
        }
    } */
`;

export const NotesDocumentList = styled.div`
    display: flex;
    flex: 0 1 0;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
`;

export const DropzoneContainer = styled.div`
    min-height: 100px;
    width: 100%;
    display: flex;
    flex: 1 0 1;
    flex-direction: column;
    vertical-align: center;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border: 1px dashed #cdcccc;

    &:hover {
        cursor: pointer;
    }
    input {
        border: 1px dashed black /* #cdcccc */;
    }
`;

export const NoteDocuments = styled.div`
    display: flex;
    flex: 1 0 0;
    width: 100%;
    flex-direction: row;
`;

export const NoteDocument = styled.div`
    display: flex;
    flex: 1 1 0;
    flex-direction: row;

    img {
        display: flex;
        flex: 0 1 0;
        max-width: 15px;
        max-height: 25px;
        padding: 0 5px 0 0;
        margin-top: -2px;
    }
    cursor: default;

    &:hover {
        cursor: pointer;
    }

    &.notFirst {
        padding-left: 5px;
    }
`;
