import { isNullOrUndefined } from "@shoothill/core";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

import MoneySVG from "Content/Money.svg";
import { formatCurrencyForGrid, formatCurrencyNoSign } from "Utils/Format";
import { StockTransferHistoryViewModel } from "Views/Stock/Stock/StockTransferHistory/StockTransferHistoryViewModel";
import { IEPOItemTitle } from "../IEGrid.Styles";
import { IEItemMaterial } from "../../IEmodels/IEGrid.IEItemMaterialModel";
import { StockTransferHistoryView } from "Views/Stock/Stock/StockTransferHistory/StockTransferHistoryView";

interface ITableProps {
    itemModel: IEItemMaterial;
}

export const IEItemMaterialRow: React.FunctionComponent<ITableProps> = observer((props) => {
    // #region Stock Transfer History Modal

    // This component has no supporting IEItemMaterial viewmodel available for us to store the
    // state of a transfer history viewmodel. So we will have to fall back onto React State.

    const [transferHistoryViewModel, setTransferHistoryViewModel] = useState<StockTransferHistoryViewModel | null>(null);

    const handleDisplayHistoryModal = () => {
        setTransferHistoryViewModel(new StockTransferHistoryViewModel(props.itemModel.id, handleCloseHistoryModal));
    };

    const handleCloseHistoryModal = () => {
        setTransferHistoryViewModel(null);
    };

    const canDisplayTransferHistoryModal = !isNullOrUndefined(transferHistoryViewModel);

    // #endregion Stock Transfer History Modal

    return (
        <li className="item" onClick={handleDisplayHistoryModal} style={{ cursor: "pointer" }}>
            <IEPOItemTitle>
                <img src={MoneySVG}></img>
                <div>
                    <span className="ie-row-name ie-po-name" style={{ marginLeft: "10px", whiteSpace: "normal" }} title={props.itemModel.description}>
                        {props.itemModel.description}
                    </span>
                </div>
            </IEPOItemTitle>
            <div></div>
            <div></div>
            <div></div>
            <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(props.itemModel.committedCost))}</div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            {canDisplayTransferHistoryModal && <StockTransferHistoryView viewModel={transferHistoryViewModel!} />}
        </li>
    );
});
