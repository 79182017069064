import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import * as React from "react";

import { BaseSidebarPaper } from "./BaseSidebar.Style";

interface IProps {
    open?: boolean;
    onClose(): void;
    primaryTitle: string;
    secondaryTitle?: string | null;
    bodyContent?: React.ReactChild;
    footerContent?: React.ReactChild;
}

export const BaseSidebar: React.FC<IProps> = ({ open, onClose, primaryTitle, secondaryTitle, bodyContent, footerContent }) => {
    const [ishide, setHide] = React.useState<boolean>(false);
    const onHide = () => {
        setHide(!ishide);
    };

    return (
        <>
            {open && (
                <BaseSidebarPaper style={!ishide ? { bottom: 0 } : { bottom: "unset" }}>
                    <div className="darwin-dialog-title darwin-dialog-title--head">
                        <div className="primary-row">
                            <span className="primaryTitle">{primaryTitle}</span>
                            <div>
                                <button onClick={onHide}>
                                    {!ishide && <ArrowDropDownIcon />}
                                    {ishide && <ArrowDropUpIcon />}
                                </button>
                                {/* <span role="button" onClick={onClose} className="close">
                                        🗙
                                    </span> */}
                            </div>
                        </div>
                        {secondaryTitle !== undefined && secondaryTitle !== null && (
                            <div className="secondary-row">
                                <span className="secondaryTitle">{secondaryTitle}</span>
                            </div>
                        )}
                    </div>
                    {!ishide && <div className="darwin-dialog-body">{bodyContent}</div>}
                    {footerContent != undefined && !ishide && <div className="darwin-dialog-footer">{footerContent}</div>}
                </BaseSidebarPaper>
            )}
        </>
    );
};
