import { ApiResult, FieldType, KeyValuePair, ViewModelBase } from "@shoothill/core";
import { IEViewModel } from "./IEViewModel";
import { IEViewModelDTO } from "./IEViewModel";

export class IEViewViewModel extends ViewModelBase<IEViewModel> {
    public constructor() {
        super(new IEViewModel(), false);
        this.setDecorators(IEViewModel);
        //this.model.fromDto(ie);
    }

    public async isFieldValid(fieldName: keyof FieldType<IEViewModel>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
