import { ModelBase } from "@shoothill/core";
import { Equals, IsNotEmpty, ValidateIf } from "class-validator";
import { action, computed, observable } from "mobx";

import { InductionType } from "Globals/Models/Enums/InductionType";

export class Step5Model extends ModelBase<Step5Model> {
    // #region Defaults

    public static readonly DEFAULT_INDUCTIONTYPEID = null;
    public static readonly DEFAULT_INDUCTIONTYPES = [];

    // #endregion Defaults

    // #region Properties

    @observable
    public inductionTypeId: string | null = Step5Model.DEFAULT_INDUCTIONTYPEID;

    @computed
    public get inductionType() {
        return this.inductionTypes.find((it) => it.id === this.inductionTypeId);
    }

    @computed
    public get isVisitorInductionType(): boolean {
        return this.inductionType ? this.inductionType?.IsOfType(InductionType.Visitor) : false;
    }

    @observable
    public inductionTypes = observable<InductionType>(Step5Model.DEFAULT_INDUCTIONTYPES);

    @observable
    @IsNotEmpty({ message: "Please select yes or no" })
    @Equals("true", { message: "You must answer Yes to this question" })
    public general: string = "";

    @observable
    @ValidateIf((o) => o.isVisitorInductionType === false)
    @IsNotEmpty({ message: "Please select yes or no" })
    @Equals("true", { message: "You must answer Yes to this question" })
    public trainingAndEquipment: string = "";

    @observable
    @IsNotEmpty({ message: "Please select yes or no" })
    @Equals("true", { message: "You must answer Yes to this question" })
    public ppe: string = "";

    @observable
    @ValidateIf((o) => o.isVisitorInductionType === false)
    @IsNotEmpty({ message: "Please select yes or no" })
    @Equals("true", { message: "You must answer Yes to this question" })
    public rams: string = "";

    @observable
    @ValidateIf((o) => o.isVisitorInductionType === false)
    @IsNotEmpty({ message: "Please select yes or no" })
    @Equals("true", { message: "You must answer Yes to this question" })
    public housekeeping: string = "";

    @observable
    @IsNotEmpty({ message: "Please select yes or no" })
    @Equals("true", { message: "You must answer Yes to this question" })
    public rulesDiscipline: string = "";

    @observable
    @IsNotEmpty({ message: "Please select yes or no" })
    @Equals("true", { message: "You must answer Yes to this question" })
    public siteSpecificBriefing: string = "";

    // #endregion Properties

    constructor() {
        super();
    }

    // #region Actions

    @action
    public fromDto(dto: any): void {
        throw new Error("Method not implemented.");
    }

    public toDto(): any {
        return {
            general: this.general === "true",
            trainingAndEquipment: !this.isVisitorInductionType ? this.trainingAndEquipment === "true" : false,
            ppe: this.ppe === "true",
            rams: !this.isVisitorInductionType ? this.rams === "true" : false,
            housekeeping: !this.isVisitorInductionType ? this.housekeeping === "true" : false,
            rulesDiscipline: this.rulesDiscipline === "true",
            siteSpecificBriefing: this.siteSpecificBriefing === "true",
        };
    }

    // #endregion Actions
}
