import { observable, runInAction, action, computed } from "mobx";
import { ViewModelBase, FieldType } from "@shoothill/core";
import { FireExtinguishersModel } from "./FireExtinguishersModel";
import { InductionUserLookupModel } from "../../Inspections/InductionUserLookupModel";
import { FireExtinguisherTypeLookupModel } from "./FireExtinguisherTypeLookupModel";

export class FireExtinguishersViewModel extends ViewModelBase<FireExtinguishersModel> {
    public extinguisherTypesDTO = [
        { id: "Water", displayName: "Water" },
        { id: "CO2", displayName: "CO2" },
        { id: "Dry Powder", displayName: "Dry Powder" },
        { id: "Foam", displayName: "Foam" },
        { id: "Wet Chemical", displayName: "Wet Chemical" },
        { id: "Other", displayName: "Other" },
    ];
    public extinguisherTypes: any = [];
    constructor(model = new FireExtinguishersModel()) {
        super(model);
        this.setDecorators(FireExtinguishersModel);
        // debugger;
        // this.extinguisherTypes.replace(FireExtinguisherTypeLookupModel.fromDtos(this.extinguisherTypesDTO));
        //this.extinguisherTypes.push(new FireExtinguisherTypeLookupModel());
        //this.extinguisherTypes.push({ id: "Other", displayName: "Other" });
    }
    @action
    public radioChanged = async (value: string, fieldName: string, editable: boolean) => {
        if (!editable) return;
        this.model[fieldName] = value;
        await this.isFieldValid(fieldName as any, value);
    };
    @action
    public setExtinguisherType = (type: any) => {
        this.model.typeOfExtinguisher = type.id;
        this.model.typeOfExtinguisherModel = type;
    };
    @computed
    public get extinguisherType() {
        const retval = this.extinguisherTypesDTO.find((x: any) => x.id === this.model.typeOfExtinguisher);
        return retval;
    }
    public async isFieldValid(fieldName: keyof FieldType<FireExtinguishersModel>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }
    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
