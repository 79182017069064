import * as React from "react";

import { CancelButton } from "Globals/Styles/Control/Buttons";
import { BaseModal } from "Components/Modal/BaseModal";
import { BaseModalDialogPaper, DarwinModalDialogPaper } from "Components/Modal/BaseModal.styles";
import { Button, Typography } from "@material-ui/core";
import { useEffect } from "react";
import { ApprovalDelegateUserDataViewModel } from "./ApprovalDelegateUserDataViewModel";
import { useObserver } from "mobx-react-lite";
import { DarwinSelect } from "Components/AutoComplete/DarwinSelect";
import { DarwinDateSelect } from "Components/DateSelect/DarwinDateSelect";
import styled from "styled-components";
import moment from "moment";

interface IProps {
    open?: boolean;
    title: string;
    onSave(): void;
    onClose(): void;
}

export const DelegateApprovalsModal: React.FC<IProps> = ({ open, onSave, onClose, title }) => {
    const viewModel: ApprovalDelegateUserDataViewModel = ApprovalDelegateUserDataViewModel.Instance;

    const [errorMessage, setErrorMessage] = React.useState<string>("");

    //useEffect below only gets run on initial render
    useEffect(() => {
        return () => {
            viewModel.cleanUp();
        };
    }, []);

    const onSaveLocal = () => {
        setErrorMessage("");
        const modelPromise: Promise<boolean> = viewModel.isMyModelValid();
        modelPromise.then(async (isValid: boolean) => {
            if (isValid === true) {
                let upsertPromise = viewModel.delegateApprovals();
                await upsertPromise.then((response) => {
                    if (response.wasSuccessful) {
                        onClose();
                    } else {
                        setErrorMessage("Failed to submit approval delegation. Please refresh and try again.");
                    }
                });
            }
        });
    };

    return useObserver(() => (
        <BaseModal
            dialogClasses="approval-delegate-dialog"
            open={open}
            onClose={onClose}
            title={title}
            PaperComponent={ApprovalDelegateModalDialogPaper}
            actions={
                <>
                    <Button onClick={onSaveLocal} color="secondary" autoFocus variant="contained" disabled={viewModel.IsLoading}>
                        Add
                    </Button>
                    <CancelButton onClick={onClose}>Cancel</CancelButton>
                </>
            }
        >
            <div>
                <DarwinSelect
                    displayName="User"
                    execute={viewModel.setDelegateUser}
                    fullWidth={true}
                    getOptionLabel={(option: any) => option.displayName}
                    options={viewModel.delegateUsers}
                    placeholder="Please select"
                    value={viewModel.delegateUser}
                    validationMessage={viewModel.approvalDelegateViewModel.getError("delegatedToUserId")}
                    onBlur={() => viewModel.approvalDelegateViewModel.isFieldValid("delegatedToUserId")}
                />
                <div>
                    <DarwinDateSelect
                        displayName="Start date"
                        execute={(value: string | null) => viewModel.approvalDelegateViewModel.setValue("startDate", value)}
                        onBlur={() => viewModel.approvalDelegateViewModel.isFieldValid("startDate")}
                        placeholder="Please select"
                        validationMessage={viewModel.approvalDelegateViewModel.getError("startDate")}
                        value={viewModel.approvalDelegateViewModel.getValue("startDate")}
                        canExecute={!viewModel.IsLoading}
                        minDate={moment.utc().toDate()}
                    />
                </div>
            </div>

            {viewModel !== null && errorMessage !== "" && (
                <Typography variant="caption" style={{ color: "red" }}>
                    {errorMessage}
                </Typography>
            )}
        </BaseModal>
    ));
};

export const ApprovalDelegateModalDialogPaper = styled(DarwinModalDialogPaper)`
    .MuiDialogContent-root {
        min-height: 80px;

        > div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;
            > div.MuiFormControl-root {
                max-width: 250px;
                min-width: 200px;
            }

            > div.MuiFormControl-root:nth-child(1) {
                margin-right: 10px;
            }
        }
    }
`;
