import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText, RadioGroup, TextField, Typography } from "@material-ui/core";
import { useObserver } from "mobx-react-lite";
import React from "react";
import styled from "styled-components";
import { CustomRadio } from "Views/Project/CustomComponents";
import { PermitQuestionAnswerViewModel } from "./PermitQuestionAnswerViewModel";
import { PermitQuestionFormTypeEnum } from "./PermitQuestionAnswerModel";
import { SolidDivider } from "Views/PurchaseOrder/Form/Views/Dividers";
import { SubtitleContainer } from "./PermitFormView";

interface IProps {
    questionAnswers: PermitQuestionAnswerViewModel[];
    permitTypeName: string;
    isLoading: boolean;
    isFormDisabled: boolean;
}

const DynamicQuestionAnswersBase: React.FC<IProps> = (props) => {
    // #region Code Behind

    const getFormInputId = (question: string): string => {
        return question.replace(" ", "-").replace(/[^\w\s]/gi, "");
    };

    const renderRadioButtons = (questionAnswer: PermitQuestionAnswerViewModel): JSX.Element => {
        return (
            <RadioContainer>
                <p>{questionAnswer.model.question}</p>
                <FormControl error={questionAnswer.getError("answerRadio") !== ""} variant="standard">
                    <RadioGroup
                        row
                        name={"radio-" + getFormInputId(questionAnswer.model.question) + "-group"}
                        value={questionAnswer.model.answerRadio}
                        onChange={(e: any) => questionAnswer.setValue("answerRadio", e.target.value)}
                    >
                        <FormControlLabel
                            value={"yes"}
                            control={<CustomRadio checked={questionAnswer.model.answerRadio === "yes"} />}
                            label="Yes"
                            disabled={props.isFormDisabled || props.isLoading}
                        />
                        <FormControlLabel
                            value={"no"}
                            control={<CustomRadio checked={questionAnswer.model.answerRadio === "no"} />}
                            label="No"
                            disabled={props.isFormDisabled || props.isLoading}
                        />
                        <FormControlLabel
                            value={"n/a"}
                            control={<CustomRadio checked={questionAnswer.model.answerRadio === "n/a"} />}
                            label="N/A"
                            disabled={props.isFormDisabled || props.isLoading}
                        />
                    </RadioGroup>
                    <FormHelperText>{questionAnswer.getError("answerRadio")}</FormHelperText>
                </FormControl>
            </RadioContainer>
        );
    };

    const renderTextArea = (questionAnswer: PermitQuestionAnswerViewModel): JSX.Element => {
        return (
            <TextAreaContainer>
                <TextField
                    id={getFormInputId(questionAnswer.model.question)}
                    multiline
                    minRows={4}
                    value={questionAnswer.model.answerText}
                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => questionAnswer.setValue<string>("answerText", event.target.value)}
                    fullWidth
                    className="textarea"
                    label={questionAnswer.model.question}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    placeholder={`Please be as specific as possible`}
                    disabled={props.isFormDisabled || props.isLoading}
                    error={questionAnswer.getError("answerText") !== ""}
                    helperText={questionAnswer.getError("answerText")}
                />
            </TextAreaContainer>
        );
    };

    const renderDropdown = (questionAnswer: PermitQuestionAnswerViewModel): JSX.Element => {
        return <></>;
    };

    const renderCheckbox = (questionAnswer: PermitQuestionAnswerViewModel, index: number): JSX.Element => {
        return (
            <RadioContainer>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={questionAnswer.model.answerCheckbox}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => questionAnswer.setValue("answerCheckbox", checked)}
                        />
                    }
                    label={questionAnswer.model.question}
                    name={`permit-checkbox` + index}
                    disabled={props.isFormDisabled || props.isLoading}
                />
                <FormHelperText>{questionAnswer.getError("answerCheckbox")}</FormHelperText>
            </RadioContainer>
        );
    };

    const renderQuestionAnswer = (questionAnswer: PermitQuestionAnswerViewModel, index: number): JSX.Element => {
        switch (questionAnswer.model.formType) {
            case PermitQuestionFormTypeEnum.TextInput:
                return renderTextArea(questionAnswer);
            case PermitQuestionFormTypeEnum.RadioButtons:
                return renderRadioButtons(questionAnswer);
            case PermitQuestionFormTypeEnum.Dropdown:
                return renderDropdown(questionAnswer);
            case PermitQuestionFormTypeEnum.Checkbox:
                return renderCheckbox(questionAnswer, index);
            default:
                return <></>;
        }
    };

    return useObserver(() => {
        return (
            <>
                {props.questionAnswers.filter((i) => i.model.isAdditionalQuestion).length > 0 && (
                    <>
                        <DynamicQuestionAnswersContainer>
                            {props.questionAnswers
                                .filter((i, index) => i.model.isAdditionalQuestion)
                                .map((questionAnswer, index) => {
                                    return renderQuestionAnswer(questionAnswer, index);
                                })}
                        </DynamicQuestionAnswersContainer>

                        <SolidDivider gutterBottom={true} gutterTop={true} borderThickness={2} borderOpacity={0.5} />
                    </>
                )}

                {props.questionAnswers.filter((i) => !i.model.isAdditionalQuestion).length > 0 && (
                    <>
                        <SubtitleContainer margin={"0px"}>
                            <Typography variant="h2">Precautions</Typography>
                        </SubtitleContainer>
                        <DynamicQuestionAnswersContainer>
                            {props.questionAnswers
                                .filter((i) => !i.model.isAdditionalQuestion)
                                .map((questionAnswer, index) => {
                                    return renderQuestionAnswer(questionAnswer, index);
                                })}
                        </DynamicQuestionAnswersContainer>
                    </>
                )}
            </>
        );
    });
};

export const DynamicQuestionAnswers = styled(DynamicQuestionAnswersBase)``;

export const DynamicQuestionAnswersContainer = styled(Box)`
    margin-bottom: 1.25rem;
`;

export const TextAreaContainer = styled.div`
    margin-bottom: 1.25rem;

    label {
        font-size: 12px !important;
        letter-spacing: -0.24px !important;
    }
`;

export const YesNoContainer = styled.div`
    margin-bottom: 1.25rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    > div,
    > p {
        display: flex;
        flex: 1 1 50%;
    }

    > p {
        font-size: 12px;
        font-weight: bold;
        letter-spacing: -0.24px;
    }
`;

export const RadioContainer = styled.div`
    margin-bottom: 1.25rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    > div,
    > p {
        display: flex;
        flex: 1 1 33%;
    }

    > p {
        font-size: 12px;
        font-weight: bold;
        letter-spacing: -0.24px;
    }
`;
