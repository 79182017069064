import { styled } from "@shoothill/core";
import { useObserver } from "mobx-react-lite";
import React from "react";
import { InductionAdminViewModel } from "../InductionAdminViewModel";
import { AdminSiteAccessFilterViewModel } from "./AdminSiteAccessFilterViewModel";
import { AdminSiteAccessTable } from "./AdminSiteAccessTable";

interface IProps {
    className?: string;
    viewModel: InductionAdminViewModel;
    inductionUserId: string;
}

const InductionAdminSiteAccessLogViewBase: React.FC<IProps> = (props) => {
    const { viewModel, inductionUserId } = props;
    // #region Code Behind

    const renderPage = () => {
        return (
            <div>
                <AdminSiteAccessTable
                    data={viewModel.getSiteAccessItems}
                    viewModel={viewModel}
                    isLoading={viewModel.IsLoading}
                    startDateValue={AdminSiteAccessFilterViewModel.Instance.model.getStartDateFilter}
                    endDateValue={AdminSiteAccessFilterViewModel.Instance.model.getEndDateFilter}
                    handleChangeStartDate={(startDate: string | null) => viewModel.handleChangeStartDate(inductionUserId, startDate)}
                    handleChangeEndDate={(endDate: string | null) => viewModel.handleChangeEndDate(inductionUserId, endDate)}
                    handleResetDateFilters={() => viewModel.handleResetDateFilters(inductionUserId)}
                    handleForceSignoutInductions={() => viewModel.forceInductionsSignOut(inductionUserId)}
                    handleSearchChange={(searchText: string) => viewModel.handleSearchChange(inductionUserId, searchText)}
                    handleSignoutFilterChange={(val: boolean) => viewModel.handleSignoutFilterChange(inductionUserId, val)}
                    onSiteFilterValue={AdminSiteAccessFilterViewModel.Instance.model.showOnSiteOnly}
                    handleDownloadCSV={() => viewModel.generateSiteAccessCSV(inductionUserId)}
                />
            </div>
        );
    };

    return useObserver(() => renderPage());
};

export const InductionAdminSiteAccessLogView = styled(InductionAdminSiteAccessLogViewBase)``;
