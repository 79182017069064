import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { DarwinSelect } from "../../../../Components/AutoComplete/DarwinSelect";
import { PrimaryButton } from "../../../../Components/Buttons/Buttons";
import { SHBox } from "../../../../Components/Box";
import { PatchedPagination } from "../../../../Components/Table/PatchedPagination";
import * as Defaults from "../../../../Globals/Defaults/TableOptions";
import { DarwinTablePageContent } from "../../../../Globals/Styles/AppStyling";
import MaterialTable from "material-table";
import { formatDate, formatTime } from "../../../../Utils/Format";
import styled from "styled-components";
import { NonConformanceListViewModel } from "./NonConformanceListViewModel";
import { NonConformanceListModel } from "./NonConformanceListModel";

const Wrapper = styled.div`
    padding: 5px 30px;
`;

interface Props {
    projectId: string;
    projectName: string;
    projectReference: string;
}
export const NonConformanceView: React.FC<Props> = observer((props: Props) => {
    const [listViewModel] = useState(() => new NonConformanceListViewModel());

    useEffect(() => {
        listViewModel.projectId = props.projectId;
        listViewModel.loadNonConformanceAndRelated();
    }, []);

    const renderListView = (
        <SHBox>
            <SHBox grid dc={"300px 100px"} alignItems={"flex-end"}>
                <DarwinSelect
                    displayName="Add non-conformance"
                    execute={listViewModel.setNonConformanceForm}
                    fullWidth={true}
                    getOptionLabel={(option: any) => option.displayName}
                    options={listViewModel.nonConformanceTypes}
                    placeholder="Please select type"
                    value={listViewModel.nonConformanceFormType}
                />
                <PrimaryButton canExecute={listViewModel.nonConformanceFormType !== null} execute={listViewModel.addNonConformanceForm} value={"Add"} />
            </SHBox>
            <DarwinTablePageContent>
                <MaterialTable
                    columns={[
                        { title: "Conformance type", field: "nonConformanceTypeName" },
                        {
                            title: "Reference No.",
                            field: "nonConformanceNumber",
                            render: (rowData: NonConformanceListModel) => (
                                <SHBox>
                                    <SHBox>{`CDG-${listViewModel.referenceNumber(rowData.nonConformanceNumber)}`}</SHBox>
                                </SHBox>
                            ),
                        },
                        {
                            title: "Last updated",
                            field: "lastUpdatedDate",
                            render: (rowData: NonConformanceListModel) => (
                                <SHBox>
                                    <SHBox>
                                        {rowData.lastUpdatedDate !== "" ? formatDate(rowData.lastUpdatedDate) : "-"} @
                                        {rowData.lastUpdatedDate !== "" ? formatTime(rowData.lastUpdatedDate) : "-"}
                                    </SHBox>
                                    <SHBox>{rowData.lastUpdatedByUserName ? `by ${rowData.lastUpdatedByUserName} ` : ""}</SHBox>
                                </SHBox>
                            ),
                        },
                    ]}
                    components={{
                        Pagination: PatchedPagination,
                    }}
                    options={Defaults.GetDarwinTableOptions()}
                    data={listViewModel.nonConformanceListItems}
                    onRowClick={(e, rowData) => {
                        listViewModel.handleRowClick(e, rowData!);
                    }}
                    title=""
                />
            </DarwinTablePageContent>
        </SHBox>
    );

    return <Wrapper>{renderListView}</Wrapper>;
});
