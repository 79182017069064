import { ViewModelBase } from "@shoothill/core";
import { action, computed } from "mobx";
import { IEAdministrationRoleUserModel } from "./IEAdministrationRoleUserModel";

export class IEAdministrationRoleUserViewModel extends ViewModelBase<IEAdministrationRoleUserModel> {
    // #region Constructors and Disposers

    constructor(ieAdministrationRoleUserModel: IEAdministrationRoleUserModel) {
        super(ieAdministrationRoleUserModel);
        this.setDecorators(IEAdministrationRoleUserModel);
    }

    // #region Constructors and Disposers

    // #region Item

    // @action
    // public addItem = (model: OrderLineModel) => {
    //     this.model.categoryId = model.categoryId!;
    //     this.model.categoryDisplayName = model.categoryDisplayName;
    //     this.model.subCategoryId = model.subCategoryId!;
    //     this.model.subCategoryDisplayName = model.subCategoryDisplayName;
    //     this.model.lineDescriptionId = model.lineDescriptionId!;
    //     this.model.lineDescriptionDisplayName = model.lineDescriptionDisplayName;

    //     //this.model.requisitionRequestItems.push(RequisitionRequestItemModel.fromOrderLineModel(this.model.id, model));
    // };

    @computed
    public get roleDisplayName() {
        return this.model.roleName;
    }

    @computed
    public get userDisplayName() {
        return this.model.userName;
    }

    // #endregion Item

    // #region Helpers
    @action
    public setIsDeleted = (val: boolean) => {
        this.model.isDeleted = val;
    };

    // #endregion Helpers

    // #region Boilerplate

    public async isFieldValid(fieldName: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
