import { Button } from "@material-ui/core";
import { isNullOrUndefined } from "@shoothill/core";
import MaterialTable from "material-table";
import { observer } from "mobx-react-lite";
import React from "react";

import { SHBox } from "Components/Box";
import { BaseModal } from "Components/Modal/BaseModal";
import { SnackBar } from "Components/SnackBar/SnackBar";
import * as Defaults from "Globals/Defaults/TableOptions";
import { pxToRem } from "Globals/Styles/AppTheme";
import { DarwinTablePageContent } from "Globals/Styles/AppStyling";
import { formatDate, formatTime, formatCurrencyFromPounds, formatIntegerNumber } from "Utils/Format";
import { ReportModalStyle } from "Views/Project/Modals/Modal.Styles";
import { DetailPanel, DummyEntryLink } from "./StockTransferHistoryView.styles";
import { StockTransferHistoryItemViewModel } from "./StockTransferHistoryItemViewModel";
import { StockTransferHistoryViewModel } from "./StockTransferHistoryViewModel";

interface IProps {
    viewModel: StockTransferHistoryViewModel;
}

export const StockTransferHistoryView: React.FC<IProps> = observer((props) => {
    const viewModel = props.viewModel;
    const tableOptions = Defaults.GetDarwinTablStockHistory() as any;

    // #region React Kludges

    // Unlike other modals, this is not displayed via the MVVM pattern and uses
    // React state instead. So we have to check the viewModel state and stop
    // event bubbling otherwise the modal will not close when the close button
    // is clicked.
    const handleClose = (event?: any) => {
        viewModel?.close();
        event?.stopPropagation();
    };

    // This prevents the modal from closing when the clicks within the body of
    // the modal.
    const hanleModalClick = (event?: any) => {
        event?.stopPropagation();
    };

    const canDisplaySnackBar = !isNullOrUndefined(viewModel);

    // #endregion React Kludges

    return (
        <BaseModal
            open={viewModel !== null}
            onClose={handleClose}
            onClick={hanleModalClick}
            title="Stock transfer history"
            PaperComponent={ReportModalStyle}
            actions={
                <React.Fragment>
                    <Button onClick={handleClose} color="secondary" autoFocus variant="contained">
                        Close
                    </Button>
                </React.Fragment>
            }
        >
            <DarwinTablePageContent style={{ padding: "0 0 20px 0" }}>
                <MaterialTable
                    columns={[
                        {
                            title: "Changed on/by",
                            field: "updatedDate",
                            width: "auto",
                            render: (rowData: StockTransferHistoryItemViewModel) => (
                                <SHBox>
                                    <SHBox>{`${formatDate(rowData.updatedDate)} @ ${formatTime(rowData.updatedDate)} by`}</SHBox>
                                    <SHBox>{rowData.updatedByUser}</SHBox>
                                </SHBox>
                            ),
                            cellStyle: {
                                ...tableOptions.cellStyle,
                                fontSize: pxToRem(10),
                                letterSpacing: pxToRem(-0.2),
                            },
                        },
                        {
                            title: "Entry type",
                            field: "stockTransactionType",
                            width: "auto",
                            sorting: false,
                            render: (rowData: StockTransferHistoryItemViewModel) => (
                                <SHBox>
                                    <SHBox>{rowData.stockTransactionType}</SHBox>
                                    {rowData.canDisplayStockTransactionRequestNumber && <DummyEntryLink>{rowData.stockTransactionRequestNumber}</DummyEntryLink>}
                                </SHBox>
                            ),
                        },
                        {
                            title: "Movement",
                            field: "unitChange",
                            align: "right",
                            width: "auto",
                            sorting: false,
                            render: (rowData: StockTransferHistoryItemViewModel) => formatIntegerNumber(rowData.unitChange),
                            cellStyle: (all: StockTransferHistoryItemViewModel[], rowData: StockTransferHistoryItemViewModel) => {
                                return {
                                    ...tableOptions.cellStyle,
                                    fontWeight: "600",
                                    backgroundColor: rowData.unitChange < 0 ? "#ECB4B4" : "#CAEAD4",
                                };
                            },
                        },
                        {
                            title: "Stock balance",
                            field: "stockQuantity",
                            align: "right",
                            width: "auto",
                            sorting: false,
                            render: (rowData: StockTransferHistoryItemViewModel) => formatIntegerNumber(rowData.stockQuantity),
                        },
                        {
                            title: "Stock value",
                            field: "stockValue",
                            align: "right",
                            width: "auto",
                            sorting: false,
                            render: (rowData: StockTransferHistoryItemViewModel) => formatCurrencyFromPounds(rowData.stockValue),
                            cellStyle: (all: StockTransferHistoryItemViewModel[], rowData: StockTransferHistoryItemViewModel) => {
                                return {
                                    ...tableOptions.cellStyle,
                                    borderRight: "none",
                                    fontWeight: "600",
                                    backgroundColor: rowData.unitChange < 0 ? "#ECB4B4" : "#CAEAD4",
                                };
                            },
                        },
                    ]}
                    data={viewModel?.filteredStockHistoryItems ?? []}
                    options={{ ...tableOptions }}
                    title=""
                    detailPanel={(rowData: StockTransferHistoryItemViewModel) => {
                        return (
                            <DetailPanel>
                                <div>Notes</div>
                                <div>{rowData.note}</div>
                            </DetailPanel>
                        );
                    }}
                />
            </DarwinTablePageContent>
            {canDisplaySnackBar && (
                <SnackBar
                    messageText={viewModel.snackMessage}
                    messageType={viewModel.snackType}
                    active={viewModel.snackbarState}
                    closeOption={() => viewModel.setSnackbarState(false)}
                    autoHideDuration={5000}
                />
            )}
        </BaseModal>
    );
});
