import { FieldType, KeyValuePair, observable, ViewModelBase } from "@shoothill/core";
import { action, computed } from "mobx";
import { RDDListFilterParamsModel } from "./RDDListFilterParamsModel";

export class RDDFilterViewModel extends ViewModelBase<RDDListFilterParamsModel> {
    private static _intsance: RDDFilterViewModel;
    public static get Instance() {
        return this._intsance || (this._intsance = new this());
    }

    constructor() {
        super(new RDDListFilterParamsModel(), false);
        this.setDecorators(RDDListFilterParamsModel);
    }

    public async isFieldValid(fieldName: keyof FieldType<RDDListFilterParamsModel>): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
