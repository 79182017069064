import React, { useEffect } from "react";
import { useObserver } from "@shoothill/core";
import { SupplierListViewModel } from "./SupplierListViewModel";
import { SupplierListFilterViewModel } from "./SupplierListFilterViewModel";
import { FilterRow, FilterWrapper } from "./SupplierList.styles";
import { MultiSelectView } from "Components/Select/MultiSelect";
import { PrimaryButton } from "Components/Buttons/Buttons";

interface ISupplierListFilterViewProps {
    supplierListViewModel: SupplierListViewModel;
    filtersViewModel: SupplierListFilterViewModel;
}

export const SupplierListFilterView: React.FunctionComponent<ISupplierListFilterViewProps> = (props: ISupplierListFilterViewProps) => {
    //useEffect below only gets run on initial render
    useEffect(() => {
        return () => {};
    }, []);

    const renderPage = () => {
        return (
            <>
                <FilterWrapper style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                    <FilterRow>
                        <MultiSelectView
                            style={{ maxWidth: "200px", marginRight: "20px" }}
                            display="Status"
                            fieldName="statuses"
                            values={props.filtersViewModel.model.statuses}
                            getOptions={props.filtersViewModel.getStatusOptions}
                            handleChange={props.supplierListViewModel.handleStatusChange}
                        />
                    </FilterRow>
                    <div className="filter-button-container">
                        <PrimaryButton displayName={"Clear"} execute={props.supplierListViewModel.clear} fullWidth={false} canExecute={!props.supplierListViewModel.IsLoading} />
                    </div>
                </FilterWrapper>
            </>
        );
    };

    return useObserver(() => renderPage());
};
