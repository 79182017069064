import { ModelBase, isNullOrEmpty } from "@shoothill/core";
import { IsNotEmpty, ValidateIf } from "class-validator";
import { observable, runInAction } from "mobx";
import { AuditDocumentModel } from "../AuditDocument";

export class FireExitModel extends ModelBase<FireExitModel> {
    public id: string | null = null;
    @observable
    @IsNotEmpty({ message: "Please select yes or no" })
    public signageOverEntExit: string = "";
    @observable
    @ValidateIf((o) => o.signageOverEntExit === "no")
    @IsNotEmpty({ message: "Please enter more detail" })
    public signageOverEntExitDetail: string = "";
    @observable
    @IsNotEmpty({ message: "Please select yes or no" })
    public doorsWorking: string = "";
    @observable
    @ValidateIf((o) => o.doorsWorking === "no")
    @IsNotEmpty({ message: "Please enter more detail" })
    public doorsWorkingDetail: string = "";
    @observable
    @IsNotEmpty({ message: "Please select yes or no" })
    public entExitFreeFromObstruction: string = "";
    @observable
    @ValidateIf((o) => o.entExitFreeFromObstruction === "no")
    @IsNotEmpty({ message: "Please enter more detail" })
    public entExitFreeFromObstructionDetail: string = "";
    @observable
    @IsNotEmpty({ message: "Please select yes or no" })
    public escapeRoutes: string = "";
    @observable
    @ValidateIf((o) => o.escapeRoutes === "no")
    @IsNotEmpty({ message: "Please enter more detail" })
    public escapeRoutesDetail: string = "";
    @observable
    @IsNotEmpty({ message: "Please select yes or no" })
    public adequateLighting: string = "";
    @observable
    @ValidateIf((o) => o.adequateLighting === "no")
    @IsNotEmpty({ message: "Please enter more detail" })
    public adequateLightingDetail: string = "";
    @observable
    @IsNotEmpty({ message: "Please enter a location" })
    public location: string | null = null; // nvarchar(max)
    @observable
    public auditId: string | null = null; // uniqueidentifier
    @observable
    public auditDocuments: AuditDocumentModel[] = [];
    constructor() {
        super();
    }

    fromDto(model: any): void {
        this.id = model.id;
        this.signageOverEntExit = model.signageOverEntExit ? "yes" : "no";
        this.doorsWorking = model.doorsWorking ? "yes" : "no";
        this.entExitFreeFromObstruction = model.entExitFreeFromObstruction ? "yes" : "no";
        this.escapeRoutes = model.escapeRoutes ? "yes" : "no";
        this.adequateLighting = model.adequateLighting ? "yes" : "no";
        this.location = model.location;
        this.auditId = model.auditId;
        if (model.auditDocuments) {
            this.auditDocuments = model.auditDocuments.map((x: any) => {
                const doc = new AuditDocumentModel();
                doc.fromDto(x);
                return doc;
            });
        }
        this.signageOverEntExitDetail = model.signageOverEntExitDetail;
        this.doorsWorkingDetail = model.doorsWorkingDetail;
        this.entExitFreeFromObstructionDetail = model.entExitFreeFromObstructionDetail;
        this.escapeRoutesDetail = model.escapeRoutesDetail;
        this.adequateLightingDetail = model.adequateLightingDetail;
    }

    toDto() {
        return {
            id: this.id,
            signageOverEntExit: this.signageOverEntExit === "yes" ? true : false,
            doorsWorking: this.doorsWorking === "yes" ? true : false,
            entExitFreeFromObstruction: this.entExitFreeFromObstruction === "yes" ? true : false,
            escapeRoutes: this.escapeRoutes === "yes" ? true : false,
            adequateLighting: this.adequateLighting === "yes" ? true : false,
            location: this.location,
            auditId: this.auditId,
            auditDocuments: this.auditDocuments,
            signageOverEntExitDetail: this.signageOverEntExitDetail,
            doorsWorkingDetail: this.doorsWorkingDetail,
            entExitFreeFromObstructionDetail: this.entExitFreeFromObstructionDetail,
            escapeRoutesDetail: this.escapeRoutesDetail,
            adequateLightingDetail: this.adequateLightingDetail,
        };
    }
}
