import * as MobX from "mobx";
import { ModelBase } from "@shoothill/core";
import { IsArray, IsNotEmpty, Min } from "class-validator";

import { ProjectContactDTO } from "Views/Project/ProjectContactModel";
import { AddressModelDTO, AddressModel, NoteModelDTO, NoteModel } from "Globals/Models/Domain";
import { ClientNameAndId, ClientNameAndIdDTO } from "Globals/Models/ClientNameAndId";
import { ContractTypeModel, ContractTypeModelDTO } from "Globals/Models/ContractTypeModel";
import { action } from "mobx";
import { ProjectConstructionModelDTO } from "Views/Project/Construction/ProjectConstruction";
import { ProjectContractualModelDTO } from "Views/Project/Contractual/ProjectContractual";
import { ProjectStatusTypeDTO } from "../ProjectStatusTypeModel";
import { ProjectTypeDTO } from "../ProjectTypeModel";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class GeneralModel extends ModelBase<GeneralModel, GeneralModelDTO> {
    // #region Defaults and Constants

    public static readonly DEFAULT_PROJECTSTATUSTYPEID = null;
    public static readonly DEFAULT_PROJECTTYPEID = null;

    // #endregion Defaults and Constants

    public id: string = "";

    @MobX.observable
    @IsNotEmpty({ message: "Project name required" })
    public name: string = "";

    @MobX.observable
    @IsNotEmpty({ message: "Project reference required" })
    public reference: string = "";

    @MobX.observable
    public description: string = "";

    @MobX.observable
    //@Min(1, { message: "Project cell required" })
    public cell: number = -99;

    @MobX.observable
    public central: boolean = false;

    @MobX.observable
    @IsNotEmpty({ message: "Contract value is required" })
    public contractLoiValue: number = 0;

    @MobX.observable
    @IsNotEmpty({ message: "Project status required" })
    public projectStatusTypeId: string | null = GeneralModel.DEFAULT_PROJECTSTATUSTYPEID;

    @MobX.observable
    @IsNotEmpty({ message: "Project type required" })
    public projectTypeId: string | null = GeneralModel.DEFAULT_PROJECTTYPEID;

    public isDeleted: boolean = false;
    public originatorId: string | null = null;
    public rowVersion: string | null = null;

    @MobX.observable
    public isDraft: boolean = false;

    @MobX.observable
    @IsArray()
    //public addresses: MobX.IObservableArray<AddressModelDTO> = MobX.observable([]);
    public addresses: AddressModel[] = [];

    @MobX.observable
    public contacts: MobX.IObservableArray<ProjectContactDTO> = MobX.observable([]);

    @MobX.observable
    public notes: NoteModel[] = [];
    //public notes: MobX.IObservableArray<NoteModelDTO> = MobX.observable([]);

    @MobX.observable
    public clientList: ClientNameAndId[] = [];

    @MobX.observable
    public contractTypes: ContractTypeModel[] = [];

    @MobX.observable
    public projectConstruction: ProjectConstructionModelDTO | null = null;

    @MobX.observable
    public projectContractual: ProjectContractualModelDTO | null = null;

    @MobX.computed public get getProjectConstruction() {
        return this.projectConstruction;
    }

    @MobX.computed public get getProjectConstractual() {
        return this.projectContractual;
    }

    @action
    public clearModel() {
        this.id = "";
        this.name = "";
        this.reference = "";
        this.description = "";
        this.originatorId = null;
        this.cell = -99;
        this.central = false;
        this.contractLoiValue = 0;
        this.isDraft = false;
        this.isDeleted = false;
        this.rowVersion = null;
        this.addresses = [];
        this.contacts.clear();
        this.notes = [];
        this.clientList = [];
        this.contractTypes = [];
        this.projectConstruction = null;
        this.projectContractual = null;
        this.projectStatusTypeId = GeneralModel.DEFAULT_PROJECTSTATUSTYPEID;
        this.projectTypeId = GeneralModel.DEFAULT_PROJECTTYPEID;
    }

    //fromDto is required but you can leave it blank
    fromDto(model: GeneralModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: GeneralModel): void {}
}

export type GeneralModelDTO = {
    id: string;
    name: string;
    reference: string;
    description: string;
    /* clientId: string | null; */
    originatorId: string | null;
    cell: number;
    central: boolean;
    contractLoiValue: number;
    isDeleted: boolean;
    isDraft: boolean;
    addresses: AddressModelDTO[];
    contacts: ProjectContactDTO[];
    notes: NoteModelDTO[];
    clientList: ClientNameAndIdDTO[];
    contractTypes: ContractTypeModelDTO[];

    projectConstruction: ProjectConstructionModelDTO;
    projectContractual: ProjectContractualModelDTO;

    projectStatusTypes: ProjectStatusTypeDTO[];
    projectTypes: ProjectTypeDTO[];

    projectStatusTypeId: string | null;
    projectTypeId: string | null;

    overheadPercentage: number | null;
};
