import { ClientListItem } from "./ClientListItem";
import * as MobX from "mobx";
import { ModelBase } from "@shoothill/core";
import { IsNotEmpty } from "class-validator";
import moment from "moment";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class ClientListModel extends ModelBase<ClientListModel, ClientListItem> {
    public id: string = "";
    @MobX.observable createdDate: string = "";
    @MobX.observable reference: string = "";
    @MobX.observable name: string = "";
    @MobX.observable contactName: string = "";
    @MobX.observable contactEmail: string = "";
    @MobX.observable contactPhone: string = "";

    //fromDto is required but you can leave it blank
    fromDto(model: ClientListItem): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: ClientListModel): void {}

    public toTableModel = (): ClientListItemDTO => {
        const client: ClientListItemDTO = {
            id: this.id,
            clientRef: this.reference,
            name: this.name,
            projectContact: this.contactName,
            contactEmail: this.contactEmail,
            contactPhone: this.contactPhone,
            createdDate: moment(this.createdDate).format("DD/MM/YY").toString(),
        };

        return client;
    };
}

export interface ClientListItemDTO {
    id: string;
    clientRef: string;
    name: string;
    projectContact: string;
    contactEmail: string;
    contactPhone: string;
    createdDate: string;
}
