import React, { useContext } from "react";
import { Stores, StoresContext } from "Globals/Stores";
import { PrivateRoute } from "Globals/Views/PrivateRoute";
import * as RouterUtil from "Utils/Routing";
import { StoresInstance } from "Globals/Stores";
import { AppUrls } from "../../AppUrls";
import { VariationView } from "./Form/VariationView";
import { DarwinLayout } from "Globals/Views/Layouts/DarwinLayout";
import { RoleTypeEnum } from "../../Globals/Stores/Domain/Admin";
import { VariationTypeEnum } from "./Form/VariationModel";

export const VariationRoutes: React.FC = () => {
    function getRootLayout() {
        return DarwinLayout;
    }
    const store = useContext<Stores>(StoresContext);
    return (
        <>
            <PrivateRoute
                exact
                path={[AppUrls.Client.Variation.Add, AppUrls.Client.Variation.AddIE, AppUrls.Client.Variation.Edit, AppUrls.Client.Variation.View]}
                component={() => <VariationView variationType={VariationTypeEnum.Variation} />}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireNotSiteManagerLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            {/* <PrivateRoute
                exact
                path={AppUrls.Client.Variation.AddIE}
                component={() => <VariationView variationType={VariationTypeEnum.Variation} />}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireNotSiteManagerLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Variation.Edit}
                component={() => <VariationView variationType={VariationTypeEnum.Variation} />}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireNotSiteManagerLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />

            <PrivateRoute
                exact
                path={AppUrls.Client.Variation.View}
                component={() => <VariationView variationType={VariationTypeEnum.Variation} />}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireNotSiteManagerLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            /> */}

            <PrivateRoute
                exact
                path={[
                    AppUrls.Client.ClientProvisionalSum.Add,
                    AppUrls.Client.ClientProvisionalSum.AddIE,
                    AppUrls.Client.ClientProvisionalSum.Edit,
                    AppUrls.Client.ClientProvisionalSum.View,
                ]}
                component={() => <VariationView variationType={VariationTypeEnum.ClientProvisionalSum} />}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireNotSiteManagerLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            {/* <PrivateRoute
                exact
                path={AppUrls.Client.ClientProvisionalSum.AddIE}
                component={() => <VariationView variationType={VariationTypeEnum.ClientProvisionalSum} />}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireNotSiteManagerLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.ClientProvisionalSum.Edit}
                component={() => <VariationView variationType={VariationTypeEnum.ClientProvisionalSum} />}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireNotSiteManagerLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />

            <PrivateRoute
                exact
                path={AppUrls.Client.ClientProvisionalSum.View}
                component={() => <VariationView variationType={VariationTypeEnum.ClientProvisionalSum} />}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireNotSiteManagerLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            /> */}
        </>
    );
};
