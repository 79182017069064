import { ApiResult, FieldType, ViewModelBase } from "@shoothill/core";
import { action, computed, observable, runInAction } from "mobx";
import type { IObservableArray } from "mobx";
import { GenericIncludeDeleted } from "Globals/Models";
import { AppUrls } from "AppUrls";
import { PackageCategoriesResult } from "Views/PurchaseOrder/PackageCategoriesResult";
import { RequisitionsListFilterViewModel } from "./RequisitionsListFilterViewModel";
import debounce from "lodash/debounce";
import { OrderType, ProjectDTO, RequisitionPOStatus, UserDTO } from "Views/Approval/PurchaseListViewModel";
import { RequisitionsModel, RequisitionsModelDTO, RequisitionsTableModel } from "../Commercial/OrdersAndRequisitions/Requisitions.Model";

export class RequisitionsListViewModel extends ViewModelBase<RequisitionsModel> {
    @observable public errorMessage: string = "";

    @observable
    public requisitions: IObservableArray<RequisitionsModel> = observable<RequisitionsModel>([]);

    @computed
    public get getRequisitionList(): RequisitionsTableModel[] {
        // return this.requisitions.slice().map((po) => {
        //     return po.toTableModel();
        // });
        return this.requisitions.map((po: RequisitionsModel) => {
            return po.toTableModel2();
        });
    }

    constructor() {
        super(new RequisitionsModel(), false);
        this.setDecorators(RequisitionsModel);
    }

    public handleCategoryChange = (val: number[] | null) => {
        RequisitionsListFilterViewModel.Instance.setValue("categories", val);
        RequisitionsListFilterViewModel.Instance.setValue("subCategories", []);
        this.GetAllRequisitionsListAsync();
    };

    public handleSubCategoryChange = (val: number[] | null) => {
        RequisitionsListFilterViewModel.Instance.setValue("subCategories", val);
        this.GetAllRequisitionsListAsync();
    };

    public handleSearchChange = (val: string) => {
        RequisitionsListFilterViewModel.Instance.setValue("searchText", val);

        if (val.length > 3 || val.length === 0) {
            this.reloadDataDelayed();
        }
    };

    public handleStatusChange = (val: number[] | null) => {
        RequisitionsListFilterViewModel.Instance.setValue("statuses", val);
        this.GetAllRequisitionsListAsync();
    };

    public handleCellChange = (val: number[] | null) => {
        RequisitionsListFilterViewModel.Instance.setValue("cells", val);
        this.GetAllRequisitionsListAsync();
    };

    public handleOrderTypeChange = (val: number[] | null) => {
        RequisitionsListFilterViewModel.Instance.setValue("orderTypes", val);
        this.GetAllRequisitionsListAsync();
    };

    public handleRaisedByChange = (val: number[] | null) => {
        RequisitionsListFilterViewModel.Instance.setValue("raisedByIds", val);
        this.GetAllRequisitionsListAsync();
    };

    public handleProjectChange = (val: number[] | null) => {
        RequisitionsListFilterViewModel.Instance.setValue("projects", val);
        this.GetAllRequisitionsListAsync();
    };

    private reloadDataDelayed = debounce(() => {
        this.GetAllRequisitionsListAsync();
    }, 300);

    @action
    public cleanUp = () => {
        this.reloadDataDelayed.cancel();
    };

    @action
    public clear = () => {
        RequisitionsListFilterViewModel.Instance.model.reset();
        this.GetAllRequisitionsListAsync();
    };

    @action
    public setRequisitions(requisitions: RequisitionsModelDTO[]) {
        this.requisitions.clear();
        requisitions?.forEach((item, index) => {
            let domainModel = this.requisitions.find((c) => c.id === item.id);

            if (!domainModel) {
                domainModel = new RequisitionsModel();
                domainModel.fromDto(item);
                this.requisitions.push(domainModel);
            }
        });
    }

    @action
    public async loadPurchaseOrderListAsync(): Promise<ApiResult<RequisitionsModelDTO[]>> {
        const request: GenericIncludeDeleted = {
            includeDeleted: true,
        };

        let apiResult = await this.Post<RequisitionsModelDTO[]>(AppUrls.Server.PurchaseOrder.GetAll, request);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                this.setRequisitions(apiResult.payload);
            });
        }
        return apiResult;
    }

    @action
    public GetAllRequisitionsListAsync = async (): Promise<ApiResult<RequisitionsListAndRelatedDTO>> => {
        let paramsViewModel = RequisitionsListFilterViewModel.Instance;
        let params = paramsViewModel.model.toDto();
        const apiResult = await this.Post<RequisitionsListAndRelatedDTO>(AppUrls.Server.PurchaseOrder.GetAllRequisitionList, params);
        if (apiResult.wasSuccessful) {
            this.setRequisitions(apiResult.payload.requisitionsOrders);
            RequisitionsListFilterViewModel.Instance.setCategories(apiResult.payload.packageCategories, true);
        }
        return apiResult;
    };

    public async isFieldValid(fieldName: keyof FieldType<RequisitionsModel>): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}

export type RequisitionsListAndRelatedDTO = {
    requisitionsOrders: RequisitionsModelDTO[];
    packageCategories: PackageCategoriesResult;
    statuses: RequisitionPOStatus[];
    orderTypes: OrderType[];
    purchaseUsers: UserDTO[];
    projects: ProjectDTO[];
};
