import React, { useContext } from "react";
import { Stores, StoresContext } from "Globals/Stores";
import { PrivateRoute } from "Globals/Views/PrivateRoute";
import * as RouterUtil from "Utils/Routing";
import { StoresInstance } from "Globals/Stores";
import { AppUrls } from "../../../AppUrls";
import { DarwinLayout } from "Globals/Views/Layouts/DarwinLayout";
import { InductionListView } from "./List/InductionListView";
import { InductionAdminView } from "./Form/InductionAdminView";

export const InductionAdminRoutes: React.FC = () => {
    function getRootLayout() {
        return DarwinLayout;
    }
    const store = useContext<Stores>(StoresContext);
    return (
        <>
            <PrivateRoute
                exact
                path={AppUrls.Client.Induction.List}
                component={InductionListView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.canViewInductions(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Induction.View}
                component={InductionAdminView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.canViewInductions(StoresInstance);
                }}
                layout={getRootLayout()}
            />
        </>
    );
};
