import { Box, Grid, withStyles } from "@material-ui/core";
import styled from "styled-components";

export const OverviewContainer: any = styled.div`
    padding: 0px 30px;
    > div {
        width: 40%;
        display: flex;
        flex-direction: row;
    }

    font-size: 12px;
    font-weight: normal;
    span {
        display: inline;
        width: 30%;
        font-weight: bold;
    }
`;

export const StyledListBase: any = styled.ul`
    list-style: unset;
`;

export const AddressList: any = styled(StyledListBase)`
    padding: 10px 0;
    border-bottom: solid 1px #d4d4d4;
`;

export const AddressItem: any = styled.li`
    position: relative;
    list-style-type: none;
    background-color: #eaf4f9;
    padding: 5px;
    margin-bottom: 10px;
`;

export const NotesList: any = styled.div`
    padding: 10px 0;
    border-top: solid 1px #d4d4d4;
    li {
        border-bottom: solid 1px #d4d4d4;
    }
    margin-bottom: 5px;
`;

export const ProjectEditBox: any = styled(Box)`
    .row,
    .column {
        display: flex;
        width: 100%;
        margin-bottom: 30px;
    }

    .row {
        flex-direction: row;
    }

    .column {
        flex-direction: column;
    }

    .block {
        // TODO
    }

    .row > div {
        max-width: 250px;
        width: 100%;
        padding: 0px;
        margin: 0px 15px;
        &:first-of-type {
            margin: 0px 15px 0px 30px;
        }

        &:last-of-type {
            button {
                margin-top: 23px;
            }
        }

        .MuiBox-root {
            width: 100%;
        }

        label > div {
            max-width: 287px;
        }
    }

    .row > div.double {
        max-width: 100%;
        .MuiBox-root {
            max-width: 100% !important;
            label {
                .control-label {
                    font-size: 12px !important;
                    line-height: 30px !important;
                    color: #000 !important;
                    font-weight: 700 !important;
                    font-family: Open Sans;
                    transform: inherit;
                }
                p {
                    font-size: 12px;
                    line-height: 30px;
                    color: #000;
                    font-family: "Open Sans", sans-serif;
                }
            }
        }
    }

    .row > div.description {
        width: 100%;
        max-width: 1124px;
        /* background-color: green; */

        .textarea {
            width: 100%;
            max-width: 1124px;
            /* background-color: pink; */
        }
    }

    @media screen and (max-width: 1024px) {
    }

    @media screen and (max-width: 640px) {
        flex-direction: column;
    }
`;

const EditBox: any = styled(Box)`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 30px;
    padding: 0px 30px;
`;

export const ProjectAddressEditBox: any = styled(EditBox)``;

export const ProjectNoteEditBox: any = styled(EditBox)`
    margin-top: 15px;
`;

export const ProjectButtonContainer: any = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
    gap: 15px;

    .MuiButtonBase-root {
        max-width: 262px;
        height: 30px;
        width: 100%;
        padding: 20px;
    }
`;
