// Libs
import { FieldType, isNullOrUndefined, KeyValuePair, ViewModelBase } from "@shoothill/core";
import { action, computed, observable, runInAction } from "mobx";

// App
import { ContractTypeModel, ContractTypeModelDTO } from "Globals/Models";

export class ContractTypeViewModel extends ViewModelBase<ContractTypeModel> {
    private static _instance: ContractTypeViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable public errorMessage: string = "";

    @observable
    public hasLoaded = true;

    @action
    public setHasLoaded(val: boolean) {
        this.hasLoaded = val;
    }

    constructor() {
        super(new ContractTypeModel(), false);
        this.setDecorators(ContractTypeModel);
    }

    @observable
    private contractNameAndIdArr: ContractTypeModelDTO[] = [];

    @computed
    public get returnContractArr() {
        return this.contractNameAndIdArr.map((c) => {
            return { id: c.id, name: c.name };
        });
    }

    @computed public get getContractTypes() {
        return this.contractNameAndIdArr
            .filter((f) => f.isDeleted === false)
            .map((c) => {
                const retVal: KeyValuePair = {
                    key: c.name,
                    value: c.id,
                };
                return retVal;
            });
    }

    @computed
    public get returnActiveTypes() {
        return this.contractNameAndIdArr
            .filter((f) => f.isDeleted === false)
            .map((c) => {
                return { id: c.id, name: c.name };
            });
    }

    @action
    public chosenContractType(val: string) {
        let _val = !isNullOrUndefined(val) ? val : "";
        let _client = this.contractNameAndIdArr.find((c) => c.id === _val);
        return !isNullOrUndefined(_client) ? _client : this.contractNameAndIdArr[0];
    }

    @action
    public populateContractNameAndId(payload: ContractTypeModelDTO[]) {
        this.contractNameAndIdArr = [];
        payload.forEach((c) => {
            this.contractNameAndIdArr.push(c);
        });
    }

    public async isFieldValid(fieldName: keyof FieldType<ContractTypeModel>): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
