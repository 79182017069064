import { action, observable, computed } from "mobx";
import { ModelBase } from "@shoothill/core";
import { validateTwoDecimalPlaces } from "Utils/Utils";

export class VariationItemModel extends ModelBase<VariationItemModel, VariationItemDTO> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Constants and Defaults

    public static readonly DEFAULT_ID = null;
    public static readonly DEFAULT_CREATEDBYUSERID = null;
    public static readonly DEFAULT_CREATEDDATE = null;
    public static readonly DEFAULT_DESCRIPTIONID = null;
    public static readonly DEFAULT_DISPLAYNAME = null;
    public static readonly DEFAULT_VARIATIONSUBCATEGORYID = null;
    public static readonly DEFAULT_DESCRIPTIONOTHER = null;
    public static readonly DEFAULT_FUTURESPEND = null;
    public static readonly DEFAULT_QUANTITY = null;
    public static readonly DEFAULT_RATE = null;
    public static readonly DEFAULT_ROWVERSION = null;
    public static readonly DEFAULT_VARIATIONUNITTYPEID = null;
    public static readonly DEFAULT_VARIATIONUNITNAME = null;
    public static readonly DEFAULT_ISDELETED = false;
    public static readonly DEFAULT_COMMITTEDCOST = null;
    public static readonly DEFAULT_CANDELETEITEM = false;
    public static readonly DEFAULT_ISDEFAULTITEM = false;

    // #endregion Constants and Defaults

    // #region Properties

    @observable
    public id: string | null = VariationItemModel.DEFAULT_ID;

    @observable
    public createdByUserId: string | null = VariationItemModel.DEFAULT_CREATEDBYUSERID;

    @observable
    public createdDate: string | null = VariationItemModel.DEFAULT_CREATEDDATE;

    @observable
    public variationSubCategoryId: string | null = VariationItemModel.DEFAULT_VARIATIONSUBCATEGORYID;

    @observable
    public descriptionId: number | null = VariationItemModel.DEFAULT_DESCRIPTIONID;

    @observable
    public descriptionOther: string | null = VariationItemModel.DEFAULT_DESCRIPTIONOTHER;

    @observable
    public rate: number | null = VariationItemModel.DEFAULT_RATE;

    @observable
    public variationUnitName: string | null = VariationItemModel.DEFAULT_VARIATIONUNITNAME;

    @observable
    public quantity: number | null = VariationItemModel.DEFAULT_QUANTITY;

    @observable
    public futureSpend: number | null = VariationItemModel.DEFAULT_FUTURESPEND;

    @observable
    public rowVersion: string | null = VariationItemModel.DEFAULT_ROWVERSION;

    @observable
    public isDeleted: boolean = VariationItemModel.DEFAULT_ISDELETED;

    @observable
    public displayName: string | null = VariationItemModel.DEFAULT_DISPLAYNAME;

    @observable
    public committedCost: number | null = VariationItemModel.DEFAULT_COMMITTEDCOST;

    @observable
    public canDeleteItem: boolean = VariationItemModel.DEFAULT_CANDELETEITEM;

    @observable
    public isDefaultItem: boolean = VariationItemModel.DEFAULT_ISDEFAULTITEM;

    // #endregion Properties

    // #region Actions

    @action
    public reset = () => {
        this.id = VariationItemModel.DEFAULT_ID;
        this.descriptionId = VariationItemModel.DEFAULT_DESCRIPTIONID;
        this.displayName = VariationItemModel.DEFAULT_DISPLAYNAME;
        this.variationSubCategoryId = VariationItemModel.DEFAULT_VARIATIONSUBCATEGORYID;
        this.descriptionOther = VariationItemModel.DEFAULT_DESCRIPTIONOTHER;
        this.futureSpend = VariationItemModel.DEFAULT_FUTURESPEND;
        this.quantity = VariationItemModel.DEFAULT_QUANTITY;
        this.rate = VariationItemModel.DEFAULT_RATE;
        this.rowVersion = VariationItemModel.DEFAULT_ROWVERSION;
        this.variationUnitName = VariationItemModel.DEFAULT_VARIATIONUNITNAME;
        this.createdByUserId = VariationItemModel.DEFAULT_CREATEDBYUSERID;
        this.createdDate = VariationItemModel.DEFAULT_CREATEDDATE;
        this.isDeleted = VariationItemModel.DEFAULT_ISDELETED;
        this.canDeleteItem = VariationItemModel.DEFAULT_CANDELETEITEM;
        this.isDefaultItem = VariationItemModel.DEFAULT_ISDEFAULTITEM;
    };

    @action
    public fromDto(dto: VariationItemDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in dto) {
            if (dto.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(dto[key]);
                } else {
                    this[key] = dto[key];
                }
            }
        }
    }

    public toDto(): VariationItemDTO {
        return {
            id: this.id,
            createdDate: this.createdDate,
            createdByUserId: this.createdByUserId,
            variationSubCategoryId: this.variationSubCategoryId,
            descriptionId: this.descriptionId,
            descriptionOther: this.descriptionOther,
            rate: this.rate,
            variationUnitName: this.variationUnitName,
            quantity: this.quantity,
            futureSpend: this.futureSpend,
            rowVersion: this.rowVersion,
            displayName: this.displayName,
            isDeleted: this.isDeleted,
            committedCost: this.committedCost,
            canDeleteItem: this.canDeleteItem,
            isDefaultItem: this.isDefaultItem,
        };
    }

    // #endregion Actions

    // #region Custom Validation

    @computed
    public get validateRate(): string {
        // RULES
        if (this.rate === VariationItemModel.DEFAULT_RATE || isNaN(this.rate)) {
            return "Please enter a rate";
        } else if (!validateTwoDecimalPlaces(this.rate)) {
            return "No more than two decimal places";
        }

        return "";
    }

    @computed
    public get validateVariationUnitName(): string {
        // RULES
        // The variationUnitTypeName must be defined.
        return this.variationUnitName === VariationItemModel.DEFAULT_VARIATIONUNITNAME ? "Please enter a unit Name" : "";
    }

    @computed
    public get validateQuantity(): string {
        // RULES
        // The quantity must be defined.
        return this.quantity === VariationItemModel.DEFAULT_QUANTITY || isNaN(this.quantity) ? "Please enter a quantity" : "";
    }

    @computed
    public get validateFutureSpend(): string {
        // RULES
        if (this.futureSpend === VariationItemModel.DEFAULT_FUTURESPEND || isNaN(this.futureSpend)) {
            return "Please enter a future spend";
        } else if (!validateTwoDecimalPlaces(this.futureSpend)) {
            return "No more than two decimal places";
        }

        return "";
    }

    // #endregion Custom Validation
}

export interface VariationItemDTO {
    id: string | null;
    createdDate: string | null;
    createdByUserId: string | null;
    variationSubCategoryId: string | null;
    descriptionId: number | null;
    descriptionOther: string | null;
    rate: number | null;
    variationUnitName: string | null;
    quantity: number | null;
    futureSpend: number | null;
    rowVersion: string | null;
    isDeleted: boolean;
    displayName: string | null;
    committedCost: number | null;
    canDeleteItem: boolean;
    isDefaultItem: boolean;
}
