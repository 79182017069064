import { Link } from "@material-ui/core";
import { isNullOrUndefined, useRouter } from "@shoothill/core";
import { Link as RouterLink } from "react-router-dom";
import { AppUrls } from "AppUrls";
import { ClassNameMap } from "@material-ui/styles";
import { BreadcrumbViewModel } from "../BreadcrumbViewModel";
import { IEBreadCrumbSection } from "./IEBreadCrumbSection";

export const IEMatchSection1 = (
    ieId: string,
    viewModel: BreadcrumbViewModel,
    sections: IEBreadCrumbSection,
    classes: ClassNameMap<"root" | "common" | "prev" | "heading" | "main" | "secondary" | "lists" | "nested" | "parent" | "child">,
): any => {
    return (
        <div>
            {!isNullOrUndefined(ieId) && (
                <>
                    {!viewModel.isSiteManager && (
                        <>
                            <Link component={RouterLink} to={AppUrls.Client.Project.IE.replace(":ieid", ieId)} className={sections.ieDetails ? "" : classes.prev}>
                                {sections.ieDetails ? (
                                    <div className={classes.main}>
                                        Commercial
                                        <span className="triangle"></span>
                                    </div>
                                ) : (
                                    <>Commercial</>
                                )}
                            </Link>
                        </>
                    )}
                </>
            )}
        </div>
    );
};
