import { ModelBase } from "@shoothill/core";
import { action, observable } from "mobx";

export class SupplierModel extends ModelBase<SupplierModel, SupplierDTO> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Defaults and Constants

    public static readonly DEFAULT_ID = "";
    public static readonly DEFAULT_DISPLAYNAME = "";
    public static readonly DEFAULT_PAYMENTTERMSINDAYS = "";
    public static readonly DEFAULT_PAYMENTTERMSID = "";
    public static readonly DEFAULT_PAYMENTTERMSNAME = "";

    // #endregion Defaults and Constants

    // #region Observables

    @observable
    public id: string = SupplierModel.DEFAULT_ID;

    @observable
    public displayName: string = SupplierModel.DEFAULT_DISPLAYNAME;

    @observable
    public paymentTermsInDays: string = SupplierModel.DEFAULT_PAYMENTTERMSINDAYS;

    @observable
    public paymentTermsId: string = SupplierModel.DEFAULT_PAYMENTTERMSID;

    @observable
    public paymentTermsName: string = SupplierModel.DEFAULT_PAYMENTTERMSNAME;

    // #endregion Observables

    // #region Actions

    @action
    public reset(): void {
        this.id = SupplierModel.DEFAULT_ID;
        this.displayName = SupplierModel.DEFAULT_DISPLAYNAME;
        this.paymentTermsInDays = SupplierModel.DEFAULT_PAYMENTTERMSINDAYS;
        this.paymentTermsId = SupplierModel.DEFAULT_PAYMENTTERMSID;
        this.paymentTermsName = SupplierModel.DEFAULT_PAYMENTTERMSNAME;
    }

    @action
    public fromDto(dto: SupplierDTO): void {
        this.id = dto.id;
        this.displayName = dto.name;
        this.paymentTermsInDays = dto.paymentTermsInDays;
        this.paymentTermsId = dto.paymentTermsId;
        this.paymentTermsName = dto.paymentTermsName;
    }

    public toDto() {
        throw new Error("Method not implemented.");
    }

    // #endregion Actions

    // #region Helpers

    public static fromDtos(dtos: SupplierDTO[]): SupplierModel[] {
        const types: SupplierModel[] = [];

        for (const dto of dtos) {
            const model = new SupplierModel();

            model.fromDto(dto);
            types.push(model);
        }

        return types;
    }

    // #endregion Helpers
}

export interface SupplierDTO {
    id: string;
    name: string;
    paymentTermsInDays: string;
    paymentTermsId: string;
    paymentTermsName: string;
}
