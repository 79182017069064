// Libraries
import { FormControl, InputLabel } from "@material-ui/core";
import { isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import SignatureCanvas from "react-signature-canvas";
import { SHBox } from "../Box";

interface IProps {
    canExecute?: boolean;
    className?: string;
    displayName?: string;
    execute: (value: any) => void;
    fullWidth?: boolean;
    onBlur?: () => void;
    placeholder?: string;
    validationMessage?: string;
    value?: any;
    error?: boolean;
}

const SignatureBase: React.FC<IProps> = (props: IProps) => {
    // #region Code Behind
    const padRef = useRef<SignatureCanvas>(null);

    useEffect(() => {
        if (!isEmptyOrWhitespace(props.value)) {
            padRef.current?.fromDataURL(props.value);
        } else {
            padRef.current?.clear();
        }
    }, [props.value, props.canExecute]);

    const getId = (): string => {
        return "selectControlId";
    };

    const getPlaceholder = (): string => {
        return !isEmptyOrWhitespace(props.placeholder) ? props.placeholder! : "";
    };

    const getValidationMessage = (): string => {
        return !isEmptyOrWhitespace(props.validationMessage) ? props.validationMessage! : "";
    };

    const isDisabled = (): boolean => {
        return !isNullOrUndefined(props.canExecute) ? !props.canExecute : false;
    };

    const hasError = (): boolean => {
        return !isEmptyOrWhitespace(props.validationMessage) || props.error === true;
    };

    const handleOnEnd = (data: any): void => {
        props.execute(padRef.current!.getTrimmedCanvas().toDataURL("image/png"));
    };

    // #endregion Code Behind

    return useObserver(() => (
        <FormControl className={props.className} fullWidth={props.fullWidth}>
            {props.displayName && (
                <InputLabel disabled={isDisabled()} htmlFor={getId()} shrink>
                    {props.displayName}
                </InputLabel>
            )}
            <SHBox mt={"22px"}>
                {props.canExecute ? (
                    <SignatureCanvas
                        ref={padRef}
                        onEnd={(data) => handleOnEnd(data)}
                        penColor="black"
                        backgroundColor="white"
                        canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
                    />
                ) : (
                    <img src={props.value} />
                )}
            </SHBox>
        </FormControl>
    ));
};
SignatureBase.defaultProps = {
    canExecute: true,
};
export const Signature = styled(SignatureBase)``;
