import { Box, FormLabel, IconButton, TextField } from "@material-ui/core";
import { Cell, CoreStoreInstance, Grid, isNullOrEmpty, useRouter } from "@shoothill/core";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Form, FormSection } from "Views/PurchaseOrder/Form/Views/Forms";
import { FormHeader } from "Views/PurchaseOrder/Form/Views/FormHeaders";
import { DashedDivider, SolidDivider } from "Views/PurchaseOrder/Form/Views/Dividers";
import { formatIncidentNumber } from "Utils/Format";
import { pxToRem, theme } from "Globals/Styles/AppTheme";
import { BackButtonHolder } from "Views/Project/Commercial/IETables/IEGrid.Styles";
import { AppUrls } from "AppUrls";
import { CustomArrow } from "Views/Project/CustomComponents";
import { useLocation } from "react-router-dom";
import { DefaultButton, PrimaryButton } from "Components/Buttons/Buttons";
import { Alert, AlertTitle } from "@material-ui/lab";
import { DarwinSelect } from "Components/AutoComplete/DarwinSelect";
import { IncidentFormNearMissViewModel } from "./IncidentFormNearMissViewModel";
import { Uploader } from "Components/Uploader/Uploader";
import SystemUpdateTwoToneIcon from "@material-ui/icons/SystemUpdateTwoTone";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { GenericPhotoListItemModel } from "../Supporting/GenericPhotoListItemModel";
import { RootCauseChecks } from "./RootCauseChecks";
import { GenericFreeTextListItemModel } from "../Supporting/GenericFreeTextListItemModel";
import { AccountStore } from "../../../../../../Globals/Stores/Domain";
import { StoresInstance } from "../../../../../../Globals/Stores";

interface IProps {
    className?: string;
}

const IncidentFormNearMissViewBase: React.FC<IProps> = (props) => {
    const { match } = useRouter();
    const { incidentnearmissid, incidentid } = match.params as any;
    const [viewModel] = useState(() => new IncidentFormNearMissViewModel(incidentnearmissid, incidentid));
    const { history } = useRouter();
    const location = useLocation();
    const [currentRootCause, setCurrentRootCause] = useState<string>("");
    const accountStore = StoresInstance.Domain.AccountStore;

    useEffect(() => {
        return () => {
            viewModel.reset();
        };
    }, []);

    const COLUMNS8 = "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr";

    const onSubmit = (event: any) => {
        event.preventDefault();
    };

    return useObserver(() => {
        return (
            <Form className={props.className} onSubmit={onSubmit}>
                <BackButtonHolder
                    onClick={() => {
                        !accountStore.isSiteTablet
                            ? history.push(
                                  AppUrls.Client.Project.Incident.View.replace(":projectid", viewModel.incident!.projectId).replace(":incidentid", viewModel.incident!.id!),
                              )
                            : history.push(AppUrls.Client.Project.SiteTablet.Menu);
                    }}
                    style={{ minHeight: "30px", paddingTop: "3px" }}
                >
                    <div style={{ position: "relative", height: "30px", display: "flex", marginLeft: "10px" }}>
                        <div style={{ position: "absolute", top: "5px" }}>
                            <CustomArrow size={"8px"} color={theme.palette.blue.main} type={"left"} />
                        </div>
                        {!accountStore.isSiteTablet && (
                            <div style={{ marginLeft: "20px", fontSize: "18px", fontWeight: "bold", color: theme.palette.blue.main }}>
                                Back to incident ({viewModel.incident ? formatIncidentNumber(viewModel.incident.incidentNumber) : ""})
                            </div>
                        )}
                        {accountStore.isSiteTablet && (
                            <div style={{ marginLeft: "20px", fontSize: "18px", fontWeight: "bold", color: theme.palette.blue.main }}>Back to Site Menu</div>
                        )}
                    </div>
                </BackButtonHolder>

                <DashedDivider gutterBottom={false} gutterTop={false} borderThickness={2} borderOpacity={0.5} />

                <HeaderContainer>
                    <FormHeader displayName={isNullOrEmpty(viewModel.model.id) ? "New near miss" : "Near miss"} />
                </HeaderContainer>

                <FormSection>
                    <ProjectDetailsContainer>
                        <div>
                            <span>Date / time added:</span> {viewModel.getTodayDateFormatted}
                        </div>
                        <div>
                            <span>Incident No:</span> {viewModel.incident ? formatIncidentNumber(viewModel.incident.incidentNumber) : ""}
                        </div>
                        <div>
                            <span>Project reference:</span> {viewModel.getProjectReferenceFormatted}
                        </div>
                        <div>
                            <span>Project name:</span> {viewModel.getProjectName}
                        </div>
                    </ProjectDetailsContainer>
                </FormSection>

                <FormSection>
                    {/* Default options */}
                    <DashedDivider gutterBottom={true} borderThickness={2} borderOpacity={0.5} />

                    <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
                        <Cell ts={2} ds={2}>
                            <TextField
                                type="text"
                                value={viewModel.getValue("location")}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => viewModel.setValue("location", e.target.value)}
                                fullWidth
                                error={viewModel.getError("location") !== ""}
                                disabled={viewModel.isFirstFormDisabled || viewModel.IsLoading || viewModel.model.hasId}
                                label={"Exact location:"}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                placeholder={"Location of near miss"}
                                helperText={viewModel.getError("location")}
                            />
                        </Cell>

                        <Cell ts={8} ds={8}>
                            <TextField
                                id="what-happened-textarea"
                                multiline
                                minRows={4}
                                value={viewModel.getValue("whatHappened")}
                                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => viewModel.setValue<string>("whatHappened", event.target.value)}
                                fullWidth
                                className="textarea-forminput"
                                label="What happened?"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                placeholder={`Please add notes`}
                                disabled={viewModel.isFirstFormDisabled || viewModel.IsLoading || viewModel.model.hasId}
                                error={viewModel.getError("whatHappened") !== ""}
                                helperText={viewModel.getError("whatHappened")}
                            />
                        </Cell>

                        <Cell ts={4} ds={4}>
                            {!viewModel.isFirstFormDisabled && (
                                <Uploader
                                    handleSelectFile={(e: React.ChangeEvent<HTMLInputElement>) => viewModel.fileChange(e)}
                                    isFormDisabled={viewModel.isFirstFormDisabled || viewModel.IsLoading || viewModel.model.hasId}
                                    label="Photo(s):"
                                    dropzoneMessage="Click here to add photo/file"
                                />
                            )}

                            {viewModel.getPhotos.length > 0 && (
                                <FormLabel component="label">
                                    <span className="control-label">Photo(s) added</span>
                                </FormLabel>
                            )}

                            <div className="uploadedFile">
                                {viewModel.getPhotos.map((item: GenericPhotoListItemModel, index: number) => {
                                    return (
                                        <div className="uploadedFile-box">
                                            <div className="uploadedFile-heading">
                                                <IconButton onClick={() => viewModel.DownloadFile(item.photoURL, item.fileName)}>
                                                    <SystemUpdateTwoToneIcon />
                                                </IconButton>
                                                <h3>{item.fileName}</h3>
                                            </div>
                                            <IconButton onClick={() => item.handleDelete(true)} disabled={viewModel.isFirstFormDisabled || viewModel.model.hasId}>
                                                <DeleteOutlinedIcon />
                                            </IconButton>
                                        </div>
                                    );
                                })}
                            </div>
                        </Cell>
                    </Grid>
                </FormSection>

                <FormSection>
                    <SolidDivider gutterBottom={true} borderThickness={2} borderOpacity={0.5} />

                    {viewModel.model.hasInvestigationStarted && (
                        <>
                            <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
                                <Cell ts={2} ds={2}>
                                    <div style={{ display: "flex", flexDirection: "row", marginBottom: "15px" }}>
                                        <FormLabel component="label">
                                            <span className="control-label">Date added:</span>
                                        </FormLabel>
                                        <p style={{ fontSize: "12px", marginLeft: "10px" }}>{viewModel.getInvestigationDateFormatted}</p>
                                    </div>
                                </Cell>
                            </Grid>
                            <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
                                <Cell ts={2} ds={2}>
                                    <DarwinSelect
                                        displayName="Investigator:"
                                        execute={(data: { id: string; displayName: string }) => viewModel.handleSetInvestigatorUser(data)}
                                        fullWidth={true}
                                        getOptionLabel={(option: any) => option.displayName}
                                        options={viewModel.incidentUsers}
                                        placeholder="Please select user"
                                        value={viewModel.investigatorUser}
                                        canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                                        error={viewModel.getError("investigatorUserId") !== ""}
                                        validationMessage={viewModel.getError("investigatorUserId")}
                                    />
                                </Cell>
                                <Cell ts={8} ds={8}>
                                    <TextField
                                        id="whichPartOfWorkProcess-textarea"
                                        multiline
                                        minRows={4}
                                        value={viewModel.getValue("whichPartOfWorkProcess")}
                                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                                            viewModel.setValue<string>("whichPartOfWorkProcess", event.target.value)
                                        }
                                        fullWidth
                                        className="textarea-forminput"
                                        label="What part of the work process was the individual doing?"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        placeholder={`Please add notes`}
                                        disabled={viewModel.isFormDisabled || viewModel.IsLoading}
                                        error={viewModel.getError("whichPartOfWorkProcess") !== ""}
                                        helperText={viewModel.getError("whichPartOfWorkProcess")}
                                    />
                                </Cell>

                                <Cell ts={8} ds={8}>
                                    <TextField
                                        id="descriptionOfEnvironment-textarea"
                                        multiline
                                        minRows={4}
                                        value={viewModel.getValue("descriptionOfEnvironment")}
                                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                                            viewModel.setValue<string>("descriptionOfEnvironment", event.target.value)
                                        }
                                        fullWidth
                                        className="textarea-forminput"
                                        label="Description of the environment:"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        placeholder={`Please add notes`}
                                        disabled={viewModel.isFormDisabled || viewModel.IsLoading}
                                        error={viewModel.getError("descriptionOfEnvironment") !== ""}
                                        helperText={viewModel.getError("descriptionOfEnvironment")}
                                    />
                                </Cell>

                                <Cell ts={8} ds={8}>
                                    <TextField
                                        id="descriptionOfEquipment-textarea"
                                        multiline
                                        minRows={4}
                                        value={viewModel.getValue("descriptionOfEquipment")}
                                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                                            viewModel.setValue<string>("descriptionOfEquipment", event.target.value)
                                        }
                                        fullWidth
                                        className="textarea-forminput"
                                        label="Description of any equipment being used at time of near miss:"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        placeholder={`Please add notes`}
                                        disabled={viewModel.isFormDisabled || viewModel.IsLoading}
                                        error={viewModel.getError("descriptionOfEquipment") !== ""}
                                        helperText={viewModel.getError("descriptionOfEquipment")}
                                    />
                                </Cell>

                                <Cell ts={8} ds={8}>
                                    <TextField
                                        id="ppeRequired-textarea"
                                        multiline
                                        minRows={4}
                                        value={viewModel.getValue("ppeRequired")}
                                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                                            viewModel.setValue<string>("ppeRequired", event.target.value)
                                        }
                                        fullWidth
                                        className="textarea-forminput"
                                        label="Detail all PPE required by the risk assessment for the operation:"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        placeholder={`Please add notes`}
                                        disabled={viewModel.isFormDisabled || viewModel.IsLoading}
                                        error={viewModel.getError("ppeRequired") !== ""}
                                        helperText={viewModel.getError("ppeRequired")}
                                    />
                                </Cell>

                                <Cell ts={8} ds={8}>
                                    <TextField
                                        id="ppeWorn-textarea"
                                        multiline
                                        minRows={4}
                                        value={viewModel.getValue("ppeWorn")}
                                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => viewModel.setValue<string>("ppeWorn", event.target.value)}
                                        fullWidth
                                        className="textarea-forminput"
                                        label="Detail all PPE worn at time of incident:"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        placeholder={`Please add notes`}
                                        disabled={viewModel.isFormDisabled || viewModel.IsLoading}
                                        error={viewModel.getError("ppeWorn") !== ""}
                                        helperText={viewModel.getError("ppeWorn")}
                                    />
                                </Cell>
                            </Grid>
                        </>
                    )}
                </FormSection>

                {viewModel.model.hasInvestigationStarted && (
                    <>
                        <RootCauseChecks viewModel={viewModel} isDisabled={viewModel.isFormDisabled || viewModel.IsLoading} />

                        <FormSection>
                            <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
                                <Cell ts={2} ds={2}>
                                    <TextField
                                        type="text"
                                        value={currentRootCause}
                                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setCurrentRootCause(e.target.value)}
                                        fullWidth
                                        disabled={viewModel.isFormDisabled || viewModel.IsLoading}
                                        label={"If required give details of any other root causes:"}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        placeholder={"Please select"}
                                    />
                                </Cell>
                                <Cell ts={1} ds={1} style={{ display: "flex", alignItems: "end" }}>
                                    <PrimaryButton
                                        displayName="Add"
                                        execute={() => {
                                            if (currentRootCause !== "") {
                                                viewModel.addRootCause(currentRootCause);
                                                setCurrentRootCause("");
                                            }
                                        }}
                                        fullWidth={true}
                                        canExecute={!viewModel.IsLoading && currentRootCause !== ""}
                                    />
                                </Cell>
                                <Cell ts={8} ds={8}>
                                    <div className="uploadedFile">
                                        {viewModel.getRootCauses.map((item: GenericFreeTextListItemModel, index: number) => {
                                            return (
                                                <div className="uploadedFile-box">
                                                    <div className="uploadedFile-heading">
                                                        <h3>{item.name}</h3>
                                                    </div>
                                                    <IconButton onClick={() => item.handleDelete(true)} disabled={viewModel.isFormDisabled}>
                                                        <DeleteOutlinedIcon />
                                                    </IconButton>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </Cell>
                            </Grid>
                        </FormSection>

                        <FormSection>
                            <SolidDivider gutterBottom={true} borderThickness={2} borderOpacity={0.5} />

                            <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
                                <Cell ts={8} ds={8}>
                                    <TextField
                                        id="actionsToRecover-textarea"
                                        multiline
                                        minRows={4}
                                        value={viewModel.getValue("actionsToRecover")}
                                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                                            viewModel.setValue<string>("actionsToRecover", event.target.value)
                                        }
                                        fullWidth
                                        className="textarea-forminput"
                                        label="Details of actions taken immediately following the near miss to recover the situation:"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        placeholder={`Please add notes`}
                                        disabled={viewModel.isFormDisabled || viewModel.IsLoading}
                                        error={viewModel.getError("actionsToRecover") !== ""}
                                        helperText={viewModel.getError("actionsToRecover")}
                                    />
                                </Cell>

                                <Cell ts={8} ds={8}>
                                    <TextField
                                        id="actionsToPrevent-textarea"
                                        multiline
                                        minRows={4}
                                        value={viewModel.getValue("actionsToPrevent")}
                                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                                            viewModel.setValue<string>("actionsToPrevent", event.target.value)
                                        }
                                        fullWidth
                                        className="textarea-forminput"
                                        label="Action taken (or suggested) to prevent reoccurrence and to communicate lessons learnt from the near miss:"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        placeholder={`Please add notes`}
                                        disabled={viewModel.isFormDisabled || viewModel.IsLoading}
                                        error={viewModel.getError("actionsToPrevent") !== ""}
                                        helperText={viewModel.getError("actionsToPrevent")}
                                    />
                                </Cell>
                            </Grid>
                        </FormSection>
                    </>
                )}

                <FormSection>
                    <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
                        {viewModel!.server.HaveValidationMessage && (
                            <>
                                <Cell ts={8} ds={8}>
                                    <Alert severity="error">
                                        <AlertTitle>Error</AlertTitle>
                                        {viewModel!.server.ValidationMessage}
                                    </Alert>
                                </Cell>
                            </>
                        )}
                    </Grid>
                </FormSection>

                {viewModel.canStartInvestigation && (
                    <>
                        <div style={{ margin: `0 ${pxToRem(30)} 0 ${pxToRem(30)}` }}>
                            <SolidDivider gutterBottom={true} borderThickness={2} borderOpacity={0.5} />
                        </div>
                        <FormSection>
                            {/* Buttons */}
                            <ButtonsContainer>
                                <PrimaryButton displayName="Continue to investigation" execute={() => viewModel.upsert(true)} fullWidth={true} canExecute={!viewModel.IsLoading} />
                                <DefaultButton displayName="Return to incidents" execute={() => viewModel.handleCancel()} fullWidth={true} canExecute={!viewModel.IsLoading} />
                            </ButtonsContainer>
                        </FormSection>
                    </>
                )}

                {viewModel.canSaveForm && (
                    <>
                        <div style={{ margin: `0 ${pxToRem(30)} 0 ${pxToRem(30)}` }}>
                            <SolidDivider gutterBottom={true} borderThickness={2} borderOpacity={0.5} />
                        </div>
                        <FormSection>
                            {/* Buttons */}
                            <ButtonsContainer>
                                <PrimaryButton displayName="Submit" execute={() => viewModel.upsert(false)} fullWidth={true} canExecute={!viewModel.IsLoading} />
                                <DefaultButton displayName="Cancel" execute={() => viewModel.handleCancel()} fullWidth={true} canExecute={!viewModel.IsLoading} />
                            </ButtonsContainer>
                        </FormSection>
                    </>
                )}
            </Form>
        );
    });
};

export const IncidentFormNearMissView = styled(IncidentFormNearMissViewBase)`
    display: flex;
    flex: 1;
    flex-direction: column;

    form {
        position: relative;
    }
`;

const HeaderContainer = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 70px;

    // Header
    > div:nth-child(1) {
        margin: 4px 30px 13.5px 30px;
    }
`;

const ProjectDetailsContainer = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    max-width: 700px;

    > div {
        font-size: 12px;
    }

    > div > span {
        font-weight: bold;
    }
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;

    > button {
        max-width: 262px;
    }

    > button:nth-child(1) {
        margin-right: 30px;
    }
`;

interface SubtitleProps {
    margin?: string;
}

export const SubtitleContainer = styled.div<SubtitleProps>`
    h2 {
        margin-bottom: ${(props) => (props.margin ? props.margin : "10px")};
    }
`;
