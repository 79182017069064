import { ApiResult, FieldType, ViewModelBase } from "@shoothill/core";
import { RDDModel, RDDModelDTO } from "./RDDModel";
import { observable, action, computed, runInAction } from "mobx";
import type { IObservableArray } from "mobx";
import { AppUrls } from "AppUrls";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { DesignDataStatusViewModel } from "./DesignDataStatusViewModel";
import { RDDItemViewModel } from "./RDDItemViewModel";
import { RDDItemResponseViewModel } from "./RDDItemResponseViewModel";
import { RDDFilterViewModel } from "./RDDFilterViewModel";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import debounce from "lodash/debounce";
import axios, * as Axios from "axios";
import { ProjectGeneralViewModel } from "../../General/ProjectGeneralViewModel";
import { is } from "date-fns/locale";
import { RDDItemModelDTO } from "./RDDItemModel";

export class RDDViewModel extends ViewModelBase<RDDModel> {
    private static _instance: RDDViewModel;
    /*public static get Instance() {
        return this._instance || (this._instance = new this());
    }*/

    public constructor() {
        super(new RDDModel(), false);
        this.setDecorators(RDDModel);
    }

    @observable
    public searchString: string = "";

    @observable
    public hasLoaded: boolean = false;

    @observable public errorMessage: string = "";

    @observable
    public projectId: string | null = null;

    @observable
    public designDataId: string | null = null;

    @observable
    public selectedSupplierReference: string | null = null;

    @observable
    public designDataResponseId: string | null = null;

    @observable
    public isDarwinGroup: boolean | null = null;

    @observable
    public designDataItemViewModels: IObservableArray<RDDItemViewModel> = observable([]);

    @observable
    public designDataItemResponseViewModels: IObservableArray<RDDItemResponseViewModel> = observable([]);

    @computed
    public get getDesignDataItems(): RDDItemViewModel[] {
        return this.designDataItemViewModels;
    }

    @computed
    public get getDesignDataItemResponses(): RDDItemResponseViewModel[] {
        return this.designDataItemResponseViewModels;
    }

    @computed
    public get getLateCount(): number {
        const listOfLateItems = this.designDataItemViewModels.filter((i) => i.isLate);
        if (listOfLateItems) {
            return listOfLateItems.length;
        }
        return 0;
    }

    @observable
    public statuses = observable<DesignDataStatusDTO>([]);

    @observable
    public fullStatuses = observable<DesignDataFullStatusDTO>([]);

    @observable
    public addResponse: boolean = false;

    @observable
    public showItems: boolean = false;

    @observable
    public showFiles: boolean = false;

    @observable
    public showStatusChange: boolean = false;

    @action
    public setHasLoaded = (val: boolean) => {
        this.hasLoaded = val;
    };

    @action
    public clean = () => {
        this.designDataItemViewModels.replace([]);
    };

    @action
    public setBackGroundStatus(id: string | null): string {
        const status = this.fullStatuses.find((i: any) => i.id === id);
        const color = status?.color;
        return color ? color : "";
    }

    @action
    public setColorStatus(id: string | null): string {
        const status = this.fullStatuses.find((i: any) => i.id === id);
        const color = status?.textColor;
        return color ? color : "";
    }

    @action
    public handleAddResponseShow = (itemId: string | null, designDataId: string, projectId: string, isDarwinGroup: boolean | null) => {
        this.designDataResponseId = itemId;
        this.designDataId = designDataId;
        this.projectId = projectId;
        this.isDarwinGroup = isDarwinGroup;
        this.addResponse = true;
    };

    @action
    public handleItemsShow = (itemId: string | null, designDataId: string, projectId: string, isDarwinGroup: boolean | null) => {
        this.designDataResponseId = itemId;
        this.designDataId = designDataId;
        this.projectId = projectId;
        this.isDarwinGroup = isDarwinGroup;
        this.showItems = true;
    };

    @action
    public handleFilesShow = (itemId: string | null, designDataId: string, projectId: string, isDarwinGroup: boolean | null) => {
        this.designDataResponseId = itemId;
        this.designDataId = designDataId;
        this.projectId = projectId;
        this.isDarwinGroup = isDarwinGroup;
        this.showFiles = true;
    };

    @action
    public handleStatusChangeShow = (itemId: string | null, designDataId: string, projectId: string, isDarwinGroup: boolean | null) => {
        this.designDataResponseId = itemId;
        this.designDataId = designDataId;
        this.projectId = projectId;
        this.isDarwinGroup = isDarwinGroup;
        this.showStatusChange = true;
    };

    @action
    public handleAddResponseClose = (projectId: string, isDarwinGroup: boolean | null) => {
        this.addResponse = false;
        this.loadDesignData(projectId, isDarwinGroup);
    };

    @action
    public handleShowItemsClose = () => {
        this.showItems = false;
    };

    @action
    public handleShowFilesClose = () => {
        this.showFiles = false;
    };

    @action
    public handleStatusChangeClose = () => {
        this.showStatusChange = false;
    };

    @action
    public handleForwardToClientRDD = (designDataId: string) => {
        this.showStatusChange = false;
        this.history.push(
            AppUrls.Client.Project.ReviewableDesignData.AddWithReference.replace(":projectid", this.projectId!)
                .replace(":isdarwinrdd", "0")
                .replace(":ref", this.selectedSupplierReference!),
        );
    };

    @action
    public handleReturnStatus = (rowData: RDDItemViewModel) => {
        return this.statuses.find((s) => s.id === rowData.statusId);
    };

    @action
    public handleReturnResponses = (rowData: RDDItemViewModel) => {
        return this.designDataItemResponseViewModels.find((s) => s.designDataId === rowData.id);
    };

    @action
    public handleReturnResponseCount = (rowData: RDDItemViewModel) => {
        const responses = this.designDataItemResponseViewModels.filter((s) => s.designDataId === rowData.id);
        if (responses) {
            return responses.length;
        }
        return 0;
    };

    @action
    public handleStatusId = (
        e: React.MouseEvent<HTMLSelectElement, MouseEvent>,
        rowData: RDDItemViewModel,
        item: { id: string; displayName: string },
        projectId: string,
        isDarwinGroup: boolean | null,
    ) => {
        e.stopPropagation();
        e.preventDefault();
        this.updateStatus(rowData.id, item.id, projectId, isDarwinGroup);
    };

    @action
    public createViewModels = () => {
        for (const item of this.model.designDataItems) {
            this.designDataItemViewModels.push(new RDDItemViewModel(item));
        }
        for (const itemResponse of this.model.designDataItemResponses) {
            this.designDataItemResponseViewModels.push(new RDDItemResponseViewModel(itemResponse));
        }
    };

    public getSearchString = () => {
        return computed(() => this.searchString);
    };

    @action
    public exportAsCSV = async (projectId: string, isDarwin: boolean | null) => {
        this.setIsLoading(true);

        const requestBody = {
            projectId,
            isDarwinGroup: isDarwin,
            designDataId: null,
            designDataStatusId: null,
            searchText: "",
        };

        let config: Axios.AxiosRequestConfig = {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
        };

        const response = await axios
            .post(AppUrls.Server.Projects.ProjectTrackers.DesignData.ExportRDDList, requestBody, await this.getConfig(true, config))
            .then((response: any) => {
                if (response.status === 200) {
                    const headerFileName: string = response.headers["content-disposition"].split("filename=")[1].split(";")[0];
                    let fileName = "RDDCSV.csv";
                    if (headerFileName.endsWith(".csv")) {
                        fileName = headerFileName;
                    }
                    const url_1 = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url_1;
                    link.setAttribute("download", fileName);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    window.URL.revokeObjectURL(url_1);
                }
            })
            .finally(() => this.setIsLoading(false));
    };

    public server: ServerViewModel = new ServerViewModel();

    public loadDesignData = async (projectId: string, isDarwinGroup: boolean | null): Promise<ApiResult<RDDModelDTO>> => {
        this.setIsLoading(true);
        let paramsViewModel = RDDFilterViewModel.Instance;
        let params = paramsViewModel.model.toDto();
        params.projectId = projectId;
        params.isDarwinGroup = isDarwinGroup;
        params.designDataId = null;
        params.designDataStatusId = null;

        const apiResult = await this.Post<RDDModelDTO>(AppUrls.Server.Projects.ProjectTrackers.DesignData.GetDesignDataList, params);
        if (apiResult) {
            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.model.designDataItems = [];
                    this.designDataItemViewModels.clear();
                    this.model.designDataItemResponses = [];
                    this.designDataItemResponseViewModels.clear();
                    this.statuses.replace(apiResult.payload.designDataStatuses);
                    this.fullStatuses.replace(apiResult.payload.fullStatuses);
                    this.model.fromDto(apiResult.payload);
                    this.createViewModels();
                });
            } else {
                console.log(apiResult.errors);
            }
        }
        this.setHasLoaded(true);
        this.setIsLoading(false);
        return apiResult;
    };

    public updateStatus = async (id: string, statusId: string, projectId: string, isDarwinGroup: boolean | null): Promise<ApiResult<RDDModelDTO>> => {
        this.setIsLoading(true);
        let paramsViewModel = RDDFilterViewModel.Instance;
        let params = paramsViewModel.model.toDto();
        params.projectId = projectId;
        params.isDarwinGroup = isDarwinGroup;
        params.designDataId = id;
        params.designDataStatusId = statusId;

        this.projectId = projectId;
        this.designDataId = id;

        const apiResult = await this.Post<RDDModelDTO>(AppUrls.Server.Projects.ProjectTrackers.DesignData.UpdateStatus, params);
        if (apiResult) {
            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.model.designDataItems = [];
                    this.designDataItemViewModels.clear();
                    this.model.designDataItemResponses = [];
                    this.designDataItemResponseViewModels.clear();
                    this.statuses.replace(apiResult.payload.designDataStatuses);
                    this.fullStatuses.replace(apiResult.payload.fullStatuses);
                    this.model.fromDto(apiResult.payload);
                    this.createViewModels();

                    const selectedStatus: DesignDataStatusDTO | undefined = this.statuses.find((t) => t.id === statusId);
                    const updatedDesignData: RDDItemModelDTO | undefined = apiResult.payload.designDataItems.find((t) => t.id === id);
                    if (selectedStatus) {
                        if (selectedStatus.displayName === "With client") {
                            if (updatedDesignData) {
                                this.selectedSupplierReference = updatedDesignData.supplierReference;
                            }
                            this.showStatusChange = true;
                        }
                    }
                });
            } else {
                console.log(apiResult.errors);
            }
        }
        this.setHasLoaded(true);
        this.setIsLoading(false);
        return apiResult;
    };

    public handleSearchChange = (projectId: string, val: string, isDarwinGroup: boolean | null) => {
        RDDFilterViewModel.Instance.setValue("searchText", val);

        if (val.length > 3 || val.length === 0) {
            this.reloadDataDelayed(projectId, isDarwinGroup);
        }
    };

    private reloadDataDelayed = debounce((projectId: string, isDarwinGroup: boolean | null) => {
        this.loadDesignData(projectId, isDarwinGroup);
    }, 300);

    @action
    public handleRowClick = (e: any, rowData: RDDItemViewModel, projectId: string) => {
        if (e.target.src === undefined) {
            this.history.push(
                AppUrls.Client.Project.ReviewableDesignData.View.replace(":projectid", projectId)
                    .replace(":designdataid", rowData.id)
                    .replace(":isdarwinrdd", rowData.model.isDarwinGroup ? "1" : "0"),
            );
        }
    };

    @action
    public handleCreateNew = (projectId: string, isDarwin: boolean | null) => {
        this.history.push(AppUrls.Client.Project.ReviewableDesignData.Add.replace(":projectid", projectId).replace(":isdarwinrdd", isDarwin ? "1" : "0"));
    };

    @action
    public handleRevisionClick = (e: any, rowData: RDDItemViewModel, projectId: string) => {
        e.stopPropagation;
        e.preventDefault;
        this.history.push(
            AppUrls.Client.Project.ReviewableDesignData.AddWithRevision.replace(":projectid", projectId)
                .replace(":designdataid", rowData.id)
                .replace(":isdarwinrdd", rowData.model.isDarwinGroup ? "1" : "0")
                .replace(":isrevision", "1"),
        );
    };

    public async isFieldValid(fieldName: keyof FieldType<RDDModel>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}

export interface DesignDataUpdateStatusDTO {
    id: string;
    designDataStatusId: string;
}

export interface DesignDataStatusDTO {
    id: string;
    displayName: string;
}

export interface DesignDataFullStatusDTO {
    id: string;
    displayName: string;
    color: string;
    textColor: string;
    type: number;
}
