import { EnumType, IEnumType } from "./Base/EnumType";

export class InductionSectionType extends EnumType {
    // #region Constants

    public static readonly PlantAndEquipment = "PLANT_EQUIPMENT";
    public static readonly SkillAndTraining = "SKILLS_TRAINING";

    // #endregion Constants

    // #region Properties
    // #endregion Properties

    // #region Methods

    public fromDto(dto: IInductionSectionType) {
        super.fromDto(dto);
    }

    // #endregion Methods
}

export interface IInductionSectionType extends IEnumType {}
