import { isEmptyOrWhitespace, ModelBase } from "@shoothill/core";
import { computed, observable } from "mobx";
import { nanoid } from "nanoid";

import { FileModel } from "../File/FileModel";

export class FilesModel extends ModelBase<FilesModel> {
    public KEY: string = nanoid();
    public backupCopy: FilesModel | null = null;

    @observable
    public files = observable<FileModel>([]);

    @observable
    public removedFiles = observable<FileModel>([]);

    /**
     * If user is only allow to seledt one file, set this to false.
     */
    public allowMultipleFiles = true;

    /**
     * Permitted file mime types. Examples are:
     * "image/png, image/jpeg" - png or jpeg files.
     * "image/*" - any image files.
     */
    public fileTypes = "";

    constructor() {
        super();
    }

    // #region Business Logic

    static readonly MAX_FILESIZE_BYTES = 30000000;
    static readonly MAX_FILESIZE_MB = "28MB";

    /**
     * The total size of the files to be uploaded must not exceed
     * the maximum file size MAX_FILESIZE_BYTES.
     */
    @computed
    public get insideUploadLimit(): boolean {
        return (
            this.files
                .filter((f) => isEmptyOrWhitespace(f.id))
                .map((f) => f.fileSizeBytes)
                .reduce((runningFileSize, fileSizeBytes) => runningFileSize + fileSizeBytes, 0) < FilesModel.MAX_FILESIZE_BYTES
        );
    }

    /**
     * If allowMultipleFiles is false, there cannot be more than
     * one file.
     */
    @computed
    public get validMultipleFiles() {
        if (!this.allowMultipleFiles && this.files.length > 1) {
            return false;
        }

        return true;
    }

    /**
     * If mimetype constraints are defined, ensure the type of any
     * files to be uploaded meet those constraints.
     */
    @computed
    public get validFileTypes() {
        if (!isEmptyOrWhitespace(this.fileTypes)) {
            for (const file of this.files) {
                if (!this.isValidFileType(file)) {
                    return false;
                }
            }
        }

        return true;
    }

    private isValidFileType = (fileModel: FileModel): boolean => {
        // If no accepted file types defined, then the file is valid.
        if (isEmptyOrWhitespace(this.fileTypes)) {
            return true;
        }

        // Files that have been uploaded will not have a file defined in the model.
        // Fall back onto the model for these.
        const fileType = fileModel.file ? fileModel.file.type : fileModel.mimeType;
        const fileName = fileModel.file ? fileModel.file.name : fileModel.fileName;

        const baseFileType = fileType.replace(/\/.*$/, "");

        // File types may contain one of more comma separated types.
        const acceptedFileMimeTypes = this.fileTypes.split(",");

        for (let acceptedFileMimeType of acceptedFileMimeTypes) {
            acceptedFileMimeType = acceptedFileMimeType.trim();

            if (acceptedFileMimeType.charAt(0) === ".") {
                if (fileName.toLowerCase().indexOf(acceptedFileMimeType.toLowerCase(), fileName.length - acceptedFileMimeType.length) !== -1) {
                    return true;
                }
            } else if (/\/\*$/.test(acceptedFileMimeType)) {
                // This is something like a image/* mime type
                if (baseFileType === acceptedFileMimeType.replace(/\/.*$/, "")) {
                    return true;
                }
            } else {
                if (fileType === acceptedFileMimeType) {
                    return true;
                }
            }
        }

        return false;
    };

    // #endregion Business Logic

    // #region Helpers

    public copy = (excludeKey: boolean = false): FilesModel => {
        const model = new FilesModel();

        model.KEY = excludeKey ? model.KEY : this.KEY;
        model.files.replace(this.files.map((f) => f.copy()));
        model.removedFiles.replace(this.removedFiles.map((f) => f.copy()));

        return model;
    };

    public backup = (): FilesModel => {
        const model = new FilesModel();

        model.KEY = model.KEY;
        model.files.replace(this.files.slice());
        model.removedFiles.replace(this.removedFiles.slice());

        this.backupCopy = model;

        return model;
    };

    public restore = (): FilesModel => {
        if (this.backupCopy) {
            this.KEY = this.backupCopy.KEY;
            this.files.replace(this.backupCopy.files.slice());
            this.removedFiles.replace(this.backupCopy.removedFiles.slice());
        }

        return this;
    };

    // #endregion Helpers

    fromDto(model: any): void {
        throw new Error("Method not implemented.");
    }
    toDto(model: FilesModel): void {
        throw new Error("Method not implemented.");
    }
}
