import { ApiResult, FieldType, ViewModelBase, isEmptyOrWhitespace } from "@shoothill/core";
import { runInAction, observable, computed, action } from "mobx";
import {
    FormRequestDTO,
    OtherProgrammeNotesByIdResponseDTO,
    OtherProgrammeNotesFile,
    OtherProgrammeNotesModel,
    UpsertOtherProgrammeNotesRequestDTO,
    UpsertOtherProgrammeNotesResponseDTO,
} from "./OtherProgrammeNotesModel";
import { AppUrls } from "AppUrls";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";

export class OtherProgrammeNotesViewModel extends ViewModelBase<OtherProgrammeNotesModel> {
    public projectId: string = "";
    public constructor(projectId: string | null, date: any) {
        super(new OtherProgrammeNotesModel(), false);
        this.setDecorators(OtherProgrammeNotesModel);
        this.model.projectId = projectId!;
        this.model.createdDate = date.toISOString();
        isEmptyOrWhitespace(this.model.id) && this.loadWithRelated();
    }

    @observable public errorMessage: string = "";

    public server: ServerViewModel = new ServerViewModel();

    @computed
    public get isFormDisabled(): boolean {
        return this.model.hasId;
    }

    @action
    public reset = () => {
        this.model.reset();
        this.server.reset();
    };

    @action
    public setOtherProgrammePhotosFile = (file: OtherProgrammeNotesFile) => {
        this.model.otherProgrammePhotos.push(file);
    };

    @action
    public deleteOtherProgrammePhotosFile = async (index: number): Promise<void> => {
        this.model.otherProgrammePhotos[index].isDeleted = true;
    };

    public fileChange = async (event: any): Promise<void> => {
        if (event.target.files.length > 0) {
            let data: any = {
                fileName: event.target.files[0].name,
                formFile: event.target.files[0],
            };
            event.target.value = null;
            const apiResult = await this.fileUpload(data);
            if (apiResult && apiResult.wasSuccessful) {
                let fileToDisplay: OtherProgrammeNotesFile = {
                    id: null,
                    otherProgrammeNotesId: null,
                    fileName: data.fileName,
                    photoURL: apiResult.payload,
                    isDeleted: false,
                    createdByUserId: "",
                    createdDate: "",
                };
                this.setOtherProgrammePhotosFile(fileToDisplay);
            }
        }
    };

    public fileUpload = async (data: any): Promise<ApiResult<any>> => {
        const formData = new FormData();
        formData.append("formFile", data.formFile);
        formData.append("fileName", data.fileName);
        const apiResult = await this.Post<any>(AppUrls.Server.File.UploadFile, formData);
        if (apiResult) {
            if (!apiResult.wasSuccessful) {
                console.log(apiResult.errors);
            }
        }
        return apiResult;
    };

    public DownloadFile = async (fileUrl: string, fileName: string): Promise<void> => {
        try {
            const apiResult = await this.Post<Blob>(AppUrls.Server.File.DownloadFile, fileUrl, undefined, { responseType: "blob" });
            const response = apiResult as any;
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
        } catch (exception) {
            console.error(exception);
            this.setIsErrored(true);
        }
    };

    @action
    public loadWithRelated = async (): Promise<void> => {
        this.setIsLoading(true);
        const request: FormRequestDTO = {
            date: this.model.createdDate,
            projectId: this.model.projectId,
            formId: null,
        };
        let apiResult = await this.Post<any>(AppUrls.Server.Projects.Construction.ProgrammeUpdates.OtherProgrammeNotes.GetOtherProgrammeNotesAndRelatedByProjectId, request);
        if (apiResult) {
            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.model.fromDto(apiResult.payload.otherProgrammeNotes);
                    apiResult.payload.otherProgrammeNotesPhotos.map((el: any) => this.setOtherProgrammePhotosFile(el));
                });
            } else {
                console.log(apiResult.errors);
            }
        }
    };

    @action
    public upsert = async (e?: any): Promise<ApiResult<UpsertOtherProgrammeNotesRequestDTO>> => {
        e?.preventDefault();

        if (await this.isMyModelValid()) {
            const model: any = this.model.toDto();
            let otherProgrammePhotos: OtherProgrammeNotesFile[] = [];
            this.model.otherProgrammePhotos.forEach((otherProgrammePhoto) => {
                const otherProgramme: OtherProgrammeNotesFile = {
                    id: otherProgrammePhoto.id,
                    otherProgrammeNotesId: otherProgrammePhoto.otherProgrammeNotesId,
                    fileName: otherProgrammePhoto.fileName,
                    photoURL: otherProgrammePhoto.photoURL,
                    createdByUserId: otherProgrammePhoto.createdByUserId,
                    isDeleted: otherProgrammePhoto.isDeleted,
                    createdDate: otherProgrammePhoto.createdDate,
                };
                otherProgrammePhotos.push(otherProgramme);
            });

            const request: UpsertOtherProgrammeNotesRequestDTO = {
                otherProgrammeNotes: model,
                otherProgrammeNotesPhotos: otherProgrammePhotos,
            };

            let apiResult = await this.Post<UpsertOtherProgrammeNotesResponseDTO>(
                AppUrls.Server.Projects.Construction.ProgrammeUpdates.OtherProgrammeNotes.UpsertOtherProgrammeNotes,
                request,
            );

            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.model.fromDto(apiResult.payload.otherProgrammeNotes);
                    apiResult.payload.otherProgrammeNotesPhotos.map((el: any) => this.setOtherProgrammePhotosFile(el));
                });
            }
            return apiResult;
        } else {
            this.errorMessage = "Form is not valid";
            return Promise.reject();
        }
    };

    // Validation Start

    @computed
    private get validateOtherProgrammeNote() {
        const errorMessage = this.model.validateOtherProgrammeNote;
        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    // End Validation

    private isMyModelValid = async (): Promise<boolean> => {
        let isValid = true;
        if ((await this.isModelValid()) === false) {
            isValid = false;
        }

        return isValid;
    };

    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (await this.isModelValid()) {
            this.errorMessage = "Form is valid";
        } else {
            this.errorMessage = "Form is not valid";
        }
    };

    public async isFieldValid(fieldName: keyof FieldType<OtherProgrammeNotesModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        if (fieldName) {
            switch (fieldName) {
                case "otherProgrammeNote": {
                    const result = this.validateOtherProgrammeNote;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
            }
        } else {
            errorMessage = "";
            isValid = true;
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
