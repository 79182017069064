import { FieldType, ViewModelBase } from "@shoothill/core";
import { action, computed, observable } from "mobx";

import { AppUrls } from "AppUrls";
import { MaterialType } from "Globals/Models/Enums/MaterialType";
import { MaterialSubType } from "Globals/Models/Enums/MaterialSubType";
import { UnitOfMeasure } from "Globals/Models/Enums/UnitOfMeasure";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { isNullOrUndefined } from "Utils/Utils";
import { MaterialDetailsModel } from "./MaterialDetailsModel";

export class MaterialDetailsViewModel extends ViewModelBase<MaterialDetailsModel> {
    public server: ServerViewModel = new ServerViewModel();

    private materialId: string;
    private parentCloseAction: (refreshPage: boolean) => void;

    constructor(materialId: string, closeAction: (refreshPage: boolean) => void) {
        super(new MaterialDetailsModel(), false);

        this.materialId = materialId;
        this.parentCloseAction = closeAction;

        this.setDecorators(MaterialDetailsModel);

        // Load material details.
        this.apiLoadDetailsAsync();
    }

    // #region Material Types

    @computed
    public get materialTypes() {
        return this.model.materialTypes;
    }

    @computed
    public get materialType() {
        return this.model.materialType;
    }

    @action
    public setMaterialType = (value: MaterialType | null): void => {
        this.model.materialType = value ?? MaterialDetailsModel.DEFAULT_MATERIALTYPE;
        this.isFieldValid("materialType");

        // SIDE-EFFECT.
        // If the user changes the material type, it invalidates
        // any selected material subtype.
        this.model.materialSubType = MaterialDetailsModel.DEFAULT_MATERIALSUBTYPE;
    };

    // #endregion Material Types

    // #region Material SubTypes

    @computed
    public get materialSubTypes() {
        if (isNullOrUndefined(this.model.materialType)) {
            return [];
        }

        return this.model.materialSubTypes.filter((mst) => mst.materialTypeId === this.model.materialType!.id);
    }

    @computed
    public get materialSubType() {
        return this.model.materialSubType;
    }

    @action
    public setMaterialSubType = (value: MaterialSubType | null): void => {
        this.model.materialSubType = value ?? MaterialDetailsModel.DEFAULT_MATERIALSUBTYPE;
        this.isFieldValid("materialSubType");
    };

    // #region Material SubTypes

    // #endregion Units of Measure

    @computed
    public get unitsOfMeasure() {
        return this.model.unitsOfMeasure;
    }

    @computed
    public get unitOfMeasure() {
        return this.model.unitOfMeasure;
    }

    @action
    public setUnitOfMeasure = (value: UnitOfMeasure | null): void => {
        this.model.unitOfMeasure = value ?? MaterialDetailsModel.DEFAULT_UNITOFMEASURE;
        this.isFieldValid("unitOfMeasure");
    };

    // #endregion Units of Measure

    // #region Actions

    @action
    public cancel = () => this.parentCloseAction(false);

    // #endregion Actions

    // #region Api Actions

    @action
    public apiLoadDetailsAsync = async (): Promise<void> => {
        await this.server.query<any>(
            () => this.Get(AppUrls.Server.Stock.Material.GetMaterialDetailsWithRelatedByMaterialId.replace("{materialid}", this.materialId)),
            (result) => this.model.fromDto(result),
            "Error whilst loading the material data",
        );

        if (this.server.HaveValidationMessage) {
            this.setSnackMessage(this.server.ValidationMessage);
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }
    };

    @action
    public apiSaveDetailsAsync = async (): Promise<void> => {
        await this.server.command<any>(
            () => this.Post(AppUrls.Server.Stock.Material.UpdateMaterialDetails.replace("{materialid}", this.materialId), this.model.toDto()),
            (result) => this.parentCloseAction(true),
            this.isModelValid,
            "Error whilst saving the material data",
        );

        if (this.server.HaveValidationMessage) {
            this.setSnackMessage(this.server.ValidationMessage);
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }
    };

    // #endregion Api Actions

    // #region Snack Bar

    public SNACKERROR = "error";

    @observable
    public snackbarState = false;

    @observable
    public snackType = "";

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public snackMessage = "";

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    // #endregion Snack Bar

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<MaterialDetailsModel>): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boliderplate
}
