import { FieldType, ViewModelBase } from "@shoothill/core";
import { action, computed } from "mobx";

import { QualificationModel } from "./QualificationModel";

export class QualificationViewModel extends ViewModelBase<QualificationModel> {
    constructor(model: QualificationModel) {
        super(model);
        this.setDecorators(model);
    }

    @action
    public sectionChange = (fieldName: any, value: string) => {
        this.setValue(fieldName as any, value);
    };

    @action
    public deletePicture = () => {
        this.setValue("photoEvidenceData", "");
        this.setValue("photoEvidenceFileName", "");
    };

    @action
    public fileChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        if (event.target.files !== null && event.target.value !== null && event.target.files.length > 0) {
            const file = event.target.files[0];

            // Check if the selected file is an image
            if (this.isImageFile(file)) {
                let data: any = {
                    fileName: file.name,
                    formFile: file,
                };

                this.setValue("photoEvidenceFileName", data.fileName);
                this.setValue("photoEvidenceData", await this.convertBase64(data.formFile));
            } else {
                alert("Only images can be uploaded as photo evidence.");
                event.target.value = "";
            }
        }
    };

    // Check if the file is an image file
    private isImageFile = (file: File): boolean => {
        const acceptedImageTypes = ["image/jpeg", "image/png", "image/gif"];
        return acceptedImageTypes.includes(file.type);
    };

    public convertBase64 = (file: Blob) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    @computed
    public get canDisplayPassedFormItems() {
        return this.model.passed === "true";
    }

    @computed
    public get canDisplayFailedFormItems() {
        return this.model.passed === "false";
    }

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<QualificationModel>, value?: any): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
