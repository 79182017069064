import { Button } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React from "react";

import { DarwinSelect } from "Components/AutoComplete/DarwinSelect";
import { BaseModal } from "Components/Modal/BaseModal";
import { SnackBar } from "Components/SnackBar/SnackBar";
import { PlanOfWorkStage } from "Globals/Models/Enums/PlanOfWorkStage";
import { CancelButton } from "Globals/Styles/Control/Buttons";
import { AddPlanOfWorkModalStyle } from "Views/Project/Modals/Modal.Styles";
import { FormModalSection, FormRow } from "Views/Stock/Shared/Shared.styles";
import { AddPlanOfWorkViewModel } from ".";

interface IProps {
    viewModel: AddPlanOfWorkViewModel;
}

export const AddPlanOfWorkView: React.FC<IProps> = observer((props) => {
    const viewModel = props.viewModel;

    return (
        <BaseModal
            open={viewModel !== null}
            onClose={viewModel.cancel}
            title="Add new stage"
            PaperComponent={AddPlanOfWorkModalStyle}
            actions={
                <React.Fragment>
                    <Button autoFocus color="secondary" disabled={viewModel.server.IsBusy} onClick={viewModel.apiInsertPlanOfWorkAsync} variant="contained">
                        Save
                    </Button>
                    <CancelButton onClick={viewModel.cancel}>Cancel</CancelButton>
                </React.Fragment>
            }
        >
            <FormModalSection>
                <FormRow grid dc={"1fr"}>
                    <DarwinSelect
                        displayName="RIBA stage:"
                        execute={viewModel.setPlanOfWorkStage}
                        fullWidth={true}
                        getOptionLabel={(option: PlanOfWorkStage) => option?.displayName ?? ""}
                        options={viewModel.planOfWorkStages}
                        placeholder="Please select"
                        validationMessage={viewModel.getError("planOfWorkStage")}
                        value={viewModel.planOfWorkStage}
                    />
                </FormRow>
            </FormModalSection>
            <SnackBar
                messageText={viewModel.snackMessage}
                messageType={viewModel.snackType}
                active={viewModel.snackbarState}
                closeOption={() => viewModel.setSnackbarState(false)}
                autoHideDuration={5000}
            />
        </BaseModal>
    );
});
