import { computed, observable } from "mobx";
import { FieldType, ViewModelBase } from "@shoothill/core";
import moment from "moment";
import { RFIItemModel } from "./RFIItemModel";

export class RFIItemViewModel extends ViewModelBase<RFIItemModel> {
    constructor(item: RFIItemModel) {
        super(item);
    }

    @observable
    public category: { id: string; displayName: string } | null = null;

    @computed
    public get id() {
        return this.model.id;
    }

    @computed
    public get rFICategoryId() {
        return this.model.rfiCategoryId;
    }

    @computed
    public get latestResponseName() {
        return this.model.latestResponseName;
    }

    @computed
    public get categoryName() {
        return this.model.categoryName;
    }

    @computed
    public get supplierReference() {
        return this.model.supplierReference;
    }

    @computed
    public get clientReference() {
        return this.model.clientReference;
    }

    @computed
    public get supplierName() {
        return this.model.supplierName;
    }

    @computed
    public get title() {
        return this.model.title;
    }

    @computed
    public get description() {
        return this.model.description;
    }

    @computed
    public get requestedDateFormatted() {
        return this.model.requestedDate ? `${moment(this.model.requestedDate).format("DD/MM/YYYY")}` : "";
    }

    @computed
    public get requestedByName() {
        return this.model.requestedByName;
    }

    @computed
    public get requestedTo() {
        return this.model.requestedTo;
    }

    @computed
    public get responseRequiredByDateFormatted() {
        return this.model.responseRequiredByDate ? `${moment(this.model.responseRequiredByDate).format("DD/MM/YYYY")}` : "";
    }

    @computed
    public get numberOfFiles() {
        return this.model.numberOfFiles;
    }

    @computed
    public get statusName() {
        return this.model.statusName;
    }

    @computed
    public get statusId() {
        return this.model.rfiStatusId;
    }

    @computed
    public get clientReferenceFormatted() {
        if (this.model.clientReference === null) {
            return "";
        } else if (this.model.clientReference === "0" || (this.model.clientReference === null && this.model.revision === null)) {
            return "";
        } else {
            if (this.model.revision === 0 || this.model.revision === null) {
                return "CL" + this.model.clientReference;
            } else {
                return "CL" + this.model.clientReference + "." + this.model.revision.toString();
            }
        }
    }

    @computed
    public get latestResponseFormatted() {
        return this.model.latestResponseName ? `${moment(this.model.latestResponseDate).format("DD/MM/YYYY @ HH:mm")} by ${this.model.latestResponseName}` : "";
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public async isFieldValid(fieldName: keyof FieldType<RFIItemModel>): Promise<boolean> {
        return true;
    }
}
