import { ApiResult, FieldType, ModelBase, ViewModelBase, isEmptyOrWhitespace, observable } from "@shoothill/core";
import { DilapidationFormModel } from "../Dilapidation/DilapidationFormModel";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { AppUrls } from "AppUrls";
import { runInAction, action, computed } from "mobx";
import { RAMSAndRelatedResult, RAMSReviewFormModel } from "./RAMSReviewFormModel";
import { IRAMSProjectDetailsDTO } from "./RAMSProjectDetails";
import { RAMSChoiceViewModel } from "./RAMSChoiceViewModel";
import { RAMSViewModel } from "./RAMSViewModel";
import moment from "moment";

export class RAMSReviewFormViewModel extends ViewModelBase<RAMSReviewFormModel> {
    public server: ServerViewModel = new ServerViewModel();

    @observable
    public subContractorSupervisor: { id: string; displayName: string } | null = null;

    @observable
    public ReviewBy: { id: string; displayName: string } | null = null;

    @observable
    public showValidationError: boolean = false;

    // Form View Models

    public RAMSViewModel = observable<RAMSViewModel>(new RAMSViewModel());

    public RAMSChoiceViewModel = observable<RAMSChoiceViewModel>(new RAMSChoiceViewModel());

    constructor(projectid: string | null, ramsId: string | null) {
        super(new RAMSReviewFormModel());
        this.setDecorators(RAMSReviewFormModel);
        this.RAMSViewModel.model.id = ramsId;
        isEmptyOrWhitespace(this.RAMSViewModel.model.id) ? "" : "";
    }

    public loadRelated = async (projectId: any) => {
        this.setProjectId(projectId);

        this.setIsLoading(true);

        let url = `${AppUrls.Server.Projects.Construction.Rams.GetRAMSRelated}/${projectId ? projectId : ""}`;

        return await this.server
            .query<RAMSRelatedResultDTO>(
                () => this.Get(url),
                (result: RAMSRelatedResultDTO) => {
                    runInAction(() => {
                        this.model.fromRelatedDto(result);
                    });
                },
            )
            .finally(() => this.setIsLoading(false));
    };

    public loadWithRelated = async (RamsId: any): Promise<void> => {
        this.setIsLoading(true);
        return await this.server
            .query<RAMSAndRelatedResult>(
                () => this.Get(`${AppUrls.Server.Projects.Construction.Rams.GetRAMSAndRelatedById}\\${RamsId}`),
                (result: RAMSAndRelatedResult) => {
                    runInAction(() => {
                        this.RAMSViewModel.model.fromDto(result.rams);
                        this.RAMSChoiceViewModel.model.fromDto(result.ramsChoice);
                        this.model.rAMSAttachments = result.attachments;
                        this.model.fromDto(result);

                        this.model.supervisorUsers.replace(result.supervisorUsers);

                        this.model.reviewedByUsers.replace(result.reviewedByUsers);
                        const selectedReviewedByUser: RAMSUserDTO | undefined = this.model.reviewedByUsers.find((t) => t.id === this.RAMSViewModel.getValue("reviewedByUserId"));

                        if (selectedReviewedByUser) {
                            this.handleReviewByUserName(selectedReviewedByUser);
                        }
                    });
                },
            )
            .finally(() => this.setIsLoading(false));
    };

    @action
    public handleSubmit = async () => {
        this.setIsLoading(true);
        let isValid = true;
        let RAMSAttachmentsToDTO: RAMSAttachment[] = [];

        this.model.rAMSAttachments.forEach((Attachment) => {
            const RAMSAttachment: RAMSAttachment = {
                id: Attachment.id,
                rAMSId: Attachment.rAMSId,
                fileName: Attachment.fileName,
                fileURL: Attachment.fileURL,
                createdByUserId: Attachment.createdByUserId,
                isDeleted: Attachment.isDeleted,
            };
            RAMSAttachmentsToDTO.push(RAMSAttachment);
        });

        const request: UpsertRAMSAndRelated = {
            rAMS: this.RAMSViewModel.model.toDto(),
            rAMSChoice: this.RAMSChoiceViewModel.model.toDto(),
            rAMSAttachments: RAMSAttachmentsToDTO,
        };

        return this.server
            .command<RAMSAndRelatedResult>(
                () => this.Post(`${AppUrls.Server.Projects.Construction.Rams.UpsertRAMSForm}`, request),
                (response: RAMSAndRelatedResult) => {
                    runInAction(() => {
                        if (response) {
                            console.log("submitted");
                            this.handleCancel(response.rams.projectId);
                        }
                    });
                },
                this.areMyModelsValid,
                "There was an error trying to add the RAMS Review",
            )
            .finally(() => this.setIsLoading(false));
    };

    // #region Snackbar

    @observable
    public snackbarState = false;

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public snackMessage = "";

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @observable
    public snackType = "";

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    @observable
    public SNACKSUCCESS = "success";

    @observable
    public SNACKERROR = "error";
    // #endregion

    @computed
    public get isFormDisabled(): boolean {
        return this.IsLoading;
    }

    @computed
    public get signatureAddedDate(): string {
        return this.RAMSViewModel.model.lastUpdatedDate
            ? moment(this.RAMSViewModel.model.lastUpdatedDate).format("DD/MM/YYYY @ h:mm a")
            : moment(new Date()).format("DD/MM/YYYY @ h:mm a");
    }

    @action
    public setProjectId(projectId: any) {
        this.model.projectId = projectId;
        this.RAMSViewModel.model.projectId = projectId;
    }

    @computed
    public get reasonWhoPreparedDocument() {
        return this.RAMSChoiceViewModel.getValue("reasonWhoPreparedDocument");
    }

    @action
    public handleReviewByUserName = (item: { id: string; displayName: string }) => {
        this.RAMSViewModel.model.reviewedByUserId = item.id;
        this.ReviewBy = item;
    };

    @action
    public handleRadioChange = (value: string, fieldName: string) => {
        this.RAMSChoiceViewModel.model[fieldName] = value;
    };

    private areMyModelsValid = async (): Promise<boolean> => {
        let isValid = true;

        let isValidRams = true;
        let isValidRamsChoices = true;
        let isValidAttachments = true;
        this.showValidationError = false;
        isValidRams = await this.RAMSViewModel.isModelValid();
        isValidRamsChoices = await this.RAMSChoiceViewModel.isModelValid();
        isValidAttachments = await this.isModelValid();
        if (isValidRams === false || isValidRamsChoices === false || isValidAttachments === false) {
            isValid = false;
        }

        return isValid;
    };

    @action
    public reset = () => {
        this.RAMSViewModel.resetModel();
        this.RAMSChoiceViewModel.resetModel();
        this.model.rAMSAttachments = [];
    };

    @action
    public handleCancel(projectId: string | null): void {
        this.reset();
        this.history.push(AppUrls.Client.Project.Construction.replace(":projectid", projectId ? projectId : this.model.projectId) + "#ramsReview");
    }

    /**
     * Handle a file being selected and process the data for upload.
     * @param event
     */
    @action
    public fileChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        if (event.target.files !== null && event.target.value !== null && event.target.files.length > 0) {
            let data: any = {
                fileName: event.target.files[0].name,
                formFile: event.target.files[0],
            };
            event.target.value = "";
            const apiResult = await this.fileUpload(data);
            if (apiResult && apiResult.wasSuccessful) {
                let fileToDisplay: RAMSAttachment = {
                    id: null,
                    rAMSId: null,
                    fileURL: apiResult.payload,
                    fileName: data.fileName,
                    isDeleted: false,
                    createdByUserId: null,
                    // createdDate: null,
                };

                // let model: RAMSAttachment = new GenericPhotoListItemModel();
                // model.fromDto(fileToDisplay);
                runInAction(() => this.model.rAMSAttachments.push(fileToDisplay));
            }
        }
    };

    /**
     * Upload a file to azure.
     * @param data The data of the file to be uploaded.
     * @returns apiResult.
     */
    public fileUpload = async (data: any): Promise<ApiResult<any>> => {
        const formData = new FormData();
        formData.append("formFile", data.formFile);
        formData.append("fileName", data.fileName);
        const apiResult = await this.Post<any>(AppUrls.Server.File.UploadFile, formData);
        if (apiResult) {
            if (!apiResult.wasSuccessful) {
                console.log(apiResult.errors);
                runInAction(() => {
                    this.setSnackMessage("Error uploading file please try again.");
                    this.setSnackType(this.SNACKERROR);
                    this.setSnackbarState(true);
                });
            }
        }
        return apiResult;
    };

    /**
     * Download a file that exists in azure.
     * @param fileUrl The URL of the file to be downloaded.
     * @param fileName The name of the file to be downloaded.
     */
    public DownloadFile = async (fileUrl: string, fileName: string): Promise<void> => {
        try {
            const apiResult = await this.Post<Blob>(AppUrls.Server.File.DownloadFile, fileUrl, undefined, { responseType: "blob" });
            const response = apiResult as any;
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
        } catch (exception) {
            console.error(exception);
            this.setIsErrored(true);
        }
    };

    @action
    public deleteRAMSReviewPhotoFile = async (index: number): Promise<void> => {
        this.model.rAMSAttachments[index].isDeleted = true;
    };

    public async isFieldValid(fieldName: keyof FieldType<RAMSReviewFormModel>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}

export interface RAMSRelatedResultDTO {
    supervisorUsers: RAMSUserDTO[];
    reviewedByUsers: RAMSUserDTO[];
    ramsProjectDetails: IRAMSProjectDetailsDTO;
}

export interface RAMSUserDTO {
    id: string;
    displayName: string;
}

export interface UpsertRAMSAndRelated {
    rAMS: RAMS;
    rAMSChoice: RAMSChoice;
    rAMSAttachments: RAMSAttachment[];
}

export interface RAMS {
    projectId: string;
    fileName: string;
    fileURL: string;
    title: string;
    subContractor: string;
    supervisorUserName: string | null;
    estimatedStartDate: string;
    reviewedByUserId: string;
    authorisationSignatureURL: string;
    authorisationSignatureDate: string | null;
    createdByUserId: string | null;
    lastUpdatedDate: string | null;
    lastUpdatedByUserId: string | null;
    completedDate: string | null;
    completedByUserId: string | null;
    isDeleted: boolean;
    rowVersion: string | null;
}

export interface RAMSChoice {
    rAMSId: string | null;
    hasPassedScopeOfWorks: boolean;
    reasonScopeOfWorks: string;
    hasPassedWhoPreparedDocument: boolean;
    reasonWhoPreparedDocument: string;
    hasPassedMethodStatement: boolean;
    reasonMethodStatement: string;
    hasPassedYoungPersonsRA: boolean;
    reasonYoungPersonsRA: string;
    hasPassedRequiredPPE: boolean;
    reasonRequiredPPE: string;
    hasPassedLocationOfWorks: boolean;
    reasonLocationOfWorks: string;
    hasPassedSequenceOfWorks: boolean;
    reasonSequenceOfWorks: string;
    hasPassedProgrammeOfWorks: boolean;
    reasonProgrammeOfWorks: string;
    hasPassedSitePersonnel: boolean;
    reasonSitePersonnel: string;
    hasPassedMaterialsInvolved: boolean;
    reasonMaterialsInvolved: string;
    hasPassedHAVsAssessments: boolean;
    reasonHAVsAssessments: string;
    hasPassedCOSHHAssessment: boolean;
    reasonCOSHHAssessment: string;
    hasPassedRiskAssessment: boolean;
    reasonRiskAssessment: string;
    hasPassedManualHandlingAssessment: boolean;
    reasonManualHandlingAssessment: string;
    hasPassedPlantAndEquipmentRequired: boolean;
    reasonPlantAndEquipmentRequired: string;
    hasPassedWorkingHours: boolean;
    reasonWorkingHours: string;
    hasPassedEmergencyRescuePlan: boolean;
    reasonEmergencyRescuePlan: string;
    hasPassedShoringPlan: boolean;
    reasonShoringPlan: string;
    isApprovedRAMSRecommended: boolean;
    reasonApprovedRAMSRecommended: string;
}

export interface RAMSAttachment {
    id: string | null;
    rAMSId: string | null;
    fileName: string;
    fileURL: string;
    createdByUserId: string | null;
    isDeleted: boolean;
}
