import { FieldType, ViewModelBase } from "@shoothill/core";
import { action } from "mobx";
import { AdminSiteAccessFilterModel } from "./AdminSiteAccessFilterModel";

export class AdminSiteAccessFilterViewModel extends ViewModelBase<AdminSiteAccessFilterModel> {
    private static _intsance: AdminSiteAccessFilterViewModel;
    public static get Instance() {
        return this._intsance || (this._intsance = new this());
    }

    constructor() {
        super(new AdminSiteAccessFilterModel(), false);
        this.setDecorators(AdminSiteAccessFilterModel);
    }

    @action
    public setStartDateFilter = (val: Date | null) => {
        this.model.startDateFilter = val;
    };

    @action
    public setEndDateFilter = (val: Date | null) => {
        this.model.endDateFilter = val;
    };

    @action
    public resetStartDateFilter = () => {
        this.model.startDateFilter = null;
    };

    @action
    public resetEndDateFilter = () => {
        this.model.endDateFilter = null;
    };

    @action
    public resetDateFilters = () => {
        this.resetStartDateFilter();
        this.resetEndDateFilter();
    };

    public async isFieldValid(fieldName: keyof FieldType<AdminSiteAccessFilterModel>): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
