import { action, observable, computed } from "mobx";
import { ModelBase } from "@shoothill/core";

export class InvoicePurchaseOrderItemModel extends ModelBase<InvoicePurchaseOrderItemModel, any> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Constants and Defaults

    public static readonly DEFAULT_ID = null;
    public static readonly DEFAULT_REQUISITIONREQUESTITEMID = "";
    public static readonly DEFAULT_REQUISITIONREQUESTITEMGROUPID = "";
    public static readonly DEFAULT_REQUISITIONREQUESTID = "";
    public static readonly DEFAULT_INVOICEPURCHASEORDERID = "";
    public static readonly DEFAULT_ITEMDESCRIPTION = "";
    public static readonly DEFAULT_INITIALVALUE = "";
    public static readonly DEFAULT_ALREADYALLOCATED = "";
    public static readonly DEFAULT_AVAILABLEBALANCE = "";
    public static readonly DEFAULT_AMOUNTALLOCATED = null;
    public static readonly DEFAULT_ISSUBMITTED = false;

    // #endregion Constants and Defaults

    // #region Properties

    @observable
    public id: string | null = InvoicePurchaseOrderItemModel.DEFAULT_ID;

    @observable
    public requisitionRequestItemId: string = InvoicePurchaseOrderItemModel.DEFAULT_REQUISITIONREQUESTITEMID;

    @observable
    public requisitionRequestItemGroupId: string = InvoicePurchaseOrderItemModel.DEFAULT_REQUISITIONREQUESTITEMGROUPID;

    @observable
    public requisitionRequestId: string = InvoicePurchaseOrderItemModel.DEFAULT_REQUISITIONREQUESTID;

    @observable
    public invoicePurchaseOrderId: string = InvoicePurchaseOrderItemModel.DEFAULT_INVOICEPURCHASEORDERID;

    @observable
    public itemDescription: string = InvoicePurchaseOrderItemModel.DEFAULT_ITEMDESCRIPTION;

    @observable
    public initialValue: string = InvoicePurchaseOrderItemModel.DEFAULT_INITIALVALUE;

    @observable
    public alreadyAllocated: string = InvoicePurchaseOrderItemModel.DEFAULT_ALREADYALLOCATED;

    @observable
    public availableBalance: string = InvoicePurchaseOrderItemModel.DEFAULT_AVAILABLEBALANCE;

    @observable
    public amountAllocated: number | null = InvoicePurchaseOrderItemModel.DEFAULT_AMOUNTALLOCATED;

    @observable
    public isSubmitted: boolean = InvoicePurchaseOrderItemModel.DEFAULT_ISSUBMITTED;

    @observable
    public invoicePurchaseOrderItemsAllocated: InvoicePurchaseOrderItemsAllocatedDTO[] = [];

    // #endregion Properties

    // #region Actions

    @action
    public reset = () => {
        this.id = InvoicePurchaseOrderItemModel.DEFAULT_ID;
        this.requisitionRequestItemId = InvoicePurchaseOrderItemModel.DEFAULT_REQUISITIONREQUESTITEMID;
        this.requisitionRequestItemGroupId = InvoicePurchaseOrderItemModel.DEFAULT_REQUISITIONREQUESTITEMGROUPID;
        this.itemDescription = InvoicePurchaseOrderItemModel.DEFAULT_ITEMDESCRIPTION;
        this.initialValue = InvoicePurchaseOrderItemModel.DEFAULT_INITIALVALUE;
        this.alreadyAllocated = InvoicePurchaseOrderItemModel.DEFAULT_ALREADYALLOCATED;
        this.availableBalance = InvoicePurchaseOrderItemModel.DEFAULT_AVAILABLEBALANCE;
        this.amountAllocated = InvoicePurchaseOrderItemModel.DEFAULT_AMOUNTALLOCATED;
        this.isSubmitted = InvoicePurchaseOrderItemModel.DEFAULT_ISSUBMITTED;
        this.invoicePurchaseOrderItemsAllocated = [];
    };

    @action
    public fromDto(dto: InvoicePurchaseOrderItemDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself

        for (let key in dto) {
            if (dto.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(dto[key]);
                } else {
                    this[key] = dto[key];
                }
            }
        }
    }

    public toDto(): UpsertInvoicePurchaseOrderItemDTO {
        const dto: UpsertInvoicePurchaseOrderItemDTO = {
            id: this.id,
            requisitionRequestItemId: this.requisitionRequestItemId,
            invoicePurchaseOrderId: this.invoicePurchaseOrderId,
            amountAllocated: this.amountAllocated !== null ? this.amountAllocated : 0,
            isSubmitted: true,
        };

        return dto;
    }

    // #endregion Actions

    // #region Custom Validation

    // #endregion Custom Validation
}

export interface UpsertInvoicePurchaseOrderItemDTO {
    id: string | null;
    requisitionRequestItemId: string | null;
    invoicePurchaseOrderId: string | null;
    amountAllocated: number | null;
    isSubmitted: boolean;
}

export interface InvoicePurchaseOrderItemDTO {
    id: string | null;
    requisitionRequestItemId: string | null;
    requisitionRequestItemGroupId: string | null;
    requisitionRequestId: string | null;
    invoicePurchaseOrderId: string | null;
    itemDescription: string | null;
    invoiceValue: string | null;
    alreadyAllocated: string | null;
    availableBalance: string | null;
    amountAllocated: number | null;
    isSubmitted: boolean;
    invoicePurchaseOrderItemsAllocated: InvoicePurchaseOrderItemsAllocatedDTO[];
}

export interface InvoicePurchaseOrderItemsAllocatedDTO {
    id: string;
    alreadyAllocated: number | null;
    invoicePurchaseOrderId: string;
    invoiceId: string;
    invoiceNumber: string;
    requisitionRequestItemId: string;
}
