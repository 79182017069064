import { action, observable } from "mobx";
import { DarwinModelBaseDTO } from "Globals/Models/Domain";
import { ModelBase } from "@shoothill/core";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class DesignDataModel extends ModelBase<DesignDataModel, DesignDataModelDTO> {
    // DarwinModelBase
    public id: string = "";
    public createdDate: string = "";

    @observable
    public projectId: string = "";

    //fromDto is required but you can leave it blank
    fromDto(model: DesignDataModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: DesignDataModel): void {}

    @action public clearModel() {
        this.id = "";
        this.createdDate = "";
    }
}

export interface DesignDataModelDTO extends DarwinModelBaseDTO {
    projectId: string;
}
