import React, { useState } from "react";
import { useObserver } from "mobx-react-lite";
import { TextField } from "@material-ui/core";
import { BaseModal } from "Components/Modal/BaseModal";
import { CancelButton } from "Globals/Styles/Control/Buttons";
import { Button } from "@material-ui/core";
import { Cell, Grid, useRouter } from "@shoothill/core";
import { DarwinDateSelect } from "Components/DateSelect/DarwinDateSelect";
import { AddEditResponseViewModel } from "./AddEditResponseViewModel";
import { ReportModalStyle } from "Views/Project/Modals/Modal.Styles";

interface ICreatedModalProps {
    open?: boolean;
    onClose(): void;
    designDataId: string | null;
    id: string | null;
    isdarwinrdd: string | null;
}

export const AddEditResponseView: React.FC<ICreatedModalProps> = (props) => {
    const { match } = useRouter();
    const { projectid } = match.params as any;
    const { open, onClose, designDataId, id, isdarwinrdd } = props;
    const [viewModel] = useState(() => new AddEditResponseViewModel(projectid, designDataId, id, isdarwinrdd));
    const COLUMNS8 = "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr";

    const onSave = () => {
        let promise = viewModel.upsert();
        promise.then((response) => {
            if (response.wasSuccessful) {
                onClose();
            }
        });
    };

    return useObserver(() => (
        <BaseModal
            open={open}
            onClose={onClose}
            title={viewModel.hasId ? "Edit response" : "Add response"}
            PaperComponent={ReportModalStyle}
            actions={
                <>
                    <Button onClick={onSave} color="secondary" autoFocus variant="contained">
                        {viewModel.hasId ? "Update" : "Add"}
                    </Button>
                    <CancelButton onClick={onClose}>Cancel</CancelButton>
                </>
            }
        >
            <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8} mb="35px">
                <Cell ts={8} ds={8}>
                    <TextField
                        id="note-textarea"
                        multiline
                        minRows={5}
                        value={viewModel.getValue("comment")}
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => viewModel.setValue<string>("comment", event.target.value)}
                        fullWidth
                        className="textarea-forminput"
                        label="Response:"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        placeholder={`Please add`}
                        disabled={false}
                        error={viewModel.getError("comment") !== ""}
                        helperText={viewModel.getError("comment")}
                        onBlur={() => viewModel.isFieldValid("comment")}
                    />
                </Cell>
                <Cell ts={2} ds={2}>
                    <DarwinDateSelect
                        displayName="Date received:"
                        execute={(value: string | null) => viewModel.setReceivedDate(value)}
                        onBlur={() => viewModel.isFieldValid("receivedDate")}
                        placeholder="Please select"
                        validationMessage={viewModel.getError("receivedDate")}
                        value={viewModel.getValue("receivedDate")}
                        canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                    />
                </Cell>
            </Grid>
        </BaseModal>
    ));
};
