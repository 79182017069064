import { action, computed, observable } from "mobx";
import { ModelBase } from "@shoothill/core";
import { validateTwoDecimalPlaces } from "Utils/Utils";

export class VariationFutureSpendAmendmentModel extends ModelBase<VariationFutureSpendAmendmentModel, VariationFutureSpendAmendmentUpsertResponseDTO> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Constants and Defaults

    public static readonly DEFAULT_ID = null;
    public static readonly DEFAULT_IEID = "";
    public static readonly DEFAULT_VARIATIONID = "";
    public static readonly DEFAULT_VARIATIONITEMID = "";
    public static readonly DEFAULT_FUTURESPEND = "";
    public static readonly DEFAULT_NOTE = "";
    public static readonly DEFAULT_ROWVERSION = null;

    // #endregion Constants and Defaults

    // #region Properties

    @observable
    public id: string | null = null;

    @observable
    public ieId: string = VariationFutureSpendAmendmentModel.DEFAULT_IEID;

    @observable
    public variationId: string = VariationFutureSpendAmendmentModel.DEFAULT_VARIATIONID;

    @observable
    public variationItemId: string = VariationFutureSpendAmendmentModel.DEFAULT_VARIATIONITEMID;

    @observable
    public futureSpend: string = VariationFutureSpendAmendmentModel.DEFAULT_FUTURESPEND;

    @observable
    public note: string = VariationFutureSpendAmendmentModel.DEFAULT_NOTE;

    @observable
    public rowVersion: string | null = VariationFutureSpendAmendmentModel.DEFAULT_ROWVERSION;

    // #endregion Properties

    // #region Actions

    @action
    public fromDto(dto: VariationFutureSpendAmendmentUpsertResponseDTO): void {
        this.id = dto.id;
        this.ieId = dto.ieId;
        this.variationId = dto.variationId;
        this.variationItemId = dto.variationItemId;
        this.futureSpend = dto.futureSpend;
        this.note = dto.note;
        this.rowVersion = dto.rowVersion;
    }

    public toDto(): VariationFutureSpendAmendmentUpsertRequestDTO {
        return {
            id: this.id,
            ieId: this.ieId,
            variationId: this.variationId,
            variationItemId: this.variationItemId,
            futureSpend: this.futureSpend,
            note: this.note,
            rowVersion: this.rowVersion,
        };
    }

    public reset(): void {
        this.id = null;
        this.futureSpend = VariationFutureSpendAmendmentModel.DEFAULT_FUTURESPEND;
        this.note = VariationFutureSpendAmendmentModel.DEFAULT_NOTE;
        this.rowVersion = VariationFutureSpendAmendmentModel.DEFAULT_ROWVERSION;
    }

    // #endregion Actions

    // #region Custom Validation

    @computed
    public get validateFutureSpend(): string {
        // RULES
        const futureSpendNumber: number = Number(this.futureSpend);
        if (this.futureSpend === VariationFutureSpendAmendmentModel.DEFAULT_FUTURESPEND || isNaN(futureSpendNumber)) {
            return "Please enter a future spend";
        } else if (!validateTwoDecimalPlaces(futureSpendNumber)) {
            return "No more than two decimal places";
        }

        return "";
    }

    @computed
    public get validateNote(): string {
        return this.note === VariationFutureSpendAmendmentModel.DEFAULT_NOTE ? "Please enter a note" : "";
    }

    // #endregion Custom Validation
}

export interface VariationFutureSpendAmendmentUpsertRequestDTO {
    id: string | null;
    ieId: string;
    variationId: string;
    variationItemId: string;
    futureSpend: string;
    note: string;
    rowVersion: string | null;
}

export interface VariationFutureSpendAmendmentUpsertResponseDTO {
    id: string;
    ieId: string;
    variationId: string;
    variationItemId: string;
    futureSpend: string;
    note: string;
    rowVersion: string | null;
}

export interface VariationFutureSpendAmendmentAndRelatedResponseDTO {
    categoryName: string;
    subCategoryName: string;
    lineDescriptionName: string;
    ieTitle: string;
    variationFutureSpendAmendmentItems: VariationFutureSpendAmendmentDTO[];
    matchingUnapprovedRequisitions: { id: string; name: string; requestedByUserName: string }[];
}

export interface VariationFutureSpendAmendmentDTO {
    id: string;
    isPO: boolean;
    isAmendment: boolean;
    futureSpendType: string;
    poNumber: number;
    formattedPONumber: string;
    revision: string;
    poId: string;
    note: string;
    orderValue: number;
    orderValueDifference: number;
    previousFutureSpend: number;
    futureSpend: number;
    projectImpact: number;
    isDeleted: boolean;
    amendmentCreatedByUserName: string;
    poApprovedByUserName: string;
    createdDate: string;
}

//export interface IncomeAndExpenditureAndRelatedResponseDTO extends IncomeAndExpenditureUpsertResponseDTO, IncomeAndExpenditureRelatedResponseDTO {}
