import styled from "styled-components";
import { BaseModalDialogPaper } from "./BaseModal.styles";

export const GenericWarningModalDialogPaper = styled(BaseModalDialogPaper)`
    min-width: 420px;

    .MuiDialogContent-root {
        p {
            padding-bottom: 40px;
            font-size: 14px;
        }
    }
`;
