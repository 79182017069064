import { EnumType, IEnumType } from "./Base/EnumType";

export class PlanOfWorkStage extends EnumType {
    // #region Constants

    public static readonly Placeholder = "PLACEHOLDER";

    // #endregion Constants

    // #region Properties
    // #endregion Properties

    // #region Methods

    public fromDto(dto: IPlanOfWorkStage) {
        super.fromDto(dto);
    }

    // #endregion Methods
}

export interface IPlanOfWorkStage extends IEnumType {}
