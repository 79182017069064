// Libraries
import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { useRouter } from "@shoothill/core";
import { runInAction } from "mobx";

// Custom
import { DetailsHeader } from "Globals/Views/DetailsPage/DetailsHeader";
import { ProjectGeneralViewModel } from "../../General/ProjectGeneralViewModel";
import { RFITable } from "./RFITable";

// Styling & Images
import { SHBox } from "../../../../Components/Box";
import { DetailsPage } from "Globals/Views/DetailsPage/DetailsPage.styles";
import { RFIViewModel } from "./RFIViewModel";

export const RFIListView: React.FunctionComponent = () => {
    const router = useRouter();
    let { projectid } = router.match.params as any;
    const [generalViewModel] = useState(() => ProjectGeneralViewModel.Instance);
    const [viewModel] = useState(new RFIViewModel());

    useEffect(() => {
        runInAction(async () => {
            await viewModel.loadRFIs(projectid);
        });
        return () => {
            viewModel.clean();
        };
    }, []);

    const renderPage = () => {
        return (
            <DetailsPage className="cell-right rcselect">
                <DetailsHeader viewModel={generalViewModel.getHeader} />
                <SHBox style={{ padding: "5px 30px 20px 30px" }}>
                    {viewModel.hasLoaded && (
                        <RFITable
                            data={viewModel.getRFIItems}
                            isLoading={viewModel.IsLoading}
                            handleSearchChange={(searchText: string) => viewModel.handleSearchChange(projectid, searchText)}
                            projectId={projectid}
                            viewModel={viewModel}
                        />
                    )}
                </SHBox>
            </DetailsPage>
        );
    };

    return useObserver(() => renderPage());
};
