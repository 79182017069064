import { action, computed, observable } from "mobx";
import { ModelBase } from "@shoothill/core";

/**
 * The form input to be used. Textarea, radio buttons etc.
 */
export enum PermitQuestionFormTypeEnum {
    RadioButtons = 10,
    TextInput = 20,
    Dropdown = 30,
    DateTime = 40,
    Checkbox = 50,
}

/**
 * The type of validation to be performed against the form input.
 */
export enum PermitQuestionValidationTypeEnum {
    None = 10,
    FreeText = 20,
    Number = 30,
    Dropdown = 40,
    DateTime = 50,
}

export class PermitQuestionAnswerModel extends ModelBase<PermitQuestionAnswerModel, any> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // // #region Constants and Defaults

    public static readonly DEFAULT_ID: string = "";
    public static readonly DEFAULT_QUESTION: string = "";
    public static readonly DEFAULT_ORDINAL: number = 0;
    public static readonly DEFAULT_FORM_TYPE: PermitQuestionFormTypeEnum = PermitQuestionFormTypeEnum.TextInput;
    public static readonly DEFAULT_VALIDATION_TYPE: PermitQuestionValidationTypeEnum = PermitQuestionValidationTypeEnum.None;
    public static readonly DEFAULT_IS_REQUIRED: boolean = false;
    public static readonly DEFAULT_IS_ADDITIONAL_QUESTION: boolean = false;
    public static readonly DEFAULT_PERMIT_ANSWER_ID: string | null = null;
    public static readonly DEFAULT_PERMIT_ID: string | null = null;
    public static readonly DEFAULT_ANSWER_TEXT: string | null = null;
    public static readonly DEFAULT_ANSWER_YES_NO: boolean | null = null;
    public static readonly DEFAULT_ANSWER_RADIO: string | null = null;
    public static readonly DEFAULT_ANSWER_CHECKBOX: boolean | undefined = undefined;

    @observable
    public id: string = PermitQuestionAnswerModel.DEFAULT_ID;

    @observable
    public question: string = PermitQuestionAnswerModel.DEFAULT_QUESTION;

    @observable
    public ordinal: number = PermitQuestionAnswerModel.DEFAULT_ORDINAL;

    @observable
    public formType: PermitQuestionFormTypeEnum = PermitQuestionAnswerModel.DEFAULT_FORM_TYPE;

    @observable
    public validationType: PermitQuestionValidationTypeEnum = PermitQuestionAnswerModel.DEFAULT_VALIDATION_TYPE;

    @observable
    public isRequired: boolean = PermitQuestionAnswerModel.DEFAULT_IS_REQUIRED;

    @observable
    public isAdditionalQuestion: boolean = PermitQuestionAnswerModel.DEFAULT_IS_ADDITIONAL_QUESTION;

    @observable
    public permitAnswerId: string | null = PermitQuestionAnswerModel.DEFAULT_PERMIT_ANSWER_ID;

    @observable
    public permitId: string | null = PermitQuestionAnswerModel.DEFAULT_PERMIT_ID;

    @observable
    public answerText: string | null = PermitQuestionAnswerModel.DEFAULT_ANSWER_TEXT;

    @observable
    public answerYesNo: boolean | null = PermitQuestionAnswerModel.DEFAULT_ANSWER_YES_NO;

    @observable
    public answerRadio: string | null = PermitQuestionAnswerModel.DEFAULT_ANSWER_RADIO;

    @observable
    public answerCheckbox: boolean | undefined = PermitQuestionAnswerModel.DEFAULT_ANSWER_CHECKBOX;

    @action
    public reset = () => {
        this.id = PermitQuestionAnswerModel.DEFAULT_ID;
        this.question = PermitQuestionAnswerModel.DEFAULT_QUESTION;
        this.ordinal = PermitQuestionAnswerModel.DEFAULT_ORDINAL;
        this.formType = PermitQuestionAnswerModel.DEFAULT_FORM_TYPE;
        this.validationType = PermitQuestionAnswerModel.DEFAULT_VALIDATION_TYPE;
        this.isRequired = PermitQuestionAnswerModel.DEFAULT_IS_REQUIRED;
        this.isAdditionalQuestion = PermitQuestionAnswerModel.DEFAULT_IS_ADDITIONAL_QUESTION;
        this.permitAnswerId = PermitQuestionAnswerModel.DEFAULT_PERMIT_ANSWER_ID;
        this.permitId = PermitQuestionAnswerModel.DEFAULT_PERMIT_ID;
        this.answerText = PermitQuestionAnswerModel.DEFAULT_ANSWER_TEXT;
        this.answerYesNo = PermitQuestionAnswerModel.DEFAULT_ANSWER_YES_NO;
        this.answerRadio = PermitQuestionAnswerModel.DEFAULT_ANSWER_RADIO;
        this.answerCheckbox = PermitQuestionAnswerModel.DEFAULT_ANSWER_CHECKBOX;
    };

    @action
    fromDto(dto: PermitQuestionAnswerDTO): void {
        for (let key in dto) {
            if (dto.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(dto[key]);
                } else if (this[key] === "answerText") {
                    if (dto[key] === null || dto[key] === undefined) {
                        this[key] = "";
                    } else {
                        this[key] = dto[key];
                    }
                } else if (this[key] === "answerCheckbox") {
                    if (dto[key] === null || dto[key] === undefined) {
                        this[key] = undefined;
                    } else {
                        this[key] = dto[key];
                    }
                } else {
                    this[key] = dto[key];
                }
            }
        }
    }

    toDto() {}

    // #endregion Actions

    // #region Custom Validation

    @computed
    public get validateAnswerText(): string {
        if (this.isRequired) {
            if (this.answerText === PermitQuestionAnswerModel.DEFAULT_ANSWER_TEXT) {
                return "Please provide a response";
            }
        }

        switch (this.validationType) {
            case PermitQuestionValidationTypeEnum.Number:
                if (isNaN(Number(this.answerText))) {
                    return "Response must be a number";
                }
                return "";
            case PermitQuestionValidationTypeEnum.None:
            case PermitQuestionValidationTypeEnum.FreeText:
                return "";
            default:
                return "";
        }
    }

    @computed
    public get validateAnswerRadio(): string {
        if (this.answerRadio === PermitQuestionAnswerModel.DEFAULT_ANSWER_RADIO && this.isRequired) {
            return "Please provide a response";
        }

        return "";
    }

    // #endregion Custom Validation
}

export interface PermitQuestionAnswerDTO {
    // Question data
    id: string | null;
    question: string;
    ordinal: number;
    formType: PermitQuestionFormTypeEnum;
    validationType: PermitQuestionValidationTypeEnum;
    isRequired: boolean;
    isAdditionalQuestion: boolean;
    // Answer data
    permitAnswerId: string | null;
    permitId: string | null;
    answerText: string | null;
    answerYesNo: boolean | null;
    answerRadio: string | null;
    answerCheckbox: boolean | null;
}
