import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import * as Defaults from "Globals/Defaults/TableOptions";
import styled from "styled-components";
import { SHBox } from "Components/Box";
import { DarwinSelect } from "Components/AutoComplete/DarwinSelect";
import { PrimaryButton } from "Components/Buttons/Buttons";
import { DarwinTablePageContent } from "Globals/Styles/AppStyling";
import MaterialTable from "material-table";
import { formatDate, formatIncidentNumber, formatTime } from "Utils/Format";
import { PatchedPagination } from "Components/Table/PatchedPagination";
import { IncidentViewModel } from "./IncidentViewModel";
import { useRouter } from "@shoothill/core";
import { IncidentFormItemDTO } from "./IncidentModel";
import { Form, FormSection } from "Views/PurchaseOrder/Form/Views/Forms";
import { BackButtonHolder } from "Views/Project/Commercial/IETables/IEGrid.Styles";
import { CustomArrow } from "Views/Project/CustomComponents";
import { DashedDivider } from "Views/PurchaseOrder/Form/Views/Dividers";
import { Box } from "@material-ui/core";
import { FormHeader } from "Views/PurchaseOrder/Form/Views/FormHeaders";
import { AppUrls } from "AppUrls";
import { pxToRem, theme } from "Globals/Styles/AppTheme";

const Wrapper = styled.div`
    padding: 5px 30px;
`;

interface Props {
    className?: string;
}
export const IncidentViewBase: React.FC<Props> = observer((props: Props) => {
    const { match } = useRouter();
    const { projectid, incidentid } = match.params as any;
    const [viewModel] = useState(() => new IncidentViewModel(incidentid));
    const { history } = useRouter();

    useEffect(() => {
        viewModel.projectId = projectid;
        return () => {
            //viewModel.reset();
        };
    }, []);

    const renderIncidentView = (
        <Form className={props.className} onSubmit={() => {}}>
            <BackButtonHolder
                onClick={() => {
                    history.push(AppUrls.Client.Project.ConstructionHAndS.replace(":projectid", projectid ? projectid : viewModel.model.projectId) + "#incidents");
                }}
                style={{ minHeight: "30px", paddingTop: "3px" }}
            >
                <div style={{ position: "relative", height: "30px", display: "flex", marginLeft: "10px" }}>
                    <div style={{ position: "absolute", top: "5px" }}>
                        <CustomArrow size={"8px"} color={theme.palette.blue.main} type={"left"} />
                    </div>
                    <div style={{ marginLeft: "20px", fontSize: "18px", fontWeight: "bold", color: theme.palette.blue.main }}>Back to incidents ({viewModel.getProjectTitle})</div>
                </div>
            </BackButtonHolder>

            <DashedDivider gutterBottom={false} gutterTop={false} borderThickness={2} borderOpacity={0.5} />

            <HeaderContainer>
                <FormHeader displayName={formatIncidentNumber(viewModel.model.incidentNumber)} />
            </HeaderContainer>

            <FormSection>
                <ProjectDetailsContainer>
                    <div>
                        <span>Date:</span> {viewModel.getTodayDateFormatted}
                    </div>
                    <div>
                        <span>Project reference:</span> {viewModel.getProjectReferenceFormatted}
                    </div>
                    <div>
                        <span>Project name:</span> {viewModel.getProjectName}
                    </div>
                </ProjectDetailsContainer>
            </FormSection>

            <SHBox>
                <FormSection>
                    <SHBox grid dc={"300px 100px"} alignItems={"flex-end"}>
                        <DarwinSelect
                            displayName="Add Incident form"
                            execute={viewModel.setIncidentForm}
                            fullWidth={true}
                            getOptionLabel={(option: any) => option.displayName}
                            options={viewModel.incidentFormTypes}
                            placeholder="Please select type"
                            value={viewModel.incidentFormType}
                        />
                        <PrimaryButton canExecute={true} execute={viewModel.addIncidentForm} value={"Add"} />
                    </SHBox>
                </FormSection>
                <DarwinTablePageContent>
                    <MaterialTable
                        columns={[
                            { title: "Type of incident", field: "incidentFormTypeName" },
                            {
                                title: "Date / time added",
                                field: "createdDate",
                                render: (rowData: IncidentFormItemDTO) => (
                                    <SHBox>
                                        <SHBox>{rowData.createdDate ? `${formatDate(rowData.createdDate.toString())} @ ${formatTime(rowData.createdDate.toString())}` : ""}</SHBox>
                                    </SHBox>
                                ),
                            },
                            { title: "Location", field: "location" },
                            {
                                title: "Investigation report added",
                                field: "isInvestigationReportAdded",
                                render: (rowData: IncidentFormItemDTO) => (
                                    <SHBox>
                                        <SHBox>{rowData.isInvestigationReportAdded}</SHBox>
                                    </SHBox>
                                ),
                            },
                            {
                                title: "Last updated",
                                field: "lastUpdatedDate",
                                render: (rowData: IncidentFormItemDTO) => (
                                    <SHBox>
                                        <SHBox>
                                            {formatDate(rowData.lastUpdatedDate.toString())} @ {formatTime(rowData.lastUpdatedDate.toString())} by
                                        </SHBox>
                                        <SHBox>{`${rowData.lastUpdatedByUserName}`}</SHBox>
                                    </SHBox>
                                ),
                            },
                        ]}
                        components={{
                            Pagination: PatchedPagination,
                        }}
                        options={Defaults.GetDarwinTableOptions()}
                        data={viewModel.incidentItemList}
                        onRowClick={(e, rowData) => {
                            viewModel.handleRowClick(e, rowData!);
                        }}
                        title=""
                    />
                </DarwinTablePageContent>
            </SHBox>
        </Form>
    );

    return <>{renderIncidentView}</>;
});

export const IncidentView = styled(IncidentViewBase)`
    display: flex;
    flex: 1;
    flex-direction: column;

    form {
        position: relative;
    }
`;

const HeaderContainer = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 70px;

    // Header
    > div:nth-child(1) {
        margin: 4px 30px 13.5px 30px;
    }
`;

const ProjectDetailsContainer = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    max-width: 700px;

    > div {
        font-size: 12px;
    }

    > div > span {
        font-weight: bold;
    }
`;
