import { EnumType, IEnumType } from "./Base/EnumType";

export class PersonalProtectiveEquipment extends EnumType {
    // #region Constants

    public static readonly SafetyFootwear = "SAFETY_FOOTWEAR";
    public static readonly HiVisClothing = "HIVIS_CLOTHING";
    public static readonly HeadProtection = "HEAD_PROTECTION";
    public static readonly LongTrousers = "LONG_TROUSERS";
    public static readonly TopCoveringShoulders = "TOP_COVERING_SHOULDERS";

    // #endregion Constants

    // #region Properties

    public statement: string = "";

    // #endregion Properties

    // #region Methods

    public fromDto(dto: IPersonalProtectiveEquipment) {
        super.fromDto(dto);

        this.statement = dto.statement;
    }

    // #endregion Methods
}

export interface IPersonalProtectiveEquipment extends IEnumType {
    statement: string;
}
