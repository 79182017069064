// Libraries
import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { isNullOrUndefined, KeyValuePair, useRouter } from "@shoothill/core";
import { useHistory } from "react-router-dom";

// Custom
import { DetailsHeader } from "Globals/Views/DetailsPage/DetailsHeader";
import { ProjectGeneralViewModel } from "../General/ProjectGeneralViewModel";
import { AddressModel } from "Globals/Models/Domain";
import { ScaffoldingListView } from "./Scaffolding/ScaffoldingListView";

// Styling & Images
import "../Construction/Construction.styles";
import { DetailsPage, DetailsTabs } from "Globals/Views/DetailsPage/DetailsPage.styles";
import { ContractCell, ContractRow } from "../Contractual/Contractual.styles";
import { Typography, Switch, FormControlLabel, Box, Grid } from "@material-ui/core";
import { ProjectConstructionViewModel } from "./ProjectConstructionViewModel";
import { ProjectSystemTypeHelpers } from "Globals/Models/Domain";
import { DetailsGroup } from "../DetailsGroup";
import { AddressItem, OverviewContainer, StyledListBase, ProjectEditBox } from "../Project.styles";
import { styled } from "@material-ui/styles";
import { ContactViewDetails, ContactViewRow, ContactViewTitle, ContactViewWrapper, EmailCol } from "Views/Contacts/ContactView.styles";
import EmailLogo from "Content/Email.svg";
import { CustomHr } from "../CustomComponents";
import { ITab, Tabs } from "../../../Components/Tabs/Tabs";
import { useHasRoutedTabs } from "../../../Utils/UseHasRoutedTabs";
import { InspectionsView } from "./Inspections/InspectionsView";
import { PermitsView } from "./Permit/PermitsView";
import { StoresInstance } from "Globals/Stores";
import { AuditListView } from "./Audits/AuditListView";
import { IncidentsView } from "./Incidents/IncidentsView";
import { ToolboxTalksView } from "./Toolbox/ToolboxTalksView";
import { DarwinDateSelect } from "../../../Components/DateSelect/DarwinDateSelect";
import { SHBox } from "../../../Components/Box";
import { LeftArrowCircle } from "../../../Content/LeftArrowCircle";
import { RightArrowCircle } from "../../../Content/RightArrowCircle";
import { NonConformanceView } from "./NonConformance/NonConformanceView";
import { DailyDiaryView } from "./DailyDiary/DailyDiaryView";
import { Dilapidation } from "./Dilapidation/DilapidationView";
import { RAMSReview } from "./RAMSReview/RAMSReviewView";
import { AppUrls } from "AppUrls";
import { BreadcrumbViewModel } from "Views/Breadcrumb/BreadcrumbViewModel";
import { ProgrammeUpdatesView } from "./ProgrammeUpdates/ProgrammeUpdatesView";

const MaterialUISwitch = styled(Switch)(() => ({
    width: 184,
    height: 40,
    padding: 7,
    "& .MuiSwitch-switchBase": {
        marginTop: 6,
        padding: 0,
        transform: "translateX(7px)",
        "&.Mui-checked": {
            color: "#fff",
            transform: "translateX(87px)",
            "& .MuiSwitch-thumb:before": {},
            "& + .MuiSwitch-track": {
                opacity: 1,
            },
        },
    },
    "& .MuiSwitch-thumb": {
        width: 90,
        height: 26,
        borderRadius: 50,
        background: "#999",
        border: "1px solid #fff",
        textAlign: "center",
        marginTop: 1,
        "&:before": {
            content: "'Edit Mode'",
            position: "absolute",
            width: "100%",
            height: "100%",
            left: 0,
            top: 0,
            backgroundRepeat: "no-repeat",
            fontSize: 12,
            color: "#fff",
            whiteSpace: "nowrap",
            fontWeight: 500,
            lineHeight: "26px",
        },
    },
    "& .MuiSwitch-track": {
        opacity: 1,
        background: "#425b66",
        borderRadius: 50,
        display: "flex",
    },
    "& .MuiSwitch-track:after": {
        content: "'Off'",
        fontSize: 12,
        color: "#fff",
        whiteSpace: "nowrap",
        fontWeight: 500,
        lineHeight: "26px",
        width: 90,
        display: "inline-block",
        textAlign: "center",
    },
    "& .MuiSwitch-track:before": {
        content: "'On'",
        fontSize: 12,
        color: "#fff",
        whiteSpace: "nowrap",
        fontWeight: 500,
        lineHeight: "26px",
        width: 90,
        display: "inline-block",
        textAlign: "center",
    },
}));

export const ConstructionView: React.FunctionComponent = () => {
    const router = useRouter();
    let { projectid } = router.match.params as any;

    const [viewModel] = useState(new ProjectConstructionViewModel());

    useEffect(() => {
        let { projectid } = router.match.params as any;
        viewModel.setProjectId(projectid);

        if (StoresInstance.Domain.AccountStore.isSiteManager) {
            const getGeneralData = async () => {
                await generalViewModel.apiGetbyId(projectid);
            };

            getGeneralData();
        }

        const getConstructionData = async () => {
            await viewModel.apiGetbyId(projectid).then(() => {
                setSystemTypes(ProjectSystemTypeHelpers.getAllKeyValueSelectOptions());
            });
        };

        getConstructionData();

        return () => {
            viewModel.clean();
        };
    }, []);

    const TAB_DETAILS = "details";
    const TAB_DAILYDIARY = "dailyDiary";
    const TAB_PROGRAMMEUPDATES = "programmeUpdates";
    const TAB_TOOLBOX = "toolbox";
    const TAB_PERMITS = "permits";
    const TAB_AUDITS = "audits";
    const TAB_INPECTIONS = "inspections";
    const TAB_INCIDENTS = "incidents";
    const TAB_SCAFFOLDING = "scaffolding";
    const TAB_NONCONFORMANCE = "nonconformance";
    const TAB_DILAPIDATION = "dilapidation";
    const TAB_RAMSREVIEW = "ramsReview";

    const tabOptions: ITab[] = [
        { key: TAB_DETAILS, title: "Details", hash: "details" },
        { key: TAB_DAILYDIARY, title: "Daily Diary", hash: "dailyDiary" },
        { key: TAB_PROGRAMMEUPDATES, title: "Programme updates", hash: "programmeUpdates" },
    ];

    const tabOptionsHAndS: ITab[] = [
        { key: TAB_AUDITS, title: "Audits", hash: "audits" },
        { key: TAB_INPECTIONS, title: "Inspections", hash: "inspections" },
        { key: TAB_PERMITS, title: "Permits", hash: "permits" },
        { key: TAB_SCAFFOLDING, title: "Scaffolding", hash: "scaffolding" },
        { key: TAB_TOOLBOX, title: "Toolbox talk record", hash: "toolbox" },
        { key: TAB_INCIDENTS, title: "Incidents", hash: "incidents" },
    ];

    const tabOptionsQuality: ITab[] = [
        { key: TAB_NONCONFORMANCE, title: "Non-conformance", hash: "nonconformance" },
        { key: TAB_DILAPIDATION, title: "Dilapidation", hash: "dilapidation" },
        { key: TAB_RAMSREVIEW, title: "RA/MS review", hash: "ramsReview" },
    ];

    const hashTab = useHasRoutedTabs([...tabOptions, ...tabOptionsHAndS, ...tabOptionsQuality]);

    const getTabOptions = (): ITab[] => {
        const projectId = viewModel.projectId && viewModel.projectId !== "" ? viewModel.projectId : projectid;
        const constructionPath = AppUrls.Client.Project.Construction.replace(":projectid", projectId);
        const constructionHAndSPath = AppUrls.Client.Project.ConstructionHAndS.replace(":projectid", projectId);
        const constructionPathQuality = AppUrls.Client.Project.ConstructionQuality.replace(":projectid", projectId);

        let options: ITab[] = tabOptions;

        switch (location.pathname) {
            case constructionPath:
                options = tabOptions;
                break;
            case constructionHAndSPath:
                options = tabOptionsHAndS;
                break;
            case constructionPathQuality:
                options = tabOptionsQuality;
                break;
            default:
                options = tabOptions;
                break;
        }

        return options;
    };

    const getSelectedTab = (): string => {
        const projectId = viewModel.projectId && viewModel.projectId !== "" ? viewModel.projectId : projectid;
        const constructionPath = AppUrls.Client.Project.Construction.replace(":projectid", projectId);
        const constructionHAndSPath = AppUrls.Client.Project.ConstructionHAndS.replace(":projectid", projectId);
        const constructionPathQuality = AppUrls.Client.Project.ConstructionQuality.replace(":projectid", projectId);

        let selectedTab: string = TAB_DETAILS;

        switch (location.pathname) {
            case constructionPath:
                selectedTab = hashTab?.key || TAB_DETAILS;
                break;
            case constructionHAndSPath:
                selectedTab = hashTab?.key || TAB_AUDITS;
                break;
            case constructionPathQuality:
                selectedTab = hashTab?.key || TAB_NONCONFORMANCE;
                break;
            default:
                selectedTab = hashTab?.key || TAB_DETAILS;
                break;
        }

        return selectedTab;
    };

    const [currentTabOptions, setCurrentTabOptions] = React.useState<ITab[]>(getTabOptions());

    const [generalViewModel] = useState(() => ProjectGeneralViewModel.Instance);
    const [systemTypes, setSystemTypes] = useState<KeyValuePair<any>[]>([]);
    const [tabKey, setTabKey] = React.useState(0);
    const [selectedTab, setSelectedTab] = React.useState(hashTab?.key || getSelectedTab());
    const [date, setDate] = React.useState(new Date());

    useEffect(() => {
        setCurrentTabOptions(getTabOptions());
        setSelectedTab(getSelectedTab());
    }, [router.history.location.pathname]);

    const handleTabClick = (tab: ITab) => {
        setSelectedTab(tab.key);
        setTabKey(tabKey + 1);
    };

    let systemTypeString: string = "";
    const item: KeyValuePair | undefined = systemTypes.find((a) => a.value === viewModel.model.systemTypeId.toString());
    if (item !== undefined) {
        systemTypeString = item.key;
    }
    const mailto: string = "mailto:" + (isNullOrUndefined(viewModel.model) === false ? viewModel.model.smContactEmail : "");

    const getTabKey = (): string => {
        return `clienthome-tab-${selectedTab}-${tabKey}`;
    };
    const previousDay = () => {
        setDate(new Date(date.setDate(date.getDate() - 1)));
    };
    const nextDay = () => {
        setDate(new Date(date.setDate(date.getDate() + 1)));
    };
    const renderAddresses = () => {
        return (
            <>
                <StyledListBase>
                    {viewModel.returnAddresses.map((address: AddressModel) => (
                        <AddressItem key={"id_" + address.id}>
                            {address.addressName}, {address.addressLine1}, {address.addressLine2}, {address.addressLine3}, {address.city}, {address.county}, {address.postcode} -
                            {address.deliveryContactName} - {address.deliveryContactNumber}
                            {/* {address.isPrimary && (
                                <div style={{ position: "absolute", right: "10px", top: "50%", transform: "translateY(-50%)" }}>
                                    <Typography style={{ fontSize: "12px", fontWeight: "bold", color: "#191919" }}>Main Address</Typography>
                                </div>
                            )} */}
                        </AddressItem>
                    ))}
                </StyledListBase>
            </>
        );
    };

    const renderTabContent = () => {
        switch (selectedTab) {
            case TAB_DETAILS:
                return (
                    <div key={getTabKey()}>
                        <ContractRow>
                            <Typography variant="h3" className="mb-2">
                                Detail
                            </Typography>
                        </ContractRow>
                        <ProjectEditBox>
                            <div className="row mb-0">
                                <ContractCell className="generalLabel">
                                    <DetailsGroup detailsType={"System Type:"} detailsData={systemTypeString} maxWidth="200px" />
                                </ContractCell>
                                <ContractCell className="generalLabel">
                                    <DetailsGroup detailsType={"No. modules:"} detailsData={viewModel.model.noModules} maxWidth="200px" />
                                </ContractCell>
                                <ContractCell className="generalLabel">
                                    <DetailsGroup detailsType={"G.I.F.A (Gross Internal Floor Area):"} detailsData={viewModel.model.gifa} maxWidth="250px" />
                                </ContractCell>
                            </div>
                        </ProjectEditBox>
                        <Grid item xs={12}>
                            <CustomHr margin={"10px 0"} padding={"0px 30px"} />
                        </Grid>
                        <ContractRow className="row">
                            <Typography variant="h3" className="mb-2">
                                Site
                            </Typography>
                        </ContractRow>
                        <div className="row mb-5 site-content-text">
                            <OverviewContainer>{renderAddresses()}</OverviewContainer>
                        </div>

                        <div className="employ-list mb-5">
                            <Typography variant="h3" className="mb-4">
                                Site manager
                            </Typography>
                            <ProjectEditBox>
                                <ContactViewWrapper>
                                    <ContactViewRow className="employ-list-item">
                                        <ContactViewTitle>Name :</ContactViewTitle>
                                        <ContactViewDetails>{viewModel.model.siteManagerName}</ContactViewDetails>
                                    </ContactViewRow>
                                    <ContactViewRow className="employ-list-item">
                                        <ContactViewTitle>Contact email:</ContactViewTitle>
                                        <ContactViewDetails>
                                            <Box>{viewModel.model.smContactEmail}</Box>
                                            {viewModel.model.id !== "" && viewModel.model.id !== null && viewModel.model.id !== undefined && (
                                                <EmailCol>
                                                    <a href={mailto}>
                                                        <img src={EmailLogo} alt="Email contact" className="email" />
                                                    </a>
                                                </EmailCol>
                                            )}
                                        </ContactViewDetails>
                                    </ContactViewRow>
                                    <ContactViewRow className="employ-list-item">
                                        <ContactViewTitle>Contact phone:</ContactViewTitle>
                                        <ContactViewDetails>{viewModel.model.smContactPhone}</ContactViewDetails>
                                    </ContactViewRow>
                                </ContactViewWrapper>
                            </ProjectEditBox>
                        </div>
                    </div>
                );
            case TAB_TOOLBOX:
                return (
                    <div key={getTabKey()}>
                        {viewModel.projectId !== "" && (
                            <ToolboxTalksView projectId={viewModel.projectId} projectReference={generalViewModel.model.reference} projectName={generalViewModel.model.name} />
                        )}
                    </div>
                );
            case TAB_DAILYDIARY:
                return <DailyDiaryView date={date} projectId={viewModel.projectId} />;
            case TAB_PROGRAMMEUPDATES:
                return <div key={getTabKey()}>{viewModel.projectId !== "" && <ProgrammeUpdatesView date={date} projectId={viewModel.projectId} />}</div>;
            case TAB_AUDITS:
                return <div key={getTabKey()}>{viewModel.projectId !== "" && <AuditListView date={date} projectId={viewModel.projectId} />}</div>;
            case TAB_SCAFFOLDING:
                return <ScaffoldingListView date={date} projectId={viewModel.projectId} projectName={generalViewModel.model.reference} projectRef={generalViewModel.model.name} />;
            case TAB_NONCONFORMANCE:
                return (
                    <div key={getTabKey()}>
                        {viewModel.projectId !== "" && (
                            <NonConformanceView projectId={viewModel.projectId} projectReference={generalViewModel.model.reference} projectName={generalViewModel.model.name} />
                        )}
                    </div>
                );
            case TAB_DILAPIDATION:
                return (
                    <div key={getTabKey()}>
                        {viewModel.projectId !== "" && (
                            <Dilapidation projectId={viewModel.projectId} projectReference={generalViewModel.model.reference} projectName={generalViewModel.model.name} />
                        )}
                    </div>
                );
            case TAB_INPECTIONS:
                return (
                    <div key={getTabKey()}>
                        <InspectionsView projectId={viewModel.projectId} projectReference={generalViewModel.model.reference} projectName={generalViewModel.model.name} />
                    </div>
                );
            case TAB_PERMITS:
                return (
                    <div key={getTabKey()}>
                        {viewModel.projectId !== "" && (
                            <PermitsView projectId={viewModel.projectId} projectReference={generalViewModel.model.reference} projectName={generalViewModel.model.name} />
                        )}
                    </div>
                );
            case TAB_INCIDENTS:
                return (
                    <div key={getTabKey()}>
                        {viewModel.projectId !== "" && (
                            <IncidentsView projectId={viewModel.projectId} projectReference={generalViewModel.model.reference} projectName={generalViewModel.model.name} />
                        )}
                    </div>
                );
            case TAB_RAMSREVIEW:
                return (
                    <div key={getTabKey()}>
                        {viewModel.projectId !== "" && (
                            <RAMSReview projectId={viewModel.projectId} projectReference={generalViewModel.model.reference} projectName={generalViewModel.model.name} />
                        )}
                    </div>
                );
        }
    };

    const renderPage = () => {
        return (
            <>
                <DetailsPage className="cell-right rcselect">
                    <ProjectEditBox className="generalHeaderBox">
                        <div>
                            <DetailsHeader viewModel={generalViewModel.getHeader} />
                        </div>
                        <div className="generalHeaderBox-btn">
                            <div>
                                <div className="w-full">
                                    {BreadcrumbViewModel.Instance.canAddProject && <FormControlLabel control={<MaterialUISwitch defaultChecked />} label="" />}
                                </div>
                            </div>
                            <div className="generalstatus generalstatus-small" style={{}}>
                                <DetailsGroup detailsData={generalViewModel.projectStatusTypeDisplayName} detailsType={""}></DetailsGroup>
                            </div>
                        </div>
                    </ProjectEditBox>
                    <SHBox p={4} grid dc={"50px 30px 200px 50px"} alignItems={"center"} style={{ gap: "10px" }}>
                        <Typography variant="h2">Date</Typography>
                        <LeftArrowCircle style={{ cursor: "pointer" }} onClick={previousDay} />
                        <DarwinDateSelect
                            execute={(value: string | null) => setDate(value ? new Date(value) : new Date())}
                            //onBlur={() => viewModel.isFieldValid("invoiceDate")}
                            placeholder="Please select"
                            //validationMessage={viewModel.getError("invoiceDate")}
                            value={date ? date.toISOString() : null}
                            //canExecute={!viewModel.isFormDisabled}
                        />
                        <RightArrowCircle style={{ cursor: "pointer" }} onClick={nextDay} />
                    </SHBox>
                    <DetailsTabs>
                        <Tabs tabs={currentTabOptions} onTabClick={handleTabClick} selected={selectedTab} minTabWidth={132} tabPadding="11.5px 8px" />
                        <div className="content">{renderTabContent()}</div>
                    </DetailsTabs>
                </DetailsPage>
            </>
        );
    };

    return useObserver(() => renderPage());
};
