import { isNullOrUndefined } from "@shoothill/core";
import { action, computed, observable } from "mobx";

export class TabItemViewModel {
    public constructor(id: string, title: string) {
        this.id = id;
        this.title = title;
    }

    @observable
    public id: string = "";

    @observable
    public title: string = "";

    @observable
    public isActive: boolean = false;

    @observable
    public count?: number;

    @action
    public setCount = (value?: number) => {
        this.count = value;
    };

    @computed
    public get canDisplayCount() {
        return !isNullOrUndefined(this.count) && this.count! > 0;
    }
}
