import React, { useEffect, useState } from "react";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { LandingView } from "./Landing/LandingView";
import { Route } from "react-router";
import { SHBox } from "../../../Components/Box";
import { SignedInView } from "./SignedIn/SignedInView";
import { AppUrls } from "../../../AppUrls";
import { AccessRoutesViewModel } from "./AccessRoutesViewModel";
import { RamsView } from "./Rams/RamsView";
import { InductionViewModel } from "../InductionViewModel";

interface Props {
    viewModel: InductionViewModel;
    parentViewModel: InductionViewModel;
}

export const AccessRoutesView: React.FC<Props> = observer((props: Props) => {
    const [viewModel] = useState(() => new AccessRoutesViewModel());

    useEffect(() => {
        runInAction(() => {
            props.viewModel.showHeaderBar = false;
        });
    }, []);

    return (
        <SHBox>
            <Route
                path={AppUrls.Client.Induction.Access.Landing}
                component={() => (
                    <LandingView
                        viewModel={viewModel.landingViewModel}
                        setIsLoading={(val: boolean) => props.viewModel.setIsLoadingFlag(val)}
                        parentViewModel={props.parentViewModel}
                    />
                )}
            />
            <Route path={AppUrls.Client.Induction.Access.SignedIn} component={() => <SignedInView parentViewModel={props.parentViewModel} />} />
            <Route
                path={AppUrls.Client.Induction.Access.Rams}
                component={() => (
                    <RamsView
                        lastName={viewModel.landingViewModel.model.lastName}
                        lastThreeDigits={viewModel.landingViewModel.model.lastThreeDigits}
                        projectId={viewModel.landingViewModel.model.projectId}
                        setIsLoading={(val: boolean) => props.viewModel.setIsLoadingFlag(val)}
                        parentViewModel={props.parentViewModel}
                    />
                )}
            />
        </SHBox>
    );
});
