import { FieldType } from "@shoothill/core";
import { computed, action } from "mobx";

import { VariationApprovalPanelModel } from "./VariationApprovalPanelModel";
import { ApprovalPanelViewModelBase } from "Globals/ViewModels/ApprovalPanelViewModelBase";
import { ApprovalHistoryViewModel } from "Components/ApprovalHistory/ApprovalHistoryViewModel";
import { VariationTypeEnum } from "./Form/VariationModel";

export class VariationApprovalPanelViewModel extends ApprovalPanelViewModelBase<VariationApprovalPanelModel> {
    public approvalHistoryViewModel = new ApprovalHistoryViewModel();

    // #region Constructors and Disposers

    constructor() {
        super(VariationApprovalPanelModel);
        this.setDecorators(VariationApprovalPanelViewModel);
    }

    // #endregion Constructors and Disposers

    @action
    public setVariationTypeEnum = (val: VariationTypeEnum) => {
        this.model.variationTypeEnum = val;
    };

    @computed
    public get getTitle(): string {
        const itemName = this.model.variationTypeEnum === VariationTypeEnum.ClientProvisionalSum ? "Client provisional sum" : "Variation";
        if (this.getCanShowNewApprovalPanel) {
            return `New ${itemName.toLowerCase()}`;
        } else if (this.getCanShowAmendedApprovalPanel) {
            return `${itemName} amend`;
        } else if (this.getCanShowAmenderPanel) {
            return `Amends required`;
        } else {
            return `New ${itemName.toLowerCase()}`;
        }
    }

    @computed
    public get getCanUpsertStatus(): boolean {
        const isSubmittedForApproval = this.model.approvalStatusId === this.getSubmittedForApprovalStatusId;
        const isDraft = this.model.approvalStatusId === this.getDraftStatusId;

        const isApprover = this.model.isApprover;

        if (isApprover) {
            return !this.model.isResolved && (isSubmittedForApproval || isDraft);
        }

        return false;
    }

    // #region Server Actions

    // #endregion Server Actions

    // #region Client Actions

    // #endregion Client Actions

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<any>): Promise<boolean> {
        return true;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
