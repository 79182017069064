import { observable, runInAction, action } from "mobx";
import { ApiResult, ViewModelBase } from "@shoothill/core";
import { EndOfWeekModel } from "./EndOfWeekModel";
import { AppUrls } from "../../../../../AppUrls";
import { ServerViewModel } from "../../../../../Globals/ViewModels/ServerViewModel";
import { InspectionsAndRelatedDTO } from "../../Inspections/InspectionsAndRelated";
import { viewModelModelLink } from "../../../../../Utils/Utils";
import { AuditQuestionViewModel } from "../EndOfDay/AuditQuestionViewModel";
import { FireExitModel } from "./FireExitModel";
import { FireExtinguishersModel } from "./FireExtinguishersModel";
import { FirePointModel } from "./FirePointModel";
import { SpillKitModel } from "./SpillKitModel";
import { FirstAidKitModel } from "./FirstAidKitModel";
import { FireExitViewModel } from "./FireExitViewModel";
import { FireExtinguishersViewModel } from "./FireExtinguishersViewModel";
import { FirePointViewModel } from "./FirePointViewModel";
import { SpillKitViewModel } from "./SpillKitViewModel";
import { FirstAidKitViewModel } from "./FirstAidKitViewModel";
import { InductionUserLookupModel } from "../../Inspections/InductionUserLookupModel";
import { AuditDocumentModel } from "../AuditDocument";
import { ProjectUserLookUpModel } from "./ProjectUserLookup";

export class EndOfWeekViewModel extends ViewModelBase<EndOfWeekModel> {
    public server: ServerViewModel = new ServerViewModel();
    @observable
    public users: any = [];
    @observable
    public editable: boolean = false;
    @observable
    public showValidationError: boolean = false;
    public fireExitViewModels = observable<FireExitViewModel>([]);
    public fireExtinguisherViewModels = observable<FireExtinguishersViewModel>([]);
    public firePointViewModels = observable<FirePointViewModel>([]);
    public spillKitViewModel = observable<SpillKitViewModel>([]);
    public firstAidKitViewModels = observable<FirstAidKitViewModel>([]);
    constructor(model = new EndOfWeekModel()) {
        super(model);
        this.setDecorators(EndOfWeekModel);
        (window as any).endOfWeekViewModel = this;

        this.server.query<any>(
            () => this.Get(`${AppUrls.Server.Projects.Construction.Audits.GetEndOfWeekAuditAndRelated}`),
            (result) => {
                runInAction(() => {
                    this.users.replace(ProjectUserLookUpModel.fromDtos(result.users));
                });
            },
        );
    }

    public fireExitLinkObserverDispose = viewModelModelLink(this.model.fireExitModels, this.fireExitViewModels, FireExitViewModel);
    public fireExtinguisherLinkObserverDispose = viewModelModelLink(this.model.fireExtinguisherModels, this.fireExtinguisherViewModels, FireExtinguishersViewModel);
    public firePointLinkObserverDispose = viewModelModelLink(this.model.firePointModels, this.firePointViewModels, FirePointViewModel);
    public spillKitLinkObserverDispose = viewModelModelLink(this.model.spillKitModels, this.spillKitViewModel, SpillKitViewModel);
    public firstAidKitLinkObserverDispose = viewModelModelLink(this.model.firstAidKitModels, this.firstAidKitViewModels, FirstAidKitViewModel);

    public loadNewAudit = () => {
        this.model.fireExitModels.clear();
        this.model.fireExtinguisherModels.clear();
        this.model.firePointModels.clear();
        this.model.spillKitModels.clear();
        this.model.firstAidKitModels.clear();

        runInAction(() => {
            this.model.fireExitModels.push(new FireExitModel());
            this.model.fireExtinguisherModels.push(new FireExtinguishersModel());
            this.model.firePointModels.push(new FirePointModel());
            this.model.spillKitModels.push(new SpillKitModel());
            this.model.firstAidKitModels.push(new FirstAidKitModel());
            this.editable = true;
        });
    };
    public loadAudit = (): Promise<void> => {
        return this.server.query<any>(
            () => this.Get(`${AppUrls.Server.Projects.Construction.Audits.GetEndOfWeekAuditById}?auditId=${this.model.id}`),
            (result) => {
                runInAction(() => {
                    for (const fireExit of result.audit.fireExits) {
                        let model = new FireExitModel();
                        model.fromDto(fireExit);
                        this.model.fireExitModels.push(model);
                    }
                    for (const fireExtinguisher of result.audit.fireExtinguishers) {
                        let model = new FireExtinguishersModel();
                        model.fromDto(fireExtinguisher);
                        this.model.fireExtinguisherModels.push(model);
                    }
                    for (const firePoint of result.audit.firePoints) {
                        let model = new FirePointModel();
                        model.fromDto(firePoint);
                        this.model.firePointModels.push(model);
                    }
                    for (const spillKit of result.audit.spillKits) {
                        let model = new SpillKitModel();
                        model.fromDto(spillKit);
                        this.model.spillKitModels.push(model);
                    }
                    for (const firstAidKit of result.audit.firstAidKits) {
                        let model = new FirstAidKitModel();
                        model.fromDto(firstAidKit);
                        this.model.firstAidKitModels.push(model);
                    }

                    this.users.replace(ProjectUserLookUpModel.fromDtos(result.users));
                    this.model.fromDto(result.audit);
                    this.model.auditUser = this.users.find((x: any) => x.id === result.audit.auditUserId);

                    if (this.isDateInThisWeek(this.model.createdDate!)) {
                        console.log("Date is in this week");
                        this.editable = true;
                    } else {
                        console.log("Date is not in this week");
                        this.editable = false;
                    }
                });
            },
        );
    };

    public isDateInThisWeek = (date: Date) => {
        const todayObj = new Date();
        const todayDate = todayObj.getDate();
        const todayDay = todayObj.getDay();

        // get first date of week
        const firstDayOfWeek = new Date(todayObj.setDate(todayDate - todayDay));

        // get last date of week
        const lastDayOfWeek = new Date(firstDayOfWeek);
        lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);

        // if date is equal or within the first and last dates of the week
        return date >= firstDayOfWeek && date <= lastDayOfWeek;
    };

    public canSubmit = () => {
        //return isNullOrUndefined(this.model.id);
        return true;
    };

    @action
    public radioChanged = async (value: string, fieldName: string) => {
        this.model[fieldName] = value;
        await this.isFieldValid(fieldName, value);
    };

    @action
    public submit = async (handleClose: () => void) => {
        let isValid = true;
        this.showValidationError = false;
        isValid = await this.isModelValid();

        for (const fireExitViewModel of this.fireExitViewModels) {
            let valid = await fireExitViewModel.isModelValid();
            if (!valid) {
                isValid = false;
            }
        }
        for (const fireExtinguisherViewModel of this.fireExtinguisherViewModels) {
            let valid = await fireExtinguisherViewModel.isModelValid();
            if (!valid) {
                isValid = false;
            }
        }
        for (const firePointViewModel of this.firePointViewModels) {
            let valid = await firePointViewModel.isModelValid();
            if (!valid) {
                isValid = false;
            }
        }
        for (const spillKitViewModel of this.spillKitViewModel) {
            let valid = await spillKitViewModel.isModelValid();
            if (!valid) {
                isValid = false;
            }
        }
        for (const firstAidKitViewModel of this.firstAidKitViewModels) {
            let valid = await firstAidKitViewModel.isModelValid();
            if (!valid) {
                isValid = false;
            }
        }

        //isValid = true;
        if (!isValid) {
            runInAction(() => {
                this.showValidationError = true;
            });
        }

        const modelToPost = this.model.toDto();

        return this.server.command(
            () => this.Post(`${AppUrls.Server.Projects.Construction.Audits.UpsertEndOfWeekAudit}`, modelToPost),
            (result) => {
                runInAction(() => {
                    if (handleClose) {
                        handleClose();
                    }
                });
            },
            () => Promise.resolve(isValid),
            "There was an error trying to add the amendment",
        );
    };

    public cancel = (handleClose: () => void) => {
        runInAction(() => {
            if (handleClose) {
                handleClose();
            }
        });
    };

    public updateSignature = (data: any) => {
        this.setValue("signatureBase64", data);
    };
    @action
    public setAuditUser = (type: InductionUserLookupModel) => {
        this.model.auditUserId = type.id;
        this.model.auditUser = type;
    };

    @action
    public addAnotherFireExit = () => {
        this.model.fireExitModels.push(new FireExitModel());
    };
    @action
    public addAnotherFireExtinguisher = () => {
        this.model.fireExtinguisherModels.push(new FireExtinguishersModel());
    };
    @action
    public addAnotherFirePoint = () => {
        this.model.firePointModels.push(new FirePointModel());
    };
    @action
    public addAnotherSpillKit = () => {
        this.model.spillKitModels.push(new SpillKitModel());
    };
    @action
    public addAnotherFirstAidKit = () => {
        this.model.firstAidKitModels.push(new FirstAidKitModel());
    };

    @action
    public fileChange = async (event: React.ChangeEvent<HTMLInputElement>, auditDocuments: AuditDocumentModel[]): Promise<void> => {
        if (event.target.files !== null && event.target.value !== null && event.target.files.length > 0) {
            let data: any = {
                fileName: event.target.files[0].name,
                formFile: event.target.files[0],
            };
            event.target.value = "";
            const apiResult = await this.fileUpload(data);
            if (apiResult && apiResult.wasSuccessful) {
                let fileToDisplay: AuditDocumentModel = new AuditDocumentModel();
                //fileToDisplayid: Math.random().toString(16).substr(2, 8), // 6de5ccda
                fileToDisplay.fileName = data.fileName;
                fileToDisplay.url = apiResult.payload;
                fileToDisplay.isDeleted = false;

                runInAction(() => {
                    auditDocuments.push(fileToDisplay);
                    this.isFieldValid("auditDocuments", auditDocuments);
                });
            }
        }
    };

    /**
     * Upload a file to azure.
     * @param data The data of the file to be uploaded.
     * @returns apiResult.
     */
    public fileUpload = async (data: any): Promise<ApiResult<any>> => {
        const formData = new FormData();
        formData.append("formFile", data.formFile);
        formData.append("fileName", data.fileName);
        const apiResult = await this.Post<any>(AppUrls.Server.File.UploadFile, formData);
        if (apiResult) {
            if (!apiResult.wasSuccessful) {
                console.log(apiResult.errors);
                runInAction(() => {
                    /*this.setSnackMessage("Error uploading file please try again.");
                    this.setSnackType(this.SNACKERROR);
                    this.setSnackbarState(true);*/
                });
            }
        }
        return apiResult;
    };

    /**
     * Download a file that exists in azure.
     * @param fileUrl The URL of the file to be downloaded.
     * @param fileName The name of the file to be downloaded.
     */
    public DownloadFile = async (fileUrl: string, fileName: string): Promise<void> => {
        try {
            const apiResult = await this.Post<Blob>(AppUrls.Server.File.DownloadFile, fileUrl, undefined, { responseType: "blob" });
            const response = apiResult as any;
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
        } catch (exception) {
            console.error(exception);
            this.setIsErrored(true);
        }
    };

    /**
     * Delete a document from the local array.
     * @param id The id of the document to be deleted
     */
    @action
    public handleDeleteDocument = (id: string | null, auditDocuments: AuditDocumentModel[]): void => {
        // Used to delete by index but there's different types of documents separated into different lists.
        if (id !== null) {
            for (let i = 0; i < auditDocuments.length; i++) {
                if (auditDocuments[i].id === id) {
                    auditDocuments[i].isDeleted = true;
                }
            }
        }
    };
    public async isFieldValid(fieldName: any, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
