import { ModelBase } from "@shoothill/core";
import { IsNotEmpty, Min, NotEquals } from "class-validator";
import { action, observable } from "mobx";

import { IMaterialSubType, MaterialSubType } from "Globals/Models/Enums/MaterialSubType";
import { IMaterialType, MaterialType } from "Globals/Models/Enums/MaterialType";
import { IUnitOfMeasure, UnitOfMeasure } from "Globals/Models/Enums/UnitOfMeasure";
import { IMaterialSupplier, MaterialSupplier } from "../Shared/MaterialSupplier";

export class MaterialModel extends ModelBase<MaterialModel> {
    // #region Defaults

    public static readonly DEFAULT_MATERIALTYPES = [];
    public static readonly DEFAULT_MATERIALSUBTYPES = [];
    public static readonly DEFAULT_UNITSOFMEASURE = [];
    public static readonly DEFAULT_SUPPLIERS = [];

    // Details
    public static readonly DEFAULT_MATERIALTYPE = null;
    public static readonly DEFAULT_MATERIALSUBTYPE = null;
    public static readonly DEFAULT_UNITOFMEASURE = null;
    public static readonly DEFAULT_SUPPLIERCODE = "";
    public static readonly DEFAULT_DESCRIPTION = "";

    // Price Details
    public static readonly DEFAULT_SUPPLIER = null;
    public static readonly DEFAULT_ESTIMATEDPRICE = 0;
    public static readonly DEFAULT_PRICE = 0;
    public static readonly DEFAULT_EFFECTIVEFROMDATE = "";
    public static readonly DEFAULT_NOTE = "";

    // #endregion Defaults

    // #region Related Properties

    @observable
    public materialTypes = observable<MaterialType>(MaterialModel.DEFAULT_MATERIALTYPES);

    @observable
    public materialSubTypes = observable<MaterialSubType>(MaterialModel.DEFAULT_MATERIALSUBTYPES);

    @observable
    public unitsOfMeasure = observable<UnitOfMeasure>(MaterialModel.DEFAULT_UNITSOFMEASURE);

    @observable
    public suppliers = observable<MaterialSupplier>(MaterialModel.DEFAULT_SUPPLIERS);

    // #endregion Related Properties

    // #region Properties

    @observable
    @NotEquals(null, { message: "Please select an option" })
    public materialType: MaterialType | null = MaterialModel.DEFAULT_MATERIALTYPE;

    @observable
    @NotEquals(null, { message: "Please select an option" })
    public materialSubType: MaterialSubType | null = MaterialModel.DEFAULT_MATERIALSUBTYPE;

    @observable
    @NotEquals(null, { message: "Please select an option" })
    public unitOfMeasure: UnitOfMeasure | null = MaterialModel.DEFAULT_UNITOFMEASURE;

    @observable
    @IsNotEmpty({ message: "Please provide a code" })
    public supplierCode: string = MaterialModel.DEFAULT_SUPPLIERCODE;

    @observable
    @IsNotEmpty({ message: "Please provide a description" })
    public description: string = MaterialModel.DEFAULT_DESCRIPTION;

    @observable
    @NotEquals(null, { message: "Please select an option" })
    public supplier: MaterialSupplier | null = MaterialModel.DEFAULT_SUPPLIER;

    @observable
    @Min(0.01, { message: "Please provide a price" })
    public estimatedPrice: number = MaterialModel.DEFAULT_ESTIMATEDPRICE;

    @observable
    @Min(0.01, { message: "Please provide a price" })
    public price: number = MaterialModel.DEFAULT_PRICE;

    @observable
    @IsNotEmpty({ message: "Please provide a date" })
    public effectiveFromDate: string = MaterialModel.DEFAULT_EFFECTIVEFROMDATE;

    @observable
    @IsNotEmpty({ message: "Please provide a note" })
    public note: string = MaterialModel.DEFAULT_NOTE;

    // #endregion Properties

    // #region Methods

    @action
    public fromDto(dto: any): void {
        // Material types.
        this.materialTypes.replace(
            dto.materialTypes.map((mt: IMaterialType) => {
                const enumType = new MaterialType();

                enumType.fromDto(mt);

                return enumType;
            }),
        );

        // Material subtypes.
        this.materialSubTypes.replace(
            dto.materialSubTypes.map((mst: IMaterialSubType) => {
                const enumType = new MaterialSubType();

                enumType.fromDto(mst);

                return enumType;
            }),
        );

        // Units of measure.
        this.unitsOfMeasure.replace(
            dto.unitsOfMeasure.map((uom: IUnitOfMeasure) => {
                const enumType = new UnitOfMeasure();

                enumType.fromDto(uom);

                return enumType;
            }),
        );

        // Suppliers.
        this.suppliers.replace(
            dto.suppliers.map((s: IMaterialSupplier) => {
                const type = new MaterialSupplier();

                type.fromDto(s);

                return type;
            }),
        );
    }

    public toDto(): any {
        return {
            details: {
                materialTypeId: this.materialType?.id,
                materialSubTypeId: this.materialSubType?.id,
                unitOfMeasureId: this.unitOfMeasure?.id,
                description: this.description,
                supplierCode: this.supplierCode,
            },
            priceDetails: {
                supplierId: this.supplier?.id,
                estimatedPrice: this.estimatedPrice,
                price: this.price,
                effectiveFromDate: this.effectiveFromDate,
                note: this.note,
            },
        };
    }

    // #region Methods
}
