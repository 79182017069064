import * as MobX from "mobx";
import { ModelBase } from "@shoothill/core";
import moment from "moment";
export class InvoiceListBaseModel extends ModelBase<InvoiceListBaseModel, InvoiceListBaseModelDTO> {
    @MobX.observable
    public id: string = "";

    @MobX.observable
    public invoiceNumber: string = "";

    @MobX.observable
    public invoiceDate: Date | null = null;

    @MobX.observable
    public supplierName: string = "";

    @MobX.observable
    public invoiceStatusId: string = "";

    @MobX.observable
    public invoiceValue: number = 0;

    @MobX.observable
    public approverRoleName: string | null = null;

    @MobX.observable
    public disputedStatusCodeName: string | null = null;

    @MobX.observable
    public projectName: string | null = "";

    @MobX.observable
    public approvedDate: Date | null = null;

    @MobX.observable
    public postedDate: Date | null = null;

    @MobX.observable
    public filterStartDateINV: Date | null = null;

    @MobX.observable
    public filterEndDateINV: Date | null = null;

    @MobX.computed get getFilterStartDateINV(): string | null {
        return this.filterStartDateINV ? this.filterStartDateINV.toISOString() : null;
    }

    @MobX.computed get getFilterEndDateINV(): string | null {
        return this.filterEndDateINV ? this.filterEndDateINV.toISOString() : null;
    }

    @MobX.action validateFilters = () => {
        let start: moment.Moment | null = null;
        let end: moment.Moment | null = null;

        if (this.filterStartDateINV !== null && this.filterStartDateINV !== undefined) {
            start = moment.utc(this.filterStartDateINV);
        }
        if (this.filterEndDateINV !== null && this.filterEndDateINV !== undefined) {
            end = moment.utc(this.filterEndDateINV);
        }

        if (start !== null && end !== null) {
            if (start < end) {
                return true;
            }
        } else {
            return true;
        }

        return false;
    };

    fromDto(model: InvoiceListBaseModelDTO): void {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    toDto(model: InvoiceListBaseModel): void {}
}

export type InvoiceListBaseModelDTO = {
    id: string;
    invoiceNumber: string;
    invoiceDate: Date | null;
    supplierName: string;
    invoiceStatusId: string;
    invoiceValue: number;
    approverRoleName: string | null;
    disputedStatusCodeName: string | null;
    projectName: string;
    approvedDate: Date | null;
    postedDate: Date | null;
};
