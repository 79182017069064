import { FieldType, ViewModelBase } from "@shoothill/core";
import { action, computed, observable } from "mobx";

import { AppUrls } from "AppUrls";
import { MaterialSubType } from "Globals/Models/Enums/MaterialSubType";
import { MaterialType } from "Globals/Models/Enums/MaterialType";
import { UnitOfMeasure } from "Globals/Models/Enums/UnitOfMeasure";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { isNullOrUndefined } from "Utils/Utils";
import { MaterialModel } from "./MaterialModel";
import { MaterialSupplier } from "../Shared/MaterialSupplier";

export class MaterialViewModel extends ViewModelBase<MaterialModel> {
    public server: ServerViewModel = new ServerViewModel();

    constructor() {
        super(new MaterialModel(), false);

        this.setDecorators(MaterialModel);

        // Load related information.
        this.apiLoadMaterialRelatedAsync();
    }

    // #region Material Types

    @computed
    public get materialTypes() {
        return this.model.materialTypes;
    }

    @computed
    public get materialType() {
        return this.model.materialType;
    }

    @action
    public setMaterialType = (value: MaterialType | null): void => {
        this.model.materialType = value ?? MaterialModel.DEFAULT_MATERIALTYPE;
        this.isFieldValid("materialType");

        // SIDE-EFFECT.
        // If the user changes the material type, it invalidates
        // any selected material subtype.
        this.model.materialSubType = MaterialModel.DEFAULT_MATERIALSUBTYPE;
    };

    // #endregion Material Types

    // #region Material SubTypes

    @computed
    public get materialSubTypes() {
        if (isNullOrUndefined(this.model.materialType)) {
            return [];
        }

        return this.model.materialSubTypes.filter((mst) => mst.materialTypeId === this.model.materialType!.id);
    }

    @computed
    public get materialSubType() {
        return this.model.materialSubType;
    }

    @action
    public setMaterialSubType = (value: MaterialSubType | null): void => {
        this.model.materialSubType = value ?? MaterialModel.DEFAULT_MATERIALSUBTYPE;
        this.isFieldValid("materialSubType");
    };

    // #region Material SubTypes

    // #endregion Units of Measure

    @computed
    public get unitsOfMeasure() {
        return this.model.unitsOfMeasure;
    }

    @computed
    public get unitOfMeasure() {
        return this.model.unitOfMeasure;
    }

    @action
    public setUnitOfMeasure = (value: UnitOfMeasure | null): void => {
        this.model.unitOfMeasure = value ?? MaterialModel.DEFAULT_UNITOFMEASURE;
        this.isFieldValid("unitOfMeasure");
    };

    // #endregion Units of Measure

    // #region Suppliers

    @computed
    public get supplier() {
        return this.model.supplier;
    }

    @computed
    public get suppliers() {
        return this.model.suppliers;
    }

    @action
    public setSupplier = (value: MaterialSupplier | null): void => {
        this.model.supplier = value ?? MaterialModel.DEFAULT_SUPPLIER;
        this.isFieldValid("supplier");
    };

    // #endregion Suppliers

    // #region Effective Date

    @action
    public setEffectiveFromDate = (value: string | null) => {
        this.model.effectiveFromDate = value ?? MaterialModel.DEFAULT_EFFECTIVEFROMDATE;
        this.isFieldValid("effectiveFromDate");
    };

    // #endregion Effective Date

    // #region Actions

    @action
    public close = () => this.history.replace(AppUrls.Client.Stock.MaterialList);

    // #endregion Actions

    // #region Api Actions

    @action
    public apiLoadMaterialRelatedAsync = async (): Promise<void> => {
        await this.server.query<any>(
            () => this.Get(AppUrls.Server.Stock.Material.GetMaterialRelated),
            (result) => this.model.fromDto(result),
            "Error whilst loading the material dependencies",
        );

        if (this.server.HaveValidationMessage) {
            this.setSnackMessage(this.server.ValidationMessage);
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }
    };

    @action
    public apiSaveMaterialAsync = async (): Promise<void> => {
        await this.server.command<any>(
            () => this.Post(AppUrls.Server.Stock.Material.RequestCreateMaterial, this.model.toDto()),
            (result) => this.close(),
            this.isModelValid,
            "Error whilst saving the material data",
        );

        if (this.server.HaveValidationMessage) {
            this.setSnackMessage(this.server.ValidationMessage);
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }
    };

    // #endregion Api Actions

    // #region Snack Bar

    public SNACKERROR = "error";

    @observable
    public snackbarState = false;

    @observable
    public snackType = "";

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public snackMessage = "";

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    // #endregion Snack Bar

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<MaterialModel>): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boliderplate
}
