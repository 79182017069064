import { ApiResult, FieldType, ViewModelBase } from "@shoothill/core";
import { PurchaseOrderListModel, PurchaseOrderListModelDTO, PurchaseOrderListTableModel2 } from "./PurchaseOrderListModel";
import * as mobx from "mobx";
import { GenericIncludeDeleted } from "Globals/Models";
import { AppUrls } from "AppUrls";
import { PackageCategoriesResult } from "./PackageCategoriesResult";
import { PurchaseOrderListFilterViewModel } from "./PurchaseOrderListFilterViewModel";
import debounce from "lodash/debounce";
import { OrderType, ProjectDTO, RequisitionPOStatus, UserDTO } from "Views/Approval/PurchaseListViewModel";
import { csvAxiosRequestConfig, exportPDFWithGet } from "Utils/Utils";
import { SupplierDTO } from "./Form/Supporting/SupplierModel";

export class PurchaseOrderListViewModel extends ViewModelBase<PurchaseOrderListModel> {
    @mobx.observable public errorMessage: string = "";

    @mobx.observable
    public purchaseOrders: mobx.IObservableArray<PurchaseOrderListModel> = mobx.observable<PurchaseOrderListModel>([]);

    @mobx.computed
    public get getPurchaseOrdersList(): PurchaseOrderListTableModel2[] {
        // return this.purchaseOrders.slice().map((po) => {
        //     return po.toTableModel();
        // });
        return this.purchaseOrders.map((p) => {
            return p.toTableModel2();
        });
    }

    constructor() {
        super(new PurchaseOrderListModel(), false);
        this.setDecorators(PurchaseOrderListModel);
    }

    public handleCategoryChange = (val: number[] | null) => {
        PurchaseOrderListFilterViewModel.Instance.setValue("categories", val);
        PurchaseOrderListFilterViewModel.Instance.setValue("subCategories", []);
        this.GetAllWithFiltersAsync();
    };

    public handleRaisedByChange = (val: number[] | null) => {
        PurchaseOrderListFilterViewModel.Instance.setValue("raisedByIds", val);
        this.GetAllWithFiltersAsync();
    };

    public handleSupplierChange = (val: number[] | null) => {
        PurchaseOrderListFilterViewModel.Instance.setValue("supplierIds", val);
        this.GetAllWithFiltersAsync();
    };

    public handleProjectChange = (val: number[] | null) => {
        PurchaseOrderListFilterViewModel.Instance.setValue("projects", val);
        this.GetAllWithFiltersAsync();
    };

    public handleSubCategoryChange = (val: number[] | null) => {
        PurchaseOrderListFilterViewModel.Instance.setValue("subCategories", val);
        this.GetAllWithFiltersAsync();
    };

    public handleSearchChange = (val: string) => {
        PurchaseOrderListFilterViewModel.Instance.setValue("searchText", val);

        if (val.length > 3 || val.length === 0) {
            this.reloadDataDelayed();
        }
    };

    public handleStatusChange = (val: number[] | null) => {
        PurchaseOrderListFilterViewModel.Instance.setValue("statuses", val);
        this.GetAllWithFiltersAsync();
    };

    public handleCellChange = (val: number[] | null) => {
        PurchaseOrderListFilterViewModel.Instance.setValue("cells", val);
        this.GetAllWithFiltersAsync();
    };

    public handleOrderTypeChange = (val: number[] | null) => {
        PurchaseOrderListFilterViewModel.Instance.setValue("orderTypes", val);
        this.GetAllWithFiltersAsync();
    };

    private reloadDataDelayed = debounce(() => {
        this.GetAllWithFiltersAsync();
    }, 500);

    @mobx.action
    public cleanUp = () => {
        this.reloadDataDelayed.cancel();
    };

    @mobx.action
    public clear = () => {
        PurchaseOrderListFilterViewModel.Instance.model.reset();
        this.GetAllWithFiltersAsync();
    };

    @mobx.action
    public setPurchaseOrders(purchaseOrders: PurchaseOrderListModelDTO[]) {
        this.purchaseOrders.clear();
        purchaseOrders.forEach((item, index) => {
            let domainModel = this.purchaseOrders.find((c) => c.id === item.id);

            if (!domainModel) {
                domainModel = new PurchaseOrderListModel();

                domainModel.fromDto(item);
                this.purchaseOrders.push(domainModel);
            }
        });
    }

    @mobx.action
    public async loadPurchaseOrderListAsync(): Promise<ApiResult<PurchaseOrderListModelDTO[]>> {
        const request: GenericIncludeDeleted = {
            includeDeleted: true,
        };

        let apiResult = await this.Post<PurchaseOrderListModelDTO[]>(AppUrls.Server.PurchaseOrder.GetAll, request);

        if (apiResult.wasSuccessful) {
            mobx.runInAction(() => {
                this.setPurchaseOrders(apiResult.payload);
            });
        }
        return apiResult;
    }

    @mobx.action
    public setHasLoaded = (val: boolean) => {
        this.hasLoaded = val;
    };

    // Used to ensure that only the results of the last request are used to populate the table.
    private lastRequestId = 0;

    @mobx.observable
    public hasLoaded: boolean = false;

    @mobx.action
    public GetAllWithFiltersAsync = async (): Promise<ApiResult<PurchaseOrderListAndRelatedDTO>> => {
        const currentRequestId = ++this.lastRequestId;
        let paramsViewModel = PurchaseOrderListFilterViewModel.Instance;
        let params = paramsViewModel.model.toDto();
        params.initialLoad = !this.hasLoaded;

        const apiResult = await this.Post<PurchaseOrderListAndRelatedDTO>(AppUrls.Server.PurchaseOrder.GetAllWithFilters, params);

        if (currentRequestId === this.lastRequestId && apiResult.wasSuccessful) {
            this.setPurchaseOrders(apiResult.payload.purchaseOrders);
            PurchaseOrderListFilterViewModel.Instance.setTotalCount(apiResult.payload.totalCount);

            if (!this.hasLoaded) {
                PurchaseOrderListFilterViewModel.Instance.setCategories(apiResult.payload.packageCategories, true);
                PurchaseOrderListFilterViewModel.Instance.setStatuses(apiResult.payload.statuses, true);
                PurchaseOrderListFilterViewModel.Instance.setPurchaseUsers(apiResult.payload.purchaseUsers, true);
                PurchaseOrderListFilterViewModel.Instance.setOrderTypes(apiResult.payload.orderTypes, true);
                PurchaseOrderListFilterViewModel.Instance.setSuppliers(apiResult.payload.suppliers, true);
                PurchaseOrderListFilterViewModel.Instance.setProjects(apiResult.payload.projects, true);
            }
        }

        this.setHasLoaded(true);

        return apiResult;
    };

    @mobx.action
    public apiGeneratePOPDF = async (id: string) => {
        await exportPDFWithGet(AppUrls.Server.PurchaseOrder.GeneratePOPDFById, { id: id }, await this.getConfig(true, csvAxiosRequestConfig));
    };

    public async isFieldValid(fieldName: keyof FieldType<PurchaseOrderListModel>): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}

export type PurchaseOrderListAndRelatedDTO = {
    purchaseOrders: PurchaseOrderListModelDTO[];
    packageCategories: PackageCategoriesResult;
    statuses: RequisitionPOStatus[];
    orderTypes: OrderType[];
    purchaseUsers: UserDTO[];
    projects: ProjectDTO[];
    suppliers: SupplierDTO[];
    totalCount: number;
};
