import { Cell } from "@shoothill/core/dist/Components";
import { observer } from "mobx-react-lite";
import React from "react";

import { SolidSectionSplitter } from "Components/Form/SectionSplitter";
import { BaseModal } from "Components/Modal/BaseModal";
import { SnackBar } from "Components/SnackBar/SnackBar";
import { ApproveButton, CancelButton, RejectButton } from "Globals/Styles/Control/Buttons";
import { ReportModalStyle } from "Views/Project/Modals/Modal.Styles";
import { FormModalSection, SummaryLabelValueSection, SummaryLabelValueSectionStack, SummaryRow, SummaryLabel, SummaryValue } from "Views/Stock/Shared/Shared.styles";
import { MaterialPriceDetailsApprovalViewModel } from "./MaterialPriceDetailsApprovalViewModel";

interface IProps {
    viewModel: MaterialPriceDetailsApprovalViewModel;
}

export const MaterialPriceDetailsApprovalView: React.FC<IProps> = observer((props) => {
    const viewModel = props.viewModel;

    const renderButtons = () => {
        return (
            <React.Fragment>
                {viewModel.canApprove && (
                    <ApproveButton disabled={viewModel.server.IsBusy} onClick={viewModel.approve}>
                        Approve
                    </ApproveButton>
                )}
                {viewModel.canReject && (
                    <RejectButton disabled={viewModel.server.IsBusy} onClick={viewModel.reject}>
                        Reject
                    </RejectButton>
                )}
                {viewModel.canCancel ? <CancelButton onClick={viewModel.cancel}>Cancel</CancelButton> : <CancelButton onClick={viewModel.cancel}>Close</CancelButton>}
            </React.Fragment>
        );
    };

    return (
        <BaseModal open={viewModel !== null} onClose={viewModel.cancel} title={viewModel.displayName} PaperComponent={ReportModalStyle} actions={renderButtons()}>
            <FormModalSection>
                <SummaryRow grid dc={"1fr"}>
                    <SummaryLabelValueSectionStack>
                        <SummaryLabel>Item description:</SummaryLabel>
                        <SummaryValue>{viewModel.model.description}</SummaryValue>
                    </SummaryLabelValueSectionStack>
                </SummaryRow>
            </FormModalSection>
            <SolidSectionSplitter mb="0.625rem" />
            <FormModalSection>
                <SummaryRow grid dc={"1fr"}>
                    <SummaryLabelValueSection>
                        <SummaryLabel>Supplier:</SummaryLabel>
                        <SummaryValue>{viewModel.model.supplierDisplayName}</SummaryValue>
                    </SummaryLabelValueSection>
                </SummaryRow>
                <SummaryRow grid dc={"1fr 1fr 1fr 1fr"}>
                    <SummaryLabelValueSection>
                        <SummaryLabel>Before estimating price:</SummaryLabel>
                        <SummaryValue>{viewModel.beforeEstimatedPrice}</SummaryValue>
                    </SummaryLabelValueSection>
                    <SummaryLabelValueSection>
                        <SummaryLabel>Before purchase price:</SummaryLabel>
                        <SummaryValue>{viewModel.beforePrice}</SummaryValue>
                    </SummaryLabelValueSection>
                    <Cell ms={2} ts={2} ds={2}>
                        <SummaryLabelValueSection>
                            <SummaryLabel>Purchase effective date:</SummaryLabel>
                            <SummaryValue>{viewModel.effectiveFromDate}</SummaryValue>
                        </SummaryLabelValueSection>
                    </Cell>
                </SummaryRow>
                <SummaryRow grid dc={"1fr 1fr 1fr 1fr"}>
                    <SummaryLabelValueSection>
                        <SummaryLabel>Estimating price:</SummaryLabel>
                        <SummaryValue>{viewModel.estimatedPrice}</SummaryValue>
                    </SummaryLabelValueSection>
                    <SummaryLabelValueSection>
                        <SummaryLabel>Purchase price:</SummaryLabel>
                        <SummaryValue>{viewModel.price}</SummaryValue>
                    </SummaryLabelValueSection>
                </SummaryRow>
                <SummaryRow grid dc={"1fr"}>
                    <SummaryLabelValueSectionStack>
                        <SummaryLabel>Notes:</SummaryLabel>
                        <SummaryValue>{viewModel.model.note}</SummaryValue>
                    </SummaryLabelValueSectionStack>
                </SummaryRow>
            </FormModalSection>
            <SolidSectionSplitter mb="0.625rem" />
            <FormModalSection>
                <SummaryRow grid dc={"1fr 1fr"}>
                    <SummaryLabelValueSection>
                        <SummaryLabel>Created by:</SummaryLabel>
                        <SummaryValue>
                            {viewModel.model.createdByUser}&nbsp;&nbsp;&nbsp;{viewModel.createdDate}
                        </SummaryValue>
                    </SummaryLabelValueSection>
                    {viewModel.canDisplayLastUpdated && (
                        <SummaryLabelValueSection>
                            <SummaryLabel>Last updated by:</SummaryLabel>
                            <SummaryValue>
                                {viewModel.model.lastUpdatedByUser}&nbsp;&nbsp;&nbsp;{viewModel.lastUpdatedDate}
                            </SummaryValue>
                        </SummaryLabelValueSection>
                    )}
                </SummaryRow>
            </FormModalSection>
            <SnackBar
                messageText={viewModel.snackMessage}
                messageType={viewModel.snackType}
                active={viewModel.snackbarState}
                closeOption={() => viewModel.setSnackbarState(false)}
                autoHideDuration={5000}
            />
        </BaseModal>
    );
});
