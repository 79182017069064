import styled from "styled-components";

import { FormRow } from "Views/Stock/Shared/Shared.styles";

export const AdjustmentTable = styled(FormRow)`
    margin: 1.25rem 0 0.625rem 0;
`;

export const AdjustmentCell = styled.div<{
    $value?: number | null | undefined;
}>`
    background-color: ${(props: any) => (props.$value < 0 ? "#ECB4B4" : props.$value > 0 ? "#CAEAD4" : "white")};
    border-right: 1px solid #d4d4d4;
    border-bottom: 1px solid #d4d4d4;
    color: #191919;
    font-size: 0.75rem;
    font-weight: ${(props: any) => (props.$value < 0 ? 600 : props.$value > 0 ? 600 : 400)};
    line-height: 0.875rem;
    letter-spacing: -0.015rem;
    padding: 0.5rem 0.75rem;
    text-align: right;
`;

export const AdjustmentCellLabel = styled(AdjustmentCell)`
    background-color: #ced4da;
    font-weight: 600;
`;
