import { FormControl, FormControlLabel, Radio, Typography } from "@material-ui/core";
import { SHBox } from "Components/Box";
import { RadioGroup } from "Globals/Styles/AdminStyling";
import { useObserver } from "mobx-react-lite";

interface RadioRAMSChoiceProps {
    // value: () => string | void;
    value: any;
    validationMessage: () => string;
    onChange: (value: string) => void;
    title: string;
    canExecute: boolean;
    label1: string;
    label2: string;
}

export function RadioRAMSChoiceForm(props: RadioRAMSChoiceProps) {
    return useObserver(() => (
        <>
            <Typography variant={"h5"}>{props.title}</Typography>
            <SHBox>
                <FormControl component="fieldset">
                    <RadioGroup row name={"radio-" + "" + "-group"} value={props.value} onChange={(e: any) => props.onChange(e.target.value)}>
                        <FormControlLabel value={"pass"} control={<Radio color={"secondary"} />} label={props.label1} disabled={!props.canExecute} />
                        <FormControlLabel value={"fail"} control={<Radio color={"secondary"} />} label={props.label2} disabled={!props.canExecute} />
                    </RadioGroup>
                    <Typography variant={"h5"} color={"error"}>
                        {props.validationMessage()}
                    </Typography>
                </FormControl>
            </SHBox>
        </>
    ));
}
