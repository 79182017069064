import { ModelBase } from "@shoothill/core";
import { action, observable } from "mobx";

export class IncomeAndExpenditureTypeModel extends ModelBase<IncomeAndExpenditureTypeModel, IncomeAndExpenditureTypeModelDTO> {
    // #region Defaults

    // #endregion Defaults and Constants

    public static readonly DEFAULT_ID = "";
    public static readonly DEFAULT_DISPLAYNAME = "";

    public static readonly CONSTANT_TYPEMASTERID = "MASTER";
    public static readonly CONSTANT_TYPESUBID = "SUB";

    // #endregion Defaults and Constants

    // #region Observables

    @observable
    public id: string = IncomeAndExpenditureTypeModel.DEFAULT_ID;

    @observable
    public displayName: string = IncomeAndExpenditureTypeModel.DEFAULT_DISPLAYNAME;

    // #endregion Observables

    // #region Actions

    @action
    public reset(): void {
        this.id = IncomeAndExpenditureTypeModel.DEFAULT_ID;
        this.displayName = IncomeAndExpenditureTypeModel.DEFAULT_DISPLAYNAME;
    }

    @action
    public fromDto(dto: IncomeAndExpenditureTypeModelDTO): void {
        this.id = dto.id;
        this.displayName = dto.displayName;
    }

    public toDto() {
        throw new Error("Method not implemented.");
    }

    // #endregion Actions

    // #region Helpers

    public static fromDtos(dtos: IncomeAndExpenditureTypeModelDTO[]): IncomeAndExpenditureTypeModel[] {
        const types: IncomeAndExpenditureTypeModel[] = [];

        for (const dto of dtos) {
            const model = new IncomeAndExpenditureTypeModel();

            model.fromDto(dto);
            types.push(model);
        }

        return types;
    }

    // #endregion Helpers
}

export interface IncomeAndExpenditureTypeModelDTO {
    id: string;
    displayName: string;
}
