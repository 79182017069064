import styled from "styled-components";

import { theme } from "Globals/Styles/AppTheme";
import { CustomArrow } from "Views/Project/CustomComponents";

interface IProps {
    className?: string;
    action: () => void;
    displayName: string;
}

const NavigationBar: React.FC<IProps> = (props) => {
    return (
        <div className={props.className} onClick={props.action}>
            <div className="navbar-arrow">
                <CustomArrow size={"8px"} color={theme.palette.blue.main} type={"left"} />
            </div>
            <div className="navbar-displayname">{props.displayName}</div>
        </div>
    );
};

export const NavigationBarView = styled(NavigationBar)`
    cursor: pointer;
    flex-direction: row;
    margin: 12px 12px 8px 27px;
    position: relative;

    .navbar-arrow {
        position: absolute;
        top: 5px;
    }

    .navbar-displayname {
        margin-left: 20px;
        font-size: 18px;
        font-weight: bold;
        color: ${theme.palette.blue.main};
    }
`;
