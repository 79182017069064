import { action, observable } from "mobx";
import { ModelBase } from "@shoothill/core";

export class RDDListFilterParamsModel extends ModelBase<RDDListFilterParamsModel, RDDListFilterParamsModelDTO> {
    @observable
    public projectId: string = "";

    @observable
    public searchText: string = "";

    @observable
    public isDarwinGroup: boolean | null = null;

    @observable
    public designDataId: string | null = null;

    @observable
    public designDataStatusId: string | null = null;

    fromDto(model: RDDListFilterParamsModelDTO): void {
        this.projectId = model.projectId;
        this.searchText = model.searchText;
        this.isDarwinGroup = model.isDarwinGroup;
        this.designDataId = model.designDataId;
        this.designDataStatusId = model.designDataStatusId;
    }

    toDto(): RDDListFilterParamsModelDTO {
        let dto: RDDListFilterParamsModelDTO = {
            projectId: this.projectId,
            searchText: this.searchText,
            isDarwinGroup: this.isDarwinGroup,
            designDataId: this.designDataId,
            designDataStatusId: this.designDataStatusId,
        };
        return dto;
    }

    @action
    public reset = () => {
        this.searchText = "";
        this.isDarwinGroup = null;
        this.designDataId = null;
        this.designDataStatusId = null;
    };
}

export interface RDDListFilterParamsModelDTO {
    projectId: string;
    searchText: string;
    isDarwinGroup: boolean | null;
    designDataId: string | null;
    designDataStatusId: string | null;
}
