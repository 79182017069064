import { Box, Button, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useObserver, useRouter } from "@shoothill/core";
import { ProjectListViewModel } from "./ProjectListViewModel";
import MaterialTable from "material-table";
import * as Defaults from "Globals/Defaults/TableOptions";
import { AppUrls } from "AppUrls";
import { DarwinPage, DarwinPageTitle, DarwinTablePageContent } from "Globals/Styles/AppStyling";
import { ProjectListModel, ProjectListModelDTO } from "./ProjectListModel";
import { PatchedPagination } from "../../Components/Table/PatchedPagination";

interface IProjectTableViewProps {
    projectViewURL: string;
    data: ProjectListModelDTO[];
}

export const ProjectTableView: React.FunctionComponent<IProjectTableViewProps> = (props: IProjectTableViewProps) => {
    const [viewModel] = useState(() => new ProjectListViewModel());
    const { history } = useRouter();
    //useEffect below only gets run on initial render
    useEffect(() => {
        return () => {};
    }, []);

    const handleRowClick = (e: any, rowData: any) => {
        const url: string = props.projectViewURL.replace(":projectid", rowData.id);
        history.push(url);
    };

    const renderPage = () => {
        return (
            <DarwinTablePageContent>
                <MaterialTable
                    columns={[
                        { title: "Project ref", field: "reference" },
                        { title: "Project name", field: "name" },
                        { title: "Description", field: "" },
                        { title: "System type", field: "" },
                        { title: "(Estimated) start date", field: "" },
                        { title: "(Estimated) completion date", field: "" },
                        { title: "Income", field: "" },
                    ]}
                    components={{
                        Pagination: PatchedPagination,
                    }}
                    options={Defaults.GetDarwinTableOptions()}
                    data={props.data}
                    onRowClick={handleRowClick}
                    title=""
                />
            </DarwinTablePageContent>
        );
    };

    return useObserver(() => renderPage());
};
