// Libraries
import { Typography } from "@material-ui/core";
import { Grid, useObserver } from "@shoothill/core";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";

// Custom
import * as Defaults from "Globals/Defaults/TableOptions";
import { StatusCell } from "Globals/Styles/Control/StatusCell.style";
import { ProjectListItemViewModel } from "./ProjectListItemViewModel";
import { ProjectListViewModel } from "./ProjectListViewModel";
import { TableListSearch } from "./../../Components/Table/TableListSearch";
import { AddButton, PrimaryButton } from "./../../Components/Buttons/Buttons";

// Styling & Images
import { DarwinPage, DarwinPageTitle, DarwinTableAddButtonBox, DarwinTablePageContent, DarwinTableSearchBox, DarwinTableToolbar } from "Globals/Styles/AppStyling";
import { pxToRem } from "Globals/Styles/AppTheme";
import { ProjectCellHelpers } from "Globals/Models/Domain/ProjectCellEnum";
import { ApprovalStatusIcon } from "./CustomComponents";
import { PatchedPagination } from "../../Components/Table/PatchedPagination";
import { ProjectListFilterView } from "./ProjectListFilterView";
import { ProjectListFilterViewModel } from "./ProjectListFilterViewModel";

export const ProjectListView: React.FunctionComponent = () => {
    const [viewModel] = useState(() => new ProjectListViewModel());
    const filtersViewModel = ProjectListFilterViewModel.Instance;

    useEffect(() => {
        //PackagesViewModel.Instance.apiGetRelated();
        viewModel.apiGetAllAndRelated();
        return () => {};
    }, []);

    const setSearchString = (value: string) => {
        viewModel.setSearchString(value);
    };

    const onRowClick = (event?: any, rowViewModel?: ProjectListItemViewModel) => {
        rowViewModel?.navigateToProject();
    };

    const onAddProjectClick = (event: any) => {
        viewModel.navigateToAddNewProject();
    };

    return useObserver(() => {
        const tableOptions = Defaults.GetDarwinTableOptions() as any;

        return (
            <DarwinPage>
                <DarwinPageTitle>
                    <Typography variant="h1" color="textPrimary">
                        Projects
                    </Typography>
                </DarwinPageTitle>
                <DarwinTableToolbar>
                    <ProjectListFilterView filtersViewModel={filtersViewModel} projectListViewModel={viewModel} />
                    <Grid columnGap={30} dc={"1fr 1fr"} rowGap={15} tc={"1fr 1fr"}>
                        <DarwinTableSearchBox>
                            <TableListSearch searchString={viewModel.getSearchString()} setSearchString={setSearchString} />
                        </DarwinTableSearchBox>
                        {viewModel.canAddProject && (
                            <DarwinTableAddButtonBox className="addproject-btn">
                                <AddButton color="primary" displayName="Add new project" execute={onAddProjectClick} />
                            </DarwinTableAddButtonBox>
                        )}
                    </Grid>
                    <br />
                    <PrimaryButton displayName={"Clear"} execute={viewModel.clear} fullWidth={false} canExecute={!viewModel.IsLoading} />
                </DarwinTableToolbar>
                <DarwinTablePageContent>
                    <MaterialTable
                        columns={[
                            {
                                title: "Project ref",
                                field: "projectReference",
                                width: "auto",
                                cellStyle: { ...tableOptions.cellStyle, padding: `${pxToRem(8)} ${pxToRem(16)} ${pxToRem(6)} ${pxToRem(23)}` },
                                headerStyle: { ...tableOptions.headerStyle, padding: `${pxToRem(8)} ${pxToRem(16)} ${pxToRem(6)} ${pxToRem(23)}` },
                            },
                            {
                                title: "Project name",
                                field: "projectName",
                                width: "auto",
                            },
                            {
                                title: "Current authorised value",
                                field: "currentAuthorizedValue",
                                align: "right",
                                width: "auto",
                            },
                            {
                                title: "Cell allocation",
                                field: "cellAllocation",
                                width: "auto",
                                render: (rowData: ProjectListItemViewModel) => ProjectCellHelpers.getText(rowData.cellAllocation),
                            },
                            {
                                title: "Status",
                                field: "statusName",
                                width: "auto",
                                render: (rowData: ProjectListItemViewModel) => (
                                    <StatusCell
                                        className="Unknown"
                                        style={{
                                            backgroundColor: rowData.statusColor,
                                            color: rowData.statusTextColor,
                                            margin: "3px 0",
                                            padding: "2px 0",
                                        }}
                                    >
                                        {rowData.statusName.toLocaleUpperCase()}
                                    </StatusCell>
                                ),
                            },
                            {
                                title: "Approved",
                                field: "approvalName",
                                width: "auto",
                                render: (rowData: ProjectListItemViewModel) => <ApprovalStatusIcon status={rowData.approvalType} />,
                            },
                        ]}
                        components={{
                            Pagination: PatchedPagination,
                        }}
                        data={viewModel.filteredProjectViewModels}
                        onRowClick={onRowClick}
                        options={{ ...tableOptions, filtering: false, search: false, toolbar: false }}
                        title=""
                    />
                </DarwinTablePageContent>
            </DarwinPage>
        );
    });
};
