import { ModelBase } from "@shoothill/core";
import { action, observable } from "mobx";

export class RFIFormFileModel extends ModelBase<RFIFormFileModel> {
    public static readonly DEFAULT_ID = "";
    public static readonly DEFAULT_RFI_ID = "";
    public static readonly DEFAULT_RFI_CATEGORY_ID = "";
    public static readonly DEFAULT_FILENAME = "";
    public static readonly DEFAULT_FILEURL = "";
    public static readonly DEFAULT_CREATED_BY_USER_ID = null;
    public static readonly DEFAULT_IS_DELETED = false;
    public static readonly DEFAULT_UNIQUE_ID = "";

    @observable
    public id: string | null = RFIFormFileModel.DEFAULT_ID;
    @observable
    public rfiId: string = RFIFormFileModel.DEFAULT_RFI_ID;
    @observable
    public rfiCategoryId: string = RFIFormFileModel.DEFAULT_RFI_CATEGORY_ID;
    @observable
    public fileName: string = RFIFormFileModel.DEFAULT_FILENAME;
    @observable
    public fileURL: string = RFIFormFileModel.DEFAULT_FILEURL;
    @observable
    public createdByUserId: string | null = RFIFormFileModel.DEFAULT_CREATED_BY_USER_ID;
    @observable
    public isDeleted: boolean = RFIFormFileModel.DEFAULT_IS_DELETED;
    @observable
    public uniqueId: string | null = RFIFormFileModel.DEFAULT_UNIQUE_ID;

    @action
    public reset = () => {
        this.id = RFIFormFileModel.DEFAULT_ID;
        this.rfiId = RFIFormFileModel.DEFAULT_RFI_ID;
        this.rfiCategoryId = RFIFormFileModel.DEFAULT_RFI_CATEGORY_ID;
        this.fileName = RFIFormFileModel.DEFAULT_FILENAME;
        this.fileURL = RFIFormFileModel.DEFAULT_FILEURL;
        this.createdByUserId = RFIFormFileModel.DEFAULT_CREATED_BY_USER_ID;
        this.isDeleted = RFIFormFileModel.DEFAULT_IS_DELETED;
        this.uniqueId = RFIFormFileModel.DEFAULT_UNIQUE_ID;
    };

    @action
    public fromDto(dto: RFIFileDTO): void {
        for (let key in dto) {
            if (dto.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(dto[key]);
                } else {
                    this[key] = dto[key];
                }
            }
        }
    }

    public toDto(): RFIFileDTO {
        const rFIFormFileModel: RFIFileDTO = {
            id: this.id,
            rfiId: this.rfiId,
            rfiCategoryId: this.rfiCategoryId,
            fileName: this.fileName,
            fileURL: this.fileURL,
            createdByUserId: this.createdByUserId,
            isDeleted: this.isDeleted,
            uniqueId: this.uniqueId,
        };

        return rFIFormFileModel;
    }
}

export interface RFIFileDTO {
    id: string | null;
    rfiId: string | null;
    rfiCategoryId: string | null;
    fileName: string;
    fileURL: string;
    createdByUserId: string | null;
    isDeleted: boolean;
    uniqueId: string | null;
}
