import { computed } from "mobx";
import { FieldType, ViewModelBase } from "@shoothill/core";
import moment from "moment";
import { ProjectSiteAccessItemModel } from "./ProjectSiteAccessItemModel";

export class ProjectSiteAccessItemViewModel extends ViewModelBase<ProjectSiteAccessItemModel> {
    constructor(item: ProjectSiteAccessItemModel) {
        super(item);
    }

    @computed
    public get id() {
        return this.model.id;
    }

    @computed
    public get isSignIn() {
        return this.model.isSignIn;
    }

    @computed
    public get visitDate() {
        return this.model.visitDate;
    }

    @computed
    public get visitDateFormatted() {
        return this.model.visitDate ? `${moment(this.model.visitDate).format("DD/MM/YYYY")} @ ${moment(this.model.visitDate).format("HH:mm")}` : "";
    }

    @computed
    public get visitorFullName() {
        return this.model.visitorFullName;
    }

    @computed
    public get priorityEmployerName() {
        return this.model.priorityEmployerName;
    }

    @computed
    public get lastThreePhoneDigits() {
        return this.model.lastThreePhoneDigits;
    }

    @computed
    public get visitorTypeName() {
        return this.model.visitorTypeName;
    }

    @computed
    public get healthProblemsFormatted() {
        return this.model.healthProblems ? "Y" : "N";
    }

    @computed
    public get lastThreePhoneDigitsFormatted() {
        return this.model.lastThreePhoneDigits.toString().padStart(3, "0");
    }

    @computed
    public get ramsTitle() {
        return this.model.ramsTitle;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public async isFieldValid(fieldName: keyof FieldType<ProjectSiteAccessItemModel>): Promise<boolean> {
        return true;
    }
}
