import * as MobX from "mobx";
import { ModelBase } from "@shoothill/core";
import { NumberLiteralType } from "typescript";

export class IESubcategoryModel extends ModelBase<IESubcategoryModel, IESubcategoryModelDTO> {
    public id: string = "";
    public createdDate: string = "";
    public isDeleted: boolean = false;
    public originatorId: string | null = null;
    public subCategory: number = 0;
    public categoryId: string = "";

    fromDto(model: IESubcategoryModelDTO): void {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    toDto(model: IESubcategoryModel): void {}
}

export type IESubcategoryModelDTO = {
    id: string;
    createdDate: string;
    isDeleted: boolean;
    originatorId: string | null;
    subCategory: number;
    categoryId: string;
};
