import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import * as Mui from "@material-ui/core";
import { useObserver } from "mobx-react-lite";
import { isNullOrUndefined, Loader, useRouter } from "@shoothill/core";
import UserViewModel from "Globals/ViewModels/UserViewModel";
import styled from "styled-components";
import * as Defaults from "Globals/Defaults/TableOptions";
import { DarwinPage, DarwinPageTitle, DarwinTablePageContent } from "Globals/Styles/AppStyling";
import { AppUrls } from "AppUrls";
import { User } from "Globals/Models";
import { formatDateTime } from "Utils/Format";
import { UserAddEditModal } from "./UserAddEditModal";
import { PatchedPagination } from "../../../Components/Table/PatchedPagination";

export const Container = styled(Mui.Box)`
    .MuiTableCell-root {
        font-size: 0.8rem;
    }
    .MuiTableSortLabel-root {
        :hover {
            color: lightgray;
        }
    }
    .MuiTableRow-root {
        cursor: pointer;
        :hover {
            background-color: lightgray;
        }
    }
`;

export const UserListContainer: React.FC = () => {
    const [viewModel] = useState(() => UserViewModel.Instance);
    const { history } = useRouter();
    useEffect(() => {
        const fetchData = async () => {
            const apiResult = await viewModel.loadUsersAsync();
        };

        fetchData();

        return () => {
            // Clean up after yourself
            viewModel.showAddEditModal(false);
        };
    }, []);

    useEffect(() => {
        //setUserOpen(true);
        /*         if (userListAddMatch !== undefined) {
            //handleNewClick();
        } */
    }, [location]);

    const handleRowClick = (e: any, rowData: any) => {
        const url: string = AppUrls.Client.Admin.User.Detail.replace(":userid", rowData.id);
        history.push(url);
    };

    const handleUserClose = () => {
        viewModel.showAddEditModal(false);
        history.push(AppUrls.Client.Admin.User.List);
    };

    const handleAddedUser = async (userId: string) => {
        viewModel.showAddEditModal(false);
        history.push(AppUrls.Client.Admin.User.List);
    };

    if (viewModel.IsLoading) return <Loader />;

    return useObserver(() => (
        <>
            <UserAddEditModal onClose={handleUserClose} open={viewModel.getShowAddEditModal} onUpsert={handleAddedUser} title="Add new user" confirmText="Add user" />
            <DarwinPage>
                <DarwinPageTitle>
                    <Mui.Typography variant="h1" color="textPrimary">
                        Users
                    </Mui.Typography>
                </DarwinPageTitle>
                <DarwinTablePageContent>
                    <MaterialTable
                        columns={[
                            { title: "First name", field: "firstName" },
                            { title: "Surname", field: "lastName" },
                            { title: "Email", field: "emailAddress" },
                            { title: "Access Level", field: "role", render: (rowData: User) => <Mui.Box>{rowData.getRoleListFormatted}</Mui.Box> },
                            { title: "Failed Count", field: "accessFailedCount" },
                            {
                                title: "Last Logged In",
                                field: "lastLoginDate",
                                render: (rowData: User) => <Mui.Box>{formatDateTime(isNullOrUndefined(rowData.lastLoginDate) === false ? rowData.lastLoginDate! : "")}</Mui.Box>,
                                type: "datetime",
                                sorting: true,
                                filtering: false,
                                width: 150,
                            },
                        ]}
                        components={{
                            Pagination: PatchedPagination,
                        }}
                        options={Defaults.GetDarwinTableOptions()}
                        data={viewModel.getUserForList}
                        onRowClick={handleRowClick}
                        title=""
                    />
                </DarwinTablePageContent>
            </DarwinPage>
        </>
    ));
};
