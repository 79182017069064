import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText, IconButton, TextField, Typography } from "@material-ui/core";
import { Cell, Grid, useRouter } from "@shoothill/core";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { PermitViewModel } from "./PermitViewModel";
import { Form, FormSection } from "Views/PurchaseOrder/Form/Views/Forms";
import { FormHeader } from "Views/PurchaseOrder/Form/Views/FormHeaders";
import { DashedDivider, SolidDivider } from "Views/PurchaseOrder/Form/Views/Dividers";
import { formatPermitNumber } from "Utils/Format";
import { pxToRem, theme } from "Globals/Styles/AppTheme";
import { BackButtonHolder } from "Views/Project/Commercial/IETables/IEGrid.Styles";
import { AppUrls } from "AppUrls";
import { CustomArrow } from "Views/Project/CustomComponents";
import { useLocation } from "react-router-dom";
import { DynamicQuestionAnswers } from "./DynamicQuestionAnswers";
import { DefaultButton, PrimaryButton } from "Components/Buttons/Buttons";
import { DarwinTimeSelect, IDarwinTimeSelectProps } from "Components/TimeSelect/DarwinTimeSelect";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Signature } from "Components/Signature/Signature";
import { DarwinSelect } from "Components/AutoComplete/DarwinSelect";

interface IProps {
    className?: string;
}

const PermitFormViewBase: React.FC<IProps> = (props) => {
    // #region Code Behind

    const { match } = useRouter();
    const { projectid, permitTypeId, permitId } = match.params as any;
    const [viewModel] = useState(() => new PermitViewModel(permitId, projectid, permitTypeId));
    const { history } = useRouter();
    const location = useLocation();
    const [sideOpen, setSideOpen] = useState<boolean>(false);

    useEffect(() => {
        return () => {
            viewModel.reset();
        };
    }, []);

    const COLUMNS8 = "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr";

    const onSubmit = (event: any) => {
        event.preventDefault();
    };

    return useObserver(() => {
        return (
            <Form className={props.className} onSubmit={onSubmit}>
                <BackButtonHolder
                    onClick={() => {
                        //history.push(AppUrls.Client.Project.Construction.replace(":projectid", projectid ? projectid : viewModel.model.projectId) + "#permits");
                        //EN: I think we can just use goBack here which will help with the site tablet view
                        history.goBack();
                    }}
                    style={{ minHeight: "30px", paddingTop: "3px" }}
                >
                    <div style={{ position: "relative", height: "30px", display: "flex", marginLeft: "10px" }}>
                        <div style={{ position: "absolute", top: "5px" }}>
                            <CustomArrow size={"8px"} color={theme.palette.blue.main} type={"left"} />
                        </div>
                        <div style={{ marginLeft: "20px", fontSize: "18px", fontWeight: "bold", color: theme.palette.blue.main }}>
                            Back to Permits ({viewModel.getProjectTitle})
                        </div>
                    </div>
                </BackButtonHolder>

                <DashedDivider gutterBottom={false} gutterTop={false} borderThickness={2} borderOpacity={0.5} />

                <HeaderContainer>
                    <FormHeader displayName={`${viewModel.model.id ? formatPermitNumber(viewModel.model.permitNumber) : "New permit"} - ${viewModel.getPermitTypeName} permit`} />
                </HeaderContainer>

                <FormSection>
                    <ProjectDetailsContainer>
                        <div>
                            <span>Date:</span> {viewModel.getTodayDateFormatted}
                        </div>
                        <div>
                            <span>Project reference:</span> {viewModel.getProjectReferenceFormatted}
                        </div>
                        <div>
                            <span>Project name:</span> {viewModel.getProjectName}
                        </div>
                    </ProjectDetailsContainer>
                </FormSection>

                <FormSection>
                    {/* Default options */}
                    <DashedDivider gutterBottom={true} borderThickness={2} borderOpacity={0.5} />

                    <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8} style={{ marginBottom: "20px" }}>
                        <Cell ts={1} ds={1}>
                            <DarwinTimeSelect
                                displayName="Est. start time:"
                                execute={(value: string | null) => viewModel.setValue("estimatedStartTime", value)}
                                onBlur={() => viewModel.isFieldValid("estimatedStartTime")}
                                placeholder="Please select"
                                validationMessage={viewModel.getError("estimatedStartTime")}
                                value={viewModel.getValue("estimatedStartTime")}
                                canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                            />
                        </Cell>

                        <Cell ts={1} ds={1}>
                            <DarwinTimeSelect
                                displayName="Est. end time:"
                                execute={(value: string | null) => viewModel.setValue("estimatedEndTime", value)}
                                onBlur={() => viewModel.isFieldValid("estimatedEndTime")}
                                placeholder="Please select"
                                validationMessage={viewModel.getError("estimatedEndTime")}
                                value={viewModel.getValue("estimatedEndTime")}
                                canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                            />
                        </Cell>

                        <Cell ts={3} ds={3}>
                            <NumberInputContainer>
                                <TextField
                                    type="number"
                                    value={viewModel.getValue("numWorkingUnderPermit")}
                                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => viewModel.setValue("numWorkingUnderPermit", e.target.value)}
                                    fullWidth
                                    error={viewModel.getError("numWorkingUnderPermit") !== ""}
                                    placeholder={"0.00"}
                                    disabled={viewModel.isFormDisabled || viewModel.IsLoading}
                                    label={"No. of individuals working under this permit"}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    helperText={viewModel.getError("numWorkingUnderPermit")}
                                />
                            </NumberInputContainer>
                        </Cell>

                        <Cell ts={3} ds={3}>
                            <NumberInputContainer>
                                <TextField
                                    type="number"
                                    value={viewModel.getValue("numWorkingInArea")}
                                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => viewModel.setValue("numWorkingInArea", e.target.value)}
                                    fullWidth
                                    error={viewModel.getError("numWorkingInArea") !== ""}
                                    placeholder={"0.00"}
                                    disabled={viewModel.isFormDisabled || viewModel.IsLoading}
                                    label={"No. of individuals working in area not part of these works"}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    helperText={viewModel.getError("numWorkingInArea")}
                                />
                            </NumberInputContainer>
                        </Cell>
                    </Grid>

                    <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
                        <Cell ts={8} ds={8}>
                            <TextField
                                id="exact-location-of-work"
                                multiline
                                minRows={4}
                                value={viewModel.model.exactLocationOfWork}
                                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                                    viewModel.setValue<string>("exactLocationOfWork", event.target.value)
                                }
                                fullWidth
                                className="textarea"
                                label="Exact location of work:"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                placeholder={`Please describe the exact location of the work please be as specific as possible`}
                                disabled={viewModel.isFormDisabled || viewModel.IsLoading}
                                error={viewModel.getError("exactLocationOfWork") !== ""}
                                helperText={viewModel.getError("exactLocationOfWork")}
                            />
                        </Cell>

                        <Cell ts={8} ds={8}>
                            <TextField
                                id="signage-notices-locations"
                                multiline
                                minRows={4}
                                value={viewModel.model.signageNoticesLocations}
                                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                                    viewModel.setValue<string>("signageNoticesLocations", event.target.value)
                                }
                                fullWidth
                                className="textarea"
                                label="Signage notices locations:"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                placeholder={`Please be as specific as possible`}
                                disabled={viewModel.isFormDisabled || viewModel.IsLoading}
                                error={viewModel.getError("signageNoticesLocations") !== ""}
                                helperText={viewModel.getError("signageNoticesLocations")}
                            />
                        </Cell>

                        <Cell ts={8} ds={8}>
                            <SolidDivider gutterBottom={true} borderThickness={2} borderOpacity={0.5} />
                            <SubtitleContainer>
                                <Typography variant="h2">{viewModel.getPermitTypeName} reason</Typography>
                            </SubtitleContainer>
                            <TextField
                                id="descriptions-of-works-being-done"
                                multiline
                                minRows={4}
                                value={viewModel.model.descriptionOfWorksBeingUndertaken}
                                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                                    viewModel.setValue<string>("descriptionOfWorksBeingUndertaken", event.target.value)
                                }
                                fullWidth
                                className="textarea"
                                label="Description of works being undertaken:"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                placeholder={`Please be as specific as possible`}
                                disabled={viewModel.isFormDisabled || viewModel.IsLoading}
                                error={viewModel.getError("descriptionOfWorksBeingUndertaken") !== ""}
                                helperText={viewModel.getError("descriptionOfWorksBeingUndertaken")}
                            />
                        </Cell>
                    </Grid>
                </FormSection>

                <FormSection>
                    {/* Dynamic options */}
                    <DynamicQuestionAnswers
                        questionAnswers={viewModel.permitQuestionAnswerViewModels}
                        permitTypeName={viewModel.getPermitTypeName}
                        isLoading={viewModel.IsLoading}
                        isFormDisabled={viewModel.isFormDisabled}
                    />
                </FormSection>

                <FormSection>
                    <SolidDivider gutterBottom={true} borderThickness={2} borderOpacity={0.5} />
                    {/* Authorisations */}
                    <SubtitleContainer>
                        <Typography variant="h2">Authorisations</Typography>
                    </SubtitleContainer>
                    <AuthorisationsContainer>
                        <p>
                            "All signatories to this permit confirm that they understand the precautions prior to and during the work. The recipient of this permit is responsible
                            for the safety of all persons and equipment involved. The authorised person (Site Manager) can cancel this permit at any time."
                        </p>
                    </AuthorisationsContainer>
                </FormSection>

                <FormSection>
                    <DashedDivider gutterBottom={true} borderThickness={2} borderOpacity={0.5} />
                    {/* Signatures */}
                    <SignatureContainer>
                        <SubtitleContainer>
                            <Typography variant="h2">Permit issued by</Typography>
                        </SubtitleContainer>
                        <div>
                            <TextField
                                type="text"
                                value={viewModel.getValue("issuedByUserName")}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => viewModel.setValue("issuedByUserName", e.target.value)}
                                fullWidth
                                error={viewModel.getError("issuedByUserName") !== ""}
                                disabled={viewModel.isFormDisabled || viewModel.IsLoading}
                                label={"Name:"}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                helperText={viewModel.getError("issuedByUserName")}
                            />
                            <div>
                                <Signature
                                    displayName={"Your signature:"}
                                    execute={(data: any) => viewModel.setValue<string>("issuedBySignatureURL", data)}
                                    value={viewModel.model.issuedBySignatureURL}
                                    canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                                />
                                {viewModel.getError("issuedBySignatureURL") !== "" && (
                                    <FormHelperText style={{ color: "#f44336" }}>{viewModel.getError("issuedBySignatureURL")}</FormHelperText>
                                )}
                            </div>
                        </div>
                    </SignatureContainer>

                    <SignatureContainer>
                        <SubtitleContainer>
                            <Typography variant="h2">Contractor issued to</Typography>
                        </SubtitleContainer>
                        <div>
                            <DarwinSelect
                                displayName="Name:"
                                execute={(data: { id: string; displayName: string }) => viewModel.handleSetInductionUser(data)}
                                fullWidth={false}
                                getOptionLabel={(option: any) => option.displayName}
                                options={viewModel.inductionUsers}
                                placeholder="Please select user"
                                value={viewModel.inductionUser}
                                canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                            />
                            <div>
                                <Signature
                                    displayName={"Your signature:"}
                                    execute={(data: any) => viewModel.setValue<string>("issuedToSignatureURL", data)}
                                    value={viewModel.model.issuedToSignatureURL}
                                    canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                                />
                                {viewModel.getError("issuedToSignatureURL") !== "" && (
                                    <FormHelperText style={{ color: "#f44336" }}>{viewModel.getError("issuedToSignatureURL")}</FormHelperText>
                                )}
                            </div>
                        </div>
                    </SignatureContainer>

                    {viewModel.hasId && (
                        <React.Fragment>
                            {viewModel.canDisplaySurrenderedBy && (
                                <SignatureContainer>
                                    <SubtitleContainer>
                                        <Typography variant="h2">Surrendered by</Typography>
                                    </SubtitleContainer>
                                    <div>
                                        <TextField
                                            disabled={!viewModel.canSaveForm || viewModel.IsLoading}
                                            error={viewModel.getError("surrenderedByUserName") !== ""}
                                            fullWidth
                                            helperText={viewModel.getError("surrenderedByUserName")}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            label={"Name:"}
                                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => viewModel.setSurrenderedByUserName(e.target.value)}
                                            type="text"
                                            value={viewModel.getValue("surrenderedByUserName") ?? ""}
                                        />
                                        <div>
                                            <Signature
                                                canExecute={viewModel.canSaveForm && !viewModel.IsLoading}
                                                displayName={"Your signature:"}
                                                execute={viewModel.setSurrenderedBySignatureUrl}
                                                value={viewModel.getValue("surrenderedBySignatureURL")}
                                            />
                                            {viewModel.getError("surrenderedBySignatureURL") !== "" && (
                                                <FormHelperText style={{ color: "#f44336" }}>{viewModel.getError("surrenderedBySignatureURL")}</FormHelperText>
                                            )}
                                        </div>
                                    </div>
                                </SignatureContainer>
                            )}
                            {viewModel.canDisplayCancelledBy && (
                                <SignatureContainer>
                                    <SubtitleContainer>
                                        <Typography variant="h2">Cancelled by</Typography>
                                    </SubtitleContainer>
                                    <div>
                                        <TextField
                                            disabled={!viewModel.canSaveForm || viewModel.IsLoading}
                                            error={viewModel.getError("cancelledByUserName") !== ""}
                                            fullWidth
                                            helperText={viewModel.getError("cancelledByUserName")}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            label={"Name:"}
                                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => viewModel.setCancelledByUserName(e.target.value)}
                                            type="text"
                                            value={viewModel.getValue("cancelledByUserName") ?? ""}
                                        />
                                        <div>
                                            <Signature
                                                canExecute={viewModel.canSaveForm && !viewModel.IsLoading}
                                                displayName={"Your signature:"}
                                                execute={viewModel.setCancelledBySignatureUrl}
                                                value={viewModel.getValue("cancelledBySignatureURL")}
                                            />
                                            {viewModel.getError("cancelledBySignatureURL") !== "" && (
                                                <FormHelperText style={{ color: "#f44336" }}>{viewModel.getError("cancelledBySignatureURL")}</FormHelperText>
                                            )}
                                        </div>
                                    </div>
                                </SignatureContainer>
                            )}
                        </React.Fragment>
                    )}
                </FormSection>

                <FormSection>
                    <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
                        {viewModel!.server.HaveValidationMessage && (
                            <>
                                <Cell ts={8} ds={8}>
                                    <Alert severity="error">
                                        <AlertTitle>Error</AlertTitle>
                                        {viewModel!.server.ValidationMessage}
                                    </Alert>
                                </Cell>
                            </>
                        )}
                    </Grid>
                </FormSection>

                {viewModel.canSaveForm && (
                    <>
                        <div style={{ margin: `0 ${pxToRem(30)} 0 ${pxToRem(30)}` }}>
                            <SolidDivider gutterBottom={true} borderThickness={2} borderOpacity={0.5} />
                        </div>
                        <FormSection>
                            {/* Buttons */}
                            <ButtonsContainer>
                                <PrimaryButton displayName="Save" execute={() => viewModel.upsert()} fullWidth={true} canExecute={!viewModel.IsLoading} />
                                <DefaultButton displayName="Cancel" execute={() => viewModel.handleCancel(projectid)} fullWidth={true} canExecute={!viewModel.IsLoading} />
                            </ButtonsContainer>
                        </FormSection>
                    </>
                )}
            </Form>
        );
    });
};

export const PermitFormView = styled(PermitFormViewBase)`
    display: flex;
    flex: 1;
    flex-direction: column;

    form {
        position: relative;
    }
`;

const HeaderContainer = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 70px;

    // Header
    > div:nth-child(1) {
        margin: 4px 30px 13.5px 30px;
    }
`;

const ProjectDetailsContainer = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    max-width: 700px;

    > div {
        font-size: 12px;
    }

    > div > span {
        font-weight: bold;
    }
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;

    > button {
        max-width: 262px;
    }

    > button:nth-child(1) {
        margin-right: 30px;
    }
`;

const NumberInputContainer = styled.div`
    .MuiFormControl-root {
        display: flex;
        flex-direction: row;
        width: auto;
        align-items: center;
        padding-top: 20px;

        label {
            display: flex;
            position: relative;
            margin: 0px;
            margin-right: 10px;
        }

        .MuiInputBase-root {
            width: auto;
            display: flex;
            margin: 0px;
            max-width: 70px;
        }

        .MuiFormHelperText-root {
            position: absolute;
            top: 50px;
        }
    }
`;

const AuthorisationsContainer = styled.div`
    p {
        font-size: 12px;
    }
`;

interface SubtitleProps {
    margin?: string;
}

export const SubtitleContainer = styled.div<SubtitleProps>`
    h2 {
        margin-bottom: ${(props) => (props.margin ? props.margin : "10px")};
    }
`;

const SignatureContainer = styled.div`
    display: flex;
    flex-direction: column;

    > div:nth-child(2) {
        display: flex;
        flex-direction: row;

        > div {
            max-width: 262px;
            margin-right: 30px;
            width: 100%;

            canvas {
                border: 1px solid #d4d4d4;
            }
        }
    }
`;
