import { debounce } from "@material-ui/core";
import { ViewModelBase, isEmptyOrWhitespace } from "@shoothill/core";
import { action, computed, observable, runInAction } from "mobx";

import { AppUrls } from "AppUrls";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { ProjectGeneralViewModel } from "Views/Project/General/ProjectGeneralViewModel";
import { PlansOfWorkItemViewModel } from ".";
import { AddPlanOfWorkViewModel } from "../AddPlanOfWork";

export class PlansOfWorkViewModel extends ViewModelBase<any> {
    public server: ServerViewModel = new ServerViewModel();
    public general: ProjectGeneralViewModel = ProjectGeneralViewModel.Instance;

    private readonly DEBOUNCE_VALUE_MS = 200;

    private projectId: string;
    private plansOfWork = observable<PlansOfWorkItemViewModel>([]);

    constructor(projectId: string) {
        super({});

        this.projectId = projectId;

        // Load general data on the project.
        this.general.apiGetbyId(this.projectId);

        // Load plans of work.
        this.apiLoadPlansOfWorkAsync();
    }

    // #region Filtering

    @observable
    public searchString: string = "";

    @observable
    public filterSearchString: string = "";

    public getSearchString = () => {
        return computed(() => this.searchString);
    };

    @action
    public setSearchString = (value: string) => {
        this.searchString = value;
        this.setFilterSearchString(value);
    };

    private setFilterSearchString = debounce(
        action((value: string) => {
            this.filterSearchString = value;
        }),
        this.DEBOUNCE_VALUE_MS,
    );

    @computed
    public get filteredPlansOfWork(): PlansOfWorkItemViewModel[] {
        if (isEmptyOrWhitespace(this.filterSearchString)) {
            return this.plansOfWork.slice();
        }

        return this.plansOfWork.filter((vm) => vm.matchesFilter(this.filterSearchString)).slice();
    }

    // #endregion Filtering

    // #region Add Plan of Work

    @observable
    public addPlanOfWorkViewModel: AddPlanOfWorkViewModel | null = null;

    @computed
    public get canDisplayAddPlanOfWork() {
        return this.addPlanOfWorkViewModel !== null;
    }

    @action
    public displayAddPlanOfWork = () => {
        this.addPlanOfWorkViewModel = new AddPlanOfWorkViewModel(this.projectId!, this.closeAddPlanOfWork);
    };

    @action
    public closeAddPlanOfWork = (refreshPage: boolean) => {
        this.addPlanOfWorkViewModel = null;

        if (refreshPage) {
            this.apiLoadPlansOfWorkAsync();
        }
    };

    // #endregion Add Plan of Work

    // #region Actions

    public navigateToOutputs = (viewModel: PlansOfWorkItemViewModel) => {
        this.history.push(`plansofwork/${viewModel.id}/outputs`);
    };

    // #endregion Actions

    // #region Api Actions

    @action
    public apiLoadPlansOfWorkAsync = async (): Promise<void> => {
        await this.server.query<any>(
            () => this.Get(AppUrls.Server.Projects.ProjectTrackers.OutputTracker.PlansOfWork.GetByProjectId.replace("{projectid}", this.projectId)),
            (result) => {
                runInAction(() => {
                    this.plansOfWork.replace(
                        result.map((dto: any) => {
                            const planOfWork = new PlansOfWorkItemViewModel();

                            planOfWork.id = dto.id;
                            planOfWork.name = dto.name;
                            planOfWork.statusName = dto.statusName;
                            planOfWork.statusColor = dto.statusColor;
                            planOfWork.statusTextColor = dto.statusTextColor;

                            return planOfWork;
                        }),
                    );
                });
            },
        );

        if (this.server.HaveValidationMessage) {
            this.setSnackMessage(this.server.ValidationMessage);
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }
    };

    // #endregion Api Actions

    // #region Snack Bar

    public SNACKERROR = "error";

    @observable
    public snackbarState = false;

    @observable
    public snackType = "";

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public snackMessage = "";

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    // #endregion Snack Bar

    // #region Boilerplate

    isFieldValid(fieldName: string | number | symbol, value: any): Promise<boolean> {
        throw new Error("Method not implemented.");
    }
    beforeUpdate?(fieldName: string | number | symbol, value: any) {
        throw new Error("Method not implemented.");
    }
    afterUpdate?(fieldName: string | number | symbol, value: any): void {
        throw new Error("Method not implemented.");
    }

    // #endregion Boilerplate
}
