import { ModelBase } from "@shoothill/core";
import { ArrayNotEmpty, IsEmail, IsNotEmpty, ValidateIf } from "class-validator";
import { observable } from "mobx";

export class IssueModel extends ModelBase<IssueModel> {
    // #region Defaults

    public static readonly DEFAULT_ISSUEDRAWINGS = [];
    public static readonly DEFAULT_TOEMAILADDRESS = "";
    public static readonly DEFAULT_CCEMAILADDRESS = "";
    public static readonly DEFAULT_NOTES = "";
    public static readonly DEFAULT_ISISSUE = false;

    // #endregion Defaults

    // #region Related Properties
    // #endregion Related Properties

    // #region Properties

    @observable
    @ArrayNotEmpty({ message: "You must select at least one drawing" })
    public issueItems = observable<string>(IssueModel.DEFAULT_ISSUEDRAWINGS);

    @observable
    @ValidateIf((model) => model.isIssue)
    @IsNotEmpty({ message: "You must enter an email address" })
    @IsEmail({}, { message: "Email address is not in a valid format" })
    public toEmailAddress: string = IssueModel.DEFAULT_TOEMAILADDRESS;

    @observable
    @ValidateIf((model) => model.isIssue && model.ccEmailAddress !== IssueModel.DEFAULT_CCEMAILADDRESS)
    @IsEmail({}, { message: "Email address is not in a valid format" })
    public ccEmailAddress: string = IssueModel.DEFAULT_CCEMAILADDRESS;

    @observable
    @IsNotEmpty({ message: "You must enter a note" })
    public notes: string = IssueModel.DEFAULT_NOTES;

    @observable
    public isIssue: boolean = IssueModel.DEFAULT_ISISSUE;

    // #endregion Properties

    // #region Methods

    fromDto(model: any): void {
        throw new Error("Method not implemented.");
    }

    public toDto(): any {
        return {
            toEmailAddress: this.toEmailAddress === IssueModel.DEFAULT_TOEMAILADDRESS ? null : this.toEmailAddress,
            ccEmailAddress: this.ccEmailAddress === IssueModel.DEFAULT_CCEMAILADDRESS ? null : this.ccEmailAddress,
            notes: this.notes,
            drawingIds: this.issueItems,
            isIssue: this.isIssue,
        };
    }

    // #endregion Methods
}
