import { observable, runInAction, action } from "mobx";
import { FieldType, ViewModelBase } from "@shoothill/core";
import { EndOfDayModel } from "./EndOfDayModel";
import { AppUrls } from "../../../../../AppUrls";
import { ServerViewModel } from "../../../../../Globals/ViewModels/ServerViewModel";
import { AuditQuestionViewModel } from "./AuditQuestionViewModel";
import { viewModelModelLink } from "../../../../../Utils/Utils";
import { InspectionsAndRelatedDTO } from "../../Inspections/InspectionsAndRelated";
import { AuditQuestionModel } from "./AuditQuestionModel";
import { UserLookUpModel } from "../../Inspections/UserLookUpModel";

export class EndOfDayViewModel extends ViewModelBase<EndOfDayModel> {
    public server: ServerViewModel = new ServerViewModel();
    @observable
    public questionViewModels = observable<AuditQuestionViewModel>([]);
    @observable
    public users: any = [];
    @observable
    public editable: boolean = false;
    constructor(model = new EndOfDayModel()) {
        super(model);
        this.setDecorators(EndOfDayModel);
        (window as any).EndOfDayViewModel = this;
    }

    public surveyListItemLinkObserverDispose = viewModelModelLink(this.model.questionModels, this.questionViewModels, AuditQuestionViewModel);

    public isTodaysDate = (date: Date) => {
        const today = new Date();

        if (date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear()) {
            console.log("Date is today!");
            return true;
        }
        return false;
    };
    public loadQuestions = (): Promise<void> => {
        return this.server.query<any>(
            () => this.Get(`${AppUrls.Server.Projects.Construction.Audits.GetAuditQuestions}?endOfDay=true&projectId=${this.model.projectId}`),
            (result) => {
                runInAction(() => {
                    this.model.questionModels.clear();
                    for (const question of result.questions) {
                        let model = new AuditQuestionModel();
                        model.fromDto(question);
                        this.model.questionModels.push(model);
                    }
                    this.editable = true;
                    this.users.replace(UserLookUpModel.fromDtos(result.users));
                });
            },
        );
    };

    public loadAudit = (): Promise<void> => {
        return this.server.query<any>(
            () => this.Get(`${AppUrls.Server.Projects.Construction.Audits.GetEndOfDayAuditById}?auditId=${this.model.id}`),
            (result) => {
                runInAction(() => {
                    this.model.questionModels.clear();
                    for (const question of result.audit.questions) {
                        let model = new AuditQuestionModel();
                        model.fromDto(question);
                        this.model.questionModels.push(model);

                        model.auditDocuments = [];
                        for (const document of question.auditDocuments) {
                            model.auditDocuments.push(document);
                        }
                    }

                    this.users.replace(UserLookUpModel.fromDtos(result.users));
                    this.model.fromDto(result.audit);
                    this.model.auditUser = this.users.find((x: any) => x.id === result.audit.auditUserId);

                    if (this.isTodaysDate(this.model.createdDate!)) {
                        this.editable = true;
                    }
                });
            },
        );
    };

    public canSubmit = () => {
        //return isNullOrUndefined(this.model.id);
        return true;
    };

    @action
    public submit = async (handleClose: () => void) => {
        let isValid = true;
        isValid = await this.isModelValid();

        for (const question of this.questionViewModels) {
            let valid = await question.isModelValid();
            if (!valid) {
                isValid = false;
            }
        }

        return this.server.command<InspectionsAndRelatedDTO>(
            () => this.Post(`${AppUrls.Server.Projects.Construction.Audits.UpsertEndOfDayAudit}`, this.model.toDto()),
            (result) => {
                runInAction(() => {
                    if (handleClose) {
                        handleClose();
                    }
                });
            },
            () => Promise.resolve(isValid),
            "There was an error trying to add the amendment",
        );
    };

    public cancel = (handleClose: () => void) => {
        runInAction(() => {
            if (handleClose) {
                handleClose();
            }
        });
    };

    public updateSignature = (data: any) => {
        this.setValue("signatureBase64", data);
    };
    @action
    public setAuditUser = (type: UserLookUpModel) => {
        this.model.auditUserId = type.id;
        this.model.auditUser = type;
    };

    public async isFieldValid(fieldName: keyof FieldType<EndOfDayModel>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }
    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
