// Libraries
import React, { useState } from "react";
import { useObserver } from "mobx-react-lite";
import { Cell, Grid, useRouter } from "@shoothill/core";
import { TextField } from "@material-ui/core";

// Styling & Images
import { DetailsHeader } from "Globals/Views/DetailsPage/DetailsHeader";
import { DetailsPage } from "Globals/Views/DetailsPage/DetailsPage.styles";
import { VariationRiskOppViewModel } from "./VariationRiskOppViewModel";
import { VariationRiskOppDTO, VariationRiskOppModel } from "./VariationRiskOppModel";
import { AppUrls } from "AppUrls";
import { formatCurrencyNoSign, formatDateTime } from "Utils/Format";
import { DarwinMoneyInput } from "Globals/Styles/Control/DarwinInput";
import { RiskOppAmendmentAddButton, RiskOppItem, RiskOppItemContentRow, RiskOppItemNoteRow, NewRiskOppContainer, NewRiskOppLabel } from "../RiskOpp/RiskOpp.styles";
import { BackButtonHolder } from "../IETables/IEGrid.Styles";
import { CustomArrow } from "Views/Project/CustomComponents";
import { theme } from "Globals/Styles/AppTheme";
import { IEGridItemViewModel } from "../IEmodels/IEGridItemViewModel";

export const VariationRiskOppView: React.FunctionComponent = () => {
    const { match } = useRouter();
    const { ieid, variationid, variationitemid } = match.params as any;
    const [viewModel] = useState(() => new VariationRiskOppViewModel(ieid, variationid, variationitemid));
    const { history } = useRouter();

    const COLUMNS8 = "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr";
    const COLUMNS12 = "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr";

    const renderRiskValueFormatted = (item: VariationRiskOppDTO) => {
        let className = "value-orange";

        if (item.riskValue < 0) {
            className = "value-green";
        } else if (item.riskValue > 0) {
            className = "value-red";
        }

        return (
            <>
                <div className="value-container">
                    <div>{item.isCurrent ? `Current ${formatDateTime(item.createdDate)} risk/opportunity:` : `${formatDateTime(item.createdDate)} Risk/opportunity:`} </div>
                    <div className={`value-wrapper ${className}`}>{formatCurrencyNoSign(item.riskValue)}</div>
                </div>
            </>
        );
    };

    const renderRiskOppHistory = () => {
        return viewModel.variationRiskOppAmendmentItems.map((item, index) => {
            return (
                <RiskOppItem>
                    <RiskOppItemContentRow>
                        <div className="riskOpp-group">{renderRiskValueFormatted(item)}</div>
                    </RiskOppItemContentRow>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ marginRight: "8px" }}>
                            <strong>Created by:</strong>
                        </div>
                        <div>{item.createdByUserName}</div>
                    </div>
                    <RiskOppItemNoteRow>
                        <div>
                            <strong>Notes:</strong>
                        </div>
                        <div className={"keep-newline-formatting"}>{item.note}</div>
                    </RiskOppItemNoteRow>
                </RiskOppItem>
            );
        });
    };

    return useObserver(() => (
        <DetailsPage>
            <BackButtonHolder
                onClick={() => {
                    if (IEGridItemViewModel.Instance.isCentral) {
                        history.push(AppUrls.Client.Central.View.replace(":ieid", ieid ? ieid : viewModel.model.ieId));
                    } else {
                        history.push(AppUrls.Client.Project.IE.replace(":ieid", ieid ? ieid : viewModel.model.ieId));
                    }
                }}
                style={{ minHeight: "30px", paddingTop: "3px" }}
            >
                <div style={{ position: "relative", height: "30px", display: "flex", marginLeft: "10px" }}>
                    <div style={{ position: "absolute", top: "5px" }}>
                        <CustomArrow size={"8px"} color={theme.palette.blue.main} type={"left"} />
                    </div>
                    <div style={{ marginLeft: "20px", fontSize: "18px", fontWeight: "bold", color: theme.palette.blue.main }}>Back to {viewModel.getIETitle}</div>
                </div>
            </BackButtonHolder>
            <DetailsHeader viewModel={viewModel.getHeader} />
            <div style={{ margin: "0 30px 17.5px 30px" }}>
                <NewRiskOppContainer>
                    <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
                        {/* Group of 8 columns */}
                        <Cell ts={1} ds={1}>
                            <NewRiskOppLabel>Current risk/opportunity:</NewRiskOppLabel>
                        </Cell>
                        <Cell ts={1} ds={1}>
                            <DarwinMoneyInput<VariationRiskOppModel>
                                type="number"
                                label=""
                                placeholder=""
                                validateOnBlur={true}
                                viewModel={viewModel}
                                fieldName="riskValue"
                                shrink={true}
                                maxLength={32}
                                editMode={viewModel.canAddRiskOppAmendment}
                            />
                        </Cell>
                    </Grid>

                    <TextField
                        id="amendment_notes"
                        multiline
                        rows={6}
                        value={viewModel.model.note}
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => viewModel.setNote(event.target.value)}
                        fullWidth
                        className="textarea"
                        label="Notes"
                        placeholder="
                        Example:
                        Risk - Gloves, £1,000
                        Risk - Fencing, £1,000
                        Opp - Screws, (£500)
                        "
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={!viewModel.canAddRiskOppAmendment}
                    />

                    <RiskOppAmendmentAddButton displayName="Add" execute={viewModel.handleSave} fullWidth={true} canExecute={viewModel.canAddRiskOppAmendment} />
                </NewRiskOppContainer>

                {renderRiskOppHistory()}
            </div>
        </DetailsPage>
    ));
};
