import { Checkbox } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React from "react";

import { OutputsItemViewModel } from "../Output";

interface IProps {
    viewModel: OutputsItemViewModel;
}

export const IssueCheckboxView: React.FC<IProps> = observer((props) => {
    const viewModel = props.viewModel;

    /**
     * This is required should you host this component on a table row
     * that is clickable.
     */
    const onLinkClick = (event: any) => {
        event.stopPropagation();
        viewModel.toggleIssue();
    };

    return <Checkbox checked={viewModel.issue} onClick={onLinkClick} />;
});
