import * as MobX from "mobx";
import { ModelBase } from "@shoothill/core";
import { NumberLiteralType } from "typescript";

export class IECategoryModel extends ModelBase<IECategoryModel, IECategoryModelDTO> {
    public id: string = "";
    public createdDate: string = "";
    public isDeleted: boolean = false;
    public originatorId: string | null = null;
    public iEId: string = "";
    public categoryId: number = 0;

    fromDto(model: IECategoryModelDTO): void {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    toDto(model: IECategoryModel): void {}
}

export type IECategoryModelDTO = {
    id: string;
    createdDate: string;
    isDeleted: boolean;
    originatorId: string | null;
    iEId: string;
    categoryId: number;
};
