import { Snackbar } from "@material-ui/core";
import styled from "styled-components";

export interface IComponentProps {
    backgroundcolor?: string;
    additionalcss?: string;
}

export const AdminSnackBarStyle: any = styled(Snackbar)<IComponentProps>`
    .MuiSnackbarContent-root {
        font-size: 16px;
        padding: 5px 10px;
        background-color: ${(props) => (props.backgroundcolor !== undefined ? props.backgroundcolor : "#2196f3")};
        ${(props) => props.additionalcss};
    }
`;
