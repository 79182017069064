/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useRef } from "react";
import { Box, Typography } from "@material-ui/core";
import { AccountImageBox, AccountPage, BoxAccount, BoxPage } from "Globals/Styles/AccountStyling";
import { generateID, useRouter } from "@shoothill/core";
import { IWindowState } from "Views/Login/LoginView";
import logoUrl from "Content/darwinlogo.png";
import { AccountTitleBox } from "Views/Login/LoginView.style";
import { CancelButton } from "Globals/Styles/Control/Buttons";
import { AppUrls } from "AppUrls";

export const SessionExpiredView: React.FC = () => {
    const router = useRouter();
    const componentRef: any = useRef(generateID());
    const [dimensions, setDimensions] = React.useState<IWindowState>({
        width: 0,
        height: 0,
    });

    ///
    /// updateWindowDimensions
    ///
    const updateWindowDimensions = () => {
        if (componentRef !== undefined && componentRef != null && componentRef.current !== null && componentRef.current !== undefined) {
            setDimensions({
                width: componentRef!.current!.offsetWidth,
                height: componentRef.current!.offsetHeight!,
            });
        }
    };

    useEffect(() => {
        updateWindowDimensions();
        window.addEventListener("resize", updateWindowDimensions);

        // tidy up after yourself
        return () => {
            window.removeEventListener("resize", updateWindowDimensions);
        };
    }, []);

    const inPortrait: boolean = dimensions.height > dimensions.width;
    const triangleHeight: string = (inPortrait === true ? (dimensions.width * 0.765).toString() : (dimensions.height * 0.765).toString()) + "px";

    const cancel = () => {
        router.history.push(AppUrls.Client.Home);
    };

    return (
        <>
            <AccountPage triangleheight={triangleHeight} ref={componentRef}>
                <div className="triangle"></div>
                <BoxPage>
                    <BoxAccount>
                        <AccountImageBox>
                            <img className="darwin-logo" alt={"Darwin Group Logo"} src={logoUrl} />
                        </AccountImageBox>
                        <AccountTitleBox>{/* <span>Project Management System</span> */}</AccountTitleBox>
                        <Box margin="50px 0 0 0" textAlign="center">
                            <Typography variant={"h2"} color={"error"}>
                                Your session has expired!
                            </Typography>
                        </Box>
                        <Box margin="50px 0 0 0" textAlign="center">
                            <CancelButton onClick={cancel}>
                                <span>Login</span>
                            </CancelButton>
                        </Box>
                    </BoxAccount>
                </BoxPage>
            </AccountPage>
        </>
    );
};
