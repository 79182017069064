import { ForgotPasswordModel, ForgotPasswordModelDTO } from "Globals/Models";
import { action } from "mobx";

import { AppUrls } from "AppUrls";
import { ApiResult, ViewModelBase } from "@shoothill/core";

export class ForgotPasswordViewModel extends ViewModelBase<ForgotPasswordModel> {
    constructor() {
        super(new ForgotPasswordModel());
        this.setDecorators(ForgotPasswordModel);
    }

    @action
    public ForgotPasswordAsync = async (): Promise<ApiResult<ForgotPasswordModelDTO>> => {
        return await this.Post<ForgotPasswordModelDTO>(AppUrls.Server.Account.ForgotPassword, this.model);
    };

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public async isFieldValid(fieldName: "emailAddress", value: any): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }
}
