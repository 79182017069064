import { formatAddress } from "Utils/Format";
import { GenericId } from "Globals/Models/GenericId";
import { ApiResult, FieldType, ViewModelBase } from "@shoothill/core";
import { ClientDetailModel } from "./ClientDetailModel";
import { observable, action, runInAction, computed } from "mobx";
import { AppUrls } from "AppUrls";
import type { ClientWithRelatedDTO } from "./ClientWithRelatedDTO";
import { DetailsHeaderModel } from "Globals/Views/DetailsPage/DetailsHeaderModel";
import { AddressModel, AddressModelDTO, UpdateContactIsPrimaryRequest } from "Globals/Models/Domain";
import type { ContactBaseDTO } from "Globals/Models/Domain";
import { ClientContactModelDTO } from "./ClientContactModel";
import { AddEditContactModel } from "Views/Contacts/AddEditContactModel";
import { DeleteContactRequest } from "Globals/Models/Domain/DeleteContactRequest";
import { ProjectListModelDTO } from "Views/Project/ProjectListModel";

export class ClientDetailViewModel extends ViewModelBase<ClientDetailModel> {
    private static _instance: ClientDetailViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable public errorMessage: string = "";

    @observable public showAEModal: boolean = false;

    @action
    public showAddEditModal(show: boolean) {
        this.showAEModal = show;
    }

    @computed
    public get getShowAddEditModal() {
        return this.showAEModal;
    }

    // @computed
    // public get getPurchaseOrdersList(): PurchaseOrderListTableModel[] {
    //     return this.model.purchaseOrders.slice().map((po) => {
    //         return po.toTableModel();
    //     });
    // }

    @computed
    public get getProjects(): ProjectListModelDTO[] {
        return this.model.currentProjects.slice();
    }

    public constructor() {
        super(new ClientDetailModel(), false);
        this.setDecorators(ClientDetailModel);
    }

    @action
    public cleanUp = () => {
        // TODO Any Cleanup Code here. e.g. if  a user or project or client etc, wipe it from the instance on page shutdown
    };

    @computed
    get getHeader(): DetailsHeaderModel {
        const retVal: DetailsHeaderModel = new DetailsHeaderModel();
        retVal.setValue("title", this.model.reference + " - " + this.model.name);
        if (this.model.addresses.length > 0) {
            const addModel: AddressModel = new AddressModel();
            // https://stackoverflow.com/questions/61482664/mobx-methods-object-is-not-a-function
            addModel.fromObservable(this.model.addresses[0]);
            const address: AddressModelDTO = addModel.toAddressDto();
            retVal.setValue("address", formatAddress(address));
        } else {
            retVal.setValue("address", "");
        }

        return retVal;
    }

    @computed get getContactList(): ContactBaseDTO[] {
        let retVal: ContactBaseDTO[] = [];

        const contacts: ClientContactModelDTO[] = this.model.contacts.slice().filter((a) => a.isDeleted === false);
        retVal.push(...contacts);

        return retVal;
    }

    @computed get getPrimaryContact(): ContactBaseDTO {
        let retVal: ContactBaseDTO = {
            id: undefined,
            createdDate: undefined,
            isDeleted: false,
            rowVersion: undefined,
            originatorId: undefined,
            firstName: "",
            contactTypeId: null,
            lastName: "",
            email: "",
            isPrimary: false,
            position: "",
            phone: "",
        };

        const contacts: ClientContactModelDTO[] = this.model.contacts.slice();

        if (contacts.length > 0) {
            const prime: ClientContactModelDTO | undefined = contacts.find((a) => a.isPrimary === true && a.isDeleted === false);

            if (prime === undefined) {
                retVal = contacts[0];
            } else {
                retVal = prime;
            }
        }

        return retVal;
    }

    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (await this.isModelValid()) {
            //Do stuff here
            this.errorMessage = "Form is valid";
        } else {
            this.errorMessage = "Form is not valid";
        }
    };

    public async isFieldValid(fieldName: keyof FieldType<ClientDetailModel>): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    @action
    public async loadClientAsync(clientId: string): Promise<ApiResult<ClientWithRelatedDTO>> {
        const request: GenericId = {
            id: clientId,
        };

        let apiResult = await this.Post<ClientWithRelatedDTO>(AppUrls.Server.Client.GetDetails, request);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                this.setClient(apiResult.payload);
            });
        }
        return apiResult;
    }

    public setClient(payload: ClientWithRelatedDTO) {
        this.model.fromDto(payload);
    }

    @action
    public updateClient(editedClient: ClientWithRelatedDTO) {
        this.setClient(editedClient);
    }

    public async deleteContact(id: string): Promise<ApiResult<number>> {
        const clientId: string = this.model.id!;
        const request: DeleteContactRequest = {
            id: id,
            sourceId: clientId,
        };

        let apiResult = await this.Post<number>(AppUrls.Server.Client.DeleteContact, request);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                if (apiResult.payload === 0) {
                    this.model.removeContact(id);
                }
            });
        }
        return apiResult;
    }

    public async updatContactIsPrimary(id: string, isPrimary: boolean): Promise<ApiResult<ClientContactModelDTO[]>> {
        const clientId: string = this.model.id!;
        const request: UpdateContactIsPrimaryRequest = {
            id: id,
            sourceId: clientId,
            isPrimary: isPrimary,
        };

        let apiResult = await this.Post<ClientContactModelDTO[]>(AppUrls.Server.Client.UpdateClientContactIsPrimary, request);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                this.model.replaceContacts(apiResult.payload);
            });
        }
        return apiResult;
    }

    public async addContact(addEdit: AddEditContactModel): Promise<ApiResult<ClientContactModelDTO>> {
        if (await this.isModelValid()) {
            const request: ClientContactModelDTO = addEdit.toClientContactDto(this.model.id!);

            let apiResult = await this.Post<ClientContactModelDTO>(AppUrls.Server.Client.UpsertContact, request);

            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.model.addContact(apiResult.payload);
                });
            }
            return apiResult;
        } else {
            this.errorMessage = "Form is not valid";
            return Promise.reject();
        }
    }

    public async updateContact(addEdit: AddEditContactModel): Promise<ApiResult<ClientContactModelDTO>> {
        if (await this.isModelValid()) {
            const request: ClientContactModelDTO = addEdit.toClientContactDto(this.model.id!);

            let apiResult = await this.Post<ClientContactModelDTO>(AppUrls.Server.Client.UpsertContact, request);

            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.model.replaceContact(apiResult.payload);
                });
            }
            return apiResult;
        } else {
            this.errorMessage = "Form is not valid";
            return Promise.reject();
        }
    }

    @action
    public async loadContactAsync(contactId: string): Promise<ApiResult<ClientContactModelDTO>> {
        const clientId: string = this.model.id!;
        const request: DeleteContactRequest = {
            id: contactId,
            sourceId: clientId,
        };

        let apiResult = await this.Post<ClientContactModelDTO>(AppUrls.Server.Client.GetClientContact, request);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                // TODO CMR
            });
        }
        return apiResult;
    }

    @action
    public setNewClient = () => {
        this.model.reset();
    };
}
