import { isEmptyOrWhitespace } from "@shoothill/core";
import MaterialTable from "material-table";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { PermitListModel } from "./PermitListModel";
import { PermitListViewModel } from "./PermitListViewModel";
import { DarwinSelect } from "../../../../Components/AutoComplete/DarwinSelect";
import { SHBox } from "../../../../Components/Box";
import { PrimaryButton } from "../../../../Components/Buttons/Buttons";
import { PatchedPagination } from "../../../../Components/Table/PatchedPagination";
import * as Defaults from "../../../../Globals/Defaults/TableOptions";
import { DarwinTablePageContent } from "../../../../Globals/Styles/AppStyling";
import { formatDate, formatTime } from "../../../../Utils/Format";

const Wrapper = styled.div`
    padding: 5px 30px;
`;

interface Props {
    projectId: string;
    projectName: string;
    projectReference: string;
}
export const PermitsView: React.FC<Props> = observer((props: Props) => {
    const [listViewModel] = useState(() => new PermitListViewModel());

    useEffect(() => {
        listViewModel.projectId = props.projectId;
        listViewModel.loadPermitsAndRelated();
    }, []);

    const renderListView = (
        <SHBox>
            <SHBox grid dc={"300px 100px"} alignItems={"flex-end"}>
                <DarwinSelect
                    displayName="Add permit form"
                    execute={listViewModel.setPermitForm}
                    fullWidth={true}
                    getOptionLabel={(option: any) => option.displayName}
                    options={listViewModel.permitTypes}
                    placeholder="Please select type"
                    value={listViewModel.permitFormType}
                />
                <PrimaryButton canExecute={listViewModel.permitFormType !== null} execute={listViewModel.addPermitForm} value={"Add"} />
            </SHBox>
            <DarwinTablePageContent>
                <MaterialTable
                    columns={[
                        { title: "Permit type", field: "permitTypeName" },
                        { title: "Permit no.", field: "permitNumber" },
                        {
                            title: "Issued by",
                            field: "issuedByDate",
                            render: (rowData: PermitListModel) => (
                                <SHBox>
                                    <SHBox>{rowData.issuedByDate ? `${formatDate(rowData.issuedByDate)} @ ${formatTime(rowData.issuedByDate.toString())} by ` : " "}</SHBox>
                                    <SHBox>{rowData.issuedByUserName ? `${rowData.issuedByUserName}` : " "}</SHBox>
                                </SHBox>
                            ),
                        },
                        {
                            title: "Issued to",
                            field: "issuedToDate",
                            render: (rowData: PermitListModel) => (
                                <SHBox>
                                    <SHBox>{rowData.issuedToDate ? `${formatDate(rowData.issuedToDate)} @ ${formatTime(rowData.issuedToDate.toString())} to ` : " "}</SHBox>
                                    <SHBox>{rowData.issuedToUserName ? `${rowData.issuedToUserName}` : " "}</SHBox>
                                </SHBox>
                            ),
                        },
                        {
                            title: "Surrendered by",
                            field: "surrenderedDate",
                            render: (rowData: PermitListModel) => (
                                <SHBox>
                                    <SHBox>
                                        {!isEmptyOrWhitespace(rowData.surrenderedByDate)
                                            ? ` ${formatDate(rowData.surrenderedByDate!)} @ ${formatTime(rowData.surrenderedByDate!)} by `
                                            : " "}
                                    </SHBox>
                                    <SHBox>{!isEmptyOrWhitespace(rowData.surrenderedByUserName) ? rowData.surrenderedByUserName! : " "}</SHBox>
                                </SHBox>
                            ),
                        },
                        {
                            title: "Cancelled by",
                            field: "cancelledDate",
                            render: (rowData: PermitListModel) => (
                                <SHBox>
                                    <SHBox>
                                        {!isEmptyOrWhitespace(rowData.cancelledByDate)
                                            ? ` ${formatDate(rowData.cancelledByDate!)} @ ${formatTime(rowData.cancelledByDate!)} by `
                                            : " "}
                                    </SHBox>
                                    <SHBox>{!isEmptyOrWhitespace(rowData.cancelledByUserName) ? rowData.cancelledByUserName! : " "}</SHBox>
                                </SHBox>
                            ),
                        },
                    ]}
                    components={{
                        Pagination: PatchedPagination,
                    }}
                    options={Defaults.GetDarwinTableOptions()}
                    data={listViewModel.permitListModels}
                    onRowClick={(e, rowData) => {
                        listViewModel.handleRowClick(e, rowData!);
                    }}
                    title=""
                />
            </DarwinTablePageContent>
        </SHBox>
    );

    return <Wrapper>{renderListView}</Wrapper>;
});
