import styled from "styled-components";

export const DetailsCell = styled.div`
    padding: 0.5rem 1rem 0.125rem 1rem;
`;

export const HiddenDetailsCell = styled(DetailsCell)`
    visibility: hidden;
`;

export const EditDetailsCell = styled(DetailsCell)`
    display: flex;
    justify-content: center;
`;
