import { isNullOrUndefined, observable } from "@shoothill/core";
import { ApprovalHistoryInvoiceItemViewModel } from "./ApprovalHistoryInvoiceItemViewModel";
import { ApprovalHistoryInvoiceDTO } from "./ApprovalHistoryInvoiceDTO";

export class ApprovalHistoryInvoiceViewModel {
    public approvalHistoryInvoiceItems = observable<ApprovalHistoryInvoiceItemViewModel>([]);

    public fromDto = (dtos: ApprovalHistoryInvoiceDTO[]) => {
        const approvalHistoryInvoiceItems: ApprovalHistoryInvoiceItemViewModel[] = [];

        if (!isNullOrUndefined(dtos)) {
            for (const dto of dtos) {
                approvalHistoryInvoiceItems.push(new ApprovalHistoryInvoiceItemViewModel(dto.id, dto.type, dto.updatedBy, dto.updatedDate, dto.notes, dto.disputedStatusName));
            }
        }

        this.approvalHistoryInvoiceItems.replace(approvalHistoryInvoiceItems);
    };
}
