// Libraries
import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { useRouter } from "@shoothill/core";
import { Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

// Custom

import { IEGridItemModel } from "../../IEmodels";
import { IEGridItemViewModel, PopulateFromVariationItemModel } from "../../IEmodels/IEGridItemViewModel";
import { CommercialViewModel } from "../../Commercial.ViewModel";
import { PackagesViewModel } from "../../PackagesViewModel";
import { IEViewModel } from "../../IE.ViewModel";
import { formatCurrencyForGrid, formatCurrencyNoSign } from "Utils/Format";
import { makeStyles } from "@material-ui/core";
import { AppUrls } from "AppUrls";

// Styling & Images
import RequisitionIcon from "Content/RequisitionIcon.svg";
import { RowDash } from "Views/Project/CustomComponents";
import { ToggleButton } from "../VariationGrid.Styles";
import { formatCreditDebitClass } from "Utils/Utils";

interface ITableProps {
    ieId: string;
    itemModel: IEGridItemModel;
    sourceId: number;
    variationId: string;
    rowIndex: number;
    categoryId: number;
    subcategoryId: number;
    thisGuid: string;
    parentId: string;
    //rowEdit(row: string): void;
    rowEnable(variationIndex: number, categoryIndex: number, subCategoryIndex: number, index: number): void;
    subCategoryIndex: number;
    categoryIndex: number;
    variationIndex: number;
    currentActiveVarIndex: number;
    currentActiveCatIndex: number;
    currentActiveSubCatIndex: number;
    currentActiveDescIndex: number;
    guidHistory: PopulateFromVariationItemModel;
}

export const VariationItem: React.FunctionComponent<ITableProps> = (props) => {
    const router = useRouter();
    const { history } = useRouter();
    const {
        itemModel,
        ieId,
        //rowEdit,
        rowIndex,
        categoryId,
        subcategoryId,
        parentId,
        rowEnable,
        variationIndex,
        categoryIndex,
        subCategoryIndex,
        currentActiveVarIndex,
        currentActiveCatIndex,
        currentActiveSubCatIndex,
        currentActiveDescIndex,
    } = props;
    const [viewModel] = useState(new IEGridItemViewModel());
    const classes = useStyles();
    const commercialViewModel = CommercialViewModel.Instance;
    const packagesViewModel = PackagesViewModel.Instance;

    const ieViewModel = IEViewModel.Instance;

    useEffect(() => {
        if (itemModel !== null && itemModel !== undefined) {
            viewModel.loadModel(itemModel, parentId);
        }
    }, []);

    const formatDebit = (val: number) => {
        return commercialViewModel.formatDebit(val);
    };

    const rowPRClick = (name: string | undefined) => {
        const ieItemViewModel = IEGridItemViewModel.Instance;
        let item = props.guidHistory;
        item.variationItemName = name != undefined ? name : "";
        ieItemViewModel.setVariationAndRelatedGuids(item);
        history.push(AppUrls.Client.PurchaseOrder.Add);
    };

    const renderFutureSpendCell = () => {
        return (
            <Link
                component={RouterLink}
                to={AppUrls.Client.Project.VariationItemFutureSpendAmendment.replace(":ieid", props.ieId)
                    .replace(":variationid", props.variationId)
                    .replace(":variationitemid", props.guidHistory.variationItemLineId)}
            >
                {formatCurrencyForGrid(formatCurrencyNoSign(itemModel.futureSpend))}
            </Link>
        );
    };

    const renderRiskOppCell = () => {
        return (
            <Link
                component={RouterLink}
                to={AppUrls.Client.Project.VariationItemRiskOpp.replace(":ieid", props.ieId)
                    .replace(":variationid", props.variationId)
                    .replace(":variationitemid", props.guidHistory.variationItemLineId)}
            >
                {formatCurrencyForGrid(formatCurrencyNoSign(itemModel.roValue))}
            </Link>
        );
    };

    return useObserver(() => (
        <>
            <li className="item" key={"item_" + rowIndex}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div
                        style={{
                            color: `${
                                currentActiveVarIndex === variationIndex &&
                                currentActiveCatIndex === categoryIndex &&
                                currentActiveSubCatIndex === subCategoryIndex &&
                                currentActiveDescIndex === rowIndex
                                    ? "#0095DA"
                                    : ""
                            }`,
                            paddingLeft: "50px",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                    >
                        <RowDash style={{ display: "inline-block", marginRight: "10px" }} />
                        <span className="variation-row-name variation-item-name" title={itemModel.getName()}>
                            {itemModel.getName()}
                        </span>
                    </div>
                    {currentActiveVarIndex === variationIndex &&
                    currentActiveCatIndex === categoryIndex &&
                    currentActiveSubCatIndex === subCategoryIndex &&
                    currentActiveDescIndex === rowIndex ? (
                        <ToggleButton marginLeft={"26px"}>
                            <span
                                className={"open"}
                                onClick={() => {
                                    rowEnable(-1, -1, -1, -1);
                                }}
                            >
                                &minus;
                            </span>
                        </ToggleButton>
                    ) : (
                        <ToggleButton marginLeft={"26px"}>
                            <span
                                className={"close"}
                                onClick={() => {
                                    rowEnable(variationIndex, categoryIndex, subCategoryIndex, rowIndex);
                                }}
                            >
                                &#43;
                            </span>
                        </ToggleButton>
                    )}
                </div>
                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(itemModel.income))}</div>
                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(itemModel.estimateCost))}</div>
                <div className="right">{itemModel.margin}</div>
                <div className={"right" + formatDebit(itemModel.committedCost)}>{formatCurrencyForGrid(formatCurrencyNoSign(itemModel.committedCost))}</div>
                <div className="right">{renderFutureSpendCell()}</div>
                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(itemModel.totalExpectedSpend))}</div>
                <div className={"right" + formatCreditDebitClass(itemModel.variance)}>{formatCurrencyForGrid(formatCurrencyNoSign(itemModel.variance))}</div>
                <div className={"right"}>{renderRiskOppCell()}</div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>
                    {ieViewModel.projectStatusType != "DRAFT" && !itemModel.isAudit && (
                        <>
                            <span onClick={() => rowPRClick(itemModel.getName())} className={classes.inputIcon}>
                                <img src={RequisitionIcon} />
                            </span>
                        </>
                    )}
                </div>
            </li>
        </>
    ));
};

const useStyles = makeStyles({
    buttonGroup: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    autoCompleteInput: {
        display: "flex",
        justifyContent: "start",
        paddingRight: "30px !important",
        paddingLeft: "40px !important",
        "& div.MuiAutocomplete-inputRoot": {
            width: "90%",
            height: "90%",
            display: "flex",
            alignItems: "center",
        },
    },
    input: {
        "& .MuiInputBase-root": {
            display: "flex !important",
            alignItems: "center !important",
            justifyContent: "center !important",
            margin: "0 5px",
            height: "90%",
            backgroundColor: "gold",
            width: "90%",

            "& > input": {
                textAlign: "right",
                fontSize: "12px",
            },
        },
    },
    inputIcon: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
});
