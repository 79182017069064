import { ModelBase, observable } from "@shoothill/core";
import { BuildingControlSiteVisitAndRelatedResponseDTO, BuildingControlSiteVisitModel } from "./BuildingControlSiteVisit/BuildingControlSiteVisitModel";
import { CleanlinessReportModel, CleanlinessReportResponseDTO } from "./CleanlinessReport/CleanlinessReportModel";
import { CustomerVisitsSiteInstructionsModel, CustomerVisitsSiteInstructionsResponseDTO } from "./CustomerVisitsSiteInstructions/CustomerVisitsSiteInstructionsModel";
import { DailyProgrammeProgressUpdateModel, DailyProgrammeProgressUpdateResponseDTO } from "./DailyProgrammeProgressUpdate/DailyProgrammeProgressUpdateModel";
import { OtherProgrammeNoteResponseDTO, OtherProgrammeNotesModel } from "./OtherProgrammeNotes/OtherProgrammeNotesModel";
import { OutstandingDesignInformationModel, OutstandingDesignInformationResponseDTO } from "./OutstandingDesignInformation/OutstandingDesignInformationModel";
import { PotentialProgrammeDelaysModel, PotentialProgrammeDelaysResponseDTO } from "./PotentialProgrammeDelays/PotentialProgrammeDelaysModel";
import { LabourShortageModel, LabourShortageResponseDTO } from "./LabourShortage/LabourShortageModel";
import { MaterialStorageModel, MaterialStorageResponseDTO } from "./MaterialStorage/MaterialStorageModel";
import { WeatherReportModel, WeatherReportResponseDTO } from "./WeatherReport/WeatherReportModel";

export class ProgrammeUpdatesModel extends ModelBase<ProgrammeUpdatesModel, ProgrammeUpdatesModelDTO> {
    @observable
    public buildingControlSiteVisitModel = new BuildingControlSiteVisitModel();
    @observable
    public cleanlinessReportModel = observable<CleanlinessReportModel>([]);
    @observable
    public weatherReportModel = new WeatherReportModel();
    @observable
    public customerVisitsSiteInstructions = new CustomerVisitsSiteInstructionsModel();
    @observable
    public dailyProgrammeProgressUpdateModel = new DailyProgrammeProgressUpdateModel();
    @observable
    public otherProgrammeNotesModel = new OtherProgrammeNotesModel();
    @observable
    public potentialProgrammeDelaysModel = observable<PotentialProgrammeDelaysModel>([]);
    @observable
    public outstandingDesignInformationModel = observable<OutstandingDesignInformationModel>([]);
    @observable
    public materialStorageModel = observable<MaterialStorageModel>([]);
    @observable
    public labourShortageModel = observable<LabourShortageModel>([]);
    @observable
    public potentialProgrammeDelays = new PotentialProgrammeDelaysModel();
    @observable
    public outstandingDesignInformation = new OutstandingDesignInformationModel();
    @observable
    public materialStorage = new MaterialStorageModel();
    @observable
    public labourShortage = new LabourShortageModel();

    fromDto(dto: ProgrammeUpdatesModelDTO): void {
        this.buildingControlSiteVisitModel.reset();
        this.cleanlinessReportModel.clear();
        this.weatherReportModel.reset();
        this.customerVisitsSiteInstructions.reset();
        this.dailyProgrammeProgressUpdateModel.reset();
        this.otherProgrammeNotesModel.reset();
        this.potentialProgrammeDelaysModel.clear();
        this.outstandingDesignInformationModel.clear();
        this.materialStorageModel.clear();
        this.labourShortageModel.clear();

        for (const question of dto.cleanlinessReportList?.cleanlinessReportList!) {
            const model = new CleanlinessReportModel();
            model.fromDto(question);
            this.cleanlinessReportModel.push(model);
        }

        for (const potentialProgrammeDelays of dto.potentialProgrammeDelayList?.potentialProgrammeDelaysList!) {
            const model = new PotentialProgrammeDelaysModel();
            model.fromDto(potentialProgrammeDelays);
            this.potentialProgrammeDelaysModel.push(model);
        }

        for (const outstandingDesignInformation of dto.outstandingDesignInformationList?.outstandingDesignInformationList!) {
            const model = new OutstandingDesignInformationModel();
            model.fromDto(outstandingDesignInformation);
            this.outstandingDesignInformationModel.push(model);
        }

        for (const materialStorage of dto.materialStorageList?.materialStorageList!) {
            const model = new MaterialStorageModel();
            model.fromDto(materialStorage);
            this.materialStorageModel.push(model);
        }

        for (const labourShortage of dto.labourShortageList?.labourShortageList!) {
            const model = new LabourShortageModel();
            model.fromDto(labourShortage);
            this.labourShortageModel.push(model);
        }

        this.buildingControlSiteVisitModel.fromDto(dto.buildingControlSiteVisitList!.buildingControlSiteVisit);
        this.weatherReportModel.fromDto(dto.weatherReportList?.weatherReport ?? null);
        this.customerVisitsSiteInstructions.fromCustomerVisitsSitePhotosFileDTO(dto.customerVisitsSiteInstructionsList!.customerVisitsSiteInstructionsPhotos);
        this.customerVisitsSiteInstructions.fromDto(dto.customerVisitsSiteInstructionsList!.customerVisitsSiteInstructions);
        this.dailyProgrammeProgressUpdateModel.fromDto(dto.dailyProgrammeProgressUpdateList!.dailyProgrammeProgressUpdate);
        this.dailyProgrammeProgressUpdateModel.fromDailyProgrammeProgressPhotosDTO(dto.dailyProgrammeProgressUpdateList!.dailyProgrammeProgressUpdatePhotos);
        this.otherProgrammeNotesModel.fromDto(dto.otherProgrammeNoteList!.otherProgrammeNotes);
        this.otherProgrammeNotesModel.fromOtherProgrammePhotosPhotosDTO(dto.otherProgrammeNoteList!.otherProgrammeNotesPhotos);

        this.potentialProgrammeDelays.fromPotentialProgrammeDelaysPhotosFileDTO(dto.potentialProgrammeDelayList?.potentialProgrammeDelaysPhotos!);
        this.outstandingDesignInformation.fromOutstandingDesignInformationPhotosDTO(dto.outstandingDesignInformationList?.outstandingDesignInformationPhotos!);
        this.materialStorage.fromMaterialStoragePhotosDTO(dto.materialStorageList?.materialStoragePhotos!);
        this.labourShortage.fromLabourShortagePhotosDTO(dto.labourShortageList?.labourShortagePhotos!);
    }

    toDto(model: ProgrammeUpdatesModel): void {
        throw new Error("Method not implemented.");
    }
}

export class ProgrammeUpdatesModelDTO {
    public buildingControlSiteVisitList: BuildingControlSiteVisitAndRelatedResponseDTO | undefined;
    public cleanlinessReportList: CleanlinessReportResponseDTO | undefined;
    public weatherReportList: WeatherReportResponseDTO | undefined;
    public customerVisitsSiteInstructionsList: CustomerVisitsSiteInstructionsResponseDTO | undefined;
    public dailyProgrammeProgressUpdateList: DailyProgrammeProgressUpdateResponseDTO | undefined;
    public otherProgrammeNoteList: OtherProgrammeNoteResponseDTO | undefined;
    public potentialProgrammeDelayList: PotentialProgrammeDelaysResponseDTO | undefined;
    public outstandingDesignInformationList: OutstandingDesignInformationResponseDTO | undefined;
    public materialStorageList: MaterialStorageResponseDTO | undefined;
    public labourShortageList: LabourShortageResponseDTO | undefined;
    public users: UsersListDTO[] = [];
}

export interface UsersListDTO {
    id: string;
    displayName: string;
}

export interface FormRequestDTO {
    date: string | null;
    projectId: string | null;
    formId: string | null;
}
