import * as React from "react";
import { Button } from "@material-ui/core";

import { DeleteModalDialogPaper } from "./DeleteModal.styles";
import { BaseModal } from "./BaseModal";
import { CancelButton } from "Globals/Styles/Control/Buttons";

interface IProps {
    open?: boolean;
    onClose(): void;
    onDelete(): void;
    title: string;
    text: string;
    isLoading?: boolean;
}

export const DeleteModal: React.FC<IProps> = ({ open, onClose, onDelete, title, text, isLoading = false }) => (
    <BaseModal
        open={open}
        onClose={onClose}
        title={title}
        PaperComponent={DeleteModalDialogPaper}
        actions={
            <>
                <Button onClick={onDelete} color="secondary" autoFocus variant="contained" disabled={isLoading}>
                    Agree
                </Button>
                <CancelButton onClick={onClose}>Disagree</CancelButton>
            </>
        }
    >
        <p className="client-modal-description">{text}</p>
    </BaseModal>
);
