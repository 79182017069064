import styled from "styled-components";

export const AddEditContactViewWrapper = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;

    // background-color: orange
    @media screen and (max-width: 1024px) {
    }

    @media screen and (max-width: 640px) {
        flex-direction: column;
    }

    > div > div {
        max-width: 287px;
        width: 100%;
        padding: 0px;
        margin: 0px 15px;
        &:first-of-type {
            margin: 0px 15px 0px 30px;
        }

        &:last-of-type {
            button {
                margin-top: 23px;
            }
        }

        .MuiBox-root {
            width: 100%;
        }
    }

    padding-bottom: 20px;
    margin-bottom: 20px;
`;

export const AddEditContactViewRow = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: row;
    width: 100%;
    padding: 8px 0;
    align-items: flex-start;

    // background-color: orange
    @media screen and (max-width: 1024px) {
    }

    @media screen and (max-width: 640px) {
        flex-direction: row;
    }
`;

export const ContactCell = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: row;
    padding: 0 30px;

    // background-color: orange
    @media screen and (max-width: 1024px) {
    }

    @media screen and (max-width: 640px) {
        flex-direction: row;
    }
`;

export const AddButtonCell = styled(ContactCell)`
    flex-direction: column;
    flex: 1 0 1;

    padding-top: 15px;
`;
