import styled from "styled-components";
import { Box } from "@material-ui/core";

export const DetailPage = styled(Box)`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    position: relative;
`;

export const DetailContainer = styled(Box)`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    position: relative;
`;

export const DetailHeader = styled(Box)`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 0;
    position: relative;
    padding: 15px;
`;

export const DetailContent = styled(Box)`
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0 15px;
`;

export const DetailBox = styled(Box)`
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    //background-color: pink;
`;

export const DetailBoxRow = styled(Box)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 1 0 0;
    //background-color: maroon;
`;

export const DetailBoxItemRow = styled(Box)`
    display: flex;
    flex-direction: row;
    flex: 1 0 0;
    //background-color: orange;
`;

export const DetailBoxTitleItem = styled(Box)`
    display: flex;
    flex: 1 0 0;
    font-weight: 700;
    max-width: 150px;
    //background-color: yellow;
`;

export const DetailBoxItem = styled(Box)`
    display: flex;
    flex: 1 0 0;
    max-width: 200px;
    //background-color: forestgreen;
`;

export const DetailDivider = styled(Box)`
    display: flex;
    flex: 1 0 0;
    height: 10px;
    border-bottom: 1px solid #9dd7f2;
    padding-top: 15px;
    margin-bottom: 15px;
    position: relative;
    //background-color: fuchsia;
`;
