// Libraries
import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { useRouter } from "@shoothill/core";

// Custom
import { DetailsHeader } from "Globals/Views/DetailsPage/DetailsHeader";
import { ProjectGeneralViewModel } from "../General/ProjectGeneralViewModel";

// Styling & Images
import { DetailsPage } from "Globals/Views/DetailsPage/DetailsPage.styles";
import { ProjectTrackersViewModel } from "./ProjectTrackersViewModel";

export const ProjectTrackersView: React.FunctionComponent = () => {
    const { match } = useRouter();
    const { projectid } = match.params as any;
    const [generalViewModel] = useState(() => ProjectGeneralViewModel.Instance);
    const [viewModel] = useState(() => ProjectTrackersViewModel.Instance);

    useEffect(() => {}, []);

    const renderPage = () => {
        return (
            <DetailsPage className="cell-right rcselect">
                <DetailsHeader viewModel={generalViewModel.getHeader} />
            </DetailsPage>
        );
    };

    return useObserver(() => renderPage());
};
