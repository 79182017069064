import styled from "styled-components";
import { Paper } from "@material-ui/core";
import { theme } from "../../Globals/Styles/AppTheme";

export const BaseModalDialogPaper = styled(Paper)`
    border-radius: 10px;

    .darwin-dialog-title {
        background: ${theme.palette.primary.main};
        color: ${theme.palette.common.white};
        padding: 16px 20px;

        h2 {
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            font-weight: normal;

            span.modal-close {
                cursor: pointer;
                font-weight: bold;
            }
        }
    }

    .MuiDialogContent-root {
        padding: 16px 20px 0;

        p:not(.MuiFormHelperText-root) {
            font-size: 14px;
        }

        hr {
            border: none;
            height: 1px;
            background-color: ${theme.palette.primary.main};
        }

        .AddEditUserForm {
            flex-direction: row;
        }

        .supplierNameAdd,
        .supplierAddressAdd,
        .clientNameAdd,
        .clientAddressAdd {
            margin-bottom: 15px;
        }

        .supplierNameAdd {
            display: block;
        }

        .supplier-dialog .MuiPaper-root.MuiDialog-paper {
            max-width: 600px;
            margin: 0 auto;
        }

        .AddEditContactForm > div,
        .AddEditUserForm,
        .supplierNameAdd {
            > div {
                width: 100%;
                padding: 0px;
                margin: 0px 15px;
                &:first-of-type {
                    margin: 0px 15px 0px 0px;
                }

                &:last-of-type {
                    button {
                        margin-top: 23px;
                    }
                }

                .MuiBox-root {
                    width: 100%;
                }
            }
        }
    }

    .MuiDialogActions-root {
        padding: 20px;
        justify-content: center;

        > :not(:first-child) {
            margin-left: 20px;
        }

        > button {
            width: 100%;
            max-width: 116px;
            height: 30px;
            font-size: 14px;
        }
    }
`;

export const DarwinModalDialogPaper = styled(BaseModalDialogPaper)`
    width: 100% !important;

    .MuiDialogActions-root > button {
        max-width: 262px;
    }

    .MuiDialogContent-root {
        margin-bottom: 20px;
    }

    p {
        padding: 0px !important;
    }
`;
