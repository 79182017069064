import { Box } from "@material-ui/core";
import { PrimaryButton } from "Components/Buttons/Buttons";
import { pxToRem } from "Globals/Styles/AppTheme";
import React from "react";
import styled from "styled-components";

export const NewRiskOppLabel = styled(Box)`
    font-size: ${pxToRem(12)};
    font-weight: bold;
    padding: ${pxToRem(5.6)} 0;
`;

export const NewRiskOppContainer = styled(Box)`
    //margin: ${pxToRem(24)} ${pxToRem(16)} ${pxToRem(0)} ${pxToRem(16)};
`;

export const RiskOppAmendmentAddButton = styled(PrimaryButton)`
    max-width: 70px;
    margin-top: 20px;
`;

export const RiskOppItem: any = styled.div`
    display: flex;
    flex-direction: column;
    padding: 30px 0px;
    border-bottom: 1px solid #80808054;
    font-size: 12px;
    font-weight: 600;

    a {
        text-decoration: underline;
    }

    .value-orange {
        background-color: #eb8d00;
    }

    .value-green {
        background-color: #caead4;
    }

    .value-red {
        background-color: #ecb4b4;
    }

    .value-yellow {
        background-color: #efe350bf;
    }

    .value-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        .value-wrapper {
            padding: 8px 10px;
            text-align: right;
            margin-left: 8px;
            min-width: 116px;
            font-weight: normal;
        }

        > div:nth-child(1) {
            min-width: 165px;
        }
    }

    .riskOpp-group {
        max-width: 450px;
        display: flex;
        flex-direction: row;

        > div {
            min-width: 150px;
        }
    }
`;

export const RiskOppItemContentRow: any = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 0px;
`;

export const RiskOppItemNoteRow: any = styled.div`
    padding: 5px 0px;
    font-weight: normal;
`;
