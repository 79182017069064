import * as MobX from "mobx";
import { ModelBase } from "@shoothill/core";
import { PackageCategoryModel } from "Globals/Models/PackageCategoryModel";
import { IENameModel } from "./IEmodels/IENameModel";

export class PackagesModel extends ModelBase<PackagesModel, PackagesModelDTO> {
    public categories: PackageCategoryModel[] = [];

    public subCategories: PackageCategoryModel[] = [];

    public descriptions: PackageCategoryModel[] = [];

    /* public ieName: IENameModel[] = []; */

    fromDto(model: PackagesModelDTO): void {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: PackagesModel): void {}

    @MobX.action
    public clearModel() {
        this.categories = [];
        this.subCategories = [];
        this.descriptions = [];
        /* this.ieName = []; */
    }
}

export type PackagesModelDTO = {
    categories: PackageCategoryModel[];
    subCategories: PackageCategoryModel[];
    descriptions: PackageCategoryModel[];
    /* ieName: IENameModel[]; */
};
