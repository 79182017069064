import { observable, action } from "mobx";
import { ModelBase } from "@shoothill/core";
import { DelegateUserDTO } from "./DelegateUserModel";
import { ApprovalDelegateDTO, ApprovalDelegatedBy } from "./ApprovalDelegateModel";

export class ApprovalDelegateUserDataModel extends ModelBase<ApprovalDelegateUserDataModel, ApprovalDelegateUserDataDTO> {
    @observable
    public approvalDelegatedToUsers: ApprovalDelegatedTo[] = [];

    @observable
    public hasDelegation: boolean = false;

    @observable
    public isDelegator: boolean = false;

    @observable
    public isDelegatee: boolean = false;

    @action
    public cleanUp() {
        this.hasDelegation = false;
        this.isDelegator = false;
        this.isDelegatee = false;
    }

    //fromDto is required but you can leave it blank
    fromDto(model: ApprovalDelegateUserDataDTO): void {
        if (model === null || model === undefined) {
            this.cleanUp();
        } else {
            //this just iterates through every key assigning it to the model
            //Should only use if there is a direct mapping between dto and domain model
            //otherwise just map them yourself
            for (let key in model) {
                if (model.hasOwnProperty(key)) {
                    if (this[key] instanceof Date) {
                        this[key] = new Date(model[key]);
                    } else {
                        this[key] = model[key];
                    }
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: ApprovalDelegateUserDataModel): void {}
}

export interface ApprovalDelegateUserDataDTO {
    approvalDelegatedByUser: ApprovalDelegatedBy;
    approvalDelegatedToUsers: ApprovalDelegatedTo[];
    hasDelegation: boolean;
    isDelegator: boolean;
    isDelegatee: boolean;
    canViewHasDelegateApprovalsModal: boolean;
    canViewDelegationAddedNotificationModal: boolean;
    canViewDelegationRemovedNotificationModal: boolean;
}

export interface ApprovalDelegatedTo extends ApprovalDelegateDTO {
    delegatedToUserName: string;
    delegatedByUserName: string;
}

export interface ApprovalDelegateAndRelatedDTO {
    approvalDelegateUserData: ApprovalDelegateUserDataDTO;
    users: DelegateUserDTO[];
}
