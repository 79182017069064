import { observable, action } from "mobx";
import { ModelBase } from "@shoothill/core";

export class RAMSProjectDetailsModel extends ModelBase<RAMSProjectDetailsModel, IRAMSProjectDetailsDTO> {
    @observable
    public projectReference: string = "";
    @observable
    public projectName: string = "";
    @observable
    public completedByUserName: string = "";

    @action
    public reset() {
        this.projectReference = "";
        this.projectName = "";
        this.completedByUserName = "";
    }

    //fromDto is required but you can leave it blank
    fromDto(model: IRAMSProjectDetailsDTO): void {
        this.projectReference = model.projectReference;
        this.projectName = model.projectName;
        this.completedByUserName = model.completedByUserName;
    }

    //toDto is required but you can leave it blank
    toDto(model: RAMSProjectDetailsModel): void {}
}

export interface IRAMSProjectDetailsDTO {
    projectReference: string;
    projectName: string;
    completedByUserName: string;
}
