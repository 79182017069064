import styled from "styled-components";
import { Button } from "@material-ui/core";
import { pxToRem, theme } from "../AppTheme";

const _BaseBtn = styled(Button)({
    variant: "contained",
    height: pxToRem(30),
});

export const LoginButton: any = styled(_BaseBtn)`
    border-radius: 17px;
    color: ${theme.palette.common.white};
    background-color: ${theme.palette.primary.main};
    width: 100%;
    max-width: 274px;

    :hover {
        color: ${theme.palette.common.white};
        background-color: ${theme.palette.primary.light};
    }
`;

export const _InternalButtons: any = styled(_BaseBtn)`
    border-radius: 23px;
    width: 100%;
    max-width: 116px;
`;

export const AcceptButton: any = styled(_InternalButtons)`
    color: ${theme.palette.common.white};
    background-color: ${theme.palette.primary.main};
    :hover {
        background-color: ${theme.palette.primary.light};
    }
    max-width: 150px;
`;

export const CancelButton: any = styled(_InternalButtons)`
    border-radius: pxToRem(17);
    color: ${theme.palette.common.white};
    background-color: ${theme.palette.cancel.main};
    :hover {
        background-color: ${theme.palette.cancel.light};
    }
    margin-left: 15px;
`;

export const ApproveButton: any = styled(_InternalButtons)`
    border-radius: pxToRem(17);
    color: ${theme.palette.common.white};
    background-color: ${theme.palette.success.main};
    :hover {
        background-color: ${theme.palette.success.light};
    }
    margin-left: 15px;
`;

export const RejectButton: any = styled(_InternalButtons)`
    border-radius: pxToRem(17);
    color: ${theme.palette.common.white};
    background-color: ${theme.palette.error.main};
    :hover {
        background-color: ${theme.palette.error.light};
    }
    margin-left: 15px;
`;

export const ResetAccessFailedLoginCountButton: any = styled(_BaseBtn)`
    color: ${theme.palette.common.white};
    background-color: ${theme.palette.primary.main};
    :hover {
        background-color: ${theme.palette.primary.light};
    }
    max-width: 274px;
`;
