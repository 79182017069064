import { computed } from "mobx";
import { FieldType, ViewModelBase } from "@shoothill/core";
import moment from "moment";
import { RFIItemResponseModel } from "./RFIItemResponseModel";

export class RFIItemResponseViewModel extends ViewModelBase<RFIItemResponseModel> {
    constructor(item: RFIItemResponseModel) {
        super(item);
    }

    @computed
    public get id() {
        return this.model.id;
    }

    @computed
    public get rfiId() {
        return this.model.rfiId;
    }

    @computed
    public get comment() {
        return this.model.comment;
    }

    @computed
    public get createdDate() {
        return this.model.createdDate ? `${moment(this.model.createdDate).format("DD/MM/YYYY")}` : "";
    }

    @computed
    public get createdByUserId() {
        return this.model.createdByUserId;
    }

    @computed
    public get createdByName() {
        return this.model.createdByName;
    }

    @computed
    public get isDeleted() {
        return this.model.isDeleted;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public async isFieldValid(fieldName: keyof FieldType<RFIItemResponseModel>): Promise<boolean> {
        return true;
    }
}
