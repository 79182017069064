export class MaterialPriceDetailsHistoryItemViewModel {
    // #region Properties

    public effectiveFromDate: string = "";
    public estimatedPrice: number = 0;
    public note: string = "";
    public price: number = 0;
    public supplier: string = "";
    public updatedByUser: string = "";
    public updatedDate: string = "";

    // #endregion Properties
}
