// Libs
import { ApiResult, CheckHttpStatus, MergeDefaultConfig, ViewModelBase } from "@shoothill/core";
import { action, computed, runInAction } from "mobx";
import axios, * as Axios from "axios";

export abstract class DarwinViewModelBase<T> extends ViewModelBase {
    postFormWithFile = <TPayload = undefined>(url: string, file: File, model?: any, config?: Axios.AxiosRequestConfig): Promise<ApiResult<TPayload>> => {
        const requestConfig = this.getFileConfig(config);
        const formData = new FormData();

        // Add model
        if (model) {
            for (const key of Object.keys(model)) {
                const value = model[key];

                formData.append(key, value);
            }
        }

        formData.append("file", file);

        const postFormPromise = axios.post<ApiResult<TPayload>>(url, formData, requestConfig).then((response) => {
            CheckHttpStatus(response);

            const apiResult = response.data;

            return apiResult;
        });

        return postFormPromise;
    };

    postFormWithFiles = <TPayload = undefined>(url: string, fieldName: string, files: File[], model?: any, config?: Axios.AxiosRequestConfig): Promise<ApiResult<TPayload>> => {
        const requestConfig = this.getFileConfig(config);
        const formData = new FormData();

        // Add model
        if (model) {
            for (const key of Object.keys(model)) {
                const value = model[key];

                formData.append(key, value);
            }
        }

        files.forEach((file: File) => {
            formData.append(fieldName, file);
        });

        const postFormPromise = axios.post<ApiResult<TPayload>>(url, formData, requestConfig).then((response) => {
            CheckHttpStatus(response);

            const apiResult = response.data;

            return apiResult;
        });

        return postFormPromise;
    };

    getFileConfig = (config?: Axios.AxiosRequestConfig) => {
        const requestConfig = MergeDefaultConfig(config);
        //Sets the bearer on every header if available
        //Note: You might need to remove this bearer if calling 3rd party api's
        let jwt = sessionStorage.getItem(".auth") as string;
        if (jwt && jwt.length > 0) {
            requestConfig.headers = {
                Authorization: "Bearer " + jwt,
                "content-type": "multipart/form-data",
            };
        } else {
            requestConfig.headers = {
                "content-type": "multipart/form-data",
            };
        }
        return requestConfig;
    };
}
