import * as React from "react";
import { Error } from "./Globals/Views/Error";

interface ErrorBoundaryProps {
    hasError: boolean;
    error: string;
}
export class AppErrorBoundary extends React.Component<unknown, ErrorBoundaryProps> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false, error: "" };
    }
    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error: error.toString() };
    }
    public componentDidCatch(error: any, info: any) {
        //this.setError(error.toString());
        console.log("Error => " + error.toString());
    }

    public render() {
        if (this.state.hasError) {
            return <Error supportEmail={"support@shoothill.com"} error={this.state.error} />;
        }

        return this.props.children;
    }
}
