export class OutputType {
    public id: string = "";
    public displayName: string = "";

    // #region Methods

    public fromDto(dto: IOutputType) {
        this.id = dto.id;
        this.displayName = dto.displayName;
    }

    // #endregion Methods
}

export interface IOutputType {
    id: string;
    displayName: string;
}
