import { Box } from "@material-ui/core";
import { Cell, Grid } from "@shoothill/core";
import { pxToRem } from "Globals/Styles/AppTheme";
import { useObserver } from "mobx-react-lite";
import React from "react";
import styled, { css } from "styled-components";

import { BudgetForecastFormatted } from "Views/PurchaseOrder/Form/PurchaseOrderModel";

interface IProps {
    budgetForecast: BudgetForecastFormatted;
    isTotal: boolean;
}

const BudgetForecastTableBase: React.FC<IProps> = (props) => {
    // #region Code Behind

    const COLUMNS6 = "1fr 1fr 1fr 1fr 1fr 1fr";

    // #endregion Code Behind

    return useObserver(() => (
        <FutureSpendTable>
            <Grid columnGap={0} rowGap={0} tc={COLUMNS6} dc={COLUMNS6}>
                {/* Group of 6 columns */}
                <Cell ts={1} ds={1}></Cell>
                <Cell ts={1} ds={1}>
                    <FutureSpendHeaderCell isTotal={props.isTotal}>Target cost</FutureSpendHeaderCell>
                </Cell>
                <Cell ts={1} ds={1}>
                    <FutureSpendHeaderCell isTotal={props.isTotal}>Committed cost</FutureSpendHeaderCell>
                </Cell>
                <Cell ts={1} ds={1}>
                    <FutureSpendHeaderCell isTotal={props.isTotal}>Future spend</FutureSpendHeaderCell>
                </Cell>
                <Cell ts={1} ds={1}>
                    <FutureSpendHeaderCell isTotal={props.isTotal}>Total expected spend</FutureSpendHeaderCell>
                </Cell>
                <Cell ts={1} ds={1}>
                    <FutureSpendHeaderCell isTotal={props.isTotal}>Variance</FutureSpendHeaderCell>
                </Cell>

                {/* Group of 6 columns */}
                <Cell ts={1} ds={1}>
                    <FutureSpendSideCell isTotal={props.isTotal}>Pre</FutureSpendSideCell>
                </Cell>
                <Cell ts={1} ds={1}>
                    <FutureSpendCell>{props.budgetForecast.preTargetCost}</FutureSpendCell>
                </Cell>
                <Cell ts={1} ds={1}>
                    <FutureSpendCell>{props.budgetForecast.preCommittedCost}</FutureSpendCell>
                </Cell>
                <Cell ts={1} ds={1}>
                    <FutureSpendCell>{props.budgetForecast.preFutureSpend}</FutureSpendCell>
                </Cell>
                <Cell ts={1} ds={1}>
                    <FutureSpendCell>{props.budgetForecast.preTotalExpectedSpend}</FutureSpendCell>
                </Cell>
                <Cell ts={1} ds={1}>
                    <FutureSpendCell>{props.budgetForecast.preVariance}</FutureSpendCell>
                </Cell>

                {/* Group of 6 columns */}
                <Cell ts={1} ds={1}>
                    <FutureSpendSideCell isTotal={props.isTotal}>Post</FutureSpendSideCell>
                </Cell>
                <Cell ts={1} ds={1}>
                    <FutureSpendCell>{props.budgetForecast.postTargetCost}</FutureSpendCell>
                </Cell>
                <Cell ts={1} ds={1}>
                    <FutureSpendCell>{props.budgetForecast.postCommittedCost}</FutureSpendCell>
                </Cell>
                <Cell ts={1} ds={1}>
                    <FutureSpendCell>{props.budgetForecast.postFutureSpend}</FutureSpendCell>
                </Cell>
                <Cell ts={1} ds={1}>
                    <FutureSpendCell>{props.budgetForecast.postTotalExpectedSpend}</FutureSpendCell>
                </Cell>
                <Cell ts={1} ds={1}>
                    <FutureSpendCell>{props.budgetForecast.postVariance}</FutureSpendCell>
                </Cell>
            </Grid>
        </FutureSpendTable>
    ));
};

export const BudgetForecastTable = styled(BudgetForecastTableBase)`
    background-color: #ededed;
    display: flex;
    flex-direction: column;
`;

// #region Future Spend Table

const FutureSpendTable = styled(Box)`
    margin: ${pxToRem(0)} ${pxToRem(16)} ${pxToRem(18)} ${pxToRem(16)};
`;

interface ICellProps {
    isTotal?: boolean;
}

const FutureSpendHeaderCell = styled(Box)<ICellProps>`
    ${(props) =>
        props.isTotal &&
        css`
            background-color: #425b66;
            color: #ffffff;
        `}
    ${(props) =>
        !props.isTotal &&
        css`
            background-color: #ced4da;
            color: #000000de;
        `}
    border-bottom: 1px solid transparent;
    border-right: 1px solid transparent;
    font-size: ${pxToRem(12)};
    font-weight: bold;
    padding: ${pxToRem(12)} 0;
    text-align: center;
`;

const FutureSpendSideCell = styled(Box)<ICellProps>`
    ${(props) =>
        props.isTotal &&
        css`
            background-color: #425b66;
            color: #ffffff;
        `}
    ${(props) =>
        !props.isTotal &&
        css`
            background-color: #ced4da;
            color: #000000de;
        `}
    border-bottom: 1px solid transparent;
    border-right: 1px solid transparent;
    font-size: ${pxToRem(12)};
    font-weight: bold;
    padding: ${pxToRem(14)} 0;
    text-align: center;
`;

const FutureSpendCell = styled(Box)<ICellProps>`
    background-color: #ffffff;
    border-bottom: 1px solid #ced4da;
    border-right: 1px solid #ced4da;
    font-size: ${pxToRem(12)};
    padding: ${pxToRem(14)} 0;
    text-align: center;
`;

// #endregion Future Spend Table
