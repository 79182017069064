import { ModelBase } from "@shoothill/core";
import { action, observable } from "mobx";
import { RFIFormDocumentModel, RFIDocumentDTO } from "../Common/RFIFormDocumentModel";
import { RFIFormFileModel, RFIFileDTO } from "../Common/RFIFormFileModel";

export class RFIFormModel extends ModelBase<RFIFormModel> {
    public static readonly DEFAULT_ID = "";
    public static readonly DEFAULT_PROJECT_ID = "";
    public static readonly DEFAULT_RFICATEGORY_ID = "";
    public static readonly DEFAULT_CATEGORY_NAME = "";
    public static readonly DEFAULT_SUPPLIER_REFERENCE = "";
    public static readonly DEFAULT_CLIENT_REFERENCE = null;
    public static readonly DEFAULT_SUPPLIER_ID = "";
    public static readonly DEFAULT_RFI_STATUS_ID = "";
    public static readonly DEFAULT_PARENT_RFI_ID = null;
    public static readonly DEFAULT_REQUESTED_BY_USER_ID = null;
    public static readonly DEFAULT_REQUESTED_DATE = null;
    public static readonly DEFAULT_TITLE = "";
    public static readonly DEFAULT_DESCRIPTION = "";
    public static readonly DEFAULT_REQUESTEDTO = "";
    public static readonly DEFAULT_RESPONSE_REQUIRED_BY_DATE = null;
    public static readonly DEFAULT_LAST_UPDATED_DATE = null;
    public static readonly DEFAULT_CREATED_DATE = null;
    public static readonly DEFAULT_CREATED_BY_USER_ID = null;
    public static readonly DEFAULT_LAST_UPDATED_BY_USER_ID = null;
    public static readonly DEFAULT_IS_DELETED = false;
    public static readonly DEFAULT_ROW_VERSION = null;
    public static readonly DEFAULT_RFI_ATTACHMENTS = [];
    public static readonly DEFAULT_REVISION = null;
    public static readonly DEFAULT_RFI_DOCUMENTS = [];
    public static readonly DEFAULT_UNIQUE_ID = "";

    @observable
    public id: string | null = RFIFormModel.DEFAULT_ID;
    @observable
    public projectId: string = RFIFormModel.DEFAULT_PROJECT_ID;
    @observable
    public rfiCategoryId: string = RFIFormModel.DEFAULT_RFICATEGORY_ID;
    @observable
    public categoryName: string = RFIFormModel.DEFAULT_CATEGORY_NAME;
    @observable
    public supplierReference: string | null = RFIFormModel.DEFAULT_SUPPLIER_REFERENCE;
    @observable
    public clientReference: number | null = RFIFormModel.DEFAULT_CLIENT_REFERENCE;
    @observable
    public supplierId: string | null = RFIFormModel.DEFAULT_SUPPLIER_ID;
    @observable
    public rfiStatusId: string | null = RFIFormModel.DEFAULT_RFI_STATUS_ID;
    @observable
    public parentRFIId: string | null = RFIFormModel.DEFAULT_PARENT_RFI_ID;
    @observable
    public requestedByUserId: string | null = RFIFormModel.DEFAULT_REQUESTED_BY_USER_ID;
    @observable
    public requestedDate: string | null = RFIFormModel.DEFAULT_REQUESTED_DATE;
    @observable
    public title: string | null = RFIFormModel.DEFAULT_TITLE;
    @observable
    public description: string | null = RFIFormModel.DEFAULT_DESCRIPTION;
    @observable
    public requestedTo: string | null = RFIFormModel.DEFAULT_REQUESTEDTO;
    @observable
    public responseRequiredByDate: string | null = RFIFormModel.DEFAULT_RESPONSE_REQUIRED_BY_DATE;
    @observable
    public createdDate: string | null = RFIFormModel.DEFAULT_CREATED_DATE;
    @observable
    public createdByUserId: string | null = RFIFormModel.DEFAULT_CREATED_BY_USER_ID;
    @observable
    public lastUpdatedDate: string | null = RFIFormModel.DEFAULT_LAST_UPDATED_DATE;
    @observable
    public lastUpdatedByUserId: string | null = RFIFormModel.DEFAULT_LAST_UPDATED_BY_USER_ID;
    @observable
    public isDeleted: boolean = RFIFormModel.DEFAULT_IS_DELETED;
    @observable
    public rowVersion: string | null = RFIFormModel.DEFAULT_ROW_VERSION;
    @observable
    public rfiAttachments: RFIFormFileModel[] = RFIFormModel.DEFAULT_RFI_ATTACHMENTS;
    @observable
    public revision: number | null = RFIFormModel.DEFAULT_REVISION;
    @observable
    public rfiDocuments: RFIFormDocumentModel[] = RFIFormModel.DEFAULT_RFI_DOCUMENTS;
    @observable
    public uniqueId: string | null = RFIFormModel.DEFAULT_UNIQUE_ID;

    @action
    public reset = () => {
        this.id = RFIFormModel.DEFAULT_ID;
        this.projectId = RFIFormModel.DEFAULT_PROJECT_ID;
        this.rfiCategoryId = RFIFormModel.DEFAULT_RFICATEGORY_ID;
        this.categoryName = RFIFormModel.DEFAULT_CATEGORY_NAME;
        this.supplierReference = RFIFormModel.DEFAULT_SUPPLIER_REFERENCE;
        this.clientReference = RFIFormModel.DEFAULT_CLIENT_REFERENCE;
        this.supplierId = RFIFormModel.DEFAULT_SUPPLIER_ID;
        this.rfiStatusId = RFIFormModel.DEFAULT_RFI_STATUS_ID;
        this.parentRFIId = RFIFormModel.DEFAULT_PARENT_RFI_ID;
        this.requestedByUserId = RFIFormModel.DEFAULT_REQUESTED_BY_USER_ID;
        this.requestedDate = RFIFormModel.DEFAULT_REQUESTED_DATE;
        this.title = RFIFormModel.DEFAULT_TITLE;
        this.description = RFIFormModel.DEFAULT_DESCRIPTION;
        this.requestedTo = RFIFormModel.DEFAULT_REQUESTEDTO;
        this.responseRequiredByDate = RFIFormModel.DEFAULT_RESPONSE_REQUIRED_BY_DATE;
        this.createdDate = RFIFormModel.DEFAULT_CREATED_DATE;
        this.createdByUserId = RFIFormModel.DEFAULT_CREATED_BY_USER_ID;
        this.lastUpdatedDate = RFIFormModel.DEFAULT_LAST_UPDATED_DATE;
        this.lastUpdatedByUserId = RFIFormModel.DEFAULT_LAST_UPDATED_BY_USER_ID;
        this.isDeleted = RFIFormModel.DEFAULT_IS_DELETED;
        this.rowVersion = RFIFormModel.DEFAULT_ROW_VERSION;
        this.rfiAttachments.length = 0;
        this.revision = RFIFormModel.DEFAULT_REVISION;
        this.rfiDocuments.length = 0;
        this.uniqueId = RFIFormModel.DEFAULT_UNIQUE_ID;
    };

    @action
    public fromDto(dto: RFIAndRelatedResponseDTO): void {
        const rFIDto = dto.rfi;

        for (let key in rFIDto) {
            if (rFIDto.hasOwnProperty(key)) {
                if (key === "rfiAttachments") {
                    // do nothing
                } else if (this[key] instanceof Date) {
                    this[key] = new Date(rFIDto[key]);
                } else {
                    this[key] = rFIDto[key];
                }
            }
        }

        let processedFiles: RFIFormFileModel[] = [];

        for (const item of dto.rfiAttachments) {
            const itemToAdd = new RFIFormFileModel();
            itemToAdd.fromDto(item);
            processedFiles.push(itemToAdd);
        }

        this.rfiAttachments = [...this.rfiAttachments, ...processedFiles];

        let processedDocuments: RFIFormDocumentModel[] = [];

        for (const rfiDocument of dto.rfiDocuments) {
            const rfiDocumentToAdd = new RFIFormDocumentModel();
            rfiDocumentToAdd.fromDto(rfiDocument);
            processedDocuments.push(rfiDocumentToAdd);
        }

        this.rfiDocuments = [...this.rfiDocuments, ...processedDocuments];
    }

    public toDto() {
        const RFIFormModel: RFIDTO = {
            id: this.id,
            projectId: this.projectId,
            rfiCategoryId: this.rfiCategoryId,
            categoryName: this.categoryName,
            supplierReference: this.supplierReference,
            clientReference: this.clientReference,
            supplierId: this.supplierId,
            rfiStatusId: this.rfiStatusId,
            parentRFIId: this.parentRFIId,
            requestedByUserId: this.requestedByUserId,
            requestedDate: this.requestedDate,
            title: this.title,
            description: this.description,
            requestedTo: this.requestedTo,
            responseRequiredByDate: this.responseRequiredByDate,
            createdDate: this.createdDate,
            createdByUserId: this.createdByUserId,
            lastUpdatedDate: this.lastUpdatedDate,
            lastUpdatedByUserId: this.lastUpdatedByUserId,
            isDeleted: this.isDeleted,
            rowVersion: this.rowVersion,
            revision: this.revision,
            uniqueId: this.uniqueId,
        };

        return RFIFormModel;
    }

    @action
    public fromRelatedDto(dto: RFIRelatedResultResponseDTO): void {}
}

export interface RFIStatusDTO {
    id: string;
    displayName: string;
}

export interface RFISupplierDTO {
    id: string;
    displayName: string;
}

export interface RFICategoryDTO {
    id: string;
    displayName: string;
}

export interface RFIRequestedByDTO {
    id: string;
    displayName: string;
}

export interface RFIDarwinSupplierReferenceDTO {
    id: string;
    displayName: string;
}

export interface RFIDTO {
    id: string | null;
    projectId: string;
    rfiCategoryId: string;
    categoryName: string;
    supplierReference: string | null;
    clientReference: number | null;
    supplierId: string | null;
    rfiStatusId: string | null;
    parentRFIId: string | null;
    requestedByUserId: string | null;
    requestedDate: string | null;
    title: string | null;
    description: string | null;
    requestedTo: string | null;
    responseRequiredByDate: string | null;
    createdDate: string | null;
    createdByUserId: string | null;
    lastUpdatedDate: string | null;
    lastUpdatedByUserId: string | null;
    isDeleted: boolean;
    rowVersion: string | null;
    revision: number | null;
    uniqueId: string | null;
}

export interface UpsertRFIAndRelatedRequestDTO {
    rfi: RFIDTO;
    rfiAttachments: RFIFileDTO[];
    rfiDocuments: RFIDocumentDTO[];
}

export interface RFIAndRelatedResponseDTO extends RFIRelatedResultResponseDTO {
    rfi: RFIDTO;
    rfiAttachments: RFIFileDTO[];
    rfiDocuments: RFIDocumentDTO[];
}

export interface RFIProjectDetailsDTO {
    projectReference: string;
    projectName: string;
}

export interface RFIRelatedResultResponseDTO {
    suppliers: RFISupplierDTO[];
    statuses: RFIStatusDTO[];
    rfiProjectDetails: RFIProjectDetailsDTO;
    categories: RFICategoryDTO[];
}

export interface RFIAttachment {
    rfiId: string | null;
    fileName: string;
    fileURL: string;
    createdByUserId: string | null;
    isDeleted: boolean;
}

export interface RFICategoryDocument {
    rfiCategoryId: string | null;
    fileName: string;
    fileURL: string;
    createdByUserId: string | null;
    isDeleted: boolean;
}
