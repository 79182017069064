import { Box, Link } from "@material-ui/core";
import EmailIcon from "@material-ui/icons//Email";
import PhoneIcon from "@material-ui/icons/Phone";
import { Cell, Grid, useRouter } from "@shoothill/core";
import { useObserver } from "mobx-react-lite";
import React, { useState } from "react";
import styled from "styled-components";

import { pxToRem } from "Globals/Styles/AppTheme";
import { SupplierDetailsModel } from "./SupplierDetailsModel";

interface IProps {
    className?: string;
    supplierDetails: SupplierDetailsModel;
}

const SupplierDetailsViewBase: React.FC<IProps> = (props) => {
    // #region Code Behind

    const COLUMNS16 = "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr";

    // #endregionCode Behind

    return useObserver(() => (
        <Box className={props.className}>
            <Grid columnGap={5} rowGap={1} tc={COLUMNS16} dc={COLUMNS16}>
                <Cell ts={16} ds={16}>
                    <DisplayName>{props.supplierDetails.displayName}</DisplayName>
                </Cell>
                <Cell ts={16} ds={16}>
                    <Address>{props.supplierDetails.address}</Address>
                </Cell>
                <Cell ts={5} ds={5}>
                    <ContactItemLabel>Primary contact:</ContactItemLabel>
                </Cell>
                <Cell ts={11} ds={11}>
                    <ContactItem>{props.supplierDetails.contactName}</ContactItem>
                </Cell>
                <Cell ts={5} ds={5}>
                    <ContactItemLabel>Contact email:</ContactItemLabel>
                </Cell>
                <Cell ts={11} ds={11}>
                    <ContactItem>
                        <Link href={`mailto:${props.supplierDetails.contactEmail}`}>{props.supplierDetails.contactEmail}</Link>
                    </ContactItem>
                </Cell>
                <Cell ts={5} ds={5}>
                    <ContactItemLabel>Contact telephone:</ContactItemLabel>
                </Cell>
                <Cell ts={11} ds={11}>
                    <ContactItem>
                        <Link href={`tel:${props.supplierDetails.contactNumber}`}>{props.supplierDetails.contactNumber}</Link>
                    </ContactItem>
                </Cell>
            </Grid>
        </Box>
    ));
};

export const SupplierDetailView = styled(SupplierDetailsViewBase)`
    background-color: white;
    border: 1px solid #ced4da;
    padding: 8px 10px;
`;

const DisplayName = styled(Box)`
    font-size: ${pxToRem(18)};
    font-weight: bold;
    letter-spacing: ${pxToRem(-0.36)};
    line-height: 1;
    margin-bottom: ${pxToRem(3)};
`;

const Address = styled(Box)`
    font-size: ${pxToRem(14)};
    font-weight: bold;
    letter-spacing: ${pxToRem(-0.28)};
    line-height: 1;
    margin-bottom: ${pxToRem(6)};
`;

const ContactItemLabel = styled(Box)`
    font-size: ${pxToRem(12)};
    font-weight: bold;
    letter-spacing: ${pxToRem(-0.24)};
`;

const ContactItem = styled(Box)`
    font-size: ${pxToRem(12)};
    letter-spacing: ${pxToRem(-0.24)};
`;
