import { Button } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React from "react";

import { DarwinSelect } from "Components/AutoComplete/DarwinSelect";
import { DarwinSelectCreate } from "Components/AutoComplete/DarwinSelectCreate";
import { DarwinDateSelect } from "Components/DateSelect/DarwinDateSelect";
import { BaseModal } from "Components/Modal/BaseModal";
import { SnackBar } from "Components/SnackBar/SnackBar";
import { OutputCategory } from "Globals/Models/Enums/OutputCategory";
import { CancelButton } from "Globals/Styles/Control/Buttons";
import { AddPlanOfWorkModalStyle } from "Views/Project/Modals/Modal.Styles";
import { FormModalSection, FormRow } from "Views/Stock/Shared/Shared.styles";
import { AddOutputViewModel } from ".";
import { OutputType } from "../../Shared";

interface IProps {
    viewModel: AddOutputViewModel;
}

export const AddOutputView: React.FC<IProps> = observer((props) => {
    const viewModel = props.viewModel;

    return (
        <BaseModal
            open={viewModel !== null}
            onClose={viewModel.cancel}
            title={viewModel.displayName}
            PaperComponent={AddPlanOfWorkModalStyle}
            actions={
                <React.Fragment>
                    <Button autoFocus color="secondary" disabled={viewModel.server.IsBusy} onClick={viewModel.apiUpsertOutputAsync} variant="contained">
                        Save
                    </Button>
                    <CancelButton onClick={viewModel.cancel}>Cancel</CancelButton>
                </React.Fragment>
            }
        >
            <FormModalSection>
                <FormRow grid dc={"1fr"}>
                    <DarwinSelectCreate
                        displayName="Title:"
                        execute={viewModel.setOutputType}
                        fullWidth={true}
                        getOptionLabel={(option: OutputType) => option?.displayName ?? ""}
                        options={viewModel.outputTypes}
                        placeholder="Please select"
                        validationMessage={viewModel.getError("outputType")}
                        value={viewModel.outputType}
                        addValue={(value: string) => ({
                            id: "",
                            displayName: value,
                        })}
                        filterValue={(value: string) => ({
                            inputValue: value,
                            displayName: `Add "${value}"`,
                        })}
                    />
                </FormRow>
                <FormRow grid dc={"1fr"}>
                    <DarwinSelect
                        displayName="Category:"
                        execute={viewModel.setOutputCategory}
                        fullWidth={true}
                        getOptionLabel={(option: OutputCategory) => option?.displayName ?? ""}
                        options={viewModel.outputCategories}
                        placeholder="Please select"
                        validationMessage={viewModel.getError("outputCategory")}
                        value={viewModel.outputCategory}
                    />
                </FormRow>
                <FormRow grid dc={"1fr 1fr"}>
                    <DarwinDateSelect
                        displayName="Required by:"
                        execute={viewModel.setRequiredByDate}
                        placeholder="dd/mm/yyyy"
                        validationMessage={viewModel.getError("requiredByDate")}
                        value={viewModel.getValue("requiredByDate")}
                    />
                </FormRow>
            </FormModalSection>
            <SnackBar
                messageText={viewModel.snackMessage}
                messageType={viewModel.snackType}
                active={viewModel.snackbarState}
                closeOption={() => viewModel.setSnackbarState(false)}
                autoHideDuration={5000}
            />
        </BaseModal>
    );
});
