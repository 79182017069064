import { EnumType, IEnumType } from "./Base/EnumType";

export class PersonalProtectiveEquipmentType extends EnumType {
    // #region Constants

    public static readonly SafetyBoots = "SAFETY_BOOTS";
    public static readonly RiggerBoots = "RIGGER_BOOTS";
    public static readonly Vest = "VEST";
    public static readonly Jacket = "JACKET";
    public static readonly Jumper = "JUMPER";
    public static readonly Coveralls = "COVERALLS";
    public static readonly SafetyHelmet = "SAFETY_HELMET";
    public static readonly LongTrousers = "LONG_TROUSERS";
    public static readonly TopCoveringShoulders = "TOP_COVERING_SHOULDERS";

    // #endregion Constants

    // #region Properties

    public personalProtectiveEquipmentId: string = "";

    // #endregion Properties

    // #region Methods

    public fromDto(dto: IPersonalProtectiveEquipmentType) {
        super.fromDto(dto);

        this.personalProtectiveEquipmentId = dto.personalProtectiveEquipmentId;
    }

    // #endregion Methods
}

export interface IPersonalProtectiveEquipmentType extends IEnumType {
    personalProtectiveEquipmentId: string;
}
