import React, { useEffect, useState } from "react";
import { useObserver } from "@shoothill/core";
import { RequisitionsListViewModel } from "./RequisitionsListViewModel";
import { DarwinPage } from "Globals/Styles/AppStyling";
import { RequisitionsListFilterView } from "./RequisitionsListFilterView";
import { RequisitionsListFilterViewModel } from "./RequisitionsListFilterViewModel";
import { AllRequisitionsTableView } from "./AllRequisitionsTableView";
import { IEGridItemViewModel } from "../Commercial/IEmodels/IEGridItemViewModel";

export const RequisitionsListView: React.FunctionComponent = () => {
    const [viewModel] = useState(() => new RequisitionsListViewModel());
    const filtersViewModel = RequisitionsListFilterViewModel.Instance;

    useEffect(() => {
        IEGridItemViewModel.Instance.setIsCentral(false);
        viewModel.GetAllRequisitionsListAsync().then((result) => {
            if (result.wasSuccessful === true) {
                filtersViewModel.setCategories(result.payload.packageCategories, true);
                filtersViewModel.setStatuses(result.payload.statuses, true);
                filtersViewModel.setOrderTypes(result.payload.orderTypes, true);
                filtersViewModel.setRaisedByTypes(result.payload.purchaseUsers, true);
                filtersViewModel.setProjects(result.payload.projects, true);
            }
            /* TODO */
        });

        return () => {
            viewModel.cleanUp();
        };
    }, []);

    const renderPage = () => {
        return (
            <DarwinPage style={{ paddingTop: 25 }}>
                <RequisitionsListFilterView RequisitionsListViewModel={viewModel} filtersViewModel={filtersViewModel} />
                <AllRequisitionsTableView data={viewModel.getRequisitionList} isLoading={viewModel.IsLoading} />
            </DarwinPage>
        );
    };

    return useObserver(() => renderPage());
};
