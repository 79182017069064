import { FieldType, ViewModelBase } from "@shoothill/core";
import { ApprovedVariationListModel } from "./ApprovedVariationListModel";

export class ApprovedVariationViewModel extends ViewModelBase<ApprovedVariationListModel> {
    public constructor() {
        super(new ApprovedVariationListModel(), false);
        this.setDecorators(ApprovedVariationListModel);
    }

    public async isFieldValid(fieldName: keyof FieldType<ApprovedVariationListModel>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
