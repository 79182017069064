import { FieldType, ViewModelBase, isEmptyOrWhitespace } from "@shoothill/core";
import { action, computed, observable, runInAction } from "mobx";

import { AppUrls } from "AppUrls";
import { PlanOfWorkStage } from "Globals/Models/Enums/PlanOfWorkStage";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { AddPlanOfWorkModel } from ".";

export class AddPlanOfWorkViewModel extends ViewModelBase<AddPlanOfWorkModel> {
    public server: ServerViewModel = new ServerViewModel();

    private projectId: string;
    private parentCloseAction: (refreshPage: boolean) => void;

    constructor(projectId: string, closeAction: (refreshPage: boolean) => void) {
        super(new AddPlanOfWorkModel(), false);

        this.projectId = projectId;
        this.parentCloseAction = closeAction;

        this.setDecorators(AddPlanOfWorkModel);

        // Load plans of work stages.
        this.apiLoadRelatedAsync();
    }

    // #region Plan of Work Stages

    @computed
    public get planOfWorkStages() {
        return this.model.planOfWorkStages;
    }

    @computed
    public get planOfWorkStage() {
        return this.model.planOfWorkStage;
    }

    @action
    public setPlanOfWorkStage = (value: PlanOfWorkStage | null) => {
        this.model.planOfWorkStage = value ?? AddPlanOfWorkModel.DEFAULT_PLANOFWORKSTAGE;
        this.isFieldValid("planOfWorkStage");
    };

    // #endregion Plan of Work Stages

    // #region Actions

    @action
    public cancel = () => this.parentCloseAction(false);

    // #endregion Actions

    // #region Api Actions

    @action
    public apiInsertPlanOfWorkAsync = async (): Promise<void> => {
        await this.server.command<any>(
            () => this.Post(AppUrls.Server.Projects.ProjectTrackers.OutputTracker.PlansOfWork.Insert.replace("{projectid}", this.projectId), this.model.toDto()),
            (result) => this.parentCloseAction(true),
            this.isModelValid,
            "Error whilst saving the new stage",
        );

        if (this.server.HaveValidationMessage) {
            this.setSnackMessage(this.server.ValidationMessage);
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }
    };

    @action
    public apiLoadRelatedAsync = async (): Promise<void> => {
        await this.server.query<any>(
            () => this.Get(AppUrls.Server.Projects.ProjectTrackers.OutputTracker.PlansOfWork.Related.replace("{projectid}", this.projectId)),
            (result) => this.model.fromDto(result),
            "Error whilst loading the stage data",
        );

        if (this.server.HaveValidationMessage) {
            this.setSnackMessage(this.server.ValidationMessage);
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }
    };

    // #endregion Api Actions

    // #region Snack Bar

    public SNACKERROR = "error";

    @observable
    public snackbarState = false;

    @observable
    public snackType = "";

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public snackMessage = "";

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    // #endregion Snack Bar

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<AddPlanOfWorkModel>): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boliderplate
}
