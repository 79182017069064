import { Button, InputAdornment, TextField } from "@material-ui/core";
import { isEmptyOrWhitespace } from "@shoothill/core/dist/Utils";
import { observer } from "mobx-react-lite";
import React from "react";

import { DarwinSelect } from "Components/AutoComplete/DarwinSelect";
import { SHBox } from "Components/Box";
import { DarwinDateSelect } from "Components/DateSelect/DarwinDateSelect";
import { BaseModal } from "Components/Modal/BaseModal";
import { SnackBar } from "Components/SnackBar/SnackBar";
import { DarwinMoneyInput } from "Globals/Styles/Control/DarwinInput";
import { CancelButton } from "Globals/Styles/Control/Buttons";
import { ReportModalStyle } from "Views/Project/Modals/Modal.Styles";
import { MaterialPriceDetailsModel } from "./MaterialPriceDetailsModel";
import { MaterialPriceDetailsViewModel } from "./MaterialPriceDetailsViewModel";
import { MaterialSupplier } from "../Shared/MaterialSupplier";
import { FormModalSection, FormRow } from "Views/Stock/Shared/Shared.styles";

interface IProps {
    viewModel: MaterialPriceDetailsViewModel;
}

export const MaterialPriceDetailsView: React.FC<IProps> = observer((props) => {
    const viewModel = props.viewModel;

    return (
        <BaseModal
            open={viewModel !== null}
            onClose={viewModel.cancel}
            title="Update material price/supplier"
            PaperComponent={ReportModalStyle}
            actions={
                <React.Fragment>
                    <Button autoFocus color="secondary" disabled={viewModel.server.IsBusy} onClick={viewModel.apiSavePriceDetailsAsync} variant="contained">
                        Save
                    </Button>
                    <CancelButton onClick={viewModel.cancel}>Cancel</CancelButton>
                </React.Fragment>
            }
        >
            <FormModalSection>
                <FormRow grid dc={"1fr 1fr 1fr 1fr"}>
                    <SHBox dcs={"2"}>
                        <DarwinSelect
                            displayName="Supplier:"
                            execute={viewModel.setSupplier}
                            fullWidth={true}
                            getOptionLabel={(option: MaterialSupplier) => option?.displayName ?? ""}
                            options={viewModel.suppliers}
                            placeholder="Please select"
                            validationMessage={viewModel.getError("supplier")}
                            value={viewModel.supplier}
                        />
                    </SHBox>
                </FormRow>
                <FormRow grid dc={"1fr 1fr 1fr 1fr"}>
                    <DarwinMoneyInput<MaterialPriceDetailsModel>
                        fieldName="estimatedPrice"
                        InputProps={{ startAdornment: <InputAdornment position="start">£</InputAdornment> }}
                        label={viewModel.currentEstimatedPrice}
                        maxLength={11}
                        placeholder=""
                        shrink={true}
                        type="number"
                        validateOnBlur={true}
                        viewModel={viewModel}
                    />
                    <DarwinMoneyInput<MaterialPriceDetailsModel>
                        fieldName="price"
                        InputProps={{ startAdornment: <InputAdornment position="start">£</InputAdornment> }}
                        label={viewModel.currentPrice}
                        maxLength={11}
                        placeholder=""
                        shrink={true}
                        type="number"
                        validateOnBlur={true}
                        viewModel={viewModel}
                    />
                </FormRow>
                <FormRow grid dc={"1fr"}>
                    <TextField
                        className="textarea-forminput"
                        InputLabelProps={{ shrink: true }}
                        label="Notes:"
                        onBlur={() => viewModel.isFieldValid("note")}
                        onChange={(event) => viewModel.setValue("note", event.target.value)}
                        helperText={viewModel.getError("note")}
                        error={!isEmptyOrWhitespace(viewModel.getError("note"))}
                        value={viewModel.getValue("note")}
                    />
                </FormRow>
                <FormRow grid dc={"1fr 1fr 1fr 1fr"}>
                    <SHBox dcs={"1"}>
                        <DarwinDateSelect
                            displayName="Purchase effective date:"
                            execute={(value: string | null) => viewModel.setValue("effectiveFromDate", value)}
                            placeholder="dd/mm/yyyy"
                            validationMessage={viewModel.getError("effectiveFromDate")}
                            value={viewModel.getValue("effectiveFromDate")}
                        />
                    </SHBox>
                </FormRow>
            </FormModalSection>
            <SnackBar
                messageText={viewModel.snackMessage}
                messageType={viewModel.snackType}
                active={viewModel.snackbarState}
                closeOption={() => viewModel.setSnackbarState(false)}
                autoHideDuration={5000}
            />
        </BaseModal>
    );
});
