// Libraries
import React from "react";
import { useObserver } from "mobx-react-lite";

// Custom

// Styling & Images
import { theme } from "Globals/Styles/AppTheme";
import { formatCurrencyForGrid, formatCurrencyNoSign } from "Utils/Format";
import { AllProjectReportProjectDTO, AllProjectReportAllProjectDTO } from "./AllProjectReportModelBase";
import { CVRSummaryGridView } from "../CVR/AllProjectCVRReport.styles";
import { makeStyles } from "@material-ui/core";

interface ITableProps {
    reportViewModel: AllProjectReportAllProjectDTO;
}

export const AllProjectReportGrid: React.FunctionComponent<ITableProps> = (props) => {
    const { reportViewModel } = props;

    const classes = useStyles();

    const RenderGrid = () => {
        return (
            <CVRSummaryGridView className={classes.IELineGroup}>
                {reportViewModel.projects.map((i: AllProjectReportProjectDTO, index: number) => (
                    <>
                        <li className="ie" key={"ie_" + index}>
                            <div style={{ display: "flex" }} className="ie-row-name" title={i.projectName}>
                                {i.projectName + " - " + i.projectReference}
                            </div>
                            <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column1.previous))}</div>
                            <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column1.current))}</div>
                            <div className={`right ${i.column1.creditOrDebitClassName}`}>{formatCurrencyForGrid(formatCurrencyNoSign(i.column1.movement))}</div>
                            <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column2.previous))}</div>
                            <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column2.current))}</div>
                            <div className={`right ${i.column2.creditOrDebitClassName}`}>{formatCurrencyForGrid(formatCurrencyNoSign(i.column2.movement))}</div>
                            <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column3.previous))}</div>
                            <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column3.current))}</div>
                            <div className={`right ${i.column3.creditOrDebitClassName}`}>{formatCurrencyForGrid(formatCurrencyNoSign(i.column3.movement))}</div>
                            <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column4.previous))}</div>
                            <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column4.current))}</div>
                            <div className={`right ${i.column4.creditOrDebitClassName}`}>{formatCurrencyForGrid(formatCurrencyNoSign(i.column4.movement))}</div>
                        </li>
                    </>
                ))}
                {reportViewModel.projects.length > 0 && (
                    <>
                        <li className="footing">
                            <div className="right">Projects total</div>
                            <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(reportViewModel.column1.previous))}</div>
                            <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(reportViewModel.column1.current))}</div>
                            <div className={`right ${reportViewModel.column1.creditOrDebitClassName}`}>
                                {formatCurrencyForGrid(formatCurrencyNoSign(reportViewModel.column1.movement))}
                            </div>
                            <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(reportViewModel.column2.previous))}</div>
                            <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(reportViewModel.column2.current))}</div>
                            <div className={`right ${reportViewModel.column2.creditOrDebitClassName}`}>
                                {formatCurrencyForGrid(formatCurrencyNoSign(reportViewModel.column2.movement))}
                            </div>
                            <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(reportViewModel.column3.previous))}</div>
                            <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(reportViewModel.column3.current))}</div>
                            <div className={`right ${reportViewModel.column3.creditOrDebitClassName}`}>
                                {formatCurrencyForGrid(formatCurrencyNoSign(reportViewModel.column3.movement))}
                            </div>
                            <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(reportViewModel.column4.previous))}</div>
                            <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(reportViewModel.column4.current))}</div>
                            <div className={`right ${reportViewModel.column4.creditOrDebitClassName}`}>
                                {formatCurrencyForGrid(formatCurrencyNoSign(reportViewModel.column4.movement))}
                            </div>
                        </li>
                    </>
                )}
            </CVRSummaryGridView>
        );
    };

    return useObserver(() => <>{props.reportViewModel && RenderGrid()}</>);
};

const useStyles = makeStyles({
    IELineGroup: {
        borderBottom: `4px solid ${theme.palette.detail.main}`,
    },
});
