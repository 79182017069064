import { ModelBase, observable } from "@shoothill/core";
import { action, computed } from "mobx";
import { DesignDataFormItemModel } from "../../Forms/DesignDataFormItemModel";
export class DesignDataItemModel extends ModelBase<DesignDataItemModel> {
    public static readonly DEFAULT_DESIGNDATA_ID = "";

    @observable
    public designDataId: string = DesignDataItemModel.DEFAULT_DESIGNDATA_ID;

    @observable
    public designDataItems: DesignDataFormItemModel[] = [];

    @action
    public fromDto(dto: DesignDataItemResponseDTO): void {
        for (let key in dto) {
            if (dto.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(dto[key]);
                } else if (key === "designDataItems") {
                    // Do nothing.
                } else {
                    this[key] = dto[key];
                }
            }
        }
    }

    @action
    toDto(model: DesignDataItemModel): void {}
}

export interface DesignDataItemResponseDTO {
    designDataItems: DesignDataFormItemModel[];
}

export interface DesignDataItemRequestDTO {
    id: string | null;
}
