import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { DailyDiaryViewModel } from "./DailyDiaryViewModel";
import { uniqueId } from "lodash-es";
import { DailyDiaryQuestionView } from "./DailyDiaryQuestionView";
import { SHBox } from "Components/Box";
import { Typography } from "@material-ui/core";
import { DashedDivider } from "../../../PurchaseOrder/Form/Views/Dividers";

interface Props {
    date: Date;
    projectId: string;
}
export const DailyDiaryView: React.FC<Props> = observer((props: Props) => {
    const [viewModel] = useState(() => new DailyDiaryViewModel());

    useEffect(() => {
        viewModel.setValue("projectId", props.projectId);
        viewModel.date = props.date;
        viewModel.getDailyDiary();
    }, [props.date]);

    return (
        <SHBox p={3}>
            <SHBox grid dc={"1fr 100px 150px"} mb={2}>
                <Typography></Typography>
                <Typography variant={"h5"}>Complete?</Typography>
                <Typography variant={"h5"}>Completed by</Typography>
            </SHBox>
            <DashedDivider gutterBottom />
            {viewModel.dailyDiaryQuestionViewModels.map((viewModel) => {
                return (
                    <SHBox key={uniqueId()}>
                        <DailyDiaryQuestionView viewModel={viewModel} disabled={props.date.toDateString() !== new Date().toDateString()} />
                        <SHBox pt={2} pb={2}>
                            <DashedDivider />
                        </SHBox>
                    </SHBox>
                );
            })}
        </SHBox>
    );
});
