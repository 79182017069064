import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { FireExtinguishersViewModel } from "./FireExtinguishersViewModel";
import { SHBox } from "../../../../../Components/Box";
import { RadioAuditView } from "./EndOfWeekView";
import { FireExitModel } from "./FireExitModel";
import { Uploader } from "../../../../../Components/Uploader/Uploader";
import { SectionSplitter } from "../../../../../Components/Form/SectionSplitter";
import { FireExitViewModel } from "./FireExitViewModel";
import { EndOfWeekViewModel } from "./EndOfWeekViewModel";
import { uniqueId } from "lodash-es";
import { IconButton, Typography } from "@material-ui/core";
import SystemUpdateTwoToneIcon from "@material-ui/icons/SystemUpdateTwoTone";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { AuditDocumentModel } from "../AuditDocument";
import { DarwinInput } from "../../../../../Globals/Styles/Control/DarwinInput";
import { DarwinSelect } from "../../../../../Components/AutoComplete/DarwinSelect";
interface Props {
    index: number;
    fireExtinguisherViewModel: FireExtinguishersViewModel;
    endOfWeekViewModel: EndOfWeekViewModel;
}
export const FireExtinguishersView: React.FC<Props> = observer((props: Props) => {
    const { fireExtinguisherViewModel, endOfWeekViewModel } = props;

    return (
        <>
            <SHBox mt={2} key={uniqueId()}>
                <Typography variant={"h4"}>Fire Extinguisher {props.index + 1}:</Typography>
                <SHBox grid dc={"262px 262px"} mt={2} mb={2}>
                    <DarwinInput<FireExtinguishersViewModel>
                        type="text"
                        label="Location:"
                        placeholder="Please add"
                        validateOnBlur={false}
                        viewModel={fireExtinguisherViewModel}
                        fieldName={"location"}
                        shrink={true}
                        maxLength={4096}
                        editMode={endOfWeekViewModel.editable}
                    />
                    <DarwinSelect
                        displayName="Type of Extinguisher:"
                        execute={fireExtinguisherViewModel.setExtinguisherType}
                        fullWidth={false}
                        getOptionLabel={(option: any) => option.displayName}
                        options={fireExtinguisherViewModel.extinguisherTypesDTO}
                        placeholder="Please select user"
                        value={fireExtinguisherViewModel.extinguisherType}
                        canExecute={endOfWeekViewModel.editable}
                    />
                </SHBox>
                <SHBox grid dc={"2fr 1fr"} mt={1}>
                    <RadioAuditView
                        title={"Serviced within last twelve months"}
                        value={() => fireExtinguisherViewModel.getValue("servicedWithinLast12Months")}
                        validationMessage={() => fireExtinguisherViewModel.getError("servicedWithinLast12Months")}
                        onChange={(e) => fireExtinguisherViewModel.radioChanged(e, "servicedWithinLast12Months", endOfWeekViewModel.editable)}
                        canExecute={endOfWeekViewModel.editable}
                    />
                    <SHBox dcs={"2"} showIf={fireExtinguisherViewModel.getValue("servicedWithinLast12Months") === "no"}>
                        <DarwinInput<FireExitViewModel>
                            type="text"
                            label="Please give more details"
                            multiLine={true}
                            validateOnBlur={false}
                            viewModel={fireExtinguisherViewModel}
                            fieldName={"servicedWithinLast12MonthsDetail"}
                            shrink={true}
                            maxLength={4096}
                            editMode={endOfWeekViewModel.editable}
                        />
                    </SHBox>
                    <RadioAuditView
                        title={"Pin and security tags in place"}
                        value={() => fireExtinguisherViewModel.getValue("pinAndSecurityTagsInPlace")}
                        validationMessage={() => fireExtinguisherViewModel.getError("pinAndSecurityTagsInPlace")}
                        onChange={(e) => fireExtinguisherViewModel.radioChanged(e, "pinAndSecurityTagsInPlace", endOfWeekViewModel.editable)}
                        canExecute={endOfWeekViewModel.editable}
                    />
                    <SHBox dcs={"2"} showIf={fireExtinguisherViewModel.getValue("pinAndSecurityTagsInPlace") === "no"}>
                        <DarwinInput<FireExitViewModel>
                            type="text"
                            label="Please give more details"
                            multiLine={true}
                            validateOnBlur={false}
                            viewModel={fireExtinguisherViewModel}
                            fieldName={"pinAndSecurityTagsInPlaceDetail"}
                            shrink={true}
                            maxLength={4096}
                            editMode={endOfWeekViewModel.editable}
                        />
                    </SHBox>
                    <RadioAuditView
                        title={"Either on a bracket or a stand"}
                        value={() => fireExtinguisherViewModel.getValue("eitherOnABracketOrStand")}
                        validationMessage={() => fireExtinguisherViewModel.getError("eitherOnABracketOrStand")}
                        onChange={(e) => fireExtinguisherViewModel.radioChanged(e, "eitherOnABracketOrStand", endOfWeekViewModel.editable)}
                        canExecute={endOfWeekViewModel.editable}
                    />
                    <SHBox dcs={"2"} showIf={fireExtinguisherViewModel.getValue("eitherOnABracketOrStand") === "no"}>
                        <DarwinInput<FireExitViewModel>
                            type="text"
                            label="Please give more details"
                            multiLine={true}
                            validateOnBlur={false}
                            viewModel={fireExtinguisherViewModel}
                            fieldName={"eitherOnABracketOrStandDetail"}
                            shrink={true}
                            maxLength={4096}
                            editMode={endOfWeekViewModel.editable}
                        />
                    </SHBox>
                    <RadioAuditView
                        title={"Extinguisher clean, nothing leaning or placed on it with no obstructions around it"}
                        value={() => fireExtinguisherViewModel.getValue("extinguisherClean")}
                        validationMessage={() => fireExtinguisherViewModel.getError("extinguisherClean")}
                        onChange={(e) => fireExtinguisherViewModel.radioChanged(e, "extinguisherClean", endOfWeekViewModel.editable)}
                        canExecute={endOfWeekViewModel.editable}
                    />
                    <SHBox dcs={"2"} showIf={fireExtinguisherViewModel.getValue("extinguisherClean") === "no"}>
                        <DarwinInput<FireExitViewModel>
                            type="text"
                            label="Please give more details"
                            multiLine={true}
                            validateOnBlur={false}
                            viewModel={fireExtinguisherViewModel}
                            fieldName={"extinguisherCleanDetail"}
                            shrink={true}
                            editMode={endOfWeekViewModel.editable}
                            maxLength={4096}
                        />
                    </SHBox>
                    {/*<SHBox grid dc={"1fr"}>
                        <SHBox>
                            <Uploader
                                handleSelectFile={(e: React.ChangeEvent<HTMLInputElement>) => endOfWeekViewModel.fileChange(e, fireExtinguisherViewModel.model.auditDocuments)}
                                isFormDisabled={endOfWeekViewModel.IsLoading}
                                label="Photo(s)"
                            />
                            <Typography variant={"h5"} color={"error"}>
                                {fireExtinguisherViewModel.getError("auditDocuments")}
                            </Typography>
                        </SHBox>
                        <div className="uploadedFile">
                            {fireExtinguisherViewModel.model.auditDocuments.map((item: AuditDocumentModel, index: number) => {
                                return (
                                    <div className="uploadedFile-box" key={uniqueId()}>
                                        <div className="uploadedFile-heading">
                                            <IconButton onClick={() => endOfWeekViewModel.DownloadFile(item.url, item.fileName)}>
                                                <SystemUpdateTwoToneIcon />
                                            </IconButton>
                                            <h3>{item.fileName}</h3>
                                        </div>
                                        <IconButton onClick={() => item.deleteDocument()} disabled={false}>
                                            <DeleteOutlinedIcon />
                                        </IconButton>
                                    </div>
                                );
                            })}
                        </div>
                    </SHBox>*/}
                </SHBox>
                <SectionSplitter />
            </SHBox>
        </>
    );
});
