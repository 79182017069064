import { Box } from "@material-ui/core";
import styled from "styled-components";

export const SectionSplitter = styled(Box)`
    margin: 20px 0;
    border-bottom: 1px dashed #ced4da;
`;

export const SolidSectionSplitter = styled(Box)`
    border-bottom: 1px solid #ced4da;
`;

export const DashedSectionSplitter = styled(Box)`
    border-bottom: 2px dashed #ced4da;
`;
