import { FieldType, ViewModelBase } from "@shoothill/core";
import { ProjectTrackersModel } from "./ProjectTrackersModel";
import { observable, action } from "mobx";

export class ProjectTrackersViewModel extends ViewModelBase<ProjectTrackersModel> {
    private static _instance: ProjectTrackersViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable
    public hasLoaded: boolean = false;

    @action
    public setHasLoaded = (val: boolean) => {
        this.hasLoaded = val;
    };

    @observable public errorMessage: string = "";

    public constructor() {
        super(new ProjectTrackersModel(), false);
        this.setDecorators(ProjectTrackersModel);
    }

    public async isFieldValid(fieldName: keyof FieldType<ProjectTrackersModel>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
