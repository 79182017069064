import { Box, FormHelperText, Typography } from "@material-ui/core";
import { Grid, isEmptyOrWhitespace, useRouter } from "@shoothill/core";
import { useObserver } from "mobx-react-lite";
import moment from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { AppUrls } from "AppUrls";
import { DarwinSelect } from "Components/AutoComplete/DarwinSelect";
import { DefaultButton, PrimaryButton } from "Components/Buttons/Buttons";
import { Signature } from "Components/Signature/Signature";
import { pxToRem, theme } from "Globals/Styles/AppTheme";
import { BackButtonHolder } from "Views/Project/Commercial/IETables/IEGrid.Styles";
import { CustomArrow } from "Views/Project/CustomComponents";
import { DashedDivider, SolidDivider } from "Views/PurchaseOrder/Form/Views/Dividers";
import { Form, FormSection } from "Views/PurchaseOrder/Form/Views/Forms";
import { HouseKeepingFormViewModel } from "./HouseKeepingFormViewModel";
import NonConformanceFormHeader from "../NonConformanceFormHeader";
import { NonConformanceListViewModel } from "../NonConformanceListViewModel";
import { SupplierDTO } from "./HouseKeepingFormModel";

interface IProps {
    className?: string;
}

const PermitFormViewBase: React.FC<IProps> = (props) => {
    const { match } = useRouter();
    const { projectid, nonConformanceTypeId, nonConformanceId } = match.params as any;
    const [viewModel] = useState(() => new HouseKeepingFormViewModel(nonConformanceId, projectid, nonConformanceTypeId));
    const [listViewModel] = useState(() => new NonConformanceListViewModel());
    const [currentDateTime, setCurrentDateTime] = useState(moment(Date().toLocaleString()).format("D/M/YYYY @ H:mm"));
    const { history } = useRouter();

    useEffect(() => {
        return () => {
            viewModel.reset();
        };
    }, []);

    setInterval(() => {
        setCurrentDateTime(moment(Date().toLocaleString()).format("D/M/YYYY @ H:mm"));
    }, 1000);

    useEffect(() => {
        listViewModel.projectId = projectid;
        listViewModel.loadNonConformanceAndRelated();
    }, []);

    const COLUMNS4 = "1fr 1fr 1fr 1fr";

    const onSubmit = (event: any) => {
        event.preventDefault();
    };

    const renderForm = () => {
        return (
            <FormBox>
                <FormViewBox>
                    <DashedDivider gutterBottom={false} borderThickness={1} borderOpacity={1} />
                    <FormSection>
                        <Grid rowGap={20} tc={COLUMNS4} dc={COLUMNS4} mt={"17px"}>
                            <DarwinSelect
                                canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                                displayName="Company issued To:"
                                error={viewModel.getError("supplierId") !== ""}
                                execute={viewModel.setSupplier}
                                fullWidth={false}
                                getOptionLabel={(option: SupplierDTO) => option.displayName}
                                onBlur={() => viewModel.isFieldValid("supplierId")}
                                options={viewModel.suppliers}
                                placeholder="Please select"
                                validationMessage={viewModel.getError("supplierId")}
                                value={viewModel.supplier}
                            />
                        </Grid>
                        <AuthorisationsContainer>
                            It has been brought to our attention that you/your company operatives are not cleaning out your/their own debris to the designated waste disposal points
                            as agreed prior to commencement of works. Charges will be issued in-line with the subcontractor agreement.
                        </AuthorisationsContainer>
                    </FormSection>
                    <FormSection>
                        <SolidDivider gutterBottom={true} borderThickness={1} borderOpacity={1} />
                        <SignatureContainer>
                            <SubtitleContainer>
                                <Typography
                                    variant="h2"
                                    style={{ font: "normal normal 600 18px/26px Open Sans", letterSpacing: "-0.36px", color: "#191919", marginBottom: "12px" }}
                                >
                                    Authorization
                                </Typography>
                            </SubtitleContainer>
                            <Grid rowGap={20} tc={COLUMNS4} dc={COLUMNS4} style={{ display: "grid" }}>
                                <DarwinSelect
                                    canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                                    displayName="Clean up notice issued by:"
                                    error={viewModel.getError("issuedByUserId") !== ""}
                                    execute={viewModel.setIssuedByUser}
                                    fullWidth={false}
                                    getOptionLabel={(option: any) => option.displayName}
                                    onBlur={() => viewModel.isFieldValid("issuedByUserId")}
                                    options={viewModel.issuedByUsers}
                                    placeholder="Please select user"
                                    validationMessage={viewModel.getError("issuedByUserId")}
                                    value={viewModel.issuedByUser}
                                />
                            </Grid>
                        </SignatureContainer>
                        <Grid rowGap={20} tc={COLUMNS4} dc={COLUMNS4}>
                            <SignatureContainer style={{ marginTop: "25px" }}>
                                <SubtitleContainer></SubtitleContainer>
                                <Box>
                                    <Box>
                                        <Signature
                                            displayName={"Your signature:"}
                                            execute={viewModel.setSignature}
                                            value={viewModel.model.signatureIssuedByURL}
                                            canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                                        />
                                        {viewModel.model.signatureIssuedByURL === null && viewModel.getError("signatureIssuedByURL") !== "" && (
                                            <FormHelperText style={{ color: "#f44336" }}>{viewModel.getError("signatureIssuedByURL")}</FormHelperText>
                                        )}
                                    </Box>
                                </Box>
                                <ProjectDetailsContainer>
                                    <Typography variant="h2">
                                        <span style={{ fontWeight: 700 }}>Date / time added:</span> {`${currentDateTime}`}
                                    </Typography>
                                </ProjectDetailsContainer>
                            </SignatureContainer>
                        </Grid>
                    </FormSection>
                    <Box style={{ margin: `0 ${pxToRem(30)} 0 ${pxToRem(30)}` }}>
                        <SolidDivider gutterBottom={true} borderThickness={1} borderOpacity={1} />
                    </Box>
                    <FormSection>
                        <ButtonsContainer>
                            <PrimaryButton displayName="Save" execute={() => viewModel.upsert()} fullWidth={true} canExecute={!viewModel.IsLoading} />
                            <DefaultButton displayName="Cancel" execute={() => viewModel.handleCancel(projectid)} fullWidth={true} canExecute={!viewModel.IsLoading} />
                        </ButtonsContainer>
                    </FormSection>
                </FormViewBox>
            </FormBox>
        );
    };

    const renderReadOnlyView = () => {
        return (
            <FormBox>
                <DataViewBox>
                    <DashedDivider gutterBottom={false} borderThickness={1} borderOpacity={1} />

                    {/* Concerning housekeeping */}
                    <FormSection>
                        <Grid columnGap={30} rowGap={20} tc={COLUMNS4} dc={COLUMNS4} mt={"5px"}>
                            <ProjectDetailsContainer>
                                <Box>
                                    <span>Company issued to:</span> {viewModel.readOnlySupplierDisplayName}
                                </Box>
                            </ProjectDetailsContainer>
                        </Grid>
                        <AuthorisationsContainer>
                            It has been brought to our attention that you/your company operatives are not cleaning out your/their own debris to the designated waste disposal agreed
                            prior to commencement of works. Charges will be issued in-line with the subcontractor agreement.
                        </AuthorisationsContainer>
                        <SolidDivider gutterBottom={true} gutterTop={true} />
                        <Typography style={{ font: "normal normal 600 18px/26px Open Sans", letterSpacing: "-0.36px", color: "#191919" }} variant="h2">
                            Authorisation
                        </Typography>
                        <Grid columnGap={30} rowGap={20} tc={COLUMNS4} dc={COLUMNS4} style={{ marginBottom: "20px" }}>
                            <ProjectDetailsContainer>
                                <Box>
                                    <span>Date / time added:</span>{" "}
                                    {viewModel.houseKeeping?.createdDate ? moment(Date.parse(viewModel.houseKeeping.createdDate)).format("D/M/YYYY @ H:mm") : ""}
                                </Box>
                            </ProjectDetailsContainer>
                            <ProjectDetailsContainer>
                                <Box>
                                    <span>Clean up notice issued by:</span>
                                    {viewModel.houseKeeping?.issuedByUserId ? viewModel.setIssuedByUser(viewModel.houseKeeping.issuedByUserId) : ""}
                                </Box>
                            </ProjectDetailsContainer>
                        </Grid>
                        <Grid columnGap={30} rowGap={20} tc={COLUMNS4} dc={COLUMNS4} style={{ marginBottom: "20px" }}>
                            <SignBlock>
                                <span>Card issued by signature:</span>
                                <img src={viewModel.houseKeeping?.signatureIssuedByURL ? viewModel.houseKeeping.signatureIssuedByURL : ""} />
                            </SignBlock>
                        </Grid>
                    </FormSection>
                </DataViewBox>
            </FormBox>
        );
    };

    return useObserver(() => {
        return (
            <Form className={props.className} onSubmit={onSubmit}>
                <BackButtonHolder
                    onClick={() => {
                        history.push(AppUrls.Client.Project.ConstructionQuality.replace(":projectid", projectid ? projectid : viewModel.model.projectId) + "#nonconformance");
                    }}
                    style={{ padding: "10px 0", marginTop: 0 }}
                >
                    <Box style={{ position: "relative", display: "flex", marginLeft: "10px", alignItems: "center" }}>
                        <Box style={{ position: "absolute", height: "15px", width: "8px" }}>
                            <CustomArrow size={"8px"} color={theme.palette.blue.main} type={"left"} />
                        </Box>
                        <Box style={{ marginLeft: "20px", font: "normal normal 600 18px/32px Open Sans", letterSpacing: "-0.36px", color: theme.palette.blue.main }}>
                            Back to Pre-use inspections {viewModel.projectDisplayName}
                        </Box>
                    </Box>
                </BackButtonHolder>
                <NonConformanceFormHeader
                    ProjectDetails={viewModel.housekeepingProjectDetails}
                    ProjectDate={viewModel.getTodayDateFormatted}
                    CompletedBy={"Emma Girlface"}
                    SafetyId={viewModel.model.id}
                    FormHeaderTitle={viewModel.nonConformanceTypeDisplayName(listViewModel)}
                    NonConformanceListItems={listViewModel.nonConformanceListModels}
                    item={viewModel.houseKeeping}
                    IsNewForm={isEmptyOrWhitespace(viewModel.model.id)}
                />
                {isEmptyOrWhitespace(viewModel.model.id) ? renderForm() : renderReadOnlyView()}
            </Form>
        );
    });
};

export const HouseKeepingFormView = styled(PermitFormViewBase)`
    display: flex;
    flex: 1;
    flex-direction: column;

    form {
        position: relative;
    }
`;

const ProjectDetailsContainer = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    max-width: 100%;
    margin-top: 12px;

    > div {
        font: normal normal normal 12px/16px Segoe UI;
        letter-spacing: -0.18px;
        color: #191919;
    }

    > div > span {
        font-weight: bold;
        margin-right: 10px;
    }
    h2 {
        margin-bottom: ${(props) => (props.margin ? props.margin : "10px")};
        font: normal normal 600 12px/16px Segoe UI;
        letter-spacing: -0.18px;
        color: #191919;
        span {
            font-weight: bold;
            margin-right: 10px;
        }
    }
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;

    > button {
        max-width: 262px;
    }

    > button:nth-child(1) {
        margin-right: 30px;
    }
`;

const FormViewBox = styled(Box)`
    .MuiFormLabel-root {
        font: normal normal 700 12px/16px Segoe UI;
        letter-spacing: -0.18px;
        color: #191919;
    }
`;

const FormBox = styled(Box)`
    .MuiFormLabel-root {
        font: normal normal 700 12px/16px Segoe UI;
        letter-spacing: -0.18px;
        color: #191919;
        margin: 0 0 7px;
    }
`;

const DataViewBox = styled(Box)`
    .MuiFormLabel-root {
        font: normal normal 700 12px/16px Segoe UI;
        letter-spacing: -0.18px;
        color: #191919;
    }
`;

const AuthorisationsContainer = styled.div`
    text-align: left;
    font: normal normal normal 12px/16px Open Sans;
    letter-spacing: -0.3px;
    color: #191919;
    opacity: 1;
    margin-top: 18px;
`;

interface SubtitleProps {
    margin?: string;
}

export const SubtitleContainer = styled.div<SubtitleProps>`
    h2 {
        margin-bottom: ${(props) => (props.margin ? props.margin : "10px")};
    }
`;

const SignBlock = styled(Box)`
    font: normal normal normal 12px/16px Segoe UI;
    letter-spacing: -0.18px;
    color: #191919;
    span {
        font-weight: 700;
        display: block;
        margin-bottom: 10px;
    }
`;

const SignatureContainer = styled.div`
    display: flex;
    flex-direction: column;
    grid-column-start: 1;
    grid-column-end: 3;
    > div:nth-child(2) {
        display: flex;
        flex-direction: row;

        > div {
            width: 100%;

            canvas {
                border: 1px solid #d4d4d4;
            }
        }
    }
    .MuiFormControl-root {
        width: 100% !important;
    }
`;
