import { debounce } from "@material-ui/core";
import { FieldType, ViewModelBase, isNullOrUndefined } from "@shoothill/core";
import { observable, action, computed, IObservableArray, runInAction } from "mobx";

import { AppUrls } from "AppUrls";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { MaterialApprovalViewModel } from "Views/Stock/Material/MaterialApproval/MaterialApprovalViewModel";
import { MaterialPriceDetailsApprovalViewModel } from "Views/Stock/Material/MaterialPriceDetailsApproval/MaterialPriceDetailsApprovalViewModel";
import { StockAdjustmentApprovalViewModel } from "Views/Stock/Stock/StockAdjustmentApproval/StockAdjustmentApprovalViewModel";
import { StockTransferApprovalViewModel } from "Views/Stock/Stock/StockTransferApproval/StockTransferApprovalViewModel";
import { IMaterialTransactionRequestViewDTO } from "Views/Stock/Material/Shared/MaterialTransactionRequestDtos";
import { IStockTransactionRequestViewDTO } from "Views/Stock/Stock/Shared/StockTransactionRequestDtos";
import { ApprovalListModel } from "./ApprovalListModel";
import { MyApprovalsNewListItemViewModel } from "./MyApprovalsNewListItemViewModel";
import { MyApprovalsActionedListItemViewModel } from "./MyApprovalsActionedListItemViewModel";
import { MyApprovalsNewListItemModelDTO } from "./MyApprovalsNewListItemModel";
import { MyApprovalsActionedListItemModelDTO } from "./MyApprovalsActionedListItemModel";
import { TabItemViewModel } from "./Navigation/TabItemViewModel";
import { TabsViewModel } from "./Navigation/TabsViewModel";

export class ApprovalListViewModel extends ViewModelBase<any> {
    public server: ServerViewModel = new ServerViewModel();

    // #region Constants

    public static readonly TAB_DEFAULT_INDEX = 0;
    public static readonly TAB_ALL_APPROVALS = "allApprovals";
    public static readonly TAB_REQUIRED_APPROVALS = "requiredApprovals";
    public static readonly TAB_RESOLVED = "resolved";

    // #endregion Constants

    private static _instance: ApprovalListViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    public tabsViewModel = new TabsViewModel(
        [
            new TabItemViewModel(ApprovalListViewModel.TAB_REQUIRED_APPROVALS, "Required approvals"),
            new TabItemViewModel(ApprovalListViewModel.TAB_ALL_APPROVALS, "All approvals"),
            new TabItemViewModel(ApprovalListViewModel.TAB_RESOLVED, "Resolved"),
        ],
        (value: TabItemViewModel) => {
            this.loadData(value.id);
        },
    );

    public constructor() {
        super(new ApprovalListModel(), false);

        this.setDecorators(ApprovalListModel);
        this.initialize();
    }

    /**
     * Load dependent data from the API.
     */
    private initialize = () => {
        this.tabsViewModel.setActiveTabItemViewModel(this.tabsViewModel.tabItemViewModels[ApprovalListViewModel.TAB_DEFAULT_INDEX]);

        this.getApprovalCounts();
    };

    @observable
    public errorMessage: string = "";

    @observable
    public hasLoaded = true;

    // #region Search String Filter

    private readonly DEBOUNCE_VALUE_MS = 200;

    @observable
    public searchString: string = "";

    @observable
    public filterSearchString: string = "";

    public getSearchString = () => {
        return computed(() => this.searchString);
    };

    @action
    public setSearchString = (value: string, tabKey: string) => {
        this.searchString = value;
        this.setFilterSearchString(value, tabKey);
    };

    private setFilterSearchString = debounce(
        action((value: string, tabKey: string) => {
            this.filterSearchString = value;
            this.loadData(tabKey);
        }),
        this.DEBOUNCE_VALUE_MS,
    );

    // #endregion Search String Filter

    @computed
    public get filteredMyApprovalsNewViewModels(): MyApprovalsNewListItemViewModel[] {
        return this.myApprovalsNewViewModels;
    }

    @computed
    public get filteredMyApprovalsRequiredViewModels(): MyApprovalsNewListItemViewModel[] {
        return this.myApprovalsRequiredViewModels;
    }

    @computed
    public get filteredMyApprovalsActionedViewModels(): MyApprovalsActionedListItemViewModel[] {
        return this.myApprovalsActionedViewModels;
    }

    public myApprovalsNewViewModels: IObservableArray<MyApprovalsNewListItemViewModel> = observable<MyApprovalsNewListItemViewModel>([]);
    public myApprovalsRequiredViewModels: IObservableArray<MyApprovalsNewListItemViewModel> = observable<MyApprovalsNewListItemViewModel>([]);
    public myApprovalsActionedViewModels: IObservableArray<MyApprovalsActionedListItemViewModel> = observable<MyApprovalsActionedListItemViewModel>([]);

    @action
    private setHasLoaded(val: boolean) {
        this.hasLoaded = val;
    }

    @action
    private populateNewListItemViewModels = (dto: MyApprovalsNewListItemModelDTO[]) => {
        const myApprovalsNewViewModels: MyApprovalsNewListItemViewModel[] = [];

        for (const myApprovalsNewListItem of dto) {
            const viewModel = new MyApprovalsNewListItemViewModel();
            viewModel.model.fromDto(myApprovalsNewListItem);
            myApprovalsNewViewModels.push(viewModel);
        }

        this.myApprovalsNewViewModels.replace(myApprovalsNewViewModels);
    };

    @action
    private populateRequiredListItemViewModels = (dto: MyApprovalsNewListItemModelDTO[]) => {
        const myApprovalsRequiredViewModels: MyApprovalsNewListItemViewModel[] = [];

        for (const myApprovalsRequiredListItem of dto) {
            const viewModel = new MyApprovalsNewListItemViewModel();
            viewModel.model.fromDto(myApprovalsRequiredListItem);
            myApprovalsRequiredViewModels.push(viewModel);
        }

        this.myApprovalsRequiredViewModels.replace(myApprovalsRequiredViewModels);
    };

    @action
    private populateActionedListItemViewModels = (dto: MyApprovalsActionedListItemModelDTO[]) => {
        const myApprovalsActionedViewModels: MyApprovalsActionedListItemViewModel[] = [];

        for (const myapprovalsActionedListItem of dto) {
            const viewModel = new MyApprovalsActionedListItemViewModel();
            viewModel.model.fromDto(myapprovalsActionedListItem);
            myApprovalsActionedViewModels.push(viewModel);
        }

        this.myApprovalsActionedViewModels.replace(myApprovalsActionedViewModels);
    };

    public loadData = (val: string) => {
        switch (val) {
            case ApprovalListViewModel.TAB_ALL_APPROVALS:
                this.getMyApprovalsNew(false);
                break;
            case ApprovalListViewModel.TAB_REQUIRED_APPROVALS:
                this.getMyApprovalsNew(true);
                break;
            case ApprovalListViewModel.TAB_RESOLVED:
                this.getMyApprovalsActioned();
                break;
            default:
                this.getMyApprovalsNew(true);
        }
    };

    public getMyApprovalsNew = async (isRequired: boolean): Promise<void> => {
        this.setIsLoading(true);
        const search = this.searchString === "" ? "" : this.searchString;

        const path: string = AppUrls.Server.Approval.SearchMyApprovalsNew + "/?search=" + search + "&isRequired=" + isRequired;

        const apiResult = await this.Get<MyApprovalsNewListItemModelDTO[]>(path);

        if (apiResult) {
            this.setHasLoaded(false);
            if (apiResult.wasSuccessful) {
                if (isRequired) {
                    this.populateRequiredListItemViewModels(apiResult.payload);
                } else {
                    this.populateNewListItemViewModels(apiResult.payload);
                }
            } else {
                console.log(apiResult.errors);
            }
            this.setHasLoaded(true);
        }
        this.setIsLoading(false);
    };

    public getMyApprovalsActioned = async (): Promise<void> => {
        this.setIsLoading(true);
        const search = this.searchString === "" ? "" : this.searchString;

        const path: string = AppUrls.Server.Approval.SearchMyApprovalsActioned + "/?search=" + search;

        const apiResult = await this.Get<MyApprovalsActionedListItemModelDTO[]>(path);

        if (apiResult) {
            this.setHasLoaded(false);
            if (apiResult.wasSuccessful) {
                this.populateActionedListItemViewModels(apiResult.payload);
            } else {
                console.log(apiResult.errors);
            }
            this.setHasLoaded(true);
        }
        this.setIsLoading(false);
    };

    public getApprovalCounts = async (): Promise<void> => {
        this.setIsLoading(true);
        const apiResult = await this.Get(AppUrls.Server.Approval.GetApprovalCounts);

        this.setHasLoaded(false);
        if (apiResult.wasSuccessful) {
            const payload: any = apiResult.payload;

            for (const tabItemViewModel of this.tabsViewModel.tabItemViewModels) {
                switch (tabItemViewModel.id) {
                    case ApprovalListViewModel.TAB_REQUIRED_APPROVALS:
                        tabItemViewModel.setCount(payload.required);
                        break;

                    default:
                        tabItemViewModel.setCount(undefined);
                        break;
                }
            }
        } else {
            console.log(apiResult.errors);
        }
        this.setHasLoaded(true);
        this.setIsLoading(false);
    };

    @action
    public cleanUp = () => {
        // TODO Any Cleanup Code here. e.g. if  a user or project or client etc, wipe it from the instance on page shutdown
    };

    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (await this.isModelValid()) {
            //Do stuff here
            this.errorMessage = "Form is valid";
        } else {
            this.errorMessage = "Form is not valid";
        }
    };

    // #region Approval Modals

    @observable
    public approvalViewModel: MaterialApprovalViewModel | MaterialPriceDetailsApprovalViewModel | StockAdjustmentApprovalViewModel | StockTransferApprovalViewModel | null = null;

    @action
    public navigateToMaterialApproval = (rowData: MyApprovalsNewListItemViewModel | MyApprovalsActionedListItemViewModel): void => {
        this.apiLoadMaterialTransactionRequest(rowData.model.materialTransactionRequestId!);
    };

    @action
    public navigateToStockApproval = (rowData: MyApprovalsNewListItemViewModel | MyApprovalsActionedListItemViewModel): void => {
        this.apiLoadStockTransactionRequest(rowData.model.stockTransactionRequestId!);
    };

    @action
    public closeApprovalViewModel = (refreshPage: boolean) => {
        this.approvalViewModel = null;

        if (refreshPage) {
            this.loadData(this.tabsViewModel.activeTabItemViewModel?.id!);
            this.getApprovalCounts();
        }
    };

    @action
    public apiLoadMaterialTransactionRequest = async (materialTransactionRequestId: string): Promise<void> => {
        await this.server.query<IMaterialTransactionRequestViewDTO>(
            () =>
                this.Get(
                    AppUrls.Server.Stock.Material.GetMaterialTransactionRequestViewByMaterialTransactionRequestId.replace(
                        "{materialtransactionrequestid}",
                        materialTransactionRequestId,
                    ),
                ),
            (result) => {
                runInAction(() => {
                    switch (true) {
                        case !isNullOrUndefined(result.createMaterialTransactionRequestView):
                            this.approvalViewModel = new MaterialApprovalViewModel(true, this.closeApprovalViewModel);
                            this.approvalViewModel.model.fromDto(result.createMaterialTransactionRequestView!);
                            break;

                        case !isNullOrUndefined(result.updateMaterialPriceDetailsTransactionRequestView):
                            this.approvalViewModel = new MaterialPriceDetailsApprovalViewModel(true, this.closeApprovalViewModel);
                            this.approvalViewModel.model.fromDto(result.updateMaterialPriceDetailsTransactionRequestView!);
                            break;
                    }
                });
            },
            "Error whilst loading the material request",
        );

        if (this.server.HaveValidationMessage) {
            this.setSnackMessage(this.server.ValidationMessage);
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }
    };

    @action
    public apiLoadStockTransactionRequest = async (stockTransactionRequestId: string): Promise<void> => {
        await this.server.query<IStockTransactionRequestViewDTO>(
            () => this.Get(AppUrls.Server.Stock.GetStockTransactionRequestViewByStockTransactionRequestId.replace("{stocktransactionrequestid}", stockTransactionRequestId)),
            (result) => {
                runInAction(() => {
                    switch (true) {
                        case !isNullOrUndefined(result.adjustStockTransactionRequestView):
                            this.approvalViewModel = new StockAdjustmentApprovalViewModel(true, this.closeApprovalViewModel);
                            this.approvalViewModel.model.fromDto(result.adjustStockTransactionRequestView!);
                            break;

                        case !isNullOrUndefined(result.transferStockTransactionRequestView):
                            this.approvalViewModel = new StockTransferApprovalViewModel(true, this.closeApprovalViewModel);
                            this.approvalViewModel.model.fromDto(result.transferStockTransactionRequestView!);
                            break;
                    }
                });
            },
            "Error whilst loading the stock request",
        );

        if (this.server.HaveValidationMessage) {
            this.setSnackMessage(this.server.ValidationMessage);
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }
    };

    // #endregion Approval Modals

    // #region Snack Bar

    public SNACKERROR = "error";

    @observable
    public snackbarState = false;

    @observable
    public snackType = "";

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public snackMessage = "";

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    // #endregion Snack Bar

    public async isFieldValid(fieldName: keyof FieldType<ApprovalListModel>): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
