import * as React from "react";

import { CancelButton } from "Globals/Styles/Control/Buttons";
import { BaseModal } from "Components/Modal/BaseModal";
import { InvoiceMatchSubmittedWarningModalDialogPaper } from "./InvoiceMatchSubmitWarningModal.styles";

interface IProps {
    open?: boolean;
    onClose(): void;
    title: string;
    text: string;
    isLoading?: boolean;
}

export const InvoiceMatchSubmitWarningModal: React.FC<IProps> = ({ open, onClose, title, text, isLoading = false }) => (
    <BaseModal
        open={open}
        onClose={onClose}
        title={title}
        PaperComponent={InvoiceMatchSubmittedWarningModalDialogPaper}
        actions={
            <>
                <CancelButton onClick={onClose}>Ok</CancelButton>
            </>
        }
    >
        <p className="client-modal-description">{text}</p>
    </BaseModal>
);
