import { ModelBase } from "@shoothill/core";
import { action, observable } from "mobx";
import { DesignDataFormItemModel, DesignDataItemDTO } from "./DesignDataFormItemModel";
import { DesignDataFormFileModel, DesignDataFileDTO } from "./DesignDataFormFileModel";

export class DesignDataFormModel extends ModelBase<DesignDataFormModel> {
    public static readonly DEFAULT_ID = "";
    public static readonly DEFAULT_PROJECT_ID = "";
    public static readonly DEFAULT_SUPPLIER_REFERENCE = "";
    public static readonly DEFAULT_CLIENT_REFERENCE = null;
    public static readonly DEFAULT_SUPPLIER_ID = "";
    public static readonly DEFAULT_DESIGNDATA_STATUS_ID = "";
    public static readonly DEFAULT_IS_DARWIN_GROUP = false;
    public static readonly DEFAULT_PARENT_DESIGNDATA_ID = null;
    public static readonly DEFAULT_REQUESTED_BY_USER_ID = null;
    public static readonly DEFAULT_REQUESTED_DATE = null;
    public static readonly DEFAULT_DISCIPLINE = "";
    public static readonly DEFAULT_REQUESTEDTO = "";
    public static readonly DEFAULT_RESPONSE_REQUIRED_BY_DATE = null;
    public static readonly DEFAULT_DESIGNDATA_PRIORITY_ID = "";
    public static readonly DEFAULT_LAST_UPDATED_DATE = null;
    public static readonly DEFAULT_CREATED_DATE = null;
    public static readonly DEFAULT_CREATED_BY_USER_ID = null;
    public static readonly DEFAULT_LAST_UPDATED_BY_USER_ID = null;
    public static readonly DEFAULT_IS_DELETED = false;
    public static readonly DEFAULT_ROW_VERSION = null;
    public static readonly DEFAULT_DESIGNDATA_ITEMS = [];
    public static readonly DEFAULT_DESIGNDATA_FILES = [];
    public static readonly DEFAULT_DESIGNDATA_TEMP_ITEM = null;
    public static readonly DEFAULT_IS_REVISION = false;
    public static readonly DEFAULT_REVISION = null;

    @observable
    public id: string | null = DesignDataFormModel.DEFAULT_ID;
    @observable
    public projectId: string = DesignDataFormModel.DEFAULT_PROJECT_ID;
    @observable
    public supplierReference: string | null = DesignDataFormModel.DEFAULT_SUPPLIER_REFERENCE;
    @observable
    public clientReference: number | null = DesignDataFormModel.DEFAULT_CLIENT_REFERENCE;
    @observable
    public supplierId: string | null = DesignDataFormModel.DEFAULT_SUPPLIER_ID;
    @observable
    public designDataStatusId: string | null = DesignDataFormModel.DEFAULT_DESIGNDATA_STATUS_ID;
    @observable
    public isDarwinGroup: boolean = DesignDataFormModel.DEFAULT_IS_DARWIN_GROUP;
    @observable
    public parentDesignDataId: string | null = DesignDataFormModel.DEFAULT_PARENT_DESIGNDATA_ID;
    @observable
    public requestedByUserId: string | null = DesignDataFormModel.DEFAULT_REQUESTED_BY_USER_ID;
    @observable
    public requestedDate: string | null = DesignDataFormModel.DEFAULT_REQUESTED_DATE;
    @observable
    public discipline: string | null = DesignDataFormModel.DEFAULT_DISCIPLINE;
    @observable
    public requestedTo: string | null = DesignDataFormModel.DEFAULT_REQUESTEDTO;
    @observable
    public responseRequiredByDate: string | null = DesignDataFormModel.DEFAULT_RESPONSE_REQUIRED_BY_DATE;
    @observable
    public designDataPriorityId: string | null = DesignDataFormModel.DEFAULT_DESIGNDATA_PRIORITY_ID;
    @observable
    public createdDate: string | null = DesignDataFormModel.DEFAULT_CREATED_DATE;
    @observable
    public createdByUserId: string | null = DesignDataFormModel.DEFAULT_CREATED_BY_USER_ID;
    @observable
    public lastUpdatedDate: string | null = DesignDataFormModel.DEFAULT_LAST_UPDATED_DATE;
    @observable
    public lastUpdatedByUserId: string | null = DesignDataFormModel.DEFAULT_LAST_UPDATED_BY_USER_ID;
    @observable
    public isDeleted: boolean = DesignDataFormModel.DEFAULT_IS_DELETED;
    @observable
    public rowVersion: string | null = DesignDataFormModel.DEFAULT_ROW_VERSION;
    @observable
    public designDataItems: DesignDataFormItemModel[] = DesignDataFormModel.DEFAULT_DESIGNDATA_ITEMS;
    @observable
    public designDataFiles: DesignDataFormFileModel[] = DesignDataFormModel.DEFAULT_DESIGNDATA_FILES;
    @observable
    public designDataTempItem: DesignDataFormItemModel | null = DesignDataFormModel.DEFAULT_DESIGNDATA_TEMP_ITEM;
    @observable
    public isRevision: boolean = DesignDataFormModel.DEFAULT_IS_REVISION;
    @observable
    public revision: number | null = DesignDataFormModel.DEFAULT_REVISION;

    @action
    public reset = () => {
        this.id = DesignDataFormModel.DEFAULT_ID;
        this.projectId = DesignDataFormModel.DEFAULT_PROJECT_ID;
        this.supplierReference = DesignDataFormModel.DEFAULT_SUPPLIER_REFERENCE;
        this.clientReference = DesignDataFormModel.DEFAULT_CLIENT_REFERENCE;
        this.supplierId = DesignDataFormModel.DEFAULT_SUPPLIER_ID;
        this.designDataStatusId = DesignDataFormModel.DEFAULT_DESIGNDATA_STATUS_ID;
        this.isDarwinGroup = DesignDataFormModel.DEFAULT_IS_DARWIN_GROUP;
        this.parentDesignDataId = DesignDataFormModel.DEFAULT_PARENT_DESIGNDATA_ID;
        this.requestedByUserId = DesignDataFormModel.DEFAULT_REQUESTED_BY_USER_ID;
        this.requestedDate = DesignDataFormModel.DEFAULT_REQUESTED_DATE;
        this.discipline = DesignDataFormModel.DEFAULT_DISCIPLINE;
        this.requestedTo = DesignDataFormModel.DEFAULT_REQUESTEDTO;
        this.responseRequiredByDate = DesignDataFormModel.DEFAULT_RESPONSE_REQUIRED_BY_DATE;
        this.designDataPriorityId = DesignDataFormModel.DEFAULT_DESIGNDATA_PRIORITY_ID;
        this.createdDate = DesignDataFormModel.DEFAULT_CREATED_DATE;
        this.createdByUserId = DesignDataFormModel.DEFAULT_CREATED_BY_USER_ID;
        this.lastUpdatedDate = DesignDataFormModel.DEFAULT_LAST_UPDATED_DATE;
        this.lastUpdatedByUserId = DesignDataFormModel.DEFAULT_LAST_UPDATED_BY_USER_ID;
        this.isDeleted = DesignDataFormModel.DEFAULT_IS_DELETED;
        this.rowVersion = DesignDataFormModel.DEFAULT_ROW_VERSION;
        this.designDataItems.length = 0;
        this.designDataFiles.length = 0;
        this.designDataTempItem = DesignDataFormModel.DEFAULT_DESIGNDATA_TEMP_ITEM;
        this.isRevision = DesignDataFormModel.DEFAULT_IS_REVISION;
        this.revision = DesignDataFormModel.DEFAULT_REVISION;
    };

    // Images/Attachments
    @observable
    public designDataAttachments: DesignDataAttachment[] = [];

    @action
    public fromDto(dto: DesignDataAndRelatedResponseDTO): void {
        const designDataDto = dto.designData;

        for (let key in designDataDto) {
            if (designDataDto.hasOwnProperty(key)) {
                if (key === "designDataItems") {
                    // do nothing
                } else if (this[key] instanceof Date) {
                    this[key] = new Date(designDataDto[key]);
                } else {
                    this[key] = designDataDto[key];
                }
            }
        }

        // Manually process the child array otherwise we will end up with an array of dtos being stored in the array of models.
        let processedItems: DesignDataFormItemModel[] = [];

        for (const item of dto.designDataItems) {
            const itemToAdd = new DesignDataFormItemModel();
            itemToAdd.fromDto(item);
            processedItems.push(itemToAdd);
        }

        this.designDataItems = [...this.designDataItems, ...processedItems];

        let processedFiles: DesignDataFormFileModel[] = [];

        for (const item of dto.designDataFiles) {
            const itemToAdd = new DesignDataFormFileModel();
            itemToAdd.fromDto(item);
            processedFiles.push(itemToAdd);
        }

        this.designDataFiles = [...this.designDataFiles, ...processedFiles];
    }

    public toDto() {
        const DesignDataFormModel: DesignDataDTO = {
            id: this.id,
            projectId: this.projectId,
            supplierReference: this.supplierReference,
            clientReference: this.clientReference,
            supplierId: this.supplierId,
            designDataStatusId: this.designDataStatusId,
            isDarwinGroup: this.isDarwinGroup,
            parentDesignDataId: this.parentDesignDataId,
            requestedByUserId: this.requestedByUserId,
            requestedDate: this.requestedDate,
            discipline: this.discipline,
            requestedTo: this.requestedTo,
            responseRequiredByDate: this.responseRequiredByDate,
            designDataPriorityId: this.designDataPriorityId,
            createdDate: this.createdDate,
            createdByUserId: this.createdByUserId,
            lastUpdatedDate: this.lastUpdatedDate,
            lastUpdatedByUserId: this.lastUpdatedByUserId,
            isDeleted: this.isDeleted,
            rowVersion: this.rowVersion,
            isRevision: this.isRevision,
            revision: this.revision,
        };

        return DesignDataFormModel;
    }

    @action
    public fromRelatedDto(dto: DesignDataRelatedResultResponseDTO): void {}

    // @computed
    // public get validateDilapidationReportTypeId(): string {
    //     return this.dilapidationReportTypeId === DilapidationFormModel.DEFAULT_DILAPIDATION_REPORT_TYPE_ID ? "Please provide a report type" : "";
    // }

    // @computed
    // public get validateAuthorisationSignatureURL(): string {
    //     return this.authorisationSignatureURL === DilapidationFormModel.DEFAULT_AUTHORISATION_SIGNATURE_URL ? "Please provide a signature" : "";
    // }
}

export interface DesignDataStatusDTO {
    id: string;
    displayName: string;
}

export interface DesignDataSupplierDTO {
    id: string;
    displayName: string;
}

export interface DesignDataRequestedByDTO {
    id: string;
    displayName: string;
}

export interface DesignDataPriorityDTO {
    id: string;
    displayName: string;
}

export interface DesignDataDarwinSupplierReferenceDTO {
    id: string;
    displayName: string;
}

export interface DesignDataDTO {
    id: string | null;
    projectId: string;
    supplierReference: string | null;
    clientReference: number | null;
    supplierId: string | null;
    designDataStatusId: string | null;
    isDarwinGroup: boolean;
    parentDesignDataId: string | null;
    requestedByUserId: string | null;
    requestedDate: string | null;
    discipline: string | null;
    requestedTo: string | null;
    responseRequiredByDate: string | null;
    designDataPriorityId: string | null;
    createdDate: string | null;
    createdByUserId: string | null;
    lastUpdatedDate: string | null;
    lastUpdatedByUserId: string | null;
    isDeleted: boolean;
    rowVersion: string | null;
    isRevision: boolean;
    revision: number | null;
}

export interface UpsertDesignDataAndRelatedRequestDTO {
    designData: DesignDataDTO;
    designDataItems: DesignDataItemDTO[];
    designDataFiles: DesignDataFileDTO[];
}

export interface DesignDataAndRelatedResponseDTO extends DesignDataRelatedResultResponseDTO {
    designData: DesignDataDTO;
    designDataItems: DesignDataItemDTO[];
    designDataFiles: DesignDataFileDTO[];
}

export interface DesignDataProjectDetailsDTO {
    projectReference: string;
    projectName: string;
}

export interface DesignDataRelatedResultResponseDTO {
    suppliers: DesignDataSupplierDTO[];
    statuses: DesignDataStatusDTO[];
    requestedByUsers: DesignDataRequestedByDTO[];
    priorities: DesignDataPriorityDTO[];
    designDataProjectDetails: DesignDataProjectDetailsDTO;
    darwinSupplierReferences: DesignDataDarwinSupplierReferenceDTO[];
}

export interface DesignDataAttachment {
    designDataId: string | null;
    fileName: string;
    fileURL: string;
    createdByUserId: string | null;
    isDeleted: boolean;
}
