// Libraries
import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { isNullOrUndefined, useRouter } from "@shoothill/core";

// Custom
import { DetailsHeader } from "Globals/Views/DetailsPage/DetailsHeader";
import { ProjectGeneralViewModel } from "./ProjectGeneralViewModel";
import { AddressModel, NoteModel, ProjectCellHelpers } from "Globals/Models/Domain";
import { CustomHr } from "../CustomComponents";
// Styling & Images
import { DetailsPage } from "Globals/Views/DetailsPage/DetailsPage.styles";
import { AddressItem, OverviewContainer, StyledListBase, ProjectEditBox } from "../Project.styles";
import { Box, Grid, Typography } from "@material-ui/core";
import NoteViewModel from "Globals/ViewModels/NoteViewModel";
import { DetailsGroup } from "../DetailsGroup";
import { ContractCell } from "../Contractual/Contractual.styles";
import { NoteComponentView } from "Views/Notes/NoteComponentView";
import { NoteModelDTO } from "Globals/Models/Domain";
import { NotesListContainer } from "Views/Notes/NotesListContainer";
export const GeneralView: React.FunctionComponent = () => {
    const router = useRouter();
    const [viewModel] = useState(() => ProjectGeneralViewModel.Instance);
    const [notes, setNotes] = useState<NoteViewModel[]>([]);
    const [isNewProject, setIsNewProject] = useState(true);
    const { history } = useRouter();
    useEffect(() => {
        loadProjectNotes();
        return () => {};
    }, []);

    const loadProjectNotes = (): any => {
        let { projectid } = router.match.params as any;
        setNotes(viewModel.getNoteViewModels);
        if (isNullOrUndefined(projectid) === false) {
            let promise = viewModel.apiGetbyId(projectid);
            promise.then(() => {
                setNotes(viewModel.getNoteViewModels);
            });

            setIsNewProject(false);
        } else {
            viewModel.apiGetRelated();
            viewModel.setValue("projectId", projectid);
        }
    };

    const onAddNote = async (note: NoteModelDTO): Promise<any> => {
        note.projectId = viewModel.projectId;
        note.projectReference = "documents";
        await viewModel.generalAddNote(note);
        setNotes(viewModel.getNoteViewModels);
    };

    const noteRowClicked = (val: NoteModel): any => {
        viewModel.setNoteModel(val);
    };

    const noteDelete = (val: NoteModel): any => {
        viewModel.deleteNote(val);
        setNotes(viewModel.getNoteViewModels);
    };

    const renderAddresses = () => {
        return (
            <>
                <h2 style={{ marginBottom: "10px" }}>Invoice addresses</h2>
                <StyledListBase>
                    {viewModel.returnAddresses.map((address: AddressModel, index: number) => (
                        <AddressItem key={"id_" + address.id}>
                            {/* {address.isPrimary && <>MAIN </>} */}
                            {address.addressName}, {address.addressLine1}, {address.addressLine2}, {address.addressLine3}, {address.city}, {address.county}, {address.postcode}
                            {address.isPrimary && (
                                <div style={{ position: "absolute", right: "10px", top: "50%", transform: "translateY(-50%)" }}>
                                    <Typography style={{ fontSize: "12px", fontWeight: "bold", color: "#191919" }}>Main Address</Typography>
                                </div>
                            )}
                        </AddressItem>
                    ))}
                    <CustomHr padding={"10px 0"} />
                </StyledListBase>
            </>
        );
    };

    const renderNotesForm = () => {
        return (
            <>
                <div style={{ width: "100%" }}>
                    <div style={{ width: "100%" }}>
                        <Typography variant="h2">Project notes</Typography>
                        <Typography variant="h6" style={{ marginTop: 10 }}>
                            Project notes
                        </Typography>
                        <NoteComponentView
                            onUpdateModel={onAddNote}
                            model={viewModel.noteModel}
                            isLoading={viewModel.IsLoading}
                            showAddButton={true}
                            buttonLabel="Add note"
                            showUploadFacility={true}
                        />
                    </div>
                </div>
                <NotesListContainer notesViewModel={notes} noteRowClicked={noteRowClicked} noteDeleteText={"Delete"} noteDelete={noteDelete} showUploaded={true} />
            </>
        );
    };

    interface IGeneralDetailsGroupProps {
        detailsType: string;
        detailsData: string | number;
    }

    const GeneralDetailsGroup = (props: IGeneralDetailsGroupProps) => {
        return (
            <Box display="flex" padding="0px 30px">
                <Typography variant="h5" style={{ fontWeight: "bold", marginRight: "10px" }}>
                    {props.detailsType}
                </Typography>
                <Typography style={{ fontSize: "12px" }}>{props.detailsData}</Typography>
            </Box>
        );
    };

    const cells: any = ProjectCellHelpers.getAllSelectOptions(true);
    let cellString: string = "";

    const cell: any | undefined = cells.find((a: any) => a.value === viewModel.model.cell.toString());
    if (cell !== undefined) {
        cellString = cell.label;
    }

    return useObserver(() => {
        return (
            <DetailsPage className="cell-right rcselect">
                <DetailsHeader viewModel={viewModel.getHeader} />
                <ProjectEditBox className="generalBox">
                    <div className="row" style={{ marginBottom: 0 }}>
                        {/* <ContractCell>
                            <DetailsGroup detailsType={"Project Reference:"} detailsData={viewModel.model.reference}></DetailsGroup>
                        </ContractCell> */}
                        <ContractCell className="generalLabel">
                            <DetailsGroup detailsType={"Project Type:"} detailsData={viewModel.projectTypeDisplayName}></DetailsGroup>
                        </ContractCell>
                        <ContractCell className="generalLabel generalLabel--price">
                            {/* <h6>£</h6> */}
                            <DetailsGroup detailsType={"Contract (or LOI) value:"} detailsData={viewModel.model.contractLoiValue}></DetailsGroup>
                        </ContractCell>
                        <ContractCell className="generalLabel">
                            <DetailsGroup detailsType={"Project Cell:"} detailsData={cellString}></DetailsGroup>
                        </ContractCell>
                        <ContractCell className="generalstatus">
                            <DetailsGroup detailsData={viewModel.projectStatusTypeDisplayName} detailsType={""}></DetailsGroup>
                        </ContractCell>
                    </div>
                </ProjectEditBox>
                <ProjectEditBox>
                    <div className="row">
                        {/* <div className="double">
                            <ContractCell>
                                <DetailsGroup detailsType={"Project name:"} detailsData={viewModel.model.name}></DetailsGroup>
                            </ContractCell>
                        </div> */}
                        {/* <div className="double">
                            <ContractCell>
                                <DetailsGroup detailsType={"Contract (or LOI) value:"} detailsData={viewModel.model.contractLoiValue}></DetailsGroup>
                            </ContractCell>
                        </div> */}
                        <div className="double w-full">
                            <ContractCell>
                                <DetailsGroup detailsType={"Project description:"} detailsData={viewModel.model.description}></DetailsGroup>
                            </ContractCell>
                        </div>
                    </div>
                </ProjectEditBox>
                <Grid item xs={12}>
                    <CustomHr margin={"10px 0"} padding={"0px 30px"} />
                </Grid>
                <ProjectEditBox>
                    <div className="row">
                        {/* <div className="description">
                            <ContractCell>
                                <DetailsGroup detailsType={"Project description:"} detailsData={viewModel.model.description}></DetailsGroup>
                            </ContractCell>
                        </div> */}
                    </div>
                </ProjectEditBox>

                {/*                <Grid container spacing={1} style={{ width: "100%" }}>
                    <Grid item xs={12}>
                        <Box display="flex">
                            <GeneralDetailsGroup detailsType={"Project Name:"} detailsData={viewModel.getValue("name")} />
                            <GeneralDetailsGroup detailsType={"Project type:"} detailsData={viewModel.getValue("projectType")} />
                            <GeneralDetailsGroup detailsType={"Project reference:"} detailsData={viewModel.getValue("reference")} />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex">
                            <GeneralDetailsGroup detailsType={"Contract (or LOI) value:"} detailsData={viewModel.getValue("contractLoiValue")} />
                            <GeneralDetailsGroup detailsType={"Project status:"} detailsData={viewModel.getValue("status")} />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex">
                            <GeneralDetailsGroup detailsType={"Project description:"} detailsData={viewModel.getValue("description")} />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <CustomHr margin={"10px 0"} padding={"0px 30px"} />
                    </Grid>
                </Grid> */}

                <OverviewContainer>
                    {renderAddresses()}
                    {renderNotesForm()}
                </OverviewContainer>
            </DetailsPage>
        );
    });
};
