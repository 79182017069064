import { ApiResult, FieldType, ViewModelBase, isEmptyOrWhitespace, observable } from "@shoothill/core";
import { runInAction, action, computed } from "mobx";
import { DilapidationFormItemModel } from "./DilapidationFormItemModel";
import { DilapidationPhotosFile } from "./DilapidationFormModel";
import { DilapidationFormItemPhotoListItemDTO, DilapidationFormItemPhotoListItemModel } from "./DilapidationFormItemPhotoListItemModel";
import { AppUrls } from "AppUrls";

export class DilapidationFormItemViewModel extends ViewModelBase<DilapidationFormItemModel> {
    constructor(item: DilapidationFormItemModel | null) {
        super(item ? item : new DilapidationFormItemModel());
        this.setDecorators(DilapidationFormItemModel);
    }

    // @action
    // public setDilapidationReportItemPhotosFile = (file: DilapidationPhotosFile) => {
    //     this.model.dilapidationReportItemPhotos.push(file);
    // };

    // @action
    // public deleteDilapidationReportItemPhotosFile = async (index: number): Promise<void> => {
    //     this.model.dilapidationReportItemPhotos[index].isDeleted = true;
    // };

    @action
    public reset = () => {
        this.model.reset();
    };

    public fileUpload = async (data: any): Promise<ApiResult<any>> => {
        const formData = new FormData();
        formData.append("formFile", data.formFile);
        formData.append("fileName", data.fileName);
        const apiResult = await this.Post<any>(AppUrls.Server.File.UploadFile, formData);
        if (apiResult) {
            if (!apiResult.wasSuccessful) {
                console.log(apiResult.errors);
                // runInAction(() => {
                //     this.setSnackMessage("Error uploading file please try again.");
                //     this.setSnackType(this.SNACKERROR);
                //     this.setSnackbarState(true);
                // });
            }
        }
        return apiResult;
    };

    public fileChange = async (event: any): Promise<void> => {
        if (event.target.files.length > 0) {
            let data: any = {
                fileName: event.target.files[0].name,
                formFile: event.target.files[0],
            };
            event.target.value = null;
            const apiResult = await this.fileUpload(data);
            if (apiResult && apiResult.wasSuccessful) {
                let fileToDisplay: DilapidationFormItemPhotoListItemDTO = {
                    dilapidationReportItemId: null,
                    fileName: data.fileName,
                    photoURL: apiResult.payload,
                    isDeleted: false,
                    createdByUserId: "",
                    createdDate: null,
                    id: null,
                };
                //this.setDilapidationReportItemPhotosFile(fileToDisplay);
                let model: DilapidationFormItemPhotoListItemModel = new DilapidationFormItemPhotoListItemModel();
                model.fromDto(fileToDisplay);
                runInAction(() => this.model.dilapidationReportItemPhotos.push(model));
            }
        }
    };

    public DownloadFile = async (fileUrl: string, fileName: string): Promise<void> => {
        try {
            const apiResult = await this.Post<Blob>(AppUrls.Server.File.DownloadFile, fileUrl, undefined, { responseType: "blob" });
            const response = apiResult as any;
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
        } catch (exception) {
            console.error(exception);
            this.setIsErrored(true);
        }
    };

    private isMyModelValid = async (): Promise<boolean> => {
        let isValid = true;
        if ((await this.isModelValid()) === false) {
            isValid = false;
        }

        return isValid;
    };

    public async isFieldValid(fieldName: keyof FieldType<DilapidationFormItemModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        switch (fieldName) {
            case "inspectedItem": {
                const result = this.validateInspectedItem;

                errorMessage = result.errorMessage;
                isValid = result.isValid;
                break;
            }
            case "comments": {
                const result = this.validateComments;

                errorMessage = result.errorMessage;
                isValid = result.isValid;
                break;
            }
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    @computed
    private get validateInspectedItem() {
        const errorMessage = this.model.validateInspectedItem;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateComments() {
        const errorMessage = this.model.validateComments;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
