import { ModelBase } from "@shoothill/core";
import { action, observable } from "mobx";

export class UserLookUpModel extends ModelBase<UserLookUpModel, any> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Defaults and Constants

    public static readonly DEFAULT_ID = "";
    public static readonly DEFAULT_DISPLAYNAME = "";

    // #endregion Defaults and Constants

    // #region Observables

    @observable
    public id: string = UserLookUpModel.DEFAULT_ID;

    @observable
    public displayName: string = UserLookUpModel.DEFAULT_DISPLAYNAME;

    // #endregion Observables

    // #region Actions

    @action
    public reset(): void {
        this.id = UserLookUpModel.DEFAULT_ID;
        this.displayName = UserLookUpModel.DEFAULT_DISPLAYNAME;
    }

    @action
    public fromDto(dto: UserLookUpDTO): void {
        this.id = dto.id;
        this.displayName = dto.displayName;
    }

    public toDto() {
        throw new Error("Method not implemented.");
    }

    // #endregion Actions

    // #region Helpers

    public static fromDtos(dtos: UserLookUpDTO[]): UserLookUpModel[] {
        const types: UserLookUpModel[] = [];

        for (const dto of dtos) {
            const model = new UserLookUpModel();

            model.fromDto(dto);
            types.push(model);
        }

        return types;
    }

    // #endregion Helpers
}

export interface UserLookUpDTO {
    id: string;
    displayName: string;
}
