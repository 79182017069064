import { Typography } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React from "react";

import { InformationBarContainer, ProjectInformationContainer } from "./InformationBar.styles";

interface IProps {
    displayName: string;
    projectReference: string;
    projectName: string;
}

export const InformationBarView: React.FC<IProps> = observer((props) => {
    return (
        <InformationBarContainer>
            <Typography variant={"h1"}>{props.displayName}</Typography>
            <ProjectInformationContainer>
                <div>
                    <Typography component="span" variant={"h5"}>
                        Project reference:{" "}
                    </Typography>
                    <Typography component="span" style={{ fontWeight: "normal" }} variant={"h5"}>
                        {props.projectReference}
                    </Typography>
                </div>
                <div>
                    <Typography component="span" variant={"h5"}>
                        Project name:{" "}
                    </Typography>
                    <Typography component="span" style={{ fontWeight: "normal" }} variant={"h5"}>
                        {props.projectName}
                    </Typography>
                </div>
            </ProjectInformationContainer>
        </InformationBarContainer>
    );
});
