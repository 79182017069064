import { ApiResult, FieldType, ViewModelBase } from "@shoothill/core";
import { action, computed, IObservableArray, observable, runInAction } from "mobx";
import { AppUrls } from "AppUrls";
import { SupplierListModel, SupplierListItemDTO } from "./SupplierListModel";
import { SupplierListFilterViewModel } from "./SupplierListFilterViewModel";

export class SupplierListViewModel extends ViewModelBase<SupplierListModel> {
    private static _instance: SupplierListViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable public errorMessage: string = "";
    @observable public showAEModal: boolean = false;

    public suppliers: IObservableArray<SupplierListModel> = observable<SupplierListModel>([]);

    @computed get getSuppliers(): SupplierListModel[] {
        return this.suppliers.slice();
    }

    @action
    public showAddEditModal(show: boolean) {
        this.showAEModal = show;
    }

    @computed
    public get getShowAddEditModal() {
        return this.showAEModal;
    }

    @action
    public setNewClient = () => {
        this.model.reset();
    };

    @action
    public cleanUp = () => {
        // TODO Any Cleanup Code here.
    };

    @action
    public clear = () => {
        SupplierListFilterViewModel.Instance.model.reset();
        this.loadSupplierForListAsync();
    };

    constructor() {
        super(new SupplierListModel(), false);
        this.setDecorators(SupplierListModel);
    }

    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (await this.isModelValid()) {
            //Do stuff here
            this.errorMessage = "Form is valid";
        } else {
            this.errorMessage = "Form is not valid";
        }
    };

    public handleStatusChange = (val: number[] | null) => {
        SupplierListFilterViewModel.Instance.setValue("statuses", val);
        this.loadSupplierForListAsync();
    };

    public async isFieldValid(fieldName: keyof FieldType<SupplierListModel>): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    @action
    public setSuppliers(suppliers: SupplierListItemDTO[]) {
        this.suppliers.clear();
        suppliers.forEach((item, index) => {
            let domainModel = this.suppliers.find((c) => c.id === item.id);

            if (!domainModel) {
                domainModel = new SupplierListModel();

                domainModel.fromDto(item);
                this.suppliers.push(domainModel);
            }
        });
    }

    @action
    public async loadSupplierForListAsync(): Promise<ApiResult<SupplierListItemDTO[]>> {
        let paramsViewModel = SupplierListFilterViewModel.Instance;
        let params = paramsViewModel.model.toDto();

        let apiResult = await this.Post<SupplierListItemDTO[]>(AppUrls.Server.Supplier.GetAll, params);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                this.setSuppliers(apiResult.payload);
            });
        }
        return apiResult;
    }

    // @mobx.action
    // public GetAllWithFiltersAsync = async (): Promise<ApiResult<PurchaseOrderListAndRelatedDTO>> => {
    //     let paramsViewModel = PurchaseOrderListFilterViewModel.Instance;
    //     let params = paramsViewModel.model.toDto();

    //     //this.setHasLoaded(false);

    //     const apiResult = await this.Post<PurchaseOrderListAndRelatedDTO>(AppUrls.Server.PurchaseOrder.GetAllWithFilters, params);

    //     if (apiResult.wasSuccessful) {
    //         //this.resetUsersArr();
    //         //ContractRatesViewModel.Instance.populateContractRates(apiResult.payload.contractRates);
    //         //DepartmentsViewModel.Instance.populateDepartments(apiResult.payload.departments);
    //         //NILetterViewModel.Instance.populateNILetter(apiResult.payload.niLetters);
    //         this.setPurchaseOrders(apiResult.payload.purchaseOrders);
    //         PurchaseOrderListFilterViewModel.Instance.setCategories(apiResult.payload.packageCategories, true);
    //     }

    //     return apiResult;
    // };

    @action
    public exportAsCSV(): void {
        alert("TODO CSV export");
    }
}
