import { ModelBase } from "@shoothill/core";
import { action, observable } from "mobx";

export class IncomeAndExpenditureModel extends ModelBase<IncomeAndExpenditureModel, IncomeAndExpenditureDTO> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Defaults and Constants

    public static readonly DEFAULT_ID = "";
    public static readonly DEFAULT_DISPLAYNAME = "";
    public static readonly DEFAULT_PROJECTID = "";

    // #endregion Defaults and Constants

    // #region Observables

    @observable
    public id: string = IncomeAndExpenditureModel.DEFAULT_ID;

    @observable
    public displayName: string = IncomeAndExpenditureModel.DEFAULT_DISPLAYNAME;

    @observable
    public projectId: string = IncomeAndExpenditureModel.DEFAULT_PROJECTID;

    // #endregion Observables

    // #region Actions

    @action
    public reset(): void {
        this.id = IncomeAndExpenditureModel.DEFAULT_ID;
        this.displayName = IncomeAndExpenditureModel.DEFAULT_DISPLAYNAME;
        this.projectId = IncomeAndExpenditureModel.DEFAULT_PROJECTID;
    }

    @action
    public fromDto(dto: IncomeAndExpenditureDTO): void {
        this.id = dto.id;
        this.displayName = dto.label;
        this.projectId = dto.parentId;
    }

    public toDto() {
        throw new Error("Method not implemented.");
    }

    // #endregion Actions

    // #region Helpers

    public static fromDtos(dtos: IncomeAndExpenditureDTO[]): IncomeAndExpenditureModel[] {
        const types: IncomeAndExpenditureModel[] = [];

        for (const dto of dtos) {
            const model = new IncomeAndExpenditureModel();

            model.fromDto(dto);
            types.push(model);
        }

        return types;
    }

    // #endregion Helpers
}

export interface IncomeAndExpenditureDTO {
    id: string;
    label: string;
    parentId: string;
}
