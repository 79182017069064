import { ModelBase } from "@shoothill/core";
import { action, observable } from "mobx";

export class VisitorTypeModel extends ModelBase<VisitorTypeModel> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Defaults and Constants

    public static readonly DEFAULT_ID = "";
    public static readonly DEFAULT_DISPLAYNAME = "";

    // #endregion Defaults and Constants

    // #region Observables

    @observable
    public id: string = VisitorTypeModel.DEFAULT_ID;

    @observable
    public displayName: string = VisitorTypeModel.DEFAULT_DISPLAYNAME;

    // #endregion Observables

    // #region Actions

    constructor() {
        super();
    }

    @action
    public reset(): void {
        this.id = VisitorTypeModel.DEFAULT_ID;
        this.displayName = VisitorTypeModel.DEFAULT_DISPLAYNAME;
    }

    fromDto(model: any): void {
        throw new Error("Method not implemented.");
    }
    toDto(model: VisitorTypeModel): void {
        throw new Error("Method not implemented.");
    }
}
