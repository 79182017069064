import styled from "styled-components";

export const AddressWrapper = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;

    > div {
        > div {
            max-width: 287px;
            width: 100%;
            padding: 0px;
            margin: 0px 15px;
            &:first-of-type {
                margin: 0px 15px 0px 0px;
            }

            &:last-of-type {
                button {
                    margin-top: 23px;
                }
            }

            .MuiBox-root {
                width: 100%;
            }
        }
    }

    // background-color: orange
    @media screen and (max-width: 1024px) {
    }

    @media screen and (max-width: 640px) {
        flex-direction: column;
    }
`;

export const AddressRow = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: row;
    width: 100%;
    padding: 8px 0;
    align-items: flex-start;

    // background-color: orange
    @media screen and (max-width: 1024px) {
    }

    @media screen and (max-width: 640px) {
        flex-direction: row;
    }
`;

export const AddressCell = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: row;
    padding: 0 30px;

    // background-color: orange
    @media screen and (max-width: 1024px) {
    }

    @media screen and (max-width: 640px) {
        flex-direction: row;
    }
`;

export const AddressStyledList: any = styled.ul`
    margin: 0px 30px;
    list-style: none;

    li {
        font-size: 12px;
        background-color: #eaf4f9;
        padding: 10px;

        > div:not(.icon) {
            display: inline-flex;
            flex-direction: column;
        }

        > div.content {
            width: 80%;
        }

        > div.icon {
            display: inline-flex;
            flex-direction: row;
            img {
                width: 16px;
                vertical-align: middle;
            }
        }
    }
`;
