import { action, observable, runInAction } from "mobx";
import { FieldType, ViewModelBase } from "@shoothill/core";
import { FormRequestDTO, ProgrammeUpdatesModel, UsersListDTO } from "./ProgrammeUpdatesModel";
import { AppUrls } from "AppUrls";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { CleanlinessReportStatusDTO } from "./CleanlinessReport/CleanlinessReportModel";
import { BuildingControlSiteVisitDTO } from "./BuildingControlSiteVisit/BuildingControlSiteVisitModel";
import { CustomerVisitsSiteInstructionsDTO } from "./CustomerVisitsSiteInstructions/CustomerVisitsSiteInstructionsModel";
import { DailyProgrammeProgressUpdateDTO } from "./DailyProgrammeProgressUpdate/DailyProgrammeProgressUpdateModel";
import { OtherProgrammeNotesDTO } from "./OtherProgrammeNotes/OtherProgrammeNotesModel";
import { WeatherReportDTO } from "./WeatherReport/WeatherReportModel";

export class ProgrammeUpdatesViewModel extends ViewModelBase<ProgrammeUpdatesModel> {
    public projectId: string = "";
    public date: Date = new Date();

    @observable
    public cleanlinessReport: boolean = false;
    @observable
    public canDisplayWeatherReport: boolean = false;
    @observable
    public customerVisitsSiteInstructions: boolean = false;
    @observable
    public dailyProgrammeProgress: boolean = false;
    @observable
    public otherProgrammeNotes: boolean = false;
    @observable
    public potentialProgrammeDelays: boolean = false;
    @observable
    public outstandingDesignInformation: boolean = false;
    @observable
    public materialStorage: boolean = false;
    @observable
    public labourShortage: boolean = false;
    @observable
    public cleanlinessReportFormId: string | null = null;
    @observable
    public potentialProgrammeDelaysId: string | null = null;
    @observable
    public outstandingDesignInformationId: string | null = null;
    @observable
    public materialStorageId: string | null = null;
    @observable
    public labourShortageId: string | null = null;

    constructor(projectId: string, date: Date, model = new ProgrammeUpdatesModel()) {
        super(model);
        this.setDecorators(ProgrammeUpdatesModel);
        this.projectId = projectId;
        this.date = date;
    }

    public server: ServerViewModel = new ServerViewModel();

    @observable
    public cleanlinessReportStatus = observable<CleanlinessReportStatusDTO>([]);
    @observable
    public usersList = observable<UsersListDTO>([]);
    @observable
    public buildingControlSiteVisit: BuildingControlSiteVisitDTO | null = null;
    @observable
    public weatherReport: WeatherReportDTO | null = null;
    @observable
    public customerVisitsSiteInstruction: CustomerVisitsSiteInstructionsDTO | null = null;
    @observable
    public dailyProgrammeProgressUpdate: DailyProgrammeProgressUpdateDTO | null = null;
    @observable
    public otherProgrammeNote: OtherProgrammeNotesDTO | null = null;

    @action
    public handleCleanlinessReportShow = () => {
        this.cleanlinessReport = true;
        this.cleanlinessReportFormId = null;
    };

    @action
    public cleanlinessReportId = (formId: string | null) => {
        this.cleanlinessReportFormId = formId;
        this.cleanlinessReport = true;
    };

    @action
    public potentialProgrammeDelayId = (formId: string | null) => {
        this.potentialProgrammeDelaysId = formId;
        this.potentialProgrammeDelays = true;
    };

    @action
    public outstandingDesignInformationIds = (formId: string | null) => {
        this.outstandingDesignInformationId = formId;
        this.outstandingDesignInformation = true;
    };

    @action
    public materialStorageIds = (formId: string | null) => {
        this.materialStorageId = formId;
        this.materialStorage = true;
    };

    @action
    public labourShortageIds = (formId: string | null) => {
        this.labourShortageId = formId;
        this.labourShortage = true;
    };

    @action
    public handleCleanlinessReportClose = () => {
        this.cleanlinessReport = false;
        this.getProgrammeProgressUpdates();
    };

    @action
    public handleWeatherReportShow = () => {
        this.canDisplayWeatherReport = true;
    };

    @action
    public handleWeatherReportClose = (reloadPage: boolean = false) => {
        this.canDisplayWeatherReport = false;

        if (reloadPage) {
            this.getProgrammeProgressUpdates();
        }
    };

    @action
    public handleCustomerVisitsSiteInstructionsShow = () => {
        this.customerVisitsSiteInstructions = true;
    };

    @action
    public handleCustomerVisitsSiteInstructionsClose = () => {
        this.customerVisitsSiteInstructions = false;
        this.getProgrammeProgressUpdates();
    };

    @action
    public handleDailyProgrammeProgressUpdateShow = () => {
        this.dailyProgrammeProgress = true;
    };

    @action
    public handleDailyProgrammeProgressUpdateClose = () => {
        this.dailyProgrammeProgress = false;
        this.getProgrammeProgressUpdates();
    };

    @action
    public handleOtherProgrammeNotesShow = () => {
        this.otherProgrammeNotes = true;
    };

    @action
    public handleOtherProgrammeNotesClose = () => {
        this.otherProgrammeNotes = false;
        this.getProgrammeProgressUpdates();
    };

    @action
    public handlePotentialProgrammeDelaysShow = () => {
        this.potentialProgrammeDelays = true;
        this.potentialProgrammeDelaysId = null;
    };

    @action
    public handlePotentialProgrammeDelaysClose = () => {
        this.potentialProgrammeDelays = false;
        this.getProgrammeProgressUpdates();
    };

    @action
    public handleOutstandingDesignInformationShow = () => {
        this.outstandingDesignInformation = true;
        this.outstandingDesignInformationId = null;
    };

    @action
    public handleOutstandingDesignInformationClose = () => {
        this.outstandingDesignInformation = false;
        this.getProgrammeProgressUpdates();
    };

    @action
    public handleMaterialStorageShow = () => {
        this.materialStorage = true;
        this.materialStorageId = null;
    };

    @action
    public handleMaterialStorageClose = () => {
        this.materialStorage = false;
        this.getProgrammeProgressUpdates();
    };

    @action
    public handleLabourShortageShow = () => {
        this.labourShortage = true;
        this.labourShortageId = null;
    };

    @action
    public handleLabourShortageClose = () => {
        this.labourShortage = false;
        this.getProgrammeProgressUpdates();
    };

    @action
    public navigateBuildingControlSiteVisit = () => {
        this.history.push(AppUrls.Client.Project.ProgrammeUpdates.BuildingControlSiteVisit.Add.replace(":projectid", this.projectId).replace(":date", this.date.toISOString()));
    };

    @action
    public setBackGroundStatus(id: string | null): string {
        const cleanlinessReportStatus = this.cleanlinessReportStatus.find((i: any) => i.id === id);
        const color = cleanlinessReportStatus?.color;
        return color ? color : "";
    }

    @action
    public getUserName(id: string | null): string {
        const userList = this.usersList.find((i: any) => i.id === id);
        const getUserName = userList?.displayName;
        return getUserName ? getUserName : "";
    }

    // start getProgrammeProgressUpdates

    @action
    public getProgrammeProgressUpdates = async (): Promise<void> => {
        const request: FormRequestDTO = {
            date: this.date.toISOString(),
            projectId: this.projectId,
            formId: null,
        };

        let apiResult = await this.Post<any>(AppUrls.Server.Projects.Construction.ProgrammeUpdates.GetProgrammeUpdatesAndRelated, request);
        if (apiResult) {
            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.model.fromDto(apiResult.payload);

                    // The users list is used for audit information.
                    this.usersList.replace(apiResult.payload.users);

                    this.cleanlinessReportStatus.replace(apiResult.payload.cleanlinessReportList?.cleanlinessReportStatus!);
                    this.buildingControlSiteVisit = apiResult.payload.buildingControlSiteVisitList?.buildingControlSiteVisit!;
                    this.weatherReport = apiResult.payload.weatherReportList?.weatherReport ?? null;
                    this.customerVisitsSiteInstruction = apiResult.payload.customerVisitsSiteInstructionsList?.customerVisitsSiteInstructions!;
                    this.dailyProgrammeProgressUpdate = apiResult.payload.dailyProgrammeProgressUpdateList?.dailyProgrammeProgressUpdate!;
                    this.otherProgrammeNote = apiResult.payload.otherProgrammeNoteList?.otherProgrammeNotes!;
                });
            } else {
                console.log(apiResult.errors);
            }
        }
    };

    // End getProgrammeProgressUpdates

    // start DownloadFile

    public DownloadFile = async (fileUrl: string, fileName: string): Promise<void> => {
        try {
            const apiResult = await this.Post<Blob>(AppUrls.Server.File.DownloadFile, fileUrl, undefined, { responseType: "blob" });
            const response = apiResult as any;
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
        } catch (exception) {
            console.error(exception);
            this.setIsErrored(true);
        }
    };

    // End DownloadFile

    public async isFieldValid(fieldName: keyof FieldType<ProgrammeUpdatesModel>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }
    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
