// Libs
import React, { useContext, useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { generateID } from "@shoothill/core";

// App
import NoteViewModel from "Globals/ViewModels/NoteViewModel";
import { NoteModel, NoteModelDTO } from "Globals/Models/Domain";
import { NotesListItem } from "./NotesListItem";

// Styling
import { NotesStyledList } from "./Notes.styles";

interface INotesLIProps {
    notesViewModel: NoteViewModel[];
    noteRowClicked: (model: NoteModel) => any;
    noteDelete: (model: NoteModel) => any;
    noteDeleteText?: string;
    showUploaded: boolean;
}

export const NotesListContainer: React.FC<INotesLIProps> = (props) => {
    const { notesViewModel, noteRowClicked, noteDelete, noteDeleteText, showUploaded } = props;

    return useObserver(() => (
        <>
            <NotesStyledList style={{ display: "block", width: "100%", margin: 0 }}>
                {notesViewModel.map((note: NoteViewModel, index) => {
                    return <NotesListItem key={"key_" + generateID()} noteViewModel={note} noteRowClicked={noteRowClicked} noteDelete={noteDelete} />;
                })}
            </NotesStyledList>
        </>
    ));
};
