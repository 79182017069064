import * as MobX from "mobx";
import { ModelBase } from "@shoothill/core";

export class RequisitionsModel extends ModelBase<RequisitionsModel, RequisitionsModelDTO> {
    public id: string = "";

    @MobX.observable
    public poNumber: number | null = null;

    @MobX.observable
    public formattedPONumber: string | null = null;

    @MobX.observable
    public itemDescription: string = "";

    @MobX.observable
    public committedCost: number = 0;

    @MobX.observable
    public supplierName: string = "";

    @MobX.observable
    public raisedBy: string = "";

    @MobX.observable
    public status: string = "";

    @MobX.observable
    public createdDate: Date | null = null;

    @MobX.observable
    public projectName: string = "";

    fromDto(model: RequisitionsModelDTO): void {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    toDto(model: RequisitionsModel): void {}

    public toTableModel = (): RequisitionsTableModel => {
        const po: RequisitionsTableModel = {
            id: this.id,
            poNumberFormatted: this.formattedPONumber ? this.formattedPONumber : "",
            itemDescription: this.itemDescription,
            committedCost: this.committedCost,
            supplierName: this.supplierName,
            raisedBy: this.raisedBy,
            status: this.status,
            createdDate: this.createdDate,
            projectName: this.projectName,
        };

        return po;
    };

    public toTableModel2 = (): RequisitionsTableModel => {
        const po: RequisitionsTableModel = {
            id: this.id,
            poNumberFormatted: this.formattedPONumber ? this.formattedPONumber : "",
            itemDescription: this.itemDescription,
            committedCost: this.committedCost,
            supplierName: this.supplierName,
            raisedBy: this.raisedBy,
            status: this.status,
            createdDate: this.createdDate,
            projectName: this.projectName,
        };

        return po;
    };
}

export type RequisitionsTableModel = {
    id: string;
    poNumberFormatted: string;
    itemDescription: string;
    committedCost: number;
    supplierName: string;
    raisedBy: string;
    status: string;
    createdDate: Date | null;
    projectName: string;
};

export type RequisitionsModelDTO = {
    id: string;
    poNumber: number | null;
    formattedPONumber: string | null;
    itemDescription: string;
    committedCost: number;
    supplierName: string;
    raisedBy: string;
    status: string;
    createdDate: Date | null;
    projectName: string;
};
