import { FieldType, KeyValuePair, observable, ViewModelBase } from "@shoothill/core";
import { SupplierFilterParamsModel } from "./SupplierFilterParamsModel";
import { action } from "mobx";
import { SupplierStatus, SupplierStatusHelpers } from "Globals/Models/Domain";

export class SupplierListFilterViewModel extends ViewModelBase<SupplierFilterParamsModel> {
    private static _intsance: SupplierListFilterViewModel;
    public static get Instance() {
        return this._intsance || (this._intsance = new this());
    }

    constructor() {
        super(new SupplierFilterParamsModel(), false);
        this.setDecorators(SupplierFilterParamsModel);

        this.setStatuses(Object.values(SupplierStatus).filter((s) => typeof s === "number") as SupplierStatus[], false);
    }

    @observable
    public readonly statusOptions: KeyValuePair[] = [];

    public getStatusOptions = (includeUnKnown?: boolean): KeyValuePair[] => {
        return this.statusOptions === undefined ? [] : this.statusOptions.slice();
    };

    @action
    public setStatuses(statusOptions: SupplierStatus[], resetFilters: boolean) {
        if (this.statusOptions !== null && this.statusOptions.length === 0) {
            if (statusOptions.length > 0) {
                this.statusOptions.push(
                    ...statusOptions.map((item: SupplierStatus) => {
                        return { key: SupplierStatusHelpers.getText(item), value: item };
                    }),
                );
            }
        }
    }

    public async isFieldValid(fieldName: keyof FieldType<SupplierFilterParamsModel>): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
