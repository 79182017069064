import { IconButton, Typography } from "@material-ui/core";
import { Grid } from "@shoothill/core";
import MaterialTable from "material-table";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

import { AddButton, DownloadButton } from "Components/Buttons/Buttons";
import { SnackBar } from "Components/SnackBar/SnackBar";
import { PatchedPagination } from "Components/Table/PatchedPagination";
import { TableListSearch } from "Components/Table/TableListSearch";
import EditIcon from "Content/EditIcon";
import PriceUpDownIcon from "Content/PriceUpDownIcon";
import TimerBlackIcon from "Content/TimerBlackIcon";
import * as Defaults from "Globals/Defaults/TableOptions";
import { DarwinPage, DarwinPageTitle, DarwinTableButtonContainer, DarwinTablePageContent, DarwinTableSearchBox, DarwinTableToolbar } from "Globals/Styles/AppStyling";
import { formatCurrencyFromPounds, formatDate } from "Utils/Format";
import { MaterialsListViewModel } from "./MaterialsListViewModel";
import { MaterialDetailsView } from "../MaterialDetails/MaterialDetailsView";
import { MaterialPriceDetailsView } from "../MaterialPriceDetails/MaterialPriceDetailsView";
import { MaterialPriceDetailsHistoryView } from "../MaterialPriceDetailsHistory/MaterialPriceDetailsHistoryView";
import { MaterialsListFilterView } from "./MaterialsListFilterView";
import { MaterialsListFilterViewModel } from "./MaterialsListFilterViewModel";

export const MaterialsListView: React.FunctionComponent = observer(() => {
    const [viewModel] = useState(() => new MaterialsListViewModel());
    const tableOptions = Defaults.GetDarwinTableOptions() as any;
    const filtersViewModel = MaterialsListFilterViewModel.Instance;

    return (
        <React.Fragment>
            {/* Display Material Details Modal */}
            {viewModel.canDisplayDetails && <MaterialDetailsView viewModel={viewModel.materialDetailsViewModel!} />}

            {/* Display Material Price Details Modal */}
            {viewModel.canDisplayPriceDetails && <MaterialPriceDetailsView viewModel={viewModel.materialPriceDetailsViewModel!} />}

            {/* Display Material Price Details History Modal */}
            {viewModel.canDisplayPriceDetailsHistory && <MaterialPriceDetailsHistoryView viewModel={viewModel.materialPriceDetailsHistoryViewModel!} />}

            <DarwinPage>
                <DarwinPageTitle>
                    <Typography variant="h1" color="textPrimary">
                        Master materials
                    </Typography>
                </DarwinPageTitle>
                <DarwinTableToolbar>
                    <MaterialsListFilterView materialsListViewModel={viewModel} filtersViewModel={filtersViewModel} />
                    <Grid columnGap={30} dc={"1fr 1fr"} rowGap={15} tc={"1fr 1fr"}>
                        <DarwinTableSearchBox>
                            <TableListSearch searchString={viewModel.getSearchString()} setSearchString={viewModel.setSearchString} />
                        </DarwinTableSearchBox>
                        <DarwinTableButtonContainer>
                            <AddButton color="primary" displayName="Add new material" execute={viewModel.navigateToNewMaterial} />
                            <DownloadButton color="primary" canExecute={!viewModel.server.IsBusy} displayName="Export as CSV" execute={viewModel.apiDownloadMaterialsAsync} />
                        </DarwinTableButtonContainer>
                    </Grid>
                </DarwinTableToolbar>
                <DarwinTablePageContent>
                    <MaterialTable
                        columns={[
                            {
                                title: "Item description",
                                field: "description",
                                width: "auto",
                            },
                            {
                                title: "Type",
                                field: "materialType",
                                width: "auto",
                            },
                            {
                                title: "Sub type",
                                field: "materialSubType",
                                width: "auto",
                            },
                            {
                                title: "Supplier code",
                                field: "supplierCode",
                                width: "auto",
                            },
                            {
                                title: "UoM",
                                field: "unitOfMeasure",
                                width: "auto",
                            },
                            {
                                title: "Estimating price",
                                field: "estimatedPrice",
                                align: "right",
                                width: "auto",
                                render: (rowData: any) => formatCurrencyFromPounds(rowData.estimatedPrice, 2, 4),
                            },
                            {
                                title: "Purchase price",
                                field: "price",
                                align: "right",
                                width: "auto",
                                render: (rowData: any) => formatCurrencyFromPounds(rowData.price, 2, 4),
                            },
                            {
                                title: "Price effective date",
                                field: "effectiveFromDate",
                                width: "auto",
                                render: (rowData: any) => formatDate(rowData.effectiveFromDate),
                            },
                            {
                                title: "Effective supplier",
                                field: "supplier",
                            },
                            {
                                title: "",
                                field: "",
                                cellStyle: { ...tableOptions.cellStyle, width: "120px" },
                                headerStyle: { ...tableOptions.headerStyle, width: "120px" },
                                render: (rowData: any) => (
                                    <div>
                                        <IconButton
                                            style={{ padding: "5px" }}
                                            size="small"
                                            onClick={() => viewModel.displayMaterialDetails(rowData)}
                                            disabled={!viewModel.canEditMaterialDetails}
                                            title="Edit material details"
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton
                                            style={{ padding: "5px" }}
                                            size="small"
                                            onClick={() => viewModel.displayMaterialPriceDetails(rowData)}
                                            disabled={!rowData.canEditPrice}
                                            title="Update material price/supplier"
                                        >
                                            <PriceUpDownIcon />
                                        </IconButton>
                                        <IconButton style={{ padding: "5px" }} size="small" onClick={() => viewModel.displayPriceDetailsHistory(rowData)} title="Material history">
                                            <TimerBlackIcon />
                                        </IconButton>
                                    </div>
                                ),
                                sorting: false,
                            },
                        ]}
                        components={{
                            Pagination: PatchedPagination,
                        }}
                        data={viewModel.filteredMaterials}
                        options={{ ...tableOptions, filtering: false, search: false, toolbar: false }}
                        title=""
                    />
                </DarwinTablePageContent>
                <SnackBar
                    messageText={viewModel.snackMessage}
                    messageType={viewModel.snackType}
                    active={viewModel.snackbarState}
                    closeOption={() => viewModel.setSnackbarState(false)}
                    autoHideDuration={5000}
                />
            </DarwinPage>
        </React.Fragment>
    );
});
