import { ModelBase } from "@shoothill/core";
import { observable, action } from "mobx";

export class ToolboxTalkFileModel extends ModelBase<ToolboxTalkFileModel> {
    @observable
    public id: string | null = null;
    @observable
    public fileName: string = "";
    @observable
    public fileURL: string = "";
    @observable
    public isDeleted: boolean = false;
    @observable
    public createdByUserId: string | null = "";
    @observable
    public createdDate: string | null = "";
    constructor() {
        super();
    }

    @action
    public handleDelete = (val: boolean) => {
        this.isDeleted = val;
    };

    fromDto(model: ToolboxTalkFileDTO): void {
        this.id = model.id;
        this.fileName = model.fileName;
        this.fileURL = model.fileURL;
        this.isDeleted = model.isDeleted;
        this.createdByUserId = model.createdByUserId;
        this.createdDate = model.createdDate;
    }

    public static fromDtos(dtos: ToolboxTalkFileDTO[]): ToolboxTalkFileModel[] {
        const types: ToolboxTalkFileModel[] = [];

        for (const dto of dtos) {
            const model = new ToolboxTalkFileModel();

            model.fromDto(dto);
            types.push(model);
        }

        return types;
    }

    toDto(): ToolboxTalkFileDTO {
        return {
            id: this.id,
            fileName: this.fileName,
            fileURL: this.fileURL,
            isDeleted: this.isDeleted,
            createdByUserId: this.createdByUserId,
            createdDate: this.createdDate,
        };
    }
}

export interface ToolboxTalkFileDTO {
    id: string | null;
    fileName: string;
    fileURL: string;
    isDeleted: boolean;
    createdByUserId: string | null;
    createdDate: string | null;
}
