import { ApiResult, FieldType, ViewModelBase, isEmptyOrWhitespace, observable } from "@shoothill/core";
import {
    DesignDataStatusDTO,
    DesignDataSupplierDTO,
    DesignDataRequestedByDTO,
    DesignDataPriorityDTO,
    UpsertDesignDataAndRelatedRequestDTO,
    DesignDataDTO,
    DesignDataAndRelatedResponseDTO,
    DesignDataFormModel,
    DesignDataRelatedResultResponseDTO,
    DesignDataProjectDetailsDTO,
    DesignDataDarwinSupplierReferenceDTO,
} from "./DesignDataFormModel";
import moment from "moment";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { AppUrls } from "AppUrls";
import { runInAction, action, computed } from "mobx";
import { DesignDataFormItemModel, DesignDataItemDTO } from "./DesignDataFormItemModel";
import { DesignDataFormFileModel, DesignDataFileDTO } from "./DesignDataFormFileModel";
import { DesignDataFormItemViewModel } from "./DesignDataFormItemViewModel";
import { DesignDataFormFileViewModel } from "./DesignDataFormFileViewModel";
import { RDDFilterViewModel } from "../RDDFilterViewModel";

export class DesignDataFormViewModel extends ViewModelBase<DesignDataFormModel> {
    constructor(id: string | null, projectId: string | null, ref: string | null, isrevision: string | null) {
        super(new DesignDataFormModel());
        this.setDecorators(DesignDataFormModel);
        this.model.id = id;
        this.model.projectId = projectId!;
        isEmptyOrWhitespace(this.model.id) ? this.loadRelated(ref!) : this.loadWithRelated();
    }

    public server: ServerViewModel = new ServerViewModel();

    @observable
    public snackMessage = "";

    @observable
    public snackType = "";

    @observable
    public SNACKSUCCESS = "success";

    @observable
    public SNACKERROR = "error";

    @observable
    public snackbarState = false;

    @observable
    public discipline: any = null;

    @observable
    public statuses = observable<DesignDataStatusDTO>([]);

    @observable
    public supplier: { id: string; displayName: string } | null = null;

    @observable
    public status: { id: string; displayName: string } | null = null;

    @observable
    public requestedBy: { id: string; displayName: string } | null = null;

    @observable
    public priority: { id: string; displayName: string } | null = null;

    @observable
    public darwinGroupClientReference: { id: string; displayName: string } | null = null;

    @observable
    public suppliers = observable<DesignDataSupplierDTO>([]);

    @observable
    public requestedByUsers = observable<DesignDataRequestedByDTO>([]);

    @observable
    public priorities = observable<DesignDataPriorityDTO>([]);

    @observable
    public darwinGroupSupplierReferences = observable<DesignDataDarwinSupplierReferenceDTO>([]);

    @observable
    public designDataProjectDetails: DesignDataProjectDetailsDTO | null = null;

    @observable
    public designDataItemViewModels: DesignDataFormItemViewModel[] = [];

    @observable
    public designDataItemTempViewModel: DesignDataFormItemViewModel | null = null;

    @observable
    public designDataFileViewModels: DesignDataFormFileViewModel[] = [];

    @observable
    public designData: DesignDataDTO | null = null;

    @observable
    public hasLoaded: boolean = false;

    @computed
    public get getHasLoaded(): boolean {
        return this.hasLoaded;
    }

    @computed
    public get clientReferenceFormatted() {
        if (this.model.clientReference === null) {
            return "";
        } else {
            if (!this.model.isRevision) {
                return "CL" + this.model.clientReference;
            } else {
                return "CL" + this.model.clientReference + "." + this.model.revision ? this.model.revision?.toString() : 0;
            }
        }
    }

    @action
    public getFormTitle(isdarwinrdd: string, isrevision: string): string {
        if (isrevision === "1") {
            return this.model.getValue("supplierReference") + " revision";
        } else {
            if (isdarwinrdd === "1") {
                return this.hasId ? "Darwin Group RDD" : "New Darwin Group RDD";
            }
            if (isdarwinrdd === "0") {
                return this.hasId ? "Client RDD" : "New Client RDD";
            }
        }
        return "";
    }

    @action
    public setHasLoaded = (val: boolean) => {
        this.hasLoaded = val;
    };

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public isDarwin: boolean = false;

    @action
    public setIsDarwin = (val: boolean) => {
        this.isDarwin = val;
    };

    @action
    public handleSupplierId = (item: { id: string; displayName: string }) => {
        this.supplier = item;
        this.setValue("supplierId", item.id);
    };

    @action
    public handleStatusId = (item: { id: string; displayName: string }) => {
        this.status = item;
        this.setValue("designDataStatusId", item.id);
    };

    @action
    public handleRequestedById = (item: { id: string; displayName: string }) => {
        this.requestedBy = item;
        this.setValue("requestedByUserId", item.id);
    };

    @action
    public handlePriorityId = (item: { id: string; displayName: string }) => {
        this.priority = item;
        this.setValue("designDataPriorityId", item.id);
    };

    @action
    public handleDarwinGroupSupplierId = (item: { id: string; displayName: string }) => {
        this.darwinGroupClientReference = item;
        this.setValue("supplierReference", item.id);
    };

    @observable
    public hasRequestedDateChanged: boolean = false;

    @observable
    public hasResponseRequiredByDateChanged: boolean = false;

    @action
    public setRequestedDate = (value: string | null): void => {
        this.setValue("requestedDate", value);
        this.hasRequestedDateChanged = true;
    };

    @action
    public setResponseRequiredByDate = (value: string | null): void => {
        this.setValue("responseRequiredByDate", value);
        this.hasResponseRequiredByDateChanged = true;
    };

    @action
    public reset = () => {
        this.model.reset();
        this.server.reset();
    };

    @action
    public handleCancel(projectId: string | null): void {
        this.reset();
        this.history.push(AppUrls.Client.Project.DesignData.replace(":projectid", projectId ? projectId : this.model.projectId));
    }

    @action
    public addDesignDataItem = () => {
        let model = new DesignDataFormItemModel();
        console.log(this.designDataItemTempViewModel?.getValue("title"));
        model.title = this.designDataItemTempViewModel?.getValue("title") ? this.designDataItemTempViewModel?.getValue("title") : "";
        model.description = this.designDataItemTempViewModel?.getValue("description") ? this.designDataItemTempViewModel?.getValue("description") : "";
        this.model.designDataItems.push(model);
        let viewModel = new DesignDataFormItemViewModel(model);
        this.designDataItemViewModels.push(viewModel);
        this.addTempDesignDataItem();
    };

    @action
    public addTempDesignDataItem = () => {
        this.designDataItemTempViewModel = null;
        let model = new DesignDataFormItemModel();
        this.designDataItemTempViewModel = new DesignDataFormItemViewModel(model);
    };

    @action
    public deleteDesignDataItem(indexToDelete: number) {
        this.designDataItemViewModels.forEach((designDataItem, index) => {
            if (index === indexToDelete) {
                if (this.designDataItemViewModels[index].hasId) {
                    this.designDataItemViewModels[index].model.isDeleted = true;
                } else {
                    this.designDataItemViewModels.splice(index, 1);
                }
            }
        });
    }

    /**
     * Handle a file being selected and process the data for upload.
     * @param event
     */
    @action
    public fileChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        if (event.target.files !== null && event.target.value !== null && event.target.files.length > 0) {
            let data: any = {
                fileName: event.target.files[0].name,
                formFile: event.target.files[0],
            };
            event.target.value = "";
            const apiResult = await this.fileUpload(data);
            if (apiResult && apiResult.wasSuccessful) {
                runInAction(() => {
                    let model = new DesignDataFormFileModel();
                    model.designDataId = "";
                    model.fileName = data.fileName;
                    model.fileURL = apiResult.payload;
                    model.isDeleted = false;
                    model.createdByUserId = null;
                    this.model.designDataFiles.push(model);
                    let viewModel = new DesignDataFormFileViewModel(model);
                    this.designDataFileViewModels.push(viewModel);
                });
            }
        }
    };
    /**
     * Upload a file to azure.
     * @param data The data of the file to be uploaded.
     * @returns apiResult.
     */
    public fileUpload = async (data: any): Promise<ApiResult<any>> => {
        const formData = new FormData();
        formData.append("formFile", data.formFile);
        formData.append("fileName", data.fileName);
        const apiResult = await this.Post<any>(AppUrls.Server.File.UploadFile, formData);
        if (apiResult) {
            if (!apiResult.wasSuccessful) {
                console.log(apiResult.errors);
                runInAction(() => {
                    this.setSnackMessage("Error uploading file please try again.");
                    this.setSnackType(this.SNACKERROR);
                    this.setSnackbarState(true);
                });
            }
        }
        return apiResult;
    };
    /**
     * Download a file that exists in azure.
     * @param fileUrl The URL of the file to be downloaded.
     * @param fileName The name of the file to be downloaded.
     */
    public DownloadFile = async (fileUrl: string, fileName: string): Promise<void> => {
        try {
            const apiResult = await this.Post<Blob>(AppUrls.Server.File.DownloadFile, fileUrl, undefined, { responseType: "blob" });
            const response = apiResult as any;
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
        } catch (exception) {
            console.error(exception);
            this.setIsErrored(true);
        }
    };

    @action
    public deleteDesignDataFile = async (index: number): Promise<void> => {
        this.designDataFileViewModels[index].model.isDeleted = true;
    };

    @computed
    public get isFormDisabled(): boolean {
        return this.model.id !== null && this.model.id !== undefined && this.model.id !== "";
    }

    @computed
    public get hasId(): boolean {
        return this.model.id !== null && this.model.id !== undefined && this.model.id !== "";
    }

    @computed
    public get getTodayDateFormatted(): string {
        return this.model.createdDate ? moment(this.model.createdDate).format("DD/MM/YYYY @ HH:mm").toString() : moment().format("DD/MM/YYYY @ HH:mm").toString();
    }

    @action
    private createViewModels() {
        for (const item of this.model.designDataItems) {
            this.designDataItemViewModels.push(new DesignDataFormItemViewModel(item));
        }
        for (const fileItem of this.model.designDataFiles) {
            this.designDataFileViewModels.push(new DesignDataFormFileViewModel(fileItem));
        }
    }

    @computed
    public get getProjectReferenceFormatted(): string {
        if (!this.designDataProjectDetails) {
            return "";
        }

        return this.designDataProjectDetails.projectReference;
    }

    @computed
    public get getProjectName(): string {
        if (!this.designDataProjectDetails) {
            return "";
        }

        return this.designDataProjectDetails.projectName;
    }

    public loadRelated = async (reference: string | null): Promise<void> => {
        this.setIsLoading(true);
        return await this.server
            .query<DesignDataRelatedResultResponseDTO>(
                () => this.Get(`${AppUrls.Server.Projects.ProjectTrackers.DesignData.GetDesignDataRelated}\\${this.model.projectId}`),
                (result) => {
                    runInAction(() => {
                        this.suppliers.replace(result.suppliers);
                        this.statuses.replace(result.statuses);
                        this.requestedByUsers.replace(result.requestedByUsers);
                        this.priorities.replace(result.priorities);
                        this.designDataProjectDetails = result.designDataProjectDetails;
                        this.darwinGroupSupplierReferences.replace(result.darwinSupplierReferences);
                        this.addTempDesignDataItem();

                        if (reference) {
                            const selectedDarwinGroupSupplierReference: DesignDataDarwinSupplierReferenceDTO | undefined = this.darwinGroupSupplierReferences.find(
                                (t) => t.displayName === reference,
                            );
                            if (selectedDarwinGroupSupplierReference) {
                                this.handleDarwinGroupSupplierId(selectedDarwinGroupSupplierReference);
                            }
                        }
                    });
                },
            )
            .finally(() => {
                this.setIsLoading(false);
                this.setHasLoaded(true);
            });
    };

    public loadWithRelated = async (): Promise<void> => {
        this.setIsLoading(true);
        return await this.server
            .query<DesignDataAndRelatedResponseDTO>(
                () => this.Get(`${AppUrls.Server.Projects.ProjectTrackers.DesignData.GetDesignDataAndRelated}\\${this.model.id}`),
                (result) => {
                    runInAction(() => {
                        this.model.fromDto(result);
                        this.suppliers.replace(result.suppliers);
                        this.statuses.replace(result.statuses);
                        this.requestedByUsers.replace(result.requestedByUsers);
                        this.priorities.replace(result.priorities);
                        this.designDataProjectDetails = result.designDataProjectDetails;
                        this.darwinGroupSupplierReferences.replace(result.darwinSupplierReferences);
                        this.addTempDesignDataItem();
                        this.createViewModels();
                        const selectedSupplier: DesignDataSupplierDTO | undefined = this.suppliers.find((t) => t.id === this.model.supplierId);
                        const selectedStatus: DesignDataStatusDTO | undefined = this.statuses.find((t) => t.id === this.model.designDataStatusId);
                        const selectedRequestedByUser: DesignDataRequestedByDTO | undefined = this.requestedByUsers.find((t) => t.id === this.model.requestedByUserId);
                        const selectedPriority: DesignDataPriorityDTO | undefined = this.priorities.find((t) => t.id === this.model.designDataPriorityId);
                        const selectedDarwinGroupSupplierReference: DesignDataDarwinSupplierReferenceDTO | undefined = this.darwinGroupSupplierReferences.find(
                            (t) => t.id === this.model.supplierReference,
                        );

                        if (selectedSupplier) {
                            this.handleSupplierId(selectedSupplier);
                        }
                        if (selectedStatus) {
                            this.handleStatusId(selectedStatus);
                        }
                        if (selectedRequestedByUser) {
                            this.handleRequestedById(selectedRequestedByUser);
                        }
                        if (selectedPriority) {
                            this.handlePriorityId(selectedPriority);
                        }
                        if (selectedDarwinGroupSupplierReference) {
                            this.handleDarwinGroupSupplierId(selectedDarwinGroupSupplierReference);
                        }
                    });
                },
            )
            .finally(() => {
                this.setIsLoading(false);
                this.setHasLoaded(true);
            });
    };

    public upsert = async (isdarwinrdd: any, isrevision: any): Promise<void> => {
        this.setIsLoading(true);
        const model: DesignDataDTO = this.model.toDto();
        console.log("isrevision: " + isrevision);
        if (isdarwinrdd === "1") {
            model.isDarwinGroup = true;
        }
        if (isdarwinrdd === "0") {
            model.supplierReference = this.darwinGroupClientReference?.displayName ? this.darwinGroupClientReference?.displayName : null;
        }
        if (isrevision === "1") {
            model.isRevision = true;
        }

        let itemsToAdd: DesignDataItemDTO[] = [];
        let filesToAdd: DesignDataFileDTO[] = [];

        this.designDataItemViewModels.forEach((item) => {
            const itemToAdd: DesignDataItemDTO = item.model.toDto();
            itemsToAdd.push(itemToAdd);
        });

        this.designDataFileViewModels.forEach((item) => {
            const fileToAdd: DesignDataFileDTO = item.model.toDto();
            filesToAdd.push(fileToAdd);
        });

        const request: UpsertDesignDataAndRelatedRequestDTO = {
            designData: model,
            designDataItems: itemsToAdd,
            designDataFiles: filesToAdd,
        };
        return await this.server
            .command<DesignDataAndRelatedResponseDTO>(
                () => this.Post(AppUrls.Server.Projects.ProjectTrackers.DesignData.Upsert, request),
                (result: DesignDataAndRelatedResponseDTO) => {
                    runInAction(() => {
                        if (result) {
                            this.handleCancel(result.designData.projectId);
                        }
                    });
                },
                this.isMyModelValid,
                "There was an error trying to send the design data",
            )
            .finally(() => this.setIsLoading(false));
    };

    private isMyModelValid = async (): Promise<boolean> => {
        let isValid = true;

        for (let i = 0; i < this.designDataItemViewModels.length; i++) {
            let item = this.designDataItemViewModels[i];

            // Validate each child.
            if ((await item.isModelValid()) === false) {
                isValid = false;
            }
        }

        if ((await this.isModelValid()) === false) {
            isValid = false;
        }

        return isValid;
    };

    public async isFieldValid(fieldName: keyof FieldType<DesignDataFormModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);
        // if (this.server.IsSubmitted) {
        //     switch (fieldName) {
        //         case "dilapidationReportTypeId": {
        //             const result = this.validateDilapidationReportTypeId;

        //             errorMessage = result.errorMessage;
        //             isValid = result.isValid;
        //             break;
        //         }
        //         case "authorisationSignatureURL": {
        //             const result = this.validateAuthorisationSignatureURL;

        //             errorMessage = result.errorMessage;
        //             isValid = result.isValid;
        //             break;
        //         }
        //     }
        // } else {
        //     errorMessage = "";
        //     isValid = true;
        // }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    // @computed
    // private get validateDilapidationReportTypeId() {
    //     const errorMessage = this.model.validateDilapidationReportTypeId;

    //     return {
    //         errorMessage: errorMessage,
    //         isValid: isEmptyOrWhitespace(errorMessage),
    //     };
    // }

    // @computed
    // private get validateAuthorisationSignatureURL() {
    //     const errorMessage = this.model.validateAuthorisationSignatureURL;

    //     return {
    //         errorMessage: errorMessage,
    //         isValid: isEmptyOrWhitespace(errorMessage),
    //     };
    // }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
