import { FieldType, ViewModelBase } from "@shoothill/core";
import { action, computed } from "mobx";

import { AppUrls } from "AppUrls";
import { Step4Model } from "./Step4Model";

export class Step4ViewModel extends ViewModelBase<Step4Model> {
    constructor() {
        super(new Step4Model());
    }

    @computed
    public get personalProtectiveEquipment() {
        return this.model.personalProtectiveEquipment;
    }

    @action
    public togglePersonalProtectiveEquipmentType = (ppeTypeId: string) => {
        const ppeType = this.model.personalProtectiveEquipmentTypes.find((ppeType) => ppeType.id === ppeTypeId);

        if (ppeType && !this.model.selectedPersonalProtectiveEquipmentTypes.remove(ppeType)) {
            this.model.selectedPersonalProtectiveEquipmentTypes.push(ppeType);
        }
    };

    public isPersonalProtectiveEquipmentTypeSelected = (ppeTypeId: string) => {
        return this.model.selectedPersonalProtectiveEquipmentTypes.findIndex((ppeType) => ppeType.id === ppeTypeId) > -1;
    };

    public getPersonalProtectiveEquipmentTypesByGroupId = (personalProtectiveEquipmentId: string) => {
        return this.model.personalProtectiveEquipmentTypes.filter((ppet) => ppet.personalProtectiveEquipmentId === personalProtectiveEquipmentId);
    };

    // #region Wizard Steps Actions

    public previousStep = () => {
        this.history.push(AppUrls.Client.Induction.Step3);
    };

    public nextStep = () => {
        this.history.push(AppUrls.Client.Induction.Step5);
    };

    @computed
    public get canExecuteNextStep() {
        return this.model.isValid;
    }

    // #endregion Wizard Steps Actions

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<Step4Model>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        if (!isValid) {
            console.log("isFieldValid", fieldName, isValid, errorMessage);
        }

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
