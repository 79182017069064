import { Button } from "@material-ui/core";
import MaterialTable from "material-table";
import { observer } from "mobx-react-lite";
import React from "react";

import { SHBox } from "Components/Box";
import { BaseModal } from "Components/Modal/BaseModal";
import { SnackBar } from "Components/SnackBar/SnackBar";
import * as Defaults from "Globals/Defaults/TableOptions";
import { DarwinTablePageContent } from "Globals/Styles/AppStyling";
import { pxToRem } from "Globals/Styles/AppTheme";
import { ReportModalStyle } from "Views/Project/Modals/Modal.Styles";
import { formatCurrencyFromPounds, formatDate, formatTime } from "Utils/Format";
import { MaterialPriceDetailsHistoryItemViewModel } from "./MaterialPriceDetailsHistoryItemViewModel";
import { MaterialPriceDetailsHistoryViewModel } from "./MaterialPriceDetailsHistoryViewModel";
import { DetailPanel } from "./MaterialPriceDetailsHistoryView.styles";

interface IProps {
    viewModel: MaterialPriceDetailsHistoryViewModel;
}

export const MaterialPriceDetailsHistoryView: React.FC<IProps> = observer((props) => {
    const viewModel = props.viewModel;
    const tableOptions = Defaults.GetDarwinTableMaterialPriceDetailsHistory() as any;

    return (
        <BaseModal
            open={viewModel !== null}
            onClose={viewModel.close}
            title="Material history"
            PaperComponent={ReportModalStyle}
            actions={
                <React.Fragment>
                    <Button onClick={viewModel.close} color="secondary" autoFocus variant="contained">
                        Close
                    </Button>
                </React.Fragment>
            }
        >
            <DarwinTablePageContent style={{ padding: "0 0 20px 0" }}>
                <MaterialTable
                    columns={[
                        {
                            title: "Changed on/by",
                            field: "updatedDate",
                            width: "auto",
                            render: (rowData: MaterialPriceDetailsHistoryItemViewModel) => (
                                <SHBox>
                                    <SHBox>{`${formatDate(rowData.updatedDate)} @ ${formatTime(rowData.updatedDate)} by`}</SHBox>
                                    <SHBox>{rowData.updatedByUser}</SHBox>
                                </SHBox>
                            ),
                            cellStyle: {
                                ...tableOptions.cellStyle,
                                fontSize: pxToRem(10),
                                letterSpacing: pxToRem(-0.2),
                            },
                        },
                        {
                            title: "Estimating price",
                            field: "estimatedPrice",
                            align: "right",
                            width: "auto",
                            render: (rowData: MaterialPriceDetailsHistoryItemViewModel) => formatCurrencyFromPounds(rowData.estimatedPrice, 2, 4),
                            cellStyle: {
                                ...tableOptions.cellStyle,
                                fontWeight: "600",
                            },
                        },
                        {
                            title: "Purchase price",
                            field: "price",
                            align: "right",
                            width: "auto",
                            render: (rowData: MaterialPriceDetailsHistoryItemViewModel) => formatCurrencyFromPounds(rowData.price, 2, 4),
                            cellStyle: {
                                ...tableOptions.cellStyle,
                                fontWeight: "600",
                            },
                        },
                        {
                            title: "Price effective date",
                            field: "effectiveFromDate",
                            width: "auto",
                            render: (rowData: MaterialPriceDetailsHistoryItemViewModel) => formatDate(rowData.effectiveFromDate),
                        },
                        {
                            title: "Effective supplier",
                            field: "supplier",
                            width: "auto",
                            cellStyle: {
                                ...tableOptions.cellStyle,
                                borderRight: "none",
                            },
                        },
                    ]}
                    data={viewModel.filteredMaterials}
                    options={{ ...tableOptions }}
                    title=""
                    detailPanel={(rowData: MaterialPriceDetailsHistoryItemViewModel) => {
                        return (
                            <DetailPanel>
                                <div>Notes</div>
                                <div>{rowData.note}</div>
                            </DetailPanel>
                        );
                    }}
                />
            </DarwinTablePageContent>
            <SnackBar
                messageText={viewModel.snackMessage}
                messageType={viewModel.snackType}
                active={viewModel.snackbarState}
                closeOption={() => viewModel.setSnackbarState(false)}
                autoHideDuration={5000}
            />
        </BaseModal>
    );
});
