// Libraries
import { ControlLabel } from "Components/Form/ControlLabel";
import { pxToRem } from "Globals/Styles/AppTheme";
import { useObserver } from "mobx-react-lite";
import React from "react";
import styled from "styled-components";
import { mimeTypeMap } from "Utils/Utils";

interface IProps {
    handleSelectFile: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
    isFormDisabled: boolean;
    label: string;
    dropzoneMessage?: string;
    style?: React.CSSProperties;
}

export const Uploader: React.FC<IProps> = (props: IProps) => {
    const { handleSelectFile, isFormDisabled, label, dropzoneMessage = "Click to add or drag your file to attach" } = props;

    const acceptedFileTypes: string = Object.values(mimeTypeMap).join(", ");

    // Note: application/vnd.ms-outlook seems to not work as expected so .msg is required.
    const additionalSupportedTypes: string = acceptedFileTypes + ", " + ".msg";

    return useObserver(() => (
        <UploaderContainer>
            <ControlLabel label={label} htmlFor="file-uploader">
                <div className="fileUpload" style={{ margin: label !== "" ? "6px auto 1.25em" : "1.25em auto", cursor: isFormDisabled ? "default" : "pointer", ...props.style }}>
                    <input
                        name="file-uploader"
                        type="file"
                        className="upload"
                        onChange={(event) => handleSelectFile(event)}
                        disabled={isFormDisabled}
                        style={{ cursor: isFormDisabled ? "default" : "pointer" }}
                        accept={additionalSupportedTypes}
                    />
                    <span>{dropzoneMessage}</span>
                </div>
            </ControlLabel>
        </UploaderContainer>
    ));
};

const UploaderContainer = styled.div`
    .fileUpload {
        overflow: hidden;
        position: relative;
        text-align: center;
        width: 100%;
        background: #fff;
        color: #bbb;
        border: 1px dashed #ccc;
        border-radius: 0;
        padding: 35px;
        font-size: 14px;

        input.upload {
            position: absolute;
            top: 0;
            right: 0;
            margin: 0;
            padding: 0;
            font-size: 20px;
            opacity: 0;
            filter: alpha(opacity=0);
            width: 100%;
            height: 100%;
        }
    }

    .control-label {
        font-size: ${pxToRem(11)};
        font-weight: 600;
        letter-spacing: ${pxToRem(-0.18)};
    }
`;
