import { Typography } from "@material-ui/core";
import { Grid } from "@shoothill/core";
import MaterialTable from "material-table";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

import { SnackBar } from "Components/SnackBar/SnackBar";
import { TableListSearch } from "Components/Table/TableListSearch";
import * as Defaults from "Globals/Defaults/TableOptions";
import { DarwinPage, DarwinPageTitle, DarwinTableButtonContainer, DarwinTablePageContent, DarwinTableSearchBox, DarwinTableToolbar } from "Globals/Styles/AppStyling";
import { formatCurrencyFromPounds, formatDate, formatIntegerNumber, formatTime } from "Utils/Format";
import { StockHistoryGlobalViewModel } from "./StockHistoryGlobalViewModel";
import { StockHistoryItemViewModel } from "../StockHistory/StockHistoryItemViewModel";
import { SHBox } from "Components/Box";
import { pxToRem } from "Globals/Styles/AppTheme";
import { DetailPanel, DummyEntryLink, EntryLink } from "./StockHistoryGlobalView.styles";
import { DownloadButton } from "Components/Buttons/Buttons";

export const StockHistoryGlobalView: React.FunctionComponent = observer(() => {
    const [viewModel] = useState(() => new StockHistoryGlobalViewModel());
    const tableOptions = Defaults.GetDarwinTableOptionsNoSearchV2() as any;

    return (
        <React.Fragment>
            <DarwinPage>
                <DarwinPageTitle>
                    <Typography variant="h1" color="textPrimary">
                        Stock history
                    </Typography>
                </DarwinPageTitle>
                <DarwinTableToolbar>
                    <Grid columnGap={30} dc={"1fr 1fr"} rowGap={15} tc={"1fr 1fr"}>
                        <DarwinTableSearchBox>
                            <TableListSearch searchString={viewModel.getSearchString()} setSearchString={viewModel.setSearchString} />
                        </DarwinTableSearchBox>
                        <DarwinTableButtonContainer>
                            <DownloadButton color="primary" canExecute={!viewModel.server.IsBusy} displayName="Export as CSV" execute={viewModel.apiDownloadStockHistoryAsync} />
                        </DarwinTableButtonContainer>
                    </Grid>
                </DarwinTableToolbar>
                <DarwinTablePageContent>
                    <MaterialTable
                        columns={[
                            {
                                title: "Changed on/by",
                                field: "updatedDate",
                                width: "auto",
                                render: (rowData: StockHistoryItemViewModel) => (
                                    <SHBox>
                                        <SHBox>{`${formatDate(rowData.updatedDate)} @ ${formatTime(rowData.updatedDate)} by`}</SHBox>
                                        <SHBox>{rowData.createdByUser}</SHBox>
                                    </SHBox>
                                ),
                                cellStyle: {
                                    ...tableOptions.cellStyle,
                                    fontSize: pxToRem(10),
                                    letterSpacing: pxToRem(-0.2),
                                },
                            },
                            {
                                title: "Description",
                                field: "description",
                                width: "auto",
                                sorting: false,
                            },
                            {
                                title: "SKU number",
                                field: "skuNumber",
                                width: "auto",
                                sorting: false,
                            },
                            {
                                title: "Entry type",
                                field: "stockTransactionType",
                                width: "auto",
                                sorting: false,
                                render: (rowData: StockHistoryItemViewModel) => (
                                    <SHBox>
                                        <SHBox>{rowData.stockTransactionType}</SHBox>
                                        {rowData.canDisplayPurchaseOrder && (
                                            <EntryLink onClick={() => viewModel.navigateToPurchaseOrder(rowData.purchaseOrderId!)}>{rowData.purchaseOrderNumber}</EntryLink>
                                        )}
                                        {rowData.canDisplayStockTransactionRequestNumber && <DummyEntryLink>{rowData.stockTransactionRequestNumber}</DummyEntryLink>}
                                    </SHBox>
                                ),
                            },
                            {
                                title: "From",
                                field: "stockTransactionFrom",
                                width: "auto",
                                sorting: false,
                                render: (rowData: StockHistoryItemViewModel) => (
                                    <SHBox>
                                        <SHBox>{rowData.stockTransactionFrom}</SHBox>
                                    </SHBox>
                                ),
                            },
                            {
                                title: "To",
                                field: "stockTransactionTo",
                                width: "auto",
                                sorting: false,
                                render: (rowData: StockHistoryItemViewModel) => (
                                    <SHBox>
                                        <SHBox>{rowData.stockTransactionTo}</SHBox>
                                    </SHBox>
                                ),
                            },
                            {
                                title: "Movement",
                                field: "unitChange",
                                align: "right",
                                width: "auto",
                                sorting: false,
                                render: (rowData: StockHistoryItemViewModel) => formatIntegerNumber(rowData.unitChange),
                                cellStyle: (all: StockHistoryItemViewModel[], rowData: StockHistoryItemViewModel) => {
                                    return {
                                        ...tableOptions.cellStyle,
                                        fontWeight: "600",
                                        backgroundColor: rowData.unitChange < 0 ? "#ECB4B4" : "#CAEAD4",
                                    };
                                },
                            },
                            {
                                title: "Stock cost/item",
                                field: "pricePerUnit",
                                align: "right",
                                width: "auto",
                                sorting: false,
                                render: (rowData: StockHistoryItemViewModel) => formatCurrencyFromPounds(rowData.pricePerUnit, 2, 4),
                            },
                            {
                                title: "Stock balance",
                                field: "stockQuantity",
                                align: "right",
                                width: "auto",
                                sorting: false,
                                render: (rowData: StockHistoryItemViewModel) => formatIntegerNumber(rowData.stockQuantity),
                            },
                            {
                                title: "Stock value",
                                field: "stockValue",
                                align: "right",
                                width: "auto",
                                sorting: false,
                                render: (rowData: StockHistoryItemViewModel) => formatCurrencyFromPounds(rowData.stockValue),
                                cellStyle: (all: StockHistoryItemViewModel[], rowData: StockHistoryItemViewModel) => {
                                    return {
                                        ...tableOptions.cellStyle,
                                        borderRight: "none",
                                        fontWeight: "600",
                                        backgroundColor: rowData.unitChange < 0 ? "#ECB4B4" : "#CAEAD4",
                                    };
                                },
                            },
                        ]}
                        isLoading={viewModel.IsLoading}
                        data={viewModel.filteredStockHistoryItems}
                        options={{ ...tableOptions }}
                        title=""
                        detailPanel={(rowData: StockHistoryItemViewModel) => {
                            return (
                                <DetailPanel>
                                    <div>Notes</div>
                                    <div>{rowData.note}</div>
                                </DetailPanel>
                            );
                        }}
                    />
                </DarwinTablePageContent>
                <SnackBar
                    messageText={viewModel.snackMessage}
                    messageType={viewModel.snackType}
                    active={viewModel.snackbarState}
                    closeOption={() => viewModel.setSnackbarState(false)}
                    autoHideDuration={5000}
                />
            </DarwinPage>
        </React.Fragment>
    );
});
