// Libraries
import MomentUtils from "@date-io/moment";
import { FormHelperText } from "@material-ui/core";
import { KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import { useObserver } from "mobx-react-lite";
import moment from "moment";
import React from "react";
import styled from "styled-components";

export interface IDarwinTimeSelectProps {
    canExecute?: boolean;
    className?: string;
    displayName?: string;
    execute: (value: string | null) => void;
    fullWidth?: boolean;
    onBlur?: () => void;
    placeholder?: string;
    validationMessage?: string;
    value: string | null;
    //minDate?: Date;
}

const DarwinTimeSelectBase: React.FC<IDarwinTimeSelectProps> = (props: IDarwinTimeSelectProps) => {
    // #region Code Behind

    const getId = (): string => {
        return "selectDateControlId";
    };

    const getPlaceholder = (): string => {
        return !isEmptyOrWhitespace(props.placeholder) ? props.placeholder! : "";
    };

    const getValidationMessage = (): string => {
        return !isEmptyOrWhitespace(props.validationMessage) ? props.validationMessage! : "";
    };

    const isDisabled = (): boolean => {
        return !isNullOrUndefined(props.canExecute) ? !props.canExecute : false;
    };

    const isInError = (): boolean => {
        return !isEmptyOrWhitespace(props.validationMessage);
    };

    const onChange = (date: MaterialUiPickersDate) => {
        const isoString = !isNullOrUndefined(date) ? (!isNullOrUndefined(date!.toISOString()) ? moment(date!.toISOString()).toISOString() : null) : null;

        props.execute(isoString);
    };

    // #endregion Code Behind

    return useObserver(() => (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardTimePicker
                disabled={isDisabled()}
                //format="DD/MM/YYYY"
                fullWidth={props.fullWidth}
                error={isInError()}
                id={getId()}
                invalidDateMessage={props.validationMessage ? props.validationMessage : ""}
                label={props.displayName}
                onBlur={props.onBlur}
                onChange={onChange}
                placeholder={getPlaceholder()}
                value={isNullOrUndefined(props.value) ? null : moment(props.value)}
                InputLabelProps={{
                    shrink: true,
                }}
                className={props.className}
                //minDate={props.minDate}
            />
            {isInError() && <FormHelperText style={{ color: "#f44336" }}>{getValidationMessage()}</FormHelperText>}
        </MuiPickersUtilsProvider>
    ));
};

export const DarwinTimeSelect: React.FC<IDarwinTimeSelectProps> = styled(DarwinTimeSelectBase)``;
