import { FieldType, ViewModelBase } from "@shoothill/core";
import { action } from "mobx";
import { RFIFormDocumentModel } from "./RFIFormDocumentModel";

export class RFIFormDocumentViewModel extends ViewModelBase<RFIFormDocumentModel> {
    constructor(item: RFIFormDocumentModel | null) {
        super(item ? item : new RFIFormDocumentModel());
        this.setDecorators(RFIFormDocumentModel);
    }

    @action
    public reset = () => {
        this.model.reset();
    };

    private isMyModelValid = async (): Promise<boolean> => {
        let isValid = true;
        if ((await this.isModelValid()) === false) {
            isValid = false;
        }

        return isValid;
    };

    public async isFieldValid(fieldName: keyof FieldType<RFIFormDocumentModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
