import styled from "styled-components";
import { Paper, Button } from "@material-ui/core";
import { theme } from "../../Globals/Styles/AppTheme";

export const BaseSidebarPaper: any = styled(Paper)`
    background-color: #ffffff;
    bottom: 0;
    box-shadow: -3px 0px 3px #00000029;
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 0px;
    top: 70px; // offset for nav bar
    width: 320px;
    z-index: 1600; // Keep this on top of notification bar (NotificationBar.style.ts).

    .darwin-dialog-title {
        padding: 8px 15px 10px 15px;
        background: ${theme.palette.navy.main};
        color: ${theme.palette.common.white};

        .primary-row {
            display: flex;
            flex-direction: row;
            padding: 0;
            span.primaryTitle {
                font-size: 14px;
                letter-spacing: -0.21px;
            }

            .close {
                cursor: pointer;
                font-weight: bold;
                position: absolute;
                top: 5px;
                right: 15px;
            }
        }

        .secondary-row {
            padding: 0;
            span.secondaryTitle {
                font-size: 10px;
                letter-spacing: -0.15px;
            }
        }
    }

    .darwin-dialog-body {
        padding: 16px 15px;
        flex: 1;
        font-size: 12px;
        overflow-y: auto;
    }

    .darwin-dialog-footer {
        background-color: #eaf4f9;
        box-shadow: 0px -3px 3px #00000029;
        padding: 20px 15px;
        width: 100%;
        z-index: 1;
    }
`;

const BaseButton: any = styled(Button)`
    top: 647px;
    left: 1061px;
    width: 290px;
    height: 30px;
    background: 0% 0% no-repeat padding-box;
    border-radius: 23px;
    font-size: 12px;
    font-weight: normal;
`;

export const ApproveButton: any = styled(BaseButton)`
    color: #ffffff;
    background-color: #52bb71;
`;

export const AmendsButton: any = styled(BaseButton)`
    color: #000000;
    background-color: #ffcc00;
`;

export const RejectButton: any = styled(BaseButton)`
    color: #ffffff;
    background-color: #df3a3a;
`;
