import { Box, IconButton, TextField, Typography } from "@material-ui/core";
import { Cell, Grid, useRouter } from "@shoothill/core";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { uniqueId } from "lodash-es";
import { Form, FormSection } from "Views/PurchaseOrder/Form/Views/Forms";
import { FormHeader } from "Views/PurchaseOrder/Form/Views/FormHeaders";
import { ProjectGeneralViewModel } from "../../../../General/ProjectGeneralViewModel";
import { DashedDivider, SolidDivider } from "Views/PurchaseOrder/Form/Views/Dividers";
import { pxToRem, theme } from "Globals/Styles/AppTheme";
import { CustomArrow } from "Views/Project/CustomComponents";
import { DefaultButton, PrimaryButton } from "Components/Buttons/Buttons";
import { DarwinSelect } from "Components/AutoComplete/DarwinSelect";
import { DarwinDateSelect } from "Components/DateSelect/DarwinDateSelect";
import { BackButtonHolder } from "Views/Project/Commercial/VariationTables/VariationGrid.Styles";
import { AppUrls } from "AppUrls";
import { Uploader } from "Components/Uploader/Uploader";
import { CustomHr } from "../../../../CustomComponents";
import { RFICreateFormViewModel } from "./RFICreateFormViewModel";
import { RFICreateFormItemViewModel } from "./RFICreateFormItemViewModel";
import { RFIFormDocumentViewModel } from "../Common/RFIFormDocumentViewModel";
import { RFIFormFileViewModel } from "../Common/RFIFormFileViewModel";

// Styling
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import SystemUpdateTwoToneIcon from "@material-ui/icons/SystemUpdateTwoTone";
import moment from "moment";

interface IProps {
    className?: string;
}

export const RFICreateFormViewBase: React.FC<IProps> = (props) => {
    const { match } = useRouter();
    const { history } = useRouter();
    const { projectid, rfiid, ref, isrevision } = match.params as any;
    const [viewModel] = useState(() => new RFICreateFormViewModel(projectid));
    const [generalViewModel] = useState(() => ProjectGeneralViewModel.Instance);
    useEffect(() => {
        return () => {
            viewModel.reset();
        };
    }, []);

    const COLUMNS8 = "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr";
    const COLUMNS4 = "1fr 1fr 1fr 1fr";
    const COLUMNS2 = "1fr 1fr";
    const onSubmit = (event: any) => {
        event.preventDefault();
    };

    const renderForm = () => {
        return (
            <FormBox>
                <Form className={props.className} onSubmit={onSubmit}>
                    <FormSection>
                        <DashedDivider gutterBottom={true} borderThickness={1} borderOpacity={1} />
                        <Grid columnGap={30} rowGap={20} tc={COLUMNS4} dc={COLUMNS4} style={{ marginBottom: "20px" }}>
                            <Cell ts={1} ds={1}>
                                <DarwinSelect
                                    displayName="Category:"
                                    execute={(data: { id: string; displayName: string }) => viewModel.handleCategoryId(data)}
                                    fullWidth={true}
                                    getOptionLabel={(option: any) => option.displayName}
                                    options={viewModel.categories}
                                    onBlur={() => viewModel.isFieldValid("rfiCategoryId")}
                                    placeholder="Please select"
                                    value={viewModel.category}
                                    error={viewModel.getError("rfiCategoryId") !== ""}
                                    validationMessage={viewModel.getError("rfiCategoryId")}
                                    canExecute={!viewModel.IsLoading}
                                />
                            </Cell>
                        </Grid>

                        <Grid columnGap={30} rowGap={20} marginBottom={1} tc={COLUMNS2} dc={COLUMNS2}>
                            <Cell ts={1} ds={1}>
                                <FileUploader>
                                    <Typography
                                        style={{ marginBottom: "8px", font: "normal normal 700 12px/16px Segoe UI", letterSpacing: "-0.18px", color: "#191919" }}
                                        variant="h5"
                                    >
                                        Category documents to be applied to all RFI's:
                                    </Typography>
                                    <Box>
                                        <Uploader handleSelectFile={(e: any) => viewModel.fileChangeForCategory(e)} isFormDisabled={false} label="" />
                                        {viewModel.model.rfiDocuments.filter((el: any) => !el.isDeleted).length > 0 && (
                                            <Typography style={{ margin: "17px 0px 13px" }} variant="h5">
                                                Document(s) added:
                                            </Typography>
                                        )}
                                    </Box>
                                    <Box className="uploadedFileRFI">
                                        {viewModel.rfiDocumentViewModels.map((item: RFIFormDocumentViewModel, index: number) => {
                                            return (
                                                !item.model.isDeleted && (
                                                    <Box className="uploadedFile-box" key={uniqueId()}>
                                                        <Box className="uploadedFile-heading">
                                                            <IconButton onClick={() => viewModel.DownloadFile(item.model.fileURL, item.model.fileName)}>
                                                                <SystemUpdateTwoToneIcon />
                                                            </IconButton>
                                                            <h3>{item.model.fileName}</h3>
                                                        </Box>
                                                        <IconButton style={{ cursor: "pointer" }} onClick={() => viewModel.deleteRFIDocument(index)} disabled={false}>
                                                            <DeleteOutlinedIcon />
                                                        </IconButton>
                                                    </Box>
                                                )
                                            );
                                        })}
                                    </Box>
                                    {viewModel.rfiDocumentViewModels.length === 0 && (
                                        <NoItemsTextContainer>
                                            <Typography variant="body1">No documents added</Typography>
                                        </NoItemsTextContainer>
                                    )}
                                </FileUploader>
                            </Cell>
                        </Grid>

                        <Typography variant="h2" style={{ marginTop: "30px" }}>
                            Add item(s) to category
                        </Typography>
                        <div style={{ padding: "0px 0px", marginTop: "10px", marginBottom: "10px" }}>
                            <CustomHr />
                        </div>

                        {viewModel.rfiTempCreateFormItemViewModels.map((item: RFICreateFormItemViewModel, index: number) => {
                            return (
                                <>
                                    <Grid columnGap={30} rowGap={20} tc={COLUMNS4} dc={COLUMNS4} style={{ marginBottom: "10px" }}>
                                        <Cell ts={1} ds={1}>
                                            <TextField
                                                type="text"
                                                value={item.getValue("title")}
                                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => item.setValue("title", e.target.value)}
                                                fullWidth
                                                error={false}
                                                disabled={viewModel.IsLoading}
                                                label={"Item:*"}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                placeholder={"Please add"}
                                                helperText={item.getError("title")}
                                            />
                                        </Cell>
                                        <Cell ts={1} ds={1}>
                                            <TextField
                                                type="text"
                                                value={item.getValue("supplierReference")}
                                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => item.setValue("supplierReference", e.target.value)}
                                                fullWidth
                                                disabled={viewModel.IsLoading}
                                                label={"Supplier ref:"}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                placeholder={"Please add"}
                                            />
                                        </Cell>
                                        <Cell ts={1} ds={1}>
                                            <DarwinSelect
                                                displayName="Supplier:*"
                                                execute={(data: { id: string; displayName: string }) => item.handleSupplierId(data)}
                                                fullWidth={true}
                                                getOptionLabel={(option: any) => option.displayName}
                                                options={viewModel.suppliers}
                                                onBlur={() => item.isFieldValid("supplierId")}
                                                placeholder="Please select"
                                                value={item.supplier}
                                                error={item.getError("supplierId") !== ""}
                                                validationMessage={item.getError("supplierId")}
                                                canExecute={!viewModel.IsLoading}
                                            />
                                        </Cell>
                                    </Grid>
                                    <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8} style={{ marginBottom: "10px" }}>
                                        <Cell ts={1} ds={1}>
                                            <DarwinDateSelect
                                                displayName="Request date:*"
                                                execute={(value: string | null) => item.setRequestedDate(value)}
                                                onBlur={() => item.isFieldValid("requestedDate")}
                                                placeholder="Please select"
                                                validationMessage={item.getError("requestedDate")}
                                                value={
                                                    item.getValue("requestedDate") === null
                                                        ? item.setRequestedDate(moment().format("YYYY-MM-DDTHH:mm:ss[Z]"))
                                                        : item.getValue("requestedDate")
                                                }
                                                canExecute={!viewModel.IsLoading}
                                            />
                                        </Cell>
                                        <Cell ts={1} ds={1}>
                                            <DarwinDateSelect
                                                displayName="Response required by:*"
                                                execute={(value: string | null) => item.setResponseRequiredByDate(value)}
                                                onBlur={() => item.isFieldValid("responseRequiredByDate")}
                                                placeholder="Please select"
                                                validationMessage={item.getError("responseRequiredByDate")}
                                                value={
                                                    item.getValue("responseRequiredByDate") === null
                                                        ? item.setResponseRequiredByDate(moment().add(2, "weeks").format("YYYY-MM-DDTHH:mm:ss[Z]"))
                                                        : item.getValue("responseRequiredByDate")
                                                }
                                                canExecute={!viewModel.IsLoading}
                                            />
                                        </Cell>
                                        <Cell ts={2} ds={2}>
                                            <DarwinSelect
                                                displayName="Status:*"
                                                execute={(data: { id: string; displayName: string }) => item.handleStatusId(data)}
                                                fullWidth={true}
                                                getOptionLabel={(option: any) => option.displayName}
                                                options={viewModel.statuses}
                                                onBlur={() => item.isFieldValid("rfiStatusId")}
                                                placeholder="Please select"
                                                value={item.status}
                                                error={item.getError("rfiStatusId") !== ""}
                                                validationMessage={item.getError("rfiStatusId")}
                                                canExecute={!viewModel.IsLoading}
                                            />
                                        </Cell>
                                    </Grid>
                                    <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8} style={{ marginBottom: "10px" }}>
                                        <Cell ts={8} ds={8}>
                                            <TextField
                                                type="text"
                                                value={item.getValue("description")}
                                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => item.setValue("description", e.target.value)}
                                                fullWidth
                                                error={false}
                                                disabled={viewModel.IsLoading}
                                                label={"Item description:*"}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                placeholder={"Please add"}
                                                helperText={item.getError("description")}
                                            />
                                        </Cell>
                                    </Grid>
                                    <Grid columnGap={30} rowGap={20} marginBottom={1} tc={COLUMNS2} dc={COLUMNS2}>
                                        <Cell ts={1} ds={1}>
                                            <FileUploader>
                                                <Typography
                                                    style={{ marginBottom: "8px", font: "normal normal 700 12px/16px Segoe UI", letterSpacing: "-0.18px", color: "#191919" }}
                                                    variant="h5"
                                                >
                                                    Attachments:
                                                </Typography>
                                                <Box>
                                                    <Uploader handleSelectFile={(e: any) => item.fileChange(e, item.model.uniqueId)} isFormDisabled={false} label="" />
                                                    {item.model.rfiDocuments.filter((el: any) => !el.isDeleted).length > 0 && (
                                                        <Typography style={{ margin: "17px 0px 13px" }} variant="h5">
                                                            Document(s) added:
                                                        </Typography>
                                                    )}
                                                </Box>
                                                <Box className="uploadedFileRFI">
                                                    {item.rfiFileViewModels.map((fileViewModel: RFIFormFileViewModel, index: number) => {
                                                        return (
                                                            !item.model.isDeleted && (
                                                                <Box className="uploadedFile-box" key={uniqueId()}>
                                                                    <Box className="uploadedFile-heading">
                                                                        <IconButton
                                                                            onClick={() => viewModel.DownloadFile(fileViewModel.model.fileURL, fileViewModel.model.fileName)}
                                                                        >
                                                                            <SystemUpdateTwoToneIcon />
                                                                        </IconButton>
                                                                        <h3>{fileViewModel.model.fileName}</h3>
                                                                    </Box>
                                                                    <IconButton style={{ cursor: "pointer" }} onClick={() => item.deleteRFIAttachment(index)} disabled={false}>
                                                                        <DeleteOutlinedIcon />
                                                                    </IconButton>
                                                                </Box>
                                                            )
                                                        );
                                                    })}
                                                </Box>
                                                {item.rfiFileViewModels.length === 0 && (
                                                    <NoItemsTextContainer>
                                                        <Typography variant="body1">No attachments added</Typography>
                                                    </NoItemsTextContainer>
                                                )}
                                            </FileUploader>
                                        </Cell>
                                    </Grid>
                                    <Grid columnGap={30} rowGap={20} marginBottom={1} tc={COLUMNS8} dc={COLUMNS8}>
                                        <Cell ts={1} ds={1}>
                                            <PrimaryButton displayName="Add" execute={() => viewModel.addRFIItem()} fullWidth={true} canExecute={!viewModel.IsLoading} />
                                        </Cell>
                                    </Grid>
                                </>
                            );
                        })}
                    </FormSection>

                    <Box style={{ margin: `0 ${pxToRem(30)} 0 ${pxToRem(30)}` }}>
                        <DashedDivider gutterBottom={true} borderThickness={1} borderOpacity={1} />
                    </Box>

                    <FormSection>
                        <Typography variant="h2">Attached item(s)</Typography>
                        {viewModel.rfiCreateFormItemViewModels.length > 0 && (
                            <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8} style={{ marginBottom: "10px" }}>
                                {viewModel.rfiCreateFormItemViewModels.map((item: RFICreateFormItemViewModel, index: number) => {
                                    return (
                                        <>
                                            <Cell ts={8} ds={8}>
                                                <Box className="uploadedFileRFI">
                                                    <AttachedItemContainer>
                                                        <div>
                                                            <span>Item:</span> {item.model.title}
                                                        </div>
                                                        <div>
                                                            <span>Supplier ref:</span> {item.model.supplierReference}
                                                        </div>
                                                        <div>
                                                            <span>Supplier:</span> {viewModel.getSupplier(item.model.supplierId)}
                                                        </div>
                                                        <div>
                                                            <span>Item description:</span> {item.model.description}
                                                        </div>
                                                        <div>
                                                            <IconButton style={{ cursor: "pointer" }} onClick={() => viewModel.deleteRFIItem(index)} disabled={false}>
                                                                <DeleteOutlinedIcon />
                                                            </IconButton>
                                                        </div>
                                                    </AttachedItemContainer>
                                                </Box>
                                            </Cell>
                                        </>
                                    );
                                })}
                            </Grid>
                        )}
                    </FormSection>

                    <Box style={{ margin: `0 ${pxToRem(30)} 0 ${pxToRem(30)}` }}>
                        <SolidDivider gutterBottom={true} borderThickness={1} borderOpacity={1} />
                    </Box>

                    <FormSection>
                        <ButtonsContainer>
                            {/* <PrimaryButton displayName="Add Additional Category" fullWidth={true} canExecute={!viewModel.IsLoading} /> */}
                            <PrimaryButton
                                displayName="Create"
                                execute={() => viewModel.upsert()}
                                fullWidth={true}
                                canExecute={!viewModel.IsLoading && viewModel.rfiCreateFormItemViewModels.length > 0}
                            />
                            <DefaultButton displayName="Cancel" execute={() => viewModel.handleCancel(projectid)} fullWidth={true} canExecute={!viewModel.IsLoading} />
                        </ButtonsContainer>
                    </FormSection>
                </Form>
            </FormBox>
        );
    };

    return useObserver(() => {
        return (
            <Form className={props.className} onSubmit={() => {}}>
                <BackButtonHolder
                    onClick={() => {
                        history.push(AppUrls.Client.Project.RequestForInformation.replace(":projectid", projectid ? projectid : viewModel.model.projectId));
                    }}
                    style={{ padding: "10px 0", marginTop: 0 }}
                >
                    <Box style={{ position: "relative", display: "flex", marginLeft: "10px", alignItems: "center" }}>
                        <Box style={{ position: "absolute", height: "15px", width: "8px" }}>
                            <CustomArrow size={"8px"} color={theme.palette.blue.main} type={"left"} />
                        </Box>
                        <Box style={{ marginLeft: "20px", font: "normal normal 600 18px/32px Open Sans", letterSpacing: "-0.36px", color: theme.palette.blue.main }}>
                            Back to Request for information
                        </Box>
                    </Box>
                </BackButtonHolder>

                <DashedDivider gutterBottom={false} gutterTop={false} borderThickness={2} borderOpacity={0.5} />

                <HeaderContainer>
                    <FormHeader displayName={viewModel.getFormTitle(isrevision)} />
                </HeaderContainer>

                <FormSection>
                    <ProjectDetailsContainer>
                        <div>
                            <span>Date / time added:</span> {viewModel.getTodayDateFormatted}
                        </div>
                        <div>
                            <span>Project reference:</span> {viewModel.getProjectReferenceFormatted}
                        </div>
                        <div>
                            <span>Project name:</span> {viewModel.getProjectName}
                        </div>
                    </ProjectDetailsContainer>
                </FormSection>

                {renderForm()}
            </Form>
        );
    });
};

export const RFICreateFormView = styled(RFICreateFormViewBase)`
    display: flex;
    flex: 1;
    flex-direction: column;

    form {
        position: relative;
    }
`;

const HeaderContainer = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 70px;

    // Header
    > div:nth-child(1) {
        margin: 4px 30px 13.5px 30px;
    }
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;

    > button {
        max-width: 262px;
    }

    > button:nth-child(1) {
        margin-right: 30px;
    }

    > button:nth-child(2) {
        margin-right: 30px;
    }
`;

export const NoItemsTextContainer = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;

    p {
        font-size: 12px !important;
    }
`;

export const ItemTextContainer = styled.div`
    margin-left: 5px;

    p {
        font-size: 12px !important;
    }
`;

const FormBox = styled(Box)`
    .MuiFormLabel-root {
        font: normal normal 700 12px/16px Segoe UI;
        letter-spacing: -0.18px;
        color: #191919;
        margin: 0 0 7px;
    }
`;

interface SubtitleProps {
    margin?: string;
}

export const SubtitleContainer = styled.div<SubtitleProps>`
    margin-top: 12px;
    h2 {
        margin-bottom: ${(props) => (props.margin ? props.margin : "10px")};
        font: normal normal 600 12px/16px Segoe UI;
        letter-spacing: -0.18px;
        color: #191919;
        span {
            font-weight: bold;
            margin-right: 10px;
        }
    }
`;

const ProjectDetailsContainer = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    max-width: 700px;

    > div {
        font-size: 12px;
    }

    > div > span {
        font-weight: bold;
    }
`;

const AttachedItemContainer = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #eaf4f9;

    > div {
        font-size: 12px;
        margin-right: 30px;
    }

    > div > span {
        font-weight: bold;
    }
`;

const FileUploader = styled(Box)`
    margin-top: 20px;
    .fileUpload {
        background: #6c93a626;
        border: 1px dashed #6c93a6;
        font: normal normal normal 12px/14px Open Sans;
        letter-spacing: 0px;
        color: #425b66;
        padding: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 90px;
        margin: 0 !important;
    }
`;

// I don't know why, but the textarea sometimes has a default height of 0px...
export const TextAreaContainer = styled.div`
    textarea.MuiInputBase-input {
        min-height: 60px;
    }
`;
