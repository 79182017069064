import * as MobX from "mobx";
import { ModelBase } from "@shoothill/core";
import { IsEmail, IsNotEmpty } from "class-validator";
import moment from "moment";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class SupplierListModel extends ModelBase<SupplierListModel, SupplierListItemDTO> {
    public id: string = "";

    @MobX.observable
    public createdDate: string = "";
    @MobX.observable
    public reference: string = "";
    @MobX.observable
    public name: string = "";
    @MobX.observable
    public costCategory: number = 0;
    @MobX.observable
    public contactName: string = "";
    @MobX.observable
    /*     @IsNotEmpty({ message: "Email is required!" })
    @IsEmail({}, { message: "Email is not valid!" }) */
    public contactEmail: string = "";
    @MobX.observable
    public contactPhone: string = "";
    @MobX.observable
    public status: number = 0;
    @MobX.observable
    public queriedPo: number = 0;
    @MobX.observable
    public queriedInvoices: number = 0;

    @MobX.observable
    public insuranceExpiryDate: Date | null = null;

    //fromDto is required but you can leave it blank
    fromDto(model: SupplierListItemDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: SupplierListItemDTO): void {}

    @MobX.action
    public reset = () => {
        // CMR TODO
    };
}

export interface SupplierListItemDTO {
    id: string;
    createdDate: string;
    reference: string;
    name: string;
    costCategory: number;
    contactName: string;
    contactEmail: string;
    contactPhone: string;
    status: number;
    queriedPo: number;
    queriedInvoices: number;
    insuranceExpiryDate: Date | null;
}
