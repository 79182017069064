import { ModelBase, observable } from "@shoothill/core";
//import { IncidentTypeDTO } from "./IncidentModel";

export class IncidentListModel extends ModelBase<IncidentListModel> {
    public id: string = "";
    @observable
    public nearMissCount: number = 0;
    @observable
    public accidentCount: number = 0;
    @observable
    public witnessStatementCount: number = 0;
    @observable
    public incidentNumber: number = 0;
    @observable
    public createdDate: string = "";
    @observable
    public lastUpdatedDate: string = "";
    @observable
    public lastUpdatedByUserName: string = "";

    constructor() {
        super();
    }

    fromDto(model: IncidentListDTO): void {
        this.id = model.id;
        this.nearMissCount = model.nearMissCount;
        this.accidentCount = model.accidentCount;
        this.witnessStatementCount = model.witnessStatementCount;
        this.incidentNumber = model.incidentNumber;
        this.createdDate = model.createdDate;
        this.lastUpdatedDate = model.lastUpdatedDate;
        this.lastUpdatedByUserName = model.lastUpdatedByUserName;
    }
    toDto(model: IncidentListModel): void {
        throw new Error("Method not implemented.");
    }
}

export interface IncidentListDTO {
    id: string;
    nearMissCount: number;
    accidentCount: number;
    witnessStatementCount: number;
    incidentNumber: number;
    createdDate: string;
    lastUpdatedDate: string;
    lastUpdatedByUserName: string;
}

export interface IncidentListAndRelatedDTO {
    incidentListItems: IncidentListDTO[];
    //IncidentTypes: IncidentTypeDTO[];
}
