import React from "react";
import { Box, Typography } from "@material-ui/core";
import { ControlLabel } from "Components/Form/ControlLabel";

export interface IDetailsGroupProps {
    detailsType: string;
    detailsData: string | number | null;
    maxWidth?: string;
}

export const DetailsGroup = (props: IDetailsGroupProps) => {
    const maxWidth: string = props.maxWidth === undefined ? "200px" : props.maxWidth;

    return (
        <Box maxWidth={maxWidth}>
            <ControlLabel label={props.detailsType} htmlFor={""}>
                <Typography variant="body1" color="textPrimary">
                    {props.detailsData}
                </Typography>
            </ControlLabel>
        </Box>
    );
};
