import { ApprovalPanelModelBase } from "Globals/Models/ApprovalPanelModelBase";

export class InvoiceApprovalPanelModel extends ApprovalPanelModelBase<InvoiceApprovalPanelModel> {
    // #region Properties
    // #endregion Properties
    // #region Other properties
    // #endregion Other properties
    // #region Actions
    // #endregion Actions
}
