/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect } from "react";
import { StoresInstance } from "Globals/Stores";

export const LogoutView: React.FC = () => {
    useEffect(() => {
        StoresInstance.Domain.AccountStore.Logout(true);
    }, []);

    return <></>;
};
