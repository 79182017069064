// Libraries
import React from "react";
import { useObserver } from "mobx-react-lite";

// Custom
import { VariationCategoryViewModel } from "./VariationCategoryViewModel";
import { ToggleButton } from "Views/Project/Commercial/IETables/IEGrid.Styles";
import { VariationViewModel } from "../VariationViewModel";

// Styling & Images

interface ITableProps {
    viewModel: VariationViewModel;
    itemModel: VariationCategoryViewModel;
    rowType: string;
    rowIndex: number;
    rowEnable(index: number): void;
    currentActiveCatIndex: number;
}

export const VariationCat: React.FunctionComponent<ITableProps> = (props) => {
    const { itemModel, rowType, rowIndex, rowEnable, currentActiveCatIndex, viewModel } = props;

    const handleName = () => {
        if (itemModel.isDefault && viewModel.model.variationNumber) {
            return viewModel.formattedVariationNumber;
        } else {
            return itemModel.displayName;
        }
    };

    return useObserver(() => (
        <>
            <li className={rowType} key={"cat_" + rowIndex}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <span style={{ color: `${currentActiveCatIndex === rowIndex ? "#0095DA" : ""}`, paddingLeft: "5px" }}>{handleName()}</span>
                    {currentActiveCatIndex === rowIndex ? (
                        <ToggleButton>
                            <span
                                className={"open"}
                                onClick={() => {
                                    rowEnable(-1);
                                }}
                            >
                                &minus;
                            </span>
                        </ToggleButton>
                    ) : (
                        <ToggleButton>
                            <span
                                className={"opcloseen"}
                                onClick={() => {
                                    rowEnable(rowIndex);
                                }}
                            >
                                &#43;
                            </span>
                        </ToggleButton>
                    )}
                </div>
                <div className="right"></div>
                <div className="right"></div>
                <div className="right"></div>
                <div className="right"></div>
                <div className="right"></div>
                <div className="right"></div>
                <div className="right"></div>
                <div className="right"></div>
            </li>
        </>
    ));
};
