import { ApiResult, FieldType, ViewModelBase, isEmptyOrWhitespace } from "@shoothill/core";
import { runInAction, observable, computed, action } from "mobx";

import { AppUrls } from "AppUrls";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { FormRequestDTO, MaterialStorageModel, MaterialStoragePhotosFile, UpsertMaterialStorageRequestDTO, UpsertMaterialStorageResponseDTO } from "./MaterialStorageModel";

export class MaterialStorageViewModel extends ViewModelBase<MaterialStorageModel> {
    public projectId: string = "";
    public constructor(projectId: string | null, date: any, formId: string | null) {
        super(new MaterialStorageModel(), false);
        this.setDecorators(MaterialStorageModel);
        this.model.projectId = projectId!;
        this.model.id = formId!;
        this.model.createdDate = date.toISOString();
        if (!isEmptyOrWhitespace(this.model.id) || this.model.id === null) {
            this.loadWithRelated();
        }
    }

    @observable public errorMessage: string = "";

    public server: ServerViewModel = new ServerViewModel();

    @observable
    public assignToUserList = observable<any>([]);

    @observable
    public assignToUserType: { id: string; displayName: string } | null = null;

    @computed
    public get isFormDisabled(): boolean {
        return this.model.hasId;
    }

    @action
    public reset = () => {
        this.model.reset();
        this.server.reset();
    };

    @action
    public handleAssignToUserId = (item: { id: string; displayName: string }) => {
        if (item && item.id !== undefined) {
            this.assignToUserType = item;
            this.setValue("assignToUserId", item.id);
        }
    };

    @action
    public setMaterialStoragePhotosFile = (file: MaterialStoragePhotosFile) => {
        this.model.materialStoragePhotos.push(file);
    };

    @action
    public deleteMaterialStoragePhotosFile = async (index: number): Promise<void> => {
        this.model.materialStoragePhotos[index].isDeleted = true;
    };

    public fileChange = async (event: any): Promise<void> => {
        if (event.target.files.length > 0) {
            let data: any = {
                fileName: event.target.files[0].name,
                formFile: event.target.files[0],
            };
            event.target.value = null;
            const apiResult = await this.fileUpload(data);
            if (apiResult && apiResult.wasSuccessful) {
                let fileToDisplay: MaterialStoragePhotosFile = {
                    id: null,
                    materialStorageId: null,
                    fileName: data.fileName,
                    photoURL: apiResult.payload,
                    isDeleted: false,
                    createdByUserId: "",
                    createdDate: "",
                };
                this.setMaterialStoragePhotosFile(fileToDisplay);
            }
        }
    };

    public fileUpload = async (data: any): Promise<ApiResult<any>> => {
        const formData = new FormData();
        formData.append("formFile", data.formFile);
        formData.append("fileName", data.fileName);
        const apiResult = await this.Post<any>(AppUrls.Server.File.UploadFile, formData);
        if (apiResult) {
            if (!apiResult.wasSuccessful) {
                console.log(apiResult.errors);
            }
        }
        return apiResult;
    };

    public DownloadFile = async (fileUrl: string, fileName: string): Promise<void> => {
        try {
            const apiResult = await this.Post<Blob>(AppUrls.Server.File.DownloadFile, fileUrl, undefined, { responseType: "blob" });
            const response = apiResult as any;
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
        } catch (exception) {
            console.error(exception);
            this.setIsErrored(true);
        }
    };

    // start loadWithRelated

    @action
    public loadWithRelated = async (): Promise<void> => {
        const request: FormRequestDTO = {
            date: this.model.createdDate,
            projectId: null,
            formId: this.model.id,
        };

        let apiResult = await this.Post<any>(AppUrls.Server.Projects.Construction.ProgrammeUpdates.MaterialStorage.GetMaterialStorageAndRelatedById, request);
        if (apiResult) {
            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.model.fromDto(apiResult.payload.materialStorage);
                    this.assignToUserList.replace(apiResult.payload.materialStorageUsers);
                    apiResult.payload.materialStoragePhotos.map((el: any) => this.setMaterialStoragePhotosFile(el));
                    this.handleAssignToUserId(this.assignToUserList.find((i) => i.id === this.model.assignToUserId)!);
                });
            } else {
                console.log(apiResult.errors);
            }
        }
    };

    // End loadWithRelated

    @action
    public upsert = async (e?: any): Promise<ApiResult<UpsertMaterialStorageRequestDTO>> => {
        e?.preventDefault();

        if (await this.isMyModelValid()) {
            const model: any = this.model.toDto();
            let materialStoragePhoto: MaterialStoragePhotosFile[] = [];
            this.model.materialStoragePhotos.forEach((materialStoragePhotos) => {
                const materialStorage: MaterialStoragePhotosFile = {
                    id: materialStoragePhotos.id,
                    materialStorageId: materialStoragePhotos.materialStorageId,
                    fileName: materialStoragePhotos.fileName,
                    photoURL: materialStoragePhotos.photoURL,
                    createdByUserId: materialStoragePhotos.createdByUserId,
                    isDeleted: materialStoragePhotos.isDeleted,
                    createdDate: materialStoragePhotos.createdDate,
                };
                materialStoragePhoto.push(materialStorage);
            });

            const request: UpsertMaterialStorageRequestDTO = {
                materialStorage: model,
                materialStoragePhotos: materialStoragePhoto,
            };

            let apiResult = await this.Post<UpsertMaterialStorageResponseDTO>(AppUrls.Server.Projects.Construction.ProgrammeUpdates.MaterialStorage.UpsertMaterialStorage, request);

            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.model.fromDto(apiResult.payload.materialStorage);
                    this.assignToUserList.replace(apiResult.payload.materialStorageUsers);
                    apiResult.payload.materialStoragePhotos.map((el: any) => this.setMaterialStoragePhotosFile(el));
                });
            }
            return apiResult;
        } else {
            this.errorMessage = "Form is not valid";
            return Promise.reject();
        }
    };

    // Validation Start

    @computed
    private get validateMaterialStorageNote() {
        const errorMessage = this.model.validateMaterialStorageNote;
        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateAssignToUserId() {
        const errorMessage = this.model.validateAssignToUserId;
        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    // End Validation

    private isMyModelValid = async (): Promise<boolean> => {
        let isValid = true;
        if ((await this.isModelValid()) === false) {
            isValid = false;
        }

        return isValid;
    };

    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (await this.isModelValid()) {
            this.errorMessage = "Form is valid";
        } else {
            this.errorMessage = "Form is not valid";
        }
    };

    public async isFieldValid(fieldName: keyof FieldType<MaterialStorageModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        if (fieldName) {
            switch (fieldName) {
                case "materialStorageNote": {
                    const result = this.validateMaterialStorageNote;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
                case "assignToUserId": {
                    const result = this.validateAssignToUserId;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
            }
        } else {
            errorMessage = "";
            isValid = true;
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
