import { ModelBase } from "@shoothill/core";
import { action, observable } from "mobx";

export class PaymentTypeModel extends ModelBase<PaymentTypeModel, PaymentTypeDTO> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Defaults and Constants

    public static readonly DEFAULT_ID = "";
    public static readonly DEFAULT_DISPLAYNAME = "";

    // #endregion Defaults and Constants

    // #region Observables

    @observable
    public id: string = PaymentTypeModel.DEFAULT_ID;

    @observable
    public displayName: string = PaymentTypeModel.DEFAULT_DISPLAYNAME;

    // #endregion Observables

    // #region Actions

    @action
    public reset(): void {
        this.id = PaymentTypeModel.DEFAULT_ID;
        this.displayName = PaymentTypeModel.DEFAULT_DISPLAYNAME;
    }

    @action
    public fromDto(dto: PaymentTypeDTO): void {
        this.id = dto.id;
        this.displayName = dto.name;
    }

    public toDto() {
        throw new Error("Method not implemented.");
    }

    // #endregion Actions

    // #region Helpers

    public static fromDtos(dtos: PaymentTypeDTO[]): PaymentTypeModel[] {
        const types: PaymentTypeModel[] = [];

        for (const dto of dtos) {
            const model = new PaymentTypeModel();

            model.fromDto(dto);
            types.push(model);
        }

        return types;
    }

    // #endregion Helpers
}

export interface PaymentTypeDTO {
    id: string;
    name: string;
}
