import { Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { AppUrls } from "AppUrls";
import { ClassNameMap } from "@material-ui/styles";

export const approvalMatchSection1 = (classes: ClassNameMap<"root" | "common" | "prev" | "heading" | "main" | "secondary" | "lists" | "nested">): any => {
    return (
        <div>
            <Link component={RouterLink} to={AppUrls.Client.Approval.List} className={classes.heading}>
                Approvals
            </Link>
            <Link component={RouterLink} to={AppUrls.Client.Approval.List}>
                <div className={classes.main}>
                    Needs my authority
                    <span className="triangle"></span>
                </div>
            </Link>
            <Link component={RouterLink} to={AppUrls.Client.Approval.MyAmends} className={classes.prev}>
                I need authority
            </Link>
        </div>
    );
};

export const approvalPurchaseMatchSection1 = (classes: ClassNameMap<"root" | "common" | "prev" | "heading" | "main" | "secondary" | "lists" | "nested">): any => {
    return (
        <div>
            <Link component={RouterLink} to={AppUrls.Client.Approval.List} className={classes.heading}>
                {/* <div className={classes.main}> */}
                Approvals
                {/* <span className="triangle"></span> */}
                {/* </div> */}
            </Link>
            <Link component={RouterLink} to={AppUrls.Client.Approval.List} className={classes.prev}>
                Needs my authority
            </Link>
            <Link component={RouterLink} to={AppUrls.Client.Approval.MyAmends}>
                <div className={classes.main}>
                    I need authority
                    <span className="triangle"></span>
                </div>
            </Link>
        </div>
    );
};
