import { ResetPasswordModel, ResetPasswordModelDTO } from "./ResetPasswordModel";
import { observable, runInAction } from "mobx";

import { ApiResult, isNullOrEmpty } from "@shoothill/core";
import { AppUrls } from "AppUrls";
import { ViewModelBase } from "@shoothill/core";
import { getUrlSearchParams } from "@shoothill/core";
import { FieldType } from "@shoothill/core";

export class ResetPasswordViewModel extends ViewModelBase<ResetPasswordModel> {
    @observable
    public tokenValid?: boolean;

    constructor() {
        super(new ResetPasswordModel());
        this.setDecorators(ResetPasswordModel);
    }

    public getTokenFromUrl = () => {
        const urlSearchParams = getUrlSearchParams();
        const forgotPasswordToken = urlSearchParams.get("forgottoken");
        if (forgotPasswordToken) {
            this.model.setValue("token", forgotPasswordToken);
            this.verifyForgotPasswordTokenAsync();
        } else {
            runInAction(() => {
                this.tokenValid = false;
            });
        }
    };

    public verifyForgotPasswordTokenAsync = async (): Promise<void> => {
        let apiResult = await this.Post<ResetPasswordModelDTO>(AppUrls.Server.Account.ResetPassword.VerifyToken, this.model);
        runInAction(() => {
            this.tokenValid = apiResult.wasSuccessful;

            if (this.tokenValid) {
                this.model.fromDto(apiResult.payload);
            }
        });
    };

    public resetPasswordAsync = async (): Promise<ApiResult<ResetPasswordModelDTO>> => {
        let apiResult = await this.Post<ResetPasswordModelDTO>(AppUrls.Server.Account.ResetPassword.Reset, this.model);
        return apiResult;
    };

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public async isFieldValid(fieldName: keyof FieldType<ResetPasswordModel>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        if (fieldName === "newPassword") {
            errorMessage = this.isNewPasswordValid;
            isValid = errorMessage === "";
        }

        if (fieldName === "confirmPassword") {
            errorMessage = this.isConfirmPasswordValid;
            isValid = errorMessage === "";
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public get isNewPasswordValid(): string {
        if (isNullOrEmpty(this.getValue("newPassword"))) return "New password is required";
        return "";
    }

    public get isConfirmPasswordValid(): string {
        if (this.getValue("newPassword") !== this.getValue("confirmPassword") && this.getValue("newPassword") !== "") return "Passwords must match";
        return "";
    }
}
