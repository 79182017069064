import { ApiResult, FieldType, ViewModelBase, isEmptyOrWhitespace, observable } from "@shoothill/core";
import { runInAction, action, computed } from "mobx";
import { DesignDataFormItemModel } from "./DesignDataFormItemModel";
import { DesignDataFileDTO, DesignDataFormFileModel } from "./DesignDataFormFileModel";
import { AppUrls } from "AppUrls";

export class DesignDataFormItemViewModel extends ViewModelBase<DesignDataFormItemModel> {
    constructor(item: DesignDataFormItemModel | null) {
        super(item ? item : new DesignDataFormItemModel());
        this.setDecorators(DesignDataFormItemModel);
    }

    @computed
    public get hasId(): boolean {
        return this.model.id !== null && this.model.id !== undefined && this.model.id !== "";
    }

    @action
    public reset = () => {
        this.model.reset();
    };

    private isMyModelValid = async (): Promise<boolean> => {
        let isValid = true;
        if ((await this.isModelValid()) === false) {
            isValid = false;
        }

        return isValid;
    };

    public async isFieldValid(fieldName: keyof FieldType<DesignDataFormItemModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        switch (fieldName) {
            case "title": {
                const result = this.validateTitle;

                errorMessage = result.errorMessage;
                isValid = result.isValid;
                break;
            }
            case "description": {
                const result = this.validateDescription;

                errorMessage = result.errorMessage;
                isValid = result.isValid;
                break;
            }
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    @computed
    private get validateTitle() {
        const errorMessage = this.model.validateTitle;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateDescription() {
        const errorMessage = this.model.validateDescription;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
