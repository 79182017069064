import { ModelBase } from "@shoothill/core";
import { OrderType } from "Globals/Models/Domain/OrderTypeEnum";
import { action, observable } from "mobx";

export class OrderTypeModel extends ModelBase<OrderTypeModel, OrderTypeDTO> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Defaults and Constants

    public static readonly DEFAULT_ID = "";
    public static readonly DEFAULT_DISPLAYNAME = "";
    public static readonly DEFAULT_TYPE = OrderType.Purchase;

    // #endregion Defaults and Constants

    // #region Observables

    @observable
    public id: string = OrderTypeModel.DEFAULT_ID;

    @observable
    public displayName: string = OrderTypeModel.DEFAULT_DISPLAYNAME;

    @observable
    public type: string = OrderTypeModel.DEFAULT_TYPE;

    // #endregion Observables

    // #region Actions

    @action
    public reset(): void {
        this.id = OrderTypeModel.DEFAULT_ID;
        this.displayName = OrderTypeModel.DEFAULT_DISPLAYNAME;
        this.type = OrderTypeModel.DEFAULT_TYPE;
    }

    @action
    public fromDto(dto: OrderTypeDTO): void {
        this.id = dto.id;
        this.displayName = dto.displayName;
        this.type = dto.orderType;
    }

    public toDto() {
        throw new Error("Method not implemented.");
    }

    // #endregion Actions

    // #region Helpers

    public static fromDtos(dtos: OrderTypeDTO[]): OrderTypeModel[] {
        const types: OrderTypeModel[] = [];

        for (const dto of dtos) {
            const model = new OrderTypeModel();

            model.fromDto(dto);
            types.push(model);
        }

        return types;
    }

    // #endregion Helpers
}

export interface OrderTypeDTO {
    id: string;
    displayName: string;
    orderType: string;
}
