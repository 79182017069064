import * as React from "react";
import styled from "styled-components";

import { BaseModal } from "Components/Modal/BaseModal";
import { CancelButton } from "Globals/Styles/Control/Buttons";
import { useObserver } from "mobx-react-lite";
import { EditContactModalDialogPaper } from "Views/Contacts/EditContactModal.styles";
import { PrimaryButton } from "Components/Buttons/Buttons";

interface IInvoiceDetailsPostModalProps {
    open?: boolean;
    onClose(): void;
    onSave(): any;
    title: string;
    confirmText: string;
    isLoading: boolean;
}

export const InvoiceDetailsPostModal: React.FunctionComponent<IInvoiceDetailsPostModalProps> = ({ open, onClose, onSave, title, confirmText, isLoading }) => {
    return useObserver(() => (
        <BaseModal
            open={open}
            onClose={onClose}
            title={title}
            PaperComponent={InvoiceDetailsPostModalDialogPaper}
            actions={
                <>
                    <PrimaryButton execute={onSave} color="secondary" autoFocus variant="contained" disabled={isLoading} displayName={confirmText} />
                    <CancelButton onClick={onClose}>Cancel</CancelButton>
                </>
            }
        >
            <p>The invoice value doesn't match the total value. Are you sure you want to proceed?</p>
        </BaseModal>
    ));
};

export const InvoiceDetailsPostModalDialogPaper = styled(EditContactModalDialogPaper)`
    width: 100% !important;

    .MuiDialogActions-root > button {
        max-width: 262px;
    }

    .MuiDialogContent-root {
        margin-bottom: 20px;
    }

    p {
        padding: 0px !important;
    }
`;
