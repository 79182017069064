import React, { useEffect } from "react";
import { FieldType, useObserver } from "@shoothill/core";
import { RequisitionsListViewModel } from "./RequisitionsListViewModel";
import { RequisitionsListFilterViewModel } from "./RequisitionsListFilterViewModel";
import { DarwinInput } from "Globals/Styles/Control/DarwinInput";
import { FilterRow, FilterWrapper } from "./RequisitionsList.styles";
import { MultiSelectView } from "Components/Select/MultiSelect";
import { ProjectCellHelpers } from "Globals/Models/Domain/ProjectCellEnum";
import { REFilterParamsModel } from "./REFilterParamsModel";
import { PrimaryButton } from "Components/Buttons/Buttons";

interface IRequisitionsListFilterViewProps {
    RequisitionsListViewModel: RequisitionsListViewModel;
    filtersViewModel: RequisitionsListFilterViewModel;
}

export const RequisitionsListFilterView: React.FunctionComponent<IRequisitionsListFilterViewProps> = (props: IRequisitionsListFilterViewProps) => {
    //useEffect below only gets run on initial render
    useEffect(() => {
        return () => {};
    }, []);

    const isInError = (fieldName: keyof FieldType<REFilterParamsModel>): boolean => {
        let isValid = props.filtersViewModel.getValid(fieldName);
        return !isValid;
    };

    const getError = (fieldName: keyof FieldType<REFilterParamsModel>): string => {
        let retVal: string = props.filtersViewModel.getError(fieldName);
        return retVal;
    };

    const renderPage = () => {
        return (
            <>
                <FilterWrapper style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                    <FilterRow>
                        <MultiSelectView
                            style={{ maxWidth: "200px", marginRight: "20px" }}
                            display="Category"
                            fieldName="categories"
                            values={props.filtersViewModel.model.categories}
                            getOptions={props.filtersViewModel.getCategoryOptions}
                            handleChange={props.RequisitionsListViewModel.handleCategoryChange}
                        />

                        <MultiSelectView
                            style={{ maxWidth: "200px", marginRight: "20px" }}
                            display="Subcategory"
                            fieldName="subCategories"
                            values={props.filtersViewModel.model.subCategories}
                            getOptions={props.filtersViewModel.getSubCategoryOptions}
                            handleChange={props.RequisitionsListViewModel.handleSubCategoryChange}
                        />

                        <MultiSelectView
                            style={{ maxWidth: "200px", marginRight: "20px" }}
                            display="Status"
                            fieldName="statuses"
                            values={props.filtersViewModel.model.statuses}
                            getOptions={props.filtersViewModel.getStatusOptions}
                            handleChange={props.RequisitionsListViewModel.handleStatusChange}
                        />
                    </FilterRow>
                    <FilterRow>
                        <MultiSelectView
                            style={{ maxWidth: "200px", marginRight: "20px" }}
                            display="Cell"
                            fieldName="cells"
                            values={props.filtersViewModel.model.cells}
                            getOptions={ProjectCellHelpers.getAllKeyValueSelectOptions}
                            handleChange={props.RequisitionsListViewModel.handleCellChange}
                        />

                        <MultiSelectView
                            style={{ maxWidth: "200px", marginRight: "20px" }}
                            display="Order Type"
                            fieldName="orderTypes"
                            values={props.filtersViewModel.model.orderTypes}
                            getOptions={props.filtersViewModel.getOrderTypeOptions}
                            handleChange={props.RequisitionsListViewModel.handleOrderTypeChange}
                        />
                        <MultiSelectView
                            style={{ maxWidth: "200px", marginRight: "20px" }}
                            display="Raised by"
                            fieldName="raisedBy"
                            values={props.filtersViewModel.model.raisedByIds}
                            getOptions={props.filtersViewModel.getRaisedByOptions}
                            handleChange={props.RequisitionsListViewModel.handleRaisedByChange}
                        />
                        <MultiSelectView
                            style={{ maxWidth: "200px", marginRight: "20px" }}
                            display="Project"
                            fieldName="projects"
                            values={props.filtersViewModel.model.projects}
                            getOptions={props.filtersViewModel.getProjectOptions}
                            handleChange={props.RequisitionsListViewModel.handleProjectChange}
                        />
                    </FilterRow>
                    <FilterRow>
                        <DarwinInput
                            type="text"
                            label="Search"
                            validateOnBlur={true}
                            viewModel={props.filtersViewModel}
                            fieldName="searchText"
                            shrink={true}
                            maxLength={128}
                            onChange={(e: any) => props.RequisitionsListViewModel.handleSearchChange(e.target.value)}
                        />
                        <div className="filter-button-container">
                            <PrimaryButton
                                displayName={"Clear"}
                                execute={props.RequisitionsListViewModel.clear}
                                fullWidth={false}
                                canExecute={!props.RequisitionsListViewModel.IsLoading}
                            />
                        </div>
                    </FilterRow>
                </FilterWrapper>
            </>
        );
    };

    return useObserver(() => renderPage());
};
