import { FieldType, ViewModelBase, isEmptyOrWhitespace } from "@shoothill/core";

import { RequisitionPOStatusEnum } from "Globals/Models";
import { formatDate, formatTime } from "Utils/Format";

export class ApprovalHistoryInvoiceItemViewModel extends ViewModelBase<any> {
    public id: string | null;
    public type: number;
    public updateBy: string;
    public updatedDate: string;
    public notes: string | null;
    public disputedStatusName: string | null;

    public constructor(id: string | null, type: number, updateBy: string, updatedDate: string, notes: string | null, disputedStatusName: string | null) {
        super();
        this.id = id;
        this.type = type;
        this.updateBy = updateBy;
        this.updatedDate = updatedDate;
        this.notes = notes;
        this.disputedStatusName = disputedStatusName;
    }

    public get canDisplayNotes() {
        return !isEmptyOrWhitespace(this.notes);
    }

    public get formattedDateTime() {
        return `${formatDate(this.updatedDate)} @ ${formatTime(this.updatedDate)}`;
    }

    public get formattedType() {
        switch (this.type) {
            case RequisitionPOStatusEnum.Draft:
            case RequisitionPOStatusEnum.SubmittedForApproval:
                return "Sent for approval";

            case RequisitionPOStatusEnum.Rejected:
                return "Rejected";

            case RequisitionPOStatusEnum.AmendRequired:
                return this.disputedStatusName;

            case RequisitionPOStatusEnum.Approved:
                return "Approved";

            default:
                return "Unknown";
        }
    }

    public async isFieldValid(fieldName: keyof FieldType, value: any): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
