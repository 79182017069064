import { Button, Box, TextField } from "@material-ui/core";
import { Cell, Grid } from "@shoothill/core";
import React, { useState } from "react";
import { useObserver } from "mobx-react-lite";
import styled from "styled-components";

import { BaseModal } from "Components/Modal/BaseModal";
import { SnackBar } from "Components/SnackBar/SnackBar";
import { CancelButton } from "Globals/Styles/Control/Buttons";
import { ReportModalStyle } from "Views/Project/Modals/Modal.Styles";
import { WeatherReportViewModel } from "./WeatherReportViewModel";

interface IProps {
    open?: boolean;
    onClose(reloadPage: boolean): void;
    projectId: string;
    weatherReportDate: Date;
}

export const WeatherReportView: React.FC<IProps> = (props) => {
    const { open, onClose } = props;
    const [viewModel] = useState(() => new WeatherReportViewModel(props.projectId, props.weatherReportDate));
    const COLUMNS8 = "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr";

    const onSave = () => {
        const promise = viewModel.upsertWeatherReport();

        promise.then((response) => {
            if (response.wasSuccessful) {
                onClose(true);
            }
        });
    };

    const onCancel = () => {
        onClose(false);
    };

    return useObserver(() => (
        <BaseModal
            open={open}
            onClose={onCancel}
            title={"Weather report"}
            PaperComponent={ReportModalStyle}
            actions={
                <React.Fragment>
                    <Button onClick={onSave} color="secondary" autoFocus variant="contained">
                        Add
                    </Button>
                    <CancelButton onClick={onCancel}>Cancel</CancelButton>
                </React.Fragment>
            }
        >
            <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8} mb="35px">
                <Cell ts={8} ds={8}>
                    <NotesText>
                        <TextField
                            id="note-textarea"
                            multiline
                            minRows={5}
                            value={viewModel.getValue("weatherReportNote")}
                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => viewModel.setValue("weatherReportNote", e.target.value)}
                            fullWidth
                            className="textarea-forminput"
                            label={"Note:"}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            placeholder={"Please add your note"}
                            disabled={false}
                            error={viewModel.getError("weatherReportNote") !== ""}
                            helperText={viewModel.getError("weatherReportNote")}
                            onBlur={() => viewModel.isFieldValid("weatherReportNote")}
                        />
                    </NotesText>
                </Cell>
            </Grid>
            <SnackBar
                messageText={viewModel.snackMessage}
                messageType={viewModel.snackType}
                active={viewModel.snackbarState}
                closeOption={() => viewModel.setSnackbarState(false)}
                autoHideDuration={5000}
            />
        </BaseModal>
    ));
};

const NotesText = styled(Box)`
    label {
        margin-bottom: 8px !important;
        font: 700 12px / 16px "Segoe UI" !important;
        letter-spacing: -0.18px !important;
        color: rgb(25, 25, 25) !important;
        position: static;
    }
    .MuiInputBase-root {
        margin-top: 0 !important;
    }
`;
