import { ModelBase } from "@shoothill/core";
import { action, observable } from "mobx";

export class NonConformanceTypesLookupModel extends ModelBase<NonConformanceTypesLookupModel, any> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Defaults and Constants

    public static readonly DEFAULT_ID = "";
    public static readonly DEFAULT_DISPLAYNAME = "";

    // #endregion Defaults and Constants

    // #region Observables

    @observable
    public id: string = NonConformanceTypesLookupModel.DEFAULT_ID;

    @observable
    public displayName: string = NonConformanceTypesLookupModel.DEFAULT_DISPLAYNAME;

    // #endregion Observables

    // #region Actions

    @action
    public reset(): void {
        this.id = NonConformanceTypesLookupModel.DEFAULT_ID;
        this.displayName = NonConformanceTypesLookupModel.DEFAULT_DISPLAYNAME;
    }

    @action
    public fromDto(dto: NonConformanceTypeDTO): void {
        this.id = dto.id;
        this.displayName = dto.displayName;
    }

    public toDto() {
        throw new Error("Method not implemented.");
    }

    // #endregion Actions

    // #region Helpers

    public static fromDtos(dtos: NonConformanceTypeDTO[]): NonConformanceTypesLookupModel[] {
        const types: NonConformanceTypesLookupModel[] = [];

        for (const dto of dtos) {
            const model = new NonConformanceTypesLookupModel();

            model.fromDto(dto);
            types.push(model);
        }

        return types;
    }

    // #endregion Helpers
}

export interface NonConformanceTypeDTO {
    id: string;
    displayName: string;
}
