import { ModelBase } from "@shoothill/core";
import { action, observable } from "mobx";

export class SubCategoryModel extends ModelBase<SubCategoryModel, SubCategoryDTO> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Defaults and Constants

    public static readonly DEFAULT_ID = "";
    public static readonly DEFAULT_DISPLAYNAME = "";
    public static readonly DEFAULT_CATEGORYID = "";

    // #endregion Defaults and Constants

    // #region Observables

    @observable
    public id: string = SubCategoryModel.DEFAULT_ID;

    @observable
    public displayName: string = SubCategoryModel.DEFAULT_DISPLAYNAME;

    @observable
    public categoryId: string = SubCategoryModel.DEFAULT_CATEGORYID;

    // #endregion Observables

    // #region Actions

    @action
    public reset(): void {
        this.id = SubCategoryModel.DEFAULT_ID;
        this.displayName = SubCategoryModel.DEFAULT_DISPLAYNAME;
        this.categoryId = SubCategoryModel.DEFAULT_CATEGORYID;
    }

    @action
    public fromDto(dto: SubCategoryDTO): void {
        this.id = dto.id;
        this.displayName = dto.label;
        this.categoryId = dto.parentId;
    }

    public toDto() {
        throw new Error("Method not implemented.");
    }

    // #endregion Actions

    // #region Helpers

    public static fromDtos(dtos: SubCategoryDTO[]): SubCategoryModel[] {
        const types: SubCategoryModel[] = [];

        for (const dto of dtos) {
            const model = new SubCategoryModel();

            model.fromDto(dto);
            types.push(model);
        }

        return types;
    }

    // #endregion Helpers
}

export interface SubCategoryDTO {
    id: string;
    label: string;
    parentId: string;
}
