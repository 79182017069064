import { ApiResult, FieldType, ViewModelBase, isEmptyOrWhitespace } from "@shoothill/core";
import { runInAction, observable, computed, action } from "mobx";
import {
    CustomerVisitsSiteInstructionsModel,
    CustomerVisitsSitePhotosFile,
    FormRequestDTO,
    UpsertCustomerVisitsSiteInstructionsRequestDTO,
    UpsertCustomerVisitsSiteInstructionsResponseDTO,
} from "./CustomerVisitsSiteInstructionsModel";
import { AppUrls } from "AppUrls";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";

export class CustomerVisitsSiteInstructionsViewModel extends ViewModelBase<CustomerVisitsSiteInstructionsModel> {
    public projectId: string = "";
    public constructor(projectId: string | null, date: any) {
        super(new CustomerVisitsSiteInstructionsModel(), false);
        this.setDecorators(CustomerVisitsSiteInstructionsModel);
        this.model.projectId = projectId!;
        this.model.createdDate = date.toISOString();
        isEmptyOrWhitespace(this.model.id) && this.loadWithRelated();
    }

    @observable public errorMessage: string = "";

    public server: ServerViewModel = new ServerViewModel();

    @computed
    public get isFormDisabled(): boolean {
        return this.model.hasId;
    }

    @action
    public reset = () => {
        this.model.reset();
        this.server.reset();
    };

    @action
    public setCustomerVisitsSiteFile = (file: CustomerVisitsSitePhotosFile) => {
        this.model.customerVisitsSitePhotos.push(file);
    };

    @action
    public deleteCustomerVisitsSiteFile = async (index: number): Promise<void> => {
        this.model.customerVisitsSitePhotos[index].isDeleted = true;
    };

    public fileChange = async (event: any): Promise<void> => {
        if (event.target.files.length > 0) {
            let data: any = {
                fileName: event.target.files[0].name,
                formFile: event.target.files[0],
            };
            event.target.value = null;
            const apiResult = await this.fileUpload(data);
            if (apiResult && apiResult.wasSuccessful) {
                let fileToDisplay: CustomerVisitsSitePhotosFile = {
                    id: null,
                    customerVisitsSiteId: null,
                    fileName: data.fileName,
                    photoURL: apiResult.payload,
                    isDeleted: false,
                    createdByUserId: "",
                    createdDate: "",
                };
                this.setCustomerVisitsSiteFile(fileToDisplay);
            }
        }
    };

    public fileUpload = async (data: any): Promise<ApiResult<any>> => {
        const formData = new FormData();
        formData.append("formFile", data.formFile);
        formData.append("fileName", data.fileName);
        const apiResult = await this.Post<any>(AppUrls.Server.File.UploadFile, formData);
        if (apiResult) {
            if (!apiResult.wasSuccessful) {
                console.log(apiResult.errors);
            }
        }
        return apiResult;
    };

    public DownloadFile = async (fileUrl: string, fileName: string): Promise<void> => {
        try {
            const apiResult = await this.Post<Blob>(AppUrls.Server.File.DownloadFile, fileUrl, undefined, { responseType: "blob" });
            const response = apiResult as any;
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
        } catch (exception) {
            console.error(exception);
            this.setIsErrored(true);
        }
    };

    // start loadWithRelated

    @action
    public loadWithRelated = async (): Promise<void> => {
        this.setIsLoading(true);
        const request: FormRequestDTO = {
            date: this.model.createdDate,
            projectId: this.model.projectId,
            formId: this.model.id,
        };
        let apiResult = await this.Post<any>(
            AppUrls.Server.Projects.Construction.ProgrammeUpdates.CustomerVisitsSiteInstructions.GetCustomerVisitsSiteInstructionsAndRelatedByProjectId,
            request,
        );
        if (apiResult) {
            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.model.fromDto(apiResult.payload.customerVisitsSiteInstructions);
                    apiResult.payload.customerVisitsSiteInstructionsPhotos.map((el: any) => this.setCustomerVisitsSiteFile(el));
                });
            } else {
                console.log(apiResult.errors);
            }
        }
    };

    // End loadWithRelated

    @action
    public upsert = async (e?: any): Promise<ApiResult<UpsertCustomerVisitsSiteInstructionsRequestDTO>> => {
        e?.preventDefault();

        if (await this.isMyModelValid()) {
            const model: any = this.model.toDto();
            let customerVisitsSitePhotos: CustomerVisitsSitePhotosFile[] = [];
            this.model.customerVisitsSitePhotos.forEach((customerVisitsSitePhoto) => {
                const customerVisitsSite: CustomerVisitsSitePhotosFile = {
                    id: customerVisitsSitePhoto.id,
                    customerVisitsSiteId: customerVisitsSitePhoto.customerVisitsSiteId,
                    fileName: customerVisitsSitePhoto.fileName,
                    photoURL: customerVisitsSitePhoto.photoURL,
                    createdByUserId: customerVisitsSitePhoto.createdByUserId,
                    isDeleted: customerVisitsSitePhoto.isDeleted,
                    createdDate: customerVisitsSitePhoto.createdDate,
                };
                customerVisitsSitePhotos.push(customerVisitsSite);
            });
            const request: UpsertCustomerVisitsSiteInstructionsRequestDTO = {
                customerVisitsSiteInstructions: model,
                customerVisitsSiteInstructionsPhotos: customerVisitsSitePhotos,
            };

            let apiResult = await this.Post<UpsertCustomerVisitsSiteInstructionsResponseDTO>(
                AppUrls.Server.Projects.Construction.ProgrammeUpdates.CustomerVisitsSiteInstructions.UpsertCustomerVisitsSiteInstructions,
                request,
            );

            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.model.fromDto(apiResult.payload.customerVisitsSiteInstructions);
                    apiResult.payload.customerVisitsSiteInstructionsPhotos.map((el: any) => this.setCustomerVisitsSiteFile(el));
                });
            }
            return apiResult;
        } else {
            this.errorMessage = "Form is not valid";
            return Promise.reject();
        }
    };

    // Validation Start

    @computed
    private get validateCustomerSiteNote() {
        const errorMessage = this.model.validateCustomerSiteNote;
        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    // End Validation

    private isMyModelValid = async (): Promise<boolean> => {
        let isValid = true;
        if ((await this.isModelValid()) === false) {
            isValid = false;
        }

        return isValid;
    };

    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (await this.isModelValid()) {
            this.errorMessage = "Form is valid";
        } else {
            this.errorMessage = "Form is not valid";
        }
    };

    public async isFieldValid(fieldName: keyof FieldType<CustomerVisitsSiteInstructionsModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        if (this.server.IsSubmitted) {
            switch (fieldName) {
                case "customerSiteNote": {
                    const result = this.validateCustomerSiteNote;
                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
            }
        } else {
            errorMessage = "";
            isValid = true;
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
