import React, { useEffect } from "react";
import { useObserver } from "@shoothill/core";
import { PurchaseOrderListViewModel } from "./PurchaseOrderListViewModel";
import { PurchaseOrderListFilterViewModel } from "./PurchaseOrderListFilterViewModel";
import { DarwinInput } from "Globals/Styles/Control/DarwinInput";
import { FilterRow, FilterWrapper } from "./PurchaseOrderList.styles";
import { MultiSelectView } from "Components/Select/MultiSelect";
import { ProjectCellHelpers } from "Globals/Models/Domain/ProjectCellEnum";
import { PrimaryButton } from "Components/Buttons/Buttons";

interface IPurchaseOrderListFilterViewProps {
    purchaseOrderListViewModel: PurchaseOrderListViewModel;
    filtersViewModel: PurchaseOrderListFilterViewModel;
}

export const PurchaseOrderListFilterView: React.FunctionComponent<IPurchaseOrderListFilterViewProps> = (props: IPurchaseOrderListFilterViewProps) => {
    //useEffect below only gets run on initial render
    useEffect(() => {
        return () => {};
    }, []);

    const renderPage = () => {
        return (
            <>
                <FilterWrapper style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                    <FilterRow>
                        <MultiSelectView
                            style={{ maxWidth: "200px", marginRight: "20px" }}
                            display="Category"
                            fieldName="categories"
                            values={props.filtersViewModel.model.categories}
                            getOptions={props.filtersViewModel.getCategoryOptions}
                            handleChange={props.purchaseOrderListViewModel.handleCategoryChange}
                        />

                        <MultiSelectView
                            style={{ maxWidth: "200px", marginRight: "20px" }}
                            display="Subcategory"
                            fieldName="subCategories"
                            values={props.filtersViewModel.model.subCategories}
                            getOptions={props.filtersViewModel.getSubCategoryOptions}
                            handleChange={props.purchaseOrderListViewModel.handleSubCategoryChange}
                        />

                        <MultiSelectView
                            style={{ maxWidth: "200px", marginRight: "20px" }}
                            display="Status"
                            fieldName="statuses"
                            values={props.filtersViewModel.model.statuses}
                            getOptions={props.filtersViewModel.getStatusOptions}
                            handleChange={props.purchaseOrderListViewModel.handleStatusChange}
                        />
                    </FilterRow>
                    <FilterRow>
                        <MultiSelectView
                            style={{ maxWidth: "200px", marginRight: "20px" }}
                            display="Cell"
                            fieldName="cells"
                            values={props.filtersViewModel.model.cells}
                            getOptions={ProjectCellHelpers.getAllKeyValueSelectOptions}
                            handleChange={props.purchaseOrderListViewModel.handleCellChange}
                        />

                        <MultiSelectView
                            style={{ maxWidth: "200px", marginRight: "20px" }}
                            display="Order Type"
                            fieldName="orderTypes"
                            values={props.filtersViewModel.model.orderTypes}
                            getOptions={props.filtersViewModel.getOrderTypeOptions}
                            handleChange={props.purchaseOrderListViewModel.handleOrderTypeChange}
                        />
                        <MultiSelectView
                            style={{ maxWidth: "200px", marginRight: "20px" }}
                            display="Raised by"
                            fieldName="raisedBy"
                            values={props.filtersViewModel.model.raisedByIds}
                            getOptions={props.filtersViewModel.getRaisedByOptions}
                            handleChange={props.purchaseOrderListViewModel.handleRaisedByChange}
                        />
                        <MultiSelectView
                            style={{ maxWidth: "200px", marginRight: "20px" }}
                            display="Project"
                            fieldName="projects"
                            values={props.filtersViewModel.model.projects}
                            getOptions={props.filtersViewModel.getProjectOptions}
                            handleChange={props.purchaseOrderListViewModel.handleProjectChange}
                        />
                        <MultiSelectView
                            style={{ maxWidth: "200px", marginRight: "20px" }}
                            display="Supplier"
                            fieldName="supplierIds"
                            values={props.filtersViewModel.model.supplierIds}
                            getOptions={props.filtersViewModel.getSupplierOptions}
                            handleChange={props.purchaseOrderListViewModel.handleSupplierChange}
                        />
                    </FilterRow>
                    <FilterRow>
                        <DarwinInput
                            type="text"
                            label="Search"
                            validateOnBlur={true}
                            viewModel={props.filtersViewModel}
                            fieldName="searchText"
                            shrink={true}
                            maxLength={128}
                            onChange={(e: any) => props.purchaseOrderListViewModel.handleSearchChange(e.target.value)}
                        />
                        <div className="filter-button-container">
                            <PrimaryButton
                                displayName={"Clear"}
                                execute={props.purchaseOrderListViewModel.clear}
                                fullWidth={false}
                                canExecute={!props.purchaseOrderListViewModel.IsLoading}
                            />
                        </div>
                    </FilterRow>
                </FilterWrapper>
            </>
        );
    };

    return useObserver(() => renderPage());
};
