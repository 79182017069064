import { ViewModelBase } from "@shoothill/core";
import { action, computed, observable, runInAction } from "mobx";

import { AppUrls } from "AppUrls";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { MaterialPriceDetailsHistoryItemViewModel } from "./MaterialPriceDetailsHistoryItemViewModel";

export class MaterialPriceDetailsHistoryViewModel extends ViewModelBase<any> {
    public server: ServerViewModel = new ServerViewModel();

    private materialId: string;
    private parentCloseAction: () => void;

    private materials = observable<MaterialPriceDetailsHistoryItemViewModel>([]);

    constructor(materialId: string, closeAction: () => void) {
        super({});

        this.materialId = materialId;
        this.parentCloseAction = closeAction;

        // Load material price details history.
        this.apiLoadPriceDetailsHistoryAsync();
    }

    // #region Filtering

    @computed
    public get filteredMaterials(): MaterialPriceDetailsHistoryItemViewModel[] {
        return this.materials.slice();
    }

    // #endregion Filtering

    // #region Actions

    @action
    public close = () => this.parentCloseAction();

    // #endregion Actions

    // #region Api Actions

    @action
    public apiLoadPriceDetailsHistoryAsync = async (): Promise<void> => {
        await this.server.query<any>(
            () => this.Get(AppUrls.Server.Stock.Material.GetMaterialPriceDetailsViewsByMaterialId.replace("{materialid}", this.materialId)),
            (result) => {
                runInAction(() => {
                    this.materials.replace(
                        result.map((dto: any) => {
                            const material = new MaterialPriceDetailsHistoryItemViewModel();

                            material.effectiveFromDate = dto.effectiveFromDate;
                            material.estimatedPrice = dto.estimatedPrice;
                            material.note = dto.note;
                            material.price = dto.price;
                            material.supplier = dto.supplier;
                            material.updatedByUser = dto.updatedByUser;
                            material.updatedDate = dto.updatedDate;

                            return material;
                        }),
                    );
                });
            },
            "Error whilst loading the material data",
        );

        if (this.server.HaveValidationMessage) {
            this.setSnackMessage(this.server.ValidationMessage);
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }
    };

    // #endregion Api Actions

    // #region Snack Bar

    public SNACKERROR = "error";

    @observable
    public snackbarState = false;

    @observable
    public snackType = "";

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public snackMessage = "";

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    // #endregion Snack Bar

    // #region Boilerplate

    isFieldValid(fieldName: string | number | symbol, value: any): Promise<boolean> {
        throw new Error("Method not implemented.");
    }
    beforeUpdate?(fieldName: string | number | symbol, value: any) {
        throw new Error("Method not implemented.");
    }
    afterUpdate?(fieldName: string | number | symbol, value: any): void {
        throw new Error("Method not implemented.");
    }

    // #endregion Boliderplate
}
