import React, { useEffect } from "react";
import { useObserver, useRouter } from "@shoothill/core";
import MaterialTable from "material-table";
import * as Defaults from "Globals/Defaults/TableOptions";
import { DarwinTablePageContent } from "Globals/Styles/AppStyling";
import { TickIcon } from "Globals/Views/IconSVGs/TickIcon";
import { PurchaseOrderTableModel } from "Views/Project/Commercial/OrdersAndRequisitions/PurchaseOrder.Model";
import { formatCurrencyFromPounds, formatDate } from "Utils/Format";
import { PatchedPagination } from "../../Components/Table/PatchedPagination";

interface IPurchaseOrderTableViewProps {
    poViewURL: string;
    data: PurchaseOrderTableModel[];
}

export const PurchaseOrderTableView: React.FunctionComponent<IPurchaseOrderTableViewProps> = (props: IPurchaseOrderTableViewProps) => {
    const { history } = useRouter();
    //useEffect below only gets run on initial render
    useEffect(() => {
        return () => {};
    }, []);

    const handleRowClick = (e: any, rowData: any) => {
        const url: string = props.poViewURL.replace(":poid", rowData.id);
        history.push(url);
    };

    const renderPage = () => {
        return (
            <DarwinTablePageContent>
                <MaterialTable
                    columns={[
                        {
                            title: "PO no.",
                            field: "poNumberFormatted",
                            width: "auto",
                        },
                        {
                            title: "Revision",
                            field: "revision",
                            width: "auto",
                        },
                        {
                            title: "Purchase order description",
                            field: "itemDescription",
                            width: "auto",
                        },
                        {
                            title: "Raised by",
                            field: "raisedBy",
                            width: "auto",
                        },
                        {
                            title: "PO Date",
                            field: "createdDate",
                            width: "auto",
                            type: "date",
                            render: (rowData: PurchaseOrderTableModel) => <>{formatDate(rowData.createdDate !== null ? rowData.createdDate.toString() : "")}</>,
                        },
                        {
                            title: "Start Date",
                            field: "dateFrom",
                            width: "auto",
                            type: "date",
                            render: (rowData: PurchaseOrderTableModel) => <>{formatDate(rowData.dateFrom !== null ? rowData.dateFrom.toString() : "")}</>,
                        },
                        {
                            title: "Completion Date",
                            field: "dateTo",
                            width: "auto",
                            type: "date",
                            render: (rowData: PurchaseOrderTableModel) => <>{formatDate(rowData.dateTo !== null ? rowData.dateTo.toString() : "")}</>,
                        },
                        {
                            title: "Supplier",
                            field: "supplierName",
                            width: "auto",
                        },
                        {
                            title: "Value",
                            field: "committedCost",
                            width: "auto",
                            render: (rowData: PurchaseOrderTableModel) => <div style={{ textAlign: "right" }}>{formatCurrencyFromPounds(rowData.committedCost)}</div>,
                        },
                        {
                            title: "Delivery required",
                            field: "deliveryRequired",
                            width: "auto",
                            render: (rowData: PurchaseOrderTableModel) => (
                                <div className="table-svg-container">{rowData.deliveryRequired === true ? <TickIcon colour="#eb8d00" /> : <></>}</div>
                            ),
                        },
                        {
                            title: "Query",
                            field: "query",
                            width: "auto",
                        },
                    ]}
                    components={{
                        Pagination: PatchedPagination,
                    }}
                    options={Defaults.GetDarwinTableOptionsNoSearch()}
                    data={props.data}
                    onRowClick={handleRowClick}
                    title=""
                />
            </DarwinTablePageContent>
        );
    };

    return useObserver(() => renderPage());
};
