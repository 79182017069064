import React, { Fragment, ReactNode, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { ColumnResizeMode, flexRender, getCoreRowModel, getSortedRowModel, SortingState, useReactTable, ColumnDef, getPaginationRowModel } from "@tanstack/react-table";
import { ButtonsWrapper, FilteringSectionBottom, FilteringSectionTop, ItemsPerPageWrapper, PaginationButton, PaginationWrapper, TableWrapper } from "./DarwinTableV2.styles";

import Arrow from "Content/TableSortArrow.svg";
import { Loader, generateID } from "@shoothill/core";

import ArrowStart from "Content/Pagination-start.png";
import ArrowPrevious from "Content/Pagination-Previous.png";
import ArrowNext from "Content/Pagination-Next.png";
import ArrowEnd from "Content/Pagination-end.png";
import { ButtonImg } from "./DarwinTableV2.styles";
import { MenuItem, Select } from "@material-ui/core";

export enum SortOrderDirection {
    ASC = "ASC",
    DESC = "DESC",
}

export enum PaginationDirection {
    NEXT = "NEXT",
    BACK = "BACK",
    START = "START",
    END = "END",
}

export interface IDarwinTableHeaderOptions {
    backgroundColour?: string;
    textColour?: string;
    fontFamily?: string[];
    fontSize?: string | number;
    borderTop?: string; // TODO should be the correct settings
    borderBottom?: string; // TODO should be the correct settings
}

export interface IDarwinTableRowOptions {
    backgroundColour?: string;
    textColour?: string;
    fontFamily?: string[];
    fontSize?: string | number;
    borderTop?: string; // TODO should be the correct settings
    borderBottom?: string; // TODO should be the correct settings
}

export interface IDarwinTable {
    // Data
    columns: ColumnDef<any, any>[];
    data: any[];

    // Styling
    showFooter?: boolean;
    showPagination?: boolean;
    tableHeaderColour?: string; // TODO Should use IDarwinTableHeaderOptions
    tableFilteringColour?: string; // TODO Should use IDarwinTableHeaderOptions

    // Pagination
    pageNumber?: number; // Current Page Number
    pageSize?: number; // Current Page size (if we have all the data)
    pageSizeOptions?: number[];

    totalPageCount?: number; // The total number of pages.
    totalRowCount?: number; // Total number of rows for this query

    initialSortColumn?: string;
    initialSortDirection?: SortOrderDirection;

    initialPage?: number; // TODO

    // Filtering
    showFiltering?: boolean;
    filteringElementsTopSection?: ReactNode;
    filteringElementsBottomSection?: ReactNode;

    // Sorting
    manualSorting?: boolean;

    // Operations
    onRowClick?: (rowId: string | number) => void;

    onSortChange?: (orderedColumnId: string, orderDirection: SortOrderDirection) => void;

    onChangePage?: (change: PaginationDirection) => void;
    onChangePageNumber?: (pageNumber: number) => void; //This is for manually entering a page number
    onChangeRowPerPage?: (numberRows: number) => void;

    isProcessing?: boolean;

    //clearFilteringCommand?: ICommand<any>;
}

/**
 * '@tanstack/react-table' component.
 * Copied from SharmansV2.
 */
export const DarwinTableV2: React.FC<IDarwinTable> = observer((props) => {
    const [columnResizeMode, setColumnResizeMode] = useState<ColumnResizeMode>("onChange");

    const [sorting, setSorting] = React.useState<SortingState>([]);
    // Internal state, so number can be updated without making a server call - that only happens on "Enter"
    const [paginationPageNumber, setPaginationPageNumber] = useState(1);

    let pageSizeOptions: number[] = [10, 20, 50, 100];
    if (props.pageSizeOptions !== undefined && props.pageSizeOptions !== null && props.pageSizeOptions.length > 0) {
        pageSizeOptions = props.pageSizeOptions;
    }

    let localRowCount: string = "0";

    if (props.data.length > 0 && props.pageNumber !== undefined) {
        let a: number = props.data.length * (props.pageNumber - 1) + 1;
        let b: number = props.data.length * props.pageNumber;

        if (props.pageNumber > 1) {
            a = (props.pageNumber - 1) * (props.pageSize !== undefined ? props.pageSize! : 0) + 1;
            b = (props.pageNumber - 1) * (props.pageSize !== undefined ? props.pageSize! : 0) + props.data.length;
        }

        localRowCount = a.toString() + "-" + b.toString();
    }

    useEffect(() => {
        if (sorting != undefined && sorting.length > 0 && props.onSortChange !== undefined) {
            let columnName = sorting[0].id;
            let sortDirection = sorting[0].desc === true ? SortOrderDirection.DESC : SortOrderDirection.ASC;
            props.onSortChange(columnName, sortDirection);
        }
    }, [sorting]);

    useEffect(() => {
        if (props.initialSortColumn !== undefined && props.initialSortDirection !== undefined) {
            setSorting([
                {
                    id: props.initialSortColumn,
                    desc: props.initialSortDirection === SortOrderDirection.ASC ? false : true,
                },
            ]);
        }
    }, []);

    useEffect(() => {
        setPaginationPageNumber(props.pageNumber ? props.pageNumber : 1);
    }, [props.pageNumber]);

    const onChangeRowPerPageEditSelect = (value: any) => {
        if (props.onChangeRowPerPage) {
            props.onChangeRowPerPage(Number(value));
        }
    };

    const table = useReactTable({
        data: props.data,
        columns: props.columns,
        columnResizeMode,
        state: {
            sorting,
        },
        initialState: {
            pagination: {
                pageSize: props.pageSize ? props.pageSize : pageSizeOptions[0],
            },
        },
        pageCount: props.totalPageCount ? props.totalPageCount : 0,
        manualSorting: props.manualSorting !== undefined ? props.manualSorting : true,
        manualPagination: true,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        enableSortingRemoval: false,
    });

    const onPageNumberChangeEnter = () => {
        if (props.onChangePageNumber) {
            const pageCount = Number(table.getPageCount());
            let newPageNumber = paginationPageNumber;

            if (paginationPageNumber > pageCount) {
                newPageNumber = pageCount;
            } else if (paginationPageNumber < 1) {
                newPageNumber = 1;
            }

            setPaginationPageNumber(newPageNumber);
            props.onChangePageNumber(newPageNumber);
        }
    };

    const Pagination = (table: any) => {
        return (
            <PaginationWrapper pb="15px">
                <ItemsPerPageWrapper>
                    <span className="flex items-center gap-1">
                        <div>Items per page: </div>
                    </span>
                    <Select value={props.pageSize} onChange={(event: any) => (onChangeRowPerPageEditSelect ? onChangeRowPerPageEditSelect(event.target.value) : {})}>
                        {pageSizeOptions?.map((item: number, index: number) => {
                            return (
                                <MenuItem key={index} value={item}>
                                    {item}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    <div>
                        {localRowCount} of {props.totalRowCount} items
                    </div>
                </ItemsPerPageWrapper>

                <ButtonsWrapper>
                    <PaginationButton
                        isStartOrEnd={props.pageNumber === 1}
                        onClick={() => (props.onChangePage != undefined && props.pageNumber !== 1 ? props.onChangePage(PaginationDirection.START) : null)}
                        disabled={!table.getCanNextPage()}
                    >
                        <ButtonImg src={ArrowStart} height="10px" isStartOrEnd={props.pageNumber === 1} />
                    </PaginationButton>
                    <PaginationButton
                        isStartOrEnd={props.pageNumber === 1}
                        onClick={() => (props.onChangePage != undefined && props.pageNumber !== 1 ? props.onChangePage(PaginationDirection.BACK) : null)}
                    >
                        <ButtonImg src={ArrowPrevious} height="10px" isStartOrEnd={props.pageNumber === 1} />
                    </PaginationButton>
                    {/* <NextPrevText
                        isStartOrEnd={props.pageNumber === 1}
                        onClick={() => (props.onChangePage != undefined && props.pageNumber !== 1 ? props.onChangePage(PaginationDirection.BACK) : null)}
                    >
                        Previous
                    </NextPrevText> */}
                    <input
                        className="PaginationInput"
                        value={paginationPageNumber === 0 ? "" : paginationPageNumber}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                onPageNumberChangeEnter();
                            }
                        }}
                        onChange={(e) => {
                            if (!isNaN(Number(e.target.value))) {
                                setPaginationPageNumber(Number(e.target.value));
                            }
                        }}
                        style={{ maxWidth: "80px", paddingLeft: "5px" }}
                    />
                    <div>of {table.getPageCount()}</div>
                    {/* <NextPrevText
                        isStartOrEnd={props.pageNumber === table.getPageCount()}
                        onClick={() => (props.onChangePage != undefined && props.pageNumber !== table.getPageCount() ? props.onChangePage(PaginationDirection.NEXT) : null)}
                    >
                        Next
                    </NextPrevText> */}
                    <PaginationButton
                        isStartOrEnd={props.pageNumber === table.getPageCount()}
                        onClick={() => (props.onChangePage != undefined && props.pageNumber !== table.getPageCount() ? props.onChangePage(PaginationDirection.NEXT) : null)}
                        disabled={!table.getCanNextPage()}
                    >
                        <ButtonImg src={ArrowNext} isStartOrEnd={props.pageNumber === table.getPageCount()} height="10px" />
                    </PaginationButton>
                    <PaginationButton
                        isStartOrEnd={props.pageNumber === table.getPageCount()}
                        onClick={() => (props.onChangePage != undefined && props.pageNumber !== table.getPageCount() ? props.onChangePage(PaginationDirection.END) : null)}
                        disabled={!table.getCanNextPage()}
                    >
                        <ButtonImg src={ArrowEnd} isStartOrEnd={props.pageNumber === table.getPageCount()} height="10px" />
                    </PaginationButton>
                </ButtonsWrapper>
            </PaginationWrapper>
        );
    };

    const FilteringTopSection = () => {
        return <FilteringSectionTop style={{ backgroundColor: "white" }}>{props.filteringElementsTopSection}</FilteringSectionTop>;
    };

    const FilteringBottomSection = () => {
        return (
            <FilteringSectionBottom style={{ backgroundColor: props.tableFilteringColour ? props.tableFilteringColour : "#EDEDED" }}>
                {props.filteringElementsBottomSection}
                {/* {props.clearFilteringCommand && <ClearFilteringButton clearFiltering={props.clearFilteringCommand} />} */}
            </FilteringSectionBottom>
        );
    };

    return (
        <TableWrapper>
            <div className="p-2" style={{ width: "100%" }}>
                {props.filteringElementsTopSection && FilteringTopSection()}
                {props.filteringElementsBottomSection && FilteringBottomSection()}

                {props.isProcessing !== undefined && props.isProcessing === true ? (
                    <Loader />
                ) : (
                    <>
                        <table
                            {...{
                                key: generateID(),
                                style: {
                                    width: table.getCenterTotalSize(),
                                },
                            }}
                        >
                            <thead style={{ backgroundColor: props.tableHeaderColour ? props.tableHeaderColour : "#D4D4D4" }} className="t-head">
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map((header) => (
                                            <Fragment key={generateID()}>
                                                <th
                                                    {...{
                                                        key: header.id,
                                                        colSpan: header.colSpan,
                                                        style: {
                                                            width: header.getSize(),
                                                        },
                                                        onClick: header.column.getToggleSortingHandler(),
                                                    }}
                                                >
                                                    {header.isPlaceholder ? null : (
                                                        <div
                                                            {...{
                                                                key: generateID(),
                                                                className: header.column.getCanSort() ? "cursor-pointer select-none" : "",
                                                                onClick: header.column.getToggleSortingHandler(),
                                                            }}
                                                        >
                                                            <div className="title-wrapper" key={generateID()}>
                                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                                                <div className="arrow-box" key={generateID()}>
                                                                    {{
                                                                        // asc: " 🔼",
                                                                        // desc: " 🔽",
                                                                        asc: <img key={generateID()} src={Arrow} />,
                                                                        desc: <img key={generateID()} src={Arrow} style={{ transform: "rotate(180deg)" }} />,
                                                                    }[header.column.getIsSorted() as string] ?? null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div
                                                        {...{
                                                            key: generateID(),
                                                            onMouseDown: header.getResizeHandler(),
                                                            onTouchStart: header.getResizeHandler(),
                                                            className: `resizer ${header.column.getIsResizing() ? "isResizing" : ""}`,
                                                            style: {
                                                                transform:
                                                                    columnResizeMode === "onEnd" && header.column.getIsResizing()
                                                                        ? `translateX(${table.getState().columnSizingInfo.deltaOffset}px)`
                                                                        : "",
                                                            },
                                                        }}
                                                    />
                                                </th>
                                            </Fragment>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody>
                                {table.getRowModel().rows.map((row) => (
                                    <tr key={row.id} onClick={() => (props.onRowClick !== undefined ? props.onRowClick(row.original.id) : null)}>
                                        {row.getVisibleCells().map((cell) => (
                                            <td key={cell.id}>
                                                <div>{flexRender(cell.column.columnDef.cell, cell.getContext())}</div>
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                            <tfoot hidden={props.showFooter === undefined || props.showFooter === false}>
                                {table.getFooterGroups().map((footerGroup) => (
                                    <tr key={footerGroup.id}>
                                        {footerGroup.headers.map((header) => (
                                            <th key={header.id}>{header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}</th>
                                        ))}
                                    </tr>
                                ))}
                            </tfoot>
                        </table>
                        {/*   <div className="h-4" /> */}
                        {props.showPagination && Pagination(table)}
                    </>
                )}
            </div>
        </TableWrapper>
    );
});
