import { ModelBase } from "@shoothill/core";
import { action, observable, computed } from "mobx";
import { DilapidationFormItemModel, DilapidationReportItemsDTO } from "./DilapidationFormItemModel";

export class DilapidationFormModel extends ModelBase<DilapidationFormModel> {
    public static readonly DEFAULT_ID = "";
    public static readonly DEFAULT_PROJECT_ID = "";
    public static readonly DEFAULT_DILAPIDATION_REPORT_TYPE_ID = "";
    public static readonly DEFAULT_AUTHORISATION_SIGNATURE_URL = null;
    public static readonly DEFAULT_AUTHORISATION_SIGNATURE_DATE = "";
    public static readonly DEFAULT_LAST_UPDATED_DATE = null;
    public static readonly DEFAULT_CREATED_BY_USER_ID = null;
    public static readonly DEFAULT_LAST_UPDATED_BY_USER_ID = null;
    public static readonly DEFAULT_COMPLETED_DATE = null;
    public static readonly DEFAULT_COMPLETED_BY_USER_ID = null;
    public static readonly DEFAULT_IS_DELETED = false;
    public static readonly DEFAULT_ROW_VERSION = null;
    public static readonly DEFAULT_DILAPIDATION_REPORT_ITEMS = [];
    public static readonly DEFAULT_COMPLETED_BY_USER_NAME = null;
    public static readonly DEFAULT_CREATED_DATE = null;

    @observable
    public id: string | null = DilapidationFormModel.DEFAULT_ID;
    @observable
    public projectId: string = DilapidationFormModel.DEFAULT_PROJECT_ID;
    @observable
    public dilapidationReportTypeId: string | null = DilapidationFormModel.DEFAULT_DILAPIDATION_REPORT_TYPE_ID;
    @observable
    public authorisationSignatureURL: string | null = DilapidationFormModel.DEFAULT_AUTHORISATION_SIGNATURE_URL;
    @observable
    public authorisationSignatureDate: string | null = DilapidationFormModel.DEFAULT_AUTHORISATION_SIGNATURE_DATE;
    @observable
    public createdByUserId: string | null = DilapidationFormModel.DEFAULT_CREATED_BY_USER_ID;
    @observable
    public lastUpdatedDate: string | null = DilapidationFormModel.DEFAULT_LAST_UPDATED_DATE;
    @observable
    public lastUpdatedByUserId: string | null = DilapidationFormModel.DEFAULT_LAST_UPDATED_BY_USER_ID;
    @observable
    public completedDate: string | null = DilapidationFormModel.DEFAULT_COMPLETED_DATE;
    @observable
    public completedByUserId: string | null = DilapidationFormModel.DEFAULT_COMPLETED_BY_USER_ID;
    @observable
    public isDeleted: boolean = DilapidationFormModel.DEFAULT_IS_DELETED;
    @observable
    public rowVersion: string | null = DilapidationFormModel.DEFAULT_ROW_VERSION;
    @observable
    public dilapidationReportItems: DilapidationFormItemModel[] = DilapidationFormModel.DEFAULT_DILAPIDATION_REPORT_ITEMS;

    @observable
    public completedByUserName: string | null = DilapidationFormModel.DEFAULT_COMPLETED_BY_USER_NAME;

    @observable
    public createdDate: Date | null = DilapidationFormModel.DEFAULT_CREATED_DATE;

    @action
    public reset = () => {
        this.id = DilapidationFormModel.DEFAULT_ID;
        this.projectId = DilapidationFormModel.DEFAULT_PROJECT_ID;
        this.dilapidationReportTypeId = DilapidationFormModel.DEFAULT_DILAPIDATION_REPORT_TYPE_ID;
        this.authorisationSignatureURL = DilapidationFormModel.DEFAULT_AUTHORISATION_SIGNATURE_URL;
        this.authorisationSignatureDate = DilapidationFormModel.DEFAULT_AUTHORISATION_SIGNATURE_DATE;
        this.lastUpdatedDate = DilapidationFormModel.DEFAULT_LAST_UPDATED_DATE;
        this.lastUpdatedByUserId = DilapidationFormModel.DEFAULT_LAST_UPDATED_BY_USER_ID;
        this.completedDate = DilapidationFormModel.DEFAULT_COMPLETED_DATE;
        this.completedByUserId = DilapidationFormModel.DEFAULT_COMPLETED_BY_USER_ID;

        this.createdByUserId = DilapidationFormModel.DEFAULT_CREATED_BY_USER_ID;
        this.createdDate = DilapidationFormModel.DEFAULT_CREATED_DATE;
        this.isDeleted = DilapidationFormModel.DEFAULT_IS_DELETED;
        this.rowVersion = DilapidationFormModel.DEFAULT_ROW_VERSION;

        this.dilapidationReportItems.length = 0;
    };

    @action
    public fromDto(dto: DilapidationAndRelatedResponseDTO): void {
        const dilapidationDto = dto.dilapidation;

        for (let key in dilapidationDto) {
            if (dilapidationDto.hasOwnProperty(key)) {
                if (key === "dilapidationReportItems") {
                    // do nothing
                } else if (this[key] instanceof Date) {
                    this[key] = new Date(dilapidationDto[key]);
                } else {
                    this[key] = dilapidationDto[key];
                }
            }
        }

        // Manually process the child array otherwise we will end up with an array of dtos being stored in the array of models.
        let processedItems: DilapidationFormItemModel[] = [];

        for (const item of dto.dilapidationReportItems) {
            const itemToAdd = new DilapidationFormItemModel();
            itemToAdd.fromDto(item);
            processedItems.push(itemToAdd);
        }

        this.dilapidationReportItems = [...this.dilapidationReportItems, ...processedItems];
    }

    public toDto() {
        const DilapidationFormModel: DilapidationDTO = {
            id: this.id,
            projectId: this.projectId,
            dilapidationReportTypeId: this.dilapidationReportTypeId,
            authorisationSignatureURL: this.authorisationSignatureURL,
            authorisationSignatureDate: this.authorisationSignatureDate,
            completedByUserId: this.completedByUserId,
            lastUpdatedDate: this.lastUpdatedDate,
            lastUpdatedByUserId: this.lastUpdatedByUserId,
            completedDate: this.completedDate,
            isDeleted: this.isDeleted,
            rowVersion: this.rowVersion,
            createdByUserId: this.createdByUserId,
            completedByUserName: null,
            createdDate: null,
        };

        return DilapidationFormModel;
    }

    @action
    public fromRelatedDto(dto: DilapidationRelatedResultResponseDTO): void {}

    @computed
    public get validateDilapidationReportTypeId(): string {
        return this.dilapidationReportTypeId === DilapidationFormModel.DEFAULT_DILAPIDATION_REPORT_TYPE_ID ? "Please provide a report type" : "";
    }

    @computed
    public get validateAuthorisationSignatureURL(): string {
        return this.authorisationSignatureURL === DilapidationFormModel.DEFAULT_AUTHORISATION_SIGNATURE_URL ? "Please provide a signature" : "";
    }
}

export interface DilapidationReportTypesDTO {
    id: string;
    displayName: string;
    type: DilapidationReportTypeEnum;
    ordinal: number;
    isDeleted: boolean;
}

export enum DilapidationReportTypeEnum {
    PlantAndHire = 10,
    SiteEnd = 20,
    SiteStart = 30,
}

export interface DilapidationDTO {
    id: string | null;
    projectId: string;
    dilapidationReportTypeId: string | null;
    authorisationSignatureURL: string | null;
    authorisationSignatureDate: string | null;
    createdByUserId: string | null;
    lastUpdatedDate: string | null;
    lastUpdatedByUserId: string | null;
    completedDate: string | null;
    completedByUserId: string | null;
    isDeleted: boolean;
    rowVersion: string | null;
    completedByUserName: string | null;
    createdDate: Date | null;
}

export interface DilapidationPhotosFile {
    dilapidationReportItemId: string | null;
    fileName: string;
    photoUrl: string;
    createdByUserId: string;
    isDeleted: boolean;
}

export interface UpsertDilapidationAndRelatedRequestDTO {
    dilapidation: DilapidationDTO;
    dilapidationReportItems: DilapidationReportItemsDTO[];
    //dilapidationReportItemPhotos: DilapidationReportItemPhotosDTO[];
}

export interface DilapidationAndRelatedResponseDTO extends DilapidationRelatedResultResponseDTO {
    dilapidation: DilapidationDTO;
    dilapidationReportItems: DilapidationReportItemsDTO[];
}

export interface DilapidationProjectDetailsDTO {
    projectReference: string;
    projectName: string;
}

export interface DilapidationRelatedResultResponseDTO {
    dilapidationTypes: DilapidationReportTypesDTO[];
    dilapidationProjectDetails: DilapidationProjectDetailsDTO;
}
