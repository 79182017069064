import { FormControlLabel } from "@material-ui/core";
import { Loader, useRouter } from "@shoothill/core";
import { observer } from "mobx-react-lite";
import React from "react";
import Vimeo from "@u-wave/react-vimeo";
import styled from "styled-components";

import { SHBox } from "../../../../Components/Box";
import { DefaultButton, PrimaryButton } from "../../../../Components/Buttons/Buttons";
import { CustomRadio } from "../../../Project/CustomComponents";
import { RadioGroup } from "../../../../Globals/Styles/AdminStyling";
import { RamsViewModel } from "./RamsViewModel";
import { DashedDivider } from "../../../PurchaseOrder/Form/Views/Dividers";
import { TopAccessHeaderView } from "../Common/TopAccessHeader";
import { DarwinInput } from "../../../../Globals/Styles/Control/DarwinInput";
import { InductionViewModel } from "Views/Induction/InductionViewModel";
import { DarwinSelect } from "Components/AutoComplete/DarwinSelect";

const Wrapper = styled(SHBox)`
    .Mui-error.MuiFormHelperText-filled {
        display: none;
    }
`;

const YouTubeWrapper = styled.div`
    .videoContainer {
        position: relative;
        max-width: 600px;
        height: 0;
        padding-bottom: 56.25%;
        overflow: hidden;

        // Under 600px, video fills the available width.
        @media (max-width: 600px) {
            width: 100%;
        }
    }

    .videoContainer iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    p {
        line-height: 1.25;
        max-width: 600px;
        padding-bottom: 1rem;
        padding-top: 1rem;
        text-align: center;

        // Under 600px, text fills the available width.
        @media (max-width: 600px) {
            width: 100%;
        }
    }
`;

interface Props {
    lastName: string;
    lastThreeDigits: string;
    projectId: string;
    setIsLoading: (val: boolean) => void;
    parentViewModel: InductionViewModel;
}

interface RouterMatchProps {
    projectId: string;
}

export const RamsView: React.FC<Props> = observer((props: Props) => {
    const { match } = useRouter();
    const { projectId } = match.params as RouterMatchProps;
    const [viewModel] = React.useState(() => new RamsViewModel(projectId));

    React.useEffect(() => {
        viewModel.lastName = props.lastName;
        viewModel.lastThreeDigits = props.lastThreeDigits;
    }, []);

    const handleSignIn = () => {
        props.setIsLoading(true);
        viewModel.signIn().finally(() => props.setIsLoading(false));
    };

    const handleGoBack = () => {
        props.setIsLoading(true);
        viewModel.goBack();
        setTimeout(() => {
            props.setIsLoading(false);
        }, 100);
    };

    const getOptionLabel = (option: any | null | undefined) => {
        if (option) {
            if (option.displayName) {
                return option.displayName;
            }
        }

        return "";
    };

    const youTubePlayerOptions = {
        playerVars: {
            autoplay: 0,
            controls: 0,
        },
    };

    if (props.parentViewModel.isStageLoading) {
        return <Loader />;
    }

    return (
        <SHBox flexBox justifyContent={"center"}>
            <SHBox grid dc={"1fr"} mc={"1fr"} pl={3} pr={3} maxWidth={"800px"}>
                <TopAccessHeaderView />

                {viewModel.canDisplaySiteVideo && (
                    <React.Fragment>
                        <YouTubeWrapper>
                            <Vimeo className={"videoContainer"} video={viewModel.siteVideoLink} onEnd={viewModel.setSiteVideoFinished} onError={viewModel.setSiteVideoFinished} />
                            <p>The video is a MUST WATCH. Once finished, you will be asked some final statements to confirm and accept.</p>
                        </YouTubeWrapper>
                    </React.Fragment>
                )}

                {viewModel.canDisplayRAMSQuestions && (
                    <React.Fragment>
                        {viewModel.canDisplayDistance && (
                            <SHBox grid dc={"1fr 75px"}>
                                <SHBox>
                                    <p>Please enter the distance travelled to this site today (miles):</p>
                                    <span style={{ color: "red" }}>{viewModel.getError("distanceTravelled")}</span>
                                </SHBox>
                                <Wrapper>
                                    <DarwinInput<RamsViewModel>
                                        shrink={true}
                                        type={"number"}
                                        maxLength={3}
                                        autoComplete={"off"}
                                        viewModel={viewModel}
                                        fieldName="distanceTravelled"
                                        placeholder={""}
                                    />
                                </Wrapper>
                            </SHBox>
                        )}

                        <SHBox grid dc={"1fr"} mc={"1fr"} justifyItems={"center"} alignContent={"flex-start"}>
                            <SHBox textAlign={"center"}>
                                <p style={{ fontSize: "18px", fontWeight: 600 }}>Daily Declaration</p>
                                <p>Before completing your sign-in, please confirm:</p>
                            </SHBox>
                        </SHBox>

                        {viewModel.canDisplayRiskAssessment && (
                            <React.Fragment>
                                <SHBox grid dc={"2fr 1fr"} mc={"2fr 1fr"} alignItems={"center"}>
                                    <SHBox>
                                        <p>
                                            That you have fully read, understood and will adhere to the Risk Assessment regarding the hazards and mitigation strategies for the
                                            tasks you are undertaking.
                                        </p>
                                        <span style={{ color: "red" }}>{viewModel.getError("q1")}</span>
                                    </SHBox>
                                    <RadioGroup
                                        row
                                        name={"radio-" + "" + "-group"}
                                        value={viewModel.model.q1}
                                        onChange={(e: any) => viewModel.setRiskAssessmentConfirmed(e.target.value)}
                                    >
                                        <FormControlLabel value="true" control={<CustomRadio checked={viewModel.model.q1 === "true"} />} label="Yes" />
                                        <FormControlLabel value="false" control={<CustomRadio checked={viewModel.model.q1 === "false"} />} label="No" />
                                    </RadioGroup>
                                </SHBox>
                                <DashedDivider />
                            </React.Fragment>
                        )}

                        {viewModel.canDisplayWorkinigPractices && (
                            <React.Fragment>
                                <SHBox grid dc={"2fr 1fr"} mc={"2fr 1fr"} alignItems={"center"}>
                                    <SHBox>
                                        <p>
                                            That you have fully read, understood and will adhere to the Method Statement detailing the working practices for the tasks that you are
                                            undertaking.
                                        </p>
                                        <span style={{ color: "red" }}>{viewModel.getError("q2")}</span>
                                    </SHBox>
                                    <RadioGroup
                                        row
                                        name={"radio-" + "" + "-group"}
                                        value={viewModel.model.q2}
                                        onChange={(e: any) => viewModel.setWorkingPracticesConfirmed(e.target.value)}
                                    >
                                        <FormControlLabel value="true" control={<CustomRadio checked={viewModel.model.q2 === "true"} />} label="Yes" />
                                        <FormControlLabel value="false" control={<CustomRadio checked={viewModel.model.q2 === "false"} />} label="No" />
                                    </RadioGroup>
                                </SHBox>
                                <DashedDivider />
                            </React.Fragment>
                        )}

                        {viewModel.canDisplayToolsAndEquipment && (
                            <React.Fragment>
                                <SHBox grid dc={"2fr 1fr"} mc={"2fr 1fr"} alignItems={"center"}>
                                    <SHBox>
                                        <p>
                                            That you have the correct tools and equipment, in good working order and free of defect or fault, required to undertake your tasks
                                            safely.
                                        </p>
                                        <span style={{ color: "red" }}>{viewModel.getError("q3")}</span>
                                    </SHBox>
                                    <RadioGroup
                                        row
                                        name={"radio-" + "" + "-group"}
                                        value={viewModel.model.q3}
                                        onChange={(e: any) => viewModel.setToolsAndEquipmentConfirmed(e.target.value)}
                                    >
                                        <FormControlLabel value="true" control={<CustomRadio checked={viewModel.model.q3 === "true"} />} label="Yes" />
                                        <FormControlLabel value="false" control={<CustomRadio checked={viewModel.model.q3 === "false"} />} label="No" />
                                    </RadioGroup>
                                </SHBox>
                                <DashedDivider />
                            </React.Fragment>
                        )}

                        {viewModel.canDisplayPersonalProtectiveEquipment && (
                            <React.Fragment>
                                <SHBox grid dc={"2fr 1fr"} mc={"2fr 1fr"} alignItems={"center"}>
                                    <SHBox>
                                        <p>
                                            That you have the correct Personal Protective Equipment, in good working order and free of defect or fault, required to undertake your
                                            tasks safely.
                                        </p>
                                        <span style={{ color: "red" }}>{viewModel.getError("q4")}</span>
                                    </SHBox>
                                    <RadioGroup
                                        row
                                        name={"radio-" + "" + "-group"}
                                        value={viewModel.model.q4}
                                        onChange={(e: any) => viewModel.setPersonalProtectiveEquipmentConfirmed(e.target.value)}
                                    >
                                        <FormControlLabel value="true" control={<CustomRadio checked={viewModel.model.q4 === "true"} />} label="Yes" />
                                        <FormControlLabel value="false" control={<CustomRadio checked={viewModel.model.q4 === "false"} />} label="No" />
                                    </RadioGroup>
                                </SHBox>
                                <DashedDivider />
                            </React.Fragment>
                        )}

                        {viewModel.canDisplayRams && (
                            <React.Fragment>
                                <SHBox grid dc={"2fr 1fr"} mc={"2fr 1fr"} alignItems={"baseline"}>
                                    <DarwinSelect
                                        canExecute={viewModel.canSetRams}
                                        displayName="Please select the RAMS you have agreed to"
                                        execute={viewModel.setRams}
                                        fullWidth={true}
                                        getOptionLabel={(option: any) => getOptionLabel(option)}
                                        onBlur={() => viewModel.isFieldValid("ramsId")}
                                        options={viewModel.ramSs}
                                        placeholder="Please select"
                                        validationMessage={viewModel.getError("ramsId")}
                                        value={viewModel.rams}
                                    />
                                    {viewModel.canDisplayRamsDownload && (
                                        <PrimaryButton
                                            displayName={"Download & Review"}
                                            execute={viewModel.getRamsDocumentByRAMSId}
                                            fullWidth={true}
                                            canExecute={!viewModel.server.IsBusy}
                                        />
                                    )}
                                </SHBox>
                                {viewModel.canDisplayRamsReviewConfirmation && (
                                    <SHBox grid dc={"2fr 1fr"} mc={"2fr 1fr"} alignItems={"center"}>
                                        <SHBox>
                                            <p>Please confirm that you agree you have reviewed the correct RAMS for the day.</p>
                                            <span style={{ color: "red" }}>{viewModel.getError("ramsReviewConfirmed")}</span>
                                        </SHBox>
                                        <RadioGroup
                                            row
                                            name={"radio-" + "" + "-group"}
                                            value={viewModel.model.ramsReviewConfirmed}
                                            onChange={(e: any) => viewModel.setRamsReviewConfirmed(e.target.value)}
                                        >
                                            <FormControlLabel value="true" control={<CustomRadio checked={viewModel.model.ramsReviewConfirmed === "true"} />} label="Yes" />
                                            <FormControlLabel value="false" control={<CustomRadio checked={viewModel.model.ramsReviewConfirmed === "false"} />} label="No" />
                                        </RadioGroup>
                                    </SHBox>
                                )}
                            </React.Fragment>
                        )}

                        <SHBox>
                            <SHBox pb={1}>
                                <DefaultButton
                                    displayName="Sign in"
                                    execute={handleSignIn}
                                    fullWidth={true}
                                    canExecute={true}
                                    style={{ backgroundColor: viewModel.server?.IsBusy ? "gray" : "" }}
                                />
                            </SHBox>
                            <SHBox pb={"30px"}>
                                <PrimaryButton displayName={"Go back"} execute={handleGoBack} fullWidth={true} canExecute={true} />
                            </SHBox>
                        </SHBox>
                    </React.Fragment>
                )}
            </SHBox>
        </SHBox>
    );
});
