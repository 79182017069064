import { ModelBase, isEmptyOrWhitespace } from "@shoothill/core";
import { computed, observable } from "mobx";

export class ScaffoldingListItemModel extends ModelBase<ScaffoldingListItemModel> {
    id: string = "";
    projectId: string = "";

    @observable
    scaffoldInspectorName: string = "";
    @observable
    numberOfScaffolds: number = 0;
    @observable
    createdByUserName: string = "";
    @observable
    createdDate: string = "";
    @observable
    lastUpdatedDate: string = "";
    @observable
    lastUpdatedByName: string = "";

    constructor() {
        super();
    }

    @computed
    public get updatedByName() {
        return !isEmptyOrWhitespace(this.lastUpdatedByName) ? this.lastUpdatedByName : this.createdByUserName;
    }

    @computed
    public get updatedDate() {
        return !isEmptyOrWhitespace(this.lastUpdatedDate) ? this.lastUpdatedDate : this.createdDate;
    }

    fromDto(dto: ScaffoldInspectionListItemDTO): void {
        this.id = dto.id;
        this.projectId = dto.projectId;

        this.scaffoldInspectorName = dto.scaffoldInspectorName;
        this.numberOfScaffolds = dto.numberOfScaffolds;

        this.createdByUserName = dto.createdByUserName;
        this.createdDate = dto.createdDate;
        this.lastUpdatedByName = dto.lastUpdatedByName;
        this.lastUpdatedDate = dto.lastUpdatedDate;
    }

    toDto(model: ScaffoldingListItemModel): void {
        throw new Error("Method not implemented.");
    }
}

export interface ScaffoldInspectionListDTO {
    scaffoldInspectionList: ScaffoldInspectionListItemDTO[];
}

export interface ScaffoldInspectionListItemDTO {
    id: string;
    projectId: string;
    scaffoldInspectorName: string;
    createdByUserName: string;
    createdDate: string;
    lastUpdatedByName: string;
    lastUpdatedDate: string;
    numberOfScaffolds: number;
}
