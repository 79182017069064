// Libraries
import { FormControl, InputLabel, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import { useObserver } from "mobx-react-lite";
import React from "react";
import styled from "styled-components";

interface IProps {
    canExecute?: boolean;
    className?: string;
    displayName?: string;
    execute?: (value: any) => void;
    executeWithEvent?: (e: React.MouseEvent<HTMLSelectElement, MouseEvent>, value: any) => void;
    fullWidth?: boolean;
    getOptionLabel: (option: any) => string;
    groupBy?: (option: any) => string;
    onBlur?: () => void;
    options: any[];
    placeholder?: string;
    validationMessage?: string;
    value: any;
    error?: boolean;
    stopPropagation?: boolean;
}

const DarwinSelectBase: React.FC<IProps> = (props: IProps) => {
    // #region Code Behind

    const getId = (): string => {
        return "selectControlId";
    };

    const getPlaceholder = (): string => {
        return !isEmptyOrWhitespace(props.placeholder) ? props.placeholder! : "";
    };

    const getValidationMessage = (): string => {
        return !isEmptyOrWhitespace(props.validationMessage) ? props.validationMessage! : "";
    };

    const isDisabled = (): boolean => {
        return !isNullOrUndefined(props.canExecute) ? !props.canExecute : false;
    };

    const hasError = (): boolean => {
        return !isEmptyOrWhitespace(props.validationMessage) || props.error === true;
    };

    const onChange = (e: any, value: any): void => {
        if (props.execute) {
            props.execute(value);
        }
        if (props.executeWithEvent) {
            props.executeWithEvent(e, value);
        }
    };

    const onClick = (e: any): void => {
        if (props.stopPropagation) {
            e.stopPropagation();
        }
    };

    // #endregion Code Behind

    return useObserver(() => (
        <FormControl className={props.className} fullWidth={props.fullWidth} onClick={onClick}>
            {props.displayName && (
                <InputLabel disabled={isDisabled()} htmlFor={getId()} shrink>
                    {props.displayName}
                </InputLabel>
            )}
            <Autocomplete
                disableClearable={true}
                disabled={isDisabled()}
                getOptionLabel={props.getOptionLabel}
                groupBy={props.groupBy}
                onBlur={props.onBlur}
                onChange={onChange}
                options={props.options}
                renderInput={(params: any) => (
                    <TextField
                        {...params}
                        InputProps={{
                            ...params.InputProps,
                        }}
                        error={hasError()}
                        helperText={getValidationMessage()}
                        placeholder={getPlaceholder()}
                        disabled={isDisabled()}
                    />
                )}
                value={props.value}
            />
        </FormControl>
    ));
};

export const DarwinSelect = styled(DarwinSelectBase)``;
