import { Box, FormControl, FormControlLabel, FormHelperText, RadioGroup, Typography } from "@material-ui/core";
import { FieldType } from "@shoothill/core";
import { useObserver } from "mobx-react-lite";
import React from "react";
import styled from "styled-components";
import { CustomRadio } from "Views/Project/CustomComponents";
import { SolidDivider } from "Views/PurchaseOrder/Form/Views/Dividers";
import { FormSection } from "Views/PurchaseOrder/Form/Views/Forms";
import { AccidentFormModel } from "./AccidentFormModel";
import { AccidentFormViewModel } from "./AccidentFormViewModel";
import { DarwinRadioButtons } from "Components/Form/DarwinRadioButtons";

interface IProps {
    viewModel: AccidentFormViewModel;
    isDisabled: boolean;
}

type Dictionary = {
    [Key in keyof FieldType<AccidentFormModel>]: string;
};

const workEnvironmentQuestions: Dictionary = {
    defectiveWorkplace: "Defective workplace",
    lighting: "Lighting",
    designOrLayout: "Design/Layout",
    noiseOrDistraction: "Noise/distaction",
    housekeeping: "Housekeeping",
    weather: "Weather",
    lackOfRoom: "Lack of room",
    accessOrEgress: "Access/Egress",
} as Dictionary;

const humanFactorsQuestions: Dictionary = {
    failureToFollowRules: "Failure to follow rules",
    lackOfExperience: "Lack of experience",
    failureToAdhereToRiskAssessment: "Failure to adhere to risk assessment",
    fatigue: "Fatigue",
    instructionsMisunderstood: "Instructions misunderstood",
    unsafeAttitude: "Unsafe attitude",
    horseplay: "Horseplay",
    workingWithoutAuthorisation: "Working without authorisation",
    errorOfJudgement: "Error of judgement",
    undueHaste: "Undue haste",
    lapseOfConcentration: "Lapse of concentration",
} as Dictionary;

const ppeFactorsQuestions: Dictionary = {
    design: "Design",
    poorlyMaintainedOrDefective: "Poorly maintained/defective",
    notUsed: "Not used",
    wrongTypeUsed: "Wrong type used",
    notProvidedOrUnavailable: "Not provided/unavailable",
} as Dictionary;

const managementFactorsQuestions: Dictionary = {
    nonCommunicationOfRisk: "Non-communication of risk",
    supervision: "Supervision",
    training: "Training",
    systemFailure: "System failure",
    constructionOrDesign: "Construction/design",
    safetyDevice: "Safety device",
    mechanicalFailure: "Mechanical failure",
    installation: "Installation",
    operationOrUse: "Operation/use",
} as Dictionary;

const otherFactorsQuestions: Dictionary = {
    thirdParty: "Third party",
    underInvestigation: "Under investigation",
} as Dictionary;

/**
 * Copy of /NearMiss/RootCauseChecks.tsx. I couldn't figure out how to merge both of them.
 */
const RootCauseChecksBase: React.FC<IProps> = (props) => {
    const { viewModel, isDisabled } = props;

    /**
     * Renders a pair of radio buttons.
     * @param label The question or statement. The label for the form group.
     * @param fieldName The model fieldname.
     * @returns Radio button form group component.
     */
    const renderRadioButtons = (label: string, fieldName: keyof FieldType<AccidentFormModel>): JSX.Element => {
        return (
            <DarwinRadioButtons<AccidentFormModel>
                error={viewModel.getError(fieldName) !== ""}
                validationMessage={viewModel.getError(fieldName)}
                execute={(val: boolean) => viewModel.setValue(fieldName, val)}
                canExecute={!isDisabled}
                value={viewModel.getValue(fieldName)}
                fieldName={fieldName}
                label={label}
            />
        );
    };

    return useObserver(() => {
        return (
            <FormSection>
                <SolidDivider gutterBottom={true} borderThickness={2} borderOpacity={0.5} />

                <SubtitleContainer>
                    <Typography variant="h2">Root cause checks</Typography>
                </SubtitleContainer>

                <SectionSubtitleContainer>
                    <Typography variant="h3">Work environment:</Typography>
                </SectionSubtitleContainer>

                {Object.entries(workEnvironmentQuestions).map(([key, value], index: number) => {
                    const fieldName = key as keyof FieldType<AccidentFormModel>;
                    return renderRadioButtons(value, fieldName);
                })}

                <br />

                <SectionSubtitleContainer>
                    <Typography variant="h3">Human Factors:</Typography>
                </SectionSubtitleContainer>

                {Object.entries(humanFactorsQuestions).map(([key, value], index: number) => {
                    const fieldName = key as keyof FieldType<AccidentFormModel>;
                    return renderRadioButtons(value, fieldName);
                })}

                <br />

                <SectionSubtitleContainer>
                    <Typography variant="h3">PPE:</Typography>
                </SectionSubtitleContainer>

                {Object.entries(ppeFactorsQuestions).map(([key, value], index: number) => {
                    const fieldName = key as keyof FieldType<AccidentFormModel>;
                    return renderRadioButtons(value, fieldName);
                })}

                <br />

                <SectionSubtitleContainer>
                    <Typography variant="h3">Management:</Typography>
                </SectionSubtitleContainer>

                {Object.entries(managementFactorsQuestions).map(([key, value], index: number) => {
                    const fieldName = key as keyof FieldType<AccidentFormModel>;
                    return renderRadioButtons(value, fieldName);
                })}

                <br />

                <SectionSubtitleContainer>
                    <Typography variant="h3">Other:</Typography>
                </SectionSubtitleContainer>

                {Object.entries(otherFactorsQuestions).map(([key, value], index: number) => {
                    const fieldName = key as keyof FieldType<AccidentFormModel>;
                    return renderRadioButtons(value, fieldName);
                })}
            </FormSection>
        );
    });
};

export const RootCauseChecks = styled(RootCauseChecksBase)``;

export const DynamicQuestionAnswersContainer = styled(Box)`
    margin-bottom: 1.25rem;
`;

export const TextAreaContainer = styled.div`
    margin-bottom: 1.25rem;

    label {
        font-size: 12px !important;
        letter-spacing: -0.24px !important;
    }
`;

export const YesNoContainer = styled.div`
    margin-bottom: 1.25rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    > div,
    > p {
        display: flex;
        flex: 1 1 50%;
    }

    > p {
        font-size: 12px;
        font-weight: bold;
        letter-spacing: -0.24px;
    }
`;

interface SubtitleProps {
    margin?: string;
}

export const SubtitleContainer = styled.div<SubtitleProps>`
    h2 {
        margin-bottom: ${(props) => (props.margin ? props.margin : "10px")};
    }
`;

export const SectionSubtitleContainer = styled.div<SubtitleProps>`
    h3 {
        margin-bottom: ${(props) => (props.margin ? props.margin : "10px")};
    }
`;
