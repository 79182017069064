// Libs
import React from "react";
import { useObserver } from "mobx-react-lite";
import { generateID } from "@shoothill/core";

// App
import NoteViewModel from "Globals/ViewModels/NoteViewModel";
import { NoteModel, ProjectDocumentDTO } from "Globals/Models/Domain";

// Styling
import DownloadDocIcon from "Content/fileIcon.svg";
import { NoteDocument, NoteListRow, NoteListRowContent, NotesDocumentList } from "./Notes.styles";
import { Typography } from "@material-ui/core";
interface INotesLIProps {
    noteViewModel: NoteViewModel;
    noteRowClicked?: (model: NoteModel) => any | undefined;
    noteDelete?: (model: NoteModel) => any;
}

export const NotesListItem: React.FC<INotesLIProps> = (props) => {
    const { noteViewModel, noteRowClicked, noteDelete } = props;

    const downloadFile = (file: ProjectDocumentDTO) => {
        let fileUri = file!.url;
        let link = document.createElement("a");
        link.href = fileUri;
        link.setAttribute("download", file.filename);
        document.body.appendChild(link);
        link.click();
    };

    const localNoteRowClicked = (model: NoteModel): any => {
        if (noteRowClicked !== undefined) {
            noteRowClicked(model);
        } // else don't do anything
    };

    return useObserver(() => (
        <>
            {/* <CustomHr /> */}
            <NoteListRow className="generalNotes">
                <NoteListRowContent>
                    <div className="who" onClick={() => localNoteRowClicked(noteViewModel.model)}>
                        <div style={{ display: "flex", marginRight: "50px" }}>
                            <Typography variant="h5" style={{ marginRight: "10px" }}>
                                Added:
                            </Typography>
                            <Typography variant="h5" style={{ fontWeight: "normal" }}>
                                {noteViewModel.getValue("createdDate")}
                            </Typography>
                        </div>
                        <div style={{ display: "flex" }}>
                            <Typography variant="h5" style={{ marginRight: "10px" }}>
                                Added by:
                            </Typography>
                            <Typography variant="h5" style={{ fontWeight: "normal" }}>
                                {noteViewModel.getValue("originatorName")}
                            </Typography>
                        </div>
                    </div>
                    <div className="contentRow">
                        <div className="content" onClick={() => localNoteRowClicked(noteViewModel.model)}>
                            {noteViewModel.getValue("note")}
                        </div>
                        {noteDelete != undefined && (
                            <>
                                <div className="icon" onClick={() => noteDelete(noteViewModel.model)}>
                                    {/* <img src={DeleteLogo} /> */}
                                </div>
                            </>
                        )}
                    </div>
                </NoteListRowContent>
                {/* <CustomHr padding={"10px 0"} /> */}
                {noteViewModel.model.documents.length > 0 && (
                    <NotesDocumentList style={{ width: "100%", marginTop: 15 }}>
                        <div className="uploadedFile" style={{ width: "100%" }}>
                            {noteViewModel.model.documents.map((file, index) => {
                                return (
                                    <div className="uploadedFile-box">
                                        <div className="uploadedFile-heading">
                                            <NoteDocument className={index > 0 ? "notFirst" : ""} key={file.filename} onClick={() => downloadFile(file)}>
                                                <img src={DownloadDocIcon} />
                                                <div>{file.filename}</div>
                                            </NoteDocument>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </NotesDocumentList>
                )}
            </NoteListRow>
        </>
    ));
};
