// Libraries
import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { KeyValuePair } from "@shoothill/core";
import { Box, Button, createMuiTheme, makeStyles, TextField } from "@material-ui/core";
// Custom
import { BaseModal } from "Components/Modal/BaseModal";
import { IEGridItemModel, IEGridItemModelDTO } from "../IEmodels";
import { IEGridItemViewModel, IEUpsertLineDTO, IECategory, IESubcategory } from "../IEmodels/IEGridItemViewModel";
import { PackagesViewModel } from "../PackagesViewModel";
import { GenericAutoCompleteView } from "Components/AutoComplete/GenericAutoCompleteView";
import { CommercialViewModel, IEItemModalRequest } from "../Commercial.ViewModel";
import { IEViewModel } from "../IE.ViewModel";

// Styling & Images
import { ModalGridView, ModalEditBox } from "../IETables/IEGrid.Styles";
import { IEItemLineModalStyle, Main } from "../../Modals/Modal.Styles";
import { ProjectEditBox } from "../../Project.styles";
import { DarwinInput } from "Globals/Styles/Control/DarwinInput";
import { formatCurrencyNoSign } from "Utils/Format";
import { theme } from "Globals/Styles/AppTheme";
import { DefaultButton } from "Components/Buttons/Buttons";

interface ICreatedModalProps {
    open?: boolean;
    onClose(): void;
    ieId: string;
    itemModel: IEGridItemModel;
    parentId: string;
    categoryId: number;
    subcategoryId: number;
    descriptionId: number;
    descriptionOther: string;
    saveResponse(saved: string): void;
}

export const IELineModal: React.FC<ICreatedModalProps> = (props) => {
    const { open, onClose, itemModel, ieId, parentId } = props;
    const [viewModel] = useState(new IEGridItemViewModel());

    const ieViewModel = IEViewModel.Instance;
    const packagesViewModel = PackagesViewModel.Instance;

    //const [categoryIndex, setCategoryIndex] = useState<number>(-1);
    const [subcategoryIndex, setSubcategoryIndex] = useState<number>(-1);

    const [categoryId, setCategoryId] = useState<number>(props.categoryId);
    const [subcategoryId, setSubcategoryId] = useState<number>(props.subcategoryId);
    const [descriptionId, setDescriptionId] = useState<number>(props.descriptionId);
    //const [isShowOtherText, setIsShowOtherText] = useState<boolean>(false);
    const [newIELine, setNewIELine] = useState<boolean>(true);
    const [otherDescriptionText, setOtherDescriptionText] = useState<string>(props.descriptionOther);

    const classes = useStyles();
    useEffect(() => {
        if (itemModel !== null && itemModel !== undefined) {
            viewModel.loadModel(itemModel, parentId);
            viewModel.setValue<number>("category", categoryId);
            viewModel.setValue<number>("subCategory", subcategoryId);
            viewModel.setValue<number>("descriptionId", descriptionId);
            viewModel.setValue<string>("descriptionOther", otherDescriptionText);

            if (descriptionId == -2) {
                //setIsShowOtherText(true);
            }
            if (viewModel.model.id.length > 0) {
                setNewIELine(false);
            } else {
                setNewIELine(true);
            }
        }
    }, []);

    const handleOtherDescriptionText = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!IEGridItemViewModel.Instance.isCentral) {
            setOtherDescriptionText(event.target.value);
            viewModel.setValue<string>("descriptionOther", event.target.value);
        }
    };

    // const handleDescription = (val: KeyValuePair) => {
    //     let id = parseInt(val.key, 10);
    //     setDescriptionId(id);
    //     viewModel.setValue<number>("descriptionId", id);
    //     if (id == -2) {
    //         //setIsShowOtherText(true);
    //     } else {
    //         //setIsShowOtherText(false);
    //     }
    // };

    const handleSubcategory = (val: KeyValuePair) => {
        if (!IEGridItemViewModel.Instance.isCentral) {
            let id = parseInt(val.key, 10);
            setSubcategoryId(id);
            viewModel.setValue<number>("subCategory", parseInt(val.key, 10));
        }
    };

    const handleCategory = (val: KeyValuePair) => {
        if (!IEGridItemViewModel.Instance.isCentral) {
            let id = parseInt(val.key, 10);
            setCategoryId(id);
            viewModel.setValue<number>("category", parseInt(val.key, 10));
        }
    };

    const onSaveClick = async () => {
        if (await viewModel.isModelValid()) {
            let categoryItem: IECategory = {
                id: "",
                iEId: ieViewModel.workingId,
                categoryId: categoryId,
            };

            let subcategoryItem: IESubcategory = {
                id: "",
                categoryId: "",
                subCategoryId: subcategoryId,
            };

            viewModel.setValue<string>("descriptionOther", otherDescriptionText);
            let request: IEUpsertLineDTO = {
                category: categoryItem,
                subcategory: subcategoryItem,
                ieItem: viewModel.model.toDto(),
            };

            ieViewModel.apiUpsertIEItemLine(request).then((result) => {
                if (result.wasSuccessful) {
                    props.saveResponse("successful");
                } else {
                    props.saveResponse("failed");
                }
            });
        }
    };

    return useObserver(() => (
        <BaseModal
            dialogClasses="project-dialog"
            open={open}
            onClose={onClose}
            title={newIELine ? "Add line description" : "Update line description"}
            PaperComponent={IEItemLineModalStyle}
            actions={
                <>
                    <Button
                        onClick={() => onSaveClick()}
                        color="primary"
                        variant="contained"
                        disableElevation
                        style={{ maxWidth: "116px" }}
                        disabled={!viewModel.isTaxonomyValid || viewModel.IsLoading}
                    >
                        {newIELine && <>Add</>}
                        {!newIELine && <>Update</>}
                    </Button>
                    <DefaultButton execute={onClose} style={{ maxWidth: "116px" }} displayName="Cancel" />
                </>
            }
        >
            <Main>
                <ModalEditBox>
                    <Box display={"grid"} gridTemplateColumns={"1fr 1fr"} gridGap={"25px"} mb="15px">
                        <Box>
                            <label style={{ fontSize: "12px" }}>Category</label>
                            <GenericAutoCompleteView
                                allowMultiple={false}
                                currentId={categoryId.toString()}
                                includeDeleted={false}
                                dataSet={packagesViewModel.getCategoryList}
                                returnPair={(val) => handleCategory(val)}
                                idVal={"category"}
                                allowAdd={false}
                                variant="standard"
                                placeHolder={"Select Category"}
                                autoSelect={false}
                                disabled={IEGridItemViewModel.Instance.isCentral}
                            />
                        </Box>
                        <Box>
                            <label style={{ fontSize: "12px" }}>Sub category</label>
                            <GenericAutoCompleteView
                                allowMultiple={false}
                                currentId={subcategoryId.toString()}
                                includeDeleted={false}
                                dataSet={packagesViewModel.getSubcategoryList(categoryId)}
                                returnPair={(val) => handleSubcategory(val)}
                                idVal={"subcategory"}
                                allowAdd={false}
                                variant="standard"
                                placeHolder={"Select Subcategory"}
                                autoSelect={false}
                                disabled={IEGridItemViewModel.Instance.isCentral}
                            />
                        </Box>
                    </Box>

                    {/* <div className="row fullwidth">
                        <label>Line description</label>
                    </div>
                    <div className="row fullwidth" style={{ borderBottom: "1px dashed #ced4da", paddingBottom: "16px", marginBottom: "16px" }}>
                        <GenericAutoCompleteView
                            allowMultiple={false}
                            currentId={descriptionId.toString()}
                            includeDeleted={false}
                            dataSet={packagesViewModel.getDescriptionList(subcategoryIndex)}
                            returnPair={(val) => handleDescription(val)}
                            idVal={"description"}
                            allowAdd={true}
                            variant="standard"
                            placeHolder={"Select line description"}
                        />
                    </div> */}
                    <div className="row fullwidth">
                        <label>Line description</label>
                    </div>
                    <div className="row fullwidth">
                        <TextField type="search" onChange={handleOtherDescriptionText} value={otherDescriptionText} disabled={IEGridItemViewModel.Instance.isCentral} />
                    </div>
                    <br />
                </ModalEditBox>
                {/* {isShowOtherText ? (
                    <>
                        <div className="row fullwidth">
                            <label>Other text</label>
                            <TextField type="search" onChange={handleOtherDescriptionText} value={otherDescriptionText} />
                        </div>
                        <br />
                    </>
                ) : (
                    ""
                )} */}
                <ModalEditBox style={{}}>
                    <div className="row">
                        <ModalGridView>
                            <li className="tableHeading">
                                <div>Income</div>
                                <div>Target cost</div>
                                <div>Margin %</div>
                                <div>Committed cost</div>
                                <div>Future spend</div>
                                <div>Total expected spend</div>
                                <div>Variance</div>
                                <div>
                                    Risk /<br />
                                    Opportunity
                                </div>
                            </li>
                            {newIELine ? (
                                <>
                                    <li className="item" key={"item_0"}>
                                        <div className={`right noPadding ${classes.input}`}>
                                            <TextField
                                                id=""
                                                type="number"
                                                value={viewModel.model.income}
                                                onChange={(event: any) => viewModel.setValue("income", event.target.value)}
                                                fullWidth
                                                error={viewModel.getError("income") !== ""}
                                            />
                                        </div>
                                        <div className={`right noPadding ${classes.input}`}>
                                            <TextField
                                                id=""
                                                type="number"
                                                value={viewModel.model.estimateCost}
                                                onChange={(event: any) => viewModel.setValue("estimateCost", event.target.value)}
                                                fullWidth
                                                error={viewModel.getError("estimateCost") !== ""}
                                            />
                                        </div>
                                        <div></div>
                                        <div></div>
                                        <div className={`right noPadding ${classes.input}`}>
                                            <TextField
                                                id=""
                                                type="number"
                                                value={viewModel.model.futureSpend}
                                                onChange={(event: any) => viewModel.setValue("futureSpend", event.target.value)}
                                                fullWidth
                                                error={viewModel.getError("futureSpend") !== ""}
                                            />
                                        </div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </li>
                                </>
                            ) : (
                                <>
                                    <li className="item" key={"item_0"}>
                                        <div className={`right noPadding ${classes.input}`}>
                                            <TextField
                                                id=""
                                                type="number"
                                                value={viewModel.model.income}
                                                onChange={(event: any) => viewModel.setValue("income", event.target.value)}
                                                fullWidth
                                                error={viewModel.getError("income") !== ""}
                                            />
                                        </div>
                                        <div className={`right noPadding ${classes.input}`}>
                                            <TextField
                                                id=""
                                                type="number"
                                                value={viewModel.model.estimateCost}
                                                onChange={(event: any) => viewModel.setValue("estimateCost", event.target.value)}
                                                fullWidth
                                                error={viewModel.getError("estimateCost") !== ""}
                                            />
                                        </div>
                                        <div className={`right ${classes.input}`}>-</div>
                                        <div className={`right ${classes.input}`}>{formatCurrencyNoSign(itemModel.committedCost)}</div>
                                        <div className={`right noPadding ${classes.input}`}>
                                            <TextField
                                                id=""
                                                type="number"
                                                value={viewModel.model.futureSpend}
                                                onChange={(event: any) => viewModel.setValue("futureSpend", event.target.value)}
                                                fullWidth
                                                error={viewModel.getError("futureSpend") !== ""}
                                            />
                                        </div>
                                        <div className={`right ${classes.input}`}>{formatCurrencyNoSign(itemModel.totalExpectedSpend)}</div>
                                        <div className={`right ${classes.input}`}>-</div>
                                        <div className={`right ${classes.input}`}>{formatCurrencyNoSign(itemModel.roValue)}</div>
                                    </li>
                                </>
                            )}
                        </ModalGridView>
                    </div>
                </ModalEditBox>
            </Main>
        </BaseModal>
    ));
};

const useStyles = makeStyles({
    buttonGroup: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    autoCompleteInput: {
        display: "flex",
        justifyContent: "start",
        paddingRight: "30px !important",
        paddingLeft: "40px !important",
        "& div.MuiAutocomplete-inputRoot": {
            width: "90%",
            height: "90%",
            display: "flex",
            alignItems: "center",
        },
    },
    input: {
        "& .MuiInputBase-root": {
            display: "flex !important",
            alignItems: "center !important",
            justifyContent: "center !important",
            margin: "0 5px",
            height: "90%",
            backgroundColor: "gold",
            width: "90%",

            "& > input": {
                textAlign: "right",
                fontSize: "12px",
            },
        },
    },
    inputIcon: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
});
