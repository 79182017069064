import { FieldType, ViewModelBase } from "@shoothill/core";
import { BreadcrumbModel } from "Views/Breadcrumb/BreadcrumbModel";

import { ProjectGeneralViewModel } from "../General/ProjectGeneralViewModel";
import { action, computed, observable } from "mobx";
export class ProjectBreadcrumbViewModel extends ViewModelBase<BreadcrumbModel> {
    //Singleton instance of class
    private static _instance: ProjectBreadcrumbViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    private generalViewModel = ProjectGeneralViewModel.Instance;

    @observable public errorMessage: string = "";

    constructor() {
        super(new BreadcrumbModel(), false);
        this.setDecorators(BreadcrumbModel);
    }

    @computed
    public get getProjectName(): string {
        return this.generalViewModel.model.name;
    }

    @computed
    public get getProjectRef(): string {
        return this.generalViewModel.model.reference;
    }

    public async isFieldValid(fieldName: keyof FieldType<BreadcrumbModel>): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public onAddProject = () => {
        alert("TODO onAddProject");
    };

    public onEditProject = () => {
        alert("TODO onEditProject");
    };
}
