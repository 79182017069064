import * as React from "react";
import { Button } from "@material-ui/core";

import { GenericModalDialogPaper } from "./GenericModal.styles";
import { BaseModal } from "./BaseModal";
import { CancelButton } from "Globals/Styles/Control/Buttons";

interface IProps {
    open?: boolean;
    onClose(): void;
    onSecondButtonClick(): void;
    onFirstButtonClick(): void;
    title: string;
    firstButtonText?: string;
    secondButtonText?: string;
    text: string;
    isLoading?: boolean;
    canClose?: boolean;
}

export const GenericModal: React.FC<IProps> = ({
    open,
    onSecondButtonClick,
    onFirstButtonClick,
    onClose,
    title,
    text,
    firstButtonText,
    secondButtonText,
    isLoading = false,
    canClose = true,
}) => (
    <BaseModal
        open={open}
        onClose={onClose}
        title={title}
        PaperComponent={GenericModalDialogPaper}
        actions={
            <>
                <Button onClick={onFirstButtonClick} color="secondary" autoFocus variant="contained">
                    {firstButtonText ? firstButtonText : "Agree"}
                </Button>
                <CancelButton onClick={onSecondButtonClick} disabled={isLoading}>
                    {secondButtonText ? secondButtonText : "Disagree"}
                </CancelButton>
            </>
        }
        canClose={canClose}
    >
        <p className="client-modal-description">{text}</p>
    </BaseModal>
);
