import { ModelBase, observable } from "@shoothill/core";
import { action } from "mobx";
export class DesignDataStatusModel extends ModelBase<DesignDataStatusModel> {
    public static readonly DEFAULT_DESIGNDATA_ID = "";

    @observable
    public designDataId: string = DesignDataStatusModel.DEFAULT_DESIGNDATA_ID;

    @action
    public fromDto(dto: DesignDataStatusResponseDTO): void {
        for (let key in dto) {
            if (dto.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(dto[key]);
                } else if (key === "designDataItems") {
                    // Do nothing.
                } else {
                    this[key] = dto[key];
                }
            }
        }
    }

    @action
    toDto(model: DesignDataStatusModel): void {}
}

export interface DesignDataStatusResponseDTO {}

export interface DesignDataStatusRequestDTO {
    id: string | null;
}
