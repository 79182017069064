import { Box, Typography } from "@material-ui/core";
import { Loader, styled, useRouter } from "@shoothill/core";
import { ITab, Tabs } from "Components/Tabs/Tabs";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { runInAction } from "mobx";
import { InductionAdminViewModel } from "./InductionAdminViewModel";
import { InductionAdminDetailsView } from "./Details/InductionAdminDetailsView";
import { InductionAdminSiteAccessLogView } from "./SiteAccessLog/InductionAdminSiteAccessLogView";
import { PreviewImage } from "../../../../Globals/Styles/AppStyling";
import { SHBox } from "../../../../Components/Box";
import { PrimaryButton } from "Components/Buttons/Buttons";
import { DeleteModal } from "Components/Modal/DeleteModal";
import { SnackBar } from "Components/SnackBar/SnackBar";
import { AdminSiteAccessFilterViewModel } from "./SiteAccessLog/AdminSiteAccessFilterViewModel";
import moment from "moment";

interface IProps {
    className?: string;
}

export const TAB_DETAILS = "details";
export const TAB_SITEACCESSLOG = "siteaccesslog";

const InductionAdminViewBase: React.FC<IProps> = (props) => {
    // #region Code Behind
    const { match } = useRouter();
    const { inductionid } = match.params as any;
    const [viewModel] = useState(() => InductionAdminViewModel.Instance(inductionid));
    const { history } = useRouter();
    const [selectedTab, setSelectedTab] = React.useState(TAB_DETAILS);
    const [isApprovalMode, setIsApprovalMode] = React.useState(false);
    const [approveOpen, setApproveOpen] = React.useState(false);

    const tabOptions: ITab[] = [
        { key: TAB_DETAILS, title: "Details", hash: "details" },
        { key: TAB_SITEACCESSLOG, title: "Site access log", hash: "siteaccesslog" },
    ];

    const handleTabClick = (tab: ITab) => {
        setSelectedTab(tab.key);
    };

    const handleApproveClose = () => {
        setApproveOpen(false);
    };

    const handleApproveInduction = () => {
        viewModel.approveInduction();
        setApproveOpen(false);
    };

    useEffect(() => {
        runInAction(async () => {
            if (!InductionAdminViewModel.GetInstance) {
                InductionAdminViewModel.Instance(inductionid);
            }
            await viewModel.loadInductionDetails();
            //await viewModel.loadInductionSiteAccessLog();
        });
        return () => {
            if (InductionAdminViewModel.GetInstance) {
                InductionAdminViewModel.GetInstance.reset();
            }
        };
    }, []);

    useEffect(() => {
        runInAction(async () => {
            let todayDate = moment();
            todayDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
            AdminSiteAccessFilterViewModel.Instance.setStartDateFilter(todayDate.toDate());

            await viewModel.loadSiteAccess(inductionid);
        });
        return () => {
            viewModel.clean();
        };
    }, []);

    const renderForm = () => {
        switch (selectedTab) {
            case TAB_DETAILS:
                return renderDetailsForm();
            case TAB_SITEACCESSLOG:
                return renderSiteAccessLog();
            default:
                return renderDetailsForm();
        }
    };

    const renderDetailsForm = () => {
        return <InductionAdminDetailsView viewModel={viewModel.inductionAdminItemViewModel} />;
    };

    const renderSiteAccessLog = () => {
        return <InductionAdminSiteAccessLogView viewModel={viewModel} inductionUserId={inductionid} />;
    };

    const renderPage = () => {
        return (
            <InductionPageContainer>
                <HeaderContainer>
                    <div>
                        <SHBox m={1}>
                            <PreviewImage src={viewModel.inductionAdminItemViewModel.model.photographUrl} alt="Induction photo" />
                        </SHBox>
                        <div>
                            <Typography variant="h1">{viewModel.inductionAdminItemViewModel.model.fullName}</Typography>
                            <Typography variant="h2">{viewModel.inductionAdminItemViewModel.model.priorityEmployerName}</Typography>
                        </div>
                    </div>
                    {viewModel.canApprove && (
                        <PrimaryButton displayName="Approve visitor" execute={() => setApproveOpen(true)} fullWidth={true} canExecute={!viewModel.IsLoading} />
                    )}
                </HeaderContainer>
                <Tabs tabs={tabOptions} onTabClick={handleTabClick} selected={selectedTab} minTabWidth={132} tabPadding="11.5px 8px" />
                {renderForm()}
                <DeleteModal
                    open={approveOpen}
                    onClose={handleApproveClose}
                    onDelete={handleApproveInduction}
                    title="Approve induction?"
                    text="Are you sure you want to approve this induction?"
                    isLoading={viewModel.IsLoading}
                />
                <SnackBar
                    messageText={viewModel.snackMessage}
                    messageType={viewModel.snackType}
                    active={viewModel.snackbarState}
                    closeOption={() => viewModel.setSnackbarState(false)}
                    autoHideDuration={5000}
                />
            </InductionPageContainer>
        );
    };

    return useObserver(() => (viewModel && viewModel.hasLoaded ? renderPage() : <Loader />));
};

export const InductionAdminView = styled(InductionAdminViewBase)``;

export const InductionPageContainer = styled(Box)`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

const HeaderContainer = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 30px 30px 15px 30px;
    justify-content: space-between;

    > div {
        display: flex;
        flex-direction: row;
    }

    > div > div:nth-child(1) {
        img {
            width: 48px;
            height: 48px;
        }
    }

    > div > div:nth-child(2) {
        display: flex;
        flex-direction: column;
    }

    button {
        max-width: 262px;
    }
`;
