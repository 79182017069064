import { ViewModelBase } from "@shoothill/core";
import { action, computed, observable, runInAction } from "mobx";

import { AppUrls } from "AppUrls";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { StockTransferHistoryItemViewModel } from "./StockTransferHistoryItemViewModel";

export class StockTransferHistoryViewModel extends ViewModelBase<any> {
    public server: ServerViewModel = new ServerViewModel();

    private ieItemMaterialId: string;
    private parentCloseAction: () => void;

    private stockHistoryItems = observable<StockTransferHistoryItemViewModel>([]);

    constructor(ieItemMaterialId: string, closeAction: () => void) {
        super({});

        this.ieItemMaterialId = ieItemMaterialId;
        this.parentCloseAction = closeAction;

        // Load stock history.
        this.apiLoadStockHistoryAsync();
    }

    // #region Filtering

    @computed
    public get filteredStockHistoryItems(): StockTransferHistoryItemViewModel[] {
        return this.stockHistoryItems.slice();
    }

    // #endregion Filtering

    // #region Actions

    @action
    public close = () => this.parentCloseAction();

    @action
    public navigateToPurchaseOrder = (value: string) => {
        this.history.push(`${AppUrls.Client.PurchaseOrder.List}/${value}/view`);
    };

    // #endregion Actions

    // #region Api Actions

    @action
    public apiLoadStockHistoryAsync = async (): Promise<void> => {
        await this.server.query<any>(
            () => this.Get(AppUrls.Server.Stock.GetStockTransferHistoryViewsByIEItemMaterialId.replace("{ieItemMaterialId}", this.ieItemMaterialId)),
            (result) => {
                runInAction(() => {
                    this.stockHistoryItems.replace(
                        result.map((dto: any) => {
                            const stock = new StockTransferHistoryItemViewModel();

                            stock.note = dto.note;
                            stock.stockQuantity = dto.stockQuantity;
                            stock.stockTransactionType = dto.stockTransactionType;
                            stock.stockTransactionRequestNumber = dto.stockTransactionRequestNumber;
                            stock.stockValue = dto.stockValue;
                            stock.unitChange = dto.unitChange;
                            stock.updatedByUser = dto.updatedByUser;
                            stock.updatedDate = dto.updatedDate;

                            return stock;
                        }),
                    );
                });
            },
            "Error whilst loading the stock data",
        );

        if (this.server.HaveValidationMessage) {
            this.setSnackMessage(this.server.ValidationMessage);
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }
    };

    // #endregion Api Actions

    // #region Snack Bar

    public SNACKERROR = "error";

    @observable
    public snackbarState = false;

    @observable
    public snackType = "";

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public snackMessage = "";

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    // #endregion Snack Bar

    // #region Boilerplate

    isFieldValid(fieldName: string | number | symbol, value: any): Promise<boolean> {
        throw new Error("Method not implemented.");
    }
    beforeUpdate?(fieldName: string | number | symbol, value: any) {
        throw new Error("Method not implemented.");
    }
    afterUpdate?(fieldName: string | number | symbol, value: any): void {
        throw new Error("Method not implemented.");
    }

    // #endregion Boliderplate
}
