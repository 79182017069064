import { Box } from "@material-ui/core";
import { useObserver } from "mobx-react-lite";
import React, { useState } from "react";
import styled from "styled-components";

import { pxToRem } from "Globals/Styles/AppTheme";

// #region Form

interface IFormProps {
    className?: string;
    onSubmit: (event: any) => void;
}

const FormViewBase: React.FC<IFormProps> = (props) => {
    // #region Code Behind
    // #endregion Code Behind

    return useObserver(() => {
        return (
            <form className={props.className} onSubmit={props.onSubmit}>
                {props.children}
            </form>
        );
    });
};

export const Form = styled(FormViewBase)`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

// #endregion Form

// #region FormSection

interface IFormSectionProps {
    className?: string;
}

const FormSectionViewBase: React.FC<IFormSectionProps> = (props) => {
    // #region Code Behind
    // #endregion Code Behind

    return useObserver(() => {
        return <Box className={props.className}>{props.children}</Box>;
    });
};

export const FormSection = styled(FormSectionViewBase)`
    margin: 0 ${pxToRem(30)} ${pxToRem(20)} ${pxToRem(30)};
`;

// #endregion FormSection
