import { debounce } from "@material-ui/core";
import { FieldType, ViewModelBase, isNullOrUndefined } from "@shoothill/core";
import { observable, action, computed, IObservableArray, runInAction } from "mobx";

import { AppUrls } from "AppUrls";
import { RequisitionPOStatusEnum } from "Globals/Models/RequisitionPOStatusEnum";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { MaterialApprovalViewModel } from "Views/Stock/Material/MaterialApproval/MaterialApprovalViewModel";
import { MaterialPriceDetailsApprovalViewModel } from "Views/Stock/Material/MaterialPriceDetailsApproval/MaterialPriceDetailsApprovalViewModel";
import { StockAdjustmentApprovalViewModel } from "Views/Stock/Stock/StockAdjustmentApproval/StockAdjustmentApprovalViewModel";
import { StockTransferApprovalViewModel } from "Views/Stock/Stock/StockTransferApproval/StockTransferApprovalViewModel";
import { IMaterialTransactionRequestViewDTO } from "Views/Stock/Material/Shared/MaterialTransactionRequestDtos";
import { IStockTransactionRequestViewDTO } from "Views/Stock/Stock/Shared/StockTransactionRequestDtos";
import { MyAmendListItemViewModel } from "./MyAmendListItemViewModel";
import { TabItemViewModel } from "./Navigation/TabItemViewModel";
import { TabsViewModel } from "./Navigation/TabsViewModel";
import { PurchaseListModel, PurchaseListModelDTO } from "./PurchaseListModel";

export class PurchaseListViewModel extends ViewModelBase<PurchaseListModel> {
    public server: ServerViewModel = new ServerViewModel();

    // #region Constants

    public static readonly TAB_DEFAULT_INDEX = 0;
    public static readonly TAB_PENDING_APPROVAL = RequisitionPOStatusEnum.Draft.toString();
    public static readonly TAB_AMENDS_REQUIRED = RequisitionPOStatusEnum.AmendRequired.toString();
    public static readonly TAB_COMPLETED = RequisitionPOStatusEnum.Approved.toString();
    public static readonly TAB_REJECTED = RequisitionPOStatusEnum.Rejected.toString();

    // #endregion Constants

    private static _instance: PurchaseListViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    public tabsViewModel = new TabsViewModel(
        [
            new TabItemViewModel(PurchaseListViewModel.TAB_PENDING_APPROVAL, "Pending approval"),
            new TabItemViewModel(PurchaseListViewModel.TAB_AMENDS_REQUIRED, "Amends required"),
            new TabItemViewModel(PurchaseListViewModel.TAB_COMPLETED, "Completed"),
            new TabItemViewModel(PurchaseListViewModel.TAB_REJECTED, "Rejected"),
        ],
        (value: TabItemViewModel) => {
            const statusName = this.getRequisitionPOStatusNameByType(Number(value.id));

            this.getMyAmends(statusName);
        },
    );

    public constructor() {
        super(new PurchaseListModel(), false);

        this.setDecorators(PurchaseListModel);
        this.initialize();
    }

    /**
     * Load dependent data from the API.
     */
    private initialize = () => {
        // As we have coupled the retreival of approval data from the API to the active tab,
        // we must ensure that requisition statuses are returned first.
        this.loadRequisitionPOStatuses().then(() => {
            this.tabsViewModel.setActiveTabItemViewModel(this.tabsViewModel.tabItemViewModels[PurchaseListViewModel.TAB_DEFAULT_INDEX]);

            this.getRequisitionPOStatusCounts();
        });
    };

    @observable
    public errorMessage: string = "";

    @observable
    public hasLoaded = true;

    // #region Search String Filter

    private readonly DEBOUNCE_VALUE_MS = 200;

    @observable
    public searchString: string = "";

    @observable
    public filterSearchString: string = "";

    public getSearchString = () => {
        return computed(() => this.searchString);
    };

    @action
    public setSearchString = (value: string, currentStatus: string) => {
        this.searchString = value;
        this.setFilterSearchString(value, currentStatus);
    };

    private setFilterSearchString = debounce(
        action((value: string, currentStatus: string) => {
            this.filterSearchString = value;
            this.getMyAmends(currentStatus);
        }),
        this.DEBOUNCE_VALUE_MS,
    );

    // #endregion Search String Filter

    @observable
    public requisitionPOStatuses: RequisitionPOStatus[] = [];

    @action
    public getRequisitionPOStatusNameByType = (val: number): string => {
        const status = this.requisitionPOStatuses.find((r) => r.type === val);

        return status ? status.name : "Draft";
    };

    @computed
    public get filteredMyAmendViewModels(): MyAmendListItemViewModel[] {
        return this.myAmendViewModels;
    }

    private myAmendViewModels: IObservableArray<MyAmendListItemViewModel> = observable<MyAmendListItemViewModel>([]);

    @action
    public navigateToRequisition = () => {
        //this.history.push(AppUrls.Client.Project.GeneralAdd);
    };

    @action
    private setHasLoaded(val: boolean) {
        this.hasLoaded = val;
    }

    @action
    private populateListItemViewModels = (dto: PurchaseListModelDTO[]) => {
        const myAmendViewModels: MyAmendListItemViewModel[] = [];

        for (const myAmendListItem of dto) {
            const viewModel = new MyAmendListItemViewModel();
            viewModel.model.fromDto(myAmendListItem);
            myAmendViewModels.push(viewModel);
        }

        this.myAmendViewModels.replace(myAmendViewModels);
    };

    @action
    private populateRequisitionPOStatuses = (dto: RequisitionPOStatus[]) => {
        this.requisitionPOStatuses = dto;
    };

    public loadRequisitionPOStatuses = async (): Promise<void> => {
        this.setIsLoading(true);
        const path = AppUrls.Server.Approval.GetAllRequisitionPOStatuses;

        const apiResult = await this.Get<RequisitionPOStatus[]>(path);

        if (apiResult) {
            this.setHasLoaded(false);
            if (apiResult.wasSuccessful) {
                this.populateRequisitionPOStatuses(apiResult.payload);
            } else {
                console.log(apiResult.errors);
            }
            this.setHasLoaded(true);
        }
        this.setIsLoading(false);
    };

    public getMyAmends = async (statusName: string): Promise<void> => {
        this.setIsLoading(true);
        const search = this.searchString === "" ? "" : this.searchString;
        const status = statusName === "" ? "" : statusName;

        const path = AppUrls.Server.Approval.SearchMyAmends + "/?search=" + search + "&statusName=" + status;

        const apiResult = await this.Get<PurchaseListModelDTO[]>(path);

        if (apiResult) {
            this.setHasLoaded(false);
            if (apiResult.wasSuccessful) {
                this.populateListItemViewModels(apiResult.payload);
            } else {
                console.log(apiResult.errors);
            }
            this.setHasLoaded(true);
        }
        this.setIsLoading(false);
    };

    public getRequisitionPOStatusCounts = async (): Promise<void> => {
        this.setIsLoading(true);
        const apiResult = await this.Get(AppUrls.Server.Approval.GetRequisitionPOStatusCounts);

        this.setHasLoaded(false);
        if (apiResult.wasSuccessful) {
            const payload: any = apiResult.payload;

            for (const tabItemViewModel of this.tabsViewModel.tabItemViewModels) {
                switch (tabItemViewModel.id) {
                    case PurchaseListViewModel.TAB_PENDING_APPROVAL:
                        tabItemViewModel.setCount(payload.draft);
                        break;

                    case PurchaseListViewModel.TAB_AMENDS_REQUIRED:
                        tabItemViewModel.setCount(payload.amendRequired);
                        break;

                    default:
                        tabItemViewModel.setCount(undefined);
                        break;
                }
            }
        } else {
            console.log(apiResult.errors);
        }
        this.setHasLoaded(true);
        this.setIsLoading(false);
    };

    @action
    public cleanUp = () => {
        // TODO Any Cleanup Code here. e.g. if  a user or project or client etc, wipe it from the instance on page shutdown
    };

    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (await this.isModelValid()) {
            //Do stuff here
            this.errorMessage = "Form is valid";
        } else {
            this.errorMessage = "Form is not valid";
        }
    };

    // #region Approval Modals

    @observable
    public approvalViewModel: MaterialApprovalViewModel | MaterialPriceDetailsApprovalViewModel | StockAdjustmentApprovalViewModel | StockTransferApprovalViewModel | null = null;

    @action
    public navigateToMaterialApproval = (rowData: MyAmendListItemViewModel): void => {
        this.apiLoadMaterialTransactionRequest(rowData.model.materialTransactionRequestId!);
    };

    @action
    public navigateToStockApproval = (rowData: MyAmendListItemViewModel): void => {
        this.apiLoadStockTransactionRequest(rowData.model.stockTransactionRequestId!);
    };

    @action
    public closeApprovalViewModel = (refreshPage: boolean) => {
        this.approvalViewModel = null;

        if (refreshPage) {
        }
    };

    @action
    public apiLoadMaterialTransactionRequest = async (materialTransactionRequestId: string): Promise<void> => {
        await this.server.query<IMaterialTransactionRequestViewDTO>(
            () =>
                this.Get(
                    AppUrls.Server.Stock.Material.GetMaterialTransactionRequestViewByMaterialTransactionRequestId.replace(
                        "{materialtransactionrequestid}",
                        materialTransactionRequestId,
                    ),
                ),
            (result) => {
                runInAction(() => {
                    switch (true) {
                        case !isNullOrUndefined(result.createMaterialTransactionRequestView):
                            this.approvalViewModel = new MaterialApprovalViewModel(false, this.closeApprovalViewModel);
                            this.approvalViewModel.model.fromDto(result.createMaterialTransactionRequestView!);
                            break;

                        case !isNullOrUndefined(result.updateMaterialPriceDetailsTransactionRequestView):
                            this.approvalViewModel = new MaterialPriceDetailsApprovalViewModel(false, this.closeApprovalViewModel);
                            this.approvalViewModel.model.fromDto(result.updateMaterialPriceDetailsTransactionRequestView!);
                            break;
                    }
                });
            },
            "Error whilst loading the material request",
        );

        if (this.server.HaveValidationMessage) {
            this.setSnackMessage(this.server.ValidationMessage);
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }
    };

    @action
    public apiLoadStockTransactionRequest = async (stockTransactionRequestId: string): Promise<void> => {
        await this.server.query<IStockTransactionRequestViewDTO>(
            () => this.Get(AppUrls.Server.Stock.GetStockTransactionRequestViewByStockTransactionRequestId.replace("{stocktransactionrequestid}", stockTransactionRequestId)),
            (result) => {
                runInAction(() => {
                    switch (true) {
                        case !isNullOrUndefined(result.adjustStockTransactionRequestView):
                            this.approvalViewModel = new StockAdjustmentApprovalViewModel(false, this.closeApprovalViewModel);
                            this.approvalViewModel.model.fromDto(result.adjustStockTransactionRequestView!);
                            break;

                        case !isNullOrUndefined(result.transferStockTransactionRequestView):
                            this.approvalViewModel = new StockTransferApprovalViewModel(false, this.closeApprovalViewModel);
                            this.approvalViewModel.model.fromDto(result.transferStockTransactionRequestView!);
                            break;
                    }
                });
            },
            "Error whilst loading the stock request",
        );

        if (this.server.HaveValidationMessage) {
            this.setSnackMessage(this.server.ValidationMessage);
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }
    };

    // #endregion Approval Modals

    // #region Snack Bar

    public SNACKERROR = "error";

    @observable
    public snackbarState = false;

    @observable
    public snackType = "";

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public snackMessage = "";

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    // #endregion Snack Bar

    public async isFieldValid(fieldName: keyof FieldType<PurchaseListModel>): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}

export interface RequisitionPOStatus {
    id: string | null;
    name: string;
    ordinal: number;
    type: number;
    isDeleted: boolean;
}

export interface OrderType {
    displayName: string;
    id: string;
    orderType: number;
}

export interface UserDTO {
    displayName: string;
    id: string;
}

export interface ProjectDTO {
    displayName: string;
    id: string;
}
