import { observable, runInAction, action, computed } from "mobx";
import { FieldType, ViewModelBase } from "@shoothill/core";
import { IncidentModel, IncidentAndRelatedResponseDTO, IncidentFormItemDTO, IncidentProjectDetailsDTO } from "./IncidentModel";
import { IncidentFormTypesEnum, IncidentFormTypesLookupModel } from "./IncidentFormTypesLookupModel";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { AppUrls } from "AppUrls";
import moment from "moment";

export class IncidentViewModel extends ViewModelBase<IncidentModel> {
    public server: ServerViewModel = new ServerViewModel();
    public projectId: string = "";
    @observable public incidentFormType: IncidentFormTypesLookupModel | null = null;
    @observable public incidentFormTypes: any = [];
    @observable public incidentItemList: IncidentFormItemDTO[] = [];
    constructor(id: string) {
        super(new IncidentModel());
        this.setDecorators(IncidentViewModel);
        this.model.id = id;

        this.loadWithRelated();
    }

    @action
    public setIncidentForm = (type: IncidentFormTypesLookupModel) => {
        this.incidentFormType = type;
    };

    @observable
    public incidentProjectDetails: IncidentProjectDetailsDTO | null = null;

    @computed
    public get getProjectTitle(): string {
        return `${this.getProjectReferenceFormatted} - ${this.getProjectName}`;
    }

    @computed
    public get getTodayDateFormatted(): string {
        return this.model.createdDate ? moment(this.model.createdDate).format("DD/MM/YYYY").toString() : moment().format("DD/MM/YYYY").toString();
    }

    @computed
    public get getProjectReferenceFormatted(): string {
        if (!this.incidentProjectDetails) {
            return "";
        }

        return this.incidentProjectDetails.projectReference;
    }

    @computed
    public get getProjectName(): string {
        if (!this.incidentProjectDetails) {
            return "";
        }

        return this.incidentProjectDetails.projectName;
    }

    // @action
    // public loadIncidentAndRelated = async (): Promise<void> => {
    //     this.setIsLoading(true);
    //     const request: any = {
    //         id: this.projectId,
    //     };
    //     let apiResult = await this.Post<IncidentAndRelatedDTO>(AppUrls.Server.Projects.Construction.Incident.Load.LoadIncidentAndRelated, {});
    //     if (apiResult) {
    //         if (apiResult.wasSuccessful) {
    //             runInAction(() => {
    //                 this.incidentFormTypes.replace(IncidentFormTypesLookupModel.fromDtos(apiResult.payload.incidentFormTypes));
    //                 this.incidentItemList = apiResult.payload.incidentFormListItems;
    //             });
    //         } else {
    //             console.log(apiResult.errors);
    //         }
    //     }
    //     this.setIsLoading(false);
    // };

    /**
     * Load an existing Permit with any related data.
     */
    public loadWithRelated = async (): Promise<void> => {
        this.setIsLoading(true);
        return await this.server
            .query<IncidentAndRelatedResponseDTO>(
                () => this.Get(`${AppUrls.Server.Projects.Construction.Incident.Load.WithRelatedById}\\${this.model.id}`),
                (result) => {
                    runInAction(() => {
                        this.model.fromDto(result);
                        this.incidentFormTypes.replace(IncidentFormTypesLookupModel.fromDtos(result.incidentFormTypes));
                        this.incidentItemList = result.incidentFormListItems;
                        this.incidentProjectDetails = result.incidentProjectDetails;
                    });
                },
            )
            .finally(() => this.setIsLoading(false));
    };

    // public upsert = async (): Promise<void> => {
    //     this.setIsLoading(true);
    //     //const model: PermitDTO = this.model.toDto();

    //     const request: any = {
    //         incident: {
    //             id: null,
    //             projectId: this.model.projectId,
    //             incidentnumber: null,
    //             isDeleted: false,
    //             createdByUserId: null,
    //             rowVersion: null,
    //             lastUpdatedByUserId: null,
    //             lastUpdatedDate: null,
    //         },
    //     };

    //     return await this.server
    //         .command<any>(
    //             () => this.Post(AppUrls.Server.Projects.Construction.Incident.Upsert, request),
    //             (result: any) => {
    //                 runInAction(() => {
    //                     this.history.push(AppUrls.Client.Project.Incident.View.replace(":projectid", this.projectId).replace(":incidentId", result.id));
    //                 });
    //             },
    //             this.isModelValid,
    //             "There was an error trying to send the incident",
    //         )
    //         .finally(() => this.setIsLoading(false));
    // };

    @action
    public handleRowClick = (e: any, rowData: IncidentFormItemDTO) => {
        //this.history.push(AppUrls.Client.Project.Incident.View.replace(":projectid", this.projectId).replace(":IncidentId", rowData.id));
        if (rowData) {
            switch (rowData.incidentFormType) {
                case IncidentFormTypesEnum.NearMiss:
                    this.history.push(
                        AppUrls.Client.Project.Incident.NearMiss.View.replace(":projectid", this.projectId)
                            .replace(":incidentid", this.model.id!)
                            .replace(":incidentnearmissid", rowData.id),
                    );
                    break;
                case IncidentFormTypesEnum.Accident:
                    this.history.push(
                        AppUrls.Client.Project.Incident.Accident.View.replace(":projectid", this.projectId)
                            .replace(":incidentid", this.model.id!)
                            .replace(":incidentaccidentid", rowData.id),
                    );
                    break;
                case IncidentFormTypesEnum.WitnessStatement:
                    this.history.push(
                        AppUrls.Client.Project.Incident.WitnessStatement.View.replace(":projectid", this.projectId)
                            .replace(":incidentid", this.model.id!)
                            .replace(":incidentwitnessstatementid", rowData.id),
                    );
                    break;
            }
        }
    };

    @action
    public addIncidentForm = () => {
        if (this.incidentFormType) {
            switch (this.incidentFormType.type) {
                case IncidentFormTypesEnum.NearMiss:
                    this.history.push(AppUrls.Client.Project.Incident.NearMiss.Add.replace(":projectid", this.projectId).replace(":incidentid", this.model.id!));
                    break;
                case IncidentFormTypesEnum.Accident:
                    this.history.push(AppUrls.Client.Project.Incident.Accident.Add.replace(":projectid", this.projectId).replace(":incidentid", this.model.id!));
                    break;
                case IncidentFormTypesEnum.WitnessStatement:
                    this.history.push(AppUrls.Client.Project.Incident.WitnessStatement.Add.replace(":projectid", this.projectId).replace(":incidentid", this.model.id!));
                    break;
            }
        }
    };

    public async isFieldValid(fieldName: keyof FieldType<IncidentModel>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }
    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
