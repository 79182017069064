import { Box, FormHelperText, FormLabel, IconButton, TextField, Typography } from "@material-ui/core";
import { Cell, Grid, isNullOrEmpty, useRouter } from "@shoothill/core";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Form, FormSection } from "Views/PurchaseOrder/Form/Views/Forms";
import { FormHeader } from "Views/PurchaseOrder/Form/Views/FormHeaders";
import { DashedDivider, SolidDivider } from "Views/PurchaseOrder/Form/Views/Dividers";
import { formatIncidentNumber } from "Utils/Format";
import { pxToRem, theme } from "Globals/Styles/AppTheme";
import { BackButtonHolder } from "Views/Project/Commercial/IETables/IEGrid.Styles";
import { AppUrls } from "AppUrls";
import { CustomArrow } from "Views/Project/CustomComponents";
import { useLocation } from "react-router-dom";
import { DefaultButton, PrimaryButton } from "Components/Buttons/Buttons";
import { Alert, AlertTitle } from "@material-ui/lab";
import { DarwinSelect } from "Components/AutoComplete/DarwinSelect";
import { IncidentFormWitnessStatementViewModel } from "./IncidentFormWitnessStatementViewModel";
import { Uploader } from "Components/Uploader/Uploader";
import SystemUpdateTwoToneIcon from "@material-ui/icons/SystemUpdateTwoTone";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { GenericPhotoListItemModel } from "../Supporting/GenericPhotoListItemModel";
import { GenericFreeTextListItemModel } from "../Supporting/GenericFreeTextListItemModel";
import { Signature } from "Components/Signature/Signature";
import { DarwinDateSelect } from "Components/DateSelect/DarwinDateSelect";
import { DarwinTimeSelect } from "Components/TimeSelect/DarwinTimeSelect";

interface IProps {
    className?: string;
}

const IncidentFormWitnessStatementViewBase: React.FC<IProps> = (props) => {
    const { match } = useRouter();
    const { incidentwitnessstatementid, incidentid } = match.params as any;
    const [viewModel] = useState(() => new IncidentFormWitnessStatementViewModel(incidentwitnessstatementid, incidentid));
    const { history } = useRouter();
    const location = useLocation();
    const [currentRootCause, setCurrentRootCause] = useState<string>("");

    useEffect(() => {
        return () => {
            viewModel.reset();
        };
    }, []);

    const COLUMNS8 = "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr";

    const onSubmit = (event: any) => {
        event.preventDefault();
    };

    return useObserver(() => {
        return (
            <Form className={props.className} onSubmit={onSubmit}>
                <BackButtonHolder
                    onClick={() => {
                        history.push(AppUrls.Client.Project.Incident.View.replace(":projectid", viewModel.incident!.projectId).replace(":incidentid", viewModel.incident!.id!));
                    }}
                    style={{ minHeight: "30px", paddingTop: "3px" }}
                >
                    <div style={{ position: "relative", height: "30px", display: "flex", marginLeft: "10px" }}>
                        <div style={{ position: "absolute", top: "5px" }}>
                            <CustomArrow size={"8px"} color={theme.palette.blue.main} type={"left"} />
                        </div>
                        <div style={{ marginLeft: "20px", fontSize: "18px", fontWeight: "bold", color: theme.palette.blue.main }}>
                            Back to incident ({viewModel.incident ? formatIncidentNumber(viewModel.incident.incidentNumber) : ""})
                        </div>
                    </div>
                </BackButtonHolder>

                <DashedDivider gutterBottom={false} gutterTop={false} borderThickness={2} borderOpacity={0.5} />

                <HeaderContainer>
                    <FormHeader displayName={isNullOrEmpty(viewModel.model.id) ? "New witness statement" : "New witness statement"} />
                </HeaderContainer>

                <FormSection>
                    <ProjectDetailsContainer>
                        <div>
                            <span>Date / time added:</span> {viewModel.getTodayDateFormatted}
                        </div>
                        <div>
                            <span>Incident No:</span> {viewModel.incident ? formatIncidentNumber(viewModel.incident.incidentNumber) : ""}
                        </div>
                        <div>
                            <span>Project reference:</span> {viewModel.getProjectReferenceFormatted}
                        </div>
                        <div>
                            <span>Project name:</span> {viewModel.getProjectName}
                        </div>
                    </ProjectDetailsContainer>
                </FormSection>

                <FormSection>
                    {/* Default options */}
                    <DashedDivider gutterBottom={true} borderThickness={2} borderOpacity={0.5} />

                    <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
                        <Cell ts={1} ds={1}>
                            <DateSelectContainer>
                                <DarwinDateSelect
                                    displayName="Date:"
                                    execute={(value: string | null) => viewModel.setValue("witnessStatementDate", value)}
                                    onBlur={() => viewModel.isFieldValid("witnessStatementDate")}
                                    placeholder="dd/mm/yyyy"
                                    validationMessage={viewModel.getError("witnessStatementDate")}
                                    value={viewModel.getValue("witnessStatementDate")}
                                    canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                                />
                            </DateSelectContainer>
                        </Cell>
                        <Cell ts={1} ds={1}>
                            <DateSelectContainer>
                                <DarwinTimeSelect
                                    displayName="Time:"
                                    execute={(value: string | null) => viewModel.setValue("witnessStatementDate", value)}
                                    onBlur={() => viewModel.isFieldValid("witnessStatementDate")}
                                    placeholder="00:00"
                                    validationMessage={viewModel.getError("witnessStatementDate")}
                                    value={viewModel.getValue("witnessStatementDate")}
                                    canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                                />
                            </DateSelectContainer>
                        </Cell>
                        <Cell ts={2} ds={2}>
                            <TextField
                                type="text"
                                value={viewModel.getValue("location")}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => viewModel.setValue("location", e.target.value)}
                                fullWidth
                                error={viewModel.getError("location") !== ""}
                                disabled={viewModel.isFormDisabled || viewModel.IsLoading}
                                label={"Location:"}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                placeholder={"Please add"}
                                helperText={viewModel.getError("location")}
                            />
                        </Cell>

                        <Cell ts={8} ds={8}>
                            <TextField
                                id="whereWereYou-textarea"
                                multiline
                                minRows={4}
                                value={viewModel.getValue("whereWereYou")}
                                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => viewModel.setValue<string>("whereWereYou", event.target.value)}
                                fullWidth
                                className="textarea-forminput"
                                label="Where were you in location to the incident?"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                placeholder={`Please add notes`}
                                disabled={viewModel.isFormDisabled || viewModel.IsLoading}
                                error={viewModel.getError("whereWereYou") !== ""}
                                helperText={viewModel.getError("whereWereYou")}
                            />
                        </Cell>

                        <Cell ts={8} ds={8}>
                            <TextField
                                id="whatDidYouSee-textarea"
                                multiline
                                minRows={4}
                                value={viewModel.getValue("whatDidYouSee")}
                                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => viewModel.setValue<string>("whatDidYouSee", event.target.value)}
                                fullWidth
                                className="textarea-forminput"
                                label="What did you see happen?"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                placeholder={`Please add notes`}
                                disabled={viewModel.isFormDisabled || viewModel.IsLoading}
                                error={viewModel.getError("whatDidYouSee") !== ""}
                                helperText={viewModel.getError("whatDidYouSee")}
                            />
                        </Cell>

                        <Cell ts={8} ds={8}>
                            <TextField
                                id="whatDidYouObserve-textarea"
                                multiline
                                minRows={4}
                                value={viewModel.getValue("whatDidYouObserve")}
                                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => viewModel.setValue<string>("whatDidYouObserve", event.target.value)}
                                fullWidth
                                className="textarea-forminput"
                                label="What did you observe at the scene? (i.e position of equipment, surrounding area, environment)"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                placeholder={`Please add notes`}
                                disabled={viewModel.isFormDisabled || viewModel.IsLoading}
                                error={viewModel.getError("whatDidYouObserve") !== ""}
                                helperText={viewModel.getError("whatDidYouObserve")}
                            />
                        </Cell>

                        <Cell ts={4} ds={4}>
                            {!viewModel.isFormDisabled && (
                                <Uploader
                                    handleSelectFile={(e: React.ChangeEvent<HTMLInputElement>) => viewModel.fileChange(e)}
                                    isFormDisabled={viewModel.isFormDisabled || viewModel.IsLoading}
                                    label="Photo(s)/sketch(s) of incident:"
                                    dropzoneMessage="Click here to add photo/file"
                                />
                            )}

                            {viewModel.getPhotos.length > 0 && (
                                <FormLabel component="label">
                                    <span className="control-label">Photo(s)/sketch(s) added:</span>
                                </FormLabel>
                            )}

                            <div className="uploadedFile">
                                {viewModel.getPhotos.map((item: GenericPhotoListItemModel, index: number) => {
                                    return (
                                        <div className="uploadedFile-box">
                                            <div className="uploadedFile-heading">
                                                <IconButton onClick={() => viewModel.DownloadFile(item.photoURL, item.fileName)}>
                                                    <SystemUpdateTwoToneIcon />
                                                </IconButton>
                                                <h3>{item.fileName}</h3>
                                            </div>
                                            <IconButton onClick={() => item.handleDelete(true)} disabled={viewModel.isFormDisabled}>
                                                <DeleteOutlinedIcon />
                                            </IconButton>
                                        </div>
                                    );
                                })}
                            </div>
                        </Cell>
                    </Grid>
                </FormSection>

                <FormSection>
                    <SolidDivider gutterBottom={true} borderThickness={2} borderOpacity={0.5} />

                    <SubtitleContainer>
                        <Typography variant="h2">Authorisation</Typography>
                    </SubtitleContainer>

                    <LegalTextContainer>
                        <Typography variant="body1">"I hereby confirm that the information in this statement is correct to the best of my knowledge."</Typography>
                    </LegalTextContainer>

                    <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
                        <Cell ts={2} ds={2}>
                            <DarwinSelect
                                displayName="Witness:"
                                execute={(data: { id: string; displayName: string }) => viewModel.handleSetWitnessUser(data)}
                                fullWidth={true}
                                getOptionLabel={(option: any) => option.displayName}
                                options={viewModel.incidentUsers}
                                placeholder="Please select user"
                                value={viewModel.witnessUser}
                                canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                                error={viewModel.getError("witnessUserId") !== ""}
                                validationMessage={viewModel.getError("witnessUserId")}
                            />
                        </Cell>
                        <Cell ts={2} ds={2}>
                            {viewModel.canShowNotInSystemField && (
                                <TextField
                                    type="text"
                                    value={viewModel.getValue("witnessNameNotInSystem")}
                                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => viewModel.setValue("witnessNameNotInSystem", e.target.value)}
                                    fullWidth
                                    error={viewModel.getError("witnessNameNotInSystem") !== ""}
                                    disabled={viewModel.isFormDisabled || viewModel.IsLoading}
                                    label={"Witness name not in system:"}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    placeholder={"Please add"}
                                    helperText={viewModel.getError("witnessNameNotInSystem")}
                                />
                            )}
                        </Cell>
                        <Cell ts={4} ds={4}></Cell>
                        <Cell ts={4} ds={4}>
                            <SignatureContainer>
                                <div>
                                    <div>
                                        <Signature
                                            displayName={"Witness Signature:"}
                                            execute={(data: any) => viewModel.setValue<string>("witnessSignatureURL", data)}
                                            value={viewModel.model.witnessSignatureURL}
                                            canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                                        />
                                        {viewModel.getError("witnessSignatureURL") !== "" && (
                                            <FormHelperText style={{ color: "#f44336" }}>{viewModel.getError("witnessSignatureURL")}</FormHelperText>
                                        )}
                                    </div>
                                </div>
                            </SignatureContainer>
                        </Cell>
                        <Cell ts={4} ds={4}></Cell>
                        <Cell ts={2} ds={2}>
                            <div style={{ display: "flex", flexDirection: "row", marginBottom: "15px" }}>
                                <FormLabel component="label">
                                    <span className="control-label">Date / time added:</span>
                                </FormLabel>
                                <p style={{ fontSize: "12px", marginLeft: "10px" }}>{viewModel.getWitnessSignatureDateFormatted}</p>
                            </div>
                        </Cell>
                    </Grid>
                </FormSection>

                <FormSection>
                    <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
                        {viewModel!.server.HaveValidationMessage && (
                            <>
                                <Cell ts={8} ds={8}>
                                    <Alert severity="error">
                                        <AlertTitle>Error</AlertTitle>
                                        {viewModel!.server.ValidationMessage}
                                    </Alert>
                                </Cell>
                            </>
                        )}
                    </Grid>
                </FormSection>

                {viewModel.canSaveForm && (
                    <>
                        <div style={{ margin: `0 ${pxToRem(30)} 0 ${pxToRem(30)}` }}>
                            <SolidDivider gutterBottom={true} borderThickness={2} borderOpacity={0.5} />
                        </div>
                        <FormSection>
                            {/* Buttons */}
                            <ButtonsContainer>
                                <PrimaryButton displayName="Submit" execute={() => viewModel.upsert()} fullWidth={true} canExecute={!viewModel.IsLoading} />
                                <DefaultButton displayName="Cancel" execute={() => viewModel.handleCancel()} fullWidth={true} canExecute={!viewModel.IsLoading} />
                            </ButtonsContainer>
                        </FormSection>
                    </>
                )}
            </Form>
        );
    });
};

export const IncidentFormWitnessStatementView = styled(IncidentFormWitnessStatementViewBase)`
    display: flex;
    flex: 1;
    flex-direction: column;

    form {
        position: relative;
    }
`;

const HeaderContainer = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 70px;

    // Header
    > div:nth-child(1) {
        margin: 4px 30px 13.5px 30px;
    }
`;

const ProjectDetailsContainer = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    max-width: 700px;

    > div {
        font-size: 12px;
    }

    > div > span {
        font-weight: bold;
    }
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;

    > button {
        max-width: 262px;
    }

    > button:nth-child(1) {
        margin-right: 30px;
    }
`;

interface SubtitleProps {
    margin?: string;
}

export const SubtitleContainer = styled.div<SubtitleProps>`
    h2 {
        margin-bottom: ${(props) => (props.margin ? props.margin : "10px")};
    }
`;

export const LegalTextContainer = styled.div`
    margin-bottom: 10px;

    p {
        font-size: 12px !important;
    }
`;

const SignatureContainer = styled.div`
    display: flex;
    flex-direction: column;

    > div {
        display: flex;
        flex-direction: row;

        > div {
            max-width: 262px;
            margin-right: 30px;
            width: 100%;

            canvas {
                border: 1px solid #d4d4d4;
            }
        }
    }
`;

const DateSelectContainer = styled.div`
    .MuiInput-input {
        min-width: 70px;
    }
`;
