import { ModelBase } from "@shoothill/core";
import { action, observable } from "mobx";

export class AddressModel extends ModelBase<AddressModel, AddressDTO> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Defaults and Constants

    public static readonly DEFAULT_ID = "";
    public static readonly DEFAULT_DISPLAYNAME = "";
    public static readonly DEFAULT_PROJECTID = "";
    public static readonly DEFAULT_DELIVERYCONTACTNAME = "";
    public static readonly DEFAULT_DELIVERYCONTACTNUMBER = "";

    // #endregion Defaults and Constants

    // #region Observables

    @observable
    public id: string = AddressModel.DEFAULT_ID;

    @observable
    public displayName: string = AddressModel.DEFAULT_DISPLAYNAME;

    @observable
    public projectId: string = AddressModel.DEFAULT_PROJECTID;

    @observable
    public deliveryContactName: string = AddressModel.DEFAULT_DELIVERYCONTACTNAME;

    @observable
    public deliveryContactNumber: string = AddressModel.DEFAULT_DELIVERYCONTACTNUMBER;

    // #endregion Observables

    // #region Actions

    @action
    public reset(): void {
        this.id = AddressModel.DEFAULT_ID;
        this.displayName = AddressModel.DEFAULT_DISPLAYNAME;
        this.projectId = AddressModel.DEFAULT_PROJECTID;
        this.deliveryContactName = AddressModel.DEFAULT_DELIVERYCONTACTNAME;
        this.deliveryContactNumber = AddressModel.DEFAULT_DELIVERYCONTACTNUMBER;
    }

    @action
    public fromDto(dto: AddressDTO): void {
        this.id = dto.id;
        this.displayName = dto.address;
        this.projectId = dto.projectId;
        this.deliveryContactName = dto.deliveryContactName;
        this.deliveryContactNumber = dto.deliveryContactNumber;
    }

    public toDto() {
        throw new Error("Method not implemented.");
    }

    // #endregion Actions

    // #region Helpers

    public static fromDtos(dtos: AddressDTO[]): AddressModel[] {
        const types: AddressModel[] = [];

        for (const dto of dtos) {
            const model = new AddressModel();

            model.fromDto(dto);
            types.push(model);
        }

        return types;
    }

    // #endregion Helpers
}

export interface AddressDTO {
    id: string;
    address: string;
    projectId: string;
    deliveryContactName: string;
    deliveryContactNumber: string;
}
