import { ApiResult, FieldType, generateID, isNullOrEmpty, isNullOrUndefined, ViewModelBase } from "@shoothill/core";
import { ClientDetailModelDTO } from "./ClientDetailModel";
import { action, computed, observable, runInAction } from "mobx";
import { AddEditContactModel } from "Views/Contacts/AddEditContactModel";
import { ClientAddEditModel } from ".";
import type { ClientWithRelatedDTO } from ".";
import { AppUrls } from "AppUrls";
import { GenericId } from "Globals/Models/GenericId";
import { AddressModel } from "Globals/Models/Domain";
import type { AddressModelDTO } from "Globals/Models/Domain";
import AddressViewModel from "Globals/ViewModels/AddressViewModel";
import { IsReferenceInUseRequest } from "Globals/Models";
import { AddEditContactViewModel } from "Views/Contacts/AddEditContactViewModel";
export class ClientAddEditViewModel extends ViewModelBase<ClientAddEditModel> {
    NEWITEM = "DELETEME_";

    private static _instance: ClientAddEditViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable public errorMessage: string = "";
    @observable public showAEModal: boolean = false;

    @observable
    private addressViewModels: AddressViewModel[] = [];

    @observable
    public contactFormViewModel = new AddEditContactViewModel();

    @observable
    public addressFormViewModel = new AddressViewModel(new AddressModel());

    private constructor() {
        super(new ClientAddEditModel(), false);
        this.setDecorators(ClientAddEditModel);
    }

    @action
    public showAddEditModal(show: boolean) {
        this.showAEModal = show;
    }

    @computed
    public get getShowAddEditModal() {
        return this.showAEModal;
    }

    @computed
    public get getAddressViewModels(): AddressViewModel[] {
        return this.addressViewModels.filter((address) => address.model.isDeleted === false);
    }

    @computed
    public get getModelAddress(): AddressModel {
        return this.model.getAddress;
    }

    @action
    public setNewClient = () => {
        this.model.reset();
    };

    @action public async addContact(contact: AddEditContactModel) {
        if (await this.contactFormViewModel.isModelValid()) {
            if (isNullOrUndefined(contact.id) === true) {
                runInAction(() => {
                    contact.id = this.NEWITEM + generateID();
                });
            }

            runInAction(() => {
                this.model.addContact(contact);
                this.contactFormViewModel = new AddEditContactViewModel();
            });
        }
    }

    @action
    public cleanUp = () => {
        // Any Cleanup Code here.
        // TODO CMR Clear down the client detail
        this.model.reset();
    };

    @action
    public upsert = async (e?: any): Promise<ApiResult<ClientWithRelatedDTO>> => {
        e?.preventDefault();

        if (await this.isMyModelValid()) {
            const request: ClientDetailModelDTO = this.model.toClientDetailModelDto();

            request.contacts.forEach((contact) => {
                if (contact.id?.startsWith(this.NEWITEM) === true) {
                    contact.id = undefined;
                }
            });

            let apiResult = await this.Post<ClientWithRelatedDTO>(AppUrls.Server.Client.Upsert, request);

            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.model.fromClientWithRelatedDto(apiResult.payload.client);
                });
            }
            return apiResult;
        } else {
            this.errorMessage = "Form is not valid";
            return Promise.reject();
        }
    };

    /**
     * Custom model validation function. Validates child models.
     * @returns True if model is valid, false if not.
     */
    private isMyModelValid = async (): Promise<boolean> => {
        let isValid = true;

        // Contact validation is handle before this point, as the contact form has its own "Add" button.

        if ((await this.addressFormViewModel.isModelValid()) === false) {
            isValid = false;
        }

        if ((await this.isModelValid()) === false) {
            isValid = false;
        }
        return isValid;
    };

    @action
    public async loadClientAsync(supplierId: string): Promise<ApiResult<ClientWithRelatedDTO>> {
        const request: GenericId = {
            id: supplierId,
        };

        let apiResult = await this.Post<ClientWithRelatedDTO>(AppUrls.Server.Client.GetDetails, request);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                this.setClient(apiResult.payload);
            });
        }
        return apiResult;
    }

    @action
    public setClient(payload: ClientWithRelatedDTO) {
        this.model.fromClientWithRelatedDto(payload.client);
    }

    public async isFieldValid(fieldName: keyof FieldType<ClientAddEditModel>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        if (fieldName === "reference") {
            errorMessage = this.isReferenceValid;
            isValid = errorMessage === "";
        }

        if (fieldName === "name") {
            errorMessage = this.isNameValid;
            isValid = errorMessage === "";
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public get isReferenceValid(): string {
        let error = "";

        const field: string | null = this.getValue<string | null>("reference");

        if (isNullOrEmpty(field)) {
            error = "Reference is required";
        } else if (field!.length > 128) {
            error = "Reference needs to be less than 15 characters";
        }

        return error;
    }

    public get isNameValid(): string {
        let error = "";

        const field: string | null = this.getValue<string | null>("name");

        if (isNullOrEmpty(field)) {
            error = "Name is required";
        } else if (field!.length > 128) {
            error = "Name needs to be less than 128 characters";
        }

        return error;
    }

    @action
    public getAddEditContact(contactId: string): AddEditContactModel {
        if (contactId.length === 0) {
            return new AddEditContactModel();
        } else {
            return new AddEditContactModel();
        }
    }

    @action
    public updatContactIsPrimary(id: string, isPrimary: boolean) {
        this.model.updatContactIsPrimary(id, isPrimary);
    }

    @action
    public deleteContact(id: string) {
        this.model.deleteContact(id);
    }

    public isReferenceInUse = async (id: string, reference: string): Promise<boolean> => {
        const request: IsReferenceInUseRequest = {
            id: id,
            reference: reference,
        };

        const apiResult = await this.Post<boolean>(AppUrls.Server.Client.IsReferenceInUse, request);

        if (apiResult.wasSuccessful === true && apiResult.payload === true) {
            runInAction(() => {
                this.IsErrored = true;
                this.setError("reference", "The reference address is already in use.");
                this.setValid("reference", false);
            });
        }

        return apiResult.payload;
    };

    @action
    public updateAddress(address: AddressModelDTO) {
        this.model.setAddress(0, address);
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
