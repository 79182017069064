import { action, observable } from "mobx";
import { ModelBase } from "@shoothill/core";

export class RFIListFilterParamsModel extends ModelBase<RFIListFilterParamsModel, RFIListFilterParamsModelDTO> {
    @observable
    public projectId: string = "";

    @observable
    public searchText: string = "";

    @observable
    public rfiId: string | null = null;

    @observable
    public rfiStatusId: string | null = null;

    fromDto(model: RFIListFilterParamsModelDTO): void {
        this.projectId = model.projectId;
        this.searchText = model.searchText;
        this.rfiId = model.rfiId;
        this.rfiStatusId = model.rfiStatusId;
    }

    toDto(): RFIListFilterParamsModelDTO {
        let dto: RFIListFilterParamsModelDTO = {
            projectId: this.projectId,
            searchText: this.searchText,
            rfiId: this.rfiId,
            rfiStatusId: this.rfiStatusId,
        };
        return dto;
    }

    @action
    public reset = () => {
        this.searchText = "";
        this.rfiId = null;
        this.rfiStatusId = null;
    };
}

export interface RFIListFilterParamsModelDTO {
    projectId: string;
    searchText: string;
    rfiId: string | null;
    rfiStatusId: string | null;
}
