import { Box } from "@material-ui/core";
import { Cell, Grid } from "@shoothill/core";
import { useObserver } from "mobx-react-lite";
import React from "react";
import styled from "styled-components";

import { DarwinSelect } from "Components/AutoComplete/DarwinSelect";
import { PrimaryButton } from "Components/Buttons/Buttons";
import { IEAdministratorRolesViewModel } from "./IEAdministratorRolesViewModel";
import { IncomeAndExpenditureViewModel } from "./IncomeAndExpenditureViewModel";

interface IProps {
    className?: string;
    viewModel: IEAdministratorRolesViewModel;
    ieViewModel: IncomeAndExpenditureViewModel;
}

const IEAdministrationRolesViewBase: React.FC<IProps> = (props) => {
    // #region Code Behind

    const COLUMNS8 = "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr";
    const COLUMNS12 = "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr";

    //const ieViewModel = IncomeAndExpenditureViewModel.Instance;

    // #endregion Code Behind

    return useObserver(() => (
        <Grid className={props.className} columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
            <Cell ts={8} ds={8}>
                <RoleUserPanel>
                    <Grid columnGap={30} rowGap={20} tc={COLUMNS12} dc={COLUMNS12}>
                        <Cell ts={4} ds={4}>
                            <DarwinSelect
                                displayName="Role:"
                                execute={props.viewModel.setRole}
                                fullWidth={true}
                                getOptionLabel={(option: any) => option.displayName}
                                onBlur={() => props.viewModel.isFieldValid("roleId")}
                                options={props.ieViewModel.roleOptions}
                                placeholder="Please select"
                                validationMessage={props.viewModel.getError("roleId")}
                                value={props.ieViewModel.role}
                            />
                        </Cell>
                        <Cell ts={4} ds={4}>
                            <DarwinSelect
                                displayName="User:"
                                execute={props.viewModel.setUser}
                                fullWidth={true}
                                getOptionLabel={(option: any) => option.displayName}
                                onBlur={() => props.viewModel.isFieldValid("userId")}
                                options={props.ieViewModel.userOptions}
                                placeholder="Please select"
                                validationMessage={props.viewModel.getError("userId")}
                                value={props.ieViewModel.user}
                                canExecute={props.ieViewModel.role != null}
                            />
                        </Cell>
                    </Grid>
                </RoleUserPanel>
            </Cell>
            <Cell ts={1} ds={1}>
                <PrimaryButton displayName="Add" execute={props.viewModel.add} fullWidth={true} />
            </Cell>
        </Grid>
    ));
};

export const RoleUserPanel = styled(Box)`
    border-top: 1px dashed rgb(206, 212, 218);
    margin-top: 20px;
    padding-top: 20px;
`;

export const IEAdministrationRolesView = styled(IEAdministrationRolesViewBase)``;

export const OrderLineUnitTotal = styled(Box)`
    display: flex;
    align-items: end;
    margin-bottom: 6px;
    font-size: 12px;
    font-weight: bold;
`;
