import { observable, runInAction } from "mobx";
import { ViewModelBase, FieldType } from "@shoothill/core";
import { DailyDiaryQuestionModel } from "./DailyDiaryQuestionModel";
import { ChangeEvent } from "react";
import { ServerViewModel } from "../../../../Globals/ViewModels/ServerViewModel";
import { DailyDiaryModelDTO } from "./DailyDiaryModel";
import { AppUrls } from "../../../../AppUrls";
import { StoresInstance } from "../../../../Globals/Stores";

export class DailyDiaryQuestionViewModel extends ViewModelBase<DailyDiaryQuestionModel> {
    public server: ServerViewModel = new ServerViewModel();
    constructor(model = new DailyDiaryQuestionModel()) {
        super(model);
        this.setDecorators(DailyDiaryQuestionModel);
    }

    public updateCompleted = async (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        return this.server.query<any>(
            () =>
                this.Post<DailyDiaryModelDTO>(`${AppUrls.Server.Projects.Construction.DailyDiary.Load}`, {
                    id: this.getValue("id"),
                    completed: checked,
                }),
            (result) => {
                this.setValue("completed", checked);
                if (checked) {
                    this.setValue("completedBy", StoresInstance.Domain.AccountStore.FullName);
                    this.setValue("completedByDate", new Date());
                } else {
                    this.setValue("completedBy", "");
                    this.setValue("completedByDate", null);
                }
            },
        );
    };

    public async isFieldValid(fieldName: keyof FieldType<DailyDiaryQuestionModel>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }
    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
