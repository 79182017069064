import { ModelBase, observable } from "@shoothill/core";
import { action, computed } from "mobx";
import moment from "moment";
export class MaterialStorageModel extends ModelBase<MaterialStorageModel> {
    public static readonly DEFAULT_ID = "";
    public static readonly DEFAULT_PROJECT_ID = "";
    public static readonly DEFAULT_MATERIAL_STORAGE_NOTE = "";
    public static readonly DEFAULT_MATERIAL_STORAGE_PHOTOS = [];
    public static readonly DEFAULT_ASSIGN_TO_USER_ID = "";
    public static readonly DEFAULT_CREATED_BY_USER_ID = "";
    public static readonly DEFAULT_CREATED_DATE = new Date();
    public static readonly DEFAULT_IS_DELETED = false;
    public static readonly DEFAULT_LAST_UPDATED_BY_USER_ID = "";
    public static readonly DEFAULT_LAST_UPDATED_DATE = "";
    public static readonly DEFAULT_ROW_VERSION = "";

    @observable
    public id: string = MaterialStorageModel.DEFAULT_ID;

    @observable
    public projectId: string | null = MaterialStorageModel.DEFAULT_PROJECT_ID;

    @observable
    public materialStorageNote: string | null = MaterialStorageModel.DEFAULT_MATERIAL_STORAGE_NOTE;

    @observable
    public materialStoragePhotos: MaterialStoragePhotosFile[] = MaterialStorageModel.DEFAULT_MATERIAL_STORAGE_PHOTOS;

    @observable
    public assignToUserId: string | null = MaterialStorageModel.DEFAULT_ASSIGN_TO_USER_ID;

    @observable
    public createdByUserId: string | null = MaterialStorageModel.DEFAULT_CREATED_BY_USER_ID;

    @observable
    public createdDate: any = MaterialStorageModel.DEFAULT_CREATED_DATE;

    @observable
    public isDeleted: boolean = MaterialStorageModel.DEFAULT_IS_DELETED;

    @observable
    public lastUpdatedByUserId: string | null = MaterialStorageModel.DEFAULT_LAST_UPDATED_BY_USER_ID;

    @observable
    public lastUpdatedDate: string | null = MaterialStorageModel.DEFAULT_LAST_UPDATED_DATE;

    @observable
    public rowVersion: string | null = MaterialStorageModel.DEFAULT_ROW_VERSION;

    @computed
    public get hasId(): boolean {
        return this.id !== null && this.id !== undefined && this.id !== "";
    }

    @computed
    public get formattedLastUpdatedDate(): string {
        return moment(this.lastUpdatedDate).format("DD/MM/YYYY @ HH:mm");
    }

    @action
    public fromDto(dto: MaterialStorageDTO): void {
        for (let key in dto) {
            if (dto.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(dto[key]);
                } else {
                    this[key] = dto[key];
                }
            }
        }
    }

    @action
    public fromMaterialStoragePhotosDTO(dto: MaterialStoragePhotosFile[]): void {
        let processedPhotos: MaterialStoragePhotosFile[] = [];
        for (const photo of dto) {
            processedPhotos.push(photo);
        }
        this.materialStoragePhotos = processedPhotos;
    }

    @action
    public toDto() {
        const MaterialStorageModel: MaterialStorageDTO = {
            id: this.id,
            projectId: this.projectId,
            materialStorageNote: this.materialStorageNote,
            assignToUserId: this.assignToUserId,
            createdByUserId: this.createdByUserId,
            createdDate: this.createdDate,
            isDeleted: this.isDeleted,
            lastUpdatedByUserId: this.lastUpdatedByUserId,
            lastUpdatedDate: this.lastUpdatedDate,
            rowVersion: this.rowVersion,
        };

        return MaterialStorageModel;
    }

    @action
    public reset = () => {
        this.id = MaterialStorageModel.DEFAULT_ID;
        this.projectId = MaterialStorageModel.DEFAULT_PROJECT_ID;
        this.materialStorageNote = MaterialStorageModel.DEFAULT_MATERIAL_STORAGE_NOTE;
        this.assignToUserId = MaterialStorageModel.DEFAULT_ASSIGN_TO_USER_ID;
        this.materialStoragePhotos = MaterialStorageModel.DEFAULT_MATERIAL_STORAGE_PHOTOS;
    };

    // #Start Custome Validation

    @computed
    public get validateMaterialStorageNote(): string {
        return this.materialStorageNote === MaterialStorageModel.DEFAULT_MATERIAL_STORAGE_NOTE ? "Please enter a note" : "";
    }

    @computed
    public get validateAssignToUserId(): string {
        return this.assignToUserId === MaterialStorageModel.DEFAULT_ASSIGN_TO_USER_ID ? "Please select a assign to" : "";
    }

    // #End Custome Validation
}

export interface MaterialStorageDTO {
    id: string | null;
    projectId: string | null;
    materialStorageNote: string | null;
    assignToUserId: string | null;
    createdByUserId: string | null;
    createdDate: string | null;
    isDeleted: boolean;
    lastUpdatedByUserId: string | null;
    lastUpdatedDate: string | null;
    rowVersion: string | null;
}

export interface MaterialStoragePhotosFile {
    id: string | null;
    materialStorageId: string | null;
    fileName: string;
    photoURL: string;
    createdByUserId: string | null;
    isDeleted: boolean;
    createdDate: string | null;
}

export interface AssignToUsersDTO {
    id: string;
    displayName: string;
}

export interface UpsertMaterialStorageRequestDTO {
    materialStorage: MaterialStorageDTO;
    materialStoragePhotos: MaterialStoragePhotosFile[];
}

export interface UpsertMaterialStorageResponseDTO {
    materialStorage: MaterialStorageDTO;
    materialStorageUsers: AssignToUsersDTO[];
    materialStoragePhotos: MaterialStoragePhotosFile[];
}

export interface MaterialStorageByIdResponseDTO {
    materialStorage: MaterialStorageDTO;
    materialStorageUsers: AssignToUsersDTO[];
    materialStoragePhotos: MaterialStoragePhotosFile[];
}

export interface MaterialStorageResponseDTO {
    materialStorage: MaterialStorageDTO;
    materialStorageList: MaterialStorageDTO[];
    materialStorageUsers: AssignToUsersDTO[];
    materialStoragePhotos: MaterialStoragePhotosFile[];
}

export interface FormRequestDTO {
    date: string | null;
    projectId: string | null;
    formId: string | null;
}
