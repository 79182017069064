import { action, computed } from "mobx";

import { FieldType, ViewModelBase } from "@shoothill/core";
import { PurchaseListModel } from "./PurchaseListModel";
import { AppUrls } from "AppUrls";
import { formatCurrencyFromPounds, formatDateTime } from "Utils/Format";
import { PurchaseListViewModel } from "./PurchaseListViewModel";

export class MyAmendListItemViewModel extends ViewModelBase<PurchaseListModel> {
    // #region Constructors and Disposers

    constructor() {
        super(new PurchaseListModel());
    }

    // #endregion Constructors and Disposers

    // #region Properties

    @computed
    public get refNameFormatted(): string {
        if (this.model.reference && this.model.name) {
            return `${this.model.reference} - ${this.model.name}`;
        } else if (this.model.reference) {
            return `${this.model.reference}`;
        } else if (this.model.name) {
            return `${this.model.name}`;
        }

        return ``;
    }

    @computed
    public get requestedOnDate() {
        return this.model.requestedDate;
    }

    @computed
    public get approvalSectionName() {
        return this.model.approvalSectionName;
    }

    @computed
    public get approvalTypeName() {
        return this.model.approvalTypeName;
    }

    @computed
    public get requestedBy() {
        return this.model.requestedBy;
    }

    @computed
    public get requisitionOrPOId() {
        return this.model.requisitionOrPOId;
    }

    @computed
    public get variationId() {
        return this.model.variationId;
    }

    @computed
    public get drawingId() {
        return this.model.drawingId;
    }

    @computed
    public get invoiceId() {
        return this.model.invoiceId;
    }

    @computed
    public get itemDescription() {
        return this.model.itemDescription;
    }

    @computed
    public get itemCost() {
        return this.model.itemCost;
    }

    @computed
    public get itemCostFormatted() {
        return this.model.itemCost ? formatCurrencyFromPounds(this.model.itemCost) : null;
    }

    @computed
    public get approverRoleName() {
        return this.model.approverRoleName;
    }

    @computed
    public get supplierName() {
        return this.model.supplierName;
    }

    // #endregion Properties

    // #region Actions

    @action
    public navigateToRequisition = () => {
        this.history.push(AppUrls.Client.PurchaseOrder.Edit.replace(":poid", this.requisitionOrPOId!).replace(":approvalMode", "false"));
    };

    @action
    public navigateToRequisitionApproval = () => {
        this.history.push(AppUrls.Client.PurchaseOrder.Edit.replace(":poid", this.requisitionOrPOId!).replace(":approvalMode", "true"));
    };

    @action
    public navigateToVariation = () => {
        this.history.push(AppUrls.Client.Variation.Edit.replace(":variationid", this.variationId!).replace(":approvalMode", "false"));
    };

    @action
    public navigateToDrawing = () => {
        this.history.push(AppUrls.Client.Project.OutputTracker.Drawings.Edit.replace(":drawingid", this.drawingId!).replace(":approvalMode", "false"));
    };

    @action
    public navigateToCPS = () => {
        this.history.push(AppUrls.Client.ClientProvisionalSum.Edit.replace(":variationid", this.variationId!).replace(":approvalMode", "false"));
    };

    @action
    public navigateToVariationApproval = () => {
        this.history.push(AppUrls.Client.Variation.Edit.replace(":variationid", this.variationId!).replace(":approvalMode", "true"));
    };

    @action
    public navigateToDrawingApproval = () => {
        this.history.push(AppUrls.Client.Project.OutputTracker.Drawings.Edit.replace(":drawingid", this.drawingId!).replace(":approvalMode", "true"));
    };

    @action
    public navigateToCPSApproval = () => {
        this.history.push(AppUrls.Client.ClientProvisionalSum.Edit.replace(":variationid", this.variationId!).replace(":approvalMode", "true"));
    };

    @action
    public navigateToInvoice = () => {
        this.history.push(AppUrls.Client.Invoicing.Edit.replace(":invoiceid", this.invoiceId!).replace(":approvalMode", "false"));
    };

    @action
    public navigateToInvoiceApproval = () => {
        this.history.push(AppUrls.Client.Invoicing.Edit.replace(":invoiceid", this.invoiceId!).replace(":approvalMode", "true"));
    };

    @action
    public navigateToForm = (parentViewModel: PurchaseListViewModel): void => {
        // TODO: Update this to use an enum.
        switch (this.model.approvalSectionName) {
            case "Purchase Requisition":
                this.navigateToRequisition();
                break;
            case "Variation":
                this.navigateToVariation();
                break;
            case "Client Provisional Sum":
                this.navigateToCPS();
                break;
            case "Invoice":
                this.navigateToInvoice();
                break;
            case "Material":
                this.navigateToMaterialApproval(parentViewModel);
                break;
            case "Stock":
                this.navigateToStockApproval(parentViewModel);
                break;
            case "Drawing":
                this.navigateToDrawing();
                break;
            default:
                break;
        }
    };

    @action
    public navigateToFormApproval = (): void => {
        switch (this.model.approvalSectionName) {
            case "Purchase Requisition":
                this.navigateToRequisitionApproval();
                break;
            case "Variation":
                this.navigateToVariationApproval();
                break;
            case "Client Provisional Sum":
                this.navigateToCPSApproval();
                break;
            case "Invoice":
                this.navigateToInvoiceApproval();
                break;
            case "Drawing":
                this.navigateToDrawingApproval();
                break;
            default:
                break;
        }
    };

    // #endregion Actions

    // #region Approval View

    @action
    private navigateToMaterialApproval = (parentViewModel: PurchaseListViewModel): void => {
        parentViewModel.navigateToMaterialApproval(this);
    };

    @action
    private navigateToStockApproval = (parentViewModel: PurchaseListViewModel): void => {
        parentViewModel.navigateToStockApproval(this);
    };

    // #endregion Approval View

    // #region Viewmodel Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public async isFieldValid(fieldName: keyof FieldType<PurchaseListModel>): Promise<boolean> {
        return true;
    }

    // #endregion Viewmodel Boilerplate
}
