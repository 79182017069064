import * as MobX from "mobx";
import { ModelBase } from "@shoothill/core";
export class RFIItemModel extends ModelBase<RFIItemModel, RFIItemModelDTO> {
    @MobX.observable
    public id: string = "";

    @MobX.observable
    public rfiCategoryId: string = "";

    @MobX.observable
    public categoryName: string = "";

    @MobX.observable
    public clientReference: string = "";

    @MobX.observable
    public supplierReference: string = "";

    @MobX.observable
    public supplierName: string = "";

    @MobX.observable
    public title: string = "";

    @MobX.observable
    public description: string = "";

    @MobX.observable
    public requestedDate: Date | null = null;

    @MobX.observable
    public requestedByName: string = "";

    @MobX.observable
    public requestedTo: string = "";

    @MobX.observable
    public responseRequiredByDate: Date | null = null;

    @MobX.observable
    public numberOfFiles: number = 0;

    @MobX.observable
    public rfiStatusId: string = "";

    @MobX.observable
    public statusName: string = "";

    @MobX.observable
    public revision: number = 0;

    @MobX.observable
    public latestResponseDate: Date | null = null;

    @MobX.observable
    public latestResponseName: string = "";

    fromDto(model: RFIItemModelDTO): void {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    toDto(model: RFIItemModel): void {}
}

export type RFIItemModelDTO = {
    id: string;
    rFICategoryId: string;
    categoryName: string;
    clientReference: string;
    supplierReference: string;
    supplierName: string;
    title: string;
    description: string;
    requestedDate: Date | null;
    requestedByName: string;
    requestedTo: string;
    responseRequiredByDate: Date | null;
    numberOfFiles: number;
    rFIStatusId: string;
    statusName: string;
    revision: number;
    latestResponseDate: Date | null;
    latestResponseName: string;
};
