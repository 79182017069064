import { Button, InputAdornment, TextField } from "@material-ui/core";
import { isEmptyOrWhitespace } from "@shoothill/core";
import { observer } from "mobx-react-lite";
import React from "react";
import { NumericFormat } from "react-number-format";

import { DarwinSelect } from "Components/AutoComplete/DarwinSelect";
import { SolidSectionSplitter } from "Components/Form/SectionSplitter";
import { BaseModal } from "Components/Modal/BaseModal";
import { SnackBar } from "Components/SnackBar/SnackBar";
import { StockTransactionType } from "Globals/Models/Enums/StockTransactionType";
import { CancelButton } from "Globals/Styles/Control/Buttons";
import { isTypeOfNumber } from "Utils/Utils";
import { ReportModalStyle } from "Views/Project/Modals/Modal.Styles";
import { FormModalSection, FormRow, FormLabelValueSection, FormReadonlyLabel, FormReadOnlyValue } from "Views/Stock/Shared/Shared.styles";
import { StockTransferViewModel } from "./StockTransferViewModel";
import { CategoryModel } from "./Supporting/CategoryModel";
import { IncomeAndExpenditureModel } from "./Supporting/IncomeAndExpenditureModel";
import { LineDescriptionModel } from "./Supporting/LineDescriptionModel";
import { ProjectModel } from "./Supporting/ProjectModel";
import { SubCategoryModel } from "./Supporting/SubCategoryModel";
import { StockTransferModel } from "./StockTransferModel";
import { DarwinMoneyInput } from "Globals/Styles/Control/DarwinInput";

interface IProps {
    viewModel: StockTransferViewModel;
}

export const StockTransferView: React.FC<IProps> = observer((props) => {
    const viewModel = props.viewModel;

    return (
        <BaseModal
            open={viewModel !== null}
            onClose={viewModel.cancel}
            title="Transfer stock"
            PaperComponent={ReportModalStyle}
            actions={
                <React.Fragment>
                    <Button autoFocus color="secondary" disabled={viewModel.server.IsBusy} onClick={viewModel.apiSaveTransferStockAsync} variant="contained">
                        Save
                    </Button>
                    <CancelButton onClick={viewModel.cancel}>Cancel</CancelButton>
                </React.Fragment>
            }
        >
            <FormModalSection>
                <FormRow grid dc={"1fr 1fr"}>
                    <DarwinSelect
                        displayName="Project:"
                        execute={viewModel.setProjectAsync}
                        fullWidth={true}
                        getOptionLabel={(option: ProjectModel) => option?.displayName ?? ""}
                        options={viewModel.projects}
                        placeholder="Please select"
                        validationMessage={viewModel.getError("project")}
                        value={viewModel.project}
                    />
                    <DarwinSelect
                        displayName="I & E:"
                        execute={viewModel.setIncomeAndExpenditureAsync}
                        fullWidth={true}
                        getOptionLabel={(option: IncomeAndExpenditureModel) => option?.displayName ?? ""}
                        options={viewModel.incomeAndExpenditures}
                        placeholder="Please select"
                        validationMessage={viewModel.getError("incomeAndExpenditure")}
                        value={viewModel.incomeAndExpenditure}
                    />
                </FormRow>
                <FormRow grid dc={"1fr 1fr"}>
                    <DarwinSelect
                        displayName="Category:"
                        execute={viewModel.setCategoryAsync}
                        fullWidth={true}
                        getOptionLabel={(option: CategoryModel) => option?.displayName ?? ""}
                        options={viewModel.categories}
                        placeholder="Please select"
                        validationMessage={viewModel.getError("category")}
                        value={viewModel.category}
                    />
                    <DarwinSelect
                        displayName="Sub-category:"
                        execute={viewModel.setSubCategoryAsync}
                        fullWidth={true}
                        getOptionLabel={(option: SubCategoryModel) => option?.displayName ?? ""}
                        options={viewModel.subCategories}
                        placeholder="Please select"
                        validationMessage={viewModel.getError("subCategory")}
                        value={viewModel.subCategory}
                    />
                </FormRow>
                <FormRow grid dc={"1fr 1fr"}>
                    <DarwinSelect
                        displayName="Line:"
                        execute={viewModel.setLineDescription}
                        fullWidth={true}
                        getOptionLabel={(option: LineDescriptionModel) => option?.displayName ?? ""}
                        options={viewModel.lineDescriptions}
                        placeholder="Please select"
                        validationMessage={viewModel.getError("lineDescription")}
                        value={viewModel.lineDescription}
                    />
                </FormRow>
            </FormModalSection>
            <SolidSectionSplitter />
            <FormModalSection>
                <FormRow grid dc={"1fr 1fr 1fr 1fr"}>
                    <DarwinSelect
                        displayName="From/To:"
                        execute={viewModel.setStockTransactionType}
                        fullWidth={true}
                        getOptionLabel={(option: StockTransactionType) => option?.displayName ?? ""}
                        options={viewModel.stockTransactionTypes}
                        placeholder="Please select"
                        validationMessage={viewModel.getError("stockTransactionType")}
                        value={viewModel.stockTransactionType}
                    />
                    <NumericFormat
                        customInput={TextField}
                        InputLabelProps={{ shrink: true }}
                        label={viewModel.currentQuantity}
                        onBlur={() => {
                            viewModel.isFieldValid("transferUnits");
                            // SIDE-EFFECT
                            // Having set the line-description, we need to try and evaluate the number
                            // of available units and the stock price.
                            viewModel.apiGetUnitsAndCostsAsync();
                        }}
                        onValueChange={(values: any) => {
                            viewModel.setValue("transferUnits", isTypeOfNumber(values.floatValue) ? values.floatValue : values.value);
                        }}
                        allowNegative={false}
                        decimalScale={0}
                        thousandSeparator={true}
                        suffix={" units"}
                        helperText={viewModel.getError("transferUnits")}
                        error={!isEmptyOrWhitespace(viewModel.getError("transferUnits"))}
                        value={viewModel.getValue("transferUnits")}
                    />
                    <FormLabelValueSection>
                        <FormReadonlyLabel>Stock value:</FormReadonlyLabel>
                        <FormReadOnlyValue>{viewModel.currentStockValue}</FormReadOnlyValue>
                    </FormLabelValueSection>
                </FormRow>
                <FormRow grid dc={"1fr 1fr"}>
                    <DarwinMoneyInput<StockTransferModel>
                        type="number"
                        label="Suggested future spend:"
                        placeholder=""
                        validateOnBlur={true}
                        viewModel={props.viewModel}
                        fieldName="suggestedFutureSpend"
                        shrink={true}
                        maxLength={32}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">£</InputAdornment>,
                        }}
                    />
                    <TextField
                        className="textarea-forminput"
                        InputLabelProps={{ shrink: true }}
                        label="Reason:"
                        onBlur={() => viewModel.isFieldValid("suggestedFutureSpendReason")}
                        onChange={(event) => viewModel.setValue("suggestedFutureSpendReason", event.target.value)}
                        helperText={viewModel.getError("suggestedFutureSpendReason")}
                        error={!isEmptyOrWhitespace(viewModel.getError("suggestedFutureSpendReason"))}
                        value={viewModel.getValue("suggestedFutureSpendReason")}
                    />
                </FormRow>
                <FormRow grid dc={"1fr"}>
                    <TextField
                        className="textarea-forminput"
                        InputLabelProps={{ shrink: true }}
                        label="Notes:"
                        onBlur={() => viewModel.isFieldValid("transferNote")}
                        onChange={(event) => viewModel.setValue("transferNote", event.target.value)}
                        helperText={viewModel.getError("transferNote")}
                        error={!isEmptyOrWhitespace(viewModel.getError("transferNote"))}
                        value={viewModel.getValue("transferNote")}
                    />
                </FormRow>
            </FormModalSection>
            <SnackBar
                messageText={viewModel.snackMessage}
                messageType={viewModel.snackType}
                active={viewModel.snackbarState}
                closeOption={() => viewModel.setSnackbarState(false)}
                autoHideDuration={5000}
            />
        </BaseModal>
    );
});
