export abstract class EnumType {
    public id: string = "";
    public displayName: string = "";
    public type: string = "";
    public ordinal: number = 0;
    public isDeleted: boolean = false;

    // #region Methods

    public IsOfType(type: string): boolean;
    public IsOfType(type: EnumType): boolean;

    public IsOfType(type: any): any {
        if (typeof type === "string") {
            return this.type === type;
        }

        if (type instanceof EnumType) {
            return this.type === type.type;
        }
    }

    public fromDto(dto: IEnumType) {
        this.id = dto.id;
        this.displayName = dto.displayName;
        this.type = dto.type;
        this.ordinal = dto.ordinal;
        this.isDeleted = dto.isDeleted;
    }

    // #endregion Methods
}

export interface IEnumType {
    id: string;
    displayName: string;
    type: string;
    ordinal: number;
    isDeleted: boolean;
}
