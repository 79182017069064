import { observable, runInAction, computed, action } from "mobx";
import { FieldType, ViewModelBase } from "@shoothill/core";

import { AppUrls } from "AppUrls";
import { InductionType } from "Globals/Models/Enums/InductionType";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { LandingModel } from "./LandingModel";
import { VisitorTypeModel } from "./VisitorTypeModel";
import { SupplierModel } from "../../PurchaseOrder/Form/Supporting/SupplierModel";

export class LandingViewModel extends ViewModelBase<LandingModel> {
    @observable public visitorTypes: any = [];
    @observable public suppliers: SupplierModel[] = [];
    @observable public allowNextStep: boolean = false;

    public showHeaderBar: (state: boolean) => void;
    public server: ServerViewModel = new ServerViewModel();

    constructor(showHeaderBar: (state: boolean) => void) {
        super(new LandingModel());

        this.showHeaderBar = showHeaderBar;
    }

    // #region Name

    @computed
    public get fullName() {
        return this.model.firstName + " " + this.model.lastName;
    }

    // #endregion Name

    // #region Induction Type selection

    @action
    public setInductionType = (value: InductionType | null): void => {
        this.setValue("inductionTypeId", value?.id ?? LandingModel.DEFAULT_INDUCTIONTYPEID);
    };

    @computed
    public get inductionType(): InductionType | null {
        return this.model.inductionTypes.find((r) => r.id === this.model.inductionTypeId) ?? null;
    }

    @computed
    public get inductionTypes() {
        return this.model.inductionTypes;
    }

    // #endregion Induction Type selection

    // #region Age

    @action
    public setPreferNotToStateAge = () => {
        this.setValue("preferNotToSay", !this.model.preferNotToSay);

        // SIDE-EFFECT
        // If the user does not want to state their age, reset it
        // to its default value.
        if (this.model.preferNotToSay) {
            this.setValue("age", LandingModel.DEFAULT_AGE);
        }
    };

    @action
    public setOtherEmployerName = () => {
        this.setValue("otherEmployerName", !this.model.otherEmployerName);

        // SIDE-EFFECT
        // Reset options.
        this.setValue("supplierId", "");
        this.setValue("priorityEmployerName", "");
    };

    @computed
    public get canSetAge() {
        return !this.model.preferNotToSay;
    }

    // #endregion Age

    @action
    public startYourInduction = async () => {
        if (await this.isModelValid()) {
            runInAction(() => {
                this.setIsLoading(true);
                this.showHeaderBar(true);
                this.history.push(AppUrls.Client.Induction.Step1);

                setTimeout(() => {
                    this.setIsLoading(false);
                }, 100);
            });
        }
    };

    public get startYourInductionCanExecute() {
        return true;
    }

    public visitorTypeById = (id: string) => {
        const result = this.visitorTypes.find((p: any) => p.id.toUpperCase() === id.toUpperCase());
        return result ? result!.displayName : "";
    };

    public setVisitorType = (value: VisitorTypeModel) => {
        this.setValue("visitorType", value.id);
    };

    public setSupplierType = (value: SupplierModel) => {
        this.setValue("supplierId", value.id);
        this.setValue("priorityEmployerName", value.displayName);
    };

    @computed
    public get visitorType() {
        const result = this.visitorTypes.find((p: any) => p.id === this.model.visitorType);
        return result ? result! : null;
    }

    @computed
    public get supplierType() {
        const result = this.suppliers.find((p: any) => p.id === this.model.supplierId);
        return result ? result! : null;
    }

    @computed
    public get isOver18(): boolean {
        return this.model.age >= 18 && !this.model.preferNotToSay;
    }

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<LandingModel>, value: any): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
