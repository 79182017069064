import * as MobX from "mobx";

import { IsNotEmpty } from "class-validator";
// Libs
import { ModelBase } from "@shoothill/core";

// App

export class Role extends ModelBase<Role, RoleDTO> {
    public id: string = "";
    @IsNotEmpty({ message: "Name is required" })
    @MobX.observable
    public name: string = "";
    public displayName: string = "";

    constructor(id: string) {
        super();
        this.id = id;
    }

    @MobX.action
    public fromDto = (model: RoleDTO) => {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                this[key] = model[key];
            }
        }
    };

    public toDto = (model: RoleDTO) => {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                model[key] = this[key];
            }
        }
    };
}

export interface RoleDTO {
    id: string;
    name: string;
    displayName: string;
}
