import styled from "styled-components";

/**
 * Styling for clear text button that appears next to input/select labels.
 */
export const ClearTextRoot = styled.div`
    color: #aaa;
    cursor: pointer;
    font-size: 0.825rem;
    font-weight: bold;
    min-width: 1.5rem;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0.5625rem;

    ::before {
        content: "\\2715";
    }

    &:hover {
        color: #d0021b;
    }
`;

/**
 * Styling for files controls
 */
export const FilesRoot = styled.div`
    margin-bottom: 1rem;

    .file-button {
        display: flex;

        button {
            align-items: center;
            background-color: #FFF;
            border: 1px dashed #ced4da;
            color: #bbb;
            display: flex;
            flex-direction: column;
            flex: 1;
            font-family: "Open Sans";
            font-weight: bold;
            height: 80px;
            justify-content: center;
            letter-spacing: 0.36;

            :hover {
                cursor: pointer;
            },
        }

        &.file-button-error {
            button {
                background-color: #EF9099;
                color: #8f1924;
            }
        }
    }

    .file-container: {
        max-height: 180px;
        overflow-y: auto;
    }
`;

/**
 * Styling for individual file controls
 */
export const FileRoot = styled.div`
    align-items: center;
    background-color: #FFF;
    border: 1px solid #ced4da;
    display: flex;
    height: 4rem;
    justify-content: space-between;
    position: relative;

    .fileinformation {
        align-items: center;
        display: flex;

        p,
        a {
            display: inline-block;
            font-size: 0.75rem !important;
            font-family: "Open Sans";
            word-break: break-all;
        },

        .image-container {
            height: 4rem;
        }

        .information-container {
            display: flex;
            flex-direction: column;
            padding: 0 1.5rem 0 0.5rem;
        }
    
        img,
        video {
            height: 4rem;
            width: 6rem;
            object-fit: cover;
        }
    },
    .fileicon {
        margin: 8px 0;
    },
    .documenturl {
        font-size: 0.75rem;
    },
    .filesizebytes {},
    .clear-button {},
`;

/**
 * Styling for individual file controls
 */
export const SingleFileRoot = styled.div`
    border-radius: 0;
    min-height: 15rem;
    overflow: hidden;
    position: relative;

    .file-button {
        align-items: center;
        background-color: #f9f9f9;
        border: 1px dashed #ced4da;
        display: flex;
        flex-direction: column;
        flex: 1;
        font-family: theme.defaultFontStyle.fontFamily;
        font-size: 10px;
        min-height: inherit;
        justify-content: center;
        letter-spacing: 0.36;
        text-transform: uppercase;
        width: 100%;

        :hover {
            cursor: pointer;
        },
    }

    .file-button-image {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        div {
            display: none;
        }

        :hover {
            background-image: none !important;

            div {
                display: flex;
            }            
        },
    }

    .file-button-delete {
        background-color: #e5e5e5;
        color: #c53030;
        min-height: 1.5rem;
        padding: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 1.5rem;
    }
`;
