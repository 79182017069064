import { ModelBase } from "@shoothill/core";
import { action, observable } from "mobx";

export class TermsAndConditionsModel extends ModelBase<TermsAndConditionsModel, TermsAndConditionsDTO> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Defaults and Constants

    public static readonly DEFAULT_ID = "";
    public static readonly DEFAULT_DISPLAYNAME = "";

    // #endregion Defaults and Constants

    // #region Observables

    @observable
    public id: string = TermsAndConditionsModel.DEFAULT_ID;

    @observable
    public displayName: string = TermsAndConditionsModel.DEFAULT_DISPLAYNAME;

    // #endregion Observables

    // #region Actions

    @action
    public reset(): void {
        this.id = TermsAndConditionsModel.DEFAULT_ID;
        this.displayName = TermsAndConditionsModel.DEFAULT_DISPLAYNAME;
    }

    @action
    public fromDto(dto: TermsAndConditionsDTO): void {
        this.id = dto.id;
        this.displayName = dto.name;
    }

    public toDto() {
        throw new Error("Method not implemented.");
    }

    // #endregion Actions

    // #region Helpers

    public static fromDtos(dtos: TermsAndConditionsDTO[]): TermsAndConditionsModel[] {
        const types: TermsAndConditionsModel[] = [];

        for (const dto of dtos) {
            const model = new TermsAndConditionsModel();

            model.fromDto(dto);
            types.push(model);
        }

        return types;
    }

    // #endregion Helpers
}

export interface TermsAndConditionsDTO {
    id: string;
    name: string;
}
