import { ModelBase } from "@shoothill/core";
import { IsNotEmpty } from "class-validator";
import { action, observable } from "mobx";

export class Step6Model extends ModelBase<Step6Model> {
    // #region Properties

    @observable
    @IsNotEmpty({ message: "You must sign in the box" })
    public signature: string = "";

    @observable
    @IsNotEmpty({ message: "You must provide your last 3 digits" })
    public last3PhoneDigits: string = "";

    // #endregion Properties

    constructor() {
        super();
    }

    // #region Actions

    @action
    public fromDto(dto: any): void {
        throw new Error("Method not implemented.");
    }

    public toDto(): any {
        return {
            signature: this.signature,
            last3PhoneDigits: this.last3PhoneDigits,
        };
    }

    // #endregion Actions
}
