import { observable, runInAction, action } from "mobx";
import { FieldType, ViewModelBase } from "@shoothill/core";
import { AppUrls } from "../../../../AppUrls";
import { ServerViewModel } from "../../../../Globals/ViewModels/ServerViewModel";
import { IncidentListAndRelatedDTO, IncidentListModel } from "./IncidentListModel";
import { IncidentAndRelatedResponseDTO } from "./Indicent/IncidentModel";
//import { IncidentTypesLookupModel } from "./IncidentTypesLookupModel";

export class IncidentListViewModel extends ViewModelBase<any> {
    public server: ServerViewModel = new ServerViewModel();
    public projectId: string = "";
    //@observable public IncidentFormType: IncidentTypesLookupModel | null = null;
    //@observable public incidentTypes: any = [];
    @observable public incidentListModels: IncidentListModel[] = [];
    constructor() {
        super();
    }

    // @action
    // public setIncidentForm = (type: IncidentTypesLookupModel) => {
    //     this.IncidentFormType = type;
    // };

    @action
    public loadIncidentsAndRelated = async (): Promise<void> => {
        this.setIsLoading(true);
        const request: any = {
            id: this.projectId,
        };
        let apiResult = await this.Post<IncidentListAndRelatedDTO>(AppUrls.Server.Projects.Construction.Incident.LoadIncidentsAndRelated, request);
        if (apiResult) {
            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    //this.IncidentTypes.replace(IncidentTypesLookupModel.fromDtos(apiResult.payload.IncidentTypes));
                    this.incidentListModels = [];
                    for (const incident of apiResult.payload.incidentListItems) {
                        let model = new IncidentListModel();
                        model.fromDto(incident);
                        this.incidentListModels.push(model);
                    }
                    //this.model.fromRelatedDto();
                });
            } else {
                console.log(apiResult.errors);
            }
        }
        this.setIsLoading(false);
    };

    public upsert = async (): Promise<void> => {
        this.setIsLoading(true);
        //const model: PermitDTO = this.model.toDto();

        const request: any = {
            incident: {
                id: null,
                projectId: this.projectId,
                incidentnumber: null,
                isDeleted: false,
                createdByUserId: null,
                rowVersion: null,
                lastUpdatedByUserId: null,
                lastUpdatedDate: null,
            },
        };

        return await this.server
            .command<IncidentAndRelatedResponseDTO>(
                () => this.Post(AppUrls.Server.Projects.Construction.Incident.Upsert, request),
                (result: IncidentAndRelatedResponseDTO) => {
                    runInAction(() => {
                        this.history.push(AppUrls.Client.Project.Incident.View.replace(":projectid", result.incident.projectId).replace(":incidentid", result.incident.id!));
                    });
                },
                this.isModelValid,
                "There was an error trying to send the incident",
            )
            .finally(() => this.setIsLoading(false));
    };

    @action
    public handleRowClick = (e: any, rowData: IncidentListModel) => {
        this.history.push(AppUrls.Client.Project.Incident.View.replace(":projectid", this.projectId).replace(":incidentid", rowData.id));
    };

    @action
    public addIncidentForm = async () => {
        //this.history.push(AppUrls.Client.Project.Incident.Add.replace(":projectid", this.projectId).replace(":IncidentTypeId", this.IncidentFormType?.id!));
        await this.upsert();
    };

    public async isFieldValid(fieldName: keyof FieldType<IncidentListModel>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }
    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
