import { createMuiTheme, createTheme, lighten, PaletteColorOptions, Theme, ThemeOptions } from "@material-ui/core";
import { colors } from "@material-ui/core";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { Palette, PaletteColor, TypeText, SimplePaletteColorOptions } from "@material-ui/core/styles/createPalette";
import { Overrides as CoreOverrides } from "@material-ui/core/styles/overrides";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { AlertClassKey, AutocompleteClassKey } from "@material-ui/lab";

import { CostCategoryPaletteColor, ProjectStatusPaletteColor, SupplierStatusPaletteColor, VariationStatusPaletteColor } from "./AppPallet";

interface Overrides extends CoreOverrides {
    // If you want to theme lab components, define here as needed.
    MuiAlert?: Partial<Record<AlertClassKey, CSSProperties | (() => CSSProperties)>> | undefined;
    MuiAutocomplete?: Partial<Record<AutocompleteClassKey, CSSProperties | (() => CSSProperties)>> | undefined;
}

export const pxToRem = (value: number) => {
    return `${value / 16}rem`;
};
const getTheme = (): Theme => {
    return createTheme(appTheme as ThemeOptions);
};

//Define your custom breakpoints here or accept the default values which are the standard common breakpoints
let customBreakpointValues = {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
};

///***************
///Add your Custom Colours below for typescript checking
///Probably only need to add to interface Palette as I have done here with accent
///***************
declare module "@material-ui/core/styles/createPalette" {
    interface IconPaletteColorOptions {
        pdf?: string;
        csv?: string;
    }
    interface IconPaletteColor {
        pdf: string;
        csv: string;
    }
    interface PaletteOptions {
        accent?: PaletteColorOptions;
        icon?: IconPaletteColorOptions;
    }

    ///***************
    ///Add your Custom Colours below for typescript
    ///***************
    interface Palette {
        purpleAccent: PaletteColor;
        customText: TypeText;
        icon: IconPaletteColor;
        cancel: PaletteColor;
        orange: PaletteColor;
        red: PaletteColor;
        green: PaletteColor;
        lightGrey: PaletteColor;
        mediumGrey: PaletteColor;
        blue: PaletteColor;
        costCategory: CostCategoryPaletteColor;
        projectStatus: ProjectStatusPaletteColor;
        supplierStatus: SupplierStatusPaletteColor;
        variationStatus: VariationStatusPaletteColor;
        detail: PaletteColor;
        navy: PaletteColor;
    }
}

let customPalette = {
    ///****************
    ///Customise the colours below to match your the designs
    ///****************
    common: {
        black: "#000000",
        white: "#ffffff",
    },
    projectStatus: {
        unknown: "#D4D4D4",
        draft: "#707070",
        pendingApproval: "#EB8D00",
        cappedLetter: "#1FDB57",
        fullOrder: "#0B98D9",
        completed12: "#0095DA",
        completedRetention: "#6C93A6",
    },
    costCategory: { unknown: "#D4D4D4", all: "#000000" },
    supplierStatus: { unknown: "#D4D4D4", draft: "#707070", pendingApproval: "#EB8D00", suspended: "#CE0000", verified: "#52BB71", expired: "#CE0000" },
    variationStatus: {
        pendingClientApproval: "#eb8d00",
        pendingInternalApproval: "#eb8d00",
        approved: "#6c93a6",
        rejectedByClient: "#ce0000",
        rejectedInternally: "#ce0000",
        draft: "#707070",
        approvedByClient: "#52adbb",
        repairRequired: "#52bb71",
        amendRequired: "#52bb71",
    },
    background: { paper: "#fcfcfc", default: "#fcfcfc" },
    primary: { light: "#95CCE6", dark: "#465185", main: "#0095DA", contrastText: "#ffffff" },
    secondary: { light: "#4f83cc", main: "#01579b", dark: "#002f6c", contrastText: "#ffffff" },
    error: { light: "#e57373", main: "#f44336", dark: "#d32f2f", contrastText: "#ffffff" },
    warning: { light: "#ffb74d", main: "#ff9800", dark: "#f57c00", contrastText: "rgba(0, 0, 0, 0.87)" },
    info: { light: "#64b5f6", main: "#2196f3", dark: "#1976d2", contrastText: "#fff" },
    success: { light: "#81c784", main: "#4caf50", dark: "#388e3c", contrastText: "rgba(0, 0, 0, 0.87)" },
    text: {
        primary: "rgba(0,0,0, 0.87)",
        secondary: "rgba(0,0,0, 0.54)",
        disabled: "rgba(0,0,0, 0.38)",
        hint: "rgba(0,0,0, 0.38)",
    },
    divider: "rgba(0,0,0,0.12)",
    ///***************
    ///Add your Custom Colours below and in the declare module above this code block
    ///***************
    cancel: { light: lighten("#191919", 0.5), main: "#191919", dark: "", contrastText: "" },
    purpleAccent: { light: colors.purple[300], main: colors.purple[500], dark: colors.purple[700], contrastText: "#ffb74d" },
    customText: {
        primary: "rgba(255,255,255, 0.87)",
        secondary: "rgba(255,255,255, 0.54)",
        disabled: "rgba(255,255,255, 0.38)",
        hint: "rgba(255,255,255, 0.38)",
    },
    orange: { light: "#eb8d00", main: "#eb8d00", dark: "#eb8d00", contrastText: "#eb8d00" },
    red: { light: "#ecb4b4", main: "#df3a3a", dark: colors.red[700], contrastText: "#ce0000" },
    blue: { light: "#6c93a6", main: "#0095da", dark: "#0095da", contrastText: "#ffffff" },
    green: { light: "#1fdb57", main: "#1fdb57", dark: "#52bb71", contrastText: "#ffffff" },
    lightGrey: { light: "#fcfcfc", main: "#00000029", dark: "#cdcccc", contrastText: "#191919" },
    mediumGrey: { light: "#00000029", main: "#ced4da", dark: "#707070", contrastText: "#191919" },
    icon: { csv: "rgba(255,255,255, 0.87)", pdf: "rgba(255,255,255, 0.87)" },
    detail: { main: "#425B66" },
    black: { main: "#191919" },
    navy: { main: "#047ab1" },
} as unknown as Palette;

const customComponentOverrides: Overrides = {
    MuiButton: {
        root: {
            textTransform: "none",
            fontSize: "14px",
            borderRadius: "23px",
        },
    },
    MuiSelect: {
        root: {
            backgroundColor: "#ffffff !important",
            //backgroundColor: "green",
            color: "#757474 !important",
            fontWeight: "normal",
            fontFamily: ["Open Sans", "Roboto Condensed", "Helvetica", "Arial", "sans-serif"].join(","),
            "&.Mui-disabled": {
                color: "rgba(0,0,0, 0.38) !important",
            },
        },
        selectMenu: {
            width: "100%",
        },
        select: {
            //minWidth: "200px",
        },
    },
    MuiAutocomplete: {
        input: {
            paddingLeft: "10px !important",
        },
        listbox: {
            padding: 0,
        },
        noOptions: {
            padding: `${pxToRem(6)} ${pxToRem(10)}`,
        },
        paper: {
            background: "white",
            border: "1px solid #CED4DA",
            borderRadius: 0,
            fontSize: pxToRem(12),
            margin: "-1px 0 0 0",
        },
        popper: {},
    },
    MuiInput: {
        root: {
            fontSize: pxToRem(12),
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            border: "1px solid #D4D4D4",
            backgroundColor: "#ffffff !important",
            borderRadius: "0px",
            color: "#757474 !important",
            fontWeight: "normal",
            opacity: "1",
            textAlign: "left",
            letterSpacing: "0px",
            "&.Mui-disabled": {
                color: "rgba(0,0,0, 0.38) !important",
                border: "1px solid #D4D4D4D1 !important",
            },
        },
        input: {
            paddingLeft: "10px",
            paddingRight: "10px",
            "&:-internal-autofill-selected": {
                backgroundColor: "#FFF !important",
            },
            "& .-internal-autofill-selected": {
                backgroundColor: "#FFF !important",
            },
        },
        underline: {
            "&::before": {
                border: "none !important",
            },
        },
    },
    MuiTextField: {
        root: {
            width: "100%",
        },
    },
    MuiFormControlLabel: {
        label: {
            fontSize: `${pxToRem(12)}`,
            fontWeight: 400,
            letterSpacing: pxToRem(-0.24),
        },
    },
    MuiFormLabel: {
        root: {
            color: `${customPalette.text?.primary} !important`,
            fontSize: `${pxToRem(11)} !important`,
            fontWeight: 600,
            letterSpacing: pxToRem(-0.18),
            transform: `translate(0, 0.09375rem) scale(1)`,
        },
    },
    MuiInputLabel: {
        formControl: {
            textAlign: "left",
            fontFamily: "Open Sans",
            letterSpacing: "0px",
            fontWeight: 600,
            color: "#040505",
            opacity: 1,
            height: pxToRem(19),
            marginBottom: pxToRem(5),
        },
        shrink: {
            color: `${customPalette.text?.primary} !important`,
            fontSize: `${pxToRem(11)} !important`,
            letterSpacing: pxToRem(-0.18),
            transform: `translate(0, ${pxToRem(1.5)}) scale(1)`,
            "& + .MuiInput-formControl": {
                marginTop: pxToRem(21),
            },
            "& + .MuiAutocomplete-root": {
                marginTop: pxToRem(21),
            },
        },
    },
    MuiFormHelperText: {
        root: {
            fontSize: pxToRem(11),
        },
    },
    MuiAlert: {
        root: {
            borderRadius: 0,
        },
        standardError: {
            backgroundColor: "#170b0b",
            color: "#f5c9c9",
        },
        standardWarning: {
            backgroundColor: "#191207",
            color: "#ffe2b7",
        },
        standardInfo: {
            backgroundColor: "#071318",
            color: "#b8e7fb",
        },
        standardSuccess: {
            backgroundColor: "0c130d",
            color: "#cce8cd",
        },
    },
    /* MuiCheckbox: {
        root: {
            "& svg": {
                border: "1px solid #CDCCCC",
                borderRadius: "5px",
                "& > path": {
                    display: "none",
                },
            },
        },
        colorSecondary: {
            "&.Mui-checked svg": {
                backgroundColor: (customPalette.secondary as SimplePaletteColorOptions).main,
                "& > path": {
                    display: "initial",
                    fill: "white",
                    height: "20px",
                    width: "20px",
                    transform: "scale(1.4)",
                    transformOrigin: "50% 50%",
                },
            },
        },
        colorPrimary: {
            "&.Mui-checked svg": {
                backgroundColor: (customPalette.primary as SimplePaletteColorOptions).main,
                "& > path": {
                    display: "initial",
                    fill: "white",
                    height: "20px",
                    width: "20px",
                    transform: "scale(1.4)",
                    transformOrigin: "50% 50%",
                },
            },
        }, 
    },*/
};

export const customBreakpoints = createBreakpoints({ values: customBreakpointValues });
export const appTheme = createTheme({
    customBreakpointValues,
    ///**************
    ///Set up your spacing here according to Daves designs
    ///Dave tends to do multiples of 10. so p={1} will be 10px padding. p(5) will be 50px padding
    ///The code below will shrink the padding on small devices
    ///**************
    spacing: (value: number) => {
        let spacing = 10; //Default spacing on desktop
        const screenWidth = document.body.clientWidth;
        if (screenWidth <= appTheme.breakpoints.values["md"]) spacing /= 2;
        if (screenWidth > appTheme.breakpoints.values["md"] && screenWidth <= appTheme.breakpoints.values["lg"]) spacing /= 1.5;
        if (screenWidth > appTheme.breakpoints.values["lg"]) spacing /= 1;
        return value * spacing;
    },
    ///**************
    ///Set up your text sizes here to match Dave's designs
    ///You can also set up the values text should be on mobile devices if differenct
    ///**************
    typography: {
        fontFamily: `"Open Sans", sans-serif`,
        h1: {
            fontSize: pxToRem(28),
            fontWeight: 600,
            letterSpacing: pxToRem(-0.56),
            [customBreakpoints.down("sm")]: {
                fontSize: pxToRem(16),
            },
        },
        h2: {
            fontSize: pxToRem(18),
            fontWeight: 700,
            [customBreakpoints.down("sm")]: {
                fontSize: pxToRem(12),
            },
        },
        h3: {
            fontSize: pxToRem(16),
            fontWeight: 700,
            [customBreakpoints.down("sm")]: {
                fontSize: pxToRem(12),
            },
        },
        h4: {
            fontSize: pxToRem(14),
            fontWeight: 700,
            [customBreakpoints.down("sm")]: {
                fontSize: pxToRem(10),
            },
        },
        h5: {
            fontSize: pxToRem(12),
            fontWeight: 700,
            [customBreakpoints.down("sm")]: {
                fontSize: pxToRem(8),
            },
        },
        h6: {
            fontSize: pxToRem(10),
            fontWeight: 700,
            [customBreakpoints.down("sm")]: {
                fontSize: pxToRem(6),
            },
        },
        subtitle1: {
            fontSize: pxToRem(16),
            [customBreakpoints.down("sm")]: {
                fontSize: pxToRem(14),
            },
        },
        subtitle2: {
            fontSize: pxToRem(14),
            [customBreakpoints.down("sm")]: {
                fontSize: pxToRem(10),
            },
        },
        body1: {
            fontSize: pxToRem(16),
            [customBreakpoints.down("sm")]: {
                fontSize: pxToRem(14),
            },
        },
        body2: {
            fontSize: pxToRem(14),
            [customBreakpoints.down("sm")]: {
                fontSize: pxToRem(10),
            },
        },
        button: {
            fontSize: pxToRem(16),
            [customBreakpoints.down("sm")]: {
                fontSize: pxToRem(14),
            },
        },
        caption: {
            fontSize: pxToRem(14),
            [customBreakpoints.down("sm")]: {
                fontSize: pxToRem(10),
            },
        },
    },
    ///***************
    ///Set up your material ui overrides here or in the index.scss file. Scss will probably be faster and easier overall
    ///***************
    overrides: customComponentOverrides,
} as ThemeOptions);

export let theme = getTheme();
theme.breakpoints = customBreakpoints;
theme.palette = { ...theme.palette, ...customPalette };
export const palette = theme.palette;
window.theme = theme;
export const defaultTheme = theme;

//console.log("Theme done");
