import { isEmptyOrWhitespace } from "@shoothill/core";

export class MaterialsListItemViewModel {
    // #region Properties

    public id: string = "";
    public description: string = "";
    public materialType: string = "";
    public materialSubType: string = "";
    public supplierCode: string = "";
    public unitOfMeasure: string = "";
    public estimatedPrice: number = 0;
    public price: number = 0;
    public effectiveFromDate: string = "";
    public supplier: string = "";
    public canEditPrice: boolean = false;

    // #endrgion Properties

    // #region Filtering

    public matchesFilter = (filterString: string): boolean => {
        if (isEmptyOrWhitespace(filterString)) {
            return true;
        }

        const filterStringUpperCase = filterString.toUpperCase();

        return this.description.toUpperCase().includes(filterStringUpperCase);
    };

    // #endregion Filtering
}
