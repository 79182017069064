import * as React from "react";
import { Button, Typography } from "@material-ui/core";

import { EditContactModalDialogPaper, Main } from "./AddEditSupplierModal.styles";
import { BaseModal } from "Components/Modal/BaseModal";
import { CancelButton } from "Globals/Styles/Control/Buttons";
import { useEffect } from "react";
import { useObserver } from "mobx-react-lite";
import { AddSupplierViewModel } from "./AddSupplierViewModel";
import { AddSupplierView } from "./AddSupplierView";

interface IAddSupplierModalProps {
    open?: boolean;
    title: string;
    text: string;
    onSave(): void;
    onClose(): void;
}

export const AddSupplierModal: React.FunctionComponent<IAddSupplierModalProps> = ({ open, onSave, onClose, title }) => {
    const viewModel: AddSupplierViewModel = AddSupplierViewModel.Instance;

    const [errorMessage, setErrorMessage] = React.useState<string>("");

    //useEffect below only gets run on initial render
    useEffect(() => {
        return () => {
            viewModel.cleanUp();
        };
    }, []);

    React.useEffect(() => {
        if (open) {
        }
    }, [open]);

    const onSaveLocal = () => {
        setErrorMessage("");
        const modelPromise: Promise<boolean> = viewModel.isMyModelValid();
        modelPromise.then((isValid: boolean) => {
            if (isValid === true) {
                let upsertPromise = viewModel.insert();
                upsertPromise.then((response) => {
                    if (response.wasSuccessful) {
                        onSave();
                        onClose();
                    }
                });
            } else {
                setErrorMessage("Please fix the following errors and re-submit");
            }
        });
    };

    return useObserver(() => (
        <BaseModal
            dialogClasses="supplier-dialog"
            open={open}
            onClose={onClose}
            title={title}
            PaperComponent={EditContactModalDialogPaper}
            actions={
                <>
                    <Button onClick={onSaveLocal} color="secondary" autoFocus variant="contained" disabled={viewModel.IsLoading}>
                        Add new supplier
                    </Button>
                    <CancelButton onClick={onClose}>Cancel & return to suppliers</CancelButton>
                </>
            }
        >
            {viewModel !== null && (
                <Main>
                    {errorMessage !== "" && (
                        <Typography variant="caption" style={{ color: "red" }}>
                            {errorMessage}
                        </Typography>
                    )}
                    <AddSupplierView />
                </Main>
            )}
        </BaseModal>
    ));
};
