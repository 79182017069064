import { ModelBase, observable } from "@shoothill/core";

export class ToolboxTalkListModel extends ModelBase<ToolboxTalkListModel> {
    public id: string = "";
    @observable
    public individualCount: number = 0;
    @observable
    public subject: string = "";
    @observable
    public talkerUserName: string = "";
    @observable
    public createdDate: string = "";
    @observable
    public lastUpdatedDate: string = "";
    @observable
    public lastUpdatedByUserName: string = "";

    constructor() {
        super();
    }

    fromDto(model: ToolboxTalkListDTO): void {
        this.id = model.id;
        this.individualCount = model.individualCount;
        this.subject = model.subject;
        this.talkerUserName = model.talkerUserName;
        this.createdDate = model.createdDate;
        this.lastUpdatedDate = model.lastUpdatedDate;
        this.lastUpdatedByUserName = model.lastUpdatedByUserName;
    }
    toDto(model: ToolboxTalkListModel): void {
        throw new Error("Method not implemented.");
    }
}

export interface ToolboxTalkListDTO {
    id: string;
    individualCount: number;
    subject: string;
    talkerUserName: string;
    incidentNumber: number;
    createdDate: string;
    lastUpdatedDate: string;
    lastUpdatedByUserName: string;
}

export interface ToolboxTalkListAndRelatedDTO {
    toolboxTalkListItems: ToolboxTalkListDTO[];
}
