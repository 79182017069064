import MaterialTable from "material-table";
import React, { useContext, useEffect, useState } from "react";
import { useObserver, useRouter } from "@shoothill/core";
import { ClientListViewModel } from "./ClientListViewModel";
import * as Defaults from "Globals/Defaults/TableOptions";
import { AppUrls } from "AppUrls";
import { Box, Typography } from "@material-ui/core";
import { DarwinPage, DarwinPageTitle, DarwinTablePageContent } from "Globals/Styles/AppStyling";
import { ClientAddEditModal } from "./ClientAddEditModal";
import { ClientWithRelatedDTO } from "./ClientWithRelatedDTO";
import { PatchedPagination } from "../../../Components/Table/PatchedPagination";

export const ClientListView: React.FunctionComponent = () => {
    const [viewModel] = useState(() => ClientListViewModel.Instance);

    const { history } = useRouter();

    //useEffect below only gets run on initial render
    useEffect(() => {
        const apiResult = viewModel.loadClientsAsync();

        apiResult.then((result) => {});

        return () => {
            viewModel.cleanUp();
        };
    }, []);

    const handleRowClick = (e: any, rowData: any) => {
        const url: string = AppUrls.Client.Admin.Client.Detail.replace(":clientid", rowData.id);
        history.push(url);
    };

    const handleModalClose = () => {
        viewModel.showAddEditModal(false);
        history.push(AppUrls.Client.Admin.Client.List);
    };

    const onSave = (editedClient: ClientWithRelatedDTO): any => {
        viewModel.loadClientsAsync();
    };

    const renderPage = () => {
        return (
            <>
                <ClientAddEditModal onClose={handleModalClose} open={viewModel.getShowAddEditModal} title="Add new client" confirmText="Add client" onSave={onSave} />
                <DarwinPage>
                    <DarwinPageTitle>
                        <Typography variant="h1" color="textPrimary">
                            Clients
                        </Typography>
                    </DarwinPageTitle>
                    <DarwinTablePageContent>
                        <MaterialTable
                            columns={[
                                { title: "Client ref", field: "clientRef" },
                                { title: "Client name", field: "name" },
                                { title: "Project contact", field: "projectContact" },
                                { title: "Contact email", field: "contactEmail" },
                                { title: "Contact phone", field: "contactPhone" },
                                { title: "Date added", field: "createdDate" },
                            ]}
                            components={{
                                Pagination: PatchedPagination,
                            }}
                            options={Defaults.GetDarwinTableOptions()}
                            data={viewModel.getClientsList}
                            onRowClick={handleRowClick}
                            title=""
                        />
                    </DarwinTablePageContent>
                </DarwinPage>
            </>
        );
    };

    return useObserver(() => renderPage());
};
