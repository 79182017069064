import { FieldType, ViewModelBase } from "@shoothill/core";
import { action, computed, observable } from "mobx";
import { formatCurrencyFromPounds } from "Utils/Format";
import { InvoicePurchaseOrderModel } from "./InvoicePurchaseOrderModel";
import { InvoiceGroupViewModel } from "./InvoiceGroupViewModel";

export class InvoicePurchaseOrderViewModel extends ViewModelBase<InvoicePurchaseOrderModel> {
    // #region Constructors and Disposers

    constructor(model: InvoicePurchaseOrderModel) {
        super(model);
        this.setDecorators(InvoicePurchaseOrderViewModel);

        this.createViewModels();
    }

    @observable
    public invoiceGroups: InvoiceGroupViewModel[] = [];

    @computed
    public get getInvoiceGroups(): InvoiceGroupViewModel[] {
        return this.invoiceGroups;
    }

    @computed
    public get getInitialValue(): number {
        return this.getInvoiceGroups.map((item) => item.getInitialValue).reduce((prev, next) => parseFloat((prev + next).toFixed(2)), 0);
    }

    @computed
    public get getAlreadyAllocated(): number {
        return this.getInvoiceGroups.map((item) => item.getAlreadyAllocated).reduce((prev, next) => parseFloat((prev + next).toFixed(2)), 0);
    }

    @computed
    public get getAvailableBalance(): number {
        return this.getInvoiceGroups.map((item) => item.getAvailableBalance).reduce((prev, next) => parseFloat((prev + next).toFixed(2)), 0);
    }

    @computed
    public get getAmountAllocated(): number {
        return this.getInvoiceGroups.map((item) => item.getAmountAllocated).reduce((prev, next) => parseFloat((prev + next).toFixed(2)), 0);
    }

    @computed
    public get isAmountAllocatedValid(): boolean {
        // RULES
        // The amount allocated must be defined.
        return Number(this.getAmountAllocated) === 0 || isNaN(Number(this.getAmountAllocated)) ? false : true;
    }

    @computed
    public get getInitialValueFormatted(): string {
        if (this.getInitialValue !== 0) {
            return formatCurrencyFromPounds(this.getInitialValue);
        } else {
            return "---";
        }
    }

    @computed
    public get getInvoiceValueFormatted(): string {
        if (this.model.invoiceValue) {
            return formatCurrencyFromPounds(this.model.invoiceValue);
        } else {
            return "N/A";
        }
    }

    @computed
    public get getAlreadyAllocatedFormatted(): string {
        if (this.getAlreadyAllocated !== 0) {
            return formatCurrencyFromPounds(this.getAlreadyAllocated);
        } else {
            return "---";
        }
    }

    @computed
    public get getAvailableBalanceFormatted(): string {
        if (this.getAvailableBalance !== 0) {
            return formatCurrencyFromPounds(this.getAvailableBalance);
        } else {
            return "---";
        }
    }

    @computed
    public get getAmountAllocatedFormatted(): string {
        if (this.getAmountAllocated !== 0) {
            return formatCurrencyFromPounds(this.getAmountAllocated);
        } else {
            return "---";
        }
    }

    @action
    private createViewModels() {
        for (const item of this.model.invoiceGroups) {
            this.invoiceGroups.push(new InvoiceGroupViewModel(item));
        }
    }

    @computed
    public get invoiceValueNumber(): number {
        return this.model.invoiceValue ? Number(this.model.invoiceValue) : 0;
    }

    @action
    public reset = () => {
        this.model.reset();
    };

    // #endregion Client Actions

    /**
     * Custom model validation function. Validates child category models and its children
     * @returns True if model is valid, false if not.
     */
    public isMyModelValid = async (): Promise<boolean> => {
        let isValid = true;

        // JC: Changed forEach into for loop as the await seems to have issues with forEach.
        for (let i = 0; i < this.invoiceGroups.length; i++) {
            let item = this.invoiceGroups[i];

            // Validate each child item.
            if ((await item.isMyModelValid()) === false) {
                isValid = false;
            }
        }

        return isValid;
    };

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<InvoicePurchaseOrderModel>): Promise<boolean> {
        return true;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
