import React from "react";
import { Box } from "@material-ui/core";
import { UnsupportedBrowserView } from "../../../Views/UnsupportedBrowser/UnsupportedBrowserView";

//EN: use empty generic type to get default react props
export const LayoutNoHeader: React.FC<{}> = (props) => {
    return (
        <>
            {window.IE11 ? (
                <UnsupportedBrowserView />
            ) : (
                <>
                    <Box id={"content"}>
                        <div>{props.children}</div>
                    </Box>
                </>
            )}
        </>
    );
};
