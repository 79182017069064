import * as MobX from "mobx";
import { ModelBase } from "@shoothill/core";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class ProjectListModel extends ModelBase<ProjectListModel, ProjectListModelDTO> {
    // #region Constructors and Disposers

    constructor(model: ProjectListModelDTO | null = null) {
        super();

        if (model) {
            this.fromDto(model);
        }
    }

    // #region Constructors and Disposers

    @MobX.observable
    public id: string = "";

    @MobX.observable
    public name: string = "";

    @MobX.observable
    public reference: string = "";

    @MobX.observable
    public currentAuthorisedValue: number = -99;

    @MobX.observable
    public cell: number = -99;

    @MobX.observable
    public projectApprovalTypeId: string = "";

    @MobX.observable
    public projectStatusTypeId: string = "";

    //fromDto is required but you can leave it blank
    fromDto(model: ProjectListModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: ProjectListModel): void {}
}

export type ProjectListModelDTO = {
    id: string;
    name: string;
    reference: string;
    currentAuthorisedValue: number;
    cell: number;

    projectApprovalTypeId: string;
    projectStatusTypeId: string;
};
