import { FieldType } from "@shoothill/core";
import { computed } from "mobx";

import { InvoiceApprovalPanelModel } from "./InvoiceApprovalPanelModel";
import { ApprovalPanelViewModelBase } from "Globals/ViewModels/ApprovalPanelViewModelBase";
import { ApprovalHistoryInvoiceViewModel } from "Components/ApprovalHistoryInvoice/ApprovalHistoryInvoiceViewModel";

export class InvoiceApprovalPanelViewModel extends ApprovalPanelViewModelBase<InvoiceApprovalPanelModel> {
    // #region Constructors and Disposers

    public approvalHistoryViewModel = new ApprovalHistoryInvoiceViewModel();

    constructor() {
        super(InvoiceApprovalPanelModel);
        this.setDecorators(InvoiceApprovalPanelViewModel);
    }

    // #endregion Constructors and Disposers

    @computed
    public get getCanUpsertStatus(): boolean {
        const isSubmittedForApproval = this.model.approvalStatusId === this.getSubmittedForApprovalStatusId;
        const isDraft = this.model.approvalStatusId === this.getDraftStatusId;

        const isApprover = this.model.isApprover;

        if (isApprover) {
            return !this.model.isResolved && (isSubmittedForApproval || isDraft);
        }

        return false;
    }

    // #region Server Actions

    // #endregion Server Actions

    // #region Client Actions

    // #endregion Client Actions

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<any>): Promise<boolean> {
        return true;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
