import * as React from "react";
import styled from "styled-components";

import { BaseModal } from "Components/Modal/BaseModal";
import { CancelButton } from "Globals/Styles/Control/Buttons";
import { useObserver } from "mobx-react-lite";
import { EditContactModalDialogPaper } from "Views/Contacts/EditContactModal.styles";
import { PrimaryButton } from "Components/Buttons/Buttons";

interface IInvalidDocumentsModalProps {
    open?: boolean;
    onClose(): void;
    onSubmit(): void;
    title: string;
    message: string;
    isLoading: boolean;
    items: string[];
    saveText: string;
}

export const InvalidDocumentsModal: React.FunctionComponent<IInvalidDocumentsModalProps> = ({ open, onClose, onSubmit, title, isLoading, message, items, saveText }) => {
    const renderBody = () => {
        return (
            <>
                <div>{message}</div>
                <ul style={{ paddingLeft: "20px" }}>
                    {items.map((item, index) => {
                        return <li key={index}>{item}</li>;
                    })}
                </ul>
            </>
        );
    };

    return useObserver(() => (
        <BaseModal
            open={open}
            onClose={onClose}
            title={title}
            PaperComponent={(props) => <InvalidDocumentsModalDialogPaper {...props} />}
            actions={
                <>
                    {/* May add back later. */}
                    {/* <PrimaryButton displayName={saveText} execute={onSubmit} fullWidth={false} canExecute={true} /> */}
                    <CancelButton onClick={onClose}>Close</CancelButton>
                </>
            }
        >
            <div>{renderBody()}</div>
        </BaseModal>
    ));
};

export const InvalidDocumentsModalDialogPaper = styled(EditContactModalDialogPaper)`
    width: 100% !important;
    max-width: 1000px;
    max-width: "1000px";

    .MuiDialogActions-root > button {
        max-width: 262px;
    }

    .MuiDialogContent-root {
        margin-bottom: 20px;
    }

    .MuiDivider-root {
        background-color: rgba(0, 0, 0, 0.12) !important;
    }
`;
