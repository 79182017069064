import { Box, InputAdornment, TextField } from "@material-ui/core";
import { Cell, Grid, isEmptyOrWhitespace } from "@shoothill/core";
import { useObserver } from "mobx-react-lite";
import React from "react";
import { NumericFormat } from "react-number-format";
import styled from "styled-components";

import { DarwinSelect } from "Components/AutoComplete/DarwinSelect";
import { PrimaryButton } from "Components/Buttons/Buttons";
import { DarwinInput, DarwinMoneyInput } from "Globals/Styles/Control/DarwinInput";
import { isTypeOfNumber } from "Utils/Utils";
import { SolidDivider } from "Views/PurchaseOrder/Form/Views/Dividers";
import { OrderLineModel } from "./OrderLineModel";
import { OrderLineViewModel } from "./OrderLineViewModel";

interface IProps {
    className?: string;
    viewModel: OrderLineViewModel;
    isDisabled: boolean;
}

const OrderLineViewBase: React.FC<IProps> = (props) => {
    // #region Code Behind

    const COLUMNS8 = "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr";
    const COLUMNS12 = "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr";

    const getOptionLabel = (option: any | null | undefined) => {
        if (option) {
            if (option.displayName) {
                return option.displayName;
            }
        }

        return "";
    };

    // #endregion Code Behind

    const renderNonStockOrderLine = () => {
        return (
            <OrderLinePanel key={"ORDERLINE-NONSTOCK"}>
                <OrderLinePanelSection>
                    <Grid columnGap={30} rowGap={20} tc={COLUMNS12} dc={COLUMNS12}>
                        <Cell ts={4} ds={4}>
                            <DarwinSelect
                                displayName="Category:*"
                                execute={props.viewModel.setCategoryAsync}
                                fullWidth={true}
                                getOptionLabel={(option: any) => getOptionLabel(option)}
                                onBlur={() => props.viewModel.isFieldValid("categoryId")}
                                options={props.viewModel.categoriesForIEAndVariations}
                                placeholder="Please select"
                                validationMessage={props.viewModel.getError("categoryId")}
                                value={props.viewModel.category}
                                canExecute={!props.isDisabled}
                            />
                        </Cell>
                        <Cell ts={4} ds={4}>
                            <DarwinSelect
                                displayName="Sub-category:*"
                                execute={props.viewModel.setSubCategoryAsync}
                                fullWidth={true}
                                getOptionLabel={(option: any) => getOptionLabel(option)}
                                onBlur={() => props.viewModel.isFieldValid("subCategoryId")}
                                options={props.viewModel.subCategoriesForCategory}
                                placeholder="Please select"
                                validationMessage={props.viewModel.getError("subCategoryId")}
                                value={props.viewModel.subCategory}
                                canExecute={!props.isDisabled}
                            />
                        </Cell>
                        <Cell ts={4} ds={4}>
                            <DarwinSelect
                                displayName="Line description:*"
                                execute={props.viewModel.setLineDescription}
                                fullWidth={true}
                                getOptionLabel={(option: any) => getOptionLabel(option)}
                                onBlur={() => props.viewModel.isFieldValid("lineDescriptionId")}
                                options={props.viewModel.lineDescriptionsForSubCategory}
                                placeholder="Please select"
                                validationMessage={props.viewModel.getError("lineDescriptionId")}
                                value={props.viewModel.lineDescription}
                                canExecute={!props.isDisabled}
                            />
                        </Cell>
                    </Grid>
                </OrderLinePanelSection>
                <SolidDivider />
                <OrderLinePanelSection>
                    <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
                        <Cell ts={4} ds={4}>
                            <DarwinInput<OrderLineModel>
                                type="text"
                                label="Item description:*"
                                placeholder="Description"
                                validateOnBlur={true}
                                viewModel={props.viewModel}
                                fieldName="itemDescription"
                                shrink={true}
                                editMode={!props.isDisabled}
                            />
                        </Cell>
                        <Cell ts={1} ds={1}>
                            <DarwinInput<OrderLineModel>
                                type="number"
                                label="Units:*"
                                placeholder=""
                                validateOnBlur={true}
                                viewModel={props.viewModel}
                                fieldName="units"
                                shrink={true}
                                maxLength={32}
                                editMode={!props.isDisabled}
                            />
                        </Cell>
                        <Cell ts={1} ds={1}>
                            <DarwinMoneyInput<OrderLineModel>
                                type="number"
                                label="Unit price:*"
                                placeholder=""
                                validateOnBlur={true}
                                viewModel={props.viewModel}
                                fieldName="unitPrice"
                                shrink={true}
                                maxLength={11}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                }}
                                editMode={!props.isDisabled}
                            />
                        </Cell>
                        <Cell display="flex" ts={2} ds={2}>
                            <OrderLineUnitTotal>Total: {props.viewModel.unitsTotalFormatted}</OrderLineUnitTotal>
                        </Cell>
                    </Grid>
                </OrderLinePanelSection>
            </OrderLinePanel>
        );
    };

    const renderStockOrderLine = () => {
        return (
            <OrderLinePanel key={"ORDERLINE-STOCK"}>
                <OrderLinePanelSection>
                    <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
                        <Cell ts={4} ds={4}>
                            <DarwinSelect
                                displayName="Item description:*"
                                execute={props.viewModel.setMaterial}
                                fullWidth={true}
                                getOptionLabel={(option: any) => getOptionLabel(option)}
                                onBlur={() => props.viewModel.isFieldValid("materialId")}
                                options={props.viewModel.materialsForSupplier}
                                placeholder="Please select"
                                validationMessage={props.viewModel.getError("materialId")}
                                value={props.viewModel.material}
                                canExecute={!props.isDisabled}
                            />
                        </Cell>
                        <Cell display="flex" ts={1} ds={1}>
                            {(() => {
                                switch (props.isDisabled) {
                                    case false:
                                        return (
                                            <NumericFormat
                                                customInput={TextField}
                                                InputLabelProps={{ shrink: true }}
                                                label="Units:*"
                                                onBlur={() => {
                                                    props.viewModel.isFieldValid("units");
                                                }}
                                                onValueChange={(values: any) => {
                                                    props.viewModel.setValue("units", isTypeOfNumber(values.floatValue) ? values.floatValue : values.value);
                                                }}
                                                allowNegative={false}
                                                decimalScale={0}
                                                thousandSeparator={true}
                                                suffix={" units"}
                                                helperText={props.viewModel.getError("units")}
                                                error={!isEmptyOrWhitespace(props.viewModel.getError("units"))}
                                                value={props.viewModel.getValue("units")}
                                            />
                                        );

                                    default:
                                        return <OrderLineUnitTotal>Units: {props.viewModel.model.units}</OrderLineUnitTotal>;
                                }
                            })()}
                        </Cell>
                        <Cell display="flex" ts={1} ds={1}>
                            <OrderLineUnitTotal>Unit price: {props.viewModel.materialUnitPriceFormatted}</OrderLineUnitTotal>
                        </Cell>
                        <Cell display="flex" ts={2} ds={2}>
                            <OrderLineUnitTotal>Total: {props.viewModel.unitsTotalFormatted}</OrderLineUnitTotal>
                        </Cell>
                    </Grid>
                </OrderLinePanelSection>
            </OrderLinePanel>
        );
    };

    return useObserver(() => (
        <Grid className={props.className} columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
            <Cell ts={8} ds={8}>
                {(() => {
                    switch (props.viewModel.isStockOrderLine) {
                        case true:
                            return renderStockOrderLine();

                        case false:
                            return renderNonStockOrderLine();
                    }
                })()}
            </Cell>
            <Cell ts={1} ds={1}>
                <PrimaryButton displayName="Add" execute={props.viewModel.add} fullWidth={true} canExecute={!props.isDisabled} />
            </Cell>
        </Grid>
    ));
};

export const OrderLinePanel = styled(Box)`
    background-color: #ededed;
    display: flex;
    flex-direction: column;
`;

export const OrderLinePanelSection = styled(Box)`
    margin: 18px 16px;
`;

export const OrderLineView = styled(OrderLineViewBase)``;

export const OrderLineUnitTotal = styled(Box)`
    display: flex;
    align-items: end;
    margin-bottom: 6px;
    font-size: 12px;
    font-weight: bold;
`;
