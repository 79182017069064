import { ModelBase, isEmptyOrWhitespace } from "@shoothill/core";
import { IsNotEmpty } from "class-validator";
import { computed, observable } from "mobx";

import { ScaffoldInspectionDetailModel } from "./InspectionDetail/ScaffoldInspectionDetailModel";
import { formatDate, formatTime } from "Utils/Format";

export class ScaffoldInspectionModel extends ModelBase<ScaffoldInspectionModel> {
    public id: string | null = null;
    public projectId: string | null = null;

    @IsNotEmpty({ message: "Please provide an inspector" })
    @observable
    public scaffoldInspectorName: string = "";
    @IsNotEmpty({ message: "Please provide a signature" })
    @observable
    public signatureBase64: string = "";

    @observable
    public createdDate: string = "";
    @observable
    public createdByUserId: string | null = null;
    @observable
    public createdByUserName: string = "";
    @observable
    public lastUpdatedDate: string = "";
    @observable
    public lastUpdatedById: string | null = null;
    @observable
    public lastUpdatedByName: string = "";
    public isDeleted: boolean = false;

    public scaffoldInspectionDetailsModel = observable<ScaffoldInspectionDetailModel>([]);

    constructor() {
        super();
    }

    @computed
    public get hasId(): boolean {
        return !isEmptyOrWhitespace(this.id);
    }

    @computed
    public get formattedAuditorCreationDate(): string {
        return `${formatDate(this.createdDate)} @ ${formatTime(this.createdDate)}`;
    }

    @computed
    public get auditorUserId(): string {
        return this.createdByUserId ?? "";
    }

    fromDto(dto: any): void {
        this.id = dto.scaffoldInspection.id;
        this.projectId = dto.scaffoldInspection.projectId;

        this.scaffoldInspectorName = dto.scaffoldInspection.scaffoldInspectorName;
        this.signatureBase64 = dto.scaffoldInspection.signatureBase64;

        this.createdDate = dto.scaffoldInspection.createdDate;
        this.createdByUserId = dto.scaffoldInspection.createdByUserId;

        this.lastUpdatedDate = dto.scaffoldInspection.lastUpdatedDate;
        this.lastUpdatedById = dto.scaffoldInspection.lastUpdatedById;

        this.isDeleted = dto.scaffoldInspection.isDeleted;
    }

    toDto() {
        return {
            id: this.id,
            projectId: this.projectId,

            scaffoldInspectorName: this.scaffoldInspectorName,
            signatureBase64: this.signatureBase64,

            isDeleted: this.isDeleted,
            scaffoldInspectionDetails: this.scaffoldInspectionDetailsModel.map((x) => x.toDto()),
        };
    }
}

export interface ScaffoldInspectionAuditUserDTO {
    id: string;
    displayName: string;
}
