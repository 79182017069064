import * as React from "react";

import { CancelButton } from "Globals/Styles/Control/Buttons";
import { BaseModal } from "Components/Modal/BaseModal";
import { InvoiceMatchItemAlreadyAllocatedModalDialogPaper } from "./InvoiceMatchItemAlreadyAllocatedModal.styles";
import { formatCurrencyFromPounds } from "Utils/Format";
import { InvoicePurchaseOrderItemsAllocatedDTO } from "../Supporting/InvoicePurchaseOrderItemModel";

interface IProps {
    open?: boolean;
    onClose(): void;
    title: string;
    text: string;
    isLoading?: boolean;
    allocatedInvoices: InvoicePurchaseOrderItemsAllocatedDTO[];
}

export const InvoiceMatchItemAlreadyAllocatedModal: React.FC<IProps> = ({ open, onClose, title, text, isLoading = false, allocatedInvoices }) => (
    <BaseModal
        open={open}
        onClose={onClose}
        title={title}
        PaperComponent={InvoiceMatchItemAlreadyAllocatedModalDialogPaper}
        actions={
            <>
                <CancelButton onClick={onClose}>Ok</CancelButton>
            </>
        }
    >
        <p className="client-modal-description">{text}</p>
        <ul>
            {allocatedInvoices
                .filter((i) => i.alreadyAllocated !== null)
                .map((i) => {
                    return (
                        <li>
                            {i.invoiceNumber} - {formatCurrencyFromPounds(Number(i.alreadyAllocated!.toFixed(2)))}
                        </li>
                    );
                })}
        </ul>
    </BaseModal>
);
