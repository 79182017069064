import { FieldType, ViewModelBase, isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import { action, computed, observable } from "mobx";

import { AppUrls } from "AppUrls";
import { MaterialTransactionRequestStatusType } from "Globals/Models/Enums/MaterialTransactionRequestStatusType";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { formatCurrencyFromPounds, formatDate, formatTime } from "Utils/Format";
import { MaterialPriceDetailsApprovalModel } from "./MaterialPriceDetailsApprovalModel";

export class MaterialPriceDetailsApprovalViewModel extends ViewModelBase<MaterialPriceDetailsApprovalModel> {
    public server: ServerViewModel = new ServerViewModel();

    public canApproveAndReject: boolean;
    private parentCloseAction: (refreshPage: boolean) => void;

    constructor(canApproveAndReject: boolean, closeAction: (refreshPage: boolean) => void) {
        super(new MaterialPriceDetailsApprovalModel(), false);

        this.canApproveAndReject = canApproveAndReject;
        this.parentCloseAction = closeAction;

        this.setDecorators(MaterialPriceDetailsApprovalModel);
    }

    // #region Properties

    @computed
    public get displayName() {
        const displayName = "Master material price details";

        if (isNullOrUndefined(this.model.currentTransactionRequestStatusType)) {
            return displayName;
        }

        return `${displayName} (${this.model.currentTransactionRequestStatusType?.displayName})`;
    }

    @computed
    public get createdDate() {
        return `${formatDate(this.model.createdDate)} @ ${formatTime(this.model.createdDate)}`;
    }

    @computed
    public get lastUpdatedDate() {
        return isEmptyOrWhitespace(this.model.lastUpdatedDate) ? "" : `${formatDate(this.model.lastUpdatedDate!)} @ ${formatTime(this.model.lastUpdatedDate!)}`;
    }

    @computed
    public get canDisplayLastUpdated() {
        return !isEmptyOrWhitespace(this.model.lastUpdatedDate) && !isEmptyOrWhitespace(this.model.lastUpdatedByUser);
    }

    @computed
    public get estimatedPrice() {
        return formatCurrencyFromPounds(this.model.estimatedPrice, 2, 4);
    }

    @computed
    public get price() {
        return formatCurrencyFromPounds(this.model.price, 2, 4);
    }

    @computed
    public get beforeEstimatedPrice() {
        return formatCurrencyFromPounds(this.model.beforeEstimatedPrice, 2, 4);
    }

    @computed
    public get beforePrice() {
        return formatCurrencyFromPounds(this.model.beforePrice, 2, 4);
    }

    @computed
    public get effectiveFromDate() {
        return formatDate(this.model.effectiveFromDate);
    }

    @computed
    public get canApprove() {
        return this.canApproveAndReject && this.model.availableTransactionRequestStatusTypes.some((st) => st.IsOfType(MaterialTransactionRequestStatusType.Approved));
    }

    @computed
    public get canReject() {
        return this.canApproveAndReject && this.model.availableTransactionRequestStatusTypes.some((st) => st.IsOfType(MaterialTransactionRequestStatusType.Rejected));
    }

    @computed
    public get canCancel() {
        return this.canApproveAndReject && this.model.availableTransactionRequestStatusTypes.length > 0;
    }

    // #endregion Properties

    // #region Actions

    @action
    public approve = () => {
        const statusType = this.model.availableTransactionRequestStatusTypes.find((st) => st.IsOfType(MaterialTransactionRequestStatusType.Approved));

        if (!isNullOrUndefined(statusType)) {
            this.model.transactionRequestStatusType = statusType!;
            this.apiSaveDetailsAsync();
        }
    };

    @action
    public reject = () => {
        const statusType = this.model.availableTransactionRequestStatusTypes.find((st) => st.IsOfType(MaterialTransactionRequestStatusType.Rejected));

        if (!isNullOrUndefined(statusType)) {
            this.model.transactionRequestStatusType = statusType!;
            this.apiSaveDetailsAsync();
        }
    };

    @action
    public cancel = () => this.parentCloseAction(false);

    // #endregion Actions

    // #region Api Actions

    @action
    public apiSaveDetailsAsync = async (): Promise<void> => {
        await this.server.command<any>(
            () =>
                this.Post(AppUrls.Server.Stock.Material.UpdateMaterialPriceDetails.replace("{materialtransactionrequestid}", this.model.transactionRequestId!), this.model.toDto()),
            (result) => this.parentCloseAction(true),
            this.isModelValid,
            "Error whilst saving the material approval",
        );

        if (this.server.HaveValidationMessage) {
            this.setSnackMessage(this.server.ValidationMessage);
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }
    };

    // #endregion Api Actions

    // #region Snack Bar

    public SNACKERROR = "error";

    @observable
    public snackbarState = false;

    @observable
    public snackType = "";

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public snackMessage = "";

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    // #endregion Snack Bar

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<MaterialPriceDetailsApprovalModel>): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boliderplate
}
