import { FieldType, ViewModelBase, isEmptyOrWhitespace } from "@shoothill/core";
import { runInAction, observable, action } from "mobx";
import { RFIFileModel, RFIFileRequestDTO } from "./RFIFileModel";
import { RFIFormFileModel } from "../../Forms/Common/RFIFormFileModel";
import { AppUrls } from "AppUrls";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";

export class RFIFileViewModel extends ViewModelBase<RFIFileModel> {
    public constructor(rFIId: string | null) {
        super(new RFIFileModel(), false);
        this.setDecorators(RFIFileModel);
        this.model.rFIId = rFIId!;
        if (!isEmptyOrWhitespace(this.model.rFIId)) {
            this.loadRFIFiles();
        }
    }

    public server: ServerViewModel = new ServerViewModel();

    @observable
    public files = observable<RFIFormFileModel>([]);

    /**
     * Download a file that exists in azure.
     * @param fileUrl The URL of the file to be downloaded.
     * @param fileName The name of the file to be downloaded.
     */
    public DownloadFile = async (fileUrl: string, fileName: string): Promise<void> => {
        try {
            const apiResult = await this.Post<Blob>(AppUrls.Server.File.DownloadFile, fileUrl, undefined, { responseType: "blob" });
            const response = apiResult as any;
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
        } catch (exception) {
            console.error(exception);
            this.setIsErrored(true);
        }
    };

    @action
    public loadRFIFiles = async (): Promise<void> => {
        const request: RFIFileRequestDTO = {
            id: this.model.rFIId,
        };
        let apiResult = await this.Post<any>(AppUrls.Server.Projects.ProjectTrackers.RFI.GetRFIFilesById, request);
        if (apiResult) {
            if (apiResult.wasSuccessful) {
                if (apiResult.wasSuccessful) {
                    runInAction(() => {
                        this.files.replace(apiResult.payload);
                    });
                } else {
                    console.log(apiResult.errors);
                }
            }
        }
    };

    public async isFieldValid(fieldName: keyof FieldType<RFIFileModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        errorMessage = "";
        isValid = true;

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
