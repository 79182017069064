import { ModelBase } from "@shoothill/core";
import { ApprovalSubType } from "Globals/Models";
import { action, computed, observable } from "mobx";
import { OrderLineModel } from "../Form/OrderLineModel";
import { RequisitionRequestItemModel, RequisitionRequestItemRequestDTO, RequisitionRequestItemResponseDTO } from "./RequisitionRequestItemModel";
import { validateTwoDecimalPlaces } from "Utils/Utils";

export class RequisitionRequestItemGroupModel extends ModelBase<RequisitionRequestItemGroupModel, RequisitionRequestItemGroupResponseDTO> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Defaults and Constants

    public static readonly DEFAULT_ID = null;
    public static readonly DEFAULT_ROWVERSION = null;

    public static readonly DEFAULT_LINEDESCRIPTIONID = "";
    public static readonly DEFAULT_LINEDESCRIPTIONDISPLAYNAME = "";

    public static readonly DEFAULT_SUBCATEGORYID = "";
    public static readonly DEFAULT_SUBCATEGORYDISPLAYNAME = "";

    public static readonly DEFAULT_CATEGORYID = "";
    public static readonly DEFAULT_CATEGORYDISPLAYNAME = "";

    public static readonly DEFAULT_IEID = "";
    public static readonly DEFAULT_REQUISITIONREQUESTITEMS = [];

    public static readonly DEFAULT_NEWFUTURESPENDID = "";
    public static readonly DEFAULT_NEWFUTURESPEND = 0;
    public static readonly DEFAULT_NOTE = "";
    public static readonly DEFAULT_ISWITHINSPENDALLOWANCE = false;
    public static readonly DEFAULT_SUBTYPE = ApprovalSubType.InBudget;

    public static readonly DEFAULT_TARGET_COST = 0;
    public static readonly DEFAULT_COMMITTED_COST = 0;
    public static readonly DEFAULT_FUTURE_SPEND = 0;
    public static readonly DEFAULT_TOTAL_EXPECTED_SPEND = 0;
    public static readonly DEFAULT_VARIANCE = 0;
    public static readonly DEFAULT_ISDELETED = false;

    public static readonly DEFAULT_VARIATIONID = "";

    // #endregion Defaults and Constants

    // #region Observables

    @observable
    public id: string | null = RequisitionRequestItemGroupModel.DEFAULT_ID;

    @observable
    public rowVersion: string | null = RequisitionRequestItemGroupModel.DEFAULT_ROWVERSION;

    @observable
    public lineDescriptionId: string = RequisitionRequestItemGroupModel.DEFAULT_LINEDESCRIPTIONID;

    @observable
    public variationId: string | null = RequisitionRequestItemGroupModel.DEFAULT_VARIATIONID;

    @observable
    public lineDescriptionDisplayName: string = RequisitionRequestItemGroupModel.DEFAULT_LINEDESCRIPTIONDISPLAYNAME;

    @observable
    public subCategoryId: string = RequisitionRequestItemGroupModel.DEFAULT_SUBCATEGORYID;

    @observable
    public subCategoryDisplayName: string = RequisitionRequestItemGroupModel.DEFAULT_SUBCATEGORYDISPLAYNAME;

    @observable
    public categoryId: string = RequisitionRequestItemGroupModel.DEFAULT_CATEGORYID;

    @observable
    public categoryDisplayName: string = RequisitionRequestItemGroupModel.DEFAULT_CATEGORYDISPLAYNAME;

    @observable
    public incomeAndExpenditureId: string = RequisitionRequestItemGroupModel.DEFAULT_IEID;

    @observable
    public requisitionRequestItems = observable<RequisitionRequestItemModel>(RequisitionRequestItemGroupModel.DEFAULT_REQUISITIONREQUESTITEMS);

    @observable
    public newFutureSpendId: string | null = RequisitionRequestItemGroupModel.DEFAULT_NEWFUTURESPENDID;

    @observable
    public newFutureSpend: number = RequisitionRequestItemGroupModel.DEFAULT_NEWFUTURESPEND;

    @observable
    public note: string = RequisitionRequestItemGroupModel.DEFAULT_NOTE;

    @observable
    public isWithinSpendAllowance: boolean = RequisitionRequestItemGroupModel.DEFAULT_ISWITHINSPENDALLOWANCE;

    @observable
    public subType: ApprovalSubType = RequisitionRequestItemGroupModel.DEFAULT_SUBTYPE;

    @observable
    public targetCost: number = RequisitionRequestItemGroupModel.DEFAULT_TARGET_COST;

    @observable
    public committedCost: number = RequisitionRequestItemGroupModel.DEFAULT_COMMITTED_COST;

    @observable
    public futureSpend: number = RequisitionRequestItemGroupModel.DEFAULT_FUTURE_SPEND;

    @observable
    public totalExpectedSpend: number = RequisitionRequestItemGroupModel.DEFAULT_TOTAL_EXPECTED_SPEND;

    @observable
    public variance: number = RequisitionRequestItemGroupModel.DEFAULT_VARIANCE;

    @observable
    public isDeleted: boolean = RequisitionRequestItemGroupModel.DEFAULT_ISDELETED;

    // Used to determine how to calculate the total.
    //@observable
    //public hasChanged: boolean = false;

    @observable
    public isPO: boolean = false;

    @observable
    public poNumber: number | null = null;

    @observable
    public isInRevisionMode: boolean = false;

    @observable
    public isAmending: boolean = false;

    @observable
    public isCentral: boolean = false;

    // #endregion Observables

    // #region Actions

    @action
    public fromDto(dto: RequisitionRequestItemGroupResponseDTO): void {
        this.id = dto.id;
        this.rowVersion = dto.rowVersion;
        this.newFutureSpendId = dto.newFutureSpendId;
        this.newFutureSpend = dto.newFutureSpend;
        this.note = dto.note;
        this.incomeAndExpenditureId = dto.incomeAndExpenditureId;
        this.lineDescriptionId = dto.lineDescriptionId;
        this.lineDescriptionDisplayName = dto.lineDescriptionDisplayName;
        this.subCategoryId = dto.subCategoryId;
        this.subCategoryDisplayName = dto.subCategoryDisplayName;
        this.categoryId = dto.categoryId;
        this.categoryDisplayName = dto.categoryDisplayName;
        this.isWithinSpendAllowance = dto.isWithinSpendAllowance;
        this.subType = dto.subType;
        this.requisitionRequestItems.replace(RequisitionRequestItemModel.fromDtos(dto.requisitionRequestItems));

        this.targetCost = dto.targetCost;
        this.committedCost = dto.committedCost;
        this.futureSpend = dto.futureSpend;
        this.totalExpectedSpend = dto.totalExpectedSpend;
        this.variance = dto.variance;
        this.variationId = dto.variationId;

        this.isDeleted = dto.isDeleted;

        this.isPO = dto.isPO;
        this.poNumber = dto.poNumber;
        this.isAmending = dto.isAmending;
    }

    public toDto(): RequisitionRequestItemGroupRequestDTO {
        return {
            id: this.id,
            rowVersion: this.rowVersion,
            newFutureSpendId: this.newFutureSpendId,
            newFutureSpend: this.newFutureSpend,
            note: this.note,
            lineDescriptionId: this.lineDescriptionId,
            lineDescriptionDisplayName: this.lineDescriptionDisplayName,
            subCategoryId: this.subCategoryId,
            subCategoryDisplayName: this.subCategoryDisplayName,
            categoryId: this.categoryId,
            categoryDisplayName: this.categoryDisplayName,
            isWithinSpendAllowance: this.isWithinSpendAllowance,
            subType: this.subType,
            requisitionRequestItems: this.requisitionRequestItems,
            variationId: this.variationId,
            isDeleted: this.isDeleted,
        };
    }

    // #endregion Actions

    // #region Helpers

    public static fromDtos(dtos: RequisitionRequestItemGroupResponseDTO[]): RequisitionRequestItemGroupModel[] {
        const types: RequisitionRequestItemGroupModel[] = [];

        for (const dto of dtos) {
            const model = new RequisitionRequestItemGroupModel();

            model.fromDto(dto);
            types.push(model);
        }

        return types;
    }

    public static fromOrderLineModel(orderLineModel: OrderLineModel) {
        const model = new RequisitionRequestItemGroupModel();

        model.categoryId = orderLineModel.categoryId!;
        model.categoryDisplayName = orderLineModel.categoryDisplayName;

        model.subCategoryId = orderLineModel.subCategoryId!;
        model.subCategoryDisplayName = orderLineModel.subCategoryDisplayName;

        model.lineDescriptionId = orderLineModel.lineDescriptionId!;
        model.lineDescriptionDisplayName = orderLineModel.lineDescriptionDisplayName;

        model.variationId = orderLineModel.variationId;

        model.requisitionRequestItems.push(RequisitionRequestItemModel.fromOrderLineModel(null, orderLineModel));

        return model;
    }

    @computed
    public get getIsVariation(): boolean {
        return this.variationId !== "" && this.variationId !== null && this.variationId !== undefined;
    }

    @computed
    public get getIsPO(): boolean {
        return this.isPO;
    }

    @computed
    public get getPONumber(): number | null {
        return this.poNumber;
    }

    /**
     * After the revise button is clicked but before it has been saved.
     */
    @computed
    public get getIsInRevisionMode(): boolean {
        return this.isInRevisionMode;
    }

    /**
     * After the revision has been saved.
     */
    @computed
    public get getIsInRevision() {
        return !this.getIsPO && this.getPONumber !== null;
    }

    /**
     * Whether or not the requisition requires amends.
     */
    @computed
    public get getIsAmending() {
        return this.isAmending;
    }

    // #endregion Helpers

    // #region Business Logic

    /**
     * The cost of all requisition items (not deleted) in this group.
     * This is abit more complicated as we can view a request in multiple stages. (New, existing but not PO, amending, existing and PO, existing and currently revising).
     * If this request is a PO, then we only want to show the change in value for existing groups, because their current value is used for the pre value already.
     */
    @computed
    public get totalChange(): number {
        return this.requisitionRequestItems.reduce((acc, m) => {
            if ((m.id === null || m.id === undefined || m.id === "") && m.isDeleted) {
                // Deleting new, so don't count any change.
                return acc;
            } else if (m.isDeleted) {
                // Deleting existing, so do count the change.
                return parseFloat((acc + m.totalChange).toFixed(2));
            }
            // Just use the default calculation if this is request isn't a PO.
            if (!this.getIsPO && !this.getIsInRevision && !this.getIsAmending) {
                return parseFloat((acc + m.total).toFixed(2));
            } else if (this.getIsInRevision) {
                // Exclude the existing value if the item has already been approved. Only include any change.
                if (m.hasBeenApproved) {
                    return parseFloat((acc + m.totalChange).toFixed(2));
                } else if (!this.getIsInRevisionMode) {
                    return parseFloat((acc + m.total).toFixed(2));
                } else {
                    // Else just show the default calculation.
                    return parseFloat((acc + m.total).toFixed(2));
                }
            } else if (this.getIsAmending) {
                return parseFloat((acc + m.totalChange).toFixed(2));
            } else {
                // Otherwise just show the change in value if the group already exists.
                if (m.id !== null) {
                    return parseFloat((acc + m.totalChange).toFixed(2));
                } else {
                    // Else just show the default calculation.
                    return parseFloat((acc + m.total).toFixed(2));
                }
            }
        }, 0);
    }

    @computed
    public get total(): number {
        return this.requisitionRequestItems
            .filter((m) => !m.isDeleted)
            .reduce((acc, m) => {
                return parseFloat((acc + m.total).toFixed(2));
            }, 0);
    }

    /**
     * BUSINESS RULE.
     * The pre committed cost including the total cost of all
     * new requisition items (not deleted) in this group.
     */
    @computed
    public get postCommittedCost() {
        return parseFloat((this.committedCost + this.totalChange).toFixed(2));
    }

    /**
     * BUSINESS RULE.
     * The new future spend.
     */
    @computed
    public get postFutureSpend() {
        return this.newFutureSpend;
    }

    /**
     * BUSINESS RULE.
     * The total expected spend is the sum of the committed cost
     * and future spend.
     */
    @computed
    public get postTotalExpectedSpend() {
        return parseFloat((this.postCommittedCost + this.postFutureSpend).toFixed(2));
    }

    /**
     * BUSINESS RULE.
     * The variance is the differnce in the target cost and total
     * expended spend.
     */
    @computed
    public get postVariance() {
        return parseFloat((this.targetCost - this.postTotalExpectedSpend).toFixed(2));
    }

    // #endregion Business Logic

    @computed
    public get validateNote(): string {
        // RULES
        // The note is required.

        if (this.isCentral) {
            return "";
        }

        return this.note === RequisitionRequestItemGroupModel.DEFAULT_NOTE || this.note === null || this.note === undefined ? "Please enter a future spend note." : "";
    }

    @computed
    public get validateNewFutureSpend(): string {
        // RULES
        if (isNaN(this.newFutureSpend)) {
            return "Please set the new future spend";
        } else if (!validateTwoDecimalPlaces(this.newFutureSpend)) {
            return "No more than two decimal places";
        }

        return "";
    }
}

export interface RequisitionRequestItemGroupRequestDTO {
    id: string | null;
    rowVersion: string | null;
    newFutureSpendId: string | null;
    newFutureSpend: number;
    note: string;
    lineDescriptionId: string;
    lineDescriptionDisplayName: string;
    subCategoryId: string;
    subCategoryDisplayName: string;
    categoryId: string;
    categoryDisplayName: string;
    isWithinSpendAllowance: boolean;
    subType: ApprovalSubType;
    requisitionRequestItems: RequisitionRequestItemRequestDTO[];
    variationId: string | null;
    isDeleted: boolean;
}

export interface RequisitionRequestItemGroupResponseDTO {
    id: string | null;
    rowVersion: string | null;
    newFutureSpendId: string | null;
    newFutureSpend: number;
    note: string;
    incomeAndExpenditureId: string;
    variationId: string | null;
    lineDescriptionId: string;
    lineDescriptionDisplayName: string;
    subCategoryId: string;
    subCategoryDisplayName: string;
    categoryId: string;
    categoryDisplayName: string;
    isWithinSpendAllowance: boolean;
    subType: ApprovalSubType;
    requisitionRequestItems: RequisitionRequestItemResponseDTO[];
    isPO: boolean;
    poNumber: number | null;
    isAmending: boolean;
    isDeleted: boolean;

    targetCost: number;
    committedCost: number;
    futureSpend: number;
    totalExpectedSpend: number;
    variance: number;
}
