import { ModelBase } from "@shoothill/core";
import { observable, action } from "mobx";

export class AuditDocumentModel extends ModelBase<AuditDocumentModel> {
    @observable
    public id: string | null = null;
    @observable
    public fileName: string = "";
    @observable
    public url: string = "";
    @observable
    public isDeleted: boolean = false;
    constructor() {
        super();
    }

    @action
    public deleteDocument = () => {
        this.isDeleted = true;
    };

    fromDto(model: any): void {
        this.id = model.id;
        this.fileName = model.fileName;
        this.url = model.url;
        this.isDeleted = model.isDeleted;
    }
    toDto() {
        return {
            id: this.id,
            fileName: this.fileName,
            url: this.url,
            isDeleted: this.isDeleted,
        };
    }
}
