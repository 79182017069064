import { Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useObserver } from "@shoothill/core";
import { DarwinPage, DarwinPageTitle } from "Globals/Styles/AppStyling";
import { AllPurchaseOrderTableView } from "./AllPurchaseOrderTableView";
import { IEGridItemViewModel } from "Views/Project/Commercial/IEmodels/IEGridItemViewModel";
import { RejectedOrderListViewModel } from "./RejectedOrderListViewModel";
import { RejectedOrderListFilterView } from "./RejectedOrderListFilterView";
import { RejectedOrderListFilterViewModel } from "./RejectedOrderListFilterViewModel";
import { PaginationDirection, SortOrderDirection } from "Components/Table/DarwinTableV2";

export const RejectedOrderListView: React.FunctionComponent = () => {
    const [viewModel] = useState(() => new RejectedOrderListViewModel());
    const filtersViewModel = RejectedOrderListFilterViewModel.Instance;
    useEffect(() => {
        IEGridItemViewModel.Instance.setIsCentral(false);
        viewModel.GetAllWithFiltersAsync();

        return () => {
            viewModel.cleanUp();
        };
    }, []);

    const handleSorting = async (columnName: string, orderBy: SortOrderDirection) => {
        if (viewModel.hasLoaded) {
            filtersViewModel.handleSorting(columnName, orderBy);
            loadData();
        }
    };

    const handlePageChange = async (change: PaginationDirection) => {
        const pageNumber: number = filtersViewModel.model.pageNumber;
        const pageCount: number = filtersViewModel.model.pageCount;

        switch (change) {
            case PaginationDirection.NEXT:
                pageNumber < pageCount ? filtersViewModel.setPageNumber(pageNumber + 1) : filtersViewModel.setPageNumber(1);
                loadData();
                break;
            case PaginationDirection.BACK:
                pageNumber > 1 ? filtersViewModel.setPageNumber(pageNumber - 1) : filtersViewModel.setPageNumber(1);
                loadData();

                break;
            case PaginationDirection.START:
                filtersViewModel.setPageNumber(1);
                loadData();

                break;
            case PaginationDirection.END:
                filtersViewModel.setPageNumber(pageCount);
                loadData();

                break;
            default:
        }
    };

    const handleRowsPerPageChange = async (pageSize: number) => {
        filtersViewModel.handleRowsPerPageChange(pageSize);
        loadData();
    };

    const handlePageValueChange = async (page: number) => {
        filtersViewModel.setPageNumber(page);
        loadData();
    };

    const loadData = async () => {
        await viewModel.GetAllWithFiltersAsync();
    };

    const renderPage = () => {
        return (
            <DarwinPage>
                <DarwinPageTitle>
                    <Typography variant="h1" color="textPrimary">
                        Rejected Orders
                    </Typography>
                </DarwinPageTitle>
                <RejectedOrderListFilterView rejectedOrderListViewModel={viewModel} filtersViewModel={filtersViewModel} />
                <AllPurchaseOrderTableView
                    data={viewModel.getPurchaseOrdersList}
                    isLoading={viewModel.IsLoading}
                    downloadPDF={viewModel.apiGeneratePOPDF}
                    handleSorting={handleSorting}
                    handlePageChange={handlePageChange}
                    handlePageValueChange={handlePageValueChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    totalCount={filtersViewModel.model.totalCount}
                    pageNumber={filtersViewModel.model.pageNumber}
                    pageSize={filtersViewModel.model.pageSize}
                    pageCount={filtersViewModel.model.pageCount}
                    defaultSortColumn={filtersViewModel.model.sortBy}
                    defaultSortDirection={SortOrderDirection[filtersViewModel.model.sortDirection as keyof typeof SortOrderDirection]}
                />
            </DarwinPage>
        );
    };

    return useObserver(() => renderPage());
};
