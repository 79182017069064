import { ModelBase } from "@shoothill/core";
import { observable } from "mobx";

export class AuditListModel extends ModelBase<AuditListModel> {
    public endOfDayId: string = "";
    public endOfWeekId: string = "";
    @observable
    public endOfDayValidUntil: Date | null = null;
    @observable
    public endOfDayLastUpdatedDate: Date | null = null;
    @observable
    public endOfDayCreatedDate: Date | null = null;
    @observable
    public endOfWeekCreatedDate: Date | null = null;
    @observable
    public endOfDayLastUpdatedBy: string = "";
    @observable
    public endOfWeekValidUntil: string | null = null;
    @observable
    public endOfWeekLastUpdatedDate: Date | null = null;
    @observable
    public endOfWeekLastUpdatedBy: string = "";

    constructor() {
        super();
    }

    fromDto(model: any): void {
        throw new Error("Method not implemented.");
    }
    toDto(model: AuditListModel): void {
        throw new Error("Method not implemented.");
    }
}
