import { isNullOrUndefined } from "@shoothill/core";
// Libs

import * as MobX from "mobx";

import { IsEmail, IsNotEmpty, IsPhoneNumber, ValidateIf } from "class-validator";
import { ModelBase } from "@shoothill/core";
import { Role, RoleDTO } from "./index";
import { UserListItem } from "./UserListItem";
import { ProjectDTO } from "../../Views/PurchaseOrder/Form/Supporting/ProjectModel";
import { ProjectListModel } from "../../Views/Project/ProjectListModel";

// App
export class User extends ModelBase<User, UserDTO> {
    public id: string = "";

    @MobX.observable
    @IsNotEmpty({ message: "First name is required" })
    public firstName: string = "";
    @MobX.observable
    @IsNotEmpty({ message: "Last name is required" })
    public lastName: string = "";
    @MobX.observable
    @IsEmail({}, { message: "Email is not valid" })
    public emailAddress: string = "";
    @MobX.observable public password: string = "";
    @MobX.observable public concurrencyToken: string = "";
    @MobX.observable public isDeleted: boolean = false;
    @MobX.observable public roles = MobX.observable<Role>([]);

    // Assuming a user can have only one role for the moment.
    @MobX.observable
    @IsNotEmpty({ message: "Role is required" })
    public roleIds: string[] = [];

    @MobX.observable
    public roleDisplayName: string = "";

    @MobX.observable
    public projectIds: string[] = [];

    @MobX.observable
    @ValidateIf((o) => o.roleDisplayName === "Site Tablet")
    @IsNotEmpty({ message: "Project is required" })
    public siteTabletProjectId: string = "";

    @MobX.observable
    @ValidateIf((o) => o.length > 0)
    @IsPhoneNumber("GB", { message: "Phone number is not valid" })
    public phoneNumber: string = "";
    @MobX.observable public lockoutEnabled: boolean = false;
    @MobX.observable public accessFailedCount: number = 0;
    @MobX.observable public createdAt: string = "";
    @MobX.observable public lockoutEnd: string | null = null;
    @MobX.observable public lastLoginDate: string | null = null;

    @MobX.observable public publicId: string | null = null;
    @MobX.observable public concurrencyStamp: string = "";
    @MobX.observable public userName: string = "";
    @MobX.observable public updatedAt: string = "";

    @MobX.observable
    public canChangeUserProject: boolean = false;

    @MobX.computed get getRoleListFormatted(): string {
        return this.roles ? this.roles.map((r) => r.displayName).join(", ") : "";
    }

    constructor(id: string) {
        super();
        this.id = id;
    }

    @MobX.computed
    public get getName() {
        return this.firstName + " " + this.lastName;
    }

    // @MobX.computed get roleList() {
    //     if (isNullOrUndefined(this.roles) === true) {
    //         return "";
    //     }
    //     return this.roles
    //         .map((a) => {
    //             return a.name;
    //         })
    //         .join(", ");
    // }

    @MobX.action
    public fromDto = (model: UserDTO) => {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                this[key] = model[key];
            }
        }

        if (this.roleIds.length > 0) {
            this.roleIds = this.roleIds;
        }
        // this.id = model.id;
        // this.firstName = model.firstName;
        // this.lastName = model.lastName;
        // this.email = model.email;
        // this.email = model.email;
        // this.password = model.password;
        // this.roles = model.roles;
        // this.concurrencyToken = model.concurrencyToken;
        // this.isDeleted = model.isDeleted;
    };

    @MobX.action
    public fromListItem = (model: UserListItem) => {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                this[key] = model[key];
            }
        }

        if (this.roleIds && this.roleIds.length > 0) {
            this.roleIds = this.roleIds;
        }
        // this.id = model.id;
        // this.firstName = model.firstName;
        // this.lastName = model.lastName;
        // this.email = model.email;
        // this.password = model.password;
        // this.roles = model.roles;
        // this.concurrencyToken = model.concurrencyToken;
        // this.isDeleted = model.isDeleted;
    };

    public toDto() {
        return {
            id: this.id,
            firstName: this.firstName,
            lastName: this.lastName,
            projectIds: this.projectIds,
            siteTabletProjectId: this.siteTabletProjectId,
            emailAddress: this.emailAddress,
            password: this.password,
            concurrencyToken: this.concurrencyToken,
            isDeleted: this.isDeleted,
            roleIds: this.roleIds,
            roles: this.roles,
            phoneNumber: this.phoneNumber,
            lockoutEnabled: this.lockoutEnabled,
            accessFailedCount: this.accessFailedCount,
            createdAt: this.createdAt,
            lockoutEnd: this.lockoutEnd,
            lastLoginDate: this.lastLoginDate,
            publicId: this.publicId,
            concurrencyStamp: this.concurrencyStamp,
            userName: this.userName,
            updatedAt: this.updatedAt,
            canChangeUserProject: this.canChangeUserProject,
        };
    }

    // public toDtoWithRoles = (model: UserDTO, roles: Role[]) => {
    //     this.toDto(model);

    //     model.roles = [];
    //     const role: Role | undefined = roles.find((a) => a.id === this.roleId);

    //     if (isNullOrUndefined(role) === false) {
    //         let m: RoleDTO = {
    //             id: "",
    //             name: "",
    //             displayName: "",
    //         };
    //         role?.toDto(m);
    //         model.roles.push(m);
    //     }
    // };

    public generateBlankDTO = (): UserDTO => {
        const retVal: UserDTO = {
            id: "",
            firstName: "",
            lastName: "",
            projectIds: [],
            siteTabletProjectId: "",
            emailAddress: "",
            password: "",
            concurrencyToken: "",
            isDeleted: false,
            roleIds: [],
            roles: [],
            phoneNumber: "",
            lockoutEnabled: false,
            accessFailedCount: 0,
            createdAt: "",
            lockoutEnd: null,
            lastLoginDate: null,
            publicId: null,
            concurrencyStamp: "",
            userName: "",
            updatedAt: "",
            canChangeUserProject: false,
        };

        return retVal;
    };
}

// UserDTO is ClientUser in the API
export interface UserDTO {
    id: string;
    password: string;
    emailAddress: string;
    phoneNumber: string;
    lockoutEnabled: boolean;
    accessFailedCount: number;
    firstName: string;
    lastName: string;
    projectIds: string[];
    siteTabletProjectId: string;
    createdAt: string;
    lockoutEnd: string | null;
    lastLoginDate: string | null;
    isDeleted: boolean;
    roles: RoleDTO[];
    roleIds: string[];
    publicId: string | null;
    concurrencyStamp: string;
    userName: string;
    updatedAt: string;
    concurrencyToken: string;
    canChangeUserProject: boolean;
}

export interface UserWithProjectDTO extends UserDTO {
    projectListItem: ProjectListModel;
}
