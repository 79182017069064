import { action, observable, computed } from "mobx";
import { ModelBase } from "@shoothill/core";
import { IsNotEmpty, IsPostalCode, MaxLength } from "class-validator";
import { DarwinModelBaseDTO } from "Globals/Models/Domain";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class AddressModel extends ModelBase<AddressModel, AddressModelDTO> {
    // DarwinModelBase
    public id: string = "";
    public createdDate: string = "";
    public originatorId: string | undefined = undefined;
    public rowVersion: string | undefined = undefined;
    public sourceId: string = "";

    @observable
    public isDeleted: boolean = false;

    @observable
    public addressName: string = "";

    @observable
    @IsNotEmpty({ message: "Address line 1 is required" })
    @MaxLength(128, { message: "Address line length must be less than 128 characters" })
    public addressLine1: string = "";

    @observable
    @MaxLength(128, { message: "Address line length must be less than 128 characters" })
    public addressLine2: string = "";

    @observable
    public addressLine3: string = "";

    @observable
    @MaxLength(128, { message: "Town length must be less than 128 characters" })
    public city: string = "";

    @observable
    @MaxLength(128, { message: "County length must be less than 128 characters" })
    public county: string = "";

    @observable
    @IsNotEmpty({ message: "Postcode is required" })
    @MaxLength(12, { message: "Postcode length must be less than 12 characters" })
    @IsPostalCode("GB")
    public postcode: string = "";

    @observable
    public isPrimary: boolean = true; // since at the moment there is only one address

    @observable
    public deliveryContactName: string = "";

    @observable
    public deliveryContactNumber: string = "";

    @observable
    public accountOpened: string = "";
    @observable
    public vatNumber: string = "";
    @observable
    public defaultVatRate: number = 0;

    @observable
    public paymentTermsInDays: number = 0;

    @observable
    public paymentTerms: string = "";
    @observable
    public creditLimit: number = 0;
    @observable
    public paymentGroup: string = "";
    @observable
    public insuranceExpiryDate: string = "";
    @observable
    public constructionalReg: string = "";
    @observable
    public pqq: boolean = true;
    @observable
    public invoiceFactoring: string = "";
    @observable
    public creditInsurer: string = "";

    @observable
    public deliveryInstructions: string = "";

    @action
    public clear() {
        this.fromDto(DefaultAddressModelDTO);
    }

    //fromDto is required but you can leave it blank
    fromDto(model: AddressModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    @action
    fromObservable(obVersion: AddressModel) {
        this.id = obVersion.id;
        this.createdDate = obVersion.createdDate;
        this.isDeleted = obVersion.isDeleted;
        this.rowVersion = obVersion.rowVersion;
        this.originatorId = obVersion.originatorId;
        this.addressName = obVersion.addressName;
        this.addressLine1 = obVersion.addressLine1;
        this.addressLine2 = obVersion.addressLine2;
        this.addressLine3 = obVersion.addressLine3;
        this.city = obVersion.city;
        this.county = obVersion.county;
        this.postcode = obVersion.postcode;
        this.isPrimary = obVersion.isPrimary;
        this.sourceId = obVersion.sourceId;
        this.deliveryContactName = obVersion.deliveryContactName;
        this.deliveryContactNumber = obVersion.deliveryContactNumber;
        this.deliveryInstructions = obVersion.deliveryInstructions;
    }

    //toDto is required but you can leave it blank
    toDto(model: AddressModel): void {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    model[key] = new Date(this[key]);
                } else {
                    model[key] = this[key];
                }
            }
        }
    }

    public toAddressDto = (): AddressModelDTO => {
        let retVal: AddressModelDTO = JSON.parse(JSON.stringify(DefaultAddressModelDTO));

        for (let key in retVal) {
            if (retVal.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    retVal[key] = new Date(this[key]);
                } else {
                    retVal[key] = this[key];
                }
            }
        }

        return retVal;
    };

    @action
    public deleteContact = (id: string) => {};

    @action
    public cleanUp() {
        this.id = "";
        this.createdDate = "";
        this.isDeleted = false;
        this.rowVersion = undefined;
        this.originatorId = undefined;
        this.addressName = "";
        this.addressLine1 = "";
        this.addressLine2 = "";
        this.addressLine3 = "";
        this.city = "";
        this.county = "";
        this.postcode = "";
        this.isPrimary = false;
        this.sourceId = "";
        this.deliveryContactName = "";
        this.deliveryContactNumber = "";
        this.deliveryInstructions = "";
    }
}

export interface AddressModelDTO extends DarwinModelBaseDTO {
    isDeleted: boolean;
    rowVersion: string | undefined;
    originatorId: string | undefined;
    addressName: string;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    city: string;
    county: string;
    postcode: string;
    isPrimary: boolean;
    sourceId: string;
    deliveryContactName: string;
    deliveryContactNumber: string;
    deliveryInstructions: string;
}

export const DefaultAddressModelDTO: AddressModelDTO = {
    id: "",
    createdDate: "",
    isDeleted: false,
    rowVersion: "",
    originatorId: "",
    addressName: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    city: "",
    county: "",
    postcode: "",
    isPrimary: false,
    sourceId: "",
    deliveryContactName: "",
    deliveryContactNumber: "",
    deliveryInstructions: "",
};

export interface FinancialModelDTO extends DarwinModelBaseDTO {
    accountOpened: string;
    vatNumber: string;
    defaultVatRate: number;
    paymentTermsInDays: number;
    paymentTerms: string;
    creditLimit: number;
    paymentGroup: string;
    insuranceExpiryDate: string;
    constructionalReg: string;
    pqq: boolean;
    invoiceFactoring: string;
    creditInsurer: string;
}

export const DefaultFinancialModelDTO: FinancialModelDTO = {
    accountOpened: "",
    vatNumber: "",
    defaultVatRate: 0,
    paymentTermsInDays: 0,
    paymentTerms: "",
    creditLimit: 0,
    paymentGroup: "",
    insuranceExpiryDate: "",
    constructionalReg: "",
    pqq: false,
    id: "",
    createdDate: "",
    invoiceFactoring: "",
    creditInsurer: "",
};
