import * as MobX from "mobx";
import { ModelBase } from "@shoothill/core";
import { IsEmail, IsNotEmpty, IsPhoneNumber, ValidateIf } from "class-validator";
import { ClientListItemDTO } from "./ContactListItemDTO";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class ContactListModel extends ModelBase<ContactListModel, ClientListItemDTO> {
    public id: string = "";
    public sourceId: string = "";

    @MobX.observable
    firstName: string = "";

    @MobX.observable
    lastName: string = "";

    @MobX.observable
    position: string = "";

    @MobX.observable
    email: string = "";

    @MobX.observable
    phone: string = "";

    @MobX.observable isPrimary: boolean = false;

    //fromDto is required but you can leave it blank
    fromDto(model: ClientListItemDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: ClientListItemDTO): void {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    model[key] = new Date(this[key]);
                } else {
                    model[key] = this[key];
                }
            }
        }
    }
}
