import { isEmptyOrWhitespace } from "@shoothill/core";

export class StockTransferHistoryItemViewModel {
    // #region Properties

    public note: string = "";
    public stockQuantity: number = 0;
    public stockTransactionType: string = "";
    public stockTransactionRequestNumber: string = "";
    public stockValue: number = 0;
    public unitChange: number = 0;
    public updatedByUser: string = "";
    public updatedDate: string = "";

    // #endregion Properties

    public get canDisplayStockTransactionRequestNumber() {
        return !isEmptyOrWhitespace(this.stockTransactionRequestNumber);
    }
}
