import { Theme } from "@material-ui/core";

export enum SupplierStatus {
    Unknown = 0,
    Draft = 100,
    PendingApproval = 200,
    Suspended = 300,
    Verified = 400,
    Expired = 500,
}

export class SupplierStatusHelpers {
    public static getText = (status: SupplierStatus) => {
        switch (status) {
            case SupplierStatus.Draft: {
                return "Draft";
            }
            case SupplierStatus.PendingApproval: {
                return "Pending Approval";
            }
            case SupplierStatus.Suspended: {
                return "Suspended";
            }
            case SupplierStatus.Verified: {
                return "Verified";
            }
            case SupplierStatus.Expired: {
                return "Expired";
            }
            case SupplierStatus.Unknown:
            default: {
                return "Unknown";
            }
        }
    };

    public static getClassName = (status: SupplierStatus) => {
        switch (status) {
            case SupplierStatus.Draft: {
                return "supplierstatus-draft";
            }
            case SupplierStatus.PendingApproval: {
                return "supplierstatus-pendingapproval";
            }
            case SupplierStatus.Suspended: {
                return "supplierstatus-suspended";
            }
            case SupplierStatus.Verified: {
                return "supplierstatus-verified";
            }
            case SupplierStatus.Expired: {
                return "supplierstatus-expired";
            }
            case SupplierStatus.Unknown:
            default: {
                return "Unknown";
            }
        }
    };

    public static getCellColour = (status: SupplierStatus, theme: Theme): string => {
        switch (status) {
            case SupplierStatus.Draft: {
                return theme.palette.supplierStatus.draft.toString();
            }
            case SupplierStatus.PendingApproval: {
                return theme.palette.supplierStatus.pendingApproval.toString();
            }
            case SupplierStatus.Suspended: {
                return theme.palette.supplierStatus.suspended.toString();
            }
            case SupplierStatus.Verified: {
                return theme.palette.supplierStatus.verified.toString();
            }
            case SupplierStatus.Expired: {
                return theme.palette.supplierStatus.expired.toString();
            }
            case SupplierStatus.Unknown:
            default: {
                return theme.palette.supplierStatus.unknown.toString();
            }
        }
    };

    public static getCellTextColour = (status: SupplierStatus): string => {
        /*         switch (status) {
            case ProjectStatus.All:
                return "#FFFFFF";
            case ProjectStatus.Unknown:
            default: */
        return "#FFFFFF";
        //}
    };

    public static getAllOptions = (ignoreUnknown: boolean = false) => {
        const options: {
            label: string;
            value: string;
        }[] = [];

        for (const key in SupplierStatus) {
            const keyAsInt = parseInt(key);

            if (isNaN(keyAsInt) === false) {
                const status: SupplierStatus = keyAsInt as SupplierStatus;

                if (ignoreUnknown === false || status !== SupplierStatus.Unknown) {
                    options.push({
                        label: SupplierStatusHelpers.getText(status),
                        value: keyAsInt.toString(),
                    });
                }
            }
        }
        return options;
    };
}
