import { FieldType, isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import type { ValidationResponse } from "@shoothill/core";
import { action, computed, observable, runInAction } from "mobx";

import { AppUrls } from "AppUrls";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { DeleteIncomeAndExpenditureModel, IncomeAndExpenditureDeleteResponseDTO } from "./DeleteIncomeAndExpenditureModel";

export class DeleteIncomeAndExpenditureViewModel extends ViewModelBase<DeleteIncomeAndExpenditureModel> {
    constructor(id: string, parentCancelActionCallback: () => void, parentDeleteActionCallback: (incomeAndExpenditureId: string) => void) {
        super(new DeleteIncomeAndExpenditureModel());
        this.setDecorators(DeleteIncomeAndExpenditureModel);

        this.model.id = id;

        this.parentCancelCallback = parentCancelActionCallback;
        this.parentDeleteCallback = parentDeleteActionCallback;

        this.load();
    }

    // #region Properties

    public server: ServerViewModel = new ServerViewModel();

    @computed
    private get validateId(): ValidationResponse {
        const errorMessage = this.model.validateId;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateRowVersion(): ValidationResponse {
        const errorMessage = this.model.validateRowVersion;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    // #endregion Properties

    // #region Actions

    private parentCancelCallback: () => void;
    private parentDeleteCallback: (incomeAndExpenditureId: string) => void;

    @action
    public cancel = () => {
        if (!this.server.IsBusy) {
            this.parentCancelCallback();
        }
    };

    public load = (): Promise<void> => {
        return this.server.query<IncomeAndExpenditureDeleteResponseDTO>(
            () => this.Get(`${AppUrls.Server.Projects.IncomeExpend.GetDeleteIE}\\${this.model.id}`),
            (result) => {
                runInAction(() => {
                    this.model.fromDto(result);
                });
            },
        );
    };

    public delete = (): Promise<void> => {
        return this.server.command<IncomeAndExpenditureDeleteResponseDTO>(
            () => this.Post(AppUrls.Server.Projects.IncomeExpend.DeleteIE, this.model.toDto()),
            (result) => {
                runInAction(() => {
                    this.model.fromDto(result);

                    // Side-effect. Notify parent that we have deleted.
                    this.parentDeleteCallback(result.id);
                });
            },
            this.isModelValid,
            "There was an error trying to delete the income and expenditure",
        );
    };

    // #endregion Actions

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<DeleteIncomeAndExpenditureModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        if (this.server.IsSubmitted) {
            // Process the properties of the model that cannot be supported via
            // the use of decorators.
            switch (fieldName) {
                case "id": {
                    const result = this.validateId;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }

                case "rowVersion": {
                    const result = this.validateRowVersion;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
            }
        } else {
            // Do not validate if the properties of the model have not been
            // submitted to the server.
            errorMessage = "";
            isValid = true;
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Bolierplate
}
