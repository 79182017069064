import { ModelBase, observable } from "@shoothill/core";
import { action, computed } from "mobx";
import moment from "moment";
export class LabourShortageModel extends ModelBase<LabourShortageModel> {
    public static readonly DEFAULT_ID = null;
    public static readonly DEFAULT_PROJECT_ID = "";
    public static readonly DEFAULT_LABOUR_SHORTAGE_NOTE = "";
    public static readonly DEFAULT_LABOUR_SHORTAGE_PHOTOS = [];
    public static readonly DEFAULT_ASSIGN_TO_USER_ID = "";
    public static readonly DEFAULT_CREATED_BY_USER_ID = "";
    public static readonly DEFAULT_CREATED_DATE = new Date();
    public static readonly DEFAULT_IS_DELETED = false;
    public static readonly DEFAULT_LAST_UPDATED_BY_USER_ID = "";
    public static readonly DEFAULT_LAST_UPDATED_DATE = "";
    public static readonly DEFAULT_ROW_VERSION = "";

    @observable
    public id: string | null = LabourShortageModel.DEFAULT_ID;

    @observable
    public projectId: string | null = LabourShortageModel.DEFAULT_PROJECT_ID;

    @observable
    public labourShortageNote: string | null = LabourShortageModel.DEFAULT_LABOUR_SHORTAGE_NOTE;

    @observable
    public labourShortagePhotos: LabourShortagePhotosFile[] = LabourShortageModel.DEFAULT_LABOUR_SHORTAGE_PHOTOS;

    @observable
    public assignToUserId: string | null = LabourShortageModel.DEFAULT_ASSIGN_TO_USER_ID;

    @observable
    public createdByUserId: string | null = LabourShortageModel.DEFAULT_CREATED_BY_USER_ID;

    @observable
    public createdDate: any = LabourShortageModel.DEFAULT_CREATED_DATE;

    @observable
    public isDeleted: boolean = LabourShortageModel.DEFAULT_IS_DELETED;

    @observable
    public lastUpdatedByUserId: string | null = LabourShortageModel.DEFAULT_LAST_UPDATED_BY_USER_ID;

    @observable
    public lastUpdatedDate: string | null = LabourShortageModel.DEFAULT_LAST_UPDATED_DATE;

    @observable
    public rowVersion: string | null = LabourShortageModel.DEFAULT_ROW_VERSION;

    @computed
    public get hasId(): boolean {
        return this.id !== null && this.id !== undefined && this.id !== "";
    }

    @computed
    public get formattedLastUpdatedDate(): string {
        return moment(this.lastUpdatedDate).format("DD/MM/YYYY @ HH:mm");
    }

    @action
    public fromDto(dto: LabourShortageDTO): void {
        for (let key in dto) {
            if (dto.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(dto[key]);
                } else {
                    this[key] = dto[key];
                }
            }
        }
    }

    @action
    public fromLabourShortagePhotosDTO(dto: LabourShortagePhotosFile[]): void {
        let processedPhotos: LabourShortagePhotosFile[] = [];
        for (const photo of dto) {
            processedPhotos.push(photo);
        }
        this.labourShortagePhotos = processedPhotos;
    }

    @action
    public toDto() {
        const LabourShortageModel: LabourShortageDTO = {
            id: this.id,
            projectId: this.projectId,
            labourShortageNote: this.labourShortageNote,
            assignToUserId: this.assignToUserId,
            createdByUserId: this.createdByUserId,
            createdDate: this.createdDate,
            isDeleted: this.isDeleted,
            lastUpdatedByUserId: this.lastUpdatedByUserId,
            lastUpdatedDate: this.lastUpdatedDate,
            rowVersion: this.rowVersion,
        };

        return LabourShortageModel;
    }

    @action
    public reset = () => {
        this.id = LabourShortageModel.DEFAULT_ID;
        this.projectId = LabourShortageModel.DEFAULT_PROJECT_ID;
        this.labourShortageNote = LabourShortageModel.DEFAULT_LABOUR_SHORTAGE_NOTE;
        this.assignToUserId = LabourShortageModel.DEFAULT_ASSIGN_TO_USER_ID;
        this.labourShortagePhotos = LabourShortageModel.DEFAULT_LABOUR_SHORTAGE_PHOTOS;
    };

    // #Start Custome Validation

    @computed
    public get validateLabourShortageNote(): string {
        return this.labourShortageNote === LabourShortageModel.DEFAULT_LABOUR_SHORTAGE_NOTE ? "Please enter a note" : "";
    }

    @computed
    public get validateAssignToUserId(): string {
        return this.assignToUserId === LabourShortageModel.DEFAULT_ASSIGN_TO_USER_ID ? "Please select a assign to" : "";
    }

    // #End Custome Validation
}

export interface LabourShortageDTO {
    id: string | null;
    projectId: string | null;
    labourShortageNote: string | null;
    assignToUserId: string | null;
    createdByUserId: string | null;
    createdDate: string | null;
    isDeleted: boolean;
    lastUpdatedByUserId: string | null;
    lastUpdatedDate: string | null;
    rowVersion: string | null;
}

export interface LabourShortagePhotosFile {
    id: string | null;
    labourShortageId: string | null;
    fileName: string;
    photoURL: string;
    createdByUserId: string | null;
    isDeleted: boolean;
    createdDate: string | null;
}

export interface AssignToUsersDTO {
    id: string;
    displayName: string;
}

export interface UpsertLabourShortageRequestDTO {
    labourShortage: LabourShortageDTO;
    labourShortagePhotos: LabourShortagePhotosFile[];
}

export interface UpsertLabourShortageResponseDTO {
    labourShortage: LabourShortageDTO;
    labourShortageUsers: AssignToUsersDTO[];
    labourShortagePhotos: LabourShortagePhotosFile[];
}

export interface LabourShortageByIdResponseDTO {
    labourShortage: LabourShortageDTO;
    labourShortageList: LabourShortageDTO[];
    labourShortageUsers: AssignToUsersDTO[];
    labourShortagePhotos: LabourShortagePhotosFile[];
}

export interface LabourShortageResponseDTO {
    labourShortage: LabourShortageDTO;
    labourShortageList: LabourShortageDTO[];
    labourShortageUsers: AssignToUsersDTO[];
    labourShortagePhotos: LabourShortagePhotosFile[];
}

export interface FormRequestDTO {
    date: string | null;
    projectId: string | null;
    formId: string | null;
}
