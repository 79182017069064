import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";

import { ApiResult } from "@shoothill/core";
import { ClientDetailViewModel } from "./ClientDetailViewModel";
import { DetailsPage, DetailsPrimaryContact, DetailsTabs } from "Globals/Views/DetailsPage/DetailsPage.styles";
import { DetailsHeader } from "Globals/Views/DetailsPage/DetailsHeader";
import { ITab, Tabs } from "Components/Tabs/Tabs";
import { useHasRoutedTabs } from "Utils/UseHasRoutedTabs";
import { ClientWithRelatedDTO } from "./ClientWithRelatedDTO";
import { useRef } from "react";
import { useRouter } from "@shoothill/core";

import { AddContactTitle } from "./ClientDetails.styles";
import { AddEditContactModel } from "Views/Contacts/AddEditContactModel";
import { AddEditContactView } from "Views/Contacts/AddEditContactView";
import { ClientAddEditModal } from "./ClientAddEditModal";
import { ContactListView } from "Views/Contacts/ContactListView";
import { ContactType } from "Views/Contacts/ContactType";
import { ContactView } from "Views/Contacts/ContactView";
import { DetailsHeaderModel } from "Globals/Views/DetailsPage/DetailsHeaderModel";

import OrangeCloseImg from "Content/OrangeCross.svg";
import OrangeOpenImg from "Content/OrangePlus.svg";
import { ClientContactModelDTO } from "./ClientContactModel";
import { PurchaseOrderTableView } from "Views/PurchaseOrder/PurchaseOrderTableView";
import { ProjectTableView } from "Views/Project/ProjectTableView";
import { AppUrls } from "AppUrls";
import { InvoiceTableView } from "Views/Invoice/InvoiceTableView";
import { AddEditContactViewModel } from "Views/Contacts/AddEditContactViewModel";
import { runInAction } from "mobx";

const TAB_CURRENT_PROJECTS = "currentProjects";
const TAB_PURCHASE_ORDERS = "purchaseOrders";
const TAB_CONTACTS = "contacts";

const tabOptions: ITab[] = [
    { key: TAB_CURRENT_PROJECTS, title: "Current Projects", hash: "currentProjects" },
    { key: TAB_PURCHASE_ORDERS, title: "Purchase Orders", hash: "purchaseOrders" },
    { key: TAB_CONTACTS, title: "Client Contacts", hash: "contacts" },
];

export const ClientDetailView: React.FunctionComponent = () => {
    const addContactRef = useRef();
    const [viewModel] = useState(() => ClientDetailViewModel.Instance);
    const router = useRouter();
    const hashTab = useHasRoutedTabs(tabOptions);
    const [selectedTab, setSelectedTab] = React.useState(hashTab?.key || TAB_CONTACTS);
    const [tabKey, setTabKey] = React.useState(0);
    const [contactViewModel] = useState(() => new AddEditContactViewModel());
    const [titleModel, setTitleModel] = React.useState<DetailsHeaderModel>(viewModel.getHeader);
    const [showAddContact, setShowAddContact] = React.useState(false);

    //useEffect below only gets run on initial render
    useEffect(() => {
        let { clientid } = router.match.params as any;

        let promise = viewModel.loadClientAsync(clientid);

        promise.then((result: ApiResult<ClientWithRelatedDTO>) => {
            if (result.wasSuccessful === true) {
                setTitleModel(viewModel.getHeader);
            }
        });

        return () => {
            // Clean up after yourself
            viewModel.cleanUp();
        };
    }, []);

    const handleTabClick = (tab: ITab) => {
        setSelectedTab(tab.key);
        setTabKey(tabKey + 1);
    };

    const getTabKey = (): string => {
        return `clienthome-tab-${selectedTab}-${tabKey}`;
    };

    const onAddContact = async (model: AddEditContactModel) => {
        if (await contactViewModel.isModelValid()) {
            runInAction(() => {
                const dto = model.toClientContactDto(model.sourceId);
                contactViewModel.model.fromClientContactDto(dto);
            });

            let promise = viewModel.addContact(model);
            promise.then((result: ApiResult<ClientContactModelDTO>) => {
                if (result.wasSuccessful === true) {
                    contactViewModel.model.clear();
                }
            });
        }
    };

    const onEditContact = (model: AddEditContactModel) => {
        runInAction(() => {
            const dto = model.toClientContactDto(model.sourceId);
            contactViewModel.model.fromClientContactDto(dto);
        });

        let promise = viewModel.updateContact(model);
        promise.then((result: ApiResult<ClientContactModelDTO>) => {
            if (result.wasSuccessful === true) {
                contactViewModel.model.clear();
            }
        });
        return promise;
    };

    const onContactListCleanup = () => {
        // TODO CMR
        contactViewModel.model.clear();
    };

    const onDeleteContact = (id: string) => {
        const promise = viewModel.deleteContact(id);

        promise.then((result: ApiResult<number>) => {});
    };

    const updatContactIsPrimary = (id: string, isPrimary: boolean) => {
        const promise = viewModel.updatContactIsPrimary(id, isPrimary);

        promise.then((result: ApiResult<ClientContactModelDTO[]>) => {});
    };

    const loadContactAsync = (id: string): Promise<ApiResult<ClientContactModelDTO>> => {
        const promise = viewModel.loadContactAsync(id);
        return promise;
    };

    const toggleAddContact = () => {
        setShowAddContact(!showAddContact);
    };

    const renderTabContent = () => {
        switch (selectedTab) {
            case TAB_CONTACTS:
                return (
                    <div>
                        <AddContactTitle onClick={toggleAddContact}>
                            <img src={showAddContact === true ? OrangeCloseImg : OrangeOpenImg} alt={showAddContact === true ? "Close add contact" : "Open Add Contact"} />
                            Add Contact
                        </AddContactTitle>

                        {showAddContact === true && (
                            <AddEditContactView viewModel={contactViewModel} key={getTabKey()} onUpsertContact={onAddContact} isLoading={viewModel.IsLoading} />
                        )}
                        <ContactListView
                            contactType={ContactType.Client}
                            contactList={viewModel.getContactList}
                            deleteContact={onDeleteContact}
                            loadContact={loadContactAsync}
                            upsertContact={onEditContact}
                            onCleanup={onContactListCleanup}
                            updatContactIsPrimary={updatContactIsPrimary}
                            showEditColumn={true}
                        />
                    </div>
                );
            case TAB_PURCHASE_ORDERS:
                return <div key={getTabKey()}>{/* <PurchaseOrderTableView data={viewModel.getPurchaseOrdersList} poViewURL={AppUrls.Client.PurchaseOrder.Detail} /> */}</div>;
            case TAB_CURRENT_PROJECTS:
                return (
                    <div key={getTabKey()}>
                        <ProjectTableView data={viewModel.getProjects} projectViewURL={AppUrls.Client.Project.General} />
                    </div>
                );
        }
    };

    const onCloseModal = () => {
        viewModel.showAddEditModal(false);
    };

    const onSave = (editedClient: ClientWithRelatedDTO): any => {
        viewModel.updateClient(editedClient);
        setTitleModel(viewModel.getHeader);
    };

    return useObserver(() => (
        <DetailsPage>
            <ClientAddEditModal open={viewModel.getShowAddEditModal} onClose={onCloseModal} title="Edit Client" onSave={onSave} confirmText="Update" />
            <DetailsHeader viewModel={titleModel} />
            <DetailsPrimaryContact>
                <ContactView model={viewModel.getPrimaryContact} key={getTabKey()} isLoading={viewModel.IsLoading} />
            </DetailsPrimaryContact>
            <DetailsTabs>
                <Tabs tabs={tabOptions} onTabClick={handleTabClick} selected={selectedTab} minTabWidth={132} tabPadding="11.5px 8px" />
                <div className="content">{renderTabContent()}</div>
            </DetailsTabs>
        </DetailsPage>
    ));
};
