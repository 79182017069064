import { observable, runInAction, computed, action } from "mobx";
import { FieldType, ViewModelBase } from "@shoothill/core";

import { AppUrls } from "AppUrls";
import { isEmpty } from "Utils/Utils";
import { Step1Model } from "./Step1Model";

export class Step1ViewModel extends ViewModelBase<Step1Model> {
    @observable public allowNextStep: boolean = false;
    @observable public takingPicture: boolean = false;
    @observable public startCamera: boolean = false;
    @observable public showCropper: boolean = false;
    @observable public tempPhoto: string = "";

    constructor() {
        super(new Step1Model());
    }

    @action
    public takePicture = () => {
        this.startCamera = true;
    };

    @action
    public savePicture = (photo: string) => {
        this.startCamera = false;
        this.setValue("photoData", photo);
        this.showCropper = false;
        this.takingPicture = false;
    };

    @action
    public reTakePicture = (photo: string) => {
        this.showCropper = false;
        this.startCamera = true;
    };

    public canTakePicture = () => {};
    public uploadPicture = () => {};

    @computed
    public get hasImage() {
        return !isEmpty(this.model.photoData);
    }

    @action
    public turnOffCamera = () => {
        this.startCamera = false;
        //this.takingPicture = false;
    };

    // #region Step1

    @action
    public nextStep = () => {
        this.setIsLoading(true);
        this.history.push(AppUrls.Client.Induction.Step2);
        setTimeout(() => {
            this.setIsLoading(false);
        }, 100);
    };

    @action
    public previousStep = () => {
        this.setIsLoading(true);
        this.history.push(AppUrls.Client.Induction.Landing);
        setTimeout(() => {
            this.setIsLoading(false);
        }, 100);
    };

    @computed
    public get canExecuteNextStep() {
        this.isModelValid().then((isValid) => {
            runInAction(() => {
                this.allowNextStep = isValid;
            });
        });
        return this.allowNextStep;
    }

    // #endregion Step1

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<Step1Model>, value: any): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
