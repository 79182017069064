import { Theme } from "@material-ui/core";

export enum ProjectCell {
    Unknown = 0,
    Cell1 = 1,
    Cell2 = 2,
    Cell3 = 3,
    Cell4 = 4,
    Cell5 = 5,
    Central = 1000,
    Production = 2000,
}

export class ProjectCellHelpers {
    public static getText = (status: ProjectCell) => {
        switch (status) {
            case ProjectCell.Cell1: {
                return "Cell 1";
            }
            case ProjectCell.Cell2: {
                return "Cell 2";
            }
            case ProjectCell.Cell3: {
                return "Cell 3";
            }
            case ProjectCell.Cell4: {
                return "Cell 4";
            }
            case ProjectCell.Cell5: {
                return "Cell 5";
            }
            case ProjectCell.Central: {
                return "Central";
            }
            case ProjectCell.Production: {
                return "Production";
            }
            case ProjectCell.Unknown:
            default: {
                return "Unknown";
            }
        }
    };

    public static getClassName = (status: ProjectCell) => {
        switch (status) {
            case ProjectCell.Cell1: {
                return "projectcell-cell1";
            }
            case ProjectCell.Cell2: {
                return "projectcell-cell2";
            }
            case ProjectCell.Cell3: {
                return "projectcell-cell3";
            }
            case ProjectCell.Cell4: {
                return "projectcell-cell4";
            }
            case ProjectCell.Cell5: {
                return "projectcell-cell5";
            }
            case ProjectCell.Central: {
                return "projectcell-central";
            }
            case ProjectCell.Production: {
                return "projectcell-production";
            }
            case ProjectCell.Unknown:
            default: {
                return "Unknown";
            }
        }
    };

    public static getAllOptions = (ignoreUnknown: boolean = false) => {
        const options: {
            label: string;
            value: string;
        }[] = [];

        for (const key in ProjectCell) {
            const keyAsInt = parseInt(key);

            if (isNaN(keyAsInt) === false) {
                const status: ProjectCell = keyAsInt as ProjectCell;

                if (ignoreUnknown === false || status !== ProjectCell.Unknown) {
                    options.push({
                        label: ProjectCellHelpers.getText(status),
                        value: keyAsInt.toString(),
                    });
                }
            }
        }
        return options;
    };

    public static getAllSelectOptions = (ignoreUnknown: boolean = false) => {
        const options: {
            label: string;
            value: string;
        }[] = [];

        for (const key in ProjectCell) {
            const keyAsInt = parseInt(key);

            if (isNaN(keyAsInt) === false) {
                const status: ProjectCell = keyAsInt as ProjectCell;

                if (ignoreUnknown === false || status !== ProjectCell.Unknown) {
                    options.push({
                        label: ProjectCellHelpers.getText(status),
                        value: keyAsInt.toString(),
                    });
                }
            }
        }
        return options;
    };

    public static getProjectCellColour = (status: ProjectCell, theme: Theme) => {
        switch (status) {
            case ProjectCell.Cell1: {
                return theme.palette.common.white[300];
            }
            case ProjectCell.Cell2: {
                return theme.palette.common.white[300];
            }
            case ProjectCell.Cell3: {
                return theme.palette.common.white[300];
            }
            case ProjectCell.Cell4: {
                return theme.palette.common.white[300];
            }
            case ProjectCell.Cell5: {
                return theme.palette.common.white[300];
            }
            case ProjectCell.Central: {
                return theme.palette.common.white[300];
            }
            case ProjectCell.Production: {
                return theme.palette.common.white[300];
            }
            case ProjectCell.Unknown:
            default: {
                return theme.palette.common.white[300];
            }
        }
    };

    public static getCellTextColour = (status: ProjectCell) => {
        /*         switch (status) {
            case ProjectStatus.All:
                return "#FFFFFF";
            case ProjectStatus.Unknown:
            default: */
        return "#000000";
        //}
    };

    public static getAllKeyValueSelectOptions = (ignoreUnknown: boolean = false) => {
        const options: {
            key: string;
            value: string;
        }[] = [];

        for (const key in ProjectCell) {
            const keyAsInt = parseInt(key);

            if (isNaN(keyAsInt) === false) {
                const status: ProjectCell = keyAsInt as ProjectCell;

                if (ignoreUnknown === false || status !== ProjectCell.Unknown) {
                    options.push({
                        key: ProjectCellHelpers.getText(status),
                        value: keyAsInt.toString(),
                    });
                }
            }
        }
        return options;
    };
}
