import { KeyValuePair } from "@shoothill/core";
// Libs
import { FieldType, isNullOrUndefined, ViewModelBase } from "@shoothill/core";
import { action, computed, observable, runInAction } from "mobx";

// App
import { ClientNameAndId, ClientNameAndIdDTO } from "Globals/Models";

export class ClientSelectViewModel extends ViewModelBase<ClientNameAndId> {
    private static _instance: ClientSelectViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable public errorMessage: string = "";

    @observable
    public hasLoaded = true;

    @action
    public setHasLoaded(val: boolean) {
        this.hasLoaded = val;
    }

    constructor() {
        super(new ClientNameAndId(), false);
        this.setDecorators(ClientNameAndId);
    }

    @observable
    private clientNameAndIdArr: ClientNameAndIdDTO[] = [];

    @computed
    public get returnClientArr() {
        return this.clientNameAndIdArr.map((c) => {
            return { id: c.id, name: c.name };
        });
    }

    @computed
    public get returnClientList() {
        return this.clientNameAndIdArr
            .filter((f) => f.isDeleted === false)
            .map((c) => {
                const retVal: KeyValuePair = {
                    key: c.id.toString(),
                    value: c.name,
                };
                return retVal;
            });
    }

    @computed
    public get returnActiveClients() {
        return this.clientNameAndIdArr
            .filter((f) => f.isDeleted === false)
            .map((c) => {
                return { id: c.id, name: c.name };
            });
    }

    @action
    public chosenClient(val: string) {
        let _val = !isNullOrUndefined(val) ? val : "";
        let _client = this.clientNameAndIdArr.find((c) => c.id === _val);
        return !isNullOrUndefined(_client) ? _client : this.clientNameAndIdArr[0];
    }

    @action
    public populateClientNameAndId(payload: ClientNameAndIdDTO[]) {
        this.clientNameAndIdArr = [];
        payload.forEach((client) => {
            this.clientNameAndIdArr.push(client);
        });
    }

    public async isFieldValid(fieldName: keyof FieldType<ClientNameAndId>): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
