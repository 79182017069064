// Libraries
import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { Button, Typography } from "@material-ui/core";
import PlusSign from "Content/WhitePlus.svg";

// Custom
import { BaseModal } from "Components/Modal/BaseModal";

// Styling & Images
import { ProjectModalStyle, Main } from "../Modals/Modal.Styles";

interface IProjectCreatedModalProps {
    open?: boolean;
    onClose(): void;
    onContinue(): void;
    onSaveDraft(): void;
}

export const GeneralCreatedModal: React.FC<IProjectCreatedModalProps> = (props) => {
    const { open, onClose, onContinue, onSaveDraft } = props;
    return useObserver(() => (
        <BaseModal
            dialogClasses="project-dialog"
            open={open}
            onClose={onClose}
            title="New project"
            PaperComponent={ProjectModalStyle}
            actions={
                <>
                    <Button onClick={onContinue} color="primary" autoFocus variant="contained">
                        Continue creating project
                    </Button>
                    <Button onClick={onSaveDraft} color="primary" variant="contained">
                        Save as draft & return to project list
                    </Button>
                </>
            }
            alternateTitle={
                <>
                    <span>
                        <img src={PlusSign} className="add-image" alt="Add/update project" style={{ marginRight: "10px" }} />
                        New project
                    </span>
                    <span role="button" onClick={onClose} className="modal-close">
                        🗙
                    </span>
                </>
            }
        >
            <Main>
                <p>Your project has been created in the system, you may now choose to either continue creating this project or save as a draft to complete later</p>
            </Main>
        </BaseModal>
    ));
};
