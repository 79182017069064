// Libraries
import { Typography } from "@material-ui/core";
import { Grid, useObserver } from "@shoothill/core";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";

// Custom
import { PatchedPagination } from "Components/Table/PatchedPagination";
import { TableListSearch } from "Components/Table/TableListSearch";
import * as Defaults from "Globals/Defaults/TableOptions";
import { DarwinPage, DarwinPageTitle, DarwinTablePageContent, DarwinTableSearchBox, DarwinTableToolbar } from "Globals/Styles/AppStyling";
import { IEGridItemViewModel } from "Views/Project/Commercial/IEmodels/IEGridItemViewModel";
import { formatDateTime } from "Utils/Format";
import { MaterialApprovalView } from "Views/Stock/Material/MaterialApproval/MaterialApprovalView";
import { MaterialApprovalViewModel } from "Views/Stock/Material/MaterialApproval/MaterialApprovalViewModel";
import { MaterialPriceDetailsApprovalViewModel } from "Views/Stock/Material/MaterialPriceDetailsApproval/MaterialPriceDetailsApprovalViewModel";
import { MaterialPriceDetailsApprovalView } from "Views/Stock/Material/MaterialPriceDetailsApproval/MaterialPriceDetailsApprovalView";
import { StockAdjustmentApprovalViewModel } from "Views/Stock/Stock/StockAdjustmentApproval/StockAdjustmentApprovalViewModel";
import { StockAdjustmentApprovalView } from "Views/Stock/Stock/StockAdjustmentApproval/StockAdjustmentApprovalView";
import { StockTransferApprovalViewModel } from "Views/Stock/Stock/StockTransferApproval/StockTransferApprovalViewModel";
import { StockTransferApprovalView } from "Views/Stock/Stock/StockTransferApproval/StockTransferApprovalView";
import { ApprovalDelegateUserDataViewModel } from "./ApprovalDelegate/ApprovalDelegateUserDataViewModel";
import { MyAmendListItemViewModel } from "./MyAmendListItemViewModel";
import { Tabsv2 } from "./Navigation/Tabsv2";
import { PurchaseListViewModel } from "./PurchaseListViewModel";

export const PurchaseListView: React.FunctionComponent = () => {
    const [viewModel] = useState(() => new PurchaseListViewModel());

    useEffect(() => {
        IEGridItemViewModel.Instance.setIsCentral(false);
        ApprovalDelegateUserDataViewModel.Instance.getDelegateData();

        return () => {};
    }, []);

    const setSearchString = (value: string) => {
        const name = viewModel.getRequisitionPOStatusNameByType(Number(viewModel.tabsViewModel.activeTabItemViewModel?.id ?? ""));

        viewModel.setSearchString(value, name);
    };

    const onRowClick = (event?: any, rowViewModel?: MyAmendListItemViewModel) => {
        switch (viewModel.tabsViewModel.activeTabItemViewModel?.id) {
            case PurchaseListViewModel.TAB_AMENDS_REQUIRED:
                rowViewModel?.navigateToFormApproval();
                break;

            case PurchaseListViewModel.TAB_PENDING_APPROVAL:
            case PurchaseListViewModel.TAB_COMPLETED:
            case PurchaseListViewModel.TAB_REJECTED:
            default:
                rowViewModel?.navigateToForm(viewModel);
                break;
        }
    };

    const renderMyAmendsTable = () => {
        const tableOptions = Defaults.GetDarwinTableOptions() as any;

        return (
            <MaterialTable
                isLoading={viewModel.IsLoading}
                columns={[
                    {
                        title: "What is being amended?",
                        field: "approvalSectionName",
                        width: "auto",
                    },
                    {
                        title: "Ref/Name",
                        field: "refNameFormatted",
                        width: "auto",
                    },
                    {
                        title: "Description",
                        field: "itemDescription",
                        width: "auto",
                    },
                    {
                        title: "Cost",
                        field: "itemCost",
                        width: "auto",
                        render: (rowData: MyAmendListItemViewModel) => <>{rowData.itemCostFormatted}</>,
                    },
                    {
                        title: "Approver",
                        field: "approverRoleName",
                        width: "auto",
                    },
                    {
                        title: "Supplier",
                        field: "supplierName",
                        width: "auto",
                    },
                    {
                        title: "Approval type",
                        field: "approvalTypeName",
                        align: "center",
                        width: "auto",
                    },
                    {
                        title: "Requested by",
                        field: "requestedBy",
                        width: "auto",
                    },
                    {
                        title: "Requested on",
                        field: "requestedOnDate",
                        width: "auto",
                        type: "date",
                        render: (rowData: MyAmendListItemViewModel) => <>{formatDateTime(rowData.requestedOnDate !== null ? rowData.requestedOnDate.toString() : "")}</>,
                    },
                ]}
                components={{
                    Pagination: PatchedPagination,
                }}
                data={viewModel.filteredMyAmendViewModels}
                onRowClick={onRowClick}
                options={{ ...tableOptions, filtering: false, search: false, toolbar: false }}
                title=""
            />
        );
    };

    const renderApprovalModal = () => {
        switch (true) {
            case viewModel.approvalViewModel instanceof MaterialApprovalViewModel:
                return <MaterialApprovalView viewModel={viewModel.approvalViewModel as MaterialApprovalViewModel} />;

            case viewModel.approvalViewModel instanceof MaterialPriceDetailsApprovalViewModel:
                return <MaterialPriceDetailsApprovalView viewModel={viewModel.approvalViewModel as MaterialPriceDetailsApprovalViewModel} />;

            case viewModel.approvalViewModel instanceof StockAdjustmentApprovalViewModel:
                return <StockAdjustmentApprovalView viewModel={viewModel.approvalViewModel as StockAdjustmentApprovalViewModel} />;

            case viewModel.approvalViewModel instanceof StockTransferApprovalViewModel:
                return <StockTransferApprovalView viewModel={viewModel.approvalViewModel as StockTransferApprovalViewModel} />;

            default:
                return null;
        }
    };

    return useObserver(() => {
        return (
            <DarwinPage>
                <DarwinPageTitle>
                    <Typography variant="h1" color="textPrimary">
                        Approvals - I need authority
                    </Typography>
                </DarwinPageTitle>
                <DarwinTableToolbar>
                    <Grid columnGap={30} dc={"1fr 1fr"} rowGap={15} tc={"1fr 1fr"}>
                        <DarwinTableSearchBox>
                            <TableListSearch searchString={viewModel.getSearchString()} setSearchString={setSearchString} />
                        </DarwinTableSearchBox>
                    </Grid>
                </DarwinTableToolbar>
                <DarwinTablePageContent>
                    <Tabsv2 viewModel={viewModel.tabsViewModel} />
                    {viewModel.hasLoaded && renderMyAmendsTable()}
                </DarwinTablePageContent>
                {renderApprovalModal()}
            </DarwinPage>
        );
    });
};
