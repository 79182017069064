import { computed } from "mobx";
import { AccidentGenericListItemDTO, AccidentGenericListItemModel } from "./AccidentGenericListItemModel";

export class AccidentInjuryAreaItemModel extends AccidentGenericListItemModel {
    constructor() {
        super();
    }

    fromDto(model: AccidentInjuryAreaItemDTO): void {
        super.fromDto(model);
        this.typeId = model.injuryAreaId;
    }

    @computed
    public get injuryAreaId() {
        return this.typeId;
    }

    public static fromDtos(dtos: AccidentInjuryAreaItemDTO[]): AccidentInjuryAreaItemModel[] {
        const types: AccidentInjuryAreaItemModel[] = [];

        for (const dto of dtos) {
            const model = new AccidentInjuryAreaItemModel();

            model.fromDto(dto);
            types.push(model);
        }

        return types;
    }

    toDto(): AccidentInjuryAreaItemDTO {
        const dto: AccidentGenericListItemDTO = super.toDto();
        return {
            ...dto,
            injuryAreaId: this.injuryAreaId,
        };
    }
}

export interface AccidentInjuryAreaItemDTO extends AccidentGenericListItemDTO {
    injuryAreaId: string | null;
}
