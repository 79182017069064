import { pxToRem, theme } from "Globals/Styles/AppTheme";
import styled from "styled-components";

export const InvoiceMatchRowBase = styled.div`
    display: flex;
    flex-direction: row;

    font-size: 12px;

    > div:nth-child(1) {
        width: 30%;
    }

    > div:nth-child(2) {
        width: 10%;
    }

    > div:nth-child(3),
    div:nth-child(4),
    div:nth-child(5) {
        width: 12%;
        text-align: center;
    }

    > div:nth-child(6) {
        width: 24%;
        text-align: center;
    }
`;

export const InvoiceMatchHeader = styled(InvoiceMatchRowBase)`
    letter-spacing: -0.24px;
    line-height: 17px;
    margin-bottom: 6px;
    font-weight: bold;
    background-color: ${theme.palette.mediumGrey.main};
    padding: 12px 23px;
`;

export const InvoiceMatchRow: any = styled(InvoiceMatchRowBase)`
    letter-spacing: -0.18px;
    line-height: 16px;
    display: flex;
    align-items: center;
    min-height: 44px;
    padding: 6px 23px;

    input {
        height: 17px;
    }
`;

export const InvoiceMatchFooter = styled(InvoiceMatchRowBase)`
    letter-spacing: -0.18px;
    line-height: 16px;
    display: flex;
    align-items: center;
    min-height: 44px;
    padding: 6px 23px;
    font-weight: bold;

    > div:nth-child(2) {
        font-size: ${pxToRem(18)};
    }
`;

export const InvoiceMatchPORow: any = styled(InvoiceMatchRow)`
    font-weight: bold;

    > div:nth-child(1),
    div:nth-child(2) {
        color: ${theme.palette.blue.main};
    }

    > div:nth-child(1) {
        display: flex;
        flex-direction: row;

        > span {
            margin-left: auto;
            display: block;
            margin-right: 40px;
        }
    }

    .po-link {
        cursor: pointer;
    }

    .po-link:hover {
        text-decoration: underline;
    }
`;

export const InvoiceMatchGroupRow: any = styled(InvoiceMatchRow)`
    font-weight: bold;
`;

export const InvoiceMatchItemRow: any = styled(InvoiceMatchRow)``;

export const InvoiceMatchRows: any = styled.div`
    hr {
        margin: 6px 23px;
    }

    > div > hr {
        margin: 16px 23px;
    }

    > div:last-child > hr {
        display: none;
    }
`;

export const InvoiceMatchGroupRowContainer: any = styled.div``;

export const InvoiceMatchItemRowsContainer: any = styled.div`
    > hr {
        margin-bottom: 20px;
    }
`;
