import { IconButton, TextField } from "@material-ui/core";
import { isEmptyOrWhitespace } from "@shoothill/core";
import { useObserver } from "mobx-react-lite";
import React, { useState } from "react";

import {
    ApprovalPanelContainer,
    ApprovalPanelAmendButton,
    ApprovalPanelApproveButton,
    ApprovalPanelFooter,
    ApprovalPanelRejectButton,
    ApprovalPanelSendButton,
} from "Components/Approval/ApprovalPanel.styles";
import { DefaultButton } from "Components/Buttons/Buttons";
import { BaseSidebar } from "Components/Sidebar/BaseSidebar";
import { Uploader } from "Components/Uploader/Uploader";
import { ApprovalDocumentModel } from "./ApprovalDocumentModel";
import SystemUpdateTwoToneIcon from "@material-ui/icons/SystemUpdateTwoTone";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

interface IProps {
    sideOpen: boolean;
    upsertApprovalStatus: (isApproved: boolean, amendmentNote: string, requisitionStatusId: string) => void;
    bodyNewApprovalPanel: JSX.Element;
    bodyAmenderPanel: JSX.Element;
    bodyAmendedApprovalPanel: JSX.Element;
    bodyDefault: JSX.Element;
    canShowNewApprovalPanel: boolean;
    canShowAmenderPanel: boolean;
    canShowAmendedApprovalPanel: boolean;
    isFromApprovalSection: boolean;
    canUpsertStatus: boolean;
    primaryTitle: string;
    secondaryTitle: string | null;
    approvedStatusId: string;
    amendRequiredStatusId: string;
    rejectedStatusId: string;
    canViewFooter: boolean;
    downloadFile?: (fileUrl: string, fileName: string) => Promise<void>;
    fileChange?: (event: any) => Promise<void>;
    approvalDocumentsToUpload?: ApprovalDocumentModel[];
    handleCancelAmend?: () => void;
    handleGeneratePreviewPDF?: () => Promise<void>;
}

export const ApprovalPanel: React.FC<IProps> = (props: IProps) => {
    const [isAmendingMode, setIsAmendingMode] = useState<boolean>(false);
    const [amendmentNote, setAmendmentNote] = useState<string>("");

    const handleUpsertApprovalStatus = (isApproved: boolean, requisitionStatusId: string) => {
        props.upsertApprovalStatus(isApproved, amendmentNote, requisitionStatusId);
    };

    const handleApprove = () => {
        const requisitionPOStatusId = props.approvedStatusId;
        handleUpsertApprovalStatus(true, requisitionPOStatusId ? requisitionPOStatusId : "");
    };

    const handleAmend = () => {
        const requisitionPOStatusId = props.amendRequiredStatusId;
        setIsAmendingMode(false);
        handleUpsertApprovalStatus(false, requisitionPOStatusId ? requisitionPOStatusId : "");
    };

    const handleReject = () => {
        const requisitionPOStatusId = props.rejectedStatusId;
        handleUpsertApprovalStatus(false, requisitionPOStatusId ? requisitionPOStatusId : "");
    };

    const cancelAmend = () => {
        setIsAmendingMode(false);
        setAmendmentNote("");
        if (props.handleCancelAmend) {
            props.handleCancelAmend();
        }
    };

    const canExecuteSendRequiresAmendment = () => {
        return isAmendingMode && !isEmptyOrWhitespace(amendmentNote);
    };

    const canExecuteCancelRequiresAmendment = () => {
        return isAmendingMode;
    };

    /**
     * Body component for a new approval. Shows when an approver needs to approve, require amend or reject an item.
     * @returns The component.
     */
    const bodyNewApprovalPanel = () => {
        return props.bodyNewApprovalPanel;
    };

    /**
     * Body component for amending an item. Shows when an amender needs to amend an item.
     * @returns The component.
     */
    const bodyAmenderPanel = () => {
        return props.bodyAmenderPanel;
    };

    /**
     * Body component for approving an amendment. Shows when an approver needs to approve an amendment.
     * @returns The component.
     */
    const bodyAmendedApprovalPanel = () => {
        return props.bodyAmendedApprovalPanel;
    };

    /**
     * The default Body component. Shows whenever the other body components don't show.
     * @returns The component.
     */
    const bodyDefault = () => {
        return props.bodyDefault;
    };

    /**
     * Determines which body component needs to be shown.
     * @returns The component to be shown.
     */
    const getBody = () => {
        if (props.canShowNewApprovalPanel) {
            return bodyNewApprovalPanel();
        } else if (props.canShowAmenderPanel) {
            return bodyAmenderPanel();
        } else if (props.canShowAmendedApprovalPanel) {
            return bodyAmendedApprovalPanel();
        } else {
            return bodyDefault();
        }
    };

    const handleGeneratePreviewPDF = async () => {
        if (props.handleGeneratePreviewPDF) {
            props.handleGeneratePreviewPDF();
        }
    };

    const fileChange = async (e: any) => {
        if (props.fileChange) {
            props.fileChange(e);
        }
    };

    const footer = () => {
        return (
            <ApprovalPanelFooter>
                {props.handleGeneratePreviewPDF && (
                    <p className={"pdf-link"} onClick={() => handleGeneratePreviewPDF()}>
                        Download PDF preview
                    </p>
                )}
                <ApprovalPanelApproveButton displayName="Approve" execute={() => handleApprove()} fullWidth={true} canExecute={!isAmendingMode && props.canUpsertStatus} />
                <ApprovalPanelAmendButton
                    displayName="Requires amend"
                    execute={() => setIsAmendingMode(true)}
                    fullWidth={true}
                    canExecute={!isAmendingMode && props.canUpsertStatus}
                />
                {isAmendingMode === true && (
                    <div className="amend-reason-container">
                        <TextField
                            id="amend-reason"
                            multiline
                            rows={10}
                            value={amendmentNote}
                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setAmendmentNote(event.target.value)}
                            fullWidth
                            className="textarea"
                        />
                        {props.fileChange && (
                            <div>
                                <Uploader handleSelectFile={(e: any) => fileChange(e)} isFormDisabled={!isAmendingMode} label="" />
                                {props.approvalDocumentsToUpload && (
                                    <div className="uploadedFile">
                                        {props.approvalDocumentsToUpload.map((item: ApprovalDocumentModel, index: number) => {
                                            return (
                                                !item.isDeleted && (
                                                    <div className="uploadedFile-box">
                                                        <div className="uploadedFile-heading">
                                                            <IconButton onClick={() => (props.downloadFile ? props.downloadFile(item.fileUrl, item.fileName) : () => {})}>
                                                                <SystemUpdateTwoToneIcon />
                                                            </IconButton>
                                                            <h3>{item.fileName}</h3>
                                                        </div>
                                                        <IconButton onClick={() => item.handleDelete(true)}>
                                                            <DeleteOutlinedIcon />
                                                        </IconButton>
                                                    </div>
                                                )
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        )}

                        <div className="amend-reason-button-container">
                            <ApprovalPanelSendButton displayName="Send" execute={() => handleAmend()} canExecute={canExecuteSendRequiresAmendment()} />
                            <DefaultButton displayName="Cancel" execute={() => cancelAmend()} canExecute={canExecuteCancelRequiresAmendment()} />
                        </div>
                    </div>
                )}
                <ApprovalPanelRejectButton displayName="Reject" execute={() => handleReject()} fullWidth={true} canExecute={!isAmendingMode && props.canUpsertStatus} />
            </ApprovalPanelFooter>
        );
    };

    return useObserver(() => {
        return (
            <ApprovalPanelContainer>
                <BaseSidebar
                    open={props.sideOpen}
                    primaryTitle={props.primaryTitle}
                    secondaryTitle={props.secondaryTitle}
                    onClose={() => {}}
                    bodyContent={getBody()}
                    footerContent={props.canViewFooter ? footer() : undefined}
                />
            </ApprovalPanelContainer>
        );
    });
};
