import { action, observable, computed } from "mobx";
import { ModelBase } from "@shoothill/core";

export class MaterialsFilterParamsModel extends ModelBase<MaterialsFilterParamsModel, MaterialsFilterParamsModelDTO> {
    @observable
    public includeDeleted: boolean = false;

    @observable
    public types: string[] | null = [];

    @observable
    public subTypes: string[] | null = [];

    @observable
    public uoms: string[] | null = [];

    @observable
    public suppliers: string[] | null = [];

    // @observable
    // public searchText: string = "";

    fromDto(model: MaterialsFilterParamsModelDTO): void {
        this.types = model.types;
        this.subTypes = model.subTypes;
        this.uoms = model.uoms;
        this.suppliers = model.suppliers;
    }

    toDto(): MaterialsFilterParamsModelDTO {
        let dto: MaterialsFilterParamsModelDTO = {
            includeDeleted: this.includeDeleted,
            types: this.types,
            subTypes: this.subTypes,
            uoms: this.uoms,
            suppliers: this.suppliers,
            initialLoad: true,
        };
        return dto;
    }

    @action
    public reset = () => {
        this.types = [];
        this.subTypes = [];
        this.uoms = [];
        this.suppliers = [];
    };
}

export interface MaterialsFilterParamsModelDTO {
    includeDeleted: boolean;
    types: string[] | null;
    subTypes: string[] | null;
    uoms: string[] | null;
    suppliers: string[] | null;
    initialLoad: boolean;
}
