import { Box, Typography } from "@material-ui/core";
import { useObserver } from "mobx-react-lite";
import React, { useState } from "react";
import styled from "styled-components";

interface IProps {
    className?: string;
    displayName: string;
}

const FormHeaderViewBase: React.FC<IProps> = (props) => {
    // #region Code Behind
    // #endregion Code Behind

    return useObserver(() => {
        return (
            <Box className={props.className}>
                <Typography variant="h1">{props.displayName}</Typography>
            </Box>
        );
    });
};

export const FormHeader = styled(FormHeaderViewBase)`
    margin: 27.5px 30px;
    //font-size: "32px";
    //letter-spacing: "-0.64px";
`;
