import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { PrimaryButton } from "../../../../Components/Buttons/Buttons";
import { SHBox } from "../../../../Components/Box";
import { PatchedPagination } from "../../../../Components/Table/PatchedPagination";
import * as Defaults from "../../../../Globals/Defaults/TableOptions";
import { DarwinTablePageContent } from "../../../../Globals/Styles/AppStyling";
import MaterialTable from "material-table";
import { formatDate, formatIncidentNumber, formatTime } from "../../../../Utils/Format";
import { IncidentListViewModel } from "./IncidentListViewModel";
import { IncidentListModel } from "./IncidentListModel";
import styled from "styled-components";

const Wrapper = styled.div`
    padding: 5px 30px;
`;

interface Props {
    projectId: string;
    projectName: string;
    projectReference: string;
}
export const IncidentsView: React.FC<Props> = observer((props: Props) => {
    const [listViewModel] = useState(() => new IncidentListViewModel());

    useEffect(() => {
        listViewModel.projectId = props.projectId;
        listViewModel.loadIncidentsAndRelated();
    }, []);

    const renderListView = (
        <SHBox>
            <SHBox grid dc={"300px 100px"} alignItems={"flex-end"}>
                <PrimaryButton canExecute={!listViewModel.IsLoading} execute={listViewModel.addIncidentForm} value={"Add"} />
            </SHBox>
            <DarwinTablePageContent>
                <MaterialTable
                    columns={[
                        {
                            title: "Date / time added",
                            field: "createdDate",
                            render: (rowData: IncidentListModel) => (
                                <SHBox>
                                    <SHBox>{rowData.createdDate ? `${formatDate(rowData.createdDate.toString())} @ ${formatTime(rowData.createdDate.toString())}` : ""}</SHBox>
                                </SHBox>
                            ),
                        },
                        { title: "Near miss", field: "nearMissCount" },
                        { title: "Accident", field: "accidentCount" },
                        { title: "Witness statement", field: "witnessStatementCount" },
                        {
                            title: "Incident no.",
                            field: "incidentNumber",
                            render: (rowData: IncidentListModel) => (
                                <SHBox>
                                    <SHBox>{formatIncidentNumber(rowData.incidentNumber)}</SHBox>
                                </SHBox>
                            ),
                        },
                        {
                            title: "Last updated",
                            field: "lastUpdatedDate",
                            render: (rowData: IncidentListModel) => (
                                <SHBox>
                                    <SHBox>
                                        {formatDate(rowData.lastUpdatedDate.toString())} @ {formatTime(rowData.lastUpdatedDate.toString())} by
                                    </SHBox>
                                    <SHBox>{`${rowData.lastUpdatedByUserName}`}</SHBox>
                                </SHBox>
                            ),
                        },
                    ]}
                    components={{
                        Pagination: PatchedPagination,
                    }}
                    options={Defaults.GetDarwinTableOptions()}
                    data={listViewModel.incidentListModels}
                    onRowClick={(e, rowData) => {
                        listViewModel.handleRowClick(e, rowData!);
                    }}
                    title=""
                />
            </DarwinTablePageContent>
        </SHBox>
    );

    return <Wrapper>{renderListView}</Wrapper>;
});
