import { KeyValuePair } from "@shoothill/core";

export enum OrderType {
    Unknown = 0,
    Purchase = "PURCHASE",
    Hire = "HIRE",
    Labour = "LABOUR",
}

export class OrderTypeHelpers {
    public static getText = (status: string) => {
        switch (status) {
            case OrderType.Purchase: {
                return "Purchase Order";
            }
            case OrderType.Hire:
            default: {
                return "Hire Order";
            }
        }
    };

    public static getOptions = (ignoreUnknown: boolean = false) => {
        const options: KeyValuePair[] = [];

        for (const key in OrderType) {
            const keyAsInt = parseInt(key);

            if (isNaN(keyAsInt) && key !== "Unknown") {
                const type: OrderType = key as OrderType;

                if (ignoreUnknown === false || type !== OrderType.Unknown) {
                    options.push({
                        key: OrderTypeHelpers.getText(OrderType[key]),
                        value: OrderType[key],
                    });
                }
            }
        }
        return options;
    };
}
