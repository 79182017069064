import * as MobX from "mobx";
import { ModelBase } from "@shoothill/core";
import { IEGridCategoryModel, IEGridCategoryModelDTO } from "../IEGridCategoryModel";

export class VariationGridModel extends ModelBase<VariationGridModel, VariationGridModelDTO> {
    public id: string = "";
    public variationNumber: number = 0;

    @MobX.observable
    public income: number = 0;

    @MobX.observable
    public estimateCost: number = 0;

    @MobX.observable
    public estimatedMargin: number = 0;

    @MobX.observable
    public futureSpend: number = 0;

    // @MobX.observable
    // public targetCost: number = 0;

    @MobX.observable
    public committedCost: number = 0;

    @MobX.observable
    public totalExpectedSpend: number = 0;

    @MobX.observable
    public variance: number = 0;

    @MobX.observable
    public changePeriod: number = 0;

    @MobX.observable
    public margin: number = 0;

    @MobX.observable
    public roValue: number = 0;

    /**
     * Currently just uses the IE models, but if any changes are required you should create dedicated models for the variation grid, such as VariationGridCategoryModel.
     */
    @MobX.observable
    public categories: IEGridCategoryModel[] = [];
    displayName: any;

    fromDto(model: VariationGridModelDTO): void {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else if (key === "categories") {
                    let items: IEGridCategoryModel[] = [];
                    model[key].forEach((element) => {
                        let newModel = new IEGridCategoryModel();
                        newModel.fromDto(element);
                        items.push(newModel);
                    });
                    this.categories = items;
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    toDto(model: VariationGridModel): void {}
}

export type VariationGridModelDTO = {
    id: string;
    description: string;
    income: number;
    estimateCost: number;
    estimatedMargin: number;
    futureSpend: number;
    //targetCost: number;
    committedCost: number;
    totalExpectedSpend: number;
    variance: number;
    changePeriod: number;
    margin: number;
    roValue: number;
    categories: IEGridCategoryModelDTO[];
};

// export interface VariationItemModalRequest {
//     categoryId: number;
//     subCategoryId: number;
//     descriptionId: number;
//     itemId: string;
// }
