import React from "react";

import { DownloadDrawingContainer } from ".";

interface IProps {}

export const NoDrawingsView: React.FC<IProps> = (props) => {
    return (
        <DownloadDrawingContainer>
            <div className="nodrawings">There are currently no drawings added to this stage.</div>
        </DownloadDrawingContainer>
    );
};
