import { TextField } from "@material-ui/core";
import { Cell, Grid } from "@shoothill/core";
import { useObserver } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { PrimaryButton } from "Components/Buttons/Buttons";
import { VariationNoteViewModel } from "./VariationNoteViewModel";
import { SolidDivider } from "Views/PurchaseOrder/Form/Views/Dividers";
import { VariationNoteDTO, VariationNoteModel } from "./VariationNoteModel";
import { StoresContext, Stores } from "Globals/Stores";

interface IProps {
    className?: string;
    addNote: (noteToAdd: VariationNoteDTO) => void;
    notes: VariationNoteViewModel[];
    label: string;
    canExecute: boolean;
}

const VariationNoteFormBase: React.FC<IProps> = (props) => {
    // #region Code Behind
    const { addNote: addVariationNote, notes, canExecute, label } = props;
    const [viewModel] = useState(() => new VariationNoteViewModel(new VariationNoteModel()));
    const storesContext = useContext<Stores>(StoresContext);

    useEffect(() => {
        return () => {
            viewModel.reset();
        };
    }, []);

    const COLUMNS8 = "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr";

    const addNote = async () => {
        if (await viewModel.isModelValid()) {
            addVariationNote(viewModel.model.toDto());
            viewModel.reset();
        }
    };

    const renderNotes = () => {
        return notes.map((n) => {
            return (
                <VariationNotetItem>
                    <div>
                        <div>
                            <span>Added:</span> {n.createdDateFormatted}
                        </div>
                        <div>
                            <span>Added by:</span> {n.createdByUserName ? n.createdByUserName : storesContext.Domain.AccountStore.DisplayName}
                        </div>
                    </div>
                    <div>{n.model.note}</div>
                </VariationNotetItem>
            );
        });
    };

    return useObserver(() => {
        return (
            <div>
                <VariationNoteFormContainer>
                    <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
                        <Cell ts={8} ds={8}>
                            <TextField
                                id="note"
                                multiline
                                rows={5}
                                value={viewModel.model.note}
                                onChange={(event: any) => viewModel.setValue("note", event.target.value)}
                                fullWidth
                                className="textarea"
                                placeholder={`Please add your note...`}
                                error={viewModel.getError("note") !== ""}
                                label={label}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={!canExecute}
                            />
                        </Cell>
                    </Grid>

                    <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
                        {/* Group of 8 columns */}
                        {/* <Cell display={{ xs: "none", md: "block" }} ts={1} ds={1} /> */}
                        <Cell ts={1} ds={1}>
                            <PrimaryButton displayName="Add note" execute={addNote} fullWidth={true} canExecute={canExecute} />
                        </Cell>
                    </Grid>
                </VariationNoteFormContainer>

                {notes && notes.length > 0 && <SolidDivider gutterBottom={true} />}

                {renderNotes()}
            </div>
        );
    });
};

export const VariationNotesContainer = styled(VariationNoteFormBase)``;

export const VariationNoteFormContainer = styled.div`
    button {
        margin: 20px 0px;
    }
`;

export const VariationNotetItem = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px 0px 20px 0px;

    &:last-child {
        margin: 20px 0px 0px 0px;
    }

    > div:nth-child(1) {
        display: flex;
        flex-direction: row;

        > div:nth-child(1) {
            margin-right: 50px;
        }

        > div {
            > span {
                font-weight: bold;
            }
        }
    }
`;
