import { ModelBase } from "@shoothill/core";
import { action, observable, IObservableArray } from "mobx";
import { RAMSChoiceModelDTO } from "./RAMSChoiceModel";
import { RAMSModel, RAMSModelDTO } from "./RAMSModel";
import { RAMSRelatedResultDTO, RAMSUserDTO } from "./RAMSReviewFormViewModel";
import { RAMSProjectDetailsModel } from "./RAMSProjectDetails";
import { ArrayNotEmpty, IsNotEmpty, arrayNotEmpty, isNotEmpty } from "class-validator";

export class RAMSReviewFormModel extends ModelBase<RAMSReviewFormModel> {
    public projectId: string = "";

    // Coming In related Data
    public supervisorUsers: IObservableArray<RAMSUserDTO> = observable([]);
    public reviewedByUsers: IObservableArray<RAMSUserDTO> = observable([]);
    public ramsProjectDetails: RAMSProjectDetailsModel = new RAMSProjectDetailsModel();

    // Images/Attachments
    @observable
    @ArrayNotEmpty({ message: "Please add an attachment" })
    public rAMSAttachments: RAMSAttachment[] = [];

    reset() {
        this.supervisorUsers.clear();
        this.reviewedByUsers.clear();
        this.ramsProjectDetails.reset();
    }

    public fromRelatedDto(result: RAMSRelatedResultDTO) {
        this.supervisorUsers.clear();
        this.reviewedByUsers.clear();
        this.ramsProjectDetails.reset();

        if (result !== null && result !== undefined) {
            this.supervisorUsers.replace(result.supervisorUsers);
            this.reviewedByUsers.replace(result.reviewedByUsers);
            this.ramsProjectDetails.fromDto(result.ramsProjectDetails);
        }
    }

    fromDto(model: RAMSAndRelatedResult): void {
        this.ramsProjectDetails.fromDto(model.ramsProjectDetails);
    }

    toDto() {
        return {};
    }
}

export interface UpsertRAMSAndRelatedDTO {
    rAMS: RAMSModelDTO;
    rAMSChoice: RAMSChoiceModelDTO;
}

export interface RAMSReviewPhotosFile {
    Id: string | null;
    fileName: string;
    photoUrl: string;
    createdByUserId: string;
    isDeleted: boolean;
}

export interface RAMSAndRelatedResult extends RAMSRelatedResult {
    rams: RAMS;
    ramsChoice: RAMSChoice;
    attachments: RAMSAttachment[];
}

export interface RAMSRelatedResult {
    supervisorUsers: RAMSUser[];
    reviewedByUsers: RAMSUser[];
    ramsProjectDetails: RAMSProjectDetails;
}

export interface RAMSUser {
    id: string;
    displayName: string;
}

export interface RAMSProjectDetails {
    projectReference: string;
    projectName: string;
    completedByUserName: string;
}

export interface RAMS {
    id: string;
    projectId: string;
    fileName: string;
    fileURL: string;
    title: string;
    subContractor: string;
    supervisorUserName: string | null;
    estimatedStartDate: string;
    reviewedByUserId: string;
    authorisationSignatureURL: string;
    authorisationSignatureDate: string | null;
    createdByUserId: string | null;
    lastUpdatedDate: string | null;
    lastUpdatedByUserId: string | null;
    completedDate: string | null;
    completedByUserId: string | null;
    isDeleted: boolean;
    rowVersion: string | null;
}

export interface RAMSChoice {
    id: string | null;
    rAMSId: string | null;
    hasPassedScopeOfWorks: boolean;
    reasonScopeOfWorks: string;
    hasPassedWhoPreparedDocument: boolean;
    reasonWhoPreparedDocument: string;
    hasPassedMethodStatement: boolean;
    reasonMethodStatement: string;
    hasPassedYoungPersonsRA: boolean;
    reasonYoungPersonsRA: string;
    hasPassedRequiredPPE: boolean;
    reasonRequiredPPE: string;
    hasPassedLocationOfWorks: boolean;
    reasonLocationOfWorks: string;
    hasPassedSequenceOfWorks: boolean;
    reasonSequenceOfWorks: string;
    hasPassedProgrammeOfWorks: boolean;
    reasonProgrammeOfWorks: string;
    hasPassedSitePersonnel: boolean;
    reasonSitePersonnel: string;
    hasPassedMaterialsInvolved: boolean;
    reasonMaterialsInvolved: string;
    hasPassedHAVsAssessments: boolean;
    reasonHAVsAssessments: string;
    hasPassedCOSHHAssessment: boolean;
    reasonCOSHHAssessment: string;
    hasPassedRiskAssessment: boolean;
    reasonRiskAssessment: string;
    hasPassedManualHandlingAssessment: boolean;
    reasonManualHandlingAssessment: string;
    hasPassedPlantAndEquipmentRequired: boolean;
    reasonPlantAndEquipmentRequired: string;
    hasPassedWorkingHours: boolean;
    reasonWorkingHours: string;
    hasPassedEmergencyRescuePlan: boolean;
    reasonEmergencyRescuePlan: string;
    hasPassedShoringPlan: boolean;
    reasonShoringPlan: string;
    isApprovedRAMSRecommended: boolean;
    reasonApprovedRAMSRecommended: string;
}

export interface RAMSAttachment {
    id: string | null;
    rAMSId: string | null;
    fileName: string;
    fileURL: string;
    createdByUserId: string | null;
    isDeleted: boolean;
}
