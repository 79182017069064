import { observable, runInAction } from "mobx";
import { FieldType, ViewModelBase } from "@shoothill/core";
import { LandingModel } from "./LandingModel";
import { AppUrls } from "../../../../AppUrls";
import { ServerViewModel } from "../../../../Globals/ViewModels/ServerViewModel";
import { UserModel } from "../Common/UserModel";

export class LandingViewModel extends ViewModelBase<LandingModel> {
    public server: ServerViewModel = new ServerViewModel();
    public userModel: UserModel = new UserModel();
    @observable
    public errorStatus = "";

    constructor(model = new LandingModel()) {
        super(model);
        this.setDecorators(model);
        this.userModel = JSON.parse(localStorage.getItem("user") as any);
        if (this.userModel === null) {
            this.userModel = new UserModel();
        }
    }

    public signIn = async () => {
        if (this.server.IsBusy) return;

        this.setIsLoading(true);

        return this.server
            .command<UserModel>(
                () =>
                    this.Post(`${AppUrls.Server.Induction.SignIn}`, {
                        lastName: this.model.lastName,
                        lastThreeDigits: this.model.lastThreeDigits,
                        projectId: this.model.projectId,
                        ramsAnswered: this.ramsAnsweredForToday(),
                    }),
                (result: any) => {
                    runInAction(() => {
                        this.userModel.id = result.id;
                        this.userModel.fullName = result.firstName + " " + result.lastName;
                        this.userModel.priorityEmployerName = result.priorityEmployerName;
                        this.userModel.photographUrl = result.photographUrl;
                        this.userModel.visitorTypeName = result.visitorTypeName;
                        this.userModel.projectId = this.model.projectId;

                        if (this.ramsAnsweredForToday()) {
                            this.userModel.signedIn = true;

                            localStorage.setItem("signedIn", "true");
                            localStorage.setItem("user", JSON.stringify(this.userModel));

                            this.history.push(AppUrls.Client.Induction.Access.SignedIn);
                        } else {
                            this.userModel.signedIn = false;

                            // We need to store the user locally, so that the information
                            // is made available to the RAMS page.
                            localStorage.setItem("user", JSON.stringify(this.userModel));

                            this.history.push(AppUrls.Client.Induction.Access.Rams.replace(":projectId", this.model.projectId));
                        }
                    });
                },
                this.isModelValid,
            )
            .finally(() => this.setIsLoading(false));
    };

    public ramsAnsweredForToday = () => {
        const signedInDate = localStorage.getItem("signedInDate");

        return signedInDate !== null && signedInDate === new Date().toLocaleDateString();
    };

    public signOut = async () => {
        if (this.server.IsBusy) return;

        this.setIsLoading(true);

        return this.server
            .command<UserModel>(
                () =>
                    this.Post(`${AppUrls.Server.Induction.SignOut}`, {
                        id: this.userModel.id,
                        lastName: this.model.lastName,
                        lastThreeDigits: this.model.lastThreeDigits,
                    }),
                (result: any) => {
                    runInAction(() => {
                        this.userModel.signedIn = false;

                        localStorage.setItem("signedIn", "false");
                        localStorage.setItem("user", JSON.stringify(this.userModel));

                        this.history.push(AppUrls.Client.Induction.Access.SignedIn);
                    });
                },
                this.isModelValid,
            )
            .finally(() => this.setIsLoading(false));
    };

    public async isFieldValid(fieldName: keyof FieldType<LandingModel>, value: any): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
