import { action, observable } from "mobx";
import { ModelBase } from "@shoothill/core";
import { ProjectDTO } from "Views/PurchaseOrder/Form/Supporting/ProjectModel";

export class ProjectReportModel extends ModelBase<ProjectReportModel, any> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Constants and Defaults

    // #endregion Constants and Defaults

    // #region Properties

    @observable
    public projectId: string = "";

    @observable
    public reportType: string = "";

    // #endregion Properties

    // #region Actions

    @action
    public reset = () => {
        this.reportType = "";
        this.projectId = "";
    };

    @action
    public fromDto(dto: any): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in dto) {
            if (dto.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(dto[key]);
                } else {
                    this[key] = dto[key];
                }
            }
        }
    }

    public toDto(): void {}

    // #endregion Actions

    // #region Custom Validation

    // #endregion Business Logic
}

export interface ReportRelatedResponseDTO {
    projects: ProjectDTO[];
}
