import { observable, runInAction, action, computed } from "mobx";
import { ApiResult, FieldType, ViewModelBase } from "@shoothill/core";
import { AuditQuestionModel } from "./AuditQuestionModel";
import { AppUrls } from "../../../../../AppUrls";
import { AuditDocumentModel } from "../AuditDocument";

export class AuditQuestionViewModel extends ViewModelBase<AuditQuestionModel> {
    constructor(model = new AuditQuestionModel()) {
        super(model);
        this.setDecorators(AuditQuestionModel);
    }

    @action
    public passFailChanged = (value: string) => {
        this.model.answer = value;
        this.isFieldValid("answer", this.model.answer);
    };

    @computed
    public get auditDocuments(): AuditDocumentModel[] {
        return this.model.auditDocuments.filter((d) => !d.isDeleted);
    }

    /**
     * Handle a file being selected and process the data for upload.
     * @param event
     */
    @action
    public fileChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        if (event.target.files !== null && event.target.value !== null && event.target.files.length > 0) {
            let data: any = {
                fileName: event.target.files[0].name,
                formFile: event.target.files[0],
            };
            event.target.value = "";
            const apiResult = await this.fileUpload(data);
            if (apiResult && apiResult.wasSuccessful) {
                let fileToDisplay: AuditDocumentModel = new AuditDocumentModel();
                //fileToDisplayid: Math.random().toString(16).substr(2, 8), // 6de5ccda
                fileToDisplay.fileName = data.fileName;
                fileToDisplay.url = apiResult.payload;
                fileToDisplay.isDeleted = false;

                runInAction(() => {
                    this.model.auditDocuments.push(fileToDisplay);
                    this.isFieldValid("auditDocuments", this.model.auditDocuments);
                });
            }
        }
    };

    /**
     * Upload a file to azure.
     * @param data The data of the file to be uploaded.
     * @returns apiResult.
     */
    public fileUpload = async (data: any): Promise<ApiResult<any>> => {
        const formData = new FormData();
        formData.append("formFile", data.formFile);
        formData.append("fileName", data.fileName);
        const apiResult = await this.Post<any>(AppUrls.Server.File.UploadFile, formData);
        if (apiResult) {
            if (!apiResult.wasSuccessful) {
                console.log(apiResult.errors);
                runInAction(() => {
                    /*this.setSnackMessage("Error uploading file please try again.");
                    this.setSnackType(this.SNACKERROR);
                    this.setSnackbarState(true);*/
                });
            }
        }
        return apiResult;
    };

    /**
     * Download a file that exists in azure.
     * @param fileUrl The URL of the file to be downloaded.
     * @param fileName The name of the file to be downloaded.
     */
    public DownloadFile = async (fileUrl: string, fileName: string): Promise<void> => {
        try {
            const apiResult = await this.Post<Blob>(AppUrls.Server.File.DownloadFile, fileUrl, undefined, { responseType: "blob" });
            const response = apiResult as any;
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
        } catch (exception) {
            console.error(exception);
            this.setIsErrored(true);
        }
    };

    /**
     * Delete a document from the local array.
     * @param id The id of the document to be deleted
     */
    @action
    public handleDeleteDocument = (id: string | null): void => {
        // Used to delete by index but there's different types of documents separated into different lists.
        if (id !== null) {
            for (let i = 0; i < this.model.auditDocuments.length; i++) {
                if (this.model.auditDocuments[i].id === id) {
                    this.model.auditDocuments[i].isDeleted = true;
                }
            }
        }
    };

    public async isFieldValid(fieldName: keyof FieldType<AuditQuestionModel>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        if (this.model.showPassFail || this.model.showPassFailNA) {
            if (!this.model.answer || this.model.answer === "") {
                isValid = false;
                errorMessage = "Please select an answer.";
            }
        }

        if (this.model.showPhotoUpload) {
            if (!this.model.auditDocuments || this.model.auditDocuments.length === 0) {
                isValid = false;
                errorMessage = "Please upload at least one photo.";
            }
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);
        if (!isValid) {
            console.log("isFieldValid", fieldName, value, isValid, errorMessage);
        }
        return isValid;
    }
    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
