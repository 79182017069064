import { ModelBase } from "@shoothill/core";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class ProjectTrackersModel extends ModelBase<ProjectTrackersModel, ProjectTrackersModelDTO> {
    // DarwinModelBase
    // public id: string = "";
    // public createdDate: string = "";

    //fromDto is required but you can leave it blank
    fromDto(model: ProjectTrackersModelDTO): void {}

    //toDto is required but you can leave it blank
    toDto(model: ProjectTrackersModel): void {}
}

export type ProjectTrackersModelDTO = {};
