import { FieldType, ViewModelBase } from "@shoothill/core";
import { action, observable, runInAction, computed } from "mobx";

import { AppUrls } from "AppUrls";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { IDrawing, IssueItemViewModel, IssueModel } from ".";

export class IssueViewModel extends ViewModelBase<IssueModel> {
    public server: ServerViewModel = new ServerViewModel();

    private outputIds: string[];
    private planOfWorkId: string;
    public issueItems = observable<IssueItemViewModel>([]);
    private parentCloseAction: (refreshPage: boolean) => void;

    constructor(outputIds: string[], planOfWorkId: string, closeAction: (refreshPage: boolean) => void, isIssue: boolean) {
        super(new IssueModel(), false);

        this.outputIds = outputIds;
        this.planOfWorkId = planOfWorkId;
        this.parentCloseAction = closeAction;
        this.model.isIssue = isIssue;

        this.setDecorators(IssueModel);

        // Load issue data.
        this.apiLoadIssueDataAsync();
    }

    @computed
    public get title(): string {
        return this.model.isIssue ? "Issue" : "Communication";
    }

    // #region Issue

    public isIssueSelected = (drawing: IDrawing) => {
        return this.model.issueItems.some((item: string) => item === drawing.drawingId);
    };

    @action
    public setIssueSelection = (drawing: IDrawing) => {
        if (this.model.issueItems.some((item: string) => item === drawing.drawingId)) {
            this.model.issueItems.remove(drawing.drawingId);
        } else {
            this.model.issueItems.push(drawing.drawingId);
        }

        this.isFieldValid("issueItems");
    };

    // #endregion Issue

    // #region Actions

    @action
    public cancel = () => this.parentCloseAction(false);

    // #endregion Actions

    // #region Api Actions

    @action
    public apiLoadIssueDataAsync = async (): Promise<void> => {
        await this.server.query<any>(
            () => this.Post(AppUrls.Server.Projects.ProjectTrackers.OutputTracker.Outputs.IssueRelatedByOutputIds, this.outputIds),
            (result) => {
                runInAction(() => {
                    this.issueItems.replace(
                        result.map((dto: any) => {
                            const issueItem = new IssueItemViewModel();

                            issueItem.outputTypeDisplayName = dto.outputTypeDisplayName;
                            issueItem.outputTypeId = dto.outputTypeId;
                            issueItem.drawings = dto.drawings;

                            return issueItem;
                        }),
                    );
                });
            },
            "Error whilst loading output and drawing data",
        );

        if (this.server.HaveValidationMessage) {
            this.setSnackMessage(this.server.ValidationMessage);
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }
    };

    @action
    public apiIssueAsync = async (): Promise<void> => {
        let model = this.model.toDto();
        model.planOfWorkId = this.planOfWorkId;
        await this.server.command<any>(
            () => this.Post(AppUrls.Server.Projects.ProjectTrackers.OutputTracker.Outputs.IssueDrawings, model),
            (result) => this.parentCloseAction(true),
            this.isModelValid,
            "Error whilst issuing drawings",
        );

        if (this.server.HaveValidationMessage) {
            this.setSnackMessage(this.server.ValidationMessage);
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }
    };

    // #endregion Api Actions

    // #region Snack Bar

    public SNACKERROR = "error";

    @observable
    public snackbarState = false;

    @observable
    public snackType = "";

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public snackMessage = "";

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    // #endregion Snack Bar

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<IssueModel>): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boliderplate
}
