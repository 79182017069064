import { Box } from "@material-ui/core";
import { Cell, Grid, useRouter } from "@shoothill/core";
import { observer } from "mobx-react-lite";
import { runInAction } from "mobx";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { PrimaryButton } from "Components/Buttons/Buttons";
import { ApproveDrawingViewModel } from "./ApproveDrawingViewModel";
import { SnackBar } from "Components/SnackBar/SnackBar";
import { DrawingApprovalPanel } from "./DrawingApprovalPanel";
import { AppUrls } from "AppUrls";
import { useLocation } from "react-router-dom";
import { Form, FormSection } from "Views/PurchaseOrder/Form/Views/Forms";
import { SendForApprovalModal } from "Views/PurchaseOrder/Form/SendForApprovalModal";
import { DashedDivider, SolidDivider } from "Views/PurchaseOrder/Form/Views/Dividers";
import { DarwinInputWrapper } from "Components/Form/DarwinInputWrapper";
import { Files } from "Components/Files/Files/Files";
import { ApproveDrawingModel } from "./ApproveDrawingModel";
import { DownloadDrawingView } from "../../Shared/DownloadDrawingView";
import { pxToRem, theme } from "Globals/Styles/AppTheme";
import { BackButtonHolder } from "Views/Project/Commercial/IETables/IEGrid.Styles";
import { CustomArrow } from "Views/Project/CustomComponents";

interface IProps {
    className?: string;
    drawingId?: string | null;
}

const ApproveDrawingViewBase: React.FC<IProps> = observer((props) => {
    // #region Code Behind

    const { match } = useRouter();
    const location = useLocation();
    const { drawingid } = match.params as any;
    const [viewModel] = useState(() => new ApproveDrawingViewModel());
    const [isFromApprovalSection, setIsFromApprovalSection] = useState<boolean>(false);

    const COLUMNS8 = "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr";

    useEffect(() => {
        runInAction(() => {
            viewModel.model.id = props.drawingId ? props.drawingId : drawingid;
        });
        let handleDataLoading = new Promise<void>(async (resolve, reject) => {
            try {
                await viewModel.loadWithRelated();
                resolve();
            } catch (error) {
                reject(error);
            }
        })
            .then(async () => {
                if (props.drawingId) {
                    viewModel.setIsViewOnly(true);
                }
            })
            .then(() => {})
            .catch((error) => {
                // handle error
            });

        // Check if the user has navigated from the approvals list.
        const { approvalMode } = match.params as any;
        if (approvalMode && approvalMode === "true") {
            setIsFromApprovalSection(true);
        } else {
            setIsFromApprovalSection(false);
        }

        // Check if the form should be view only.
        if (viewModel.model.id && AppUrls.Client.Project.OutputTracker.Drawings.View.replace(":drawingid", viewModel.model.id) === location.pathname) {
            viewModel.setIsViewOnly(true);
        }
    }, []);

    const onSubmit = (event: any) => {
        event.preventDefault();
    };

    return (
        <Form className={props.className} onSubmit={onSubmit}>
            {viewModel.showSendForApprovalModal && (
                <SendForApprovalModal
                    onClose={() => viewModel.handleShowSendForApprovalModalChange(false)}
                    open={viewModel.getShowSendForApprovalModal}
                    title="Send for approval"
                    confirmText="Send for approval"
                    onSave={viewModel.amendDrawing}
                    value={viewModel.model.requesterNotes}
                    setValue={(val: string) => viewModel.setRequesterNote(val)}
                    isLoading={viewModel.IsLoading}
                    isDisabled={!viewModel.getRequesterNoteValid}
                />
            )}
            <DrawingApprovalPanel
                viewModel={viewModel}
                sideOpen={viewModel.approvalPanelViewModel.getCanShowPanel && !viewModel.IsLoading && isFromApprovalSection}
                isFromApprovalSection={isFromApprovalSection}
                primaryTitle={viewModel.approvalPanelViewModel.getTitle}
                secondaryTitle={`${viewModel.approvalPanelViewModel.model.requesterName} ${viewModel.approvalPanelViewModel.getRequestedDateFormatted}`}
            />
            <BackButtonHolder
                onClick={() => {
                    viewModel.goBack();
                }}
                style={{ minHeight: "30px", paddingTop: "3px" }}
            >
                <div style={{ position: "relative", height: "30px", display: "flex", marginLeft: "10px" }}>
                    <div style={{ position: "absolute", top: "5px" }}>
                        <CustomArrow size={"8px"} color={theme.palette.blue.main} type={"left"} />
                    </div>
                    <div style={{ marginLeft: "20px", fontSize: "18px", fontWeight: "bold", color: theme.palette.blue.main }}>Back</div>
                </div>
            </BackButtonHolder>
            <DashedDivider gutterBottom={true} borderThickness={2} borderOpacity={0.5} />
            <ContentContainer>
                <DarwinInputWrapper<ApproveDrawingModel>
                    type="text"
                    label="Description:*"
                    placeHolder="Description"
                    validateOnBlur={true}
                    viewModel={viewModel}
                    fieldName="description"
                    shrink={true}
                    editMode={!viewModel.isFormDisabled && !viewModel.IsLoading}
                />

                <SolidDivider gutterTop={true} borderOpacity={0} borderThickness={0} />

                <Files viewModel={viewModel.filesViewModel} disabled={viewModel.isFormDisabled} />

                {viewModel.filesViewModel.fileViewModels.length === 0 && viewModel.model.attachmentUrl !== "" && (
                    <DownloadDrawingView fileName={viewModel.model.name!} attachmentUrl={viewModel.model.attachmentUrl!} inlineUrl={viewModel.model.inlineUrl!} />
                )}

                <SolidDivider gutterBottom={true} />

                {viewModel.canAmendDrawing && (
                    <FormButtonsContainer>
                        <PrimaryButton
                            displayName="Resubmit amendment"
                            execute={() => viewModel.handleShowSendForApprovalModalChange(true)}
                            fullWidth={true}
                            canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                        />
                    </FormButtonsContainer>
                )}
            </ContentContainer>
            <SnackBar
                messageText={viewModel.snackMessage}
                messageType={viewModel.snackType}
                active={viewModel.snackbarState}
                closeOption={() => viewModel.setSnackbarState(false)}
                autoHideDuration={5000}
            />
        </Form>
    );
});

export const ApproveDrawingView = styled(ApproveDrawingViewBase)`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

const HeaderContainer = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 70px;
`;

export const FormButtonsContainer = styled(FormSection)`
    padding: 10px 0px;
    background-color: #ffffff;
    border-top: 1px solid lightgrey;
    margin: 0px;
    display: flex;
    justify-content: center;

    button {
        max-width: 350px;
        > span {
            font-size: ${pxToRem(14)} !important;
        }
    }
`;

export const ContentContainer = styled.div`
    max-width: 700px;
    margin: 0 auto;
    width: 100%;
    margin-top: 20px;
`;
