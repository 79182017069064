import { ModelBase, observable } from "@shoothill/core";
import { action, computed } from "mobx";
import moment from "moment";
export class DailyProgrammeProgressUpdateModel extends ModelBase<DailyProgrammeProgressUpdateModel> {
    public static readonly DEFAULT_ID = null;
    public static readonly DEFAULT_PROJECT_ID = "";
    public static readonly DEFAULT_DAILY_PROGRAMME_PROGRESS_NOTE = "";
    public static readonly DEFAULT_DAILY_PROGRAMME_PROGRESS_PHOTOS = [];
    public static readonly DEFAULT_CREATED_BY_USER_ID = "";
    public static readonly DEFAULT_CREATED_DATE = new Date();
    public static readonly DEFAULT_IS_DELETED = false;
    public static readonly DEFAULT_LAST_UPDATED_BY_USER_ID = "";
    public static readonly DEFAULT_LAST_UPDATED_DATE = "";
    public static readonly DEFAULT_ROW_VERSION = "";

    @observable
    public id: string | null = DailyProgrammeProgressUpdateModel.DEFAULT_ID;

    @observable
    public projectId: string | null = DailyProgrammeProgressUpdateModel.DEFAULT_PROJECT_ID;

    @observable
    public dailyProgrammeProgressNote: string | null = DailyProgrammeProgressUpdateModel.DEFAULT_DAILY_PROGRAMME_PROGRESS_NOTE;

    @observable
    public dailyProgrammeProgressPhotos: DailyProgrammeProgressUpdateFile[] = DailyProgrammeProgressUpdateModel.DEFAULT_DAILY_PROGRAMME_PROGRESS_PHOTOS;

    @observable
    public createdByUserId: string | null = DailyProgrammeProgressUpdateModel.DEFAULT_CREATED_BY_USER_ID;

    @observable
    public createdDate: any = DailyProgrammeProgressUpdateModel.DEFAULT_CREATED_DATE;

    @observable
    public isDeleted: boolean = DailyProgrammeProgressUpdateModel.DEFAULT_IS_DELETED;

    @observable
    public lastUpdatedByUserId: string | null = DailyProgrammeProgressUpdateModel.DEFAULT_LAST_UPDATED_BY_USER_ID;

    @observable
    public lastUpdatedDate: string | null = DailyProgrammeProgressUpdateModel.DEFAULT_LAST_UPDATED_DATE;

    @observable
    public rowVersion: string | null = DailyProgrammeProgressUpdateModel.DEFAULT_ROW_VERSION;

    @computed
    public get hasId(): boolean {
        return this.id !== null && this.id !== undefined && this.id !== "";
    }

    @computed
    public get formattedLastUpdatedDate(): string {
        return moment(this.lastUpdatedDate).format("DD/MM/YYYY @ HH:mm");
    }

    @action
    public fromDto(dto: DailyProgrammeProgressUpdateDTO): void {
        for (let key in dto) {
            if (dto.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(dto[key]);
                } else {
                    this[key] = dto[key];
                }
            }
        }
    }

    @action
    public fromDailyProgrammeProgressPhotosDTO(dto: DailyProgrammeProgressUpdateFile[]): void {
        let processedPhotos: DailyProgrammeProgressUpdateFile[] = [];
        for (const photo of dto) {
            processedPhotos.push(photo);
        }
        this.dailyProgrammeProgressPhotos = processedPhotos;
    }

    @action
    public toDto() {
        const DailyProgrammeProgressUpdateModel: DailyProgrammeProgressUpdateDTO = {
            id: this.id,
            projectId: this.projectId,
            dailyProgrammeProgressNote: this.dailyProgrammeProgressNote,
            createdByUserId: this.createdByUserId,
            createdDate: this.createdDate,
            isDeleted: this.isDeleted,
            lastUpdatedByUserId: this.lastUpdatedByUserId,
            lastUpdatedDate: this.lastUpdatedDate,
            rowVersion: this.rowVersion,
        };

        return DailyProgrammeProgressUpdateModel;
    }

    @action
    public reset = () => {
        this.id = DailyProgrammeProgressUpdateModel.DEFAULT_ID;
        this.projectId = DailyProgrammeProgressUpdateModel.DEFAULT_PROJECT_ID;
        this.dailyProgrammeProgressNote = DailyProgrammeProgressUpdateModel.DEFAULT_DAILY_PROGRAMME_PROGRESS_NOTE;
        this.dailyProgrammeProgressPhotos = DailyProgrammeProgressUpdateModel.DEFAULT_DAILY_PROGRAMME_PROGRESS_PHOTOS;
    };

    // #Start Custome Validation

    @computed
    public get validateDailyProgrammeProgressNote(): string {
        return this.dailyProgrammeProgressNote === DailyProgrammeProgressUpdateModel.DEFAULT_DAILY_PROGRAMME_PROGRESS_NOTE ? "Please enter a note" : "";
    }

    // #End Custome Validation
}

export interface DailyProgrammeProgressUpdateDTO {
    id: string | null;
    projectId: string | null;
    dailyProgrammeProgressNote: string | null;
    createdByUserId: string | null;
    createdDate: string | null;
    isDeleted: boolean;
    lastUpdatedByUserId: string | null;
    lastUpdatedDate: string | null;
    rowVersion: string | null;
}

export interface DailyProgrammeProgressUpdateFile {
    id: string | null;
    dailyProgrammeProgressId: string | null;
    fileName: string;
    photoURL: string;
    createdByUserId: string | null;
    isDeleted: boolean;
    createdDate: string | null;
}

export interface UpsertDailyProgrammeProgressUpdateRequestDTO {
    dailyProgrammeProgressUpdate: DailyProgrammeProgressUpdateDTO;
    dailyProgrammeProgressUpdatePhotos: DailyProgrammeProgressUpdateFile[];
}

export interface UpsertDailyProgrammeProgressUpdateResponseDTO {
    dailyProgrammeProgressUpdate: DailyProgrammeProgressUpdateDTO;
    dailyProgrammeProgressUpdatePhotos: DailyProgrammeProgressUpdateFile[];
}

export interface DailyProgrammeProgressUpdateByIdResponseDTO {
    dailyProgrammeProgressUpdate: DailyProgrammeProgressUpdateDTO;
    dailyProgrammeProgressUpdatePhotos: DailyProgrammeProgressUpdateFile[];
}

export interface DailyProgrammeProgressUpdateResponseDTO {
    dailyProgrammeProgressUpdate: DailyProgrammeProgressUpdateDTO;
    dailyProgrammeProgressUpdatePhotos: DailyProgrammeProgressUpdateFile[];
}

export interface FormRequestDTO {
    date: string | null;
    projectId: string | null;
    formId: string | null;
}
