import React, { useEffect } from "react";
import { Page, Text, View, Document, StyleSheet, Image } from "@react-pdf/renderer";
import { SiteAccessViewModel } from "./SiteAccessViewModel";
import { ProjectGeneralViewModel } from "../General/ProjectGeneralViewModel";
import { SHBox } from "../../../Components/Box";
import styled from "styled-components";
import { AppUrls } from "../../../AppUrls";
import QRCode from "react-qr-code";
import { useParams } from "react-router";
import ScanMe from "../../../Content/scanme.jpg";
import darwinLogo from "../../../Content/darwinlogo.png";

const Wrapper = styled(SHBox)`
    font-family: "open sans";
    width: 21cm;
    height: 29.7cm;
    display: flex;
    flex-direction: column;
    border: 1px solid black;
    align-items: center;
    //margin: 5mm 5mm 5mm 5mm;
    padding: 1cm;
    .title {
        font-size: 40px;
        font-weight: 600;
        text-align: center;
    }
    .subTitle {
        font-size: 24px;
        letter-spacing: -0.01em;
        line-height: 24px;
        color: #333;
        text-align: center;
        padding-top: 1cm;
        padding-bottom: 1cm;
    }
`;
type Params = {
    projectId: string;
    displayName: string;
    bitly: string;
};
interface Props {
    imgURL: string;
    viewModel: SiteAccessViewModel;
    generalViewModel: ProjectGeneralViewModel;
}

// Create Document Component
export const SiteAccessQRCodePDFTemplate = (props: Props) => {
    const svgRef = React.useRef<any>(null);
    const { projectId, displayName, bitly } = useParams<Params>();
    useEffect(() => {
        console.log(projectId, displayName, bitly);
    }, []);

    return (
        <Wrapper>
            <SHBox className={"title"}>{displayName}</SHBox>
            <SHBox className={"subTitle"}>Please scan the below QR code with your smartphone camera on arriving and leaving at this address</SHBox>
            <SHBox mb={"30px"} style={{ border: "1px solid #0095da", height: "1px", width: "100%" }} />
            <QRCode id="QRCode" value={`https://${location.host}${AppUrls.Client.Induction.Access.Landing.replace(":projectId", projectId)}`} size={225} ref={svgRef} />
            <img src={ScanMe} />
            <SHBox mt={"60px"}>
                <SHBox>Or enter the following web address and fill in the form</SHBox>
                <SHBox className={"title"}>https://bit.ly/{bitly}</SHBox>
            </SHBox>
            <SHBox mt={"6cm"}>
                <img height={"100px"} src={darwinLogo} alt="Darwin Group" className="logoDarwin" />
            </SHBox>
        </Wrapper>
    );
};
