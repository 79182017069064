import { action, computed, observable } from "mobx";
import { isEmptyOrWhitespace, ModelBase } from "@shoothill/core";

import { IncomeAndExpenditureCellModelDTO } from "./IncomeAndExpenditureCellModel";
import { IncomeAndExpenditureParentModel, IncomeAndExpenditureParentModelDTO } from "./IncomeAndExpenditureParentModel";
import { IncomeAndExpenditureTypeModel, IncomeAndExpenditureTypeModelDTO } from "./IncomeAndExpenditureTypeModel";
import { IEAdministrationRoleUserModel, IEAdministrationRoleUserRequestDTO } from "./IEAdministrationRoleUserModel";
import { RoleDTO } from "./RoleModel";
import { UserDTO } from "./UserModel";

export class IncomeAndExpenditureModel extends ModelBase<IncomeAndExpenditureModel, IncomeAndExpenditureUpsertResponseDTO> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Constants and Defaults

    public static readonly DEFAULT_ID = null;
    public static readonly DEFAULT_CELL = -99;
    public static readonly DEFAULT_NAME = "";
    public static readonly DEFAULT_NAMEDID = null;
    public static readonly DEFAULT_PARENTID = null;
    public static readonly DEFAULT_PROJECTID = "";
    public static readonly DEFAULT_TYPEID = IncomeAndExpenditureTypeModel.CONSTANT_TYPEMASTERID;
    public static readonly DEFAULT_ROWVERSION = null;
    public static readonly DEFAULT_IEADMINISTRATIONROLEUSERS = [];

    // #endregion Constants and Defaults

    // #region Properties

    @observable
    public id: string | null = IncomeAndExpenditureModel.DEFAULT_ID;

    @observable
    public cell: number = IncomeAndExpenditureModel.DEFAULT_CELL;

    @observable
    public name: string = IncomeAndExpenditureModel.DEFAULT_NAME;

    @observable
    public nameId: string | null = IncomeAndExpenditureModel.DEFAULT_NAMEDID;

    @observable
    public parentId: string | null = IncomeAndExpenditureModel.DEFAULT_PARENTID;

    @observable
    public projectId: string = IncomeAndExpenditureModel.DEFAULT_PROJECTID;

    @observable
    public typeId: string = IncomeAndExpenditureModel.DEFAULT_TYPEID;

    @observable
    public rowVersion: string | null = IncomeAndExpenditureModel.DEFAULT_ROWVERSION;

    @observable
    public ieAdministrationRoleUsers = observable<IEAdministrationRoleUserModel>(IncomeAndExpenditureModel.DEFAULT_IEADMINISTRATIONROLEUSERS);

    // #endregion Properties

    // #region Actions

    @action
    public fromDto(dto: IncomeAndExpenditureUpsertResponseDTO): void {
        this.id = dto.id;
        this.parentId = dto.parentId;
        this.projectId = dto.projectId;
        this.name = dto.name;
        this.nameId = dto.nameId;
        this.cell = dto.cell;
        this.typeId = dto.type;
        this.rowVersion = dto.rowVersion;
    }

    public toDto(): IncomeAndExpenditureUpsertRequestDTO {
        return {
            id: this.id,
            parentId: this.parentId,
            projectId: this.projectId,
            name: this.name,
            nameId: this.nameId,
            cell: this.cell,
            type: this.typeId,
            rowVersion: this.rowVersion,
            ieAdministrationRoleUsers: this.ieAdministrationRoleUsers.map((a) => {
                return a.toDto();
            }),
        };
    }

    // #endregion Actions

    // #region Custom Validation

    @computed
    public get validateCell(): string {
        // RULES
        // A cell must be selected.
        return this.cell === IncomeAndExpenditureModel.DEFAULT_CELL ? "Please select a cell" : "";
    }

    @computed
    public get validateParentId(): string {
        // RULES
        // A parent must be selected if the type is a sub.
        return this.typeId === IncomeAndExpenditureTypeModel.CONSTANT_TYPESUBID ? (this.parentId === IncomeAndExpenditureModel.DEFAULT_PARENTID ? "Please select a link" : "") : "";
    }

    public validateName = (name: string, parents: IncomeAndExpenditureParentModel[]): string => {
        // RULES
        // 1. A name cannot be null or whitespace.
        // 2. A name cannot match the name of an existing I&E.
        return isEmptyOrWhitespace(this.name) ? "Please provide a name" : parents.findIndex((p) => p.displayName === name) !== -1 ? "Name is already in use by another I&E" : "";
    };

    // #endregion Custom Validation
}

export interface IncomeAndExpenditureUpsertRequestDTO {
    id: string | null;
    parentId: string | null;
    projectId: string;
    name: string;
    nameId: string | null;
    cell: number;
    type: string;
    rowVersion: string | null;
    ieAdministrationRoleUsers: IEAdministrationRoleUserRequestDTO[];
}

export interface IncomeAndExpenditureUpsertResponseDTO {
    id: string;
    parentId: string | null;
    projectId: string;
    name: string;
    nameId: string;
    cell: number;
    type: string;
    rowVersion: string;
}

export interface IncomeAndExpenditureRelatedResponseDTO {
    cells: IncomeAndExpenditureCellModelDTO[];
    parents: IncomeAndExpenditureParentModelDTO[];
    types: IncomeAndExpenditureTypeModelDTO[];
    ieAdministrationRoles: RoleDTO[];
    users: UserDTO[];
    ieAdministrativeRoleUsers: IEAdministrationRoleUserRequestDTO[];
}

export interface IncomeAndExpenditureAndRelatedResponseDTO extends IncomeAndExpenditureUpsertResponseDTO, IncomeAndExpenditureRelatedResponseDTO {}
